import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate, useLocation } from "react-router-dom";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import DepartmentEntry from 'Masters/MDepartment/FrmDepartmentEntry';
import ConfigConstants from "assets/Constants/config-constant";
import FrmProductionSectionEntry from 'Masters/MProductionSection/FrmProductionSectionEntry';
import FrmProductionSubSectionEntry from 'Masters/MProductionSubSection/FrmProductionSubSectionEntry';


function MFrmProductionWastageTypesEntry() {

  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
  var activeValue = '';

  // For navigate
  const navigate = useNavigate();
  const comboDataAPiCall = useRef();
  const validate = useRef();

  const { state } = useLocation();
  const { production_wastage_typesId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

  //MODEL
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')


  const [Chk_production_wastage_type, setChk_Chk_production_wastage_type] = useState('1');
  const [txt_production_wastage_types_name, settxt_production_wastage_types_name] = useState('');
  const [txt_std_wastage_percent, settxt_std_wastage_percent] = useState('');
  const [cmb_production_department_Id, setcmb_production_department_Id] = useState('');
  const [productiondepartmentId, setproductiondepartmentId] = useState([]);
  const [cmb_section_id, setProdSection] = useState();
  const [cmb_sub_section_id, setProdSubSection] = useState();
  // const [productionSubdepartmentId, setproductionSubdepartmentId] = useState([]);
  // const [cmb_production_sub_department_id, setcmb_production_sub_department_id] = useState('')

  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
  const [actionType, setActionType] = useState('')

  // let production_wastage_types_id = sessionStorage.getItem('production_wastage_types_id');
  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const validateNumberDateInput = useRef();

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/MProductionWastageType/MFrmProductionWastageTypesListing`);
    }
  }

  useEffect(() => {
    const functionCall = async () => {
      await ActionType();
      await fillComobos();
      if (production_wastage_typesId !== 0) {
        await FnCheckUpdateResponce();
      }
    }
    functionCall()
  }, [])

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Creation)');
        break;
    }

  };


  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');


  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionWastageTypes/FnShowParticularRecordForUpdate/${production_wastage_typesId}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();

      let resp = (updateRes.data)


      setChk_Chk_production_wastage_type(resp.production_wastage_types_type);
      settxt_production_wastage_types_name(resp.production_wastage_types_name);
      settxt_std_wastage_percent(resp.std_wastage_percent);
      setcmb_production_department_Id(resp.production_department_id);
      // await comboOnChange('departmentId')
      // setcmb_production_sub_department_id(resp.production_sub_department_id);
      setProdSection(resp.section_id);
      comboOnChange('cmb_section_id');
      setProdSubSection(resp.sub_section_id);


      $('#saveBtn').show();

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
        default: break;
      }
      // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');

      switch (keyForViewUpdate) {
        case 'update':
          $('#txt_production_wastage_types_name').attr('disabled', true);
          $('#saveBtn').attr('disabled', false);
          break;
        case 'view':
          $("input[type=radio]").attr('disabled', true);
          $('#saveBtn').attr('disabled', true);
          $('#cmb_production_department_Id').attr('disabled', true);
          $('#txt_production_wastage_types_name').attr('disabled', true);
          // $('#cmb_production_sub_department_id').attr('disabled', true);
          $('#txt_std_wastage_percent').attr('disabled', true);
          break;
        default: break;
        // }

      }

    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const fillComobos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_department"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getmaxcountproductionParentProcessApiCall) => {
        setproductiondepartmentId(getmaxcountproductionParentProcessApiCall);
      })


      ////Production section
      comboDataAPiCall.current.fillMasterData("xmv_production_section", "", "").then((getProdSectionApiCall) => {
        setProdSectionOptions(getProdSectionApiCall);
      })

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {

      case 'departmentId':
        var departmentIdVal = document.getElementById('cmb_production_department_Id').value;
        setcmb_production_department_Id(departmentIdVal)
        if (departmentIdVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Department')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 100)
        }

        // if (departmentIdVal !== "" && departmentIdVal !== "0") {
        //   resetGlobalQuery();
        //   globalQuery.columns.push("field_id");
        //   globalQuery.columns.push("field_name");
        //   globalQuery.table = "cmv_department"
        //   globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
        //   globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        //   globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
        //   const subProductionIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        //    setproductionSubdepartmentId(subProductionIdApiCall)
        //    $('#error_cmb_production_sub_departmentId').hide();
        // }
        break;


      // case 'SubdepartmentId':
      //   var SubdepartmentIdVal = document.getElementById('cmb_production_sub_department_id').value;
      //   setcmb_production_sub_department_id(SubdepartmentIdVal)
      //   if (SubdepartmentIdVal === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Sub Department')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(1).css("padding-top", "0px");
      //     }, 100)
      //   }
      //   break;

      case 'cmb_section_id':
        const prod_section_Id = $('#cmb_section_id').val();
        if (prod_section_Id === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Production Section')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");

          }, 100)
        }
        const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_id", prod_section_Id);
        setProdSubSectionOptions(getProdSubSectionApiCall);

        break;

      case 'cmb_sub_section_id':
        const prod_sub_section_Id = $('#cmb_sub_section_id').val();
        if (prod_sub_section_Id === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Production Sub Section')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");

          }, 100)
        }
        break;
    }
  }



  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("ProductionWastageId");
      if (checkIsValidate === true) {
        // var production_wastage_types_id = sessionStorage.getItem("production_wastage_types_id");
        // if (production_wastage_types_id === '' || production_wastage_types_id === null) { production_wastage_types_id = 0 }
        var active;

        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
          default: break;
        }
        const data = {
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          production_wastage_types_id: production_wastage_typesId,
          created_by: UserName,
          modified_by: production_wastage_typesId === 0 ? null : UserName,
          production_department_id: cmb_production_department_Id,
          production_wastage_types_name: txt_production_wastage_types_name,
          production_sub_department_id: 1,
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          production_wastage_types_type: Chk_production_wastage_type,
          std_wastage_percent: txt_std_wastage_percent,
          is_active: active,

        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionWastageTypes/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.success === '0') {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          sessionStorage.setItem("productionWastegeId", responce.data.production_wastage_types_id)
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };



  const validateFields = () => {
    var formObj = $('#ProductionWastageId');
    var inputObj;

    for (var i = 0; i <= formObj.get(0).length - 1; i++) {
      inputObj = formObj.get(0)[i];
      if (inputObj.type === 'text' && inputObj.value !== '') {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === 'date' && inputObj.value !== '') {
        $("#error_" + inputObj.id).hide();
      }

    }
  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {

      case 'Department':
        await fillComobos();
        break;

      // case 'Sub Department':
      //   var departmentIdVal = document.getElementById('cmb_production_department_Id').value;
      //   resetGlobalQuery();
      //   globalQuery.columns.push("field_id");
      //   globalQuery.columns.push("field_name");
      //   globalQuery.table = "cmv_department"
      //   globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
      //   globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      //   globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
      //   const subProductionIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      //   setproductionSubdepartmentId(subProductionIdApiCall)
      //   break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Department':
        return <DepartmentEntry btn_disabled={true} />;

      // case 'Sub Department':
      //   return <DepartmentEntry department={cmb_production_department_Id} btn_disabled={true} />;

      case 'Production Section':
        return <FrmProductionSectionEntry btn_disabled={true} />;

      case 'Production Sub Section':
        return <FrmProductionSubSectionEntry btn_disabled={true} />

      default:
        return null;
    }
  }



  //var Mixing =  validateNumberDateInput.current.decimalNumber(txt_Mixing.toString(), 4)
  return (
    <>
      <ComboBox ref={comboDataAPiCall} />

      <FrmValidations ref={validate} />

      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <div className='erp_top_Form'>
        <div className='card p-1'>

          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Production Wastage Types {actionType}  </label>
          </div>

          <form id="ProductionWastageId">
            <div className="row erp_transporter_div">

              {/* first row */}
              <div className="col-sm-6 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">  Department<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_production_department_Id" className="form-select form-select-sm" value={cmb_production_department_Id} onChange={(e) => { setcmb_production_department_Id(e.target.value); validateFields(); comboOnChange('departmentId'); }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productiondepartmentId?.map(DepartmentOption => (
                        <option value={DepartmentOption.field_id}>{DepartmentOption.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_production_department_Id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>



                {/* <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">  Sub Department<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_production_sub_department_id" className="form-select form-select-sm" value={cmb_production_sub_department_id} onChange={(e) => { setcmb_production_sub_department_id(e.target.value); comboOnChange('SubdepartmentId'); validateFields(); }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productionSubdepartmentId?.map(SubDepartmentOption => (
                        <option value={SubDepartmentOption.field_id}>{SubDepartmentOption.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_production_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Production Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { comboOnChange('cmb_section_id'); setProdSection(e.target.value); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map(production => (
                            <option value={production.field_id}>{production.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { comboOnChange('cmb_sub_section_id'); setProdSubSection(e.target.value); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map(subproduction => (
                            <option value={subproduction.field_id}>{subproduction.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

              </div>

              {/* second row */}

              <div className="col-sm-6 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Wastage Types Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_production_wastage_types_name" className="erp_input_field" value={txt_production_wastage_types_name} onChange={e => { settxt_production_wastage_types_name(e.target.value); validateFields(); }} maxLength="500" />
                      <MDTypography variant="button" id="error_txt_production_wastage_types_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Std Wastage (%) </Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="number" id="txt_std_wastage_percent" className="erp_input_field text-end" value={txt_std_wastage_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { settxt_std_wastage_percent(e.target.value) }; validateFields(); }} maxLength="18" optional='optional' />
                      <MDTypography variant="button" id="error_txt_std_wastage_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-sm-2">
                  <Form.Label className="erp-form-label">  Type </Form.Label>
                </div>
                <div className="col">
                  <div className="erp_form_radio">
                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Saleable" type="radio" lbl="Saleable" value="1" name="Chk_production_wastage_type" checked={Chk_production_wastage_type === "1"} onClick={() => setChk_Chk_production_wastage_type("1")} /> </div>
                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="Re-Usable" type="radio" lbl="Re-Usable" value="2" name="Chk_production_wastage_type" checked={Chk_production_wastage_type === "2"} onClick={() => setChk_Chk_production_wastage_type("2")} /> </div>
                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="Scrap" type="radio" lbl="Scrap" value="3" name="Chk_production_wastage_type" checked={Chk_production_wastage_type === "3"} onClick={() => setChk_Chk_production_wastage_type("3")} /> </div>
                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="Contonment-Ejection" type="radio" lbl="Contonment-Ejection" value="4" name="Chk_production_wastage_type" checked={Chk_production_wastage_type === "4"} onClick={() => setChk_Chk_production_wastage_type("4")} /> </div>
                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="Micro-Duster" type="radio" lbl="Micro-Duster" value="5" name="Chk_production_wastage_type" checked={Chk_production_wastage_type === "5"} onClick={() => setChk_Chk_production_wastage_type("5")} /> </div>

                  </div>

                </div>

              </div>

            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"

              onClick={() => {
                const path = compType === 'Register' ? '/Masters/MProductionWastageType/MFrmProductionWastageTypesListing/reg' : '/Masters/MProductionWastageType/MFrmProductionWastageTypesListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular">Back</MDButton>
            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}
          </div>

        </div>

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          {/* <Modal.Header>
            <Modal.Title className='erp_modal_title'>{cat2ModalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeCat3RecModal}></button></span>
          </Modal.Header> */}
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            {displayRecordComponent()}
          </Modal.Body>
          {/* <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecCat2Modal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer> */}
        </Modal >

      </div>

    </>
  )
}

export default MFrmProductionWastageTypesEntry



