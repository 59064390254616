import React from 'react';
import { Accordion, Table } from 'react-bootstrap';

function TaxSummary({ grnTaxSummaryData, validateNumberDateInput }) {
    return (
        <Accordion defaultActiveKey="0" >
            <Accordion.Item eventKey="1">
                <Accordion.Header className="erp-form-label-md">Taxation Summary</Accordion.Header>
                <Accordion.Body>
                    {grnTaxSummaryData.length !== 0 ?
                        <Table className="erp_table" id='grn-taxationSummary-table' responsive bordered striped>
                            <thead className="erp_table_head">
                                <tr>
                                    {/* text-center */}
                                    <th className="erp_table_th" style={{ width: '40px' }}>Sr. No</th>
                                    <th className="erp_table_th">HSN SAC Code</th>
                                    <th className="erp_table_th">Taxable Amount</th>
                                    <th className="erp_table_th">CGST Percent</th>
                                    <th className="erp_table_th">CGST Total</th>
                                    <th className="erp_table_th">SGST Percent</th>
                                    <th className="erp_table_th">SGST Total</th>
                                    <th className="erp_table_th">IGST Percent</th>
                                    <th className="erp_table_th">IGST Total</th>
                                    <th className="erp_table_th">Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grnTaxSummaryData.map((taxItem, index) =>
                                    <tr rowindex={index}>
                                        <td className="erp_table_td">{index + 1}</td>
                                        <td className="erp_table_td">{taxItem.hsn_sac_code}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_taxable_amount), 4)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_cgst_percent), 2)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_cgst_total), 4)} </td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_sgst_percent), 2)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_sgst_total), 4)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_igst_percent), 2)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_igst_total), 4)}</td>
                                        <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_total_amount), 4)}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </Table>
                        : null}

                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}

export default TaxSummary;
