import React, { useEffect, useRef, useState, useMemo } from "react";
import { Accordion, Modal, Table, Button, Card } from "react-bootstrap";

import $ from 'jquery';
import { useNavigate, useLocation } from "react-router-dom";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { RxCrossCircled } from "react-icons/rx";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { renderToString } from "react-dom/server";


//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ComboBox from "Features/ComboBox";
// import FrmMBOMEntry from "FrmGeneric/MBOMFilter/FrmMBOMEntry";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import DocumentF from "Features/Document";


// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MDTypography from "components/MDTypography";
// import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmValidations from "FrmGeneric/FrmValidations";
import Datatable from "components/DataTable";
// import { itemContent } from "examples/Sidenav/styles/sidenavItem";
import ProductList from "FrmGeneric/ProductList";
import { Link } from "react-router-dom";
import ConfigConstants from "assets/Constants/config-constant";
// import { stringify } from "stylis";
import ExcelExport from "Features/Exports/ExcelExport";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import { FiDownload, FiPrinter } from "react-icons/fi";

// Import for the searchable combo box.
import Select from 'react-select';
import CustMaterialReceipt from 'FrmGeneric/Invoice/CustMaterialReceipt';


function FrmTCustomerRecieptEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);

    //for current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')

    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);
    const [custContactDetailsCols, setCustContactDetailsCols] = useState([]);


    //UseRefs
    const validateNumberDateInput = useRef();
    const navigate = useNavigate();
    const validate = useRef();
    const generateAutoNoAPiCall = useRef();
    const comboDataFunc = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    let customerComboRef = useRef(null);
    const navigator = useNavigate();



    //Header hooks
    const [customer_goods_receipt_master_transaction_id, setCustomerGoodsReceiptMasterTransactionId] = useState(keyForViewUpdate !== "approve" ? 0 : idList.customer_goods_receipt_master_transaction_id)

    const [cmb_customer_goods_receipt_type_id, setCustomerGoodsReceiptTypeId] = useState('');
    const [txt_Customer_grn_version, setCustomerGrnVersion] = useState(1);
    const [txt_customer_grn_no, setCustomerGRNno] = useState(0);
    const [dt_goods_receipt_date, setGoodReceiptDate] = useState(today());
    const [txt_customer_challan_no, setCustomerChallanNo] = useState('');
    const [dt_customer_challan_Date, setdtCustomerChallanDate] = useState('');
    const [cmb_expected_branch_id, setExpectedBranchId] = useState(COMPANY_BRANCH_ID);
    // const [cmb_expected_branch_state_id, setExpectedBranchStateId] = useState('');
    // const [cmb_expected_branch_city_id, setExpectedBranchCityId] = useState('');

    // const [dt_approved_date, setApprovedDate] = useState(today);
    // const [cmb_approved_by_id, setApprovedById] = useState();
    const [cmb_customer_id, setCustomerId] = useState(idList !== null && idList !== undefined ? idList.customer_id : '');

    const [cmb_customer_state_id, setCustomerStateId] = useState('');
    // const [cmb_customer_city_id, setCustomerCityId] = useState('');
    const [cmb_customer_state_name, setCustomerStateName] = useState('');
    // const [cmb_customer_city_name, setCustomerCityName] = useState('');
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('')
    const [customer_order_date, setCustomerOrderDate] = useState('');
    const [cmb_qa_by_id, setQabyId] = useState(parseInt(UserId));
    const [dt_qa_date, setQadate] = useState(today);

    // Document Form
    let documentGroup = "Customer Material Receipt";
    const [docData, setDocumentData] = useState([]);


    //Footer hooks
    //changes by tushar     
    const [txt_grandtotal, setgrandtotal] = useState(0);

    const [cmb_goods_receipt_status, setGoodsReceiptStatus] = useState("C");
    const [txt_lr_no, setlrNo] = useState('');
    const [dt_lr_date, setlrDate] = useState('');
    const [txt_ebw_no, setEbwNo] = useState('');
    const [dt_ebw_date, setEbwDate] = useState('');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    // const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [is_active, setIsActive] = useState(true);

    // const [customercontactidbyorderno, setcustomercontactidbyorderno] = useState('');


    // podetails Table Data
    // const [grnDetailsData, setGrnDetailsData] = useState([])
    // const [storedGrnDetailsData, setStoredGrnDetailsData] = useState([])
    // const [reloadTable, setReloadGrnTable] = useState(false)
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);


    //combo options
    const [rejectionParameterList, setRejectionParameterList] = useState([])
    // const [supplierList, setSupplierList] = useState([])
    const [customerGoodReciptTypeOptions, setcustomerGoodReciptTypeOptions] = useState([])

    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [expectedBranchList, setExpectedBranchList] = useState([])
    // const [goodReceiptTypeList, setGoodReceiptTypeList] = useState([])
    const [employeeList, setEmployeeList] = useState([]);
    const [packingList, setPackingList] = useState([]);
    // const [freightHSNList, setfreightHSNList] = useState([]);
    // const [agentsList, setAgentsList] = useState([]);
    const [godownsList, setGodownsList] = useState([]);
    const [godownsSectionList, setGodownsSectionList] = useState([]);
    const [godownsSectioonBeanList, setGodownsSectionBeanList] = useState([]);

    const [unitList, setUnitList] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([])
    // const [stateOpts, setStateOpts] = useState([]);
    // const [godownOpts, setGoDownOpts] = useState([]);

    // const [customerCityOpts, setCustomerCityIdOpts] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);

    //Popup fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')



    // const [IsView, setIsView] = useState(false)

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);
    const viewDocumentForm = () => {
        // if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
        if (keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }

    // var updateData = JSON.parse(sessionStorage.getItem('customer_goods_receipt_no'));
    let fin_year = "";
    let customer_goods_receipt_version = "";


    useEffect(async () => {
        setIsLoading(true);
        try {
            await ActionType();
            fillComobos();
            await removeSessions('onload');
            if (idList !== '') {
                await FnCheckUpdateResponce();
            }
            // await FnSetFieldUserAccess();
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsLoading(false);
        }
    }, [])



    // const [showAddRecModal, setShowAddRecModal] = useState(false);
    // const [modalHeaderName, setHeaderName] = useState('')

    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {

            case 'Good Receipt Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
                comboDataFunc.current.fillFiltersCombo(globalQuery)
                    .then(getProductType => {
                        setcustomerGoodReciptTypeOptions(getProductType)
                        console.log("Product Type List: ", getProductType);
                    });

                break;
            default:
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    let requiredCols = ['product_name', 'product_tech_spect', 'product_std_weight', 'product_unit_name', 'product_packing_name',
        'product_packing_id', 'customer_material_accepted_quantity', 'product_hsn_sac_code', 'product_hsn_sac_code_id',
        'customer_material_accepted_weight', 'product_material_type_name', 'product_material_grade_name', 'product_make_name', 'product_drawing_no',
        'product_category1_name', 'product_category2_name', 'product_category3_name', 'product_category4_name', 'product_category5_name', "product_material_stock_quantity",
        "product_material_stock_weight", "product_material_reserve_quantity", "product_material_reserve_weight", "product_material_receipt_quantity",
        "product_material_receipt_weight", "product_material_return_quantity", "product_material_return_weight", "product_unit_id", "product_id", "product_lead_time", "godown_id",
        'godown_section_id', 'godown_section_beans_id', 'customer_material_conversion_factor', "customer_material_rate", "customer_material_item_amount",
        "product_type_id", "product_type_name", "customer_material_order_quantity", "customer_material_order_weight", "expiry_date",
    ];


    const [bomfilteredData, setBomfilteredData] = useState([])

    useEffect(() => {
        const FunctionCall = () => {
            if (bomfilteredData.length !== 0) {
                calculateGrandTotal(bomfilteredData);
            }
        }
        FunctionCall();
    }, [bomfilteredData])




    const viewBomFilterForm = async () => {

        let checkIsValidate = await validate.current.validateForm('CustomerHeaderFormId');
        // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');
        if (checkIsValidate === true) {
            sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
            setShowBomFilterForm(true);
        }

    }

    const categoryDisplay = () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    // --------------------------------------- BOm Filter Start------------------------------------------------------------------
    const handleCloseBomFilterForm = async () => {


        setShowBomFilterForm(false)
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];

        setBomfilteredData((prevArray) => {
            if (getData === null) {
                return null; // If getData is null, set dispatchDetailsData to null.
            } else {
                // Filter prevArray to keep only items that are also in getData.
                // const filteredArray = prevArray.filter(prevItem => {
                //     return getData.some(item => item.product_id === prevItem.customer_material_id);
                // });
                const combinedArray = [
                    ...prevArray,
                    ...getData.filter(item => !prevArray.some(prevItem => prevItem.customer_material_id === item.product_id))
                        .map(function (item) {
                            return {
                                ...item,
                                product_material_std_weight: item.product_std_weight,
                                customer_material_id: item.product_id,
                                customer_material_name: item.product_name,
                                customer_material_tech_spect: item.product_tech_spect,
                                customer_material_unit_id: item.product_unit_id,
                                customer_material_unit_name: item.product_unit_name,
                                // product_rm_stock_unit_id: item.product_rm_stock_unit_id,
                                customer_material_packing_id: item.product_packing_id,
                                customer_material_packing_name: item.product_packing_name,
                                customer_material_hsn_code_id: item.product_hsn_sac_code_id,
                                customer_material_hsn_code: item.product_hsn_sac_code,
                                customer_material_prev_accepted_quantity: 0,
                                customer_material_rejected_quantity: 0,
                                customer_material_rejected_weight: 0,
                                customer_material_prev_accepted_weight: 0,
                                pree_closed_customer_quantity: 0,
                                pree_closed_customer_weight: 0,
                                customer_return_quantity: 0,
                                customer_material_conversion_factor: 0,
                                product_lead_time: 0,
                                customer_material_rejection_reason_id: '0',
                                customer_return_weight: 0,
                                godown_id: item.godown_id,
                                // godown_name: item.godown_name,
                                godown_section_beans_id: item.godown_section_beans_id,
                                // godown_section_beans_name: item.godown_section_beans_name,
                                godown_section_id: item.godown_section_id,
                                // godown_section_name: item.godown_section_name,
                                department_id: 0,
                                customer_item_status: 'C',
                                // customer_batch_no: latestGrnNo,
                                customer_batch_no: "",
                                customer_material_rate: 1,
                                customer_material_item_amount: 0,
                                customer_goods_receipt_type_id: item.product_type_id,
                                customer_goods_receipt_type: item.product_type_name,
                                // customer_material_order_quantity: item.customer_material_order_quantity,
                                customer_material_order_quantity: 1,
                                customer_material_accepted_quantity: 1,
                                customer_material_order_weight: 0,
                                expiry_date: '',
                                customer_material_item_amount: 1,
                                prev_accepted_quantity: 0,
                                prev_accepted_weight: 0,
                                prev_order_quantity: 0,
                                prev_order_weight: 0
                            };
                        })
                ];
                console.log('combinedArray:', combinedArray);
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(combinedArray))
                return combinedArray;

            }
        });

        if (getData !== '' && getData !== null && getData.length !== 0) { $('.showData').show() } else { $('.showData').hide() }
        sessionStorage.setItem('isComboFilterExist', false)
        // Remove Bom Filter Session
        var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))
        console.log('LocalArr: ', LocalArr)
        if (LocalArr !== null) {
            for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                localStorage.removeItem(LocalArr[localArrIndex])
            }
        }
        removeSessions();
        setTimeout(() => { categoryDisplay(); }, 300);
    };

    // --------------------------------------- BOm Filter End------------------------------------------------------------------

    const ActionType = async () => {

        switch (keyForViewUpdate) {
            // case 'approve':
            //     setActionType('(Approval)');
            //     setActionLabel('Approve')
            //     //  setIsApprove(true)
            //     await validate.current.readOnly("CustomerHeaderFormId");
            //     await validate.current.readOnly("CustomerHeaderFormId");
            //     // $('#cmb_goods_receipt_status').attr('disabled', false)
            //     // $('#txt_remark').removeAttr('readOnly')
            //     break;
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#cmb_customer_id').attr('disabled', true)
                $('#cmb_customer_order_no').attr('disabled', true)


                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("CustomerHeaderFormId");
                await validate.current.readOnly("CGRNFooterFormId");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");

                break;
            case 'delete':
                setActionType('(Delete)');
                await validate.current.readOnly("CustomerHeaderFormId");
                await validate.current.readOnly("CGRNFooterFormId");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");

                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    const displayRecordComponent = () => {
        switch (modalHeaderName) {

            case 'Good Receipt Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            default:
                return null;
        }
    }
    const checkDuplicateChallan = async (challan_no) => {

        resetGlobalQuery();
        globalQuery.columns = ["customer_id, customer_challan_no"]
        globalQuery.table = "pt_goods_receipt_master_customer"
        globalQuery.conditions.push({ field: "customer_challan_no", operator: "=", value: challan_no });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
        const getDuplicateChallanNos = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        console.log("getDuplicateChallanNos", getDuplicateChallanNos);
        return getDuplicateChallanNos;
    }



    // --------------------------------------- Material Reciept NOtes Save Functionality Start------------------------------------------------------------------
    const createCustomerRecieptNote = async () => {
        try {
            setIsLoading(true);

            let mrnValidated = true;
            // changes by tushar temporary commented 
            mrnValidated = await FnValidateGoodReceiptNote();
            if (cmb_goods_receipt_status === '') {
                $('#error_cmb_goods_receipt_status').show();
                mrnValidated = false;
            }
            // if (!isApprove) {
            //     // mrnValidated = await FnValidateGoodReceiptNote();
            // } else {
            //     if (cmb_goods_receipt_status === '') {
            //         $('#error_cmb_goods_receipt_status').show();
            //         mrnValidated = false;
            //     }
            // }
            let latestGrnNo = txt_customer_grn_no;
            if (mrnValidated) {
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }
                if (customer_goods_receipt_master_transaction_id === 0)
                    latestGrnNo = await generateCustGrnNo();
                // let customerContactIds = FnGetCustomerContactPersonIds();
                const mrnMasterData = {
                    customer_goods_receipt_master_transaction_id: 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    customer_goods_receipt_no: latestGrnNo,
                    customer_goods_receipt_date: dt_goods_receipt_date,
                    customer_goods_receipt_version: txt_Customer_grn_version,
                    customer_id: cmb_customer_id,
                    customer_state_id: cmb_customer_state_id,
                    customer_order_date: customer_order_date,
                    customer_order_no: cmb_customer_order_no,
                    // customer_contacts_ids: customerContactIds,
                    expected_branch_id: cmb_expected_branch_id,
                    // changes by tushar
                    customer_goods_receipt_type_id: cmb_customer_goods_receipt_type_id,
                    customer_goods_receipt_type: $('#cmb_customer_goods_receipt_type_id').find(":selected").text(),
                    customer_challan_no: txt_customer_challan_no,
                    customer_challan_Date: dt_customer_challan_Date,
                    // approved_by_id: cmb_approved_by_id,
                    // approved_date: dt_approved_date,
                    customer_goods_receipt_status: cmb_goods_receipt_status,
                    lr_no: txt_lr_no,
                    lr_date: dt_lr_date,
                    ewb_no: txt_ebw_no,
                    ewb_valid_till: dt_ebw_date,
                    vehicle_no: txt_vehicle_no,
                    remark: txt_remark,
                    qa_by_id: cmb_qa_by_id,
                    qa_date: dt_qa_date,
                    customer_state_id: cmb_customer_state_id,
                    // customer_city_id: cmb_customer_city_id
                    grand_total: txt_grandtotal,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' ? UserName : '',
                }
                json.TransHeaderData = mrnMasterData;

                for (let bomDetailIndex = 0; bomDetailIndex < bomfilteredData.length; bomDetailIndex++) {

                    var crJson = {}
                    const bomfilteredDataElement = bomfilteredData[bomDetailIndex];
                    // if (isApprove) {
                    //     crJson['customer_goods_receipt_details_transaction_id'] = bomfilteredDataElement.customer_goods_receipt_details_transaction_id !== undefined ? bomfilteredDataElement.customer_goods_receipt_master_transaction_id : 0;
                    //     crJson['customer_goods_receipt_master_transaction_id'] = bomfilteredDataElement.customer_goods_receipt_master_transaction_id;
                    // }
                    crJson['company_id'] = COMPANY_ID
                    crJson['company_branch_id'] = COMPANY_BRANCH_ID
                    crJson['financial_year'] = FINANCIAL_SHORT_NAME
                    crJson['customer_goods_receipt_no'] = keyForViewUpdate === null ? latestGrnNo : txt_customer_grn_no
                    crJson['customer_goods_receipt_date'] = dt_goods_receipt_date
                    crJson['customer_goods_receipt_version'] = txt_Customer_grn_version
                    //changes by tushar
                    crJson['customer_goods_receipt_type_id'] = bomfilteredDataElement.customer_goods_receipt_type_id
                    crJson['customer_goods_receipt_type'] = bomfilteredDataElement.customer_goods_receipt_type
                    crJson['customer_batch_no'] = bomfilteredDataElement.customer_batch_no === undefined || bomfilteredDataElement.customer_batch_no === '' ?
                        `${FINANCIAL_SHORT_NAME}/${txt_customer_grn_no}/${today()}` :
                        bomfilteredDataElement.customer_batch_no
                    crJson['customer_material_id'] = bomfilteredDataElement.customer_material_id
                    crJson['customer_material_tech_spect'] = bomfilteredDataElement.customer_material_tech_spect
                    crJson['sr_no'] = bomDetailIndex + 1
                    crJson['customer_material_unit_id'] = bomfilteredDataElement.customer_material_unit_id
                    crJson['customer_material_packing_id'] = bomfilteredDataElement.customer_material_packing_id
                    crJson['customer_material_hsn_code_id'] = bomfilteredDataElement.customer_material_hsn_code_id
                    crJson['customer_material_accepted_quantity'] = bomfilteredDataElement.customer_material_accepted_quantity
                    crJson['customer_material_accepted_weight'] = bomfilteredDataElement.customer_material_accepted_weight
                    crJson['customer_material_conversion_factor'] = bomfilteredDataElement.customer_material_conversion_factor
                    crJson['customer_material_rejected_quantity'] = bomfilteredDataElement.customer_material_rejected_quantity
                    crJson['customer_material_rejected_weight'] = bomfilteredDataElement.customer_material_rejected_weight
                    crJson['customer_material_rejection_reason_id'] = bomfilteredDataElement.customer_material_rejection_reason_id
                    crJson['customer_material_prev_accepted_quantity'] = bomfilteredDataElement.customer_material_prev_accepted_quantity === undefined || bomfilteredDataElement.customer_material_prev_accepted_quantity === null ? 0 : bomfilteredDataElement.customer_material_prev_accepted_quantity
                    crJson['customer_material_prev_accepted_weight'] = bomfilteredDataElement.customer_material_prev_accepted_weight === undefined || bomfilteredDataElement.customer_material_prev_accepted_weight === null ? 0 : bomfilteredDataElement.customer_material_prev_accepted_weight
                    crJson['pree_closed_customer_quantity'] = bomfilteredDataElement.pree_closed_customer_quantity
                    crJson['pree_closed_customer_weight'] = bomfilteredDataElement.pree_closed_customer_weight
                    crJson['customer_return_quantity'] = bomfilteredDataElement.customer_return_quantity
                    crJson['customer_return_weight'] = bomfilteredDataElement.customer_return_weight
                    //changes by tushar
                    crJson['customer_material_rate'] = bomfilteredDataElement.customer_material_rate
                    crJson['customer_material_item_amount'] = bomfilteredDataElement.customer_material_item_amount
                    crJson['customer_material_order_quantity'] = bomfilteredDataElement.customer_material_order_quantity
                    crJson['customer_material_order_weight'] = bomfilteredDataElement.customer_material_order_weight
                    crJson['expiry_date'] = bomfilteredDataElement.expiry_date
                    crJson['prev_accepted_quantity'] = bomfilteredDataElement.prev_accepted_quantity
                    crJson['prev_accepted_weight'] = bomfilteredDataElement.prev_accepted_weight
                    crJson['prev_order_quantity'] = bomfilteredDataElement.prev_order_quantity
                    crJson['prev_order_weight'] = bomfilteredDataElement.prev_order_weight
                    crJson['department_id'] = bomfilteredDataElement.department_id
                    crJson['godown_id'] = bomfilteredDataElement.godown_id
                    crJson['godown_section_id'] = bomfilteredDataElement.godown_section_id
                    crJson['godown_section_beans_id'] = bomfilteredDataElement.godown_section_beans_id
                    crJson['customer_item_status'] = bomfilteredDataElement.customer_item_status
                    crJson['remark'] = txt_remark
                    crJson['created_by'] = UserName
                    crJson['modified_by'] = keyForViewUpdate === 'update' ? UserName : ''
                    crJson.total_box_weight = bomfilteredDataElement.total_box_weight
                    crJson.total_quantity_in_box = bomfilteredDataElement.total_quantity_in_box
                    crJson.weight_per_box_item = bomfilteredDataElement.weight_per_box_item

                    json.TransDetailData.push(crJson)
                }
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.customer_goods_receipt_no = keyForViewUpdate === null ? latestGrnNo : txt_customer_grn_no
                json.commonIds.customer_goods_receipt_version = txt_Customer_grn_version
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME

                const formData = new FormData();
                formData.append(`GoodReceiptMasterCustomerData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                // remove tushar from api
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json();
                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else if (responce.status === 500) {
                    setErrMsg(responce.message)
                    setShowErrorMsgModal(true)
                } else {

                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('customer_goods_receipt_version') && responce.data.hasOwnProperty('customer_goods_receipt_no')) {
                        setModalOrderDetails(`Customer Goods Receipt No: ${responce.data.customer_goods_receipt_no} and Version: ${responce.data.customer_goods_receipt_version}`)
                    }
                    setShowSuccessMsgModal(true);
                    // clearFormFields();
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            navigate('/Error')


        }
    }
    // --------------------------------------- Material Reciept NOtes Save Functionality End------------------------------------------------------------------


    // --------------------------------------- FnChechkUpdateResponce Functionality End------------------------------------------------------------------
    const FnCheckUpdateResponce = async () => {
        try {

            if (idList !== '') {
                fin_year = idList.financial_year;
                customer_goods_receipt_version = idList.customer_goods_receipt_version;
                setCustomerGRNno(idList.customer_goods_receipt_no)
                setCustomerGoodsReceiptMasterTransactionId(idList.customer_goods_receipt_master_transaction_id)

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnShowAllDetailsAndMastermodelRecords/${customer_goods_receipt_version}/${fin_year}/${COMPANY_ID}?customer_goods_receipt_no=${idList.customer_goods_receipt_no}`)
                const responce = await apiCall.json();
                console.log('Customer Materails Details: ', responce);
                let customerGrnMasterData = responce.GoodReceiptCustomerMasterRecord;
                if (customerGrnMasterData !== null && customerGrnMasterData !== "") {
                    setCustomerGoodsReceiptTypeId(customerGrnMasterData.customer_goods_receipt_type_id);
                    // setCustomerGRNno(customerGrnMasterData.customer_goods_receipt_no);
                    setCustomerGrnVersion(customerGrnMasterData.customer_goods_receipt_version);
                    setGoodReceiptDate(customerGrnMasterData.customer_goods_receipt_date)
                    setCustomerId(customerGrnMasterData.customer_id);
                    await comboOnChange('customer');
                    setCustomerOrderNo(customerGrnMasterData.customer_order_no);
                    await comboOnChange('CustomerOrder');
                    setCustomerStateId(customerGrnMasterData.customer_state_id);
                    await comboOnChange('ExpectedbranchId')
                    setCustomerOrderDate(customerGrnMasterData.customer_order_date);
                    setCustomerChallanNo(customerGrnMasterData.customer_challan_no);
                    setdtCustomerChallanDate(customerGrnMasterData.customer_challan_Date);
                    setQabyId(customerGrnMasterData.qa_by_id);
                    setVehicleNo(customerGrnMasterData.vehicle_no);
                    setEbwNo(customerGrnMasterData.ewb_no);
                    setEbwDate(customerGrnMasterData.ewb_valid_till);
                    setlrNo(customerGrnMasterData.lr_no);
                    setlrDate(customerGrnMasterData.lr_date);
                    setRemark(customerGrnMasterData.remark)
                    setgrandtotal(customerGrnMasterData.grand_total)
                    setCustomerStateName(customerGrnMasterData.customer_state_name)
                    setCustomerStateId(customerGrnMasterData.customer_state_id)

                    const updateKeys = (object) => {
                        object.prev_accepted_quantity = object.customer_material_accepted_quantity;
                        object.prev_accepted_weight = object.customer_material_accepted_weight;
                        object.prev_order_quantity = object.customer_material_order_quantity;
                        object.prev_order_weight = object.customer_material_order_weight;

                        const updatedObject = {};
                        for (const key in object) {

                            if (key === 'customer_material_id') {
                                updatedObject[`product_id`] = object[key];
                            }
                            if (key === 'product_material_name') {
                                updatedObject[`customer_material_name`] = object[key];
                            }
                            else {
                                updatedObject[`${key}`] = object[key];
                            }
                        }
                        return updatedObject;
                    };



                    if (responce.GoodReceiptCustomerDetailsRecord != null) {

                        const updatedCustomerGRNDetailsDataArray = responce.GoodReceiptCustomerDetailsRecord.map(updateKeys);
                        // let updateWithCustomerCusRecNoteItemStatus = [];
                        // if (keyForViewUpdate === 'approve') {
                        //     updateWithCustomerCusRecNoteItemStatus = updatedCustomerGRNDetailsDataArray.map(function (rowData) {
                        //         return {
                        //             ...rowData,
                        //             // indent_item_status: 'A',

                        //             // product_material_approved_weight: rowData.indent_weight,
                        //         };
                        //     });
                        // }
                        // setBomfilteredData(updateWithCustomerCusRecNoteItemStatus.length === 0 ? updatedCustomerGRNDetailsDataArray : updateWithCustomerCusRecNoteItemStatus)
                        // sessionStorage.setItem('filteredMaterialData', JSON.stringify(updateWithCustomerCusRecNoteItemStatus.length === 0 ? updatedCustomerGRNDetailsDataArray : updateWithCustomerCusRecNoteItemStatus))
                        //

                        setBomfilteredData(updatedCustomerGRNDetailsDataArray)
                        sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedCustomerGRNDetailsDataArray))

                        $('.showData').show();
                    }

                }
            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')

        }

    }

    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnDeleteRecord/${cmb_customer_id}/${COMPANY_ID}/${UserName}?customer_goods_receipt_no=${txt_customer_grn_no}`, method)
            const responce = await deleteApiCall.json();
            console.log("Customer Material Reciept entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const deleteshow = () => {
        setShow(true)
    }

    // --------------------------------------- FnChechkUpdateResponce Functionality End------------------------------------------------------------------


    // const FnSetFieldUserAccess = async () => {
    //     if (keyForViewUpdate != "") {
    //         // setIsApprove(keyForViewUpdate === 'approve' ? true : false);
    //         switch (keyForViewUpdate) {
    //             case 'view':
    //                 setIsView(true)
    //                 $("input[type=radio]").attr('disabled', true);
    //                 $("form").find("input,textarea,select").attr("disabled", "disabled");
    //                 $("table").find("input,button,textarea,select").attr("disabled", "disabled");
    //                 $('input[type="checkbox"]').prop('readonly', true);
    //                 $('table . ').prop('readonly', true);
    //                 $('#add-material').attr('disabled', 'disabled');
    //                 await validate.current.readOnly("CustomerHeaderFormId");
    //                 break;
    //             // case 'approve':
    //             //     setIsView(true)

    //             //     $("form").find("input,textarea,select").attr("disabled", "disabled");
    //             //     $("table").find("input,button,textarea,select").attr("disabled", "disabled");
    //             //     $('input[type="checkbox"]').prop('readonly', true);
    //             //     $("form").find('textarea#txt_remark').removeAttr("disabled");
    //             //     // $('table . ').removeAttr("disabled");
    //             //     break;
    //             case 'update':
    //                 // $("form").find('select#chk_indent_status').removeAttr("disabled");

    //                 // setIsApprove(false)

    //                 break;
    //         }
    //     }
    // }

    // const FnGetCustomerContactPersonIds = () => {
    //     let customerContactPersonsIds = '';
    //     const checkboxes = $('.selectCustContactPerson:checked');
    //     checkboxes.each(function () {
    //         customerContactPersonsIds += $(this).val() + ":";
    //     });
    //     return customerContactPersonsIds.replace(/:$/, '');
    // }

    const FnValidateGoodReceiptNote = async () => {
        // Validating header footer forms data.
        let headerDataIsValid = await validate.current.validateForm("CustomerHeaderFormId");
        if (!headerDataIsValid) { return false; }

        // Check combo values for customer combo
        let customerVal = cmb_customer_id || '';
        if (customerVal === "" || customerVal === '0') {
            $("#error_cmb_customer_id").text("Please fill this field...!");
            $("#error_cmb_customer_id").show();
            $("#cmb_customer_id").focus();
            return false;
        }

        if (keyForViewUpdate === '') {
            const getchallanNo = await checkDuplicateChallan(txt_customer_challan_no);
            if (getchallanNo.length > 0) {
                $('#error_txt_customer_challan_no').text("Challan No. Already Exists...!")
                $('#error_txt_customer_challan_no').show();
                $('#error_txt_customer_challan_no').focus();
                return false;
            }
        }

        let custGrnDetailsIsValid = true;
        if (bomfilteredData.length === 0) {
            setErrMsg('Please add atleast one material...');
            setShowErrorMsgModal(true);
            return custGrnDetailsIsValid = false;
        }
        else {
            const tableRows = document.querySelectorAll('#mrnDetailsTbl tbody tr');
            let custGrnDetailsIsValid = true;

            for (let i = 0; i < tableRows.length; i++) {
                const row = tableRows[i];

                const custmerOrderQty = row.querySelector('input[id^="customer_material_order_quantity_"]').value;
                // console.log("custmerOrderQty ========> ", custmerOrderQty);
                const materialAcceptedQty = row.querySelector('input[id^="customer_material_accepted_quantity_"]').value;
                // console.log("materialAcceptedQty ========>", materialAcceptedQty);
                const materialRejectionReason = row.querySelector('select[id^="customer_material_rejection_reason_id_"]').value;

                if (custmerOrderQty.trim() !== '') {
                    if (isNaN(custmerOrderQty) || parseInt(custmerOrderQty) <= 0) {
                        row.querySelector('input[id^="customer_material_order_quantity_"]').parentElement.dataset.tip = 'Order Quantity cannot be zero or left blank..!';
                        row.querySelector('input[id^="customer_material_order_quantity_"]').focus();
                        custGrnDetailsIsValid = false;
                        break;
                    } else {
                        delete row.querySelector('input[id^="customer_material_order_quantity_"]').parentElement.dataset.tip;
                    }
                } else {
                    row.querySelector('input[id^="customer_material_order_quantity_"]').parentElement.dataset.tip = 'Order Quantity cannot be zero or left blank..!';
                    row.querySelector('input[id^="customer_material_order_quantity_"]').focus();
                    custGrnDetailsIsValid = false;
                    break;
                }



                if (parseInt(custmerOrderQty) < parseInt(materialAcceptedQty)) {
                    row.querySelector('input[id^="customer_material_accepted_quantity_"]').parentElement.dataset.tip = 'please enter valid Entry ..!';
                    row.querySelector('input[id^="customer_material_accepted_quantity_"]').focus();
                    custGrnDetailsIsValid = false;
                } else {
                    if (isNaN(materialAcceptedQty) || parseInt(materialAcceptedQty) <= 0) {
                        row.querySelector('input[id^="customer_material_accepted_quantity_"]').parentElement.dataset.tip = 'Accepted Quantity cannot be zero or left blank...!';
                        row.querySelector('input[id^="customer_material_accepted_quantity_"]').focus();
                        return custGrnDetailsIsValid = false;
                    } else {
                        delete row.querySelector('input[id^="customer_material_accepted_quantity"]').parentElement.dataset.tip
                    }
                }

                const tdWithRejectedQty = row.querySelector('td[rejectedqty]');
                const inputElement = row.querySelector('select[id^="customer_material_rejection_reason_id_"]');
                if (tdWithRejectedQty && parseInt(tdWithRejectedQty.textContent) > 0) {
                    if (materialRejectionReason === '0' || materialRejectionReason === undefined) {
                        if (inputElement) {
                            inputElement.parentElement.dataset.tip = 'Please select Valid rejection reason...!';
                            inputElement.focus();
                            custGrnDetailsIsValid = false;
                            break;
                        }
                    } else {
                        delete inputElement.parentElement.dataset.tip;
                    }
                }

                const custmerOrderRate = row.querySelector('input[id^="customer_material_rate_"]').value;
                if (!isNaN(custmerOrderRate) && parseInt(custmerOrderRate) !== 0) {
                    delete row.querySelector('input[id^="customer_material_rate_"]').parentElement.dataset.tip;
                } else {
                    row.querySelector('input[id^="customer_material_rate_"]').parentElement.dataset.tip = 'please enter material rate...!';
                    row.querySelector('input[id^="customer_material_rate_"]').focus();
                    custGrnDetailsIsValid = false;
                    break;
                }
                const godownId = row.querySelector('select[id^="godown_id_"]').value;
                if (godownId === '' || godownId === '0') {
                    row.querySelector('select[id^="godown_id_"]').parentElement.dataset.tip = 'please select Godown ...!';
                    row.querySelector('select[id^="godown_id_"]').focus();
                    custGrnDetailsIsValid = false;
                    break;
                } else {
                    delete row.querySelector('select[id^="godown_id_"]').parentElement.dataset.tip;
                }

                // const inputdeptElement = row.querySelector('select[id^="department_id_"]').value;
                // // console.log("inputdeptElement", inputdeptElement);
                // if (inputdeptElement === '0') {
                //     row.querySelector('select[id^="department_id_"]').parentElement.dataset.tip = 'please select Department ...!';
                //     row.querySelector('select[id^="department_id_"]').focus();
                //     custGrnDetailsIsValid = false;
                //     break;
                // } else {
                //     delete row.querySelector('select[id^="department_id_"]').parentElement.dataset.tip;
                // }

                if (!custGrnDetailsIsValid) {
                    break;
                }
            }

            let footerDataIsValid = await validate.current.validateForm("CGRNFooterFormId");
            if (!footerDataIsValid) {
                return false;
            }

            return custGrnDetailsIsValid;
        }
    }


    // const tableRows = document.querySelectorAll('#mrnDetailsTbl tbody tr');
    // tableRows.forEach((row) => {

    //     const custmerOrderQty = row.querySelector('input[id^="customer_material_order_quantity_"]').value;
    //     console.log("custmerOrderQty ========> ", custmerOrderQty);
    //     const materialAcceptedQty = row.querySelector('input[id^="customer_material_accepted_quantity_"]').value;
    //     console.log("materialAcceptedQty ========>", materialAcceptedQty);


    //     const materialRejectionReason = row.querySelector('select[id^="customer_material_rejection_reason_id_"]').value;
    //     if (custmerOrderQty.trim() !== '') {
    //         if (isNaN(custmerOrderQty) || parseInt(custmerOrderQty) <= 0) {
    //             row.querySelector('input[id^="customer_material_order_quantity_"]').parentElement.dataset.tip = 'Order Quantity cannot be zero or left blank..!';
    //             row.querySelector('input[id^="customer_material_order_quantity_"]').focus();
    //             return custGrnDetailsIsValid = false;
    //         } else {
    //             delete row.querySelector('input[id^="customer_material_order_quantity_"]').parentElement.dataset.tip
    //         }
    //     } else {
    //         row.querySelector('input[id^="customer_material_order_quantity_"]').parentElement.dataset.tip = 'Order Quantity cannot be zero or left blank..!';
    //         row.querySelector('input[id^="customer_material_order_quantity_"]').focus();
    //         return custGrnDetailsIsValid = false;
    //     }
    //     if (isNaN(materialAcceptedQty) || parseInt(materialAcceptedQty) <= 0) {
    //         row.querySelector('input[id^="customer_material_accepted_quantity_"]').parentElement.dataset.tip = 'Accepted Quantity cannot be zero or left blank...!';
    //         row.querySelector('input[id^="customer_material_accepted_quantity_"]').focus();
    //         return custGrnDetailsIsValid = false;
    //     } else {
    //         delete row.querySelector('input[id^="customer_material_accepted_quantity"]').parentElement.dataset.tip
    //     }



    //     const custmerOrderRate = row.querySelector('input[id^="customer_material_rate_"]').value;
    //     if (!isNaN(custmerOrderRate) && parseInt(custmerOrderRate) !== 0) {
    //         delete row.querySelector('input[id^="customer_material_rate_"]').parentElement.dataset.tip
    //     } else {
    //         row.querySelector('input[id^="customer_material_rate_"]').parentElement.dataset.tip = 'please enter material rate...!';
    //         row.querySelector('input[id^="customer_material_rate_"]').focus();
    //         return custGrnDetailsIsValid = false;
    //     }




    //     const tdWithRejectedQty = row.querySelector('td[rejectedqty]');
    //     const inputElement = row.querySelector('select[id^="customer_material_rejection_reason_id_"]');
    //     if (tdWithRejectedQty && parseInt(tdWithRejectedQty.textContent) > 0) {
    //         if (materialRejectionReason === '0' || materialRejectionReason === undefined) {
    //             if (inputElement) {
    //                 inputElement.parentElement.dataset.tip = 'Please select grn quantity rejection reason...!';
    //                 inputElement.focus();
    //                 return custGrnDetailsIsValid = false;
    //             }
    //         } else {
    //             delete inputElement.parentElement.dataset.tip;
    //         }
    //     }

    //     const inputdeptElement = row.querySelector('select[id^="department_id_"]').value;
    //     console.log("inputdeptElement", inputdeptElement);
    //     if (inputdeptElement === '0') {
    //         row.querySelector('select[id^="department_id_"]').parentElement.dataset.tip = 'please select Department ...!';
    //         row.querySelector('select[id^="department_id_"]').focus();
    //         return custGrnDetailsIsValid = false;
    //     } else {
    //         delete row.querySelector('select[id^="department_id_"]').parentElement.dataset.tip
    //     }



    // }
    // );
    //     }
    //     let footerDataIsValid = await validate.current.validateForm("CGRNFooterFormId");
    //     if (!footerDataIsValid) { return false; }

    //     return custGrnDetailsIsValid;
    // }



    const generateCustGrnNo = async () => {
        const ptShortName = customerGoodReciptTypeOptions.find((item) => item.field_id === parseInt($("#cmb_customer_goods_receipt_type_id").val()));
        const grn_no = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_receipt_master_customer", "customer_goods_receipt_no", ptShortName.product_type_short_name, 'CR', "6");
        setCustomerGRNno(grn_no);
        return grn_no;
    }

    const fillComobos = async () => {
        try {

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setcustomerGoodReciptTypeOptions(getProductType)
                    console.log("Product Type List: ", getProductType);
                });



            // resetGlobalQuery();
            // globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
            // globalQuery.table = "smv_product_type";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            // const getProductTypes = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setGoodReceiptTypeList(getProductTypes);

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_state_id", "state_name"]
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const customerOrderIdApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            const options = [
                { value: '', label: 'Select', },
                { value: '0', label: 'Add New Record+' },
                ...customerOrderIdApiCall.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name, state_id: customer.state_id, state_name: customer.state_name })),
            ];
            setCustomerOptions(options);

            resetGlobalQuery();
            globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "state_name", 'is_sez']
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getExpectedtBranchs => {
                    setExpectedBranchList(getExpectedtBranchs);
                });

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "employee_type_group", operator: "!=", value: 'Workers', });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getEmployees => {
                    setEmployeeList(getEmployees);
                });


            // setEmployeeList(getEmployees);



            comboDataFunc.current.fillMasterData("smv_product_rejection_parameters", "", "")
                .then(getRejectionParameters => {
                    setRejectionParameterList(getRejectionParameters)
                });

            // godownsList list for details table
            comboDataFunc.current.fillMasterData("cmv_godown", "", "")
                .then(getGodownsList => {
                    setGodownsList(getGodownsList)
                });

            // godownsList list for details table
            comboDataFunc.current.fillMasterData("cmv_godown_section", "", "")
                .then(getGodownsSectionlist => {
                    setGodownsSectionList(getGodownsSectionlist)
                });

            // godownsList list for details table
            comboDataFunc.current.fillMasterData("cmv_godown_section_beans", "", "")
                .then(getGodownsSectionbeansList => {
                    setGodownsSectionBeanList(getGodownsSectionbeansList)
                });



            // unit list for details table
            comboDataFunc.current.fillMasterData("smv_product_unit", "", "")
                .then(getUnitList => {
                    setUnitList(getUnitList)
                });

            // Dept list for details table
            comboDataFunc.current.fillMasterData("cmv_department", "", "")
                .then(getDepartmentList => {
                    setDepartmentList(getDepartmentList)
                });


            // Packing list for details table
            const getPackingList = await comboDataFunc.current.fillMasterData("smv_product_packing", "", "");
            setPackingList(getPackingList)

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }



    const comboOnChange = async (key) => {
        switch (key) {
            case 'customerGoodReciptType':
                var customerGoodReciptTypeId = document.getElementById('cmb_customer_goods_receipt_type_id').value;
                if (customerGoodReciptTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Good Receipt Type')
                    setShowAddRecModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }

                if (customerGoodReciptTypeId !== "0" && customerGoodReciptTypeId !== "") {
                    $('#error_cmb_customer_goods_receipt_type_id').hide();
                    setCustomerGoodsReceiptTypeId(customerGoodReciptTypeId)
                    await generateCustGrnNo();
                } else {
                    setCustomerGRNno("0")
                }
                break;

            case 'challan_no':
                let challan_no = document.getElementById('txt_customer_challan_no').value
                setCustomerChallanNo(challan_no)

                if (keyForViewUpdate === '') {
                    const getchallanNo = await checkDuplicateChallan(txt_customer_challan_no);
                    if (getchallanNo.length > 0) {
                        $('#error_txt_customer_challan_no').text("Challan No. Already Exists...!")
                        $('#error_txt_customer_challan_no').show();
                        $('#error_txt_customer_challan_no').focus();
                        return false;
                    }
                } else {
                    $('#error_txt_customer_challan_no').text("Please fill this field...!")
                    $('#error_txt_customer_challan_no').show()
                }
                break;

            case 'customer':
                let customerVal = customerComboRef.current.value || '';
                // let customerVal = document.getElementById("cmb_customer_id").value;
                // setCustomerId(customerVal);
                if (customerVal === '0') {
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                else if (customerVal !== "" & customerVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_order_no");
                    globalQuery.columns.push("customer_order_Date");
                    globalQuery.columns.push("customer_state_id");
                    globalQuery.columns.push("customer_state_name");
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "sales_order_status", operator: "=", value: 'A' });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];

                    const getCustomerOrdersApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                    if (getCustomerOrdersApiCall.length !== 0) {
                        setCustomerOrdOpts(getCustomerOrdersApiCall);
                        // setCustomerStateId(getCustomerOrdersApiCall[0].customer_state_id);
                        // setCustomerStateName(getCustomerOrdersApiCall[0].customer_state_name);
                    } else {
                        setCustomerOrdOpts([]);
                        // setCustomerStateId('');
                        // setCustomerStateName('')
                        setCustomerOrderDate('')
                    }
                    FnGetCustomersContactPersonsDetails(parseInt(customerVal));
                    setCustomerStateId(customerComboRef.current.state_id)
                    setCustomerStateName(customerComboRef.current.state_name)
                    // setCustomerOrderDate('');
                    $("#error_cmb_customer_id").hide();

                } else {
                    setCustomerOrdOpts([]);
                    // setCustomerCityId('');
                    setCustomerStateId('');
                    setCustomerOrderDate('');
                    setCustomerOrderDate('')
                    setCustContactDetails([])

                }
                break;

            case 'CustomerOrder':
                let customerOrderNoVal = document.getElementById("cmb_customer_order_no").value;
                if (customerOrderNoVal !== null && customerOrderNoVal !== '') {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_contacts_ids, customer_order_Date");
                    globalQuery.table = "mtv_sales_order_master_trading_summary";
                    globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: customerOrderNoVal, });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    const custContactsidsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                    if (custContactsidsApiCall.length !== 0) {
                        FnSetExistingCustomerContactPersonIds(custContactsidsApiCall[0])
                        setCustomerOrderDate(custContactsidsApiCall[0].customer_order_Date);
                    }
                } else {
                    setCustomerOrderDate('');
                }
                break;

            case 'ExpectedbranchId':
                let expectedBranchValue = parseInt(document.getElementById('cmb_expected_branch_id').value);
                setExpectedBranchId(expectedBranchValue)
                if (expectedBranchValue === 0) {
                    const newTab = window.open('Masters/Company', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case 'GoodReceiptStatus':
                let goodReceiptStatus = document.getElementById('goods_receipt_status')
                if (goodReceiptStatus !== '') {
                    $('#error_goods_receipt_status').hide()
                }
                break;
            default:
                break;
        }
    }

    const FnSetExistingCustomerContactPersonIds = (contactIds) => {
        if (contactIds !== '') {
            const distinctCustomerContsIds = [...new Set(contactIds.customer_contacts_ids.split(":").map(id => parseInt(id)))];

            const filteredContacts = custContactDetails.filter(contact =>
                distinctCustomerContsIds.includes(contact.customer_contact_id)
            );

            setCustContactDetails(filteredContacts);

            // Optionally, update the columns as well if needed
            // This depends on how your table rendering logic works
            // setCustContactDetailsCols(filteredColumns);
        } else {
            setCustContactDetails([]);
        }
    }



    const FnGetCustomersContactPersonsDetails = async (customerId) => {

        try {
            if (customerId != null && customerId != undefined && customerId != '' && customerId != NaN) {
                resetGlobalQuery();
                globalQuery.columns.push("customer_id");
                globalQuery.columns.push("customer_branch_name");
                globalQuery.columns.push("cust_branch_id");
                globalQuery.columns.push("customer_contact_id");
                globalQuery.columns.push("cust_contact_person");
                globalQuery.columns.push("cust_contact_no");
                globalQuery.columns.push("cust_alternate_contact");
                globalQuery.columns.push("cust_email_id");
                globalQuery.columns.push("cust_alternate_EmailId");
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                if (getContactDtlsApiCall.length !== 0) {
                    let column = [];
                    let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                    for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                        // if (colKey === 0) {
                        //     column.push({
                        //         // Headers: <div className="col-1"><input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { checkBoxesSelection('selectAllCustContact'); }} />Select</div>,
                        //         Headers: <div className="col-1"><input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" />Select</div>,

                        //         accessor: "Action",
                        //         Cell: row => (
                        //             <div className="text-center">
                        //                 <input type='checkbox' class="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + row.original.customer_contact_id} contact_personId={row.original.customer_contact_id} value={row.original.customer_contact_id} disabled />
                        //             </div>
                        //         ),
                        //     });
                        // }
                        if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
                            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                        }
                    }
                    setCustContactDetailsCols(column);
                    setCustContactDetails(getContactDtlsApiCall);
                } else {
                    setCustContactDetailsCols([]);
                    setCustContactDetails([]);
                }
            } else {
                setCustContactDetailsCols([]);
                setCustContactDetails([]);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }


    }

    // function checkBoxesSelection(key) {

    //     switch (key) {
    //         // For customer contact
    //         case 'selectAllCustContact':
    //             $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
    //             if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
    //                 $('#error_customer_contact_persons').text('Please select atleast one contact person.');
    //                 $('#error_customer_contact_persons').show();
    //             } else {
    //                 $('#error_customer_contact_persons').hide();
    //             }
    //             break;
    //         case 'PartiallyCustContactSelection':
    //             $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
    //             if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
    //                 $('#error_customer_contact_persons').text('Please select atleast one contact person.');
    //                 $('#error_customer_contact_persons').show();
    //             } else {
    //                 $('#error_customer_contact_persons').hide();
    //             }
    //             break;
    //     }
    // }



    // Material Name	 	Department    	Material Godown	Material Godown Section	Material Godown Section Beans	
    //  	Material Weight	
    //  	Accepted Weight
    //   	Item Status	
    // 	 	Material Hsn Code


    let requiredColsForExport = [
        "sr_no",
        "unit_name",
        "customer_material_order_quantity",
        "customer_material_accepted_quantity",
        "customer_material_rate",
        "customer_material_item_amount",
        "customer_material_rejected_quantity",
        "rejection_reason",
        "department",
        "godown_name",
        "godown_section_name",
        "godown_section_beans_name",
        "expiry_date",
        "product_material_std_weight",
        "customer_material_order_weight",
        "customer_material_accepted_weight",
        "customer_material_rejected_weight",
        "customer_batch_no",
        "customer_item_status",
        "customer_material_tech_spect",
        "customer_material_name",
        "customer_material_packing_id",
        "customer_material_hsn_code_id",
        "customer_goods_receipt_type_id",
        "customer_goods_receipt_type",
        "customer_material_id",
        "godown_id",
        "godown_section_id",
        "godown_section_beans_id",
        "customer_material_unit_id",
        "customer_material_rejection_reason_id",
        "department_id",
        "customer_material_packing_name",
        "customer_material_hsn_code",
        // "customer_item_status",
        // "customer_material_conversion_factor",
        // "prev_accepted_quantity",
        // "prev_accepted_weight"
    ];



    const ExportToExcel = () => {
        setIsLoading(true)
        const catCount = sessionStorage.getItem('compRMCatCount')
        var counter = 1;
        if (bomfilteredData.length !== 0) {
            let customerVal = customerComboRef.current.value || '';

            //changes by tushar
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [2, 3, 4] }
            jsonToExportExcel['filtrKeyValue']['0'] = "Customer Goods Receipt No" + ' : ' + txt_customer_grn_no
            jsonToExportExcel['filtrKeyValue']['1'] = "Customer Goods Receipt Version" + ' : ' + txt_Customer_grn_version
            jsonToExportExcel['filtrKeyValue']['2'] = "Customer Goods Receipt Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_goods_receipt_date)
            // jsonToExportExcel['filtrKeyValue']['3'] = "Customer" + ' : ' + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")"
            jsonToExportExcel['filtrKeyValue']['3'] = "Customer : " + (customerVal !== "" && customerVal !== '0' && customerVal !== null ? customerComboRef.current?.label + "(" + customerComboRef.current?.value + ")" : '');
            jsonToExportExcel['filtrKeyValue']['4'] = "Customer Order No" + ' : ' + $('#cmb_customer_order_no').find(":selected").text() + "(" + cmb_customer_order_no + ")"
            jsonToExportExcel['filtrKeyValue']['5'] = "Customer Order Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(customer_order_date) + "(" + customer_order_date + ")"
            jsonToExportExcel['filtrKeyValue']['6'] = "Customer Challan No" + ' : ' + txt_customer_challan_no + "(" + txt_customer_challan_no + ")"
            jsonToExportExcel['filtrKeyValue']['7'] = "Customer Challan Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_customer_challan_Date) + "(" + dt_customer_challan_Date + ")"
            jsonToExportExcel['filtrKeyValue']['8'] = "Customer State" + ' : ' + cmb_customer_state_name + "(" + cmb_customer_state_id + ")"
            jsonToExportExcel['filtrKeyValue']['9'] = "Qa By" + ' : ' + $('#cmb_qa_by_id').find(":selected").text() + "(" + cmb_qa_by_id + ")"
            jsonToExportExcel['filtrKeyValue']['10'] = "Qa Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_qa_date)


            //changes by tushar

            // set columns name.
            for (let col = 0; col < requiredColsForExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": requiredColsForExport[col], "accessor": requiredColsForExport[col] })
            }

            bomfilteredData.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                    godown_name: $(`#godown_id_${orderDetail.customer_material_id}`).find(":selected").text(),
                    godown_section_name: $(`#godown_section_id_${orderDetail.customer_material_id}`).find(":selected").text(),
                    godown_section_beans_name: $(`#godown_section_beans_id_${orderDetail.customer_material_id}`).find(":selected").text(),
                    unit_name: $(`#customer_material_unit_id_${orderDetail.customer_material_id}`).find(":selected").text(),
                    rejection_reason: $(`#customer_material_rejection_reason_id_${orderDetail.customer_material_id}`).find(":selected").text() === 'Select' ? '' : $(`#customer_material_rejection_reason_id_${orderDetail.customer_material_id}`).find(":selected").text(),
                    department: $(`#department_id_${orderDetail.customer_material_id}`).find(":selected").text(),
                    customer_item_status: $(`#customer_item_status_${orderDetail.customer_material_id}`).find(":selected").text(),
                    expiry_date: validateNumberDateInput.current.formatDateToDDMMYYYY($(`#expiry_date_${orderDetail.customer_material_id}`).val()),
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });

            jsonToExportExcel['headings']['ReportName'] = "Customer Material Receipt Note"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_customer_grn_no + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
        setIsLoading(false)

    }



    const handleInputChange = async (currentRowData, event) => {

        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;

        if (clickedColName === 'customer_material_accepted_quantity') {

            let reciepttemsQtyinp = document.querySelector('#' + event.target.id);
            const customerOrderQty = currentRowData.customer_material_order_quantity
            if (event.target.value !== '') {
                delete reciepttemsQtyinp.parentElement.dataset.tip;
                if (parseInt(event.target.value) > parseInt(customerOrderQty)) {
                    reciepttemsQtyinp.parentElement.dataset.tip = 'Accepted quantity must not exceed the Order Qty...!';
                    setTimeout(function () {
                        delete reciepttemsQtyinp.parentElement.dataset.tip; // Clear the tip after 5 second
                    }, 5000);
                    // currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(customerOrderQty.toString(), 4);;
                } else {
                    currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                    await fnCalculateItemQtyandAmount(currentRowData)
                    delete reciepttemsQtyinp.parentElement.dataset.tip;
                }
            } else {
                currentRowData[clickedColName] = '';
                reciepttemsQtyinp.parentElement.dataset.tip = 'Please enter valid accepted quantity...!';
            }
        }
        else if (clickedColName === 'customer_material_rejected_quantity') {
            let rejectedQty_qty = enteredValue;
            let product_std_wgt = currentRowData['product_std_weight'];
            let product_rej_wgt = rejectedQty_qty * product_std_wgt;

            currentRowData['customer_material_rejected_quantity'] = validateNumberDateInput.current.decimalNumber(rejectedQty_qty.toString(), 4);
            currentRowData['customer_material_rejected_weight'] = validateNumberDateInput.current.decimalNumber(product_rej_wgt.toString(), 4);
            let reciepttemsQtyinp = document.querySelector('#' + event.target.id);
            if (event.target.value !== '') {
                await fnCalculateItemQtyandAmount(currentRowData)
                currentRowData[clickedColName] = event.target.value;
                delete reciepttemsQtyinp.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = '';
                reciepttemsQtyinp.parentElement.dataset.tip = 'Please enter valid Rejected quantity...!';
            }
        }
        else if (clickedColName === 'customer_material_unit_id') {
            currentRowData[clickedColName] = event.target.value;
            const fromUnitId = event.target.value;
            const toUnitId = currentRowData['product_unit_id'];
            resetGlobalQuery();
            globalQuery.column = "convsersion_factor";
            globalQuery.table = "smv_product_unit_conversion";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_from_unit_id", operator: "=", value: fromUnitId });
            globalQuery.conditions.push({ field: "product_to_unit_id", operator: "=", value: toUnitId });
            const ConversionFactor = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            // removeCatcheFillCombo
            console.log(globalQuery);
            currentRowData['customer_material_conversion_factor'] = ConversionFactor;
        }
        else if (clickedColName === 'department_id') {
            let reciepttemsQtyinp = document.querySelector('#' + event.target.id);
            if (event.target.value !== '') {
                delete reciepttemsQtyinp.parentElement.dataset.tip;
                currentRowData[clickedColName] = event.target.value;
            }
        }
        else if (clickedColName === 'customer_item_status') {
            currentRowData[clickedColName] = event.target.value;
        }
        else if (clickedColName === 'customer_material_rejection_reason_id') {

            let reciepttemsQtyinp = document.querySelector('#' + event.target.id);
            const customeRejectionrQty = currentRowData.customer_material_rejected_quantity
            if (event.target.value !== '') {
                delete reciepttemsQtyinp.parentElement.dataset.tip;
                currentRowData[clickedColName] = event.target.value;
            } else {
                if (parseInt(customeRejectionrQty) > 0) {
                    reciepttemsQtyinp.parentElement.dataset.tip = 'Please select valid reason ...!';
                    currentRowData[clickedColName] = event.target.value;
                } else if (event.target.value === '') {
                    delete reciepttemsQtyinp.parentElement.dataset.tip;
                }
            }
        }
        else if (clickedColName === 'godown_id') {
            if (event.target.value !== '') {
                currentRowData[clickedColName] = enteredValue;
                await renderGodownSectionList(enteredValue, event.target.getAttribute('gdDetailsId'))
            } else {
                currentRowData['godown_section_id'] = ''
                currentRowData['godown_section_beans_id'] = ''
            }
        }
        else if (clickedColName === 'godown_section_id') {
            if (event.target.value !== '') {
                currentRowData[clickedColName] = enteredValue;
                await renderGodownSectionBeansList(enteredValue, event.target.getAttribute('gdDetailsId'))
            } else {
                currentRowData['godown_section_beans_id'] = ''
            }
        }
        else if (clickedColName === 'godown_section_beans_id') {
            currentRowData[clickedColName] = enteredValue;
        }
        // changes by tushar
        else if (clickedColName === 'customer_material_rate') {
            let reciepttemsQtyinp = document.querySelector('#' + event.target.id);
            if (event.target.value !== '') {
                currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue.toString(), 4);
                await fnCalculateItemQtyandAmount(currentRowData)
                delete reciepttemsQtyinp.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = '';
                reciepttemsQtyinp.parentElement.dataset.tip = 'Please enter material rate ...!';
            }
        }
        // customer_material_order_quantity
        else if (clickedColName === 'customer_material_order_quantity') {
            let reciepttemsQtyinp = document.querySelector('#' + event.target.id);


            if (enteredValue !== '' && enteredValue !== '0') {
                currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue.toString(), 4);
                delete reciepttemsQtyinp.parentElement.dataset.tip;
                await fnCalculateItemQtyandAmount(currentRowData)
                if (parseInt(currentRowData.customer_material_order_quantity) > '0') {
                    currentRowData.customer_material_accepted_quantity = validateNumberDateInput.current.decimalNumber(enteredValue.toString(), 4);
                    await fnCalculateItemQtyandAmount(currentRowData)
                } else {
                    currentRowData.customer_material_accepted_quantity = '0'
                }
            } else {
                currentRowData[clickedColName] = '';
                currentRowData.customer_material_accepted_quantity = '0'
                currentRowData.customer_material_rejected_quantity = '0'
                currentRowData.customer_material_rejected_weight = '0'
                currentRowData.customer_material_item_amount = '0'
                currentRowData.customer_material_rate = '1'
                currentRowData.customer_material_accepted_weight = '0'
                currentRowData.customer_material_rejection_reason_id = '0'
                await fnCalculateItemQtyandAmount(currentRowData)
            }
        }
        else if (clickedColName === 'total_box_weight'
            || clickedColName === 'total_quantity_in_box' || clickedColName === 'weight_per_box_item') {
            currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 2);
        } else if (clickedColName === 'expiry_date' || clickedColName === 'product_material_packing_id') {
            currentRowData[clickedColName] = event.target.value;
        }
        else {
            [clickedColName] = '';
        }

        const detailData = [...bomfilteredData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        detailData[arrayIndex] = currentRowData
        setBomfilteredData(detailData);
        sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));
        calculateGrandTotal(detailData)
    };


    // ------------------------------Qty and Amout Calculation-------------------------------------------------------------------------------------------
    const fnCalculateItemQtyandAmount = (currentRowData) => {

        let materialAmt = 0;
        let rejectedQty = 0;
        let rejectedwgt = 0;
        let acceptedwgt = 0;
        let orderQtyweight = 0;

        const customermaterialacceptedquantity = currentRowData.customer_material_accepted_quantity
        const materialrate = currentRowData.customer_material_rate
        const customerOrderQty = currentRowData.customer_material_order_quantity
        const stdweight = currentRowData.product_material_std_weight

        // console.log("customerOrderQty", customerOrderQty);
        // console.log("materialrate", materialrate);
        // console.log("customermaterialacceptedquantity", customermaterialacceptedquantity);


        if (parseInt(customermaterialacceptedquantity) >= parseInt(customerOrderQty)) {
            currentRowData.customer_material_accepted_quantity = validateNumberDateInput.current.decimalNumber(JSON.stringify(customerOrderQty), 4);
        }

        if (parseInt(customermaterialacceptedquantity) === parseInt(customerOrderQty)) {
            currentRowData.customer_material_rejection_reason_id = '0'
        }


        rejectedQty = (customerOrderQty - customermaterialacceptedquantity);
        // console.log("rejectedQty", rejectedQty);
        if (rejectedQty === 0) {
            currentRowData.customer_material_rejected_weight = 0;
        }
        currentRowData.customer_material_rejected_quantity = validateNumberDateInput.current.decimalNumber(JSON.stringify(rejectedQty), 4);

        if (rejectedQty !== 0) {
            rejectedwgt = (rejectedQty * stdweight)
            // console.log("rejected weight === >", rejectedwgt)
            currentRowData.customer_material_rejected_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(rejectedwgt), 4);
        }

        acceptedwgt = customermaterialacceptedquantity * stdweight;
        currentRowData['customer_material_accepted_weight'] = validateNumberDateInput.current.decimalNumber(acceptedwgt.toString(), 4);

        //   for calucalting material amt 
        materialAmt = (customermaterialacceptedquantity * materialrate);
        // console.log("materialAmt", materialAmt);
        currentRowData.customer_material_item_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(materialAmt), 4);

        orderQtyweight = (customerOrderQty * stdweight)
        // console.log("orderQtyweight", orderQtyweight);
        currentRowData.customer_material_order_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderQtyweight), 4);

        return currentRowData;
    }


    const renderGodownSectionList = async (godown_id, customer_material_id) => {


        let godownSectionObject = document.getElementById(`godown_section_id_${customer_material_id}`);
        let godownSectionBeansObject = document.getElementById(`godown_section_beans_id_${customer_material_id}`);

        // Remove filled elements first & then filled new
        godownSectionObject.innerHTML = '<option value="0">Select</option>';
        godownSectionBeansObject.innerHTML = '<option value="0">Select</option>';

        // getGodownsSectionList list for details table
        let godownSectionList = await comboDataFunc.current.fillMasterData("cmv_godown_section", "godown_id", godown_id);
        FnFillGodownOptions(godownSectionList, godownSectionObject)
    }

    const renderGodownSectionBeansList = async (godown_section_id, customer_material_id) => {
        let godownSectionBeansObject = document.getElementById(`godown_section_beans_id_${customer_material_id}`);

        // Remove filled elements
        godownSectionBeansObject.innerHTML = '<option value="0">Select</option>';

        // getGodownsSectionList list for details table
        let godownSectionBeansList = await comboDataFunc.current.fillMasterData("cmv_godown_section_beans", "godown_section_id", godown_section_id);
        FnFillGodownOptions(godownSectionBeansList, godownSectionBeansObject)
    }

    // Fill Options of godown section & beans
    const FnFillGodownOptions = (options, object) => {
        options.forEach(item => {
            const optionElement = document.createElement('option');
            optionElement.value = item.field_id;
            optionElement.text = item.field_name;
            object.appendChild(optionElement);
        });
    }



    const calculateGrandTotal = (bomfilted_data) => {

        let basicTotal = bomfilted_data.reduce((total, item) => total + parseFloat(item.customer_material_item_amount), 0);
        // console.log("basicTotal", basicTotal);
        setgrandtotal(validateNumberDateInput.current.decimalNumber(JSON.stringify(basicTotal), 4))
    }

    const renderTrackingTable = useMemo(() => {

        return <>

            <div style={{ overflowX: "hidden", flex: 0 }}>
                <Table className="erp_table showData" style={{ display: 'none' }} id='mrnDTrackingTbl' responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className="erp_table_th"> Prev Acceptance Qty</th>
                            <th className="erp_table_th"> Prev Acceptance Wt</th>
                            <th className="erp_table_th"> Pre Closed Customer Qty</th>
                            <th className="erp_table_th"> Pre Closed Customer Wt</th>
                            <th className="erp_table_th"> Customer Return Qty</th>
                            <th className="erp_table_th"> Customer Return Wt</th>
                            <th className="erp_table_th"> Production Issue Quantity</th>
                            <th className="erp_table_th"> Production Issue Weight</th>
                            <th className="erp_table_th"> Production Issue Return Quantity</th>
                            <th className="erp_table_th"> Production Issue Return Weight</th>
                            <th className="erp_table_th"> Production Issue Rejection Quantity</th>
                            <th className="erp_table_th"> Production Issue Rejection Weight</th>
                            <th className="erp_table_th"> Assembly Production Issue Quantity</th>
                            <th className="erp_table_th"> Assembly Production Issue Weight</th>
                            <th className="erp_table_th"> Transfer Issue Quantity</th>
                            <th className="erp_table_th"> Transfer Issue Weight</th>
                            <th className="erp_table_th"> Transfer Receipt Quantity</th>
                            <th className="erp_table_th"> Transfer Receipt Weight</th>
                            <th className="erp_table_th"> Outsource Out Quantity </th>
                            <th className="erp_table_th"> Outsource Out Weight </th>
                            <th className="erp_table_th"> Outsource In Quantity </th>
                            <th className="erp_table_th"> Outsource In Weight  </th>
                            <th className="erp_table_th"> Outsource Rejection Quantity </th>
                            <th className="erp_table_th"> Outsource Rejection Weight</th>
                            <th className="erp_table_th"> Loan Receipt Quantity </th>
                            <th className="erp_table_th"> Loan Receipt Weight </th>
                            <th className="erp_table_th"> Loan Issue Quantity </th>
                            <th className="erp_table_th"> Loan Issue Weight </th>
                            <th className="erp_table_th"> Cancel Quantity </th>
                            <th className="erp_table_th"> Cancel Weight </th>
                            <th className="erp_table_th"> Difference Quantity </th>
                            <th className="erp_table_th"> Difference Weight </th>

                        </tr>
                    </thead>
                    <tbody>
                        {bomfilteredData.map((CusRecNoteItem, Index) =>
                            <tr rowindex={Index} className="sticky-column">
                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_prev_accepted_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_prev_accepted_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.pree_closed_customer_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.pree_closed_customer_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_return_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_return_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.production_issue_quantity}</td >
                                <td className="erp_table_td text-end">{CusRecNoteItem.production_issue_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.production_issue_return_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.production_issue_return_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.production_issue_rejection_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.production_issue_rejection_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.assembly_production_issue_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.assembly_production_issue_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.transfer_issue_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.transfer_issue_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.transfer_receipt_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.transfer_receipt_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.outsources_out_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.outsources_out_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.outsources_in_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.outsources_in_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.outsources_rejection_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.outsources_rejection_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.loan_receipt_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.loan_receipt_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.loan_issue_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.loan_issue_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.cancel_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.cancel_weight}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.difference_quantity}</td>
                                <td className="erp_table_td text-end">{CusRecNoteItem.difference_weight}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    }, [bomfilteredData])

    const removeMaterial = (row, e) => {
        // if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
        if (keyForViewUpdate !== 'view') {

            const detailData = [...bomfilteredData]
            const findElementIndex = detailData.findIndex(record => record.product_id === row.customer_material_id);
            if (findElementIndex !== -1) { detailData.splice(findElementIndex, 1); }
            setBomfilteredData(detailData);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));
            calculateGrandTotal(detailData);
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('CustomerHeaderFormId')
        validate.current.validateFieldsOnChange('CGRNFooterFormId')


        // var formObj = $('#CustomerHeaderFormId');
        // var inputObj;
        // for (var i = 0; i <= formObj.get(0).length - 1; i++) {
        //     inputObj = formObj.get(0)[i];
        //     if (inputObj.type === 'text' && inputObj.value !== '') {
        //         $("#error_" + inputObj.id).hide();
        //     } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
        //         $("#error_" + inputObj.id).hide();
        //     } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
        //         $("#error_" + inputObj.id).hide();
        //     } else if (inputObj.type === 'date' && inputObj.value !== '') {
        //         $("#error_" + inputObj.id).hide();
        //     }
        // }
    }
    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('mrnDetailsTbl');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#mrnDetailsTbl').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('mrnDetailsTbl');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const removeSessions = async (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData');
        }

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const clearFormFields = async () => {
        $('#error_txt_customer_challan_no').hide()
        setCustomerOrdOpts([])
        await generateCustGrnNo();
        setCustContactDetails([])
        setCustomerOrderNo('')
        setCustomerOrderDate('')
        setQabyId(UserId)
        setQadate(today)
        setCustomerId('');
        setlrNo('');
        setVehicleNo('');
        setRemark('');
        setIsActive(true);
        setCustomerChallanNo('')
        setdtCustomerChallanDate('');
        setExpectedBranchId(COMPANY_BRANCH_ID)
        setCustomerStateId('')
        setCustomerStateName('')
        sessionStorage.removeItem('filteredMaterialData')
        setBomfilteredData([])
    }

    const moveToListing = () => {
        navigate(`/Transactions/TCustomerReciept/FrmTCustomerRecieptListing`)
    }

    // For update the combobox data after add new record.
    const FnUpdateComboBoxData = async (key) => {
        // if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
        if (keyForViewUpdate === 'view') {

            return false;
        }
        switch (key) {
            case 'Customer':
                const customerOrderIdApiCall = await comboDataFunc.current.fillMasterData("cmv_customer", "", "")
                // setCustomerOptions(customerOrderIdApiCall)
                //  ** Changes for the Searchable Combo
                const options = [
                    { value: '', label: 'Select', },
                    { value: '0', label: 'Add New Record+' },
                    ...customerOrderIdApiCall.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                ];
                setCustomerOptions(options);
                break;
            default:
                break;
        }
    }
    //--------------------------------- Document List Starts--------------------------------------------------

    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${documentGroup}&group_id=${txt_customer_grn_no !== 0 ? txt_customer_grn_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);



    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        switch (eventKey) {
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }

    }
    // --------------------------------------- CGRN Import Start ------------------------------------------------------------------

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        try {
            if (event.target.files && event.target.files.length > 0) {
                setIsLoading(true)
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                console.log(fetchRes)
                if (fetchRes.success === 1) {
                    if (fetchRes.data.length !== 0) {
                        let customerOrderNo = '';
                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            if (isNaN(parseFloat(orderDetail.customer_material_order_quantity))) {
                                orderDetail.customer_material_order_quantity = 0;
                            }

                            if (isNaN(parseFloat(orderDetail.customer_material_accepted_quantity))) {
                                orderDetail.customer_material_accepted_quantity = 0;
                            }

                            if (isNaN(parseFloat(orderDetail.customer_material_rate))) {
                                orderDetail.customer_material_rate = 0;
                            }
                            return orderDetail;

                        });

                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();

                                let comboVal;
                                // || fieldName === 'Customer Order Date'  if (fieldVal !== "" && fieldName === 'Po Creation Type' || fieldName === 'Po Life' || fieldName === 'Company city' || fieldName === 'Product Type' || fieldName === 'Supplier' || fieldName === 'Purchase Process' || fieldName === 'Supplier State' || fieldName === 'Company Branch' || fieldName === 'Department' || fieldName === 'Company Branch' || fieldName === 'Supplier City' || fieldName === 'Company Branch State' || fieldName === 'Mail Status') {
                                if (fieldVal !== "" && fieldName === 'Customer' || fieldName === 'Customer Order No' || fieldName === 'Customer Order Date' || fieldName === 'Customer Challan No' || fieldName === 'Customer Challan Date') {
                                    if (fieldVal !== "") {
                                        const splitVal = fieldVal.split('(')
                                        comboVal = splitVal[1].replace(')', '')
                                    }
                                }
                                switch (fieldName) {
                                    case 'Customer':
                                        setCustomerId(comboVal)
                                        await comboOnChange("customer")
                                        setCustomerOrderNo(customerOrderNo)
                                        await comboOnChange("CustomerOrder")
                                        break;
                                    case 'Customer Order No':
                                        customerOrderNo = comboVal;
                                        setCustomerOrderNo(comboVal)
                                        await comboOnChange("CustomerOrder")
                                        break;
                                    case 'Customer Order Date':
                                        setCustomerOrderDate(comboVal)
                                        break;

                                    case 'Customer Challan No':
                                        setCustomerChallanNo(comboVal)
                                        break;

                                    case 'Customer Challan Date':
                                        setdtCustomerChallanDate(comboVal)
                                        break;

                                }
                            }
                        }

                        setBomfilteredData(() => {
                            let modifiedImportData = readedOrderDetailsFromExcel.map((ordDetail) => ({
                                ...ordDetail,
                                customer_item_status: 'C',
                                customer_goods_receipt_master_transaction_id: 0,
                                customer_goods_receipt_details_transaction_id: 0,
                                expiry_date: validateNumberDateInput.current.formatDateToDDMMYYYY(ordDetail.expiry_date)
                                // prev_accepted_quantity: ordDetail.customer_material_accepted_quantity,
                                // prev_accepted_weight: ordDetail.customer_material_accepted_weight,
                            }))
                            modifiedImportData = modifiedImportData.map(fnCalculateItemQtyandAmount)
                            sessionStorage.setItem('filteredMaterialData', JSON.stringify(modifiedImportData))
                            calculateGrandTotal(modifiedImportData);
                            return modifiedImportData;
                        })
                        setIsLoading(false)
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setIsLoading(false)
                    setShowErrorMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // --------------------------------------- CGRN Import End ------------------------------------------------------------------

    const renderCustomerMaterialDetails = useMemo(() =>
        <Table className="erp_table erp_table_scroll" id='mrnDetailsTbl' responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === '' ? 'display' : 'd-none'}`}>Actions</th>
                    <th className="erp_table_th">Sr.no</th>
                    <th className="erp_table_th"> Material Name</th>
                    <th className="erp_table_th" style={{ paddingRight: "40px" }}> Material Unit</th>
                    <th className="erp_table_th"> Order Quantity</th>
                    <th className="erp_table_th"> Accepted Quantity</th>
                    <th className="erp_table_th"> Material rate</th>
                    <th className="erp_table_th"> Material Amount</th>
                    <th className="erp_table_th"> Rejected Quantity</th>
                    <th className="erp_table_th"> Rejection Reason</th>
                    <th className="erp_table_th" style={{ paddingRight: "100px" }}> Department</th>
                    <th className="erp_table_th"> Material Godown </th>
                    <th className="erp_table_th"> Material Godown Section</th>
                    <th className="erp_table_th"> Material Godown Section Beans</th>
                    <th className="erp_table_th"> Expiry Date</th>
                    <th className='erp_table_th'>Total Box Weight</th>
                    <th className='erp_table_th'>Total Quantity In Box</th>
                    <th className='erp_table_th'>Weight Per Box Item</th>
                    <th className="erp_table_th"> Material Weight</th>
                    <th className="erp_table_th"> Order Weight</th>
                    <th className="erp_table_th"> Accepted Weight</th>
                    <th className="erp_table_th"> Rejected Weight</th>
                    <th className="erp_table_th" style={{ paddingRight: "100px" }}> Batch No</th>
                    <th className="erp_table_th" style={{ paddingRight: "40px" }}> Item Status</th>
                    <th className="erp_table_th"> Material Tech. Spect.</th>
                    <th className="erp_table_th"> Material Packing</th>
                    <th className="erp_table_th"> Material Hsn Code</th>

                    {/* <th className="erp_table_th"> Conversion Factor</th> */}
                    {/* <th className={`erp_table_th ${keyForViewUpdate === '' ? 'd-none' : 'display'}`}> Prev Accepted Quantity</th> */}
                    {/* <th className={`erp_table_th ${keyForViewUpdate === '' ? 'd-none' : 'display'}`}> Prev Accepted Weight</th> */}

                </tr>
            </thead>
            <tbody>
                {bomfilteredData.map((CusRecNoteItem, Index) =>
                    <tr rowindex={Index} className="sticky-column">

                        <td className={`erp_table_td ${keyForViewUpdate === '' ? 'display' : 'd-none'} sticky-column`}>
                            <div style={{ display: 'flex' }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => removeMaterial(CusRecNoteItem, e)} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                        </td>

                        <td className="erp_table_td text-end sticky-column">{Index + 1}</td>

                        <td className={`erp_table_td  sticky-column`}>{CusRecNoteItem.customer_material_name}</td>

                        <td className="erp_table_td">
                            <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.customer_material_unit_id}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                id={`customer_material_unit_id_${CusRecNoteItem.customer_material_id}`}
                                Headers='customer_material_unit_id' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false}>
                                <option value='0'>Select</option>
                                {unitList.map(item =>
                                    <option value={item.field_id}>{item.field_name}</option>
                                )}
                            </select>
                        </td>

                        <td className={`erp_table_td`}>
                            {
                                <input type="text"
                                    id={`customer_material_order_quantity_${CusRecNoteItem.customer_material_id}`} className="erp_input_field mb-0 text-end" value={CusRecNoteItem.customer_material_order_quantity}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='customer_material_order_quantity' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view')} />
                            }
                        </td>


                        <td className={`erp_table_td`}>
                            {
                                <input type="text"
                                    id={`customer_material_accepted_quantity_${CusRecNoteItem.customer_material_id}`} className="erp_input_field mb-0 text-end" value={CusRecNoteItem.customer_material_accepted_quantity}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='customer_material_accepted_quantity' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view')}
                                />
                            }
                        </td>


                        <td className={`erp_table_td`}>
                            {
                                <input type="text"
                                    id={`customer_material_rate_${CusRecNoteItem.customer_material_id}`} className="erp_input_field mb-0 text-end" value={CusRecNoteItem.customer_material_rate}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='customer_material_rate' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view')}
                                />
                            }
                        </td>

                        <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_item_amount}</td>
                        <td className="erp_table_td text-end" rejectedqty={CusRecNoteItem.customer_material_rejected_quantity}>{CusRecNoteItem.customer_material_rejected_quantity}</td>
                        <td className="erp_table_td">
                            <select className="form-select form-select-sm mb-0" value={CusRecNoteItem.customer_material_rejection_reason_id}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                id={`customer_material_rejection_reason_id_${CusRecNoteItem.customer_material_id}`}
                                Headers='customer_material_rejection_reason_id' disabled={keyForViewUpdate !== "view" && keyForViewUpdate !== "delete" ? CusRecNoteItem.customer_material_rejected_quantity <= '0' ? true : false : true}>
                                <option value='0'>Select</option>
                                {rejectionParameterList.map(item =>
                                    <option value={item.field_id}>{item.field_name}</option>
                                )}
                            </select>
                        </td>

                        <td className="erp_table_td">
                            <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.department_id}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                id={`department_id_${CusRecNoteItem.customer_material_id}`}
                                Headers='department_id' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false}>
                                <option value='0'>Select</option>
                                {departmentList.map(item =>
                                    <option value={item.field_id}>{item.field_name}</option>
                                )}
                            </select>
                        </td>

                        <td className="erp_table_td">
                            <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.godown_id}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                id={`godown_id_${CusRecNoteItem.customer_material_id}`}
                                Headers='godown_id' gdDetailsId={CusRecNoteItem.customer_material_id} disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false}>
                                <option value=''>Select</option>
                                {godownsList.map(item =>
                                    <option value={item.field_id}>{item.field_name}</option>)}
                            </select>
                        </td>

                        <td className="erp_table_td text-end">
                            <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.godown_section_id}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                id={`godown_section_id_${CusRecNoteItem.customer_material_id}`}
                                Headers='godown_section_id' gdDetailsId={CusRecNoteItem.customer_material_id} disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false}>
                                <option value=''>Select</option>

                            </select>
                        </td>

                        <td className="erp_table_td">
                            <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.godown_section_beans_id}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                id={`godown_section_beans_id_${CusRecNoteItem.customer_material_id}`}
                                Headers='godown_section_beans_id' gdDetailsId={CusRecNoteItem.customer_material_id} disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false}>
                                <option value=''>Select</option>
                            </select>
                        </td>
                        <td className={`erp_table_td`} >
                            <input type="date"
                                id={`expiry_date_${CusRecNoteItem.customer_material_id}`} className="erp_input_field mb-0" value={CusRecNoteItem.expiry_date}
                                // id={`expiry_date_${CusRecNoteItem.customer_material_id}`} className="erp_input_field mb-0" value={"2024-02-20"}
                                onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='expiry_date' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false} />
                        </td>
                        <td className={`erp_table_td`}>
                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? CusRecNoteItem.total_box_weight :
                                <input type="text"
                                    id={`total_box_weight_${CusRecNoteItem.customer_material_id}`}
                                    className="erp_input_field mb-0"
                                    value={CusRecNoteItem.total_box_weight}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                    Headers='total_box_weight'
                                />
                            }
                        </td>
                        <td className={`erp_table_td`}>
                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? CusRecNoteItem.total_quantity_in_box :
                                <input type="text"
                                    id={`total_quantity_in_box_${CusRecNoteItem.customer_material_id}`}
                                    className="erp_input_field mb-0"
                                    value={CusRecNoteItem.total_quantity_in_box}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                    Headers='total_quantity_in_box'
                                />
                            }
                        </td>
                        <td className={`erp_table_td`}>
                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? CusRecNoteItem.weight_per_box_item :
                                <input type="text"
                                    id={`weight_per_box_item_${CusRecNoteItem.customer_material_id}`}
                                    className="erp_input_field mb-0"
                                    value={CusRecNoteItem.weight_per_box_item}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                    Headers='weight_per_box_item'
                                />
                            }
                        </td>
                        <td className="erp_table_td text-end">{CusRecNoteItem.product_material_std_weight}</td>
                        <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_order_weight}</td>
                        <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_accepted_weight}</td>
                        <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_rejected_weight}</td>


                        <td className={`erp_table_td`}>
                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? CusRecNoteItem.customer_batch_no :
                                <input type="text"
                                    id={`customer_batch_no_${CusRecNoteItem.customer_material_id}`} className="erp_input_field mb-0 text-end"
                                    value={CusRecNoteItem.customer_batch_no === undefined || CusRecNoteItem.customer_batch_no === '' ? CusRecNoteItem.customer_batch_no = `${FINANCIAL_SHORT_NAME}/${txt_customer_grn_no}/${today()}` : CusRecNoteItem.customer_batch_no}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='customer_batch_no' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view') ? true : false}
                                />
                            }
                        </td>



                        <td className="erp_table_td">
                            <select id={`customer_item_status_${CusRecNoteItem.customer_material_id}`} value={CusRecNoteItem.customer_item_status}
                                className="form-select form-select-sm  " Headers='customer_item_status'
                                disabled onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}>
                                <option value="C">Material Reciept</option>
                                <option value="Q">QC Done</option>
                                <option value="R">Rejected</option>
                                <option value="I">Used in production</option>
                                <option value="X">Material return</option>
                            </select></td>

                        <td className="erp_table_td sticky-column">{CusRecNoteItem.customer_material_tech_spect}</td>

                        {/* <td className="erp_table_td text-end scrollable-table">{CusRecNoteItem.customer_material_packing_name}</td> */}
                        <td className="erp_table_td">
                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? CusRecNoteItem.customer_material_packing_name :
                                <select className="form-select form-select-sm mb-0" id={`product_material_packing_id_${CusRecNoteItem.customer_material_id}`} value={CusRecNoteItem.product_material_packing_id || CusRecNoteItem.product_packing_id}
                                    onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                    Headers='product_material_packing_id' >
                                    <option value="">Select</option>
                                    {packingList?.map(packingItem => (
                                        <option value={packingItem.field_id}>{packingItem.field_name}</option>
                                    ))}
                                </select>}
                        </td>
                        <td className="erp_table_td text-end scrollable-table">{CusRecNoteItem.customer_material_hsn_code}</td>

                        {/* <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_conversion_factor}</td> */}
                        {/* <td className={`erp_table_th text-end ${keyForViewUpdate === '' ? 'd-none' : 'display'}`}>{CusRecNoteItem.prev_accepted_quantity}</td> */}
                        {/* <td className={`erp_table_th text-end ${keyForViewUpdate === '' ? 'd-none' : 'display'}`}>{CusRecNoteItem.prev_accepted_weight}</td> */}
                    </tr>
                )}
            </tbody>
        </Table>)

    // ------------------------ Changes for the Searchable Combos. --------------------------

    //GRN printout Start
    const cgrnInvoiceContent = {
        company: {
            company: '',
            company_branch: '',
        },
        customerDetails: {
            name: '',
            address: '',
            state: '',
            contact: '',
            City: '',
            Email: '',
            agent_name: '',
            qa_by_name: '',
            qa_date: '',
            is_sez: '',
            customer_challan_no: '',
            customer_challan_Date: '',
            customer_order_no: '',
            customer_order_date: '',

        },

        cgrnDetails: {
            customer_goods_receipt_no: '',
            customer_goods_receipt_date: '',
            customer_goods_receipt_version: '',
        },

        purchaseDetails: {
            purchase_order_no: '',
            purchase_order_date: ''

        },
        footer: {
            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            freight_amount: '',
            discount_amount: '',
            discount_percent: ''

        },
        items: [],
        paymentTerms: [],
    };

    const printInvoice = async (openPrintModal) => {
        debugger
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnShowAllDetailsAndMastermodelRecords/${txt_Customer_grn_version}/${FINANCIAL_SHORT_NAME}/${COMPANY_ID}?customer_goods_receipt_no=${txt_customer_grn_no}`)
        const responce = await apiCall.json();
        console.log("Response CGRN Invoice : ", responce);
        let CustomerReceiptMasterData = responce.GoodReceiptCustomerMasterRecord;
        let CustomerReceiptDetailsData = responce.GoodReceiptCustomerDetailsRecord;

        // //GRN details
        cgrnInvoiceContent.cgrnDetails.customer_goods_receipt_no = txt_customer_grn_no
        cgrnInvoiceContent.cgrnDetails.customer_goods_receipt_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_goods_receipt_date)
        cgrnInvoiceContent.cgrnDetails.customer_goods_receipt_version = txt_Customer_grn_version

        //Company details
        cgrnInvoiceContent.company.company = CustomerReceiptMasterData.company_name
        cgrnInvoiceContent.company.company_branch = CustomerReceiptMasterData.company_branch_name

        // //supplier details
        cgrnInvoiceContent.customerDetails.name = CustomerReceiptMasterData.customer_name
        // cgrnInvoiceContent.customerDetails.address = GoodsReceiptMasterData.supp_branch_address1
        cgrnInvoiceContent.customerDetails.state = CustomerReceiptMasterData.customer_state_name
        cgrnInvoiceContent.customerDetails.City = CustomerReceiptMasterData.customer_city_name

        cgrnInvoiceContent.customerDetails.contact = CustomerReceiptMasterData.supp_branch_phone_no
        cgrnInvoiceContent.customerDetails.Email = CustomerReceiptMasterData.supp_branch_EmailId

        let is_sez = null;
        if (CustomerReceiptMasterData.is_sez === "true") {
            is_sez = "Yes";
        } else {
            is_sez = "No";
        }

        cgrnInvoiceContent.customerDetails.customer_order_no = CustomerReceiptMasterData.customer_order_no
        cgrnInvoiceContent.customerDetails.customer_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(CustomerReceiptMasterData.supplier_invoice_date)


        cgrnInvoiceContent.customerDetails.customer_challan_no = CustomerReceiptMasterData.customer_challan_no
        cgrnInvoiceContent.customerDetails.customer_challan_Date = validateNumberDateInput.current.formatDateToDDMMYYYY(CustomerReceiptMasterData.customer_challan_Date)
        cgrnInvoiceContent.customerDetails.qa_by_name = CustomerReceiptMasterData.qa_by_name
        cgrnInvoiceContent.customerDetails.qa_date = validateNumberDateInput.current.formatDateToDDMMYYYY(CustomerReceiptMasterData.qa_date)
        // cgrnInvoiceContent.customerDetails.is_sez = is_sez
        // cgrnInvoiceContent.customerDetails.purchase_order_life = GoodsReceiptMasterData.purchase_order_life_desc

        // //Customer details
        // cgrnInvoiceContent.company.company = GoodsReceiptMasterData.company_name
        // cgrnInvoiceContent.company.company_branch = GoodsReceiptMasterData.company_branch_name

        // //footer details    

        // cgrnInvoiceContent.footer.basic_total = txt_basic_total
        // cgrnInvoiceContent.footer.cgst_total = txt_cgst_total
        // cgrnInvoiceContent.footer.sgst_total = txt_sgst_total
        // cgrnInvoiceContent.footer.igst_total = txt_igst_total
        // cgrnInvoiceContent.footer.taxable_total = txt_taxable_total
        // cgrnInvoiceContent.footer.grand_total = txt_grand_total
        // cgrnInvoiceContent.footer.freight_amount = txt_freight_amount
        // cgrnInvoiceContent.footer.discount_amount = txt_goods_receipt_discount_amount
        // cgrnInvoiceContent.footer.discount_percent = txt_goods_receipt_discount_percent



        for (let index = 0; index < CustomerReceiptDetailsData.length; index++) {
            const element = CustomerReceiptDetailsData[index];

            const detailsData = {
                sr_no: element.sr_no,
                material_name: element.product_material_name,
                material_tech_spect: element.customer_material_tech_spect === undefined || element.customer_material_tech_spect === null ? "" : element.customer_material_tech_spect,
                hsn_code: element.customer_material_hsn_code,
                order_quantity: element.customer_material_order_quantity,
                grn_accepted_quantity: element.customer_material_accepted_quantity,
                grn_rejected_quantity: element.customer_material_rejected_quantity,
                unit: element.customer_material_unit_name,
                rate: element.customer_material_rate,
                discount_amount: element.material_discount_amount,
                cgst_amount: element.material_cgst_total,
                sgst_amount: element.material_sgst_total,
                igst_amount: element.material_igst_total,
                cgst_percent: element.material_cgst_percent,
                sgst_percent: element.material_sgst_percent,
                igst_percent: element.material_igst_percent,
                basic_amount: element.material_basic_amount,
                taxable_amount: element.material_taxable_amount,
                material_total_amount: element.material_total_amount,
                purchase_order_no: element.purchase_order_no,
                purchase_order_version: element.purchase_order_version,
                supplier_challan_no: element.supplier_challan_date,
                purchase_order_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.purchase_order_date),
                customer_order_no: element.customer_order_no
            }
            cgrnInvoiceContent.items.push(detailsData)
        }


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: cgrnInvoiceContent, navigationLink: "/Transactions/TCustomerReciept/FrmTCustomerRecieptEntry", invoiceType: 'CMR', title: 'Customer Material Receipt', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            // return the html code.
            return renderToString(<CustMaterialReceipt invoiceContent={cgrnInvoiceContent} />);
        }

    };


    return (
        <>
            <DashboardLayout>
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ComboBox ref={comboDataFunc} />
                <ExcelExport ref={exlsExp} />
                <FrmValidations ref={validate} />
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}
                <form id="CustomerHeaderFormId">
                    <div className='card p-1'>
                        <div className='card-header text-center py-1'>
                            <label className='erp-form-label-lg text-center'> Customer Material Receipt {actionType}</label>
                        </div>

                        <div className="row p-1">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Customer Goods Receipt Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <select id="cmb_customer_goods_receipt_type_id" className="form-select form-select-sm" value={cmb_customer_goods_receipt_type_id} onChange={() => { comboOnChange('customerGoodReciptType'); validateFields(); }} disabled={keyForViewUpdate === 'update'}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerGoodReciptTypeOptions.length !== 0 ? (
                                                <>
                                                    {customerGoodReciptTypeOptions.map(customerGoodReciptTypeID => (
                                                        <option key={customerGoodReciptTypeID.field_id} value={customerGoodReciptTypeID.field_id}>
                                                            {customerGoodReciptTypeID.field_name}
                                                        </option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_goods_receipt_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Customer Grn No, Version & Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_customer_grn_no" className="erp_input_field" value={txt_customer_grn_no} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='txt_Customer_grn_version' className="erp_input_field text-end" value={txt_Customer_grn_version} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='dt_goods_receipt_date' className="erp_input_field" value={dt_goods_receipt_date} onChange={e => { setGoodReceiptDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_goods_receipt_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-md-7 col-10 mb-1'>
                                        {/* <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { setCustomerId(e.target.value); comboOnChange('customer'); validateFields() }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerOptions?.map(customer => (
                                                <option value={customer.field_id}>{customer.field_name}</option>
                                            ))}
                                        </select> */}
                                        <Select ref={customerComboRef}
                                            options={customerOptions}
                                            isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate)}
                                            inputId="cmb_customer_id" // Provide the ID for the input box
                                            value={customerOptions.find(option => option.value === cmb_customer_id)}
                                            onChange={(selectedOpt) => {
                                                setCustomerId(selectedOpt.value);
                                                customerComboRef.current = selectedOpt;
                                                comboOnChange('customer');
                                                validateFields();
                                            }}
                                            placeholder="Search for a customer..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography >
                                                <MdRefresh onClick={() => { if (keyForViewUpdate !== 'delete' || keyForViewUpdate !== 'view') { FnUpdateComboBoxData('Customer') } }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">So No.</Form.Label>
                                    </div>
                                   
                                    <div className='col'>
                                        <select
                                            id="cmb_so_no"
                                            className="form-select form-select-sm"
                                            value={cmb_so_no}
                                            onChange={(e) => { setSalesNoId(e.target.value); comboOnChange('customerso_no'); validateFields() }} >
                                            <option value="">Select</option>
                                            {customerOrdOpts?.map(customer => (
                                                <option value={customer.sales_order_no}>{customer.sales_order_no}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_so_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>


                                </div> */}

                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer No.</Form.Label>
                                    </div>
                                   
                                    <div className='col'>
                                        <select
                                            id="cmb_so_no"
                                            className="form-select form-select-sm"
                                            value={cmb_so_no}
                                            onChange={(e) => { setSalesNoId(e.target.value); comboOnChange('customerso_no'); validateFields() }} >
                                            <option value="">Select</option>
                                            {customerOrdOpts?.map(customer => (
                                                <option value={customer.customer_order_no}>{customer.customer_order_no}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_so_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                  </div> */}
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer Order No & Order Date
                                            {/* <span className="required">*</span> */}
                                        </Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); comboOnChange('CustomerOrder'); validateFields(); }} optional='optional'>
                                                    <option value="">Select</option>
                                                    {customerOrdOpts.length !== 0 ?
                                                        <>
                                                            {customerOrdOpts?.map(order => (
                                                                <option value={order.customer_order_no} ord_date={order.customer_order_Date}>{order.customer_order_no}</option>
                                                            ))}
                                                        </>
                                                        : ''
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='customer_order_date' className="erp_input_field" value={customer_order_date} optional='optional' />
                                                <MDTypography variant="button" id="error_customer_order_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer Challan No. & Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_customer_challan_no" className="erp_input_field" value={txt_customer_challan_no} onBlur={e => { comboOnChange("challan_no"); validateFields(); }} onChange={e => { setCustomerChallanNo(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_txt_customer_challan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='dt_customer_challan_Date' className="erp_input_field" value={dt_customer_challan_Date} onChange={e => { setdtCustomerChallanDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_customer_challan_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Customer State  </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} disabled='disabled' optional='optional'>
                                            <option value="" disabled>Select</option>
                                            {stateOpts?.map(state => (
                                                <option value={state.field_id} >{state.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}

                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer City <span className="required"></span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="cmb_customer_city_id" getAttribute={cmb_customer_city_id} className="erp_input_field" value={cmb_customer_city_name} disabled='disabled' optional='optional' />
                                        <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}
                            </div>
                            {/* second row */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer State </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="cmb_customer_state_id" getAttribute={cmb_customer_state_id} className="erp_input_field" value={cmb_customer_state_name} disabled='disabled' optional='optional' />
                                        <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Expected Branch <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange('ExpectedbranchId'); validateFields(); }}   >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {expectedBranchList?.map(Expectedbranchid => (
                                                <option value={Expectedbranchid.company_branch_id} is_sez={`${Expectedbranchid.is_sez}`}>{Expectedbranchid.company_branch_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>



                                {/* {(keyForViewUpdate === 'approve') ? (
                                    <>
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-9 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-8 ">
                                                        <select id="cmb_approved_by_id" className="form-select form-select-sm" value={UserId} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} disabled >
                                                            <option value="">Select</option>
                                                            <option value="0">Add New Record+</option>
                                                            {employeeList?.map(employee => (
                                                                <option value={employee.field_id}>{employee.field_name}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                        <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApprovedDate(e.target.value); validateFields(); }} />
                                                        <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null
                                } */}

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Q.A. By & Q.A. Date <span className="required">*</span></Form.Label>
                                    </div>

                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_qa_by_id" className="form-select form-select-sm" value={cmb_qa_by_id} onChange={(e) => { setQabyId(e.target.value); validateFields(); }}  >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {employeeList?.map(employee => (
                                                        <option value={employee.field_id}>{employee.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_qa_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='dt_qa_date' className="erp_input_field" value={dt_qa_date} onChange={(e) => { setQadate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_qa_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    custContactDetails.length > 0
                                        ? <>
                                            <div className="row">

                                                <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                                        <Accordion.Body className="p-0">

                                                            {/* {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>

                                                                <div className={` d-flex justify-content-end align-items-center`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }>  <Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(cmb_customer_id) }}>Refresh</MDButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                } */}


                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <Datatable data={custContactDetails} columns={custContactDetailsCols} />
                                                                </div>
                                                            </div>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion >
                                            </div>
                                        </>
                                        : null
                                }

                                {/* <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography> */}

                            </div>
                        </div>
                    </div>
                </form>
                <hr />

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Customer Receipt Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex row mb-2">
                                <div className="col-md-4 col-12">
                                    <div className={`row ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                                        <div className="col-md-8 col-12">
                                            <SearchItemFilter getSearchData={handleCloseBomFilterForm} formIdForValidate="CustomerHeaderFormId" requiredCols={requiredCols} />
                                        </div>
                                        <div className="col-md-4 " >
                                            <MDButton type="button" className={`erp-gb-button ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button" id="add-material" fontWeight="regular" onClick={viewBomFilterForm} >Add Materials</MDButton>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row"> */}
                                {/* <div className="col-md-3 col-12 ">
                                        <MDButton type="button" className={`erp-gb-button ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button" id="add-material" fontWeight="regular" onClick={viewBomFilterForm} >Add Materials</MDButton>
                                    </div> */}
                                {/* <div className={`col-md-7 col-12 d-flex  ${bomfilteredData.length !== 0 ? 'display' : 'd-none'}`}>
                                        <Form.Control type="text" id="txt-detail-data-highliter" style={{ display: 'none' }} className="erp_input_field txt-filter-bom showData" />
                                        <MDButton type="button" className="erp-gb-button erp_MLeft_btn showData" style={{ display: 'none' }} variant="button" onClick={() => scrollToTableRow()}
                                            fontWeight="regular">Search</MDButton>
                                    </div> */}
                                <div className="col-md-6 col-12"></div>
                                <div className={`col-md-2 col-12 d-flex justify-content-end`}>
                                    <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                                    <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                    <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} >Import</MDButton>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="erp-Mt-10">
                                <>
                                    {bomfilteredData.length !== 0 ?
                                        <>
                                            <div style={{ overflowX: "hidden", flex: 0 }}>
                                                {renderCustomerMaterialDetails}
                                            </div>
                                        </> : ""
                                    }
                                </>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                    {keyForViewUpdate !== '' ?
                        <>
                            <hr />
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="erp-form-label-md">Customer Receipt Tracking Details</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="erp-Mt-10">
                                        <>
                                            {renderTrackingTable}
                                        </>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <hr />
                        </> : ""}
                </Accordion>
                {keyForViewUpdate !== '' ?
                    <> <hr />
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                <Accordion.Body>
                                    {docData.length !== 0 ? (
                                        renderDocumentTable
                                    ) : (
                                        <div className='row'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    : null
                }
                <hr />






                <div className="card">

                    <div className="card-header main_heding text-start">
                        <label className='erp-form-label-lg'>Customer Receipt Totals</label>
                    </div>
                    <div className="card-body">
                        <form id="CGRNFooterFormId">
                            <div className="row">
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Grand Total <span className="required"></span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id="txt_grandtotal" className="erp_input_field text-end" value={txt_grandtotal} onChange={(e) => { setgrandtotal(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled optional='optional' />
                                            <MDTypography variant="button" id="error_txt_grandtotal" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Customer Receipt Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_goods_receipt_status" className="form-select form-select-sm" value={cmb_goods_receipt_status} onChange={(e) => { setGoodsReceiptStatus(e.target.value); comboOnChange('GoodReceiptStatus') }} optional='optional'   >
                                                <option value="C">Material Reciept</option>
                                                <option value="Q">QC Done</option>
                                                <option value="R">Rejected</option>
                                                <option value="I">Used in production</option>
                                                <option value="X">Material return</option>
                                                <option value="MC">Completed</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_goods_receipt_status" className='erp_validation' fontWeight="regular" color="error" style={{ display: 'none' }}>
                                                Please select good receipt status!..
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Vehicle No <span className="required"></span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">EWB No. & Valid till</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_ebw_no" className="erp_input_field" value={txt_ebw_no} onChange={(e) => { setEbwNo(e.target.value); validateFields(); }} optional='optional' />
                                                    <MDTypography variant="button" id="error_txt_ebw_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                    <Form.Control type="datetime-local" id='dt_ebw_date' className="erp_input_field" value={dt_ebw_date} onChange={e => { setEbwDate(e.target.value); }} optional='optional' />
                                                    <MDTypography variant="button" id="dt_ebw_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">LR No. & Date</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_lr_no" className="erp_input_field" value={txt_lr_no} onChange={(e) => { setlrNo(e.target.value); validateFields(); }} optional='optional' />
                                                    <MDTypography variant="button" id="error_txt_lr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                    <Form.Control type="date" id='dt_lr_date' className="erp_input_field" value={dt_lr_date} onChange={e => { setlrDate(e.target.value); }} optional='optional' />
                                                    <MDTypography variant="button" id="dt_lr_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                {/* <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_active" checked={is_active === true} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_active" checked={is_active === false} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                             */}
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_active" checked={is_active === true} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' ? true : false} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_active" checked={is_active === false} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' ? true : false} /> </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>




                {/* ADD Filter modal */}
                {/* changes by tushar */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={handleCloseBomFilterForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Raw Material Filter</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseBomFilterForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductListProductTypeWise closeModal={handleCloseBomFilterForm} props={{ product_type_id: cmb_customer_goods_receipt_type_id }} stockType={"Customer"} />
                            {/* <ProductList closeModal={handleCloseBomFilterForm} props={{ selectedProduct: ['smv_product_rm_summary', 'smv_product_fg_summary'] }} stockType={"Customer"} /> */}
                        </React.Fragment>
                    </Modal.Body>
                </Modal>

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                        {/* <Modal.Header>
                            <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                        </Modal.Header> */}
                        <Modal.Body className='erp_city_modal_body'>
                            {displayRecordComponent()}
                        </Modal.Body>
                        <Modal.Footer>
                            <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                                fontWeight="regular">Close</MDButton>
                        </Modal.Footer>
                    </ Modal> : null
                }
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                <div className="text-center">
                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn ms-4" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="btn_action_label" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => createCustomerRecieptNote()} variant="button"
                        fontWeight="regular">{actionLabel}</MDButton>

                    {/* <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => createCustomerRecieptNote(!isApprove ? 'Add' : 'A')} variant="button"
                        fontWeight="regular">{actionLabel}</MDButton> */}
                    {/* <MDButton type="button" className={`erp-gb-button ms-2 ${isApprove ? 'display' : 'd-none'}`}
                        variant="button" onClick={() => createGoodReceiptNote('R')}
                                               fontWeight="regular">Reject</MDButton> */}

                    <MDButton className={`erp-gb-button ms-2`} variant="button" fontWeight="regular" id='btn_view_document' onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;
                    <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" id='clearbutton-id' onClick={clearFormFields} fontWeight="regular" >Clear</MDButton>
                    <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => deleteshow()}>Delete</MDButton>
                    {/* <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" id='clearbutton-id' onClick={clearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton> */}
                    {/* <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => { printInvoice(); }}>Print Invoice<FiDownload className="erp-download-icon-btn" /></MDButton> */}
                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'update' || keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                    </MDButton>
                </div >
                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_customer_grn_no !== 0 ? txt_customer_grn_no.replaceAll('/', '_') : null} document_group={documentGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout >
        </>
    )
}
export default FrmTCustomerRecieptEntry