import { useState, useEffect, useRef } from "react";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstra
import Form from 'react-bootstrap/Form';

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';

import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';


// File Imports
// import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import DepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import { useNavigate, useLocation } from "react-router-dom";
import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from "FrmGeneric/FrmValidations";
import ComboBox from "Features/ComboBox";

function FrmMProductionSpinningPlanParameterEntry() {

  // var production_spinning_plan_parameter_id = sessionStorage.getItem('production_spinning_plan_parameter_id');

  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName, COMPANY_BRANCH_ID } = configConstants;

  const { state } = useLocation();
  const { production_spinning_plan_parameterId = 0, keyForViewUpdate, compType } = state || {};

  // useReff
  // const combobox = useRef();
  const navigate = useNavigate()
  const comboDataAPiCall = useRef();
  const validateNumberDateInput = useRef();
  const validate = useRef();

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing`)
    }
  }

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // form Feilds



  const [txtproduction_spinning_plan_parameter_id, setproduction_spinning_plan_parameter_id] = useState(production_spinning_plan_parameterId)
  const [cmb_production_department_id, setproduction_department_Id] = useState('')
  const [cmb_production_sub_department_id, setproduction_sub_department_id] = useState('')
  const [txt_production_spinning_plan_parameter_name, setproductionspinningplanparametername] = useState('')
  const [chk_Calculationtype, setCalculationtype] = useState("Manual")
  // cmb
  const [cmb_formula_plan_parameter1, setformulaplanparameter1] = useState('')
  const [cmb_formula_plan_parameter2, setformulaplanparameter2] = useState('')
  const [cmb_formula_plan_parameter3, setformulaplanparameter3] = useState('')
  const [cmb_formula_plan_parameter4, setformulaplanparameter4] = useState('')
  const [cmb_formula_plan_parameter5, setformulaplanparameter5] = useState('')


  const [txt_Formula, setFormula] = useState('')
  const [txt_production_plan_value, setproduction_plan_value] = useState('')
  const [txt_production_spinning_plan_parameter_sequance, setproductionspinningplanparametersequance] = useState('')

  // Combo box options.
  const [productiondepartmentOption, setproductiondepartmentOption] = useState([]);
  const [productionsubdepartmentOption, setproductionsubdepartmentOption] = useState([]);

  const [formulaplanparameter1Options, setformulaplanparameter1Options] = useState([])
  const [formulaplanparameter2Options, setformulaplanparameter2Options] = useState([])
  const [formulaplanparameter3Options, setformulaplanparameter3Options] = useState([])
  const [formulaplanparameter4Options, setformulaplanparameter4Options] = useState([])
  const [formulaplanparameter5Options, setformulaplanparameter5Options] = useState([])

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')



  const fillComobos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_department"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
      globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
      const productionParentProcessApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
      setproductiondepartmentOption(productionParentProcessApiCall);


      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "xmv_production_spinning_plan_parameter"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      const formulaparameterApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      setformulaplanparameter1Options(formulaparameterApiCall);
      setformulaplanparameter2Options(formulaparameterApiCall);
      setformulaplanparameter3Options(formulaparameterApiCall);
      setformulaplanparameter4Options(formulaparameterApiCall);
      setformulaplanparameter5Options(formulaparameterApiCall);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }




  // Handler for radio button change
  const handleCalculationTypeChange = (type) => {
    setCalculationtype(type);
    if (type === 'Manual') {
      setformulaplanparameter1('');
      setformulaplanparameter2('');
      setformulaplanparameter3('');
      setformulaplanparameter4('');
      setformulaplanparameter5('');
      setFormula('');

    } else if (type === 'Auto') {
      setproduction_plan_value('')
    }
  };


  const [actionType, setActionType] = useState('')

  // const ActionType = () => {
  //   if (keyForViewUpdate === 'update') {
  //     setActionType('(Modification)');
  //   } else if (keyForViewUpdate === 'view') {
  //     setActionType('(View)');
  //   } else {
  //     setActionType('(Create)');
  //   }
  // };
  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        $('#cmb_production_department_id').attr('disabled', true);
        $('#cmb_production_sub_department_id').attr('disabled', true);
        $('#btn_save').attr('disabled', false);
        $('#btn_upload_doc').attr('disabled', false);
        break;
      case 'view':
        await validate.current.readOnly("spinningplanparameterid");
        $("input[type=radio]").attr('disabled', true);

        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };



  useEffect(async () => {
    ActionType()
    await fillComobos();
    if (production_spinning_plan_parameterId !== 0) {
      await FnCheckUpdateResponce();
    }
  }, [])

  const validateFields = () => {
    validate.current.validateFieldsOnChange('spinningplanparameterid')

  }


  const comboOnChange = async (key) => {
    try {
      switch (key) {
        case 'Department':
          var departmentIdVal = document.getElementById('cmb_production_department_id').value;
          setproduction_department_Id(departmentIdVal)
          if (departmentIdVal === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Department')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(1).css("padding-top", "0px");
            }, 200)

          }

          if (departmentIdVal !== 0 || departmentIdVal !== null) {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department"
            globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
            const subProductionIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setproductionsubdepartmentOption(subProductionIdApiCall)
            setproduction_sub_department_id('');
          }
          $('#error_cmb_production_sub_department_Id').hide();
          break;


        case 'SubDepartment':
          var subdepartmentIdVal = document.getElementById('cmb_production_sub_department_id').value;
          setproduction_sub_department_id(subdepartmentIdVal)
          if (subdepartmentIdVal === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Sub Department')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("Error: " + error)
      navigate('/Error')
    }
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {

      case 'Department':
        return <DepartmentEntry btn_disabled={true} />;

      case 'Sub Department':
        return <DepartmentEntry department={cmb_production_department_id} btn_disabled={true} />;

      default:
        return null;
    }
  }



  const AddData = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm('spinningplanparameterid');
      if (checkIsValidate) {

        if (chk_Calculationtype === "Auto") {
          let productionplanformula = $('#txt_Formula').val().toLowerCase().trim();

          let parameterCmbValue;
          if (productionplanformula !== '') {
            $('#error_txt_Formula').hide();
            for (let parameterCount = 1; parameterCount <= 5; parameterCount++) {
              $(`#error_cmb_formula_plan_parameter${parameterCount}`).hide();

              let parameter = `p${parameterCount}`;
              let comboValue = $(`#cmb_formula_plan_parameter${parameterCount}`).val();

              if (productionplanformula.includes(parameter) && $(`#cmb_formula_plan_parameter${parameterCount}`).val() === '') {
                $(`#error_cmb_formula_plan_parameter${parameterCount}`).text('This parameter is added in the formula but you not selected...!');
                $(`#error_cmb_formula_plan_parameter${parameterCount}`).show();
                return false;
              } else if (comboValue !== '' && !productionplanformula.includes(parameter)) {
                $(`#error_cmb_formula_plan_parameter${parameterCount}`).text(`You've selected ${parameter}, but it's not used in the formula.`);
                $(`#error_cmb_formula_plan_parameter${parameterCount}`).show();
                return false;
              }
            }
          }
          else {
            $('#error_txt_Formula').text("Please insert the salary formula.");
            $('#error_txt_Formula').show();
            return;
          }
        }

        if (checkIsValidate === true) {
          var active;
          var activeValue = document.querySelector('input[name=isproductionspinningplanparameterActive]:checked').value
          switch (activeValue) {
            case '0': active = false; break;
            case '1': active = true; break;
          }

          var Calculationtype = document.querySelector('input[name=chk_Calculationtype]:checked').value
          setCalculationtype(Calculationtype);
          console.log("Calculationtype: ", Calculationtype);
          const data = {
            company_id: COMPANY_BRANCH_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            production_spinning_plan_parameter_id: txtproduction_spinning_plan_parameter_id,
            created_by: UserName,
            modified_by: txtproduction_spinning_plan_parameter_id === 0 ? null : UserName,
            production_department_id: cmb_production_department_id,
            production_sub_department_id: cmb_production_sub_department_id,
            production_spinning_plan_parameter_name: txt_production_spinning_plan_parameter_name,
            production_plan_calculation_type: Calculationtype,
            formula_plan_parameter_id1: cmb_formula_plan_parameter1,
            formula_plan_parameter_id2: cmb_formula_plan_parameter2,
            formula_plan_parameter_id3: cmb_formula_plan_parameter3,
            formula_plan_parameter_id4: cmb_formula_plan_parameter4,
            formula_plan_parameter_id5: cmb_formula_plan_parameter5,
            production_plan_formula: txt_Formula,
            production_plan_value: txt_production_plan_value,
            production_spinning_plan_parameter_sequance: txt_production_spinning_plan_parameter_sequance,
            is_active: active,
          };
          console.log(data);

          const requestOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          };
          const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSpinningPlanParameter/FnAddUpdateRecord`, requestOptions)
          const responce = await apicall.json()
          console.log("response error: ", responce.data);
          if (responce.error !== "") {
            console.log("response error: ", responce.error);
            setErrMsg(responce.error)
            setShowErrorMsgModal(true)

          } else {
            const evitCache = comboDataAPiCall.current.evitCache();
            console.log(evitCache);
            console.log("production_spinning_plan_parameter_id", responce.data);
            setSuccMsg(responce.message)
            setShowSuccessMsgModal(true);
            await FnCheckUpdateResponce();

          }
        }
      }
    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }


  {/* Function for fetch details for view or edit */ }
  const FnCheckUpdateResponce = async () => {
    debugger
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSpinningPlanParameter/FnShowParticularRecordForUpdate/${production_spinning_plan_parameterId}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();
      const data = updateRes.data
      if (data !== null && data !== "") {
        setproduction_spinning_plan_parameter_id(data.production_spinning_plan_parameter_id)
        setproduction_department_Id(data.production_department_id)
        await comboOnChange('Department');
        setproduction_sub_department_id(data.production_sub_department_id)
        setproductionspinningplanparametername(data.production_spinning_plan_parameter_name)
        setCalculationtype(data.production_plan_calculation_type)
        setformulaplanparameter1(data.formula_plan_parameter_id1)
        setformulaplanparameter2(data.formula_plan_parameter_id2)
        setformulaplanparameter3(data.formula_plan_parameter_id3)
        setformulaplanparameter4(data.formula_plan_parameter_id4)
        setformulaplanparameter5(data.formula_plan_parameter_id5)
        setFormula(data.production_plan_formula)
        setproduction_plan_value(data.production_plan_value)
        setproductionspinningplanparametersequance(data.production_spinning_plan_parameter_sequance)


        switch (data.calculation_type) {
          case true:
            document.querySelector('input[name="chk_Calculationtype"][value="Manual"]').checked = true;
            break;
          case false:
            document.querySelector('input[name="chk_Calculationtype"][value="Auto"]').checked = true;
            break;
        }

        switch (data.is_active) {
          case true:
            document.querySelector('input[name="isproductionspinningplanparameterActive"][value="1"]').checked = true;
            break;
          case false:
            document.querySelector('input[name="isproductionspinningplanparameterActive"][value="0"]').checked = true;
            break;
        }
        sessionStorage.removeItem('production_spinning_plan_parameter_name')
        sessionStorage.setItem("production_spinning_plan_parameter_name", data.production_spinning_plan_parameter_name);

        // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');

      }


    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {

      case 'Department':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_department"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
        const productionParentProcessApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setproductiondepartmentOption(productionParentProcessApiCall); break;
      case 'Sub Department':

        var departmentIdVal = document.getElementById('cmb_production_department_id').value;
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_department"
        globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "department_group", operator: "=", value: "Production" });
        const subProductionIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setproductionsubdepartmentOption(subProductionIdApiCall)
        setproduction_sub_department_id('');
        break;



      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }




  // Function to handle changes in the Production Department combobox
  const handleProductionDepartmentChange = (e) => {
    setproduction_department_Id(e.target.value);
    checkIfBothSelected(e.target.value, cmb_production_sub_department_id);
  }

  // Function to handle changes in the Production Sub Department combobox
  const handleProductionSubDepartmentChange = (e) => {
    setproduction_sub_department_id(e.target.value);
    checkIfBothSelected(cmb_production_department_id, e.target.value);
  }

  // Function to check if both comboboxes are selected and call the handlechangesequence function
  const checkIfBothSelected = (departmentId, subDepartmentId) => {
    if (departmentId && subDepartmentId) {
      handlechangesequence();
    } else {
      setproductionspinningplanparametersequance('');
    }
  }

  // Function to be called when both comboboxes are selected
  const handlechangesequence = async () => {
    debugger;

    var departmentIdVal = document.getElementById('cmb_production_department_id').value;
    var subdepartmentIdVal = document.getElementById('cmb_production_sub_department_id').value;

    resetGlobalQuery();
    globalQuery.columns.push("MAX(production_spinning_plan_parameter_sequance) as count");
    globalQuery.table = "xmv_production_spinning_plan_parameter"
    globalQuery.conditions.push({ field: "production_department_id", operator: "=", value: departmentIdVal });
    globalQuery.conditions.push({ field: "production_sub_department_id", operator: "=", value: subdepartmentIdVal });
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    const getmaxcountspiningsequenceApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    setproductionspinningplanparametersequance(getmaxcountspiningsequenceApiCall[0].count + 1);

    console.log('Both comboboxes are selected, calling handlechangesequence');
  }


  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <FrmValidations ref={validate} />


      <div className='erp_top_Form'>
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'> Production Spinning Plan Parameter  {actionType}  </label>
          </div>

          {/* <div className='card mt-4'> */}
          <form id="spinningplanparameterid">
            <div className="row erp_transporter_div">
              {/* First Collumn */}
              <div className="col-sm-6 erp_form_col_div">

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Department<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_production_department_id" className="form-select form-select-sm" value={cmb_production_department_id} onChange={(e) => {
                      handleProductionDepartmentChange(e); setproduction_department_Id(e.target.value); comboOnChange('Department');
                    }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productiondepartmentOption?.map(Department => (
                        <option value={Department.field_id}>{Department.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_production_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>



                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">  Sub Department<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_production_sub_department_id" className="form-select form-select-sm" value={cmb_production_sub_department_id} onChange={(e) => {
                      handleProductionSubDepartmentChange(e); setproduction_sub_department_id(e.target.value); comboOnChange('SubDepartment');
                    }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productionsubdepartmentOption?.map(SubDepartment => (
                        <option value={SubDepartment.field_id}>{SubDepartment.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_production_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>




                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Parameter Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_production_spinning_plan_parameter_name" className="erp_input_field" value={txt_production_spinning_plan_parameter_name} onChange={e => { setproductionspinningplanparametername(e.target.value); validateFields(); }} maxLength="500" disabled={keyForViewUpdate === 'update' ? true : false} />
                    <MDTypography variant="button" id="error_txt_production_spinning_plan_parameter_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">
                      Plan Calculation Type <span className="required">*</span>
                    </Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          id="chk_CalculationtypeManual"
                          label="Manual"
                          type="radio"
                          lbl="Manual"
                          value="Manual"
                          name="chk_Calculationtype"
                          checked={chk_Calculationtype === "Manual"}
                          onChange={() => handleCalculationTypeChange("Manual")}
                          onClick={() => setCalculationtype("Manual")}
                          disabled={keyForViewUpdate === "update"} // Disable if keyForViewUpdate is 'update'
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          id="chk_CalculationtypeAuto"
                          label="Auto"
                          type="radio"
                          lbl="Auto"
                          value="Auto"
                          name="chk_Calculationtype"
                          checked={chk_Calculationtype === "Auto"}
                          onChange={() => handleCalculationTypeChange("Auto")}
                          onClick={() => setCalculationtype("Auto")}
                          disabled={keyForViewUpdate === "update"} // Disable if keyForViewUpdate is 'update'
                        />
                      </div>
                    </div>
                  </div>
                </div>


                {
                  chk_Calculationtype === "Auto" ?
                    <>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <Form.Label className="erp-form-label"> Formula Plan Parameter 1 <span style={{ color: '#52E919' }}>(P1)</span> </Form.Label>
                        </div>
                        <div className='col'>
                          <select id="cmb_formula_plan_parameter1" className="form-select form-select-sm" value={cmb_formula_plan_parameter1} onChange={(e) => { setformulaplanparameter1(e.target.value); }} optional='optional'    >
                            <option value="">Select</option>
                            {formulaplanparameter1Options?.map(formulaparameter1 => (
                              <option value={formulaparameter1.field_id}>{formulaparameter1.field_name}</option>
                            ))}
                          </select>
                          <MDTypography variant="button" id="error_cmb_formula_plan_parameter1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                    </> : ""
                }

                {
                  chk_Calculationtype === "Auto" ?
                    <>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <Form.Label className="erp-form-label"> Formula Plan Parameter 2 <span style={{ color: '#52E919' }}>(P2)</span>  </Form.Label>
                        </div>
                        <div className='col'>
                          <select id="cmb_formula_plan_parameter2" className="form-select form-select-sm" value={cmb_formula_plan_parameter2} onChange={(e) => { setformulaplanparameter2(e.target.value); validateFields(); }} optional='optional'   >
                            <option value="">Select</option>
                            {formulaplanparameter2Options?.map(formulaparameter2 => (
                              <option value={formulaparameter2.field_id}>{formulaparameter2.field_name}</option>
                            ))}
                          </select>
                          <MDTypography variant="button" id="error_cmb_formula_plan_parameter2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                    </> : ""
                }





              </div>
              {/* second column */}
              <div className="col-sm-6 erp_form_col_div">
                {
                  chk_Calculationtype === "Auto" ?
                    <>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <Form.Label className="erp-form-label"> Formula Plan Parameter 3 <span style={{ color: '#52E919' }}>(P3)</span> </Form.Label>
                        </div>
                        <div className='col'>
                          <select id="cmb_formula_plan_parameter3" className="form-select form-select-sm" value={cmb_formula_plan_parameter3} onChange={(e) => { setformulaplanparameter3(e.target.value); validateFields(); }} optional='optional'   >
                            <option value="">Select</option>
                            {formulaplanparameter3Options?.map(formulaparameter3 => (
                              <option value={formulaparameter3.field_id}>{formulaparameter3.field_name}</option>
                            ))}
                          </select>
                          <MDTypography variant="button" id="error_cmb_formula_plan_parameter3" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                    </> : ""
                }

                {
                  chk_Calculationtype === "Auto" ?
                    <>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <Form.Label className="erp-form-label">Formula Plan Parameter  4 <span style={{ color: '#52E919' }}>(P4)</span>  </Form.Label>
                        </div>
                        <div className='col'>
                          <select id="cmb_formula_plan_parameter4" className="form-select form-select-sm" value={cmb_formula_plan_parameter4} onChange={(e) => { setformulaplanparameter4(e.target.value); validateFields(); }} optional='optional'   >
                            <option value="">Select</option>
                            {formulaplanparameter4Options?.map(formulaparameter4 => (
                              <option value={formulaparameter4.field_id}>{formulaparameter4.field_name}</option>
                            ))}
                          </select>
                          <MDTypography variant="button" id="error_cmb_formula_plan_parameter4" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div> </> : ""

                }
                {
                  chk_Calculationtype === "Auto" ?
                    <><div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label"> Formula Plan Parameter 5<span style={{ color: '#52E919' }}>(P5)</span>  </Form.Label>
                      </div>
                      <div className='col'>
                        <select id="cmb_formula_plan_parameter5" className="form-select form-select-sm" value={cmb_formula_plan_parameter5} onChange={(e) => { setformulaplanparameter5(e.target.value); validateFields(); }} optional='optional'    >
                          <option value="">Select</option>
                          {formulaplanparameter5Options?.map(formulaparameter5 => (
                            <option value={formulaparameter5.field_id}>{formulaparameter5.field_name}</option>
                          ))}
                        </select>
                        <MDTypography variant="button" id="error_cmb_formula_plan_parameter5" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                    </> : ""
                }

                {
                  chk_Calculationtype === "Auto" ?
                    <>

                      <div className="row">
                        <div className="col-sm-4">
                          <Form.Label className="erp-form-label">Formula {chk_Calculationtype === 'Auto' ? <span className="required">*</span> : ''}  </Form.Label>
                          <Form.Label class="MuiTypography-root MuiTypography-button erp-form-label-md-lg css-tidaut-MuiTypography-root" style={{ color: '#52E919' }}>(Use Formula parameter as P1, P2 for creation Formula) <span ></span></Form.Label>
                        </div>
                        <div className="col-sm-8">
                          <Form.Control as="textarea" rows={3} className="erp_txt_area" id="txt_Formula" value={txt_Formula} onChange={e => { setFormula(e.target.value); validateFields(); }} maxLength="255" optional={chk_Calculationtype === 'Formula' ? '' : 'optional'} />
                          <MDTypography variant="button" id="error_txt_Formula" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </> : ""
                }
                {
                  chk_Calculationtype === "Manual" ?
                    <>
                      <div className="row">
                        <div className="col-sm-4">
                          <Form.Label className="erp-form-label">Parameter Value {chk_Calculationtype === 'Manual' ? <span className="required">*</span> : ''}</Form.Label>
                        </div>
                        <div className="col">
                          <Form.Control type="number" className="erp_input_field text-end " id="txt_production_plan_value" value={txt_production_plan_value} onChange={e => { setproduction_plan_value(validateNumberDateInput.current.decimalNumber((e.target.value).toString(), 4)); validateFields(); }} maxLength="23" />
                          <MDTypography variant="button" id="error_txt_production_plan_value" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </> : ""
                }

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Parameter Sequance<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_production_spinning_plan_parameter_sequance" className="erp_input_field" value={txt_production_spinning_plan_parameter_sequance} onChange={e => { setproductionspinningplanparametersequance(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); validateFields(); }} maxLength="500" disabled />
                    <MDTypography variant="button" id="error_txt_production_spinning_plan_parameter_sequance" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* spinning plan parameter Active Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">is Active</Form.Label>
                  </div>
                  <div className="col">

                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check className="erp_radio_button" label="Yes" type="radio" value="1" name="isproductionspinningplanparameterActive" defaultChecked />
                      </div>
                      <div className="sCheck">
                        <Form.Check className="erp_radio_button" label="No" value="0" type="radio" name="isproductionspinningplanparameterActive" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* </div> */}

          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing/reg' : '/Masters/MXMProductionSpinningPlanParameter/FrmMProductionSpinningPlanParameterListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular">Back</MDButton>
            {/* fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton> */}

            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={AddData} id="btn_save" className="erp-gb-button erp_MLeft_btn" variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}
          </div >
        </div>

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />




        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >

          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            {displayRecordComponent()}
          </Modal.Body>

        </Modal >
      </div>
    </>
  )
}

export default FrmMProductionSpinningPlanParameterEntry

