import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";

function PFChallanRegister() {

    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // combo's options hooks;
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);

    // Input field's Hooks;
    const [cmb_year, setYear] = useState('');
    const [cmb_month, setMonth] = useState('');
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');

    // Hooks For table;
    const [pfChallanDtlTblData, setPFChallanDtlTblData] = useState([]);

    // UseRefs Hooks For Searchable combos.
    let monthComboRef = useRef(null);
    let yearComboRef = useRef(null);
    let employeeTypesComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillCombos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillCombos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setYear(defaultYear.value);
            yearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setMonth(defaultMonth.value);
            monthComboRef.current = defaultMonth;

            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeType')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnCombosOnChange = async (comboName, comboVal) => {
        try {
            setIsLoading(true);

            switch (comboName) {
                case "Department":
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    await FnSetDefaultData();
                    break;

                case "SubDepartment":
                    await FnSetDefaultData();
                    break;

                case "EmployeeType":
                    await FnSetDefaultData();
                    break;

                default:
                    break;
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const FnSetDefaultData = async () => {
        try {
            // Filters for load employee query.
            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
                { field: "pf_flag", operator: "=", value: 1 },
            ];

            // First Set Default Department.
            let selectedDepartment = departmentComboRef.current?.department_id;
            if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
            } else if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
                let defaultDept = departmentOpts.find(department => department.value == 'All');
                setDepartmentId(defaultDept.value);
                departmentComboRef.current = defaultDept;

                // Also Load All Sub-Department
                const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
                selectedDepartment = '';
            }

            // Second Set Default Sub-Department.
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
            } else {
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
            }

            // Third Set Default Employee Type.
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
            } else {
                let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                setEmployeeTypeId(defaultEmplType.value);
                employeeTypesComboRef.current = defaultEmplType;
                let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                    ?.map(item => item?.field_name)
                    ?.filter(id => id !== undefined && id !== null && id !== '');;
                filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
            }

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name"];
            globalQuery.table = "cmv_employee"
            globalQuery.conditions = filterConditions;
            let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            if (getEmployeeList.length > 0) {
                getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
                getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
                setEmployeeOpts(getEmployeeList);
                setEmployeeId('All');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            } else {
                setEmployeeOpts([]);
                setEmployeeId('');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // ****-------Get PF Challan Data Functions Starts--------------***
    const FnGetPFChallanDetailsData = async () => {
        try {
            let isValidFilters = await FnChkIsValidFilters();
            if (!isValidFilters) { return false; }


            // *** Means Filters are valid then filter data as per the filters.
            setIsLoading(true);
            let filterConditions = [
                { field: "empl.pf_flag", operator: "=", value: 1 },
                { field: "empl.company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "empl.is_delete", operator: "=", value: 0 },
            ];

            // *** Apply Filters Conditions;
            let selectedDepartment = departmentComboRef.current?.department_id;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            let selectedEmployee = employeeComboRef.current.employee_id;

            // Check it selected one employee or not? 
            if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
                filterConditions.push({ field: "empl.employee_id", operator: "=", value: selectedEmployee });
            } else {
                // Means load data without employee filter.
                // If Valid Then set the default data if not selected any filter.
                await FnSetDefaultData();

                if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                    filterConditions.push({ field: "empl.department_id", operator: "=", value: selectedDepartment });
                }
                if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                    filterConditions.push({ field: "empl.sub_department_id", operator: "=", value: selectedSubDepartment });
                }
                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    filterConditions.push({ field: "empl.employee_type", operator: "=", value: selectedEmplType });
                }
            }

            resetGlobalQuery();
            globalQuery.columns = [
                `empl.employee_name`, `empl.uan_no`,
                `(SELECT IFNULL(e.earning_amount, 0) from ht_salary_earnings e WHERE e.earning_heads_id = 2 and e.employee_id = empl.employee_id and e.salary_month = ${cmb_month} and e.salary_year = ${cmb_year} and e.is_delete = 0) as gross_wages`,
                `(SELECT IFNULL(d1.deduction_amount, 0) from ht_salary_deduction d1 WHERE d1.deduction_heads_id = 1 and d1.employee_id = empl.employee_id and d1.salary_month = ${cmb_month} and d1.salary_year = ${cmb_year} and d1.is_delete = 0) as epf_contri_remitted`,
                `(SELECT IFNULL(d2.deduction_amount, 0) from ht_salary_deduction d2 WHERE d2.deduction_heads_id = 10 and d2.employee_id = empl.employee_id and d2.salary_month = ${cmb_month} and d2.salary_year = ${cmb_year} and d2.is_delete = 0) as eps_contri_remitted`,
                `(SELECT IFNULL(d3.deduction_amount, 0) from ht_salary_deduction d3 WHERE d3.deduction_heads_id = 11 and d3.employee_id = empl.employee_id and d3.salary_month = ${cmb_month} and d3.salary_year = ${cmb_year} and d3.is_delete = 0) as epf_eps_diff_remitted`,
                `0 as ncp_days`,
                `0 as refund_of_advance`,
            ];
            globalQuery.table = "cmv_employee empl";
            globalQuery.conditions = filterConditions;
            globalQuery.orderBy = ['empl.department_id DESC', 'empl.sub_department_id DESC', 'empl.employee_code Asc'];
            let receivedPFChallanData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            if (receivedPFChallanData.length > 0) {
                receivedPFChallanData = receivedPFChallanData.map((record, index) => ({
                    ...record,
                    sr_no: index + 1,
                    epf_wages: record.gross_wages,
                    eps_wages: record.gross_wages,
                    edli_wages: record.gross_wages,

                    // gross_wages: Math.round(record.gross_wages),
                    // epf_wages: Math.round(record.gross_wages),
                    // eps_wages: Math.round(record.gross_wages),
                    // edli_wages: Math.round(record.gross_wages),

                    // epf_contri_remitted: Math.round(record.epf_contri_remitted),
                    // eps_contri_remitted: Math.round(record.eps_contri_remitted),
                    // epf_eps_diff_remitted: Math.round(record.epf_eps_diff_remitted),

                    // ncp_days: Math.round(record.ncp_days),
                    // refund_of_advance: Math.round(record.refund_of_advance),
                }));
                setPFChallanDtlTblData(receivedPFChallanData);
                console.table(receivedPFChallanData);
            } else {
                setPFChallanDtlTblData([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnRenderPFChallanDetailsTbl = useMemo(() => {
        if (pfChallanDtlTblData.length === 0) { return null; }

        return (
            <>
                <style>
                    {`
                @media print {
                    .print-content {
                        padding: 0;
                        box-sizing: border-box;
                    }
                    @page {
                        margin: 0;
                        size: A4;
                        margin: 2%;
                        border: 2px solid black;
                    }
                    html, body {
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                }
                .erp_table {
                    font-size: 3px !important; /* Default font size */
                }
                .erp_table_th, .erp_table_td {
                    border: 1px solid black;
                    padding-top: 2px !important;
                    padding-bottom: 2px !important;
                }
            `}
                </style>

                <Table className="erp_table" id='PFChallanDtlsTbl' style={{ whiteSpace: 'normal' }} ref={printComponentRef}>
                    <thead className="erp_table_head thead-light text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th">Sr. No.</th>
                            <th className="erp_table_th">UAN No</th>
                            <th className="erp_table_th">Member Name</th>

                            <th className="erp_table_th">Gross Wages</th>
                            <th className="erp_table_th">EPF Wages</th>
                            <th className="erp_table_th">EPS Wages</th>
                            <th className="erp_table_th">EDLI Wages</th>

                            <th className="erp_table_th">EPF Contri. Remitted</th>      {/* Means PF  12% */}
                            <th className="erp_table_th">EPS Contri. Remitted</th>      {/* Means EPF-1 8.33% */}
                            <th className="erp_table_th">EPF EPS Diff. Remitted</th>    {/* Means EPF-3 3.67% */}

                            <th className="erp_table_th">NCP Days</th>
                            <th className="erp_table_th">Refund Of Advance</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            pfChallanDtlTblData?.map((pfChallanRecord, index) => {
                                return (
                                    <tr key={index} rowIndex={index}>
                                        <td className="erp_table_td">{index + 1}</td>
                                        <td className="erp_table_td">{pfChallanRecord.uan_no}</td>
                                        <td className="erp_table_td">{pfChallanRecord.employee_name}</td>

                                        <td className="erp_table_td text-end">{pfChallanRecord.gross_wages}</td>
                                        <td className="erp_table_td text-end">{pfChallanRecord.epf_wages}</td>
                                        <td className="erp_table_td text-end">{pfChallanRecord.eps_wages}</td>
                                        <td className="erp_table_td text-end">{pfChallanRecord.edli_wages}</td>

                                        <td className="erp_table_td text-end">{pfChallanRecord.epf_contri_remitted}</td>
                                        <td className="erp_table_td text-end">{pfChallanRecord.eps_contri_remitted}</td>
                                        <td className="erp_table_td text-end">{pfChallanRecord.epf_eps_diff_remitted}</td>

                                        <td className="erp_table_td text-end">{pfChallanRecord.ncp_days}</td>
                                        <td className="erp_table_td text-end">{pfChallanRecord.refund_of_advance}</td>
                                    </tr>
                                )
                            })
                        }

                        {
                            // Footer Summary Row.
                            <tr className='table-light' key={pfChallanDtlTblData.length + 1} rowIndex={pfChallanDtlTblData.length + 1}>
                                <th className="erp_table_th" colSpan={2}>Grand Totals: </th>
                                <th className="erp_table_th text-end">{pfChallanDtlTblData.length}</th>

                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.gross_wages, 0).toFixed(2)} </th>
                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.epf_wages, 0).toFixed(2)} </th>
                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.eps_wages, 0).toFixed(2)} </th>
                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.edli_wages, 0).toFixed(2)} </th>

                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.epf_contri_remitted, 0).toFixed(2)} </th>
                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.eps_contri_remitted, 0).toFixed(2)} </th>
                                <th className="erp_table_th text-end" > {pfChallanDtlTblData.reduce((sum, summary) => sum + summary.epf_eps_diff_remitted, 0).toFixed(2)} </th>

                                <th className="erp_table_th text-end" > {0} </th>
                                <th className="erp_table_th text-end" > {0} </th>
                            </tr>
                        }

                    </tbody>
                </Table>
            </>
        )
    }, [pfChallanDtlTblData])


    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }

    // Get Current DateTime as "DD-MM-YYYY HH:mm:ss"
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date();
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }

    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = async () => {
        if (cmb_year === '') {
            $('#error_cmb_year').text('Please select the atleast one...!')
            $('#error_cmb_year').show();
            return false;
        } else {
            $('#error_cmb_year').hide();
        }

        if (cmb_month === '') {
            $('#error_cmb_month').text('Please select the atleast one...!')
            $('#error_cmb_month').show();
            return false;
        } else {
            $('#error_cmb_month').hide();
        }
        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setPFChallanDtlTblData([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(cmb_month)) { $('#error_dt_att_to').hide(); }
        if (isEmpty(cmb_year)) { $('#error_dt_att_from').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    // ****-------Validation Functions Ends--------------***


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintReport = useReactToPrint({
        content: () => {
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Provident Fund Challan For ${monthComboRef.current?.label}-${yearComboRef.current?.value}`
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1 border-bottom border-dark'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'> Provident Fund Challan </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                For Month: {monthComboRef.current?.label}   Year: {yearComboRef.current?.value}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************


    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (pfChallanDtlTblData.length === 0) { return null; }

        setIsLoading(true);
        // Preparing Data For ExportFile.
        let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

        // Excel Headers;
        jsonToExportExcel['headings'] = {
            'ReportName': `Provident Fund Challan`,
            'CompanyName': COMPANY_NAME,
            'CompanyAddress': sessionStorage.getItem('companyAddress'),
        }

        // Applied Filters.
        let appliedFilters = {
            '0': `Year: ${yearComboRef.current?.label || ""}`,
            '1': `Month: ${monthComboRef.current?.label}(${monthComboRef.current?.value || ""})`,
        };
        addExcelFilter(departmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Department', appliedFilters);
        addExcelFilter(subDepartmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Sub Department', appliedFilters);
        addExcelFilter(employeeTypesComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Employee Type', appliedFilters);
        appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
        jsonToExportExcel['filtrKeyValue'] = appliedFilters;

        // Details Table Columns and Accessors.
        jsonToExportExcel['columns'] = [
            { "Headers": 'Sr No', "accessor": 'sr_no' },
            { "Headers": 'UAN No', "accessor": 'uan_no' },
            { "Headers": 'Member Name', "accessor": 'employee_name' },
            { "Headers": 'Gross Wages', "accessor": 'gross_wages' },
            { "Headers": 'EPF Wages', "accessor": 'epf_wages' },
            { "Headers": 'EPS Wages', "accessor": 'eps_wages' },
            { "Headers": 'EDLI Wages', "accessor": 'edli_wages' },
            { "Headers": 'EPF Contri. Remitted', "accessor": 'epf_contri_remitted' },
            { "Headers": 'EPS Contri. Remitted', "accessor": 'eps_contri_remitted' },
            { "Headers": 'EPF EPS Diff. Remitted', "accessor": 'epf_eps_diff_remitted' },
            { "Headers": 'NCP Days', "accessor": 'ncp_days' },
            { "Headers": 'Refund Of Advance', "accessor": 'refund_of_advance' },
        ]
        // Data For Table.
        pfChallanDtlTblData.map((challanRecord, Index) => {
            challanRecord.sr_no = Index + 1;
            jsonToExportExcel['allData'][Index] = challanRecord;

            // Check if it is the last item then push the last sum-of-totals object.
            if (Index === pfChallanDtlTblData.length - 1) {
                jsonToExportExcel['allData'][pfChallanDtlTblData.length] = {
                    sr_no: '',
                    uan_no: 'Grand Totals: ',
                    employee_name: Index + 1,
                    gross_wages: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.gross_wages, 0)),
                    epf_wages: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.epf_wages, 0)),
                    eps_wages: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.eps_wages, 0)),
                    edli_wages: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.edli_wages, 0)),
                    epf_contri_remitted: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.epf_contri_remitted, 0)),
                    eps_contri_remitted: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.eps_contri_remitted, 0)),
                    epf_eps_diff_remitted: parseFloat(pfChallanDtlTblData.reduce((sum, summary) => sum + summary.epf_eps_diff_remitted, 0)),
                    ncp_days: 0,
                    refund_of_advance: 0,
                }
            }

        });
        await exlsExp.current.excel(jsonToExportExcel, `Provident_Fund_Challan_${monthComboRef.current?.label}-${yearComboRef.current?.value}`);

        setIsLoading(false);
    }
    // ********* ExportToExcel Functionalities Ends. ****************************************


    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />


            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">PF Challan Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={yearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_year"
                                            value={yearOpts.find(option => option.value == cmb_year) || null}
                                            onChange={(selectedOpt) => {
                                                setYear(selectedOpt.value);
                                                yearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={monthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_month"
                                            value={monthOpts.find(option => option.value == cmb_month) || null}
                                            onChange={(selectedOpt) => {
                                                setMonth(selectedOpt.value);
                                                monthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>


                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("SubDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnCombosOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center me-1`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnGetPFChallanDetailsData(); }}
                                >Show Data</MDButton>
                            </div>
                        </div>

                        {/* Attendance Details Table */}
                        {
                            pfChallanDtlTblData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <> <hr />

                                    <div className="row py-1">
                                        <div className="text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintReport(); }} >Print </MDButton>
                                        </div>
                                    </div>

                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">PF Challan Details</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                {FnRenderPFChallanDetailsTbl}
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                {
                                    pfChallanDtlTblData.length === 0
                                        ? null
                                        : <>
                                            {
                                                pfChallanDtlTblData.length > 50
                                                    ? <>
                                                        <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                                            className={`erp-gb-button float-center`}
                                                            onClick={(e) => { goBack(); }}  > Back </MDButton>

                                                        <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-2"
                                                            onClick={(e) => { FnPrintReport(); }} >Print</MDButton>
                                                    </>
                                                    : null
                                            }

                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout>


        </>
    )
}

export default PFChallanRegister