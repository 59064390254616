import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useNavigate, useLocation } from "react-router-dom";


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// Imports React bootstrap
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import ConfigConstants from "assets/Constants/config-constant";

function MFrmProductionProcessEntry() {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName, COMPANY_BRANCH_ID } = configConstants;

  var activeValue = '';

  const child = useRef();
  // For navigate
  const navigate = useNavigate();
  // Add Product Type Form Fields
  const validate = useRef();
  const comboDataFunc = useRef();

  const [ParentProcessOptions, setcmb_productionParentProcess] = useState([]);
  const [chk_production_process_work, setproduction_process_work] = useState('In House');
  const [txt_Process_Short_Name, settxt_Process_Short_Name] = useState('');
  const [txt_Process_Std_Scrap_Percent, settxt_Process_Std_Scrap_Percent] = useState('');
  const [txt_process_std_production_hrs, settxt_process_std_production_hrs] = useState('');
  const [txt_production_process_sequance, settxt_production_process_sequance] = useState('');
  const [txt_Process_Name, settxt_Process_Name] = useState('');
  const [cmb_productionParentProcess, setproduction_parent_process] = useState('');
  const [chk_production_process_type, setproduction_process_type] = useState('Main');

  const [actionType, setActionType] = useState('')


  const { state } = useLocation();
  const { productionProcessId = 0, keyForViewUpdate, compType = 'Masters' } = state || {};


  const handleRadioChange = (event) => {
    setproduction_process_type(event.target.value);
    fillComobos();
    if ((event.target.value) === 'Main') {
      setproduction_parent_process('')
    }
  };


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const validateNumberDateInput = useRef();

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/MProductionProcess/MFrmProductionProcessListing`);
    }
  }

  useEffect(() => {
    const functionCall = async () => {
      await ActionType();
      await fillComobos();
      if (productionProcessId !== 0) {
        await FnCheckUpdateResponce();

      }

    }
    functionCall()
  }, [])



  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');


  const FnCheckUpdateResponce = async () => {
    try {
      if (productionProcessId !== "undefined" && productionProcessId !== '' && productionProcessId !== null) {

        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionProcess/FnShowParticularRecordForUpdate/${productionProcessId}/${COMPANY_ID}`)
        const updateRes = await apiCall.json();

        let resp = (updateRes.data)

        setproduction_process_type(resp.production_process_type);
        setproduction_parent_process(resp.production_parent_process_id);
        setproduction_process_work(resp.production_process_work);
        settxt_Process_Short_Name(resp.production_process_short_name);
        settxt_Process_Name(resp.production_process_name)
        settxt_Process_Std_Scrap_Percent(resp.production_process_std_scrap_percent);
        settxt_process_std_production_hrs(resp.production_process_std_production_hrs);
        settxt_production_process_sequance(resp.production_process_sequance);
        // setcmb_productionParentProcess(resp.Production_process_sequance)


        $('#saveBtn').show();

        switch (resp.is_active) {
          case true:
            document.querySelector('input[name="isactive"][value="1"]').checked = true;
            break;
          case false:
            document.querySelector('input[name="isactive"][value="0"]').checked = true;
            break;
          default: break;
        }

        // switch (keyForViewUpdate) {
        //   case 'update':
        //     $('#txt_Process_Name').attr('disabled', true);
        //     $('#saveBtn').attr('disabled', false);
        //     break;
        //   case 'view':
        //     $("input[type=radio]").attr('disabled', true);
        //     await validate.current.readOnly("ProductionProcessId");
        //     break;
        //   default:
        //     break;
        // }

      }

    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const fillComobos = async () => {
    debugger
    try {
      // const productionprocess = await comboDataFunc.current.fillMasterDataWithOperator("xmv_production_process", "production_process_type", "=", "'Main'");
      // setcmb_productionParentProcess(productionprocess);

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "xmv_production_process"
      globalQuery.conditions.push({ field: "production_process_type", operator: "=", value: "Main" });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      const productionprocess = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
      setcmb_productionParentProcess(productionprocess);

      resetGlobalQuery();
      globalQuery.columns.push("MAX(production_process_sequance) as count");
      globalQuery.table = "xm_production_process"
      globalQuery.conditions.push({ field: "production_process_type", operator: "=", value: "Main" });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

      // globalQuery.conditions.push({ field: "production_process_sequance", operator: "=", value: parseInt(maxcount) });
      const getmaxcountproductionParentProcessApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
      settxt_production_process_sequance(getmaxcountproductionParentProcessApiCall[0].count + 1);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'subSequenceCOunt':
        resetGlobalQuery();
        let subSequenceType = parseInt($('#cmb_productionParentProcess').val());
        globalQuery.columns.push("MAX(production_process_sequance) as count");
        globalQuery.table = "xmv_production_process"
        globalQuery.conditions.push({ field: "production_parent_process_id", operator: "=", value: parseInt(subSequenceType) });
        globalQuery.conditions.push({ field: "production_process_type", operator: "=", value: "Sub" });
        // globalQuery.conditions.push({ field: "production_process_sequance", operator: "=", value: parseInt(maxcount) });
        const getmaxcountproductionParentProcessApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
        settxt_production_process_sequance(getmaxcountproductionParentProcessApiCall[0].count + 1);


    }
  }



  const handleSubmit = async () => {
    debugger
    try {
      const checkIsValidate = await validate.current.validateForm("ProductionProcessId");
      if (checkIsValidate === true) {
        var active;
        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
          default: break;
        }
        const data = {
          company_id: COMPANY_ID,
          production_process_id: productionProcessId,
          created_by: UserName,
          modified_by: productionProcessId === 0 ? null : UserName,
          company_branch_id: COMPANY_BRANCH_ID,
          production_process_type: chk_production_process_type,
          production_parent_process_id: cmb_productionParentProcess === "" ? 0 : cmb_productionParentProcess,
          production_process_work: chk_production_process_work,
          production_process_name: txt_Process_Name,
          production_process_short_name: txt_Process_Short_Name.trim(),
          production_process_std_scrap_percent: txt_Process_Std_Scrap_Percent,
          production_process_std_production_hrs: txt_process_std_production_hrs,
          production_process_sequance: txt_production_process_sequance,
          is_active: active,


        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionProcess/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.success === 0) {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataFunc.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();

        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };

  const clearFormFields = () => {
    setproduction_process_type('');
    setcmb_productionParentProcess('');
    setproduction_process_work('');
    settxt_Process_Short_Name('');
    settxt_Process_Name('');
    settxt_Process_Std_Scrap_Percent('');
    settxt_process_std_production_hrs('');
    settxt_production_process_sequance('');

  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('ProductionProcessId')
  }



  const handleProcessStdproductionhrs = (e) => {
    const inputValue = e.target.value;
    if (inputValue === null || inputValue === '') {
      settxt_process_std_production_hrs('');
    } else {
      // Regular expression to validate numeric input with up to two decimal places
      const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (regex.test(inputValue)) {
        settxt_process_std_production_hrs(inputValue);
      }
    }
  };



  const ActionType = async () => {
    switch (keyForViewUpdate) {

      case 'update':
        setActionType('(Modification)');
        $('#txt_Process_Name').attr('disabled', true);
        $('#txt_Process_Short_Name').attr('disabled', true);
        $('#cmb_productionParentProcess').attr('disabled', true);
        $('#saveBtn').attr('disabled', false);
        $('[name="chk_production_process_type"]').attr('disabled', true)

        break;

      case 'view':
        setActionType('(View)');
        $("input[type=radio]").attr('disabled', true);
        await validate.current.readOnly("ProductionProcessId");
        break;

      default:
        setActionType('(Creation)');
        break;
    }

  };


  return (
    <>

      <ComboBox ref={child} />
      <FrmValidations ref={validate} />
      <ComboBox ref={comboDataFunc} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <div className='erp_top_Form'>
        <div className='card p-1'>

          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Production Process  {actionType}  </label>
          </div>

          {/* <div className='card mt-3'> */}
          <form id="ProductionProcessId">
            <div className="row erp_transporter_div">

              {/* first row */}


              <div className="col-sm-6 erp_filter_group-by-result">

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">  Production Process Type<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check className="erp_radio_button" id="chk_production_process_type_Main" label="Main" type="radio" value="Main" name="chk_production_process_type" checked={chk_production_process_type === "Main"} onChange={handleRadioChange} />
                      </div>
                      <div className="sCheck"><Form.Check className="erp_radio_button" id="chk_production_process_type_Sub" label="Sub" type="radio" value="Sub" name="chk_production_process_type" checked={chk_production_process_type === "Sub"} onChange={handleRadioChange} />
                      </div>
                    </div>
                  </div>
                </div>

                {
                  chk_production_process_type === 'Sub' ?
                    <>
                      <div className="row">
                        <div className="col-sm-4">
                          <Form.Label className="erp-form-label">Production Parent Process<span className="required">*</span></Form.Label>
                        </div>
                        <div className="col">
                          <select id="cmb_productionParentProcess" className="form-select form-select-sm" value={cmb_productionParentProcess} onChange={(e) => { setproduction_parent_process(e.target.value); comboOnChange('subSequenceCOunt'); }} disabled={(keyForViewUpdate === 'view') || (keyForViewUpdate === 'update')}  >
                            <option value="">Select</option>
                            {ParentProcessOptions?.map(parentOption => (
                              <option value={parentOption.field_id}>{parentOption.field_name}</option>

                            ))}

                          </select>
                          <MDTypography variant="button" id="error_cmb_productionParentProcess" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>


                    </>
                    : ""
                }


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Process Work<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck"> <Form.Check className="erp_radio_button" label="Both" type="radio" lbl="Both" value="Both" name="chk_production_process_work" checked={chk_production_process_work === "Both"} onClick={() => setproduction_process_work("Both")} /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" label="In House" type="radio" lbl="In House" value="In House" name="chk_production_process_work" checked={chk_production_process_work === "In House"} onClick={() => setproduction_process_work("In House")} /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" label="Out Source" type="radio" lbl="Out Source" value="Out Source" name="chk_production_process_work" checked={chk_production_process_work === "Out Source"} onClick={() => setproduction_process_work("Out Source")} /> </div>

                    </div>

                  </div>

                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Process Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_Process_Name" className="erp_input_field" value={txt_Process_Name} onChange={e => { settxt_Process_Name(e.target.value); validateFields(); }} maxLength="500" />
                      <MDTypography variant="button" id="error_txt_Process_Name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Process Short Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_Process_Short_Name" className="erp_input_field" value={txt_Process_Short_Name} onChange={e => { settxt_Process_Short_Name(e.target.value.toUpperCase()); validateFields(); }} maxLength="20" />
                      <MDTypography variant="button" id="error_txt_Process_Short_Name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>
              </div>

              {/* second row */}

              <div className="col-sm-6 erp_filter_group-by-result">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Std. Scrap (%) </Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control
                        type="number"
                        id="txt_Process_Std_Scrap_Percent"
                        className="erp_input_field text-end"
                        value={txt_Process_Std_Scrap_Percent}
                        onChange={(e) => {
                          const inputValue = e.target.value; if (validateNumberDateInput.current.percentValidate(inputValue)) { settxt_Process_Std_Scrap_Percent(inputValue); }
                          validateFields();
                        }}
                        maxLength="5" optional='optional'
                      />
                      <MDTypography variant="button" id="error_txt_Process_Std_Scrap_Percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Std. Production Hrs </Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_process_std_production_hrs" className="erp_input_field text-end" value={txt_process_std_production_hrs} onChange={e => { handleProcessStdproductionhrs(e); validateNumberDateInput.current.decimalNumber(e.target.value, 2); validateFields(); }} disabled={keyForViewUpdate === 'view'} maxLength="8" optional='optional' />
                      <MDTypography variant="button" id="error_txt_process_std_production_hrs" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>



                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Process Sequance </Form.Label>
                  </div>
                  <div className="col">

                    <div className='col'>
                      <Form.Control type="text" id="txt_production_process_sequance" className="erp_input_field text-end" value={txt_production_process_sequance} onChange={e => { settxt_production_process_sequance(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); validateFields(); }} maxLength="500" optional='optional' />
                      <MDTypography variant="button" id="error_txt_production_process_sequance" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/MProductionProcess/MFrmProductionProcessListing/reg' : '/Masters/MProductionProcess/MFrmProductionProcessListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular">Back</MDButton>

            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}

          </div>

          {/* </div > */}
        </div>

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
      </div>

    </>
  )
}

export default MFrmProductionProcessEntry




