import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
// Imports React bootstrap
import Form from "react-bootstrap/Form";
// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from "FrmGeneric/FrmValidations";


function FrmProductProcessEntry(props) {
    var activeValue = "";
    //changes by ujjwala on 6/1/2024
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { productProcessID = 0, keyForViewUpdate, compType } = state || {}

    const navigate = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Combo Options
    const [parentProcessNameOptions, setParentProcessNameOptions] = useState([]);
    // Product process form fields
    const [product_process_id, setProductProcessId] = useState(productProcessID);
    const [product_process_type, setProductProcessType] = useState('Main');
    const [cmb_product_parent_process_id, setProductParentProcessId] = useState('');
    const [txt_product_process_name, setProductProcessName] = useState('');
    const [txt_product_process_short_name, setProductProcessShortName] = useState('');
    const [txt_product_process_std_production_hrs, setProductProcessSTDProductionHrs] = useState();
    const [txt_product_process_std_scrap_percent, setProductProcessSTDScrapPercent] = useState('');
    const [txt_remark, setRemark] = useState('')
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')
    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        if (sessionStorage.getItem("dataAddedByCombo") !== "dataAddedByCombo") {
            navigate(`/Masters/FrmMProductProcessListing`);
        }
        setShowSuccessMsgModal(false);
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await fillComobos();
            if (productProcessID !== 0) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_product_process_name').attr('disabled', true)
                $("input[type=radio][name=product_process_type]").attr('disabled', true);
                $('#txt_product_process_short_name').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("productProcessForm");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const fillComobos = async () => {
        const parentProcessTpApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_process", "", "")
        setParentProcessNameOptions(parentProcessTpApiCall)
    }



    function onRadioBtnChange(event) {
        const changedRadioBtn = event.target.name;
        switch (changedRadioBtn) {
            case 'productProcessType':
                setProductProcessType(event.target.value);
                if (event.target.value === '1') {
                    setProductParentProcessId('');
                }
                break;
        }
    }



    const comboOnChange = async (key) => {
        debugger
        switch (key) {
            case "productparentprocess":
                var selectedParentProcessId = document.getElementById('cmb_product_parent_process_id').value;

                setProductParentProcessId(selectedParentProcessId);
                if (selectedParentProcessId !== '0' && selectedParentProcessId !== "") {
                    $("#error_cmb_product_type_id").hide();

                }
                break;
            default:
                break;
        }
    };


    const handleSubmit = async () => {
        try {
            const checkIsValidate = await validate.current.validateForm("productProcessForm");
            if (checkIsValidate) {
                var active;
                activeValue = document.querySelector('input[name=isProductProcessActive]:checked').value

                switch (activeValue) {
                    case '0': active = false; break;
                    case '1': active = true; break;
                }

                const data = {
                    company_id: COMPANY_ID,
                    product_process_id: product_process_id,
                    created_by: UserName,
                    modified_by: product_process_id === 0 ? null : UserName,
                    product_process_type: product_process_type,
                    product_parent_process_id: product_process_type === 'Main' ? '' : cmb_product_parent_process_id,
                    product_process_name: txt_product_process_name,
                    product_process_short_name: txt_product_process_short_name,
                    product_process_std_production_hrs: txt_product_process_std_production_hrs,
                    product_process_std_scrap_percent: txt_product_process_std_scrap_percent,
                    remark: txt_remark,
                    is_active: active
                };
                console.log("Product Process From Information before saving: " + JSON.stringify(data));
                const method = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PmProductProcess/FnAddUpdateRecord`, method)
                const responce = await apiCall.json();
                console.log("response error: ", responce.data);
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('productProcessForm')
    }

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PmProductProcess/FnShowParticularRecordForUpdate/${product_process_id}`)
            const updateRes = await apiCall.json();
            // setProductProcessId(updateRes.data.product_process_id);
            setProductProcessType(updateRes.data.product_process_type);
            setProductParentProcessId(updateRes.data.product_parent_process_id);
            setProductProcessName(updateRes.data.product_process_name);
            setProductProcessShortName(updateRes.data.product_process_short_name);
            setProductProcessSTDProductionHrs(updateRes.data.product_process_std_production_hrs);
            setProductProcessSTDScrapPercent(updateRes.data.product_process_std_scrap_percent);
            setRemark(updateRes.data.remark);

            switch (updateRes.data.is_active) {
                case true:
                    document.querySelector('input[name="isProductProcessActive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isProductProcessActive"][value="0"]').checked = true;
                    break;
            }
            // document.querySelector('input[name="productProcessType"][value="' + selectedProcessType + '"]').checked = true;
        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }

    const validateNo = (key) => {
        const numCheck = /^[0-9]*\.?[0-9]*$/;
        const regexNo = /^[0-9]*\.[0-9]{4}$/
        var dot = '.';
        switch (key) {
            case 'pFromRange':
                var pFromRangeVal = $('#txt_product_process_std_production_hrs').val();
                if (!regexNo.test(pFromRangeVal) && pFromRangeVal.length <= 8 || pFromRangeVal.indexOf(dot) === 8) {
                    if (numCheck.test(pFromRangeVal)) {
                        setProductProcessSTDProductionHrs(pFromRangeVal)
                    }

                }
                break;
        }
    }
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <FrmValidations ref={validate} />

            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Product Process Information {actionType} </label>
                    </div>

                    <form id="productProcessForm">
                        <div className="row erp_transporter_div">

                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Process Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Main" type="radio" lbl="Main" value="Main" id="product_process_type" name="product_process_type" checked={product_process_type === "Main"} onClick={() => { setProductProcessType("Main"); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Sub" type="radio" lbl="Sub" value="Sub" id="product_process_type" name="product_process_type" checked={product_process_type === "Sub"} onClick={() => { setProductProcessType("Sub"); }} /> </div>
                                        </div>
                                    </div>
                                </div>

                                {product_process_type === "Sub" ?
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Parent Process Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select size="sm" id="cmb_product_parent_process_id" value={cmb_product_parent_process_id} className="form-select form-select-sm erp_input_field" onChange={() => comboOnChange("productparentprocess")}>
                                                <option value="">Select</option>
                                                {parentProcessNameOptions?.map(parentDepart => (
                                                    <option value={parentDepart.field_id}>{parentDepart.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_parent_process_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    : ''
                                }

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Process Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_process_name" className="erp_input_field" value={txt_product_process_name} onChange={(e) => { setProductProcessName(e.target.value); validateFields(); }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_product_process_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Process Short Name<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_process_short_name" className="erp_input_field" value={txt_product_process_short_name} onChange={(e) => { setProductProcessShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="20" />
                                        <MDTypography variant="button" id="error_txt_product_process_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* Second column   */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Std. Production Hrs. </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_process_std_production_hrs" className="erp_input_field text-end" value={txt_product_process_std_production_hrs} onChange={e => { validateNo('pFromRange'); }} optional='optional' maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_product_process_std_production_hrs" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Standard Scrap Percentage (%)</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id="txt_product_process_std_scrap_percent" className="erp_input_field" value={txt_product_process_std_scrap_percent} onChange={e => { isNumeric(e); }} maxLength="5" optional='optional' />
                                        <MDTypography variant="button" id="error_txt_product_process_std_scrap_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}

                                {/* ....................... */}

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Process Std. Scrap (%)<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className='col'>

                                            <Form.Control
                                                type="number"
                                                id="txt_product_process_std_scrap_percent"
                                                className="erp_input_field text-end"
                                                value={txt_product_process_std_scrap_percent}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (validateNumberDateInput.current.percentValidate(inputValue)) {
                                                        setProductProcessSTDScrapPercent(inputValue);
                                                    }
                                                    validateFields();
                                                }}
                                                maxLength="5"
                                            />
                                            <MDTypography variant="button" id="error_txt_product_process_std_scrap_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>
                                {/* ............... */}

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); }} maxLength="255" optional='optional' />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Is Active </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form>
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button" label="Yes" type="radio" value="1" name="isProductProcessActive" defaultChecked />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button" label="No" value="0" type="radio" name="isProductProcessActive" />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        </div >
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/FrmMProductProcessListing/reg' : '/Masters/FrmMProductProcessListing';
                                navigate(path);
                            }}
                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}
                        >Back</MDButton>
                        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >

                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


            </div>
        </>
    );
}

export default FrmProductProcessEntry;
