import React from 'react'
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// import react icons
import { useNavigate } from "react-router-dom";
// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from 'assets/Constants/config-constant';
import FrmValidations from 'FrmGeneric/FrmValidations';

export default function FrmMProductMaterialMeasureParametersEntry(props) {
  var activeValue = '';
  //changes by ujjwala on 5/1/2024 case no .2 start
  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;
  const { state } = useLocation();
  const { productmaterialmeasureparametersID = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

  const comboDataAPiCall = useRef();
  const validate = useRef();
  //end by ujjwala

  const [productTypeOptions, setProductTypeOptions] = useState([]);
  // Add Product Type Form Fields

  const [product_material_measure_parameter_id, setProductmaterialmeasureparametersID] = useState(productmaterialmeasureparametersID);
  const [cmb_product_type_id, setProductTypeId] = useState();
  const [txt_product_material_measure_parameter_name, setProductMaterialMeasureParameterName] = useState('');
  const [txt_product_material_measure_parameter_short_name, setsetProductMaterialMeasureParameterShortName] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    await fillComobos()
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')

    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigate(`/Masters/ProductMaterialMeasureParametersListing`);
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // For navigate
  const navigate = useNavigate();
  //changes by ujjwala on 5/1/2024 case no .2 start
  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      await fillComobos();
      if (productmaterialmeasureparametersID !== null) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])

  const ActionType = async () => {

    switch (keyForViewUpdate) {

      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $('#txt_product_material_measure_parameter_name').attr('disabled', true);
        $('#cmb_product_type_id').attr('disabled', true)
        $('#txt_product_material_measure_parameter_short_name').attr('disabled', true)
        break;

      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productmaterialmeasureparameterFormId");
        $("input[type=radio]").attr('disabled', true);
        break;

      default:
        setActionType('(Creation)');
        break;

    }

  };
  //end by ujjwala
  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        }
        setProductTypeId(productTpVal)
        break;
    }
  }

  const fillComobos = async () => {
    const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    setProductTypeOptions(productTypeApiCall)

  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("productmaterialmeasureparameterFormId");
      if (checkIsValidate === true) {
        var active;
        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
        }
        const data = {
          company_id: COMPANY_ID,
          product_material_measure_parameter_id: product_material_measure_parameter_id,
          product_type_id: cmb_product_type_id,
          created_by: UserName,
          modified_by: product_material_measure_parameter_id === 0 ? null : UserName,
          product_material_measure_parameter_name: txt_product_material_measure_parameter_name,
          product_material_measure_parameter_short_name: txt_product_material_measure_parameter_short_name,
          remark: txt_remark,
          is_active: active,
        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productrmmeasureparameter/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  };

  const clearFormFields = () => {
    setProductTypeId('');
    setProductMaterialMeasureParameterName('');
    setsetProductMaterialMeasureParameterShortName('');
    setRemark('');

  }

  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productrmmeasureparameter/FnShowParticularRecordForUpdate/${product_material_measure_parameter_id}`)
      const updateRes = await apiCall.json();
      let resp = JSON.parse(updateRes.data)
      await fillComobos();
      setProductmaterialmeasureparametersID(resp.product_material_measure_parameter_id);
      setProductTypeId(resp.product_type_id);
      setProductMaterialMeasureParameterName(resp.product_material_measure_parameter_name);
      setsetProductMaterialMeasureParameterShortName(resp.product_material_measure_parameter_short_name);
      setRemark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }
  //changes by ujjwala on 5/1/2024 case no .2 start
  const validateFields = () => {
    validate.current.validateFieldsOnChange('productmaterialmeasureparameterFormId')
  }
  //end

  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validate} />
      {/* //changes by ujjwala on 5/1/2024 case no .2 start  */}
      <div className="erp_top_Form">
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Material Measure Parameter Infomation{actionType} </label>
          </div>
          <form id="productmaterialmeasureparameterFormId">
            <div className="row erp_transporter_div">

              {/* first row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record +</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>
                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Measure Parameter Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_material_measure_parameter_name" className="erp_input_field" value={txt_product_material_measure_parameter_name} onChange={e => { setProductMaterialMeasureParameterName(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_material_measure_parameter_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_material_measure_parameter_short_name" className="erp_input_field" value={txt_product_material_measure_parameter_short_name} onChange={e => { setsetProductMaterialMeasureParameterShortName(e.target.value.toUpperCase()); }} maxLength="4" optional="optional" />
                    <MDTypography variant="button" id="error_txt_product_material_measure_parameter_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* second row */}
              <div className="col-sm-6">
                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </form>
          {/* //changes by ujjwala on 5/1/2024 case no .2 start */}
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductMaterialMeasureParametersListing/reg' : '/Masters/ProductMaterialMeasureParametersListing';
                navigate(path);
              }}

              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
            <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>
          </div >
        </div>

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            <FrmMProductTypeEntry btn_disabled={true} />
          </Modal.Body>
        </Modal >
        {/* end by ujjwala */}
        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
      </div>
    </>
  )
}
