import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const SupplierPurchasePaymentInvoice = React.forwardRef(({ invoiceContent }, ref) => {
    const data = invoiceContent.items
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;
    let AFTER_DECIMAL_PLACES = 2;


    function numberToWordsWithCurrency(number) {

        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }

    const totalBillingAmount = invoiceContent.footer.total_billing_amount;
    const totalBillingDeduction = invoiceContent.footer.total_billing_deduction;
    const totalPaidAmount = invoiceContent.footer.total_paid_amount;


    const totalBillingAmountWords = numberToWordsWithCurrency(totalBillingAmount);
    const totalBillingDeductionWords = numberToWordsWithCurrency(totalBillingDeduction);
    const totalPaidAmountWords = numberToWordsWithCurrency(totalPaidAmount);

    console.log(`Billing Amount Total: ${totalBillingAmountWords}`);
    console.log(`Deduction Amount Total: ${totalBillingDeductionWords}`);
    console.log(`Paid Amount Total: ${totalPaidAmountWords}`);

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-2">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-10">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {invoiceContent.company.company}
                        </div>
                        <div className='erp-invoice-print-label-md-lg  text-center'>
                            {/* {invoiceContent.company.address} */}
                            <span className='erp-invoice-print-label-md-lg'> ({invoiceContent.company.company_branch},{invoiceContent.company.state_name}-{invoiceContent.company.company_pincode}) </span>
                        </div>
                        <div className='erp-invoice-print-label-lg text-center mt-1'>
                            Supplier Purchase Payment
                        </div>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg '> Purchase Payment No. & version : {invoiceContent.paymentDetails.supplier_purchase_payment_no} &  {invoiceContent.paymentDetails.supplier_purchase_payment_version}</dt>
                    </div>
                    {/* <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-md-lg '> Purchase Payment Type : {invoiceContent.paymentDetails.supplier_purchase_payment_type}</dt>
                    </div> */}
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end'> Purchase Payment Date : {invoiceContent.paymentDetails.supplier_purchase_payment_date}</dt>
                    </div>
                </div>
                <div className='row  border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <div className="col-sm-4 p-0 m-0">
                            <dl className="row m-0 p-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Supplier:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Email:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.supp_branch_EmailId}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Contact No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.contact}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-4 p-0 m-0">
                            <dl className="row m-0 p-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Bank Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.supplier_bank_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Account No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.supplier_bank_account_number}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>IFSC Code:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.supplier_bank_ifsc_code}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-4 p-0 m-0">
                            <dl className="row m-0 p-0">
                                <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Transaction Date:</dt>
                                <dd className='col-sm-6 erp-invoice-print-label'> {invoiceContent.supplierDetails.payment_transaction_date}</dd>
                                <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Payment Type:</dt>
                                <dd className='col-sm-6 erp-invoice-print-label'> {invoiceContent.supplierDetails.payment_type_desc}</dd>
                                <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Transaction Details:</dt>
                                <dd className='col-sm-6 erp-invoice-print-label'> {invoiceContent.supplierDetails.payment_transaction_details}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    const invoiceFooter = (
        <div className="invoice-footer">
            {/* <hr className='m-0' /> */}
            <div className='row'>
                <div className='col-sm-6'>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.company.company}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                {/* <div className="row"> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    {/* <div className="row" > */}
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {data.length !== 0 && (
                                                                <thead>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Sr No.</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Bill Booking No.</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Bill Booking Date</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Purchase Order No.</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Goods Receipt No.</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Supplier Challan No.</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Supplier Challan Date</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Billing Amount</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Billing Deduction</th>
                                                                        <th className="erp_invoice_table_th  text-center" rowspan={2}>Paid Amount</th>
                                                                    </tr>
                                                                </thead>
                                                            )}
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.supplier_bill_booking_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.supplier_bill_booking_date} </td>
                                                                                <td className="erp_invoice_table_td text-center">{item.purchase_order_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.goods_receipt_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.supplier_challan_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.supplier_challan_date}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_billing_amount}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_billing_deduction}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_paid_amount}</td>
                                                                            </tr>
                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    {/* Add blank rows to fill the space */}
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_td" colSpan={6}>
                                                                                            <div className='row m-0 p-0'>
                                                                                                <dt className="col-sm-5 erp-invoice-print-label-md-lg">Billing Amount(in words) :</dt>
                                                                                                <dd className="col-sm-7 erp-invoice-print-label">{totalBillingAmountWords}</dd>
                                                                                            </div>
                                                                                            <div className='row m-0 p-0'>
                                                                                                <dt className="col-sm-5 erp-invoice-print-label-md-lg">Billing Deduction Amount(in words) :</dt>
                                                                                                <dd className="col-sm-7 erp-invoice-print-label">{totalBillingDeductionWords}</dd>
                                                                                            </div>
                                                                                            <div className='row m-0 p-0'>
                                                                                                <dt className="col-sm-5 erp-invoice-print-label-md-lg">Paid Amount(in words) :</dt>
                                                                                                <dd className="col-sm-7 erp-invoice-print-label">{totalPaidAmountWords}</dd>
                                                                                            </div>
                                                                                            <div className='row m-0 p-0'>
                                                                                                <dt className="col-sm-5 erp-invoice-print-label-md-lg">Deduction Remark :</dt>
                                                                                                <dd className="col-sm-7 erp-invoice-print-label">{invoiceContent.footer.deduction_remark === '' ? '-' : invoiceContent.footer.deduction_remark}</dd>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="erp_invoice_table_td p-0 m-0" colSpan={4}>
                                                                                            <table className="table p-0 m-0 table-borderless">
                                                                                                <tbody>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Billing Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.total_billing_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg">Deduction Amount :</span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.total_billing_deduction).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    {['taxtype_name1', 'taxtype_name2'].map((taxtype, index) => (
                                                                                                        <tr key={index} className="border-bottom border-dark">
                                                                                                            <td className="pt-0 pb-0">
                                                                                                                <span className="erp-invoice-print-label-md-lg pt-0 pb-0">
                                                                                                                    {/* {invoiceContent.footer[taxtype] !== null ? invoiceContent.footer[taxtype] : `Taxtype name ${index + 1}:`}   */}
                                                                                                                    {invoiceContent.footer[taxtype] !== null ? `${invoiceContent.footer[taxtype]}: ` : `Taxtype ${index + 1}:`}
                                                                                                                    {invoiceContent.footer[taxtype] !== null ? <span className="erp-invoice-print-label-md-lg pt-0 pb-0">Percent: ({parseFloat(invoiceContent.footer[`tax${index + 1}_percent`]).toFixed(AFTER_DECIMAL_PLACES)}%) Amount :</span> : null}
                                                                                                                    {/* {invoiceContent.footer[taxtype] !== null ? `${invoiceContent.footer[taxtype]}: Percent: (${invoiceContent.footer[`tax${index + 1}_percent`]}) Amount :` : `Tax Deducted At Source: Percent: (${invoiceContent.footer[`tax${index + 1}_percent`]}) Amount :`} */}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            {invoiceContent.footer[taxtype] !== null ? (
                                                                                                                <>
                                                                                                                    {/* <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Percent: ({invoiceContent.footer[`tax${index + 1}_percent`]})  Amount :</span></td> */}
                                                                                                                    <td className="erp-invoice-print-label-md-lg text-end pt-0 pb-0">{parseFloat(invoiceContent.footer[`tax${index + 1}_amount`]).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <td className="erp-invoice-print-label-md-lg text-end pt-0 pb-0">nil</td>
                                                                                                            )}
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                    <tr className='border-0'>
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Total Paid Amount : </span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.total_paid_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})
export default SupplierPurchasePaymentInvoice;

