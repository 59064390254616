import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Imports React bootstrap
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from "FrmGeneric/FrmValidations";

function FrmMProductMaterialShapeEntry(props) {
    //changes by ujjwala on 5/1/2024 case no.1
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { productMaterialShapeId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

    // For navigate
    const navigate = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const [product_material_shape_id, setProductMaterialShapeId] = useState(productMaterialShapeId);
    // Combo Options
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [measureParameterOptions, setMeasureParameterOptions] = useState([]);

    //  Form Fields
    const [cmb_product_type_id, setProductTypeId] = useState();
    const [txt_product_material_shape_name, setProductMaterialShapeName] = useState();
    const [txt_product_material_shape_short_name, setProductMaterialShapeShortName] = useState();
    const [txt_product_material_shape_formula, setProductMaterialShapeFormula] = useState();
    const [txt_remark, setRemark] = useState();
    const [cmb_product_material_measure_parameter_id1, setProductMaterialMeasureParam1] = useState('');
    const [cmb_product_material_measure_parameter_id2, setProductMaterialMeasureParam2] = useState('');
    const [cmb_product_material_measure_parameter_id3, setProductMaterialMeasureParam3] = useState('');
    const [cmb_product_material_measure_parameter_id4, setProductMaterialMeasureParam4] = useState('');
    const [cmb_product_material_measure_parameter_id5, setProductMaterialMeasureParam5] = useState('');
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        navigate(`/Masters/ProductMaterialMeasureShapeListing`);
        setShowSuccessMsgModal(false);
    };

    //changes by ujjwala on 5/1/2024 case no.1
    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await fillComobos();
            if (productMaterialShapeId !== null) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])
    //changes by ujjwala on 5/1/2024 case no.1
    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')

                $('#cmb_product_type_id').attr('disabled', true)
                $('#txt_product_material_shape_name').attr('disabled', true)
                $('#txt_product_material_shape_short_name').attr('disabled', true)

                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("productMaterialShapeFrmId");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };
    //end by ujjwala
    const fillComobos = async () => {
        const productTypesOptionsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
        setProductTypeOptions(productTypesOptionsApiCall);
        if (cmb_product_type_id !== '' && cmb_product_type_id != 0 && cmb_product_type_id != null) {
            const measureParameterOptionsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_material_measure_parameter", "product_type_id", document.getElementById('cmb_product_type_id').value);
            setMeasureParameterOptions(measureParameterOptionsApiCall);
        }
    }

    const onCMBoxChange = async (e) => {
        var cmboxId = e.target.id;
        if (e.target.id.startsWith('cmb_product_material_measure_parameter_id')) {
            cmboxId = 'cmb_productMaterialParameters';
        }
        switch (cmboxId) {
            case 'cmb_product_type_id':

                if (e.target.value === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                if (e.target.value !== '') {
                    const measureParameterOptionsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_material_measure_parameter", "product_type_id", e.target.value)
                    setMeasureParameterOptions(measureParameterOptionsApiCall);
                    document.getElementById("cmb_product_material_measure_parameter_id1").disabled = false;

                } else {
                    setMeasureParameterOptions([]);
                }
                break;
            case 'cmb_productMaterialParameters':
                var nextCmboxCount = parseInt(e.target.id.slice(-1)) + 1;
                document.getElementById('cmb_product_material_measure_parameter_id' + nextCmboxCount).disabled = false;
                break;
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('productMaterialShapeFrmId')
    }

    const handleSubmit = async () => {
        debugger
        const checkIsValidate = await validate.current.validateForm("productMaterialShapeFrmId");

        let ShapeFormula = $('#txt_product_material_shape_formula').val().toLowerCase().trim();
        if (ShapeFormula !== '') {
            $('#error_txt_product_material_shape_formula').hide();
            for (let parameterCount = 1; parameterCount <= 5; parameterCount++) {
                $(`#error_cmb_product_material_measure_parameter_id${parameterCount}`).hide();
                let parameter = `p${parameterCount}`;
                let comboValue = $(`#cmb_product_material_measure_parameter_id${parameterCount}`).val();
                if (ShapeFormula.includes(parameter) && $(`#cmb_product_material_measure_parameter_id${parameterCount}`).val() === '') {
                    $(`#error_cmb_product_material_measure_parameter_id${parameterCount}`).text('This parameter is added in the formula but you not selected...!');
                    $(`#error_cmb_product_material_measure_parameter_id${parameterCount}`).show();
                    return false;
                } else if (comboValue !== '' && !ShapeFormula.includes(parameter)) {
                    $(`#error_cmb_product_material_measure_parameter_id${parameterCount}`).text(`You've selected ${parameter}, but it's not used in the formula.`);
                    $(`#error_cmb_product_material_measure_parameter_id${parameterCount}`).show();
                    return false;
                }
            }
        }
        else {
            $('#error_txt_product_material_shape_formula').text("Please insert the shape formula.");
            $('#error_txt_product_material_shape_formula').show();
            return;
        }

        if (checkIsValidate === true) {
            var isActive;
            isActive = document.querySelector('input[name=isProductMaterialShapeActive]:checked').value
            switch (isActive) {
                case '0': isActive = false; break;
                case '1': isActive = true; break;
            }
            const data = {
                company_id: COMPANY_ID,
                product_material_shape_id: product_material_shape_id,
                created_by: UserName,
                modified_by: product_material_shape_id === 0 ? null : UserName,
                product_type_id: cmb_product_type_id,
                product_material_shape_name: txt_product_material_shape_name,
                product_material_shape_short_name: txt_product_material_shape_short_name,
                product_material_shape_formula: txt_product_material_shape_formula,
                remark: txt_remark,
                product_material_measure_parameter_id1: cmb_product_material_measure_parameter_id1 !== "" ? cmb_product_material_measure_parameter_id1 : 0,
                product_material_measure_parameter_id2: cmb_product_material_measure_parameter_id2 !== "" ? cmb_product_material_measure_parameter_id2 : 0,
                product_material_measure_parameter_id3: cmb_product_material_measure_parameter_id3 !== "" ? cmb_product_material_measure_parameter_id3 : 0,
                product_material_measure_parameter_id4: cmb_product_material_measure_parameter_id4 !== "" ? cmb_product_material_measure_parameter_id4 : 0,
                product_material_measure_parameter_id5: cmb_product_material_measure_parameter_id5 !== "" ? cmb_product_material_measure_parameter_id5 : 0,
                is_active: isActive
            };
            console.log("Product Material Shape Information before saving: " + JSON.stringify(data));
            const method = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productmaterialshape/FnAddUpdateRecord`, method)
            const responce = await apiCall.json();
            console.log("response error: ", responce.data);
            if (responce.error !== "") {
                console.log("response error: ", responce.error);
                setErrMsg(responce.error)
                setShowErrorMsgModal(true)
            } else {
                const evitCache = comboDataAPiCall.current.evitCache();
                console.log(evitCache);
                setSuccMsg(responce.message);
                setShowSuccessMsgModal(true)
            }
        }
    };

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productmaterialshape/FnShowParticularRecordForUpdate/${product_material_shape_id}`)
            const updateRes = await apiCall.json();

            setProductMaterialShapeId(updateRes.data.product_material_shape_id);
            setProductTypeId(updateRes.data.product_type_id);

            if (updateRes.data.product_type_id !== '' && updateRes.data.product_type_id != null) {
                const measureParameterOptionsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_material_measure_parameter", "product_type_id", updateRes.data.product_type_id.toString())
                setMeasureParameterOptions(measureParameterOptionsApiCall);
            }
            setProductMaterialShapeName(updateRes.data.product_material_shape_name);
            setProductMaterialShapeShortName(updateRes.data.product_material_shape_short_name);
            setProductMaterialShapeFormula(updateRes.data.product_material_shape_formulla);
            setRemark(updateRes.data.remark);
            setProductMaterialMeasureParam1(updateRes.data.product_material_measure_parameter_id1);
            setProductMaterialMeasureParam2(updateRes.data.product_material_measure_parameter_id2);
            setProductMaterialMeasureParam3(updateRes.data.product_material_measure_parameter_id3);
            setProductMaterialMeasureParam4(updateRes.data.product_material_measure_parameter_id4);
            setProductMaterialMeasureParam5(updateRes.data.product_material_measure_parameter_id5);

            switch (updateRes.data.is_active) {
                case true:
                    document.querySelector('input[name="isProductMaterialShapeActive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isProductMaterialShapeActive"][value="0"]').checked = true;
                    break;
            }

        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }

    // Show ADd record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {

            case 'Product Type':
                const productTypesOptionsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
                setProductTypeOptions(productTypesOptionsApiCall);

                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Product Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;

            default:
                return null;
        }
    }

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />
            <div className='erp_top_Form'>
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Product Material Shape Information{actionType} </label>
                    </div>
                    <form id="productMaterialShapeFrmId">
                        <div className="row erp_transporter_div">

                            {/* First column  */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Product Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={e => { setProductTypeId(e.target.value); onCMBoxChange(e); validateFields(); }}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>

                                            {productTypeOptions?.map(productTypeOption => (
                                                <option value={productTypeOption.field_id}>{productTypeOption.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Shape Name<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_material_shape_name" className="erp_input_field" value={txt_product_material_shape_name} onChange={e => { setProductMaterialShapeName(e.target.value); validateFields(); }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_product_material_shape_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Shape Short Name<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_product_material_shape_short_name" className="erp_input_field" value={txt_product_material_shape_short_name} onInput={e => { setProductMaterialShapeShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" />
                                        <MDTypography variant="button" id="error_txt_product_material_shape_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Measure Parameter (P1) </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_product_material_measure_parameter_id1" className="form-select form-select-sm" value={cmb_product_material_measure_parameter_id1} onChange={e => { setProductMaterialMeasureParam1(e.target.value); onCMBoxChange(e); }} optional='optional' >

                                            <option value="">Select</option>
                                            {measureParameterOptions?.map(measureParameterOption => (
                                                <option value={measureParameterOption.field_id}>{measureParameterOption.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_material_measure_parameter_id1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Measure Parameter (P2) </Form.Label>
                                    </div>
                                    <div className="col">
                                        {/* <select id="cmb_product_material_measure_parameter_id2" className="form-select form-select-sm" value={cmb_product_material_measure_parameter_id2} onChange={e => { setProductMaterialMeasureParam2(e.target.value); onCMBoxChange(e); }} optional='optional' disabled={cmb_product_material_measure_parameter_id2 === '' ? "disabled" : cmb_product_material_measure_parameter_id2 == null ? "disabled" : ""}> */}
                                        <select id="cmb_product_material_measure_parameter_id2" className="form-select form-select-sm" value={cmb_product_material_measure_parameter_id2} onChange={e => { setProductMaterialMeasureParam2(e.target.value); onCMBoxChange(e); }} optional='optional' >
                                            <option value="">Select</option>
                                            {measureParameterOptions?.map(measureParameterOption => (
                                                <option value={measureParameterOption.field_id}>{measureParameterOption.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_material_measure_parameter_id2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Measure Parameter (P3) </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_product_material_measure_parameter_id3" className="form-select form-select-sm" value={cmb_product_material_measure_parameter_id3} onChange={e => { setProductMaterialMeasureParam3(e.target.value); onCMBoxChange(e); }} optional='optional' >
                                            <option value="">Select</option>
                                            {measureParameterOptions?.map(measureParameterOption => (
                                                <option value={measureParameterOption.field_id}>{measureParameterOption.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_material_measure_parameter_id3" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Measure Parameter (P4)  </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_product_material_measure_parameter_id4" className="form-select form-select-sm" value={cmb_product_material_measure_parameter_id4} onChange={e => { setProductMaterialMeasureParam4(e.target.value); onCMBoxChange(e); }} optional='optional'>
                                            <option value="">Select</option>
                                            {measureParameterOptions?.map(measureParameterOption => (
                                                <option value={measureParameterOption.field_id}>{measureParameterOption.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_material_measure_parameter_id4" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Measure Parameter (P5)  </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_product_material_measure_parameter_id5" className="form-select form-select-sm" value={cmb_product_material_measure_parameter_id5} onChange={e => { setProductMaterialMeasureParam5(e.target.value); }} optional='optional' >
                                            <option value="">Select</option>
                                            {measureParameterOptions?.map(measureParameterOption => (
                                                <option value={measureParameterOption.field_id}>{measureParameterOption.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_material_measure_parameter_id5" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* Second column   */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Shape Formula </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id="txt_product_material_shape_formula" rows={3} className="erp_txt_area" value={txt_product_material_shape_formula} onInput={e => { setProductMaterialShapeFormula(e.target.value); }} maxlength="500" optional='optional' />
                                        <MDTypography variant="button" id="error_txt_product_material_shape_formula" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Remark </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxLength="255" optional='optional' />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Is Active </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form>
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button" label="Yes" type="radio" value="1" name="isProductMaterialShapeActive" defaultChecked />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button" label="No" value="0" type="radio" name="isProductMaterialShapeActive" />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>

                    {/* Form Actions Btns */}

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/ProductMaterialMeasureShapeListing/reg' : '/Masters/ProductMaterialMeasureShapeListing';
                                navigate(path);
                            }}
                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}
                        >Back</MDButton>
                        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>

                {/* Msg modals */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}

                    </Modal.Body>
                </Modal >

            </div>
        </>
    );
}

export default FrmMProductMaterialShapeEntry;
