import React from 'react'
import underConstruction from 'assets/images/under-construction.jpg'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'

function FrmSalesInvoiceServicesListing() {
  return (
    <>
      <DashboardLayout>
        <div className='text-center'>
          <img src={underConstruction} alt='Under Construction' height='500px' width ='1000px'  />
        </div>
      </DashboardLayout>
    </>
  )
}

export default FrmSalesInvoiceServicesListing
