import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

function WorkerDepartmentWiseSalaryRegister() {

    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // combo's options hooks;
    const [employeeProps, setEmployeeProps] = new useState([]);
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);

    // Input field's Hooks;
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
    const [cmb_salary_month, setSalaryMonth] = useState('');
    const [cmb_salary_year, setSalaryYear] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');

    // Hooks For table;
    const [earningHeads, setEarningHeads] = useState([]);
    const [deductionHeads, setDeductionHeads] = useState([]);
    const [monthlySalarySummaryDtlTblData, setMonthlySalarySummaryDtlTblData] = useState([]);
    const [monthlySalaryEarningDtlTblData, setMonthlySalaryEarningDtlTblData] = useState([]);
    const [monthlySalaryDeductionDtlTblData, setMonthlySalaryDeductionDtlTblData] = useState([]);

    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillComobos = async () => {
        try {
            // Set Default Year;
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month;
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;

            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "property_group", operator: "=", value: "W" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeType')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnComboOnChange = async (comboName) => {
        try {
            switch (comboName) {
                case "Department":
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    // ****-------Get Salary Data Functions Starts--------------***
    const FnShowSalaryData = async () => {
        try {
            let isValidFilters = await FnChkIsValidFilters();
            if (!isValidFilters) { return false; }

            // Earning Heads To not display;
            const excludedEarningHeadsCols = ["Salary", "Dearness Allowance", "HRA", "Conveyance Allowance", "Special Allowance",
                "Incentive Allow", "Arrear Paid", "Travelling Allowance", "Medical Allowance", "Communication Allowance",
                "Education Allowance", "Performance Bonus", "Referral Allowance", "LTA", "Gratuity", "Bonus", "Over Time",
                "C-OFF Allowance"];
            // Deduction Heads To not display;
            const excludedDeductionHeadsCols = ['E-PF1', 'E-PF2', 'TDS', 'Income Tax'];

            // *** Means Filters are valid then filter data as per the filters.
            setIsLoading(true);
            let selectedEmplGrp = employeeProps.find(prop => prop.properties_master_name === 'EmployeeTypeGroup' && prop.property_value === employeeTypesComboRef.current.property_group);
            // Load Earning Heads
            resetGlobalQuery();
            globalQuery.columns = ["earning_heads_id", "earning_head_name", "head_position", "earning_head_short_name"];
            globalQuery.table = "hm_earning_heads";
            globalQuery.conditions.push({ field: "earning_group", operator: "IN", values: ['All', 'Workers'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["head_position Asc"];
            const rcvdEarningHeads = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            let filteredEarningHeads = rcvdEarningHeads.filter(earning => !excludedEarningHeadsCols.includes(earning.earning_head_name));
            setEarningHeads(filteredEarningHeads);

            // Load Deduction Heads
            resetGlobalQuery();
            globalQuery.columns = ["deduction_heads_id", "deduction_head_name", "head_position", "deduction_head_short_name"];
            globalQuery.table = "hm_deduction_heads";
            globalQuery.conditions.push({ field: "deduction_group", operator: "IN", values: ['All', 'Workers'] });
            globalQuery.conditions.push({ field: "deduction_head_name", operator: "NOT IN", values: ['E-PF1', 'E-PF2'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["head_position Asc"];
            const rcvdDeductionHeads = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            let filteredDeductionHeads = rcvdDeductionHeads.filter(deduction => !excludedDeductionHeadsCols.includes(deduction.deduction_head_name));
            setDeductionHeads(filteredDeductionHeads);

            let selectedYear = salaryYearComboRef.current?.value || null;
            let selectedMonth = salaryMonthComboRef.current?.value || null;
            let selectedDepartment = departmentComboRef.current?.department_id || null;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
            let selectedEmplType = employeeTypesComboRef.current?.field_name || null;

            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
                { field: "salary_year", operator: "=", value: selectedYear },
                { field: "salary_month", operator: "=", value: selectedMonth },
            ];

            if (selectedDepartment !== null && selectedDepartment !== '') {
                filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
            } else {
                let defaultDept = departmentOpts.find(department => department.value == 'All');
                setDepartmentId(defaultDept.value);
                departmentComboRef.current = defaultDept;
            }

            if (selectedSubDepartment !== null && selectedSubDepartment !== '') {
                filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
            } else {
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
            }

            if (selectedEmplType !== null && selectedEmplType !== 'All') {
                filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
            } else {
                let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                setEmployeeTypeId(defaultEmplType.value);
                employeeTypesComboRef.current = defaultEmplType;
                let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All").map(item => item?.field_name);
                filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
            }

            // Get the Salary Summary Data;
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_year", "salary_month", "department_name", "sub_department_name", "employee_type", "salary_perday",
                "employee_name", "employee_code", "designation_name", "month_days", "salary_days", "monthly_salary",
                "gross_salary", "total_deduction", "net_salary", "salary_transaction_id", "employee_id",
                "department_id", "sub_department_id", "designation_id", "company_id", "company_branch_id",
            ];
            globalQuery.table = "htv_salary_summary";
            globalQuery.conditions = filterConditions;
            globalQuery.orderBy = ['department_id DESC', 'sub_department_id DESC', 'employee_code Asc'];
            const recvdSummaryData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setMonthlySalarySummaryDtlTblData(recvdSummaryData);

            // Get the Salary Earning Data;
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_earnings_id", "company_id", "company_branch_id", "salary_transaction_id",
                "salary_version", "salary_month", "salary_year", "department_id", "sub_department_id",
                "employee_type", "employee_id", "designation_id", "earning_heads_id", "earning_amount"
            ];
            globalQuery.table = "ht_salary_earnings";
            globalQuery.conditions = filterConditions;
            const recvdEarningData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setMonthlySalaryEarningDtlTblData(recvdEarningData);

            // Get the Salary Deduction Data;
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_deduction_id", "company_id", "company_branch_id", "salary_transaction_id",
                "salary_version", "salary_month", "salary_year", "department_id", "sub_department_id",
                "employee_type", "employee_id", "designation_id", "deduction_heads_id", "deduction_amount"
            ];
            globalQuery.table = "ht_salary_deduction";
            globalQuery.conditions = filterConditions;
            const recvdDeductionData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setMonthlySalaryDeductionDtlTblData(recvdDeductionData);

            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    // ****-------Get Salary Data Functions Ends--------------***

    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = async () => {
        if (cmb_salary_year === '') {
            $('#error_cmb_salary_year').text('Please select the atleast one...!')
            $('#error_cmb_salary_year').show();
            return false;
        } else {
            $('#error_cmb_salary_year').hide();
        }

        if (cmb_salary_month === '') {
            $('#error_cmb_salary_month').text('Please select the atleast one...!')
            $('#error_cmb_salary_month').show();
            return false;
        } else {
            $('#error_cmb_salary_month').hide();
        }
        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setMonthlySalarySummaryDtlTblData([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) { str = str.toString(); }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
        if (isEmpty(salaryMonthComboRef.current?.value)) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
    }

    // ****-------Validation Functions Ends--------------***

    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }
    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date(); // Get the current date and time
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintMonthlyDeptWiseSalarySheet = useReactToPrint({
        content: () => {
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Department Wise Worker Salary Sheet For ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Department Wise Worker Salary Sheet
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************

    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (monthlySalarySummaryDtlTblData.length === 0) { return null; }
        setIsLoading(true);

        // Preparing Data For ExportFile.
        let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

        // Excel Headers;
        jsonToExportExcel['headings'] = {
            'ReportName': `Department Wise Staff Salary Sheet ${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`,
            'CompanyName': COMPANY_NAME,
            'CompanyAddress': sessionStorage.getItem('companyAddress'),
        }

        let appliedFilters = {
            '0': `Year: ${salaryYearComboRef.current?.label || ""}`,
            '1': `Month: ${salaryMonthComboRef.current?.label}(${salaryMonthComboRef.current?.value || ""})`,
        };
        addExcelFilter(departmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Department', appliedFilters);
        addExcelFilter(subDepartmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Sub Department', appliedFilters);
        addExcelFilter(employeeTypesComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Employee Type', appliedFilters);
        appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
        jsonToExportExcel['filtrKeyValue'] = appliedFilters;

        // Details Table Columns and Accessors.
        const earningsCols = earningHeads.map(({ earning_head_name }) => ({ "Headers": earning_head_name, "accessor": earning_head_name })); // Earning Headers & Accessors
        const deductionsCols = deductionHeads.map(({ deduction_head_name }) => ({ "Headers": deduction_head_name, "accessor": deduction_head_name })); // Deductions Headers & Accessors
        jsonToExportExcel['columns'] = [
            { "Headers": 'Sr. No.', "accessor": 'sr_no' },
            { "Headers": 'Department', "accessor": 'department_name' },
            { "Headers": 'Sub Department', "accessor": 'sub_department_name' },
            { "Headers": 'Employee Count', "accessor": 'employee_count' },
            ...earningsCols,
            { "Headers": 'Gross Salary', "accessor": 'gross_salary' },
            ...deductionsCols,
            { "Headers": 'Total Deduction', "accessor": 'total_deduction' },
            { "Headers": 'Net Salary', "accessor": 'net_salary' }
        ];

        // Extract unique objects by sub_department_id using reduce
        const uniqueDepartmentObjects = Object.values(
            monthlySalarySummaryDtlTblData.reduce((acc, item) => {
                acc[item.sub_department_id] = item;
                return acc;
            }, {})
        );

        uniqueDepartmentObjects?.map((subDept, index) => {
            let filteredSummmary = monthlySalarySummaryDtlTblData.filter(detail => detail.sub_department_id === subDept.sub_department_id);
            let filteredEarnings = monthlySalaryEarningDtlTblData.filter(detail => detail.sub_department_id === subDept.sub_department_id);
            let filteredDeductions = monthlySalaryDeductionDtlTblData.filter(detail => detail.sub_department_id === subDept.sub_department_id);

            earningHeads.forEach(earningType => {
                const currentEarnings = filteredEarnings.filter(earning => earning.earning_heads_id === earningType.earning_heads_id);
                subDept[earningType.earning_head_name] = currentEarnings.reduce((sum, earning) => sum + earning.earning_amount, 0).toFixed(2)
            });

            deductionHeads.forEach(deductionType => {
                const currentDeductions = filteredDeductions.filter(deduction => deduction.deduction_heads_id === deductionType.deduction_heads_id);
                subDept[deductionType.deduction_head_name] = currentDeductions.reduce((sum, deduction) => sum + deduction.deduction_amount, 0).toFixed(2)
            });

            jsonToExportExcel['allData'][index] = {
                ...subDept,
                sr_no: index + 1,
                department_name: subDept.department_name,
                sub_department_name: subDept.sub_department_name,
                employee_count: filteredSummmary.length,
                gross_salary: filteredSummmary.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2),
                total_deduction: filteredSummmary.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2),
                net_salary: filteredSummmary.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2),
            }
        })

        // Call Common function for ExportToExcel
        await exlsExp.current.excel(jsonToExportExcel, `DepartmentWise_Staff_Salary_Sheet_Of_${salaryMonthComboRef.current?.label}-${salaryYearComboRef.current?.value}`);
        setIsLoading(false);
    }
    // ********* ExportToExcel Functionalities Ends. ****************************************


    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay">
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Worker's Department-Wise Salary Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryYearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_salary_year"
                                            value={yearOpts.find(option => option.value == cmb_salary_year) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryYear(selectedOpt.value);
                                                salaryYearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryMonthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_salary_month"
                                            value={monthOpts.find(option => option.value == cmb_salary_month) || null}
                                            onChange={(selectedOpt) => {
                                                setSalaryMonth(selectedOpt.value);
                                                salaryMonthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnComboOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowSalaryData(); }} variant="button" fontWeight="regular">show Data</MDButton>
                            </div>
                        </div>

                        {
                            monthlySalarySummaryDtlTblData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <>
                                    <hr />

                                    <div className="row py-1">
                                        <div className="text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintMonthlyDeptWiseSalarySheet(); }} >Print</MDButton>
                                        </div>
                                    </div>

                                    {/* <div className="row mx-0 print-content" ref={printComponentRef}>
                                        <DepartmentWiseSalaryTbl
                                            earningHeads={earningHeads}
                                            deductionHeads={deductionHeads}
                                            summaryData={monthlySalarySummaryDtlTblData}
                                            earningsDtls={monthlySalaryEarningDtlTblData}
                                            deductionsDtls={monthlySalaryDeductionDtlTblData}
                                        />
                                    </div> */}
                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">Salary Details</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                <DepartmentWiseSalaryTbl
                                                    earningHeads={earningHeads}
                                                    deductionHeads={deductionHeads}
                                                    summaryData={monthlySalarySummaryDtlTblData}
                                                    earningsDtls={monthlySalaryEarningDtlTblData}
                                                    deductionsDtls={monthlySalaryDeductionDtlTblData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    monthlySalarySummaryDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintMonthlyDeptWiseSalarySheet(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout>
        </>
    )
}
export default WorkerDepartmentWiseSalaryRegister;



export const DepartmentWiseSalaryTbl = ({
    earningHeads,
    deductionHeads,
    summaryData,
    earningsDtls,
    deductionsDtls
}) => {
    const uniqueSubDepartments = useMemo(() => {
        return summaryData.reduce((unique, current) => {
            if (!unique.some(item => item.sub_department_id === current.sub_department_id)) {
                unique.push(current);
            }
            return unique;
        }, []);
    }, [summaryData]);

    return (
        <>
            <style>
                {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        @page {
                            margin: 0;
                            size: legal landscape;
                            margin: 1%;
                            border: 2px solid black;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important; /* Default font size */
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
            </style>

            <Table className="erp_table" id='DepartmentWiseWorkerSalaryDtlsTbl' style={{ whiteSpace: 'normal' }} >
                <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }}>
                    <tr style={{ whiteSpace: 'normal' }}>
                        <th className="erp_table_th align-middle" style={{ width: '10px' }}>Sr. No.</th>
                        <th className="erp_table_th align-middle">Department</th>
                        <th className="erp_table_th align-middle" style={{ width: '20px' }}>Employee Count</th>
                        <th className="erp_table_th align-middle" style={{ width: '20px' }}>PerDay</th>
                        {/* Earning Head Names */}
                        {
                            earningHeads?.map((earningType, index) => (<> <th className="erp_table_th align-middle" style={{ width: '3%' }}>{earningType.earning_head_name}</th> </>))
                        }
                        <th className="erp_table_th align-middle" style={{ width: '70px' }}>Total Earning</th>
                        {/* Deduction Heads Cols */}
                        {
                            deductionHeads?.map((deductionType, index) => (<> <th className="erp_table_th align-middle" style={{ width: '3%' }}>{deductionType.deduction_head_name}</th> </>))
                        }
                        <th className="erp_table_th align-middle" style={{ width: '70px' }}>Total Deduction</th>
                        <th className="erp_table_th align-middle" style={{ width: '70px' }}>Net Salary</th>
                        <th className="erp_table_th pe-3 align-middle" style={{ width: '100px' }}>Signature</th>
                    </tr>

                </thead>
                <tbody>
                    {uniqueSubDepartments.map((subDepartment, index) => {
                        debugger
                        const subDepartmentId = subDepartment.sub_department_id;
                        const filteredSummmary = summaryData.filter(item => item.sub_department_id === subDepartmentId);
                        const filteredEarnings = earningsDtls.filter(item => item.sub_department_id === subDepartmentId);
                        const filteredDeductions = deductionsDtls.filter(item => item.sub_department_id === subDepartmentId);

                        return (
                            <React.Fragment key={subDepartmentId}>
                                <tr>
                                    <td className="erp_table_td">{index + 1}</td>
                                    <td className="erp_table_td">
                                        {subDepartment.sub_department_name}
                                        <br /> {`[${subDepartment.department_name}]`}
                                    </td>
                                    <td className="erp_table_td text-end">{filteredSummmary.length}</td>
                                    <td className="erp_table_td text-end">{subDepartment.salary_perday}</td>

                                    {/* Earning Sum */}
                                    {
                                        earningHeads?.map((earningType, index) => {
                                            const sumAmount = filteredEarnings.reduce((sum, earning) => {
                                                return earning.earning_heads_id === earningType.earning_heads_id
                                                    ? sum + earning.earning_amount : sum;
                                            }, 0);
                                            return (<td key={`summary-earning-${index}`} className="erp_table_td text-end" > {sumAmount.toFixed(2)} </td>);
                                        })
                                    }
                                    <td className="erp_table_td text-end"> {filteredSummmary.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2)} </td>
                                    {/* Deduction Sum */}
                                    {
                                        deductionHeads?.map((deductionType, index) => {
                                            const sumAmount = filteredDeductions.reduce((sum, deduction) => {
                                                return deduction.deduction_heads_id === deductionType.deduction_heads_id
                                                    ? sum + deduction.deduction_amount : sum;
                                            }, 0);
                                            return (<td key={`summary-deduction-${index}`} className="erp_table_td text-end" > {sumAmount.toFixed(2)} </td>);
                                        })
                                    }
                                    <td className="erp_table_td text-end">{filteredSummmary.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2)}</td>
                                    <td className="erp_table_td text-end"> {filteredSummmary.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)} </td>
                                    <td className="erp_table_td"></td>
                                </tr>
                            </React.Fragment>
                        );
                    })}

                    {/* Footer Summary Row  */}
                    <tr className='table-light'>
                        <td className="erp_table_td align-middle" colSpan={4}><strong>All Department Summary:</strong> </td>
                        {/* Earning Sum */}
                        {
                            earningHeads?.map((earningType, index) => {
                                const sumAmount = earningsDtls.reduce((sum, earning) => {
                                    return earning.earning_heads_id === earningType.earning_heads_id
                                        ? sum + earning.earning_amount : sum;
                                }, 0);
                                return (<th key={`summary-earning-${index}`} className="erp_table_th text-end" > {sumAmount.toFixed(2)} </th>);
                            })
                        }
                        <th className="erp_table_th text-end">
                            {summaryData.reduce((sum, summary) => sum + summary.gross_salary, 0).toFixed(2)}
                        </th>

                        {/* Deduction Sum */}
                        {
                            deductionHeads?.map((deductionType, index) => {
                                const sumAmount = deductionsDtls.reduce((sum, deduction) => {
                                    return deduction.deduction_heads_id === deductionType.deduction_heads_id
                                        ? sum + deduction.deduction_amount : sum;
                                }, 0);
                                return (<th key={`summary-deduction-${index}`} className="erp_table_th text-end" > {sumAmount.toFixed(2)} </th>);
                            })
                        }
                        <th className="erp_table_th text-end">{summaryData.reduce((sum, summary) => sum + summary.total_deduction, 0).toFixed(2)}</th>
                        <th className="erp_table_th text-end"> {summaryData.reduce((sum, summary) => sum + summary.net_salary, 0).toFixed(2)} </th>
                        <th className="erp_table_th"></th>
                    </tr>

                    <tr style={{ height: '70px' }}>
                        <th className="erp_table_th align-bottom" colSpan={8 + deductionHeads.length + earningHeads.length}>
                            <div className='row align-text-bottom text-center'>
                                <div className='col-2 align-text-bottom text-center'>Prepared By</div>
                                <div className='col-2 align-text-bottom text-center'>Checked By</div>
                                <div className='col-2 align-text-bottom text-center'>HR Manager</div>
                                <div className='col-2 align-text-bottom text-center'>Accounts Dept.</div>
                                <div className='col-2 align-text-bottom text-center'>Accounts Manager</div>
                                <div className='col-2 align-text-bottom text-center'>General Manager</div>
                            </div>
                        </th>
                    </tr>

                </tbody>
            </Table>
        </>
    );
};