import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FrmValidations from "FrmGeneric/FrmValidations";
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from 'Features/ComboBox';
import ErrorModal from 'components/Modals/ErrorModal';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';


export default function FrmYarnPackingMasterEntry(props) {
    const validate = useRef();
    const combobox = useRef();
    var activeValue = '';
    const comboDataAPiCall = useRef();
    const { state } = useLocation();
    const configConstants = ConfigConstants();
    const validateNumberDateInput = useRef();
    const { COMPANY_ID, UserName } = configConstants;
    const { yarnPackingTypesId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

    // Add Yarn Paking Types Form Fields
    const [yarn_packing_types_id, setCostYarnPackingTypesId] = useState(yarnPackingTypesId);
    const [txt_yarn_packing_types_name, setYarnPackingTypesName] = useState('');
    const [int_cone_weight, setConeWeight] = useState('');
    const [cmb_yarn_packing_types_property, setYarnPackingTypesProperty] = useState('');
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')
    const [int_cone_quantity, setConeQuantity] = useState('');
    const [costYarnPackingTypesOption, setCostYarnPackingTypesOption] = useState([]);
    const [int_packing_weight, setPackingWeight] = useState('');
    const [txt_remark, setRemark] = useState('');
    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Show ADD record Modal
    const handleCloseRecModal = async () => {
        await FillComobos()
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MYarnPackingMaster/FrmYarnPackingListing`);
        }

    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // For navigate
    const navigate = useNavigate();
    useEffect(async () => {
        await ActionType();
        await FillComobos();
        await FnCheckUpdateResponce();
    }, [])

    // fill combo onload
    const FillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name',];
            globalQuery.table = "amv_properties";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'YarnPackingTypes' });
            globalQuery.orderBy = ['property_name']
            const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
            setCostYarnPackingTypesOption(profitCenterList);

        } catch (error) {
            console.log("error : ", error)
        }
    }


    const comboOnChange = async (key) => {
        switch (key) {
            case 'costCenterName':
                let productTpVal = parseInt(document.getElementById('cmb_yarn_packing_types_property').value);
                if (productTpVal === 0) {
                    setYarnPackingTypesProperty(productTpVal)
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");

                    }, 200)
                    setConeWeight('');
                } else {
                    setConeWeight('');
                }
                break;
        }
    }


    const calculateValues = (fieldName) => {
        const getValue = (id) => parseFloat(document.getElementById(id).value);
        const setValue = (setter, value) => setter(isNaN(value) ? "0" : value.toFixed(2));

        switch (fieldName) {
            case 'int_packing_weight':
                const coneQuantity = getValue('int_cone_quantity');
                const coneWeight = getValue('int_cone_weight');
                setValue(setPackingWeight, (coneQuantity * coneWeight));
                break;
            default:
                break;
        }
    };

    const handleInputChange = (e) => {
        const { id } = e.target;
        const value = document.getElementById(id).value;
        switch (id) {
            case 'int_cone_weight':
                setConeWeight(validateNumberDateInput.current.decimalNumber(value, 4));
                calculateValues("int_packing_weight");
                break;
            case 'int_cone_quantity':
                setConeQuantity(validateNumberDateInput.current.decimalNumber(value, 4));
                calculateValues("int_packing_weight");
                break;
            default:
                break;
        }
        validateFields();
    }


    const handleSubmit = async () => {
        try {
            const checkIsValidate = await validate.current.validateForm("YarnPackingFormId");
            if (checkIsValidate === true) {
                let active;
                activeValue = document.querySelector('input[name=isactive]:checked').value
                switch (activeValue) {
                    case '1': active = true; break;
                    case '0': active = false; break;
                }
                const data = {
                    company_id: COMPANY_ID,
                    //company_branch_id: COMPANY_BRANCH_ID,
                    yarn_packing_types_id: yarnPackingTypesId,
                    created_by: UserName,
                    modified_by: yarnPackingTypesId === 0 ? null : UserName,
                    yarn_packing_types_name: txt_yarn_packing_types_name,
                    yarn_packing_types_property_id: cmb_yarn_packing_types_property,
                    cone_weight: int_cone_weight,
                    cone_quantity: int_cone_quantity,
                    packing_weight: int_packing_weight,
                    is_active: active,
                    remark: txt_remark,

                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmYarnPackingTypes/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce);
                if (responce.success === '0') {
                    console.log("response error: ", responce.success);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    };


    const FnCheckUpdateResponce = async () => {
        try {
            if (yarnPackingTypesId !== 0) {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmYarnPackingTypes/FnShowParticularRecordForUpdate/${yarnPackingTypesId}/${COMPANY_ID}`)
                const updateRes = await apiCall.json();
                
                setYarnPackingTypesProperty(updateRes.YarnPackingTypesModelRecords.yarn_packing_types_property_id);
                setYarnPackingTypesName(updateRes.YarnPackingTypesModelRecords.yarn_packing_types_name);
                setConeWeight(updateRes.YarnPackingTypesModelRecords.cone_weight);
                setConeQuantity(updateRes.YarnPackingTypesModelRecords.cone_quantity);
                setPackingWeight(updateRes.YarnPackingTypesModelRecords.packing_weight)
                setRemark(updateRes.YarnPackingTypesModelRecords.remark);
                $('#saveBtn').show();

                switch (updateRes.YarnPackingTypesModelRecords.is_active) {
                    case true:
                        document.querySelector('input[name="isactive"][value="1"]').checked = true;
                        break;
                    case false:
                        document.querySelector('input[name="isactive"][value="0"]').checked = true;
                        break;
                }
            }
        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('YarnPackingFormId')
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#saveBtn').attr('disabled', false);
                $('#txt_yarn_packing_types_name').attr('disabled', true);
                $('#cmb_yarn_packing_types_property').attr('disabled', true);
                break;
            case 'view':
                setActionType('(View)');
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly("YarnPackingFormId");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    return (
        <>
            <FrmValidations ref={validate} />
            <ComboBox ref={combobox} />
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Yarn Packing Types{actionType}</label>
                    </div>
                    <form id="YarnPackingFormId">
                        <div className="row erp_transporter_div  text-start">
                            {/* first row */}
                            <div className="col-lg-6">
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Yarn Packing Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_yarn_packing_types_name" className="erp_input_field" value={txt_yarn_packing_types_name} onChange={e => { setYarnPackingTypesName(e.target.value); validateFields(); }} maxLength="500" />
                                        <MDTypography variant="button" id="error_txt_yarn_packing_types_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Packing Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="cmb_yarn_packing_types_property" value={cmb_yarn_packing_types_property} className="form-select form-select-sm erp_input_field"
                                            onChange={e => { setYarnPackingTypesProperty(e.target.value); validateFields(); comboOnChange('costCenterName'); }} >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record +</option>
                                            {costYarnPackingTypesOption?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_yarn_packing_types_property" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Cone Weight <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="int_cone_weight" className="erp_input_field remove0 erp_align-right" value={int_cone_weight}
                                            onChange={handleInputChange} maxLength="19" />
                                        <MDTypography variant="button" id="error_int_cone_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Cone Quantity<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="int_cone_quantity" className="erp_input_field remove0 erp_align-right" value={int_cone_quantity} onChange={handleInputChange} maxLength="19" />
                                        <MDTypography variant="button" id="error_int_cone_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* second row */}
                            <div className="col-lg-6 ">
                                <div className="row">

                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Packing Weight<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="int_packing_weight" className="erp_input_field" value={int_packing_weight} maxLength="19" disabled />
                                            <MDTypography variant="button" id="error_int_packing_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label"> Remark </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Yes"
                                                    type="radio"
                                                    value="1"
                                                    name="isactive"
                                                    defaultChecked
                                                />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="No"
                                                    value="0"
                                                    type="radio"
                                                    name="isactive"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="card-footer py-3 text-center">
                        <MDButton type="button" className="erp-gb-button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MYarnPackingMaster/FrmYarnPackingListing/reg' : '/Masters/MYarnPackingMaster/FrmYarnPackingListing';

                                navigate(path);
                            }} variant="button" fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>
            </div>

            {/* Add new Record Popup */}
            <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                <Modal.Body className='erp_city_modal_body'>
                    <div className='row'>
                        <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                    </div>
                    <FrmMProductTypeEntry btn_disabled />
                </Modal.Body>

            </Modal >

            {/* Success Msg Popup */}
            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            {/* Error Msg Popup */}
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
        </>
    )

}
