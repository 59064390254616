import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";
import WaterMark from "components/WaterMark";

let PurchaseOrderTermstable = null;

const PurchaseOrderInvoice = React.forwardRef(({ invoiceContent, isWaterMark, ref }) => {

  // const configConstants = ConfigConstants();
  // const { AFTER_DECIMAL_PLACES } = configConstants;
  let AFTER_DECIMAL_PLACES = 2;
  const data = invoiceContent.items
  const scheduleData = invoiceContent.scheduleDetails
  const squareBorder = {
    border: '2px solid #000',
    // padding: '10px',
  };

  function numberToWordsWithCurrency(number) {

    // Check if the input number is NA, null, or an empty string
    if (number === null || number === "NA" || number === "" || number === undefined) {
      return "-";
    }

    const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    // Function to convert the integer part of the number to words
    function convertIntegerToWords(integer) {
      if (integer === 0) {
        return "";
      }

      if (integer < 20) {
        return ones[integer];
      }

      if (integer < 100) {
        const ten = Math.floor(integer / 10);
        const remainder = integer % 10;
        return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
      }

      if (integer < 1000) {
        const hundred = Math.floor(integer / 100);
        const remainder = integer % 100;
        return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
      }

      if (integer < 1000000) {
        const thousand = Math.floor(integer / 1000);
        const remainder = integer % 1000;
        return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
      }
      if (integer < 1000000000) {
        const million = Math.floor(integer / 1000000);
        const remainder = integer % 1000000;
        return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
      }

      return "Number too large to convert";
    }

    // Function to convert the decimal part of the number to words
    function convertDecimalToWords(decimal) {
      const tensValue = Math.floor(decimal / 10);
      const onesValue = decimal % 10;
      let words = "";

      if (tensValue > 0) {
        words += tens[tensValue];
      }

      if (onesValue > 0) {
        words += ` ${ones[onesValue]}`;
      }

      return words;
    }

    // Split the number into integer and decimal parts
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

    // Convert the integer part to words
    let words = convertIntegerToWords(integerPart);

    // Add currency
    if (words) {
      words += " Rupees";
    }

    // Add the decimal part to the words
    if (decimalPart > 0) {
      words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
    }

    return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
  }


  const cgstTotal = invoiceContent.footer.cgst_total;
  const sgstTotal = invoiceContent.footer.sgst_total;
  const igstTotal = invoiceContent.footer.igst_total;
  const basicTotal = invoiceContent.footer.basic_total;
  const taxable_total = invoiceContent.footer.taxable_total
  const grand_total = invoiceContent.footer.grand_total
  const round_off = invoiceContent.footer.round_off

  const cgstTotalInWords = numberToWordsWithCurrency(cgstTotal);
  const sgstTotalInWords = numberToWordsWithCurrency(sgstTotal);
  const igstTotalInWords = numberToWordsWithCurrency(igstTotal);
  const basicTotalInWords = numberToWordsWithCurrency(basicTotal);
  const taxabletotalInWords = numberToWordsWithCurrency(taxable_total);
  const grandtotalInWords = numberToWordsWithCurrency(grand_total);


  console.log(`CGST Total: ${cgstTotalInWords}`);
  console.log(`SGST Total: ${sgstTotalInWords}`);
  console.log(`IGST Total: ${igstTotalInWords}`);

  // check Payment Terms name each element in the Payment Terms array
  let paymentTermsList = null
  if (invoiceContent.paymentTerms && invoiceContent.paymentTerms.length > 0) {
    paymentTermsList = invoiceContent.paymentTerms.map(paymentTerm => paymentTerm.payment_terms_name).join(', ');
  }

  // Iterate over each element in the PurchaseOrderTerms array
  const PurchaseOrderTerms = invoiceContent.PurchaseOrderTerms;
  PurchaseOrderTerms.forEach(POTerms => {
    const { purchase_order_terms_parameters_name, purchase_order_terms_parameters_value } = POTerms;
    // Now you can use purchase_order_terms_parameters_name
    console.log('purchase_order_terms_parameters_name:', purchase_order_terms_parameters_name);
  });


  const contactDetails = invoiceContent.contactDetails;
  // Iterate over each element in the contactDetails array
  contactDetails.forEach(contact => {
    const { supp_contact_person, supp_contact_no } = contact;
    // Now you can use cust_contact_person and cust_contact_no
    console.log('Contact Person:', supp_contact_person);
    console.log('Contact Number:', supp_contact_no);
  });

  const itemData = extractItemData(invoiceContent.items);
  function extractItemData(items) {
    // Extract relevant data from the first item in the array
    if (items.length > 0) {

      const firstItem = items[0];
      return {
        department_name: firstItem.department_name,
        company: firstItem.company,
        company_branch: firstItem.company_branch,
        address: firstItem.address,
        phone: firstItem.phone,
        cell: firstItem.cell,
        email: firstItem.email,
        website: firstItem.website,
        GSTIN: firstItem.GSTIN,
        pan_no: firstItem.pan_no,
        state_name: firstItem.state_name,
        company_pincode: firstItem.company_pincode,
      };
    }
    // Return default values or handle the case where the array is empty
    return {
      department_name: 0,
      company: 0,
      company_branch: 0,
      address: 0,
      phone: 0,
      cell: 0,
      email: 0,
      website: 0,
      GSTIN: 0,
      pan_no: 0,
      state_name: 0,
      company_pincode: 0,
    };
  }

  const invoiceHeader = (
    <>
      <div className='bordered border-bottom-0 px-0'>
        <div className='row mb-0 p-2'>
          <div className="col-sm-3 text-center text-sm-start">
            <img src={DakshabhiLogo} alt="master card" width="150" height="70" mt={1} />
          </div>
          <div className="col-sm-6 ps-3">
            <div className='erp-invoice-print-label-lg text-center'>
              {itemData.company}
            </div>
            <div className="row">
              <div className="text-center">
                <div className='erp-invoice-print-label text-center'>
                  <div className='erp-invoice-print-label-md-lg'>
                    <span className='erp-invoice-print-label-md-lg'>{itemData.address}
                      <br />
                      {/* ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode}  */}
                    </span>
                  </div>

                  <div className='erp-invoice-print-label-md-lg'>
                    <span className='erp-invoice-print-label-md-lg'> Email: {itemData.email} || Phone: {itemData.phone}   </span>
                  </div>
                  <div className='erp-invoice-print-label-md-lg'>
                    <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no} </span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="row py-4">
              <div className='erp-invoice-print-label-lg me-3 ps-0 text-center'>Purchase Order </div>
            </div>
          </div>
        </div>

        <div className='row border-top border-dark p-0 m-0'>
          <div className='col-sm-5'>
            <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Purchase Order No.:{invoiceContent.purchaseDetails.purchase_order_no} </dt>
          </div>
          <div className='col-sm-4'>
            <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Purchase Order Type & Status:{invoiceContent.purchaseDetails.purchase_order_type} / {invoiceContent.purchaseDetails.purchase_order_status}</dt>
          </div>
          <div className='col-sm-3'>
            <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}>Purchase Order Date:{invoiceContent.purchaseDetails.purchase_order_date}</dt>
          </div>
        </div>
        <div className='row  border-top border-dark p-0 m-0'>
          <div className="row p-0 m-0">
            <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Supplier Details:</dt>
            <dt className="col-sm-6 erp-invoice-print-label-md-lg">Supplier Contact Details:</dt>
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Name:</dt>
                <dd className='col-sm-10 erp-invoice-print-label-md-lg'> {invoiceContent.supplierDetails.name}</dd>

                <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Address:</dt>
                <dd className='col-sm-10 erp-invoice-print-label-md-lg'> {invoiceContent.supplierDetails.address}</dd>

                <dl className="row mb-0 mt-0">
                  <div className="col-sm-6">
                    <dt className='erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label-md-lg"> {invoiceContent.supplierDetails.state}</span></dt>
                  </div>
                  <div className="col-sm-6">
                    <dt className='erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label-md-lg"> {invoiceContent.supplierDetails.city}</span></dt>
                  </div>
                </dl>
              </dl>
            </div>
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dd className="col-sm-10 m-0 p-0">
                  <dl className="row m-0 p-0">
                    <dl className="col-sm-6 mb-0 pb-0">
                      <dt className="erp-invoice-print-label-md-lg">Person Name:</dt>
                      {/* This will only display once */}
                    </dl>
                    <dl className="col-sm-6 mb-0 pb-0">
                      <dt className="erp-invoice-print-label-md-lg">Contact No:</dt>
                      {/* This will only display once */}
                    </dl>
                  </dl>
                  {contactDetails.map((contactData, index) => (
                    <dl className="row m-0 p-0" key={index}>
                      <dl className="col-sm-6 mb-0 pb-0">
                        <dd className="erp-invoice-print-label-md-lg">{contactData.supp_contact_person}</dd>
                      </dl>
                      <dl className="col-sm-6 mb-0 pb-0">
                        <dd className="erp-invoice-print-label-md-lg">{contactData.supp_contact_no}</dd>
                      </dl>
                    </dl>
                  ))}
                </dd>
              </dl>
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>GST NO:</dt>
                <dd className='col-sm-7 erp-invoice-print-label-md-lg'> {invoiceContent.supplierDetails.GSTIN} </dd>
              </dl>
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>Quotation No. & Date:</dt>
                <dd className='col-sm-7 erp-invoice-print-label-md-lg'> {invoiceContent.quotationDetails.quotation_no} ({invoiceContent.quotationDetails.quotation_date})</dd>
              </dl>
            </div>
          </div>


          {/* <div className='row  border-top border-dark p-0 m-0'>

            <div className="row p-0 m-0">
              <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Bill To Details:</dt>
              <dt className="col-sm-6 erp-invoice-print-label-md-lg">Shipped To Details:</dt>

              <div className="col-sm-6">


                <dl className="row pb-0 mb-0">
                  <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Name:</dt>  
                  <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.supplierDetails.name}</dd>

                  <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Address:</dt>
                  <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.supplierDetails.address}</dd>

                  <dl className="row mb-0 mt-0">
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label"> {invoiceContent.supplierDetails.state}</span></dt>
                    </div>
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {invoiceContent.supplierDetails.city}</span></dt>
                    </div>
                  </dl>
                </dl>
              </div>

              <div className="col-sm-6">
                <dl className="row pb-0 mb-0">
                  <div className='col-sm-10 erp-invoice-print-label-md-lg'>
                    {invoiceContent.consigneeDetails.consigneename}
                  </div>
                  <div className='col-sm-10 erp-invoice-print-label'>
                    {invoiceContent.consigneeDetails.consigneeaddress}
                  </div>

                  <dl className="row mb-0 mt-0">
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label"> {invoiceContent.consigneeDetails.consigneestate}</span></dt>
                    </div>
                    <div className="col-sm-6">
                      <dt className='erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {invoiceContent.consigneeDetails.consigneecity}</span></dt>
                    </div>
                  </dl>


                </dl>
              </div>

            </div>
          </div> */}



          <div className='row  border-top border-dark p-0 m-0'>
            <dt className='erp-invoice-print-label-md-lg'>Our Details :</dt>
            <dl className="row pb-0 mb-0">
              <div className="col-sm-6">
                <dl className="row pb-0 mb-0">
                  <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Branch Name :</dt>
                  <dd className='col-sm-9 erp-invoice-print-label-md-lg'> {invoiceContent.ourdetails.expected_branch_name}</dd>

                  <dt className='col-sm-3 erp-invoice-print-label-md-lg'>State :</dt>
                  <dd className='col-sm-9 erp-invoice-print-label-md-lg'> {invoiceContent.ourdetails.expected_branch_state_name}</dd>

                  <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Contact No :</dt>
                  <dd className='col-sm-9 erp-invoice-print-label-md-lg'> {invoiceContent.ourdetails.expected_branch_phone_no}</dd>
                </dl>
              </div>
              <div className="col-sm-6">
                <dl className="row pb-0 mb-0">

                  <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Department :</dt>
                  <dd className='col-sm-9 erp-invoice-print-label-md-lg'> {itemData.department_name}</dd>

                  <dt className='col-sm-3 erp-invoice-print-label-md-lg'>City :</dt>
                  <dd className='col-sm-9 erp-invoice-print-label-md-lg'> {invoiceContent.ourdetails.expected_branch_city_name}</dd>

                  <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Schedule Date :</dt>
                  <dd className='col-sm-9 erp-invoice-print-label-md-lg'> {invoiceContent.ourdetails.expected_schedule_date}</dd>

                </dl>
              </div>
            </dl>
          </div>

        </div>
      </div>

    </>
  )


  const invoiceFooter = (
    <div className="invoice-footer">
      <div className='row mt-2'>
        <div className='col-sm-3'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.supplierDetails.name}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">PO Accepted By</dt>
          </div>
        </div>
        <div className='col-sm-3 offset-sm-6'> {/* This offsets the third column */}
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For {itemData.company}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">{invoiceContent.purchaseDetails.approved_by_name}</dt>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <div className="container"> */}
      <div className="row">
        <div className="col-12">
          <div className="container-invoice">
            {
              isWaterMark ? <> <WaterMark text="Preview Only" opacity={0.9} />  {/* Add watermark here */}</>
                :
                null
            }
            <div id="content">
              <div className="invoice p-0">

                {/* <!--BILL HEADER--> */}
                <div className="row headerContentHt" style={{ padding: '0px 15px 0px' }}>
                  {invoiceHeader}
                </div>
                {/* <!--PARCEL DETAILS--> */}
                <div className="row" style={{ padding: '0px 15px 15px' }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 px-0" >
                        <div className="row p-0">
                          <div className="table-responsive">
                            <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                              {data.length !== 0 && (
                                <thead className='tblHeaderContentHt'>
                                  <tr>
                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                    <th className="erp_invoice_table_th text-center col-4" rowspan={2}>Material Name(Technical Spect,Make Name,Grade Name)</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Hsn Code</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Qty.</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Unit</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Rate.</th>
                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Disc %</th>
                                    <th className="erp_invoice_table_th text-center col-1" colSpan={3}>Taxes Extra</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Item Total</th>
                                  </tr>
                                  <tr>
                                    <th className="erp_invoice_table_th text-center text-center">CGST %</th>
                                    <th className="erp_invoice_table_th text-center text-center">SGST %</th>
                                    <th className="erp_invoice_table_th text-center text-center">IGST %</th>
                                  </tr>
                                </thead>
                              )}

                              <tbody id='detailstableId'>
                                {
                                  data.map((item, index) =>
                                    <>
                                      <tr className={`tblBodyTrHt-${index}`}>
                                        <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                        <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}
                                          <span>
                                            {
                                              (item.material_tech_spect === '' || item.material_tech_spect === "NA")
                                                && (item.product_make_name === '' || item.product_make_name === "NA")
                                                && (item.product_material_grade_name === '' || item.product_material_grade_name === "NA")
                                                ? ''
                                                : <>
                                                  ({
                                                    [item.material_tech_spect, item.product_make_name, item.product_material_grade_name].filter(str => str !== "" && str !== "NA" && str !== "General" && str !== null && str !== undefined).join(", ")})
                                                </>
                                            }
                                          </span>
                                        </td>
                                        <td className="erp_invoice_table_td">{item.hsn_code}</td>
                                        <td className="erp_invoice_table_td text-end">{item.quantity}</td>
                                        <td className="erp_invoice_table_td">{item.unit}</td>
                                        <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                        <td className="erp_invoice_table_td text-end">{item.discount_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.cgst_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.sgst_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.igst_percent}</td>
                                        <td className="erp_invoice_table_td text-end">{item.material_basic_amount}</td>
                                      </tr>
                                      {
                                        data.length - 1 === index ? <>
                                          <tr id='footerTr' className='footerContentHt'>
                                            <td className="erp_invoice_table_td" colSpan={6}>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Basic Total :</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label">{basicTotalInWords}</dd>
                                              </div>
                                              <div className='row  mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label">{cgstTotalInWords}</dd>
                                              </div>
                                              <div className='row  mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label">{sgstTotalInWords}</dd>
                                              </div>
                                              <div className='row  mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label">{igstTotalInWords}</dd>
                                              </div>
                                              <div className='row  mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Taxable Total:</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label">{taxabletotalInWords}</dd>
                                              </div>
                                              <div className='row  mt-1 m-0 p-0'>
                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Grand Total :</dt>
                                                <dd className="col-sm-8 erp-invoice-print-label">{grandtotalInWords}</dd>
                                              </div>
                                            </td>
                                            <td className="erp_invoice_table_td p-0 m-0" colSpan={6}>
                                              <table className="table p-0 m-0 table-borderless">
                                                <tbody>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Basic Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">CGST Total :</span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.cgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">SGST Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.sgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">IGST Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.igst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Taxable Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.taxable_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Packing Amount: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.packing_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount Amount: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.discount_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Freight Amount: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.freight_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Other Amount: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.other_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Round Off: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.round_off).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-0'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Grand Total: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          {scheduleData.length !== 0 ?
                                            <tr>
                                              <td colSpan={12}>
                                                <dl className="row p-0 m-0 mt-1">
                                                  <dt className="col-sm-4" style={{ fontSize: '11px' }}>Material Schedules: </dt>
                                                </dl>
                                                <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >

                                                  <thead className='tblHeaderContentHt'>
                                                    <tr>
                                                      <th className="erp_invoice_table_th text-center col-1" rowspan={2} >Sr No.</th>
                                                      <th className="erp_invoice_table_th text-center col-4" rowspan={2}>Material Name</th>
                                                      <th className="erp_invoice_table_th text-center col-2" rowspan={2}>Schedule Quantity</th>
                                                      <th className="erp_invoice_table_th text-center col-2" rowspan={2}>Schedule Date</th>
                                                      <th className="erp_invoice_table_th text-center col-3" rowspan={2}>Remark</th>
                                                    </tr>
                                                  </thead>

                                                  <tbody id='detailstableId'>
                                                    {
                                                      scheduleData.map((item, index) =>
                                                        <>
                                                          <React.Fragment key={index}>
                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                              <td className="erp_invoice_table_td text-end">{item.sr_no}</td>
                                                              <td className="erp_invoice_table_td">{item.product_material_name}</td>
                                                              <td className="erp_invoice_table_td text-end">{item.schedule_quantity}</td>
                                                              <td className="erp_invoice_table_td ">{item.schedule_date}</td>
                                                              <td className="erp_invoice_table_td ">{item.remark}</td>
                                                            </tr>
                                                          </React.Fragment>
                                                        </>
                                                      )
                                                    }
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            : null
                                          }
                                          <tr className='pOrderContent'>
                                            <td colSpan={12} >
                                              <div className='row m-0 p-0'>
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">Remark:</dt>
                                                <dt className="col-sm-8 erp-invoice-print-label">{invoiceContent.footer.remark}</dt>
                                              </div>
                                              <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">PO Payment Terms: </dt>
                                                {paymentTermsList !== null ? (
                                                  <dd className="col-sm-6 m-0 p-0">
                                                    <dl className="row m-0 p-0" key={index}>
                                                      <dd className="col-sm-8 erp-invoice-print-label">
                                                        <dt className="erp-invoice-print-label">{paymentTermsList}</dt>
                                                      </dd>
                                                    </dl>
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl>
                                              <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">PO Terms Name: </dt>
                                                {PurchaseOrderTerms.length > 0 ? (
                                                  <dd className="col-sm-6 m-0 p-0">
                                                    {PurchaseOrderTerms.map((PurchaseOrderTerm, index) => (
                                                      <dl className="row m-0 p-0" key={index}>
                                                        <dd className="col-sm-8 erp-invoice-print-label">
                                                          {PurchaseOrderTerm.purchase_order_terms_parameters_name !== undefined ? ` ${PurchaseOrderTerm.purchase_order_terms_parameters_name}` : ''}
                                                        </dd>
                                                      </dl>
                                                    ))}
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl>
                                              <dl className="row p-0 m-0 mt-1">
                                              <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Terms & Conditions : </dt>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>- Please confirm Billing or shipping address before dispatch </dt>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>- SUBJECT TO AHMEDABAD JURIDICTION ONLY </dt>
                                              </dl>
                                            </td>
                                          </tr>
                                          <tr>

                                          </tr>
                                          <tr className='invoiceFooterTr'>
                                            <td colSpan={12}>
                                              {invoiceFooter}
                                            </td>
                                          </tr>
                                        </> : null
                                      }
                                    </>
                                  )
                                }
                              </tbody>
                            </table>
                            {/* <div>
                              <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>ISO No. & Purchase Order Version : {invoiceContent.purchaseDetails.purchase_iso_no} & {invoiceContent.purchaseDetails.purchase_order_version} </span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
})
export default PurchaseOrderInvoice;

