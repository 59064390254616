import React, { useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import $ from 'jquery';
import { useLocation, useNavigate } from "react-router-dom";

// import files
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import ComboBox from "Features/ComboBox";
import ConfigConstants from "assets/Constants/config-constant";
import { resetGlobalQuery } from "assets/Constants/config-constant";
import { globalQuery } from "assets/Constants/config-constant";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import { Accordion, Card, Form, Table } from "react-bootstrap";
import { Button, Modal } from 'react-bootstrap';
import MDTypography from "components/MDTypography";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { CircularProgress } from "@material-ui/core";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { FaDatabase } from "react-icons/fa";
import TGenericAddmaterialProductionfrm from "Transactions/TSizingProduction/TGenericAddmaterialProductionfrm";

// Import for the searchable combo box.
import Select from 'react-select';


function FrmLoomProduction(props) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;
  const { state } = useLocation();
  const { weavingProductionLoomMasterId = 0, keyForViewUpdate, compType } = state || {}

  // For Refs
  const comboDataAPiCall = useRef();
  const loadStyleOptions = useRef();
  const selectRef = useRef(null);
  const generateAutoNoAPiCall = useRef();
  const navigate = useNavigate();
  const validateNumberDateInput = useRef();
  const importFile = useRef(null)
  const cmb_prod_supervisor_id_ref = useRef();
  const combobox = useRef();
  const validate = useRef();
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  const [isLoading, setIsLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [rowCount, setRowCount] = useState(1);

  //Current date
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const currentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const setMonthYear = () => {
    let prod_Plan_Dt = $("#dt_loom_production_date").val();
    if (prod_Plan_Dt) {
      setProdPlanDate(prod_Plan_Dt);
      let dateComponents = prod_Plan_Dt.split('-');
      setProdMonth(dateComponents[1]);
      setProdYear(dateComponents[0]);
    }
  }

  //Loom Production master hooks
  const [txt_weaving_production_loom_master_id, setweavingProductionLoomMasterId] = useState(weavingProductionLoomMasterId);
  const [dt_loom_production_date, setProdPlanDate] = useState(today);
  const [txt_prod_month, setProdMonth] = useState();
  const [txt_prod_year, setProdYear] = useState();
  const [cmb_plant_id, setProdPlantName] = useState(1);
  const [cmb_section_id, setProdSection] = useState(17);
  const [cmb_sub_section_id, setProdSubSection] = useState(23);
  const [cmb_prod_shift, setProdShift] = useState('');
  const [cmb_loom_production_code, setLoomProductionCode] = useState();
  const [cmb_prod_supervisor_id, setProdSupervisor] = useState();
  const [cmb_loom_production_master_status, setProductionLoomStatus] = useState('P');
  const [txt_loom_production_remark, setStatusremark] = useState('');
  const [cmb_data_import_time, setDataImportTime] = useState(currentTime);
  const [txt_data_import_file_name, setDataImportFileName] = useState();

  //loom Production  details comboboxes
  const [styleDetails, setAllStyleDetails] = useState([]);
  const [stopCountData, setStopCountData] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
  const [machineNumData, setMachineNumData] = useState([]);
  const [machineNameOption, setMachineNameOption] = useState([]);
  const [godownNameOptions, setGodownNameOptions] = useState([]);

  ///Shiftwise Machine Stoppage Entry Hooks
  const [cmb_machine_no, setMachineNo] = useState();
  const [cmb_stoppage_reason, setMachineStoppageReason] = useState();
  const [txt_stoppage_time, setMachineStoppageTime] = useState();
  const [cmb_loss_type, setLossType] = useState();
  const [txt_std_stoppage_loss_kg, setStdProdLoss] = useState();
  const [txt_actual_production_loss_kg, setActProdLoss] = useState();
  const [txt_stoppage_production_loss_kg, setStoppageProductionLossKg] = useState();
  const [txt_std_stoppage_loss_per_hour, setStdStoppageLossPerHour] = useState();
  const [labelForStoppage, setLabelForStopage] = useState('Add');

  ///Shiftwise Machine Stoppage Entry Comboboxes
  const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
  const [stoppageDetails, setStoppageDetails] = useState([]);
  const [prevdataindexforStoppage, setPrevDataIndexForStoppage] = useState();
  const [ringFrameProdShiftData, setRingFrameProdShiftData] = useState([]);

  ///Shiftwise Machine Wastage Entry Hooks
  const [cmb_prod_wastage_type, setProdWastageType] = useState();
  const [labelForWastage, setLabelForWastage] = useState('Add');
  const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();
  const [txt_wastage_quantity, setWastageQuantity] = useState();
  const [cmb_godown_id, setGodownId] = useState();

  ///Shiftwise Machine Wastage Entry Combobox
  const [prodWastageOptions, setProdWastageOptions] = useState([]);
  const [wastageDetails, setWastageDetails] = useState([]);

  /// material movement hooks
  const [loomMaterialData, setLoomMaterialData] = useState([])

  // Fields for consumption qty
  const [materialStockList, setMaterialStockList] = useState([])
  const [show, setShow] = useState(false);
  const closeConsumptionModal = () => setShow(false)

  // Fields for consumption qty
  const [materialName, setMaterialName] = useState()
  const [unitName, setUnitName] = useState()
  const [consumptionQty, setConsumptionQty] = useState()

  //Loom Production master hooks
  const [warpingOrderNo, setWarpingOrderNO] = useState('');
  const [setNo, setsetNO] = useState('');
  const [RowIndexNo, setRowIndex] = useState('');

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  //Loom Production  details comboboxes
  const [setNoOptions, setSetNoOptions] = useState([]);

  const loomMaterialBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    loom_production_date: today(),
    loom_production_set_no: 0,
    shift: '',
    loom_production_order_no: '',
    status_remark: '',
    material_status: 'P',
    weaving_production_loom_material_id: 0,
    product_loom_material_balance_quantity: 0,
    product_material_id: "",
    product_material_unit_id: 0,
    consumption_quantity: 0,
    product_material_quantity: 0,
    created_by: UserName,
  }
  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    // if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
    navigate(`/Transactions/TLoomProductaion/FrmLoomProductionListing`)
    // }
  }

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  useEffect(() => {

    const loadDataOnload = async () => {
      setIsLoading(true)
      await ActionType()
      await fillComboBox();
      setMonthYear();

      if (weavingProductionLoomMasterId !== 0) {
        await FnCheckUpdateResponce()
      }
      if (keyForViewUpdate === 'Add') {
        fillAutoNo();
      }
      if (loomMaterialData.length === 0 && weavingProductionLoomMasterId === 0) {
        const getExistingLoomProductionData = [...loomMaterialData]
        getExistingLoomProductionData.push(loomMaterialBlankObject)
        setLoomMaterialData(getExistingLoomProductionData)
      }
      setIsLoading(false)
    }
    loadDataOnload()
  }, [])

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setIsApprove(false);
        setActionType('(Modification)');
        setActionLabel('Update')
        await validate.current.readOnly("loomProductionMasterId");
        break;
      case 'view':
        setIsApprove(false);
        setActionType('(View)');
        await validate.current.readOnly("loomProductionMasterId");
        $("input[type=radio]").attr('disabled', true);
        break;
      case 'approve':
        setActionType('(Approve)');
        setActionLabel('Approve');
        $('#save-btn').text('Approve');
        $('#save-btn').attr('BtnType', 'approve');
        $("form").find("input,select").attr("disabled", "disabled");
        $("table").find("input,button,select").attr("disabled", "disabled");
        $('table .approveField').removeAttr("disabled");
        $("input[type=radio]").attr('disabled', true);
        $("input[type=textarea]").attr('disabled', false);
        setIsApprove(true)
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };

  //****************************Update fuctionalty Get All data ************************* */
  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionLoomMaster/FnShowParticularRecordForUpdate/${weavingProductionLoomMasterId}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();
      console.log('updateRes', updateRes);
      const data = updateRes.WeavingProductionLoomDetailsRecord
      const masterdata = updateRes.WeavingProductionLoomMasterRecord
      const stopPagedata = updateRes.WeavingProductionLoomStoppageRecord
      const wastageData = updateRes.WeavingProductionLoomWastageRecord
      const materialData = updateRes.weavingProductionLoomMaterialRecord

      setweavingProductionLoomMasterId(masterdata.weavingProductionLoomMasterId)
      let loom_production_shift = [];
      data.forEach((item, index) => {
        loom_production_shift.push(item.loom_production_shift);
      });
      setProdPlanDate(masterdata.loom_production_date)
      setProdMonth(masterdata.prod_month)
      setLoomProductionCode(masterdata.loom_production_code)
      setProdYear(masterdata.prod_year)
      setProdPlantName(masterdata.plant_id);
      setProdSection(masterdata.section_id)
      setProdSubSection(masterdata.sub_section_id)
      comboOnChange('cmb_section_id');
      setProdSupervisor(masterdata.production_supervisor_id)
      setProdShift(masterdata.loom_production_shift);
      setProductionLoomStatus(masterdata.loom_production_master_status);
      setDataImportFileName(masterdata.data_import_file);
      setStatusremark(masterdata.loom_production_remark);
      //updatedata for detail 
      const options = await Promise.all(data.map((item) => fetchStyleOptions(item)));
      setAllStyleDetails(options);
      setStopCountData(data);
      //updatedata for Shiftwise Machine Stoppage
      setStoppageDetails(stopPagedata);
      //updatedata for Shiftwise Machine WAstage
      setWastageDetails(wastageData)
      //updatedata for material Movement

      if (materialData !== null && materialData !== "") {
        setLoomMaterialData((prevData) => {
          const updatedData = materialData.map(item => ({
            ...item,
            product_loom_material_balance_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(item.product_loom_material_balance_quantity + item.consumption_quantity), 4),
          }));

          if (updatedData.length === 0) {
            return [...loomMaterialData, loomMaterialBlankObject];
          }
          return updatedData;
        });

        // Perform asynchronous rendering here
        await Promise.all(materialData.map(async (item, index) => {
          return renderMaterialNameList(item.loom_production_set_no, index);
        }));
      }

    } catch (error) {
      console.log("error: ", error)
    }
  }

  const fillAutoNo = async () => {
    try {
      const productionDate = $('#dt_loom_production_date').val();
      const [year, month, day] = productionDate.split('-');
      const formatted_date = `${day}${month}${year.slice(2)}`;
      await comboOnChange('cmb_section_id');

      const subSectionVal = $('#cmb_sub_section_id').val();
      const shortName = $('#cmb_sub_section_id option:selected').attr('shortName');
      const loomProductionCode = await generateAutoNoAPiCall.current.generateProductionCode({
        "entity_name": "xt_weaving_production_loom_master",
        "sub_section": subSectionVal,
        "sub_section_short_name": shortName,
        "field_name": "loom_production_code",
        "trans_no_length": "4",
        "transaction_date": formatted_date,
        "company_id": COMPANY_ID
      });
      setLoomProductionCode(loomProductionCode);

    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')
    }
  };

  const comboOnChange = async (key) => {
    try {
      switch (key) {
        case 'cmb_section_id':
          const prod_section_Id = $('#cmb_section_id').val();
          ///// Production sub section Arr[]
          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_type');
          globalQuery.table = "xmv_production_sub_section"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
          comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
            setProdSubSectionOptions(getProdSubSectionApiCall)
            setProdSubSection('24');

          });


          ///////Machine Options Arr[]
          const prodSectionId = $('#cmb_section_id').val();
          const prodsubSectionId = $('#cmb_sub_section_id').val();
          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name');
          // globalQuery.columns.push("machine_make");
          globalQuery.table = "cmv_machine"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "section_id", operator: "=", value: prodSectionId });
          globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
          comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
            setMachineNameOption(getProdSubSectionApiCall)
            console.log("sub section list: ", getProdSubSectionApiCall);
          });
          ////Employees in Warping Production
          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name');
          globalQuery.table = "cmv_employee"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "department_id", operator: "=", value: 29 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
            const prodSupervisorList = [
              { value: '', label: 'Select' },
              ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
            ];
            setMachineOperatorOptions(prodSupervisorList);
          })

          break;

        case 'cmb_plant_id':
          let plantId = $('#cmb_plant_id').val();
          setProdPlantName(plantId);
          break;
        case 'supervisor':
          let supervisorId = cmb_prod_supervisor_id_ref.current.value;
          if (supervisorId !== '' || supervisorId !== undefined) {
            $('#error_cmb_prod_supervisor_id').hide();
          }
          break;
      }
    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')
    }
  }


  const fillComboBox = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push('*');
      globalQuery.table = "xtv_weaving_production_order";
      globalQuery.conditions.push({ field: "weaving_order_status", operator: "=", value: 'A', });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const SectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setSetNoOptions(SectionApiCall);

      const getProdPlantApiCall = await comboDataAPiCall.current.fillMasterData("cmv_plant", "", "");
      setPlantOptions(getProdPlantApiCall);

      ////Production Section
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name'];
      globalQuery.table = "xmv_production_section"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
        setProdSectionOptions(getWarpingProdSecCall);
        setProdSection('18');
        comboOnChange('cmb_section_id');
      })

      const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts');
      setShiftOptions(shiftsApiCall);

      const getEmployeeApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "", "");
      setMachineOperatorOptions(getEmployeeApiCall);

      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'section_name']
      globalQuery.table = "xmv_production_wastage_types"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "section_name", operator: "=", value: 'Weaving (Weaving)' });
      const getProdWastageReasonsApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      setProdWastageOptions(getProdWastageReasonsApiCall);

      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'godown_name']
      globalQuery.table = "xmv_production_sub_section_godown_mapping"
      globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: '18' });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((godownTypeApiCall) => {
        setGodownNameOptions(godownTypeApiCall)
      })

      const getMachineNosApiCall = await comboDataAPiCall.current.fillMasterData("cmv_machine", "", "")
      setMachineNumData(getMachineNosApiCall);

      const prodSectionId = $('#cmb_section_id').val();
      const prodsubSectionId = $('#cmb_sub_section_id').val();
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_machine"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "section_id", operator: "=", value: prodSectionId });
      globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
      const geetMachineApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setMachineNameOption(geetMachineApiCall);

      const getProdStoppageReasonsApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_stoppage_reasons", "", "");
      setProdStoppageReasonOptions(getProdStoppageReasonsApiCall);

      const prodShiftDataArray = (getMachineNosApiCall.map((machine, index) => {
        return {
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          spinning_production_rf_details_id: '',
          spinning_production_rf_master_id: '',
          spinning_production_date: dt_loom_production_date,
          sub_section_id: cmb_sub_section_id,
          machine_id: machine.field_id,
          warping_production_status: 'P',
          production_operator_id: 0,
          production_count_id: 0,
          yarn_count: 0,
          actual_count: 0,
          speed: 0,
          tpi: 0,
          tm: 0,
          efficiency_target: 0,
          spindles: 0,
          grams_100: 0,
          grams_target: 0,
          grams_actual: 0,
          kgs_100: 0,
          kgs_target: 0,
          kgs_actual: 0,
          efficiency_percent: 0,
          prod_loss_with_reason: 0,
          prod_loss_with_out_reason: 0,
          waste_percent: 0,
          production_mixing: 0,
          production_count_desc: 0,
          conversion_40s: 0,
          total_stopage_time: 0,
          total_stopage_spindles: 0,
          yarn_construction: 0
        };
      }))
      setRingFrameProdShiftData(prodShiftDataArray);


    } catch (error) {
      console.log('error: ', error);
    }
  }

  //********************fuction for validation************************* */

  const validateLoom = async () => {
    const headerDataIsValid = await validate.current.validateForm("loomProductionMasterId");
    if (!headerDataIsValid) {
      return false;
    }
    if (cmb_prod_supervisor_id === '' || cmb_prod_supervisor_id === undefined) {
      let errorElement = document.getElementById("error_cmb_prod_supervisor_id");
      errorElement.textContent = "Please fill this field...!";
      errorElement.style.display = "block";
      document.getElementById("error_cmb_prod_supervisor_id").focus();
      return false;
    }
    let addedMaterials = $('#stopCountTbl tbody tr').length;
    let isValid = true;
    $('#stopCountTbl tbody tr').each(function () {
      let currentTblRow = $(this).closest('tr');
      let godownId = currentTblRow.find('select[id^="godown_id_"]').val().trim();
      let productionOperatorId = currentTblRow.find('select[id^="production_operator_id_"]').val()
      // .trim();

      if (productionOperatorId === '') {
        $(currentTblRow.find('select[id^="production_operator_id_"]'))[0].parentElement.dataset.tip = 'Please select a production operator...!';
        $(currentTblRow.find('select[id^="production_operator_id_"]'))[0].focus();
        isValid = false;
        return false;
      }
      if (godownId === '') {
        $(currentTblRow.find('select[id^="godown_id_"]'))[0].parentElement.dataset.tip = 'Please select a godown...!';
        $(currentTblRow.find('select[id^="godown_id_"]'))[0].focus();
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  const validateMaterialDetails = async () => {

    if (loomMaterialData.length > 0) {
      for (let index = 0; index < loomMaterialData.length; index++) {
        const element = loomMaterialData[index];
        const { loom_production_set_no, shift, product_material_id, consumptionQtyInfo = 0 } = element;

        if (loom_production_set_no !== 0 || (shift !== '' && shift !== 0) || (product_material_id !== '' &&
          product_material_id !== undefined) || consumptionQtyInfo !== 0) {
          if (shift === '') {
            setErrMsg('Please Select at Shift in loom material movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (loom_production_set_no === 0) {
            setErrMsg('Please Select at Set NO. in loom material movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (product_material_id === '') {
            setErrMsg('Please Select Material Name in loom material movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (consumptionQtyInfo === 0) {
            setErrMsg(`Please properly consume the stock for Shift-${shift} & Set No. - ${loom_production_set_no} in the material movement section.`);
            setShowErrorMsgModal(true);
            return false;
          }
          return true;
        } else {
          return true;
        }

      }
    } else {
      return true;
    }


    // let lastRowSelectSetNo = 0;
    // let lastRowSelectShift = '';
    // let lastRowSelectMaterialname = '';
    // let lastRowSelectConsumption_quantity = '';
    // if (loomMaterialData.length > 0) {

    //   const lastRowIndex = loomMaterialData.length - 1;
    //   lastRowSelectSetNo = loomMaterialData[lastRowIndex].loom_production_set_no;
    //   lastRowSelectShift = loomMaterialData[lastRowIndex].shift;
    //   lastRowSelectMaterialname = loomMaterialData[lastRowIndex].product_material_id;
    //   lastRowSelectConsumption_quantity = loomMaterialData[lastRowIndex].consumption_quantity;

    //   if (lastRowSelectSetNo !== 0 || lastRowSelectShift !== '' || lastRowSelectMaterialname !== '' || lastRowSelectConsumption_quantity !== 0) {
    //     if (lastRowSelectShift === '') {
    //       setErrMsg('Please Select at Shift in Loom Material Movement...!');
    //       setShowErrorMsgModal(true);
    //       return false;
    //     } else if (lastRowSelectSetNo === 0) {
    //       setErrMsg('Please Select at Set NO. in Loom Material Movement...!');
    //       setShowErrorMsgModal(true);
    //       return false;
    //     } else if (lastRowSelectMaterialname === '') {
    //       setErrMsg('Please Select Material Name in Loom Material Movement...!');
    //       setShowErrorMsgModal(true);
    //       return false;
    //     }
    //     else if (lastRowSelectConsumption_quantity === '' || lastRowSelectConsumption_quantity === 0) {
    //       setErrMsg('Please add Consumption Quantity In Loom Material Movement...!');
    //       setShowErrorMsgModal(true);
    //       return false;
    //     }
    //     return true;
    //   } else {
    //     return true;
    //   }
    // } else {
    //   return true;
    // }
  }


  //**************************fuction for save (add) loom order*************************** */
  const addLoomProduction = async (e) => {
    try {
      setIsLoading(true)
      let loomIsValid;
      let clickedBtnType = e.target.getAttribute('BtnType')
      if ((clickedBtnType === 'reject' || clickedBtnType === 'approve') && clickedBtnType !== 'save') {
        if (clickedBtnType === 'reject' && $('#txt_loom_production_remark').val().trim() === '') {
          $('#error_txt_loom_production_remark').text('Please enter the Rejection remark.');
          $('#error_txt_loom_production_remark').show();
          $('#txt_loom_production_remark').focus();
          setIsLoading(false)
          return false;
        } else {
          $('#error_txt_loom_production_remark').hide();
          loomIsValid = true;
        }
        loomIsValid = true;
      }
      else if (clickedBtnType !== 'approve' && clickedBtnType !== 'reject') {
        loomIsValid = await validateLoom();
      }
      let checkValidMaterialDetails = false;

      checkValidMaterialDetails = await validateMaterialDetails()


      if (loomIsValid === true && checkValidMaterialDetails === true) {
        const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransLoomMaterialData': [], 'TransLoomStoppageData': [], 'TransLoomWastageData': [], 'commonIds': { 'company_id': COMPANY_ID, 'weaving_production_loom_master_id': weavingProductionLoomMasterId } }

        const data = {
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          weaving_production_loom_master_id: weavingProductionLoomMasterId,
          loom_production_code: cmb_loom_production_code,
          data_import_date: dt_loom_production_date,
          loom_production_date: dt_loom_production_date,
          data_import_file: txt_data_import_file_name,
          data_import_time: cmb_data_import_time,
          prod_month: txt_prod_month,
          prod_year: txt_prod_year,
          plant_id: cmb_plant_id,
          production_supervisor_id: parseInt(cmb_prod_supervisor_id),
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          created_by: UserName,
          modified_by: weavingProductionLoomMasterId === 0 ? null : UserName,
          loom_production_master_status: cmb_loom_production_master_status,
          loom_production_remark: txt_loom_production_remark,
          loom_production_shift: $('#cmb_prod_shift option:selected').text(),
        };


        if (isApprove) {
          if (clickedBtnType === 'reject') {
            data.loom_production_master_status = 'R'
          }
          if (clickedBtnType === 'approve') {
            data.loom_production_master_status = 'A'
          }
        }
        let totalRunInMin = 0;
        let totalStopInMin = 0;
        let totalProdcut1000Pick = 0;
        let totalProductInMeter = 0;

        for (let index = 0; index < stopCountData.length; index++) {
          const element = stopCountData[index];

          totalRunInMin += parseFloat(element.run_in_min);
          totalStopInMin += parseFloat(element.stop_in_min);
          totalProdcut1000Pick += parseFloat(element.prodcut_1000pick);
          totalProductInMeter += parseFloat(element.product_in_meter);

          const loomProductionAddData = {
            company_branch_id: COMPANY_BRANCH_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            company_id: COMPANY_ID,
            weaving_production_loom_master_id: weavingProductionLoomMasterId,
            weaving_production_loom_details_id: element.weaving_production_loom_details_id,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            loom_production_code: cmb_loom_production_code,
            plant_id: cmb_plant_id,
            production_supervisor_id: parseInt(cmb_prod_supervisor_id),
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            created_by: UserName,
            modified_by: weavingProductionLoomMasterId === 0 ? null : UserName,
            created_by: UserName,
            machine_id: element.machine_id,
            loom_production_shift: element.loom_production_shift,
            production_operator_id: element.production_operator_id,
            godown_id: element.godown_id,
            loom_production_date: element.loom_production_date,
            set_no: element.set_no,
            sizing_beam_no: element.sizing_beam_no,
            sort_no: element.sort_no,
            epi: element.epi,
            ppi: element.ppi,
            width: element.width,
            target_rpm: element.target_rpm,
            target_eff: element.target_eff,
            weave: element.weave,
            remarks: element.remarks,
            code: element.code,
            act_rmp: element.act_rmp,
            act_eff: element.act_eff,
            run_in_min: element.run_in_min,
            stop_in_min: element.stop_in_min,
            prodcut_1000pick: element.prodcut_1000pick,
            product_in_meter: element.product_in_meter,
            air_flow1: element.air_flow1,
            air_flow2: element.air_flow2,
            air_flow_cfm: element.air_flow_cfm,
            warp_times: element.warp_times,
            warp_minutes: element.warp_minutes,
            warp_per_hour: element.warp_per_hour,
            warp_per_day: element.warp_per_day,
            warp_per_cmpx: element.warp_per_cmpx,
            weft_times: element.weft_times,
            weft_minutes: element.weft_minutes,
            weft_per_hour: element.weft_per_hour,
            weft_per_day: element.weft_per_day,
            weft_per_cmpx: element.weft_per_cmpx,
            leno_times: element.leno_times,
            leno_minutes: element.leno_minutes,
            leno_per_hour: element.leno_per_hour,
            leno_per_day: element.leno_per_day,
            leno_per_cmpx: element.leno_per_cmpx,
            unselect_times: element.unselect_times,
            unselect_minutes: element.unselect_minutes,
            unselect2_times: element.unselect2_times,
            unselect_per_hour: element.unselect_per_hour,
            unselect_per_day: element.unselect_per_day,
            unselect_per_cmpx: element.unselect_per_cmpx,
            total_times: element.total_times,
            total_minutes: element.total_minutes,
            total2_times: element.total2_times,
            total_per_hour: element.total_per_hour,
            total_per_day: element.total_per_day,
            total_per_cmpx: element.total_per_cmpx,
            style: element.style
          }
          json.TransDetailData.push(loomProductionAddData)
        }
        data['total_prodcut_1000pick'] = totalProdcut1000Pick;
        data['total_product_in_meter'] = totalProductInMeter;
        data['total_run_in_min'] = totalRunInMin;
        data['total_stop_in_min'] = totalStopInMin;

        for (let index = 0; index < stoppageDetails.length; index++) {
          const stopPageDataElement = stoppageDetails[index];
          const stopPageData = {
            company_branch_id: COMPANY_BRANCH_ID,
            company_id: COMPANY_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            weaving_production_loom_master_id: weavingProductionLoomMasterId,
            loom_production_code: cmb_loom_production_code,
            loom_production_date: dt_loom_production_date,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            plant_id: cmb_plant_id,
            production_supervisor_id: parseInt(cmb_prod_supervisor_id),
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            created_by: UserName,
            modified_by: weavingProductionLoomMasterId === 0 ? null : UserName,
            weaving_production_loom_stoppage_id: 0,
            shift: stopPageDataElement.shift,
            machine_id: stopPageDataElement.machine_id,
            production_stoppage_reasons_id: stopPageDataElement.production_stoppage_reasons_id,
            stoppage_time: stopPageDataElement.stoppage_time,
            loss_type: stopPageDataElement.loss_type,
            std_stoppage_loss_kg: stopPageDataElement.std_stoppage_loss_kg,
            actual_production_loss_kg: stopPageDataElement.actual_production_loss_kg,
            std_stoppage_loss_per_hour: stopPageDataElement.std_stoppage_loss_per_hour,
            stoppage_production_loss_kg: stopPageDataElement.stoppage_production_loss_kg,
            weaving_production_loom_stoppage_id: stopPageDataElement.weaving_production_loom_stoppage_id,
          };
          json.TransLoomStoppageData.push(stopPageData)
        }

        for (let index = 0; index < wastageDetails.length; index++) {
          const wastageDataElement = wastageDetails[index];
          const wastageData = {
            company_branch_id: COMPANY_BRANCH_ID,
            company_id: COMPANY_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            weaving_production_loom_wastage_id: wastageDataElement.weaving_production_loom_wastage_id,
            weaving_production_loom_master_id: weavingProductionLoomMasterId,
            loom_production_code: cmb_loom_production_code,
            loom_production_date: dt_loom_production_date,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            plant_id: cmb_plant_id,
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            shift: wastageDataElement.shift,
            production_wastage_types_id: wastageDataElement.production_wastage_types_id,
            godown_id: wastageDataElement.godown_id,
            production_wastage_types_type: wastageDataElement.production_wastage_types_type,
            wastage_quantity: wastageDataElement.wastage_quantity,
            created_by: UserName,
            modified_by: weavingProductionLoomMasterId === 0 ? null : UserName,
          };
          json.TransLoomWastageData.push(wastageData)
        }

        if (loomMaterialData.length > 0) {
          let lastIndexOfMaterialMovement = loomMaterialData.length - 1
          if (loomMaterialData[lastIndexOfMaterialMovement].loom_production_set_no !== 0) {

            for (let index = 0; index < loomMaterialData.length; index++) {
              const materialDataElement = loomMaterialData[index];
              const materialMovementData = {
                company_branch_id: COMPANY_BRANCH_ID,
                company_id: COMPANY_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                weaving_production_loom_master_id: 0,
                weaving_production_loom_details_id: 0,
                loom_production_date: dt_loom_production_date,
                loom_production_code: cmb_loom_production_code,
                plant_id: cmb_plant_id,
                prod_month: txt_prod_month,
                prod_year: txt_prod_year,
                shift: materialDataElement.shift,
                section_id: cmb_section_id,
                sub_section_id: cmb_sub_section_id,
                weaving_production_loom_master_id: weavingProductionLoomMasterId,
                weaving_production_loom_details_id: materialDataElement.weaving_production_loom_details_id,
                weaving_production_loom_material_id: materialDataElement.weaving_production_loom_material_id,
                loom_production_order_no: materialDataElement.loom_production_order_no,
                loom_production_set_no: materialDataElement.loom_production_set_no,
                product_material_id: materialDataElement.product_material_id,
                product_material_unit_id: materialDataElement.product_material_unit_id,
                consumption_quantity: materialDataElement.consumption_quantity,
                product_material_quantity: materialDataElement.product_material_quantity,
                material_status: materialDataElement.material_status,
                consumptionQtyInfo: materialDataElement.consumptionQtyInfo,
                created_by: UserName,
                modified_by: weavingProductionLoomMasterId === 0 ? null : UserName,

              };
              if (isApprove) {
                if (clickedBtnType === 'reject') {
                  materialMovementData.material_status = 'R'
                }
                if (clickedBtnType === 'approve') {
                  materialMovementData.material_status = 'A'
                }
              }
              json.TransLoomMaterialData.push(materialMovementData)
            }
          }
        }
        json.TransHeaderData = data;
        console.log('Loom data', json);
        const formData = new FormData()
        formData.append('WeavingProductionLoomData', JSON.stringify(json))
        const forwardData = {
          method: 'POST',
          body: formData,
        }
        const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionLoomMaster/FnAddUpdateRecord/`, forwardData)
        const response = await agentApiCall.json();
        if (response.success === '0') {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          setSuccMsg(response.message)
          setShowSuccessMsgModal(true)
        }
      }
    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };

  //***********************************************Stoppage date and time Start********************************************

  const fetchStyleOptions = async (item) => {
    resetGlobalQuery();
    globalQuery.columns.push("*");
    globalQuery.table = "xt_weaving_production_order_style_details";
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    globalQuery.conditions.push({ field: "set_no", operator: "=", value: item.set_no });
    const getStyleDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    return getStyleDetails;
  };

  const onImportClick = () => {
    importFile.current.click();
  };

  const importedExcelFile = async (event) => {
    setIsLoading(true);
    try {
      if (event.target.files) {
        var file = event.target.files[0];
        const formData = new FormData();
        formData.append(`file`, file)
        const requestOptions = { method: 'POST', body: formData };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/LoomProduction/FnReadExcel`, requestOptions)
        const fetchRes = await apicall.json();
        // console.log(fetchRes)
        if (fetchRes.success === true) {
          const columnMapping = {
            'Shift': 'loom_production_shift',
            'Date': 'loom_production_date',
            'Loom': 'machine_id',
            'Style': 'sort_no',
            'Beam': 'sizing_beam_no',
            'set_no': 'set_no',
            'RPM': 'act_rmp',
            'Effic.(%)': 'act_eff',
            'Run(min)': 'run_in_min',
            'Stop(min)': 'stop_in_min',
            'Product(1000pick)': 'prodcut_1000pick',
            'Product(meter)': 'product_in_meter',
            'AF1': 'air_flow1',
            'AF2': 'air_flow2',
            'Air flow(cfm)': 'air_flow_cfm',
            'Warp(times)': 'warp_times',
            'Warp(min)': 'warp_minutes',
            'Warp(times/h)': 'warp_per_hour',
            'Warp(times/day)': 'warp_per_day',
            'Warp(times/cmpx)': 'warp_per_cmpx',
            'Weft(times)': 'weft_times',
            'Weft(min)': 'weft_minutes',
            'Weft(times/h)': 'weft_per_hour',
            'Weft(times/day)': 'weft_per_day',
            'Weft(times/cmpx)': 'weft_per_cmpx',
            'Leno(times)': 'leno_times',
            'Leno(min)': 'leno_minutes',
            'Leno(times/h)': 'leno_per_hour',
            'Leno(times/day)': 'leno_per_day',
            'Leno(times/cmpx)': 'leno_per_cmpx',
            'UnSelect(times)': 'unselect_times',
            'UnSelect(min)': 'unselect_minutes',
            'UnSelect2(times)': 'unselect2_times',
            'UnSelect(times/h)': 'unselect_per_hour',
            'UnSelect(times/day)': 'unselect_per_day',
            'UnSelect(times/cmpx)': 'unselect_per_cmpx',
            'Total(times)': 'total_times',
            'Total(min)': 'total_minutes',
            'Total2(times)': 'total2_times',
            'Total(times/h)': 'total_per_hour',
            'Total(times/day)': 'total_per_day',
            'Total(times/cmpx)': 'total_per_cmpx',
            'CODE': 'code',
            'REMARKS': 'remarks',
            'BASIC / MONOGRAM / DOBBY': 'weave',
            'EPI': 'epi',
            'PPI': 'ppi',
            'WIDTH ( REED SPACE)': 'width',
            'TARGET EFF': 'target_eff',
            'TARGET RPM': 'target_rpm',
          };
          let firstSheet = fetchRes.formFieldAllSheetData['Sheet 1'].data.map(row => {
            let rowData = {};
            Object.keys(columnMapping).forEach(colName => {
              rowData[columnMapping[colName]] = row[colName];
            });

            if (!rowData['epi']) rowData['epi'] = '0';
            if (!rowData['ppi']) rowData['ppi'] = '0';
            if (!rowData['width']) rowData['width'] = '0';
            if (!rowData['target_rpm']) rowData['target_rpm'] = '0';
            if (!rowData['target_eff']) rowData['target_eff'] = '0';
            if (rowData['weave'] === undefined || rowData['weave'] === null || rowData['weave'] === '') {
              // console.log("Setting weave to ' '");
              rowData['weave'] = ' ';
            }
            if (rowData['code'] === undefined || rowData['code'] === null || rowData['code'] === '') {
              // console.log("Setting code to ' '");
              rowData['code'] = ' ';
            }
            if (rowData['remarks'] === undefined || rowData['remarks'] === null || rowData['remarks'] === '') rowData['remarks'] = ' ';

            return rowData;
          });
          const options = await Promise.all(firstSheet.map((item) => fetchStyleOptions(item)));
          setAllStyleDetails(options);

          setStopCountData(firstSheet || []);
          setDataImportFileName(fetchRes.filename)

        } else {
          setErrMsg(fetchRes.message)
          setShowErrorMsgModal(true)
          console.error(fetchRes.error);
        }
      }
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };




  useLayoutEffect(() => {
    if (keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || isApprove) {
      const getExistingStopCountData = [...stopCountData];
      getExistingStopCountData.push(loomBlankObject);
      setStopCountData(getExistingStopCountData);

    }
  }, [rowCount])

  const loomBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    financial_year: FINANCIAL_SHORT_NAME,
    set_no: 0,
    machine_id: 0,
    code: " ",
    loom_production_date: today(),
    loom_production_shift: 0,
    production_operator_id: 0,
    godown_id: 0,
    prod_month: " ",
    prod_year: "",
    sizing_beam_no: 0,
    sort_no: 0,
    epi: 0,
    ppi: 0,
    width: 0,
    target_rpm: 0,
    target_eff: 0,
    weave: 0,
    act_rmp: 0,
    act_eff: 0,
    run_in_min: 0,
    stop_in_min: 0,
    prodcut_1000pick: 0,
    product_in_meter: 0,
    air_flow1: 0,
    air_flow2: 0,
    air_flow_cfm: 0,
    warp_times: 0,
    warp_minutes: 0,
    warp_per_hour: 0,
    warp_per_day: 0,
    warp_per_cmpx: 0,
    weft_times: 0,
    weft_minutes: 0,
    weft_per_hour: 0,
    weft_per_day: 0,
    weft_per_cmpx: 0,
    leno_times: 0,
    leno_minutes: 0,
    leno_per_day: 0,
    leno_per_hour: 0,
    leno_per_cmpx: 0,
    unselect_times: 0,
    unselect_minutes: 0,
    unselect2_times: 0,
    unselect_per_hour: 0,
    unselect_per_day: 0,
    unselect_per_cmpx: 0,
    total_times: 0,
    total_minutes: 0,
    total2_times: 0,
    total_per_hour: 0,
    total_per_day: 0,
    total_per_cmpx: 0,
    remarks: " ",
  };

  const FnUpdateStopCountDateAndTimeTblRows = async (propertyName, currentMaterial, event, index) => {
    let entered_Value = 0;
    let arrayIndex = index;
    if (propertyName !== 'production_operator_id') {
      entered_Value = event.target.value;
    }

    if (propertyName === 'set_no' && event._reactName === 'onBlur') {
      const getStyles = await fetchStyleOptions(currentMaterial);
      const updatedStyleOptions = [...styleDetails];
      updatedStyleOptions[arrayIndex] = getStyles;
      setAllStyleDetails(updatedStyleOptions);
    }

    switch (propertyName) {
      case 'loom_production_date':
      case 'loom_production_shift':
      case 'set_no':
      case 'sizing_beam_no':
      case 'epi':
      case 'ppi':
      case 'width':
      case 'target_rpm':
      case 'target_eff':
      case 'product_in_meter':
      case 'warp_minutes':
      case 'weft_minutes':
      case 'leno_minutes':
      case 'unselect_times':
      case 'unselect_minutes':
      case 'total_times':
      case 'total_minutes':
      case 'godown_id':
      case 'machine_id':
      case 'set_no':
        currentMaterial[propertyName] = entered_Value;
        delete event.target.parentElement.dataset.tip;
        break;
      case 'production_operator_id':
        currentMaterial[propertyName] = event.field_id;
        break;

      case 'sort_no':
        currentMaterial[propertyName] = entered_Value;
        delete event.target.parentElement.dataset.tip;
        break;
      case 'code':
        currentMaterial[propertyName] = entered_Value;
        delete event.target.parentElement.dataset.tip;
        break;
      case 'weave':
        currentMaterial[propertyName] = entered_Value;
        delete event.target.parentElement.dataset.tip;
        break;
      case 'style':
        currentMaterial[propertyName] = entered_Value;
        break;
      case 'remarks':
        currentMaterial[propertyName] = entered_Value;
        delete event.target.parentElement.dataset.tip;
        break;

      case 'run_in_min':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);

        if (currentMaterial['run_in_min'] === 0) {
          currentMaterial['run_in_min'] = 0;
        } else {

          let runTime = parseFloat(currentMaterial['run_in_min']);
          let stopTime = parseFloat(currentMaterial['stop_in_min']);

          let calEffic = (runTime + stopTime) !== 0 ? parseFloat(100 * runTime) / (runTime + stopTime) : NaN;
          currentMaterial['act_eff'] = isNaN(calEffic) ? 'N/A' : validateNumberDateInput.current.decimalNumber(calEffic.toString(), 2);

          let calRunMin = parseFloat(1000 * currentMaterial['prodcut_1000pick']) / currentMaterial['run_in_min'];
          currentMaterial['act_rmp'] = validateNumberDateInput.current.decimalNumber(calRunMin.toString(), 2);

          let calWarpRun = parseFloat(60 * currentMaterial['warp_times']) / currentMaterial['run_in_min'];
          currentMaterial['warp_per_hour'] = validateNumberDateInput.current.decimalNumber(calWarpRun.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calWarpParDay = parseFloat(60 * 24 * currentMaterial['warp_times']) / currentMaterial['run_in_min'];
          currentMaterial['warp_per_day'] = validateNumberDateInput.current.decimalNumber(calWarpParDay.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calWeftTimeH = parseFloat(60 * currentMaterial['weft_times']) / currentMaterial['run_in_min'];
          currentMaterial['weft_per_hour'] = validateNumberDateInput.current.decimalNumber(calWeftTimeH.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calweftParDay = parseFloat(60 * 24 * currentMaterial['weft_times']) / currentMaterial['run_in_min'];
          currentMaterial['weft_per_day'] = validateNumberDateInput.current.decimalNumber(calweftParDay.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calLenoParh = parseFloat(60 * currentMaterial['leno_times']) / currentMaterial['run_in_min'];
          currentMaterial['leno_per_hour'] = validateNumberDateInput.current.decimalNumber(calLenoParh.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calLenoParDay = parseFloat(60 * 24 * currentMaterial['leno_times']) / currentMaterial['run_in_min'];
          currentMaterial['leno_per_day'] = validateNumberDateInput.current.decimalNumber(calLenoParDay.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calUnselecttimeH = parseFloat(60 * currentMaterial['unselect2_times']) / currentMaterial['run_in_min'];
          currentMaterial['unselect_per_hour'] = validateNumberDateInput.current.decimalNumber(calUnselecttimeH.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calUnselecttimeDay = parseFloat(60 * 24 * currentMaterial['unselect2_times']) / currentMaterial['run_in_min'];
          currentMaterial['unselect_per_day'] = validateNumberDateInput.current.decimalNumber(calUnselecttimeDay.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calTotaletimeH = parseFloat(60 * currentMaterial['total2_times']) / currentMaterial['run_in_min'];
          currentMaterial['total_per_hour'] = validateNumberDateInput.current.decimalNumber(calTotaletimeH.toString(), 2);

          currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
          let calTotaltimeDay = parseFloat(60 * 24 * currentMaterial['total2_times']) / currentMaterial['run_in_min'];
          currentMaterial['total_per_day'] = validateNumberDateInput.current.decimalNumber(calTotaltimeDay.toString(), 2);

        }
        delete event.target.parentElement.dataset.tip;
        break;

      case 'stop_in_min':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let runTime = parseFloat(currentMaterial['run_in_min']);
        let stopTime = parseFloat(currentMaterial['stop_in_min']);

        let calEffic = (runTime + stopTime) !== 0 ? parseFloat(100 * runTime) / (runTime + stopTime) : NaN;
        currentMaterial['act_eff'] = isNaN(calEffic) ? 'N/A' : validateNumberDateInput.current.decimalNumber(calEffic.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'prodcut_1000pick':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calProduct_1000pick = parseFloat(1000 * currentMaterial['prodcut_1000pick']) / currentMaterial['run_in_min'];
        currentMaterial['act_rmp'] = validateNumberDateInput.current.decimalNumber(calProduct_1000pick.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWarpTimesC = parseFloat(100 * currentMaterial['warp_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['warp_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calWarpTimesC.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWeftTimesC = parseFloat(100 * currentMaterial['weft_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['weft_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calWeftTimesC.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let callenotimeCmpx = parseFloat(100 * currentMaterial['leno_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['leno_per_cmpx'] = validateNumberDateInput.current.decimalNumber(callenotimeCmpx.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calUnselectCmpx = parseFloat(100 * currentMaterial['unselect2_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['unselect_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calUnselectCmpx.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calTotalCmpx = parseFloat(100 * currentMaterial['total2_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['total_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calTotalCmpx.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'air_flow1':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calAF1 = parseFloat(currentMaterial['air_flow1']) / currentMaterial['air_flow2'];
        currentMaterial['air_flow_cfm'] = validateNumberDateInput.current.decimalNumber(calAF1.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'air_flow2':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calAF2 = parseFloat(currentMaterial['air_flow1']) / currentMaterial['air_flow2'];
        currentMaterial['air_flow_cfm'] = validateNumberDateInput.current.decimalNumber(calAF2.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'warp_times':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWarpTime = parseFloat(60 * currentMaterial['warp_times']) / currentMaterial['run_in_min'];
        currentMaterial['warp_per_hour'] = validateNumberDateInput.current.decimalNumber(calWarpTime.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWarpTimeParDay = parseFloat(60 * 24 * currentMaterial['warp_times']) / currentMaterial['run_in_min'];
        currentMaterial['warp_per_day'] = validateNumberDateInput.current.decimalNumber(calWarpTimeParDay.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWarpTimeCmpx = parseFloat(100 * currentMaterial['warp_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['warp_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calWarpTimeCmpx.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'weft_times':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWeftParh = parseFloat(60 * currentMaterial['weft_times']) / currentMaterial['run_in_min'];
        currentMaterial['weft_per_hour'] = validateNumberDateInput.current.decimalNumber(calWeftParh.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calweftParDay = parseFloat(60 * 24 * currentMaterial['weft_times']) / currentMaterial['run_in_min'];
        currentMaterial['weft_per_day'] = validateNumberDateInput.current.decimalNumber(calweftParDay.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calWeftTimeCmpx = parseFloat(100 * currentMaterial['weft_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['weft_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calWeftTimeCmpx.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'leno_times':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calLenoParh = parseFloat(60 * currentMaterial['leno_times']) / currentMaterial['run_in_min'];
        currentMaterial['leno_per_hour'] = validateNumberDateInput.current.decimalNumber(calLenoParh.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calLenoParDay = parseFloat(60 * 24 * currentMaterial['leno_times']) / currentMaterial['run_in_min'];
        currentMaterial['leno_per_day'] = validateNumberDateInput.current.decimalNumber(calLenoParDay.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calLenotimeCmpx = parseFloat(100 * currentMaterial['leno_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['leno_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calLenotimeCmpx.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'unselect2_times':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calUnselecttimeH = parseFloat(60 * currentMaterial['unselect2_times']) / currentMaterial['run_in_min'];
        currentMaterial['unselect_per_hour'] = validateNumberDateInput.current.decimalNumber(calUnselecttimeH.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calUnselecttimeDay = parseFloat(60 * 24 * currentMaterial['unselect2_times']) / currentMaterial['run_in_min'];
        currentMaterial['unselect_per_day'] = validateNumberDateInput.current.decimalNumber(calUnselecttimeDay.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calUnselecttimeCmpx = parseFloat(100 * currentMaterial['unselect2_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['unselect_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calUnselecttimeCmpx.toString(), 2);

        delete event.target.parentElement.dataset.tip;
        break;

      case 'total2_times':
        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calTotaletimeH = parseFloat(60 * currentMaterial['total2_times']) / currentMaterial['run_in_min'];
        currentMaterial['total_per_hour'] = validateNumberDateInput.current.decimalNumber(calTotaletimeH.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calTotaltimeDay = parseFloat(60 * 24 * currentMaterial['total2_times']) / currentMaterial['run_in_min'];
        currentMaterial['total_per_day'] = validateNumberDateInput.current.decimalNumber(calTotaltimeDay.toString(), 2);

        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(entered_Value.toString(), 2);
        let calTotal2Cmpx = parseFloat(100 * currentMaterial['total2_times']) / currentMaterial['prodcut_1000pick'];
        currentMaterial['total_per_cmpx'] = validateNumberDateInput.current.decimalNumber(calTotal2Cmpx.toString(), 2);
        delete event.target.parentElement.dataset.tip;
        break;

      default:
        break;

    }
    const stopCountTimeDetails = [...stopCountData]
    stopCountTimeDetails[arrayIndex] = currentMaterial
    setStopCountData(stopCountTimeDetails);
  }

  const renderStopCountTable = useMemo(() => {
    if (!stopCountData) {
      return null;
    }
    return <Table id='stopCountTbl' className={`erp_table ${stopCountData.length !== 0 ? 'display' : 'd-none'}`} bordered striped hover>
      <thead className="erp_table_head">
        <tr>
          {/* <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th> */}
          <th className="erp_table_th">Sr.No</th>
          <th className="erp_table_th">Date</th>
          <th className="erp_table_th">Shift</th>
          <th className="erp_table_th">Machines no.</th>
          <th className="erp_table_th">Machine Operator.</th>
          <th className="erp_table_th">Sort no.</th>
          <th className="erp_table_th">Beam</th>
          <th className="erp_table_th">Set No.</th>
          <th className="erp_table_th">Style</th>
          <th className="erp_table_th">Run(min)</th>
          <th className="erp_table_th">Stop(min)</th>
          <th className="erp_table_th">Product(1000pick)</th>
          <th className="erp_table_th">Product(meter)</th>
          <th className="erp_table_th">RPM</th>
          <th className="erp_table_th">Effic.(%)</th>
          <th className="erp_table_th">AF1</th>
          <th className="erp_table_th">AF2</th>
          <th className="erp_table_th">Air flow(cfm)</th>
          <th className="erp_table_th">Warp(times)</th>
          <th className="erp_table_th">Warp(min)</th>
          <th className="erp_table_th">Warp(times/h)</th>
          <th className="erp_table_th">Warp(times/day)</th>
          <th className="erp_table_th">Warp(times/cmpx)</th>
          <th className="erp_table_th">Weft(times)</th>
          <th className="erp_table_th">Weft(min)</th>
          <th className="erp_table_th">Weft(times/h)</th>
          <th className="erp_table_th">Weft(times/day)</th>
          <th className="erp_table_th">Weft(times/cmpx)</th>
          <th className="erp_table_th">Leno(times)</th>
          <th className="erp_table_th">Leno(min)</th>
          <th className="erp_table_th">Leno(times/h)</th>
          <th className="erp_table_th">Leno(times/day)</th>
          <th className="erp_table_th">Leno(times/cmpx)</th>
          <th className="erp_table_th">UnSelect(times)</th>
          <th className="erp_table_th">UnSelect(min)</th>
          <th className="erp_table_th">UnSelect2(times)</th>
          <th className="erp_table_th">UnSelect(times/h)</th>
          <th className="erp_table_th">UnSelect(times/day)</th>
          <th className="erp_table_th">UnSelect(times/cmpx)</th>
          <th className="erp_table_th">Total(times)</th>
          <th className="erp_table_th">Total(min)</th>
          <th className="erp_table_th">Total2(times)</th>
          <th className="erp_table_th">Total(times/h)</th>
          <th className="erp_table_th">Total(times/day)</th>
          <th className="erp_table_th">Total(times/cmpx)</th>
          <th className="erp_table_th">EPI</th>
          <th className="erp_table_th">PPI</th>
          <th className="erp_table_th">WIDTH</th>
          <th className="erp_table_th">Tar.RPM</th>
          <th className="erp_table_th">Tar.EFF</th>
          <th className="erp_table_th">Weave</th>
          <th className="erp_table_th">Remarks</th>
          <th className="erp_table_th">Code</th>
          <th className="erp_table_th">Godown</th>
        </tr>
      </thead>
      <tbody>
        {stopCountData.map((item, index) =>
          <tr rowindex={index} className="sticky-column">
            <td className='erp_table_td'>
              {index + 1}
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <>
                    <input
                      type="date"
                      className="erp_input_field mb-0"
                      Headers="loom_production_date"
                      id={`loom_production_date_${index}`}
                      value={item.loom_production_date}
                      onChange={(e) => FnUpdateStopCountDateAndTimeTblRows("loom_production_date", item, e, index)}
                    />
                  </>
                  : item.loom_production_date
              }
            </td>

            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span className="erp_input_field_table_txt mb-0" id={`loom_production_shift_${index}`} style={{ backgroundColor: '#e9ecef' }}
                    Headers='loom_production_shift'>{item.loom_production_shift}
                  </span>
                  : item.loom_production_shift
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0"
                    id={`machine_id_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='machine_id'>{item.machine_id}
                  </span>
                  : item.machine_id
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? (
                    <Select
                      ref={selectRef}
                      options={machineOperatorOptions}
                      isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      inputId={`cmb_production_operator_id_${index}`}
                      value={machineOperatorOptions.find(option => option.field_id === item.production_operator_id)}
                      onChange={(selectedOpt) => {
                        selectRef.current = selectedOpt;
                        FnUpdateStopCountDateAndTimeTblRows('production_operator_id', item, selectedOpt, index)
                      }}

                      placeholder="Search Operator here..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          width: '200px', // Set the width of the option box
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          width: '200px', // Set the width of the menu box
                          zIndex: 1000, // Ensure this is higher than the table's zIndex
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 1000, // Ensure this is higher than the table's zIndex
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          width: '160px',
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                      menuPortalTarget={document.body}
                    />
                  ) : item.production_operator_name
              }
            </td>

            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0" id={`sort_no_${index}`} value={item.sort_no}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("sort_no", item, e, index); }} Headers='sort_no' />
                  </>
                  : item.sort_no
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 "
                    id={`sizing_beam_no_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='sizing_beam_no' >{item.sizing_beam_no}
                  </span>
                  : item.sizing_beam_no
              }
            </td>

            <td className='erp_table_td'>
              <input type="text"
                className="erp_input_field_table_txt mb-0"
                id={`set_no_${index}`}
                value={item.set_no}
                style={{ backgroundColor: '#e9ecef', width: '80px' }}
                headers='set_no'
                onChange={(e) => FnUpdateStopCountDateAndTimeTblRows("set_no", item, e, index)}
                onBlur={(e) => FnUpdateStopCountDateAndTimeTblRows("set_no", item, e, index)}
                ref={loadStyleOptions}
              />
            </td>

            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <select id={`style_${index}`} value={item.style} className="erp_input_field_table_txt mb-0"
                    Headers="style" style={{ width: '150px' }}
                    onChange={(e) => FnUpdateStopCountDateAndTimeTblRows("style", item, e, index)}>
                    <option value='' >Select</option>
                    {
                      styleDetails[index]?.map((style) => <option value={style.style_variant1} >{style.style_variant1}</option>)
                    }
                  </select>
                  : item.style
              }
            </td>

            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`run_in_min_${index}`} value={item.run_in_min}
                      style={{ backgroundColor: '#AFE1AF', width: '110px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("run_in_min", item, e, index); }} Headers='run_in_min' />
                  </>
                  : item.run_in_min
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`stop_in_min_${index}`} value={item.stop_in_min}
                      style={{ backgroundColor: '#AFE1AF', width: '110px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("stop_in_min", item, e, index); }} Headers='stop_in_min' />
                  </>
                  : item.stop_in_min
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`prodcut_1000pick_${index}`} value={item.prodcut_1000pick}
                      style={{ backgroundColor: '#AFE1AF', width: '108px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("prodcut_1000pick", item, e, index); }} Headers='prodcut_1000pick' />
                  </>
                  : item.prodcut_1000pick
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`product_in_meter_${index}`} value={item.product_in_meter}
                      style={{ backgroundColor: '#AFE1AF', width: '110px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("product_in_meter", item, e, index); }} Headers='product_in_meter' />
                  </>
                  : item.product_in_meter
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`act_rmp_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='act_rmp' > {item.act_rmp}
                  </span>
                  : item.act_rmp
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`act_eff${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='act_eff' >{item.act_eff}
                  </span>
                  : item.efficiency
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`air_flow1_${index}`} value={item.air_flow1}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("air_flow1", item, e, index); }}
                      Headers='air_flow1' />
                  </>
                  : item.air_flow1
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`air_flow2_${index}`} value={item.air_flow2}
                      style={{ backgroundColor: '#AFE1AF', width: '105px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("air_flow2", item, e, index); }} Headers='air_flow2' />
                  </>
                  : item.air_flow2
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`air_flow_cfm${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='air_flow_cfm' >{item.air_flow_cfm}
                  </span>
                  : item.air_flow_cfm
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`warp_times_${index}`} value={item.warp_times}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("warp_times", item, e, index); }} Headers='warp_times' />
                  </>
                  : item.warp_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`warp_minutes_${index}`} value={item.warp_minutes}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("warp_minutes", item, e, index); }} Headers='warp_minutes' />
                  </>
                  : item.warp_minutes
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`warp_per_hour_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='warp_per_hour' >{item.warp_per_hour}
                  </span>
                  : item.warp_per_hour
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`warp_per_day_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='warp_per_day' >{item.warp_per_day}
                  </span>
                  : item.warp_per_day
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`warp_per_cmpx_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='warp_per_cmpx' > {item.warp_per_cmpx}
                  </span>
                  : item.warp_per_cmpx
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`weft_times_${index}`} value={item.weft_times}
                      style={{ backgroundColor: '#AFE1AF', width: '105px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("weft_times", item, e, index); }} Headers='weft_times' />
                  </>
                  : item.weft_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`weft_minutes_${index}`} value={item.weft_minutes}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("weft_minutes", item, e, index); }} Headers='weft_minutes' />
                  </>
                  : item.weft_minutes
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`weft_per_hour_${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='weft_per_hour' > {item.weft_per_hour}
                  </span>
                  : item.weft_per_hour
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`weft_per_day_${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='weft_per_day' >{item.weft_per_day}
                  </span>
                  : item.weft_per_day
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`weft_per_cmpx_${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='weft_per_cmpx' >{item.weft_per_cmpx}
                  </span>
                  : item.weft_per_cmpx
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`leno_times_${index}`} value={item.leno_times}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("leno_times", item, e, index); }} Headers='leno_times' />
                  </>
                  : item.leno_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`leno_minutes_${index}`} value={item.leno_minutes}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("leno_minutes", item, e, index); }} Headers='leno_minutes' />
                  </>
                  : item.leno_minutes
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`leno_per_hour_${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='leno_per_hour' >{item.leno_per_hour}
                  </span>
                  : item.leno_per_hour
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`leno_per_day_${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='leno_per_day' >{item.leno_per_day}
                  </span>
                  : item.leno_per_day
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`leno_per_cmpx_${index}`}
                    style={{ backgroundColor: '#e9ecef', width: '100px' }}
                    Headers='leno_per_cmpx' >{item.leno_per_cmpx}
                  </span>
                  : item.leno_per_cmpx
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`unselect_times_${index}`} value={item.unselect_times}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("unselect_times", item, e, index); }} Headers='unselect_times' />
                  </>
                  : item.unselect_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`unselect_minutes_${index}`} value={item.unselect_minutes}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("unselect_minutes", item, e, index); }} Headers='unselect_minutes' />
                  </>
                  : item.unselect_minutes
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`unselect2_times_${index}`} value={item.unselect2_times}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("unselect2_times", item, e, index); }} Headers='unselect2_times' />
                  </>
                  : item.unselect2_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`unselect_per_hour_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='unselect_per_hour' >{item.unselect_per_hour}
                  </span>
                  : item.unselect_per_hour
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`unselect_per_day_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='unselect_per_day' >{item.unselect_per_day}
                  </span>
                  : item.unselect_per_day
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`unselect_per_cmpx_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='unselect_per_cmpx' >{item.unselect_per_cmpx}
                  </span>
                  : item.unSelectTimesCmpx
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`total_times_${index}`} value={item.total_times}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("total_times", item, e, index); }} Headers='total_times' />
                  </>
                  : item.total_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`total_minutes_${index}`} value={item.total_minutes}
                      style={{ backgroundColor: '#AFE1AF', width: '110x' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("total_minutes", item, e, index); }} Headers='total_minutes' />
                  </>
                  : item.total_minutes
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`total2_times_${index}`} value={item.total2_times}
                      style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("total2_times", item, e, index); }} Headers='total2_times' />
                  </>
                  : item.total2_times
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`total_per_hour_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='total_per_hour' >{item.total_per_hour}
                  </span>
                  : item.total_per_hour
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`total_per_day_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='total_per_day' >{item.total_per_day}
                  </span>
                  : item.total_per_day
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`total_per_cmpx_${index}`}
                    style={{ backgroundColor: '#e9ecef' }}
                    Headers='total_per_cmpx' >{item.total_per_cmpx}
                  </span>
                  : item.total_per_cmpx
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`epi_${index}`} value={item.epi}
                      style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("epi", item, e, index); }} Headers='epi' />
                  </>
                  : item.epi
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`ppi_${index}`} value={item.ppi}
                      style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("ppi", item, e, index); }} Headers='ppi' />
                  </>
                  : item.ppi
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end "
                      id={`width_${index}`} value={item.width}
                      style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("width", item, e, index); }} Headers='width' />
                  </>
                  : item.width
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`target_rpm_${index}`} value={item.target_rpm}
                      style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("target_rpm", item, e, index); }} Headers='target_rpm' />
                  </>
                  : item.target_rpm
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end"
                      id={`target_eff_${index}`} value={item.target_eff}
                      style={{ backgroundColor: '#AFE1AF', width: '105px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("target_eff", item, e, index); }} Headers='target_eff' />
                  </>
                  : item.target_eff
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0 "
                      style={{ backgroundColor: '#AFE1AF', width: '105px' }}
                      id={`weave_${index}`} value={item.weave}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("weave", item, e, index); }} Headers='weave' />
                  </>
                  : item.weave
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0"
                      id={`remarks_${index}`} value={item.remarks}
                      style={{ backgroundColor: '#AFE1AF', width: '110px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("remarks", item, e, index); }} Headers='remarks' />
                  </>
                  : item.remarks
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                  ? <>
                    <input type="text" className="erp_input_field_table_txt mb-0"
                      id={`code_${index}`} value={item.code}
                      style={{ backgroundColor: '#AFE1AF', width: '70px' }}
                      onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("code", item, e, index); }} Headers='code' />
                  </>
                  : item.code
              }
            </td>
            <td className='erp_table_td'>
              {
                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                  ? <select id={`godown_id_${index}`} value={item.godown_id} className="erp_input_field_table_txt mb-0"
                    Headers="godown_id" style={{ width: '150px' }}
                    onChange={(e) => FnUpdateStopCountDateAndTimeTblRows("godown_id", item, e)}
                  >
                    <option value=" " >Select</option>
                    {godownNameOptions?.map((godowntype) => (
                      <option key={godowntype.field_id} value={godowntype.field_id}>
                        {godowntype.godown_name}
                      </option>
                    ))}
                  </select>
                  : item.godown_name
              }
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  }, [stopCountData, godownNameOptions, machineOperatorOptions])

  //*******************************************************Machine Stoppage Details*****************************************************

  const clearFormFields = (form_type) => {

    switch (form_type) {
      case 'stoppage':
        setMachineNo('')
        setMachineStoppageReason('')
        setMachineStoppageTime('')
        setStdProdLoss('')
        setLossType('')
        setActProdLoss('')
        setStoppageProductionLossKg('')
        setStdStoppageLossPerHour('')
        break;
      case 'wastage':
        setProdWastageType('');
        setWastageQuantity('');
        // setProdShift('')
        setGodownId('')
        $('#wastageFormId input, #wastageFormId select').val('');
        break;
    }
  }

  const FnUpdateStoppageReason = async (row_data, index_Of_Row, action) => {

    if (action === 'update') {
      setLabelForStopage('Update')
    } else {
      setLabelForStopage('Add')
    }
    const machineStoppageDetails = [...stoppageDetails];
    let ringFrameDetails = [...ringFrameProdShiftData];
    switch (action) {
      case 'add':
        const validateStoppageReason = await validate.current.validateForm('stoppageFormId');
        if (validateStoppageReason) {
          const isDuplicate = machineStoppageDetails.some(stoppageDetails =>
            stoppageDetails.machine_id === cmb_machine_no &&
            stoppageDetails.production_stoppage_reasons_id === cmb_stoppage_reason &&
            stoppageDetails.shift === cmb_prod_shift &&
            stoppageDetails.stoppage_time === txt_stoppage_time &&
            stoppageDetails.loss_type === cmb_loss_type &&
            stoppageDetails.std_stoppage_loss_per_hour === txt_std_stoppage_loss_per_hour &&
            stoppageDetails.stoppage_production_loss_kg === txt_stoppage_production_loss_kg &&
            stoppageDetails.std_stoppage_loss_kg === txt_std_stoppage_loss_kg &&
            stoppageDetails.actual_production_loss_kg === txt_actual_production_loss_kg
          );
          if (isDuplicate) {
            $('#error_cmb_stoppage_reason').text('Stoppage Reason already exists...').css({ 'display': 'block', 'margin-top': '8px' });
          } else {
            $('#error_cmb_stoppage_reason').hide();

            ///To Update Row
            const currentRowForUpdate = machineStoppageDetails.find((stoppage_Detail, index) => (
              prevdataindexforStoppage === index
            ));
            if (currentRowForUpdate) {
              currentRowForUpdate.machine_id = cmb_machine_no;
              currentRowForUpdate.machine_name = $('#cmb_machine_no option:selected').text();
              currentRowForUpdate.production_stoppage_reasons_id = cmb_stoppage_reason;
              currentRowForUpdate.production_stoppage_reasons_name = $('#cmb_stoppage_reason option:selected').text();
              currentRowForUpdate.shift = $('#cmb_prod_shift option:selected').text();
              currentRowForUpdate.loss_type = $('#cmb_loss_type option:selected').text();
              currentRowForUpdate.stoppage_time = txt_stoppage_time;

              currentRowForUpdate.std_stoppage_loss_kg = txt_stoppage_production_loss_kg;
              currentRowForUpdate.actual_production_loss_kg = txt_actual_production_loss_kg;
              setPrevDataIndexForStoppage('');
            }
            if (!currentRowForUpdate) {
              const newStoppageDetail = {
                machine_id: cmb_machine_no,
                machine_name: $('#cmb_machine_no option:selected').text(),
                production_stoppage_reasons_id: cmb_stoppage_reason,
                production_stoppage_reasons_name: $('#cmb_stoppage_reason option:selected').text(),
                shift: $('#cmb_prod_shift option:selected').text(),
                stoppage_time: txt_stoppage_time,
                loss_type: cmb_loss_type,
                std_stoppage_loss_kg: txt_std_stoppage_loss_kg,
                actual_production_loss_kg: txt_actual_production_loss_kg,
                std_stoppage_loss_per_hour: txt_std_stoppage_loss_per_hour,
                stoppage_production_loss_kg: txt_stoppage_production_loss_kg,
              };
              machineStoppageDetails.push(newStoppageDetail);
            }
            const ringFrameIdMatchedArray = machineStoppageDetails.filter(frameDetails => frameDetails.machine_id === cmb_machine_no);
            const total_StoppageTime = ringFrameIdMatchedArray.reduce((total, ring_FrameId) =>
              total + parseInt(ring_FrameId.stoppage_time), 0);

            const matchedMachineNo = ringFrameIdMatchedArray[0]?.machine_id;
            const parsedMachineNo = parseInt(matchedMachineNo);

            if (!isNaN(parsedMachineNo)) {
              const findMachineId = ringFrameDetails.find(frameDetails => frameDetails.machine_id === parsedMachineNo);

              if (findMachineId) {
                findMachineId.total_stoppage_time = String(total_StoppageTime);
              }
            }
            clearFormFields('stoppage');
          }
        }
        break;
      case 'update':
        setMachineNo(row_data.machine_id);
        setMachineStoppageReason(row_data.production_stoppage_reasons_id);
        setMachineStoppageTime(row_data.stoppage_time);
        setLossType(row_data.loss_type);
        setProdShift(row_data.shift);
        setStdProdLoss(row_data.std_stoppage_loss_kg);
        setActProdLoss(row_data.actual_production_loss_kg);
        setStoppageProductionLossKg(row_data.stoppage_production_loss_kg);
        setStdStoppageLossPerHour(row_data.std_stoppage_loss_per_hour);
        setPrevDataIndexForStoppage(index_Of_Row);
        break;

      case 'remove':
        const findIdtoBeRemove = machineStoppageDetails.findIndex(stoppageDetails => (
          stoppageDetails.machine_id === row_data.machine_id && stoppageDetails.production_stoppage_reasons_id === row_data.production_stoppage_reasons_id && stoppageDetails.stoppage_time === row_data.stoppage_time &&
          stoppageDetails.loss_type === row_data.loss_type &&
          stoppageDetails.std_stoppage_loss_kg === row_data.std_stoppage_loss_kg &&
          stoppageDetails.actual_production_loss_kg === row_data.actual_production_loss_kg &&
          stoppageDetails.stoppage_production_loss_kg === row_data.stoppage_production_loss_kg &&
          stoppageDetails.std_stoppage_loss_per_hour === row_data.std_stoppage_loss_per_hour
        ));
        if (findIdtoBeRemove !== -1) {
          machineStoppageDetails.splice(findIdtoBeRemove, 1);
        }
        clearFormFields('stoppage');
        break;
    }
    setStoppageDetails(machineStoppageDetails);
    setRingFrameProdShiftData(ringFrameDetails);
  }

  const validateFormFields = (formname) => {
    switch (formname) {
      case 'loomMaster':
        validate.current.validateFieldsOnChange('loomProductionMasterId');
        break;
      case 'Stoppage Form':
        validate.current.validateFieldsOnChange('stoppageFormId');
        break;
      case 'Wastage Form':
        validate.current.validateFieldsOnChange('wastageFormId');
        break;
    }
  }

  const calculateProdLoss = async (event) => {
    let prod_shift_Details = [...ringFrameProdShiftData];
    let machine_id = cmb_machine_no;
    let tot_Stoppage_Time = event.target.value;
    setMachineStoppageTime(validateNumberDateInput.current.decimalNumber(tot_Stoppage_Time, 4));

    const req_Id = await prod_shift_Details.find((shiftDetails) => {
      return shiftDetails.machine_id === parseInt(machine_id)
    }
    );

    if (req_Id) {
      let stdProdCountLossPerMinute = req_Id.kgs_target / 720;
      let stdProdLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(stdProdCountLossPerMinute * tot_Stoppage_Time), 4);
      setStdProdLoss(stdProdLoss);
      setStdStoppageLossPerHour(stdProdLoss);
      setStoppageProductionLossKg(stdProdLoss);
      let actProdCountLossPerMinute = req_Id.kgs_actual / 720;
      let actProdLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(actProdCountLossPerMinute * tot_Stoppage_Time), 4);
      setActProdLoss(actProdLoss);
    }
    setRingFrameProdShiftData(prod_shift_Details);
  };


  //********************Material movement for loom************************* */

  const renderMaterialNameList = async (setno, index) => {
    let MaterialObject = document.getElementById(`product_material_id_${index}`);

    MaterialObject.innerHTML = '<option value="">Select</option>';
    MaterialObject.insertAdjacentHTML('beforeend', '<option value="0">Add New Record +</option>');

    resetGlobalQuery();
    globalQuery.columns = ['stock_quantity', 'product_material_name', 'product_material_id']
    globalQuery.table = "xtv_weaving_production_material";
    globalQuery.conditions.push({ field: "production_sub_section_name", operator: "=", value: 'Weaving (Weaving)', });
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "set_no", operator: "=", value: setno, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

    const materialApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    FnFillMaterialOptions(materialApiCall, MaterialObject)
  }

  /// update material movement records
  const FnUpdateLoomMaterialMovementRecords = async (currentProductTypeGroup, e, index) => {

    let clickedColName = e.target.getAttribute('Headers');
    delete e.target.parentElement.dataset.tip;
    let enteredValue = e.target.value;

    const updatePtGroupData = [...loomMaterialData]

    switch (clickedColName) {
      case 'loom_production_date':
        currentProductTypeGroup['loom_production_date'] = enteredValue;
        break;
      case 'loom_production_set_no':
        currentProductTypeGroup.product_material_quantity = 0
        currentProductTypeGroup.consumption_quantity = 0
        currentProductTypeGroup.product_loom_material_balance_quantity = 0
        currentProductTypeGroup.product_material_unit_name = ''
        if (enteredValue !== '') {
          currentProductTypeGroup[clickedColName] = enteredValue;
          setsetNO(enteredValue)
          await renderMaterialNameList(enteredValue, index)
          setRowIndex(index)
          const selectedSetNO = setNoOptions.find(setNo => setNo.set_no === enteredValue);
          currentProductTypeGroup.loom_production_order_no = selectedSetNO.weaving_order_no
          setWarpingOrderNO(selectedSetNO.weaving_order_no)

        } else {
          currentProductTypeGroup[clickedColName] = enteredValue;
          currentProductTypeGroup.loom_production_order_no = 0
          currentProductTypeGroup.product_material_quantity = 0
          currentProductTypeGroup.product_material_unit_name = ''
          currentProductTypeGroup.product_material_unit_id = 0
          currentProductTypeGroup.product_material_id = ''
        }
        break;


      case 'product_material_id':
        const combinationExists = loomMaterialData.find(item =>
          item.product_material_id === enteredValue &&
          item.loom_production_set_no === currentProductTypeGroup.loom_production_set_no &&
          item.shift === currentProductTypeGroup.shift
        );
        if (combinationExists) {
          e.target.parentElement.dataset.tip = 'Shift and set number combination already in use. Please select a different combination...!';
          currentProductTypeGroup[clickedColName] = "";
          break;
        }
        else {
          if (e.target.value === '0') {
            setHeaderName('Warping Order')
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css("padding-top", "0px");
            }, 200)
          } else {
            currentProductTypeGroup[clickedColName] = e.target.value;
            const availableQty = FnConsumptionChange(e.target.value, index, updatePtGroupData)
            currentProductTypeGroup['stock_quantity'] = availableQty
            let setNO = currentProductTypeGroup.loom_production_set_no;
            resetGlobalQuery();
            globalQuery.columns.push('*');
            globalQuery.table = "xtv_weaving_production_material";
            globalQuery.conditions.push({ field: "production_sub_section_name", operator: "=", value: 'Weaving (Weaving)', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: enteredValue, });
            globalQuery.conditions.push({ field: "set_no", operator: "=", value: setNO, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const productMaterialNameApicall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const selectedMaterialName = productMaterialNameApicall.find(material => material.product_material_id === enteredValue);

            currentProductTypeGroup.product_material_quantity = selectedMaterialName.product_material_quantity
            currentProductTypeGroup.product_loom_material_balance_quantity = selectedMaterialName.product_loom_material_balance_quantity
            currentProductTypeGroup.product_material_unit_name = selectedMaterialName.product_material_unit_name
            currentProductTypeGroup.product_material_unit_id = selectedMaterialName.product_material_unit_id
            currentProductTypeGroup.product_material_id = selectedMaterialName.product_material_id
            currentProductTypeGroup.loom_production_order_no = selectedMaterialName.production_order_no

            const selectedMaterialEntry = loomMaterialData.find(entry => entry.product_material_id === enteredValue);
            if (selectedMaterialEntry) {
              const totalProductQuantity = selectedMaterialEntry.product_loom_material_balance_quantity;
              const entriesWithSameSetNo = loomMaterialData.filter(entry => entry.loom_production_set_no === selectedMaterialName.set_no && entry.product_material_id === enteredValue);

              // Calculate total consumption quantity
              const totalConsumptionQuantity = entriesWithSameSetNo.reduce((total, entry) => {
                return total + parseInt(entry.consumption_quantity);
              }, 0);

              // Check if there are duplicates in loomMaterialData    
              const existingEntry = loomMaterialData.find(entry => entry.loom_production_set_no === selectedMaterialName.set_no && entry.product_material_id === enteredValue);
              if (existingEntry) {
                currentProductTypeGroup.product_loom_material_balance_quantity = totalProductQuantity - totalConsumptionQuantity;
                // If the balance quantity is negative, set it to 0
                if (currentProductTypeGroup.product_loom_material_balance_quantity < 0) {
                  currentProductTypeGroup.product_loom_material_balance_quantity = 0;
                }

              }
            }
          }
        }
        break;

      case 'shift':
        delete e.target.parentElement.dataset.tip;
        if (e.target.value !== '') {
          const combinationExists = loomMaterialData.find(item =>
            item.shift === e.target.value && item.loom_production_set_no === currentProductTypeGroup.loom_production_set_no
          );
          if (combinationExists) {
            e.target.parentElement.dataset.tip = 'Shift and set number combination already in use. Please select a different combination...!';
            currentProductTypeGroup[clickedColName] = "";
            break;
          } else {
            currentProductTypeGroup[clickedColName] = e.target.value
          }
        } else {
          currentProductTypeGroup[clickedColName] = e.target.value;
        }
        break;

      case 'consumption_quantity':
        if (e.target.value !== '0') {
          if (e.target.value > currentProductTypeGroup.product_loom_material_balance_quantity) {
            e.target.parentElement.dataset.tip = 'Consumption Quantity cant exceed Balance Quantity material Qunatity...!'
            currentProductTypeGroup[clickedColName] = validateNumberDateInput.current.decimalNumber((currentProductTypeGroup.product_loom_material_balance_quantity).toString(), 4);
            setTimeout(function () {
              delete e.target.parentElement.dataset.tip;
            }, 3000);
          } else {
            currentProductTypeGroup[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
            const remainingQty = FnConsumptionChange(currentProductTypeGroup.product_material_id, index, updatePtGroupData)
            if (remainingQty < 0) {
              e.target.parentElement.dataset.tip = 'Consumption Quantity cannot be greater than stock qunatity...!'
              return;
            }
            delete e.target.parentElement.dataset.tip;
          }
        }
        break;
      case 'material_status':
        currentProductTypeGroup[clickedColName] = e.target.value;
        delete e.target.parentElement.dataset.tip;
        break
      case 'status_remark':
        currentProductTypeGroup[clickedColName] = e.target.value;
        delete e.target.parentElement.dataset.tip;
        break
      default:
        break;
    }

    // const updatePtGroupData = [...loomMaterialData]
    const productTypeIndexArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
    updatePtGroupData[productTypeIndexArray] = currentProductTypeGroup;
    setLoomMaterialData(updatePtGroupData);
  }

  const FnConsumptionChange = (product_material_id, index, updatePtGroupData) => {
    const object = document.getElementById(`product_material_id_${index}`)
    const selectedOption = object.options[object.selectedIndex]; // object is your select element
    const initialAvailableQty = selectedOption.getAttribute('stock_quantity');

    const totalConsumedQty = updatePtGroupData.filter(item => item.product_material_id === product_material_id).reduce((acc, curr) => {
      const consumptionQty = parseFloat(curr.consumption_quantity) || 0; // Parse as float or default to 0 if it's not a valid number
      return acc + consumptionQty;
    }, 0);
    const remainingQty = parseFloat(initialAvailableQty) - totalConsumedQty;
    updatePtGroupData.filter(item => item.product_material_id === product_material_id)
      .forEach(item => {
        item.stock_quantity = remainingQty
      })
    return remainingQty;
  };


  /// Add new row for material
  const setRowCountAndAddRow = (rowCount) => {
    // debugger
    const tableRows = document.querySelectorAll('#loomMaterialTbl tbody tr');
    let lastRowSetNo = '';
    let lastRowShift = '';
    let lastRowMaterial = '';
    let lastRowConsumptionQty = '';

    if (tableRows.length > 0) {
      const lastRowIndex = tableRows.length - 1;
      const lastRowSelectSetNo = tableRows[lastRowIndex].querySelector('select[id^="loom_production_set_no_"]');
      if (lastRowSelectSetNo) {
        lastRowSetNo = lastRowSelectSetNo.value;
      }
      const lastRowSelectMaterial = tableRows[lastRowIndex].querySelector('select[id^="product_material_id_"]');
      if (lastRowSelectMaterial) {
        lastRowMaterial = lastRowSelectMaterial.value;
      }
      const lastRowSelectShift = tableRows[lastRowIndex].querySelector('select[id^="shift_"]');
      if (lastRowSelectShift) {
        lastRowShift = lastRowSelectShift.value;
      }
      const lastConsumptionQty = tableRows[lastRowIndex].querySelector('input[id^="consumption_quantity_"]');
      if (lastConsumptionQty) {
        lastRowConsumptionQty = lastConsumptionQty.value;
      }
    }

    if (lastRowSetNo !== '' && lastRowShift !== '' && lastRowMaterial !== '' && (lastRowConsumptionQty !== '' && lastRowConsumptionQty !== '0')) {
      const getExistingLoomProductionData = [...loomMaterialData]
      getExistingLoomProductionData.push(loomMaterialBlankObject)
      setLoomMaterialData(getExistingLoomProductionData)
      setRowCount(rowCount + 1);
    } else {
      const lastRow = tableRows[tableRows.length - 1];
      const setNoInput = lastRow.querySelector('select[id^="loom_production_set_no_"]');
      const shiftInput = lastRow.querySelector('select[id^="shift_"]');
      const materialName = lastRow.querySelector('select[id^="product_material_id_"]');
      const consumptionQty = lastRow.querySelector('input[id^="consumption_quantity_"]');


      if (shiftInput) {
        const selectedOption = shiftInput.options[shiftInput.selectedIndex];
        if (!selectedOption || !selectedOption.value) {
          shiftInput.parentElement.dataset.tip = 'Please select Shift';
          shiftInput.focus();
          return;
        } else {
          delete shiftInput.parentElement.dataset.tip;
        }
      }
      if (setNoInput) {
        const selectedOption = setNoInput.options[setNoInput.selectedIndex];
        if (!selectedOption || !selectedOption.value) {
          setNoInput.parentElement.dataset.tip = 'Please select SetNO.';
          setNoInput.focus();
          return;
        } else {
          delete setNoInput.parentElement.dataset.tip;
        }
      }

      if (materialName) {
        const selectedOption = materialName.options[materialName.selectedIndex];
        if (!selectedOption || !selectedOption.value) {
          materialName.parentElement.dataset.tip = 'Please select Material Name';
          materialName.focus();
          return;
        } else {
          delete materialName.parentElement.dataset.tip;
        }
      }
      if (consumptionQty.value === '0') {
        consumptionQty.parentElement.dataset.tip = 'Please add consumption Quntity';
        consumptionQty.focus();
        return;
      } else {
        delete consumptionQty.parentElement.dataset.tip;
      }
    }
  };


  /// Remove material row
  const removeFirstRow = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const loomMaterialDetails = [...loomMaterialData]
      const element = loomMaterialDetails[indexToRemove]
      const updatedLoomProductionData = loomMaterialData.filter((item, index) => index !== indexToRemove);
      // Update consumed qty of removed row materials

      const removedIndex = updatedLoomProductionData.findIndex(item => item.product_material_id === element.product_material_id)
      if (removedIndex !== -1) {
        FnConsumptionChange(element.product_material_id, removedIndex, updatedLoomProductionData)
      }
      setLoomMaterialData(updatedLoomProductionData)
    } else {
      const updatedLoomProductionData = [...loomMaterialData];
      updatedLoomProductionData[0] = { ...loomMaterialBlankObject };
      setLoomMaterialData(updatedLoomProductionData);
    }
  }

  const FnValidateForm = async () => {
    let validStatus = true;
    const tableRows1 = document.querySelectorAll('#loomMaterialTbl tbody tr');
    tableRows1.forEach(row => {
      const statusRemark = row.querySelector('input[id^="status_remark_"]');
      const materialStatus = row.querySelector(`select[id^="material_status_"]`);
      const status = statusRemark.value.trim();
      const materialStaturValue = materialStatus.value.trim();

      if (materialStaturValue === "R") {
        if (status === '') {
          statusRemark.parentElement.dataset.tip = 'Please enter the Rejection remark...!';
          statusRemark.focus();
          validStatus = false;
          return;
        } else {
          delete statusRemark.parentElement.dataset.tip;
        }
      } else {
        delete statusRemark.parentElement.dataset.tip;
      }

    });
    return validStatus;
  };


  //----------------- combobox for material name ------------------------------------
  const FnFillMaterialOptions = (options, object) => {
    options.forEach(item => {
      const optionElement = document.createElement('option');
      optionElement.value = item.product_material_id;
      optionElement.text = item.product_material_name;
      optionElement.setAttribute('stock_quantity', item.stock_quantity);
      object.appendChild(optionElement);
    });
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Warping Order':
        return <TGenericAddmaterialProductionfrm generatedSZNo={warpingOrderNo} setNO={setNo} keyForViewUpdate={'update'} productionmaterialDate={dt_loom_production_date} subSectionId={cmb_sub_section_id} />;
      default:
        return null;
    }
  }
  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Warping Order':
        await renderMaterialNameList(setNo, RowIndexNo)
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 100)
  }


  // -------------------------------Stock consumption starts here---------------------------------------

  // Material quantity consumption 
  const FnMaterialConsumption = async (item, rowIndex) => {
    try {
      resetGlobalQuery();
      globalQuery.columns = ['godown_name', 'godown_section_name', 'godown_section_beans_name', 'closing_balance_quantity'
        , 'closing_balance_weight', 'product_unit_name', 'product_rm_id', "product_rm_name",
        "godown_id", "godown_section_id", "godown_section_beans_id"];
      globalQuery.table = "smv_product_rm_stock_summary";
      globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: item.product_material_id });
      // globalQuery.conditions.push({ field: "closing_balance_quantity", operator: "!=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const stockList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

      let totalConsumptionQty = item.consumption_quantity

      // This is for existing selected stock for godown
      if (item.hasOwnProperty('consumptionQtyInfo')) {
        item.consumptionQtyInfo?.map(consumeQty => {
          let totalConsumptionQtyGdWise = 0;

          let stockDetail = stockList.find(item => item.godown_id === consumeQty.godown_id
            && item.godown_section_id === consumeQty.godown_section_id && item.godown_section_beans_id === consumeQty.godown_section_beans_id)

          loomMaterialData.filter(item => item.product_material_id === consumeQty.product_material_id)
            .forEach(item => {
              if (item.consumptionQtyInfo) {
                item.consumptionQtyInfo.filter(filterConumptionInfo =>
                  filterConumptionInfo.product_material_id === consumeQty.product_material_id &&
                  filterConumptionInfo.godown_id === consumeQty.godown_id &&
                  filterConumptionInfo.godown_section_id === consumeQty.godown_section_id &&
                  filterConumptionInfo.godown_section_beans_id === consumeQty.godown_section_beans_id
                ).map(consumptionQtyInfo => {
                  const { consumption_quantity } = consumptionQtyInfo
                  totalConsumptionQtyGdWise += parseFloat(consumption_quantity)

                })
              }
            })

          if (stockDetail) {
            stockDetail.consumptionQtyInfo = consumeQty
            stockDetail.closing_balance_quantity = stockDetail.closing_balance_quantity - totalConsumptionQtyGdWise + parseFloat(consumeQty.consumption_quantity)
            // Accessing the index of stockDetail in stockList
            const stockIndex = stockList.indexOf(stockDetail);
            stockList[stockIndex] = stockDetail
          }


        })
      }
      // This is for new stock of godown
      else {
        loomMaterialData
          .filter(item => item.product_material_id === item.product_material_id)
          .forEach(item => {
            if (item.consumptionQtyInfo) {
              item.consumptionQtyInfo.map(consumptionQtyInfo => {
                const { product_material_id, godown_id, godown_section_id, godown_section_beans_id, consumption_quantity } = consumptionQtyInfo
                const stockIndex = stockList.findIndex(particularMaterialGd =>
                  particularMaterialGd.product_rm_id === product_material_id &&
                  particularMaterialGd.godown_id === godown_id &&
                  particularMaterialGd.godown_section_id === godown_section_id &&
                  particularMaterialGd.godown_section_beans_id === godown_section_beans_id
                );

                const getStockObject = stockIndex !== -1 ? stockList[stockIndex] : null;

                if (getStockObject) {
                  // Update key-value pair in getStockObject
                  getStockObject.closing_balance_quantity = getStockObject.closing_balance_quantity - consumption_quantity;
                  // Set the updated object back to the stockList array
                  stockList[getStockObject.index] = getStockObject;
                }
              })
            }
          })
      }

      stockList.map((stocks) => {
        const consumption_quantity = totalConsumptionQty > 0 ? Math.min(stocks.closing_balance_quantity, totalConsumptionQty) : 0;
        stocks.consumptionQtyInfo = {
          index: rowIndex,
          shift: item.shift,
          set_no: item.set_no,
          product_material_id: item.product_material_id,
          consumption_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(consumption_quantity), 4),
          godown_id: stocks.godown_id,
          godown_section_id: stocks.godown_section_id,
          godown_section_beans_id: stocks.godown_section_beans_id
        };
        totalConsumptionQty -= stocks.closing_balance_quantity;
      })

      console.log(stockList);
      setMaterialName($(`#product_material_id_${rowIndex} option:selected`).text())
      setUnitName(item.product_material_unit_name)
      setConsumptionQty(item.consumption_quantity)
      setMaterialStockList(stockList)
      setShow(true)

    } catch (error) {
      console.log(error);
    }
  }

  const FnMaterialWiseStockQtyConsumption = (item, event, index) => {
    const stockData = [...materialStockList]
    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = validateNumberDateInput.current.decimalNumber(event.target.value, 4)

    if (enteredValue > item.closing_balance_quantity) {
      let actualvalidation = document.querySelector(`#${event.target.id}`);
      actualvalidation.parentElement.dataset.tip = `Consumption quantity cannot be greater than stock quantity...!`;
      return false;
    } else {
      item[clickedColName] = enteredValue;
      item.consumptionQtyInfo.consumption_quantity = enteredValue;
      delete event.target.parentElement.dataset.tip;
      stockData[index] = item
      setMaterialStockList(stockData);
    }
  }

  const FnCloseStockConsumptionModal = () => {
    const stockData = [...materialStockList]
    const loomMovementData = [...loomMaterialData]

    // Check consumption quantity & stock qty matches or not validation
    const validateConsumptionQty = FnValidateStockConsumption()

    if (validateConsumptionQty) {
      const wpIndex = stockData[0]?.consumptionQtyInfo.index;
      if (wpIndex >= 0 && wpIndex < loomMovementData.length) {
        if (!loomMovementData[wpIndex].consumptionQtyInfo) {
          loomMovementData[wpIndex].consumptionQtyInfo = [];
        } else {
          loomMovementData[wpIndex].consumptionQtyInfo = [];
        }
      } else {
        console.error(`Index ${wpIndex} is out of range.`);
      }

      stockData.forEach(stock => {
        loomMovementData[wpIndex].consumptionQtyInfo.push(stock.consumptionQtyInfo);
      });
      console.log(loomMovementData);
      setLoomMaterialData(loomMovementData)
      setShow(false)
    }
  };

  const FnValidateStockConsumption = () => {
    let erorMsgObj = document.querySelector(`#stock_consumption_error`);
    const stockData = [...materialStockList]
    let total_consumption_qty = stockData.reduce((total, item) => total + parseFloat(item.consumptionQtyInfo.consumption_quantity), 0)
    $('#stock_consumption_error').empty()
    if (total_consumption_qty < parseFloat(consumptionQty)) {
      erorMsgObj.textContent = "Total stock consumption quantity is less than consumption quantity...!";
      $('#stock_consumption_error').show();
      return false;
    } else if (total_consumption_qty > parseFloat(consumptionQty)) {
      if (erorMsgObj) {
        erorMsgObj.textContent = "Total stock consumption quantity cannot be greater than consumption quantity...!";
        $('#stock_consumption_error').show();
      }
      return false;
    }

    $('#stock_consumption_error').hide();
    return true;
  }

  /// Table for stoppage
  const renderMachineStoppageTable = useMemo(() => {
    return <Table id="machinestoppagetableId" style={{ display: "block", overflow: "auto" }} bordered striped>
      <thead className="erp_table_head">
        <tr>
          <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
          <td className="erp_table_th" style={{ width: "150px" }}>Machine No</td>
          <td className="erp_table_th" style={{ width: "300px" }}>Stoppage Reason</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Shift</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Loss Type</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Stoppage Time</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Std. Prod. Loss</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Act. Prod. Loss</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Stoppage Prod.Loss Kg</td>
          <td className="erp_table_th" style={{ width: "100px" }}>Std.Stopp. Loss/KG</td>
        </tr>
      </thead>
      <tbody>
        {stoppageDetails.length > 0 ? (
          stoppageDetails.map((details, indexOfItem) => (
            <tr key={indexOfItem}>
              <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateStoppageReason(details, indexOfItem, 'update')} />
                <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateStoppageReason(details, indexOfItem, 'remove')} />
              </td>
              <td className="erp_table_td">{details.machine_name}</td>
              <td className="erp_table_td">{details.production_stoppage_reasons_name}</td>
              <td className="erp_table_td">{details.shift}</td>
              <td className="erp_table_td">{details.loss_type}</td>
              <td className="erp_table_td">{details.stoppage_time}</td>
              <td className="erp_table_td">{details.std_stoppage_loss_kg}</td>
              <td className="erp_table_td">{details.actual_production_loss_kg}</td>
              <td className="erp_table_td">{details.std_stoppage_loss_per_hour}</td>
              <td className="erp_table_td">{details.stoppage_production_loss_kg}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="10">
              <Card className="erp_no_recrd_card">
                <Card.Body className="text-center">No records found...</Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </tbody>
    </Table>

  }, [stoppageDetails]);


  //********************************************Machine Wastage Details******************************************
  const FnUpdateWastageReason = async (row_data, index_Of_Row, action) => {
    if (action === 'update') {
      setLabelForWastage('Update');
      setProdWastageType(row_data.production_wastage_types_id);
      setWastageQuantity(row_data.wastage_quantity);
      setProdShift(row_data.shift);
      setGodownId(row_data.godown_id);
      setPrevDataIndexForWastage(index_Of_Row);
    } else {
      setLabelForWastage('Add')
      const machineWastageDetails = [...wastageDetails];
      const ringFrameDetails = [...ringFrameProdShiftData];
      switch (action) {
        case 'add':
          const validateWastageReason = await validate.current.validateForm('wastageFormId');
          if (validateWastageReason) {
            const isDuplicate = machineWastageDetails.some(wastageDetails =>
              wastageDetails.production_wastage_types_id === cmb_prod_wastage_type &&
              wastageDetails.wastage_quantity === txt_wastage_quantity &&
              wastageDetails.shift === cmb_prod_shift &&
              wastageDetails.godown_id === cmb_godown_id
            );
            if (isDuplicate) {
              $('#error_cmb_prod_wastage_type').text('Wastage Type already exists...').css({ 'display': 'block', 'margin-top': '8px' });
            } else {
              $('#error_cmb_prod_wastage_type').hide();
              ///To Update Row
              const currentRowForUpdate = machineWastageDetails.find((wastage_Details, index) => (
                prevdataindexforWastage === index
              ));
              if (currentRowForUpdate) {
                currentRowForUpdate.production_wastage_types_id = cmb_prod_wastage_type;
                currentRowForUpdate.production_wastage_types_type = $('#cmb_prod_wastage_type option:selected').text();
                currentRowForUpdate.wastage_quantity = txt_wastage_quantity;
                currentRowForUpdate.shift = cmb_prod_shift;
                currentRowForUpdate.godown_id = cmb_godown_id;
                currentRowForUpdate.godown_name = $('#cmb_godown_id option:selected').text();
                setPrevDataIndexForWastage('');
              }
              if (!currentRowForUpdate) {
                const newWastageDetail = {
                  production_wastage_types_id: cmb_prod_wastage_type,
                  production_wastage_types_type: $('#cmb_prod_wastage_type option:selected').text(),
                  wastage_quantity: txt_wastage_quantity,
                  godown_id: cmb_godown_id,
                  godown_name: $('#cmb_godown_id option:selected').text(),
                  shift: cmb_prod_shift
                };
                machineWastageDetails.push(newWastageDetail);
              }
              clearFormFields('wastage');
            }
          }
          break;
        case 'remove':
          const findIdtoBeRemove = machineWastageDetails.findIndex(wastageDetails => (
            wastageDetails.production_wastage_types_id === row_data.production_wastage_types_id &&
            wastageDetails.wastage_quantity === row_data.wastage_quantity &&
            wastageDetails.shift === row_data.shift &&
            wastageDetails.godown_id === row_data.godown_id
          ));
          if (findIdtoBeRemove !== -1) {
            machineWastageDetails.splice(findIdtoBeRemove, 1);
          }
          clearFormFields('wastage');
          break;
      }
      setWastageDetails(machineWastageDetails);
      setRingFrameProdShiftData(ringFrameDetails);
    }
  }


  const renderMachineWastageTable = useMemo(() => {
    return <Table id="machinewastagetableId" style={{ display: "block", width: '600px', overflow: "auto" }} bordered striped>
      <thead className="erp_table_head">
        <tr>
          <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>Action</td>
          <td className="erp_table_th " style={{ width: "150px" }}>Wastage Type</td>
          <td className="erp_table_th " style={{ width: "200px" }}>Wastage Quantity</td>
          <th className="erp_table_th" style={{ width: "150px" }}>Godown</th>
          <td className="erp_table_th" style={{ width: "100px" }}>Shift</td>
        </tr>
      </thead>
      <tbody>
        {wastageDetails.length > 0 ? (
          wastageDetails?.map((details, indexOfItem) => (
            <tr key={indexOfItem}>
              <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'update')} />
                <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'remove')} />
              </td>
              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.production_wastage_types_type}
              </td>
              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.wastage_quantity}
              </td>

              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.godown_name}
              </td>

              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.shift}
              </td>

            </tr>
          ))) : (
          <tr>
            <td colSpan="10">
              <Card className="erp_no_recrd_card">
                <Card.Body className="text-center">No records found...</Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </tbody>
    </Table>

  }, [wastageDetails]);




  return (
    <>
      <ComboBox ref={combobox} />
      <ComboBox ref={comboDataAPiCall} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <GenerateMaterialId ref={generateAutoNoAPiCall} />
      <FrmValidations ref={validate} />
      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress />
              <span>Loading...</span>
            </div>
          </div> : null}

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Loom Production{actionType} </label>
          </div>
          <form id='loomProductionMasterId'>
            <div className='row p-1'>
              {/* 1 st Column */}
              <div className='col-sm-4 erp_form_col_div'>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Prod. Month <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_prod_month' className="erp_input_field" value={txt_prod_month} disabled />
                    <MDTypography variant="button" id="error_txt_prod_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod. Year <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_prod_year' className="erp_input_field" value={txt_prod_year} disabled />
                    <MDTypography variant="button" id="error_txt_prod_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Prod.Plant Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFormFields('loomMaster'); }}>
                      <option value="" disabled>Select</option>
                      {plantOptions.length !== 0 ? (
                        <>{plantOptions?.map(plant => (
                          <option value={plant.field_id}>{plant.field_name}</option>
                        ))} </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod.Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id'); validateFormFields('loomMaster'); }}>
                      <option value="">Select</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map(production => (
                            <option value={production.field_id}>{production.field_name}</option>
                          ))}
                        </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* ********2ed col******** */}
              <div className='col-sm-4 erp_form_col_div'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod. Sub Section<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { setProdSubSection(e.target.value); fillAutoNo(); validateFormFields('loomMaster'); }}>
                      <option value="">Select</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map(subproduction => (
                            <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_type} value={subproduction.field_id}>
                              {subproduction.field_name}
                            </option>
                          ))}
                        </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Prod. Date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id='dt_loom_production_date' className="erp_input_field" value={dt_loom_production_date} onChange={(e) => { setMonthYear(); fillAutoNo(); validateFormFields('loomMaster'); }} />
                    <MDTypography variant="button" id="error_dt_loom_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod. Shift <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_prod_shift" className="form-select form-select-sm" value={cmb_prod_shift} onChange={(e) => { setProdShift(e.target.value); comboOnChange("shift"); validateFormFields('loomMaster'); }}>
                      <option value="">Select</option>
                      {shiftOptions.length !== 0 ? (
                        <>
                          {shiftOptions?.map(shift => (
                            <option value={shift.field_name}>{shift.field_name}</option>
                          ))}
                        </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_prod_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Loom Prod.Code<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field" id="cmb_loom_production_code" maxLength="5" value={cmb_loom_production_code} disabled onInput={() => validateFormFields('loomMaster')} />
                    <MDTypography variant="button" id="error_cmb_loom_production_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* *******3rd col******** */}
              <div className='col-sm-4 erp_form_col_div'>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod.supervisor <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Select ref={cmb_prod_supervisor_id_ref}
                      options={machineOperatorOptions}
                      isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      inputId="cmb_prod_supervisor_id" // Provide the ID for the input box
                      value={machineOperatorOptions.length > 1 ? machineOperatorOptions.find(option => option.value === cmb_prod_supervisor_id) : null}
                      onChange={(selectedOpt) => {
                        setProdSupervisor(selectedOpt.value);
                        cmb_prod_supervisor_id_ref.current = selectedOpt;
                        comboOnChange("supervisor");
                      }}
                      placeholder="Search Supervisor here..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_prod_supervisor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Loom Prod.Status<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_loom_production_master_status" className="form-select form-select-sm" value={cmb_loom_production_master_status} onChange={(e) => { setProductionLoomStatus(e.target.value); }} optional='optional' disabled  >
                      <option value="P">Pending</option>
                      <option value="A">Approved</option>
                      <option value="R">Rejected</option>
                      <option value="C">Completed</option>
                      <option value="X">Canceled</option>
                      <option value="I">Partial</option>
                    </select>
                    <MDTypography variant="button" id="error_cmb_loom_production_master_status" className='erp_validation' fontWeight="regular" color="error" style={{ display: 'none' }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Status Remark</Form.Label>
                  </div>
                  <div className="col-sm-8">
                    <Form.Control as="textarea" id='txt_loom_production_remark' className="erp_input_field_table_txt" value={txt_loom_production_remark} onChange={e => { setStatusremark(e.target.value); }} optional='optional' />
                    <MDTypography variant="button" id="error_txt_loom_production_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-md-11 align-self-end pt-2">
                    <input
                      type="file"
                      id="Excelfile"
                      ref={importFile}
                      onChange={importedExcelFile}
                      style={{ display: "none" }}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || isApprove ? { disabled: 'disabled', optional: "optional" } : {})}
                    />
                    <MDButton type="button" id="import-btn" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || isApprove ? 'd-none' : 'display'}`} variant="button" onClick={e => { onImportClick(); validateFormFields('loomMaster') }} fontWeight="regular">Import File</MDButton>
                    <MDTypography variant="button" id="error_Excelfile" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <hr />

          <Accordion defaultActiveKey='1'>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="erp-form-label-md">Stop Count And time</Accordion.Header>
              <Accordion.Body>
                {
                  stopCountData.length > 0
                    ? <>
                      <div className={`table-responsive ${stopCountData.length > 0 ? 'erp_table_scroll' : ''}`}>
                        {renderStopCountTable}
                      </div>
                    </>
                    :
                    <>
                      <div className='row text-center'>
                        <div className="col-12">
                          <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                        </div>
                      </div>
                    </>
                }
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr />
          {stopCountData.length === 0 ? null : (
            <>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="erp-form-label-md">Loom Material Movement</Accordion.Header>
                  <Accordion.Body>
                    <Table id='loomMaterialTbl' className={`erp_table ${loomMaterialData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
                      <thead className="erp_table_head">
                        <tr>
                          <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                          <th className="erp_table_th">Sr no .</th>
                          <th className="erp_table_th">Date</th>
                          <th className="erp_table_th">Shift</th>
                          <th className="erp_table_th">Set no</th>
                          <th className="erp_table_th">Material Name</th>
                          <th className="erp_table_th">Material Unit</th>
                          <th className="erp_table_th">Quantity</th>
                          <th className="erp_table_th">Balance</th>
                          <th className="erp_table_th">Stock Qty</th>
                          <th className="erp_table_th">Consumption Quantity</th>
                          <th className="erp_table_th">Material Status</th>
                          <th className="erp_table_th">Status Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loomMaterialData.map((item, index) =>

                          <tr rowindex={index} className="sticky-column">
                            <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                              <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                              <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(index)} />
                            </td>

                            <td className='erp_table_td'>
                              {index + 1}
                            </td>
                            <td className='erp_table_td'>
                              <>
                                <input
                                  type="date"
                                  className="erp_input_field mb-0"
                                  disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}
                                  Headers="loom_production_date"
                                  id={`loom_production_date_${index}`}
                                  value={item.loom_production_date}
                                  onChange={(e) => FnUpdateLoomMaterialMovementRecords(item, e, index)}
                                />
                              </>

                            </td>
                            <td className={`erp_table_td `}>
                              {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                  ? <select id={`shift_${index}`} value={item.shift} className="erp_input_field_table_txt mb-0"
                                    Headers="shift"
                                    disabled={item.material_status_desc === 'Approved'}
                                    onChange={(e) => FnUpdateLoomMaterialMovementRecords(item, e, index)}
                                  >
                                    <option value="">Select</option>
                                    {shiftOptions?.map(shift => (
                                      <option value={shift.field_name}>{shift.field_name}</option>
                                    ))}
                                  </select>
                                  : item.shift
                              }
                            </td>



                            <td className={`erp_table_td `}>
                              {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                  ? <select id={`loom_production_set_no_${index}`} value={item.loom_production_set_no} className="erp_input_field_table_txt mb-0"
                                    Headers="loom_production_set_no"
                                    disabled={item.material_status_desc === 'Approved'}
                                    onChange={(e) => { FnUpdateLoomMaterialMovementRecords(item, e, index); }}
                                  >
                                    <option value="">Select</option>
                                    {
                                      //array in ascending order
                                      setNoOptions.sort((a, b) => a.set_no - b.set_no).map(setNO => (
                                        <option value={setNO.set_no}>{setNO.set_no}</option>
                                      ))}

                                  </select>
                                  : item.loom_production_set_no
                              }
                            </td>
                            <td className={`erp_table_td`}>
                              <select className="form-select form-select-sm mb-0" id={`product_material_id_${index}`} value={item.product_material_id}
                                onChange={(e) => { FnUpdateLoomMaterialMovementRecords(item, e, index); }}
                                disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}
                                Headers='product_material_id' >
                                <option value="">Select</option>
                              </select>
                            </td>

                            <td className='erp_table_td'>
                              {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                  ? <span className="erp_input_field_table_txt mb-0" id={`product_material_unit_name_${index}`}>{item.product_material_unit_name}</span>
                                  : item.product_material_unit_name
                              }
                            </td>

                            <td className='erp_table_td'>
                              {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                  ? <input className="erp_input_field_table_txt mb-0" id={`product_material_quantity_${index}`}
                                    Headers="product_material_quantity"
                                    value={item.product_material_quantity}
                                  />
                                  : item.product_material_quantity
                              }
                            </td>
                            <td className='erp_table_td'>
                              {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                  ? <input className="erp_input_field_table_txt mb-0" id={`product_loom_material_balance_quantity_${index}`}
                                    Headers="product_loom_material_balance_quantity"
                                    value={item.product_loom_material_balance_quantity}
                                  />
                                  : item.product_loom_material_balance_quantity
                              }
                            </td>
                            <td className={`erp_table_td`}>{item?.stock_quantity ? item.stock_quantity : 0}</td>
                            <td className='erp_table_td'>
                              {
                                keyForViewUpdate !== 'view'
                                  ? <div className='d-flex' style={{ alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      className="erp_input_field_table_txt remove0 mb-0 ms-1"
                                      id={`consumption_quantity_${index}`}
                                      value={item.consumption_quantity}
                                      Headers="consumption_quantity"
                                      style={{ backgroundColor: '#AFE1AF' }}
                                      onChange={(e) => { FnUpdateLoomMaterialMovementRecords(item, e, index); }}
                                      disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                      maxLength='19'
                                    />
                                    <FaDatabase onClick={() => FnMaterialConsumption(item, index)} />
                                  </div>
                                  : item.consumption_quantity
                              }
                            </td>

                            <td className="erp_table_td">
                              <select id={`material_status_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.material_status}
                                disabled={isApprove ? item.material_status_desc === 'Approved' : true}
                                onChange={(e) => { FnUpdateLoomMaterialMovementRecords(item, e, index); FnValidateForm(); }}
                                Headers='material_status'>
                                <option value="P">Pending</option>
                                <option value="A">Approved</option>
                                <option value="R">Rejected</option>
                                <option value="I">Partial Issue</option>
                                <option value="C">Completed</option>
                                <option value="X">Canceled</option>
                                <option value="Z">PreeClosed</option>
                              </select>
                            </td>

                            <td className='erp_table_td'>
                              {keyForViewUpdate === 'approve' ? (
                                <input
                                  type="text"
                                  id={`status_remark_${index}`}
                                  disabled={!isApprove}
                                  className="erp_input_field_table_txt mb-0"
                                  value={item.status_remark}
                                  Headers='status_remark'
                                  onChange={(e) => {
                                    FnUpdateLoomMaterialMovementRecords(item, e, index);

                                  }}
                                />
                              ) : (
                                item.status_remark
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* Add new Record Popup */}
              <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                <Modal.Body className='erp_city_modal_body'>
                  <div className='row'>
                    <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                  </div>
                  {displayRecordComponent()}

                </Modal.Body>
              </Modal >


              {show ?
                <Modal show={show} onHide={closeConsumptionModal} centered size="lg">
                  <Modal.Header closeButton style={{ fontSize: '11px' }}>
                    <Modal.Title style={{ fontSize: '20px', color: '#045cb4' }}>Material Consumption</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ fontSize: '15px' }}>
                    <dl className='row'>
                      <dt className="col-sm-2 erp-form-label-md">Material & Unit: </dt>
                      <dd className="col-sm-4 erp-form-label-md">{materialName} - {unitName}</dd>
                      <dt className="col-sm-2 erp-form-label-md">Consumption Qty: </dt>
                      <dd className="col-sm-4 erp-form-label-md">{consumptionQty}</dd>
                    </dl>

                    {materialStockList.length !== 0 ? (
                      <>
                        <Table striped bordered hover>
                          <thead className='erp_table_head'>
                            <tr>
                              <th className="erp_table_th">Location</th>
                              <th className="erp_table_th">Available Qty</th>
                              <th className="erp_table_th">Consumption Qty</th>
                            </tr>
                          </thead>
                          <tbody>
                            {materialStockList.map((item, index) => (
                              <tr key={index}>

                                <td className='erp_table_td erp-form-label-md'>
                                  {item.godown_section_name ? item.godown_section_beans_name ?
                                    `${item.godown_name + '[' + item.godown_section_name + ']' + '[' + item.godown_section_beans_name + ']'}`
                                    : `${item.godown_name + '[' + item.godown_section_name + ']'}` : item.godown_name}
                                </td>
                                <td className='erp_table_td'>{item.closing_balance_quantity}</td>
                                <td className='erp_table_td'>
                                  <input
                                    type="text"
                                    value={item?.consumptionQtyInfo?.consumption_quantity}
                                    className="erp_input_field mb-0"
                                    disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}
                                    Headers="stock_consumption_quantity"
                                    id={`stock_consumption_quantity_${index}`}
                                    onChange={(event) => FnMaterialWiseStockQtyConsumption(item, event, index)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <MDTypography variant="button" id="stock_consumption_error" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </>
                    ) : (
                      <div>Not available stock!...</div>
                    )}
                    <div className='text-center mt-1'>

                      <Button className={`erp-gb-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} onClick={FnCloseStockConsumptionModal} >
                        Ok
                      </Button>
                    </div>
                  </Modal.Body>

                </Modal>
                : null}
              <hr />
              <Accordion defaultActiveKey='1'>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="erp-form-label-md">Shiftwise Machine Stoppage Entry</Accordion.Header>
                  <Accordion.Body>
                    <form id='stoppageFormId'>
                      <div className='row p-1'>
                        {/* 1 st Column */}
                        <div className='col-sm-6 erp_form_col_div'>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Machine No <span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-4">
                              <select id="cmb_machine_no" className="form-select form-select-sm" value={cmb_machine_no} onChange={(e) => { setMachineNo(e.target.value); comboOnChange('machineName'); validateFormFields('Stoppage Form'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}>
                                <option value="">Select</option>
                                {machineNameOption.length !== 0 ? (
                                  <>
                                    {machineNameOption?.map(machine => (
                                      <option value={machine.field_id}>{machine.field_name}</option>
                                    ))}
                                  </>
                                ) : null
                                }
                              </select>
                              <MDTypography variant="button" id="error_cmb_machine_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                            <div className='col-sm-4'>
                              <div className="erp_form_radio">
                                <div className="fCheck">
                                  <Form.Check
                                    className="erp_radio_button"
                                    label="Applicable To All Machines"
                                    type="checkbox"
                                    value="0"
                                    name="isTestCertRequired"
                                    defaultChecked
                                    disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Stoppage Reason <span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <select id="cmb_stoppage_reason" className="form-select form-select-sm" value={cmb_stoppage_reason} onChange={(e) => { setMachineStoppageReason(e.target.value); validateFormFields('Stoppage Form'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}>
                                <option value="">Select</option>
                                {stoppagereasonOptions.length !== 0 ? (
                                  <>
                                    {stoppagereasonOptions?.map(stoppage => (
                                      <option value={stoppage.field_id}>{stoppage.field_name}</option>
                                    ))}
                                  </>
                                ) : null
                                }
                              </select>
                              <MDTypography variant="button" id="error_cmb_stoppage_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Shift <span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control id='cmb_prod_shift' className="erp_input_field" value={cmb_prod_shift} disabled />
                              <MDTypography variant="button" id="error_cmb_prod_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Loss Type</Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <select id="cmb_loss_type" className="form-select form-select-sm" value={cmb_loss_type} onChange={(e) => { setLossType(e.target.value); validateFormFields('Stoppage Form'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}  >
                                <option value="">Select</option>
                                <option value="Utilization">Utilization</option>
                                <option value="Efficiency">Efficiency</option>
                                <option value="Others">Others</option>
                              </select>
                              <MDTypography variant="button" id="error_cmb_loss_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Stoppage Time <span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control type="text" id='txt_stoppage_time' className="erp_input_field" value={txt_stoppage_time} onChange={(e) => { calculateProdLoss(e); validateFormFields('Stoppage Form'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'} />
                              <MDTypography variant="button" id="error_txt_stoppage_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Std. Prod. Loss </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control type="text" id='txt_std_stoppage_loss_kg' className="erp_input_field" value={txt_std_stoppage_loss_kg} onChange={(e) => { setStdProdLoss(e.target.value); validateFormFields('Stoppage Form'); }} disabled />
                              <MDTypography variant="button" id="error_txt_std_stoppage_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Act. Prod. Loss </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control type="text" id='txt_actual_production_loss_kg' className="erp_input_field" value={txt_actual_production_loss_kg} onChange={(e) => { setActProdLoss(e.target.value); validateFormFields('Stoppage Form'); }} disabled />
                              <MDTypography variant="button" id="error_txt_actual_production_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Stoppage Prod.Loss Kg </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control type="text" id='txt_stoppage_production_loss_kg' className="erp_input_field" value={txt_stoppage_production_loss_kg} onChange={(e) => { setStoppageProductionLossKg(e.target.value); validateFormFields('Stoppage Form'); }} disabled />
                              <MDTypography variant="button" id="error_stoppage_production_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Std.Stopp. Loss/KG</Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control type="text" id='txt_std_stoppage_loss_per_hour' className="erp_input_field" value={txt_std_stoppage_loss_per_hour} onChange={(e) => { setStdStoppageLossPerHour(e.target.value); }} disabled />
                              <MDTypography variant="button" id="error_std_stoppage_loss_per_hour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col'>
                              <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <MDButton type="button" id="action_Button" className="erp-gb-button ms-2" onClick={() => FnUpdateStoppageReason('', '', 'add')} variant="button" fontWeight="regular">{labelForStoppage}</MDButton>
                                <MDButton type='button' className="erp-gb-button ms-2" variant="button" id='back_Button' onClick={(e) => clearFormFields('stoppage')} fontWeight="regular" >Clear</MDButton>
                              </div >
                            </div >
                          </div >
                        </div>
                        <div className='col-sm-5 erp_form_col_div'>
                          <div className='row'>
                            {renderMachineStoppageTable}
                          </div>
                        </div>
                      </div>
                    </form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <hr />

              <Accordion defaultActiveKey='1'>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="erp-form-label-md">Shiftwise Machine Wastage Entry</Accordion.Header>
                  <Accordion.Body>
                    <form id='wastageFormId'>
                      <div className='row p-1'>
                        <div className='col-sm-6 erp_form_col_div'>
                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Prod. Wastage Type<span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <select id="cmb_prod_wastage_type" className="form-select form-select-sm" value={cmb_prod_wastage_type} onChange={(e) => { setProdWastageType(e.target.value); validateFormFields('Wastage Form') }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}>
                                <option value="">Select</option>
                                {prodWastageOptions.length !== 0 ? (
                                  <>
                                    {prodWastageOptions?.map(machine => (
                                      <option value={machine.field_id}>{machine.field_name}</option>
                                    ))}
                                  </>
                                ) : null
                                }
                              </select>
                              <MDTypography variant="button" id="error_cmb_prod_wastage_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Wastage Quantity<span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control type="text" id='txt_wastage_quantity' className="erp_input_field" value={txt_wastage_quantity} onChange={(e) => { setWastageQuantity(e.target.value); validateFormFields('Wastage Form') }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'} />
                              <MDTypography variant="button" id="error_txt_wastage_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Godown<span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <select id="cmb_godown_id" className="form-select form-select-sm" value={cmb_godown_id} onChange={(e) => { setGodownId(e.target.value); validateFormFields('Wastage Form') }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}>
                                <option value="">Select</option>
                                {godownNameOptions.length !== 0 ? (
                                  <>
                                    {godownNameOptions?.map(godowntype => (
                                      <option key={godowntype.field_id} value={godowntype.field_id}>
                                        {godowntype.godown_name}
                                      </option>
                                    ))}
                                  </>
                                ) : null

                                }
                              </select>
                              <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-3'>
                              <Form.Label className="erp-form-label">Shift <span className="required">*</span> </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <Form.Control id='cmb_prod_shift' className="erp_input_field" value={cmb_prod_shift} disabled />
                              <MDTypography variant="button" id="error_cmb_prod_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                            <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnUpdateWastageReason('', '', 'add')} fontWeight="regular">{labelForWastage}</MDButton>
                            <MDButton type='button' className="erp-gb-button ms-2 disable-button" variant="button" id='back_Button' onClick={(e) => clearFormFields('wastage')} fontWeight="regular" >Clear</MDButton>
                          </div >
                        </div>
                        <div className='col-sm-6 erp_form_col_div'>
                          <div className='row'>
                            {renderMachineWastageTable}
                          </div>
                        </div>
                      </div>
                    </form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Transactions/TLoomProductaion/FrmLoomProductionListing/reg' : '/Transactions/TLoomProductaion/FrmLoomProductionListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" >Back</MDButton>
            <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" BtnType="save" onClick={(e) => addLoomProduction(e)}
              fontWeight="regular">{actionLabel}</MDButton>
            {
              keyForViewUpdate === 'approve'
                ? <>
                  <MDButton type="button" id="reject-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='reject' onClick={(e) => addLoomProduction(e)} fontWeight="regular">Reject</MDButton>
                </>
                : ""
            }
          </div >
        </div >
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
      </DashboardLayout >
    </>
  );
}
export default FrmLoomProduction;
