import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const ShortLeave = React.forwardRef(({ invoiceContent }, ref) => {
    debugger
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };
    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2 mt-3'>
                    <div className="col-sm-2 text-center mt-2">
                        <img src={DakshabhiLogo} alt="master card" width="170px" height="auto" mt={2} />
                    </div>
                    <div className="col-sm-10">
                        <div className='erp-invoice-print-label text-center'>
                            <span className='erp-invoice-print-label-lg' style={{ fontSize: '22px' }}>{invoiceContent.company}</span><br />
                            <span className='erp-invoice-print-label-md' style={{ fontSize: '17px' }}>({invoiceContent.company_branch})</span>

                            <div className='erp-invoice-print-label-lg text-center mt-1' style={{ fontSize: '20px' }}>
                                Short Leave
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row border-bottom border-top border-dark p-0 m-0 mt-3' style={{ fontSize: '20px' }}>
                    <div className="row p-0 m-0 mt-3">
                        <div className="col-sm-12">
                            <dl className="row pb-0 mb-0">

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Short Leave Id:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.short_leave_id}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Employee Name :</dt>
                                <dd className='col-sm-9 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.employee_name}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Short Leave Type :</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.leave_type}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Short Leave Date:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {formatDate(invoiceContent?.short_leave_date)} </dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Department Name:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.department_name}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Sub Department Name:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.sub_department_name}</dd>


                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Approved By:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.approved_by_name}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>out Time:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}>  {invoiceContent.out_time}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', fontSize: '14px' }}>Approved Status:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px' }}> {invoiceContent.approval_status}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg' style={{ marginTop: '20px', marginBottom: '7%', fontSize: '14px' }}>Short Leave Reason:</dt>
                                <dd className='col-sm-3 erp-invoice-print-label' style={{ fontSize: '14px', marginTop: '20px', marginBottom: '7%' }}> {invoiceContent.leave_reason}</dd>
                            </dl>
                        </div>

                        {/* <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ marginTop: '20px' ,fontSize:'14px'}}>Short Leave Date:</dt>
                                <dd className='col-sm-7 erp-invoice-print-label' style={{ fontSize: '14px',marginTop: '20px' }}> {formatDate(invoiceContent?.short_leave_date)} </dd>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ marginTop: '20px',fontSize:'14px' }}>Approved By:</dt>
                                <dd className='col-sm-7 erp-invoice-print-label' style={{fontSize: '14px', marginTop: '20px' }}> {invoiceContent.approved_by_name}</dd>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ marginTop: '20px',fontSize:'14px' }}>Approved Status:</dt>
                                <dd className='col-sm-7 erp-invoice-print-label' style={{ fontSize: '14px',marginTop: '20px' }}> {invoiceContent.approval_status}</dd>
                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ marginTop: '20px',marginBottom: '13%',fontSize:'14px' }}>Short Leave Reason:</dt>
                                <dd className='col-sm-7 erp-invoice-print-label' style={{ fontSize: '14px',marginTop: '20px',marginBottom: '13%' }}> {invoiceContent.leave_reason}</dd>


                            </dl>
                        </div> */}
                    </div>
                </div>
                <div className="invoice-footer" style={{ marginTop: '7%', marginBottom: '30px', fontSize: '14px' }}>
                    <div className='row mt-2'>
                        <div className='col-sm-4'>
                            <div className='align-bottom-center mt-3'>
                                <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: '14px' }}>HOD Signature</dt>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='align-bottom-center mt-3'>
                                <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: '14px' }}>HR Signature</dt>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='align-bottom-center mt-3'>
                                <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: '14px' }}>Security Signature</dt>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr className='m-0 p-0 hr-line' />
        </>
    )


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">
                                <div className="row" style={{ padding: '0px 14px 0px' }}>
                                    {invoiceHeader}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})


export default ShortLeave;

