import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import Accordion from "react-bootstrap/Accordion";
import { Table } from 'react-bootstrap';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import ComboBox from 'Features/ComboBox';

import Select from 'react-select';

function FrmWInspDetailsEntry({ getWeavingInspectionData, inspectionData, machineNameOpt, productionDateopt, ActionLabel, ProductionWInspStatus }) {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { weavingInspectionMasterId = 0, keyForViewUpdate } = state || {}

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //useRef Hooks
    const comboDataAPiCall = useRef();
    const selectRef = useRef(null);
    const combobox = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    const [isApprove, setIsApprove] = useState(false);
    //Warping Production master hooks
    const [txt_weaving_production_warping_master_id, setweavingProductionWarpingMasterId] = useState(weavingInspectionMasterId);
    const [dt_inspection_production_date, setWeavingInspectionDate] = useState(today);
    const [txt_inspection_production_status, setWeavingInspectionStatus] = useState('');

    //Warping Production  details comboboxes
    const [weavingInspectionData, setWeavingInspectionData] = useState(inspectionData !== null && inspectionData.length !== 0 ? inspectionData : [])
    const [rowCount, setRowCount] = useState(1)
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [loomDetailsOptions, setLoomDetailsOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
    const [godownNameOptions, setGodownNameOptions] = useState([]);
    const [allBeamNoList, setAllBeamNos] = useState([]);



    ///Warping Production Hooks
    // const [machineNameOption, setMachineNameOption] = useState(machineNameOpt !== null && machineNameOpt.length !== 0 ? machineNameOpt : []);

    const warpingProductionBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        inspection_production_date: today(),
        inspection_production_set_no: 0,
        shift: 0,
        sort_no: 0,
        production_operator_id: 0,
        actual_count: 0,
        machine_id: 0,
        product_pick: 0,
        sizing_beam_no: 0,
        weight: 0,
        status_remark: '',
        inspection_production_status: 'P',
        product_material_name: '',
        roll_no: 0,
        balance_product_in_meter: 0,
        inspection_mtr: 0,
        width: 0,
        difference: 0,
        average: 0,
        godown_id: 0,
        created_by: UserName,
    }

    useEffect(() => {

        const loadDataOnload = async () => {
            await ActionType()
            await fillComboBox();

            if (inspectionData.length === 0) {
                const getExistingWeavingInspectionData = [...weavingInspectionData]
                getExistingWeavingInspectionData.push(warpingProductionBlankObject)

                setWeavingInspectionData(getExistingWeavingInspectionData)
                getWeavingInspectionData(getExistingWeavingInspectionData)
            }
        }
        loadDataOnload()
    }, [])

    useEffect(() => {
        const FunctionCall = async () => {
            // setMachineNameOption(machineNameOpt);
            const options = await Promise.all(inspectionData.map((item) => fetchBeamNosOptions(item)));
            setAllBeamNos(options);
            setWeavingInspectionDate(productionDateopt)
            setWeavingInspectionData(inspectionData)
        }
        FunctionCall();
    }, [inspectionData, machineNameOpt, productionDateopt])

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setIsApprove(false);
                setActionLabel('Update')
                await validate.current.readOnly("weavingInspection");
                break;
            case 'view':
                setIsApprove(false);
                await validate.current.readOnly("weavingInspection");
                break;
            case 'approve':
                setActionLabel('Approve')
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                setIsApprove(true)
                break;
            default:
                break;
        }
    };

    const fillComboBox = async () => {
        try {
            const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
            setShiftOptions(shiftsApiCall);

            /////Employees in Warping Production
            resetGlobalQuery();
            globalQuery.columns.push("field_id", 'field_name');
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "department_id", operator: "=", value: 29 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
                const prodSupervisorList = [
                    { value: '', label: 'Select' },
                    ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
                ];
                setMachineOperatorOptions(prodSupervisorList);
            })

            resetGlobalQuery();
            globalQuery.columns.push('*');
            globalQuery.table = "xtv_weaving_production_loom_details";
            globalQuery.conditions.push({ field: "loom_production_master_status", operator: "=", value: 'A', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const SectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSetNoOptions(SectionApiCall);

            resetGlobalQuery();
            globalQuery.columns.push('*');
            globalQuery.table = "xtv_weaving_production_order";
            globalQuery.conditions.push({ field: "weaving_order_status", operator: "=", value: 'A', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getLoomDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setLoomDetailsOptions(getLoomDetailsApiCall);


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'godown_name']
            globalQuery.table = "xmv_production_sub_section_godown_mapping"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "production_sub_section_name", operator: "=", value: 'Weaving (Weaving)' });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((godownTypeApiCall) => {
                setGodownNameOptions(godownTypeApiCall)
            })
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const updatedWeavingInspectionDetails = (propertyName, currentMaterial, event, index) => {
        let arrayIndex = index;

        switch (propertyName) {
            case 'balance_product_in_meter':
                if (event._reactName === 'onChange') {

                    if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);

                        let calculationDifference = currentMaterial.balance_product_in_meter - parseFloat(currentMaterial.inspection_mtr);
                        currentMaterial['difference'] = validateNumberDateInput.current.decimalNumber(calculationDifference.toString(), 4);

                        delete event.target.parentElement.dataset.tip;
                        setWeavingInspectionStatus('P');
                        currentMaterial.inspection_production_status = 'P'

                    } else if (parseInt(event.target.value) === 0 || event.target.value === '') {
                        currentMaterial[propertyName] = '';
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    }

                } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                    event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                    $(`#${event.target.id}`).focus();
                }
                break;

            case 'inspection_mtr':
                if (event._reactName === 'onChange') {
                    if (event.target.value !== '' && parseFloat(event.target.value) !== 0) {
                        if (parseFloat(currentMaterial.balance_product_in_meter) < parseFloat(event.target.value)) {
                            event.target.parentElement.dataset.tip = 'Inspection Mtr cant exceed Loom Mtr...!'
                            currentMaterial[propertyName] = currentMaterial.balance_product_in_meter;
                            fnCalculateWeight(currentMaterial)
                        } else {
                            currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                            fnCalculateWeight(currentMaterial)
                            delete event.target.parentElement.dataset.tip;
                        }
                    } else if (event.target.value === '' || parseInt(event.target.value) === 0) {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        currentMaterial[propertyName] = '';
                        fnCalculateWeight(currentMaterial)
                    }
                }

                break;
            case 'weight':
                if (event._reactName === 'onChange') {
                    if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                        let calculationAverage = (currentMaterial.weight / currentMaterial.inspection_mtr) * 1000;
                        currentMaterial['average'] = validateNumberDateInput.current.decimalNumber(calculationAverage.toString(), 4);

                        delete event.target.parentElement.dataset.tip;
                        setWeavingInspectionStatus('P');
                        currentMaterial.inspection_production_status = 'P'
                    } else if (parseInt(event.target.value) === 0 || event.target.value === '') {
                        currentMaterial[propertyName] = '';
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    }
                } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                    event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                    $(`#${event.target.id}`).focus();
                }
                break;
            case 'production_operator_id':
                currentMaterial[propertyName] = event.field_id;
                break;

            case 'inspection_production_date':
                currentMaterial[propertyName] = event.target.value;
                break;
            case 'sort_no':
            case 'sizing_beam_no':
            case 'machine_id':
            case 'product_pick':
            case 'roll_no':
            case 'godown_id':

            case 'shift':
                delete event.target.parentElement.dataset.tip;
                if (event.target.value !== '') {
                    const combinationExists = weavingInspectionData.find(item =>
                        item.inspection_production_set_no === currentMaterial.inspection_production_set_no && item.shift === event.target.value
                        && item.sizing_beam_no === currentMaterial.sizing_beam_no
                    );

                    if (combinationExists) {
                        event.target.parentElement.dataset.tip = '"Shift, Set number & Beam No. combination already in use. Please select a different combination...!';
                        currentMaterial[propertyName] = "";
                        return;
                    } else {
                        currentMaterial[propertyName] = event.target.value
                        delete event.target.parentElement.dataset.tip;
                    }
                } else {
                    currentMaterial[propertyName] = event.target.value;
                    delete event.target.parentElement.dataset.tip;
                }
                break;
            case 'status_remark':
                currentMaterial[propertyName] = event.target.value
                delete event.target.parentElement.dataset.tip;
                break;
            case 'inspection_production_status':

                // On change remove the tooltip on approval remark.
                if (event.target.value === 'R') {
                    currentMaterial['inspection_production_status'] = event.target.value
                    let approvalRemarkField = $(`#status_remark_` + currentMaterial[propertyName]);
                    if (approvalRemarkField.length > 0) {
                        approvalRemarkField[0].parentElement.dataset.tip = 'Please enter the Rejection remark....!'
                    }

                } else {
                    currentMaterial['inspection_production_status'] = event.target.value
                    let approvalRemarkField = $(`#status_remark_` + currentMaterial[propertyName]);
                    if (approvalRemarkField.length > 0) {
                        delete approvalRemarkField[0].parentElement.dataset.tip;
                    }
                }
                break;
            default:
                break;
        }

        const updatedDetailsData = [...weavingInspectionData]
        updatedDetailsData[arrayIndex] = currentMaterial;
        setWeavingInspectionData(updatedDetailsData);
        getWeavingInspectionData(updatedDetailsData)
        fnManageWeavingInspStatus(updatedDetailsData);
    }

    const fnCalculateWeight = (currentMaterial) => {

        let loommtrval = parseFloat(currentMaterial.balance_product_in_meter)
        let inspectionmtrval = parseFloat(currentMaterial.inspection_mtr)
        let weightval = parseFloat(currentMaterial.weight)
        let newDifferenceCalculate = 0;
        let calculationAverage = 0;
        if (loommtrval != '' || inspectionmtrval != '') {
            newDifferenceCalculate = loommtrval - inspectionmtrval
            currentMaterial['difference'] = validateNumberDateInput.current.decimalNumber(newDifferenceCalculate.toString(), 4);
        } else {
            currentMaterial['difference'] = 0;
        }
        if (weightval != '' || inspectionmtrval != '') {
            let divisionOfWeightAndInspecter = (weightval / inspectionmtrval)
            let calculationAverage = divisionOfWeightAndInspecter * 1000
            currentMaterial['average'] = validateNumberDateInput.current.decimalNumber(calculationAverage.toString(), 4);
        } else {
            currentMaterial['average'] = 0;
        }
        return currentMaterial;
    }

    const fnManageWeavingInspStatus = (weavingInspectionData) => {
        if (keyForViewUpdate === 'approve') {
            const allApproved = weavingInspectionData.every(item => item.inspection_production_status === 'A');
            const someRejected = weavingInspectionData.some(item => item.inspection_production_status === 'R');

            if (allApproved) {
                ProductionWInspStatus('A');
                ActionLabel('Approve');
            } else if (someRejected) {
                ProductionWInspStatus('R');
                ActionLabel('Rejected');
            } else {
                const item = weavingInspectionData.find(item => item.inspection_production_status === 'P');
                if (item) {
                    ProductionWInspStatus(item.inspection_production_status);
                    ActionLabel('Save');
                } else {
                    ProductionWInspStatus('P');
                    ActionLabel('Save');
                }
            }
        }
    };

    const fetchBeamNosOptions = async (item) => {
        resetGlobalQuery();
        globalQuery.columns.push("set_no, sizing_beam_no, style, sort_no, width, product_in_meter, prodcut_1000pick, machine_name, machine_id, godown_name, godown_id, balance_product_in_meter");
        globalQuery.table = "xtv_weaving_production_loom_details";
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "set_no", operator: "=", value: item.inspection_production_set_no });
        const getBeamDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        return getBeamDetails;
    };

    const handleSetNoChange = async (currentProductTypeGroup, e) => {
        debugger
        const updatePtGroupData = [...weavingInspectionData]
        const productTypeIndexArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        let clickedColName = e.target.getAttribute('Headers');
        delete e.target.parentElement.dataset.tip;
        let enteredValue = e.target.value;
        switch (clickedColName) {
            case 'inspection_production_set_no':
                if (e.target.value !== '') {
                    currentProductTypeGroup[clickedColName] = parseInt(e.target.value);
                    const selectedSetNO = setNoOptions.find(setNo => setNo.set_no === e.target.value);

                    const selectedsetNoMaterial = loomDetailsOptions.find(setNo => setNo.set_no === selectedSetNO.set_no);
                    if (selectedsetNoMaterial === undefined) {
                        currentProductTypeGroup.set_no = selectedsetNoMaterial ? selectedsetNoMaterial.set_no : 0;

                    } else {
                        currentProductTypeGroup.product_id = selectedsetNoMaterial ? selectedsetNoMaterial.product_material_id : 0;
                        currentProductTypeGroup.product_material_name = selectedsetNoMaterial ? selectedsetNoMaterial.product_material_name : "";
                    }
                    // Get all beam no list in all beams
                    const getBeams = await fetchBeamNosOptions(currentProductTypeGroup);
                    const updatedBeamOptions = [...allBeamNoList];
                    updatedBeamOptions[productTypeIndexArray] = getBeams;
                    setAllBeamNos(updatedBeamOptions);

                }
                //  else {
                //     currentProductTypeGroup.sort_no = 0
                //     currentProductTypeGroup.status_remark = ''
                //     currentProductTypeGroup.inspection_production_status = "P"
                //     currentProductTypeGroup.product_material_id = 0
                //     currentProductTypeGroup.sizing_beam_no = 0
                //     currentProductTypeGroup.width = 0
                //     currentProductTypeGroup.sort_no = 0
                //     currentProductTypeGroup.product_pick = 0
                //     currentProductTypeGroup.balance_product_in_meter = 0
                //     currentProductTypeGroup.machine_id = 0
                //     currentProductTypeGroup.godown_id = 0
                //     currentProductTypeGroup.godown_name = ""
                //     currentProductTypeGroup.machine_name = ""
                //     currentProductTypeGroup.product_material_name = ""
                //     currentProductTypeGroup.inspection_production_set_no = 0
                //     currentProductTypeGroup.difference = 0
                //     currentProductTypeGroup.average = 0
                //     currentProductTypeGroup.inspection_mtr = 0
                //     currentProductTypeGroup.roll_no = 0
                //     currentProductTypeGroup.weight = 0
                // }
                break;

            case 'sizing_beam_no':
                // Check set no , shift, combination is already exist then dont add 
                const combinationExists = weavingInspectionData.find(item =>
                    item.inspection_production_set_no === currentProductTypeGroup.inspection_production_set_no && item.shift === currentProductTypeGroup.shift && item.sizing_beam_no === enteredValue && item.inspection_production_date === currentProductTypeGroup.inspection_production_date
                );

                if (combinationExists) {
                    e.target.parentElement.dataset.tip = '"Shift, Set number & Beam No. combination already in use. Please select a different combination...!';
                    currentProductTypeGroup[clickedColName] = "";
                    return;
                } else {
                    currentProductTypeGroup[clickedColName] = enteredValue;
                }



                // Get data from allBeamList which are comming from loom production
                const getBeamList = allBeamNoList[productTypeIndexArray];
                if (getBeamList !== null && getBeamList.length !== 0) {
                    const getBeamDetailsOfParticularSetNo = getBeamList.find(beam => parseInt(beam.set_no) === parseInt(currentProductTypeGroup.inspection_production_set_no) && beam.sizing_beam_no === currentProductTypeGroup.sizing_beam_no);



                    if (getBeamDetailsOfParticularSetNo) {
                        const { set_no, sizing_beam_no, sort_no, width, product_in_meter, prodcut_1000pick, style,
                            machine_name, machine_id, godown_name, godown_id, balance_product_in_meter } = getBeamDetailsOfParticularSetNo;

                        // Find all entries with the same set_no value
                        const entriesWithSameSetNo = weavingInspectionData.filter(entry => parseInt(entry.inspection_production_set_no) === parseInt(currentProductTypeGroup.inspection_production_set_no) && entry.sizing_beam_no === enteredValue);
                        const totalProductQuantity = product_in_meter;

                        const totalConsumptionQuantity = entriesWithSameSetNo.reduce((total, entry) => {
                            return total + parseInt(entry.inspection_mtr);
                        }, 0);

                        // Check if there are duplicates in inspectionMaterialData    
                        const existingEntry = weavingInspectionData.find(entry => entry.inspection_production_set_no === currentProductTypeGroup.inspection_production_set_no);
                        if (existingEntry) {
                            currentProductTypeGroup.balance_product_in_meter = validateNumberDateInput.current.decimalNumber(totalProductQuantity - totalConsumptionQuantity, 4);
                            if (currentProductTypeGroup.balance_product_in_meter < 0) {
                                currentProductTypeGroup.balance_product_in_meter = 0;
                            }
                        }

                        currentProductTypeGroup.width = width
                        currentProductTypeGroup.sort_no = sort_no
                        currentProductTypeGroup.product_pick = prodcut_1000pick
                        currentProductTypeGroup.balance_product_in_meter = currentProductTypeGroup.balance_product_in_meter === 0 ?
                            product_in_meter
                            : validateNumberDateInput.current.decimalNumber(currentProductTypeGroup.balance_product_in_meter, 4);

                        currentProductTypeGroup.machine_name = machine_name
                        currentProductTypeGroup.product_in_meter = product_in_meter
                        currentProductTypeGroup.machine_id = machine_id
                        currentProductTypeGroup.godown_id = godown_id
                        currentProductTypeGroup.godown_name = godown_name
                        currentProductTypeGroup.inspection_production_set_no = set_no
                        currentProductTypeGroup.style = style
                    }
                } else {
                    currentProductTypeGroup.sort_no = 0
                    currentProductTypeGroup.status_remark = ''
                    currentProductTypeGroup.inspection_production_status = "P"
                    // currentProductTypeGroup.product_material_id = 0
                    // currentProductTypeGroup.sizing_beam_no = 0
                    currentProductTypeGroup.width = 0
                    currentProductTypeGroup.sort_no = 0
                    currentProductTypeGroup.product_pick = 0
                    currentProductTypeGroup.balance_product_in_meter = 0
                    currentProductTypeGroup.machine_id = 0
                    currentProductTypeGroup.godown_id = 0
                    currentProductTypeGroup.godown_name = ""
                    currentProductTypeGroup.machine_name = ""
                    currentProductTypeGroup.product_material_name = ""
                    currentProductTypeGroup.inspection_production_set_no = 0
                    currentProductTypeGroup.difference = 0
                    currentProductTypeGroup.average = 0
                    currentProductTypeGroup.inspection_mtr = 0
                    currentProductTypeGroup.roll_no = 0
                    currentProductTypeGroup.weight = 0
                }
                break;

            default:
                break;
        }


        updatePtGroupData[productTypeIndexArray] = currentProductTypeGroup;
        setWeavingInspectionData(updatePtGroupData);
        getWeavingInspectionData(updatePtGroupData);
    }


    const setRowCountAndAddRow = (record, rowCount) => {
        const tableRows = document.querySelectorAll('#weavingInspectionTbl tbody tr');
        let lastRowSetNo = record.inspection_production_set_no;
        let lastRowInspectionMtr = record.inspection_mtr;
        let lastRowMachineOperator = record.production_operator_id;
        let lastRowShift = record.shift;
        let lastRowWeight = record.weight;
        let lastRowRollNo = record.roll_no;
        let lastRowGodown = record.godown_id;

        if (lastRowSetNo !== '' && lastRowGodown !== '' && (lastRowWeight !== '' && lastRowWeight !== 0) && lastRowMachineOperator !== '' && lastRowShift !== '' && (lastRowRollNo !== '' && lastRowRollNo !== 0) && (lastRowInspectionMtr !== '' && lastRowInspectionMtr !== 0)) {
            const getExistingWeavingInspectionData = [...weavingInspectionData]
            getExistingWeavingInspectionData.push(warpingProductionBlankObject)
            setWeavingInspectionData(getExistingWeavingInspectionData)
            getWeavingInspectionData(getExistingWeavingInspectionData)
            setRowCount(rowCount + 1);
        } else {
            const lastRow = tableRows[tableRows.length - 1];
            const setNoInput = lastRow.querySelector('select[id^="inspection_production_set_no_"]');
            const weightInput = lastRow.querySelector('input[id^="weight_"]');
            // const machineOperatorInput = lastRow.querySelector('select[id^="production_operator_id_"]');
            const shiftInput = lastRow.querySelector('select[id^="shift_"]');
            const rollNoInput = lastRow.querySelector('input[id^="roll_no_"]');
            const productPickInput = lastRow.querySelector('input[id^="product_pick_"]');
            const inspectionInput = lastRow.querySelector('input[id^="inspection_mtr_"]');
            const godownInput = lastRow.querySelector('select[id^="godown_id_"]');

            if (shiftInput) {
                const selectedOption = shiftInput.options[shiftInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    shiftInput.parentElement.dataset.tip = 'Please select Shift';
                    shiftInput.focus();
                    return;
                } else {
                    delete shiftInput.parentElement.dataset.tip;
                }
            }
            // if (machineOperatorInput) {
            //     const selectedOption = machineOperatorInput.options[machineOperatorInput.selectedIndex];
            //     if (!selectedOption || !selectedOption.value) {
            //         machineOperatorInput.parentElement.dataset.tip = 'Please select  Checker Name';
            //         machineOperatorInput.focus();
            //         return;
            //     } else {
            //         delete machineOperatorInput.parentElement.dataset.tip;
            //     }
            // }
            if (setNoInput) {
                const selectedOption = setNoInput.options[setNoInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    setNoInput.parentElement.dataset.tip = 'Please select SetNO.';
                    setNoInput.focus();
                    return;
                } else {
                    delete setNoInput.parentElement.dataset.tip;
                }
            }
            if (productPickInput.value === '0' || productPickInput.value === "") {

                productPickInput.parentElement.dataset.tip = 'Please filled Product Pick';
                productPickInput.focus();
                return;
            } else {
                delete productPickInput.parentElement.dataset.tip;
            }

            if (inspectionInput.value === '0' || inspectionInput.value === "") {

                inspectionInput.parentElement.dataset.tip = 'Please filled Inspection Mtr';
                inspectionInput.focus();
                return;
            } else {
                delete inspectionInput.parentElement.dataset.tip;
            }
            if (weightInput.value === '0' || weightInput.value === '') {

                weightInput.parentElement.dataset.tip = 'Please filled Weight';
                weightInput.focus();
                return;
            } else {
                delete weightInput.parentElement.dataset.tip;
            }
            if (rollNoInput.value === '0' || rollNoInput.value === '') {

                rollNoInput.parentElement.dataset.tip = 'Please filled Roll No';
                rollNoInput.focus();
                return;
            } else {
                delete rollNoInput.parentElement.dataset.tip;
            }
            if (godownInput) {
                const selectedOption = godownInput.options[godownInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    godownInput.parentElement.dataset.tip = 'Please select Godown.';
                    godownInput.focus();
                    return;
                } else {
                    delete godownInput.parentElement.dataset.tip;
                }
            }
        }
    };

    const removeFirstRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            const updatedWeavingInspData = weavingInspectionData.filter((item, index) => index !== indexToRemove);
            setWeavingInspectionData(updatedWeavingInspData)
            getWeavingInspectionData(updatedWeavingInspData)
        } else {
            const updatedWeavingInspData = [...weavingInspectionData];
            updatedWeavingInspData[0] = { ...warpingProductionBlankObject };
            setWeavingInspectionData(updatedWeavingInspData);
            getWeavingInspectionData(updatedWeavingInspData)
        }
    }

    const renderWeavingInspectionDetails = useMemo(() => {
        if (!weavingInspectionData) {
            return null;
        }

        return <Table id='weavingInspectionTbl' className={`erp_table ${weavingInspectionData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped >
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr no .</th>
                    <th className="erp_table_th">Date</th>
                    <th className="erp_table_th">Shift</th>
                    <th className="erp_table_th">Checker Name</th>
                    <th className="erp_table_th">Set no</th>
                    <th className="erp_table_th">Beam No</th>
                    <th className="erp_table_th">Loom No</th>
                    <th className="erp_table_th">Product Name</th>
                    <th className="erp_table_th">Style</th>
                    <th className="erp_table_th">Sort No</th>
                    <th className="erp_table_th">Width</th>
                    <th className="erp_table_th">Pick</th>
                    <th className="erp_table_th">Loom Mtr</th>
                    <th className="erp_table_th">Insp.Mtr</th>
                    <th className="erp_table_th">Difference</th>
                    <th className="erp_table_th">Weight</th>
                    <th className="erp_table_th">Roll No</th>
                    <th className="erp_table_th">Average</th>
                    <th className="erp_table_th">Godown</th>
                    <th className="erp_table_th">Weaving Insp.Status</th>
                    <th className="erp_table_th">Status Remark</th>
                </tr>
            </thead>
            <tbody>
                {weavingInspectionData.map((item, index) =>

                    <tr rowindex={index} className="sticky-column">
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(item, index)} />
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="date"
                                            className="erp_input_field mb-0"
                                            disabled={item.inspection_production_status_desc === 'Approved'}
                                            Headers="inspection_production_date"
                                            id={`inspection_production_date_${index}`}
                                            value={item.inspection_production_date}
                                            onChange={(e) => updatedWeavingInspectionDetails("inspection_production_date", item, e, index)}
                                        />
                                    </>
                                    : item.inspection_production_date
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`shift_${index}`} value={item.shift} onChange={(e) => updatedWeavingInspectionDetails("shift", item, e, index)} className="erp_input_field_table_txt mb-0"
                                        Headers="shift"
                                        disabled={item.inspection_production_status_desc === 'Approved'}
                                    >
                                        <option value="">Select</option>
                                        {shiftOptions?.map(shift => (
                                            <option value={shift.field_name}>{shift.field_name}</option>
                                        ))}
                                    </select>
                                    : item.shift
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? (
                                        <Select
                                            ref={selectRef}
                                            options={machineOperatorOptions}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            value={machineOperatorOptions.find(option => option.field_id === item.production_operator_id)}
                                            inputId={`production_operator_id_${index}`}
                                            onChange={(selectedOpt) => {
                                                selectRef.current = selectedOpt;
                                                updatedWeavingInspectionDetails('production_operator_id', item, selectedOpt, index)
                                            }}
                                            placeholder="Search Operator here..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    width: '200px', // Set the width of the option box
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    width: '200px', // Set the width of the menu box
                                                    zIndex: 1000, // Ensure this is higher than the table's zIndex
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 1000, // Ensure this is higher than the table's zIndex
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    width: '160px',
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                            menuPortalTarget={document.body}
                                        />
                                    ) : item.production_operator_name
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`inspection_production_set_no_${index}`} value={item.inspection_production_set_no} className="erp_input_field_table_txt mb-0"
                                        Headers="inspection_production_set_no"
                                        disabled={item.inspection_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        onChange={(e) => { handleSetNoChange(item, e, index); }}
                                    >
                                        <option value="">Select</option>
                                        {
                                            //array in ascending order
                                            setNoOptions.sort((a, b) => a.set_no - b.set_no).map(setNO => (
                                                <option value={setNO.set_no}>{setNO.set_no}</option>
                                            ))}

                                    </select>
                                    : item.inspection_production_set_no
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`sizing_beam_no_${index}`} value={item.sizing_beam_no} className="erp_input_field_table_txt mb-0"
                                        Headers="sizing_beam_no" style={{ width: '150px' }}
                                        onChange={(e) => handleSetNoChange(item, e, index)}>
                                        <option value=''>Select</option>
                                        {
                                            allBeamNoList[index]?.map((style) => <option value={style.sizing_beam_no} >{style.sizing_beam_no}</option>)
                                        }
                                    </select>
                                    : item.sizing_beam_no
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`machine_id_${index}`}>{item.machine_name}</span>
                                    : item.machine_name
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`product_material_name_${index}`}>{item.product_material_name}</span>
                                    : item.product_material_name
                            }
                        </td>
                        <td className='erp_table_td'> {item?.style}</td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`sort_no_${index}`}>{item.sort_no}</span>
                                    : item.sort_no
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`width_${index}`}>{item.width}</span>
                                    : item.width
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="text"
                                            className="erp_input_field_table_txt mb-0 remove0 text-end"
                                            id={`product_pick_${index}`}
                                            value={item.product_pick}
                                            style={{ backgroundColor: '#AFE1AF' }}
                                            disabled={item.inspection_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedWeavingInspectionDetails("product_pick", item, e, index)}
                                        />
                                    </>
                                    : item.product_pick
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`balance_product_in_meter_${index}`}>{item.balance_product_in_meter}</span>
                                    : item.balance_product_in_meter
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="text"
                                            className="erp_input_field_table_txt mb-0 remove0 text-end"
                                            id={`inspection_mtr_${index}`}
                                            value={item.inspection_mtr}
                                            style={{ backgroundColor: '#AFE1AF' }}
                                            disabled={item.inspection_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedWeavingInspectionDetails("inspection_mtr", item, e, index)}
                                        />
                                    </>
                                    : item.inspection_mtr
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0 text-end" id={`difference_${index}`}
                                        style={{ backgroundColor: '#e9ecef' }}>{item.difference}</span>
                                    : item.difference
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="text"
                                            className="erp_input_field_table_txt remove0 mb-0 text-end"
                                            id={`weight_${index}`}
                                            value={item.weight}
                                            style={{ backgroundColor: '#AFE1AF' }}
                                            disabled={item.inspection_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}

                                            onChange={(e) => updatedWeavingInspectionDetails("weight", item, e, index)}
                                        />
                                    </>
                                    : item.weight
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="text"
                                            className="erp_input_field_table_txt remove0 text-end mb-0"
                                            id={`roll_no_${index}`}
                                            style={{ backgroundColor: '#AFE1AF' }}
                                            value={item.roll_no}
                                            disabled={item.inspection_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedWeavingInspectionDetails("roll_no", item, e, index)}
                                        />
                                    </>
                                    : item.roll_no
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0 text-end" id={`average_${index}`}
                                        style={{ backgroundColor: '#e9ecef' }}>
                                        {item.average}</span>
                                    : item.average
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`godown_id_${index}`} value={item.godown_id} className="erp_input_field_table_txt mb-0"
                                        Headers="godown_id" onChange={(e) => { updatedWeavingInspectionDetails("godown_id", item, e, index); }}
                                        disabled={item.inspection_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                    >
                                        <option value="">Select</option>
                                        {godownNameOptions?.map(godownType => (
                                            <option value={godownType.field_id}>{godownType.godown_name}</option>
                                        ))}
                                    </select>
                                    : item.godown_name
                            }
                        </td>

                        <td className="erp_table_td">
                            <select id={`inspection_production_status_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.inspection_production_status}
                                disabled={isApprove ? (item.inspection_production_status === 'A' || item.inspection_production_status === 'PD' || item.inspection_production_status === 'D') : true}
                                onChange={(e) => { updatedWeavingInspectionDetails("inspection_production_status", item, e, index); FnValidateForm(); }}

                                Headers='inspection_production_status'>
                                <option value="P">Pending</option>
                                <option value="A">Approved</option>
                                <option value="R">Rejected</option>
                                <option value="I">Partial Issue</option>
                                <option value="C">Completed</option>
                                <option value="X">Canceled</option>
                                <option value="PD">Partial Dispatch</option>
                                <option value="D">Dispatch Done</option>
                                <option value="DG">Dispatch Note Generated</option>
                                <option value="Z">PreeClosed</option>
                            </select>
                        </td>

                        <td className='erp_table_td'>
                            {keyForViewUpdate === 'approve' ? (
                                <input
                                    type="text"
                                    id={`status_remark_${index}`}
                                    disabled={!isApprove}
                                    className="erp_input_field_table_txt mb-0"
                                    value={item.status_remark}
                                    onChange={(e) => {
                                        updatedWeavingInspectionDetails("status_remark", item, e, index);
                                    }}
                                />
                            ) : (
                                item.status_remark
                            )}
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [weavingInspectionData, setNoOptions, godownNameOptions, shiftOptions, dt_inspection_production_date, machineOperatorOptions])

    const FnValidateForm = async () => {

        let validStatus = true;
        const tableRows1 = document.querySelectorAll('#weavingInspectionTbl tbody tr');
        tableRows1.forEach(row => {
            const statusRemark = row.querySelector('input[id^="status_remark_"]');
            const inspStatus = row.querySelector(`select[id^="inspection_production_status"]`);
            const status = statusRemark.value.trim();
            const inspectionStaturValue = inspStatus.value.trim();

            if (inspectionStaturValue === "R") {
                if (status === '') {
                    statusRemark.parentElement.dataset.tip = 'Please enter the Rejection remark...!';
                    statusRemark.focus();
                    validStatus = false;
                    return;
                } else {
                    delete statusRemark.parentElement.dataset.tip;
                }
            } else {
                delete statusRemark.parentElement.dataset.tip;
            }
        });
        return validStatus;
    };
    return (
        <div>
            <ComboBox ref={combobox} />
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <FrmValidations ref={validate} />
            <Accordion defaultActiveKey='1'>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="erp-form-label-md"> Weaving Inspection Details</Accordion.Header>
                    <Accordion.Body>
                        {renderWeavingInspectionDetails}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <hr /></div>
    )
}

export default FrmWInspDetailsEntry