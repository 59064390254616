import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// import react icons
import { useLocation, useNavigate } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { UserName } from 'assets/Constants/config-constant';
import ConfigConstants from 'assets/Constants/config-constant';
import { validate } from 'uuid';
import FrmProductUnitEntry from 'Masters/MProductUnit/FrmProductUnitEntry';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { Form } from 'react-bootstrap';

export default function FrmMProductPackingEntry(props) {
  //changes by ujjwala on 4/1/2024
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
  const { state } = useLocation();
  const { productpackingID = 0, keyForViewUpdate, compType } = state || {}

  var activeValue = '';
  const validate = useRef();
  const comboDataAPiCall = useRef();
  const [product_packing_id, setProductpackingID] = useState(productpackingID);
  const [productUnitOptions, setProductUnitOptions] = useState([]);
  // Add Product Type Form Fields

  const [cmb_product_unit_id, setProductUnitId] = useState();
  const [txt_product_packing_name, setProductPackingName] = useState('');
  const [txt_quantity_per_packing, setProductQuantityPerPackingId] = useState('');
  const [txt_weight_per_packing, setWeightPerPackingId] = useState('');
  const [txt_remark, setRemark] = useState('');

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductPackingListing`);
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');
  // For navigate
  const navigate = useNavigate();

  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      await fillComobos();
      if (productpackingID !== 0) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $('#txt_product_packing_name').attr('disabled', true);
        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productpackingFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Creation)');
        break;
    }

  };

  const validateFields = () => {
    validate.current.validateFieldsOnChange('productpackingFormId')
    validate.current.validateFieldsOnChange('productpackingFormId')
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productUnit':
        var productUtVal = document.getElementById('cmb_product_unit_id').value;
        setProductUnitId(productUtVal)
        if (productUtVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 100)
        }
        break;
    }
  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Unit':
        const productUnitApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "")
        setProductUnitOptions(productUnitApiCall)
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }


  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Unit':
        return <FrmProductUnitEntry btn_disabled={true} />;
      default:
        return null;
    }
  }

  const fillComobos = async () => {
    const productUnitApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "")
    setProductUnitOptions(productUnitApiCall)

  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("productpackingFormId");
      if (checkIsValidate === true) {
        var active;

        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;

        }

        const data = {
          company_id: COMPANY_ID,
          product_packing_id: product_packing_id,
          created_by: UserName,
          modified_by: product_packing_id === 0 ? null : UserName,
          product_unit_id: cmb_product_unit_id,
          product_packing_name: txt_product_packing_name,
          quantity_per_packing: txt_quantity_per_packing,
          weight_per_packing: txt_weight_per_packing,
          remark: txt_remark,
          is_active: active,

        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productpacking/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          var data1 = JSON.parse(responce.data)
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          console.log("product_packing_id", data1);
          sessionStorage.setItem("product_packing_id", data1.product_packing_id)
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);

        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };


  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productpacking/FnShowParticularRecordForUpdate/${product_packing_id}`)
      const updateRes = await apiCall.json();

      let resp = JSON.parse(updateRes.data)
      await fillComobos();
      setProductpackingID(resp.product_packing_id);
      setProductUnitId(resp.product_unit_id);
      setProductPackingName(resp.product_packing_name);
      setProductQuantityPerPackingId(resp.quantity_per_packing);
      setWeightPerPackingId(resp.weight_per_packing);
      setRemark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }


  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{2}$/
    const regexNo1 = /^[0-9]*\.[0-9]{2}$/
    var dot = '.';
    switch (key) {
      case 'pQuantityPerPacking':
        var pProductQuantitypackingVal = $('#txt_quantity_per_packing').val();
        if (!regexNo.test(pProductQuantitypackingVal) && pProductQuantitypackingVal.length <= 16 || pProductQuantitypackingVal.indexOf(dot) === 16) {
          if (numCheck.test(pProductQuantitypackingVal)) {
            setProductQuantityPerPackingId(pProductQuantitypackingVal)
          }
        }
        break;
      case 'pProductWeightPacking':
        var pProductWeightPackingVal = $('#txt_weight_per_packing').val();
        if (!regexNo1.test(pProductWeightPackingVal) && pProductWeightPackingVal.length <= 16 || pProductWeightPackingVal.indexOf(dot) === 16) {
          if (numCheck.test(pProductWeightPackingVal)) {
            setWeightPerPackingId(pProductWeightPackingVal)
          }
        }
        break;
    }
  }

  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validate} />
      <div className="erp_top_Form">

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Packing{actionType} </label>
          </div>
          <form id="productpackingFormId">
            <div className="row erp_transporter_div">
              {/* first row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">  Packing Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_packing_name" className="erp_input_field" value={txt_product_packing_name} onChange={e => { setProductPackingName(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_packing_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Qty. Per Packing</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_quantity_per_packing" className="erp_input_field text-end" value={txt_quantity_per_packing} onChange={e => { validateNo('pQuantityPerPacking'); }} maxLength="19" optional="optional" />
                    <MDTypography variant="button" id="error_txt_quantity_per_packing" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Wt. Per Packing</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_weight_per_packing" className="erp_input_field text-end" value={txt_weight_per_packing} onChange={e => { validateNo('pProductWeightPacking'); }} maxLength="19" optional="optional" />
                    <MDTypography variant="button" id="error_txt_weight_per_packing" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* second row */}
              <div className="col-sm-6">

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Unit<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_unit_id" className="form-select form-select-sm" value={cmb_product_unit_id} onChange={() => { comboOnChange('productUnit'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productUnitOptions?.map(productUnit => (
                        <option value={productUnit.field_id}>{productUnit.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_product_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"

              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductPackingListing/reg' : '/Masters/ProductPackingListing';
                navigate(path);
              }} variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
            <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>

          </div >


        </div>

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            {displayRecordComponent()}

          </Modal.Body>
        </Modal >


      </div>
    </>
  )
}
