import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation} from "react-router-dom";
import * as XLSX from 'xlsx';
import { Form, Table } from "react-bootstrap";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDButton from "components/MDButton";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

import { RxCrossCircled } from "react-icons/rx";
import { Modal } from 'react-bootstrap';


import $ from 'jquery';
import MDTypography from "components/MDTypography";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import { CircularProgress } from "@material-ui/core";

import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import ComboBox from "Features/ComboBox";

import ConfigConstants from "assets/Constants/config-constant";
import { useNavigate } from "react-router-dom";

function FrmDailyDispatchChallanReportEntry() {


    // *********** Get Data from listing Page **********
    const { state } = useLocation();
    const { DispatchChallanID = 0, keyForViewUpdate = 'Add', compType = 'Transaction' } = state || {}

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate('/Transactions/TDailyDispatchChallanReport/TDailyDispatchChallanReportListing')
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const [actionType, setActionType] = useState('(Add)');
    const [isLoading, setIsLoading] = useState(false);

    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef();
    const navigate = useNavigate();

    //Hooks for form
    const [dispatch_challan_master_transaction_id, setDipatchChallanMasterId] = useState(DispatchChallanID);
    const [txt_delivery, setDispatchDelivery] = useState();
    const [dt_dispatch_challan_date, setDispatchChallanDt] = useState(today());
    const [txt_challan_no, setChallanNo] = useState();
    const [txt_indent_number, setIndentNo] = useState();
    const [txt_file_name, setFileName] = useState();
    // const [txt_customer_gst_no, setCustomerGSTNo] = useState();
    // const [txt_consignee_gst_no, setConsigneeGSTNo] = useState();
    const [txt_transport_company, setTransportCompany] = useState();
    const [transporter_gst_no, setTransporterGSTNo] = useState();
    const [transporter_truck_no, setTransporterTruckNo] = useState();
    const [txt_payment_days, setPaymentDays] = useState();
    const [txt_dispatch_rate, setDispatchRate] = useState();
    const [txt_lr_no, setLRNo] = useState();
    const [dailydispatchchallandata, setDailyDispatchChallanReportData] = useState([]);

    const [txt_totaldispatchmeter, SetTotalDispatchMeter] = useState(0);
    const [txt_totaldispatchweight, setTotalDispatchWeight] = useState(0);

    const [showFileModal, setShowFileModal] = useState(false);
    const fileModalClose = () => {
        setShowFileModal(false);
    }

    useEffect(async () => {
        setIsLoading(true);
        if (keyForViewUpdate !== 'Add') {
            await FnCheckUpdate();
        }
        setIsLoading(false);
    }, [])

    const emptyObject = {
        'product_material_id': '',
        'roll_no': '',
        'dispatch_quantity': '',
        'dispatch_weight': '',
        'dispatch_challan_remark': '',
        'dispatch_quantity': '',
        'dispatch_dispatch_weight': '',
    }

    //Rendering Table with emptyObject
    // useLayoutEffect(() => {
    //     let emptyArrObj = [emptyObject];
    //     setDailyDispatchChallanReportData(emptyArrObj);
    // }, []);

    const FnCheckUpdate = async () => {
        debugger
        try {
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "mt_dispatch_inspection_details_trading"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "dispatch_challan_master_transaction_id", operator: "=", value: dispatch_challan_master_transaction_id });
            let getDailyDispatchChallanAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setDailyDispatchChallanReportData(getDailyDispatchChallanAPICall);
            setChallanNo(getDailyDispatchChallanAPICall[0].dispatch_challan_no);
            setTransporterGSTNo(getDailyDispatchChallanAPICall[0].transporter_gst_no);
            setDispatchChallanDt(getDailyDispatchChallanAPICall[0].dispatch_challan_date);
            setIndentNo(getDailyDispatchChallanAPICall[0].sales_order_no);

            if (keyForViewUpdate === 'view') {
                setActionType('(View)');
                $(".view_hide").hide();
                $("#dt_dispatch_challan_date").attr('disabled', true);
                $('#dailyproductionReportTableId input').attr('disabled', true);

            }
        } catch (error) {

        }
    }


    const onImportClick = () => {
        importFile.current.click();
    };


    const importedExcelFile = async (event) => {
        try {
            setIsLoading(true);
            let dailychallandata = [...dailydispatchchallandata];

            const file = event.target.files[0];
            const fileType = file.type;
            const fileName = file.name;

            // Checking filename in the database
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "mt_dispatch_challan_master_trading";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "file_name", operator: "=", value: fileName });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

            let getAPICallFileName = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            if (getAPICallFileName.length > 0) {
                setShowFileModal(true);
            } else {
                // Validate the file type
                const validTypes = [
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
                    "application/vnd.ms-excel" // .xls
                ];

                if (validTypes.includes(fileType)) {
                    $("#error_Excelfile").hide();
                    setFileName(fileName);
                    const reader = new FileReader();

                    const processData = () => {
                        return new Promise((resolve, reject) => {
                            reader.onload = (e) => {
                                try {
                                    const binaryStr = e.target.result;
                                    const workbook = XLSX.read(binaryStr, { type: 'binary' });

                                    const sheetName = workbook.SheetNames[0]; // Get the first sheet
                                    const worksheet = workbook.Sheets[sheetName];

                                    // Convert the sheet to JSON
                                    const rawData = XLSX.utils.sheet_to_json(worksheet, {
                                        header: 1,
                                        raw: true,
                                    });

                                    const formatdate = (reqdate) => {
                                        let date = XLSX.SSF.parse_date_code(reqdate);
                                        if (date) {
                                            const day = String(date.d).padStart(2, '0');
                                            const month = String(date.m).padStart(2, '0');
                                            return `${date.y}-${month}-${day}`;
                                        }
                                        return null;
                                    };

                                    const accessorskeysObj = [
                                        { Headers: "SR NO", key: "sr_no" },
                                        { Headers: "SORT NO", key: "product_material_id" },
                                        { Headers: "ROLL NO", key: "roll_no" },
                                        { Headers: "MTR", key: "dispatch_quantity" },
                                        { Headers: "WEIGHT", key: "dispatch_weight" },
                                        { Headers: "REMARK", key: "dispatch_challan_remark" },
                                    ];

                                    let indexOfHeaderXLXSSheet = rawData.findIndex((row) => {
                                        return row.includes('SR NO') && row.includes('SORT NO') && row.includes('ROLL NO');
                                    });

                                    let lastIndexOfTheXLXSSheet = rawData.findIndex((row) => {
                                        return row.includes('Total') || row.includes('total');
                                    });

                                    // Extract Total Weight & Meter
                                    const totalRow = rawData[lastIndexOfTheXLXSSheet];

                                    totalRow.forEach((cell, colIndex) => {
                                        const header = rawData[indexOfHeaderXLXSSheet][colIndex];
                                        const keyAccessor = accessorskeysObj.find(keyAccessor => keyAccessor.Headers === header);

                                        if (header === 'MTR') {
                                            SetTotalDispatchMeter(cell);
                                        } else if (header === 'WEIGHT') {
                                            setTotalDispatchWeight(cell);
                                        }
                                    });


                                    console.log('Header Index : ' + indexOfHeaderXLXSSheet);
                                    console.log('Last Index : ' + lastIndexOfTheXLXSSheet);

                                    let dispatchDataExtractFromSheet = [];

                                    rawData.forEach((row, index) => {
                                        console.log(index + " DATA : " + row);

                                        if (index === 1) {
                                            setDispatchDelivery(row[1]);
                                        }

                                        // Setting specific values based on row index
                                        if (index === 3) {
                                            setChallanNo(row[1]);
                                            setIndentNo(row[4]);
                                        }

                                        if (index === 4 && typeof row[1] === 'number' && row[1] > 0 && row[1] < 60000) {
                                            const formattedDate = formatdate(row[1]) || today();
                                            setDispatchChallanDt(formattedDate);
                                        }

                                        if (index === 4) {
                                            setDispatchRate(row[4]);
                                        }

                                        if (index === 5) {
                                            setTransporterTruckNo(row[1]);
                                            setPaymentDays(row[4]);
                                        }

                                        if (index === 6) {
                                            setTransportCompany(row[1]);
                                        }

                                        if (index === 7) {
                                            setTransporterGSTNo(row[1]);
                                        }

                                        if (index === 8) {
                                            setLRNo(row[1]);
                                        }

                                        // Extracting data between headers and total rows
                                        if (index > indexOfHeaderXLXSSheet && index < lastIndexOfTheXLXSSheet) {
                                            let emptyJsonObject = {};

                                            for (let colIndex = 0; colIndex < row.length; colIndex++) {
                                                const header = rawData[indexOfHeaderXLXSSheet][colIndex];
                                                const cell = row[colIndex];
                                                const keyAccessor = accessorskeysObj.find(keyAccessor => keyAccessor.Headers === header);

                                                if (keyAccessor) {
                                                    emptyJsonObject[keyAccessor.key] = cell !== undefined ? cell : "";
                                                }
                                            }

                                            dispatchDataExtractFromSheet.push(emptyJsonObject);
                                        }
                                    });

                                    console.log(dispatchDataExtractFromSheet);

                                    let dailyproductiondetailsJsonArr = [...dispatchDataExtractFromSheet, ...dailychallandata];
                                    setDailyDispatchChallanReportData(dailyproductiondetailsJsonArr);
                                    $("#import_btn").attr('disabled', true);

                                    resolve(dailyproductiondetailsJsonArr); // Resolve with processed data
                                } catch (error) {
                                    console.error("Error processing data:", error);
                                    reject(error); // Reject on error
                                }
                            };
                        });
                    };

                    reader.readAsBinaryString(file);

                    const processedData = await processData();
                    console.log('Data processed successfully:', processedData);

                    // Clear the file input after processing
                    event.target.value = "";

                } else {
                    $('#error_Excelfile').text("Invalid file type. Please upload an Excel file (.xlsx or .xls).").show();
                }
            }
        } catch (error) {
            console.error('Error importing Excel file:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const addremoveRow = (action, rowIndex) => {
        debugger
        try {
            let dailydipatchchallandetails = [...dailydispatchchallandata];

            switch (action) {
                case 'add':
                    setDailyDispatchChallanReportData([...dailydipatchchallandetails, emptyObject]);

                    // Use setTimeout to ensure the DOM is updated before focusing
                    setTimeout(() => {
                        const lastRow = $('#dailyproductionReportTableId tr:last');
                        lastRow.find('input').first().focus();
                    }, 0);

                    break;

                case 'remove':
                    $(`#dispatchdatatablerowid_${rowIndex} input`).each(function () {
                        delete this.parentElement.dataset.tip;
                    });
                    dailydipatchchallandetails.splice(rowIndex, 1);
                    if (dailydipatchchallandetails.length > 0) {
                        setDailyDispatchChallanReportData(dailydipatchchallandetails);
                    } else {
                        // If the last row is removed, add an empty object to keep the array non-empty
                        setDailyDispatchChallanReportData([emptyObject]);
                    }
                    break;
            }
        } catch (error) {

        }
    }

    const renderPispatchChallanDailyReportTable = useMemo(() => {
        return <Table id="dailyproductionReportTableId" style={{ display: "block", overflow: "auto", height: dailydispatchchallandata.length > 20 ? '500px' : 'auto' }}
            bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className="erp_table_th view_hide" style={{ width: "50px", }}>Action</td>
                    <td className="erp_table_th " style={{ width: "100px", }}>Sort No.</td>
                    <td className="erp_table_th " style={{ width: "100px", }}>Roll No.</td>
                    <td className="erp_table_th " style={{ width: "100px", }}>Meter</td>
                    <td className="erp_table_th " style={{ width: "100px", }}>dispatch_weight</td>
                    <td className="erp_table_th " style={{ width: "100px", }}>dispatch_challan_remark</td>

                </tr>
            </thead>
            <tbody>
                {dailydispatchchallandata?.map((item, index) => (
                    <tr id={`dispatchdatatablerowid_${index}`} rowindex={index}>
                        <td className={`erp_table_th view_hide`}>
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => addremoveRow('add', index)} />
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => addremoveRow('remove', index)} />
                        </td>

                        <td className="erp_table_td">
                            <input
                                type="text"
                                className="erp_input_field mb-0"
                                Headers="product_material_id"
                                style={{ backgroundColor: '#AFE1AF' }}
                                id={`product_material_id_${index}`}
                                value={item.product_material_id}
                                onChange={(e) => FnUpdateDailyDispatchReportTbl(item, e, index)}
                            />
                        </td>

                        <td key={index} className="erp_table_td" >
                            <input type="text"
                                className="erp_input_field_table_txt mb-0 "
                                id={`roll_no_${index}`}
                                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                                Headers='roll_no' value={item.roll_no}
                                onInput={(e) => FnUpdateDailyDispatchReportTbl(item, e, index)}
                            />
                        </td>

                        <td key={index} className="erp_table_td" >
                            <input type="text" className="erp_input_field_table_txt mb-0" id={`dispatch_quantity_${index}`} value={item.dispatch_quantity}
                                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                                onChange={(e) => { FnUpdateDailyDispatchReportTbl(item, e, index); }} Headers='dispatch_quantity' />
                        </td>

                        <td key={index} className="erp_table_td" >
                            <input type="text"
                                className="erp_input_field_table_txt mb-0"
                                id={`dispatch_weight_${index}`}
                                value={item.dispatch_weight}
                                style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                                headers='dispatch_weight'
                                onChange={(e) => FnUpdateDailyDispatchReportTbl(item, e, index)}
                            />
                        </td>

                        <td key={index} className="erp_table_td" >
                            <input type="text"
                                className="erp_input_field_table_txt mb-0"
                                id={`dispatch_challan_remark_${index}`}
                                value={item.dispatch_challan_remark}
                                style={{ backgroundColor: '#AFE1AF', width: '200px' }}
                                headers='dispatch_challan_remark'
                                onChange={(e) => FnUpdateDailyDispatchReportTbl(item, e, index)}
                            />

                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

    }, [dailydispatchchallandata]);

    const FnUpdateDailyDispatchReportTbl = (rowData, e, index) => {
        try {
            setIsLoading(true);
            let dailydipatchchallandetails = [...dailydispatchchallandata];
            let clickedColName = e.target.getAttribute('Headers');
            let eventId = document.getElementById(e.target.id);
            let enteredValue = e.target.value;

            // if (enteredValue === '') {
            //     enteredValue = 0;
            // }

            delete eventId.parentElement.dataset.tip;

            switch (clickedColName) {
                case 'dispatch_challan_remark':
                    rowData[clickedColName] = enteredValue;
                    break;

                default:
                    rowData[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue), 4);
                    break;
            }

            dailydipatchchallandetails[index] = rowData;

            //Setting total Dispatch Meter
            let totalDispatchMeter = dailydipatchchallandetails.reduce((acc, item) => {
                return acc += parseFloat(item.dispatch_quantity);
            }, 0);
            SetTotalDispatchMeter(totalDispatchMeter);

            //Total Dispatch Meter
            let totalDispatchWeight = dailydipatchchallandetails.reduce((acc, item) => {
                return acc += parseFloat(item.dispatch_weight);
            }, 0);
            setTotalDispatchWeight(totalDispatchWeight);
            
            setDailyDispatchChallanReportData(dailydipatchchallandetails);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }


    const validateDailyProductionReportsTbl = () => {
        debugger
        try {
            let dailydipatchchallandetails = [...dailydispatchchallandata];

            let validation = true;
            if (dailydipatchchallandetails.length === 0) {
                $("#error_Excelfile").text('Import File...!').show();
                validation = false;
                return validation
            }
            for (let index = 0; index < dailydipatchchallandetails.length; index++) {
                let data = dailydipatchchallandetails[index];
                for (let key in data) {
                    if ((key === 'product_material_id' || key === 'roll_no') && (data[key] === '' || data[key] === null || data[key] === undefined || data[key] === 0)) {
                        $(`#${key}_${index}`).parent().attr('data-tip', 'Please fill it..!');
                        $(`#${key}_${index}`).focus();
                        validation = false;
                        return validation;
                    }
                }
            }

            return validation;

        } catch (error) {
            console.log('Error :' + error);
        }
    }


    const saveDailyDispatchReport = async () => {
        try {
            setIsLoading(true);
            if (validateDailyProductionReportsTbl() === true) {
                let json = { DailyDispatchChallanMasterData: {}, DailyDispatchChallanReport: [], DispatchInspectionDetails: [], commonIds: { 'company_id': COMPANY_ID, 'dispatch_challan_details_transaction_id': 1 } };

                let data = {
                    dispatch_challan_master_transaction_id: 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    file_name: txt_file_name,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_challan_no: $("#txt_challan_no").val(),
                    dispatch_challan_date: $("#dt_dispatch_challan_date").val(),
                    dispatch_challan_version: 1,
                    // consignee_gst_no: txt_consignee_gst_no,
                    // customer_gst_no: txt_customer_gst_no,
                    vehicle_no: transporter_truck_no,
                    transporter_gst_no: transporter_gst_no,
                    created_by: UserName,
                    modified_by: UserName
                };
                json.DailyDispatchChallanMasterData = data;

                for (let i = 0; i < dailydispatchchallandata.length; i++) {
                    let empty_Json = {};
                    let element = dailydispatchchallandata[i];

                    empty_Json.dispatch_challan_details_transaction_id = 0;
                    empty_Json.company_id = COMPANY_ID;
                    empty_Json.company_branch_id = COMPANY_BRANCH_ID;
                    empty_Json.financial_year = FINANCIAL_SHORT_NAME;

                    empty_Json.dispatch_challan_date = $("#dt_dispatch_challan_date").val();
                    empty_Json.dispatch_challan_no = $("#txt_challan_no").val();
                    empty_Json.product_material_id = element.product_material_id;
                    empty_Json.roll_no = element.roll_no;
                    empty_Json.dispatch_quantity = element.dispatch_quantity;
                    empty_Json.dispatch_weight = element.dispatch_weight;
                    empty_Json.dispatch_challan_remark = element.dispatch_challan_remark;
                    empty_Json.created_by = UserName;
                    empty_Json.modified_by = UserName;
                    empty_Json.dispatch_challan_version = 1;
                    empty_Json.sales_order_no = $("#txt_indent_number").val();
                    json.DailyDispatchChallanReport.push(empty_Json);
                }

                json.DailyDispatchChallanReport.forEach((data, index) => {
                    let modifiedData = { ...data };
                    delete modifiedData['dispatch_challan_details_transaction_id'];
                    modifiedData['sort_no'] = modifiedData['product_material_id'];
                    modifiedData['dispatch_inspection_details_transaction_id'] = 0;
                    console.log(`${index} Object :` + JSON.stringify(modifiedData));
                    json.DispatchInspectionDetails.push(modifiedData);
                });


                const formData = new FormData();
                formData.append(`DailyDispatchChallanReportData`, JSON.stringify(json));
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                console.clear();
                console.log("Final JSON : " + JSON.stringify(json));


                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnSaveDailyDispatchChallanData`, requestOptions)
                const apiResponse = await apiCall.json();

                if (apiResponse.success === 1) {
                    setSuccMsg(apiResponse.message);
                    setShowSuccessMsgModal(true);
                } else if (apiResponse.success === 0) {
                    setErrMsg(apiResponse.error)
                    setShowErrorMsgModal(true);
                }
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <FrmValidations ref={validate} />
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress />
                            <span>Loading...</span>
                        </div>
                    </div> : null}

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Daily Dispatch Challan Report{actionType} </label>
                    </div>

                    <form id='dailyProductionReportMasterId'>
                        <div className='row p-1'>
                            {/* 1 st Column */}

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">File Name</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_file_name' className="erp_input_field " value={txt_file_name} disabled placeholder=".xlsx file" />
                                    </div>
                                </div>


                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Delivery</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_delivery' className="erp_input_field " value={txt_delivery} disabled />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Challan No</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_challan_no' className="erp_input_field" value={txt_challan_no} disabled />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Challan Date</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_dispatch_challan_date' className="erp_input_field" value={dt_dispatch_challan_date} onChange={(e) => (setDispatchChallanDt(e.target.value))} disabled />
                                    </div>
                                </div>


                                <div className="row view_hide">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Truck No.</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='transporter_truck_no' className="erp_input_field" value={transporter_truck_no} disabled />
                                    </div>
                                </div>


                                <div className="row view_hide">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Transport</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_transport_company' className="erp_input_field" value={txt_transport_company} disabled />
                                    </div>
                                </div>
                            </div>


                            <div className='col-sm-4 erp_form_col_div'>
                                <input
                                    className="view_hide"
                                    type="file"
                                    id="Excelfile"
                                    ref={importFile}
                                    onChange={(e) => importedExcelFile(e)}
                                    style={{ display: "none" }}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                                <MDButton type="button" id="import_btn" className={`erp-gb-button view_hide`} variant="button" fontdispatch_weight="regular" onClick={e => { onImportClick(e) }} >Import File</MDButton>
                                <MDTypography variant="button" id="error_Excelfile" className="erp_validation error-msg ms-1" fontdispatch_weight="regular" color="error" style={{ display: "none" }}>
                                </MDTypography>

                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label ">GST Number</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='transporter_gst_no' className="erp_input_field" value={transporter_gst_no} disabled onInput={(e) => (setTransporterGSTNo(e.target.value))} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label ">Indent Number</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_indent_number' className="erp_input_field" value={txt_indent_number} disabled />
                                    </div>
                                </div>

                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label ">Rate</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_dispatch_rate' className="erp_input_field" value={txt_dispatch_rate} disabled />
                                    </div>
                                </div>




                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label ">Payment Days</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_payment_days' className="erp_input_field" value={txt_payment_days} disabled />
                                    </div>
                                </div>

                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label ">LR No.</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_lr_no' className="erp_input_field" value={txt_lr_no} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                            <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Total Dispatch Weight</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_totaldispatchweight' className="erp_input_field " value={txt_totaldispatchweight} disabled placeholder=".xlsx file" />
                                    </div>
                                </div>


                                <div className='row view_hide'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Total Meter</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_totaldispatchmeter' className="erp_input_field " value={txt_totaldispatchmeter} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>



                        <hr />
                        <div className="row m-1">
                            <label className='erp-form-label-lg'>Dispatch Challan Report</label>
                            <div className='col-sm-auto erp_form_col_div'>
                                <div className=' m-1'>
                                    {renderPispatchChallanDailyReportTable}
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="erp_frm_Btns">
                    <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontdispatch_weight="regular" onClick={() => navigate('/Transactions/TDailyDispatchChallanReport/TDailyDispatchChallanReportListing')}>Back</MDButton>
                    <MDButton type="submit" id="save_Button" className="erp-gb-button ms-2 view_hide" variant="button"
                        onClick={() => saveDailyDispatchReport()} fontdispatch_weight="regular">Save</MDButton>
                </div >

                {/* Delete Modal */}
                <Modal show={showFileModal} backdrop="static" keyboard={false} centered>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <div className="erp-form-label">File Already Exists..! Please Import Proper File.</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <MDButton variant="success" className='erp-gb-button' onClick={fileModalClose}>
                            Ok
                        </MDButton>&nbsp;
                    </Modal.Footer>
                </Modal>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>
        </>
    )
}

export default FrmDailyDispatchChallanReportEntry