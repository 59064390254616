import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React from 'react'

function DashBoard() {
    return (
        <DashboardLayout></DashboardLayout>
    )
}

export default DashBoard
