
import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useNavigate, useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import FrmMProductCategory1Entry from 'Masters/MProductCategory1/FrmProductCategory1Entry';
import FrmMProductCategory2Entry from 'Masters/MProductCategory2/FrmMProductCategory2Entry';
import FrmMProductCategory3Entry from 'Masters/MProductCategory3/FrmMProductCategory3Entry';
import FrmMProductCategory4Entry from 'Masters/MProductCategory4/FrmMProductCategory4Entry';
import FrmValidations from 'FrmGeneric/FrmValidations';

export default function FrmMProductCategory5Entry(props) {

  const { state } = useLocation();
  const { productcategory5ID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};

  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;

  // use refs
  const comboDataAPiCall = useRef();
  const validate = useRef();


  // combobox Option
  const [category4Options, setCategory4Options] = useState([])
  const [category3Options, setCategory3Options] = useState([])
  const [category2Options, setCategory2Options] = useState([])
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productCategory1Options, setProductCategory1Options] = useState([])


  // Add Product Type Form Fields
  const [cmb_cat5_product_type_id, setCat5ProductTypeId] = useState();
  const [cmb_cat5_product_category1_id, setCat5ProductCategory1Id] = useState('');
  const [cmb_cat5_product_category2_id, setCat5ProductCategory2Id] = useState('');
  const [cmb_cat5_product_category3_id, setCat5PCategory3Id] = useState('');
  const [cmb_cat5_product_category4_id, setCat5PCategory4Id] = useState('');
  const [txt_cat5_product_category5_name, setCat5ProductCategory5Name] = useState('');
  const [txt_cat5_product_category5_short_name, setCat5ProductCategory5ShortName] = useState('');
  const [txt_cat5_remark, setCat5Remark] = useState('');
  const [actionType, setActionType] = useState('')


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');


  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductCategory5Listing`)
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // For navigate
  const navigate = useNavigate();

  // Add rec modal
  const closeCat5RecModal = async () => {
    switch (cat5ModalHeaderName) {
      case 'Product Type':
        const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
        setProductTypeOptions(productTypeApiCall)
        break;

      case 'Product Category-1':
        var proctTypeIdVal = document.getElementById('cmb_cat5_product_type_id').value;
        comboDataAPiCall.current.fillMasterData("smv_product_category1", "product_type_id", '').then((productCategory1) => {
          setProductCategory1Options(productCategory1)
        })
        break;

      case 'Product Category-2':
        var productCat1Val = document.getElementById('cmb_cat5_product_category1_id').value;
        comboDataAPiCall.current.fillMasterData("smv_product_category2", "product_category1_id", '').then((productCategory2) => {
          setCategory2Options(productCategory2)
        })
        break;

      case 'Product Category-3':
        var productCat2Val = document.getElementById('cmb_cat5_product_category2_id').value;
        comboDataAPiCall.current.fillMasterData("smv_product_category3", "product_category2_id", '').then((productCategory3) => {
          setCategory3Options(productCategory3)
        })
        break;

      case 'Product Category-4':
        var productCat3Val = document.getElementById('cmb_cat5_product_category3_id').value;

        comboDataAPiCall.current.fillMasterData("smv_product_category4", "product_category3_id", '').then((productCategory4) => {
          setCategory4Options(productCategory4)
        })

        break;
      default:
        break;
    }
    setShowAddRecCat5Modal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({
        "padding-top": "110px"
      });
    }, 200)

  }
  const [showAddRecCat5Modal, setShowAddRecCat5Modal] = useState(false);
  const [cat5ModalHeaderName, setCat5ModalHeaderName] = useState('')

  useEffect(async () => {
    ActionType();
    fillComobos();
    if (productcategory5ID !== 0) {
      await FnCheckUpdateResponce();
    }
  }, [])

  const fillComobos = () => {
    comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypes) => {
      setProductTypeOptions(productTypes)
    })

      comboDataAPiCall.current.fillMasterData("smv_product_category1", "product_type_id", '').then((productCategory1) => {
        setProductCategory1Options(productCategory1)
      })
     
      comboDataAPiCall.current.fillMasterData("smv_product_category2", "product_category1_id", '').then((productCategory2) => {
        setCategory2Options(productCategory2)
      })
      
      comboDataAPiCall.current.fillMasterData("smv_product_category3", "product_category2_id", '').then((productCategory3) => {
        setCategory3Options(productCategory3)
      })

      comboDataAPiCall.current.fillMasterData("smv_product_category4", "product_category3_id", '').then((productCategory4) => {
        setCategory4Options(productCategory4)
      })
  }

  const FnAddUpdateCategory5 = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm('productcategory5FormId');
      if (checkIsValidate) {
        var active;
        var activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
        }

        const data = {
          company_id: COMPANY_ID,
          product_category5_id: productcategory5ID,
          created_by: UserName,
          modified_by: productcategory5ID === 0 ? null : UserName,
          product_category1_id: cmb_cat5_product_category1_id,
          product_category2_id: cmb_cat5_product_category2_id,
          product_category3_id: cmb_cat5_product_category3_id,
          product_category4_id: cmb_cat5_product_category4_id,
          product_type_id: cmb_cat5_product_type_id,
          product_category5_name: txt_cat5_product_category5_name,
          product_category5_short_name: txt_cat5_product_category5_short_name.trim(),
          remark: txt_cat5_remark,
          is_active: active,
        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory5/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)
        } else {
          var data1 = JSON.parse(responce.data)
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          console.log("productcategory5ID", data1);
          sessionStorage.setItem("productcategory5ID", data1.product_category5_id)
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  };


  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory5/FnShowParticularRecordForUpdate/${productcategory5ID}`)
      const updateRes = await apiCall.json();

      let resp = JSON.parse(updateRes.data)
      setCat5ProductTypeId(resp.product_type_id);
      // await comboOnChange('productType')
      setCat5ProductCategory1Id(resp.product_category1_id);
      // await comboOnChange('productCategory1')
      setCat5ProductCategory2Id(resp.product_category2_id);
      // await comboOnChange('productCategory2')
      setCat5PCategory3Id(resp.product_category3_id)
      // await comboOnChange('productCategory3')
      setCat5PCategory4Id(resp.product_category4_id)
      setCat5ProductCategory5Name(resp.product_category5_name);
      setCat5ProductCategory5ShortName(resp.product_category5_short_name);
      setCat5Remark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }



      switch (keyForViewUpdate) {
        case 'view':
          await validate.current.readOnly("productcategory5FormId");
          $("input[type=radio]").attr('disabled', true);

          break;
        case 'update':
          $('#cmb_cat5_product_type_id').attr('disabled', true);
          $('#cmb_cat5_product_category1_id').attr('disabled', true);
          $('#cmb_cat5_product_category2_id').attr('disabled', true);
          $('#cmb_cat5_product_category3_id').attr('disabled', true);
          $('#txt_cat5_product_category5_name').attr('disabled', true);
          $('#txt_cat5_product_category5_short_name').attr('disabled', true);
          break;
      }

    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_cat5_product_type_id').value;
       
        if (productTpVal === '0') {
          setCat5ModalHeaderName('Product Type')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        setShowAddRecCat5Modal(true)
        setTimeout(() => {
          $(".erp_top_Form").eq(1).css("padding-top", "0px");
        }, 200)
      } else {
        setCat5ProductTypeId(productTpVal)
        $('#error_cmb_cat5_product_type_id').hide();
      }
      break;

    case 'productCategory1':
      var productTpVal = document.getElementById('cmb_cat5_product_category1_id').value;
      
      if (productTpVal === '0') {
        setCat5ModalHeaderName('Product Category-1')
        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        setShowAddRecCat5Modal(true)
        setTimeout(() => {
          $(".erp_top_Form").eq(1).css("padding-top", "0px");
        }, 200)
      }else{
        setCat5ProductCategory1Id(productTpVal)
      }
      break;

    case 'productCategory2':
      var productCat2Val = document.getElementById('cmb_cat5_product_category2_id').value;
     
      if (productCat2Val === '0') {
        setCat5ModalHeaderName('Product Category-2')
        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        setShowAddRecCat5Modal(true)
        setTimeout(() => {
          $(".erp_top_Form").eq(1).css("padding-top", "0px");
        }, 200)
      }else{
        setCat5ProductCategory2Id(productCat2Val)
      }
      break;
    case 'productCategory3':
      var productCat3Val = document.getElementById('cmb_cat5_product_category3_id').value;
      
      if (productCat3Val === '0') {
        setCat5ModalHeaderName('Product Category-3')
        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        setShowAddRecCat5Modal(true)
        setTimeout(() => {
          $(".erp_top_Form").eq(1).css("padding-top", "0px");
        }, 200)
      }else{
        setCat5PCategory3Id(productCat3Val)

      }
      break;

    case 'productCategory4':
      var productCat4Val = document.getElementById('cmb_cat5_product_category4_id').value;
      
      if (productCat4Val === '0') {
        setCat5ModalHeaderName('Product Category-4')
        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        setShowAddRecCat5Modal(true)
        setTimeout(() => {
          $(".erp_top_Form").eq(1).css("padding-top", "0px");
        }, 200)
      }else{
        setCat5PCategory4Id(productCat4Val)
      }
      break;

  }
  }

  const cat5DisplayRecordComponent = () => {
    switch (cat5ModalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled />;
      case 'Product Category-1':
        return <FrmMProductCategory1Entry btn_disabled />;
      case 'Product Category-2':
        return <FrmMProductCategory2Entry btn_disabled />
      case 'Product Category-3':
        return <FrmMProductCategory3Entry btn_disabled />
      case 'Product Category-4':
        return <FrmMProductCategory4Entry btn_disabled />
      default:
        return null;
    }
  }


  const validateFields = () => {
    validate.current.validateFieldsOnChange('productcategory5FormId')
  }


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Create)');
        break;
    }

  };


  return (
    <>
      <FrmValidations ref={validate} />
      <ComboBox ref={comboDataAPiCall} />

      <div className='erp_top_Form'>
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Product Category-5  {actionType}  </label>
          </div>

          <form id="productcategory5FormId">
            <div className="row erp_transporter_div">
              <div className="col-sm-6 erp_form_col_div">

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat5_product_type_id" className="form-select form-select-sm" value={cmb_cat5_product_type_id} onChange={(e) => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_cat5_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category1</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat5_product_category1_id" className="form-select form-select-sm" value={cmb_cat5_product_category1_id} onChange={(e) => { comboOnChange('productCategory1'); validateFields(); }} optional = 'optional'>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {productCategory1Options?.map(productCategory1 => (
                        <option value={productCategory1.field_id}>{productCategory1.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_cat5_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category2</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat5_product_category2_id" className="form-select form-select-sm" value={cmb_cat5_product_category2_id} onChange={(e) => { comboOnChange('productCategory2'); validateFields(); }} optional = 'optional'>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {category2Options?.map(productCategory2 => (
                        <option value={productCategory2.field_id}>{productCategory2.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_cat5_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category3</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat5_product_category3_id" className="form-select form-select-sm" value={cmb_cat5_product_category3_id} onChange={(e) => { comboOnChange('productCategory3'); validateFields(); }} optional = 'optional'>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {category3Options?.map(productCategory3 => (
                        <option value={productCategory3.field_id}>{productCategory3.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_cat5_product_category3_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                {/* new row add  */}

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category 4</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat5_product_category4_id" className="form-select form-select-sm" value={cmb_cat5_product_category4_id} onChange={(e) => { comboOnChange('productCategory4'); validateFields(); }} optional = 'optional'>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {category4Options?.map(productCategory4 => (
                        <option value={productCategory4.field_id}>{productCategory4.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_cat5_product_category4_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* second row */}

              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Category-5</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_cat5_product_category5_name" className="erp_input_field" value={txt_cat5_product_category5_name} onChange={e => { setCat5ProductCategory5Name(e.target.value); validateFields(); }} maxLength="255" optional = 'optional'/>
                    <MDTypography variant="button" id="error_txt_cat5_product_category5_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_cat5_product_category5_short_name" className="erp_input_field" value={txt_cat5_product_category5_short_name} onChange={e => { setCat5ProductCategory5ShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" optional="optional" />
                    <MDTypography variant="button" id="error_txt_cat5_product_category5_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_cat5_remark" value={txt_cat5_remark} onChange={e => { setCat5Remark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_cat5_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is Active </Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductCategory5Listing/reg' : '/Masters/ProductCategory5Listing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}
            >Back</MDButton>

            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={FnAddUpdateCategory5} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}

          </div >
        </div>

        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


        {/* //changes by tushar */}
        <Modal size="lg" show={showAddRecCat5Modal} onHide={closeCat5RecModal} backdrop="static" keyboard={false} centered >

          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeCat5RecModal}></button></div>
            </div>
            {cat5DisplayRecordComponent()}
          </Modal.Body>
        </Modal >


      </div>
    </>
  )
}
