import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";

function DepartmentWiseMaterialUtilization() {

  // Global Constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

  // UseRefs Hooks.
  const comboDataFunc = useRef();
  const exlsExp = useRef();

  // Ref Hooks For Print-Out.    
  const printComponentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);
  const validateNumberDateInput = useRef();

  // ****** navigate
  const navigate = useNavigate();

  // combo's options hooks;
 
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
  const [departmentOpts, setDepartmentOpts] = useState([]);
  const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);

  // Input field's Hooks;
  const [cmb_department_id, setDepartmentId] = useState('');
  const [cmb_sub_department_id, setSubDepartmentId] = useState('');

  // Hooks For table;
  const [departmentWiseMaterialsTblData, setDepartmentWiseMaterialsTblData] = useState([]);

  // UseRefs Hooks For Searchable combos.
  let departmentComboRef = useRef(null);
  let subDepartmentComboRef = useRef(null);

  // Helping hooks;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true);
      await FnFillComobos();
      setIsLoading(false);
    }
    loadDataOnload();
  }, []);

  const FnFillComobos = async () => {
    try {
      // Load Department & Sub-Department
      resetGlobalQuery();
      globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
      globalQuery.table = "cm_department"
      globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdDepts => {
          const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
            if (department.department_type === "M") {
              acc.mainDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            } else if (department.department_type === "S") {
              acc.subDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            }
            return acc;
          }, { mainDepartments: [], subDepartments: [] });
          setAllDepartmentsList([...mainDepartments, ...subDepartments]);

          mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
          setDepartmentOpts(mainDepartments);
        });

    } catch (error) {
      console.log("error on filling combos: ", error)
      navigate('/Error')
    }
  }

  const FnComboOnChange = async (comboName) => {
    try {
      switch (comboName) {
        case "Department":
          let selectedDepartment = departmentComboRef.current.department_id;
          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          // remove the already selected sub-department
          subDepartmentComboRef.current = null;
          setSubDepartmentId('');
          break;

        default:
          break;
      }

    } catch (error) {
      console.log("error on combos change: ", error)
      navigate('/Error')
    }
  }

  // ****-------Get Salary Data Functions Starts--------------***
  const FnShowMaterialData = async () => {
    try {
      debugger
      // let isValidFilters = await FnChkIsValidFilters();
      // if (!isValidFilters) { return false; }
      setIsLoading(true);

      let selectedDepartment = departmentComboRef.current?.department_id || null;
      let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;

      let filterConditions = [
        { field: "isv.company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "isv.is_delete", operator: "=", value: 0 },
      ];

      if (selectedDepartment !== null && selectedDepartment !== '') {
        filterConditions.push({ field: "isv.department_id", operator: "=", value: selectedDepartment });
      } else {
        let defaultDept = departmentOpts.find(department => department.value == 'All');
        setDepartmentId(defaultDept.value);
        departmentComboRef.current = defaultDept;
      }

      if (selectedSubDepartment !== null && selectedSubDepartment !== '') {
        filterConditions.push({ field: "isv.sub_department_id", operator: "=", value: selectedSubDepartment });
      } else {
        const subDepartmentList = allDepartmentsList.filter(department =>
          (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
        );
        subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
        setSubDepartmentOpts(subDepartmentList);
        subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
        setSubDepartmentId('All');
      }

      // Get the Salary Summary Data;
      resetGlobalQuery();
      globalQuery.columns = [
        "isv.department_name", "isv.sub_department_name", "isv.issue_date", "idv.indent_no", "isv.requisition_by_name", "isv.approved_by_name",
        "isv.issued_by_name", "isv.issued_by_id", "isv.approved_by_id", "isv.requisition_by_id", "isv.requisition_date", "isv.approved_date",
        "isv.department_id", "isv.sub_department_id", "isv.company_id", "isv.company_branch_id", "isv.issue_no",
        "idv.product_material_name", "idv.product_material_id", "idv.product_material_issue_quantity", "idv.product_material_issue_weight",
        "idv.product_material_receipt_quantity", "idv.product_material_receipt_weight", "idv.product_material_unit_name",
      ];
      globalQuery.table = "stv_indent_material_issue_details as idv";
      globalQuery.joins.push({
        "table": "stv_indent_material_issue_summary as isv ",
        "type": "left",
        "on": [{
          "left": "idv.issue_master_transaction_id",
          "right": "isv.issue_master_transaction_id"
        },
        {
          "left": "idv.company_id",
          "right": "isv.company_id"
        }]
      })
      globalQuery.conditions = filterConditions;
      globalQuery.orderBy = ['isv.department_id DESC', 'isv.sub_department_id DESC', 'isv.issue_master_transaction_id Asc'];
      const receivedSummaryData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      debugger
      let product_material_consumption_quantity = 0;
      let product_material_consumption_weight = 0;
      const updatedSummaryData = receivedSummaryData.map((item) => ({
        ...item,
        product_material_consumption_quantity: 0,
        product_material_consumption_weight: 0,
        closing_quantity: parseFloat(item.product_material_issue_quantity) - parseFloat(product_material_consumption_quantity),
        closing_weight: parseFloat(item.product_material_issue_weight) - parseFloat(product_material_consumption_weight),
      }));
      setDepartmentWiseMaterialsTblData(updatedSummaryData);
      console.log("receivedSummaryData: ", updatedSummaryData)
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // ****-------Validation Functions Starts--------------***
  // Function for validate the fields at the time of button click;
  const FnChkIsValidFilters = async () => {
    if (cmb_department_id === '') {
      $('#error_cmb_department').text('Please select the atleast one...!')
      $('#error_cmb_department').show();
      return false;
    } else {
      $('#error_cmb_department').hide();
    }
    return true;
  }

  // Function for validate the fields on change
  const FnValidateFields = () => {
    setDepartmentWiseMaterialsTblData([]);
    const isEmpty = str => {
      if (typeof str === 'number' && Number.isInteger(str)) { str = str.toString(); }
      return str === undefined || str === null || str.trim() !== '';
    };
    if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
    if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
  }

  // ****-------Validation Functions Ends--------------***

  //-----------move back --------------------------------
  const goBack = () => { navigate("/Dashboard") }


  // -------------- PrintOut Functionalities Starts. --------------------------------
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const FnPrintDeptWiseMaterialSheet = useReactToPrint({
    content: () => {
      const headerHtml = printOutHeader(); // Call function to get header HTML

      const contentWrapper = document.createElement('div');
      contentWrapper.className = 'printable-content';
      contentWrapper.innerHTML = headerHtml; // Add generated header HTML

      // Append the main content to the wrapper
      const mainContentClone = printComponentRef.current.cloneNode(true);
      contentWrapper.appendChild(mainContentClone);

      return contentWrapper;
    },
    onBeforePrint: () => {
      // Apply the desired font size before printing
      if (printComponentRef.current) {
        const table = printComponentRef.current.querySelector('.erp_table');
        if (table) {
          table.style.fontSize = '7px'; // Set your desired font size
        }
      }
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    documentTitle: `Department Wise Material Utilization`

  });

  const printOutHeader = () => {
    return ReactDOMServer.renderToString(
      <>
        <div className='px-0 border border-dark'> {/* border border-dark */}
          <div className='row mb-0 p-1'>
            <div className="col-sm-1">
              <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
            </div>
            <div className="col-sm-11">
              <div className='erp-invoice-print-label text-center'>
                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
              </div>
              <div className='erp-invoice-print-label-lg text-center'>
                Department Wise Material Utilization
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };


  return (
    <>
      <ComboBox ref={comboDataFunc} />
      <ExcelExport ref={exlsExp} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />

      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        {/* Card Starts*/}
        <div className="card mt-4">
          <div className="card-header py-0 main_heding">
            <label className="erp-form-label-lg">Department-Wise Material Utilization Register</label>
          </div>

          {/* Card's Body Starts*/}
          <div className="card-body">
            <div className="row">
              {/* First Column Starts*/}
              {/* <div className="col-sm-4 erp_form_col_div">
                <div className="row"> */}
              <div className="col-sm-2"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
              <div className='col-sm-3'>
                <Select ref={departmentComboRef}
                  options={departmentOpts}
                  inputId="cmb_department_id"
                  value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                  onChange={(selectedOpt) => {
                    setDepartmentId(selectedOpt.value);
                    departmentComboRef.current = selectedOpt;
                    FnComboOnChange("Department");
                    FnValidateFields();
                  }}
                  placeholder="Search department..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"
                  styles={{
                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                  }}
                />
                <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
              </div>
              {/* </div>
              </div> */}

              {/* <div className="row"> */}
              <div className="col-sm-2"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
              <div className='col-sm-3'>
                <Select ref={subDepartmentComboRef}
                  options={subDepartmentOpts}
                  inputId="cmb_sub_department_id"
                  value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                  onChange={(selectedOpt) => {
                    setSubDepartmentId(selectedOpt.value);
                    subDepartmentComboRef.current = selectedOpt;
                    FnValidateFields();
                  }}
                  placeholder="Search sub-department..."
                  className="form-search-custom"
                  classNamePrefix="custom-select"
                  styles={{
                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                  }}
                />
                <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
              </div>
              {/* </div> */}
              {/* First Column Ends*/}
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col text-center">
                <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowMaterialData(); }} variant="button" fontWeight="regular">show Data</MDButton>
              </div>
            </div>
          </div>
          {/* Card's Body Ends*/}
        </div>
        {/* Card Ends*/}
        {
          departmentWiseMaterialsTblData.length === 0
            ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
            : <>
              <hr />
              <div className="row py-1">
                <div className="text-end">
                  {/* <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                        onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton> */}

                  <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                    onClick={(e) => { FnPrintDeptWiseMaterialSheet(); }} >Print</MDButton>
                </div>
              </div>

              <div className="row px-lg-2 d-block">
                <div className="card p-0">
                  {/* <div className="card-header py-0 main_heding mb-0">
                        <label className="erp-form-label-md-lg">Issue Details</label>
                      </div> */}
                  {/* Card's Body Starts*/}
                  <div className="card-body p-0 print-content" ref={printComponentRef}>
                    <DepartmentWiseMaterialTbl validateNumberDateInput={validateNumberDateInput} summaryData={departmentWiseMaterialsTblData} />
                  </div>
                </div>
              </div>
            </>
        }
        <hr />
        <div className="row text-center mt-3">
          <div className=''>
            <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
              className={`erp-gb-button float-center`}
              onClick={(e) => { goBack(); }}  > Back </MDButton>

            {/* {
                  departmentWiseMaterialsTblData.length === 0
                    ? null
                    : <>
                      <MDButton type="button" variant="button" fontWeight="regular"
                        className="erp-gb-button ms-2"
                        onClick={(e) => { FnPrintDeptWiseMaterialSheet(); }} >Print</MDButton>
                    </>
                } */}
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}
export default DepartmentWiseMaterialUtilization;



export const DepartmentWiseMaterialTbl = ({
  validateNumberDateInput,
  summaryData
}) => {
  return (
    <>
      <style>
        {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        @page {
                            margin: 0;
                            size: legal landscape;
                            margin: 1%;
                            border: 2px solid black;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important; /* Default font size */
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
      </style>

      <Table className="erp_table " id='DepartmentWiseStaffSalaryDtlsTbl' style={{ whiteSpace: 'normal' }} >
        <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }}>
          <tr style={{ whiteSpace: 'normal' }}>
            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '10px' }}>Sr. No.</th>
            <th className="erp_table_th align-middle" rowSpan="2" style={{ width: '70px' }}>Date</th>
            <th className="erp_table_th align-middle" style={{ width: '20px' }}>Department</th>
            <th className="erp_table_th align-middle" style={{ width: '20px' }}>Material Name</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Issue Quantity</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Issue Weight</th>
            <th className="erp_table_th align-middle" style={{ width: '100px' }}>Unit</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Consumption Quantity</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Consumption Weight</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Closing Quantity</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Closing Weight</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Issue No</th>
            <th className="erp_table_th align-middle" style={{ width: '70px' }}>Indent No</th>
            <th className="erp_table_th align-middle" style={{ width: '20px' }}>Requisition By</th>
            <th className="erp_table_th align-middle" style={{ width: '20px' }}>Approved By</th>
            <th className="erp_table_th align-middle" style={{ width: '20px' }}>Issued By</th>
          </tr>
        </thead>
        <tbody>
          {summaryData.map((subDepartment, index) => {
            const subDepartmentId = subDepartment.sub_department_id;
            return (
              <React.Fragment key={subDepartmentId}>
                <tr key={subDepartment.sub_department_id}>
                  <td className="erp_table_td">{index + 1}</td>
                  <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(subDepartment.issue_date)}</td>
                  <td className="erp_table_td">{subDepartment.sub_department_name}</td>
                  <td className="erp_table_td">{subDepartment.product_material_name}</td>
                  <td className="erp_table_td text-end">{subDepartment.product_material_issue_quantity}</td>
                  <td className="erp_table_td text-end">{subDepartment.product_material_issue_weight}</td>
                  <td className="erp_table_td">{subDepartment.product_material_unit_name}</td>
                  <td className="erp_table_td text-end">{subDepartment.product_material_consumption_quantity}</td>
                  <td className="erp_table_td text-end">{subDepartment.product_material_consumption_weight}</td>
                  <td className="erp_table_td text-end">{subDepartment.closing_quantity}</td>
                  <td className="erp_table_td text-end">{subDepartment.closing_weight}</td>
                  <td className="erp_table_td">{subDepartment.issue_no}</td>
                  <td className="erp_table_td">{subDepartment.indent_no}</td>
                  <td className="erp_table_td">{subDepartment.requisition_by_name}</td>
                  <td className="erp_table_td">{subDepartment.approved_by_name}</td>
                  <td className="erp_table_td">{subDepartment.issued_by_name}</td>
                </tr>
              </React.Fragment>
            );
          })}

          {/* Footer Summary Row  */}
          <tr className='table-light'>
            <td className="erp_table_td text-center" rowSpan="2" colSpan='4'><strong>All Department Totals:</strong> </td>
            <th className="erp_table_th text-end" style={{ width: '70px' }}>
              {summaryData.reduce((sum, summary) => sum + summary.product_material_issue_quantity, 0)}
            </th>
            <th className="erp_table_th text-end" style={{ width: '70px' }}>
              {summaryData.reduce((sum, summary) => sum + summary.product_material_issue_weight, 0)}
            </th>
            <th className="erp_table_th text-end"  style={{ width: '70px' }}></th>
            <th className="erp_table_th text-end"  style={{ width: '70px' }}>
              {summaryData.reduce((sum, summary) => sum + summary.product_material_consumption_quantity, 0)}
            </th>
            <th className="erp_table_th text-end"  style={{ width: '70px' }}>
              {summaryData.reduce((sum, summary) => sum + summary.product_material_consumption_weight, 0)}
            </th>
            <th className="erp_table_th text-end"  style={{ width: '70px' }}>
              {summaryData.reduce((sum, summary) => sum + summary.closing_quantity, 0)}
            </th>
            <th className="erp_table_th text-end"  style={{ width: '70px' }}>
              {summaryData.reduce((sum, summary) => sum + summary.closing_weight, 0)}
            </th>
            <td className="erp_table_td" ></td>
            <td className="erp_table_td"></td>
            <td className="erp_table_td" rowSpan="2"></td>
            <td className="erp_table_td" ></td>
            <td className="erp_table_td" ></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};