import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

const GatePassSlip = React.forwardRef(({ dispatchNoteContent }, ref) => {
    
    const data = dispatchNoteContent.items

    const contactDetails = dispatchNoteContent.contactDetails;

    const itemData = extractItemData(dispatchNoteContent.items);
    function extractItemData(items) {
        // Extract relevant data from the first item in the array
        if (items.length > 0) {
            const firstItem = items[0];
            return {
                department_name: firstItem.department_name,
                company: firstItem.company,
                company_branch: firstItem.company_branch,
                address: firstItem.address,
                phone: firstItem.phone,
                cell: firstItem.cell,
                email: firstItem.email,
                website: firstItem.website,
                GSTIN: firstItem.GSTIN,
                pan_no: firstItem.pan_no,
                state_name: firstItem.state_name,
                company_pincode: firstItem.company_pincode,
            };
        }
        // Return default values or handle the case where the array is empty
        return {
            department_name: 0,
            company: 0,
            company_branch: 0,
            address: 0,
            phone: 0,
            cell: 0,
            email: 0,
            website: 0,
            GSTIN: 0,
            pan_no: 0,
            state_name: 0,
            company_pincode: 0,
        };
    }

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-1 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                    </div>
                    <div className="col-sm-11 transformed">
                        <div className='erp-invoice-print-label text-center'>
                            <span className='erp-invoice-print-label-lg'>{dispatchNoteContent.company.company}</span><br />
                            <span className='erp-invoice-print-label-md'>({dispatchNoteContent.company.company_branch})</span>
                        </div>
                        {/* <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch})</span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>EmailId: {itemData.email} | Phone No.: {itemData.phone}  </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='erp-invoice-print-label-lg text-center'>
                            Gate Pass Slip {dispatchNoteContent.dispatchDetails.dispatch_schedule_type}
                        </div>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg '>Gate Pass No. :  {dispatchNoteContent.gatePassDetails.gate_pass_no} </dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end'>Gate Pass Date :  {dispatchNoteContent.gatePassDetails.gate_pass_date} </dt>
                    </div>
                </div>
                <div className='row  border-top border-dark p-0 m-0'>
                    <dt className='row pb-0 mb-0 erp-invoice-print-label-md-lg'>Customer Details:</dt>
                    <div className="row pb-0 mb-0">
                        <div class="col-sm-5">
                            <dl class="row pb-0 mb-0">
                                <dt class="col-sm-4 erp-invoice-print-label-md-lg">Customer Name:</dt>
                                <dd class="col-sm-8 erp-invoice-print-label">{dispatchNoteContent.customerDetails.customer}</dd>
                            </dl>
                        </div>
                        <div class="col-sm-5">
                            <dl class="row pb-0 mb-0">
                                <dt class="col-sm-4 erp-invoice-print-label-md-lg">Person Name:</dt>
                                <dd class="col-sm-8 erp-invoice-print-label">{contactDetails[0].cust_contact_person}</dd>
                            </dl>
                        </div>
                        <div class="col-sm-2">
                            <dl class="row pb-0 mb-0">
                                <dt class="col-sm-6 erp-invoice-print-label-md-lg px-0">Contact No:</dt>
                                <dd class="col-sm-6 erp-invoice-print-label">{contactDetails[0].cust_contact_no}</dd>
                            </dl>
                        </div>

                        {/* <div className="col-sm-4">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Customer Name:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'>{dispatchNoteContent.customerDetails.customer}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg d-none'>Address:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label d-none'>{dispatchNoteContent.customerDetails.address}</dd>
                                
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg d-none'>Email:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label d-none'>{dispatchNoteContent.customerDetails.customer_email}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-8">
                            <dl className="row pb-0 mb-0">
                                <dd className="col-sm-10 m-0 p-0">
                                    <dl className="row m-0 p-0">
                                        <dl className="col-sm-6 mb-0 pb-0">
                                        <dt className="erp-invoice-print-label-md-lg">Person Name: {contactDetails[0].cust_contact_person}</dt>
                                        </dl>
                                        <dl className="col-sm-6 mb-0 pb-0">
                                        <dt className="erp-invoice-print-label-md-lg">Contact No: {contactDetails[0].cust_contact_no}</dt>
                                        </dl>
                                    </dl>
                                </dd>
                            </dl>
                            
                            <dl className="row pb-0 mb-0 d-none">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Phone No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'></dd>
                            </dl>
                        </div> */}
                    </div>

                    <div className='row  border-top border-dark p-0 m-0'>
                        <dt className='erp-invoice-print-label-md-lg'>Vehicle Details:</dt>
                        <dl className="row pb-0 mb-0">
                            <div className="col-sm-6">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Vehicle No:</dt>
                                    <dd className='col-sm-9 erp-invoice-print-label'> {dispatchNoteContent.vehicalDetails.vehicale_no}</dd>

                                    <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Driver Name:</dt>
                                    <dd className='col-sm-9 erp-invoice-print-label'>{dispatchNoteContent.vehicalDetails.driver_name}</dd>
                                
                                    <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Seal No:</dt>
                                    <dd className='col-sm-9 erp-invoice-print-label'>{dispatchNoteContent.vehicalDetails.seal_no}</dd>                               
                                </dl>
                            </div>
                            <div className="col-sm-6">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Count No:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'>{dispatchNoteContent.vehicalDetails.count_no}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg d-none'>Net weight:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label d-none'>{dispatchNoteContent.vehicalDetails.net_weight}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Driver Contact No.:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'>{dispatchNoteContent.vehicalDetails.driver_contact_no}</dd>
                                </dl>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    )
    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For </dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Account Receiver Signatory</dt>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {itemData.company} </dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Loading Supervisor Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {/* <div className="container"> */}
            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row headerContentHt" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {/* {data.length !== 0 && ( */}
                                                            <thead>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center">Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center col-6" >Material Name</th>
                                                                    <th className="erp_invoice_table_th text-center col-2">Count</th>
                                                                    <th className="erp_invoice_table_th text-center col-3">Lot No.</th>
                                                                    {/* <th className="erp_invoice_table_th text-center col-2" rowspan={2}>Sub Lot No.</th> */}
                                                                    <th className="erp_invoice_table_th text-center">Quantity</th>
                                                                    <th className="erp_invoice_table_th text-center">Weight</th>
                                                                </tr>
                                                            </thead>
                                                            {/* )} */}
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_material_print_name}</td>
                                                                                <td className="erp_invoice_table_td">{item.count_no}</td>
                                                                                <td className="erp_invoice_table_td">{item.lot_no}</td>
                                                                                {/* <td className="erp_invoice_table_td">{item.sub_lot_no}</td> */}
                                                                                <td className="erp_invoice_table_td text-end">{item.exp_qty}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.exp_wt}</td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                }
                                                                <tr className='invoiceFooterTr' id="footerTr">
                                                                    <td colSpan={12}>
                                                                        {invoiceFooter}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})
export default GatePassSlip;

