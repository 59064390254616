import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';

const GenericAttendanceSummaryWorkers = ({

    attendanceSummaryDtlTblData,
    employeeType,
    departmentName,
    subDepartmentName,
    isLastTable = false,

}) => {
    console.log("departmentName" + departmentName);
    const tableContent = useMemo(() => {
        debugger
        if (attendanceSummaryDtlTblData.length === 0) {
            return null;
        }

        return (
            <>
                <style>
                    {`
                        @page { 
                                size: A4 portrait;
                                margin: 2%;
                            }
                        .erp_table {
                            font-size: 3px !important; /* Default font size */
                        }
                           .erp-invoice-print-label-md-lg , .erp-invoice-print-label{
                                font-size: 13px !important;
                                } 
                        .erp_table_th, .erp_table_td {
                            border: 1px solid black;
                            padding-top: 2px !important;
                            padding-bottom: 2px !important;
                        }
                            .erp-form-label-lg {
                            font-size: 15px !important;
                            }
                         
                    `}
                </style>

                <label className="erp-form-label-lg"> Department : {subDepartmentName} {`[${departmentName}]`}  </label>

                {/* table for Staff. */}
                <Table className="erp_table" id='MonthlySalaryDtlsTbl' style={{ whiteSpace: 'normal' }}>
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" style={{ width: '10px' }}>Sr. No.</th>
                            <th className="erp_table_th align-middle" style={{ width: '10px' }}>Code</th>
                            <th className="erp_table_th align-middle" style={{ width: '250px' }}>Employee Name</th>
                            {employeeType === "All" || employeeType === "Workers" ? (
                                <th className="erp_table_th align-middle" style={{ width: '20px' }}>Total Days (Hours)</th>
                            ) : (
                                <th className="erp_table_th align-middle" style={{ width: '20px' }}>Total Days</th>
                            )}

                            {/* <th className="erp_table_th" style={{ width: '20px' }}>Total Days(Hours)</th> */}
                            <th className="erp_table_th align-middle" style={{ whiteSpace: 'normal' }}>Presenty Details</th>

                        </tr>

                    </thead>
                    <tbody>
                        {/* Start content Rows  */}
                        {attendanceSummaryDtlTblData?.map((salaryDtl, index) => {

                            return (
                                <React.Fragment key={`employee-${index}`}>
                                    <tr>
                                        <td className="erp_table_td" >{index + 1}</td>
                                        <td className="erp_table_td">{salaryDtl['employee_code']}</td>
                                        <td className="erp_table_td">{salaryDtl['employee_name']}</td>
                                        <td className="erp_table_td">{salaryDtl['total_salary_days']}</td>
                                        <td className="erp_table_td" style={{ whiteSpace: 'normal' }}>{salaryDtl['Presenty_Details']}</td>

                                    </tr>

                                </React.Fragment>
                            );
                        })}

                    </tbody>
                </Table >
            </>
        );
    }, [attendanceSummaryDtlTblData]);
    return tableContent;
}

export default GenericAttendanceSummaryWorkers;
