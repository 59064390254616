import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useLocation, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
// Buttons
import MDButton from "components/MDButton";
import ComboBox from "Features/ComboBox";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from "FrmGeneric/FrmValidations";

function FrmproductUnitConversionEntry() {

  //changes by ujjwala on 6/1/2024
  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;
  const { state } = useLocation();
  const { productConversionId = 0, keyForViewUpdate, compType } = state || {}

  const validate = useRef();
  const child = useRef();
  //  Form Fields
  const [conversion_id, setProductConversionId] = useState(productConversionId)
  const [producttypeTypeOption, setProducttypeTypeOption] = useState([]);

  //  Form Fields
  const [cmb_product_form_unit_id, setProductFromUnitId] = useState()
  const [cmb_product_to_unit_id, setProductToUnitId] = useState('');
  const [conversionFactorId, setConversionFactorId] = useState();

  // Combo Options
  const [productFormUnitOptions, setProductFormUnitOptions] = useState([])
  const [productToUnitOptions, setProductToUnitOptions] = useState([])
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  const [txt_remark, setRemark] = useState('');
  const [chk_isactive, setIsActive] = useState(true);
  // For navigate
  const navigate = useNavigate();

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/FrmproductUnitConversionListing`)
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      await FnFetchPropertyRecords();
      await fillComobos();
      if (productConversionId !== 0) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("producttypeFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };

  const validateFields = () => {
    validate.current.validateFieldsOnChange('producttypeFormId')
  }


  const fillComobos = async () => {
    const productFormUnitApiCall = await child.current.fillMasterData("smv_product_unit", "", "")
    setProductFormUnitOptions(productFormUnitApiCall)

    const productToUnitApiCall = await child.current.fillMasterData("smv_product_unit", "", "")
    setProductToUnitOptions(productToUnitApiCall)
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productFormUnit':
        let selectedFromUnitId = document.getElementById('cmb_product_form_unit_id').value;
        setProductFromUnitId(selectedFromUnitId)
        if (selectedFromUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 100)
        }

        if (selectedFromUnitId !== "") {
          $('#error_cmb_product_form_unit_id').hide();
        }
        break;

      case 'productToUnit':
        let selectedToUnitId = document.getElementById('cmb_product_to_unit_id').value;
        setProductToUnitId(selectedToUnitId)
        if (selectedToUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 100)
        }

        if (selectedToUnitId !== "") {
          $('#error_cmb_product_To_unit_id').hide();
        }
        break;
    }
    await unitConversion();
  }

  const unitConversion = async () => {
    $("#error_cmb_product_to_unit_id").hide();
    $("#error_cmb_product_form_unit_id").hide();

    let fromUnitSelectBox = document.querySelector("#cmb_product_form_unit_id");
    let fromUnit = fromUnitSelectBox.options[fromUnitSelectBox.selectedIndex].getAttribute('fieldName');

    let selectedToUnit = document.querySelector("#cmb_product_to_unit_id");
    let toUnit = selectedToUnit.options[selectedToUnit.selectedIndex].getAttribute('fieldName');

    if (fromUnit == "Milli Gram" && toUnit == "Gram") {
      let conversionValue = 1 / 1000;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "Gram" && toUnit == "Milli Gram") {
      let conversionValue = 1 * 1000;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "MiliLitre" && toUnit == "Litre") {
      let conversionValue = 1 / 1000;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "Litre" && toUnit == "MiliLitre") {
      let conversionValue = 1 * 1000;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "Kilo Gram" && toUnit == "Gram") {
      let conversionValue = 1 * 1000;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "Gram" && toUnit == "Kilo Gram") {
      let conversionValue = 1 / 1000;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "Quintal" && toUnit == "Tones") {
      let conversionValue = 1 / 10;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == "Tones" && toUnit == "Quintal") {
      let conversionValue = 1 * 10;
      setConversionFactorId(conversionValue);
    }
    else if (fromUnit == toUnit) {
      let conversionValue = "1";
      setConversionFactorId(conversionValue);
    }
  }

  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{4}$/
    var dot = '.';
    switch (key) {
      case 'conversionFactorId':
        var pFromRangeVal = $('#conversionFactorId').val();
        if (!regexNo.test(pFromRangeVal) && pFromRangeVal.length <= 10 || pFromRangeVal.indexOf(dot) === 10) {
          if (numCheck.test(pFromRangeVal)) {
            setConversionFactorId(pFromRangeVal)
          }

        }
        break;
    }
  }
  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("producttypeFormId");
      if (checkIsValidate === true) {
        const data = {
          company_id: COMPANY_ID,
          created_by: UserName,
          modified_by: conversion_id === 0 ? null : UserName,
          product_unit_conversion_id: conversion_id,
          product_from_unit_id: cmb_product_form_unit_id,
          product_to_unit_id: cmb_product_to_unit_id,
          convsersion_factor: conversionFactorId,
          remark: txt_remark,
          is_active: chk_isactive,
        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productunitconversion/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = child.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();
          // if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
          //     navigate(`/Masters/ProductTypeListing`);
          // }
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  };

  const clearFormFields = () => {
    setProductFromUnitId('');
    setProductToUnitId('');
    setConversionFactorId('');
  }

  const FnFetchPropertyRecords = async () => {
    var controlName = ["smv_product_unit_conversion"];
    if (child.current) {
      var productConversion = await child.current.fillMasterData(controlName[0], "", "")
      console.log("conversionList: ", productConversion);
      setProducttypeTypeOption(productConversion)
    }
  }

  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productunitconversion/FnShowParticularRecordForUpdate/${conversion_id}`)
      const updateRes = await apiCall.json();
      let resp = JSON.parse(updateRes.data)
      setProductConversionId(resp.conversion_id)
      setProductFromUnitId(resp.product_from_unit_id);
      setProductToUnitId(resp.product_to_unit_id);
      setConversionFactorId(resp.convsersion_factor);
      setRemark(resp.remark);
      setIsActive(resp.is_active);
      $('#saveBtn').show();
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Unit':
        const productFormUnitApiCall = await child.current.fillMasterData("smv_product_unit", "", "")
        setProductFormUnitOptions(productFormUnitApiCall)
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Unit':
        return <FrmProductUnitEntry btn_disabled={true} />;

      default:
        return null;
    }
  }

  return (
    <>
      <ComboBox ref={child} />
      <FrmValidations ref={validate} />

      <div className='erp_top_Form'>
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Unit Conversion {actionType} </label>
          </div>
          <form id="producttypeFormId">
            <div className="row erp_transporter_div">

              <div className="row">
                {/* //first column */}
                <div className="col-sm-6 erp_form_col_div">
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label"> From Unit <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_form_unit_id" className="form-select form-select-sm" value={cmb_product_form_unit_id} onChange={() => { comboOnChange('productFormUnit'); validateFields() }} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {productFormUnitOptions?.map(productFromUnit => (
                          <option value={productFromUnit.field_id} fieldname={productFromUnit.field_name}>{productFromUnit.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_form_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">To Unit <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_to_unit_id" className="form-select form-select-sm" value={cmb_product_to_unit_id} onChange={(e) => { comboOnChange('productToUnit'); validateFields(); }}>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {productToUnitOptions?.map(productToUnit => (
                          <option value={productToUnit.field_id} fieldname={productToUnit.field_name}>{productToUnit.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_to_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Conversion Factor <span className="required">*</span></Form.Label>
                    </div>

                    <div className="col">
                      <Form.Control type="text" className="erp_input_field" id="conversionFactorId" value={conversionFactorId} onChange={(e) => { validateNo('conversionFactorId'); validateFields() }} maxLength="15" />
                      <MDTypography variant="button" id="error_departmentName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                </div>
                <div className="col-sm-6 erp_form_col_div">



                  <div className="row">
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label"> Remark </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                      <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Is Active</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
          <div className="erp_frm_Btns">
            {/* onClick={updateCustomer}  */}
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/FrmproductUnitConversionListing/reg' : '/Masters/FrmproductUnitConversionListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular">Back</MDButton>
            {/* <MDButton type="submit" id="saveBtn" className={`btn erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={handleSubmit} variant="button"
              fontWeight="regular">save</MDButton> */}

            <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>

          </div >

          {/* Success Msg Popup */}
          <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
          {/* Error Msg Popup */}
          <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
        </div >

        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            {displayRecordComponent()}

          </Modal.Body>
        </Modal >

      </div >

    </>
  )
}

export default FrmproductUnitConversionEntry
