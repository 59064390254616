import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";

import Datatable from "components/DataTable";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import ReactDOMServer from 'react-dom/server';
import ExcelExport from "Features/Exports/ExcelExport";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function ManualAttendanceRegister() {

    // Globally Constants.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME, FINANCIAL_SHORT_NAME, COMPANY_ADDRESS } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    // ****** Hook For Navigation
    const navigate = useNavigate();
    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);
    const exlsExp = useRef();

    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);

    // Input field's Hooks;
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('0');
    const [cmb_salary_month, setSalaryMonth] = useState('');
    const [cmb_salary_year, setSalaryYear] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [txt_employee_code, setEmployeeCode] = useState();


    const [dt_from_date, setAttFromDate] = useState('');
    const [dt_to_date, setAttToDate] = useState('');
    // combo's options hooks;
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);

    // Hooks For table;
    const [attendanceDtlTblData, setAttendanceDtlTblData] = useState([]);
    const [attendanceDtlTblCols, setAttendanceDtlTblCols] = useState([]);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);


    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);

    // FINANCIAL_SHORT_NAME wants like 2024
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );

    // On Page Load.
    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);
    const validateShiftManagementDetails = () => {
        if (cmb_employee_type_id === '') {
            let errorElement = document.getElementById("error_cmb_employee_type_id");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_employee_type_id").focus();
            return false;
        }
        if (dt_from_date === '') {
            let errorElement = document.getElementById("error_dt_from_date");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("dt_from_date").focus();
            return false;
        }
        if (dt_to_date === '') {
            let errorElement = document.getElementById("error_dt_to_date");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("dt_to_date").focus();
            return false;
        }

        return true;
    }

    const FnCombosOnChangeDate = (key, value) => {
        try {
            switch (key) {

                case 'FromDate':
                    // if (value !== '')
                    //     document.getElementById('error_dt_from_date').style.display = 'none';

                    // setAttFromDate(value);
                    const formattedFromDate = value ? value.toLocaleDateString('en-CA') : '';
                    if (formattedFromDate !== '') { document.getElementById('error_dt_from_date').style.display = 'none'; }
                    setAttFromDate(formattedFromDate);
                    // If the start date is set after the end date, clear the end date
                    if (dt_to_date && new Date(formattedFromDate) > new Date(dt_to_date)) {
                        setAttToDate('');
                    }
                    break;
                case 'ToDate':
                    // if (value !== '')
                    //     document.getElementById('error_dt_to_date').style.display = 'none';

                    // setAttToDate(value);
                    const formattedToDate = value ? value.toLocaleDateString('en-CA') : '';
                    if (formattedToDate !== '') { document.getElementById('error_dt_to_date').style.display = 'none'; }
                    setAttToDate(formattedToDate)
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.log('error', error);
        }
    }

    const FnComboOnChange = async (comboName) => {
        debugger
        try {
            switch (comboName) {
                case "Department":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    // subDepartmentComboRef.current = null;
                    // setSubDepartmentId('');
                    let selectedEmployeeTypes = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpt = departmentComboRef.current?.department_id || 'All';

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmployeeTypes && selectedEmployeeTypes !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmployeeTypes });
                    }
                    if (selectedDpt && selectedDpt !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpt });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name, old_employee_code: prop.old_employee_code }));
                            rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All', old_employee_code: '' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');
                    // }
                    break;

                case "subDepartment":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    let selectedDepTS = departmentComboRef.current.department_id;
                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentLists);

                    // subDepartmentComboRef.current = null;
                    // setSubDepartmentId('');
                    let selectedEmpType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDpts = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDPts = subDepartmentComboRef.current?.department_id || 'All';

                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmpType && selectedEmpType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmpType });
                    }
                    if (selectedDpts && selectedDpts !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDpts });
                    }
                    if (selectedSubDPts && selectedSubDPts !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDPts });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name, old_employee_code: prop.old_employee_code }));
                            rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All', old_employee_code: '' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');

                    break;
                case "EmployeeType":
                    setEmployeeCode('');
                    setAttendanceDtlTblData([]);
                    employeeComboRef.current = null;
                    setEmployeeCode('');
                    let selectedEmployeeType = employeeTypesComboRef.current?.field_name || 'All';
                    let selectedDepartments = departmentComboRef.current?.department_id || 'All';
                    let selectedSubDepartment = subDepartmentComboRef.current?.department_id || 'All';
                    resetGlobalQuery();
                    globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
                    globalQuery.table = "cmv_employee"
                    if (selectedEmployeeType && selectedEmployeeType !== 'All') {
                        globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmployeeType });
                    }
                    if (selectedDepartments && selectedDepartments !== 'All') {
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDepartments });
                    }
                    if (selectedSubDepartment && selectedSubDepartment !== 'All') {
                        globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
                    }
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    comboDataFunc.current.fillFiltersCombo(globalQuery)
                        .then(rcvdEmpls => {
                            rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name, old_employee_code: prop.old_employee_code }));
                            rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All', old_employee_code: '' });
                            setEmployeeOpts(rcvdEmpls);
                        });
                    // remove the already selected employee
                    // employeeComboRef.current = null;
                    setEmployeeId('');
                    break;

                case 'EmployeeCode':
                    debugger
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);
                    let findEmployee = null;
                    if (serachemployeeCode.length >= 3) {
                        findEmployee = employeeOpts.find((employee) => {
                            return employee.employee_code === serachemployeeCode
                                || employee?.label?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                || employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.employee_id);
                        employeeComboRef.current = {
                            employee_id: findEmployee.employee_id,
                            employee_code: findEmployee.employee_code,
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                        };
                    }
                    break;
                case 'EmplCode':
                    // setEmployeeCode(employeeComboRef.current.employee_code);
                    if (employeeComboRef.current && employeeComboRef.current.employee_code) {
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    }
                    break;
                case 'Employee':
                    debugger
                    var employeeID = employeeComboRef.current?.employee_id;
                    if (employeeID !== "") {
                        // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeComboRef.current.employee_id);
                        setEmployeeCode(employeeComboRef.current.employee_code);
                    } else {
                        setEmployeeCode('');
                    }
                default:
                    break;
            }

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const FnFillComobos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name", "old_employee_code"];
            globalQuery.table = "cmv_employee"

            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmpls => {
                    rcvdEmpls = rcvdEmpls?.map(prop => ({ ...prop, value: prop.employee_id, employee_code: prop.employee_code, label: prop.employee_name, old_employee_code: prop.old_employee_code }));
                    rcvdEmpls.unshift({ employee_id: '', employee_code: '', value: 'All', label: 'All', old_employee_code: '' });
                    setEmployeeOpts(rcvdEmpls);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "amv_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeType' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    const emplTypes = [
                        // { value: '', label: 'Select', field_id: '', field_name: '', },
                        { value: '0', label: 'All', field_id: 'All', field_name: 'All', },
                        ...rcvdEmplTypes.map((emplType) => ({ ...emplType, value: emplType.field_id, label: emplType.field_name, })),
                    ];
                    setEmployeeTypesOpts(emplTypes);
                });
            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {

        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(salaryYearComboRef?.current?.value || null)) { $('#error_cmb_salary_year').hide(); }
        if (isEmpty(salaryMonthComboRef.current?.value)) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    const goBack = () => {
        navigate("/Dashboard")
    }


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintImmediatePunch = useReactToPrint({
        content: () => {
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            // Remove the print button from the cloned content

            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: "Manual Attendance Register"
    });

    function formatDate(dateString) {
        const parts = dateString.split('-'); // Split by dash "-"
        const day = parts[0].padStart(2, '0'); // Ensure day is two digits
        const month = parts[1].padStart(2, '0'); // Ensure month is two digits
        const year = parts[2]; // Year remains unchanged
        return `${year}-${month}-${day}`;
    }

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Monthly Punch Details
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                            {/* <div className='erp-invoice-print-label-lg text-center'>
                                For Month: {salaryMonthComboRef.current?.label}   Year: {salaryYearComboRef.current?.value}
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'></div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">From Date : <span className="erp-invoice-print-label">{document.getElementById("dt_from_date").value}</span></dt>

                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                To Date: <span className="erp-invoice-print-label">{document.getElementById("dt_to_date").value}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Employee Type : <span className="erp-invoice-print-label">{
                                employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                    `${employeeTypesComboRef.current?.label}`
                                )
                            }</span></dt>

                        </div>
                        <div className="col-sm-6">
                            {/* <dt className="erp-invoice-print-label-md-lg">Employee Name :<span className="erp-invoice-print-label"> {
                                `${employeeComboRef.current?.employee_name}`
                            }</span></dt> */}
                            <dt className="erp-invoice-print-label-md-lg">
                                Employee Name: <span className="erp-invoice-print-label">{employeeComboRef.current?.label === 'Select' || employeeComboRef.current?.label === undefined || employeeComboRef.current?.label === '' ? 'All' : employeeComboRef.current?.label}
                                </span></dt>
                        </div>

                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Department Name: <span className="erp-invoice-print-label">{departmentComboRef.current?.label === 'Select' || departmentComboRef.current?.label === undefined || departmentComboRef.current?.label === '' ? 'All' : departmentComboRef.current?.label}
                                </span></dt>

                        </div>
                        <div className="col-sm-6">

                            {/* <dt className="erp-invoice-print-label-md-lg"> Sub Department Name :<span className="erp-invoice-print-label"> {
                                `${subDepartmentComboRef.current?.label}`
                            }</span></dt> */}
                            <dt className="erp-invoice-print-label-md-lg">
                                Sub Department Name: <span className="erp-invoice-print-label">{subDepartmentComboRef.current?.label === 'Select' || subDepartmentComboRef.current?.label === undefined || subDepartmentComboRef.current?.label === '' ? 'All' : subDepartmentComboRef.current?.label}
                                </span></dt>
                        </div>

                    </div>
                </div>
            </>
        );
    };



    // ********* PrintOut Functionalities Ends. ****************************************


    const capitalizeStr = str => str.charAt(0).toUpperCase() + str.slice(1);
    const formatTableHeader = key => key.split('_').map(capitalizeStr).join(' ');


    const FnLoadAttendanceData = async () => {
        debugger;
        try {
            setIsLoading(true);
            const validate = validateShiftManagementDetails();
            if (validate) {

                let FromDate = document.getElementById("dt_from_date").value;
                let ToDate = document.getElementById("dt_to_date").value;

                let selectedDepartment = departmentComboRef.current?.department_id || null;
                let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
                let selectedEmplType = employeeTypesComboRef.current?.field_name || null;
                let selectedEmployee = employeeComboRef.current?.employee_code || null;

                if (selectedEmployee === null) {
                    FnComboOnChange("subDepartment");
                    setEmployeeId('All');
                }

                if (selectedDepartment === null) {
                    setDepartmentId('All');
                }

                if (selectedSubDepartment === null) {
                    setSubDepartmentId('All');
                }

                resetGlobalQuery();
                globalQuery.columns = [
                    "htatm.attendance_date",
                    "empl.department_name",
                    "empl.sub_department_name",
                    "htatm.employee_code",
                    "htatm.employee_name",
                    "htatm.employee_type",
                    "htatm.attendance_date_time", // Include the attendance date time for sorting
                    "htatm.attendance_type"
                ];
                globalQuery.table = "hm_manual_attendance htatm";
                globalQuery.conditions.push({ field: "htatm.is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "htatm.company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "htatm.attendance_date", operator: ">=", value: formatDate(FromDate) });
                globalQuery.conditions.push({ field: "htatm.attendance_date", operator: "<=", value: formatDate(ToDate) });

                if (selectedEmplType && selectedEmplType !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.employee_type", operator: "=", value: selectedEmplType });
                }
                if (selectedEmployee && selectedEmployee !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.employee_code", operator: "=", value: selectedEmployee });
                }
                if (selectedDepartment && selectedDepartment !== 'All') {
                    globalQuery.conditions.push({ field: "htatm.department_id", operator: "=", value: selectedDepartment });
                }

                globalQuery.joins = [
                    {
                        table: "cm_department empldep",
                        type: "LEFT",
                        on: [
                            { left: "empldep.department_id", right: "htatm.department_id" },
                            // { left: "empldep.company_id", right: "htatm.company_id" }
                        ]
                    },
                    {
                        table: "cmv_employee empl",
                        type: "LEFT",
                        on: [
                            { left: "empl.employee_code", right: "htatm.employee_code" },
                            { left: "empl.company_id", right: "htatm.company_id" }
                        ]
                    }
                ];

                globalQuery.orderBy = ['htatm.attendance_date ASC', 'htatm.attendance_transaction_id ASC', 'empldep.department_name ASC', 'empl.sub_department_name ASC'];

                let getAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                if (getAttendanceData.length === 0) {
                    setAttendanceDtlTblCols([]);
                    setAttendanceDtlTblData([]);
                } else {
                    const attendanceRecords = {}; // Object to store processed attendance records

                    // Process fetched data to determine intime and outtime
                    getAttendanceData.forEach(record => {
                        const key = `${record.employee_code}_${record.attendance_date}`;
                        if (!attendanceRecords[key]) {
                            attendanceRecords[key] = {
                                attendance_date: record.attendance_date,
                                department_name: record.department_name,
                                sub_department_name: record.sub_department_name,
                                employee_code: record.employee_code,
                                employee_name: record.employee_name,
                                employee_type: record.employee_type,
                                attendance_type: record.attendance_type,
                                intime: record.attendance_date_time, // First entry as intime
                                outtime: record.attendance_date_time  // Initialize outtime with the same value
                            };
                        } else {
                            attendanceRecords[key].outtime = record.attendance_date_time; // Update outtime for each subsequent entry
                        }
                    });

                    // Convert processed records into array format for display
                    const getAttendanceDataProcessed = Object.values(attendanceRecords).map((record, index) => ({
                        sr_no: index + 1,
                        ...record,
                        intime: new Date(record.intime).toLocaleTimeString(),
                        outtime: new Date(record.outtime).toLocaleTimeString()
                    }));

                    // Prepare table headers
                    const excludedKeysToDisplay = [
                        "company_id", "department_id", "sub_department_id", "attendance_process_id",
                        "process_date", "employee_type", "attendance_month", "attendance_year", "attendance_flag",
                        "company_id", "company_branch_id", "department_id", "sub_department_id", "employee_id"
                    ];

                    const headersArray = Object.keys(getAttendanceDataProcessed[0])
                        .filter(key => !excludedKeysToDisplay.includes(key))
                        .map(key => ({
                            Headers: formatTableHeader(key),
                            accessor: key
                        }));

                    setAttendanceDtlTblCols(headersArray);
                    setAttendanceDtlTblData(getAttendanceDataProcessed);
                    console.table(getAttendanceDataProcessed);
                }
            } else {
                setAttendanceDtlTblCols([]);
                setAttendanceDtlTblData([]);
            }
        } catch (error) {
            console.log("error on filling combos: ", error);
            navigate('/Error');
        } finally {
            setIsLoading(false);
        }
    };

    //************************************* */

    const FnRenderReportTbl = useMemo(() => {
        if (attendanceDtlTblData.length === 0) { return null; }

        // const totalPresent = attendanceDtlTblData.reduce((total, item) => total + (item.min_att_time !== 'AB' ? 1 : 0), 0);
        // const totalAbsent = attendanceDtlTblData.length - totalPresent;
        const totalCount = attendanceDtlTblData.length; // Total count

        return (
            <>
                <style>
                    {`
                        @page { 
                            size: A4 portrait;
                            margin: 2%;
                        }
                        .erp_table {
                            font-size: 3px !important; /* Default font size */
                        }
                            .erp-invoice-print-label-md-lg , .erp-invoice-print-label{
                            font-size: 14px !important;

                            }
                        .erp_table_th, .erp_table_td {
                            border: 1px solid black;
                            padding-top: 2px !important;
                            padding-bottom: 2px !important;
                        }
                    `}
                </style>


                <Table className="erp_table" id='PunchDtlsTbl' style={{ whiteSpace: 'normal' }} responsive striped>
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" >Sr. No.</th>
                            <th className="erp_table_th align-middle" >Date</th>
                            <th className="erp_table_th align-middle col-2" >Department Name</th>
                            <th className="erp_table_th align-middle col-2" >Sub Department Name</th>
                            <th className="erp_table_th align-middle col-1">Employee Code</th>
                            <th className="erp_table_th align-middle col-3">Employee Name</th>
                            <th className="erp_table_th align-middle col-1">InTime</th>
                            <th className="erp_table_th align-middle col-1">OutTime</th>
                            <th className="erp_table_th align-middle col-1">Presenty Status</th>
                        </tr>
                    </thead>

                    <tbody>

                        {
                            attendanceDtlTblData.map((item, index) => (
                                <tr key={index} style={{ height: "25px" }}>
                                    <td className="erp_table_td">{index + 1}</td>
                                    <td className="erp_table_td">{item.attendance_date}</td>
                                    <td className="erp_table_td">{item.department_name}</td>
                                    <td className="erp_table_td">{item.sub_department_name}</td>
                                    <td className="erp_table_td">{item.employee_code}</td>
                                    <td className="erp_table_td">{item.employee_name}</td>
                                    <td className="erp_table_td">{item.intime}</td>
                                    <td className="erp_table_td">{item.outtime}</td>
                                    <td className="erp_table_td">{item.attendance_type}</td>
                                </tr>
                            ))
                        }
                        <tr className="summary-row table-light" style={{ height: "25px", fontWeight: "bold" }}>
                            <th className="erp_table_th" colSpan="8">Summary </th>
                            <th className="erp_table_th" colSpan="2">Total: {totalCount}</th>
                            {/* <th className="erp_table_th" colSpan="2">Total: {totalCount}, Present: {totalPresent}, Absent: {totalAbsent}</th> */}

                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }, [attendanceDtlTblData]);
    //-----------------------------------Export functionality -------------------
    const FnExport = async () => {
        try {
            setIsLoading(true)
            if (attendanceDtlTblData.length !== 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

                let filtrKeyValue = {};


                filtrKeyValue['0'] = "From Date : " + document.getElementById("dt_from_date").value;
                filtrKeyValue['1'] = "To Date : " + document.getElementById("dt_to_date").value;

                let departmentName = departmentComboRef.current?.label || '';
                if (departmentName === '' || departmentName === 0 || departmentName === undefined) {
                    departmentName = 'All';
                }

                filtrKeyValue['2'] = "Department Name : " + departmentName;
                let subDepartmentName = subDepartmentComboRef.current?.label || '';
                // let subDepartmentName = document.getElementById('cmb_sub_department_id').value;
                if (subDepartmentName === '' || subDepartmentName === 0 || subDepartmentName === undefined) {
                    subDepartmentName = 'All';
                }

                filtrKeyValue['3'] = "Sub Department Name : " + subDepartmentName;
                let employeeType = employeeTypesComboRef.current?.field_name || '';
                if (employeeType === '' || employeeType === 0 || employeeType === undefined) {
                    employeeType = 'All';
                }

                filtrKeyValue['4'] = "Employee Type : " + employeeType;
                let employeeName = employeeComboRef.current?.employee_name || '';
                // let employeeName = document.getElementById('cmb_employee_id').value;
                if (employeeName === '' || employeeName === 0 || employeeName === undefined) {
                    employeeName = 'All';
                }

                filtrKeyValue['5'] = "Employee Name : " + employeeName;
                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;
                jsonToExportExcel['columns'] = attendanceDtlTblCols;


                const updatedattendanceDtlTblData = [...attendanceDtlTblData];
                const totalCount = updatedattendanceDtlTblData.length;
                // Prepare the summary row
                // const summaryRow = {
                //     attendance_type: 'Summary',
                //     outtime: `Total: ${totalCount}`
                // };

                // // Append the summary row to the updated array
                // updatedattendanceDtlTblData.push(summaryRow);

                updatedattendanceDtlTblData.map((punchDetails, Index) => {
                    jsonToExportExcel['allData'][Index] = punchDetails;
                });



                jsonToExportExcel['headings']['ReportName'] = "Manual Attendance Register"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = COMPANY_ADDRESS

                await exlsExp.current.excel(jsonToExportExcel, 'ManualAttendance' + '_From_' + document.getElementById("dt_from_date").value + '-' + document.getElementById("dt_to_date").value)
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    // Component View Return
    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Manual Attendance Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            selected={dt_from_date}
                                            id="dt_from_date"
                                            onChange={(date) => FnCombosOnChangeDate('FromDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            selectsStart
                                            startDate={dt_from_date ? new Date(dt_from_date) : null}
                                            endDate={dt_to_date ? new Date(dt_to_date) : null}
                                            maxDate={new Date()}
                                        />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            selected={dt_to_date}
                                            id="dt_to_date"
                                            onChange={(date) => FnCombosOnChangeDate('ToDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            selectsEnd
                                            startDate={dt_from_date ? new Date(dt_from_date) : null}
                                            endDate={dt_to_date ? new Date(dt_to_date) : null}
                                            minDate={dt_from_date ? new Date(dt_from_date) : null}
                                            maxDate={new Date()}
                                        />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>
                                </div>
                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnComboOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnComboOnChange("subDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnComboOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee Name :<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-3">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { FnComboOnChange('EmployeeCode'); }}
                                            onBlur={(e) => { FnComboOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div> */}
                                    {/* <div className='col-12 col-sm-8'> */}
                                    <div className='col'>
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                                FnComboOnChange('Employee');
                                            }}

                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="save-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnLoadAttendanceData(); }}
                                >Show</MDButton>
                                <MDButton type="button" className="ms-2 erp-gb-button" onClick={FnExport} variant="button" fontWeight="regular" disabled={attendanceDtlTblData.length === 0}>Export To Excel</MDButton>

                            </div>
                        </div>

                        <hr />

                        <div className="row px-lg-2 d-block">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg">Employee's Manual Atttendance Details</label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">
                                        {/* {
                                            attendanceDtlTblData.length === 0
                                                ? <>
                                                    <Card id="NoRcrdId" >
                                                        <Card.Body>No records found...</Card.Body>
                                                    </Card>
                                                </>
                                                : <>
                                                    <Datatable data={attendanceDtlTblData} columns={attendanceDtlTblCols} freezeColumnCount={3} striped />
                                                </>
                                        } */}

                                        <div className="row print-content" ref={printComponentRef} >
                                            {FnRenderReportTbl}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>
                                {
                                    attendanceDtlTblData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2 print-button"

                                                onClick={(e) => { FnPrintImmediatePunch(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>

        </>
    )
}
export default ManualAttendanceRegister;