import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { Form, Table } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import GenerateTAutoNo from 'FrmGeneric/GenerateTAutoNo';

import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ExcelExport from 'Features/Exports/ExcelExport';
import FrmValidations from 'FrmGeneric/FrmValidations';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { CircularProgress } from '@material-ui/core';


function FrmSpinPlanEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const { state } = useLocation();
    const { spinPlanId = 0, plantId, plantCode, keyForViewUpdate = 'Add', compType } = state || {};

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [action_Button, setActionButton] = useState(keyForViewUpdate === 'Add' ? 'Save' : keyForViewUpdate === 'approve' ? 'Approve' : keyForViewUpdate === '' ? 'Cancel' : 'Save');
    const [action_Label, setActionLabel] = useState(keyForViewUpdate === 'Add' ? '(Creation)' : keyForViewUpdate === 'approve' ? '(Approval)' : keyForViewUpdate === 'copy' ? '(Copy)' : keyForViewUpdate === 'cancel' ? '(Cancel)' : '(View)');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Loader
    const [isLoading, setIsLoading] = useState(false);
    const is_approve = keyForViewUpdate === 'approve'

    //Hooks for useRef
    const validateNumberDateInput = useRef();
    const generateAutoNoAPiCall = useRef();
    const comboDataAPiCall = useRef();
    const exlsExp = useRef();
    const validate = useRef();
    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //////Converting date to string format///////
    const date_toString = (input_Date) => {

        const parsed_Date = new Date(input_Date);

        const date_OfInputDate = String(parsed_Date.getDate()).padStart(2, '0');
        const month_OfInputDate = String(parsed_Date.getMonth() + 1).padStart(2, '0');
        const year_OfInputDate = parsed_Date.getFullYear();

        const formatted_Date = date_OfInputDate + month_OfInputDate + year_OfInputDate;
        return formatted_Date;
    }


    //useRef Hooks
    const userInputParameters = ['100% production/spdl./shift in gms.', 'Addition for waste (In Percent)', '100% production per shift in kgs.', 'Addition for waste (In Percent)', '100% production per machine per shift in kgs.', 'Add for sliver  waste (In Percent)', 'Add for waste  (In Percent)', '100% production Two delivery per shift in kgs.', 'Production rate (kgs./hrs)', 'Add for waste (In Percent)', 'Total Bale Required/Day', 'Total Bags / shift', 'Total Weight / month', 'Containers Required'];
    const [prod_spin_param_Arr_forFillingFields, setProdParamArrForFields] = useState(['Actual Count', 'No. of spindles/machine',
        'No. of ring frames to be allotted', '40 s conversion factor', 'Type of card', 'Utilisation %'])
    const [production_spin_plan_param_array, setProdSpinPlanParamArray] = useState([
        'No. of spindles/machine',
        'No. of ring frames to be allotted',
        'Spindle speed in RPM',
        'T.M.',
        'Expected efficiency %',
        'Hank simplex',
        'Spindle speed (RPM)',
        'T.M.',
        'Expected efficiency %',
        'M/c. to be allotted',
        'Hank drawing',
        'Delivery speed in mtrs. per min.',
        'Expected efficiency %',
        'Total Machine to be allotted',
        'Hank drawing',
        'Delivery speed in mtrs./min',
        'Expected efficiency %',
        'Total Machine allotted',
        'Type of card',
        'Hank card sliver',
        'Carding Speed',
        'Expected efficiency %',
        'Total cards allotted',
        'Winding speed in mtrs./min',
        'Expected efficiency %',
        'No. of m/cs.',
        'LAP weight in grams per meter',
        'Combing Speed (Nips/min)',
        'Feed per nip in mm',
        'Feeds per comber',
        'Comber Noils %',
        'Back Draft %',
        'Delivery speed in mpm',
        'Drum Avialble']);

    const machineWise_Parameters = ['No. of spindles/machine',
        'No. of ring frames to be allotted',
        'Spindle speed in RPM',
        'T.M.',
        'Expected efficiency %']


    //Hooks fro spin plan data
    const [cmb_planmonth, setSpinPlanMonth] = useState("");
    const [cmb_planyear, setSpinPlanYear] = useState();
    const [txt_spin_plancode, setSpinPlanCode] = useState();
    const [cmb_spin_plantname, setSpinPlantName] = useState("");
    const [cmb_customername, setSpinPlanCustomerName] = useState();
    const [txt_spin_plantremark, setSpinRemark] = useState();
    const [cmb_spin_plancountname, setSpinPlanCountName] = useState("");
    const [dt_spin_planstartdate, setSpinPlanStartDate] = useState(today);
    const [dt_spin_planenddate, setSpinPlanEndDate] = useState(today);
    const [cmb_spin_plantstatus, setSpinPlantStatus] = useState('Pending');
    const [cmb_spin_plancreatedby, setPlanCreatedBy] = useState("");
    const [cmb_spin_planapprovedby, setPlanApprovedBy] = useState("");
    const [txt_spin_plan_avg_count, setSpinPlanAvgCount] = useState('0');
    const [txt_spin_plantAppRemark, setSpinApproveRemark] = useState('');
    const [txt_spin_plantCancelRemark, setSpinCancelRemark] = useState('');
    const [spinn_plan_id, setSpinPlanId] = useState(spinPlanId);
    const [isPlanBase, setSpinPlanBase] = useState('Generic')

    //Combo Options
    const [spinplanyearOptions, setSpinPlanYearOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [prodcountdata, setProdCountData] = useState([]);
    const [prodtargetlevelsOptions, setProdTargetLevelOptions] = useState([]);
    const [spinplanCreatedByOptions, setSpinPlanCreatedByOptions] = useState([]);
    const [spinplanApprovedByOptions, setSpinPlanApprovedByOptions] = useState([]);

    ///Product Count Check boxes
    var all = "all";
    const [selectAllCheckBoxes, setSelectAllCheckBoxes] = useState('all');

    //Hook for table
    const [total_shifts, setTotalShifts] = useState();
    const [shifts_per_hour, setShiftsPerHour] = useState();
    const [cmb_spin_targetlevel, setTargetLevel] = useState();
    const [headerdata, setHeaderData] = useState([]);
    const [planparamdata, setPlanParamData] = useState([]);
    const [totalsUpdated, setTotalsUpdated] = useState(false);
    const [machineOptions, setMachineOptions] = useState([]);

    const [machineidsJson, setMachineIdJson] = useState({});




    useEffect(async () => {
        setIsLoading(true)
        await fillComboBox();
        if (spinPlanId !== 0) {
            await FnCheckUpdateResponce();
        }
        setIsLoading(false)
        $(document).on('mouseup', function (e) {
            let container1 = $("#prodcount-order-ul");
            let container2 = $('.machine_number');

            if (!container1.is(e.target) && container1.has(e.target).length === 0) {
                container1.hide();
            }
            if (!container2.is(e.target) && container2.has(e.target).length === 0) {
                container2.hide();
            }
        });
    }, [])

    //function for fill all combo box
    const fillComboBox = async () => {
        debugger
        try {
            comboDataAPiCall.current.fillComboBox('ProductionShifts').then((getShiftApiCall) => {
                setTotalShifts(getShiftApiCall.length);
                setShiftsPerHour(24 / parseInt(getShiftApiCall.length));
            })

            //Financial Year
            const financialyearAPICall = await comboDataAPiCall.current.fillMasterData("amv_financial_year", "", "");
            setSpinPlanYearOptions(financialyearAPICall);

            //Customer Combo Box Options
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'customer_name'];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOptions(getCustomersApiCall);

            ////Plant combo box options
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'plant_name'];
            globalQuery.table = "cmv_plant"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getPlantApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setPlantOptions(getPlantApiCall);

            //Spinn Count combobox options
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xmv_spinning_prod_count"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getProdCountApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            setProdCountData(getProdCountApiCall);

            ///Spinn count Target level Options
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xmv_spinning_prod_count_levels"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getProdTargetLevelApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            setProdTargetLevelOptions(getProdTargetLevelApiCall);


            ///Employee combobox options
            const getEmployeesApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "", "");
            setSpinPlanCreatedByOptions(getEmployeesApiCall);
            setSpinPlanApprovedByOptions(getEmployeesApiCall);

            //Setting Machines Options
            resetGlobalQuery();
            globalQuery.columns = ['machine_name', 'machine_id', 'machine_short_name', 'machine_working_capacity', 'field_id', 'field_name'];
            globalQuery.table = "cmv_machine"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            const getMachineApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setMachineOptions(getMachineApiCall);
        } catch (error) {
            navigate('/Error')
        }
    }

    
    const fillAutoNo = async () => {
        try {
            const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("xt_spinning_prod_spinn_plan_master", "spinn_plan_code",
                "", "SP", "4");

            let startDate = dt_spin_planstartdate === undefined ? today() : $('#dt_spin_planstartdate').val();
            let endDate = dt_spin_planenddate === undefined ? today() : $('#dt_spin_planenddate').val();
            let string_plantName_Date = ($('#cmb_spin_plantname').find(":selected").text() + '/' + date_toString(startDate) + '/' + date_toString(endDate) + '/');
            const index_Of_Slash = autoNoApiCall.indexOf('/');

            if (startDate !== endDate) {
                if (index_Of_Slash !== -1) {
                    const spin_plan_code = autoNoApiCall.substring(0, index_Of_Slash + 1) + string_plantName_Date + autoNoApiCall.substring(index_Of_Slash + 1);
                    setSpinPlanCode(spin_plan_code);
                } else {
                    setSpinPlanCode(0);
                }
                return autoNoApiCall;
            } else {
                $('#error_dt_spin_planenddate').text('Please change end date...').show();
            }


        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        }
    };

    const renderTableData = async (selectedValue) => {

        setIsLoading(true)
        try {
            validateFields();
            setSpinPlanCountName("");
            setPlanParamData([])

            if (plantOptions.find(plant => plant.field_id === parseInt(selectedValue))) {
                resetGlobalQuery();
                globalQuery.columns = ['production_sub_section_id', 'production_section_id', 'production_spinning_plan_parameter_id', 'production_spinning_plan_parameter_name', 'production_spinning_plan_parameter_sequance', 'production_sub_section_name'];
                globalQuery.table = "xmv_production_spinning_plan_parameter"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getPlanParamApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                setHeaderData(['Sr. No', 'Section', 'Particular', 'Input', 'Total']);
                setPlanParamData(getPlanParamApiCall);
            } else {
                setHeaderData([]);
                setPlanParamData([]);
            }
            setIsLoading(false)
        } catch (error) {
            navigate('/Error');
        }
    }


    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            const json = { 'SppinProdSpinPlanData': { 'commonIds': { 'spinn_plan_code': plantCode, 'company_id': COMPANY_ID, 'plant_id': plantId, } } }
            const formData = new FormData();
            formData.append(`SppiningProdAndPlantProdDetails`, JSON.stringify(json))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const apiCallForSpinPlanData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProdSpinnPlanMaster/FnShowMasterAndDetailsModelRecordForUpdate`, requestOptions);
            const response = await apiCallForSpinPlanData.json();
            if (response.success === 1) {
                const { spinningPlanDetails, productionCountNameList } = response
                const machineIds = response.machineIdJson;

                //Set spin plan master Data.................
                let spinn_plan_master_data = response.spinningPlanDetails[0];
                setSpinPlanId(spinn_plan_master_data.spinn_plan_id)
                setSpinPlanMonth(spinn_plan_master_data.spinn_plan_month);
                setSpinPlanYear(spinn_plan_master_data.spinn_plan_year);
                setSpinPlanCode(spinn_plan_master_data.spinn_plan_code);
                setSpinPlantName(spinn_plan_master_data.plant_id);
                setSpinPlanCustomerName(spinn_plan_master_data.customer_id);
                setSpinRemark(spinn_plan_master_data.spinn_plan_description);
                setSpinPlanStartDate(spinn_plan_master_data.spinn_plan_start_date);
                setSpinPlanEndDate(spinn_plan_master_data.spinn_plan_end_date);

                setSpinPlantStatus(keyForViewUpdate === 'approve' ? 'Approved' : keyForViewUpdate === 'cancel' ? 'Cancelled' : spinn_plan_master_data.spinn_plan_status);
                setPlanCreatedBy(spinn_plan_master_data.spinn_plan_created_by_id);
                setPlanApprovedBy(spinn_plan_master_data.spinn_plan_approved_by_id);
                setSpinPlanAvgCount(spinn_plan_master_data.spinn_plan_avg_count);

                let headerDataResp = [];
                headerDataResp.push('Sr. no', 'Section', 'Particular', 'Input', 'Total', ...response.productionCountNameList);
                setHeaderData(headerDataResp);
                setPlanParamData(response.spinningPlanDetails);
                setMachineIdJson(machineIds);

                ////Checking the machine Ids 
                Object.keys(machineIds).forEach((header_Data) => {
                    var machine_checkboxes = document.querySelectorAll('input[name^="machinenumberCheckBox' + header_Data + '"]');
                    machine_checkboxes.forEach(function (checkbox) {
                        if (machineIds[header_Data].includes(checkbox.getAttribute("machineId"))) {
                            checkbox.checked = true;
                        }
                    });
                });
            }

            switch (keyForViewUpdate) {
                case 'view':
                    $('#cmb_planmonth, #cmb_planyear, #dt_spin_planstartdate, #dt_spin_planenddate, #cmb_spin_plantname, #cmb_customername, cmb_spin_plantstatus, #txt_spin_plantremark, #cmb_spin_plancreatedby').prop('disabled', true);
                    $('#action_Button #print_Button').hide();
                    break
                case 'approve':
                case 'cancel':
                    $('#cmb_planmonth, #cmb_planyear, #dt_spin_planstartdate, #dt_spin_planenddate, #cmb_spin_plantname, #cmb_customername, #txt_spin_plantremark, #cmb_spin_plancreatedby').prop('disabled', true);
                    $('#print_Button').hide();
                    break;
                case 'copy':

                    break;
                default:
                    break;
            }

            if (['view', 'approve', 'cancel', 'copy'].includes(keyForViewUpdate)) {
                $('.product_count_values').prop('disabled', true);
                document.querySelectorAll('input[type="radio"][name="isProductFGActive"]').forEach(radioButton => radioButton.disabled = true);
                $('#spin_plancountname').hide();
                $('.spin_plan_param_button').hide();
            }
        } catch (error) {
            navigate('/Error');
        }
    }

    const FnGetMachineNumbers = (event, header, id) => {
        debugger
        var splitselectedCount = header.split('_');
        var selectedCount = splitselectedCount[0];
        let productCountKey = header.replace(/ /g, "_");
        let plan_param_data = planparamdata;
        let target_val = (splitselectedCount[1].charAt(0).toUpperCase() + splitselectedCount[1].slice(1) + ' ' + splitselectedCount[2].charAt(0).toUpperCase() + splitselectedCount[2].slice(1))
        let updatedMachineIdsJson = machineidsJson;
        let machineIDsArray = [];
        let machinesData = {};

        $('#' + id + ' input[type="checkbox"]:checked').each(function () {
            let machineId = $(this).attr('machineId');
            let machineOption = machineOptions.find(option => option.machine_id === parseInt(machineId));
            if (machineOption) machinesData[machineId] = machineOption;
            machineIDsArray.push(machineId);
        });

        let selectedProdCountData = prodcountdata.find((prodCount, index) => {
            return prodCount.production_count_name === selectedCount
        });
        let findTargetLevelMatchedData = prodtargetlevelsOptions.find((count) => {
            return count.production_count_name === selectedCount && count.production_count_target_level === target_val;
        })

        let no_Of_Spindles = Object.values(machinesData).reduce((total, machine) => total + machine.machine_working_capacity, 0);
        let no_Of_RF_tobe_Alloted = machineIDsArray.length;

        let getProdActCountVal = (key) => {
            const foundData = plan_param_data.find(data => {
                return data.production_spinning_plan_parameter_name === key && data.production_sub_section_id === 13;
            });
            if (foundData) {
                return foundData[`production_actual_count_${productCountKey}`];
            }
            return null;
        };

        let total_Simplex_ProdReqInKgs;
        setPlanParamData(prevData => {
            let updatedPlanparamdata = prevData.map(paramdata => {
                if (paramdata.production_sub_section_id === 13) {
                    switch (paramdata.production_spinning_plan_parameter_name) {
                        case 'Actual Count':
                            paramdata[`production_actual_count_${productCountKey}`] = selectedProdCountData.production_actual_count;
                            break;
                        case 'No. of ring frames to be allotted':
                            paramdata[`production_actual_count_${productCountKey}`] = no_Of_RF_tobe_Alloted;
                            break;
                        case 'No. of spindles/machine':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify(no_Of_Spindles / no_Of_RF_tobe_Alloted), 4);
                            break;
                        case 'No. of spindles to be allotted':
                            paramdata[`production_actual_count_${productCountKey}`] = no_Of_Spindles;
                            break;
                        case 'Utilisation %':
                            paramdata[`production_actual_count_${productCountKey}`] = findTargetLevelMatchedData.count_target_machine_utilization;
                            break;
                        case 'Actual spindles to work':
                            paramdata[`production_actual_count_${productCountKey}`] = getProdActCountVal('No. of spindles to be allotted') * getProdActCountVal('Utilisation %') / 100;
                            break;
                        case '40 s conversion factor':
                            paramdata[`production_actual_count_${productCountKey}`] = selectedProdCountData.count_40s_conversion_factor;
                            break;
                        case 'Spindle speed in RPM':
                            paramdata[`production_actual_count_${productCountKey}`] = findTargetLevelMatchedData.count_speed;
                            break;
                        case 'T.M.':
                            paramdata[`production_actual_count_${productCountKey}`] = findTargetLevelMatchedData.count_tm;
                            break;
                        case 'T.P.I.':
                            paramdata[`production_actual_count_${productCountKey}`] = findTargetLevelMatchedData.count_tpi;
                            break;
                        case '100% production/spdl./shift in gms.':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(paramdata['input']) * getProdActCountVal('Spindle speed in RPM') / getProdActCountVal('T.P.I.') / getProdActCountVal('Actual Count')).toFixed(1)), 4);
                            break;
                        case 'Expected efficiency %':
                            paramdata[`production_actual_count_${productCountKey}`] = findTargetLevelMatchedData.count_target_efficiency_percent;
                            break;
                        case 'Expected production/spdl.per shift in gms.':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((getProdActCountVal('100% production/spdl./shift in gms.') * getProdActCountVal('Expected efficiency %') / 100).toFixed(2)), 4);
                            break;
                        case '40 s gms / spl':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((getProdActCountVal('40 s conversion factor') * getProdActCountVal('Expected production/spdl.per shift in gms.')).toFixed(2)), 4);
                            break;
                        case 'Total production/shift in kgs.':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((getProdActCountVal('Actual spindles to work') * getProdActCountVal('Expected production/spdl.per shift in gms.') / 1000).toFixed(1)), 4);
                            break;
                        case 'Total production/Day in kgs.':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((getProdActCountVal('Total production/shift in kgs.') * total_shifts).toFixed(1)), 4);
                            break;
                        case 'Total production per month kgs.':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((getProdActCountVal('Total production/Day in kgs.') * 30).toFixed(1)), 4);
                            break;
                        case 'Addition for waste (In Percent)':
                            paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify((getProdActCountVal('Total production/Day in kgs.') * paramdata['input'] / 100).toFixed(2)), 4);
                            total_Simplex_ProdReqInKgs = parseFloat(getProdActCountVal('Total production/Day in kgs.')) + parseFloat(paramdata[`production_actual_count_${productCountKey}`]);
                            break;
                        default:
                            paramdata[`production_actual_count_${productCountKey}`] = 0;
                            break;
                    }
                } else if (paramdata.production_sub_section_id === 12 && paramdata.production_spinning_plan_parameter_name === 'Total simplex production required in kgs./day') {
                    paramdata[`production_actual_count_${productCountKey}`] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(total_Simplex_ProdReqInKgs)), 4);
                }
                return paramdata;
            });
            return updatedPlanparamdata;
        });

        updatedMachineIdsJson[header] = machineIDsArray;
        setMachineIdJson(updatedMachineIdsJson);
    };


    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();
            var currentInput = event.target;
            var currentTd = currentInput.parentElement;
            var currentRow = currentTd.parentElement;
            var currentIndex = Array.from(currentRow.children).indexOf(currentTd);

            function findNextEnabledInput(row) {
                var nextRow = row.nextElementSibling;
                while (nextRow) {
                    var nextInput = nextRow.querySelector('input[type="text"]:not([disabled])');
                    if (nextInput) {
                        nextInput.focus();
                        return;
                    }
                    nextRow = nextRow.nextElementSibling;
                }
            }

            if (currentIndex === currentRow.children.length - 1) {
                findNextEnabledInput(currentRow);
            } else {
                var nextTd = currentRow.children[currentIndex + 1];
                if (nextTd && nextTd.tagName === 'TD') {
                    var nextInput = nextTd.querySelector('input[type="text"]:not([disabled])');
                    if (nextInput) {
                        nextInput.focus();
                    } else {
                        findNextEnabledInput(currentRow);
                    }
                }
            }
        } else {
            var element = document.getElementById(event.target.id);
            if (element.value !== 0) {
                element.parentElement.dataset.tip = "Please enter a valid number....";
            }
        }
    };

    const addNewColumn = async () => {
        debugger
        try {
            setIsLoading(true)
            if (validateInputKeysOfPlanParamData() === true) {
                let target_Level = $('#cmb_spin_targetlevel option:selected').val();
                if (cmb_spin_plantname !== "" && cmb_spin_plantname !== undefined) {
                    $('#error_cmb_spin_plantname').hide();

                    let selectedCheckboxes = [];
                    $('.productCountCheckBox:checked').each(function () {
                        selectedCheckboxes.push($(this).val() + '_' + target_Level);
                    });
                    if (selectedCheckboxes.length === 0) {
                        $("#error_cmb_spin_plancountname").text('Select Production Counts...!').show();
                    }

                    const excludedHeaderData = headerdata.slice(5);

                    ///////////////////////Making total = 0 or updating the total value;////////////////////////////
                    let plan_param_data = [...planparamdata];
                    if (selectedCheckboxes.length === 0 && !totalsUpdated) {
                        setTotalsUpdated(true);
                        plan_param_data.forEach((paramdata, index) => {
                            if (paramdata.total !== 0) {
                                paramdata.total = 0;
                            }
                        });
                        setPlanParamData(plan_param_data);
                    } else {
                        setTotalsUpdated(false);
                    }

                    const newArray = []
                    excludedHeaderData.filter(value => {
                        if (!selectedCheckboxes.includes(value)) {
                            newArray.push(value);
                        }
                    });

                    if (selectedCheckboxes.includes('all' + '_' + target_Level)) {
                        $('.productCountCheckBox:checked').each(function () {
                            renderingTable($(this).val() + '_' + target_Level)
                        });
                    } else {
                        const resultArray = selectedCheckboxes.filter(element => (!newArray.includes(element)));
                        for (const resultArray of selectedCheckboxes) {
                            if (!headerdata.includes(resultArray)) {
                                renderingTable(resultArray);
                            }
                        }
                    }
                } else {
                    $('#error_cmb_spin_plantname').css({
                        'display': 'block',
                        'margin-top': '8px'
                    }).text('Select the plant...');

                }
            }
        } catch (error) {
            navigate('/Error');
        } finally {
            setIsLoading(false)
        }
    }

    const renderingTable = async (selectedProdCountValue) => {
        debugger
        try {
            setIsLoading(true)
            if (selectedProdCountValue !== 'Total') {
                var splitselectedCount = selectedProdCountValue.split('_');
                var selectedCount = splitselectedCount[0];
                let productCountKey = selectedProdCountValue.replace(/ /g, "_");
                // const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                let target_val = (splitselectedCount[1].charAt(0).toUpperCase() + splitselectedCount[1].slice(1) + ' ' + splitselectedCount[2].charAt(0).toUpperCase() + splitselectedCount[2].slice(1))
                if (selectedCount !== 'all') {
                    if (!headerdata.includes(selectedProdCountValue) && selectedProdCountValue !== 'all') {
                        let updatedPlanparamdata = [...planparamdata];
                        let machineIds = machineidsJson;

                        let selectedProdCountData = prodcountdata.find((prodCount, index) => {
                            return prodCount.production_count_name === selectedCount
                        });
                        let findTargetLevelMatchedData = prodtargetlevelsOptions.find((count) => {
                            return count.production_count_name === selectedCount && count.production_count_target_level === target_val;
                        })

                        ////Here we are setting the data to the required fields based on from count master table & target level table
                        updatedPlanparamdata.forEach(paramdata => {
                            const paramName = paramdata.production_spinning_plan_parameter_name;
                            const productionActualCountKey = `production_actual_count_${productCountKey}`;

                            if (prod_spin_param_Arr_forFillingFields.includes(paramName)) {
                                switch (paramName) {
                                    case 'Actual Count':
                                        paramdata[productionActualCountKey] = selectedProdCountData.production_actual_count;
                                        break;
                                    case '40 s conversion factor':
                                        paramdata[productionActualCountKey] = selectedProdCountData.count_40s_conversion_factor;
                                        break;
                                    case 'Utilisation %':
                                        paramdata[productionActualCountKey] = findTargetLevelMatchedData.count_target_machine_utilization;
                                        break;
                                    default:
                                        paramdata[productionActualCountKey] = 0;
                                        break;
                                }
                            } else {
                                paramdata[productionActualCountKey] = 0;
                            }

                        });

                        // headerdata.push(selectedProdCountValue)
                        setHeaderData(prevArray => [...prevArray, selectedProdCountValue]);
                        setPlanParamData(updatedPlanparamdata);
                    }
                }
            }

        } catch (error) {
            navigate('/Error');
        } finally {
            setIsLoading(false)
        }
    }

    ////Deleting row from the table
    const deleteFromTable = () => {
        try {
            setIsLoading(true)
            let target_Level = $('#cmb_spin_targetlevel option:selected').val();
            let selectedCheckboxes = [];
            $('.productCountCheckBox:checked').each(function () {
                selectedCheckboxes.push($(this).val() + '_' + target_Level);
            });

            for (const select_Box of selectedCheckboxes) {
                var selectedtotheader = select_Box.split('_');
                var header = selectedtotheader[0];
                let productCountKey = select_Box.replace(/ /g, "_");
                let header_data = headerdata;
                let planPrama_data = [...planparamdata];

                var headerIndex = header_data.indexOf(select_Box);
                if (headerIndex !== -1) {
                    header_data.splice(headerIndex, 1);
                }
                setHeaderData(header_data);

                planPrama_data.forEach((paramdata) => {
                    let keyToDelete = Object.keys(paramdata).find(key => key === `production_actual_count_${productCountKey}`);
                    if (keyToDelete) {
                        delete paramdata[keyToDelete];
                    }
                    calculateRowTotal(paramdata);
                    calculateRowAvg(planPrama_data[0]);
                });
                setPlanParamData(planPrama_data);
            }
        } catch (error) {
            navigate('/Error')
        }
        setIsLoading(false)
    };

    const renderSpinPlanTable = useMemo(() => {

        return <Table id="spinplantable1" style={{ display: "block", overflow: "auto", height: "478px" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    {headerdata.map((header, indexOfItem) => {
                        if (indexOfItem > 4) {
                            let headerData = header.split('_');
                            return (
                                <th key={indexOfItem} className="erp_table_th text-center " rowspan={2} id={`${header}`}>
                                    <div className='mb-2'>
                                        {headerData[0] + ' ' +
                                            headerData[1] + ' ' + headerData[2]} <br></br>
                                        <div className={isPlanBase === 'Machine-Wise' ? '' : 'd-none'}>
                                            Machine No:   <div className='col-auto mt-1'>
                                                <div className="form-select form-select-sm"
                                                    onClick={() => FnToggleMachineList(indexOfItem)}
                                                    data-optional="optional">
                                                    {'Select'}
                                                </div>
                                                <ul className="list-items machine_number" id={`machinenumber-order-ul_${indexOfItem}`} style={{ width: "135px" }}>

                                                    {machineOptions.length !== 0 ?
                                                        machineOptions.map((machine, index) =>
                                                            <li className="item machinenumber_order_no" key={index}>
                                                                <span className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        name={`machinenumberCheckBox${header}`}
                                                                        onChange={(e) => { FnGetMachineNumbers(e, header, `machinenumber-order-ul_${indexOfItem}`); }}
                                                                        value={machine.field_name}
                                                                        machineId={machine.machine_id}
                                                                        className="erp_radio_button machinenumberCheckBox"
                                                                        optional='optional'
                                                                    />
                                                                </span>
                                                                <span className="item-text">{machine.field_name}</span>
                                                            </li>
                                                        ) : null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            );
                        } else {
                            return (
                                <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                                    {header}
                                </th>
                            );
                        }
                    })}

                </tr>
            </thead>
            <tbody>

                {planparamdata?.map((planParameter, index) => (
                    <tr rowindex={index} className="sticky-column">
                        <td className='erp_table_td' id={`${planParameter.production_spinning_plan_parameter_sequance}_${index}`}>
                            {planParameter.production_spinning_plan_parameter_sequance}
                        </td>
                        <td className='erp_table_td' id={`${planParameter.production_sub_section_name}_${index}`}>
                            {planParameter.production_sub_section_name}
                        </td>
                        <td className='erp_table_td' id={`${planParameter.production_spinning_plan_parameter_name}_${index}`}>
                            {planParameter.production_spinning_plan_parameter_name}
                        </td>
                        <td className='erp_table_td fw-bolder' style={{ width: "75px" }}>
                            {userInputParameters.includes(planParameter.production_spinning_plan_parameter_name) && (
                                <input
                                    type="text"
                                    className="erp_input_field_table_txt mb-0 text-end product_count_use_input product_count_values"
                                    id={`${planParameter.userinput}_${index}`}
                                    value={planParameter.input}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    style={{ width: "85px", backgroundColor: '#AFE1AF' }}
                                    onChange={(e) => { updateParameterTable(planParameter, e); }}
                                    Headers={'input'}
                                />
                            )}
                        </td>
                        <td className='erp_table_td fw-bolder planParameter_total' style={{ width: "75px" }} id={`${planParameter.total}_${index}`}>
                            {planParameter.total}
                        </td>

                        {Object.keys(planParameter)?.map((key) => {

                            const excludedKeys = ['customer_id', 'plant_id', 'plant_name', 'spinn_plan_approved_by_id', 'spinn_plan_avg_count', 'spinn_plan_code', 'spinn_plan_created_by_id', 'spinn_plan_description', 'spinn_plan_end_date', 'spinn_plan_id', 'spinn_plan_month', 'spinn_plan_start_date', 'spinn_plan_status', 'spinn_plan_transaction_id', 'spinn_plan_year', 'production_sub_section_id', 'production_count_id', 'production_section_id', 'production_section_name', 'production_spinning_plan_parameter_id', 'production_spinning_plan_parameter_name', 'production_spinning_plan_parameter_value', 'production_spinning_plan_parameter_sequance', 'production_sub_section_name', 'sub_section_id', 'section_id', 'total', 'input'];
                            if (!excludedKeys.includes(key)) {
                                return (
                                    <td key={key} className='erp_table_td'>
                                        {
                                            <input type="text"
                                                id={`product_count_${key}_${index}`}
                                                className="erp_input_field_table_txt mb-0 text-end product_count_values"
                                                value={planParameter[key]}
                                                onKeyDown={(e) => handleKeyDown(e, index)}

                                                {...(isPlanBase === 'Machine-Wise' && machineWise_Parameters.includes(planParameter.production_spinning_plan_parameter_name) && planParameter.production_sub_section_id === 13 ?
                                                    { style: { width: "120px" }, disabled: true }
                                                    : {
                                                        style: {
                                                            width: "120px",
                                                            ...(production_spin_plan_param_array.includes(planParameter.production_spinning_plan_parameter_name) && {
                                                                backgroundColor: '#AFE1AF',
                                                            }),
                                                        },
                                                        ...(production_spin_plan_param_array.includes(planParameter.production_spinning_plan_parameter_name) ? { width: '120px' } : { disabled: true })
                                                    })
                                                }

                                                onChange={(e) => { updateParameterTable(planParameter, e); }}
                                                Headers={key}
                                            />
                                        }
                                    </td>
                                );
                            }
                        })}

                    </tr>
                ))}
            </tbody>

        </Table>

    }, [cmb_spin_plantname, headerdata, planparamdata, isPlanBase]);


    const updateParameterTable = async (currentPlanParam, event) => {
        const updatedPlanParameterData = [...planparamdata]
        let noOfShifts = total_shifts;
        ////Calculating average for spin plan average count
        calculateRowAvg(updatedPlanParameterData[0]);
        setSpinPlanAvgCount(updatedPlanParameterData[0].total)

        let eventId = document.getElementById(event.target.id);

        // var headerRow = event.target.closest('table').querySelector('thead tr');
        // var headers = headerRow.querySelectorAll('th');
        // var index = event.target.parentNode.cellIndex;
        // var headerId = headers[index].id;
        // var ulId = headers[index].querySelector('ul').id;
        // var machineID = $('#' + ulId + ' input[type="checkbox"]:checked').attr('machineId');

        const currentObjIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))

        let enteredValue = event.target.value;
        if (enteredValue != 0 || enteredValue != "" || enteredValue != NaN) {
            delete eventId.parentElement.dataset.tip;
        }
        let validate_Value = validateNumberDateInput.current.decimalNumber(enteredValue, 4);

        let clickedColName = event.target.getAttribute('Headers');
        currentPlanParam[clickedColName] = validate_Value;

        calculateRowTotal(currentPlanParam);
        let dataOfOnChangeField = (keyForData) => {

            let reqData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === keyForData);
            let keysForReqData = Object.keys(reqData).filter(key => key.startsWith('production_actual_count_'));
            return { reqData, keysForReqData }
        }

        ////////////////////////////---------Only Calculations For Ring Frame---------//////////////////////////////////

        if (currentPlanParam.production_sub_section_id === 13) {
            if (currentPlanParam.production_spinning_plan_parameter_name === 'No. of ring frames to be allotted' || currentPlanParam.production_spinning_plan_parameter_name === 'No. of spindles/machine') {
                let Obj_ringFramesAlloted = dataOfOnChangeField('No. of ring frames to be allotted');
                let ringFramesAlloted_Data = Obj_ringFramesAlloted.reqData;
                let ringFramesAlloted_Key = Obj_ringFramesAlloted.keysForReqData;

                let Obj_noOfSpindles = dataOfOnChangeField('No. of spindles/machine');
                let noOfSpindles_Data = Obj_noOfSpindles.reqData;
                let noOfSpindles_Key = Obj_noOfSpindles.keysForReqData;

                ///Calculation for No. of spindles to be allotted  (Ring Frame)
                const resp_spindlesToBeAlloted = {};
                ringFramesAlloted_Key.forEach((key, index) => {
                    let numericValue1 = ringFramesAlloted_Data[key]
                    let numericValue2 = noOfSpindles_Data[key]
                    resp_spindlesToBeAlloted[key] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * numericValue2), 4)
                });

                let Obj_spindlesToBeAlloted = dataOfOnChangeField('No. of spindles to be allotted');
                let spindlesToBeAlloted_Data = Obj_spindlesToBeAlloted.reqData;
                let spindlesToBeAlloted_Key = Obj_spindlesToBeAlloted.keysForReqData;

                spindlesToBeAlloted_Key.forEach(objectKey => {
                    spindlesToBeAlloted_Data[objectKey] = resp_spindlesToBeAlloted[objectKey];
                });
                calculateRowTotal(spindlesToBeAlloted_Data);

                let noOfSpindlesToBeAlloted_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'No. of spindles to be allotted');
                let noOfSpindlesToBeAlloted_Key = Object.keys(noOfSpindlesToBeAlloted_Data).filter(key => key.startsWith('production_actual_count_'));

                let utilisation_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Utilisation %');
                let utilisation_Key = Object.keys(utilisation_Data).filter(key => key.startsWith('production_actual_count_'));

                calculateRowTotal(utilisation_Data);

                ///Calculating Actual spindles to work (Ring Frame)
                const resp_actualSpindlesToWork = {};
                noOfSpindlesToBeAlloted_Key.forEach((key, index) => {
                    let numericValue1 = noOfSpindlesToBeAlloted_Data[key]
                    let numericValue2 = utilisation_Data[key]
                    resp_actualSpindlesToWork[key] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(numericValue1 * numericValue2 / 100)), 4);
                });

                let actualSpindlesToWork_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual spindles to work');
                let actualSpindlesToWork_Key = Object.keys(actualSpindlesToWork_Data).filter(key => key.startsWith('production_actual_count_'));

                actualSpindlesToWork_Key.forEach(objectKey => {
                    actualSpindlesToWork_Data[objectKey] = resp_actualSpindlesToWork[objectKey];
                });
                calculateRowTotal(actualSpindlesToWork_Data);
            }


            if (currentPlanParam.production_spinning_plan_parameter_name === 'Spindle speed in RPM' || currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %' || currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %' || currentPlanParam.production_spinning_plan_parameter_name === 'T.M.') {
                debugger
                let spindleSpeedRPM_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Spindle speed in RPM');
                let spindleSpeedRPM_Key = Object.keys(spindleSpeedRPM_Data).filter(key => key.startsWith('production_actual_count_'));

                let tm_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'T.M.');
                let tm_Key = Object.keys(tm_Data).filter(key => key.startsWith('production_actual_count_'));

                let actualCountRF_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual Count');
                let actualCountRF_Key = Object.keys(actualCountRF_Data).filter(key => key.startsWith('production_actual_count_'));

                //Calculating TPI (Ring Frame)
                const resp_tpi_SpindleSpeed = {};
                tm_Key.forEach((value, index) => {
                    let numericValue1 = tm_Data[value]
                    let numericValue2 = actualCountRF_Data[value]
                    resp_tpi_SpindleSpeed[value] = Math.round(((parseFloat(numericValue1) * Math.sqrt(parseFloat(numericValue2))).toFixed(2)) * 2) / 2;
                });

                let tpi_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'T.P.I.');
                let tpi_Key = Object.keys(tpi_Data).filter(key => key.startsWith('production_actual_count_'));

                tpi_Key.forEach(objectKey => {
                    tpi_Data[objectKey] = resp_tpi_SpindleSpeed[objectKey];
                });
                calculateRowTotal(tpi_Data);

                //Calculating 100% production/spdl./shift in gms. (Ring Frame)

                let hundredPerProd_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === '100% production/spdl./shift in gms.');
                let hundredPerProd_Key = Object.keys(hundredPerProd_Data).filter(key => key.startsWith('production_actual_count_'));

                const resp_hundredPerProd = {};
                spindleSpeedRPM_Key.forEach((value, index) => {
                    let numericValue1 = spindleSpeedRPM_Data[value]
                    let numericValue2 = tpi_Data[value]
                    let numericValue3 = actualCountRF_Data[value]
                    resp_hundredPerProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(hundredPerProd_Data['input']) * parseFloat(numericValue1) / parseFloat(numericValue2) / parseFloat(numericValue3))), 4);
                });

                hundredPerProd_Key.forEach(objectKey => {
                    hundredPerProd_Data[objectKey] = resp_hundredPerProd[objectKey];
                });
                calculateRowTotal(hundredPerProd_Data);

                //Calculating Expected production/spdl.per shift in gms.
                let Obj_expectedEff_RF = dataOfOnChangeField('Expected efficiency %');
                let expectedEff_RF_Data = Obj_expectedEff_RF.reqData;
                let expectedEff_RF_Key = Obj_expectedEff_RF.keysForReqData;

                const resp_expectedEff_RF = {};
                hundredPerProd_Key.forEach((value, index) => {
                    let numericValue1 = hundredPerProd_Data[value]
                    let numericValue2 = expectedEff_RF_Data[value]
                    resp_expectedEff_RF[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * parseFloat(numericValue2) / 100).toFixed(2)), 4);
                });

                let Obj_expectedProd_Rf = dataOfOnChangeField('Expected production/spdl.per shift in gms.');
                let expectedProd_Rf_Data = Obj_expectedProd_Rf.reqData;
                let expectedProd_Rf_Key = Obj_expectedProd_Rf.keysForReqData;

                expectedProd_Rf_Key.forEach(objectKey => {
                    expectedProd_Rf_Data[objectKey] = resp_expectedEff_RF[objectKey];
                });
                calculateRowTotal(expectedProd_Rf_Data);

                //Calculating 40 s gms / spl

                let Obj_conversionfactor = dataOfOnChangeField('40 s conversion factor');
                let conversionfactor_Data = Obj_conversionfactor.reqData;
                let KeysOfConvFac = Obj_conversionfactor.keysForReqData;
                calculateRowTotal(conversionfactor_Data);

                const resp_conversionfactor = {};
                expectedProd_Rf_Key.forEach((value, index) => {
                    let numericValue1 = expectedProd_Rf_Data[value]
                    let numericValue2 = conversionfactor_Data[value]
                    resp_conversionfactor[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * parseFloat(numericValue2)).toFixed(2)), 4);
                });

                let resp_fortyGMS = dataOfOnChangeField('40 s gms / spl');
                let fortyGMS_Data = resp_fortyGMS.reqData;
                let fortyGMS_Key = resp_fortyGMS.keysForReqData;

                fortyGMS_Key.forEach(objectKey => {
                    fortyGMS_Data[objectKey] = resp_conversionfactor[objectKey];
                });
                calculateRowTotal(fortyGMS_Data);

                let Obj_actualspindles = dataOfOnChangeField('Actual spindles to work');
                let actualSpindles_Data = Obj_actualspindles.reqData;
                let actualSpindles_Key = Obj_actualspindles.keysForReqData;

                //Total production/shift in kgs.
                const resultObject = {};
                actualSpindles_Key.forEach((value, index) => {
                    let numericValue1 = actualSpindles_Data[value]
                    let numericValue2 = expectedProd_Rf_Data[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * parseFloat(numericValue2) / 1000).toFixed(1)), 4);
                });

                let respForTotalProduc = dataOfOnChangeField('Total production/shift in kgs.');
                let totProducData = respForTotalProduc.reqData;
                let KeysOfTotProduc = respForTotalProduc.keysForReqData;

                KeysOfTotProduc.forEach(objectKey => {
                    totProducData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(totProducData);

                //Calculating Total production/Day in kgs. for Ring Frame
                const resultantObject = {};
                KeysOfTotProduc.forEach((value, index) => {
                    let numericValue1 = totProducData[value]
                    resultantObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * (noOfShifts)).toFixed(1)), 4);
                });

                const updatedData = updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Total production/Day in kgs.' && updatedData.production_sub_section_id === 13
                );

                if (updatedData) {
                    let respForTotalProducDay = dataOfOnChangeField(updatedData.production_spinning_plan_parameter_name);
                    let totProducDayData = respForTotalProducDay.reqData;
                    let KeysOfTotProducday = respForTotalProducDay.keysForReqData;

                    KeysOfTotProducday.forEach(objectKey => {
                        totProducDayData[objectKey] = resultantObject[objectKey];
                    });
                    calculateRowTotal(totProducDayData);

                    //Calculating Addition for waste (In Percent)
                    const updatedDataForAddWaste = updatedPlanParameterData.find((Data) => {
                        return Data.production_spinning_plan_parameter_name === 'Addition for waste (In Percent)' && Data.production_sub_section_id === 13
                    });
                    if (updatedDataForAddWaste) {
                        let addWasteData = updatedDataForAddWaste
                        let KeysOfAddWaste = Object.keys(addWasteData).filter(key => key.startsWith('production_actual_count_'));

                        const resultForAddWaste = {};
                        KeysOfTotProduc.forEach((value, index) => {
                            let numericValue1 = totProducDayData[value]
                            resultForAddWaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * parseFloat(addWasteData['input']) / 100).toFixed(2)), 4);
                        });

                        KeysOfAddWaste.forEach(objectKey => {
                            addWasteData[objectKey] = resultForAddWaste[objectKey];
                        });
                        calculateRowTotal(addWasteData);
                    }

                    // Calculating Total production per month kgs.

                    const resultForTotProd = {};
                    KeysOfTotProducday.forEach((value, index) => {
                        let numericValue1 = totProducDayData[value]
                        resultForTotProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * 30), 4);
                    });

                    const updatedDataforTot = updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Total production per month kgs.' && updatedData.production_sub_section_id === 13
                    );

                    if (updatedDataforTot) {
                        let respForTotalProducMonth = dataOfOnChangeField(updatedDataforTot.production_spinning_plan_parameter_name);
                        let totProducMonthData = respForTotalProducMonth.reqData;
                        let KeysOfTotProducMonth = respForTotalProducMonth.keysForReqData;

                        KeysOfTotProducMonth.forEach(objectKey => {
                            totProducMonthData[objectKey] = resultForTotProd[objectKey];
                        });
                        calculateRowTotal(totProducMonthData);
                    }
                }
            }
        }

        ////////////////////////////////////-----------------Calculations for Speed Frame Starts Here----------------/////////////////////////////
        if (currentPlanParam.production_sub_section_id === 13 || currentPlanParam.production_sub_section_id === 12) {
            if ((currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %') || (currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %')) {

                /////Calculating Total Simplex Production 
                let respForTotProdday = dataOfOnChangeField('Total production/Day in kgs.');
                let totProdPerDayData = respForTotProdday.reqData;
                let KeysOfProdPerDay = respForTotProdday.keysForReqData;

                let respForAddForWaste = dataOfOnChangeField('Addition for waste (In Percent)');
                let addFor3Data = respForAddForWaste.reqData;
                let KeysOfAddFor3waste = respForAddForWaste.keysForReqData;

                const resultObject = {};
                KeysOfProdPerDay.forEach((value, index) => {
                    let numericValue1 = totProdPerDayData[value]
                    let numericValue2 = addFor3Data[value]
                    let resultForTotSimplexProd = parseFloat(numericValue1) + parseFloat(numericValue2);
                    resultObject[value] = validateNumberDateInput.current.decimalNumber((JSON.stringify(Math.round(resultForTotSimplexProd))), 4);
                });

                const updatedDataforTotSimplex = updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Total simplex production required in kgs./day' && updatedData.production_sub_section_id === 12
                );

                if (updatedDataforTotSimplex) {
                    let respForTotSimplex = dataOfOnChangeField(updatedDataforTotSimplex.production_spinning_plan_parameter_name);
                    let totSimplexData = respForTotSimplex.reqData;
                    let KeysOfTotSimplex = respForTotSimplex.keysForReqData;

                    KeysOfTotSimplex.forEach(objectKey => {
                        totSimplexData[objectKey] = resultObject[objectKey];
                    });
                    calculateRowTotal(totSimplexData);

                    //Calculating Addition for waste (In Percent)
                    const updatedDataForAddWaste = updatedPlanParameterData.find((data) => {
                        return data.production_spinning_plan_parameter_name === 'Addition for waste (In Percent)' && data.production_sub_section_id === 12
                    });
                    if (updatedDataForAddWaste) {
                        let addWasteData = updatedDataForAddWaste
                        let keysOfaddWaste = Object.keys(addWasteData).filter(key => key.startsWith('production_actual_count_'));

                        const resultForAddForWaste = {};
                        KeysOfTotSimplex.forEach((value, index) => {
                            let numericValue1 = totSimplexData[value]
                            resultForAddForWaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(numericValue1 * addWasteData['input'] / 100)), 4);
                        });

                        keysOfaddWaste.forEach(objectKey => {
                            addWasteData[objectKey] = resultForAddForWaste[objectKey];
                        });
                        calculateRowTotal(addWasteData);
                    }
                }
            }


            if (currentPlanParam.production_sub_section_id === 12) {
                if (currentPlanParam.production_spinning_plan_parameter_name === 'Hank simplex' || currentPlanParam.production_spinning_plan_parameter_name === 'Spindle speed (RPM)' ||
                    currentPlanParam.production_spinning_plan_parameter_name === 'T.M.' || currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %'
                ) {
                    debugger
                    let respForHankSimplex = dataOfOnChangeField('Hank simplex');
                    let hankSimplexData = respForHankSimplex.reqData;
                    let KeysOfHankSimplex = respForHankSimplex.keysForReqData;

                    let respForSpindleSpeed = dataOfOnChangeField('Spindle speed (RPM)');
                    let spindleSpeedData = respForSpindleSpeed.reqData;
                    let KeysOfSpindleSpeed = respForSpindleSpeed.keysForReqData;

                    const updatedDataforTM = updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'T.M.' && updatedData.production_sub_section_id === 12
                    );

                    if (updatedDataforTM) {
                        let tmData = updatedDataforTM;
                        let KeysOftm = Object.keys(tmData).filter(key => key.startsWith('production_actual_count_'));

                        //Calculating TPI (Spindle Speed)
                        const resp_tpi_SpindleSpeed = {};
                        KeysOftm.forEach((value, index) => {
                            let numericValue1 = tmData[value]
                            let numericValue2 = hankSimplexData[value]
                            resp_tpi_SpindleSpeed[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * Math.sqrt(parseFloat(numericValue2))).toFixed(2)), 4);
                        });


                        const updatedDataforTPI = updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'T.P.I.' && updatedData.production_sub_section_id === 12
                        );

                        if (updatedDataforTPI) {
                            let tpiData = updatedDataforTPI;
                            let KeysOfTPI = Object.keys(tpiData).filter(key => key.startsWith('production_actual_count_'));

                            KeysOfTPI.forEach(objectKey => {
                                tpiData[objectKey] = resp_tpi_SpindleSpeed[objectKey];
                            });
                            calculateRowTotal(tpiData);

                            //////////////Calculating 100% production per shift in kgs.///////////////

                            let respForHundProd = dataOfOnChangeField('100% production per shift in kgs.');
                            let hundProdData = respForHundProd.reqData;
                            let KeysOfHundProd = respForHundProd.keysForReqData;

                            let hundredPerProd_Data = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === '100% production/spdl./shift in gms.');
                            let hundredPerProd_Key = Object.keys(hundredPerProd_Data).filter(key => key.startsWith('production_actual_count_'));

                            const resultObject = {};
                            KeysOfHankSimplex.forEach((value, index) => {
                                let numericValue1 = hankSimplexData[value]
                                let numericValue2 = spindleSpeedData[value]
                                let numericValue3 = tpiData[value]
                                resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round((hundredPerProd_Data['input'] * numericValue2 / numericValue3 / numericValue1 / 1000) * hundProdData['input'])), 4);
                            });

                            KeysOfHundProd.forEach(objectKey => {
                                hundProdData[objectKey] = resultObject[objectKey];
                            });
                            calculateRowTotal(hundProdData);

                            // Calculating Expected production/shift in kg
                            const updatedDataForExpProd = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 12
                            );

                            if (updatedDataForExpProd) {
                                let expEffData = updatedDataForExpProd;
                                let KeysOfexpEff = Object.keys(expEffData).filter(key => key.startsWith('production_actual_count_'));

                                const resultant = {};
                                KeysOfHundProd.forEach((value, index) => {
                                    let numericValue1 = hundProdData[value]
                                    let numericValue2 = expEffData[value]
                                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) * parseFloat(numericValue2) / 100)), 4);
                                });

                                const updatedData = updatedPlanParameterData.find((updatedData) =>
                                    updatedData.production_spinning_plan_parameter_name === 'Expected production/shift in kg' && updatedData.production_sub_section_id === 12
                                );

                                if (updatedData) {
                                    let respForExpProd = dataOfOnChangeField(updatedData.production_spinning_plan_parameter_name);
                                    let expProdData = respForExpProd.reqData;
                                    let KeysOfExpProd = respForExpProd.keysForReqData;

                                    KeysOfExpProd.forEach(objectKey => {
                                        expProdData[objectKey] = resultant[objectKey];
                                    });
                                    calculateRowTotal(expProdData);

                                    // Calculating Total production/mc/Day in kgs.
                                    const resultTotProd = {};
                                    KeysOfExpProd.forEach((value, index) => {
                                        let numericValue1 = expProdData[value]
                                        resultTotProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * total_shifts), 4);
                                    });

                                    const updatedDataTotProdMC = await updatedPlanParameterData.find((updatedData) =>
                                        updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 12
                                    );

                                    if (updatedDataTotProdMC) {
                                        let totProdMCData = updatedDataTotProdMC;
                                        let KeysOftotProdMC = Object.keys(totProdMCData).filter(key => key.startsWith('production_actual_count_'));

                                        KeysOftotProdMC.forEach(objectKey => {
                                            totProdMCData[objectKey] = resultTotProd[objectKey];
                                        });
                                        calculateRowTotal(totProdMCData);

                                        //Calculating Total sf required
                                        let respForTotSimplex = dataOfOnChangeField('Total simplex production required in kgs./day');
                                        let totSimplexData = respForTotSimplex.reqData;
                                        let KeysOfSimpData = respForTotSimplex.keysForReqData;

                                        const resultant = {};
                                        KeysOfSimpData.forEach((value, index) => {
                                            let numericValue1 = totSimplexData[value]
                                            let numericValue2 = totProdMCData[value]
                                            resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((numericValue1 / numericValue2).toFixed(1)), 4);
                                        });


                                        let respForSfReq = dataOfOnChangeField('Total sf required');
                                        let sfReqData = respForSfReq.reqData;
                                        let keysOfSFreq = respForSfReq.keysForReqData;

                                        keysOfSFreq.forEach(objectKey => {
                                            sfReqData[objectKey] = resultant[objectKey];
                                        });
                                        calculateRowTotal(sfReqData);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        ////////Calculating Finisher Draw Frames//////////////////
        if (currentPlanParam.production_sub_section_id === 11 || 12) {

            if ((currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %') || (currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %') ||
                (currentPlanParam.production_spinning_plan_parameter_name === 'Hank drawing') || (currentPlanParam.production_spinning_plan_parameter_name === 'Delivery speed in mtrs. per min.')
            ) {
                if (currentPlanParam.production_sub_section_id === 12) {

                    ///calculating Total finisher drawing production required/day in kgs.
                    let respForTotSimplex = dataOfOnChangeField("Total simplex production required in kgs./day");
                    let totSimplexData = respForTotSimplex.reqData;
                    let KeysOfTotSimplex = respForTotSimplex.keysForReqData;


                    const updatedDataForAddWaste = updatedPlanParameterData.find((data) => {
                        return data.production_spinning_plan_parameter_name === 'Addition for waste (In Percent)' && data.production_sub_section_id === 12
                    });
                    if (updatedDataForAddWaste) {
                        let addWasteData = updatedDataForAddWaste
                        let keysOfaddWaste = Object.keys(addWasteData).filter(key => key.startsWith('production_actual_count_'));

                        const resultant = {};
                        KeysOfTotSimplex.forEach((value, index) => {
                            let numericValue1 = totSimplexData[value]
                            let numericValue2 = addWasteData[value]
                            resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) + parseFloat(numericValue2))), 4);
                        });

                        let respForTotFin = dataOfOnChangeField('Total finisher drawing production required/day in kgs.');
                        let totFinDrawData = respForTotFin.reqData;
                        let keysOftotFinDraw = respForTotFin.keysForReqData;

                        keysOftotFinDraw.forEach(objectKey => {
                            totFinDrawData[objectKey] = resultant[objectKey];
                        });
                        calculateRowTotal(totFinDrawData);


                        //Calculating Add for sliver waste (In Percent)
                        let respForSilverWaste = dataOfOnChangeField('Add for sliver  waste (In Percent)');
                        let silverWasteData = respForSilverWaste.reqData;
                        let keysOfSilverWaste = respForSilverWaste.keysForReqData;

                        const resultSilverWaste = {};
                        keysOftotFinDraw.forEach((value, index) => {
                            let numericValue1 = totFinDrawData[value]
                            resultSilverWaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(numericValue1 * silverWasteData['input'] / 100)), 4);
                        });

                        keysOfSilverWaste.forEach(objectKey => {
                            silverWasteData[objectKey] = resultSilverWaste[objectKey];
                        });
                        calculateRowTotal(silverWasteData);

                        ////Calculating Total comber production required/day in kgs.
                        const total_Comber_Resultant = {};
                        keysOfSilverWaste.forEach((value, index) => {
                            let numericValue1 = totFinDrawData[value];
                            let numericValue2 = silverWasteData[value];
                            total_Comber_Resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) + parseFloat(numericValue2)).toFixed()), 4);
                        });

                        let respForTotalComberProd = dataOfOnChangeField('Total comber production required/day in kgs.');
                        let totalComberProdData = respForTotalComberProd.reqData;
                        let keysOfTotalComberProd = respForTotalComberProd.keysForReqData;

                        keysOfTotalComberProd.forEach(objectKey => {
                            totalComberProdData[objectKey] = total_Comber_Resultant[objectKey];
                        });
                        calculateRowTotal(totalComberProdData);
                    }
                }

                if (currentPlanParam.production_sub_section_id === 11) {
                    //Calculating 100% production per machine per shift in kgs.

                    const updatedDataHankDrawing = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Hank drawing' && updatedData.production_sub_section_id === 11
                    );
                    if (updatedDataHankDrawing) {
                        let hankDrawingData = updatedDataHankDrawing;
                        let KeysOfHankDrawing = Object.keys(hankDrawingData).filter(key => key.startsWith('production_actual_count_'));

                        let respForDelSpeed = dataOfOnChangeField('Delivery speed in mtrs. per min.');
                        let delSpeedData = respForDelSpeed.reqData;
                        let keysOfDelSpeed = respForDelSpeed.keysForReqData;

                        let respForProdPerMach = dataOfOnChangeField('100% production per machine per shift in kgs.');
                        let prodPermachData = respForProdPerMach.reqData;
                        let keysOfProdPerMach = respForProdPerMach.keysForReqData;

                        const resultant = {};
                        KeysOfHankDrawing.forEach((value, index) => {
                            let numericValue1 = hankDrawingData[value]
                            let numericValue2 = delSpeedData[value]
                            resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round((parseFloat(prodPermachData['input']) * numericValue2 / numericValue1).toFixed(2))), 4);
                        });

                        keysOfProdPerMach.forEach(objectKey => {
                            prodPermachData[objectKey] = resultant[objectKey];
                        });
                        calculateRowTotal(prodPermachData);


                        //calculating Expected production per machine per shift in kgs.
                        const updatedDataExpecEff = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 11
                        );

                        if (updatedDataExpecEff) {
                            let expecEffData = updatedDataExpecEff;
                            let keysForExpecEff = Object.keys(expecEffData).filter(key => key.startsWith('production_actual_count_'));

                            const result = {};
                            KeysOfHankDrawing.forEach((value, index) => {
                                let numericValue1 = prodPermachData[value]
                                let numericValue2 = expecEffData[value]
                                result[value] = parseInt(numericValue1 * numericValue2 / 100);
                            });

                            let respForExpProdPerMach = dataOfOnChangeField('Expected production per machine per shift in kgs.');
                            let expprodPermachData = respForExpProdPerMach.reqData;
                            let keysOfexpProdPerMach = respForExpProdPerMach.keysForReqData;

                            keysOfexpProdPerMach.forEach(objectKey => {
                                expprodPermachData[objectKey] = result[objectKey];
                            });
                            calculateRowTotal(expprodPermachData);

                            //Calculating Total production/mc/Day in kgs.
                            const resultToTProdday = {};
                            KeysOfHankDrawing.forEach((value, index) => {
                                let numericValue1 = expprodPermachData[value]
                                resultToTProdday[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.floor(numericValue1 * noOfShifts)), 4);
                            });

                            const updatedDataTotProdDay = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 11
                            );
                            if (updatedDataTotProdDay) {
                                let totProddataData = updatedDataTotProdDay;
                                let keysFortotProdData = Object.keys(totProddataData).filter(key => key.startsWith('production_actual_count_'));

                                keysFortotProdData.forEach(objectKey => {
                                    totProddataData[objectKey] = resultToTProdday[objectKey];
                                });
                                calculateRowTotal(totProddataData);

                                //Calculating Total Machine required

                                let respForTotFin = dataOfOnChangeField('Total finisher drawing production required/day in kgs.');
                                let totFinDrawData = respForTotFin.reqData;
                                let keysOftotFinDraw = respForTotFin.keysForReqData;

                                const resultant = {};
                                keysOftotFinDraw.forEach((value, index) => {
                                    let numericValue1 = totFinDrawData[value]
                                    let numericValue2 = totProddataData[value]
                                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) / parseFloat(numericValue2)).toFixed(1)), 4);
                                });

                                const updatedDataTotMach = await updatedPlanParameterData.find((updatedData) =>
                                    updatedData.production_spinning_plan_parameter_name === 'Total Machine required' && updatedData.production_sub_section_id === 11
                                );
                                let totMachData = updatedDataTotMach;
                                let keysForTotmach = Object.keys(totMachData).filter(key => key.startsWith('production_actual_count_'));

                                keysForTotmach.forEach(objectKey => {
                                    totMachData[objectKey] = resultant[objectKey];
                                });
                                calculateRowTotal(totMachData);
                            }
                        }
                    }
                }
            }
        }

        //////////Calculations for COMBER starts here/////////////////
        if ([10].includes(currentPlanParam.production_sub_section_id)) {

            if (['LAP weight in grams per meter', 'Combing Speed (Nips/min)', 'Feed per nip in mm', 'Feeds per comber', 'Comber Noils %', 'Back Draft %', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name)) {

                ////Calculation for 100% production per mchine per shift
                let respForLAPWeight = dataOfOnChangeField('LAP weight in grams per meter');
                let lapWeightData = respForLAPWeight.reqData;
                let keysForLAPweight = respForLAPWeight.keysForReqData;

                let respForCombingSpeed = dataOfOnChangeField('Combing Speed (Nips/min)');
                let combingSpeedData = respForCombingSpeed.reqData;
                let keysForCombingSpeed = respForCombingSpeed.keysForReqData;

                let respForFeedForNIP = dataOfOnChangeField('Feed per nip in mm');
                let feedForNIPdata = respForFeedForNIP.reqData;
                let keysForFeedNIP = respForFeedForNIP.keysForReqData;

                let respForFeedsPerComber = dataOfOnChangeField('Feeds per comber');
                let feedsForComberdata = respForFeedsPerComber.reqData;
                let keysForFeedsPerComber = respForFeedsPerComber.keysForReqData;

                let respForComberNoils = dataOfOnChangeField('Comber Noils %');
                let comberNoilsdata = respForComberNoils.reqData;
                let keysForComberNoils = respForComberNoils.keysForReqData;

                let respForBackDraft = dataOfOnChangeField('Back Draft %');
                let backDraftdata = respForBackDraft.reqData;
                let keysforBackDraft = respForBackDraft.keysForReqData;

                const resultantForProdPerShift = {};
                keysforBackDraft.forEach((value, index) => {
                    let numericValue1 = lapWeightData[value];
                    let numericValue2 = combingSpeedData[value];
                    let numericValue3 = feedForNIPdata[value];
                    let numericValue4 = feedsForComberdata[value];
                    let numericValue5 = comberNoilsdata[value];
                    let numericValue6 = backDraftdata[value];
                    resultantForProdPerShift[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round((parseFloat(numericValue2) * parseFloat(numericValue3) * 60 * shifts_per_hour * parseFloat(numericValue4) * (1 - (parseFloat(numericValue6)) / 100) * (1 - parseFloat(numericValue5) / 100) * parseFloat(numericValue1)) / 1000 / 1000)), 4);
                });

                let respForProdPerMachShift = dataOfOnChangeField('100% production per mchine per shift');
                let prodPerShiftData = respForProdPerMachShift.reqData;
                let keysOfProdPerShift = respForProdPerMachShift.keysForReqData;

                keysOfProdPerShift.forEach(objectKey => {
                    prodPerShiftData[objectKey] = resultantForProdPerShift[objectKey];
                });
                calculateRowTotal(prodPerShiftData);


                /////////Calculating Expected production per machine per shift in kgs.

                const updatedDataForExpProd = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 10
                );

                if (updatedDataForExpProd) {
                    let comberEfficiencyData = updatedDataForExpProd;
                    let keysOfComberEfficiency = Object.keys(comberEfficiencyData).filter(key => key.startsWith('production_actual_count_'));

                    const resultantForComberEfficiency = {};
                    keysOfComberEfficiency.forEach((value, index) => {
                        let numericValue1 = prodPerShiftData[value]
                        let numericValue2 = comberEfficiencyData[value]
                        resultantForComberEfficiency[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) * parseFloat(numericValue2) / 100)), 4);
                    });

                    const updatedDataForExpProdComber = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Expected production per machine per shift in kgs.' && updatedData.production_sub_section_id === 10
                    );

                    if (updatedDataForExpProdComber) {
                        let expecProdPerMachData = updatedDataForExpProdComber;
                        let keysForExpecProdPerMachData = Object.keys(comberEfficiencyData).filter(key => key.startsWith('production_actual_count_'));

                        keysForExpecProdPerMachData.forEach(objectKey => {
                            expecProdPerMachData[objectKey] = resultantForComberEfficiency[objectKey];
                        });
                        calculateRowTotal(expecProdPerMachData);


                        ////Calculating Total production/mc/Day in kgs.
                        const resultantForProdInKgs = {};
                        keysForExpecProdPerMachData.forEach((value, index) => {
                            let numericValue1 = expecProdPerMachData[value]
                            resultantForProdInKgs[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * total_shifts), 4);
                        });

                        const updatedDataForProdInKgs = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 10
                        );

                        if (updatedDataForProdInKgs) {
                            let prodInKgsData = updatedDataForProdInKgs;
                            let keysOfComberProdInKgs = Object.keys(comberEfficiencyData).filter(key => key.startsWith('production_actual_count_'));

                            keysOfComberProdInKgs.forEach(objectKey => {
                                prodInKgsData[objectKey] = resultantForProdInKgs[objectKey];
                            });
                            calculateRowTotal(prodInKgsData);


                            ///////Calculating Total Machine required (Comber)
                            let respForTotalComberProd = dataOfOnChangeField('Total comber production required/day in kgs.');
                            let totalComberProdData = respForTotalComberProd.reqData;
                            let keysOfTotalComberProd = respForTotalComberProd.keysForReqData;

                            const resultantTotMachineReqComber = {};
                            keysOfTotalComberProd.forEach((value, index) => {
                                let numericValue1 = totalComberProdData[value]
                                let numericValue2 = prodInKgsData[value]
                                resultantTotMachineReqComber[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) / parseFloat(numericValue2)).toFixed(2)), 4);
                            });

                            const updatedDataForTotMachReq = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Total Machine required' && updatedData.production_sub_section_id === 10
                            );

                            if (updatedDataForTotMachReq) {
                                let totMachReqData = updatedDataForTotMachReq;
                                let keysOfTotMachReq = Object.keys(totMachReqData).filter(key => key.startsWith('production_actual_count_'));

                                keysOfTotMachReq.forEach(objectKey => {
                                    totMachReqData[objectKey] = resultantTotMachineReqComber[objectKey];
                                });
                                calculateRowTotal(totMachReqData);
                            }
                            debugger
                            ////Calculating Add for Noil waste
                            const resultantForNoilWaste = {};
                            keysOfTotalComberProd.forEach((value, index) => {
                                let numericValue1 = totalComberProdData[value]
                                let numericValue2 = comberNoilsdata[value]
                                resultantForNoilWaste[value] = parseInt(parseFloat(numericValue1) * (parseFloat(numericValue2) / 100));
                            });

                            let respForNoilWaste = dataOfOnChangeField('Add for Noil waste');
                            let noilWasteData = respForNoilWaste.reqData;
                            let keysOfNoilWaste = respForNoilWaste.keysForReqData;

                            keysOfNoilWaste.forEach(objectKey => {
                                noilWasteData[objectKey] = resultantForNoilWaste[objectKey];
                            });
                            calculateRowTotal(noilWasteData);


                            ////Calculating Total lap former production required/day in kgs. (Lap Former)
                            const resultantTotLapFormProd = {};
                            keysOfNoilWaste.forEach((value, index) => {
                                let numericValue1 = totalComberProdData[value]
                                let numericValue2 = noilWasteData[value]
                                resultantTotLapFormProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) + parseFloat(numericValue2))), 4);
                            });

                            let respForTotLapProd = dataOfOnChangeField('Total lap former production required/day in kgs.');
                            let totLapProdData = respForTotLapProd.reqData;
                            let keysOfTotLapProd = respForTotLapProd.keysForReqData;

                            keysOfTotLapProd.forEach(objectKey => {
                                totLapProdData[objectKey] = resultantTotLapFormProd[objectKey];
                            });
                            calculateRowTotal(totLapProdData);
                        }
                    }
                }
            }
        }

        ////////////////////////////Calculations for LAP Former Starts Here//////////////////////////////////
        if ([9].includes(currentPlanParam.production_sub_section_id)) {
            if (['LAP weight in grams per meter', 'Delivery speed in mpm', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name)) {

                const updatedLapWeight = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'LAP weight in grams per meter' && updatedData.production_sub_section_id === 9
                );

                if (updatedLapWeight) {
                    let lapWeightData = updatedLapWeight
                    let keysOfLAPWeight = Object.keys(lapWeightData).filter(key => key.startsWith('production_actual_count_'));

                    let respForDelSpeedInNPM = dataOfOnChangeField('Delivery speed in mpm');
                    let delSpeedNpmData = respForDelSpeedInNPM.reqData;
                    let keysOfDelSpeedNpm = respForDelSpeedInNPM.keysForReqData;

                    const resultProdPerMachLAP = {};
                    keysOfDelSpeedNpm.forEach((value, index) => {
                        let numericValue1 = lapWeightData[value]
                        let numericValue2 = delSpeedNpmData[value]
                        resultProdPerMachLAP[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) * parseFloat(numericValue2) / 1000 * 60 * shifts_per_hour)), 4);
                    });

                    //// Calculating 100% production per machine per shift (LAP Former)
                    const updatedDataProdPerMachLAP = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === '100% production per machine per shift' && updatedData.production_sub_section_id === 9
                    );

                    if (updatedDataProdPerMachLAP) {
                        let prodPerShiftLapData = updatedDataProdPerMachLAP;
                        let keysOfProdPerMachLAP = Object.keys(prodPerShiftLapData).filter(key => key.startsWith('production_actual_count_'));

                        keysOfProdPerMachLAP.forEach(objectKey => {
                            prodPerShiftLapData[objectKey] = resultProdPerMachLAP[objectKey];
                        });
                        calculateRowTotal(prodPerShiftLapData);

                        /////////Calculating Expected production per machine per shift in kgs. (LAP Former)
                        const updatedDataExpEffLap = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 9
                        );

                        if (updatedDataExpEffLap) {
                            let expEffLapData = updatedDataExpEffLap;
                            let keysOfExpEffLap = Object.keys(expEffLapData).filter(key => key.startsWith('production_actual_count_'));

                            const resultExpProdLap = {};
                            keysOfExpEffLap.forEach((value, index) => {
                                let numericValue1 = prodPerShiftLapData[value]
                                let numericValue2 = expEffLapData[value]
                                resultExpProdLap[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) * parseFloat(numericValue2) / 100)), 4);
                            });

                            const updatedExpProdMachLap = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Expected production per machine per shift in kgs.' && updatedData.production_sub_section_id === 9
                            );

                            if (updatedExpProdMachLap) {
                                let expProdMachLapData = updatedExpProdMachLap;
                                let keysOfExpProdMachLap = Object.keys(expProdMachLapData).filter(key => key.startsWith('production_actual_count_'));

                                keysOfExpProdMachLap.forEach(objectKey => {
                                    expProdMachLapData[objectKey] = resultExpProdLap[objectKey];
                                });
                                calculateRowTotal(expProdMachLapData);

                                ////Calculating Total production/mc/Day in kgs. (Lap Former)
                                const resultTotProdLap = {};
                                keysOfExpEffLap.forEach((value, index) => {
                                    let numericValue1 = expProdMachLapData[value]
                                    resultTotProdLap[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * total_shifts).toFixed(1)), 4);
                                });

                                const updatedTotProdLap = await updatedPlanParameterData.find((updatedData) =>
                                    updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 9
                                );

                                if (updatedTotProdLap) {
                                    let totProdLapData = updatedTotProdLap;
                                    let keysOftotProdLap = Object.keys(totProdLapData).filter(key => key.startsWith('production_actual_count_'));

                                    keysOftotProdLap.forEach(objectKey => {
                                        totProdLapData[objectKey] = resultTotProdLap[objectKey];
                                    });
                                    calculateRowTotal(totProdLapData);

                                    ///////Calculating total Machine Req (LAP Former)
                                    let respForTotLapProd = dataOfOnChangeField('Total lap former production required/day in kgs.');
                                    let totLapProdData = respForTotLapProd.reqData;
                                    let keysOfTotLapProd = respForTotLapProd.keysForReqData;


                                    const resultTotMachReq = {};
                                    keysOfExpEffLap.forEach((value, index) => {
                                        let numericValue1 = totLapProdData[value]
                                        let numericValue2 = totProdLapData[value]
                                        resultTotMachReq[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) / parseFloat(numericValue2)).toFixed(2)), 4);
                                    });

                                    const updatedTotMachReq = await updatedPlanParameterData.find((updatedData) =>
                                        updatedData.production_spinning_plan_parameter_name === 'Total Machine required' && updatedData.production_sub_section_id === 9
                                    );

                                    if (updatedTotMachReq) {
                                        let totMachReqData = updatedTotMachReq;
                                        let keysOftotMachReq = Object.keys(totMachReqData).filter(key => key.startsWith('production_actual_count_'));

                                        keysOftotMachReq.forEach(objectKey => {
                                            totMachReqData[objectKey] = resultTotMachReq[objectKey];
                                        });
                                        calculateRowTotal(totMachReqData);
                                    }

                                    //////Calculating Add for waste  (In Percent) (Lap Former)
                                    const updatedAddFprWasteLap = await updatedPlanParameterData.find((updatedData) =>
                                        updatedData.production_spinning_plan_parameter_name === 'Add for waste  (In Percent)' && updatedData.production_sub_section_id === 9
                                    );

                                    if (updatedAddFprWasteLap) {
                                        let addForWasteData = updatedAddFprWasteLap;
                                        let keysOfAddForWaste = Object.keys(addForWasteData).filter(key => key.startsWith('production_actual_count_'));

                                        const resultAddforwaste = {};
                                        keysOfExpEffLap.forEach((value, index) => {
                                            let numericValue1 = totLapProdData[value]
                                            resultAddforwaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * addForWasteData['input'] / 100).toFixed(2)), 4);
                                        });

                                        keysOfAddForWaste.forEach(objectKey => {
                                            addForWasteData[objectKey] = resultAddforwaste[objectKey];
                                        });
                                        calculateRowTotal(addForWasteData);
                                    }
                                }
                            }
                        }

                    }

                }
            }
        }


        //////////Calculations for Beaker Draw Frame//////////
        if ([13, 12, 9, 8].includes(currentPlanParam.production_sub_section_id)) {

            if (['Utilisation %', 'Expected efficiency %', 'Delivery speed in mtrs. per min.'].includes(currentPlanParam.production_spinning_plan_parameter_name) && [9].includes(currentPlanParam.production_sub_section_id)) {
                let respForTotFin = dataOfOnChangeField('Total lap former production required/day in kgs.');
                let totFinDrawData = respForTotFin.reqData;
                let keysOftotFinDraw = respForTotFin.keysForReqData;

                let respForSilverWaste = dataOfOnChangeField('Add for waste (In Percent)');
                let silverWasteData = respForSilverWaste.reqData;
                let keysOfSilverWaste = respForSilverWaste.keysForReqData;

                //Calculation for Total  Breaker production required/day in kgs.
                const result = {};
                keysOftotFinDraw.forEach((value, index) => {
                    let numericValue1 = totFinDrawData[value]
                    let numericValue2 = silverWasteData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) + parseFloat(numericValue2))), 4);
                });

                let respForTotBeakProd = dataOfOnChangeField('Total  Breaker production required/day in kgs.');
                let breakProdData = respForTotBeakProd.reqData;
                let keysOfBreakerProd = respForTotBeakProd.keysForReqData;

                keysOfBreakerProd.forEach(objectKey => {
                    breakProdData[objectKey] = result[objectKey];
                });
                calculateRowTotal(breakProdData);

                ///////Calculating Add for waste (In Percent) (Breaker Frame)
                let resp_addforwaste_BF = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Add for waste (In Percent)' && updatedData.production_sub_section_id === 8
                );

                if (resp_addforwaste_BF) {
                    let addforwaste_BF_Data = resp_addforwaste_BF;
                    let addforwaste_BF_Key = Object.keys(addforwaste_BF_Data).filter(key => key.startsWith('production_actual_count_'));

                    const Obj_addforwaste_BF = {};
                    keysOftotFinDraw.forEach((value, index) => {
                        let numericValue1 = breakProdData[value]
                        Obj_addforwaste_BF[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * addforwaste_BF_Data['input'] / 100).toFixed(2)), 4);
                    });

                    addforwaste_BF_Key.forEach(objectKey => {
                        addforwaste_BF_Data[objectKey] = Obj_addforwaste_BF[objectKey];
                    });
                    calculateRowTotal(addforwaste_BF_Data);
                }
            }

            if (['Hank drawing', 'Delivery speed in mtrs./min', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name)) {
                //Calculating 100% production Two delivery per shift in kgs.
                const updatedDataHankDraw = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Hank drawing' && updatedData.production_sub_section_id === 8
                );
                if (updatedDataHankDraw) {
                    let hankDrawData = updatedDataHankDraw
                    let keysOfHankDraw = Object.keys(hankDrawData).filter(key => key.startsWith('production_actual_count_'));

                    let respForDelSpeed = dataOfOnChangeField('Delivery speed in mtrs./min');
                    let delSpeedData = respForDelSpeed.reqData;
                    let keysOfDelSpeed = respForDelSpeed.keysForReqData;

                    let respForProdTwoDel = dataOfOnChangeField('100% production Two delivery per shift in kgs.');
                    let prodTwoDelData = respForProdTwoDel.reqData;
                    let keysProdTwoData = respForProdTwoDel.keysForReqData;

                    const result = {};
                    keysOfHankDraw.forEach((value, index) => {
                        let numericValue1 = hankDrawData[value]
                        let numericValue2 = delSpeedData[value]
                        result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(prodTwoDelData['input'] * numericValue2 / numericValue1)), 4);
                    });

                    keysProdTwoData.forEach(objectKey => {
                        prodTwoDelData[objectKey] = result[objectKey];
                    });
                    calculateRowTotal(prodTwoDelData);

                    //Calculating Expected production Two delivery per shift in kgs.
                    const updatedDataExpEff = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 8
                    );
                    if (updatedDataExpEff) {
                        let expEffData = updatedDataExpEff;
                        let keysOfExpEff = Object.keys(expEffData).filter(key => key.startsWith('production_actual_count_'));

                        const resultForExpecProdDel = {};
                        keysOfExpEff.forEach((value, index) => {
                            let numericValue1 = prodTwoDelData[value]
                            let numericValue2 = expEffData[value]
                            resultForExpecProdDel[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * numericValue2 / 100)), 4);
                        });

                        let respForExpecProdel = dataOfOnChangeField('Expected production Two delivery per shift in kgs.');
                        let expecProdDelData = respForExpecProdel.reqData;
                        let keysExpProdDel = respForExpecProdel.keysForReqData;

                        keysExpProdDel.forEach(objectKey => {
                            expecProdDelData[objectKey] = resultForExpecProdDel[objectKey];
                        });
                        calculateRowTotal(expecProdDelData);


                        //Calculating Total production/mc/Day in kgs.
                        const resultForTotProdDay = {};
                        keysOfExpEff.forEach((value, index) => {
                            let numericValue1 = expecProdDelData[value]
                            resultForTotProdDay[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(numericValue1 * total_shifts)), 4);
                        });

                        const updatedDataTotProdDay = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 8
                        );
                        if (updatedDataTotProdDay) {
                            let totProdDayData = updatedDataTotProdDay;
                            let keysOfTotProdDay = Object.keys(totProdDayData).filter(key => key.startsWith('production_actual_count_'));

                            keysOfTotProdDay.forEach(objectKey => {
                                totProdDayData[objectKey] = resultForTotProdDay[objectKey];
                            });
                            calculateRowTotal(totProdDayData);

                            //Calculating Total Machine required
                            let respForTotBeakProd = dataOfOnChangeField('Total  Breaker production required/day in kgs.');
                            let breakProdData = respForTotBeakProd.reqData;
                            let keysOfBreakerProd = respForTotBeakProd.keysForReqData;

                            const resultForTotMachReq = {};
                            keysOfExpEff.forEach((value, index) => {
                                let numericValue1 = breakProdData[value]
                                let numericValue2 = totProdDayData[value]
                                resultForTotMachReq[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+parseFloat(numericValue1) / parseFloat(numericValue2)).toFixed(2)), 4);
                            });

                            const updatedDataTotMachReq = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Total Machine required' && updatedData.production_sub_section_id === 8
                            );
                            if (updatedDataTotMachReq) {
                                let totMachReqData = updatedDataTotMachReq;
                                let keysOftotMachReq = Object.keys(totMachReqData).filter(key => key.startsWith('production_actual_count_'));

                                keysOftotMachReq.forEach(objectKey => {
                                    totMachReqData[objectKey] = resultForTotMachReq[objectKey];
                                });
                                calculateRowTotal(totMachReqData);
                            }
                        }
                    }
                }
            }
        }


        ///////////Calculations for CARDING starts here //////////////
        if ([13, 12, 11, 8, 7].includes(currentPlanParam.production_sub_section_id)) {
            if (['Utilisation %', 'Expected efficiency %', 'Delivery speed in mtrs. per min.', 'Carding Speed'].includes(currentPlanParam.production_spinning_plan_parameter_name) && [11, 8].includes(currentPlanParam.production_sub_section_id)) {
                ////Calculating Total carding production reqd./day in kgs.
                let respForTotBeakProd = dataOfOnChangeField('Total  Breaker production required/day in kgs.');
                let breakProdData = respForTotBeakProd.reqData;
                let keysOfBreakerProd = respForTotBeakProd.keysForReqData;

                const result = {};
                keysOfBreakerProd.forEach((value, index) => {
                    let numericValue1 = breakProdData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) * 0.5 / 100 + parseFloat(numericValue1))), 4);
                });

                let respForTotCardProd = dataOfOnChangeField('Total carding production reqd./day in kgs.');
                let totCardData = respForTotCardProd.reqData;
                let keysOftotCard = respForTotCardProd.keysForReqData;

                keysOftotCard.forEach(objectKey => {
                    totCardData[objectKey] = result[objectKey];
                });
                calculateRowTotal(totCardData);

                ///////Calculating Add for waste (In Percent) (Carding)
                let resp_addforwaste_Carding = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Add for waste (In Percent)' && updatedData.production_sub_section_id === 7
                );

                if (resp_addforwaste_Carding) {
                    let addforwaste_Carding_Data = resp_addforwaste_Carding;
                    let addforwaste_Carding_Key = Object.keys(addforwaste_Carding_Data).filter(key => key.startsWith('production_actual_count_'));

                    const Obj_addforwaste_Carding = {};
                    keysOftotCard.forEach((value, index) => {
                        let numericValue1 = totCardData[value]
                        Obj_addforwaste_Carding[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * addforwaste_Carding_Data['input'] / 100).toFixed(2)), 4);
                    });

                    addforwaste_Carding_Key.forEach(objectKey => {
                        addforwaste_Carding_Data[objectKey] = Obj_addforwaste_Carding[objectKey];
                    });
                    calculateRowTotal(addforwaste_Carding_Data);
                }

            }

            if (['Carding Speed', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name) && [7].includes(currentPlanParam.production_sub_section_id)) {

                //////Calculating Production rate (kgs./hrs)
                let respForCardingSpeed = dataOfOnChangeField('Carding Speed');
                let cardspeedingData = respForCardingSpeed.reqData;
                let keysOfcardingSpeed = respForCardingSpeed.keysForReqData;

                let respForProdRate = dataOfOnChangeField('Production rate (kgs./hrs)');
                let prodRateData = respForProdRate.reqData;
                let keysOfProdRate = respForProdRate.keysForReqData;

                const result = {};
                keysOfcardingSpeed.forEach((value, index) => {
                    let numericValue1 = cardspeedingData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+numericValue1 * prodRateData['input'] * 60 / 1000).toFixed(1)), 4);
                });

                keysOfProdRate.forEach(objectKey => {
                    prodRateData[objectKey] = result[objectKey];
                });
                calculateRowTotal(prodRateData);

                ///Calculating 100% production/card/per shift  hrs. in kgs.

                const resultant = {};
                keysOfProdRate.forEach((value, index) => {
                    let numericValue1 = prodRateData[value]
                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+numericValue1 * shifts_per_hour).toFixed(3)), 4);
                });

                let respForProdcard = dataOfOnChangeField('100% production/card/per shift  hrs. in kgs.');
                let prodCardData = respForProdcard.reqData;
                let keysOfProdCard = respForProdcard.keysForReqData;

                keysOfProdCard.forEach(objectKey => {
                    prodCardData[objectKey] = resultant[objectKey];
                });
                calculateRowTotal(prodCardData);

                ////Calculating	Expected production/per shift  hrs. in kgs.
                const updatedDataExpEff = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 7
                );
                if (updatedDataExpEff) {
                    let expEffData = updatedDataExpEff;
                    let keysOfExpEff = Object.keys(expEffData).filter(key => key.startsWith('production_actual_count_'));

                    const resultForExpecProdDel = {};
                    keysOfProdCard.forEach((value, index) => {
                        let numericValue1 = prodCardData[value]
                        let numericValue2 = expEffData[value]
                        resultForExpecProdDel[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * numericValue2 / 100)), 4);
                    });

                    let respForExpProd = dataOfOnChangeField('Expected production/per shift  hrs. in kgs.');
                    let expProdData = respForExpProd.reqData;
                    let keysOfExpProd = respForExpProd.keysForReqData;

                    keysOfExpProd.forEach(objectKey => {
                        expProdData[objectKey] = resultForExpecProdDel[objectKey];
                    });
                    calculateRowTotal(expProdData);

                    ///Calculating Total production/machine/Day in kgs.

                    const resultantProdMac = {};
                    keysOfExpProd.forEach((value, index) => {
                        let numericValue1 = expProdData[value]
                        resultantProdMac[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+numericValue1 * total_shifts).toFixed(1)), 4);
                    });

                    let respForTotProdMach = dataOfOnChangeField('Total production/machine/Day in kgs.');
                    let totProdMachData = respForTotProdMach.reqData;
                    let keysOftotProdMac = respForTotProdMach.keysForReqData;

                    keysOftotProdMac.forEach(objectKey => {
                        totProdMachData[objectKey] = resultantProdMac[objectKey];
                    });
                    calculateRowTotal(totProdMachData);

                    ///calculating Total cards required
                    let respForTotCardProd = dataOfOnChangeField('Total carding production reqd./day in kgs.');
                    let totCardData = respForTotCardProd.reqData;
                    let keysOftotCard = respForTotCardProd.keysForReqData;

                    const resultanttotCarding = {};
                    keysOftotCard.forEach((value, index) => {
                        let numericValue1 = totCardData[value]
                        let numericValue2 = totProdMachData[value]
                        resultanttotCarding[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+numericValue1 / numericValue2).toFixed(2)), 4);
                    });

                    let respForTotCardsReq = dataOfOnChangeField('Total cards required');
                    let totCardReqData = respForTotCardsReq.reqData;
                    let keysOftotCardReq = respForTotCardsReq.keysForReqData;

                    keysOftotCardReq.forEach(objectKey => {
                        totCardReqData[objectKey] = resultanttotCarding[objectKey];
                    });
                    calculateRowTotal(totCardReqData);

                }
            }
        }

        ////////Blow Room Calculations ////////
        if ([7].includes(currentPlanParam.production_sub_section_id)) {
            if (['Utilisation %', 'Expected efficiency %', 'Delivery speed in mtrs. per min.'].includes(currentPlanParam.production_spinning_plan_parameter_name)) {
                ///calculating Total blow room production reqd(kgs.)/day
                let respForTotCardProd = dataOfOnChangeField('Total carding production reqd./day in kgs.');
                let totCardData = respForTotCardProd.reqData;
                let keysOftotCard = respForTotCardProd.keysForReqData;

                const resultant = {};
                keysOftotCard.forEach((value, index) => {
                    let numericValue1 = totCardData[value]
                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+parseFloat(numericValue1) * 7 / 100 + parseFloat(numericValue1))), 4);
                });

                let respForTotalBlow = dataOfOnChangeField('Total blow room production reqd(kgs.)/day');
                let totBlowData = respForTotalBlow.reqData;
                let keysOftotBlow = respForTotalBlow.keysForReqData;

                keysOftotBlow.forEach(objectKey => {
                    totBlowData[objectKey] = resultant[objectKey];
                });
                calculateRowTotal(totBlowData);

                //Calculating Total Bale Required/Day

                let respForTotalBale = dataOfOnChangeField('Total Bale Required/Day');
                let totBaleData = respForTotalBale.reqData;
                let keysOftotBale = respForTotalBale.keysForReqData;

                const result = {};
                keysOftotBlow.forEach((value, index) => {
                    let numericValue1 = totBlowData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * 1.035 / totBaleData['input'])), 4);
                });

                keysOftotBale.forEach(objectKey => {
                    totBaleData[objectKey] = result[objectKey];
                });
                calculateRowTotal(totBaleData);

                ////Calculating Total Bale Required for Month	
                const resultTotBale = {};
                keysOftotBale.forEach((value, index) => {
                    let numericValue1 = totBaleData[value]
                    resultTotBale[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * 30)), 4);
                });

                let respForBale30days = dataOfOnChangeField('Total Bale Required for Month');
                let bale30DaysData = respForBale30days.reqData;
                let keysOfbale30Days = respForBale30days.keysForReqData;

                keysOfbale30Days.forEach(objectKey => {
                    bale30DaysData[objectKey] = resultTotBale[objectKey];
                });
                calculateRowTotal(bale30DaysData);
            }
        }

        ///Calculations for POST SPINNING
        if (([7].includes(currentPlanParam.production_sub_section_id) && ['Utilisation %', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name)) ||
            ([14].includes(currentPlanParam.production_sub_section_id) && ['Winding speed in mtrs./min', 'Expected efficiency %', 'Drum Avialble'].includes(currentPlanParam.production_spinning_plan_parameter_name))
        ) {
            ///Calculating Yarn available for cone winding in per day in kgs.
            const updatedDataTOTProd = await updatedPlanParameterData.find((updatedData) =>
                updatedData.production_spinning_plan_parameter_name === 'Total production/Day in kgs.' && updatedData.production_sub_section_id === 13
            );
            if (updatedDataTOTProd) {
                let totProdData = updatedDataTOTProd;
                let keysOfTotProd = Object.keys(totProdData).filter(key => key.startsWith('production_actual_count_'));

                let respForYarn = dataOfOnChangeField('Yarn available for cone winding in per day in kgs. ');
                let yarnData = respForYarn.reqData;
                let keysOfYarnData = respForYarn.keysForReqData;

                keysOfYarnData.forEach(objectKey => {
                    yarnData[objectKey] = parseInt(totProdData[objectKey]);
                });
                calculateRowTotal(yarnData);
            }

            //Calculating 100% production per drum per shift   hrs. in kgs.
            let respForWindSpeed = dataOfOnChangeField('Winding speed in mtrs./min');
            let windSpeedData = respForWindSpeed.reqData;
            let keysOfwindSpeed = respForWindSpeed.keysForReqData;

            let actualCOuntData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual Count');
            let keysOfActualCount = Object.keys(actualCOuntData).filter(key => key.startsWith('production_actual_count_'));

            const resultOfProdPerDrum = {};
            keysOfActualCount.forEach((value, index) => {
                let numericValue1 = windSpeedData[value]
                let numericValue2 = actualCOuntData[value]
                resultOfProdPerDrum[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+parseFloat(numericValue1) * 0.284 / parseFloat(numericValue2)).toFixed(2)), 4);
            });

            let respForProdPerDrum = dataOfOnChangeField('100% production per drum per shift   hrs. in kgs.');
            let prodPerDrumData = respForProdPerDrum.reqData;
            let keysOfProdPerDrum = respForProdPerDrum.keysForReqData;

            keysOfProdPerDrum.forEach(objectKey => {
                prodPerDrumData[objectKey] = resultOfProdPerDrum[objectKey];
            });
            calculateRowTotal(prodPerDrumData);

            //Calculating Expected production/drum per shift  hrs. in kgs.
            const updatedDataExpEffic = await updatedPlanParameterData.find((updatedData) =>
                updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 14
            );
            if (updatedDataExpEffic) {
                let effData = updatedDataExpEffic;
                let keysOfEff = Object.keys(effData).filter(key => key.startsWith('production_actual_count_'));

                const resultOfExpProd = {};
                keysOfEff.forEach((value, index) => {
                    let numericValue1 = prodPerDrumData[value]
                    let numericValue2 = effData[value]
                    resultOfExpProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+parseFloat(numericValue1) * parseFloat(numericValue2) / 100).toFixed(2)), 4);
                });

                let respForProdDrum = dataOfOnChangeField('Expected production/drum per shift  hrs. in kgs.');
                let prodDrumData = respForProdDrum.reqData;
                let keysOfProdDrum = respForProdDrum.keysForReqData;

                keysOfProdDrum.forEach(objectKey => {
                    prodDrumData[objectKey] = resultOfExpProd[objectKey];
                });
                calculateRowTotal(prodDrumData);

                let Obj_drumavailable = dataOfOnChangeField('Drum Avialble');
                let drumavailable_Data = Obj_drumavailable.reqData;
                let drumavailable_Key = Obj_drumavailable.keysForReqData;

                //Calculating Expected production/machine per shift  hrs. in kgs.
                const result = {};
                keysOfProdDrum.forEach((value, index) => {
                    let numericValue1 = prodDrumData[value]
                    let numericValue2 = drumavailable_Data[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) * parseFloat(numericValue2)), 4);
                });

                let respForProdMach = dataOfOnChangeField('Expected production/machine per shift  hrs. in kgs.');
                let prodMachData = respForProdMach.reqData;
                let keysOfProdMach = respForProdMach.keysForReqData;

                keysOfProdMach.forEach(objectKey => {
                    prodMachData[objectKey] = result[objectKey];
                });
                calculateRowTotal(prodMachData);

                ///Calculating Total production/Day in kgs. Autoconner spinning
                const resultant = {};
                keysOfProdMach.forEach((value, index) => {
                    let numericValue1 = prodMachData[value]
                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+numericValue1 * total_shifts).toFixed(1)), 4);
                });
                const updatedDataTotProdKg = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Total production/Day in kgs.' && updatedData.production_sub_section_id === 14
                );
                if (updatedDataTotProdKg) {
                    let totProdKgData = updatedDataTotProdKg;
                    let keystotProdKg = Object.keys(totProdKgData).filter(key => key.startsWith('production_actual_count_'));

                    keystotProdKg.forEach(objectKey => {
                        totProdKgData[objectKey] = resultant[objectKey];
                    });
                    calculateRowTotal(totProdKgData);

                    ////Calculating Total Machine required
                    let respForYarn = dataOfOnChangeField('Yarn available for cone winding in per day in kgs. ');
                    let yarnData = respForYarn.reqData;
                    let keysOfYarnData = respForYarn.keysForReqData;

                    const resultTotMachReq = {};
                    keysOfProdMach.forEach((value, index) => {
                        let numericValue1 = yarnData[value]
                        let numericValue2 = totProdKgData[value]
                        resultTotMachReq[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((+parseFloat(numericValue1) / parseFloat(numericValue2)).toFixed(1)), 4);
                    });

                    const updatedDataMachReq = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Total machine required' && updatedData.production_sub_section_id === 14
                    );
                    if (updatedDataMachReq) {
                        let machReqData = updatedDataMachReq;
                        let keystoMachReq = Object.keys(machReqData).filter(key => key.startsWith('production_actual_count_'));

                        keystoMachReq.forEach(objectKey => {
                            machReqData[objectKey] = resultTotMachReq[objectKey];
                        });
                        calculateRowTotal(machReqData);
                    }

                    debugger
                    ////Calculating Total Weight / month
                    let respForTotWeight = dataOfOnChangeField('Total Weight / month');
                    let totweightData = respForTotWeight.reqData;
                    let keysOfTotWeight = respForTotWeight.keysForReqData;

                    let respForTot_bags_30Days = dataOfOnChangeField('Total Bags /month');
                    let totbags_30DaysData = respForTot_bags_30Days.reqData;
                    let keysOfTotbags_30Days = respForTot_bags_30Days.keysForReqData;

                    const resultTotWeight = {};
                    keysOfTotbags_30Days.forEach((value, index) => {
                        let numericValue1 = totbags_30DaysData[value]
                        resultTotWeight[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * totweightData['input'])), 4);
                    });

                    keysOfTotWeight.forEach(objectKey => {
                        totweightData[objectKey] = resultTotWeight[objectKey];
                    });
                    calculateRowTotal(totweightData);


                    debugger
                    ///Calculating Total Bags / shift
                    let respForTotBags = dataOfOnChangeField('Total Bags / shift');
                    let totBagData = respForTotBags.reqData;
                    let keysOfTotBag = respForTotBags.keysForReqData;

                    const resultTotBags = {};
                    keysOfYarnData.forEach((value, index) => {
                        let numericValue1 = yarnData[value]
                        resultTotBags[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * totBagData['input'] / total_shifts / totweightData['input'])), 4);
                    });

                    keysOfTotBag.forEach(objectKey => {
                        totBagData[objectKey] = resultTotBags[objectKey];
                    });
                    calculateRowTotal(totBagData);

                    ///Calculating Total Bags / day
                    const resultTotDay = {};
                    keysOfTotBag.forEach((value, index) => {
                        let numericValue1 = totBagData[value]
                        resultTotDay[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * total_shifts)), 4);
                    });

                    let respForTotday = dataOfOnChangeField('Total Bags / day');
                    let totDayData = respForTotday.reqData;
                    let keysOfTotDay = respForTotday.keysForReqData;

                    keysOfTotDay.forEach(objectKey => {
                        totDayData[objectKey] = resultTotDay[objectKey];
                    });
                    calculateRowTotal(totDayData);

                    ////Calculating Total Bags /month
                    const resultTot_Bags_30Days = {};
                    keysOfTotDay.forEach((value, index) => {
                        let numericValue1 = totDayData[value]
                        resultTot_Bags_30Days[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(+numericValue1 * 30)), 4);
                    });

                    keysOfTotbags_30Days.forEach(objectKey => {
                        totbags_30DaysData[objectKey] = resultTot_Bags_30Days[objectKey];
                    });
                    calculateRowTotal(totbags_30DaysData);



                    ////Calculating Containers Required
                    let respForCont = dataOfOnChangeField('Containers Required');
                    let contData = respForCont.reqData;
                    let keysOfCont = respForCont.keysForReqData;

                    const resultContainer = {};
                    keysOfTotWeight.forEach((value, index) => {
                        let numericValue1 = totweightData[value]
                        resultContainer[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) / (contData['input'] * totweightData['input'])), 4);
                    });

                    keysOfCont.forEach(objectKey => {
                        contData[objectKey] = resultContainer[objectKey];
                    });
                    calculateRowTotal(contData);

                }
            }
        }

        const inputs = document.querySelectorAll('#spinplantable1 input[type="text"]:disabled');
        inputs.forEach(input => {
            const value = parseFloat(input.value);

            if (isNaN(value) || value === Infinity) {
                input.value = 0;
            }
        });

        updatedPlanParameterData[currentObjIndex] = currentPlanParam
        setPlanParamData(updatedPlanParameterData);
    }

    const calculateRowTotal = (addParameterData) => {
        const summationKeys = Object.keys(addParameterData).filter(key => key.startsWith('production_actual_count_'));
        const sum = summationKeys.reduce((accumulator, key) => {
            const value = parseFloat(addParameterData[key]) || 0;
            return accumulator + value;
        }, 0);

        addParameterData.total = validateNumberDateInput.current.decimalNumber(JSON.stringify(sum), 4);
        return addParameterData;
    }

    const calculateRowAvg = (currentPlanParam) => {
        let actualCount_Data = planparamdata.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual Count');
        let actualCount_Keys = Object.keys(actualCount_Data).filter(key => key.startsWith('production_actual_count_'));
        let currentParamData = calculateRowTotal(actualCount_Data);
        let actualCountData_AVG = ((currentParamData.total) / actualCount_Keys.length);
        currentPlanParam.total = validateNumberDateInput.current.decimalNumber(JSON.stringify(actualCountData_AVG), 4);
        return currentPlanParam;
    }

    ///Checking and unchecking all checkboxes
    $(document).on('change', '#checkAll', function () {
        // Set all checkboxes with class "productCountCheckBox" to the same state as "Check All" checkbox
        $('.productCountCheckBox').prop('checked', $(this).prop('checked'));
        $("#error_cmb_spin_plancountname").hide();
    });
    $(document).on('change', '.productCountCheckBox', function () {
        $("#error_cmb_spin_plancountname").hide();
        // If any individual checkbox is unchecked, uncheck the "Check All" checkbox
        if (!$(this).prop('checked')) {
            $('#checkAll').prop('checked', false);
        }
    });

    $(document).on('click', '.product_count_values', function () {
        if ($(this).val() === "0") {
            $(this).val("");
        }
    });

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".product_count_values");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    const validateInputs = () => {
        const inputs = document.querySelectorAll('#spinplantable1 input[type="text"]:not([disabled])');
        let isValid = true;
        for (const input of inputs) {
            const value = parseFloat(input.value);
            if (value === undefined || value === '' || isNaN(value) || value === 0) {
                input.focus();
                input.parentElement.dataset.tip = "Please enter a valid value...";
                isValid = false;
                break
            } else {
                delete input.parentElement.dataset.tip;
            }
        };
        return isValid;
    };

    const validateInputKeysOfPlanParamData = () => {
        const inputs = document.querySelectorAll('#spinplantable1 tbody .product_count_use_input');
        let flag = true;

        for (let i = 0; i < inputs.length; i++) {
            const value = parseFloat(inputs[i].value);
            if (value === 0) {
                inputs[i].parentElement.dataset.tip = "Please enter a valid value...";
                inputs[i].focus();
                flag = false;
                break;
            }
        }
        return flag;
    };


    const saveSpinPlanMasterData = async () => {
        debugger
        try {
            setIsLoading(true)
            const validate_form = await validate.current.validateForm("spinplanmasterID");
            const validate_table = validateInputs();
            if (validate_form) {
                let jsonData = {
                    TransHeaderData: {}, TransDetailData: [], MachineIds: {}, 'commonIds': {
                        'company_id': COMPANY_ID
                    }, saveProdCountKey: []
                }

                ///////////////Spin Plan Data/////////////////
                const spinPlanData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    spinn_plan_id: spinPlanId === "" ? 0 : spinPlanId,
                    financial_year: FINANCIAL_SHORT_NAME,
                    plant_id: cmb_spin_plantname,
                    spinn_plan_code: txt_spin_plancode,
                    spinn_plan_month: cmb_planmonth,
                    spinn_plan_year: cmb_planyear,
                    spinn_plan_start_date: dt_spin_planstartdate,
                    spinn_plan_end_date: dt_spin_planenddate,
                    customer_id: cmb_customername,
                    spinn_plan_created_by_id: cmb_spin_plancreatedby,
                    spinn_plan_approved_by_id: cmb_spin_planapprovedby,
                    spinn_plan_description: txt_spin_plantremark,
                    spinn_plan_avg_count: txt_spin_plan_avg_count,
                    spinn_plan_status: cmb_spin_plantstatus,
                    created_by: UserName,
                    modified_by: UserName,
                    spinn_plan_approved_by_id: keyForViewUpdate === 'approve' ? cmb_spin_planapprovedby : null
                }
                jsonData.TransHeaderData = spinPlanData;

                if (!is_approve) {
                    /////////////Spin plan param Data//////////////////
                    jsonData.TransDetailData = planparamdata;

                    ////////// Production Count with ID's ////////////
                    let combinedObject = combinedProd_Count_Ids();
                    let prodCountIds_Json = {};

                    Object.entries(combinedObject).forEach((entry) => {
                        let [key, value] = entry;
                        let split_Count = key.split('_');
                        let prefix = split_Count[0];

                        if (!prodCountIds_Json.hasOwnProperty(prefix)) {
                            prodCountIds_Json[prefix] = {};
                        }
                        prodCountIds_Json[prefix][key] = value;
                    });
                    jsonData.saveProdCountKey.push(prodCountIds_Json);

                    ///////////Machine Id's//////////
                    jsonData.MachineIds = machineidsJson;
                }

                const formData = new FormData();
                formData.append(`SppiningProdAndPlantProdData`, JSON.stringify(jsonData))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProdSpinnPlanMaster/FnAddUpdateRecord/${keyForViewUpdate}`, requestOptions)
                const apiResponse = await apiCall.json();

                if (apiResponse.success === 1) {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(apiResponse.message);
                    setShowSuccessMsgModal(true);
                } else if (apiResponse.success === 0) {
                    setErrMsg(apiResponse.error)
                    setShowErrorMsgModal(true);
                }
            }
        } catch (error) {
            navigate('/Error');
        } finally {
            setIsLoading(false)
        }

    }

    ////Validations starts here................
    const validateFields = () => {
        validate.current.validateFieldsOnChange('spinplanmasterID');
    }

    ///Validating dates
    const dateValidation = async (e) => {
        debugger
        let start_Date = $('#dt_spin_planstartdate').val();
        let end_Date = $('#dt_spin_planenddate').val();
        if (start_Date === end_Date) {
            $('#error_dt_spin_planenddate').text('Please change end date...');
            $('#error_dt_spin_planenddate').show()
        } else {
            $('#error_dt_spin_planenddate').hide();
        }
        setSpinPlanEndDate(e.target.value);
    };

    var expanded = false;

    // Hide show customer order no's
    const FnToggleProdCountList = () => {
        var checkboxes = document.getElementById("prodcount-order-ul");
        const items = document.querySelectorAll(".prodcount_order_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    const FnToggleMachineList = (index) => {
        var checkboxes = document.getElementById(`machinenumber-order-ul_${index}`);
        const items = document.querySelectorAll(".machinenumber_order_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    const combinedProd_Count_Ids = () => {
        let excluded_array = ['Sr. No', 'Section', 'Particular', 'Total', 'Input'];
        let header_data = [...headerdata];
        let saveKeys_array = header_data.filter(item => !excluded_array.includes(item));

        let selected_prod_count_keys = [];
        saveKeys_array.forEach(function (resultArray) {
            let prod_count = resultArray.split('_')[0]
            let foundObject = prodcountdata.find(item => item.field_name === prod_count);
            if (foundObject) {
                selected_prod_count_keys.push(foundObject.production_count_id);
            }
        });

        var combinedObject = {};
        for (var i = 0; i < saveKeys_array.length; i++) {
            combinedObject[saveKeys_array[i]] = selected_prod_count_keys[i];
        }
        return combinedObject;
    }

    /////Print Functionality.............................///////////////////////
    const ExportToExcel = async () => {
        debugger
        if (planparamdata.length !== 0) {
            let combinedObj = combinedProd_Count_Ids();
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }

            jsonToExportExcel['filtrKeyValue']['0'] = "Spin Plan Month" + ' : ' + $('#cmb_planmonth').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['1'] = "Spin Plan Year " + ' : ' + $('#cmb_planyear').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['2'] = "Spin Plan Start Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_spin_planstartdate);
            jsonToExportExcel['filtrKeyValue']['3'] = "Spin Plan End Date " + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_spin_planenddate);
            jsonToExportExcel['filtrKeyValue']['4'] = "Plant Name" + ' : ' + $('#cmb_spin_plantname').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['5'] = "Spin Plan Code" + txt_spin_plancode
            jsonToExportExcel['filtrKeyValue']['6'] = "Customer Name " + ' : ' + $('#cmb_customername').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['7'] = "Spin Plan Desc." + ' : ' + $('#cmb_spin_plantname').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['8'] = "Spin Plant Status" + ' : ' + $('#cmb_spin_plantstatus').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['9'] = "Plan Created By " + ' : ' + $('#cmb_spin_plancreatedby').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['10'] = "Plan Approved By " + ' : ' + $('#cmb_spin_planapprovedby').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['11'] = "Prod. Count Name " + ' : ' + Object.keys(combinedObj)[0];
            jsonToExportExcel['filtrKeyValue']['12'] = "Spin Plan Avg Count" + ' : ' + txt_spin_plan_avg_count;
            jsonToExportExcel['filtrKeyValue']['13'] = "Total Shifts" + ' : ' + total_shifts;
            jsonToExportExcel['filtrKeyValue']['14'] = "Shifts Per Hour" + ' : ' + shifts_per_hour;

            let columnKeys = Object.keys(planparamdata[0])
            for (let index = 0; index < columnKeys.length; index++) {
                const element = columnKeys[index];
                jsonToExportExcel.columns.push({ "Headers": element, "accessor": element })
            }

            planparamdata.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            })

            jsonToExportExcel['headings']['ReportName'] = "Spin Plan Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_spin_plancode + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ExcelExport ref={exlsExp} />
            <FrmValidations ref={validate} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}

            <DashboardLayout>
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Spin Plan Master {action_Label} </label>
                    </div>
                    <form id="spinplanmasterID">
                        <div className='row p-1'>
                            {/* 1 st Column */}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Month <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_planmonth" className="form-select form-select-sm" value={cmb_planmonth} onChange={(e) => { setSpinPlanMonth(e.target.value); validateFields() }}>
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_planmonth" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Plan Year <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_planyear" className="form-select form-select-sm" value={cmb_planyear} onChange={(e) => { setSpinPlanYear(e.target.value); validateFields() }}>
                                            <option value="">Select</option>
                                            {spinplanyearOptions.length !== 0 ? (
                                                <>
                                                    {spinplanyearOptions?.map(year => (
                                                        <option value={year.field_id}>{year.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_planyear" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Start Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_spin_planstartdate' className="erp_input_field" value={dt_spin_planstartdate} onChange={(e) => { setSpinPlanStartDate(e.target.value); fillAutoNo(); validateFields() }} />
                                        <MDTypography variant="button" id="error_dt_spin_planstartdate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan End Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_spin_planenddate' className="erp_input_field" value={dt_spin_planenddate} onChange={(e) => { dateValidation(e); fillAutoNo(); validateFields(); }} />
                                        <MDTypography variant="button" id="error_dt_spin_planenddate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* 2 nd Column */}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_spin_plantname" className="form-select form-select-sm" value={cmb_spin_plantname} onChange={(e) => { setSpinPlantName(e.target.value); fillAutoNo(); renderTableData(e.target.value); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.plant_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spin_plantname" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Code <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_spin_plancode" maxLength="5" value={txt_spin_plancode} disabled onInput={() => validateFields()} />
                                        <MDTypography variant="button" id="error_txt_spin_plancode" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_customername" className="form-select form-select-sm" value={cmb_customername} optional='optional' onChange={(e) => { setSpinPlanCustomerName(e.target.value); validateFields() }} >
                                            <option value="">Select</option>
                                            {customerOptions.length !== 0 ? (
                                                <>{customerOptions?.map(customer => (
                                                    <option value={customer.field_id}>{customer.field_name}</option>
                                                ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customername" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Description <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_spin_plantremark" className="erp_input_field form-control" maxlength="255" value={txt_spin_plantremark} onInput={() => validateFields()} onChange={(e) => setSpinRemark(e.target.value)} />
                                        <MDTypography variant="button" id="error_txt_spin_plantremark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* 3rd Column */}
                            <div className='col-sm-4 erp_form_col_div'>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Status <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_spin_plantstatus" className="form-select form-select-sm" value={cmb_spin_plantstatus} onChange={(e) => { setSpinPlantStatus(e.target.value); validateFields() }} >
                                            <option value={" "} disabled>Select</option>
                                            <option value="Pending" >Pending</option>
                                            <option value="WIP" >WIP</option>
                                            <option value="completed" >Completed</option>
                                            <option value="preclosed" >Preclosed</option>
                                            <option value="Cancelled" >Cancelled</option>
                                            <option value="Approved" >Approved</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spin_plantstatus" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Created By <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_spin_plancreatedby" className="form-select form-select-sm" value={cmb_spin_plancreatedby} onChange={(e) => { setPlanCreatedBy(e.target.value); validateFields() }} >
                                            <option value="" disabled>Select</option>
                                            <>
                                                {spinplanCreatedByOptions.length !== 0 ? (
                                                    <>{spinplanCreatedByOptions?.map(employee => (
                                                        <option value={employee.field_id}>{employee.field_name}</option>
                                                    ))}
                                                    </>
                                                ) : null
                                                }
                                            </>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spin_plancreatedby" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div id='planAppRowId' style={cmb_spin_plantstatus === 'Approved' ? null : { display: "none" }} >
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_spin_planapprovedby" className="form-select form-select-sm" value={cmb_spin_planapprovedby} onChange={(e) => { setPlanApprovedBy(e.target.value); validateFields() }} optional={cmb_spin_plantstatus === 'Approved' ? null : "optional"}>
                                                <option value="" disabled>Select</option>
                                                <>
                                                    {spinplanApprovedByOptions.length !== 0 ? (
                                                        <>{spinplanApprovedByOptions?.map(employee => (
                                                            <option value={employee.field_id}>{employee.field_name}</option>
                                                        ))}
                                                        </>
                                                    ) : null
                                                    }
                                                </>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_spin_planapprovedby" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved Remark<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_spin_plantAppRemark" className="erp_input_field form-control" maxlength="255" value={txt_spin_plantAppRemark} onInput={() => validateFields()} onChange={(e) => setSpinApproveRemark(e.target.value)} optional={cmb_spin_plantstatus === 'Approved' ? null : "optional"} />
                                            <MDTypography variant="button" id="error_txt_spin_plantAppRemark" className="erp_validation mb-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                <div id='planCancelRemarkId' style={cmb_spin_plantstatus === 'Cancelled' ? null : { display: "none" }}>
                                    <div className="row" >
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Cancelled Remark <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_spin_plantCancelRemark" className="erp_input_field form-control" maxlength="255" value={txt_spin_plantCancelRemark} onInput={() => validateFields()} onChange={(e) => setSpinCancelRemark(e.target.value)} optional={cmb_spin_plantstatus === 'Cancelled' ? null : "optional"} />
                                            <MDTypography variant="button" id="error_txt_spin_plantCancelRemark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Base : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Generic"
                                                    type="radio"
                                                    value="Generic" checked={isPlanBase === 'Generic'} onClick={() => { setSpinPlanBase('Generic'); }}
                                                    name="isProductFGActive"
                                                    defaultChecked
                                                />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Machine Wise"
                                                    value="Machine-Wise" checked={isPlanBase === 'Machine-Wise'} onClick={() => { setSpinPlanBase('Machine-Wise'); }}
                                                    type="radio"
                                                    name="isProductFGActive"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Total Shifts <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="total_shifts" maxLength="5" value={total_shifts} disabled />
                                        <MDTypography variant="button" id="error_total_shifts" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Shifts Per Hour<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="shifts_per_hour" maxLength="5" value={shifts_per_hour} disabled />
                                        <MDTypography variant="button" id="error_shifts_per_hour" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <hr />
                    <div className='row'>
                        <div className='col-sm-auto erp_form_col_div me-2'>
                            <div className='erp-form-label-lg mt-1'>Spin Plan Parameter</div>
                        </div>


                        <div className='col-sm-auto erp_form_col_div ms-2' id='spin_plancountname'>
                            <div className='row'>
                                <div className="col-auto">
                                    <Form.Label className="erp-form-label mb-2">Prod. Counts<span className="required">*</span></Form.Label>
                                </div>
                                <div className="col-auto mt-1" id='cmb_spin_plancountname'>
                                    <div className="form-select form-select-sm"
                                        onClick={() => FnToggleProdCountList()}
                                        data-optional="optional">
                                        {'Select'}
                                    </div>
                                    <ul className="list-items" id="prodcount-order-ul" >
                                        <li className="item prodcount_order_no">
                                            <span className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="checkAll"
                                                    name="productCountCheckBox"
                                                    value={selectAllCheckBoxes}
                                                    className="erp_radio_button filterCheckBox productCountCheckBox"
                                                    optional='optional'
                                                />
                                            </span>
                                            <span className="item-text">All</span>
                                        </li>
                                        {prodcountdata.length !== 0 ?
                                            prodcountdata.map((prodcount, index) =>
                                                <li className="item prodcount_order_no" key={index}>
                                                    <span className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="productCountCheckBox"

                                                            value={prodcount.field_name}
                                                            className="erp_radio_button productCountCheckBox"
                                                            optional='optional'
                                                        />
                                                    </span>
                                                    <span className="item-text">{prodcount.field_name}</span>
                                                </li>
                                            ) : null
                                        }
                                    </ul>
                                    <MDTypography variant="button" id="error_cmb_spin_plancountname" className="erp_validation mt-2" fontWeight="regular" color="error" >
                                    </MDTypography>
                                </div>

                                <div className="col-auto">
                                    <Form.Label className="erp-form-label mb-2">Target Level<span className="required">*</span></Form.Label>
                                </div>
                                <div className="col-auto mt-1">
                                    <select id="cmb_spin_targetlevel" className="form-select form-select-sm" value={cmb_spin_targetlevel} >
                                        <option value={" "} disabled>Select</option>
                                        <option value="target_level1" >Target Level 1</option>
                                        <option value="target_level2" >Target Level 2</option>
                                        <option value="target_level3" >Target Level 3</option>
                                        <option value="target_level4" >Target Level 4</option>
                                        <option value="target_level5" >Target Level 5</option>
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_spin_targetlevel" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>

                            </div>
                        </div>

                        <div className='col-sm-auto erp_form_col_div ms-2'>
                            <MDButton className="col-auto erp-gb-button me-3 spin_plan_param_button" variant="button" fontWeight="regular" onClick={() => addNewColumn()}>
                                Add Count
                            </MDButton>

                            <MDButton className="col-auto erp-gb-button spin_plan_param_button" variant="button" fontWeight="regular" onClick={() => deleteFromTable()}>
                                Delete Count
                            </MDButton>
                        </div>


                        <div className='col-sm-auto erp_form_col_div'>
                            <div className="row">
                                <div className="col-sm-auto">
                                    <Form.Label className="erp-form-label">Count Avg.</Form.Label>
                                </div>
                                <div className="col-sm-4">
                                    <Form.Control type="text" id='txt_spin_plan_avg_count' className="erp_input_field mt-1" value={txt_spin_plan_avg_count} disabled />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-auto' style={{ height: "auto" }}>
                            {renderSpinPlanTable}
                        </div>
                    </div>
                </div >
                <div className="erp_frm_Btns">
                    <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Masters/MProductionSpinPlan/MProductionSpinPlanListing')}>Back</MDButton>
                    <MDButton type="submit" id="action_Button" className="erp-gb-button ms-2" variant="button"
                        onClick={() => saveSpinPlanMasterData()} fontWeight="regular">{action_Button}</MDButton>
                    <MDButton className="erp-gb-button ms-2" variant="button" id='print_Button' fontWeight="regular" onClick={() => ExportToExcel()}>Print</MDButton>
                </div >

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>
        </>
    )
}

export default FrmSpinPlanEntry