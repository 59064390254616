import ConfigConstants from "assets/Constants/config-constant";
import { React, forwardRef, useImperativeHandle } from "react";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExcelExport = forwardRef((props, ref) => {

  const configConstants = ConfigConstants();
  const { COMPANY_ADDRESS } = configConstants;

  useImperativeHandle(ref, () => ({

    async excel(jsonToExportExcel, reportName) {

      jsonToExportExcel['headings']['CompanyAddress'] = COMPANY_ADDRESS

      var formData = new FormData();
      formData.append(`export`, JSON.stringify(jsonToExportExcel))
      const requestOptions = { method: 'POST', body: formData };
      const exporttoExcelApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/excelexport/FnExportToExcel`, requestOptions)
      const blob = await exporttoExcelApiCall.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', reportName + ".xlsx",);
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);


    },

    exportToExcel(data, columns, reportName) {
      // Map data according to columns
      const mappedData = data.map(row => {
        const newRow = {};
        columns.forEach(col => {
          newRow[col.Headers] = row[col.accessor];
        });
        return newRow;
      });

      // Convert the mapped data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(mappedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Create a blob object from the Excel file
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

      // Save the Excel file
      saveAs(blob, `${reportName}_${new Date().toISOString()}.xlsx`);
    }
  }))



  return (<div> </div>)
})

export default ExcelExport
