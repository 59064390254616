import React from 'react'
import $ from 'jquery';
import { useState, useRef, useMemo } from "react";

// import icons
import { Form, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// import files
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import FrmValidations from 'FrmGeneric/FrmValidations';


function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

function SearchItemFilter(props) {
    const configConstants = ConfigConstants();
    const { COMPANY_ID } = configConstants;
    const { formIdForValidate, requiredCols, companyId = COMPANY_ID, godownId = "", isMainGodown = false, product_type_id = 0, product_category2_id = 0 } = props

    // use refs
    const comboBox = useRef();
    const validate = useRef();
    const typeaheadRef = useRef();

    const [searchState, setSearchState] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [options, setOptions] = useState([]);


    // fn for get search results
    const FnSearchOnChange = async (searchText) => {
        try {
            console.log('product_type_id: ', product_type_id);
            console.log('product_category2_id: ', product_category2_id);
            if (searchText.trim() !== "") {
                const materialSearchObject = {
                    searchInput: searchText,
                    company_id: companyId,
                    godown_id: godownId,
                    isMainGodown: isMainGodown,
                    product_type_id: product_type_id,
                    product_category2_id: product_category2_id,
                }
                const formData = new FormData();
                formData.append(`materialSearchObject`, JSON.stringify(materialSearchObject))
                const requestOptions = { method: 'POST', body: formData };

                const responceOfSearchedData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/masterData/FnShowMaterialItems`, requestOptions);
                const responce = await responceOfSearchedData.json();

                if (responce.success === "0") {
                    setSearchResults([])
                    setOptions([])
                    console.log(responce.error);
                } else {
                    let productData = responce.data
                    if (productData.length > 0) {
                        if (productData.length !== 0) {
                            productData = productData.map(item => {
                                // Create a new object with the updated key name
                                const newItem = {
                                    ...item,
                                    product_type_group: item.product_type_group,
                                    product_type_name: item.product_type_name,
                                    product_category1_name: item.product_material_category1_name,
                                    product_id: item.product_material_id,
                                    product_code: item.product_material_code,
                                    product_name: item.product_material_name,
                                    product_material_short_name: item.product_material_short_name,
                                    product_tech_spect: item.product_material_tech_spect,
                                    product_unit_name: item.product_material_stock_unit_name,
                                    stock_quantity: item.closing_balance_quantity || 0,
                                    stock_weight: item.closing_balance_weight || 0,
                                    product_lead_time: item.lead_time,
                                    product_moq: item.product_material_moq,
                                    product_mrp: item.product_material_mrp,
                                    product_landed_price: item.product_material_landed_price,
                                    product_technical_name: item.product_material_technical_name,
                                    product_material_type_short_name: item.product_material_type_short_name,
                                    product_category2_name: item.product_material_category2_name,
                                    product_category3_name: item.product_material_category3_name,
                                    product_category4_name: item.product_material_category4_name,
                                    product_category5_name: item.product_material_category5_name,
                                    product_packing_name: item.product_material_packing_name,
                                    product_make_name: item.product_material_make_name,
                                    product_material_type_name: item.product_material_type_name,
                                    product_material_grade_name: item.product_material_grade_name,
                                    product_material_shape_name: item.product_material_shape_name,
                                    product_oem_part_code: item.product_material_oem_part_code,
                                    product_our_part_code: item.product_material_our_part_code,
                                    product_drawing_no: item.product_material_drawing_no,
                                    product_std_weight: item.product_material_std_weight,
                                    product_std_hours: item.product_material_std_hours,
                                    process_duration: item.process_duration,
                                    product_std_profit_percent: item.product_std_profit_percent,
                                    product_std_discount_percent: item.product_std_discount_percent,
                                    godown_name: item.godown_name,
                                    godown_short_name: item.godown_short_name,
                                    godown_area: item.godown_area,
                                    godown_section_name: item.godown_section_name,
                                    godown_section_short_name: item.godown_section_short_name,
                                    godown_section_area: item.godown_section_area,
                                    godown_section_beans_name: item.godown_section_beans_name,
                                    godown_section_beans_short_name: item.godown_section_beans_short_name,
                                    godown_section_beans_area: item.godown_section_beans_area,
                                    product_hsn_sac_code: item.product_material_hsn_sac_code,
                                    product_hsn_sac_rate: item.product_material_hsn_sac_rate,
                                    product_category1_id: item.product_material_category1_id,
                                    product_type_id: item.product_type_id,
                                    product_unit_id: item.product_material_stock_unit_id,
                                    product_packing_id: item.product_material_packing_id,
                                    product_hsn_sac_code_id: item.product_material_hsn_sac_code_id,
                                    product_category2_id: item.product_material_category2_id,
                                    product_category3_id: item.product_material_category3_id,
                                    product_category4_id: item.product_material_category4_id,
                                    product_category5_id: item.product_material_category5_id,
                                    product_make_id: item.product_material_make_id,
                                    product_material_type_id: item.product_material_type_id,
                                    product_material_grade_id: item.product_material_grade_id,
                                    product_material_shape_id: item.product_material_shape_id,
                                    company_id: item.company_id,
                                    company_branch_id: item.company_branch_id,
                                    godown_id: item.godown_id,
                                    godown_section_id: item.godown_section_id,
                                    godown_section_beans_id: item.godown_section_beans_id,
                                    bom_applicable: item.bom_applicable
                                };
                                return newItem;
                            })
                            setSearchResults(productData)
                            console.log('productData: ', productData);
                            // set options data for suggestions
                            let optionsData = productData.map(item => {
                                const optionItem = {
                                    name: `${item.product_code === null || item.product_code === '' ? "" : `[${item.product_code}]`}${item.product_name}`,
                                    value: item.product_id,
                                    product_id: item.product_id
                                };
                                return optionItem;
                            })
                            setOptions(optionsData)
                        } else {
                            console.log('productData else : ', productData);
                            setSearchResults([])
                            setOptions([])
                        }
                    } else {
                        console.log('productData else : ', productData);
                        setSearchResults([])
                        setOptions([])

                    }
                }

            } else {
                console.log('productData  : ', searchText);
                setSearchResults([])
                setOptions([])
                // selectMaterial();
            }
        } catch (error) {
            console.log("Error On search material: ", error);
        }
    }

    // get matreial data on select
    const selectMaterial = (productId) => {
        let mergeSessionAndSelectedArr = [];
        let selectedData = [];
        let availableData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) !== null ? JSON.parse(sessionStorage.getItem('filteredMaterialData')) : [];
        if (productId !== undefined) {
            const tdData = searchResults.find(item => item.product_id === productId)
            if (tdData) {
                const filteredDataRecord = requiredCols.reduce((acc, col) => {
                    acc[col] = tdData.hasOwnProperty(col) ? tdData[col] : 0;
                    return acc;
                }, {});

                selectedData.push(filteredDataRecord);
            }
            mergeSessionAndSelectedArr = [...selectedData, ...availableData];
            if (typeaheadRef.current) {
                typeaheadRef.current.clear();
            }
        }
        sessionStorage.setItem('filteredMaterialData', JSON.stringify(mergeSessionAndSelectedArr))
        console.log("selectedData: ", mergeSessionAndSelectedArr);
        setSearchState("")
        // setSearchResults([])
        // setOptions([])
        props.getSearchData()
    }

    //Fn for check validation befor search and call search func
    const FncheckValidate = async (searchText) => {
        //  setSearchState(searchText)
        // $('#cmb_department').attr("optional", "optional");
        console.log('searchText:- ', searchText);

        let checkIsValidate = false;
        if (formIdForValidate !== "") {
            checkIsValidate = await validate.current.validateForm(formIdForValidate);
            // $('#cmb_department').removeAttr("optional");
            if (checkIsValidate) {
                if (searchText.trim().length >= 2) {
                    await FnSearchOnChange(searchText)
                } else {
                    setSearchResults([])
                    setOptions([])
                }
            }
        } else {
            if (searchText.trim().length >= 2) {
                await FnSearchOnChange(searchText)
            } else {
                setSearchResults([])
                setOptions([])
            }
        }
    }

    // Create a debounced version of the search function
    const debouncedFncheckValidate = useMemo(() => debounce(FncheckValidate, 300), [
        formIdForValidate,
        companyId,
        godownId,
        isMainGodown,
        product_type_id,   // Include all necessary dependencies
        product_category2_id,
    ]);


    return (
        <>
            <ComboBox ref={comboBox} />
            <FrmValidations ref={validate} />
            <Form.Group>
                <InputGroup className="mt-1">
                    <Typeahead
                        className="custom-typeahead-class"
                        id="serch-input-id"
                        labelKey={(option) => `${option.name}`}
                        placeholder="Search item..."
                        value={searchState || ""}
                        onChange={(selected) => {
                            setSearchState("");
                            if (selected.length > 0) {
                                const selectedItem = selected[0];
                                selectMaterial(selectedItem.product_id); // Example: Assuming product_id is the unique identifier
                            }
                        }}
                        // onInputChange={(input) => {

                        //     FncheckValidate(input);
                        // }}
                        onInputChange={(input) => {
                            debouncedFncheckValidate(input);  // Use the debounced function here
                        }}
                        // options={searchState.length >= 2 ? options : []}
                        options={options || []}
                        filterBy={() => true}
                        minLength={2}
                        ref={typeaheadRef}
                    />
                </InputGroup>
            </Form.Group>
        </>
    )
}
export default SearchItemFilter