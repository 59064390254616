
import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// import react icons
import { useLocation, useNavigate } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from 'assets/Constants/config-constant';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { validate } from 'uuid';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';

export default function FrmMProductQaParametersEntry(props) {
  //changes by ujjwala on 5/1/2024 case no .1
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;
  const { state } = useLocation();
  const { productqaparametersID = 0, keyForViewUpdate, compType } = state || {}
  var activeValue = '';
  const validate = useRef();
  const validateNumberDateInput = useRef();
  const [product_qa_parameters_id, setproductqaparametersID] = useState(productqaparametersID);

  const comboDataAPiCall = useRef();

  const [productTypeOptions, setProductTypeOptions] = useState([]);
  // Add Product Type Form Fields

  const [cmb_product_type_id, setProductTypeId] = useState('');
  const [cmb_product_qa_type, setproduct_qa_type] = useState('ALL');
  const [txt_product_qa_parameters_name, setProductQaParametersNameId] = useState('');
  const [txt_product_qa_parameters_short_name, setProductQaParametersShortNameId] = useState('');
  const [txt_from_range, setFromRangeId] = useState('');
  const [txt_to_range, setToRangeId] = useState('');
  const [txt_from_deviation_percent, setFromDeviationPercentId] = useState('');
  const [txt_to_deviation_percent, setToDeviationPercentId] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    await fillComobos()
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')

    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductQaParametersListing`);
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');


  // For navigate
  const navigate = useNavigate();
  //changes by ujjwala on 5/1/2024 case no .1
  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      await fillComobos();
      if (productqaparametersID !== 0) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])

  //end
  const comboOnChange = async (key) => {
    debugger
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        }
        setProductTypeId(productTpVal)
        break;

      case 'productqatype':
        var productqatypeVal = document.getElementById('cmb_product_qa_type').value;
        if (productqatypeVal !== '') {
          setproduct_qa_type(productqatypeVal)
        }

        break;


    }
  }

  const fillComobos = async () => {
    const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    setProductTypeOptions(productTypeApiCall)



  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("productqaparameterFormId");
      if (checkIsValidate === true) {
        var active;
        var qatype;

        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;

        }

        const data = {
          company_id: COMPANY_ID,
          product_qa_parameters_id: product_qa_parameters_id,
          created_by: UserName,
          modified_by: product_qa_parameters_id === 0 ? null : UserName,
          product_type_id: cmb_product_type_id,
          product_qa_type: cmb_product_qa_type,
          product_qa_parameters_name: txt_product_qa_parameters_name,
          product_qa_parameters_short_name: txt_product_qa_parameters_short_name,
          from_range: txt_from_range,
          to_range: txt_to_range,
          from_deviation_percent: txt_from_deviation_percent,
          to_deviation_percent: txt_to_deviation_percent,
          remark: txt_remark,
          is_active: active,
        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productqaparameters/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          var data1 = JSON.parse(responce.data)
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };

  //changes by ujjwala on 5/1/2024 case no .1
  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $('#txt_product_qa_parameters_name').attr('disabled', true);
        $('#cmb_product_type_id').attr('disabled', true);
        $('[name="isqatypepurchase"]').attr('disabled', true);
        $('#txt_product_qa_parameters_short_name').attr('disabled', true)
        $('#cmb_product_qa_type').attr('disabled', true)
        break;

      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productqaparameterFormId");
        $("input[type=radio]").attr('disabled', true);
        break;

      default:
        setActionType('(Creation)');
        break;
    }

  };
  //end
  //changes by ujjwala on 5/1/2024 case no .1
  const validateFields = () => {
    validate.current.validateFieldsOnChange('productqaparameterFormId')
    validate.current.validateFieldsOnChange('productqaparameterFormId')
  }

  //end
  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productqaparameters/FnShowParticularRecordForUpdate/${product_qa_parameters_id}`)
      const updateRes = await apiCall.json();
      let resp = JSON.parse(updateRes.data)
      await fillComobos();
      setproductqaparametersID(resp.product_qa_parameters_id)
      setProductTypeId(resp.product_type_id);
      setProductQaParametersNameId(resp.product_qa_parameters_name);
      setProductQaParametersShortNameId(resp.product_qa_parameters_short_name);
      setproduct_qa_type(resp.product_qa_type);
      setFromRangeId(resp.from_range);
      setToRangeId(resp.to_range);
      setFromDeviationPercentId(resp.from_deviation_percent);
      setToDeviationPercentId(resp.to_deviation_percent);
      setRemark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }



  //changes by ujjwala on 5/1/2024 case no .1
  const validateNo = (key) => {
    debugger
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{4}$/
    const regexNo1 = /^[0-9]*\.[0-9]{4}$/
    var dot = '.';
    switch (key) {
      case 'pFromRange':
        var pFromRangeVal = $('#txt_from_range').val();
        if (!regexNo.test(pFromRangeVal) && pFromRangeVal.length <= 10 || pFromRangeVal.indexOf(dot) === 10) {
          if (numCheck.test(pFromRangeVal)) {
            setFromRangeId(pFromRangeVal)
          }

        }
        break;
      case 'pToRange':
        var pToRangeVal = $('#txt_to_range').val();
        if (!regexNo.test(pToRangeVal) && pToRangeVal.length <= 10 || pToRangeVal.indexOf(dot) === 10) {
          if (numCheck.test(pToRangeVal)) {
            setToRangeId(pToRangeVal)
          }

        }
        break;
    }
  }
  //end

  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validate} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />

      <div className="erp_top_Form">
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Qa Parameters {actionType} </label>
          </div>
          <form id="productqaparameterFormId">
            <div className="row erp_transporter_div">
              {/* first row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record +</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>
                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Qa Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_qa_type" className="form-select form-select-sm" value={cmb_product_qa_type} onChange={() => { comboOnChange('productqatype'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="ALL">ALL</option>
                      <option value="Purchase">Purchase</option>
                      <option value="Production">Production</option>
                      <option value="Audits">Audits</option>
                      <option value="Services">Services</option>
                      <option value="Sales">Sales</option>

                    </select>
                    <MDTypography variant="button" id="error_cmb_consumption_mode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Packing Qa Type</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button" label="Purchase" type="radio" value="Purchase" name="isqatypepurchase"
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button" label="Sales" value="Sales" type="radio" name="isqatypepurchase"
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button" label="Production" value="Production" type="radio" name="isqatypepurchase"
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button" label="All" value="All" type="radio" name="isqatypepurchase" defaultChecked
                        />
                      </div>
                    </div>
                  </div>
                </div> */}


                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">  Parameters Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_qa_parameters_name" className="erp_input_field" value={txt_product_qa_parameters_name} onChange={e => { setProductQaParametersNameId(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_qa_parameters_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Short Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_qa_parameters_short_name" className="erp_input_field" value={txt_product_qa_parameters_short_name} onChange={e => { setProductQaParametersShortNameId(e.target.value.toUpperCase()); validateFields(); }} maxLength="20" />
                    <MDTypography variant="button" id="error_txt_product_qa_parameters_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">From Range</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_from_range" className="erp_input_field text-end" value={txt_from_range} onChange={e => { validateNo('pFromRange'); }} maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_from_range" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

              </div>
              {/* second row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">To Range</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_to_range" className="erp_input_field text-end" value={txt_to_range} onChange={e => { validateNo('pToRange'); }} maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_to_range" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">From Deviation (%)</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="number" id="txt_from_deviation_percent" className="erp_input_field text-end" value={txt_from_deviation_percent}
                      onChange={(e) => {
                        if (validateNumberDateInput.current.percentValidate(e.target.value)) {
                          setFromDeviationPercentId(e.target.value)
                        }; validateFields();
                      }}
                      maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_from_deviation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> To Deviation (%)</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="number" id="txt_to_deviation_percent" className="erp_input_field text-end" value={txt_to_deviation_percent}
                      onChange={(e) => {
                        if (validateNumberDateInput.current.percentValidate(e.target.value)) {
                          setToDeviationPercentId(e.target.value)
                        }; validateFields();
                      }}
                      maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_to_deviation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/*   //changes by ujjwala on 5/1/2024 case no .1 */}
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductQaParametersListing/reg' : '/Masters/ProductQaParametersListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
            <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>
          </div>
        </div>

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            <FrmMProductTypeEntry btn_disabled />
          </Modal.Body>

        </Modal >

        {/* end by ujjwala */}
        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      </div>

    </>
  )
}
