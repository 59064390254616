import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from 'assets/Constants/config-constant';

let itemsPerPage = 0; // Number of items per page


//convert cgst,igst,sgst percent in words
function numberToWordsWithCurrency(number) {
  // Check if the input number is NA, null, or an empty string
  if (number === null || number === "NA" || number === "" || number === undefined) {
    return "-";
  }

  const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  // Function to convert the integer part of the number to words
  function convertIntegerToWords(integer) {
    if (integer === 0) {
      return "";
    }

    if (integer < 20) {
      return ones[integer];
    }

    if (integer < 100) {
      const ten = Math.floor(integer / 10);
      const remainder = integer % 10;
      return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
    }

    if (integer < 1000) {
      const hundred = Math.floor(integer / 100);
      const remainder = integer % 100;
      return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
    }

    if (integer < 1000000) {
      const thousand = Math.floor(integer / 1000);
      const remainder = integer % 1000;
      return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
    }
    if (integer < 1000000000) {
      const million = Math.floor(integer / 1000000);
      const remainder = integer % 1000000;
      return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
    }

    return "Number too large to convert";
  }

  // Function to convert the decimal part of the number to words
  function convertDecimalToWords(decimal) {
    const tensValue = Math.floor(decimal / 10);
    const onesValue = decimal % 10;
    let words = "";

    if (tensValue > 0) {
      words += tens[tensValue];
    }

    if (onesValue > 0) {
      words += ` ${ones[onesValue]}`;
    }

    return words;
  }

  // Split the number into integer and decimal parts
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

  // Convert the integer part to words
  let words = convertIntegerToWords(integerPart);

  // Add currency
  if (words) {
    words += " Rupees";
  }

  // Add the decimal part to the words
  if (decimalPart > 0) {
    words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
  }

  return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
}

const SalesOrderService = React.forwardRef(({ SalesOrderServiceContent }, ref) => {

  // // Commented because it will giving error at the time of email sending.
  // const configConstants = ConfigConstants();
  // const { AFTER_DECIMAL_PLACES } = configConstants;
  const AFTER_DECIMAL_PLACES = 2;

  itemsPerPage = 18;
  const data = SalesOrderServiceContent.items

  const cgstTotal = SalesOrderServiceContent.footer.cgst_total;
  const sgstTotal = SalesOrderServiceContent.footer.sgst_total;
  const igstTotal = SalesOrderServiceContent.footer.igst_total;
  const basicTotal = SalesOrderServiceContent.footer.basic_total;
  const taxable_total = SalesOrderServiceContent.footer.taxable_total
  const grand_total = SalesOrderServiceContent.footer.grand_total

  const cgstTotalInWords = numberToWordsWithCurrency(cgstTotal);
  const sgstTotalInWords = numberToWordsWithCurrency(sgstTotal);
  const igstTotalInWords = numberToWordsWithCurrency(igstTotal);
  const basicTotalInWords = numberToWordsWithCurrency(basicTotal);
  const taxabletotalInWords = numberToWordsWithCurrency(taxable_total);
  const grandtotalInWords = numberToWordsWithCurrency(grand_total);


  const SOTerms = SalesOrderServiceContent.SOTerms;
  SOTerms.forEach(SOTerms => {
    const { common_parameters_name, common_parameters_value } = SOTerms;
  });

  const contactDetails = SalesOrderServiceContent.contactDetails;
  // Iterate over each element in the contactDetails array
  contactDetails.forEach(contact => {
    const { cust_contact_person, cust_contact_no } = contact;
  });

  const itemData = extractItemData(SalesOrderServiceContent.items);
  function extractItemData(items) {
    // Extract relevant data from the first item in the array
    if (items.length > 0) {
      const firstItem = items[0];
      return {
        company: firstItem.company,
        company_branch: firstItem.company_branch,
        address: firstItem.address,
        phone: firstItem.phone,
        cell: firstItem.cell,
        email: firstItem.email,
        website: firstItem.website,
        GSTIN: firstItem.GSTIN,
        pan_no: firstItem.pan_no,
        state_name: firstItem.state_name,
        company_pincode: firstItem.company_pincode,
      };
    }
    // Return default values or handle the case where the array is empty
    return {
      company: 0,
      company_branch: 0,
      address: 0,
      phone: 0,
      cell: 0,
      email: 0,
      website: 0,
      GSTIN: 0,
      pan_no: 0,
      state_name: 0,
      company_pincode: 0,
    };
  }
  let paymentTermsList = null
  if (SalesOrderServiceContent.paymentTerms && SalesOrderServiceContent.paymentTerms.length > 0) {
    paymentTermsList = SalesOrderServiceContent.paymentTerms.map(paymentTerm => paymentTerm.payment_terms_name).join(', ');
  }
  const invoiceFooter = (
    <div className="invoice-footer">
      <div className='row'>
        <div className='col-sm-6'>
        </div>
        <div className='col-sm-6'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For {itemData.company}</dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
          </div>
        </div>
      </div>
    </div>
  );

  const invoiceHeader = (

    <div className='bordered border-bottom-0 px-0'>
      <div className='row mb-0 p-2'>
        <div className="col-sm-3 text-center text-sm-start">
          <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
        </div>
        <div className="col-sm-9 transformed">
          <div className='erp-invoice-print-label-lg text-center'>
            {itemData.company}
          </div>
          <div className="row">
            <div className="text-center">
              <div className='erp-invoice-print-label text-center'>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                </div>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.website}>{itemData.website}</a> || Email: {itemData.email} </span>
                </div>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'> Phone: {itemData.phone} || Cell:{itemData.cell} </span>
                </div>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no} </span>
                </div>

                <div className='erp-invoice-print-label-lg'> Service Sales Order </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row border-top border-dark p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Order No & version: {SalesOrderServiceContent.salesOrderDetails.sales_order_no} & {SalesOrderServiceContent.salesOrderDetails.sales_order_version}</dt>
          </dl>
          {SalesOrderServiceContent.salesOrderDetails.sales_order_creation_type != 'Mannual' ?
            <dl className="erp-invoice-print-label-md-lg">
              <dt>Sales Quotation No: {SalesOrderServiceContent.salesOrderDetails.sales_order_creation_type === 'Mannual' ? '-' : SalesOrderServiceContent.salesOrderDetails.sales_quotation_no}</dt>
            </dl>
            : null
          }
        </div>
        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Order Type: {SalesOrderServiceContent.salesOrderDetails.sales_order_creation_type}</dt>
            {/* <dt>Sales Order Date: {SalesOrderServiceContent.salesOrderDetails.sales_order_date}</dt> */}
          </dl>

          {SalesOrderServiceContent.salesOrderDetails.sales_order_creation_type != 'Mannual' ?
            <dl className="erp-invoice-print-label-md-lg">
              <dt>Sales Quotation Date: {SalesOrderServiceContent.salesOrderDetails.sales_order_creation_type === 'Mannual' ? '-' : SalesOrderServiceContent.salesOrderDetails.sales_quotation_date}</dt>
            </dl>
            : null
          }
        </div>
        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg">
            {/* <dt>Department: {SalesOrderServiceContent.salesOrderDetails.department_name}</dt> */}
            <dt>Sales Order Date: {SalesOrderServiceContent.salesOrderDetails.sales_order_date}</dt>
          </dl>
        </div>
      </div>
      <div className='row border-bottom border-dark p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Customer Order No: {SalesOrderServiceContent.salesOrderDetails.customer_order_no}</dt>
          </dl>
        </div>
        <div className='col-sm-6'>
          {/* <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Quotation Date: {SalesOrderServiceContent.salesOrderDetails.sales_quotation_date}</dt>
          </dl> */}
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Customer Order Date: {SalesOrderServiceContent.salesOrderDetails.customer_order_Date}</dt>
          </dl>
        </div>
      </div>
      <div className="row p-0 m-0">
        <div className="col-sm-6">
          <dt className='erp-invoice-print-label-md-lg'>Bill To Details:</dt>
          <div className='erp-invoice-print-label-md-lg'>
            {SalesOrderServiceContent.customerDetails.customer}
          </div>
          <div className="erp-invoice-print-label">
            {SalesOrderServiceContent.customerDetails.address}
          </div>
          <dl className="row pb-0 mb-0">
            {/* <dt className='col-sm-2 erp-invoice-print-label-md-lg'>State:</dt>
            <dd className='col-sm-10 erp-invoice-print-label'> {SalesOrderServiceContent.customerDetails.state}</dd> */}
            <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
            <dd class="col-sm-10 m-0 p-0">
              <dl class="row m-0 p-0">
                <dt class="col-sm-4  erp-invoice-print-label"> {SalesOrderServiceContent.customerDetails.state}</dt>
                <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                <dt class="col-sm-3 erp-invoice-print-label">{SalesOrderServiceContent.customerDetails.customer_city_name}</dt>
              </dl>
            </dd>
          </dl>

          <dl className="row pb-0 mb-0">
            <dt className="col-sm-4 erp-invoice-print-label-md-lg">Contact Details:</dt>
            <dd className="col-sm-10 m-0 p-0">
              <dl className="row m-0 p-0">
                <dl className="col-sm-7 mb-0 pb-0">
                  <dt className="erp-invoice-print-label-md-lg">Person Name:</dt>
                  {/* This will only display once */}
                </dl>
                <dl className="col-sm-5 mb-0 pb-0">
                  <dt className="erp-invoice-print-label-md-lg">Contact No:</dt>
                  {/* This will only display once */}
                </dl>
              </dl>
              {contactDetails.map((contactData, index) => (
                <dl className="row m-0 p-0" key={index}>
                  <dl className="col-sm-7 mb-0 pb-0">
                    <dd className="erp-invoice-print-label">{contactData.cust_contact_person}</dd>
                  </dl>
                  <dl className="col-sm-5 mb-0 pb-0">
                    <dd className="erp-invoice-print-label">{contactData.cust_contact_no}</dd>
                  </dl>
                </dl>
              ))}
            </dd>
          </dl>
        </div>
        <div className="col-sm-6">
          <dt className='erp-invoice-print-label-md-lg'>Shipped To Details:</dt>
          <div className='erp-invoice-print-label-md-lg'>
            {SalesOrderServiceContent.consigneeDetails.name}
          </div>
          <div className="erp-invoice-print-label">
            {SalesOrderServiceContent.consigneeDetails.address}
          </div>
          <dl className="row pb-0 mb-0">
            <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
            <dd class="col-sm-10">
              <dl class="row m-0 p-0">
                <dt class="col-sm-4  erp-invoice-print-label"> {SalesOrderServiceContent.consigneeDetails.state}</dt>
                <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                <dt class="col-sm-3 erp-invoice-print-label">{SalesOrderServiceContent.consigneeDetails.consignee_city_name}</dt>
              </dl>
            </dd>
          </dl>
        </div>
      </div>

      <div className="row border-top border-dark p-0 m-0">
        <div className="col-sm-6">
          <dt className='erp-invoice-print-label-md-lg'>Our Details:</dt>
          <dl className="row m-0 p-0">
            <dd class="col-sm-3 erp-invoice-print-label-md-lg p-0">Branch Name:</dd>
            <dt class="col-sm-9  erp-invoice-print-label">{SalesOrderServiceContent.Ourdetails.company_branch_name}</dt>
          </dl>
          {/* <dl className="row m-0 p-0">
            <dd class="col-sm-3 erp-invoice-print-label-md-lg p-0">State:</dd>
            <dt class="col-sm-9  erp-invoice-print-label">{SalesOrderServiceContent.OurDetails.state_name}</dt>
          </dl> */}
          <dl className="row pb-0 mb-0">
            <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
            <dd class="col-sm-10 m-0 p-0">
              <dl class="row m-0 p-0">
                <dt class="col-sm-4  erp-invoice-print-label"> {SalesOrderServiceContent.Ourdetails.state_name}</dt>
                <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                <dt class="col-sm-3 erp-invoice-print-label">{SalesOrderServiceContent.Ourdetails.city_name}</dt>
              </dl>
            </dd>
          </dl>
        </div>
        <div className="col-sm-6">
          <dl className="row m-0 p-0 mt-3">
            <dd class="col-sm-3 erp-invoice-print-label-md-lg p-0">Department:</dd>
            <dt class="col-sm-9  erp-invoice-print-label">{SalesOrderServiceContent.salesOrderDetails.department_name}</dt>
          </dl>
        </div>
      </div>

    </div>
  )

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="container-invoice">
            <div id="content">
              <div className="invoice p-0">

                {/* <!--BILL HEADER--> */}
                <div className="row" style={{ padding: '0px 15px 0px' }}>
                  {invoiceHeader}
                </div>
                {/* <!--PARCEL DETAILS--> */}
                <div className="row" style={{ padding: '0px 15px 15px' }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 px-0" >
                        <div className="row p-0">
                          <div className="table-responsive">
                            <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                              {data.length !== 0 && (
                                <thead>
                                  <tr>
                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                    <th className="erp_invoice_table_th text-center col-6" rowspan={2}>Service Name(Technical Spect.)</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Duration</th>
                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Std. Hrs.</th>
                                    <th className="erp_invoice_table_th text-center col-1" rowspan={2}>HSN Code</th>
                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Rate</th>
                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Qty.</th>
                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Disc %</th>
                                    <th className="erp_invoice_table_th text-center" colSpan={3}>Taxes Extra</th>
                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Total</th>
                                  </tr>
                                  <tr  >
                                    <th className="erp_invoice_table_th text-center">CGST %</th>
                                    <th className="erp_invoice_table_th text-center">SGST %</th>
                                    <th className="erp_invoice_table_th text-center">IGST %</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody id="detailstableId">
                                {
                                  data.map((item, index) =>
                                    <>
                                      <tr className={`tblBodyTrHt-${index}`}>
                                        <td className="erp_invoice_table_td text-center">{index + 1}</td>
                                        <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}
                                          <span>
                                            {
                                              item.material_tech_spect === ''
                                                ? ''
                                                : <> ({[item.material_tech_spect].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")}) </>
                                            }
                                          </span>
                                        </td>
                                        <td className="erp_invoice_table_td">{item.process_duration}</td>
                                        <td className="erp_invoice_table_td text-end">{item.service_std_hrs}</td>
                                        <td className="erp_invoice_table_td">{item.hsn_code}</td>
                                        <td className="erp_invoice_table_td text-end">{parseFloat(item.rate).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                        <td className="erp_invoice_table_td text-end">{item.quantity}</td>
                                        <td className="erp_invoice_table_td text-end">{parseFloat(item.discount_percent).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                        <td className="erp_invoice_table_td text-end">{parseFloat(item.cgst_percent).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                        <td className="erp_invoice_table_td text-end">{parseFloat(item.sgst_percent).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                        <td className="erp_invoice_table_td text-end">{parseFloat(item.igst_percent).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                        <td className="erp_invoice_table_td text-end">{parseFloat(item.material_total_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                      </tr>
                                      {
                                        data.length - 1 === index ? <>
                                          {/* Add blank rows to fill the space */}

                                          <tr id='footerTr'>
                                            <td className="erp_invoice_table_td" colSpan={6}>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Basic Total :</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{basicTotalInWords}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{cgstTotalInWords}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{sgstTotalInWords}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{igstTotalInWords}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Taxable Total:</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{taxabletotalInWords}</dd>
                                              </div>
                                              <div className='row mt-1 m-0 p-0'>
                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Grand Total :</dt>
                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{grandtotalInWords}</dd>
                                              </div>
                                            </td>
                                            <td className="erp_invoice_table_td p-0 m-0" colSpan={6}>
                                              <table className="table p-0 m-0 table-borderless">
                                                <tbody>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Basic Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">CGST Total :</span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.cgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">SGST Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.sgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">IGST Total:</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.igst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount Amount</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.discount_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Taxable Total :</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.taxable_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className="border-bottom border-dark">
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Other Amount:</span> </td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.other_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                  <tr className='border-bottom-none'>
                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Grand Total: </span></td>
                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(SalesOrderServiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={12}>
                                              <div className='row p-0 m-0'>
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">Remark :</dt>
                                                <dd className="col-sm-10 erp-invoice-print-label"> {SalesOrderServiceContent.footer.remark}</dd>

                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">Other Terms & Conditions:</dt>
                                                <dd className="col-sm-10 erp-invoice-print-label">{SalesOrderServiceContent.footer.other_terms_conditions}</dd>
                                              </div>
                                              <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">SO Payment Terms: </dt>
                                                {paymentTermsList !== null ? (
                                                  <dd className="col-sm-10 m-0 p-0">
                                                    <dl className="row m-0 p-0" key={index}>
                                                      <dl className="col-sm-12 mb-0 pb-0">
                                                        <dd className="erp-invoice-print-label">
                                                          <dt className="erp-invoice-print-label">{paymentTermsList}</dt>
                                                        </dd>
                                                      </dl>
                                                    </dl>
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl>
                                              <dl className="row p-0 m-0 mt-1">
                                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">SO Terms Name:</dt>
                                                {SOTerms.length > 0 ? (
                                                  <dd className="col-sm-10 m-0 p-0">
                                                    {SOTerms.map((SOTerms, index) => (
                                                      <dl className="row m-0 p-0" key={index}>
                                                        <dl className="col-sm-12 mb-0 pb-0">
                                                          <dd className="erp-invoice-print-label">
                                                            {SOTerms.common_parameters_name !== undefined ? ` ${SOTerms.common_parameters_name}` : ''}
                                                          </dd>
                                                        </dl>
                                                      </dl>
                                                    ))}
                                                  </dd>
                                                ) : (
                                                  <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                )}
                                              </dl>

                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={12}>
                                              {/* Your invoice footer content */}
                                              {invoiceFooter}
                                            </td>
                                          </tr>

                                        </> : null
                                      }

                                    </>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
})

export default SalesOrderService;
