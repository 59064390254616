import { React, useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { CircularProgress } from "@material-ui/core";

// Imports React bootstrap
import { Table, Form, Modal } from "react-bootstrap";

// Imports for date picker and Searchable combo.
import DatePicker from 'react-datepicker';
import Select from 'react-select';

// Imports for common functionality components.
import ComboBox from "Features/ComboBox";
import ConfigConstants from "assets/Constants/config-constant";
import { resetGlobalQuery, globalQuery } from 'assets/Constants/config-constant';
import FrmValidations from 'FrmGeneric/FrmValidations';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import ExcelExport from "Features/Exports/ExcelExport";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";


function TShiftRosterNew() {
    // Config Constant
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_NAME, FINANCIAL_SHORT_NAME, UserName } = configConstants;

    // For Navigation.
    const navigate = useNavigate();

    // Ref Hooks for use common functions.
    const comboDataFunc = useRef();
    const validate = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const generateAutoNoAPiCall = useRef();

    // Some Helping Hooks & Functions.
    const [isLoading, setIsLoading] = useState(false);
    const [action_Label, setActionLabel] = useState('(Creation)');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const FnCloseSuccessModal = () => { setShowSuccessMsgModal(false); };
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const FnCloseErrModal = () => { setShowErrorMsgModal(false); };
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const goBack = () => { navigate('/DashBoard'); }

    // Common Data Hooks.
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [employeeProps, setEmployeeProps] = useState([]);             // Storing employee's type and group.
    const [allShiftsList, setAllShiftsList] = useState([]);              // For storing all Shifts.

    // Combo Box Hooks.
    const [employeeTypeOpts, setEmployeeTypeOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [shiftOpts, setShiftOpts] = useState([]);

    const [employeeSearchOpts, setemployeeForSearch] = useState([]);
    // Input Field Hooks.
    const [shift_roster_id, setShiftRosterId] = useState('')
    const [dt_process_date, setProcessDate] = useState(today);
    const [dt_from_date, setFromDate] = useState('');
    const [dt_to_date, setToDate] = useState('');
    const [cmb_employee_type, setEmployeeType] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [txt_employee_code, setEmployeeCode] = useState();
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_previous_shift_id, setPreviousShiftId] = useState('');
    const [cmb_1to15_shift, set1to15ShiftId] = useState('');
    const [cmb_16to31_shift, set16to31ShiftId] = useState('');
    const [cmb_1to31_shift, set1to31ShiftId] = useState('');
    const [chk_rosterType, setRosterType] = useState("Monthly");

    // Hooks for the table data.
    const [shiftRosterHeaderData, setShiftRosterHeaderData] = useState([]);
    const [shiftRosterDetails, setShiftRosterDetails] = useState([]);
    const[emplcode,setEmplCode]=useState('');
    // Ref Hooks for the Searchable combo box.
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeTypesComboRef = useRef(null);
    let previousShiftsComboRef = useRef(null);
    let employeeComboRef = useRef(null);
    let shift1to15ComboRef = useRef(null);
    let shift16to31ComboRef = useRef(null);
    let shift1to31ComboRef = useRef(null);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnGenerateRosterTransNo();
            await FnFillCombos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnGenerateRosterTransNo = async () => {
        try {
            const transactionId = await generateAutoNoAPiCall.current.generateTAutoNo("ht_shift_roster_new", "roster_id", "", 'SR', "5");
            setShiftRosterId(transactionId);
            return transactionId;
        } catch (error) {
            navigate('/Error')
        }
    }

    const FnFillCombos = async () => {
        try {
            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeTypeGroup')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    setEmployeeTypeOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                });

            // Load Shift's
            resetGlobalQuery();
            globalQuery.columns = ["shift_id", "shift_name", "employee_type_group", "shift_name", "end_time"];
            globalQuery.table = "cm_shift"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdShifts => {
                    rcvdShifts = rcvdShifts.map(prop => ({ ...prop, value: prop.shift_id, label: prop.shift_name }));
                    setAllShiftsList(rcvdShifts);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnCombosOnChange = async (comboName, inputVal) => {
        try {
            setIsLoading(true);

            switch (comboName) {
                case 'ProcessDate':
                    const formattedProcessDate = inputVal ? inputVal.toLocaleDateString('en-CA') : '';
                    if (formattedProcessDate !== '') { document.getElementById('error_dt_process_date').style.display = 'none'; }
                    setProcessDate(formattedProcessDate)
                    break;

                case 'FromDate':
                    const formattedFromDate = inputVal ? inputVal.toLocaleDateString('en-CA') : '';
                    if (formattedFromDate !== '') { document.getElementById('error_dt_from_date').style.display = 'none'; }
                    setFromDate(formattedFromDate);
                    // If the start date is set after the end date, clear the end date
                    if (dt_to_date && new Date(formattedFromDate) > new Date(dt_to_date)) {
                        setToDate('');
                    }
                    break;

                case 'ToDate':
                    const formattedToDate = inputVal ? inputVal.toLocaleDateString('en-CA') : '';
                    if (formattedToDate !== '') { document.getElementById('error_dt_to_date').style.display = 'none'; }
                    setToDate(formattedToDate)
                    break;

                case "Department":
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    await FnGetEmployeeByPrevShift();
                    break;

                case "SubDepartment":
                    await FnGetEmployeeByPrevShift();
                    break;

                case "EmployeeType":
                    let selectedEmployeeType = employeeTypesComboRef.current.field_name
                    // Load the shifts as per the selected employee type.
                    const filteredShift = allShiftsList.filter(shift => (!selectedEmployeeType || shift.employee_type_group === selectedEmployeeType));
                    setShiftOpts(filteredShift);
                    setPreviousShiftId('');
                    previousShiftsComboRef.current = null;

                    set1to15ShiftId('');
                    shift1to15ComboRef.current = null;

                    set16to31ShiftId('');
                    shift16to31ComboRef.current = null;

                    set1to31ShiftId('');
                    shift1to31ComboRef.current = null;

                    await FnGetEmployeeByPrevShift();
                    break;

                case 'Employee':
                    let selectedEmployee = employeeComboRef.current?.employee_id;
                    if (selectedEmployee && selectedEmployee !== 0) {
                        let emplData = employeeOpts.find(option => option.employee_id == selectedEmployee);
                        setEmployeeCode(emplData?.employee_code || '');
                    } else {
                        setEmployeeCode('');
                    }

                    if (shiftRosterDetails.length > 0) { FnGenerateShiftRosterTbl(); }
                    break;

                // case 'EmployeeCode':
                //     if (inputVal !== '' && employeeOpts.length > 0) {
                //         let emplData = employeeOpts.find(option => option.employee_code == inputVal);
                //         setEmployeeId(emplData?.employee_id || '');
                //         employeeComboRef.current = emplData;
                //     } else {
                //         setEmployeeId('');
                //         setEmployeeCode('');
                //     }
                //     if (shiftRosterDetails.length > 0) { FnGenerateShiftRosterTbl(); }
                //     break;
                case 'EmployeeCode':
                    setEmployeeCode(employeeComboRef.current.employee_code);
                    break;
                   
                case 'EmplCode':
                    debugger
                    setEmployeeCode(inputVal);
                    let findEmployee = null;

                    if (inputVal.length >= 4) {
                        findEmployee = employeeSearchOpts.find((employee) => {
                            return employee.employee_code === inputVal ||
                                employee.field_name.toLowerCase().includes(inputVal.toLowerCase())
                                || employee.old_employee_code.toLowerCase().includes(inputVal.toLowerCase());
                        });
                    }

                    if (findEmployee) {
                        setEmployeeId(findEmployee.field_id);
                        // setEmployeeCode(findEmployee.employee_code);
                        employeeComboRef.current = {
                            employee_id: findEmployee.field_id,
                            employee_code: findEmployee.employee_code,
                         
                        };
                    } else {
                        setEmployeeId('');
                        employeeComboRef.current = {
                            employee_id: '',
                            employee_code: '',
                            
                        };
                    }
                    break;
                case 'RoasterType':
                    set1to31ShiftId('');
                    shift1to31ComboRef.current = null;

                    set1to15ShiftId('');
                    shift1to15ComboRef.current = null;

                    set16to31ShiftId('');
                    shift16to31ComboRef.current = null;

                default:
                    break;
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const getLastMonthAndYear = (date) => {
        const givenDate = new Date(date);
        // Create a new Date object for the first day of the current month and subtract one day
        const lastDayOfLastMonth = new Date(givenDate.getFullYear(), givenDate.getMonth(), 0);

        // Extract the month and year
        const month = lastDayOfLastMonth.getMonth() + 1; // Months are zero-based in JavaScript
        const year = lastDayOfLastMonth.getFullYear();

        return {
            month: month,
            year: year
        };
    }

    // Get the employee list for the shift Roster.
    const FnGetEmployeeByPrevShift = async () => {
        
        try {
            // Get the shift_Roster data by using previous shift.
            let isValidFilters = FnValidateFieldsForLoadEmployeeList();
            if (isValidFilters) {
                setIsLoading(true);

                let selectedDepartment = departmentComboRef.current?.department_id;
                let selectedSubDepartment = subDepartmentComboRef.current?.department_id;

                let selectedEmplType = employeeTypesComboRef.current.field_name
                let previousShiftId = previousShiftsComboRef.current?.shift_id;
                const { month, year } = getLastMonthAndYear(dt_from_date);

                let requestJson = { 'EmployeeShiftRosterData': {} };
                requestJson.EmployeeShiftRosterData = {
                    employee_type: selectedEmplType,
                    department_id: (selectedDepartment === 0 || selectedDepartment === '') ? null : selectedDepartment,
                    sub_department_id: (selectedSubDepartment === 0 || selectedSubDepartment === '') ? null : selectedSubDepartment,
                    attendance_year: year,
                    attendance_month: month,
                    shift_id: previousShiftId,
                    company_id: COMPANY_ID,
                }
                const formData = new FormData();
                formData.append(`prevShiftDetails`, JSON.stringify(requestJson))
                const requestOptions = { method: 'POST', body: formData };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShiftRosterNew/FnShowShiftRosterEmployeeData`, requestOptions);
                let response = await apiCall.json();
                setemployeeForSearch(response);
                if (response.length > 0) {
                    response = response.map(prop => ({ ...prop, employee_id: prop.field_id, value: prop.field_id, label: prop.field_name }));
                    response.unshift({ employee_id: 0, value: 0, label: 'All' });
                    setEmployeeOpts(response);
                    setEmployeeId(0);
                    setEmployeeCode('');
                    employeeComboRef.current = { employee_id: 0, value: 0, label: 'All' };
                } else {
                    setEmployeeOpts([]);
                    setEmployeeId('');
                    setEmployeeCode('');
                    employeeComboRef.current = null;
                }
                setIsLoading(false);
            }

        } catch (error) {
            console.log("error on get employee list for roster: ", error)
            navigate('/Error')
        }
    }


    // ************************ Create a Roster Table Functionality Starts***************************
    const FnGenerateShiftRosterTbl = async () => {
        debugger
        try {
            const validateForm = await validate.current.validateForm('shifRosterFormId');
            if (!FnValidateFieldsForLoadEmployeeList() || !validateForm || !FnValidateFieldsForGenrateRosterTbl()) return;

            let selectedEmplId = employeeComboRef.current?.employee_id;

            // Creating the DatesColumns
            const startDate = new Date(dt_from_date);
            const endDate = new Date(dt_to_date);
            const datesCols = [];
            let currentDate = startDate;
            while (currentDate <= endDate) {
                const header = `${currentDate.getDate()}/${currentDate.getMonth() + 1}`;
                const accessor = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
                const day = currentDate.getDate(); // 1 for Sunday, 2 for Monday, ..., 7 for Saturday
                const days = currentDate.toLocaleString('en-US', { weekday: 'short' });
                const fullDaysName = currentDate.toLocaleString('en-US', { weekday: 'long' });
                datesCols.push({ header, accessor, days, fullDaysName, day });
                currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
            }

            // Prepare shift roster details
            let shiftRosterDtls = [];
            if (selectedEmplId === 0) {
                shiftRosterDtls = employeeOpts.filter(obj => obj.employee_id !== 0);
            } else {
                shiftRosterDtls = employeeOpts.filter(employee => parseInt(employee.employee_id) === parseInt(selectedEmplId));
            }

            const { shift_id: shift1To15_id, shift_name: shift1To15_name } = shiftOpts.find(option => option.value == shift1to15ComboRef.current?.shift_id) || {};
            const { shift_id: shift16To31_id, shift_name: shift16To31_name } = shiftOpts.find(option => option.value == shift16to31ComboRef.current?.shift_id) || {};
            const { shift_id: shift1To31_id, shift_name: shift1To31_name } = shiftOpts.find(option => option.value == shift1to31ComboRef.current?.shift_id) || {};

            // Preparing the Data For ShiftRoaster Table.
            shiftRosterDtls
                .forEach(detail => {
                    datesCols.forEach(columnData => {
                        let shiftOption, shiftValue;
                        if (shift1To31_id) {
                            shiftOption = shift1To31_name;
                            shiftValue = shift1To31_id;
                        } else if (columnData.day >= 1 && columnData.day <= 15) {
                            shiftOption = shift1To15_name;
                            shiftValue = shift1To15_id;
                        } else {
                            shiftOption = shift16To31_name;
                            shiftValue = shift16To31_id;
                        }
                        detail[columnData.header] = {
                            Name: shiftOption,
                            id: shiftValue
                        };
                    });
                    // detail.department_name = departmentName;
                });

            // Update state or perform further actions
            setShiftRosterHeaderData([...datesCols]);
            setShiftRosterDetails(shiftRosterDtls);

        } catch (error) {
            console.log("error on creating roster table: ", error)
            navigate('/Error')
        }
    }

    const renderShiftRosterTable = useMemo(() => {
        if (shiftRosterDetails.length !== 0) {
            return (
                <>
                    <Table className="erp_table" id="shiftRoasterDtlsTbl" bordered striped>
                        <thead className="erp_table_head">
                            <tr id='headerRowId'>
                                <th className="erp_table_th" rowSpan={2}>Sr. No.</th>
                                <th className="erp_table_th" rowSpan={2}>Employee Code</th>
                                <th className="erp_table_th" rowSpan={2}>Employee Name</th>
                                <th className="erp_table_th" rowSpan={2}>Department Name</th>

                                {/* Date columns */}
                                {shiftRosterHeaderData.map((header, indexOfItem) => (
                                    <th key={indexOfItem} className="erp_table_th" id={`header_${indexOfItem}`}>
                                        {header.header}
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                {shiftRosterHeaderData.map((header, indexOfItem) => (
                                    <th key={indexOfItem} className="erp_table_th" id={`header_${header}`}>
                                        {header.days}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {shiftRosterDetails?.map((currentObj, rowIndex) => (
                                <tr key={`row_${rowIndex}`} className="sticky-column">
                                    <td className='erp_table_td'>{rowIndex + 1}</td>
                                    <td className='erp_table_td'>{currentObj.employee_code}</td>
                                    <td className='erp_table_td'>{currentObj.field_name}</td>
                                    <td className='erp_table_td'>{currentObj.department_name}</td>
                                    {/* Date cells */}

                                    {shiftRosterHeaderData.map((key, index) => (
                                        <td key={key.header} className='erp_table_td'>
                                            {currentObj[key.header]?.Name}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            );
        }
    }, [shiftRosterDetails]);


    // ************************ Create a Roster Table Functionality Starts***************************



    // ************************    // Common Functions for the validations.
    const isEmpty = str => {
        if (typeof str === 'number' && Number.isInteger(str)) { str = str.toString(); }
        return str === undefined || str === null || str.trim() !== '';
    };


    const FnValidateFieldsForLoadEmployeeList = () => {
        if (!isEmpty(dt_from_date)) {
            $('#error_dt_from_date').show();
            $('#error_dt_from_date').text("Please Select the from date...!");
            return false;
        }

        if (!isEmpty(dt_to_date)) {
            $('#error_dt_to_date').show();
            $('#error_dt_to_date').text("Please Select the To date...!");
            return false;
        }

        if (!isEmpty(previousShiftsComboRef.current?.shift_id || '')) {
            $('#error_cmb_previous_shift_id').show();
            $('#error_cmb_previous_shift_id').text("Please Select the Previous Shift...!");
            return false;
        }

        return true;
    }

    const FnValidateFieldsForGenrateRosterTbl = () => {
        if (!FnValidateFieldsForLoadEmployeeList()) {
            return false;
        }

        if (chk_rosterType === 'Monthly') {
            if (!isEmpty(shift1to31ComboRef.current?.shift_id || '')) {
                $('#error_cmb_1to31_shift').show();
                $('#error_cmb_1to31_shift').text("Please Select the Shift...!");
                return false;
            }
        } else if (chk_rosterType === '15 Days') {
            if (!isEmpty(shift1to15ComboRef.current?.shift_id || '')) {
                $('#error_cmb_1to15_shift').show();
                $('#error_cmb_1to15_shift').text("Please Select the Shift...!");
                return false;
            }
            if (!isEmpty(shift16to31ComboRef.current?.shift_id || '')) {
                $('#error_cmb_16to31_shift').show();
                $('#error_cmb_16to31_shift').text("Please Select the Shift...!");
                return false;
            }
        }
        return true;
    }

    const FnValidateFields = async () => {
        setShiftRosterDetails([]);
        setShiftRosterHeaderData([]);
        await validate.current.validateFieldsOnChange('shifRosterFormId');

        if (isEmpty(dt_from_date)) { $('#error_dt_from_date').hide(); }
        if (isEmpty(dt_to_date)) { $('#error_dt_to_date').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type').hide(); }
        if (isEmpty(previousShiftsComboRef.current?.shift_id || '')) { $('#error_cmb_previous_shift_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }

        if (isEmpty(shift1to31ComboRef.current?.shift_id || '')) { $('#error_cmb_1to31_shift').hide(); }
        if (isEmpty(shift1to15ComboRef.current?.shift_id || '')) { $('#error_cmb_1to15_shift').hide(); }
        if (isEmpty(shift16to31ComboRef.current?.shift_id || '')) { $('#error_cmb_16to31_shift').hide(); }

    }

    const FnClearFields = async () => {
        setShiftRosterDetails([]);
        setShiftRosterHeaderData([]);
        await FnGenerateRosterTransNo();
        setProcessDate(today);
        setFromDate('');
        setToDate('');
        employeeTypesComboRef.current = null;
        setEmployeeType('');
        employeeComboRef.current = null;
        setEmployeeId('');
        setEmployeeCode('');
        departmentComboRef.current = null;
        setDepartmentId('');
        subDepartmentComboRef.current = null;
        setSubDepartmentId('');
        previousShiftsComboRef.current = null;
        setPreviousShiftId('');
        setRosterType('Monthly');
        shift1to15ComboRef.current = null;
        set1to15ShiftId('');
        shift16to31ComboRef.current = null;
        set16to31ShiftId('');
        shift1to31ComboRef.current = null;
        set1to31ShiftId('');
    }

    // ************** Save the Roster Details. ************************************
    const FnSaveRosterDtls = async () => {
        try {
            const validateForm = await validate.current.validateForm('shifRosterFormId');
            if (!FnValidateFieldsForLoadEmployeeList() || !validateForm || !FnValidateFieldsForGenrateRosterTbl()) return;
            if (shiftRosterDetails.length === 0) {
                setErrMsg('No Roster Details For Save...!')
                setShowErrorMsgModal(true)
                return;
            }

            setIsLoading(true);
            const givenDate = new Date(dt_from_date);
            // Extract the month and year
            const month = givenDate.getMonth() + 1; // Months are zero-based in JavaScript
            const year = givenDate.getFullYear();

            let selectedEmployeeType = employeeTypesComboRef.current.field_name

            let requestJson = { 'ShiftRosterMasterData': {}, 'shiftDetailsData': [] };
            requestJson.ShiftRosterMasterData = {
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                roster_id: shift_roster_id,
                process_date: dt_process_date,
                employee_type: selectedEmployeeType,
                attendance_month: month,
                attendance_year: year,
                financial_year: FINANCIAL_SHORT_NAME,
                created_by: UserName,
            }
            requestJson.shiftDetailsData = shiftRosterDetails;
            console.log('JSON For Save: ', requestJson);

            const formData = new FormData();
            formData.append(`shiftRosterDetails`, JSON.stringify(requestJson))
            const requestOptions = { method: 'POST', body: formData };
            const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShiftRosterNew/FnAddUpdateShiftRosterDetails`, requestOptions)
            const response = await apicall.json();

            if (response.success === 0) {
                setErrMsg(response.error)
                setShowErrorMsgModal(true)

            } else {
                setSuccMsg(response.message)
                setShowSuccessMsgModal(true);
                FnClearFields();
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error on save roster details: ", error)
            navigate('/Error')
        }
    }

    // ****************** Export2Excel Functionality Start's ********************************************
    const FnExportToExcel = async () => {
        try {
            if (shiftRosterDetails.length > 0) {
                setIsLoading(true);

                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

                // Excel Headers;
                jsonToExportExcel['headings'] = {
                    'ReportName': `Shift Roster Details`,
                    'CompanyName': COMPANY_NAME,
                    'CompanyAddress': sessionStorage.getItem('companyAddress'),
                }

                // Filters;
                let appliedFilters = {
                    0: "Shift Roster Id :" + $('#shift_roster_id').val(),
                    1: "Shift Roster Date :" + dt_process_date,
                    2: 'From Date : ' + dt_from_date,
                    3: 'To Date : ' + dt_to_date,
                    4: 'Departement: ' + (departmentComboRef.current?.department_name || ''),
                    5: 'Sub Departement: ' + (subDepartmentComboRef.current?.department_name || ''),
                    6: 'Employee Type: ' + (employeeTypesComboRef.current?.field_name || ''),
                    7: 'From Shift : ' + previousShiftsComboRef.current?.shift_name,
                    8: 'Roaster Type: ' + (chk_rosterType == 'Monthly' ? 'Monthly' : '15 Days')
                };

                if (chk_rosterType === 'Monthly') {
                    appliedFilters[Object.keys(appliedFilters).length] = `1 to 31 Shift : ${shift1to31ComboRef.current?.shift_name}`
                } else if (chk_rosterType === '15 Days') {
                    appliedFilters[Object.keys(appliedFilters).length] = `1 to 15 Shift : ${shift1to15ComboRef.current?.shift_name}`
                    appliedFilters[Object.keys(appliedFilters).length] = `16 to 31 Shift : ${shift16to31ComboRef.current?.shift_name}`
                }
                jsonToExportExcel['filtrKeyValue'] = appliedFilters;

                // Preparing the Columns for the Tbl
                shiftRosterHeaderData.forEach((column, index) => {
                    if (index === 0) {
                        jsonToExportExcel.columns.push(
                            { "Headers": 'Sr No.', "accessor": 'sr_no' },
                            { "Headers": 'Employee Code', "accessor": 'employee_code' },
                            { "Headers": 'Employee Name', "accessor": 'field_name' },
                            { "Headers": 'Department Name', "accessor": 'department_name' }
                        );
                    }
                    jsonToExportExcel.columns.push({ "Headers": `${column.header} ${column.days}`, "accessor": column.header });
                });

                for (let index = 0; index < shiftRosterDetails.length; index++) {
                    const detail = shiftRosterDetails[index]; // Get the current detail

                    const exportRoster = {
                        sr_no: index + 1,
                        field_id: detail.field_id,
                        field_name: detail.field_name,
                        employee_code: detail.employee_code,
                        department_name: detail.department_name
                    };

                    Object.keys(detail).forEach(key => {
                        if (key.includes('/')) {
                            exportRoster[key] = detail[key].Name;
                        }
                    });

                    jsonToExportExcel['allData'][index] = exportRoster;
                }

                console.log("jsonToExportExcel: ", jsonToExportExcel);
                exlsExp.current.excel(jsonToExportExcel, `Shift_Roster_Details`);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <FrmValidations ref={validate} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {
                    isLoading
                        ? <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div>
                        </div>
                        : null
                }

                <div className='card p-1 mt-3'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Shift Roster {action_Label} </label>
                    </div>


                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <form id="shifRosterFormId">
                            <div className='row p-1'>
                                {/* First Column Start */}
                                <div className='col-sm-6 erp_form_col_div'>
                                    <div className="row">
                                        <div className="col-sm-4"> <Form.Label className="erp-form-label">Shift Roster Id & Date </Form.Label> </div>
                                        <div className="col-sm-8">
                                            <div className="row">
                                                <div className="col-sm-6 pe-md-1">
                                                    <Form.Control type="text" id='shift_roster_id' className="erp_input_field mt-1" value={shift_roster_id} onChange={(e) => { setShiftRosterId(e.target.value); FnValidateFields() }} disabled />
                                                    <MDTypography variant="button" id="error_shift_roster_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-sm-6 ps-md-1">
                                                    <DatePicker
                                                        selected={dt_process_date}
                                                        id="dt_process_date"
                                                        onChange={(date) => { FnCombosOnChange('ProcessDate', date); FnValidateFields(); }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                        className="erp_input_field optional"
                                                        selectsEnd
                                                        // startDate={dt_from_date ? new Date(dt_from_date) : null}
                                                        // endDate={dt_process_date ? new Date(dt_process_date) : null}
                                                        // minDate={dt_from_date ? new Date(dt_from_date) : null}
                                                        maxDate={new Date()}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_process_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4"> <Form.Label className="erp-form-label"> From Date - To Date<span className="required">*</span></Form.Label> </div>
                                        <div className="col-sm-8">
                                            <div className="row">
                                                <div className="col-sm-6 pe-md-1">
                                                    <DatePicker
                                                        selected={dt_from_date}
                                                        id="dt_from_date"
                                                        onChange={(date) => { FnCombosOnChange('FromDate', date); FnValidateFields(); }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                        className="erp_input_field optional"
                                                        selectsStart
                                                        startDate={dt_from_date ? new Date(dt_from_date) : null}
                                                        endDate={dt_to_date ? new Date(dt_to_date) : null}
                                                    // maxDate={new Date()}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-sm-6 ps-md-1">
                                                    <DatePicker
                                                        selected={dt_to_date}
                                                        id="dt_to_date"
                                                        onChange={(date) => { FnCombosOnChange('ToDate', date); FnValidateFields(); }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                        className="erp_input_field optional"
                                                        selectsEnd
                                                        startDate={dt_from_date ? new Date(dt_from_date) : null}
                                                        endDate={dt_to_date ? new Date(dt_to_date) : null}
                                                        minDate={dt_from_date ? new Date(dt_from_date) : null}
                                                    // maxDate={new Date()}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-1">
                                        <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                        <div className='col'>
                                            <Select ref={departmentComboRef}
                                                options={departmentOpts}
                                                inputId="cmb_department_id"
                                                value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                                onChange={(selectedOpt) => {
                                                    setDepartmentId(selectedOpt.value);
                                                    departmentComboRef.current = selectedOpt;
                                                    FnCombosOnChange("Department");
                                                    FnValidateFields();
                                                }}
                                                placeholder="Search for a department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                        <div className='col'>
                                            <Select ref={subDepartmentComboRef}
                                                options={subDepartmentOpts}
                                                inputId="cmb_sub_department_id"
                                                value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                                onChange={(selectedOpt) => {
                                                    setSubDepartmentId(selectedOpt.value);
                                                    subDepartmentComboRef.current = selectedOpt;
                                                    FnCombosOnChange("SubDepartment");
                                                    FnValidateFields();
                                                }}
                                                placeholder="Search for a sub-department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4"> <Form.Label className="erp-form-label"> Employee Type<span className="required">*</span></Form.Label> </div>
                                        <div className='col-12 col-sm-8'>
                                            <Select ref={employeeTypesComboRef}
                                                options={employeeTypeOpts}
                                                inputId="cmb_employee_type"
                                                value={employeeTypeOpts.find(option => option.value == cmb_employee_type) || null}
                                                onChange={(selectedOpt) => {
                                                    setEmployeeType(selectedOpt.value);
                                                    employeeTypesComboRef.current = selectedOpt;
                                                    FnCombosOnChange('EmployeeType');
                                                    FnValidateFields();
                                                }}
                                                placeholder="Search for a employee type..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4"> <Form.Label className="erp-form-label"> Previous Shift<span className="required">*</span> </Form.Label> </div>
                                        <div className='col-12 col-sm-8'>
                                            <Select ref={previousShiftsComboRef}
                                                options={shiftOpts}
                                                inputId="cmb_previous_shift_id"
                                                value={shiftOpts.find(option => option.value == cmb_previous_shift_id) || null}
                                                onChange={(selectedOpt) => {
                                                    setPreviousShiftId(selectedOpt.value);
                                                    previousShiftsComboRef.current = selectedOpt;
                                                    FnGetEmployeeByPrevShift();
                                                    FnValidateFields();
                                                }}
                                                placeholder="Search for a previous shift..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_previous_shift_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                </div>
                                {/* First Column Ends */}

                                {/* Second Column Starts */}
                                <div className='col-sm-6 erp_form_col_div'>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Employee<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-2 pe-md-1">
                                            <Form.Control type="text" id="txt_employee_code" className="erp_input_field optional"
                                                value={txt_employee_code}
                                                // onChange={(e) => { setEmployeeCode(e.target.value); }}
                                                onChange={(e) => {
                                                    FnCombosOnChange('EmplCode', e.target.value);
                                                    FnValidateFields();
                                                }}
                                                onBlur={(e) => { FnCombosOnChange('EmployeeCode', e.target.value); FnValidateFields(); }}
                                                optional={`${cmb_employee_id === 0 ? "optional" : ''}`} />
                                            <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className="col-sm-6 ps-md-1">
                                            <Select ref={employeeComboRef}
                                                options={employeeOpts}
                                                inputId="cmb_employee_id"
                                                value={employeeOpts.find(option => option.value == cmb_employee_id)}
                                                onChange={(selectedOpt) => {
                                                    setEmployeeId(selectedOpt.value);
                                                    employeeComboRef.current = selectedOpt;
                                                    FnCombosOnChange('Employee')
                                                    FnValidateFields();
                                                }}
                                                // onBlur={(selectedOpt) => {
                                                //     // setEmployeeId(selectedOpt.value);
                                                //     // employeeComboRef.current = selectedOpt;
                                                //     FnCombosOnChange('Employee')
                                                //     // FnValidateFields();
                                                // }}
                                                placeholder="Search for a employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Roster Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Monthly" type="radio" lbl="Monthly" value="Monthly" name="chk_rosterType" checked={chk_rosterType === "Monthly"}
                                                    onClick={() => { setRosterType("Monthly"); FnCombosOnChange('RoasterType'); FnValidateFields(); }}
                                                /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="15 Days" type="radio" lbl="15 Days" value="15 Days" name="chk_rosterType" checked={chk_rosterType === "15 Days"}
                                                    onClick={() => { setRosterType("15 Days"); FnCombosOnChange('RoasterType'); FnValidateFields(); }}
                                                /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    {chk_rosterType === 'Monthly' ? (
                                        <div className="row">
                                            <div className="col-sm-4"> <Form.Label className="erp-form-label">1 to 31 Shift<span className="required">*</span></Form.Label>  </div>
                                            <div className="col">
                                                <Select ref={shift1to31ComboRef}
                                                    options={shiftOpts}
                                                    inputId="cmb_1to31_shift"
                                                    value={shiftOpts.find(option => option.value == cmb_1to31_shift) || null}
                                                    onChange={(selectedOpt) => {
                                                        set1to31ShiftId(selectedOpt.value);
                                                        shift1to31ComboRef.current = selectedOpt;
                                                        FnValidateFields();
                                                        if (shiftRosterDetails.length > 0) { FnGenerateShiftRosterTbl(); }
                                                    }}
                                                    placeholder="Search shift for a 1-31..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_1to31_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col-sm-4"> <Form.Label className="erp-form-label">1 to 15 Shift<span className="required">*</span></Form.Label> </div>
                                                <div className="col">
                                                    <Select ref={shift1to15ComboRef}
                                                        options={shiftOpts}
                                                        inputId="cmb_1to15_shift"
                                                        value={shiftOpts.find(option => option.value == cmb_1to15_shift) || null}
                                                        onChange={(selectedOpt) => {
                                                            set1to15ShiftId(selectedOpt.value);
                                                            shift1to15ComboRef.current = selectedOpt;
                                                            FnValidateFields();
                                                            if (shiftRosterDetails.length > 0) { FnGenerateShiftRosterTbl(); }
                                                        }}
                                                        placeholder="Search shift for a 1-15..."
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select"
                                                        styles={{
                                                            option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                            singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                            input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_1to15_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-4"> <Form.Label className="erp-form-label">16 to 31 Shift<span className="required">*</span></Form.Label> </div>
                                                <div className="col">
                                                    <Select ref={shift16to31ComboRef}
                                                        options={shiftOpts}
                                                        inputId="cmb_16to31_shift"
                                                        value={shiftOpts.find(option => option.value == cmb_16to31_shift) || null}
                                                        onChange={(selectedOpt) => {
                                                            set16to31ShiftId(selectedOpt.value);
                                                            shift16to31ComboRef.current = selectedOpt;
                                                            FnValidateFields();
                                                            if (shiftRosterDetails.length > 0) { FnGenerateShiftRosterTbl(); }
                                                        }}
                                                        placeholder="Search shift for a 16-31..."
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select"
                                                        styles={{
                                                            option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                            singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                            input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_16to31_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="row">
                                        <div className="text-end">
                                            <MDButton type="button" id="show_button" className="erp-gb-button " variant="button"
                                                onClick={() => FnGenerateShiftRosterTbl()}
                                                fontWeight="regular">Generate</MDButton>
                                        </div>
                                    </div>

                                </div>
                                {/* Second Column Ends */}


                            </div>
                        </form>

                        {
                            shiftRosterDetails.length > 0
                                ? <>
                                    <hr />
                                    <div className="card">
                                        <div className='card-header py-0 text-center'>
                                            <label className='erp-form-label-lg'>Shift Roster Details </label>
                                        </div>
                                        <div className="card-body py-0">
                                            <div className='row table-responsive' style={{ maxHeight: '450px' }}>
                                                {renderShiftRosterTable}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }

                        <hr />
                        <div className="row">
                            <div className="text-center">
                                <MDButton className="erp-gb-button" variant="button" id='back_Button' fontWeight="regular"
                                    onClick={() => goBack()}>Back</MDButton>


                                {
                                    shiftRosterDetails.length > 0
                                        ? <>
                                            <MDButton className="erp-gb-button ms-1" variant="button" id='save_Button' fontWeight="regular"
                                                onClick={() => FnSaveRosterDtls()}>Save</MDButton>

                                            <MDButton className="erp-gb-button ms-1" variant="button" id='export_Button' fontWeight="regular"
                                                onClick={() => FnExportToExcel()}>Export</MDButton>
                                        </>
                                        : null
                                }

                            </div>
                        </div>


                    </div>
                    {/* Card's Body Ends*/}


                </div>

                <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => FnCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>

        </>
    )
}

export default TShiftRosterNew;