import { useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Modal from 'react-bootstrap/Modal';
import Tooltip from "@mui/material/Tooltip";

// Imports React bootstra
import Form from 'react-bootstrap/Form';

// Icons
import { MdRefresh } from "react-icons/md";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import FrmProductionSectionEntry from "Masters/MProductionSection/FrmProductionSectionEntry";
import DepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import { Accordion, Table } from "react-bootstrap";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import ProfitCenterEntry from "Masters/ProfitCenter/ProfitCenterEntry";
import { CircularProgress } from "@material-ui/core";


function FrmProductionSubSectionEntry(props) {
    //changes by ujjwala on 27/12/2024
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { productionSubsectionId = 0, keyForViewUpdate, compType } = state || {}

    // Use Refs to call external functions
    const validateNumberDateInput = useRef();
    const comboDataAPiCall = useRef();
    const combobox = useRef();
    const validate = useRef();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [subProd_profitCenterOption, setsubProdProfitCenterOption] = useState([]);
    const [txt_sub_prod_profitCenter, set_sub_prod_ProfitCenter] = useState('');

    const [txt_production_sub_section_id, setproduction_sub_section_id] = useState(productionSubsectionId);
    const [txt_sub_production_section_id, set_sub_production_section_id] = useState('');
    const [txt_sub_prod_parentDepartment, set_sub_prod_parentDepartment] = useState('');
    const [cmb_sub_prod_plant_id, set_sub_prod_PlantName] = useState('');
    const [txt_SubSectionName, setSubSectionName] = useState('');
    const [txt_sub_section_short_type, setSubSectionShortType] = useState('');
    const [txt_sub_prod_shortName, set_sub_prod_ShortName] = useState();
    const [txt_sub_prod_costCenter, set_sub_prod_CostCenter] = useState('');
    const [txt_sub_prodSectionHeadID, setsubSectionHeadID] = useState();
    const [txt_sub_prodSectionSubHeadID, setsubSectionSubHeadID] = useState();
    const [txt_sub_prodSectionStaffStrength, setSubProdSectionStaffStrength] = useState(1);
    const [txt_sub_prodSection_std_worker_strength, setSubProdSectionWorkerStrength] = useState(1);
    // const [txt_SectionName, setSectionName] = useState('');
    const [txt_SectionShortName, setSectionShortName] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    //option Box
    const [storeLocationData, setStoreLocationData] = useState([])
    const [rowCount, setRowCount] = useState(1)
    const [godownNameOptions, setGodownNameOptions] = useState([]);
    const [godownShortNames, setGodownShortNames] = useState([]);
    const [sectionOption, setSectionOption] = useState([]);
    const [subProd_parentDepartOption, setsubProdParentDepartOption] = useState([]);
    const [subProd_branchHeadOptions, setsubProdBranchHeadOptions] = useState([]);
    const [sectionSubHeadOptions, setsubProdsectionSubHeadOptions] = useState([]);
    const [subProd_costCenterOption, setsubProdCostCenterOption] = useState([]);
    const [subProd_plantOptions, setsubProdPlantOptions] = useState([]);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MProductionSubSection/FrmProductionSubSectionListing`)


        }
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');


    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true)
            await ActionType()
            await FillComobos();
            if (productionSubsectionId !== 0) {
                await FnCheckUpdateResponce()
            }
            setIsLoading(false)
        }
        loadDataOnload();
    }, []);

    const addProductionSubSection = async () => {
        try {
            setIsLoading(true)
            const checkIsValidate = await validate.current.validateForm("productionSubSectionformid");
            if (checkIsValidate === true) {
                let sectionname = $('#txt_sub_production_section_id').find(":selected").text();

                const json = { 'ProductionSubSectionJson': {}, 'GodownMappingData': [], 'commonIds': { 'company_id': COMPANY_ID, 'production_sub_section_id': productionSubsectionId } }

                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    production_sub_section_id: productionSubsectionId,
                    production_section_id: txt_sub_production_section_id,
                    parent_department_id: txt_sub_prod_parentDepartment,
                    plant_id: cmb_sub_prod_plant_id,
                    plant_name: $('#cmb_sub_prod_plant_id option:selected').text(),
                    production_sub_section_name: txt_SubSectionName,
                    production_sub_section_short_type: txt_sub_section_short_type,
                    production_sub_section_short_name: txt_sub_prod_shortName,
                    production_section_name: sectionname,
                    production_section_short_name: txt_SectionShortName,
                    cost_center_id: txt_sub_prod_costCenter,
                    profit_center_id: txt_sub_prod_profitCenter,
                    production_section_head_id: txt_sub_prodSectionHeadID,
                    production_section_subhead_id: txt_sub_prodSectionSubHeadID,
                    production_section_std_staff_strength: txt_sub_prodSectionStaffStrength,
                    production_section_std_worker_strength: txt_sub_prodSection_std_worker_strength,
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: productionSubsectionId === 0 ? null : UserName,

                };

                for (let index = 0; index < storeLocationData.length; index++) {
                    const element = storeLocationData[index];
                    const storeLocationAddData = {
                        production_sub_section_godown_id: 0,
                        company_branch_id: COMPANY_BRANCH_ID,
                        company_id: COMPANY_ID,
                        production_sub_section_id: productionSubsectionId,
                        production_section_id: txt_sub_production_section_id,
                        parent_department_id: txt_sub_prod_parentDepartment,
                        plant_id: cmb_sub_prod_plant_id,
                        created_by: UserName,
                        godown_id: element.godown_id,

                    }
                    json.GodownMappingData.push(storeLocationAddData)

                }
                json.ProductionSubSectionJson = data

                const formData = new FormData()
                formData.append('ProductionSubSectionData', JSON.stringify(json))

                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSection/FnAddUpdateProdSubSectionAndGodownMappingRecord`, forwardData)
                const response = await agentApiCall.json();


                if (response.success === '0') {
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = await combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(response.message)
                    setShowSuccessMsgModal(true)
                }

            }
        } catch (error) {
            console.log("error", error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    };

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_SubSectionName').attr('disabled', true)
                $('#txt_sub_prod_shortName').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("productionSubSectionformid");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('productionSubSectionformid')
    }

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSection/FnShowParticularProdSubSectionRecordForUpdate/${productionSubsectionId}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.productionSubSectionData

            const storeLocationDetails = updateRes.ProductionSubSectionGodownMapppingData
            console.log(data);

            if (updateRes.ProductionSubSectionGodownMapppingData.length !== 0) {
                setStoreLocationData(updateRes.ProductionSubSectionGodownMapppingData)
            } else {
                const updatedStoreLocationData = [...storeLocationData]
                updatedStoreLocationData.push(godownBlankObject)
                setStoreLocationData(updatedStoreLocationData)
            }

            setproduction_sub_section_id(data.production_sub_section_id)
            set_sub_production_section_id(data.production_section_id)
            await addRecordInProperty('Section')
            set_sub_prod_parentDepartment(data.parent_department_id)
            set_sub_prod_PlantName(data.plant_id);
            setSubSectionName(data.production_sub_section_name)
            setSubSectionShortType(data.production_sub_section_short_type)
            set_sub_prod_ShortName(data.production_sub_section_short_name)
            set_sub_prod_CostCenter(data.cost_center_id)
            setsubSectionHeadID(data.production_section_head_id)
            setsubSectionSubHeadID(data.production_section_subhead_id)
            setSubProdSectionStaffStrength(data.production_section_std_staff_strength)
            setSubProdSectionWorkerStrength(data.production_section_std_worker_strength)
            set_sub_prod_ProfitCenter(data.profit_center_id);
            setIsActive(data.is_active);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const FillComobos = async () => {
        debugger
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_department"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "department_group", operator: "=", value: 'Production' });
            globalQuery.conditions.push({ field: "department_type ", operator: "=", value: 'M' });
            let departmentList = await combobox.current.fillFiltersCombo(globalQuery)
            setsubProdParentDepartOption(departmentList)




            combobox.current.fillMasterData("cmv_employee", "", "").then((employeeList) => {
                setsubProdBranchHeadOptions(employeeList)
                setsubProdsectionSubHeadOptions(employeeList)
            })

            combobox.current.fillMasterData("xmv_production_section", "", "").then((Sectionlist) => {
                setSectionOption(Sectionlist)
            })

            combobox.current.fillMasterData("cmv_plant", "", "").then((getProdPlantApiCall) => {
                setsubProdPlantOptions(getProdPlantApiCall);
            })

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'godown_name', 'godown_short_name']
            globalQuery.table = "cmv_godown"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "godown_type", operator: "IN", values: ['Both', 'Production'] });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((godownTypeApiCall) => {
                setGodownNameOptions(godownTypeApiCall)
            })

            //Costcenter List

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
            globalQuery.table = "fmv_cost_center";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const costCenterList = await combobox.current.fillFiltersCombo(globalQuery);
            setsubProdCostCenterOption(costCenterList);

            //Profitcenter List
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
            globalQuery.table = "fmv_profit_center";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
            setsubProdProfitCenterOption(profitCenterList);
        } catch (error) {
            console.log("error : ", error)
        }



    }

    const handleGodownNameChange = (currentProductTypeGroup, e) => {

        let clickedColName = e.target.getAttribute('Headers');
        delete e.target.parentElement.dataset.tip;
        switch (clickedColName) {

            case 'godown_id':
                currentProductTypeGroup[clickedColName] = parseInt(e.target.value);
                const selectedGodown = godownNameOptions.find(godown => godown.field_id === currentProductTypeGroup[clickedColName]);
                currentProductTypeGroup.godown_short_name = selectedGodown.godown_short_name

                break;

            default:
                break;
        }

        const updatePtGroupData = [...storeLocationData]
        const productTypeIndexArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatePtGroupData[productTypeIndexArray] = currentProductTypeGroup;
        setStoreLocationData(updatePtGroupData);

    }

    const addRecordInProperty = async (key) => {
        switch (key) {

            case 'Section':
                const Sectionval = document.getElementById('txt_sub_production_section_id').value;
                set_sub_production_section_id(Sectionval)
                $('#error_txt_production_section_id').hide();
                if (Sectionval === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Production Section')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                } else if (Sectionval === '') {

                } else {
                    resetGlobalQuery();
                    globalQuery.columns.push("production_section_name");
                    globalQuery.columns.push("production_section_short_name");
                    globalQuery.columns.push("field_name");
                    globalQuery.columns.push("field_id");
                    globalQuery.table = "xmv_production_section";
                    globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: Sectionval, });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                    const SectionApiCall = await combobox.current.removeCatcheFillCombo(globalQuery);

                    setSectionShortName(SectionApiCall[0].production_section_short_name)
                    console.log(SectionApiCall);

                }

                break;

            case 'department':
                const departlist = document.getElementById('txt_sub_prod_parentDepartment').value
                set_sub_prod_parentDepartment(departlist)
                $('#error_txt_sub_prod_parentDepartment').hide();
                if (departlist === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'SectionHead':
                const SectionHeadval = document.getElementById('txt_sub_prodSectionHeadID').value;
                setsubSectionHeadID(SectionHeadval)
                $('#error_txt_sub_prodSectionHeadID').hide();
                if (SectionHeadval === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'SectionSubHead':
                const SectionSubHeadval = document.getElementById('txt_sub_prodSectionSubHeadID').value;
                setsubSectionSubHeadID(SectionSubHeadval)
                $('#error_txt_sub_prodSectionSubHeadID').hide();
                if (SectionSubHeadval === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case 'cost_center':
                const costcenter = document.getElementById('txt_sub_prod_costCenter').value;
                set_sub_prod_CostCenter(costcenter)
                $('#error_costcenterId').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'profit_center':
                const profitcenter = document.getElementById('txt_sub_prod_profitCenter').value;
                set_sub_prod_ProfitCenter(profitcenter)
                $('#error_txt_sub_prod_profitCenter').hide();
                if (profitcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Profit Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;

            default: break;
        }
    }


    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {

            case 'Department':
                combobox.current.fillMasterData("cmv_department", "", "").then((departmentList) => {
                    setsubProdParentDepartOption(departmentList)
                })
                break;

            case 'Cost Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                    globalQuery.table = "fmv_cost_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const costCenterList = await combobox.current.fillFiltersCombo(globalQuery);
                    setsubProdCostCenterOption(costCenterList);
                } catch (error) {
                    console.log("error : ", error)
                }
                break;
            case 'Profit Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
                    globalQuery.table = "fmv_profit_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
                    setsubProdProfitCenterOption(profitCenterList);
                } catch (error) {
                    console.log("error : ", error)
                }
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 100)
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;

            case 'Production Section':
                return <FrmProductionSectionEntry btn_disabled={true} />;

            case 'Department':
                return <DepartmentEntry btn_disabled={true} />;

            case 'Profit Center':
                return <ProfitCenterEntry btn_disabled={true} />;
            default:
                return null;
        }
    }


    const FnRefreshbtn = async (key) => {
        switch (key) {
            case 'SectionHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList1) => {
                    setsubProdBranchHeadOptions(empList1)
                })
                break;

            case 'SectionSubHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList) => {
                    setsubProdsectionSubHeadOptions(empList)
                })
                break;

            default:
                break;
        }
    }


    const setRowCountAndAddRow = () => {
        const tableRows = document.querySelectorAll('#storeLocationTbl tbody tr');
        let lastRowContactPerson = '';

        if (tableRows.length > 0) {
            const lastRowIndex = tableRows.length - 1;
            lastRowContactPerson = tableRows[lastRowIndex].querySelector('select[id^="godown_id_"]').value;
        }

        if (lastRowContactPerson !== '') {
            setRowCount(rowCount + 1);
        } else {
            tableRows.forEach(row => {
                const godownIdInput = row.querySelector('select[id^="godown_id_"]');
                const bankContactName = godownIdInput.value;
                if (bankContactName === '') {
                    godownIdInput.parentElement.dataset.tip = 'Please fill this Field...!';
                    godownIdInput.focus();
                    return;
                } else {
                    delete godownIdInput.parentElement.dataset.tip;
                }
            });
        }
    };

    const removeFirstRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            const updatedStoreLocationData = storeLocationData.filter((item, index) => index !== indexToRemove);
            setStoreLocationData(updatedStoreLocationData)
        } else {
            const updatedStoreLocationData = [...storeLocationData];  // Create a copy of the array
            updatedStoreLocationData[0] = { ...godownBlankObject }; // Set values of 0th index to the godownBlankObject
            setStoreLocationData(updatedStoreLocationData);
        }
    }


    const godownBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        production_sub_section_godown_id: 0,
        production_sub_section_id: 0,
        production_section_id: 0,
        parent_department_id: 0,
        godown_short_name: '',
        godown_id: 0,
        plant_id: 0,
        created_by: UserName,
    }

    useLayoutEffect(() => {
        const getExistingStoreLocationData = [...storeLocationData]
        getExistingStoreLocationData.push(godownBlankObject)
        setStoreLocationData(getExistingStoreLocationData)
    }, [rowCount])


    const renderStoreLocation = useMemo(() => {
        if (!storeLocationData) {
            return null;
        }

        return <Table id='storeLocationTbl' className={`erp_table ${storeLocationData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr no .</th>
                    <th className="erp_table_th">Godown</th>
                    <th className="erp_table_th">Short Code</th>

                </tr>
            </thead>
            <tbody>
                {storeLocationData.map((item, index) =>
                    <tr rowindex={index} className="sticky-column">
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={setRowCountAndAddRow} />
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`godown_id_${index}`} value={item.godown_id} className="erp_input_field mb-0"
                                        Headers="godown_id" onChange={(e) => { handleGodownNameChange(item, e); }}
                                    >
                                        <option value="">Select</option>
                                        {godownNameOptions?.map(godownType => (

                                            <option value={godownType.field_id} ptShortName={godownType.godown_name}>{godownType.field_name}</option>
                                        ))}

                                    </select>
                                    : item.godown_id
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <span className="erp_input_field mb-0" Headers="godown_short_name" id={`godown_short_name_${index}`}>{item.godown_short_name}</span>
                                    </>
                                    : item.short_code
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [storeLocationData, godownNameOptions, godownShortNames])
    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={combobox} />
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                </div> : null}

            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Production Sub Section {actionType} </label>
                    </div>
                    <form id="productionSubSectionformid">
                        <div className="row erp_transporter_div">
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Production Section<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_sub_production_section_id" value={txt_sub_production_section_id} className="form-select form-select-sm erp_input_field" onChange={() => addRecordInProperty('Section')} disabled={keyForViewUpdate === 'view'}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {sectionOption?.map(parentDepart => (
                                                <option value={parentDepart.field_id}>{parentDepart.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_production_section_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Parent Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select
                                            size="sm"
                                            id="txt_sub_prod_parentDepartment"
                                            value={txt_sub_prod_parentDepartment}
                                            className="form-select form-select-sm erp_input_field"
                                            onChange={() => addRecordInProperty('department')}
                                            disabled={keyForViewUpdate === 'view'}
                                        >
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {
                                                subProd_parentDepartOption.length !== 0 &&
                                                subProd_parentDepartOption.map((parentDepart) => (
                                                    <option key={parentDepart.field_id} value={parentDepart.field_id}>
                                                        {parentDepart.field_name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prod_parentDepartment" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_sub_prod_plant_id" className="form-select form-select-sm" value={cmb_sub_prod_plant_id} onChange={(e) => { set_sub_prod_PlantName(e.target.value); }}>
                                            <option value="" disabled>Select</option>
                                            {subProd_plantOptions.length !== 0 ? (
                                                <>{subProd_plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sub_prod_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Sub Section Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_SubSectionName" value={txt_SubSectionName} onChange={e => { setSubSectionName(e.target.value); validateFields() }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_SubSectionName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Sub Section Short Type</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_section_short_type" className="erp_input_field" value={txt_sub_section_short_type} onChange={(e) => { setSubSectionShortType(e.target.value.toUpperCase()); validateFields() }} maxLength="3" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_sub_section_short_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Sub Section Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_prod_shortName" className="erp_input_field" value={txt_sub_prod_shortName} onChange={(e) => { set_sub_prod_ShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="255" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_sub_prod_shortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Section Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_SectionShortName" className="erp_input_field" value={txt_SectionShortName} onChange={(e) => { setSectionShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="255" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_SectionShortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Profit Center</Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_sub_prod_profitCenter" value={txt_sub_prod_profitCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('profit_center'); }} optional="optional" >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {subProd_profitCenterOption?.map(option => (
                                                <option value={option.field_id}>[{option.profit_center_short_name} ] {option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prod_profitCenter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Cost Center <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_sub_prod_costCenter" value={txt_sub_prod_costCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('cost_center'); }} >
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {subProd_costCenterOption?.map(option => (
                                                // <option value={option.field_id}>{option.field_name}</option>
                                                <option value={option.field_id}>[{option.cost_center_short_name} ] {option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prod_costCenter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Section Head <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_sub_prodSectionHeadID" value={txt_sub_prodSectionHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("SectionHead"); }}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>

                                            {subProd_branchHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prodSectionHeadID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("SectionHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Section SubHead</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_sub_prodSectionSubHeadID" value={txt_sub_prodSectionSubHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("SectionSubHead"); }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {sectionSubHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prodSectionSubHeadID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("SectionSubHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Strength <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_prodSectionStaffStrength" className="erp_input_field erp_align-right" value={txt_sub_prodSectionStaffStrength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSubProdSectionStaffStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_sub_prodSectionStaffStrength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Wroker </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_prodSection_std_worker_strength" className="erp_input_field erp_align-right" value={txt_sub_prodSection_std_worker_strength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSubProdSectionWorkerStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_sub_prodSection_std_worker_strength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <Accordion defaultActiveKey="0" className="mt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="erp-form-label-md">Store Location</Accordion.Header>
                                    <Accordion.Body className="p-0">
                                        <div className="mt-10">
                                            <>
                                                {renderStoreLocation}
                                            </>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MProductionSubSection/FrmProductionSubSectionListing/reg' : '/Masters/MProductionSubSection/FrmProductionSubSectionListing';
                                navigate(path);
                            }}

                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={addProductionSubSection} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>


                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}

                    </Modal.Body>
                </Modal >
            </div>
        </>
    )
}

export default FrmProductionSubSectionEntry;
