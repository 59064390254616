import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { Tab, Table, Tabs, Form, Card } from "react-bootstrap";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import MDBox from 'components/MDBox';
import { Grid } from "@mui/material";
import FrmWInspDetailsEntry from './component/FrmWInspDetailsEntry';
import FrmWInspMaterialMovementEntry from './component/FrmWInspMaterialMovementEntry';

import Select from 'react-select';
import { position } from 'stylis';
import zIndex from '@material-ui/core/styles/zIndex';

function getSteps() {
  return ["Weaving Inspection Details", "Material Movement"];
}
function FrmTWeavingInspectionEntry(props) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;
  const { state } = useLocation();
  const { weavingInspectionMasterId = 0, keyForViewUpdate, compType } = state || {}

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = () => { setActiveStep((parseInt(activeStep) + 1)) };
  const handleBack = () => setActiveStep((parseInt(activeStep) - 1));
  //Current date
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  //useRef Hooks

  const comboDataAPiCall = useRef();
  const cmb_prod_supervisor_id_ref = useRef();
  const generateAutoNoAPiCall = useRef();
  const navigate = useNavigate();
  const validateNumberDateInput = useRef();
  const validate = useRef();
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  const [isLoading, setIsLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  //Inspection Production master hooks
  const [txt_weaving_production_inspection_master_id, setweavingProductionInspectionMasterId] = useState(weavingInspectionMasterId);
  const [dt_inspection_production_date, setProdPlanDate] = useState(today);
  const [txt_prod_month, setProdMonth] = useState();
  const [txt_prod_year, setProdYear] = useState();
  const [cmb_plant_id, setProdPlantName] = useState('');
  const [txt_Weaving_inspection_Status, setWeavingInspectionStatus] = useState('P');
  const [cmb_section_id, setProdSection] = useState(17);
  const [cmb_sub_section_id, setProdSubSection] = useState(26);
  const [cmb_inspection_production_code, setWeavingInspectionCode] = useState();
  const [cmb_prod_supervisor_id, setProdSupervisor] = useState();

  //Inspection Production  details comboboxes
  const [weavingInspectionData, setWeavingInspectionData] = useState([])
  const [inspectionMaterialData, setInspectionMaterialData] = useState([])
  const [setNoOptions, setSetNoOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
  const [shiftNames, setShiftNames] = useState([]);
  ///Inspection Production Hooks
  const [prodSummaryData, setProductionSummaryData] = useState([]);
  const [machineNameOption, setMachineNameOption] = useState([]);

  const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();
  const [godownNameOptions, setGodownNameOptions] = useState([]);
  const [cmb_godown_id, setGodownId] = useState([]);

  ///Shiftwise Machine Wastage Entry Hooks
  const [cmb_prod_wastage_type, setProdWastageType] = useState();
  const [txt_act_wastages, setActWastage] = useState();
  const [cmb_shift_wastage, setShiftWastage] = useState();
  const [labelForWastage, setLabelForWastage] = useState('Add');
  const [txt_wastage_quantity, setWastageQuantity] = useState();

  ///Shiftwise Machine Wastage Entry Combobox
  const [prodWastageOptions, setProdWastageOptions] = useState([]);
  const [wastageDetails, setWastageDetails] = useState([]);

  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigate(`/Transactions/TWeavingInspection/FrmTWeavingInspectionListing`)
  }

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  const moveByTabs = (eventKey) => {
    setActiveStep(eventKey)
  }
  const storedReceiveWeavingInspData = (inspectionData) => {
    setWeavingInspectionData(inspectionData)
  }
  const storedReceiveInspectionMaterialData = (inspMaterialData) => {
    setInspectionMaterialData(inspMaterialData)
  }

  const storedReceiveActionLable = (ActionLabel) => {
    setActionLabel(ActionLabel)
  }
  const storedReceiveProdStatus = (ProductionWInspStatus) => {
    setWeavingInspectionStatus(ProductionWInspStatus)
  }
  const storedReceiveProdMaterialStatus = (ProductionWInspStatus) => {
    setWeavingInspectionStatus(ProductionWInspStatus)
  }

  function getStepContent() {
    switch (parseInt(activeStep)) {
      case 0:
        return <FrmWInspDetailsEntry goBack={handleBack} ActionLabel={storedReceiveActionLable} ProductionWInspStatus={storedReceiveProdStatus} getWeavingInspectionData={storedReceiveWeavingInspData} inspectionData={weavingInspectionData} productionDateopt={dt_inspection_production_date} />;
      case 1:
        return <FrmWInspMaterialMovementEntry goToNext={handleNext} goBack={handleBack} ActionLabel={storedReceiveActionLable} ProductionWInspStatus={storedReceiveProdMaterialStatus} onGetInspectionMaterialData={storedReceiveInspectionMaterialData} inspMaterialData={inspectionMaterialData} productionDateopt={dt_inspection_production_date} subSectionVal={cmb_sub_section_id} />;

      default:
        return null;
    }

  }

  useEffect(() => {

    const loadDataOnload = async () => {
      setIsLoading(true)
      await ActionType()
      await fillComboBox();
      setMonthYear();

      if (keyForViewUpdate === 'Add') {
        fillAutoNo();
      }

      if (weavingInspectionMasterId !== 0) {
        await FnCheckUpdateResponce()
      }
      FnGetShiftDetails(today)
      setIsLoading(false)
    }
    loadDataOnload()
  }, [])


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setIsApprove(false);
        setActionType('(Modification)');
        setActionLabel('Update')
        await validate.current.readOnly("weavingInspection");
        break;
      case 'view':
        setIsApprove(false);
        setActionType('(View)');
        await validate.current.readOnly("weavingInspection");
        await validate.current.readOnly("wastageFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      case 'approve':
        setActionType('(Approve)');
        setActionLabel('Approve')
        $("form").find("input,textarea,select").attr("disabled", "disabled");
        $("table").find("input,button,textarea,select").attr("disabled", "disabled");
        $('table .approveField').removeAttr("disabled");
        $("input[type=radio]").attr('disabled', true);
        setIsApprove(true)
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };


  /// function for update inspectio data
  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnShowParticularRecordForUpdate/${weavingInspectionMasterId}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();
      const inspectionDetailData = updateRes.WeavingProductionInspectionDetailsRecords
      const masterdata = updateRes.WeavingProductionInspectionMasterModelRecord
      const wastageData = updateRes.WeavingProductionInspectionWastageRecords
      const materialData = updateRes.WeavingProductionInspectionMaterialRecords
      console.log(inspectionDetailData);

      setweavingProductionInspectionMasterId(masterdata.weavingInspectionMasterId)
      let insp_Shifts = [];
      inspectionDetailData.forEach((item, index) => {
        insp_Shifts.push(item.shift);
      });

      setProdPlanDate(masterdata.inspection_production_date)
      setProdMonth(masterdata.prod_month)
      setWeavingInspectionCode(masterdata.inspection_production_code)
      setProdYear(masterdata.prod_year)
      setProdPlantName(masterdata.plant_id);
      setProdSection(masterdata.section_id)
      setProdSubSection(masterdata.sub_section_id)
      comboOnChange('cmb_section_id');
      setProdSupervisor(masterdata.production_supervisor_id)

      // setWeavingInspectionData(inspectionDetailData)
      if (inspectionDetailData !== null && inspectionDetailData !== "") {
        setWeavingInspectionData(prevArray => {
          return inspectionDetailData.map(item => {
            return {
              ...item,
              balance_product_in_meter: item.balance_product_in_meter + item.inspection_mtr,
              difference: (item.balance_product_in_meter + item.inspection_mtr) - item.inspection_mtr,
              average: (item.weight / item.inspection_mtr) * 1000,
            };
          });
        });
      }
      if (materialData !== null && materialData !== "") {
        setInspectionMaterialData(prevArray => {
          return materialData.map(item => {
            return {
              ...item,
              //  product_inspection_material_balance_quantity: item.product_inspection_material_balance_quantity + item.consumption_quantity,
              product_inspection_material_balance_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(item.product_inspection_material_balance_quantity + item.consumption_quantity), 4),
            };
          });
        });
      }
      setWastageDetails(wastageData);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }
  $(document).on('click', '.erp_input_field_table_txt.remove0', function () {
    if ($(this).val() === "0") {
      $(this).val("")
    }
  });
  $(document).on('mouseup mousedown', function (e) {
    let inputBoxes = $(".erp_input_field_table_txt.remove0");
    inputBoxes.each(function () {
      if ($(this).val() === "") {
        $(this).val("0");
      }
    });
  });


  /// function for Generate weaving Inspection Code
  const fillAutoNo = async () => {
    try {
      const productionDate = $('#dt_inspection_production_date').val();
      const [year, month, day] = productionDate.split('-');
      const formatted_date = `${day}${month}${year.slice(2)}`;
      comboOnChange('cmb_section_id');
      const subSectionVal = $('#cmb_sub_section_id').val();
      const shortName = $('#cmb_sub_section_id option:selected').attr('shortName');
      const weavingInspectionCode = await generateAutoNoAPiCall.current.generateProductionCode({
        "entity_name": "xt_weaving_production_inspection_master",
        "sub_section": subSectionVal,
        "sub_section_short_name": shortName,
        "field_name": "inspection_production_code",
        "trans_no_length": "4",
        "transaction_date": formatted_date,
        "company_id": COMPANY_ID
      });
      setWeavingInspectionCode(weavingInspectionCode);
    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')
    }
  };


  /// function for combo on change
  const comboOnChange = async (key) => {
    try {
      switch (key) {
        case 'cmb_section_id':
          const prod_section_Id = $('#cmb_section_id').val();
          ///// Production sub section Arr[]
          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_type');
          globalQuery.table = "xmv_production_sub_section"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
          let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
          setProdSubSectionOptions(getProdSubSectionApiCall)
          setProdSubSection('27');


          const prodsubSectionId = $('#cmb_sub_section_id').val();

          resetGlobalQuery();
          globalQuery.columns.push("field_id", 'field_name');
          globalQuery.table = "cmv_machine"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "section_id", operator: "=", value: prod_section_Id });
          globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
          comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
            setMachineNameOption(getProdSubSectionApiCall)
            console.log("sub section list: ", getProdSubSectionApiCall);
          });

          // ////Employees in Warping Production
          // resetGlobalQuery();
          // globalQuery.columns.push("field_id", 'field_name');
          // globalQuery.table = "cmv_employee"
          // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          // globalQuery.conditions.push({ field: "department_id", operator: "=", value: 29 });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          // comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
          //   const prodSupervisorList = [
          //     { value: '', label: 'Select' },
          //     ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
          //   ];
          //   setMachineOperatorOptions(prodSupervisorList);
          // })
          break;

        case 'cmb_plant_id':
          let plantId = $('#cmb_plant_id').val();
          setProdPlantName(plantId);
          break;
      }

    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')

    }
  }
  const fillComboBox = async () => {
    try {
      comboDataAPiCall.current.fillMasterData("cmv_plant", "", "").then((getProdPlantApiCall) => {
        setPlantOptions(getProdPlantApiCall);
      })

      ////Production Section
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name'];
      globalQuery.table = "xmv_production_section"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
        setProdSectionOptions(getWarpingProdSecCall);
        setProdSection('18');
        comboOnChange('cmb_section_id');
      })

      const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
      setShiftOptions(shiftsApiCall);
      let shift_Names = [];
      shiftsApiCall.map((shift) => {
        shift_Names.push(shift.property_name)
      })
      setShiftNames(shift_Names);

      ////Employees in Warping Production
      resetGlobalQuery();
      globalQuery.columns.push("field_id", 'field_name');
      globalQuery.table = "cmv_employee"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "department_id", operator: "=", value: 29 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
        const prodSupervisorList = [
          { value: '', label: 'Select' },
          ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
        ];
        setMachineOperatorOptions(prodSupervisorList);
      })

      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'production_wastage_types_name']
      globalQuery.table = "xmv_production_wastage_types"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((godownTypeApiCall) => {
        setProdWastageOptions(godownTypeApiCall)
      })

      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'godown_name']
      globalQuery.table = "cmv_godown"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((godownTypeApiCall) => {
        setGodownNameOptions(godownTypeApiCall)
      })

      resetGlobalQuery();
      globalQuery.columns.push('*');
      globalQuery.table = "xtv_weaving_production_order";
      globalQuery.conditions.push({ field: "weaving_order_status", operator: "=", value: 'A', });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const SectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setSetNoOptions(SectionApiCall);


      //Inspection production  Summary Details
      let jsonForSummaryData = {};
      for (let key = 0; key < shift_Names.length; key++) {
        let data = {
          shift_total_product_in_meter: 0,
          shift_total_weight: 0,
          shift_total_inspection_mtr: 0,
          total: 0,
          total_up_date: 0,
          shift_inspection_mtr_upto_date_total: 0,
          shift_total_upto_date_weight: 0,
          shift_product_in_meter_upto_date_total: 0,
        }

        jsonForSummaryData[shift_Names[key]] = data;
      }
      setProductionSummaryData(jsonForSummaryData);
    } catch (error) {
      console.log('error: ', error);
      navigate('/Error')

    }
  }

  const setMonthYear = () => {
    let prod_Plan_Dt = $("#dt_inspection_production_date").val();
    if (prod_Plan_Dt) {
      setProdPlanDate(prod_Plan_Dt);
      let dateComponents = prod_Plan_Dt.split('-');
      setProdMonth(dateComponents[1]);
      setProdYear(dateComponents[0]);
    }
  }

  const renderproductionsummary = useMemo(() => {
    let namesOfTheShift = [...shiftNames];
    const prodSummaryRowsFirstCol = ['Loom Mtr', 'Inspection Mtr', 'Weight'];
    return (
      <Table className='erp_table' id="todaysProdSummaryTableId" bordered striped>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th"></th>
            <th className="erp_table_th text-center" colSpan={3}>
              Today's Production Summary
            </th>
            <th className="erp_table_th text-center" colSpan={3}>
              Up To Day's Production Summary
            </th>
          </tr>
        </thead>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Description</th>
            {shiftOptions?.map((header, indexOfItem) => (
              <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                {header.field_name}
              </th>
            ))}
            <th className="erp_table_th">Total</th>
            {shiftOptions?.map((header, indexOfItem) => (
              <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                {header.field_name}
              </th>
            ))}
            <th className="erp_table_th">Total</th>
          </tr>
        </thead>
        <tbody>
          {namesOfTheShift.length === 0 ? (<tr></tr>) : (
            <React.Fragment>
              {/* First Row */}
              <tr>
                <th className="erp_table_th">{prodSummaryRowsFirstCol[0]}</th>
                <>
                  {namesOfTheShift.map((key, index) => (
                    <td key={key} className="erp_table_td">
                      {prodSummaryData[key].shift_total_product_in_meter}
                    </td>

                  ))}
                  <td className="erp_table_td">
                    {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_total_product_in_meter, 0)}
                  </td>
                </>
                <>
                  {namesOfTheShift.map((key, index) => (
                    <td key={key} className="erp_table_td">
                      {prodSummaryData[key].shift_product_in_meter_upto_date_total}
                    </td>
                  ))}
                  <td className="erp_table_td">
                    {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_product_in_meter_upto_date_total, 0)}
                  </td>
                </>
              </tr>
              {/* Second Row */}
              <tr>
                <th className="erp_table_th">{prodSummaryRowsFirstCol[1]}</th>
                <>
                  {namesOfTheShift.map((key, index) => (
                    <td key={key} className="erp_table_td">
                      {prodSummaryData[key].shift_total_inspection_mtr}
                    </td>
                  ))}
                  <td className="erp_table_td">
                    {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_total_inspection_mtr, 0)}
                  </td>
                </>
                <>
                  {namesOfTheShift.map((key, index) => (
                    <td key={key} className="erp_table_td">
                      {prodSummaryData[key].shift_inspection_mtr_upto_date_total}
                    </td>
                  ))}
                  <td className="erp_table_td">
                    {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_inspection_mtr_upto_date_total, 0)}
                  </td>
                </>
              </tr>
              {/* Third Row */}
              <tr>
                <th className="erp_table_th">{prodSummaryRowsFirstCol[2]}</th>
                <>
                  {namesOfTheShift.map((key, index) => (
                    <td key={key} className="erp_table_td">
                      {prodSummaryData[key].shift_total_weight}
                    </td>
                  ))}
                  <td className="erp_table_td">
                    {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_total_weight, 0)}
                  </td>
                </>
                <>
                  {namesOfTheShift.map((key, index) => (
                    <td key={key} className="erp_table_td">
                      {prodSummaryData[key].shift_total_upto_date_weight}
                    </td>
                  ))}
                  <td className="erp_table_td">
                    {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_total_upto_date_weight, 0)}
                  </td>
                </>

              </tr>

            </React.Fragment>
          )}
        </tbody>
      </Table>
    )
  }, [prodSummaryData]);

  /// Get data for summary
  const FnGetShiftDetails = async (today) => {
    debugger
    let namesOfTheShift = [...shiftOptions];
    let newdate = ($('#dt_inspection_production_date').val() === '' || $('#dt_inspection_production_date').val() === null) ? today : $('#dt_inspection_production_date').val();

    try {
      const getShiftApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnShowParticularInspectionShiftSummary/${newdate}/${COMPANY_ID}`);
      const respForShiftApiCall = await getShiftApiCall.json();

      let productSummaryData = respForShiftApiCall.data;
      let upToDateShift_Details = {};
      namesOfTheShift.map((shift) => {
        if (productSummaryData[shift] && productSummaryData[shift].hasOwnProperty('shift_total_inspection_mtr')) {
          upToDateShift_Details[shift] = {
            shift_total_product_in_meter: productSummaryData[shift].shift_total_product_in_meter,
            shift_total_inspection_mtr: productSummaryData[shift].shift_total_inspection_mtr,
            shift_total_weight: productSummaryData[shift].shift_total_weight,
            shift_product_in_meter_upto_date_total: productSummaryData[shift].shift_product_in_meter_upto_date_total,
            shift_inspection_mtr_upto_date_total: productSummaryData[shift].shift_inspection_mtr_upto_date_total,
            shift_total_upto_date_weight: productSummaryData[shift].shift_total_upto_date_weight,
          };
        } else {
          upToDateShift_Details[shift] = {};
        }
      })
      setProductionSummaryData(productSummaryData);
    } catch (error) {
      console.log('error', error);
      navigate('/Error')

    }
  }

  const validateProdDetails = async () => {
    let lastRowSelectSetNo = '';
    let lastRowSelectMachineOperator = '';
    let lastRowSelectShift = '';
    let lastRowSelectWeight = '';
    let lastRowSelectRollNo = '';
    let lastRowSelectPick = '';
    let lastRowSelectInspectionMtr = '';
    let lastRowSelectGodown = '';

    if (weavingInspectionData.length > 0) {
      const lastRowIndex = weavingInspectionData.length - 1;
      lastRowSelectSetNo = weavingInspectionData[lastRowIndex].inspection_production_set_no;
      lastRowSelectMachineOperator = weavingInspectionData[lastRowIndex].production_operator_id;
      lastRowSelectShift = weavingInspectionData[lastRowIndex].shift;
      lastRowSelectWeight = weavingInspectionData[lastRowIndex].weight;
      lastRowSelectRollNo = weavingInspectionData[lastRowIndex].roll_no;
      lastRowSelectPick = weavingInspectionData[lastRowIndex].product_pick;
      lastRowSelectInspectionMtr = weavingInspectionData[lastRowIndex].inspection_mtr;
      lastRowSelectGodown = weavingInspectionData[lastRowIndex].godown_id;

    }
    if (parseInt(lastRowSelectSetNo) !== 0 && lastRowSelectGodown !== '' && parseInt(lastRowSelectInspectionMtr) !== 0 && parseInt(lastRowSelectMachineOperator) !== 0 && parseInt(lastRowSelectShift) !== 0 && parseInt(lastRowSelectWeight) !== 0 && parseInt(lastRowSelectRollNo) !== 0 && parseInt(lastRowSelectPick) !== 0) {

      return true;
    }
    else if (parseInt(lastRowSelectSetNo) === 0 && parseInt(lastRowSelectGodown) !== 0 && parseInt(lastRowSelectInspectionMtr) === 0 && parseInt(lastRowSelectMachineOperator) === 0 && parseInt(lastRowSelectShift) === 0 && parseInt(lastRowSelectWeight) === 0 && parseInt(lastRowSelectRollNo) === 0 && parseInt(lastRowSelectPick) !== 0) {

      setErrMsg('Please Select at least one Weaving Inspection Details');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectShift === '' || parseInt(lastRowSelectShift) === 0) {
      setErrMsg('Please select Shift in Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectMachineOperator === '' || parseInt(lastRowSelectMachineOperator) === 0) {
      setErrMsg('Please select checker Name Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectSetNo === '' || parseInt(lastRowSelectSetNo) === 0) {
      setErrMsg('Please select Set No in Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectPick === '' || parseInt(lastRowSelectPick) === 0) {
      setErrMsg('Please select Pick in Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectInspectionMtr === '' || parseInt(lastRowSelectInspectionMtr) === 0) {
      setErrMsg('Please fill Inspection Mtr Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    }
    else if (lastRowSelectWeight === '' || parseInt(lastRowSelectWeight) === 0) {
      setErrMsg('Please filled Weight Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectRollNo === '' || parseInt(lastRowSelectRollNo) === 0) {
      setErrMsg('Please filled Roll No Weaving Inspection details...!');
      setShowErrorMsgModal(true);
      return false;
    } else if (lastRowSelectGodown === '' || parseInt(lastRowSelectGodown) === 0) {
      setErrMsg('Please Select at least one Godown Inspection Details...!');
      setShowErrorMsgModal(true);
      return false;
    }
  };

  const FnValidateForm = async () => {
    let validStatus = true;

    for (const rowData of weavingInspectionData) {
      if (rowData.inspection_production_status === 'R' && rowData.status_remark === '') {
        setErrMsg('Please Enter rejection remark');
        setShowErrorMsgModal(true);
        validStatus = false;
        break;
      }
    }

    return validStatus;
  };


  const validateMaterialDetails = async () => {
    if (inspectionMaterialData.length > 0) {
      for (let index = 0; index < inspectionMaterialData.length; index++) {
        const element = inspectionMaterialData[index];
        const { inspection_production_set_no, shift, product_material_id, consumptionQtyInfo = 0 } = element;

        if (inspection_production_set_no !== 0 || (shift !== '' && shift !== 0) || (product_material_id !== '' &&
          product_material_id !== undefined) || consumptionQtyInfo !== 0) {
          if (shift === '') {
            setErrMsg('Please Select at Shift in inspection material movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (inspection_production_set_no === 0) {
            setErrMsg('Please Select at Set NO. in inspection material movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (product_material_id === '') {
            setErrMsg('Please Select Material Name in inspection material movement...!');
            setShowErrorMsgModal(true);
            return false;
          } else if (consumptionQtyInfo === 0) {
            setErrMsg(`Please properly consume the stock for Shift-${shift} & Set No. - ${inspection_production_set_no} in the material movement section.`);
            setShowErrorMsgModal(true);
            return false;
          }
          return true;
        } else {
          return true;
        }

      }
    } else {
      return true;
    }

  }


  // ------------------------------------------Add (save) functionality ---------------------------------------------------------------

  const addWeavingInspection = async (e) => {
    debugger
    try {
      setIsLoading(true)
      const checkIsValidate = await validate.current.validateForm("weavingInspection");
      if (cmb_prod_supervisor_id === '' || cmb_prod_supervisor_id === undefined) {
        let errorElement = document.getElementById("error_cmb_prod_supervisor_id");
        errorElement.textContent = "Please fill this field...!";
        errorElement.style.display = "block";
        document.getElementById("error_cmb_prod_supervisor_id").focus();
        return false;
      }
      let isCalculationValid = false;
      if (keyForViewUpdate === 'approve') {
        isCalculationValid = await FnValidateForm()
      }
      let checkValidProdDetails = false;
      checkValidProdDetails = await validateProdDetails()
      let checkValidMaterialDetails = false;

      checkValidMaterialDetails = await validateMaterialDetails()

      if (checkValidMaterialDetails === true && checkValidProdDetails === true && checkIsValidate === true && (keyForViewUpdate !== "approve" || isCalculationValid === true)) {
        let lastestStatus = txt_Weaving_inspection_Status;

        if (keyForViewUpdate === 'approve') {
          const allApprovedWeaving = weavingInspectionData.every(item => item.inspection_production_status === 'A' || item.inspection_production_status === 'PD' || item.inspection_production_status === 'D');
          const allApprovedMaterial = inspectionMaterialData.every(item => item.material_status === 'A');
          const anyApproved = weavingInspectionData.some(item => item.inspection_production_status === 'A') || inspectionMaterialData.some(item => item.material_status === 'A');
          const allPending = weavingInspectionData.every(item => item.inspection_production_status === 'P') && inspectionMaterialData.every(item => item.material_status === 'P');
          const anyRejected = weavingInspectionData.some(item => item.inspection_production_status === 'R') || inspectionMaterialData.some(item => item.material_status === 'R');

          if (anyRejected) {
            lastestStatus = 'R';
            setActionLabel('Rejected');
          } else if (allApprovedWeaving && allApprovedMaterial) {
            lastestStatus = 'A';
            setActionLabel('Approved');
          } else if (anyApproved) {
            lastestStatus = 'I';
            setActionLabel('Partially Approved');
          } else if (allPending) {
            lastestStatus = 'P';
            setActionLabel('Save');
          } else {
            setWeavingInspectionStatus('p');
          }
        }

        const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransProdWastageData': [], 'TransMaterialData': [], 'commonIds': { 'company_id': COMPANY_ID, 'weaving_production_inspection_master_id': weavingInspectionMasterId } }

        const data = {
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          weaving_production_inspection_master_id: weavingInspectionMasterId,
          inspection_production_code: cmb_inspection_production_code,
          inspection_production_date: dt_inspection_production_date,
          prod_month: txt_prod_month,
          prod_year: txt_prod_year,
          plant_id: cmb_plant_id,
          production_supervisor_id: parseInt(cmb_prod_supervisor_id),
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          created_by: UserName,
          modified_by: weavingInspectionMasterId === 0 ? null : UserName,
          inspection_production_master_status: lastestStatus,
        };

        for (let index = 0; index < wastageDetails.length; index++) {
          const wastageDataElement = wastageDetails[index];
          const wastageData = {
            company_branch_id: COMPANY_BRANCH_ID,
            company_id: COMPANY_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            weaving_production_inspection_master_id: weavingInspectionMasterId,
            inspection_production_code: cmb_inspection_production_code,
            inspection_production_date: dt_inspection_production_date,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            plant_id: cmb_plant_id,
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            created_by: UserName,
            modified_by: weavingInspectionMasterId === 0 ? null : UserName,
            wastage_quantity: wastageDataElement.wastage_quantity,
            godown_id: wastageDataElement.godown_id,
            production_wastage_types_id: wastageDataElement.production_wastage_types_id,
            production_wastage_types_type: wastageDataElement.production_wastage_types_type,
            shift: wastageDataElement.shift
          };
          json.TransProdWastageData.push(wastageData)
        }

        for (let index = 0; index < weavingInspectionData.length; index++) {
          const element = weavingInspectionData[index];
          const weavingInspectionAddData = {
            company_branch_id: COMPANY_BRANCH_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            company_id: COMPANY_ID,
            weaving_production_inspection_master_id: weavingInspectionMasterId,
            weaving_production_inspection_details_id: element.weaving_production_inspection_details_id,
            inspection_production_date: element.inspection_production_date,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            inspection_production_code: cmb_inspection_production_code,
            plant_id: cmb_plant_id,
            production_supervisor_id: parseInt(cmb_prod_supervisor_id),
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            status_remark: element.status_remark,
            modified_by: weavingInspectionMasterId === 0 ? null : UserName,
            inspection_production_set_no: element.inspection_production_set_no,
            machine_id: element.machine_id,
            roll_no: element.roll_no,
            product_pick: element.product_pick,
            production_operator_id: element.production_operator_id,
            sizing_beam_no: element.sizing_beam_no,
            dispatch_quantity: element.dispatch_quantity,
            created_by: element.created_by,
            product_id: element.product_id,
            average: element.average,
            difference: element.difference,
            inspection_production_status: element.inspection_production_status,
            sort_no: element.sort_no,
            width: element.width,
            shift: element.shift,
            weight: element.weight,
            product_in_meter: element.product_in_meter,
            godown_id: element.godown_id,
            inspection_mtr: element.inspection_mtr,
            // inspection_order_no: element.inspection_order_no,
          }
          json.TransDetailData.push(weavingInspectionAddData)
        }
        if (inspectionMaterialData.length > 0) {
          let lastIndexOfMaterialMovement = inspectionMaterialData.length - 1
          if (inspectionMaterialData[lastIndexOfMaterialMovement].inspection_production_set_no !== 0) {

            for (let index = 0; index < inspectionMaterialData.length; index++) {
              const materialDataElement = inspectionMaterialData[index];
              const materialMovementData = {
                company_branch_id: COMPANY_BRANCH_ID,
                company_id: COMPANY_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                weaving_production_inspection_master_id: 0,
                weaving_production_inspection_details_id: 0,
                inspection_production_date: materialDataElement.inspection_production_date,
                inspection_production_code: cmb_inspection_production_code,
                plant_id: cmb_plant_id,
                prod_month: txt_prod_month,
                prod_year: txt_prod_year,
                shift: materialDataElement.shift,
                section_id: cmb_section_id,
                sub_section_id: cmb_sub_section_id,
                weaving_production_inspection_master_id: weavingInspectionMasterId,
                weaving_production_inspection_material_id: materialDataElement.weaving_production_inspection_material_id,
                inspection_production_order_no: materialDataElement.inspection_production_order_no,
                inspection_production_set_no: materialDataElement.inspection_production_set_no,
                product_material_id: materialDataElement.product_material_id,
                product_material_unit_id: materialDataElement.product_material_unit_id,
                consumption_quantity: materialDataElement.consumption_quantity,
                product_material_quantity: materialDataElement.product_material_quantity,
                material_status: materialDataElement.material_status,
                material_status_remark: materialDataElement.material_status_remark,
                consumptionQtyInfo: materialDataElement.consumptionQtyInfo,
                created_by: UserName,
                modified_by: weavingInspectionMasterId === 0 ? null : UserName,

              };
              json.TransMaterialData.push(materialMovementData)
            }
          }
        }

        json.TransHeaderData = data;
        console.log('inspection data', json);
        const formData = new FormData()
        formData.append('WeavingProductionInspectionData', JSON.stringify(json))
        const forwardData = {
          method: 'POST',
          body: formData,
        }
        const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnAddUpdateRecord`, forwardData)
        const response = await agentApiCall.json();
        if (response.success === 0) {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          setSuccMsg(response.message)
          setShowSuccessMsgModal(true)
        }
      }
    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };


  const clearFormFields = (form_type) => {
    switch (form_type) {

      case 'wastage':
        setProdWastageType('');
        setWastageQuantity('');
        setGodownId('');
        setShiftWastage('');
        setLabelForWastage('Add');
        $('#wastageFormId input, #wastageFormId select').val('');
        break;
    }
  }
  const validateFields = () => {
    validate.current.validateFieldsOnChange('weavingInspection')

    validate.current.validateFieldsOnChange('wastageFormId')
  }

  /////////////////////////////////////////////////////////////Machine Wastage Details///////////////////////////////////////////////////////////
  const FnUpdateWastageReason = async (rowData, rowIndex, action) => {
    if (action === 'update') {
      setLabelForWastage('Update');
    }
    const machineWastageDetails = [...wastageDetails];
    switch (action) {
      case 'add':
        const validateWastageReason = await validate.current.validateForm('wastageFormId');
        if (validateWastageReason === true) {
          const findIdtoAdd = machineWastageDetails.some((wastage_Details) => (
            wastage_Details.production_wastage_types_id === cmb_prod_wastage_type && wastage_Details.godown_id === cmb_godown_id && wastage_Details.wastage_quantity === txt_wastage_quantity
            && wastage_Details.shift === cmb_shift_wastage
          ));
          if (findIdtoAdd) {
            $('#error_cmb_prod_wastage_type').text('Wastage Reason already exists...').css({ 'display': 'block', 'margin-top': '8px' });
          } else {
            $('#error_cmb_prod_wastage_type').hide();

            ///To Update Row
            const rowNeedToBeUpdated = machineWastageDetails.find((wastage_Details, rowIndex) => (
              prevdataindexforWastage === rowIndex
            ));

            if (rowNeedToBeUpdated) {
              rowNeedToBeUpdated.production_wastage_types_id = cmb_prod_wastage_type;
              rowNeedToBeUpdated.production_wastage_types_type = $('#cmb_prod_wastage_type option:selected').text();
              rowNeedToBeUpdated.godown_name = $('#cmb_godown_id option:selected').text();
              rowNeedToBeUpdated.godown_id = cmb_godown_id
              rowNeedToBeUpdated.wastage_quantity = txt_wastage_quantity;
              rowNeedToBeUpdated.godown_name = $('#cmb_godown_id option:selected').text();
              rowNeedToBeUpdated.shift = cmb_shift_wastage;
              setPrevDataIndexForWastage('');
            }

            if (!rowNeedToBeUpdated) {
              const newWastageDetail = {
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                inspection_production_date: dt_inspection_production_date,
                prod_month: txt_prod_month,
                inspection_production_code: cmb_inspection_production_code,
                prod_year: txt_prod_year,
                plant_id: cmb_plant_id,
                section_id: cmb_section_id,
                sub_section_id: cmb_sub_section_id,
                production_wastage_types_id: cmb_prod_wastage_type,
                production_wastage_types_type: $('#cmb_prod_wastage_type option:selected').text(),
                actual_wastage_production_kg: txt_act_wastages,
                wastage_quantity: txt_wastage_quantity,
                godown_id: cmb_godown_id,
                godown_name: $('#cmb_godown_id option:selected').text(),
                shift: cmb_shift_wastage
              };
              machineWastageDetails.push(newWastageDetail);
            }
            clearFormFields('wastage');
            setLabelForWastage('Add')
          }
        }

        break;
      case 'update':
        setProdWastageType(rowData.production_wastage_types_id);
        setWastageQuantity(rowData.wastage_quantity);
        setGodownId(rowData.godown_id)
        setShiftWastage(rowData.shift)
        setPrevDataIndexForWastage(rowIndex);

        break;
      case 'remove':
        if (rowIndex !== -1) {
          machineWastageDetails.splice(rowIndex, 1);
        }
        clearFormFields('wastage');
        break;
    }
    setWastageDetails(machineWastageDetails);
  }

  const calculateMachineWastage = async (value) => {
    const wastage_Type = $('#cmb_prod_wastage_type option:selected').val();
    setProdWastageType(wastage_Type);

  }

  const renderMachineWastageTable = useMemo(() => {
    return <Table id="machinewastagetableId" style={{ display: "block", width: '600px', overflow: "auto" }} bordered striped>
      <thead className="erp_table_head">
        <tr>
          <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>Action</td>
          <td className="erp_table_th " style={{ width: "150px" }}>Wastage Type</td>

          <td className="erp_table_th " style={{ width: "300px" }}>Wastage Quantity</td>
          <td className="erp_table_th " style={{ width: "150px" }}>Godown</td>
          <td className="erp_table_th " style={{ width: "150px" }}>Shift</td>
        </tr>
      </thead>
      <tbody>
        {wastageDetails.length > 0 ? (
          wastageDetails?.map((details, indexOfItem) => (
            <tr key={indexOfItem}>
              <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'update')} />
                <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'remove')} />
              </td>
              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.production_wastage_types_type}
              </td>

              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.wastage_quantity}
              </td>
              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.godown_name}
              </td>
              <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                {details.shift}
              </td>

            </tr>
          ))) : (
          <tr>
            <td colSpan="10" className="erp_table_td">
              No records found...
            </td>
          </tr>
        )}
      </tbody>
    </Table>

  }, [wastageDetails]);

  return (
    <>
      <DashboardLayout>
        <ComboBox ref={comboDataAPiCall} />
        <ValidateNumberDateInput ref={validateNumberDateInput} />
        <GenerateMaterialId ref={generateAutoNoAPiCall} />

        <FrmValidations ref={validate} />

        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Weaving Inspection{actionType} </label>
          </div>

          <form id='weavingInspection'>
            <div className='row p-1'>
              {/* 1 st Column */}
              <div className='col-sm-3 erp_form_col_div'>
                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Month & Year <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6 d-flex">
                    <Form.Control type="text" id='txt_prod_month' className="erp_input_field" value={txt_prod_month} disabled />
                    <Form.Control type="text" id='txt_prod_year' className="erp_input_field ms-1" value={txt_prod_year} disabled />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Insp.Plant Name <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields(); }}>
                      <option value="" disabled>Select</option>
                      {plantOptions.length !== 0 ? (
                        <>{plantOptions?.map(plant => (
                          <option value={plant.field_id}>{plant.field_name}</option>
                        ))} </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Insp.Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id'); validateFields(); }}>
                      <option value="">Select</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map(production => (
                            <option value={production.field_id}>{production.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Insp.Sub Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { setProdSubSection(e.target.value); fillAutoNo(); validateFields(); }}>
                      <option value="">Select</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map(subproduction => (
                            <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_type} value={subproduction.field_id}>
                              {subproduction.field_name}
                            </option>
                          ))}
                        </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


              </div>

              <div className='col-sm-4 erp_form_col_div'>

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Insp. Date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="date" id='dt_inspection_production_date' className="erp_input_field" value={dt_inspection_production_date} onChange={(e) => { setProdPlanDate(e.target.value); setMonthYear(); fillAutoNo(); validateFields(); FnGetShiftDetails(); }} />
                    <MDTypography variant="button" id="error_dt_inspection_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Insp.Code<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control type="text" className="erp_input_field" id="cmb_inspection_production_code" maxLength="5" value={cmb_inspection_production_code} disabled onInput={() => validateFields()} />
                    <MDTypography variant="button" id="error_cmb_inspection_production_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Insp.supervisor <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Select ref={cmb_prod_supervisor_id_ref}
                      options={machineOperatorOptions}
                      isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      inputId="cmb_prod_supervisor_id" // Provide the ID for the input box
                      value={machineOperatorOptions.length > 1 ? machineOperatorOptions.find(option => option.value === cmb_prod_supervisor_id) : null}
                      onChange={(selectedOpt) => {
                        setProdSupervisor(selectedOpt.value);
                        cmb_prod_supervisor_id_ref.current = selectedOpt;
                      }}
                      placeholder="Search Supervisor here..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                    />
                    {/* <select id="cmb_prod_supervisor_id" className="form-select form-select-sm" value={cmb_prod_supervisor_id} onChange={(e) => { setProdSupervisor(e.target.value); validateFields(); }}>
                      <option value="">Select</option>
                      {machineOperatorOptions.length !== 0 ? (
                        <>
                          {machineOperatorOptions?.map(supervisor => (
                            <option value={supervisor.field_id}>{supervisor.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select> */}
                    <MDTypography variant="button" id="error_cmb_prod_supervisor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>
              {/* <div className='col-sm-1 erp_form_col_div mt-2'></div> */}

              <div className="col-sm-5 px-lg-2 d-block">
                <div className="col-lg-12 col-12 erp_form_col_div">
                  <div className="card">
                    <div className="card-header py-0 main_heding mb-0">
                      <label className="erp-form-label-md-lg">Weaving Inspection Summary</label>
                    </div>
                    {/* Card's Body Starts*/}
                    <div className="card-body p-0">
                      <div className={`table-responsive`}>
                        {renderproductionsummary}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>

          <MDBox pt={3} pb={8} className="erp_form_tab_div">
            <Grid justifyContent="center" sx={{ my: 4 }}>
              <Grid item xs={12} lg={8} className="erp_form_container_tabs">
                <MDBox mt={-3} mx={2}>
                  <Tabs
                    activeKey={activeStep}
                    id="uncontrolled-tab-example"
                    className="mb-3 selectedTab erp-form-Tabs"
                    onSelect={eventKey => moveByTabs(eventKey)}>
                    {steps.map((label, index) => (
                      <Tab eventKey={index} title={label}></Tab>
                    ))}
                  </Tabs>
                  {getStepContent()}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          {/*..............Shiftwise Machine Wastage Entry............................... */}
          <div className="col-lg-12 col-12 erp_form_col_div">
            <div className="card">
              <div className="card-header py-0 main_heding mb-0">
                <label className="erp-form-label-md-lg">Shiftwise Machine Wastage Entry</label>
              </div>
              {/* Card's Body Starts*/}
              <div className="card-body p-0">

                <form id='wastageFormId'>
                  <div className='row p-1'>
                    <div className='col-sm-4 erp_form_col_div'>
                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Prod. Wastage Type<span className="required">*</span> </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_prod_wastage_type" className="form-select form-select-sm" value={cmb_prod_wastage_type} onChange={(e) => { calculateMachineWastage(''); validateFields(); }}>
                            <option value="">Select</option>
                            {prodWastageOptions.length !== 0 ? (
                              <>
                                {prodWastageOptions?.map(machine => (
                                  <option value={machine.field_id}>{machine.field_name}</option>
                                ))}
                              </>
                            ) : null

                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_prod_wastage_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Wastage Quantity<span className="required">*</span> </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control type="text" id='txt_wastage_quantity' className="erp_input_field" value={txt_wastage_quantity} onChange={(e) => { setWastageQuantity(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); validateFields(); }} />
                          <MDTypography variant="button" id="error_txt_wastage_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Godown<span className="required">*</span> </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_godown_id" className="form-select form-select-sm" value={cmb_godown_id} onChange={(e) => { setGodownId(e.target.value); validateFields(); }}>
                            <option value="">Select</option>
                            {godownNameOptions.length !== 0 ? (
                              <>
                                {godownNameOptions?.map(godownType => (
                                  <option value={godownType.field_id}>{godownType.godown_name}</option>
                                ))}
                              </>
                            ) : null

                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-5'>
                          <Form.Label className="erp-form-label">Shift <span className="required">*</span> </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select id="cmb_shift_wastage" className="form-select form-select-sm" value={cmb_shift_wastage} onChange={(e) => { setShiftWastage(e.target.value); validateFields(); }} >
                            <option value="">Select</option>
                            {shiftOptions.length !== 0 ? (
                              <>
                                {shiftOptions?.map(shift => (
                                  <option value={shift.field_name}>{shift.field_name}</option>
                                ))}
                              </>
                            ) : null
                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>

                      <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                        <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnUpdateWastageReason('', '', 'add')} fontWeight="regular">{labelForWastage}</MDButton>
                        <MDButton type='button' className="erp-gb-button ms-2 disable-button" variant="button" id='back_Button' onClick={(e) => clearFormFields('wastage')} fontWeight="regular" >Clear</MDButton>
                      </div >
                    </div>

                    <div className='col-sm-6 erp_form_col_div'>
                      <div className='row'>
                        {renderMachineWastageTable}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="card-footer mb-5 py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Transactions/TWeavingInspection/FrmTWeavingInspectionListing/reg' : '/Transactions/TWeavingInspection/FrmTWeavingInspectionListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" >Back</MDButton>
            <MDButton type="submit" id="approveBtn" onClick={(e) => addWeavingInspection(e)} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>

          </div >

        </div>
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      </DashboardLayout >
    </>
  )
}

export default FrmTWeavingInspectionEntry
