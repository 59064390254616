import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $, { event } from 'jquery';
import html2pdf from 'html2pdf.js';


// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";

// import valid formate
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMBOMEntry from "FrmGeneric/MBOMFilter/FrmMBOMEntry";
import Datatable from "components/DataTable";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { FiPrinter } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import Enquiry from "FrmGeneric/Invoice/EnquiryInvoice";

//import Branch from "Masters/MCompany/FrmCompany/components/FrmMBranchEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { UserId } from "assets/Constants/config-constant";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ConfigConstants from "assets/Constants/config-constant";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import ProductList from "FrmGeneric/ProductList";
import MailSentModal from "components/Modals/MailSentModal";
import { RxCrossCircled } from "react-icons/rx";

//FrmEnquiryProjectEntry
export default function FrmEnquiryProjectEntry(prop) {
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //this  hook is used for thesxt the fild nodaes 
    const currentDate = new Date().toISOString().split('T')[0];

    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false);

    let docGroup = "Project Enquiry"
    let commonTermMstName = 'Enquiry Commercial Terms'


    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType, deletionKey = false } = state || {};

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    var updateData = idList;

    // For navigate
    const navigate = useNavigate();

    const [expected_schedule_grace_days, setExpectedScheduleGraceDays] = useState(0);
    let expectedScheduleGraceDays = 0;

    // For Add New Record Popup modal.
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // For Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    // For Delete Popup modal.
    const [showDocumentForm, setShowDocumentForm] = useState(false);

    // For Error modal
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [modalOrderDetails, setModalOrderDetails] = useState('')


    // For Success modal
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectListing`);
        }
    }

    // Option Boxes Hooks.
    const [customerOptions, setCustomerOptions] = useState([]);
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [departmentIdOptions, setDepartmentIdOptions] = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);
    const [productUnitOptions, setProductUnitOptions] = useState([]);
    const [productPackingOptions, setProductPackingOptions] = useState([]);
    const [productHSNOptions, setProductHSNOptions] = useState([]);
    const [enquiryByIdOptions, setEnquiryByIdOptions] = useState([])
    const [assignToHead, setAssignToHead] = useState([]);
    const [assignToEmployeeOpts, setAssignToEmployeeOpts] = useState([]);
    const [approvedbyid, setApproved] = useState([]);

    // Forms Releated Hooks.
    const [enquiry_master_transaction_id, setEnquiryMasterTransactionId] = useState(0);
    const [txt_enquiry_no, setEnquiryno] = useState(0);
    const [txt_enquiry_version, setEnquiryVersion] = useState(1);
    const [txt_enquiry_date, setEnquiryDate] = useState(today);
    const [cmb_enquiry_type_id, setEnquiryTypeId] = useState('4');
    const [enquiry_type, setEnquiryType] = useState('PR');
    const [rb_enquiry_life, setEnquiryOrdLife] = useState('C');
    const [cmb_enquiry_status, setEnquiryStatus] = useState('P');
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_customer_state_id, setCustomerStateId] = useState('');
    const [txt_customer_state_name, setCustomerStateName] = useState('');
    const [cmb_customer_city_id, setCustomerCityId] = useState('');
    const [txt_customer_city_name, setCustomerCityName] = useState('');
    const [cmb_expected_branch_id, setExpectedBranchId] = useState();
    const [cmb_expected_branch_state_id, setExpectedBranchStateId] = useState("");
    const [txt_expected_branch_state_name, setExpectedBranchStateName] = useState("");
    const [cmb_expected_branch_city_id, setExpectedBranchCityId] = useState("");
    const [txt_expected_branch_city_name, setExpectedBranchCityName] = useState("");
    const [txt_project_name, setProjectName] = useState('');
    const [txt_project_referance_number, setProjectReferanceNumber] = useState('');
    const [date_project_start_date, setProjectStartDate] = useState(today);
    const [date_project_end_date, setProjectEndDate] = useState('');

    const [cmb_department, setDepartmentId] = useState('');
    const [cmb_enquiry_by, setEnquiryById] = useState('');
    const [cmb_assign_to_id, setAssigToId] = useState('');
    const [cmb_assign_to_head_id, setAssigntoHeadId] = useState('');
    const [cmb_approved_by_id, setApprovedById] = useState('');
    const [dt_approved_date, setApprovedDate] = useState('');

    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState(today);
    const [cmb_agent_id, setAgentId] = useState('');
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [txt_status_remark, setStatusRemark] = useState('');
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [rb_enquiry_mail_sent_status, setEnquiryMailSentStatus] = useState('P');
    const [rb_is_active, setIsActive] = useState('true');

    // **** All Listing table's data hooks.
    const [custContactDetails, setCustContactDetails] = useState([]);               // Customers contact details table data hooks
    const [enquiryItemsDetailsData, setEnquiryItemsDetailsData] = useState([]);     // Enquiry Items Table Data.
    const [enquiryTermsdata, setEnquiryTermsdata] = useState([]);                   // Enquiryterms Table Data
    const [enqTermsResponse, setEnqTermsResponse] = useState([]);                   // From update response.
    const [uploadedDocumentData, setUploadedDocumentData] = useState([]);           // For Documents table.
    const [existingFunctionalityData, setExistingFunctionalityData] = useState([]); // Enquiry Items Table Data.

    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldsByUserAccess();
            await FnFillDataOnLoad();
            if (updateData !== null) {
                await FnCheckUpdateResponce();
            } else {
                generateEnquiryNo(enquiry_type);    // Generate the latest enquiry no.
            }
            FnSetFieldsByUserAccess();
        } catch (error) {
            console.error(error);
            navigate('/Error')
            
        } finally {
            setIsLoading(false);
        }
    }, [])

    const FnSetFieldsByUserAccess = () => {
        // User Access Manage.
        let userAccessKey = keyForViewUpdate;
        switch (userAccessKey) {
            case 'update':
                setIsApprove(false);
                $('#submain_heading').append('');
                $('#submain_heading').text('(Modification)');
                $('#cmb_customer_id').attr('disabled', true);
                $('#cmb_expected_branch_id').attr('disabled', true);
                $('#save-btn').text('Update');
                $('#save-btn').attr('BtnType', 'update');
                break;

            case 'view':
                setIsApprove(false);
                $('#submain_heading').append('');
                if (deletionKey) {
                    $('#submain_heading').text('(Delete)');
                } else {
                    $('#submain_heading').text('(View)');
                }
                $("input[type=radio]").attr('disabled', true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('#selectAllCustContact').attr('disabled', true);
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                $("input[name=checkEnquiryTerms]").attr('disabled', true);

                break;

            case 'approve':
                $('#submain_heading').append('');
                $('#submain_heading').text('(Approval)');
                $('#save-btn').text('Approve');
                $('#save-btn').attr('BtnType', 'approve');
                $('#chkAllEnqTerms').attr('disabled', true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                $('#selectAllCustContact').attr('disabled', true);
                $("input[type=radio]").attr('disabled', true);
                $("input[name=checkEnquiryTerms]").attr('disabled', true);
                $('#txt_status_remark').removeAttr("disabled");
                $('#txt_status_remark').removeAttr("readonly");
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                setApprovedDate(today);
                setIsApprove(true)
                break;

            case 'copy':
                $('#submain_heading').append('');
                $('#submain_heading').text('(Copy)');
                break;

            default:
                break;
        }
    }

    const generateEnquiryNo = async (enquiry_type) => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_enquiry_master_project", "enquiry_no", enquiry_type, 'EQ', "6");
        setEnquiryno(autoNoApiCall);
        setEnquiryVersion(1);
        return autoNoApiCall;
    };

    // *****-------------------------- All Combos Releated Functions starts.---------------------------
    const FnFillDataOnLoad = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns.push('so_schedule_grace_days');
            globalQuery.table = "am_company_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getScheduleGraceDaysApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            expectedScheduleGraceDays = getScheduleGraceDaysApiCall.length === 0 ? 0 : getScheduleGraceDaysApiCall[0].so_schedule_grace_days;
            setExpectedScheduleGraceDays(expectedScheduleGraceDays)
            setOverAllScheduleDate(FnGetNextDate(expectedScheduleGraceDays));

            // Get the customer's data.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOptions(getCustomersApiCall);

            // Loading the expected branch data.
            resetGlobalQuery();
            globalQuery.columns = ['company_branch_id', 'company_branch_name', 'branch_state_id', 'state_name', 'branch_city_id', 'city_name', 'branch_EmailId'];
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setExpectedBranchOptions(exptBranchApiCall);
            if (exptBranchApiCall !== null) {
                setExpectedBranchId(COMPANY_BRANCH_ID);
                const loggedInCompanyBranchObj = exptBranchApiCall.find(companyBranch => companyBranch.company_branch_id === parseInt(COMPANY_BRANCH_ID));
                setExpectedBranchStateId(loggedInCompanyBranchObj.branch_state_id);
                setExpectedBranchStateName(loggedInCompanyBranchObj.state_name);
                setExpectedBranchCityId(loggedInCompanyBranchObj.branch_city_id);
                setExpectedBranchCityName(loggedInCompanyBranchObj.city_name);
            }

            // set the department options.
            const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
            setDepartmentIdOptions(departmentIdApiCall);
            // Set the default Department-option.
            const defaultDepartment = departmentIdApiCall.find(department => department.field_name === "Marketing & Proposals Department");
            if (defaultDepartment) { // Set the default department.
                setDepartmentId(defaultDepartment.field_id);
                // Load the enquiry-by list.
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "department_id", operator: "=", value: defaultDepartment.field_id });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setEnquiryByIdOptions(enquirybyIdApiCall)
            }

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setAssignToEmployeeOpts(getEmployeeListApiCall);
            setAssignToHead(getEmployeeListApiCall);
            setApproved(getEmployeeListApiCall);

            // Set the logged in employee as a assigned employee and it's head employee.
            setAssigToId(parseInt(UserId));
            const defaultAssignedEmployee = getEmployeeListApiCall.find(employee => parseInt(employee.field_id) === parseInt(UserId));
            if (defaultAssignedEmployee) {   // Set Head Name for Assigned Employee. // reporting_to
                setAssigntoHeadId(defaultAssignedEmployee.reporting_to);
            }

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
            globalQuery.table = "cmv_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getAgentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setAgentOptions(getAgentApiCall);
            // Set the Default Agent as NA and load it's releated %.
            const defaultAgent = getAgentApiCall.find(agent => agent.field_name === 'NA');
            if (defaultAgent) {   // Set Head Name for Assigned Employee. // reporting_to
                setAgentId(defaultAgent.field_id);
                setAgentPercent(defaultAgent.agent_std_percent);
            }

            const getProductUnitsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit ", "is_delete", "0");
            setProductUnitOptions(getProductUnitsApiCall);

            const getProductPackingsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_packing ", "is_delete", "0");
            setProductPackingOptions(getProductPackingsApiCall);

            const getProductHSNsApiCall = await comboDataAPiCall.current.fillMasterData("cmv_hsn_sac ", "is_delete", "0");
            setProductHSNOptions(getProductHSNsApiCall);

            if (keyForViewUpdate === '') {
                setProjectEndDate(FnGetNextDate(1));        // Hardcoaded 1 day.
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    const FnComboOnChange = async (changedComboName) => {
        try {
            switch (changedComboName) {
                case 'Customer':       // on change set the customer main branch state and city.
                    let selectedCustomer = parseInt($('#cmb_customer_id').val());
                    if ($('#cmb_customer_id').val() === '0') {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                        setCustomerId('');
                        setCustomerStateId('');
                        setCustomerStateName('');
                        setCustomerCityId('');
                        setCustomerCityName('');
                    } else if (selectedCustomer !== "" && selectedCustomer !== NaN) {
                        resetGlobalQuery();
                        globalQuery.columns = ['cust_branch_state_id', 'state_name', 'cust_branch_city_id', 'city_name'];
                        globalQuery.table = "cmv_customer"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (getCustomerDetailsApiCall.length > 0) {
                            setCustomerStateId(getCustomerDetailsApiCall[0].cust_branch_state_id);
                            setCustomerStateName(getCustomerDetailsApiCall[0].state_name);
                            setCustomerCityId(getCustomerDetailsApiCall[0].cust_branch_city_id);
                            setCustomerCityName(getCustomerDetailsApiCall[0].city_name)
                        } else {
                            setCustomerStateId('');
                            setCustomerStateName('');
                            setCustomerCityId('');
                            setCustomerCityName('');
                        }
                        await FnGetCustomersContactPersonsDetails(parseInt(selectedCustomer));
                    } else {
                        setCustomerStateId('');
                        setCustomerStateName('');
                        setCustomerCityId('');
                        setCustomerCityName('');
                    }
                    break;

                case 'CompanyBranch':       // on change set the branch state and city.
                    let selectedCompanyBranch = document.getElementById("cmb_expected_branch_id").value;
                    if (selectedCompanyBranch !== '0' && selectedCompanyBranch !== "") {
                        setExpectedBranchId(selectedCompanyBranch)
                        resetGlobalQuery();
                        globalQuery.columns = ['branch_state_id', 'state_name', 'branch_city_id', 'city_name'];
                        globalQuery.table = "cmv_company_branch"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "company_branch_id", operator: "=", value: selectedCompanyBranch });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (exptBranchApiCall.length > 0) {
                            setExpectedBranchStateId(exptBranchApiCall[0].branch_state_id);
                            setExpectedBranchStateName(exptBranchApiCall[0].state_name);
                            setExpectedBranchCityId(exptBranchApiCall[0].branch_city_id);
                            setExpectedBranchCityName(exptBranchApiCall[0].city_name);
                        }
                    } else {
                        setExpectedBranchStateId('');
                        setExpectedBranchStateName('');
                        setExpectedBranchCityId('');
                        setExpectedBranchCityName('');
                    }
                    break;

                case 'Department':
                    let selectedDepartment = document.getElementById('cmb_department').value;
                    setDepartmentId(selectedDepartment)
                    if (selectedDepartment === "0") {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Department')
                        setShowAddRecModal(true)
                        setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    }
                    if (selectedDepartment !== "" && selectedDepartment !== "0") {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                        globalQuery.table = "cmv_employee"
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setEnquiryByIdOptions(enquirybyIdApiCall)
                        if (enquirybyIdApiCall.length <= 0) {
                            setEnquiryById('');
                        }
                        $('#error_cmb_department').hide();
                    } else {
                        setEnquiryByIdOptions([])
                        setEnquiryById('');
                    }
                    break;

                case 'EnquiryBy':
                    let enquiryByEmployee = document.getElementById('cmb_enquiry_by').value;
                    if (enquiryByEmployee === "0") {
                        const newTab = window.open('/Masters/Employees', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    }
                    break;

                case 'AssignToemployee':
                    let assignedoToEmployee = document.getElementById('cmb_assign_to_id').value;
                    if (assignedoToEmployee === "0") {
                        const newTab = window.open('/Masters/Employees', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    }
                    if (assignedoToEmployee !== "0" && assignedoToEmployee !== "") {
                        // Set the it's head-name.
                        const assignedEmployee = assignToEmployeeOpts.find(employee => parseInt(employee.field_id) === parseInt(assignedoToEmployee));
                        if (assignedEmployee) { // Set Head Name for Assigned Employee. // reporting_to
                            setAssigntoHeadId(assignedEmployee.reporting_to);
                        } else {
                            setAssigntoHeadId('');
                        }
                    } else {
                        setAssigntoHeadId('');
                    }
                    break;

                case 'AssignToHead':
                    let assignedoToHead = document.getElementById('cmb_assign_to_head_id').value;
                    if (assignedoToHead === "0") {
                        const newTab = window.open('/Masters/Employees', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    }
                    break;

                case 'Agent': // On Agent change set the agent's std %;
                    let selectedAgent = $('#cmb_agent_id').val();
                    if ($('#cmb_agent_id').val() === "0") {
                        const newTab = window.open('/Masters/Agent', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (selectedAgent !== '0' && selectedAgent !== '') {
                        const selectedAgentData = agentOptions.find(agent => parseInt(agent.field_id) === parseInt(selectedAgent));
                        if (selectedAgentData) {
                            setAgentPercent(selectedAgentData.agent_std_percent);
                        } else {
                            setAgentPercent(0);
                        }
                    } else {
                        setAgentPercent(0);
                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // For Update the add new record Modal.
    const FnOpenAddNewRecordModal = () => {
        switch (modalHeaderName) {
            case 'Department':
                return <FrmDepartmentEntry btn_disabled={true} />;

            case 'Common Parameter Term':
                return <CommonParamterEntry master_name={commonTermMstName} btn_disabled={true} />;

            default:
                return null;
        }
    }

    // For update the combobox data after add new record.
    const FnRefreshComboData = async (key) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
            return false;
        }
        switch (key) {
            case 'Customer':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setCustomerOptions(getUpdatedCustomerData);
                break;

            case 'EnquiryBy':
                // Load the enquiry-by list.
                if (cmb_department !== '' && cmb_department !== '0') {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const enquirybyIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    setEnquiryByIdOptions(enquirybyIdApiCall)
                }
                break;

            case 'AssignToemployee':
            case 'AssignToHead':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAssignToHeadData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setAssignToHead(getUpdatedAssignToHeadData);
                setAssignToEmployeeOpts(getUpdatedAssignToHeadData);
                break;

            case 'Agent':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name']
                globalQuery.table = "cmv_agent"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAgentData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setAgentOptions(getUpdatedAgentData);
                break;

            default:
                break;
        }
    }

    const FnRefreshComboDataOnModalClose = async () => {
        switch (modalHeaderName) {
            case 'Department':
                const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0")
                setDepartmentIdOptions(departmentIdApiCall)
                break;

            case 'Common Parameter Term':
                await FnGetDefaultEnquiryTerms();
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
    }

    // *****-------------------------- All Combos Releated Functions Ends.---------------------------


    // ********************************** Details Table Data Handling Functions Starts. **********************************************************************
    const [enquiryItemRowCounter, setEnquiryItemRowCounter] = useState(1);

    const blankEnqItemObj = {
        product_material_id: '',
        product_material_name: '',
        product_type_short_name: 'PR',
        product_material_tech_spect: '',
        product_material_unit_id: 6,
        product_material_packing_id: 1,
        product_material_hsn_id: 1,
        product_material_enquiry_quantity: 1,
        product_material_enquiry_weight: 0,
        product_material_moq_quantity: 1,
        product_material_notes: '',
        product_material_conversion_factor: 1,
        expected_lead_time: 1,
        material_std_rate: 0,
        material_schedule_date: dt_overall_schedule_date,
        enquiry_item_status: 'P',
        material_enquiry_approval_remark: '',
        remark: '',
    }

    useLayoutEffect(() => {
        const enquiryItems = [...enquiryItemsDetailsData]
        enquiryItems.push(blankEnqItemObj)
        setEnquiryItemsDetailsData(enquiryItems)
    }, [enquiryItemRowCounter]);

    const renderDetailsTable = useMemo(() => {
        return <> {enquiryItemsDetailsData.length > 0
            ? <> <Table className="erp_table erp_table_scroll" id='erp-enquirydetails-table' bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>Action</th>
                        <th className="erp_table_th">Sr. No.</th>
                        <th className="erp_table_th">Product Type</th>
                        <th className="erp_table_th" style={{ paddingRight: '100px' }}>Material Name</th>
                        <th className="erp_table_th" style={{ paddingRight: '40px' }}>Technical Specification</th>
                        <th className="erp_table_th" style={{ paddingRight: '40px' }}>Unit Name</th>
                        <th className="erp_table_th" >Packing Name</th>
                        <th className="erp_table_th" style={{ paddingRight: '40px' }}>HSN Code</th>
                        <th className="erp_table_th">Expec. Sch. Date</th>
                        <th className="erp_table_th">Enquiry Quantity</th>
                        <th className="erp_table_th">MOQ Quantity</th>
                        <th className="erp_table_th">Lead Time</th>
                        <th className="erp_table_th">Material Notes</th>
                        <th className={`erp_table_th`}>Enquiry Item Status</th>
                        <th className={`erp_table_th`}>Enquiry Approval Remark</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th" style={{ paddingRight: '70px' }}>S.T.D Rate</th>
                        <th className="erp_table_th">Material ID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        enquiryItemsDetailsData.map((enquiryMaterial, Index) =>
                            <tr rowIndex={Index}>
                                <td className={`erp_table_td text-center ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>
                                    <IoRemoveCircleOutline className='erp_trRemove_icon' rowIndex={Index} onClick={() => { removeEnquiryMaterial(Index); }} />
                                    <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => { setEnquiryItemRowCounter(enquiryItemRowCounter + 1); }} />
                                </td>
                                <td className={`erp_table_td `}>{Index + 1}</td>
                                <td className={`erp_table_td`}>{enquiryMaterial.product_type_short_name}</td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" Headers='product_material_name' maxLength='1000' id={`product_material_name_${Index}`} className="erp_input_field mb-0 " value={enquiryMaterial.product_material_name}
                                                    onChange={(e) => { FnUpdateEnquiryDetails("product_material_name", enquiryMaterial, e); }}
                                                    onBlur={(e) => { FnUpdateEnquiryDetails("product_material_name", enquiryMaterial, e); }}
                                                // disabled={enquiryMaterial.product_material_id.trim().length > 0 ? true : false}
                                                />
                                            </>
                                            : enquiryMaterial.product_material_name
                                    }
                                </td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" Headers='product_material_tech_spect' maxLength='1000' id={`product_material_tech_spect_${Index}`}
                                                    className="erp_input_field mb-0 " value={enquiryMaterial.product_material_tech_spect} onChange={(e) => { FnUpdateEnquiryDetails("product_material_tech_spect", enquiryMaterial, e); }}
                                                />
                                            </>
                                            : enquiryMaterial.product_material_tech_spect
                                    }
                                </td>
                                <td className={`erp_table_td`}>
                                    <select id={`product_material_unit_${Index}`} value={enquiryMaterial.product_material_unit_id} className="form-select form-select-sm erp_input_field m-0"
                                        Headers="product_material_unit_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_unit_id', enquiryMaterial, e); }} disabled={enquiryMaterial.product_material_id.trim().length > 0 ? true : false}  >
                                        {productUnitOptions?.map(unit => (<option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>))}
                                    </select>
                                </td>
                                <td className="erp_table_td">
                                    <select id={`product_packing_name_${Index}`} value={enquiryMaterial.product_material_packing_id} className="form-select form-select-sm erp_input_field m-0"
                                        Headers="product_material_packing_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_packing_id', enquiryMaterial, e); }} disabled={enquiryMaterial.product_material_id.trim().length > 0 ? true : false}  >
                                        {productPackingOptions?.map(packing => (<option value={packing.field_id} lbl={packing.field_name} >{packing.field_name}</option>))}
                                    </select>
                                </td>
                                <td className="erp_table_td">
                                    <select id={`product_material_hsn_${Index}`} value={enquiryMaterial.product_material_hsn_id} className="form-select form-select-sm erp_input_field m-0"
                                        Headers="product_material_hsn_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_hsn_id', enquiryMaterial, e); }} disabled={enquiryMaterial.product_material_id.trim().length > 0 ? true : false}  >
                                        {productHSNOptions?.map(HSN => (<option value={HSN.field_id} lbl={HSN.field_name} >{HSN.field_name}</option>))}
                                    </select>
                                </td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="date" Headers='material_schedule_date' id={`material_schedule_date_${Index}`} className="erp_input_field mb-0 "
                                                    value={enquiryMaterial.material_schedule_date} min={new Date().toISOString().split('T')[0]} onChange={(e) => { FnUpdateEnquiryDetails("material_schedule_date", enquiryMaterial, e); }} />
                                            </>
                                            : validateNumberDateInput.current.formatDateToDDMMYYYY(enquiryMaterial.material_schedule_date)
                                    }
                                </td>
                                <td className="erp_table_td text-end">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" id={`product_material_enquiry_quantity_${Index}`} className="erp_input_field mb-0 text-end" value={enquiryMaterial.product_material_enquiry_quantity}
                                                    onChange={(e) => { FnUpdateEnquiryDetails("product_material_enquiry_quantity", enquiryMaterial, e); }} onBlur={(e) => { FnUpdateEnquiryDetails("product_material_enquiry_quantity", enquiryMaterial, e); }}
                                                    maxLength='19' disabled={isApprove === true ? true : false} Headers='product_material_enquiry_quantity' />
                                            </>
                                            : enquiryMaterial.product_material_enquiry_quantity
                                    }
                                </td>
                                <td className="erp_table_td text-end">{enquiryMaterial.product_material_moq_quantity}</td>
                                <td className="erp_table_td text-end">{enquiryMaterial.expected_lead_time}</td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" Headers='product_material_notes' maxLength='1000' id={`product_material_notes_${Index}`}
                                                    className="erp_input_field_table_txt mb-0 " value={enquiryMaterial.product_material_notes} onChange={(e) => { FnUpdateEnquiryDetails("product_material_notes", enquiryMaterial, e); }}
                                                />
                                            </>
                                            : enquiryMaterial.product_material_notes
                                    }
                                </td>
                                <td className="erp_table_td approveField">
                                    <select id={`enquiry_item_status_${enquiryMaterial.product_rm_id}`} className="form-select form-select-sm approveField m-0" value={enquiryMaterial.enquiry_item_status}
                                        onChange={(e) => { FnUpdateEnquiryDetails("enquiry_item_status", enquiryMaterial, e); }} onBlur={(e) => { FnUpdateEnquiryDetails("enquiry_item_status", enquiryMaterial, e); }}
                                        disabled={!isApprove} Headers='enquiry_item_status'>
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                        <option value="R">Rejected</option>
                                        <option value="I">Partial Issue</option>
                                        <option value="C">Completed</option>
                                        <option value="X">Canceled</option>
                                        <option value="Z">PreeClosed</option>
                                    </select>
                                </td>
                                <td className={`erp_table_td approveField`}>
                                    {
                                        keyForViewUpdate === 'approve'
                                            ? <>
                                                <input type="text" id={`material_enquiry_approval_remark_${Index}`} Headers='material_enquiry_approval_remark'
                                                    className="erp_input_field_table_txt mb-0  approveField" value={enquiryMaterial.material_enquiry_approval_remark}
                                                    onChange={(e) => { FnUpdateEnquiryDetails("material_enquiry_approval_remark", enquiryMaterial, e); }} maxLength='500' />
                                            </>
                                            : enquiryMaterial.material_enquiry_approval_remark
                                    }
                                </td>
                                < td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" maxLength='1000' id={`remark_${Index}`} Headers='remark' className="erp_input_field_table_txt mb-0"
                                                    value={enquiryMaterial.remark} onChange={(e) => { FnUpdateEnquiryDetails("remark", enquiryMaterial, e); }} />
                                            </>
                                            : enquiryMaterial.remark
                                    }
                                </td>
                                <td className="erp_table_td text-end">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" id={`material_std_rate_${Index}`} className="erp_input_field_table_txt mb-0 text-end" value={enquiryMaterial.material_std_rate}
                                                    onChange={(e) => { FnUpdateEnquiryDetails("material_std_rate", enquiryMaterial, e); }} onBlur={(e) => { FnUpdateEnquiryDetails("material_std_rate", enquiryMaterial, e); }}
                                                    maxLength='19' disabled={isApprove === true ? true : false} Headers='material_std_rate' />
                                            </>
                                            : enquiryMaterial.material_std_rate
                                    }

                                </td>
                                <td className={`erp_table_td`}>{enquiryMaterial.product_material_id}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            </>
            : null
        }
        </>
    }, [enquiryItemsDetailsData, productUnitOptions, productPackingOptions, productHSNOptions]);

    // Function for update the details table data.
    const FnUpdateEnquiryDetails = async (inputFieldKey, currentMaterial, event) => {
        let enquiryItemArrayIndex = event.target.parentElement.parentElement.getAttribute('rowIndex');
        switch (inputFieldKey) {
            case 'product_material_enquiry_quantity':
                if (event._reactName === 'onChange') {
                    currentMaterial[inputFieldKey] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                    delete event.target.parentElement.dataset.tip;
                }
                break;

            case 'material_std_rate':
                currentMaterial[inputFieldKey] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                delete event.target.parentElement.dataset.tip;
                break;

            case 'product_material_name':
            case 'material_schedule_date':
            case 'product_material_notes':
            case 'product_material_tech_spect':
            case 'material_enquiry_approval_remark':
            case 'product_material_unit_id':
            case 'product_material_packing_id':
            case 'product_material_hsn_id':
            case 'remark':
                currentMaterial[inputFieldKey] = event.target.value
                delete event.target.parentElement.dataset.tip;
                break;

            case 'enquiry_item_status':
                debugger
                currentMaterial['enquiry_item_status'] = event.target.value
                // On change remove the tooltip on approval remark.
                if (event.target.value !== 'A') {
                    let approvalRemarkField = $(`#material_enquiry_approval_remark_` + enquiryItemArrayIndex);
                    delete approvalRemarkField[0].parentElement.dataset.tip
                }
                break;

            default:
                break;
        }

        if (inputFieldKey === 'product_material_name' && event._reactName === 'onBlur') {
            if (currentMaterial.product_material_name.trim() !== '') {
                await FnCheckEnquiryItemIsExist(currentMaterial);
            } else {
                currentMaterial.product_material_id = '';
            }
        }

        // updateObjectAtIndex(index, currentMaterial, data)
        const updatedDetailsData = [...enquiryItemsDetailsData]
        updatedDetailsData[enquiryItemArrayIndex] = currentMaterial;
        setEnquiryItemsDetailsData(updatedDetailsData);

        // Check that material is already exist or not?
        if (inputFieldKey === 'product_material_name' && event._reactName === 'onBlur') {
            const duplicateRecords = FnCheckMaterialIsDuplicateInDetails(updatedDetailsData);
            if (duplicateRecords.length > 0) {
                duplicateRecords.forEach(record => {
                    let duplicateMaterialInputField = $(`#product_material_name_${record.index}`)[0];
                    duplicateMaterialInputField.parentElement.dataset.tip = 'This item is already exists...!'
                });
            } else {
                $('input[id^="product_material_name_"]').each(function () {
                    delete this.parentElement.dataset.tip
                });
            }
        }

    };

    const removeEnquiryMaterial = (indexToRemove) => {
        let updatedDetailsData = [...enquiryItemsDetailsData];
        if (indexToRemove !== 0) {
            updatedDetailsData = enquiryItemsDetailsData.filter((item, index) => index !== indexToRemove);
            setEnquiryItemsDetailsData(updatedDetailsData);
        } else {
            updatedDetailsData = [...enquiryItemsDetailsData]
            updatedDetailsData[0] = { ...blankEnqItemObj }
            setEnquiryItemsDetailsData(updatedDetailsData)
        }

        // Check Duplicates material for remove the tooltip.
        $('input[id^="product_material_name_"]').each(function () {
            delete this.parentElement.dataset.tip
        });
        const duplicateRecords = FnCheckMaterialIsDuplicateInDetails(updatedDetailsData);
        if (duplicateRecords.length > 0) {
            duplicateRecords.forEach(record => {
                let duplicateMaterialInputField = $(`#product_material_name_${record.index}`)[0];
                duplicateMaterialInputField.parentElement.dataset.tip = 'This item is already exists...!'
            });
        }
    }

    // Function to check that that material is exist in sm_product_pr using product_pr_name is exist or not? 
    const FnCheckEnquiryItemIsExist = async (currentEnquiryItem) => {
        // Here if it is exist then show it's saved data from sm_product_pr otherwise save that item.
        try {
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "sm_product_pr"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_pr_name", operator: "=", value: currentEnquiryItem.product_material_name });
            const getExistingPrMaterial = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            if (getExistingPrMaterial.length > 0) {
                let existingPrMaterial = getExistingPrMaterial[0];
                currentEnquiryItem.product_material_id = existingPrMaterial.product_pr_id;
                currentEnquiryItem.product_material_name = existingPrMaterial.product_pr_name;
                currentEnquiryItem.product_material_tech_spect = existingPrMaterial.product_pr_tech_spect;
                currentEnquiryItem.product_material_hsn_id = existingPrMaterial.product_pr_hsn_sac_code_id;
                currentEnquiryItem.product_material_unit_id = existingPrMaterial.product_pr_sales_unit_id;
                currentEnquiryItem.product_material_packing_id = existingPrMaterial.product_pr_packing_id;
            } else {
                currentEnquiryItem.product_material_id = '';
            }
            return currentEnquiryItem;
        } catch (error) {
            console.log("error: ", error)
        }
    }

    // For check the material is duplicate or not in details?
    const FnCheckMaterialIsDuplicateInDetails = (enquiryItemsData) => {
        const seenMaterialNames = {};
        const duplicateMaterials = [];
        enquiryItemsData.forEach((item, index) => {
            const materialName = item.product_material_name;
            if (materialName !== undefined && materialName.trim() !== "") {
                if (seenMaterialNames[materialName] !== undefined) {
                    duplicateMaterials.push({ materialName, index, duplicateIndex: seenMaterialNames[materialName] });
                } else {
                    seenMaterialNames[materialName] = index;
                }
            }
        });
        return duplicateMaterials;
    }

    const FnConvertObjectKeys = (convertFrom, recievedData) => {
        switch (convertFrom) {
            case 'EnquiryDetail2ProductPr':
                let convertedDataEnquiryDetail2ProductPr = [];
                // Iterate the loop on received data and change the keys.
                recievedData?.map((enquiryMaterial, index) => {
                    let hsnId = enquiryMaterial.product_material_hsn_id !== '' && enquiryMaterial.product_material_hsn_id !== undefined ? enquiryMaterial.product_material_hsn_id : 1;
                    let unitId = enquiryMaterial.product_material_unit_id !== '' && enquiryMaterial.product_material_unit_id !== undefined ? enquiryMaterial.product_material_unit_id : 6;
                    let packingId = enquiryMaterial.product_material_packing_id !== '' && enquiryMaterial.product_material_packing_id !== undefined ? enquiryMaterial.product_material_packing_id : 1;
                    let requiredJSONForProductPr = {
                        product_id: 0,
                        product_pr_id: '',
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        product_type_id: 6,         // Hardcoaded value for PR from sm_product_type
                        product_category1_id: 6,    // Default value NA from smv_product_category1 for projects.
                        product_pr_code: '',
                        product_pr_name: enquiryMaterial.product_material_name,
                        project_name: txt_project_name,
                        project_reference_number: txt_project_referance_number,
                        project_start_date: date_project_start_date,
                        project_end_date: date_project_end_date,
                        product_pr_short_name: '',
                        product_pr_print_name: enquiryMaterial.product_material_name,
                        product_pr_tech_spect: enquiryMaterial.product_material_tech_spect,
                        product_pr_oem_part_code: '',
                        product_pr_our_part_code: '',
                        product_pr_item_sr_no: '',
                        product_pr_drawing_no: '',
                        product_pr_model_no: '',
                        product_pr_hsn_sac_code_id: hsnId,
                        product_pr_purchase_unit_id: unitId,
                        product_pr_sales_unit_id: unitId,
                        product_pr_stock_unit_id: unitId,
                        product_pr_packing_id: packingId,
                        product_pr_bar_code: '',
                        product_pr_qr_code: '',
                        product_consumption_mode: 'ALL',
                        product_origin_type: 'Indigenous',
                        godown_id: 1,
                        godown_section_id: 1,
                        godown_section_beans_id: 1,
                        product_origin_country: 'India',
                        bom_applicable: 'No',
                        remark: '',
                        is_active: rb_is_active,
                        created_by: UserName
                    }
                    convertedDataEnquiryDetail2ProductPr.push(requiredJSONForProductPr);
                });
                return convertedDataEnquiryDetail2ProductPr

            case 'ProductPr2EnquiryDetail':

                break;
            default:
                return recievedData;
        }
    }

    // ********************************** Details Data Handling Functions Ends. **********************************************************************


    // ********************************** All Table Data Handling Functions Starts. **********************************************************************
    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);
                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                return getContactDtlsApiCall;
            } else {
                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            FnManageCheckboxesSelection('PartiallyCustContactSelection');
        });
    }

    // ** Functions for the common terms Starts.
    const FnGetDefaultEnquiryTerms = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            const enquiryTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const responce = await enquiryTermsApiCall.json();
            if (responce.content.length !== 0) {
                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm,
                    common_parameters_expected_value: commonTerm.common_parameters_value,
                    remark: '',
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonTermsData = [...enqTermsResponse];
                let renderedCommonTermsData = responce.content
                for (let existCommonCounter = 0; existCommonCounter < existingCommonTermsData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonTermsData[existCommonCounter].enquiry_terms_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.common_parameters_id === existingCommonTermId);
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonTermsData[existCommonCounter].enquiry_terms_parameters_expected_value;
                        renderedCommonTermsData[0].remark = existingCommonTermsData[existCommonCounter].remark;
                    }
                }
                setEnquiryTermsdata(renderedCommonTermsData);
                setTimeout(() => {
                    existingCommonTermsData.forEach(function (existingCommonTerm) {
                        $('#checkEnquiryTerms_' + existingCommonTerm.enquiry_terms_parameters_id).prop('checked', true);
                    });
                }, 200);
            }
        } catch (error) {
            console.log("error: ", error)
        }
        $('.checkEnquiryTerms').on('change', function () {
            FnManageCheckboxesSelection('PartiallyCommonTermSelection');
        });
    }

    const renderTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='erp-enquiryterms-table' bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        {/* <th className="erp_table_th">Common Parameters Master </th> */}
                        <th className="erp_table_th">Technical Parameters Term </th>
                        <th className="erp_table_th">Technical Parameters Value</th>
                        <th className="erp_table_th">Technical Prameters Expected Value</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        enquiryTermsdata.map((enquiryTerms, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td">
                                    <input type="checkbox" className="checkEnquiryTerms" name="checkEnquiryTerms"
                                        id={'checkEnquiryTerms_' + enquiryTerms.common_parameters_id} value={enquiryTerms.common_parameters_id}
                                        onChange={() => FnManageCheckboxesSelection('PartiallyCommonTermSelection')}
                                        disabled={isApprove || keyForViewUpdate === 'view' ? true : false} />
                                </td>
                                {/* <td className="erp_table_td">{enquiryTerms.common_parameters_master_name}</td> */}
                                <td className="erp_table_td">{enquiryTerms.common_parameters_name}</td>
                                <td className="erp_table_td">{enquiryTerms.common_parameters_value}</td>

                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <>
                                                <input type="text" id={`common_parameters_expected_value_${enquiryTerms.common_parameters_id}`}
                                                    className="erp_input_field mb-0 " maxLength='255' value={enquiryTerms.common_parameters_expected_value}
                                                    onChange={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} onBlur={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} Headers='common_parameters_expected_value' />
                                            </>
                                            : enquiryTerms.common_parameters_expected_value
                                    }
                                </td>

                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <>
                                                <input type="text" id={`remark_${enquiryTerms.common_parameters_id}`}
                                                    className="erp_input_field mb-0 " value={enquiryTerms.remark} maxLength='255'
                                                    onChange={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} onBlur={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} Headers='remark' />
                                            </>
                                            : enquiryTerms.remark
                                    }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [enquiryTermsdata]);

    // For update the common terms table data.
    const updateEnquiryCommonTermsTblData = (row, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        row[clickedColName] = event.target.value;
        if (event._reactName === 'onBlur') {
            row[clickedColName] = event.target.value.trim();
        }
        let termsData = [...enquiryTermsdata];
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        delete event.target.parentElement.dataset.tip;
        termsData[arrayIndex] = row
        setEnquiryTermsdata(termsData);
    }
    // ** Functions for the common terms Ends.


    // ** Functionality Starts for the existing functionality.
    const [existingFuncRowCounter, setExistingFuncRowCounter] = useState(1);

    const existingFunctionalityBlankObj = {
        enquiry_existing_functionality: '',
        enquiry_expected_functionality: '',
        enquiry_expected_value: '',
    }

    useEffect(() => {
        const getExistingFunctionalityData = [...existingFunctionalityData]
        getExistingFunctionalityData.push(existingFunctionalityBlankObj)
        setExistingFunctionalityData(getExistingFunctionalityData)
    }, [existingFuncRowCounter]);

    const renderExistingFunctionalityTable = useMemo(() => {
        return <> <Table className="erp_table" id="ExpectedFunctionality" bordered striped hover>
            <thead className='erp_table_head'>
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`} style={{ paddingRight: '-20px' }}>Action</th>
                    <th className="erp_table_th">Existing Functionality</th>
                    <th className="erp_table_th">Expected Functionality </th>
                    <th className="erp_table_th">Expected Value</th>
                </tr>
            </thead>
            <tbody className="erp_table_td">
                {
                    existingFunctionalityData?.map((existingFunctionality, index) =>
                        <tr rowIndex={index}>
                            <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>
                                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => { removeExistingFunctionalityTblRow(index); }} rowIndex={index} />
                                <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => { setExistingFuncRowCounter(existingFuncRowCounter + 1); }} />
                            </td>
                            < td className="erp_table_td ">
                                {
                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <Form.Control as="textarea" id={`existingfunctionality-${index}`} value={existingFunctionality.enquiry_existing_functionality} className="erp_input_field mb-0" maxLength="1000"
                                            Headers="enquiry_existing_functionality" onInput={(e) => { updateExistingFunctionalityTbl(existingFunctionality, e) }} />
                                        : existingFunctionality.enquiry_existing_functionality
                                }
                            </td>
                            < td className="erp_table_td ">
                                {
                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <Form.Control as="textarea" id={`expectedfunctionality-${index}`} value={existingFunctionality.enquiry_expected_functionality} className="erp_input_field mb-0" maxLength="1000"
                                            Headers="enquiry_expected_functionality" onInput={(e) => { updateExistingFunctionalityTbl(existingFunctionality, e) }} />
                                        : existingFunctionality.enquiry_expected_functionality
                                }
                            </td>
                            < td className="erp_table_td ">
                                {
                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <Form.Control as="textarea" id={`expectedvalue-${index}`} value={existingFunctionality.enquiry_expected_value} className="erp_input_field mb-0" maxLength="1000"
                                            Headers="enquiry_expected_value" onInput={(e) => { updateExistingFunctionalityTbl(existingFunctionality, e) }} />
                                        : existingFunctionality.enquiry_expected_value
                                }
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
        </>
    }, [existingFunctionalityData, existingFuncRowCounter]);

    // Function for update the details table data.
    const updateExistingFunctionalityTbl = (currentExistingFunctionality, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentExistingFunctionality[clickedColName] = event.target.value;
        const updatedTblData = [...existingFunctionalityData]
        const existingTblData = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedTblData[existingTblData] = currentExistingFunctionality;
        setExistingFunctionalityData(updatedTblData)
    };

    // Function for remove the existing functionality table row.
    const removeExistingFunctionalityTblRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            const updatedTblData = existingFunctionalityData.filter((item, index) => index !== indexToRemove);
            setExistingFunctionalityData(updatedTblData);
        } else {
            const getExistingFunctionalityData = [...existingFunctionalityData]
            getExistingFunctionalityData[0] = { ...existingFunctionalityBlankObj }
            setExistingFunctionalityData(getExistingFunctionalityData)
        }
    }
    // ** Functionality Ends for the existing functionality.


    // ** Functionality For Documents Starts.
    const FnCloseDocumentForm = async () => {
        setShowDocumentForm(false);
        await showDocumentRecords();
    };
    const viewDocumentForm = async () => { if (keyForViewUpdate !== 'view') { setShowDocumentForm(true); } }

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_enquiry_no !== 0 ? txt_enquiry_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setUploadedDocumentData(resp.content);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='document-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        uploadedDocumentData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">{docItem.document_registration_no}</td>
                                <td className="erp_table_td">{docItem.document_registration_date}</td>
                                <td className="erp_table_td">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td">{docItem.document_path}</td>
                                <td className="erp_table_td">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}> {docItem.file_name} </Link>
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [uploadedDocumentData]);

    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    // ** Functionality For Documents Ends.

    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'EnquiryTradingCommonTermsAccord':
                setIsLoading(true);
                checkedLength = $("input:checkbox[name=checkEnquiryTerms]:checked").length;
                if (checkedLength === 0) {
                    await FnGetDefaultEnquiryTerms();
                }
                setIsLoading(false);
                break;

            case 'documentList':
                await showDocumentRecords();
                break;

            default:
                break;
        }
    }

    function FnManageCheckboxesSelection(key) {
        switch (key) {
            // For Common terms
            case 'chkAllEnqTerms':
                $('.checkEnquiryTerms').prop('checked', $('#chkAllEnqTerms').is(":checked"));
                break;
            case 'PartiallyCommonTermSelection':
                $('#chkAllEnqTerms').prop('checked', $('input:checkbox.checkEnquiryTerms:checked').length === $('input:checkbox.checkEnquiryTerms').length);
                break;

            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;

            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;

            default:
                break;
        }
    }
    // ********************************** All Table Data Handling Functions Ends. **********************************************************************

    // *********************** All CRUD Functionanlity Starts. **********************
    const addEnquiry = async (e) => {
        debugger
        try {
            setIsLoading(true);
            let EnquiryIsValid = true;
            let NewEnquiryNo = '';
            let clickedBtnType = e.target.getAttribute('BtnType')
            if ((clickedBtnType === 'reject' || clickedBtnType === 'approve') && clickedBtnType !== 'save') {
                if (clickedBtnType === 'reject' && $('#txt_status_remark').val().trim() === '') {
                    $('#error_txt_status_remark').text('Please enter the status remark.');
                    $('#error_txt_status_remark').show();
                    $('#txt_status_remark').focus();
                    setIsLoading(false)
                    return false;
                } else if (clickedBtnType === 'approve') {
                    EnquiryIsValid = await FnValidateEnquiry();
                } else {
                    EnquiryIsValid = true;
                }
            } else if (clickedBtnType !== 'approve' && clickedBtnType !== 'reject') {
                EnquiryIsValid = await FnValidateEnquiry();
            }

            // If Enquiry is valid then send it to save transaction.
            if (EnquiryIsValid) {
                let json = { 'EnquiryDetailData': [], 'EnquiryHeaderData': {}, 'EnquiryTermsData': [], 'EnquiryExistingExpectedFunctionalityData': [], 'commonIds': {}, 'projectMaterialsData': [] }
                let customerContactIds = FnGetCustomerContactPersonIds();

                if (enquiry_master_transaction_id === 0) {
                    NewEnquiryNo = await generateEnquiryNo(enquiry_type);
                }

                const commonFields = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    enquiry_master_transaction_id: isApprove ? enquiry_master_transaction_id : 0,
                    enquiry_type_id: cmb_enquiry_type_id,
                    enquiry_type: enquiry_type,
                    enquiry_no: enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no,
                    enquiry_date: txt_enquiry_date,
                    enquiry_version: txt_enquiry_version,
                    financial_year: FINANCIAL_SHORT_NAME,
                    approved_by_id: isApprove ? cmb_approved_by_id : '',
                    approved_date: isApprove ? dt_approved_date : '',
                    created_by: UserName,
                    is_active: rb_is_active,
                    modified_by: UserName,
                }

                // Enquiry Master Data.
                let enquiryMstData = {
                    enquiry_life: rb_enquiry_life,
                    enquiry_status: cmb_enquiry_status,
                    customer_id: cmb_customer_id,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_contacts_ids: customerContactIds,
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    project_name: txt_project_name,
                    project_referance_number: txt_project_referance_number,
                    project_start_date: date_project_start_date,
                    project_end_date: date_project_end_date,
                    department_id: cmb_department,
                    enquiry_by_id: cmb_enquiry_by,
                    assign_to_head_id: cmb_assign_to_head_id,
                    assign_to_id: cmb_assign_to_id,
                    overall_schedule_date: dt_overall_schedule_date,
                    agent_id: cmb_agent_id,
                    agent_percent: txt_agent_percent,
                    enquiry_mail_sent_status: rb_enquiry_mail_sent_status,
                    status_remark: txt_status_remark,
                    other_terms_conditions: txt_other_terms_conditions,
                    remark: txt_remark,

                    ...commonFields,                    // Common fields.
                }
                if (isApprove) {
                    const countOfP = enquiryItemsDetailsData.filter(selectedItem => selectedItem.enquiry_item_status === 'P').length;
                    if (clickedBtnType === 'approve' && countOfP > 0) {
                        enquiryMstData.enquiry_status = 'P'
                    } else if (clickedBtnType === 'approve' && countOfP === 0) {
                        enquiryMstData.enquiry_status = 'A'
                    }
                    else if (clickedBtnType === 'reject') {
                        enquiryMstData.enquiry_status = 'R'
                    }
                }
                json.EnquiryHeaderData = enquiryMstData;

                // ** For Collecting data for enquiry Details Data.
                let enquiryMaterialsJSONArray = enquiryItemsDetailsData
                    ?.filter(enquiryMaterial => enquiryMaterial.product_material_name.trim().length > 0)
                    .map((enquiryMaterial, index) => ({
                        ...enquiryMaterial,             // Add all challan-material's existing fields.
                        enquiry_details_transaction_id: isApprove && keyForViewUpdate !== 'copy' ? enquiryMaterial.enquiry_details_transaction_id : 0,
                        sr_no: index + 1,
                        department_id: cmb_department,
                        approved_by_id: isApprove && keyForViewUpdate !== 'copy' ? cmb_approved_by_id : '',
                        approved_date: isApprove && keyForViewUpdate !== 'copy' ? dt_approved_date : '',
                        created_by: UserName,
                        enquiry_item_status: isApprove && clickedBtnType === 'reject' ? 'R' : enquiryMaterial.enquiry_item_status,

                        ...commonFields,                    // Common fields.
                    }));
                json.EnquiryDetailData = enquiryMaterialsJSONArray;

                if (!isApprove) {
                    // ** For Collecting data for add and check data into the sm_product_pr.
                    json.projectMaterialsData = FnConvertObjectKeys('EnquiryDetail2ProductPr', enquiryMaterialsJSONArray);

                    // ** For Collecting the Common Terms Data.
                    $("input:checkbox[name=checkEnquiryTerms]:checked").each(function () {
                        let findEnquiryTerm = enquiryTermsdata.find(item => item.common_parameters_id === parseInt($(this).val()));
                        const EnquiryTerm = {
                            enquiry_terms_transaction_id: 0,
                            enquiry_details_transaction_id: 0,
                            enquiry_terms_parameters_id: findEnquiryTerm.common_parameters_id,
                            enquiry_terms_parameters_name: findEnquiryTerm.common_parameters_name,
                            enquiry_terms_parameters_value: findEnquiryTerm.common_parameters_value,
                            enquiry_terms_parameters_expected_value: findEnquiryTerm.common_parameters_expected_value === '' ? findEnquiryTerm.common_parameters_value : findEnquiryTerm.common_parameters_expected_value,
                            remark: findEnquiryTerm.remark,
                            ...commonFields     // set all common fields.
                        }
                        json.EnquiryTermsData.push(EnquiryTerm);
                    });

                    if (json.EnquiryTermsData.length === 0) {
                        json.EnquiryTermsData = enqTermsResponse.map((common_term) => {
                            return {
                                enquiry_terms_transaction_id: 0,
                                enquiry_details_transaction_id: 0,
                                ...common_term,
                                ...commonFields     // set all common fields.
                            }
                        })
                    }

                    // ** For Collecting the EnquiryExistingExpectedFunctionalityData // collect only those object having all three textbox filed.
                    let enquiryExistingFunctionality = existingFunctionalityData
                        ?.filter(existingFunctionality => existingFunctionality.enquiry_existing_functionality !== '' && existingFunctionality.enquiry_expected_functionality !== '' && existingFunctionality.enquiry_expected_value !== '')
                        .map((existingFunctionality, index) => ({
                            ...existingFunctionality,
                            enquiry_functionality_transaction_id: 0,
                            enquiry_details_transaction_id: 0,
                            created_by: UserName,
                            ...commonFields     // set all common fields.
                        }));
                    json.EnquiryExistingExpectedFunctionalityData = enquiryExistingFunctionality;
                }

                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.enquiry_no = enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no
                json.commonIds.enquiry_version = enquiry_master_transaction_id === 0 ? 1 : txt_enquiry_version;
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log('Sending JSON For Save Response: ', json)

                const formData = new FormData();
                formData.append(`TransEnquiryProjectJson`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryMasterProject/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const responce = await apicall.json();
                console.log('Save Response: ', responce)
                if (responce.success === "1") {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('enquiry_version') && responce.data.hasOwnProperty('enquiry_no')) {
                        setModalOrderDetails(`Enquiry No: ${responce.data.enquiry_no} and Version: ${responce.data.enquiry_version}`)
                    }
                    if (isApprove && responce.data.enquiry_status === 'A') {
                        setShowMailSentModal(true);
                    } else {
                        setSuccMsg(responce.message)
                        setShowSuccessMsgModal(true);
                    }

                    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                        $('#uploadDocBtn').removeAttr('disabled');
                    }
                    $('#save-btn').attr('disabled', 'disabled');
                    $('#reject-btn').attr('disabled', 'disabled');
                    $('#clearFormBtn').attr('disabled', 'disabled');
                    setEnquiryMasterTransactionId(responce.data.enquiry_master_transaction_id)
                    setEnquiryVersion(responce.data.enquiry_version);
                } else {
                    setErrMsg(responce.error);
                    setShowErrorMsgModal(true);
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // For Prefilled the data on update/view/copy/approval action.
    const FnCheckUpdateResponce = async () => {
        try {
            debugger
            let todayDateForInputField = today;
            let defaultExpecScheduleDate = FnGetNextDate(expectedScheduleGraceDays);

            let enquiry_no;
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryMasterProject/FnShowAllDetailsAndMastermodelRecords/${updateData.enquiry_version}/${updateData.financial_year}/${COMPANY_ID}?enquiry_no=${updateData.enquiry_no}`)
            const responce = await apiCall.json();

            let newEnquiryNoForCopiedEnquiry;
            if (responce.EnquiryMasterProjectRecord !== null) {
                let enquiryMasterData = responce.EnquiryMasterProjectRecord;
                // ** Sets master form data.
                if (enquiryMasterData !== null && enquiryMasterData !== "") {
                    setEnquiryTypeId(enquiryMasterData.enquiry_type_id);
                    setEnquiryMasterTransactionId(enquiryMasterData.enquiry_master_transaction_id)
                    setEnquiryno(enquiryMasterData.enquiry_no);
                    setEnquiryVersion(enquiryMasterData.enquiry_version);
                    setEnquiryDate(enquiryMasterData.enquiry_date);
                    setEnquiryOrdLife(enquiryMasterData.enquiry_life);
                    if (keyForViewUpdate === 'approve') {
                        setEnquiryStatus('A');
                    } else {
                        setEnquiryStatus(enquiryMasterData.enquiry_status);
                    }
                    setCustomerId(enquiryMasterData.customer_id);

                    // Load the customer state and city.   
                    let custContactData = await FnGetCustomersContactPersonsDetails(enquiryMasterData.customer_id);
                    const customerContactPersonsIds = enquiryMasterData.customer_contacts_ids.split(":").map(Number);
                    // Set the checked checkboxes first.
                    const rearrangedContactsRows = [
                        ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                        ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                    ];
                    setCustContactDetails(rearrangedContactsRows);

                    setCustomerStateName(enquiryMasterData.customer_state_name);
                    setCustomerStateId(enquiryMasterData.customer_state_id)
                    setCustomerCityName(enquiryMasterData.customer_city_name);
                    setCustomerCityId(enquiryMasterData.customer_city_id);
                    await FnSetExistingCustomerContactPersonIds(enquiryMasterData.customer_contacts_ids);

                    setExpectedBranchId(enquiryMasterData.expected_branch_id);
                    setExpectedBranchStateId(enquiryMasterData.expected_branch_state_id);
                    setExpectedBranchStateName(enquiryMasterData.expected_branch_state_name);
                    setExpectedBranchCityId(enquiryMasterData.expected_branch_city_id);
                    setExpectedBranchCityName(enquiryMasterData.expected_branch_city_name);

                    setProjectName(enquiryMasterData.project_name);
                    setProjectReferanceNumber(enquiryMasterData.project_referance_number);
                    setProjectStartDate(enquiryMasterData.project_start_date);
                    setProjectEndDate(enquiryMasterData.project_end_date);


                    setDepartmentId(enquiryMasterData.department_id);
                    await FnComboOnChange('Department');
                    setEnquiryById(enquiryMasterData.enquiry_by_id);
                    setAssigToId(enquiryMasterData.assign_to_id);
                    setAssigntoHeadId(enquiryMasterData.assign_to_head_id);
                    if (keyForViewUpdate === 'approve' && (enquiryMasterData.approved_by_id === 0 || enquiryMasterData.approved_by_id === null)) {
                        setApprovedById(parseInt(UserId));
                    } else {
                        setApprovedById(enquiryMasterData.approved_by_id);
                    }
                    setApprovedDate(enquiryMasterData.approved_date);
                    setOverAllScheduleDate(enquiryMasterData.overall_schedule_date);
                    setAgentId(enquiryMasterData.agent_id);
                    setAgentPercent(enquiryMasterData.agent_percent);
                    setStatusRemark(enquiryMasterData.status_remark);
                    setOtherTermsConditions(enquiryMasterData.other_terms_conditions);
                    setRemark(enquiryMasterData.remark);
                    setEnquiryMailSentStatus(enquiryMasterData.enquiry_mail_sent_status);
                    setIsActive(enquiryMasterData.is_active.toString());

                    if (keyForViewUpdate === 'copy') {
                        newEnquiryNoForCopiedEnquiry = await generateEnquiryNo(enquiry_type);
                        setEnquiryMasterTransactionId(0);
                        setEnquiryDate(todayDateForInputField);
                        setEnquiryStatus('P');
                        setOverAllScheduleDate(defaultExpecScheduleDate);
                        setApprovedById('');
                        setApprovedDate('');
                        setStatusRemark('');
                        setEnquiryVersion(1);
                        setEnquiryMailSentStatus('P');

                        // let projectStartDate = new Date(enquiryMasterData.project_start_date);
                        // let currentDate = new Date();
                        // if (projectStartDate < currentDate || isNaN(projectStartDate)) {
                        //     setProjectStartDate(today);
                        //     setProjectEndDate(FnGetNextDate(1));
                        // }

                    }

                    // ** Sets the Enquiry Items Details table data.
                    if (responce.EnquiryDetailsProjectRecords !== null) {
                        let updatedBomFilteredDataArray = responce.EnquiryDetailsProjectRecords;
                        if (keyForViewUpdate === 'copy') {
                            // Update the data for copied enquiry.
                            updatedBomFilteredDataArray = updatedBomFilteredDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                enquiry_item_status: 'P',
                                enquiry_master_transaction_id: 0,
                                enquiry_details_transaction_id: 0,
                                enquiry_no: enquiry_no,
                                material_enquiry_approval_remark: '',
                                enquiry_date: todayDateForInputField,
                                enquiry_version: 1,
                                material_schedule_date: defaultExpecScheduleDate,
                                product_material_hsn_id: ordDetail.hsn_sac_id

                            }));
                        } else if (keyForViewUpdate === 'approve') {
                            updatedBomFilteredDataArray = updatedBomFilteredDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                product_material_hsn_id: ordDetail.hsn_sac_id,
                                enquiry_item_status: ordDetail.enquiry_item_status === 'P'
                                    ? "A"
                                    : ordDetail.enquiry_item_status,
                            }));
                        } else {
                            updatedBomFilteredDataArray = updatedBomFilteredDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                product_material_hsn_id: ordDetail.hsn_sac_id
                            }));
                        }
                        setEnquiryItemsDetailsData(updatedBomFilteredDataArray);
                    }

                    // ** Sets the enquiry Terms table data.
                    if (responce.EnquiryTermsProjectRecords !== null) {
                        setEnqTermsResponse(responce.EnquiryTermsProjectRecords);
                        responce.EnquiryTermsProjectRecords.forEach(function (existingCommonTerm) {
                            $('#checkEnquiryTerms_' + existingCommonTerm.enquiry_terms_parameters_id).prop('checked', true);
                        });
                    }

                    // ** Sets the existing functionality table data.
                    if (responce.EquiryExistingExpectedFunctionalityProjectRecords.length !== 0) {
                        setExistingFunctionalityData(responce.EquiryExistingExpectedFunctionalityProjectRecords);
                    } else {
                        const existingFuncData = [...existingFunctionalityData]
                        existingFuncData.push(existingFunctionalityBlankObj)
                        setExistingFunctionalityData(existingFuncData);
                    }
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryMasterProject/FnDeleteRecord/${updateData.enquiry_version}/${UserName}/${COMPANY_ID}?enquiry_no=${updateData.enquiry_no}`, method)
            const responce = await deleteApiCall.json();
            // console.log("Company Deleted: ", responce);
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const deleteshow = () => {
        setShowDeleteModal(true)
    }

    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------


    const FnValidateEnquiry = async () => {
        let headerDataIsValid = await validate.current.validateForm("enquiryFormId");
        if (!headerDataIsValid) {
            return false;
        }

        //customer contact validate
        const checkboxes = $('.selectCustContactPerson:checked');
        if (checkboxes.length === 0) {
            $('#error_customer_contact_persons').text('Please select atleast one contact person.');
            $('#error_customer_contact_persons').show();
            setErrMsg('Please select at least one customer contact.');
            setShowErrorMsgModal(true);
            return null;
        }

        //Details count validation
        let enquiryTransactionIsValid = true;
        let addedMaterials = $('#erp-enquirydetails-table tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add at least one material.');
            setShowErrorMsgModal(true);
            enquiryTransactionIsValid = false;
            return false;
        }

        // For the approval
        if (keyForViewUpdate === 'approve') {
            return true;

        } else {
            //  Checking Duplicate Materials is present or not?
            const duplicateRecords = FnCheckMaterialIsDuplicateInDetails(enquiryItemsDetailsData);
            if (duplicateRecords.length > 0) {
                duplicateRecords.forEach(record => {
                    let duplicateMaterialInputField = $(`#product_material_name_${record.index}`)[0];
                    duplicateMaterialInputField.parentElement.dataset.tip = 'This items is already exists...!'
                });
                $(`#product_material_name_${duplicateRecords[0].index}`)[0].focus();
                enquiryTransactionIsValid = false;
                return;
            }

            // validate details table for add_enquiry.
            $('#erp-enquirydetails-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialName = currentTblRow.find('input[id^="product_material_name_"]').val().trim();
                let materialQty = parseFloat(currentTblRow.find('input[id^="product_material_enquiry_quantity_"]').val());
                let materialRate = parseFloat(currentTblRow.find('input[id^="material_std_rate_"]').val());

                if (materialName.trim() === '') {
                    $(currentTblRow.find('input[id^="product_material_name_"]'))[0].parentElement.dataset.tip = 'Please Fill the material name...!';
                    $(currentTblRow.find('input[id^="product_material_name_"]'))[0].focus();
                    enquiryTransactionIsValid = false;
                    return;

                } else if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                    $(currentTblRow.find('input[id^="product_material_enquiry_quantity_"]'))[0].parentElement.dataset.tip = 'Enquiry quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="product_material_enquiry_quantity_"]'))[0].focus();
                    enquiryTransactionIsValid = false;
                    return;

                } if (isNaN(materialRate) || materialRate <= 0 || materialRate === '') {
                    $(currentTblRow.find('input[id^="material_std_rate_"]'))[0].parentElement.dataset.tip = 'Material rate should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="material_std_rate_"]'))[0].focus();
                    enquiryTransactionIsValid = false;
                    return;
                }
                // enquiryTransactionIsValid = true;
            });

            if (!enquiryTransactionIsValid) {
                return enquiryTransactionIsValid;
            }

            let selectedCommonTerms = $('#erp-enquiryterms-table tbody tr .checkEnquiryTerms:checked')
            if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
                selectedCommonTerms.each(function () {
                    let currentTblRow = $(this.parentElement.parentElement)
                    let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val().trim();
                    if (expectedValue === '') {
                        $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                        $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                        enquiryTransactionIsValid = false;
                        return false;
                    }
                });
                if (!enquiryTransactionIsValid) {
                    return enquiryTransactionIsValid;
                }
            }
        }
        return enquiryTransactionIsValid;
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('enquiryFormId')
    }

    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber(JSON.stringify(percentageInput), 4);
        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill digits this field...!';
        } else if (parseFloat(percentageInput) > 100) {

        } else {
            return true;
        }
        return percentageValidateMsg;
    }

    // *********************** All CRUD Functionanlity Starts. **********************


    // *********************** Additional Functionanlity Starts. **********************
    const clearFormFields = async () => {
        setIsLoading(true);
        await generateEnquiryNo(enquiry_type);
        setEnquiryDate(today);
        setEnquiryStatus('P');
        setEnquiryOrdLife('C');
        setCustomerId('');
        setCustomerStateId('');
        setCustomerStateName('');
        setCustomerCityId('');
        setCustomerCityName('');
        setCustContactDetails([]);
        $('.selectCustContactPerson').prop('checked', false);
        $('#selectAllCustContact').prop('checked', false);

        setExpectedBranchId(COMPANY_BRANCH_ID);
        const loggedInCompanyBranchObj = expectedBranchOptions.find(companyBranch => companyBranch.company_branch_id === parseInt(COMPANY_BRANCH_ID));
        setExpectedBranchStateId(loggedInCompanyBranchObj.branch_state_id);
        setExpectedBranchStateName(loggedInCompanyBranchObj.state_name);
        setExpectedBranchCityId(loggedInCompanyBranchObj.branch_city_id);
        setExpectedBranchCityName(loggedInCompanyBranchObj.city_name);

        setProjectName('');
        setProjectReferanceNumber('');
        setProjectStartDate(today);
        setProjectEndDate(FnGetNextDate(1));

        const defaultDepartment = departmentIdOptions.find(department => department.field_name === "Marketing & Proposals Department");
        if (defaultDepartment) { // Set the default department.
            setDepartmentId(defaultDepartment.field_id);
            // Load the enquiry-by list.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "department_id", operator: "=", value: defaultDepartment.field_id });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setEnquiryByIdOptions(enquirybyIdApiCall);
        }
        setEnquiryById('');
        setAssigToId(parseInt(UserId));
        const defaultAssignedEmployee = assignToHead.find(employee => parseInt(employee.field_id) === parseInt(UserId));
        if (defaultAssignedEmployee) {   // Set Head Name for Assigned Employee. // reporting_to
            setAssigntoHeadId(defaultAssignedEmployee.reporting_to);
        }

        setApprovedById('');
        setApprovedDate('');

        setOverAllScheduleDate(FnGetNextDate(expectedScheduleGraceDays));
        // Set the Default Agent as NA and load it's releated %.
        const defaultAgent = agentOptions.find(agent => agent.field_name === 'NA');
        if (defaultAgent) {   // Set Head Name for Assigned Employee. // reporting_to
            setAgentId(defaultAgent.field_id);
            setAgentPercent(defaultAgent.agent_std_percent);
        } else {
            setAgentId('');
            setAgentPercent(0);
        }
        setOtherTermsConditions('');
        setRemark('');
        setIsActive('true');
        setStatusRemark('');

        // For enquiry items details data.
        const enquiryItems = [];
        enquiryItems.push(blankEnqItemObj);
        setEnquiryItemsDetailsData(enquiryItems);
        setEnquiryItemRowCounter(1);

        // For Existing Functionality table data.
        const existingFunctionalities = [];
        existingFunctionalities.push(existingFunctionalityBlankObj);
        setExistingFunctionalityData(existingFunctionalities);
        setExistingFuncRowCounter(1);

        // For the Common terms.
        await FnGetDefaultEnquiryTerms();
        $('.checkEnquiryTerms').prop('checked', false);
        $('#chkAllEnqTerms').prop('checked', false);
        setIsLoading(false);
    }

    // ------------------------------------- Start Emailing Functionality -----------------------------------
    // After approved mail sent confirmation modal Emailing Modal.
    const [showMailSentModal, setShowMailSentModal] = useState(false);
    const handleCloseMailSentModal = () => {
        setShowMailSentModal(false);
        moveToListing();
    }
    const sendEmail = async () => {
        try {
            setIsLoading(true);
            setShowMailSentModal(false);
            let emailContent = await generateMailJson();

            // Generate the enquiry PDF.
            // let enquiryPDFContent = await printInvoice(false);
            let enquiryPDFContent = '';
            const fileName = `${txt_enquiry_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const enquiryPDFContainer = document.createElement('div');
            enquiryPDFContainer.style.margin = '20px';
            enquiryPDFContainer.innerHTML = enquiryPDFContent;
            let worker = html2pdf().from(enquiryPDFContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(emailContent))
                formData.append("EnquiryPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryMasterProject/FnSendEmail/${enquiry_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
            });
            setIsLoading(false);
        } catch (error) {
            console.log('Error in email sending: ', error);
        }
    }

    const generateMailJson = async () => {
        let EmailData = { 'EmailType': "Enquiry Received Email Internal", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        const enquiryByEmpl = enquiryByIdOptions.find(employee => parseInt(employee.field_id) === parseInt(cmb_enquiry_by));
        if (enquiryByEmpl) { EmailData.toMailData.push(enquiryByEmpl.email_id1); }

        const assignedEmpl = assignToEmployeeOpts.find(employee => parseInt(employee.field_id) === parseInt(cmb_assign_to_id));
        if (assignedEmpl) { EmailData.toMailData.push(assignedEmpl.email_id1); }

        const assignedHeadEmpl = assignToHead.find(employee => parseInt(employee.field_id) === parseInt(cmb_assign_to_head_id));
        if (assignedHeadEmpl) { EmailData.toMailData.push(assignedHeadEmpl.email_id1); }

        const approvedByEmpl = approvedbyid.find(employee => parseInt(employee.field_id) === parseInt(cmb_approved_by_id));
        if (approvedByEmpl) { EmailData.toMailData.push(approvedByEmpl.email_id1); }

        const selectedAgent = agentOptions.find(agent => parseInt(agent.field_id) === parseInt(cmb_agent_id));
        if (selectedAgent) { EmailData.toMailData.push(selectedAgent.agent_EmailId); }

        EmailData.subject = `Received the new enquiry ${txt_enquiry_no} ...!`;                          // Subject
        EmailData.parameters.push("Project Enquiry Details");                                           // Param0 It is a heading.
        EmailData.parameters.push(`${txt_enquiry_no}`);
        EmailData.parameters.push(cmb_customer_id === '0' || cmb_customer_id === '' || cmb_customer_id === null ? '' : $('#cmb_customer_id').find(":selected").text());
        EmailData.parameters.push(cmb_department === '0' || cmb_department === '' || cmb_department === null ? '' : $('#cmb_department').find(":selected").text());
        EmailData.parameters.push(cmb_enquiry_by === '0' || cmb_enquiry_by === '' || cmb_enquiry_by === null ? '' : $('#cmb_enquiry_by').find(":selected").text());
        EmailData.parameters.push(cmb_assign_to_id === '0' || cmb_assign_to_id === '' || cmb_assign_to_id === null ? '' : $('#cmb_assign_to_id').find(":selected").text());
        EmailData.parameters.push(cmb_assign_to_head_id === '0' || cmb_assign_to_head_id === '' || cmb_assign_to_head_id === null ? '' : $('#cmb_assign_to_head_id').find(":selected").text());
        EmailData.parameters.push(await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date));
        EmailData.parameters.push(COMPANY_NAME);
        console.log('enquiry emailData: ', EmailData);
        return EmailData;
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    // ------------------------------------- Ends Emailing Functionality -----------------------------------

    // ---------------------------- Export2Excel Functionality Starts. -------------------------------------
    const ExportToExcel = async () => {
        let headerFormIsValid = await validate.current.validateForm("enquiryFormId");
        if (enquiryItemsDetailsData.length === 1 && enquiryItemsDetailsData[0].product_material_name.trim() === '') {
            setErrMsg('Please add at least one material...! ');
            setShowErrorMsgModal(true);
            return false;
        }

        if (enquiryItemsDetailsData.length !== 0 && headerFormIsValid) {
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [8, 15] }
            let columnExport = ['sr_no', 'product_type_short_name', 'product_material_name', 'product_material_tech_spect', 'product_material_unit_name',
                'product_packing_name', 'product_material_hsn', 'material_schedule_date', 'product_material_enquiry_quantity', 'product_material_moq_quantity',
                'expected_lead_time', 'product_material_notes', 'enquiry_item_status', 'material_enquiry_approval_remark', 'remark', 'material_std_rate',
                'product_material_unit_id', 'product_material_packing_id', 'product_material_hsn_id', 'product_material_id',
            ];

            let filtrKeyValue = {};
            filtrKeyValue['0'] = "Enquiry Type : " + `Project (${cmb_enquiry_type_id})`;
            filtrKeyValue['1'] = "Enquiry No : " + txt_enquiry_no
            filtrKeyValue['2'] = "Enquiry Version : " + txt_enquiry_version
            filtrKeyValue['3'] = "Enquiry Date : " + await validateNumberDateInput.current.formatDateToDDMMYYYY(txt_enquiry_date)
            filtrKeyValue['4'] = "Enquiry Life : " + $('input[name="rb_enquiry_life"]:checked').attr("lbl") + "(" + rb_enquiry_life + ")"
            filtrKeyValue['5'] = "Enquiry Status : " + $('#cmb_enquiry_status').find(":selected").text() + "(" + cmb_enquiry_status + ")"
            filtrKeyValue['6'] = "Customer : " + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")"
            filtrKeyValue['7'] = "Customer State : " + txt_customer_state_name + `(${cmb_customer_state_id})`
            filtrKeyValue['8'] = "Customer City : " + txt_customer_city_name + `(${cmb_customer_city_id})`
            filtrKeyValue['9'] = "Expected Company Branch : " + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")"
            filtrKeyValue['10'] = "Project Name : " + txt_project_name
            filtrKeyValue['11'] = "Project Referance No. : " + txt_project_referance_number
            filtrKeyValue['12'] = "Project Start Date : " + (date_project_start_date === '0' || date_project_start_date === '' || date_project_start_date === null ? '' : await validateNumberDateInput.current.formatDateToDDMMYYYY(date_project_start_date))
            filtrKeyValue['13'] = "Project End Date : " + (date_project_end_date === '0' || date_project_end_date === '' || date_project_end_date === null ? '' : await validateNumberDateInput.current.formatDateToDDMMYYYY(date_project_end_date))
            filtrKeyValue['14'] = "Department : " + (cmb_department === '0' || cmb_department === '' || cmb_department === null ? '' : $('#cmb_department').find(":selected").text() + "(" + cmb_department + ")")
            filtrKeyValue['15'] = "Enquiry By : " + (cmb_enquiry_by === '0' || cmb_enquiry_by === '' || cmb_enquiry_by === null ? '' : $('#cmb_enquiry_by').find(":selected").text() + "(" + cmb_enquiry_by + ")")
            filtrKeyValue['16'] = "Assign To Employee : " + (cmb_assign_to_id === '0' || cmb_assign_to_id === '' || cmb_assign_to_id === null ? '' : $('#cmb_assign_to_id').find(":selected").text() + "(" + cmb_assign_to_id + ")")
            filtrKeyValue['17'] = "Assign To Head : " + (cmb_assign_to_head_id === '0' || cmb_assign_to_head_id === '' || cmb_assign_to_head_id === null ? '' : $('#cmb_assign_to_head_id').find(":selected").text() + "(" + cmb_assign_to_head_id + ")")
            filtrKeyValue['18'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")")
            filtrKeyValue['19'] = "Approved Date : " + (dt_approved_date === '0' || dt_approved_date === '' || dt_approved_date === null ? '' : await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_approved_date))
            filtrKeyValue['20'] = "Status Remark : " + txt_status_remark
            filtrKeyValue['21'] = "Expected Schedule Date : " + await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date)
            filtrKeyValue['22'] = "Agent : " + (cmb_agent_id === '0' || cmb_agent_id === '' || cmb_agent_id === null ? '' : $('#cmb_agent_id').find(":selected").text() + "(" + cmb_agent_id + ")")
            filtrKeyValue['23'] = "Agent Percentage  : " + txt_agent_percent
            filtrKeyValue['24'] = "Remark : " + txt_remark
            filtrKeyValue['25'] = 'Other Terms & Conditions : ' + txt_other_terms_conditions;
            filtrKeyValue['26'] = "Is Active : " + $("input[name='rb_is_active']:checked").attr("lbl") + "(" + rb_is_active + ")"
            jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

            // set columns name.
            for (let col = 0; col < columnExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": columnExport[col], "accessor": columnExport[col] })
            }

            enquiryItemsDetailsData.map((enquiryItem, Index) => {
                enquiryItem.sr_no = Index + 1;
                enquiryItem.product_material_hsn = enquiryItem.hsn_sac_code;
                if (enquiryItem.hsn_sac_code === undefined || enquiryItem.hsn_sac_code === '') {
                    let selectedHSN = productHSNOptions.find(HSN => HSN.field_id === parseInt(enquiryItem.product_material_hsn_id));
                    enquiryItem.product_material_hsn = selectedHSN.field_name;
                }
                if (enquiryItem.product_material_unit_name === undefined || enquiryItem.product_material_unit_name === '') {
                    let selectedUnit = productUnitOptions.find(unit => unit.field_id === parseInt(enquiryItem.product_material_unit_id));
                    enquiryItem.product_material_unit_name = selectedUnit.field_name;
                }
                if (enquiryItem.product_packing_name === undefined || enquiryItem.product_packing_name === '') {
                    let selectedPacking = productPackingOptions.find(packing => packing.field_id === parseInt(enquiryItem.product_material_packing_id));
                    enquiryItem.product_packing_name = selectedPacking.field_name;
                }

                jsonToExportExcel['allData'][Index] = enquiryItem;
            });

            jsonToExportExcel['headings']['ReportName'] = "Project Enquiry Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_enquiry_no + "@" + COMPANY_ID)
        } else {
            if (enquiryItemsDetailsData.length === 0) {
                setErrMsg('Please add at least one material...! ')
                setShowErrorMsgModal(true)
            }
        }
    }
    // ---------------------------- Export2Excel Functionality Ends. -------------------------------------

    // ---------------------------- ImportExcel Functionality Starts. -------------------------------------
    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        debugger
        setIsLoading(true);
        try {
            if (event.target.files) {
                var file = event.target.files[0];
                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryMasterProject/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();
                console.log(fetchRes)
                if (fetchRes.success === '1') {
                    let enquiryNo = fetchRes.formFieldData[6].split(':')[1].trim();
                    let comboNames = ['Enquiry Type', 'Enquiry Life', 'Enquiry Status', 'Customer', 'Customer State', 'Customer City', 'Expected Company Branch', 'Department', 'Enquiry By', 'Assign To Employee', 'Assign To Head', 'Agent', 'Is Active']

                    if (fetchRes.data.length !== 0) {
                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                const dateParts = fieldVal.split("-");
                                let comboVal;

                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }

                                switch (fieldName) {
                                    // case 'Enquiry Date': setEnquiryDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Enquiry Life': setEnquiryOrdLife(comboVal); break;
                                    // case 'Enquiry Status': setEnquiryStatus(comboVal); break;

                                    case 'Customer':
                                        setCustomerId(comboVal);
                                        await FnGetCustomersContactPersonsDetails(parseInt(comboVal));
                                        break;

                                    case 'Customer State':
                                        setCustomerStateName(fieldVal.match(/^[^(]+/)[0]);
                                        setCustomerStateId(comboVal);
                                        // $('#cmb_customer_state_id').attr('state_id', comboVal);
                                        break;
                                    case 'Customer City':
                                        setCustomerCityName(fieldVal.match(/^[^(]+/)[0]);
                                        setCustomerCityId(comboVal);
                                        // $('#cmb_customer_city_id').attr('city_id', comboVal);
                                        break;

                                    case 'Expected Company Branch':
                                        setExpectedBranchId(comboVal);
                                        await FnComboOnChange('CompanyBranch');
                                        break;

                                    case 'Project Name':
                                        setProjectName(fieldVal);
                                        break;

                                    case 'Project Referance No.':
                                        setProjectReferanceNumber(fieldVal);
                                        break;

                                    case 'Project Start Date':
                                        setProjectStartDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
                                        break;

                                    case 'Project End Date':
                                        setProjectEndDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
                                        break;

                                    case 'Department':
                                        setDepartmentId(comboVal)
                                        await FnComboOnChange('Department')
                                        break;

                                    case 'Enquiry By': setEnquiryById(comboVal); break;
                                    case 'Assign To Head': setAssigntoHeadId(comboVal); break;
                                    case 'Assign To Employee': setAssigToId(comboVal); break;
                                    case 'Expected Schedule Date': setOverAllScheduleDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Agent': setAgentId(comboVal); break;
                                    case 'Agent Percentage': setAgentPercent(fieldVal); break;
                                    case 'Other Terms & Conditions': setOtherTermsConditions(fieldVal); break;
                                    case 'Remark': setRemark(fieldVal); break;
                                    case 'Is Active': setIsActive(comboVal); break;
                                }
                            }
                        }

                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            return orderDetail;
                        });


                        setEnquiryItemsDetailsData(readedOrderDetailsFromExcel);
                        setIsLoading(false);
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }
            } else {
                setEnquiryItemsDetailsData([])
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    // ---------------------------- ImportExcel Functionality Ends. -------------------------------------


    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingCustomerContactPersonIds = async (contactIds) => {
        const customerContactPersonsIds = contactIds.split(':');
        customerContactPersonsIds.forEach(function (contactId, index) {
            $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
        });
        FnManageCheckboxesSelection('PartiallyCustContactSelection')
    }

    const moveToListing = () => {
        sessionStorage.removeItem('enquiry_no')
        sessionStorage.removeItem('enquiryItemsDetailsData')
        const Path = compType === 'Register' ? '/Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectListing/reg' : `/Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectListing`;
        navigate(Path);
    }

    const FnGetNextDate = (afterDays) => { // FnGetNextDate
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);

        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // *********************** Additional Functionanlity Ends. **********************



    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val().trim();
        if (keywords !== '') {
            var table = document.getElementById('erp-enquirydetails-table');
            var rows = table.getElementsByTagName('tr');
            for (var trNum = 1; trNum < rows.length; trNum++) {
                var row = rows[trNum];
                if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                    var w = $(window);
                    var row = $('#erp-enquirydetails-table').find('tr').eq(trNum);
                    if (row.length) {
                        w.scrollTop(row.offset().top - (w.height() / 2));
                    }
                    $(row).find('td').each(function () {
                        $(this).addClass('highlight');
                    });
                    removeOtherTrHighlightClass(trNum)
                    break;
                } else {
                    $(row).find('td').each(function () { $(this).removeClass('highlight'); });
                    $("#erp-enquirydetails-table td.highlight").removeClass("highlight");
                }
            }
        } else {
            $("#erp-enquirydetails-table td.highlight").removeClass("highlight");
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('erp-enquirydetails-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    //Enquiry Invoice Start
    const EnquiryContent = {
        company: {
            company: '',
            company_branch: '',
            company_address1: ''
        },

        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        enquiryDetails: {
            enquiry_no: '',
            enquiry_date: '',
            enquiry_version: ''
        },
        footer: {
            approved_by: '',
            enquiry_by: ''
        },

        items: [],
    };

    const printInvoice = async (openPrintModal) => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryMasterProject/FnShowAllDetailsAndMastermodelRecords/${updateData.enquiry_version}/${updateData.financial_year}/${COMPANY_ID}?enquiry_no=${updateData.enquiry_no}`)
            const response = await apiCall.json();
            console.log("Response Enquiry Invoice : ", response);
            let enquiryMasterData = response.EnquiryMasterProjectRecord;
            let enquiryDetailsData = response.EnquiryDetailsProjectRecords;

            //Enquiry details
            EnquiryContent.enquiryDetails.enquiry_no = enquiryMasterData.enquiry_no
            EnquiryContent.enquiryDetails.enquiry_date = validateNumberDateInput.current.formatDateToDDMMYYYY(enquiryMasterData.enquiry_date)
            EnquiryContent.enquiryDetails.enquiry_version = enquiryMasterData.enquiry_version

            //Company details
            EnquiryContent.company.company = enquiryMasterData.company_name
            EnquiryContent.company.company_branch = enquiryMasterData.company_branch_name
            EnquiryContent.company.company_address1 = enquiryMasterData.company_address1

            //Customer details
            EnquiryContent.customerDetails.customer = enquiryMasterData.customer_name
            EnquiryContent.customerDetails.address = enquiryMasterData.cust_branch_address1
            EnquiryContent.customerDetails.state = enquiryMasterData.customer_state_name
            EnquiryContent.customerDetails.GSTIN = enquiryMasterData.cust_branch_gst_no
            EnquiryContent.customerDetails.pan_no = enquiryMasterData.cust_branch_pan_no
            EnquiryContent.customerDetails.contact = enquiryMasterData.cust_branch_phone_no
            EnquiryContent.customerDetails.email = enquiryMasterData.customer_email

            //footer details    
            EnquiryContent.footer.approved_by = enquiryMasterData.approved_by_name
            EnquiryContent.footer.enquiry_by = enquiryMasterData.enquiry_by_name

            for (let index = 0; index < enquiryDetailsData.length; index++) {
                const element = enquiryDetailsData[index];

                const detailsData = {
                    sr_no: element.sr_no,
                    product_material_name: element.product_material_name,
                    product_material_tech_spect: element.product_material_tech_spect,
                    product_material_enquiry_quantity: element.product_material_enquiry_quantity,
                    product_material_enquiry_weight: element.product_material_enquiry_weight,
                    product_material_unit_name: element.product_material_unit_name,
                    material_std_rate: element.material_std_rate,
                }
                EnquiryContent.items.push(detailsData)
            }
            if (openPrintModal) {
                navigate('/Invoice', { state: { invoiceData: EnquiryContent, navigationLink: 'Transactions/TEnquiryProjectsManagement/FrmEnquiryProjectEntry', invoiceType: 'EN', title: ' Enquiry Management' } });
            } else {
                return renderToString(<Enquiry invoiceContent={EnquiryContent} />);
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    };


    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ExcelExport ref={exlsExp} />
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Projects Enquiry <span id="submain_heading">(Creation)</span></label>
                    </div>

                    <form id="enquiryFormId">
                        <div className="row p-1">
                            {/* //first column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Enquiry No., Ver.& Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_enquiry_no" className="erp_input_field" value={txt_enquiry_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_enquiry_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='txt_enquiry_version' className="erp_input_field text-end" value={txt_enquiry_version} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_enquiry_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='txt_enquiry_date' className="erp_input_field" value={txt_enquiry_date} onChange={e => { setEnquiryDate(e.target.value); validateFields(); }} {...(keyForViewUpdate === '' || keyForViewUpdate === 'copy' ? {} : { disabled: true })} />
                                                <MDTypography variant="button" id="error_txt_enquiry_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Enquiry Life <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_enquiry_life" checked={rb_enquiry_life === "C"} onClick={() => { setEnquiryOrdLife("C"); validateFields(); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_enquiry_life" checked={rb_enquiry_life === "O"} onClick={() => { setEnquiryOrdLife("O"); validateFields(); }} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Enquiry Status<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_enquiry_status" className="form-select form-select-sm" value={cmb_enquiry_status} onChange={() => { validateFields(); }} disabled={true} >
                                            <option value="P" selected>Pending</option>
                                            <option value="A" disabled>Approved</option>
                                            <option value="R" disabled>Rejected</option>
                                            <option value="Q" disabled>Quotaion Send</option>
                                            <option value="Z" disabled> PreeClosed</option>
                                            <option value="O" disabled>Order Confirm</option>
                                            <option value="C" disabled>Completed</option>
                                            <option value="X" disabled>Canceled</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_enquiry_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-10'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={e => { setCustomerId(e.target.value); FnComboOnChange('Customer'); validateFields(); }}  >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerOptions?.map(customer => (
                                                <option value={customer.field_id}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography  >
                                                <MdRefresh onClick={() => FnRefreshComboData('Customer')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id='txt_customer_state_name' className="erp_input_field" value={txt_customer_state_name} optional="optional" maxlength="500" disabled />
                                                <MDTypography variant="button" id="error_txt_customer_state_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="text" id='txt_customer_city_name' className="erp_input_field" value={txt_customer_city_name} optional="optional" maxlength="500" disabled />
                                                <MDTypography variant="button" id="error_txt_customer_city_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Our Company Branch<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { FnComboOnChange("CompanyBranch"); validateFields(); }} >
                                            <option value="" disabled>Select</option>
                                            {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                <option value={cmb_expected_branch_id.company_branch_id}>{cmb_expected_branch_id.company_branch_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Our Branch State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id='txt_expected_branch_state_name' className="erp_input_field" value={txt_expected_branch_state_name} optional="optional" maxlength="500" disabled />
                                                <MDTypography variant="button" id="error_txt_expected_branch_state_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="text" id='txt_expected_branch_city_name' className="erp_input_field" value={txt_expected_branch_city_name} optional="optional" maxlength="500" disabled />
                                                <MDTypography variant="button" id="error_txt_expected_branch_city_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Project Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_project_name' className="erp_input_field" value={txt_project_name} onChange={e => { setProjectName(e.target.value); validateFields(); }} />
                                        <MDTypography variant="button" id="error_txt_project_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Project Referance No.</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_project_referance_number' className="erp_input_field" value={txt_project_referance_number} onChange={e => { setProjectReferanceNumber(e.target.value); validateFields(); }} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_project_referance_number" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Project Start & End Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="date" id='date_project_start_date' className="erp_input_field" value={date_project_start_date} onChange={(e) => { setProjectStartDate(e.target.value); validateFields(); }} max={date_project_end_date} />
                                                <MDTypography variant="button" id="error_date_project_start_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='date_project_end_date' className="erp_input_field" value={date_project_end_date} onChange={(e) => { setProjectEndDate(e.target.value); validateFields(); }} min={date_project_start_date} />
                                                <MDTypography variant="button" id="error_date_project_end_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-0">
                                    <Accordion defaultActiveKey="0" >
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                                <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                }
                                                                }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                            </div>
                                                        </>
                                                        : null
                                                }

                                                {
                                                    custContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <Table className="erp_table erp_table_scroll" id='customerContactPersonsTable' responsive bordered striped>
                                                                        <thead className="erp_table_head">
                                                                            <tr>
                                                                                <th className={`erp_table_th`}> <div className="col-1"><input type='checkbox' className="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { FnManageCheckboxesSelection('selectAllCustContact'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />Select</div></th>
                                                                                <th className='erp_table_th'>Customer Branch</th>
                                                                                <th className='erp_table_th'>Contact Person</th>
                                                                                <th className='erp_table_th'>Contact No</th>
                                                                                <th className={`erp_table_th `}>Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                custContactDetails.map((item, index) =>
                                                                                    <tr rowindex={index}>
                                                                                        <td className='erp_table_td'>
                                                                                            <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id} value={item.customer_contact_id}
                                                                                                onClick={() => FnManageCheckboxesSelection('PartiallyCustContactSelection')}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="erp_table_td">{item.customer_branch_name}</td>
                                                                                        <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                        <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                        <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>

                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { FnComboOnChange('Department'); validateFields(); }} optional='optional'>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {departmentIdOptions?.map(departmentId => (
                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Enquiry By <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_enquiry_by" className="form-select form-select-sm" value={cmb_enquiry_by} onChange={(e) => { setEnquiryById(e.target.value); FnComboOnChange('EnquiryBy'); validateFields(); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {enquiryByIdOptions?.map(employee => (
                                                <option value={employee.field_id}>{employee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_enquiry_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography  >
                                                <MdRefresh onClick={() => FnRefreshComboData('EnquiryBy')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Assign To Employee <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_assign_to_id" className="form-select form-select-sm" value={cmb_assign_to_id} onChange={(e) => { setAssigToId(e.target.value); FnComboOnChange('AssignToemployee') }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {assignToEmployeeOpts?.map(employee => (
                                                <option value={employee.field_id}>{employee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_assign_to_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>

                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography  >
                                                <MdRefresh onClick={() => FnRefreshComboData('AssignToemployee')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Assign To Head<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_assign_to_head_id" className="form-select form-select-sm" value={cmb_assign_to_head_id} onChange={(e) => { setAssigntoHeadId(e.target.value); FnComboOnChange('AssignToHead') }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {assignToHead?.map(employee => (
                                                <option value={employee.field_id}>{employee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_assign_to_head_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography  >
                                                <MdRefresh onClick={() => FnRefreshComboData('AssignToHead')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Exp. Scheduled Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_overall_schedule_date' className="erp_input_field" value={dt_overall_schedule_date} onChange={(e) => { setOverAllScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                        <MDTypography variant="button" id="error_dt_overall_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Agent & Percentage</Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <div className="row">
                                            <div className="col-12 col-md-8 pe-md-0">
                                                <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); FnComboOnChange('Agent') }} optional="optional" >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {agentOptions?.map(agent => (
                                                        <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>

                                            <div className="col-10 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="number" id='txt_agent_percent' className="erp_input_field erp_align-right" value={txt_agent_percent}
                                                    onChange={(e) => {
                                                        let isValidPercentageInput = validatePercentageInput(e.target.value);
                                                        if (isValidPercentageInput === true) {
                                                            setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
                                                            validateFields();
                                                            delete e.target.parentElement.dataset.tip;
                                                        }
                                                    }} optional="optional" />
                                                <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>

                                            <div className="col-2 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1 text-center">
                                                <Tooltip title="Refresh" placement="right">
                                                    <MDTypography >
                                                        <MdRefresh onClick={() => FnRefreshComboData('Agent')} style={{ color: 'black' }} />
                                                    </MDTypography>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? <>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label"> Approved By & Date <span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 pe-md-0">
                                                            <select id="cmb_approved_by_id" className="form-select form-select-sm" name="approved_by" value={cmb_approved_by_id} disabled={true}
                                                                onChange={(e) => { setApprovedById(e.target.value); }} optional='optional' >
                                                                <option value="">Select</option>
                                                                {approvedbyid?.map(branchId => (
                                                                    <option value={branchId.field_id}>{branchId.field_name}</option>
                                                                ))}
                                                            </select>
                                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                        </div>
                                                        <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApprovedDate(e.target.value); validateFields(); }} optional='optional' disabled='disabled' />
                                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-1'>
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label">Status Remark</Form.Label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <Form.Control as="textarea" id='txt_status_remark' className="erp_input_field" value={txt_status_remark} onChange={e => { setStatusRemark(e.target.value); validateFields(); }} optional='optional' disabled={true} />
                                                    <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                }

                                <div className='row mb-1'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Other Terms & Conditions</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id='txt_other_terms_conditions' className="erp_input_field" value={txt_other_terms_conditions} onChange={e => setOtherTermsConditions(e.target.value)} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Mail Send Status</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_enquiry_mail_sent_status" checked={rb_enquiry_mail_sent_status === "P"} onClick={(e) => { setEnquiryMailSentStatus('P'); }} disabled='disabled' optional="optional" /> </div>
                                            <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Sent" type="radio" lbl="Sent" value="S" name="rb_enquiry_mail_sent_status" checked={rb_enquiry_mail_sent_status === "S"} onClick={(e) => { setEnquiryMailSentStatus('S') }} disabled='disabled' optional="optional" /> </div>
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Failed" type="radio" lbl="Failed" value="F" name="rb_enquiry_mail_sent_status" checked={rb_enquiry_mail_sent_status === "F"} onClick={(e) => { setEnquiryMailSentStatus('F') }} disabled='disabled' optional="optional" /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="rb_is_active" checked={rb_is_active === "true"} onClick={() => { setIsActive("true"); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="rb_is_active" checked={rb_is_active === "false"} onClick={() => { setIsActive("false"); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Enquiry Details</Accordion.Header>
                        <Accordion.Body className="p-1 px-md-3 pb-0">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-sm-9 d-flex pt-1">
                                            <Form.Control type="text" id="txt-detail-data-highliter" className="erp_input_field float-start" />
                                            <MDButton type="button" className="erp-gb-button erp_MLeft_btn float-start" variant="button" onClick={() => scrollToTableRow()}
                                                fontWeight="regular" style={{ float: "left" }}>Search</MDButton>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 align-self-end pt-1">
                                    <MDButton type="button" id="import-btn" 
                                        className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || isApprove ? 'd-none' : 'display'}`}
                                        variant="button" onClick={onImportClick} fontWeight="regular" disabled={isApprove === false ? false : true} > Import </MDButton>

                                    <MDButton type="button" id="export-btn" className={`erp-gb-button float-end ms-2`} variant="button" onClick={ExportToExcel} fontWeight="regular"
                                        disabled={enquiry_master_transaction_id === 0 || enquiry_master_transaction_id === '' || isApprove === false ? false : true} > Export </MDButton>
                                </div>
                            </div>

                            <div className="row py-2">
                                {renderDetailsTable}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >

                <hr />
                <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                    <Accordion.Item eventKey="EnquiryTradingCommonTermsAccord">
                        <Accordion.Header className="erp-form-label-md"> Enquiry Terms</Accordion.Header>
                        <Accordion.Body className="p-1 pt-0">
                            <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <div className="col-6">
                                    <input type='checkbox' class="" id="chkAllEnqTerms" onClick={(e) => FnManageCheckboxesSelection('chkAllEnqTerms')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /> <label class="erp-form-label pb-1 me-2"> Check All </label>
                                </div>
                                <div className="col-6">
                                    <MDButton type="button" className={`erp-gb-button float-end col-1`} variant="button" fontWeight="regular"
                                        onClick={() => {
                                            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                            setHeaderName('Common Parameter Term'); // CommonParamterEntry
                                            setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                        }}>Add
                                    </MDButton>
                                </div>
                            </div>
                            <div className={`table-responsive ${enquiryTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                <>{renderTermsTable}</>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <hr />

                <Accordion defaultActiveKey="1" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Expected Functionality</Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className='table-responsive erp_table_scroll'>
                                    {renderExistingFunctionalityTable}
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />

                {keyForViewUpdate !== '' && keyForViewUpdate !== 'copy'
                    ? <>
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md">Document List</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`table-responsive ${uploadedDocumentData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                        {uploadedDocumentData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (null)}

                                        {
                                            uploadedDocumentData.length === 0
                                                ? <div className='row'>
                                                    <div className="col-12 text-center">
                                                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                            No Records Found...
                                                        </span>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <hr />
                    </>
                    : null
                }

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => deleteshow()} >  Delete</MDButton>
                    <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" BtnType="save" onClick={(e) => addEnquiry(e)}
                        fontWeight="regular">Save</MDButton>
                    {
                        keyForViewUpdate === 'approve'
                            ? <>
                                <MDButton type="button" id="reject-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='reject' onClick={(e) => addEnquiry(e)} fontWeight="regular">Reject</MDButton>
                            </>
                            : ""
                    }

                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || isApprove ? 'd-none' : 'display'}`} id='uploadDocBtn' variant="button" fontWeight="regular" onClick={viewDocumentForm} disabled={enquiry_master_transaction_id === 0 || enquiry_master_transaction_id === '' ? true : false}>Upload Document</MDButton>&nbsp;
                    {
                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update'
                            ? <>
                                <MDButton type="button" id="clearFormBtn" className={`erp-gb-button ms-2`} variant="button" onClick={e => { clearFormFields(); }} fontWeight="regular" >Clear</MDButton>
                            </>
                            : null
                    }

                    {/* {
                        keyForViewUpdate === 'view' && cmb_enquiry_status === 'A'
                            ? <>
                                <MDButton className={`erp-gb-button ms-2`} variant="button" fontWeight="regular"
                                    onClick={() => { printInvoice(true); }} disabled={cmb_enquiry_status === "A" ? '' : 'disabled'}> Print  <FiPrinter className="erp-download-icon-btn" />
                                </MDButton>
                            </>
                            : null
                    } */}
                </div >


                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                {/* Send Email On Approval Modal */}
                <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, succMsg, modalOrderDetails]} />


                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={FnCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Documents For {txt_enquiry_no}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_enquiry_no !== 0 ? txt_enquiry_no.replaceAll('/', '_') : null} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={FnCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={FnRefreshComboDataOnModalClose} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'>
                                <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnRefreshComboDataOnModalClose}></button>
                            </div>
                        </div>
                        {FnOpenAddNewRecordModal()}
                    </Modal.Body>
                </Modal >

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

            </DashboardLayout>

        </>
    )
}
