import { useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import $ from 'jquery';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { Accordion, Button, Card, Table } from "react-bootstrap";

// React Bootstrap Imports
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from "react-paginate";
import Select from 'react-select';
// React icons
import { AiOutlineDownCircle } from "react-icons/ai";
import { TbArrowsRight } from "react-icons/tb";
import { TbArrowsLeft } from "react-icons/tb";
import { TbArrowsDown } from "react-icons/tb";
import { TbArrowsUp } from "react-icons/tb";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { MdModeEdit, MdRefresh } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FiDownload } from 'react-icons/fi';
import { RiSearchLine } from 'react-icons/ri';
import Tooltip from "@mui/material/Tooltip";


// import files
import ComboBox from "Features/ComboBox";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import Datatable from 'components/DataTable';
import FrmValidations from 'FrmGeneric/FrmValidations';
import FrmPaymentScheduleEntry from 'Masters/MPaymentSchedule/FrmPaymentScheduleEntry';
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { globalQuery, resetGlobalQuery } from 'assets/Constants/config-constant';
import DocumentF from "Features/Document";


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import FrmPropertyEntry from "Masters/MProperty/FrmPropertyEntry";
import { CircularProgress } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5"
import { IoRemoveCircleOutline } from "react-icons/io5"


export default function FrmCustomerEntry({ goToNext, customerId }) {
  // var customer_id = sessionStorage.getItem('customer_id');
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME } = configConstants;

  const { state } = useLocation();
  let { customerID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {}
  if (localStorage.getItem('dataAddedByCombo') === 'dataAddedByCombo') {
    const customerIDs = JSON.parse(localStorage.getItem('customerIDs'));
    if (customerIDs !== null && customerIDs !== undefined) {
      customerID = customerIDs.customerID;
      keyForViewUpdate = customerIDs.keyForViewUpdate;
      compType = customerIDs.compType
    }
  }

  const [isLoading, setIsLoading] = useState(true);

  //Setting hook for infoupdate
  const [keyFVUD, setKeyVUD] = useState('');

  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => setShowDocumentForm(false);
  const viewDocumentForm = () => setShowDocumentForm(true);

  const [customerBranchId, setBranchID] = useState(0);
  var Branch_ID = sessionStorage.getItem("BranchID")
  var FTcustomerData;

  let customerMainBranchId = 0;

  const comboBox = useRef();
  const validateNumberDateInput = useRef();
  const validate = useRef();
  let activeValue = '';

  let expandedGlCodeCombo = false;

  // For navigate
  const navigate = useNavigate();

  // combobox Option
  const [customerTypeOption, setCustomerTypeOption] = useState([]);
  const [customerSectorOption, setCustomerSectorOption] = useState([]);
  const [paymentTermOption, setPaymentTermOption] = useState([]);

  //GL codes Customer
  const [GLCodesOption, setGlCodesOption] = useState([]);
  const [GLCodesCheckboxes, setGLCodesCheckboxes] = useState();
  const [totalSelectedGLCodeCheckboxes, setTotalSelectedGLCodeCheckboxes] = useState(1);
  const [customerGlCodes, setCustomerGLCodes] = useState('');


  const [customer_id, setCustomerId] = useState(customerID === 0 ? customerId : customerID)
  // const [cmb_customers_type, setCustomerTypes] = useState('Manufacturing(Projects)');
  const [cmb_customers_type, setCustomerTypes] = useState('');
  const [txt_customer_code, setCustomerCodes] = useState('');
  const [txt_customer_name, setCustomerName] = useState('');
  const [txt_customer_short_name, setCustomerShortName] = useState('');
  const [cmb_company_sector, setCustomerSector] = useState('Manufacturing');
  const [txt_nature_of_business, setNatureOfBusiness] = useState('');
  const [cmb_payment_term, setPaymentTermId] = useState('151');
  const [txt_customer_ratings, setCustomerRating] = useState('');
  const [txt_customer_accounts_id, setCustomerAccountId] = useState('');
  const [txt_customer_history, setCustomerHistory] = useState('');
  const [txt_username, setUserName] = useState('');
  const [txt_password, setPassword] = useState('');
  const [remark, setRemark] = useState('');
  const [cust_is_active, setCustomerIsActive] = useState(true)
  //hide and show password 
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordhideandshow = () => {
    setShowPassword(!showPassword);
  };

  //Bank Branch Information
  // Option Box
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [customerRegionOption, setcustomerRegionOption] = useState([]);
  const [bankAccountTypeList, setBankAccountTypeList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [currencyTypeList, setCurrencyTypeList] = useState([]);

  //GL codes for branch
  const [cmb_cust_branch_gl_codes, setBranchGLCodes] = useState('');
  const [BranchGLCodesCheckboxes, setBranchGLCodesCheckboxes] = useState();
  const [totalSelectedBranchGLCodeCheckboxes, setTotalSelectedBranchGLCodeCheckboxes] = useState(1);
  const cmb_state_id_ref = useRef()
  const cmb_city_id_ref = useRef()

  // const [tempCustomerId, setTempCustomerId] = useState(customer_id)
  let tempCustomerId = customer_id
  const [chkBranchtype, setBranchType] = useState(customer_id === 0 ? 'Main' : 'Sub');

  const [txt_cust_branch_name, setCustBranchName] = useState('');
  const [txt_cust_branch_vendor_code, setBranchVendorCode] = useState(sessionStorage.getItem('defaultCustomerCode'));
  const [txt_cust_branch_short_name, setCustBranchShortName] = useState('');
  const [txt_cust_branch_address1, setBranchAddress1] = useState('');
  const [txt_cust_branch_address2, setBranchAddress2] = useState('');
  const [cmb_customer_region, setCustBranchRegion] = useState('NA');
  const [cmb_cust_branch_state_id, setCustBranchState] = useState('');
  const [cmb_cust_branch__City_id, setCustBranchCity] = useState('');
  const [cmb_cust_branch__Country_id, setCountry] = useState('');
  const [cmb_cust_branch__District_id, setDistrict] = useState('');
  const [txt_cust_branch_pin_code, setCustBranchPincode] = useState('');
  const [txt_cust_branch_phone_no, setBranchPhoneNo] = useState('');
  const [txt_cust_branch_cell_no, setCustBranchcellNo] = useState('');
  const [txt_cust_branch_email_id, setCustBranchEmail] = useState('');
  const [txt_cust_branch_website, setCustBranchWebsite] = useState('');
  const [txt_cust_branch_profile, setCustBranchProfile] = useState('');
  const [txt_cust_branch_linkedIn_profile, setBranchLinkedinProfile] = useState('');
  const [txt_cust_branch_twitter_profile, setBranchTwitterProfile] = useState('');
  const [txt_cust_branch_gst_no, setCustBranchGstNo] = useState('');
  const [txt_branch_gst_division, setCustGstDivision] = useState('');
  const [txt_branch_gst_range, setCustGstRange] = useState('');
  const [txt_cust_branch_pan_no, setCustBranchPanNo] = useState('');
  const [txt_cust_branch_udyog_adhar_no, setBranchUdyogAdharNo] = useState('');
  const [txt_cust_branch_vat_no, setCustBranchVatNo] = useState('');
  const [txt_cust_branch_service_tax_no, setBranchServiceTaxNo] = useState('');
  const [cmb_cust_payment_term_ID, setBranchPaymentTermID] = useState('151');
  const [txt_branch_excise_no, setBranchExciseNo] = useState('');
  const [txt_branch_cst_no, setBranchCstNo] = useState('');
  const [txt_cust_branch_bst_no, setBranchBstNo] = useState('');

  const [rb_is_sez, setIsSez] = useState(false);
  const [txt_sez_name, setCustSezName] = useState('');

  const [txt_cust_branch_accounts_id, setBranchAccountsID] = useState('');
  const [txt_cust_branch__ratings, setBranchRating] = useState('');
  const [txt_physical_distance, setPhysicalDistance] = useState('');
  const [txt_tally_ID, setCustTallyID] = useState('');
  const [actionType, setActionType] = useState('')

  const [actionLabel, setActionLabel] = useState('Save')

  const [customerBlocked, setcustomerBlocked] = useState(false);
  const [customerBlockedRemark, setcustomerBlockedRemark] = useState('');
  const [customerPaymentBlocked, setcustomerPaymentBlocked] = useState(false);
  const [customerPaymentBlockedRemark, setcustomerPaymentBlockedRemark] = useState('');
  const [selectedValue, setSelectedValue] = useState("");
  const [searchState, setGlobalFilterSearch] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')


  // Customers bank States
  const [customerBankData, setCustomerBankData] = useState([]);
  const [cmb_customer_bank_account_type, setCustomerBankAccountType] = useState('');
  const [cmb_bank_id, setBankId] = useState('');
  const [txt_customer_bank_branch_name, setCustomerBankBranchName] = useState('');
  const [txt_customer_bank_account_no, setCustomerBankAccountNo] = useState('');
  const [txt_customer_bank_ifsc_code, setCustomerBankIfscCode] = useState('');
  const [txt_customer_bank_swift_code, setCustomerBankSwiftCode] = useState('');
  const [txt_customer_bank_gst_no, setCustomerBankGstNo] = useState('');
  const [cmb_currency_type, setCurrencyType] = useState('Rupees');
  const [txt_is_active, setCustomerBankIsActive] = useState(true);

  var operatorLabels = { "=": "is", "!=": "is not", "1": "active", "0": "closed", "o": "open", "!*": "none", "*": "any", "~": "contains", "!~": "doesn't contain", "^": "starts with", "$": "ends with", "<=": "less than or equal", ">=": "greater than or equal", '<>=': 'BETWEEN' };
  var operatorByType = {
    "list": ["=", "!="], "status": ["1", "0"], "list_status": ["o", "=", "!", "c", "*"], "list_optional": ["=", "!", "!*", "*"],
    "list_subprojects": ["*", "!*", "=", "!"], "string": ["~", "=", "!~", "!=", "^", "$"], "text": ["~", "!~", "^", "$", "!*", "*"],
    "integer": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"], "float": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"],
    "relation": ["=", "!", "=p", "=!p", "!p", "*o", "!o", "!*", "*"], "tree": ["=", "~", "!*", "*"], "date": ["=", "<=", '>=', '<>='],
  };;


  // Form Heading 
  const [formHeading, setFormHeading] = useState('Branch Information');

  // Table Data fields
  const [data, setBranchs] = useState([]);
  const [columns, setColumns] = useState([]);
  const [bankcolumns, setBankColumns] = useState([]);
  const [bankRecords, setBankRecords] = useState([]);
  var bankColumnHeads;

  var storeSelectedValues = [];

  // Filter Fields
  var rptColumnHeads;
  const [filterComboBox, setFilterComboBox] = useState([]);
  const [optionBox, setOptionBox] = useState([]);
  const [filterBox, setFilterBox] = useState([]);
  const [recordData, setRecordData] = useState([]);

  // Export Fields
  var dataExport = [];
  var columnExport = [];

  const pdfExp = useRef();
  const exlsExp = useRef();
  const jsonExp = useRef();
  const csvExp = useRef();

  let reportName = "Customer Branch Report"
  const pdfExpFilters = {};

  const pageEntriesOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "All", value: 0 },
  ]
  const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
  const [pageCount, setpageCount] = useState(0);
  var [PageCurrent, setcurrentPage] = useState(0);

  //Pagination Fields For bank
  const [keys, setKeys] = useState('')

  const reload = () => {
    window.location.reload();
  }

  //Contact Starts here
  const [rowCount, setRowCount] = useState(1);
  const [customerContactData, setCustomerContactData] = useState([])

  //Option box 
  const [designationOptions, setDesignationOptions] = useState([]);
  const [customerBranchOptions, setCustomerBranchOptions] = useState([]);

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const [reportType, setReportType] = useState('summary');

  // Error Msg HANDLING
  // const handleCloseErrModal = () => setShowErrorMsgModal(false);

  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const usernameRef = useRef();
  const handleCloseErrModal = () => {
    if (errMsg === "User Name is already exist!") {
      setShowErrorMsgModal(false);
      // usernameRef.current.focus();
    } else {
      setShowErrorMsgModal(false);
    }
  }


  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    debugger
    setShowSuccessMsgModal(false);
    let functionType = localStorage.getItem("functionType");
    if (functionType !== "custBranchInfo") {
      moveToListing();
      // localStorage.removeItem("functionType");
    }
    localStorage.removeItem("functionType");
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);

  // Delete Modal
  const handleClose = () => setShowDeleteModal(false);
  const [showDeletemodal, setShowDeleteModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  useEffect(() => {
    const fetchDataOnload = async () => {
      setIsLoading(true)
      await ActionType()
      await FillComobos();

      await showBankRecords();
      if (customer_id !== 0) {
        await FnCheckUpdateResponce();
      }
      if (customer_id !== 0) {
        const availCols = await showReportRecords(reportType);
        await fetchFilteredData(PageCurrent, entriesPerPage, availCols)
        if (customerMainBranchId !== 0) {
          setBranchID(customerMainBranchId)
          await infoForUpdate(customerMainBranchId, keyForViewUpdate);
        }
      }
      await FnFetchPropertyRecords();
      if (customerID === 0) {
        addRecordInProperty('customerTypes')
      }
      setIsLoading(false)
    }
    fetchDataOnload()
    // FTcustomerData = localStorage.getItem('FTcustomerData')
    // if (FTcustomerData !== null) {
    //   console.log("dtaa present: ", localStorage.getItem('FTcustomerData'))
    //   addDataInFields();
    // }


    $(document).on('mouseup', function (e) {
      let container = $("#gl_code_ul");
      let container1 = $("#gl_code_ul_branch");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
      if (!container1.is(e.target) && container1.has(e.target).length === 0) {
        container1.hide();
      }
    });
    $('.hide-show-filters').hide();

  }, [reportType])

  const ActionType = async () => {

    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');

        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("custBranchFormId");
        await validate.current.readOnly("bankForm");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Create)');
        return validate.current.removeReadOnlyAttr('customerFormId');
    }
  };
  const globalSearchOnChange = async () => {
    if (entriesPerPage !== 0) {
      setcurrentPage(0)
      setEntriesPerPage(0)
      await fetchFilteredData(0, 0, selectedColumns.length !== 0 ? selectedColumns : availableColumns)
    }
    setSearchInputValue(searchState)
  }

  const FnRenderAdditionalInputsOnDateChange = (filter) => {
    if (filter.type === 'D' && document.getElementById(`operators_${filter.id}_id`).value === '<>=') {
      const filterTblRow = document.getElementById(`${filter.id}`);
      const filterTblRowTd = document.createElement('td');

      // Create the first input element
      const dateInput = document.createElement('input');
      dateInput.type = 'date';
      dateInput.id = `values_${filter.id}_id_2`;
      dateInput.className = 'erp_form_control erp_input_field inputValue erp_operator_val ms-4';

      filterTblRowTd.appendChild(dateInput);
      filterTblRow.appendChild(filterTblRowTd);
    } else {
      // Remove the existing td if it exists
      const existingTd = document.getElementById(`values_${filter.id}_id_2`);
      if (existingTd) {
        existingTd.parentNode.removeChild(existingTd);
      }
    }
    return null;
  };
  const FillComobos = async () => {

    try {
      if (comboBox.current) {
        comboBox.current.fillMasterData("cmv_country", "", "").then((cmv_countryList) => {
          setCountryOptions(cmv_countryList);
          // Set the default Country India.
          const defaultCountry = cmv_countryList.find(country => country.field_name === "India");
          setCountry(defaultCountry.field_id);
          comboBox.current.fillMasterData("cmv_state", "country_id", defaultCountry.field_id).then((getStateList) => {
            const updatedStateptions = updateKeysForSearchBox(getStateList);
            setStateOptions(updatedStateptions);
            // setStateOptions(getStateList);
            // Set the default State Maharashtra.
            const defaultState = getStateList.find(state => state.field_name === "Gujarat");
            setCustBranchState(defaultState.field_id);

            // Load the district options.
            // comboBox.current.fillMasterData("cmv_district", "state_id", defaultState.field_id).then((getDistrictList) => {
            // setDistrictOptions(getDistrictList);
            comboBox.current.fillMasterData("cmv_city", "state_id", defaultState.field_id).then((getCityList) => {
              const updatedCityptions = updateKeysForSearchBox(getCityList);
              setCityOptions(updatedCityptions);
              // setCityOptions(getCityList);
            })
          })

        })

        comboBox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
          setPaymentTermOption(paymentTermDays)
        })

        comboBox.current.fillComboBox("Regions").then((regionList) => {
          setcustomerRegionOption(regionList)
        })

        comboBox.current.fillMasterData("fmv_currency", "", "").then((currencyList) => {
          setCurrencyTypeList(currencyList)
        })

        comboBox.current.fillComboBox("BankAccountsType").then((bankAccountTypeList) => {
          setBankAccountTypeList(bankAccountTypeList)
        })

        comboBox.current.fillMasterData("cmv_banks_List", "", "").then((bankList) => {
          const updatedBankptions = updateKeysForSearchBox(bankList);
          setBankList(updatedBankptions);
          // setBankList(bankList)
        })
      }

      const apiCallCountryCodeList = await fetch(`${process.env.REACT_APP_BASE_URL}/api/country/FnFetchCountryCodes`)
      const respCountryCode = await apiCallCountryCodeList.json();
      setCountryCodeOptions(respCountryCode)

    } catch (error) {
      console.log("error: ", error)
    }
  }


  // const addDataInFields = async () => {
  //   const FTcustomerDataJson = JSON.parse(FTcustomerData)
  //   sessionStorage.setItem("customerName", FTcustomerDataJson.customer_name)
  //   sessionStorage.setItem("defaultCustomerCode", FTcustomerDataJson.customer_code)
  //   setCustomerTypes(FTcustomerDataJson.customer_type)
  //   setCustomerCodes(FTcustomerDataJson.customer_code)
  //   setCustomerName(FTcustomerDataJson.customer_name)
  //   setCustomerShortName(FTcustomerDataJson.customer_short_name)
  //   setCustomerSector(FTcustomerDataJson.customer_sector)
  //   setNatureOfBusiness(FTcustomerDataJson.nature_of_business)
  //   setPaymentTermId(FTcustomerDataJson.payment_term_id)
  //   setCustomerRating(FTcustomerDataJson.customer_rating)
  //   setUserName(FTcustomerDataJson.username)


  //   setCustomerAccountId(FTcustomerDataJson.customer_accounts_id)
  //   setCustomerHistory(FTcustomerDataJson.customer_history)
  //   setRemark(FTcustomerDataJson.remark)
  //   switch (FTcustomerDataJson.is_active) {
  //     case true:
  //       document.querySelector('input[name="isCustomerActive"][value="1"]').checked = true;
  //       break;
  //     case false:
  //       document.querySelector('input[name="isCustomerActive"][value="0"]').checked = true;
  //       break;
  //   }
  // }

  const FnCheckUpdateResponce = async () => {

    try {
      if (customer_id !== null) {
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnShowCustomerBankBranchAndContactAllRecords/${customer_id}/${COMPANY_ID}`)
        const updateRes = await apiCall.json();

        let custEntryData = updateRes.CustomerRecords;
        let custContactData = updateRes.CustomerContactRecords;

        //Setting customer data
        setCustomerId(custEntryData.customer_id)
        setCustomerTypes(custEntryData.customer_type)
        setCustomerCodes(custEntryData.customer_code)
        setCustomerName(custEntryData.customer_name)
        setCustomerShortName(custEntryData.customer_short_name)
        setCustomerSector(custEntryData.customer_sector)
        setNatureOfBusiness(custEntryData.nature_of_business)
        setPaymentTermId(custEntryData.payment_term_id)
        setCustomerRating(custEntryData.customer_rating)
        setCustomerGLCodes(custEntryData.customer_gl_codes)
        setCustomerAccountId(custEntryData.customer_accounts_id)
        setCustomerHistory(custEntryData.customer_history)
        setUserName(custEntryData.username);
        setPassword(custEntryData.password);
        setRemark(custEntryData.remark)
        setCustomerIsActive(custEntryData.is_active)


        var checkboxesWithValue6 = custEntryData.customer_gl_codes.split(':');
        var length = checkboxesWithValue6.length;
        $('.glcodeforCust').text(length + ' Selected GL Codes ');
        setTotalSelectedGLCodeCheckboxes(length);
        //Setting Customer contact DEtails
        if (custContactData.length !== 0) {
          setCustomerContactData(custContactData);
        } else {
          const getExistingCustomerContactData = [...customerContactData]
          getExistingCustomerContactData.push(contactBlankObject)
          setCustomerContactData(getExistingCustomerContactData)
        }

        switch (keyForViewUpdate) {
          case 'update':
            setActionLabel('Update');
            $('#customerTypeId').attr('disabled', true);
            $('#customerNameID').attr('disabled', true);
            $('#customerCode').attr('disabled', true);
            $('#shortName').attr('disabled', true);
            $('#branchVendorId').attr('disabled', true);
            break;
          case 'view':
            $("input[type=radio]").attr('disabled', true);
            await validate.current.readOnly("customerFormId");

            break;
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }


  const FnFetchPropertyRecords = async () => {
    try {
      if (comboBox.current) {
        comboBox.current.fillComboBox("CompanySectors").then((customerSector) => {
          setCustomerSectorOption(customerSector)
        })


        // var supplierType = await comboBox.current.fillComboBox("CustomerTypes")
        // setCustomerTypeOption(supplierType)
        resetGlobalQuery();
        globalQuery.columns = ['property_id', 'property_name', 'property_value'];
        globalQuery.table = "amv_properties"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'CustomerTypes' });
        // comboBox.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {
        //   setCustomerTypeOption(getCustomerTypes);
        let getCustomerTypes = await comboBox.current.removeCatcheFillCombo(globalQuery);
        setCustomerTypeOption(getCustomerTypes);


        comboBox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
          setPaymentTermOption(paymentTermDays)
        })

        comboBox.current.fillMasterData("fmv_general_ledger", "", "").then((fmv_general_ledgerList) => {
          setGlCodesOption(fmv_general_ledgerList)
        })


        comboBox.current.fillMasterData("cmv_designation", "", "").then((masterList) => {
          setDesignationOptions(masterList)
          localStorage.setItem("designations", JSON.stringify(masterList))
        })

        comboBox.current.fillMasterData("cmv_customer_branch", "customer_id", customer_id).then((custBranchList) => {
          setCustomerBranchOptions(custBranchList)
          localStorage.setItem("customerBranchs", JSON.stringify(custBranchList))
        })
      }

    } catch (error) {
      console.log('error:', error)
    }
  }

  const generateCustomerCode = async (tblName, fieldName, Length, companyTypeShortName, type) => {
    const data = {
      tblName: tblName, fieldName: fieldName, Length: Length, company_id: COMPANY_ID,
      additionalParam1: 'customer_type',
      additionalParam1Value: document.getElementById('customerTypeId').value
    };
    const formData = new FormData();
    formData.append(`data`, JSON.stringify(data))
    const requestOptions = { method: 'POST', body: formData };
    try {
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnGenerateCode/${COMPANY_ID}`, requestOptions)
      const resp = await fetchResponse.text();
      console.log("GenerateTAuto Api: ", resp)
      if (resp) {
        setCustomerCodes(companyTypeShortName + '-' + type + resp);
        return companyTypeShortName + '-' + type + resp;
      }
    } catch (error) {
      console.error(error);
    }
  }
  //-------------------------------------GL Codes Functionality starts here---------------------------------//
  //Branch GL Codes
  const FnShowGlCodesForbranch = async () => {
    if (GLCodesOption.length > 0) {
      await FnCreateBranchGLCodesChks();
      if (keyForViewUpdate === 'Add') {
        $('.branch_gl_code_checkboxes input[value="6"]').prop('checked', true);
      }
      // Also check the already checked checkboxes.
      if (cmb_cust_branch_gl_codes !== '') {
        const glCodeIds = cmb_cust_branch_gl_codes.split(':');
        glCodeIds.forEach(function (contactId, index) {
          $('#glCodeCheckBoxs_' + glCodeIds[index]).prop('checked', true);
        });
        FnToggleCheckBoxes('PartiallyCheckBranchGLCode');
      }

      let checkBoxesContainer1 = document.getElementById("gl_code_ul_branch");
      if (!expandedGlCodeCombo) {
        checkBoxesContainer1.style.display = "block";
        expandedGlCodeCombo = true;

      } else {
        checkBoxesContainer1.style.display = "none";
        expandedGlCodeCombo = false;
      }

      // $('.gl_code_checkboxes').on('input blur', function (e) {
      //   FnGetSelectedGLCodesIds();
      // });
      $('.branch_gl_code_checkboxes').on('input blur', function (e) {
        FnGetSelectedBranchGLCodesIds();
      });

    }
  }

  const FnCreateBranchGLCodesChks = async () => {
    if (GLCodesOption.length !== 0) {
      let checkboxes = GLCodesOption.map((item, index) => {
        if (index === 0) {
          return (
            <>
              <li className="item branch_gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" id="CheckAllSupplierBranchGLCodes" value={index} className="erp_radio_button"
                    onChange={(event) => FnToggleCheckBoxes('CheckAllSupplierBranchGLCodes')} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})} />
                </span>
                <span className="item-text">All</span>
              </li>
              <li className="item branch_gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" name="BranchglCodeCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})}
                    id={`glCodeCheckBoxs_${item.field_id}`} className="erp_radio_button BranchglCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckBranchGLCode')}
                  />
                </span>
                <span className="item-text">{item.field_name}</span>
              </li>
            </>
          );
        }
        return (
          <li className="item branch_gl_code_checkboxes">
            <span className="checkbox">
              <input type="checkbox" name="BranchglCodeCheckBox" value={item.field_id} id={`glCodeCheckBoxs_${item.field_id}`} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})}
                className="erp_radio_button BranchglCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckBranchGLCode')}
              />
            </span>
            <span className="item-text">{item.field_name}</span>
          </li>
        );
      });
      setBranchGLCodesCheckboxes(checkboxes);
    }
  }

  const FnGetSelectedBranchGLCodesIds = () => {

    let selectedGLCodes = '';
    const checkboxesBranch = $('.BranchglCodeCheckBox:checked');

    checkboxesBranch.each(function () {
      if ($(this).val() !== '0' && $(this).val() !== '') {
        selectedGLCodes += $(this).val() + ":";
      }
    });
    setBranchGLCodes(selectedGLCodes.replace(/:$/, ''));
    return selectedGLCodes.replace(/:$/, '');
  }

  //Customer GL codes 
  const FnShowGlCodes = async () => {

    if (GLCodesOption.length > 0) {
      await FnCreateGLCodesChks();
      if (keyForViewUpdate === 'Add') {
        $('.gl_code_checkboxes input[value="6"]').prop('checked', true);

      }
      // Also check the already checked checkboxes.
      if (customerGlCodes !== '') {
        const glCodeIds = customerGlCodes.split(':');
        glCodeIds.forEach(function (contactId, index) {
          $('#glCodeCheckBox_' + glCodeIds[index]).prop('checked', true);
        });
        FnToggleCheckBoxes('PartiallyCheckGLCode');
      }

      let checkBoxesContainer = document.getElementById("gl_code_ul");
      if (!expandedGlCodeCombo) {
        checkBoxesContainer.style.display = "block";
        expandedGlCodeCombo = true;
      } else {
        checkBoxesContainer.style.display = "none";
        expandedGlCodeCombo = false;
      }

      $('.gl_code_checkboxes').on('input blur', function (e) {
        FnGetSelectedGLCodesIds();
      });
    }
  }

  const FnCreateGLCodesChks = async () => {
    if (GLCodesOption.length !== 0) {
      let checkboxes = GLCodesOption.map((item, index) => {
        if (index === 0) {
          return (
            <>
              <li className="item gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" id="CheckAllGLCodes" value={index} className="erp_radio_button"
                    onChange={(event) => FnToggleCheckBoxes('CheckAllGLCodes')} {...(keyForViewUpdate === "view" ? { disabled: true } : {})} />
                </span>
                <span className="item-text">All</span>
              </li>
              <li className="item gl_code_checkboxes">
                <span className="checkbox">
                  <input type="checkbox" name="glCodeCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                    id={`glCodeCheckBox_${item.field_id}`} className="erp_radio_button glCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckGLCode')}
                  />
                </span>
                <span className="item-text">{item.field_name}</span>
              </li>
            </>
          );
        }
        return (
          <li className="item gl_code_checkboxes">
            <span className="checkbox">
              <input type="checkbox" name="glCodeCheckBox" value={item.field_id} id={`glCodeCheckBox_${item.field_id}`} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                className="erp_radio_button glCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckGLCode')}
              />
            </span>
            <span className="item-text">{item.field_name}</span>
          </li>
        );
      });
      setGLCodesCheckboxes(checkboxes);
    }
  }


  const FnToggleCheckBoxes = (checkboxType) => {
    switch (checkboxType) {
      case "CheckAllGLCodes":
        $('.glCodeCheckBox').prop('checked', $('#CheckAllGLCodes').is(":checked"));
        setTotalSelectedGLCodeCheckboxes($('input:checkbox.glCodeCheckBox:checked').length);
        // setCustomerGLCodes
        break;

      case 'PartiallyCheckGLCode':
        $('#CheckAllGLCodes').prop('checked', $('input:checkbox.glCodeCheckBox:checked').length === $('input:checkbox.glCodeCheckBox').length);
        setTotalSelectedGLCodeCheckboxes($('input:checkbox.glCodeCheckBox:checked').length);
        break;

      case "CheckAllSupplierBranchGLCodes":
        $('.BranchglCodeCheckBox').prop('checked', $('#CheckAllSupplierBranchGLCodes').is(":checked"));
        setTotalSelectedBranchGLCodeCheckboxes($('input:checkbox.BranchglCodeCheckBox:checked').length);
        // setCustomerGLCodes
        break;

      case 'PartiallyCheckBranchGLCode':
        $('#CheckAllSupplierBranchGLCodes').prop('checked', $('input:checkbox.BranchglCodeCheckBox:checked').length === $('input:checkbox.BranchglCodeCheckBox').length);
        setTotalSelectedBranchGLCodeCheckboxes($('input:checkbox.BranchglCodeCheckBox:checked').length);
        break;

      default:
        break;
    }
  }


  const FnGetSelectedGLCodesIds = () => {
    let selectedGLCodes = '';
    const checkboxes = $('.glCodeCheckBox:checked');
    checkboxes.each(function () {
      if ($(this).val() !== '0' && $(this).val() !== '') {
        selectedGLCodes += $(this).val() + ":";
      }
    });
    setCustomerGLCodes(selectedGLCodes.replace(/:$/, ''));
    return selectedGLCodes.replace(/:$/, '');
  }



  //Validations for all entries starts here.................................
  const validateCustomerData = async (functionType) => {

    if (customer_id === 0) {
      if (await validate.current.validateForm('customerFormId') === true) {
        if (await validate.current.validateForm('custBranchFormId') === true) {
          return true;
        }
      }
    } else {
      switch (functionType) {
        case 'custBranchInfo':
          if (await validate.current.validateForm('custBranchFormId') === true) {
            return true;
          } else {
            return false;
          }
        case 'custInfo':
          return FnCustContactValidation();

        case 'totalCustEntryInfo':
          if (await validate.current.validateForm('customerFormId') === true) {
            if (await validate.current.validateForm('custBranchFormId') === true) {
              //     return FnCustContactValidation();
              //   } else {
              //     return false;
              //   }
              // } else {
              //   return false;
              // }
              return true;
            }
          }
        default:
          break;
      }
    }
  };

  const validateBankFields = () => {
    validate.current.validateFieldsOnChange("bankForm")
  }

  const validateFields = async (functionType) => {

    if (customer_id === 0) {
      if (await validate.current.validateFieldsOnChange('customerFormId') === true) {
        alert('First Form Validation Successfull');
        if (await validate.current.validateFieldsOnChange('custBranchFormId') === true) {
          return true;
        }
      }
    } switch (functionType) {
      case 'custInfo':
        return await validate.current.validateFieldsOnChange('customerFormId');

      case 'custBranchInfo':
        return await validate.current.validateFieldsOnChange('custBranchFormId');

      case 'totalCustomerEntryData':
        if (await validate.current.validateFieldsOnChange('customerFormId') === true) {
          if (await validate.current.validateFieldsOnChange('custBranchFormId') === true) {
            return true;
          }
        }
      default:
        break;
    }
  }

  const validateEmail = (event) => {
    if (event.target.type === 'email' && !validateNumberDateInput.current.validateEmail(event.target.value)) {
      $(`#error_${event.target.id}`).text('Please enter valid email!...')
      $(`#error_${event.target.id}`).show()
    } else {
      $(`#error_${event.target.id}`).hide()
    }
  }

  const validateNo = (noKey) => {
    const regexNo = /^[0-9\b]+$/;
    const telephoneRegex = /^[0-9+\(\)#\.\s\/ext-]+$/;
    const value = noKey.target.value;
    switch (noKey.target.id) {
      case 'pinCode':
        if (regexNo.test(value) || value === '') {
          setCustBranchPincode(value)
        }
        break;
      case 'phoneNo':
        if (telephoneRegex.test(value) || value === '') {
          setBranchPhoneNo(value)
        }
        break;
      case 'cellNo':
        if (regexNo.test(value) || value === '') {
          setCustBranchcellNo(value)
          setUserName(value)
        }
        break;
    }
  }

  const checkCustContactEntr = async () => {
    if (customerContactData[0].cust_contact_person.length !== 0) {
      $('#error_custContact').text('Please Fill the Customer Contact.....').css('display', 'none');
      return true;
    } else {
      $('#error_custContact').text('Please Fill the Customer Contact.....').css('display', 'block');
      return false;
    }
  }

  const validateWebSite = (obj) => {
    var validateWebSite = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (obj.value === "" || validateWebSite.test(obj.value)) {
      $("#error_webSite").hide();
    } else if (obj.value !== "" && !validateWebSite.test(obj.value)) {
      $("#error_webSite").text("Please enter valid url!...");
      $("#error_webSite").show();
    }
  }


  const FnAddUpdateCustomer = async (functionType) => {
    
    setIsLoading(true);
    try {
      const checkIsValidate = await validateCustomerData(functionType);
      // const contactValidate = await checkCustContactEntr();
      if (checkIsValidate === true) {

        let json = {
          'CustomerMasterData': {}, 'CustomerBranchData': {}, 'CustomerBankData': [], 'CustomerContactData': [], 'commonIds': {
            'company_id': COMPANY_ID, 'customer_id': customer_id, 'userName': UserName, 'cust_branch_id': customerBranchId
          },
          'saveKey': functionType
        }

        if (functionType === 'custInfo' || functionType === 'totalCustEntryInfo') {
          const customerDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            customer_id: customer_id,
            customer_type: cmb_customers_type,
            customer_code: txt_customer_code,
            customer_name: txt_customer_name,
            customer_short_name: txt_customer_short_name,
            customer_sector: cmb_company_sector,
            nature_of_business: txt_nature_of_business,
            // payment_term_id: cmb_payment_term,
            payment_term_id: cmb_payment_term === '' ? 151 : cmb_payment_term,
            customer_rating: txt_customer_ratings,
            customer_gl_codes: customerGlCodes === '' ? 6 : customerGlCodes,
            customer_accounts_id: txt_customer_accounts_id,
            customer_history: txt_customer_history,
            username: txt_username,
            password: txt_password,
            is_active: cust_is_active,
            remark: remark,
            created_by: customer_id === 0 ? UserName : null,
            modified_by: customer_id !== 0 ? UserName : null,
          };
          json.CustomerMasterData = customerDATA;
        }

        ///Customer Branch Data
        var active;
        var custBranchType;

        activeValue = document.querySelector('input[name=isBranchActive]:checked').value
        switch (activeValue) {
          case '0': active = false; break;
          case '1': active = true; break;
        }

        activeValue = document.querySelector('input[name=isBranchCustomerType]:checked').value
        switch (activeValue) {
          case 'Customer': custBranchType = "Customer"; break;
          case 'Consignee': custBranchType = "Consignee"; break;
        }
        if (functionType === 'custBranchInfo' || functionType === 'totalCustEntryInfo') {
          console.log('txt_cust_branch_website', txt_cust_branch_website);
          const custBranchData = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            customer_id: customer_id,
            branch_type: chkBranchtype,
            cust_branch_id: customerBranchId,
            cust_branch_type: custBranchType,
            cust_branch_name: txt_cust_branch_name,
            cust_branch_short_name: txt_cust_branch_short_name,
            cust_branch_vendor_code: txt_customer_code,
            cust_branch_address1: txt_cust_branch_address1,
            cust_branch_address2: txt_cust_branch_address2,
            cust_branch_city_id: cmb_cust_branch__City_id,
            cust_branch_pincode: txt_cust_branch_pin_code,
            cust_branch_district_id: cmb_cust_branch__District_id,
            cust_branch_state_id: cmb_cust_branch_state_id,
            cust_branch_country_id: cmb_cust_branch__Country_id,
            cust_branch_region: cmb_customer_region,
            cust_branch_phone_no: txt_cust_branch_phone_no,
            cust_branch_cell_no: txt_cust_branch_cell_no,
            cust_branch_EmailId: txt_cust_branch_email_id,
            cust_branch_website: txt_cust_branch_website === null || txt_cust_branch_website === undefined || txt_cust_branch_website === '' ? 'NA' : txt_cust_branch_website.trim(),
            cust_branch_linkedin_profile: txt_cust_branch_linkedIn_profile === null || txt_cust_branch_linkedIn_profile === '' ? 'NA' : txt_cust_branch_linkedIn_profile.trim(),
            cust_branch_twitter_profile: txt_cust_branch_twitter_profile === null || txt_cust_branch_twitter_profile === '' ? 'NA' : txt_cust_branch_twitter_profile.trim(),
            cust_branch_facebook_profile: txt_cust_branch_profile === null || txt_cust_branch_profile === '' ? 'NA' : txt_cust_branch_profile.trim(),
            cust_branch_gst_no: txt_cust_branch_gst_no,
            cust_branch_gst_division: txt_branch_gst_division,
            cust_branch_gst_range: txt_branch_gst_range,
            cust_branch_pan_no: txt_cust_branch_pan_no,
            cust_branch_udyog_adhar_no: txt_cust_branch_udyog_adhar_no,
            cust_branch_vat_no: txt_cust_branch_vat_no,
            cust_branch_service_tax_no: txt_cust_branch_service_tax_no,
            cust_branch_excise_no: txt_branch_excise_no,
            cust_branch_cst_no: txt_branch_cst_no,
            cust_branch_bst_no: txt_cust_branch_bst_no,
            // cust_branch_payment_term_id: cmb_cust_payment_term_ID,
            cust_branch_payment_term_id: cmb_cust_payment_term_ID === '' ? 151 : cmb_cust_payment_term_ID,
            cust_branch_rating: txt_cust_branch__ratings,
            cust_branch_gl_codes: cmb_cust_branch_gl_codes === '' ? 6 : cmb_cust_branch_gl_codes,
            cust_branch_accounts_id: txt_cust_branch_accounts_id,
            cust_branch_blocked_remark: customerBlockedRemark,
            cust_branch_payment_blocked_remark: customerPaymentBlockedRemark,
            cust_branch_blocked: customerBlocked,
            cust_branch_payment_blocked: customerPaymentBlocked,
            sez_name: txt_sez_name,
            cust_branch_tally_id: txt_tally_ID,
            pysical_distance: txt_physical_distance,
            is_active: active,
            is_sez: rb_is_sez,
            created_by: customer_id === 0 ? UserName : null,
            modified_by: tempCustomerId !== 0 ? UserName : null
          };
          json.CustomerBranchData = custBranchData;

          //Customer Branch Bank Data
          // json.CustomerBankData = customerBankData;
          const customer_iD = sessionStorage.getItem('customer_ID');

          if (customerBankData.length === 0) {
            const custBankData = {
              company_id: COMPANY_ID,
              company_branch_id: COMPANY_BRANCH_ID,
              cust_bank_id: 0,
              customer_id: customer_iD,
              bank_id: 1,
              customer_bank_name: 'CASH',
              customer_bank_branch_name: 'CASH',
              cust_bank_account_type: 'Current Account',
              cust_bank_account_no: '',
              cust_bank_ifsc_code: '',
              cust_bank_swift_code: txt_customer_bank_swift_code,
              cust_bank_gst_no: txt_customer_bank_gst_no,
              currency_type: cmb_currency_type,
            }
            json.CustomerBankData = [custBankData];
          } else {
            json.CustomerBankData = customerBankData;
          }
        }
        // setCustomerBranchOptions(customerBranchId, txt_cust_branch_name);
        //Customer Customer Data
        // if (customer_id !== 0 && (functionType === 'custInfo' || functionType === 'totalCustEntryInfo')) {
        //   json.CustomerContactData = customerContactData;
        // }
        if (functionType === 'custInfo' || functionType === 'totalCustEntryInfo') {
          json.CustomerContactData = customerContactData;
        }

        const formData = new FormData();
        formData.append(`CustomerServiceData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };

        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json();
        console.log("response error: ", responce.data);
        keyForViewUpdate = 'update'

        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setIsLoading(false);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {


          if (customer_id === 0) {
            let customer_ID = responce.data.customer_id;
            setCustomerId(customer_ID);
            $('#accessContactAcc').show();

            // var custBranchList = await comboBox.current.fillMasterData("cmv_customer_branch", "customer_id", customer_ID)
            // setCustomerBranchOptions(custBranchList)
          }
          if (responce.data != null && (functionType !== 'custInfo' || functionType === 'custBranchInfo')) {
            if (tempCustomerId === 0 || tempCustomerId === undefined) {
              setPassword(responce.data.password);
            }
            tempCustomerId = responce.data.customer_id;
          }
          setCustomerBankData([]);

          // if (responce.data != null && customerContactData[0].cust_contact_person === "" && functionType === 'totalCustEntryInfo') {
          //   checkCustContactEntr();
          // }

          if (responce.cust_branch_id !== null) {
            setBranchID(responce.cust_branch_id)
            await infoForUpdate(responce.cust_branch_id, keyForViewUpdate);
          }

          // Catche evict for server side catche
          const evitCache = await comboBox.current.evitCache();
          console.log(evitCache);

          if (customerContactData[0].cust_contact_person.length !== 0) {
            $('#accessContactAcc').show();
          }
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          // checkCustContactEntr();
          localStorage.setItem("functionType", functionType);
          const availCols = await showReportRecords(reportType);
          await fetchFilteredData(PageCurrent, entriesPerPage, availCols)

        }
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsLoading(false);
    }
  }



  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {

      case 'City':
        if (cmb_cust_branch__District_id !== '' && cmb_cust_branch__District_id !== undefined) {
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
          globalQuery.table = "cmv_city"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
          globalQuery.conditions.push({ field: "district_id", operator: "=", value: cmb_cust_branch__District_id });
          comboBox.current.removeCatcheFillCombo(globalQuery).then((propertyList2) => {
            setCityOptions(propertyList2)
          })

        }
        setShowAddRecModal(false);
        break;

      case 'Payment Terms':
        comboBox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
          setPaymentTermOption(paymentTermDays)
        })
        break;

      // case 'Customer Sector':
      //   comboBox.current.fillComboBox("CompanySectors").then((customerSector) => {
      //     setCustomerSectorOption(customerSector)
      //   })
      //   break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
  }


  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'City':
        return <FrmCity btn_disabled={true} />;

      case 'Payment Terms':
        return <FrmPaymentScheduleEntry btn_disabled={true} />;

      // case 'Customer Sector':
      //   return <FrmPropertyEntry btn_disabled={true} />;

      default:
        return null;
    }
  }


  //---------------------------------------Bank Information Starts here-------------------------------------//
  const infoForUpdate = async (cust_branch_id, key) => {

    setBranchGLCodes('')
    setKeyVUD(key)
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customerbranch/FnShowParticularRecordForUpdate/${cust_branch_id}`)
      const responce = await apiCall.json();
      let resp = JSON.parse(responce.data)
      console.log("Branch Particular document: ", resp);
      // comboBox.current.fillMasterData("cmv_country", "", "").then((cmv_countryList) => {
      //   setCountryOptions(setCountryOptions);
      var countryList = await comboBox.current.fillMasterData("cmv_country", "", "")
      setCountryOptions(countryList)

      var stateList = await comboBox.current.fillMasterData("cmv_state", "country_id", JSON.stringify(resp.cust_branch_country_id))
      setStateOptions(stateList)

      var districtList = await comboBox.current.fillMasterData("cmv_district", "state_id", JSON.stringify(resp.cust_branch_state_id))
      setDistrictOptions(districtList)
      var cityList = await comboBox.current.fillMasterData("cmv_city", "state_id", JSON.stringify(data.cust_branch_state_id))
      setCityOptions(cityList)

      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
      // globalQuery.table = "cmv_city"
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      // globalQuery.conditions.push({ field: "district_id", operator: "=", value: resp.cust_branch_district_id });
      // let cityList = await comboBox.current.removeCatcheFillCombo(globalQuery);
      // setCityOptions(cityList)
      setCustBranchName(resp.cust_branch_name);
      setBranchType(resp.branch_type);
      setBranchVendorCode(resp.cust_branch_vendor_code);
      setCustBranchShortName(resp.cust_branch_short_name);
      setBranchAddress1(resp.cust_branch_address1);
      setBranchAddress2(resp.cust_branch_address2);
      setCountry(resp.cust_branch_country_id);


      setDistrict(resp.cust_branch_district_id);
      setCustBranchPincode(resp.cust_branch_pincode);
      setBranchPhoneNo(resp.cust_branch_phone_no);
      setCustBranchcellNo(resp.cust_branch_cell_no);
      setCustBranchEmail(resp.cust_branch_EmailId);
      setCustBranchWebsite(resp.cust_branch_website);
      setCustBranchProfile(resp.cust_branch_facebook_profile);
      setBranchLinkedinProfile(resp.cust_branch_linkedin_profile);
      setBranchTwitterProfile(resp.cust_branch_twitter_profile);
      setCustBranchGstNo(resp.cust_branch_gst_no);
      setCustGstDivision(resp.cust_branch_gst_division);
      setCustGstRange(resp.cust_branch_gst_range);
      setCustBranchPanNo(resp.cust_branch_pan_no);
      setBranchUdyogAdharNo(resp.cust_branch_udyog_adhar_no);
      setCustBranchVatNo(resp.cust_branch_vat_no);
      setBranchServiceTaxNo(resp.cust_branch_service_tax_no);
      await addRecordInProperty('SuppPaymentTermDays');
      setBranchPaymentTermID(resp.cust_branch_payment_term_id);
      setBranchExciseNo(resp.cust_branch_excise_no);
      setBranchCstNo(resp.cust_branch_cst_no);
      setBranchBstNo(resp.cust_branch_bst_no);
      setBranchRating(resp.cust_branch_rating);
      setBranchGLCodes(resp.cust_branch_gl_codes)
      setBranchAccountsID(resp.cust_branch_accounts_id);
      setCustSezName(resp.sez_name);
      setPhysicalDistance(resp.pysical_distance);
      setCustTallyID(resp.cust_branch_tally_id);
      setCustBranchRegion(resp.cust_branch_region);
      setcustomerBlockedRemark(resp.cust_branch_blocked_remark);
      setcustomerPaymentBlockedRemark(resp.cust_branch_payment_blocked_remark);
      setCustBranchState(resp.cust_branch_state_id);
      await FnComboOnChangeForSerach('Country', resp.cust_branch_country_id)
      setCustBranchCity(resp.cust_branch_city_id);
      await FnComboOnChangeForSerach('State', resp.cust_branch_state_id)



      var checkboxesWithValue6 = resp.cust_branch_gl_codes.split(':');
      var length = checkboxesWithValue6.length;
      $('.glcodeForBranch').text(length + ' Selected GL Codes ');
      setTotalSelectedBranchGLCodeCheckboxes(length);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isBranchActive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isBranchActive"][value="0"]').checked = true;
          break;
      }

      switch (resp.is_sez) {
        case true:
          document.querySelector('input[name="isSez"][value="true"]').checked = true;
          setIsSez(true);
          break;
        case false:
          document.querySelector('input[name="isSez"][value="false"]').checked = true;
          setIsSez(false);
          break;
      }

      switch (resp.customer_type) {
        case 'Customer':
          document.querySelector('input[name="isBranchCustomerType"][value="Customer"]').checked = true;
          break;
        case 'Consignee':
          document.querySelector('input[name="isBranchCustomerType"][value="Consignee"]').checked = true;
          break;
      }

      if (key === "update") {
        setFormHeading("Modify Branch")
        $("input[type=radio]").attr('disabled', false);
        $("input[name='chkBranchtype']").prop("disabled", true);
        $("#branchSaveBtn").attr('disabled', false);
        await validate.current.removeReadOnlyAttr("custBranchFormId");
        $("#custbranchName").attr('disabled', true);
        $("#shortNameId").attr('disabled', true);
        $('#branchVendorId').attr('disabled', true);
      } else if (key === "view") {
        setFormHeading("View Branch")
        await validate.current.readOnly("custBranchFormId");
        await validate.current.readOnly("bankForm");
        $("input[type=radio]").attr('disabled', true);
      }

      await fetchParticularBranchBanks(cust_branch_id, key);


    } catch (error) {
      console.log("error: ", error)
    }

  }
  const viewUpdateDelete = async (data, key) => {


    var branch_id = data.cust_branch_id
    setBranchID(branch_id)
    switch (key) {
      case 'update':
        setKeys('update')
        await infoForUpdate(branch_id, 'update')
        break;
      case 'delete':
        if (data.branch_type !== 'Main') {
          setShowDeleteModal(true);
        } else if (data.branch_type === 'Main') {
          setErrMsg("You couldn't delete the main branch...!")
          setShowErrorMsgModal(true)
        }
        break;
      case 'view':
        setKeys('view')
        $('#blockRemarkId').attr('disabled', true);
        $('#tallyId').attr('disabled', true);
        await validate.current.readOnly("custBranchFormId");
        await infoForUpdate(branch_id, 'view');

        break;
    }
  }

  const deleteBranch = async () => {
    try {
      const method = { method: 'DELETE' }
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customerbranch/FnDeleteRecord/${customerBranchId}/${COMPANY_ID}/${UserName}`, method)
      const response = await apiCall.json();
      console.log('Success:', response);
      setShowDeleteModal(false)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    } catch (error) {
      console.log('error:', error);
    }
  }

  const showBankRecords = async () => {
    try {
      const showBnkRecrdApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmBanksList/FnShowAllActiveRecords/${COMPANY_ID}?page=0&size=${entriesPerPage}`)
      const bankRecords = await showBnkRecrdApiCall.json();
      if (bankRecords[0].content.length > 0) {
        var bankColumn = [];
        bankColumnHeads = Object.keys(bankRecords[0].content[0]);
        console.log("column heads: ", bankColumnHeads)
        for (let colKey = 0; colKey < bankColumnHeads.length; colKey++) {
          if (colKey == 0) {
            bankColumn.push({
              Headers: "Action",
              accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <input type='checkbox' name="checkBank" id={'checkBankId_' + row.original.bank_id}
                    value={row.original.bank_id} onClick={() => handlebankCheckbox(row.original.bank_id)}>
                  </input>
                </div>
              ),
            });
          }
          bankColumn.push({ Headers: bankColumnHeads[colKey], accessor: bankColumnHeads[colKey] });

        }
        setBankColumns(bankColumn)
        setBankRecords(bankRecords[0].content)
        if (customerBranchId !== '' && customerBranchId !== undefined) {
          await fetchParticularBranchBanks(customerBranchId, keys);
        }
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }

  function handlebankCheckbox(id) {
    $('input:checkbox[name="checkBank"][value="' + id + '"]').attr('checked', false);
  }


  const showReportRecords = async (reportIdentifierKey) => {
    try {
      const summaryRptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/report/FnShowAllReportRecords?reportType=${'summary'}&viewName=${'cmv_customer_branch_rpt'}`)
      const summaryResponce = await summaryRptApiCall.json();
      console.log("summaryResponce responce: ", summaryResponce)
      if (summaryResponce.content.length !== 0) {
        var rptColumnHeads = summaryResponce.headerKeys;
        setRecordData(summaryResponce.content)
        let filterBox = [];
        let tempOptionBox = []
        for (let colKey = 0; colKey < rptColumnHeads.length; colKey++) {
          const content = summaryResponce.content;
          const columnName = rptColumnHeads[colKey];
          const value = content[columnName];
          if (value !== null) {
            const myArray = value.split(":");

            switch (myArray[2]) {
              case 'Y':
                filterBox.push({ Headers: myArray[1], accessor: columnName });
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              case 'O':
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              default:
                break

            }

          }

        }
        setFilterComboBox(filterBox)
        setAvailableColumns(tempOptionBox)
        setGroupByColumns(tempOptionBox)
        return tempOptionBox;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }

  const FnAddUpdateCustomerBank = async () => {
    const customer_iD = sessionStorage.getItem('customer_ID');
    const checkIsBankValidate = await validate.current.validateForm("bankForm");
    if (checkIsBankValidate) {
      const bankData = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        cust_bank_id: 0,
        customer_id: customer_iD,
        bank_id: cmb_bank_id,
        customer_bank_name: $("#cmb_bank_id option:selected").text(),
        customer_bank_branch_name: txt_customer_bank_branch_name,
        cust_bank_account_type: cmb_customer_bank_account_type,
        cust_bank_account_no: txt_customer_bank_account_no,
        cust_bank_ifsc_code: txt_customer_bank_ifsc_code,
        cust_bank_swift_code: txt_customer_bank_swift_code,
        cust_bank_gst_no: txt_customer_bank_gst_no,
        currency_type: cmb_currency_type,

        // cust_bank_accounts_id: txt_cust_branch_accounts_id,
        is_active: txt_is_active,
        created_by: customer_id === 0 ? UserName : null,
        modified_by: customer_id !== 0 ? UserName : null
      };

      const isDuplicate = customerBankData.some(
        (item) => item.customer_bank_name === bankData.customer_bank_name && item.cust_bank_account_type === bankData.cust_bank_account_type
      );

      if (!isDuplicate) {
        setBankId("");
        setCustomerBankBranchName("");
        setCustomerBankBranchName("");
        setCustomerBankAccountType("");
        setCustomerBankAccountNo("");
        setCustomerBankIfscCode("");
        setCustomerBankSwiftCode("");
        setCustomerBankGstNo("");
        setCurrencyType("Rupees");
        setBranchAccountsID("");
        setCustomerBankIsActive(true);

        // set Bank data in array
        setCustomerBankData((prevArray) => [...prevArray, bankData]);
      } else {
        $("#error_cmb_bank_id").text("This Bank already exists!").show();
      }
    }
  };

  const renderCustomerBanks = useMemo(() => {
    return (
      <>
        {customerBankData.length !== 0 ? (
          <Table className="erp_table mt-3" responsive bordered striped>
            <thead className="erp_table_head">
              <tr>
                {/* text-center */}
                <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
                <th className="erp_table_th">Sr. No</th>
                <th className="erp_table_th">Account Type</th>
                <th className="erp_table_th">Bank Name</th>
                <th className="erp_table_th">Branch Name</th>
                <th className="erp_table_th">Account No</th>
                <th className="erp_table_th">IFSC No</th>
                <th className="erp_table_th">Swift Code</th>
                <th className="erp_table_th">GST No</th>
                <th className="erp_table_th">Currency Type</th>
              </tr>
            </thead>
            <tbody>
              {customerBankData?.map((bankItem, index) => (
                <tr rowindex={index}>
                  <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                    <MdDelete className={`erp-delete-btn`} onClick={() => deleteBank(index)} />
                  </td>
                  <td className="erp_table_td">{index + 1}</td>
                  <td className="erp_table_td">{bankItem.cust_bank_account_type}</td>
                  <td className="erp_table_td">{bankItem.customer_bank_name}</td>
                  <td className="erp_table_td">{bankItem.customer_bank_branch_name}</td>
                  <td className="erp_table_td">{bankItem.cust_bank_account_no}</td>
                  <td className="erp_table_td">{bankItem.cust_bank_ifsc_code}</td>
                  <td className="erp_table_td">{bankItem.cust_bank_swift_code}</td>
                  <td className="erp_table_td ">{bankItem.cust_bank_gst_no}</td>
                  <td className="erp_table_td ">{bankItem.currency_type}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
      </>
    );
  }, [customerBankData]);


  const fetchParticularBranchBanks = async (cust_branch_id, key) => {
    try {
      const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customerbank/FnShowParticularRecordForUpdate/${cust_branch_id}/${COMPANY_ID}`)
      const responce = await apicall.json();
      console.log(responce)
      setCustomerBankData(responce.data)
    } catch (error) {
      console.log(error)
    }
  }


  const deleteBank = (indexToRemove) => {
    const updatedCustomerBankData = customerBankData.filter(
      (item, index) => index !== indexToRemove
    );
    setCustomerBankData(updatedCustomerBankData);
  };

  // Handler for radio button change
  const handleBlockedTypeChange = (type) => {
    if (type === 'false') {
      setcustomerBlockedRemark('')
    }
  };

  // Handler for radio button change
  const handlePaymentBlockedTypeChange = (type) => {
    if (type === 'false') {
      setcustomerPaymentBlockedRemark('')
    }
  };

  const handlePageClick = async (pageNo) => {
    let currentPage = pageNo.selected;
    setcurrentPage(currentPage);
    const commentsFormServer = await fetchFilteredData(currentPage, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    console.log("commentsFormServer: ", commentsFormServer)

  }

  const handlePageCountClick = async () => {
    let count = document.getElementById("page_entries_id").value;
    setEntriesPerPage(count)
    setcurrentPage(0)
    await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
  }


  // <!------------Filter Start------------!>

  const fetchFilteredDataToExport = async (availCols) => {
    try {
      setIsLoading(true)
      const executeQuery = await submitQuery(availCols);
      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnShowFilterRecords`, requestOptions)
      const responce = await fetchResponse.json();

      let filterColumnsToExport = [];

      if (responce.content.length > 0) {
        // var newColumnHeads = responce.Headerkeys
        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers

          filterColumnsToExport.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }

        dataExport = responce.content
        columnExport = filterColumnsToExport
      } else {
        dataExport = []
        columnExport = []
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };


  const clearFormFields = () => {

    setBranchID(0);
    setCustBranchName('');
    if (data.length !== 0) {
      setBranchType('Sub');
    }

    setBranchVendorCode(sessionStorage.getItem('defaultCustomerCode'));
    setCustBranchShortName('');
    // setBranchType('Sub');
    setBranchAddress1('');
    setBranchAddress2('');
    setCustBranchRegion('');
    setCustBranchState('');
    setCustBranchCity('');
    setCountry('');
    setDistrict('');
    setCustBranchPincode('');
    setBranchPhoneNo('');
    setCustBranchcellNo('');
    setCustBranchEmail('');
    setCustBranchWebsite('');
    setCustBranchProfile('');
    setBranchLinkedinProfile('');
    setBranchTwitterProfile('');
    setCustBranchGstNo('');
    setCustGstDivision('');
    setCustGstRange('');
    setCustBranchPanNo('');
    setBranchUdyogAdharNo('');
    setCustBranchVatNo('');
    setBranchServiceTaxNo('');
    setBranchPaymentTermID('151');
    setBranchExciseNo('');
    setBranchCstNo('');
    setBranchBstNo('');
    setcustomerBlocked(false);
    setcustomerBlockedRemark('');
    setcustomerPaymentBlocked(false);
    setcustomerPaymentBlockedRemark('');
    setBranchRating('');
    setBranchGLCodes('');
    setBranchAccountsID('');
    setIsSez(false);
    setCustSezName('');
    setCustTallyID('');
    setPhysicalDistance('');
    $('#CheckAllSupplierBranchGLCodes').prop('checked', false);
    $('.BranchglCodeCheckBox').prop('checked', false);
    setTotalSelectedBranchGLCodeCheckboxes(1);
    document.querySelector('input[name="isBranchActive"][value="1"]').checked = true;
    document.querySelector('input[name="isSez"][value="false"]').checked = true;
    document.querySelector('input[name="isBranchCustomerType"][value="Customer"]').checked = true;
    $('.branch_gl_code_checkboxes input[value="6"]').prop('checked', true);
    setCustomerBankData([])
  }

  const OpenAddBranch = async () => {
    clearFormFields();
    await FillComobos();
    await validate.current.removeReadOnlyAttr("custBranchFormId");
    setFormHeading("Customer Branch Information")
    $("#branchSaveBtn").attr('disabled', false);
    $('input:checkbox[name=checkBank]').removeAttr('checked');
    $('input:checkbox[name=checkBank]').removeAttr('disabled', 'disabled');
    $("input[type=radio]").attr('disabled', false);
    $("input[name='chkBranchtype']").prop("disabled", true);
    $("#custbranchName").attr('disabled', false);
    $("#shortNameId").attr('disabled', false);
    if (data.length !== 0) {
      setBranchType('Sub');
    }
    // $('.glCodeCheckBox').prop('checked', false);
    // setTotalSelectedGLCodeCheckboxes(0);
  }

  const fetchFilteredData = async (page, size, availCols) => {
    try {
      setIsLoading(true)
      const executeQuery = await submitQuery(availCols);

      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };

      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
      const responce = await fetchResponse.json();
      const total = responce.totalElements;
      setpageCount(parseInt(size) !== 0 ? Math.ceil(total / size) : 1);
      console.log("Responce when fetch FilteredData : ", responce)
      var nColumn = [];
      if (responce.content.length > 0) {
        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers
          if (colKey === 0) {
            nColumn.push({
              Headers: "Action", accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <Tooltip title="view" placement="top">
                    <MDTypography className="erp-view-btn" >
                      <AiFillEye className="erp-view-btn" onClick={() => viewUpdateDelete(row.original, 'view')} />
                    </MDTypography>
                  </Tooltip>
                  {compType === 'Register' ?
                    null : <>
                      <Tooltip title="Edit" placement="top">
                        <MDTypography className="erp-view-btn" >
                          <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.original, 'update')} />
                        </MDTypography>
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        <MDTypography className="erp-view-btn" >
                          <MdDelete className="erp-delete-btn" onClick={() => viewUpdateDelete(row.original, 'delete')} />
                        </MDTypography>
                      </Tooltip>
                    </>
                  }
                </div>
              ),
            });
          }
          nColumn.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }

        setColumns(nColumn);
        setBranchs(responce.content)
        // Here find the customer main-branch id.
        let mainBranch = responce.content.find(branch => branch.branch_type === 'Main')
        if (mainBranch) {
          customerMainBranchId = mainBranch.cust_branch_id
          setBranchID(customerMainBranchId)
        }

      } else {
        $('#NoRcrdId').show();
        setColumns([]);
        setBranchs([])
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  };


  const submitQuery = async (availCols) => {
    try {
      const requiredColumns = ['customer_id']

      let selectBox = document.getElementById("selectedColumns");
      let group_by = document.getElementById("group_by").value;
      let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
      storeSelectedValues = []
      if (selectBox.length !== 0) {
        let selectOrder = []; // Array to store the order of elements
        let storeSelectedValues = []; // Assuming this is defined somewhere in your code

        // Loop through selectBox to populate selectQuery and selectOrder
        for (let index = 0; index < selectBox.length; index++) {
          let optionValue = selectBox.options[index].value;
          jsonQuery['selectQuery'][index] = optionValue;
          selectOrder.push(index); // Store the index in the order array
          storeSelectedValues.push(optionValue);
        }

        // Loop through requiredColumns to add missing elements in the specified order
        for (let index = 0; index < requiredColumns.length; index++) {
          const element = requiredColumns[index];
          if (!jsonQuery.selectQuery.hasOwnProperty(element)) {
            // Add the element at the end of the order array and assign the value
            selectOrder.push(selectOrder.length);
            jsonQuery['selectQuery'][selectOrder.length - 1] = element;
          }
        }

        // Now, construct the final selectQuery object using the specified order
        let finalSelectQuery = {};
        for (let orderIndex of selectOrder) {
          finalSelectQuery[orderIndex] = jsonQuery['selectQuery'][orderIndex];
        }

        // Replace the original selectQuery with the finalSelectQuery
        jsonQuery['selectQuery'] = finalSelectQuery;


      } else {
        for (let index = 0; index < availCols.length; index++) {
          let optionValue = availCols[index].accessor;
          jsonQuery['selectQuery'][optionValue] = optionValue;
        }
      }

      for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
        let fieldvalue = selectedFilters[selIndex].id.trim()
        let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
        let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
        if (selectedFilters[selIndex].type === 'T') {
          switch (operatorSelectValue) {
            case '~':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '!~':
              operatorSelectValue = "NOT LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '^':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue;
              break;
            case '$':
              operatorSelectValue = "LIKE"
              valueSelectValue = valueSelectValue + "%";
              break;
            default:
              break;
          }
        }

        if (selectedFilters[selIndex].type === 'D' && operatorSelectValue === '<>=') {
          if (document.getElementById(`values_${fieldvalue}_id_2`).value !== '' && document.getElementById(`values_${fieldvalue}_id`).value !== '') {
            valueSelectValue = `${document.getElementById(`values_${fieldvalue}_id_2`).value}`
            operatorSelectValue = `BETWEEN '${document.getElementById(`values_${fieldvalue}_id`).value}' AND `
            // this for display filters on reports
            pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue + ` BETWEEN ` + `${document.getElementById(`values_${fieldvalue}_id`).value}`;
          } else {
            continue;
          }

        } else {
          // this for display filters on reports
          pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue;
        }
        jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
        jsonQuery['operators'][fieldvalue] = operatorSelectValue;
      }
      jsonQuery['group_by']["GROUP BY"] = group_by;
      jsonQuery['additionalParam']['is_delete'] = "0";
      // jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
      jsonQuery['additionalParam']['customer_id'] = customer_id;
      jsonQuery['orderBy']['ORDER BY'] = 'cust_branch_id'
      jsonQuery['viewname']['cmv_customer'] = 'cmv_customer_branch'


      let executeQuery = JSON.stringify(jsonQuery)
      return executeQuery;

    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    }
  }
  const moveToListing = () => {
    const path = compType === 'Register' ? '/Masters/CustomerListing/reg' : '/Masters/CustomerListing';
    navigate(path);
  }

  const addRecordInProperty = async (key) => {
    switch (key) {
      // case 'Country':
      //   const getCountryId = document.getElementById('countryId').value;
      //   setCountry(getCountryId)
      //   if (getCountryId !== '') {
      //     $('#error_countryId').hide();
      //     var stateList = await comboBox.current.fillMasterData("cmv_state", "country_id", getCountryId)
      //     setStateOptions(stateList)
      //     setCustBranchState('');
      //     setDistrictOptions([])
      //     setDistrict('');
      //     setCityOptions([]);
      //     setCustBranchCity('');
      //     setCustBranchRegion('')
      //   }
      //   break;
      // case 'State':
      //   const getStateId = document.getElementById('stateId').value;
      //   setCustBranchState(getStateId)
      //   if (getStateId !== '') {
      //     $('#error_stateId').hide();
      //     //var districtList = await comboBox.current.fillMasterData("cmv_district", "state_id", getStateId)
      //     var getCityList = await comboBox.current.fillMasterData("cmv_city", "state_id", getStateId)
      //     // setDistrictOptions([])
      //     // setDistrictOptions(districtList)
      //     // setDistrict('');
      //     setCityOptions(getCityList);
      //     //setCustBranchCity('');
      //   }
      //   break;
      // case 'District':
      //   const getDistrictId = document.getElementById('districtId').value;
      //   setDistrict(getDistrictId)
      //   if (getDistrictId !== '') {
      //     $('#error_districtId').hide();
      //     resetGlobalQuery();
      //     globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
      //     globalQuery.table = "cmv_city"
      //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      //     globalQuery.conditions.push({ field: "district_id", operator: "=", value: getDistrictId });
      //     let getCityList = await comboBox.current.removeCatcheFillCombo(globalQuery);
      //     setCityOptions(getCityList)
      //     setCustBranchCity('');
      //   }
      //   break;
      // case 'City':
      //   const propertyValCity = document.getElementById('cityId').value;
      //   if (propertyValCity === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('City')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").eq(0).css("padding-top", "0px");
      //     }, 100)
      //   }
      //   else {
      //     setCustBranchCity(propertyValCity)
      //     // Set the pincode from the city list.
      //     // const selectedCity = cityOptions.find(city => city.field_id === parseInt(propertyValCity));
      //     // setCustBranchPincode(selectedCity.city_pincode);
      //     resetGlobalQuery();
      //     globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
      //     globalQuery.table = "cmv_city"
      //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      //     globalQuery.conditions.push({ field: "city_id", operator: "=", value: propertyValCity });
      //     let getCityList = await comboBox.current.removeCatcheFillCombo(globalQuery);
      //     //  setCityOptions(getCityList)
      //     setCustBranchPincode(getCityList[0].city_pincode);
      //     if (propertyValCity !== "") { $('#error_cityId').hide(); }
      //   }
      //   break;

      case 'SuppPaymentTermDays':
        const supppaymenttermdays = document.getElementById('supplierPaymentTermID').value;
        setBranchPaymentTermID(supppaymenttermdays)
        $('#error_supplierPaymentTermID').hide();
        if (supppaymenttermdays === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Payment Terms')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 100)
        }

        break;
      // case 'Regions':
      //   const Region = document.getElementById('customerRegionID').value;
      //   setCustBranchRegion(Region)
      //   $('#error_customerRegionID').hide();
      //   break



      case 'CustPaymentTermDays':
        const custpaymentTermDays = document.getElementById('customerPaymentTermID').value;
        setPaymentTermId(custpaymentTermDays)
        $('#error_customerPaymentTermID').hide();

        if (custpaymentTermDays === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Payment Terms')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(0).css("padding-top", "0px");
          }, 100)
        }

        break;
      // case 'CustomerSectors':
      //   var propertyVal = document.getElementById('customerSectorId').value;
      //   if (propertyVal === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Customer Sector')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").css("padding-top", "0px");
      //     }, 100)
      //   }
      //   setCustomerSector(propertyVal)
      //   if (propertyVal !== "") { $('#error_customerSectorId').hide(); }
      //   break;
      case 'customerTypes':
        var customerType = document.getElementById('customerTypeId').value;
        setCustomerTypes(customerType);
        // Generate the customer-code using cust-type-shortName.
        // tblName, fieldName, type, autoNoShortName, Length
        let companyTypeShortName = $('#customerTypeId option:selected').attr('custTypeShortName');

        if (customerType !== '' && customerType !== '0') {
          let generatedCustomerCode = await generateCustomerCode('cm_customer', 'customer_code', 5, companyTypeShortName, 'C');
          // if (txt_username.trim() === '') {
          //   setUserName(generatedCustomerCode);
          //   setBranchVendorCode(generatedCustomerCode);
          // }
          // setUserName(generatedCustomerCode);
          setBranchVendorCode(generatedCustomerCode);
          $('#error_customerTypeId').hide();
        } else {
          setCustomerCodes('');
        }
        break;
    }
  }
  const addSelectedColumns = () => {
    const selectedOption = document.getElementById("availableColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = availableColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns(availableColumns.filter(column => column.accessor !== selectedValue));
        setSelectedColumns([...selectedColumns, selectedColumn]);
      }
    }
  }

  const moveAllToSelectedColumns = () => {
    setSelectedColumns([...selectedColumns, ...availableColumns]);
    setAvailableColumns([]);
  }


  const addAvailableColumns = () => {
    const selectedOption = document.getElementById("selectedColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = selectedColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns([...availableColumns, selectedColumn]);
        setSelectedColumns(selectedColumns.filter(column => column.accessor !== selectedValue));
      }
    }
  }



  const moveAllToAvailableColumns = () => {
    // Move all selected columns to available columns
    setAvailableColumns([...availableColumns, ...selectedColumns]);

    // Clear the selected columns
    setSelectedColumns([]);
  };


  const moveOptionUp = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex > 0) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex - 1]] = [newSelectedColumns[selectedIndex - 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveOptionDown = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex < selectedColumns.length - 1 && selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex + 1]] = [newSelectedColumns[selectedIndex + 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveOptionTop = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([movedColumn, ...newSelectedColumns]);
    }
  };

  const moveOptionBottom = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([...newSelectedColumns, movedColumn]);
    }
  };


  function toggleFilter() {
    $(this).text(function (_, currentText) {
      return currentText == "▼" ? "▲" : "▼";
    });
    $('.hide-show-filters').toggle('fast');
  };

  const addFilterSelect = async () => {
    let masterList;
    let filterText = document.getElementById('add_filter_select');
    if (filterText.value !== '0') {
      document.querySelectorAll("#add_filter_select option").forEach(opt => {
        if (opt.value === filterText.value) {
          opt.disabled = true;
        }
      });
      const value = recordData[filterText.value];
      const myArray = value.split(":");
      const newFilter = {
        id: filterText.value,
        label: myArray[1],
        type: myArray[3],
        dataKey: myArray[5],
        operatorArray: [],
        dataArray: []
      };
      switch (myArray[3]) {
        case 'C':
          if (myArray[5] === 'O') {
            resetGlobalQuery();
            globalQuery.columns.push(`DISTINCT (${filterText.value})`);
            globalQuery.conditions.push({
              field: "company_id",
              operator: "=",
              value: COMPANY_ID
            });
            globalQuery.conditions.push({
              field: "is_delete",
              operator: "=",
              value: 0
            });
            globalQuery.table = myArray[4]
            masterList = await comboBox.current.fillFiltersCombo(globalQuery)
          } else {
            masterList = await comboBox.current.fillMasterData(myArray[4], "", "")
          }

          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = masterList
          break;
        case 'P':
          let propertyList = await comboBox.current.fillComboBox(myArray[4])
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = propertyList
          break;
        case 'T':
          newFilter.operatorArray = operatorByType.string
          break;
        case 'H':
          // Extracting values within parentheses from the last element
          const valuesInParentheses = myArray[myArray.length - 1].slice(1, -1);
          // Splitting the string into an array of values
          const resultArray = valuesInParentheses.split(',');
          console.log(resultArray);
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = resultArray
          break;
        case 'D':
          newFilter.operatorArray = operatorByType.date
          break;
      }
      // Update the state with the new filter
      setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
    }
  }

  const exporttoPdf = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

      if (filtrdata.length !== 0) {
        var filtrObjKeys = Object.keys(pdfExpFilters);
        for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
          var key = filtrObjKeys[objKey];
          var value = pdfExpFilters[key];
        }
      }
      if (dataExport.length !== 0) {
        pdfExp.current.pdf(dataExport, columnExport, reportName, pdfExpFilters)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  }

  const exporttoExcel = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        var jsonToExportExcel = { 'allData': {}, 'columns': columnExport, 'filtrKeyValue': {}, 'headings': {}, 'key': 'reportExport' }

        if (filtrdata.length !== 0) {
          var filtrObjKeys = Object.keys(pdfExpFilters);
          for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
            var key = filtrObjKeys[objKey];
            var value = pdfExpFilters[filtrObjKeys[objKey]];
            jsonToExportExcel['filtrKeyValue'][objKey] = key + ' : ' + value
          }
        }

        for (let arrKey = 0; arrKey < dataExport.length; arrKey++) {
          jsonToExportExcel['allData'][arrKey] = dataExport[arrKey];
        }
        jsonToExportExcel['headings']['ReportName'] = reportName
        jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
        jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')

        console.log("jsonToExportExcel: ", jsonToExportExcel)
        exlsExp.current.excel(jsonToExportExcel, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const exporttoJSON = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        jsonExp.current.json(data, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  const exportToCSV = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        csvExp.current.csv(data, columnExport, reportName)
      }

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  //------------------------------ Customer Contact Functionality --------------------------------------- //
  const fnsetRowCountAndAddRow = () => {
    const lastRowIndex = customerContactData.length - 1;
    const lastRowContactPerson = customerContactData[lastRowIndex].cust_contact_person;
    if (lastRowContactPerson !== '') {
      setRowCount(rowCount + 1);
    } else {
      const tableRows = document.querySelectorAll('#customerContactTbl tbody tr');
      tableRows.forEach(row => {
        const suppContactName = row.querySelector('input[id^="cust_contact_person"]').value;
        if (suppContactName === '') {
          row.querySelector('input[id^="cust_contact_person"]').parentElement.dataset.tip = 'Please fill this Field...!';
          row.querySelector('input[id^="cust_contact_person"]').focus();
          return;
        } else {
          delete row.querySelector('input[id^="cust_contact_person"]').parentElement.dataset.tip;
        }
      })
    }
  };

  const FnUpdateCustomerContactTblRows = (rowData, event) => {
    let eventId = document.getElementById(event.target.id);
    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = event.target.value;

    switch (clickedColName) {
      case 'cust_contact_person':
        rowData[clickedColName] = enteredValue;
        if (enteredValue !== '')
          delete document.querySelector('input[id^="cust_contact_person"]').parentElement.dataset.tip;
        break;
      case 'cust_designation':
      case 'cust_contact_no':
      case 'cust_alternate_contact':
      // case 'cust_branch_id':
      case 'escalation_level':
        rowData[clickedColName] = enteredValue;
        break;

      case 'cust_email_id':
      case 'cust_alternate_EmailId':
        rowData[clickedColName] = enteredValue;
        let customerMailInp = document.getElementById(event.target.id);
        if (!validateNumberDateInput.current.validateEmail(enteredValue)) {
          customerMailInp.parentElement.dataset.tip = 'Please enter valid Mail...!';
        } else {
          delete customerMailInp.parentElement.dataset.tip;
        }
        break;
    }

    const customerContactDetails = [...customerContactData]
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
    customerContactDetails[arrayIndex] = rowData
    setCustomerContactData(customerContactDetails);
  }

  const removeFirstRow = (indexToRemove) => {
    if (indexToRemove !== 0) {
      // Create a new array without the item to be removed
      const updatedCustomerContactData = customerContactData.filter((item, index) => index !== indexToRemove);
      // Set the state with the updated array
      setCustomerContactData(updatedCustomerContactData)
    } else {
      const updatedContactData = [...customerContactData];  // Create a copy of the array
      updatedContactData[0] = { ...contactBlankObject }; // Set values of 0th index to the contactBlankObject
      setCustomerContactData(updatedContactData);
    }
  }

  const contactBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    customer_contact_id: 0,
    cust_branch_id: Branch_ID,
    customer_id: customer_id,
    cust_contact_person: '',
    cust_designation: '',
    cust_contact_no: '',
    cust_email_id: '',
    cust_alternate_contact: '',
    cust_alternate_EmailId: '',
    escalation_level: '',
    created_by: customer_id === 0 ? UserName : null,
    modified_by: customer_id !== 0 ? UserName : null
  }

  useLayoutEffect(() => {
    const getExistingCustomerContactData = [...customerContactData]
    getExistingCustomerContactData.push(contactBlankObject)
    setCustomerContactData(getExistingCustomerContactData)
  }, [rowCount])

  const renderCustomerContactTable = useMemo(() => {
    return <Table id='customerContactTbl' className={`erp_table`} responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          {keyForViewUpdate !== 'view' ? (<th className="erp_table_th">Action</th>) : null}
          <th className="erp_table_th">Customer Contact Person</th>
          {/* <th className="erp_table_th">Customer Branch </th> */}
          <th className="erp_table_th">Customer Designation</th>
          <th className="erp_table_th">Customer Contact no</th>
          <th className="erp_table_th">Whatsapp No</th>
          <th className="erp_table_th">Customer Email</th>
          <th className="erp_table_th">Customer Alternate Email</th>
          <th className="erp_table_th">Escalation level</th>

        </tr>
      </thead>
      <tbody>
        {customerContactData.map((item, index) =>
          <tr rowindex={index} className="sticky-column">
            {keyForViewUpdate !== 'view' ? (
              <td>
                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                <IoAddCircleOutline className='erp_trAdd_icon' onClick={fnsetRowCountAndAddRow} />
                {/* <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCount(rowCount + 1)} /> */}
                {/* {
                  index === 0 ? <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCount(rowCount + 1)} /> : null
                } */}
              </td>) : null}
            <td className='erp_table_td'>
              <input type="text"
                className="erp_input_field mb-0"
                id={`cust_contact_person_${index}`}
                value={item.cust_contact_person}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                Headers='cust_contact_person' disabled={keyForViewUpdate === 'view'}
              />
            </td>

            {/* <td className='erp_table_td'>
              <select className="form-select form-select-sm mb-0"
                value={item.cust_branch_id}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                id={`cust_branch_id_${index}`}
                Headers='cust_branch_id' disabled={keyForViewUpdate === 'view'}>
                <option value=''>Select</option>
                {customerBranchOptions.map(item =>
                  <option value={item.field_id}>{item.field_name}</option>
                )
                }
              </select>
            </td> */}

            {/* <td className='erp_table_td'>
              <select className="form-select form-select-sm mb-0"
                value={item.cust_designation}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                id={`cust_designation_${index}`}
                Headers='cust_designation' disabled={keyForViewUpdate === 'view'}>
                <option value='0'>Select</option>
                {designationOptions.map(item =>
                  <option value={item.field_name}>{item.field_name}</option>
                )
                }
              </select>
            </td> */}
            <td className='erp_table_td'>
              <input type="text"
                id={`cust_designation_${index}`}
                className="erp_input_field mb-0"
                value={item.cust_designation}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                Headers='cust_designation'
                maxLength="10" disabled={keyForViewUpdate === 'view'} />
            </td>

            <td className='erp_table_td'>
              <input type="text"
                id={`cust_contact_no_${index}`}
                className="erp_input_field mb-0"
                value={item.cust_contact_no}
                onChange={(e) => {
                  if (validateNumberDateInput.current.isInteger(e.target.value)) {
                    FnUpdateCustomerContactTblRows(item, e);
                  }
                }}
                Headers='cust_contact_no'
                maxLength="10" disabled={keyForViewUpdate === 'view'} />
            </td>

            <td className='erp_table_td'>
              <input type="text"
                id={`cust_alternate_contact_${index}`}
                className="erp_input_field mb-0"
                value={item.cust_alternate_contact}
                onChange={(e) => {
                  if (validateNumberDateInput.current.isInteger(e.target.value)) {
                    FnUpdateCustomerContactTblRows(item, e);
                  }
                }}
                Headers='cust_alternate_contact'
                maxLength="10"
                disabled={keyForViewUpdate === 'view'} />
            </td>


            <td className='erp_table_td'>
              <input type="email"
                id={`cust_email_id_${index}`}
                className="erp_input_field mb-0"
                value={item.cust_email_id}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                onBlur={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                Headers='cust_email_id' disabled={keyForViewUpdate === 'view'} />
            </td>

            <td className='erp_table_td'>
              <input type="email"
                id={`cust_alternate_EmailId_${index}`}
                className="erp_input_field mb-0"
                value={item.cust_alternate_EmailId}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                onBlur={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                Headers='cust_alternate_EmailId' disabled={keyForViewUpdate === 'view'} />
            </td>

            <td className='erp_table_td'>
              <input type="text"
                className="erp_input_field mb-0"
                id={`escalation_level_${index}`}
                value={item.escalation_level}
                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                Headers='escalation_level'
                disabled={keyForViewUpdate === 'view'} />
            </td>

          </tr>
        )}
      </tbody>
    </Table>
    // }, [customerContactData, customerBranchOptions, designationOptions])
  }, [customerContactData, designationOptions])
  // ----------------------------------------------------------------------------------------

  const FnCustContactValidation = async () => {

    let customerContactValid = true;
    const tableRows = document.querySelectorAll('#customerContactTbl tbody tr');
    tableRows.forEach(row => {
      const custContactName = row.querySelector('input[id^="cust_contact_person_"]').value;
      if (custContactName === '') {
        row.querySelector('input[id^="cust_contact_person_"]').parentElement.dataset.tip = 'Please fill this Field...!';
        row.querySelector('input[id^="cust_contact_person_"]').focus();
        customerContactValid = false;
        $('#error_custContact').text('Please Fill the Customer Contact.....').show();
        $('#error_custContact').focus();
        return;
      } else {
        delete row.querySelector('input[id^="cust_contact_person_"]').parentElement.dataset.tip;
        $('#error_custContact').hide()
      }


      // const custbranchName = row.querySelector('select[id^="cust_branch_id_"]').value;
      // if (custbranchName === '') {
      //   row.querySelector('select[id^="cust_branch_id_"]').parentElement.dataset.tip = 'Please fill this Field...!';
      //   row.querySelector('select[id^="cust_branch_id_"]').focus();
      //   customerContactValid = false;
      //   return;
      // } else {
      //   delete row.querySelector('select[id^="cust_branch_id_"]').parentElement.dataset.tip;
      // }
    })

    if (!customerContactValid) {
      return false;
    }

    if (customerContactData.length === 1 && customerContactData[0].customerContactData === '') {
      customerContactValid = true;
    }
    return customerContactValid;

  }

  const removeFilter = (filterId) => {
    // Remove the filter from the state
    setSelectedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
    document.querySelectorAll("#add_filter_select option").forEach(opt => {
      if (opt.value === filterId) {
        opt.disabled = false;
      }
    });
    $("#add_filter_select").val('0');
  };
  //**********************************************************code start for combo live serach**********************************************************************
  // Array of options for dropdowns
  const options = [
    { value: '0', label: 'Select', is_sez: false },
    { value: '0', label: 'Add New Record+', is_sez: false },

    // Mapping state options
    ...stateOptions.map(state => ({
      value: state.field_id,
      label: state.field_name,
    })),
    // Mapping city options
    ...cityOptions.map(city => ({
      value: city.field_id,
      label: city.field_name,
    })),
    // Mapping bank options
    ...bankList.map(bank => ({
      value: bank.field_id,
      label: bank.field_name,
    })),
  ];

  // Function to handle dropdown value changes
  const handleChange = async (selectedOptionForSearch, selectId) => {
    switch (selectId) {
      // Handling state dropdown change
      case 'cmb_cust_branch_state_id':
        if (cmb_cust_branch_state_id !== '0' && cmb_cust_branch_state_id !== '') {
          $('#error_stateId').hide()
        }
        setCustBranchState(cmb_cust_branch_state_id);
        await FnComboOnChangeForSerach('State', selectedOptionForSearch.value);
        break;
      // Handling city dropdown change
      case 'cmb_cust_branch__City_id':
        if (cmb_cust_branch__City_id !== '0' && cmb_cust_branch__City_id !== '') {
          $(`#error_cityId`).hide();
        }
        setCustBranchCity(cmb_cust_branch__City_id);
        await FnComboOnChangeForSerach('City', selectedOptionForSearch.value);
        break;
      // Handling bank dropdown change
      case 'cmb_bank_id':
        if (cmb_bank_id !== '0' && cmb_bank_id !== '') {
          $(`#error_cmb_bank_id`).hide();
        }
        setBankId(selectedOptionForSearch.value);
        break;
    }
  };

  // Function to update search box keys based on API data
  const updateKeysForSearchBox = (apiData) => {
    const options = [
      { value: '', label: 'Select' },
      { value: '0', label: 'Add New Record+' },
      ...apiData.map(items => ({
        value: items.field_id,
        label: items.field_name
      }))
    ];
    return options;
  }

  // Function to handle dropdown changes for search
  const FnComboOnChangeForSerach = async (key, selectedId) => {

    try {
      switch (key) {
        // Handling country dropdown change
        case 'Country':
          const getCountryId = document.getElementById('countryId').value;
          setCountry(getCountryId)
          if (getCountryId !== '') {
            $('#error_countryId').hide();
            var stateList = await comboBox.current.fillMasterData("cmv_state", "country_id", getCountryId)
            const updatedStateptions = updateKeysForSearchBox(stateList);
            setStateOptions(updatedStateptions);
            // setStateOptions(stateList)
            setCustBranchState('');
            setDistrictOptions([])
            setDistrict('');
            setCityOptions([]);
            setCustBranchCity('');
            setCustBranchRegion('')
          }
          break;
        // Handling state dropdown change
        case 'State':
          let getStateId = selectedId;
          setCustBranchState(getStateId)
          if (getStateId !== '') {
            $('#error_stateId').hide();
            var getCityList = await comboBox.current.fillMasterData("cmv_city", "state_id", getStateId)
            const updatedCityptions = updateKeysForSearchBox(getCityList);
            setCityOptions(updatedCityptions);
            // setCityOptions(getCityList);
          }
          break;
        // Handling city dropdown change
        case 'City':
          let propertyValCity = selectedId;
          if (propertyValCity === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('City')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          else {
            setCustBranchCity(propertyValCity)
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
            globalQuery.table = "cmv_city"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "city_id", operator: "=", value: propertyValCity });
            let getCityList = await comboBox.current.removeCatcheFillCombo(globalQuery);
            setCustBranchPincode(getCityList[0].city_pincode);
            if (propertyValCity !== "") { $('#error_cityId').hide(); }
          }
          break;
      }
    } catch (error) {
      console.log("Error: " + error)
      navigate('/Error')
    }
  }
  //**********************************************************code end for combo live serach**********************************************************************

  return (
    <>
      <ComboBox ref={comboBox} />
      <FrmValidations ref={validate} />
      <PdfExport ref={pdfExp} />
      <ExcelExport ref={exlsExp} />
      <JsonExport ref={jsonExp} />
      <CSVExport ref={csvExp} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />

      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span>Loading...</span>
          </div>
        </div> :
        ''}

      <form id="customerFormId">
        <div className='row'>
          <div className="col-sm-6 erp_form_col_div">
            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer Type<span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <select id="customerTypeId" value={cmb_customers_type} className="form-select form-select-sm" onChange={() => { validateFields('custInfo'); addRecordInProperty('customerTypes') }}>
                  <option value="">Select</option>
                  {customerTypeOption?.map(supplierTypes => (
                    <option value={supplierTypes.property_name} custTypeShortName={supplierTypes.property_value}>{supplierTypes.property_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_customerTypeId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer Code<span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <Form.Control type="text" id="customerCode" className="erp_input_field" value={txt_customer_code} onChange={e => { setCustomerCodes(e.target.value); validateFields('custInfo'); }} maxLength="50" />
                <MDTypography variant="button" id="error_customerCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer Name<span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <Form.Control type="text" id="customerNameID" className="erp_input_field" value={txt_customer_name} onChange={e => { setCustomerName(e.target.value); validateFields('custInfo') }} maxLength="200" />
                <MDTypography variant="button" id="error_customerNameID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer Short Name</Form.Label>
              </div>
              <div className="col">
                <Form.Control type="text" id="shortName" className="erp_input_field" value={txt_customer_short_name} onChange={(e) => { setCustomerShortName(e.target.value.toUpperCase()); validateFields('custInfo'); }} maxLength="10" optional="optional" />
                <MDTypography variant="button" id="error_shortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                </MDTypography>
              </div>
            </div>

            {/* <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer Sector</Form.Label>
              </div>
              <div className="col">
                <select size="sm" id="customerSectorId" value={cmb_company_sector} className="form-select form-select-sm" onChange={() => addRecordInProperty('CustomerSectors')} optional="optional" >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {customerSectorOption?.map(customerSec => (
                    <option value={customerSec.field_name}>{customerSec.field_name}</option>

                  ))}
                </select>

                <MDTypography variant="button" id="error_customerSectorId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Nature Of Business</Form.Label>
              </div>
              <div className="col">
                <Form.Control as="textarea" rows={1} id="natureOfBusiness" className="erp_txt_area " value={txt_nature_of_business} onChange={e => { setNatureOfBusiness(e.target.value); validateFields('custInfo'); }} maxlength="255" optional="optional" />
                <MDTypography variant="button" id="error_natureOfBusiness" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}



          </div>

          <div className="col-sm-6 erp_form_col_div">
            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Payment Term </Form.Label>
              </div>
              <div className="col">
                <select size='sm' id='customerPaymentTermID' className='form-select form-select-sm' value={cmb_payment_term} onChange={() => addRecordInProperty('CustPaymentTermDays')} optional="optional"  >
                  <option value=''>Select</option>
                  <option value="0">Add New Record+</option>
                  {
                    paymentTermOption?.map(paymentTerm => (
                      <option value={paymentTerm.field_id}>{paymentTerm.field_name}</option>
                    ))
                  }
                </select>
                <MDTypography variant="button" id="error_customerPaymentTermID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            {/* <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer Rating</Form.Label>
              </div>
              <div className="col">
                <Form.Control as="textarea" rows={1} id="customerRating" className="erp_txt_area " value={txt_customer_ratings} onChange={e => { setCustomerRating(e.target.value); validateFields('custInfo'); }} maxlength="255" optional="optional" />
                <MDTypography variant="button" id="error_customerRating" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            <div className='row' style={{ display: "none" }}>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Cust. GL Codes</Form.Label>
              </div>
              <div className="col">
                <div className="select-btn" onClick={() => { FnShowGlCodes() }} optional='optional'>
                  <span className="form-select form-select-sm glcodeforCust" id="">
                    {totalSelectedGLCodeCheckboxes !== 0 ? totalSelectedGLCodeCheckboxes + ' Selected GL Codes ' : 'Select GL Code'}
                  </span>
                </div>
                <ul className="list-items" id="gl_code_ul">
                  {GLCodesCheckboxes}
                </ul>
                <MDTypography variant="button" id="error_supplierGLCodesId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            {/* <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer A/C. ID</Form.Label>
              </div>
              <div className="col">
                <Form.Control type="text" id='customerAccountsId' className="erp_input_field" value={txt_customer_accounts_id} onChange={e => { setCustomerAccountId(e.target.value); validateFields('custInfo'); }} maxlength="17" optional="optional" />
                <MDTypography variant="button" id="error_customerAccountsId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            {/* <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Customer History</Form.Label>
              </div>
              <div className="col">
                <Form.Control as="textarea" rows={1} id="supplierHistoryId" className="erp_txt_area optional" value={txt_customer_history} onChange={e => { setCustomerHistory(e.target.value); validateFields('custInfo') }} maxlength="500" optional="optional" />
                <MDTypography variant="button" id="error_supplierHistoryId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">UserName <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_username" value={txt_username} onInput={(e) => { setUserName(e.target.value.trim()); validateFields('custInfo') }} maxlength="200" />
                <MDTypography variant="button" id="error_txt_username" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            {/* {
              keyForViewUpdate === "update" || "view"  ?
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Password <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" className="erp_input_field number" id="txt_password" value={txt_password} onInput={(e) => { setPassword(e.target.value.trim()); }} maxlength="50" />
                    <MDTypography variant="button" id="error_txt_password" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                : ""
            } */}
            {
              keyForViewUpdate === "update" || keyForViewUpdate === "view" ?
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Password <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col-sm-8'>
                    <div className="input-group mb-3">
                      <Form.Control type={showPassword ? 'text' : 'password'} className="erp_input_field number" id="txt_password" value={txt_password} onInput={(e) => { setPassword(e.target.value.trim()); }} maxLength="50" {...(keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {})} />
                      <span className="input-group-text" id="basic-addon2">
                        {showPassword ? (<AiFillEye onClick={togglePasswordhideandshow} />) : (<AiFillEyeInvisible onClick={togglePasswordhideandshow} />)}
                      </span>
                    </div>
                    <MDTypography variant="button" id="error_txt_password" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                : ""
            }

            {/* <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Remark</Form.Label>
              </div>
              <div className="col">
                <Form.Control as="textarea" rows={1} id="remark" className="erp_txt_area optional" value={remark} onChange={e => { setRemark(e.target.value); validateFields('custInfo') }} maxlength="255" optional="optional" />
                <MDTypography variant="button" id="error_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div> */}

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Is Active</Form.Label>
              </div>
              <div className="col">
                <Form>

                  <div className="erp_form_radio">
                    <div className="fCheck">
                      <Form.Check
                        className="erp_radio_button"
                        label="Yes"
                        type="radio"
                        value="true" checked={cust_is_active === true} onClick={() => { setCustomerIsActive(true); }}
                        name="isCustomerActive"
                        defaultChecked

                      />
                    </div>
                    <div className="sCheck">
                      <Form.Check
                        className="erp_radio_button"
                        label="No"
                        value="false" checked={cust_is_active === false} onClick={() => { setCustomerIsActive(false); }}
                        type="radio"
                        name="isCustomerActive"

                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />
      {/* Bank Branch Accordian */}
      <Accordion defaultActiveKey="1" >
        <Accordion.Item eventKey="1">
          <Accordion.Header className="erp-form-label-md">Customer Branch Info.</Accordion.Header>
          <Accordion.Body>

            {isLoading ?
              <div className="spinner-overlay"  >
                <div className="spinner-container">
                  <CircularProgress color="primary" />
                  <span id="spinner_text" className="text-dark">Loading...</span>
                </div>
              </div> :
              null}
            <button className="erp_toggle-filter" onClick={toggleFilter}>
              {/*  <AiOutlineDownCircle className="rotate_filtr_arrow" />
               <MDTypography component="label" variant="button" className="erp-form-label-md-lg">
                &nbsp; filters
              </MDTypography> */}
            </button>

            <div className="hide-show-filters card filter">
              <div className="row">

                <div className="col-sm-6 erp_filter_table_avl_col" >
                  <div className='container shadow h-100 rounded erp_group-resl_container'>
                    <div className='row erp_filter_row_tab_view'>
                      <div className='col-sm-4'>
                        <span>
                          <MDTypography component="label" variant="button" className="erp-form-label-md">
                            Available Columns
                          </MDTypography>
                          <select size="10" id="availableColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                            {availableColumns?.map(column => (
                              <option value={column.accessor}>  {column.Headers} </option>
                            ))}
                          </select>

                        </span>
                      </div>

                      <div className='col-sm-1'>
                        <div className="buttons" id="buttons_row1">
                          <TbArrowsRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToSelectedColumns} /><br></br>
                          <HiOutlineArrowNarrowRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={addSelectedColumns} /><br></br>
                          <HiOutlineArrowNarrowLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={addAvailableColumns} /><br></br>
                          <TbArrowsLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToAvailableColumns} />
                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className="col">
                          <MDTypography component="label" variant="button" className="erp-form-label-md">
                            Selected Columns
                          </MDTypography>

                          <select size="10" id="selectedColumns" className="erp_form-select-sm-filter erp_form-select-filter" onChange={e => setSelectedValue(e.target.value)}>
                            {selectedColumns.map((column, index) => (
                              <option key={index} value={column.accessor}>{column.Headers}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-1'>
                        <div className="buttons" id="buttons_row2">
                          <TbArrowsUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionTop} /><br></br>
                          <HiOutlineArrowNarrowUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionUp} /><br></br>
                          <HiOutlineArrowNarrowDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionDown} /><br></br>
                          <TbArrowsDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionBottom} />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>



                <div className="col-sm-6 erp_filter_group-by-result">
                  <div className='container shadow h-100 rounded erp_group-resl_container'>

                    <div className='row erp_group-reslut-by'>
                      <div className='col-sm-3 filtr_gropBy'>
                        <MDTypography
                          className="erp-form-label-md"
                          component="label"
                          variant="button"
                        >Group results by</MDTypography>
                      </div>
                      <div className='col filtr_gropBy'>
                        <Form.Select size="sm" className="erp_form_control operatorSelect" id="group_by">
                          <option value=""></option>
                          {groupByColumns?.map(column => (
                            <option value={column.accessor}> {column.Headers} </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="row add-filter add_filter_div">
                      <div className='col-sm-3'>
                        <MDTypography component="label" variant="button" className="erp-form-label-md">
                          Add Filter
                        </MDTypography>
                      </div>
                      <div className="col">
                        <Form.Select size="sm" onChange={addFilterSelect} className="erp_form_control group_by operatorSelect erp_add_filter" id="add_filter_select" >
                          <option value="0"></option>
                          {filterComboBox?.map(column => (
                            <option value={column.accessor}>{column.Headers}</option>

                          ))}
                        </Form.Select>
                      </div>
                    </div>


                    <table id="filters-table" className='erp-filters-table-scroll'>
                      <tbody>
                        {selectedFilters.map(filter => (
                          <tr id={filter.id} key={filter.id}>
                            <td>
                              <input
                                type="checkbox"
                                id={`cb_${filter.id}_id`}
                                value={filter.id}
                                checked
                                onClick={() => removeFilter(filter.id)}
                              />
                              <label className='erp-form-label'>&nbsp;{filter.label}</label>
                            </td>
                            {/* Operators */}
                            <td>
                              {(filter.type === 'C' || filter.type === 'P' || filter.type === 'H') && (
                                <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.operatorArray.map(operator => (
                                    <option key={operator} value={operator}>
                                      {operatorLabels[operator]}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {filter.type === 'T' && (
                                <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.operatorArray.map(operator => (
                                    <option key={operator} value={operator}>
                                      {operatorLabels[operator]}
                                    </option>
                                  ))}
                                </select>
                              )}

                              {filter.type === 'D' && (
                                <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control" onChange={() => FnRenderAdditionalInputsOnDateChange(filter)}>
                                  {filter.operatorArray.map(operator => (
                                    <option key={operator} value={operator}>
                                      {operatorLabels[operator]}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>
                            {/* Values */}
                            <td>
                              {filter.type === 'C' && (
                                <select
                                  id={`values_${filter.id}_id`}
                                  className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control"
                                >
                                  {filter.dataArray.map((item, index) => (
                                    <option
                                      key={index}
                                      value={filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                    >
                                      {filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                    </option>
                                  ))}
                                </select>
                              )}

                              {filter.type === 'P' && (
                                <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.dataArray.map((item, index) => (
                                    <option key={index} value={item.field_name}>
                                      {item.field_name}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {filter.type === 'T' && (
                                <input type="text" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />

                              )}
                              {filter.type === 'D' && (<>
                                <input type="date" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                              </>
                              )}
                              {filter.type === 'H' && (
                                <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                  {filter.dataArray.map((operator, index) => (
                                    <option key={index} value={operator}>
                                      {operator}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>


            <div className="row btn_row_class">
              <div className="col-sm-8" >
                {/* changes by shivanjali */}
                <MDButton className={`btn erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={OpenAddBranch}>Add Branch</MDButton> &nbsp;
                {/* <MDButton className="btn erp-gb-button" variant="button" fontWeight="regular" onClick={() => fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns)}>Apply Filter</MDButton> &nbsp; */}
                {/* <MDButton className="btn erp-gb-button" variant="button" fontWeight="regular" onClick={reload}>Clear Filter</MDButton>&nbsp; */}
                <span>
                  {/* changes by shivanjali */}
                  <MDButton type="button" id="viewdocument-id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;

                  <span className="page_entries">
                    <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>

                    <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                      {pageEntriesOptions.map(pageEntriesOptions => (
                        <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                      ))}
                    </Form.Select>
                  </span>
                </span>
              </div>

              <div className="col-4 pagination_id">

                <span className="exports">
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()}>PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()}>EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()}>CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()}>JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;

                </span>
              </div>
            </div>

            {data.length !== 0 ? <>
              {/* <div className="row mt-1">
                <div className="col-sm-3">
                  <div class="input-group">
                    <input type="text" className='erp_input_field form-control' style={{ height: '30px' }} value={searchState || ''}
                      onChange={(e) => setGlobalFilterSearch(e.target.value)} placeholder="Search!..." aria-describedby="basic-addon2" />
                    <span class="input-group-text" id="basic-addon2" onClick={() => { setSearchInputValue(''); setGlobalFilterSearch('') }}><FaTimes /></span>
                  </div>
                </div>
                <div className="col-sm-1">
                  <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => globalSearchOnChange()}> <RiSearchLine className="erp-download-icon-btn" /> </MDButton>
                </div>
              </div>*/}
              <Datatable data={data} columns={columns} freezeColumnCount={5} stateValue={searchInputValue} />

              {pageCount !== 1 ?
                <ReactPaginate
                  className='erp_pagination'
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link erp-gb-button"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link erp-gb-button"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link erp-gb-button"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"} /> : null}
            </> : <Card id="NoRcrdId" >
              <Card.Body>No records found...</Card.Body>
            </Card>}


            <div className='card mt-3' id="addFormOpen">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="erp-form-label-md">{formHeading}</Accordion.Header>
                  <Accordion.Body>
                    <form id='custBranchFormId'>
                      <div className='row'>
                        {/* 1 st Column */}
                        <div className='col-sm-4 erp_form_col_div'>


                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label"> Branch Type </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <div className="erp_form_radio">
                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Main" type="radio" lbl="Main" value={chkBranchtype} checked={chkBranchtype === "Main"} onClick={() => { setBranchType("Main"); }} name="chkBranchtype" defaultChecked disabled /> </div>
                                <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Sub" type="radio" lbl="Sub" value={chkBranchtype} checked={chkBranchtype === "Sub"} onClick={() => { setBranchType("Sub"); }} name="chkBranchtype" disabled /> </div>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Type<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <Form>
                                <div className="erp_form_radio">
                                  <div className="fCheck">
                                    <Form.Check className="erp_radio_button" label="Customer" type="radio" value="Customer" name="isBranchCustomerType" defaultChecked />
                                  </div>
                                  <div className="sCheck">
                                    <Form.Check className="erp_radio_button" label="Consignee" value="Consignee" type="radio" name="isBranchCustomerType" /> </div>
                                </div>
                              </Form>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Name<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="custbranchName" className="erp_input_field" value={txt_cust_branch_name} onInput={e => { validateFields('custBranchInfo'); setCustBranchName(e.target.value); }} maxLength="255" />
                              <MDTypography variant="button" id="error_custbranchName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Short Name</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="shortNameId" className="erp_input_field" value={txt_cust_branch_short_name} onChange={e => { setCustBranchShortName(e.target.value.toUpperCase()); validateFields('custBranchInfo') }} maxLength="20" optional="optional" />
                              <MDTypography variant="button" id="error_shortNameId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Vendor Code</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="branchVendorId" className="erp_input_field" value={txt_cust_branch_vendor_code} onChange={e => { setBranchVendorCode(e.target.value); validateFields('custBranchInfo') }} maxLength="255" optional="optional" />
                              <MDTypography variant="button" id="error_branchVendorId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Address1<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control as="textarea" rows={1} id="branchAddr1" className="erp_txt_area" value={txt_cust_branch_address1} onChange={e => { setBranchAddress1(e.target.value); validateFields('custBranchInfo') }} maxlength="500" />
                              <MDTypography variant="button" id="error_branchAddr1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Address2</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control as="textarea" rows={1} id="branchAddr2" className="erp_txt_area" value={txt_cust_branch_address2} onChange={e => { setBranchAddress2(e.target.value); validateFields('custBranchInfo') }} maxlength="500" optional="optional" />
                              <MDTypography variant="button" id="error_branchAddr2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Pincode<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" className="erp_input_field number" id="pinCode" value={txt_cust_branch_pin_code} onChange={e => { validateNo(e); validateFields('custBranchInfo') }} maxLength="6" />
                              <MDTypography variant="button" id="error_pinCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Country<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <select size="sm" id="countryId" className="form-select form-select-sm" value={cmb_cust_branch__Country_id} onChange={() => FnComboOnChangeForSerach("Country")}>
                                <option value="" disabled>Select</option>
                                {countryOptions?.map(country => (
                                  <option value={country.field_id}>{country.field_name}</option>
                                ))}
                              </select>
                              <MDTypography variant="button" id="error_countryId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          {/* <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">State<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <select size="sm" id="stateId" className="form-select form-select-sm" value={cmb_cust_branch_state_id} onChange={() => addRecordInProperty("State")}>
                                <option value="" disabled>Select</option>
                                {stateOptions?.map(state => (
                                  <option value={state.field_id}>{state.field_name}</option>
                                ))}

                              </select>
                              <MDTypography variant="button" id="error_stateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div> */}

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">State<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <Select
                                inputId="cmb_cust_branch_state_id" // Provide the ID for the input box
                                value={stateOptions.find(option => option.value === cmb_cust_branch_state_id)}
                                // options={stateOptions}
                                isDisabled={keyForViewUpdate === "view"}
                                options={stateOptions.filter(option => option.label !== 'Add New Record+')} // Filter out the "Add New Record+" option
                                onChange={(selectedOptionForSearch) =>
                                  handleChange(selectedOptionForSearch, 'cmb_cust_branch_state_id')
                                }

                                placeholder="Search for state..."
                                className="form-search-custom"
                                classNamePrefix="custom-select" // Add custom prefix for class names

                                styles={{
                                  option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                  }),
                                  input: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                  })
                                }}
                              />
                              <MDTypography variant="button" id="error_stateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">City<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <Select
                                inputId="cmb_cust_branch__City_id" // Provide the ID for the input box
                                value={cityOptions.find(option => option.value === cmb_cust_branch__City_id)}
                                options={cityOptions}
                                isDisabled={keyForViewUpdate === "view"}
                                onChange={(selectedOptionForSearch) =>
                                  handleChange(selectedOptionForSearch, 'cmb_cust_branch__City_id')
                                }
                                placeholder="Search for a City..."
                                className="form-search-custom"
                                classNamePrefix="custom-select" // Add custom prefix for class names

                                styles={{
                                  option: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                  }),
                                  input: (provided, state) => ({
                                    ...provided,
                                    fontSize: '12px' // Adjust the font size as per your requirement
                                  })
                                }}
                              />
                              <MDTypography variant="button" id="error_cityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          {/* <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">City<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <select id="cityId" value={cmb_cust_branch__City_id} className="form-select form-select-sm" onChange={() => addRecordInProperty("City")}>
                                <option value="" disabled>Select</option>
                                <option value="0">Add New Record +</option>
                                {cityOptions?.map(city => (
                                  <option value={city.field_id}>{city.field_name}</option>
                                ))}
                              </select>
                              <MDTypography variant="button" id="error_cityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div> */}

                          {/* <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">District<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <select size="sm" id="districtId" className="form-select form-select-sm" value={cmb_cust_branch__District_id} onChange={() => addRecordInProperty("District")}>
                                <option value="" disabled>Select</option>
                                <option></option>
                                {districtOptions?.map(district => (
                                  <option value={district.field_id}>{district.field_name}</option>

                                ))}

                              </select>
                              <MDTypography variant="button" id="error_districtId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div> */}



                          {/* <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Region<span className="required">*</span></Form.Label>
                            </div>
                            <div className='col'>
                              <select size='sm' id='customerRegionID' className='form-select form-select-sm' value={cmb_customer_region} onChange={() => addRecordInProperty('Regions')} maxlength="255">
                                {
                                  customerRegionOption?.map(supplierRegion => (
                                    <option value={supplierRegion.field_name}>{supplierRegion.field_name}</option>
                                  ))
                                }
                              </select>
                              <MDTypography variant="button" id="error_customerRegionID" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div> */}

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Phone NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <span className='erp_phone' >
                                <select size="sm" id='phoneCntryId' className='form-select-phone'>
                                  {countryCodeOptions?.map(option => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </select>
                                <Form.Control type="text" className="erp_input_field erp_phn_border" id="phoneNo" value={txt_cust_branch_phone_no} onChange={e => { validateNo(e); validateFields('custBranchInfo') }} maxLength="20" optional="optional" />
                              </span>
                              <MDTypography variant="button" id="error_phoneNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Cell NO.
                                {/* <span className="required">*</span> */}
                              </Form.Label>
                            </div>
                            <div className='col'>
                              <span className='erp_phone' >
                                <select size="sm" id='cellCntryId' className='form-select-phone'>
                                  {countryCodeOptions?.map(option => (
                                    <option value={option}>{option}</option>

                                  ))}
                                </select>
                                <Form.Control type="text" className="erp_input_field erp_phn_border" id="cellNo" value={txt_cust_branch_cell_no} onChange={e => { validateNo(e); validateFields('custBranchInfo') }} maxLength="10" optional="optional" />

                              </span>
                              <MDTypography variant="button" id="error_cellNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                        </div>

                        {/* 2 nd Column */}
                        <div className='col-sm-4 erp_form_col_div'>
                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Customer Email</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="email" id="email" className="erp_input_field" value={txt_cust_branch_email_id} onChange={e => { setCustBranchEmail(e.target.value.toLowerCase()); validateEmail(e); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_email" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Web Site</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="webSite" className="erp_input_field" value={txt_cust_branch_website} onChange={e => { setCustBranchWebsite(e.target.value); validateWebSite(e.target) }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_webSite" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Linkedin Profile</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="linkdInProfile" className="erp_input_field" value={txt_cust_branch_linkedIn_profile} onChange={e => { setBranchLinkedinProfile(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_linkdInProfile" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Twitter Profile</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="twitterProfile" className="erp_input_field" value={txt_cust_branch_twitter_profile} onChange={e => { setBranchTwitterProfile(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_twitterProfile" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Facebook Profile</Form.Label>

                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="fbProfile" className="erp_input_field" value={txt_cust_branch_profile} onChange={e => { setCustBranchProfile(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_fbProfile" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">GST NO.
                                {/* <span className="required">*</span> */}
                                {cmb_cust_branch__Country_id != '1' ? null : <span className="required">*</span>}
                              </Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="gstNo" className="erp_input_field" value={txt_cust_branch_gst_no} onChange={e => { setCustBranchGstNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('custBranchInfo') }} optional={`${cmb_cust_branch__Country_id != "1" ? "optional" : ''}`} maxLength="15" />
                              <MDTypography variant="button" id="error_gstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">GST Division</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='gstDivision' className="erp_input_field" value={txt_branch_gst_division} onChange={e => { setCustGstDivision(e.target.value); validateFields('custBranchInfo') }} maxLength="500" optional="optional" />
                              <MDTypography variant="button" id="error_gstDivision" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">GST Range</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="gstDivisionRange" className="erp_input_field" value={txt_branch_gst_range} onChange={e => { setCustGstRange(e.target.value); validateFields('custBranchInfo') }} maxLength="500" optional="optional" />
                              <MDTypography variant="button" id="error_gstDivisionRange" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">PAN NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id="panNo" className="erp_input_field" value={txt_cust_branch_pan_no} onChange={e => { setCustBranchPanNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('custBranchInfo') }} maxLength="10" optional="optional" />
                              <MDTypography variant="button" id="error_panNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Udyog Adhar NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='UdyogAdharNo' className="erp_input_field" value={txt_cust_branch_udyog_adhar_no} onChange={e => { setBranchUdyogAdharNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('custBranchInfo') }} maxLength="12" optional="optional" />
                              <MDTypography variant="button" id="error_UdyogAdharNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">VAT NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='VatNo' className="erp_input_field" value={txt_cust_branch_vat_no} onChange={e => { setCustBranchVatNo(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_VatNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Service Tax NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='taxNo' className="erp_input_field" value={txt_cust_branch_service_tax_no} onChange={e => { setBranchServiceTaxNo(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_taxNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          {/* <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Excise NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='exciseNo' className="erp_input_field" value={txt_branch_excise_no} onChange={e => { setBranchExciseNo(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_exciseNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div> */}

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">CST NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='cstNo' className="erp_input_field" value={txt_branch_cst_no} onChange={e => { setBranchCstNo(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_cstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>


                        </div>

                        {/* 3rd Column */}
                        <div className='col-sm-4 erp_form_col_div'>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">BST NO.</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='bstNo' className="erp_input_field" value={txt_cust_branch_bst_no} onChange={e => { setBranchBstNo(e.target.value); validateFields('custBranchInfo') }} maxLength="50" optional="optional" />
                              <MDTypography variant="button" id="error_bstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Payment Term</Form.Label>
                            </div>
                            <div className='col'>
                              <select id='supplierPaymentTermID' className='form-select form-select-sm' value={cmb_cust_payment_term_ID} onChange={() => addRecordInProperty('SuppPaymentTermDays')} optional="optional">
                                <option value=''>Select</option>
                                <option value="0">Add New Record+</option>
                                {
                                  paymentTermOption?.map(PaymentTermId => (
                                    <option value={PaymentTermId.field_id}>{PaymentTermId.field_name}</option>
                                  ))
                                }
                              </select>
                              <MDTypography variant="button" id="error_supplierPaymentTermID" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Rating</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='branchRating' className="erp_input_field" value={txt_cust_branch__ratings} onChange={e => { setBranchRating(e.target.value); validateFields('custBranchInfo') }} maxLength="255" optional="optional" />
                              <MDTypography variant="button" id="error_branchRating" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4 col-12'>
                              <Form.Label className="erp-form-label"> GL Codes</Form.Label>
                            </div>
                            <div className='col'>
                              <div className="select-btn" onClick={() => { FnShowGlCodesForbranch() }} optional='optional'>
                                <span className="form-select form-select-sm glcodeForBranch" id="">
                                  {totalSelectedBranchGLCodeCheckboxes !== 0 ? totalSelectedBranchGLCodeCheckboxes + ' Selected GL Codes ' : 'Select GL Code'}
                                </span>
                              </div>
                              <ul className="list-items" id="gl_code_ul_branch">
                                {BranchGLCodesCheckboxes}
                              </ul>
                              <MDTypography variant="button" id="error_suppBranchGLCodesIdd" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>


                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch A/C. ID</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='branchAccountId' className="erp_input_field" value={txt_cust_branch_accounts_id} onChange={e => { setBranchAccountsID(e.target.value); validateFields('custBranchInfo') }} maxLength="17" optional="optional" />
                              <MDTypography variant="button" id="error_branchAccountId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">  Blocked </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <div className="erp_form_radio">
                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" checked={customerBlocked === true} onChange={() => handleBlockedTypeChange('true')} onClick={() => { setcustomerBlocked(true); }} name="customerBlocked" optional="optional" /> </div>
                                <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" checked={customerBlocked === false} onChange={() => handleBlockedTypeChange('false')} onClick={() => { setcustomerBlocked(false); }} name="customerBlocked" optional="optional" /> </div>
                              </div>
                            </div>
                          </div>
                          {
                            customerBlocked === true ?
                              <>
                                <div className='row'>
                                  <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">  Blocked Remark {customerBlocked === true ? <span className="required">*</span> : ''} </Form.Label>
                                  </div>
                                  <div className='col-sm-8'>
                                    <Form.Control as="textarea" rows={1} className={`erp_txt_area ${customerBlocked === "true" ? '' : 'optional'} `} id="blockRemarkId" value={customerBlockedRemark} onChange={e => { setcustomerBlockedRemark(e.target.value); validateFields('custBranchInfo') }} maxlength="255" {...(customerBlocked === "false" ? { optional: 'optional' } : {})}  {...(keyForViewUpdate === 'view' ? { readOnly: true } : {})} />
                                    <MDTypography variant="button" id="error_blockRemarkId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                  </div>
                                </div>
                              </> : ""

                          }
                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">  Payment Blocked </Form.Label>
                            </div>
                            <div className="col-sm-8">
                              <div className="erp_form_radio">
                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" checked={customerPaymentBlocked === true} onChange={() => handlePaymentBlockedTypeChange('true')} onClick={() => { setcustomerPaymentBlocked(true); }} name="customerPaymentBlocked" optional="optional" /> </div>
                                <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" checked={customerPaymentBlocked === false} onChange={() => handlePaymentBlockedTypeChange('false')} onClick={() => { setcustomerPaymentBlocked(false); }} name="customerPaymentBlocked" optional="optional" /> </div>
                              </div>
                            </div>
                          </div>

                          {
                            customerPaymentBlocked === true ?
                              <>
                                <div className='row'>
                                  <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Payment Blocked Remark {customerPaymentBlocked === true ? <span className="required">*</span> : ''} </Form.Label>
                                  </div>
                                  <div className='col-sm-8'>
                                    <Form.Control as="textarea" rows={1} className={`erp_txt_area ${customerPaymentBlocked === "true" ? '' : 'optional'} `} id="paymentBlockRemarkId" value={customerPaymentBlockedRemark} onChange={e => { setcustomerPaymentBlockedRemark(e.target.value); validateFields('custBranchInfo') }} maxlength="255"   {...(customerPaymentBlocked === "false" ? { optional: 'optional' } : {})}  {...(keyForViewUpdate === 'view' ? { readOnly: true } : {})} />
                                    <MDTypography variant="button" id="error_paymentBlockRemarkId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                  </div>
                                </div>
                              </> : ""

                          }


                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">SEZ</Form.Label>
                            </div>
                            <div className='col'>
                              <div className="erp_form_radio">
                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="isSez" checked={rb_is_sez} onClick={() => setIsSez(true)} /> </div>
                                <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="isSez" checked={!rb_is_sez} onClick={() => setIsSez(false)} /> </div>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">SEZ Name {rb_is_sez === true ? <span className="required">*</span> : ''} </Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='txt_sez_name' className="erp_input_field" value={txt_sez_name} onChange={e => { setCustSezName(e.target.value); validateFields('custBranchInfo') }} maxLength="200" {...(rb_is_sez !== true ? { optional: 'optional' } : {})} />
                              <MDTypography variant="button" id="error_txt_sez_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Tally ID</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="text" id='tallyId' className="erp_input_field" value={txt_tally_ID} onChange={e => { setCustTallyID(e.target.value); validateFields('custBranchInfo') }} maxLength="200" optional="optional" />
                              <MDTypography variant="button" id="error_tallyId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Physical Distance</Form.Label>
                            </div>
                            <div className='col'>
                              <Form.Control type="number" id='physicalDistanceID' className="erp_input_field" value={txt_physical_distance} onChange={e => { setPhysicalDistance(e.target.value); validateFields('custBranchInfo') }} maxLength="200" optional="optional" />
                              <MDTypography variant="button" id="error_tallyIphysicalDistanceID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                              </MDTypography>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4'>
                              <Form.Label className="erp-form-label">Branch Active</Form.Label>
                            </div>
                            <div className='col'>
                              <Form>
                                <div className="erp_form_radio">
                                  <div className="fCheck">
                                    <Form.Check
                                      className="erp_radio_button"
                                      label="Yes"
                                      type="radio"
                                      value="1"
                                      name="isBranchActive"
                                      defaultChecked

                                    />

                                  </div>
                                  <div className="sCheck">
                                    <Form.Check
                                      className="erp_radio_button"
                                      label="No"
                                      value="0"
                                      type="radio"
                                      name="isBranchActive"

                                    />

                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>

                        </div>

                      </div>
                    </form>

                    {/* <hr /> */}
                    <Accordion defaultActiveKey="0" style={{ display: "none" }}>
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header className="erp-form-label-md">Banks Information</Accordion.Header>
                        <Accordion.Body>

                          <form id="bankForm">
                            {keys !== 'view' && keyForViewUpdate !== 'view' ? <>
                              <div className="row">
                                <div className="col-sm-6 erp_form_col_div">
                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">A/C. Type</Form.Label>
                                    </div>
                                    <div className="col">
                                      <select size="sm" id="cmb_customer_bank_account_type" className="form-select form-select-sm erp_input_field" value={cmb_customer_bank_account_type} onChange={(e) => { setCustomerBankAccountType(e.target.value); validateBankFields() }}>
                                        <option value="">Select</option>
                                        {bankAccountTypeList?.map(bankAcc => (
                                          <option value={bankAcc.field_name}>{bankAcc.field_name}</option>
                                        ))}
                                      </select>
                                      <MDTypography variant="button" id="error_cmb_customer_bank_account_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                      </MDTypography>
                                    </div>
                                  </div>

                                  {/* <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">Bank Name</Form.Label>
                                    </div>

                                    <div className="col">
                                      <select size="sm" id="cmb_bank_id" className="form-select form-select-sm erp_input_field" value={cmb_bank_id} onChange={(e) => { setBankId(e.target.value); validateBankFields() }}>
                                        <option value="">Select</option>
                                        {bankList?.map(bankName => (
                                          <option value={bankName.field_id}>{bankName.field_name}</option>
                                        ))}
                                      </select>
                                      <MDTypography variant="button" id="error_cmb_bank_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                      </MDTypography>
                                    </div>
                                  </div> */}

                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">Bank Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                      <Select
                                        inputId="cmb_cust_branch_state_id" // Provide the ID for the input box
                                        value={bankList.find(option => option.value === cmb_bank_id)}
                                        options={bankList.filter(option => option.label !== 'Add New Record+')}
                                        onChange={(selectedOptionForSearch) => handleChange(selectedOptionForSearch, 'cmb_bank_id')}
                                        placeholder="Search for bank..."
                                        className="form-search-custom"
                                        classNamePrefix="custom-select" // Add custom prefix for class names

                                        styles={{
                                          option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                          }),
                                          singleValue: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                          }),
                                          input: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                          })
                                        }}
                                      />
                                      <MDTypography variant="button" id="error_cmb_bank_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                      </MDTypography>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">Branch Name</Form.Label>
                                    </div>
                                    <div className='col'>
                                      <Form.Control type="text" className="erp_input_field" id="txt_customer_bank_branch_name" value={txt_customer_bank_branch_name} onChange={e => { setCustomerBankBranchName(e.target.value); validateBankFields() }} maxLength="255" />
                                      <MDTypography variant="button" id="error_txt_customer_bank_branch_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                      </MDTypography>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">Bank A/C. NO.</Form.Label>
                                    </div>

                                    <div className='col'>
                                      <Form.Control type="text" className="erp_input_field" id="txt_customer_bank_account_no" value={txt_customer_bank_account_no} onChange={(e) => {
                                        if (validateNumberDateInput.current.isInteger(e.target.value)) {
                                          setCustomerBankAccountNo(e.target.value)
                                        }; validateBankFields();
                                      }} maxLength="17" />
                                      <MDTypography variant="button" id="error_txt_customer_bank_account_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                      </MDTypography>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">IFSC NO.</Form.Label>
                                    </div>
                                    <div className='col'>
                                      <Form.Control type="text" className="erp_input_field" id="txt_customer_bank_ifsc_code" value={txt_customer_bank_ifsc_code} onChange={e => { setCustomerBankIfscCode(e.target.value.split(' ').join('').toUpperCase()); validateBankFields() }} maxLength="11" />
                                      <MDTypography variant="button" id="error_txt_customer_bank_ifsc_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                      </MDTypography>
                                    </div>
                                  </div>
                                </div>


                                {/* Second column  */}

                                <div className="col-sm-6 erp_form_col_div">
                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">Swift Code</Form.Label>
                                    </div>
                                    <div className='col'>
                                      <Form.Control type="text" className="erp_input_field" id="txt_customer_bank_swift_code" value={txt_customer_bank_swift_code} onChange={e => { setCustomerBankSwiftCode(e.target.value); }} maxLength="50" optional='optional' />
                                      <MDTypography variant="button" id="error_txt_customer_bank_swift_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                      </MDTypography>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">GST NO.</Form.Label>
                                    </div>

                                    <div className='col'>
                                      <Form.Control type="text" className="erp_input_field" id="txt_customer_bank_gst_no" value={txt_customer_bank_gst_no} onChange={e => { setCustomerBankGstNo(e.target.value.split(' ').join('').toUpperCase()); }} maxLength="50" optional='optional' />
                                      <MDTypography variant="button" id="error_txt_customer_bank_gst_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }} >
                                      </MDTypography>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <Form.Label className="erp-form-label">Currency Type</Form.Label>
                                    </div>

                                    <div className='col'>
                                      <select size="sm" id="cmb_currency_type" className="form-select form-select-sm" value={cmb_currency_type} onChange={(e) => setCurrencyType(e.target.value)} optional='optional'>
                                        <option value="" disabled>Select</option>
                                        {currencyTypeList?.map(option => (
                                          <option value={option.field_name}>{option.field_name}</option>
                                        ))}
                                      </select>
                                      <MDTypography variant="button" id="error_cmb_currency_type" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}  >
                                      </MDTypography>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4">
                                      <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>

                                    <div className="col">
                                      <Form>
                                        <div className="erp_form_radio">
                                          <div className="fCheck">
                                            <Form.Check
                                              className="erp_radio_button"
                                              label="Yes"
                                              type="radio"
                                              value="true" checked={txt_is_active === true} onClick={() => { setCustomerBankIsActive(true); }}
                                              name="isBankActive"
                                              defaultChecked
                                            />
                                          </div>
                                          <div className="sCheck">
                                            <Form.Check
                                              className="erp_radio_button"
                                              label="No"
                                              value="false" checked={txt_is_active === false} onClick={() => { setCustomerBankIsActive(false); }}
                                              type="radio"
                                              name="isBankActive"
                                            />
                                          </div>
                                        </div>
                                      </Form>
                                    </div>
                                  </div>
                                </div>
                              </div >
                            </> : null}
                            <div className="text-center">
                              {/* changes done by shivanjali */}
                              <MDButton type="button" onClick={FnAddUpdateCustomerBank} className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">Add Bank</MDButton> </div>
                            <hr />
                          </form>
                          {renderCustomerBanks}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {keyForViewUpdate !== 'view' ? (
              <div className='text-center'>
                <MDButton type="button" id="branchSaveBtn" className={`btn erp-gb-button erp_branch_btn ${customer_id !== 0 ? 'display' : 'd-none'}`} variant="button"
                  fontWeight="regular" onClick={() => FnAddUpdateCustomer('custBranchInfo')}>Save Branch</MDButton>
              </div>
            ) : null}

            <hr />
            <div>
              <Card style={{ marginTop: "10px" }}>
                <Card.Header className="erp-form-label-md">Customer Contact Info.</Card.Header>
                <Card.Body>
                  <div>
                    {renderCustomerContactTable}
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <MDTypography
                        variant="button"
                        id="error_custContact"
                        className="erp_validation"
                        fontWeight="regular"
                        color="error"
                        style={{ display: "none" }}
                      >
                      </MDTypography>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {/* <hr /> */}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>


      {/*Customer Contact Accordian */}
      {/* <div className={customer_id === 0 ? "accessContactAcc  d-none" : "accessContactAcc"}> */}
      {/* <div>
        <Accordion defaultActiveKey="0" >
          <Accordion.Item eventKey={0}>
            <Accordion.Header className="erp-form-label-md">Customer Contact Info.</Accordion.Header>
            <Accordion.Body>
              {renderCustomerContactTable}

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className='row'>
          <div className='col'>
            <MDTypography variant="button" id="error_custContact" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
            </MDTypography>
          </div>
        </div>
      
      </div> */}



      {/* Sales History Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Sales History</Accordion.Header>
          <Accordion.Body>
            FrmMCustomerSellsHistoryEntry
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* Raw Material Catelog Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Raw Material Catelog</Accordion.Header>
          <Accordion.Body>
            FrmCustomerRMProductCatelogEntry
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* Finish Goods Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Finish Goods</Accordion.Header>
          <Accordion.Body>
            FrmMCustomerFinishGodsEntry
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* MIS Information Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">MIS Information</Accordion.Header>
          <Accordion.Body>
            FrmMCustomerMISEntry
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* Evaluation Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Evaluation</Accordion.Header>
          <Accordion.Body>
            FrmMCustomerEvaluationEntry
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}

      {/* Finance History Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Finance History</Accordion.Header>
          <Accordion.Body>
            FrmMCustomerFinanceHistoryEntry
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr /> */}




      <div className="erp_frm_Btns">
        <MDButton type="button" className="erp-gb-button"
          onClick={() => {
            const path = compType === 'Register' ? '/Masters/CustomerListing/reg' : '/Masters/CustomerListing';
            navigate(path);
          }}
          variant="button"
          fontWeight="regular">Back</MDButton>
        <MDButton type="button" id='actionlabel_btn' onClick={() => FnAddUpdateCustomer('totalCustEntryInfo')} className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
          fontWeight="regular">{actionLabel}</MDButton>
        <MDButton type="button" onClick={() => goToNext(customer_id, txt_customer_code, txt_customer_name)} className={`ms-2 erp-gb-button ${customer_id !== 0 ? 'display' : 'd-none'}`} variant="button"
          fontWeight="regular">next</MDButton>
      </div >

      {/* Success Msg Popup */}
      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

      {/* Error Msg Popup */}
      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      {/* Add new Record Popup */}
      <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
        <Modal.Body className='erp_city_modal_body'>
          <div className='row'>
            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
          </div>
          {displayRecordComponent()}
        </Modal.Body>

      </Modal >

      {/* Document modal */}
      {showDocumentForm ?
        <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
          </Modal.Header>
          <Modal.Body>
            <DocumentF group_id={customer_id} document_group={"Customer"} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> : null}


    </>
  )
}