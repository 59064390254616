import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';

//React Bootstrap components
import { Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { CircularProgress } from "@material-ui/core";
import ConfigConstants from "assets/Constants/config-constant";
import MDButton from "components/MDButton";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MDTypography from "components/MDTypography";
import FrmValidations from 'FrmGeneric/FrmValidations';
import DatePicker from 'react-datepicker';

// import files
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';

function FrmManualAttendanceEntry() {

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName, COMPANY_NAME, COMPANY_BRANCH_ID } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate } = state || {}

    // UseRefs
    const navigate = useNavigate();
    const exlsExp = useRef();
    const importFile = useRef(null)
    const frmValidation = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberPercentInput = useRef();

    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
    // const [departmentopt, setDepartmentOption] = useState([]);
    const [cmb_employee_type, setEmployeeType] = useState('');
    // const [txt_department_id, setDepartmentId] = useState('');
    const [employee_code, setEmloyeeCode] = useState('');
    const [employee_name, setEmloyeeNameOnchange] = useState('');
    const [attendance_type_id, setAttendanceTypeId] = useState(1);
    const [employeeListData, setEmployeeListData] = useState([]);
    const [attendanceDetails, setAttendanceDetails] = useState([]);
    const [cmb_employee_id, setEmployeeName] = useState('');
    const [dt_attendance_from_date, setFromDate] = useState(today);
    const [dt_attendance_to_date, setToDate] = useState(today);
    // const attendanceValidDate = new Date().toISOString().split('T')[0];
    const attendanceValidDate = new Date();
    const [attendanceDateColumns, setAttendanceDates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let cmb_department_id_ref = useRef(null);
    let subDepartmentComboRef = useRef(null);
    const [dt_start_time, setStartTime] = useState('');
    const [dt_end_time, setEndTime] = useState('');
    const [two_Day_Shift, setTwoDayShift] = useState('');
    const [shift_grace_hours_min, setShiftgraceMinhour] = useState('');
    const [shift_grace_hours_max, setShiftgraceMaxhour] = useState('');
    const [shiftOptions, setShiftOptions] = useState([]);
    const [cmb_from_to_shift, setFromToShiftId] = useState('');
    const [initialStarttime, setInitialStartTime] = useState('');
    const [initialEndTime, setInitialEndTime] = useState('');

    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [cmb_departmentId, setDepartmentId] = useState(0);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [cmb_sub_department_id, setSubDepartmentId] = useState(0);
    const [txt_employee_code, setEmployeeCode] = useState();

    //Error Msg
    const handleCloseErrModal = () => {
        setShowErrorMsgModal(false)
    };

    const validateErrorMsgs = () => {
        frmValidation.current.validateFieldsOnChange('attendanceFormId')
    }
    const ClearPage = () => {

        setEmployeeType('');
        setEmployeeCode('');
        setEmployeeName('');
        setSubDepartmentId(0);
        setDepartmentId(0);
        setAttendanceDetails('');
        setFromDate(today)
        setToDate(today)
        setStartTime('');
        setEndTime('');
        setFromToShiftId('');
        cmb_department_id_ref.current = null;
        subDepartmentComboRef.current = null;
        $(`#error_dt_attendance_from_date`).hide();

    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await fillPropertyComboBoxes();
            } catch (error) {
                console.error(error);
                navigate('/Error');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);  // Month is 0-indexed
    };
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Check if dateString is undefined or null
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };

    const fillPropertyComboBoxes = async () => {

        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'property_value'];
            globalQuery.table = "amv_properties";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeTypeGroup' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ['property_name']
            const employeeTypesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setEmployeeTypeOptions(employeeTypesApiCall);

            // const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "");
            // setDepartmentOption(departmentIdApiCall);


            // resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name'];
            // globalQuery.table = "cmv_department";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            // globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

            // // const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // // setDepartmentOption(departmentIdApiCall);

            // comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((deptOptions) => {
            //     const departmentOptions = [
            //         { value: '', label: 'Select' },
            //         { value: 'All', label: 'All' },
            //         { value: '0', label: 'Add New Record+' },
            //         ...deptOptions.map((department) => ({ ...department, value: department.field_id, label: department.field_name, })),
            //     ];
            //     setDepartmentOption(departmentOptions);
            // });
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    subDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartments);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'start_time', 'end_time', 'shift_grace_hours_max', 'two_day_shift'];
            globalQuery.table = "cmv_shift"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeType });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((shiftsApiCall) => {
                setShiftOptions(shiftsApiCall);

            })

        } catch (error) {
            console.log("error : ", error)
        }
    }

    const validate_time = () => {

        let dt_start_time = $('#dt_start_time').val();
        let dt_end_time = $('#dt_end_time').val();

        let isValid = true; // Flag to track overall validity

        // Convert times to comparable format (HH:mm)
        const timeToMinutes = (time) => {
            let [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };

        const shiftStartTime = initialStarttime;
        const shiftEndTime = initialEndTime;
        const gracePeriod = 2 * 60; // 2 hours in minutes

        const shiftStartMin = timeToMinutes(shiftStartTime) - gracePeriod;
        const shiftStartMax = timeToMinutes(shiftStartTime);
        const shiftEndMin = timeToMinutes(shiftEndTime);
        const shiftEndMax = timeToMinutes(shiftEndTime) + gracePeriod;

        let dt_start_minutes = timeToMinutes(dt_start_time);
        let dt_end_minutes = timeToMinutes(dt_end_time);

        // Validate start time
        if (dt_start_time === '' || dt_start_minutes < shiftStartMin || dt_start_minutes > shiftStartMax) {
            $('#error_dt_start_time').text(`Please fill the start time between ${shiftStartMin / 60}:00 and ${shiftStartMax / 60}:00`).show();
            isValid = false;
        } else {
            $('#error_dt_start_time').hide();
        }

        // Validate end time
        if (dt_end_time === '' || dt_end_minutes < shiftEndMin || dt_end_minutes > shiftEndMax) {
            $('#error_dt_end_time').text(`Please fill the end time between ${shiftEndMin / 60}:00 and ${shiftEndMax / 60}:00`).show();
            isValid = false;
        } else {
            $('#error_dt_end_time').hide();
        }

        return isValid;
    };




    const FnComboOnchange = async (key) => {

        switch (key) {

            case 'employee_type':
                setDepartmentId('');
                setEmployeeName('');
                setEmployeeCode('');
                const employeeType = document.getElementById('cmb_employee_type').value;
                const shiftId = document.getElementById('cmb_from_to_shift').value;
                if (employeeType !== "") {

                    resetGlobalQuery();
                    globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'sub_department_name', 'department_id', 'sub_department_id', 'weeklyoff_name', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time', 'old_employee_code'];
                    globalQuery.table = "cmv_employee";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    // globalQuery.conditions.push({ field: "shift_id", operator: "=", value: shiftId });
                    if (employeeType !== "" && employeeType !== "All") {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeType });
                    }
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEmployeeListData(getEmployeeListApiCall)


                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'start_time', 'end_time', 'shift_grace_hours_max', 'two_day_shift'];
                    globalQuery.table = "cmv_shift"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    if (employeeType !== "" && employeeType !== "All") {
                        globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeType });
                    }
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((shiftsApiCall) => {
                        setShiftOptions(shiftsApiCall);
                        setStartTime(shiftsApiCall.start_time);
                        setEndTime(shiftsApiCall.end_time);
                        setTwoDayShift(shiftsApiCall.two_day_shift)

                        setShiftgraceMinhour(shiftsApiCall.shift_grace_hours_min);
                        setShiftgraceMaxhour(shiftsApiCall.shift_grace_hours_max);
                    })
                    break;
                }
                break;
            case 'department':

                subDepartmentComboRef.current = null;
                setSubDepartmentId(0);

                const departmentLists = allDepartmentsList.filter(department =>
                    (department.department_type === "M")
                );
                departmentLists.unshift({ department_id: 0, value: 'All', label: 'All' });
                setDepartmentOpts(departmentLists);


                let selectedDepartment = cmb_department_id_ref.current?.department_id;
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);

                const departmentID = cmb_department_id_ref.current?.value || 'All';
                const subdepartmentID = subDepartmentComboRef.current?.value || 'All';
                const employee_type = document.getElementById('cmb_employee_type').value;

                resetGlobalQuery();
                globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'sub_department_name', 'department_id', 'sub_department_id', 'weeklyoff_name', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time', 'old_employee_code'];
                globalQuery.table = "cmv_employee";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                if (departmentID !== "" && departmentID !== "All") {
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentID });
                }
                if (subdepartmentID !== "" && subdepartmentID !== "All") {
                    globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentID });
                }
                if (employee_type !== "" && employee_type !== "All") {
                    globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employee_type });
                }
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmployeeLIstApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setEmployeeListData(getEmployeeLIstApiCall);
                break;
            case 'employee_name':
                setAttendanceDetails([]);
                const employeeId = document.getElementById('cmb_employee_id').value;
                if (employeeId !== "" && employeeId !== "All") {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'sub_department_name', 'department_id', 'sub_department_id', 'weeklyoff_name', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time'];
                    globalQuery.table = "cmv_employee";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeId });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getEmployeeLIstApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEmloyeeCode(getEmployeeLIstApiCall[0].employee_code)
                    setEmployeeCode(getEmployeeLIstApiCall[0].employee_code)
                    setEmloyeeNameOnchange(getEmployeeLIstApiCall[0].field_name)

                }
                break;
            case 'EmployeeCode':
                // var employeeCode = $('#txt_employee_code').val();
                var serachemployeeCode = $('#txt_employee_code').val();
                setEmployeeCode(serachemployeeCode);

                // let findEmployee = employeeOptions.find((employee) => {
                //     return employee.employee_code === employeeCode;
                // })
                let findEmployee = null;

                if (serachemployeeCode.length >= 3) {
                    findEmployee = employeeListData.find((employee) => {
                        return employee.employee_code === serachemployeeCode ||
                            employee?.field_name?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                            || employee?.old_employee_code?.includes(serachemployeeCode)
                    });
                }
                if (findEmployee) {
                    setEmployeeName(findEmployee.field_id);


                } else {
                    setEmployeeName('');

                }
                break;
            case 'EmplCode':
                var employeeID = $('#cmb_employee_id').val();
                if (employeeID !== "") {
                    var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                    setEmployeeName(employeeID);
                    setEmployeeCode(employeeCode);
                }
                break;
            case 'shift_name':
                // setEmployeeCode('');
                const employeeTypes = document.getElementById('cmb_employee_type').value;

                const shiftIds = document.getElementById('cmb_from_to_shift').value;
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'start_time', 'end_time', 'shift_grace_hours_max', 'shift_grace_hours_min', 'two_day_shift'];

                globalQuery.table = "cmv_shift"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "shift_id", operator: "=", value: shiftIds });
                // globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeType });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((shiftsApiCall) => {
                    // setShiftOptions(shiftsApiCall);
                    setStartTime(shiftsApiCall[0].start_time);
                    setEndTime(shiftsApiCall[0].end_time);
                    setTwoDayShift(shiftsApiCall[0].two_day_shift)
                    setInitialStartTime(shiftsApiCall[0].start_time);
                    setInitialEndTime(shiftsApiCall[0].end_time);
                    setShiftgraceMinhour(shiftsApiCall[0].shift_grace_hours_min);
                    setShiftgraceMaxhour(shiftsApiCall[0].shift_grace_hours_max);

                })
                resetGlobalQuery();
                globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'sub_department_name', 'department_id', 'sub_department_id', 'weeklyoff_name', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time', 'old_employee_code'];
                globalQuery.table = "cmv_employee";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "shift_id", operator: "=", value: shiftIds });
                if (employeeTypes !== "" && employeeTypes !== "All") {
                    globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeTypes });
                }
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setEmployeeListData(getEmployeeListApiCall)
                break;

            case "subDepartment":

                let selectedDepTS = cmb_department_id_ref.current.department_id;
                const subDepartmentLists = allDepartmentsList.filter(department =>
                    (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                );
                subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentLists);
                const departmentIDs = cmb_department_id_ref.current?.value || 'All';
                const subdepartmentIDs = subDepartmentComboRef.current?.value || 'All';
                const employee_types = document.getElementById('cmb_employee_type').value;

                resetGlobalQuery();
                globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'sub_department_name', 'department_id', 'sub_department_id', 'weeklyoff_name', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time', 'old_employee_code'];
                globalQuery.table = "cmv_employee";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                if (departmentIDs !== "" && departmentIDs !== "All") {
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIDs });
                }
                if (subdepartmentIDs !== "" && subdepartmentIDs !== "All") {
                    globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIDs });
                }
                if (employee_types !== "" && employee_types !== "All") {
                    globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employee_types });
                }
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmployeeLIstApiCalls = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setEmployeeListData(getEmployeeLIstApiCalls);



                break;
            case "Departmentchange":

                resetGlobalQuery();
                globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
                globalQuery.table = "cm_department"
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(rcvdDepts => {
                        const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                            if (department.department_type === "M") {
                                acc.mainDepartments.push({
                                    ...department,
                                    label: department.department_name,
                                    value: department.department_id,
                                });
                            } else if (department.department_type === "S") {
                                acc.subDepartments.push({
                                    ...department,
                                    label: department.department_name,
                                    value: department.department_id,
                                });
                            }
                            return acc;
                        }, { mainDepartments: [], subDepartments: [] });
                        setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                        mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                        setDepartmentOpts(mainDepartments);
                        subDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                        setSubDepartmentOpts(subDepartments);
                    });

                break;

        }

    };

    const FnShowAttendanceDetails = async () => {
debugger
        const employee_Type = document.getElementById('cmb_employee_type').value;
        // let departmentId = document.getElementById('txt_department_id').value;
        let departmentId = cmb_department_id_ref.current.value;
        if (employee_Type === "" && (departmentId === "" || departmentId === undefined) && employee_name === "") {
            setEmployeeType('All')
            setEmployeeName('All')
            setDepartmentId('All')
        }
        const checkIsValidate = await frmValidation.current.validateForm("attendanceFormId");
        if (checkIsValidate) {
            try {
                let json = { 'MannualAttendanceData': [], 'commonIds': {} };
                setIsLoading(true);
                let employeeType = document.getElementById('cmb_employee_type').value;
                if (employeeType === "All") {
                    employeeType = '';
                }
                // let departmentId = document.getElementById('txt_department_id').value;
                let departmentId = cmb_department_id_ref.current?.value || 'All';
                // if (departmentId === "All" || departmentId === undefined) {
                //     departmentId = 0;
                // }
                let subdepartmentId = subDepartmentComboRef.current?.value || 'All';
                // if (subdepartmentId === "All" || subdepartmentId === undefined) {
                //     subdepartmentId = 0;
                // }
                const employeeId = document.getElementById('cmb_employee_id').value;
                const fromDate = document.getElementById('dt_attendance_from_date').value;
                const toDate = document.getElementById('dt_attendance_to_date').value;
                function parseDate(dateStr) {
                    const parts = dateStr.split('-');
                    return new Date(parts[2], parts[1] - 1, parts[0]); // year, month (zero-based), day
                }

                // Parse fromDate and toDate
                const fromDateObj = parseDate(fromDate);
                const toDateObj = parseDate(toDate);
                if (fromDateObj !== "" && toDateObj !== "") {
                    if (fromDateObj > toDateObj) {
                        $(`#error_dt_attendance_to_date`).text(` To Date should be less than  From Date`);
                        $(`#error_dt_attendance_to_date`).show();
                        setAttendanceDetails('');
                        return false;
                    } else {
                        $(`#error_dt_attendance_to_date`).hide();
                    }
                }

                var propertyValue = $('#cmb_employee_type option:selected').attr('property_value');
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "amv_properties";
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "EmployeeType" });
                globalQuery.conditions.push({ field: "property_value", operator: "=", value: propertyValue });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const employeeTypesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                // setEmployeeType(employeeTypesApiCall);

                const attendanceData = {
                    employee_type: employeeTypesApiCall[0].field_name,
                    // employee_type: employeeType,
                    // sub_department_id: departmentId,
                    // department_id: departmentId ,
                    // sub_department_id: subdepartmentId,
                    employee_code: employee_code,
                    employee_name: employee_name,
                    from_date: formatDate(fromDate),
                    to_date: formatDate(toDate),
                };

                if (departmentId !== null && departmentId !== 'All') {
                    attendanceData['department_id'] = departmentId;
                }
                if (subdepartmentId !== null && subdepartmentId !== "All") {
                    attendanceData['sub_department_id'] = subdepartmentId;
                }
                json.MannualAttendanceData = attendanceData;
                json.commonIds.company_id = COMPANY_ID;
                const formData = new FormData();
                formData.append(`GetMannualAttendanceData`, JSON.stringify(json));
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmManualAttendance/FnShowMannualAttendanceForUpdate`, requestOptions);
                const response = await apiCall.json();

                resetGlobalQuery();
                globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'sub_department_name', 'department_id', 'sub_department_id', 'weeklyoff_name', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time'];
                globalQuery.table = "cmv_employee";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                if (employeeType !== "" && employeeType !== "All") {
                    globalQuery.conditions.push({ field: "employee_type_group", operator: "=", value: employeeType });
                }
                if (departmentId !== "" && departmentId !== "All") {
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentId });
                }
                if (subdepartmentId !== "" && subdepartmentId !== "All") {
                    globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentId });
                }
                if (employeeId !== "" && employeeId !== "All") {
                    globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeId });
                }
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                if (getEmployeeListApiCall.length === 0) {
                    setAttendanceDetails('');
                }

                const filteredEmployeeWithoutDuplicates = getEmployeeListApiCall.filter(data => !response.MannualAttendanceDetails.some(detail => detail.employee_code === data.employee_code));

                const filteredCombineEmployeeListData = [...filteredEmployeeWithoutDuplicates, ...response.MannualAttendanceDetails];

                await FnShowAttendanceByDate(filteredCombineEmployeeListData);

            } catch (error) {
                console.error('Error showing attendance details:', error);
                navigate('/Error');
            } finally {
                setIsLoading(false);
            }
        }
    }

    const updateAttendanceTblData = (currentAttendanceDetails, event) => {
        try {
            let clickedColName = event.target.getAttribute('Headers');
            delete event.target.parentElement.dataset.tip;
            currentAttendanceDetails[clickedColName] = event.target.value;

            const updateAttendanceDetails = [...attendanceDetails];
            const attendanceIndexInArray = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));
            updateAttendanceDetails[attendanceIndexInArray] = currentAttendanceDetails;
            setAttendanceDetails(updateAttendanceDetails);
        } catch (error) {
            // console.error("error :", error);
        }
    }

    const FnValidateAttendanceDetails = async () => {
        if (attendanceDetails.length === 0) {
            setErrMsg('Please add at least one Attendance Details...! ');
            setShowErrorMsgModal(true);
            return false;
        } else {
            let selectedAttendanceRows = $('#attendanceFormId-table tbody tr');
            let attendance = true;
            $(selectedAttendanceRows.get().reverse()).each(function () {
                let currentTblRow = $(this);
                let inputsInRow = currentTblRow.find('input[type="text"]');
                inputsInRow.each(function () {
                    if ($(this).val() === '') {
                        this.parentElement.dataset.tip = 'Please fill this field...!';
                        $(this).focus();
                        attendance = false;
                    }
                });
            });
            return attendance;
        }
    }

    const FnShowAttendanceByDate = (attendanceDetails) => {

        try {
            if (attendanceDetails.length !== 0) {
                // const fromDate = new Date(document.getElementById('dt_attendance_from_date').value);
                // const toDate = new Date(document.getElementById('dt_attendance_to_date').value);
                const dateColumns = [];
                // let currentDate = new Date(fromDate);
                const fromDate = document.getElementById('dt_attendance_from_date').value;
                const toDate = document.getElementById('dt_attendance_to_date').value;


                let currentDate = parseDate(fromDate);
                const endDate = parseDate(toDate);
                while (currentDate <= endDate) {
                    const day = currentDate.getDate();
                    const month = currentDate.getMonth() + 1;
                    const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'short' });
                    const dayOfWeekFullName = currentDate.toLocaleDateString('en-US', { weekday: 'long' }); // Use 'long' option for full day name
                    const dateStr = `${day}/${month}/${currentDate.getFullYear()}`;
                    const headerStr = `${day}/${month}`;
                    const days = `${dayOfWeek}`;
                    const fullDaysName = `${dayOfWeekFullName}`;
                    dateColumns.push({ header: headerStr, accessor: dateStr, days, fullDaysName });
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                const updatedAttendanceDetails = attendanceDetails.map(attendanceDetail => {
                    const updatedDetails = { ...attendanceDetail };

                    dateColumns.forEach((date) => {
                        const dateString = date.header;
                        const weeklyoffDay = date.fullDaysName;
                        const employeeWeeklyDayName = updatedDetails.weeklyoff_name;

                        if (weeklyoffDay === employeeWeeklyDayName) {
                            updatedDetails[dateString] = 'WO';
                        } else {
                            if (updatedDetails[dateString] === '' || updatedDetails[dateString] === undefined) {
                                updatedDetails[dateString] = 'PR';
                            }
                        }

                    });

                    return updatedDetails;

                });

                const headersAndAccessors = [...dateColumns];
                setAttendanceDates(headersAndAccessors);
                setAttendanceDetails(updatedAttendanceDetails);
                return updatedAttendanceDetails;
            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error');
        }
    };

    const validateEmployeeForm = async () => {
        debugger
        var departmentId = $("#cmb_departmentId").val();
        if (cmb_department_id_ref.current?.department_id === "" || cmb_department_id_ref.current?.department_id === undefined || cmb_department_id_ref.current?.department_id === 0) {
            $("#error_cmb_department_id").text("Please fill this field...!");
            $("#error_cmb_department_id").show();
            $("#cmb_departmentId").focus();
            return false;
        } else {
            $("#error_cmb_department_id").hide();
        }
        // if (cmb_sub_department_id === '' || cmb_sub_department_id === 0) {
        if (subDepartmentComboRef.current?.department_id === "" || subDepartmentComboRef.current?.department_id === undefined || subDepartmentComboRef.current?.department_id === 0) {

            $("#error_cmb_sub_department_id").text("Please fill this field...!");
            $("#error_cmb_sub_department_id").show();
            $("#cmb_sub_department_id").focus();
            return false;
        } else {
            $("#error_cmb_sub_department_id").hide();
        }

        return true;
    }
    // ---------------------------- save Attendance Details. -------------------------------------

    const FnAddManualAttendanceData = async () => {

        let dt_start_time = $('#dt_start_time').val();
        let dt_end_time = $('#dt_end_time').val();
        const checkIsValidatedpt = await validateEmployeeForm();
        if (checkIsValidatedpt === true) {
            const checkIsValidate = await frmValidation.current.validateForm("attendanceFormId");
            let validate_Date = validate_time();
            if (checkIsValidate && validate_Date === true) {
                let json = { 'MannualAttendanceData': [], 'ManualAttendaceDates': [], 'commonIds': {} }
                try {
                    let selectElement = document.getElementById("cmb_from_to_shift");
                    let selectedOptionText = selectElement.options[selectElement.selectedIndex].text;
                    if (await FnValidateAttendanceDetails()) {

                        for (let i = 0; i < attendanceDetails.length; i++) {
                            const employee = attendanceDetails[i];

                            resetGlobalQuery();
                            globalQuery.columns = ['field_name', 'field_id', 'employee_type', 'employee_type_group', 'halfday_hours', 'fullday_hours', 'grace_early_time', 'grace_late_time'];
                            globalQuery.table = "cmv_employee";
                            globalQuery.conditions.push({ field: "employee_code", operator: "=", value: employee.employee_code });

                            const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                            if (getEmployeeListApiCall.length === 0) {
                                setAttendanceDetails('');
                            } else {
                                // Merge additional fields into the employee object
                                const additionalData = getEmployeeListApiCall[0];
                                employee.field_name = additionalData.field_name;
                                employee.field_id = additionalData.field_id;
                                employee.employee_type = additionalData.employee_type;
                                employee.employee_type_group = additionalData.employee_type_group;
                                employee.halfday_hours = additionalData.halfday_hours;
                                employee.fullday_hours = additionalData.fullday_hours;
                                employee.grace_early_time = additionalData.grace_early_time;
                                employee.grace_late_time = additionalData.grace_late_time;
                            }
                        }

                        json.MannualAttendanceData = attendanceDetails;
                        // json.MannualAttendanceData = attendanceDetails
                        json.ManualAttendaceDates = attendanceDateColumns
                        json.commonIds.attendance_type_id = attendance_type_id;
                        json.commonIds.modified_by = UserName
                        json.commonIds.company_id = COMPANY_ID
                        json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                        json.commonIds.employee_name = employee_name
                        json.commonIds.employeeTypeGroup = cmb_employee_type
                        json.commonIds.startTime = dt_start_time
                        json.commonIds.endTime = dt_end_time
                        json.commonIds.shiftId = cmb_from_to_shift
                        json.commonIds.shiftName = selectedOptionText;
                        json.commonIds.two_Day_Shift = two_Day_Shift;
                        const formData = new FormData();
                        formData.append(`MannualAttendanceData`, JSON.stringify(json))
                        const requestOptions = {
                            method: 'POST',
                            body: formData
                        };

                        setIsLoading(true);
                        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmManualAttendance/FnAddUpdateRecord`, requestOptions)
                        const resp = await apiCall.json()
                        if (resp.success === 0) {
                            setErrMsg(resp.error)
                            setShowErrorMsgModal(true)
                        } else {
                            console.log("resp: ", resp)
                            ClearPage();
                            setSuccMsg(resp.message)
                            setShowSuccessMsgModal(true)
                        }
                    }
                } catch (error) {
                    console.log("error: ", error)
                    navigate('/Error')
                } finally {
                    setIsLoading(false);
                }
            }
        }
    }

    // ---------------------------- Export2Excel Functionality Start. -------------------------------------

    const ExportToExcel = async () => {

        try {
            setIsLoading(true);
            const headerFormIsValid = await frmValidation.current.validateForm("attendanceFormId");
            if (attendanceDetails.length !== 0 && headerFormIsValid) {
                let jsonToExportExcel = {
                    'allData': {},
                    'columns': [],
                    'filtrKeyValue': {},
                    'headings': {},
                    'key': 'bomExport',
                    'editable_cols': []
                };

                attendanceDateColumns.forEach((column, index) => {

                    if (index === 0) {
                        jsonToExportExcel.columns.push(
                            { "Headers": 'Employee Name', "accessor": 'field_name' },
                            { "Headers": 'Employee Code', "accessor": 'employee_code' },
                            { "Headers": 'Employee Type', "accessor": 'employee_type' },
                            { "Headers": 'Department Id', "accessor": 'department_id' },
                            { "Headers": 'Sub Department Id', "accessor": 'sub_department_id' }
                            // { "Headers": 'Department Id', "accessor": 'department_name' },
                            // { "Headers": 'Sub Department Id', "accessor": 'sub_department_name' }
                        );
                    }
                    jsonToExportExcel.columns.push({ "Headers": column.header, "accessor": column.header });
                    jsonToExportExcel.editable_cols.push(index + 5); // Index starts from 0, so increment by 1
                });

                let filtrKeyValue = {};
                filtrKeyValue['0'] = "Employee Type: " + ($('#cmb_employee_type').find(":selected").text().trim() || "") + "(" + cmb_employee_type + ")";
                filtrKeyValue['1'] = cmb_employee_id ? "Employee Name: " + $('#cmb_employee_id').find(":selected").text() + "(" + cmb_employee_id + ")" : "Employee Name: ";
                filtrKeyValue['4'] = "Employee Code" + ' : ' + $('#txt_employee_code').val()
                filtrKeyValue['2'] = cmb_department_id_ref.current.value ? "Department Name: " + cmb_department_id_ref.current.label + "(" + cmb_department_id_ref.current.value + ")" : "Department Name: ";
                filtrKeyValue['3'] = subDepartmentComboRef.current.value
                    ? "Sub Department Name: " + subDepartmentComboRef.current.label + "(" + subDepartmentComboRef.current.value + ")"
                    : "Sub Department Name: ";

                filtrKeyValue['5'] = cmb_from_to_shift ? "Shift Name: " + $('#cmb_from_to_shift').find(":selected").text() + "(" + cmb_from_to_shift + ")" : "Shift Name: ";
                filtrKeyValue['6'] = "From Date" + ' : ' + $('#dt_attendance_from_date').val()
                filtrKeyValue['7'] = "To Date" + ' : ' + $('#dt_attendance_to_date').val()
                filtrKeyValue['8'] = "From Time" + ' : ' + $('#dt_start_time').val()
                filtrKeyValue['9'] = "To Time" + ' : ' + $('#dt_end_time').val()
                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

                for (let index = 0; index < attendanceDetails.length; index++) {
                    jsonToExportExcel['allData'][index] = attendanceDetails[index]
                }
                jsonToExportExcel['headings']['ReportName'] = "Manual Attendance Details";
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME;
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress');
                console.log("jsonToExportExcel: ", jsonToExportExcel);
                exlsExp.current.excel(jsonToExportExcel, "Manual_Attendance" + COMPANY_ID);
            }
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }

    // ---------------------------- Export2Excel Functionality Ends. -------------------------------------

    // ---------------------------- ImportExcel Functionality Starts. -------------------------------------
    const onImportClick = () => {
        importFile.current.click();
    };

    function convertAmPmTo24HourFormat(time) {
        const [timePart, period] = time.split(' ');
        let [hours, minutes] = timePart.split(':');

        hours = parseInt(hours);
        minutes = parseInt(minutes);

        if (period === 'PM' && hours < 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    }
    const importedExcelFile = async (event) => {
        setIsLoading(true);
        debugger
        try {
            if (event.target.files) {
                var file = event.target.files[0];
                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmManualAttendance/FnReadExcel`, requestOptions)
                const response = await apicall.json();

                if (response !== null && response.success === 1) {
                    let comboNames = ['Employee Type', 'Employee Name', 'Department Name', 'Sub Department Name', 'Shift Name']

                    if (response.data.length !== 0) {
                        for (let formFieldIndex = 0; formFieldIndex < response.formFieldData.length; formFieldIndex++) {
                            const element = response.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                let comboVal;

                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }

                                switch (fieldName) {
                                    case 'Employee Type':
                                        setEmployeeType(comboVal);
                                        await FnComboOnchange('Departmentchange')
                                        break;
                                    case 'Employee Code':
                                        setEmployeeCode(fieldVal);
                                        break;
                                    case 'Employee Name':
                                        await FnComboOnchange('department')
                                        await FnComboOnchange('employee_name')
                                        setEmployeeName(comboVal);

                                        break;
                                    case 'Shift Name':
                                        setFromToShiftId(comboVal);
                                        break;

                                    case 'Department Name':
                                        const selectedOption = departmentOpts.find(option => option.value === parseInt(comboVal));
                                        if (selectedOption) {
                                            setDepartmentId(parseInt(comboVal));
                                            cmb_department_id_ref.current.value = selectedOption.department_id; // Update the ref to match the selected option
                                            cmb_department_id_ref.current.department_id = selectedOption.department_id;
                                        }
                                        break;

                                    case 'Sub Department Name':
                                        // setSubDepartmentId(parseInt(comboVal));

                                        const selectedOptionSubDpt = subDepartmentOpts.find(option => option.value === parseInt(comboVal));

                                        if (selectedOptionSubDpt) {
                                            // Update the state to reflect the selected option's value
                                            setSubDepartmentId(parseInt(comboVal));

                                            subDepartmentComboRef.current = selectedOptionSubDpt;
                                        }
                                        break;
                                    case 'From Date':
                                        setFromDate(formatDate(fieldVal));
                                        break;

                                    case 'To Date':
                                        setToDate(formatDate(fieldVal));
                                        break;
                                    case 'From Time':
                                        const fromTimeVal = field[1].trim() + ':' + field[2].trim();
                                        setStartTime(convertAmPmTo24HourFormat(fromTimeVal));
                                        break;

                                    case 'To Time':
                                        const fromTimeVals = field[1].trim() + ':' + field[2].trim();
                                        setEndTime(convertAmPmTo24HourFormat(fromTimeVals));
                                        break;
                                }
                            }
                        }

                        const fromDate = document.getElementById('dt_attendance_from_date').value;
                        const toDate = document.getElementById('dt_attendance_to_date').value;
                        const dateColumns = [];
                        // let currentDate = new Date(fromDate);
                        let currentDate = parseDate(fromDate);
                        const endDate = parseDate(toDate);
                        while (currentDate <= endDate) {
                            const day = currentDate.getDate();
                            const month = currentDate.getMonth() + 1;
                            const dateStr = `${day}/${month}/${currentDate.getFullYear()}`;
                            const headerStr = `${day}/${month}`;
                            dateColumns.push({ header: headerStr, accessor: dateStr });
                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                        setAttendanceDates(dateColumns);
                        // const modifiedData = response.data.map(obj => {
                        //     const newObj = {};
                        //     Object.keys(obj).forEach(key => {
                        //         // Replace "Employee Code" with "employee_code"
                        //         const newKey = key === "Employee Code" ? "employee_code" : key;
                        //         newObj[newKey] = obj[key];
                        //     });
                        //     return newObj;
                        // });
                        // setAttendanceDetails(modifiedData);
                        const modifiedData = response.data.map(({ "Employee Code": employee_code, "Department Id": department_id, "Sub Department Id": sub_department_id, "Employee Type": employee_type, ...rest }) => ({
                            employee_code,
                            department_id,
                            sub_department_id,
                            employee_type,
                            ...rest
                        }));

                        setAttendanceDetails(modifiedData);

                        // setAttendanceDetails(response.data);
                    }
                } else {
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }

    // ---------------------------- ImportExcel Functionality Ends. -------------------------------------

    const renderLeavesDetailsTable = useMemo(() => {
        if (attendanceDetails.length !== 0) {
            return (
                <>
                    <Table className="erp_table" style={{ display: "block", padding: "10px" }} id="attendanceFormId-table" responsive bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                {/* First column for employee_name */}
                                <th className="erp_table_th" id={`attendance_employee_name`}>Employee Name</th>

                                {/* Date columns */}
                                {attendanceDateColumns.map((date, indexOfItem) => (
                                    <th key={indexOfItem} className="erp_table_th" id={`attendance_${date}`}>
                                        {date.header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='erp_table_th'></td>
                                {/* Add empty cells for date columns */}
                                {attendanceDateColumns.map((date, idx) => (
                                    <td className='erp_table_th'>{date.days}</td>
                                ))}
                            </tr>
                            {attendanceDetails?.map((attendaceParam, rowIndex) => (
                                <tr rowIndex={rowIndex} key={`row_${rowIndex}`} className="sticky-column">
                                    {/* First cell for employee_name */}
                                    <td className='erp_table_td'>{attendaceParam.field_name}</td>

                                    {/* Date cells */}
                                    {attendanceDateColumns.map((date, idx) => (
                                        <td className='erp_table_td' key={idx}>
                                            <input
                                                type="text" id={`attendance_${date.header}`} className="erp_input_field_table_txt attendance mb-0"
                                                value={attendaceParam[date.header]} style={{ width: '50px' }}
                                                onChange={(e) => { updateAttendanceTblData(attendaceParam, e); }}
                                                onBlur={(e) => { updateAttendanceTblData(attendaceParam, e); }}
                                                onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }}
                                                Headers={`${date.header}`}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}


                        </tbody>
                    </Table>
                </>
            );
        }
    }, [dt_attendance_from_date, dt_attendance_to_date, attendanceDetails]);
    const validatedatetimeFields = (key) => {

        const attendanceFromDate = document.getElementById('dt_attendance_from_date').value;
        const AttendanceEndDate = document.getElementById('dt_attendance_to_date').value;
        switch (key) {
            case 'from_date':
                if (attendanceFromDate !== "" && AttendanceEndDate !== "") {
                    if (attendanceFromDate > AttendanceEndDate) {
                        $(`#error_dt_attendance_from_date`).text(`From Date must be before To Date.`);
                        $(`#error_dt_attendance_from_date`).show();
                        $(`#error_dt_attendance_to_date`).hide();
                    } else {
                        $(`#error_dt_attendance_from_date`).hide();
                        $(`#error_dt_attendance_to_date`).hide();
                    }
                } break;
            case 'to_date':
                if (attendanceFromDate !== "" && AttendanceEndDate !== "") {
                    if (attendanceFromDate > AttendanceEndDate) {
                        $(`#error_dt_attendance_to_date`).text(`To Date must be after From Date.`);
                        $(`#error_dt_attendance_to_date`).show();
                        $(`#error_dt_attendance_from_date`).hide();

                    } else {
                        $(`#error_dt_attendance_to_date`).hide();
                        $(`#error_dt_attendance_from_date`).hide();
                    }
                } break;
        }
    }


    const handleDateChange = (key, date) => {

        switch (key) {
            case 'dt_attendance_from_date':
                // const to_date = document.getElementById('dt_attendance_to_date').value;
                // setFromDate(date);
                const formattedFromDate = date ? date.toLocaleDateString('en-CA') : '';
                if (formattedFromDate !== '') { document.getElementById('error_dt_attendance_from_date').style.display = 'none'; }
                setFromDate(formattedFromDate);
                // If the start date is set after the end date, clear the end date
                if (dt_attendance_to_date && new Date(formattedFromDate) > new Date(dt_attendance_to_date)) {
                    setToDate('');
                }

                // if (to_date && date) {
                //     const toDate = to_date instanceof Date ? to_date : parseDate(to_date);
                //     const fromDate = date;

                //     if (fromDate > to_date) {
                //         $(`#error_dt_attendance_from_date`).text(`From Date must be before To Date.`);
                //         $(`#error_dt_attendance_from_date`).show();
                //         $(`#error_dt_attendance_to_date`).hide();
                //     } else {
                //         $(`#error_dt_attendance_from_date`).hide();
                //         $(`#error_dt_attendance_to_date`).hide();
                //     }
                // }
                break;

            case 'dt_attendance_to_date':

                // const dt_from_dates = document.getElementById('dt_attendance_from_date').value;
                // setToDate(date);
                const formattedToDate = date ? date.toLocaleDateString('en-CA') : '';
                if (formattedToDate !== '') { document.getElementById('error_dt_attendance_to_date').style.display = 'none'; }
                setToDate(formattedToDate)
                // if (dt_from_dates && date) {
                //     const fromDate = dt_from_dates instanceof Date ? dt_from_dates : parseDate(dt_from_dates);
                //     const toDate = date;


                //     if (fromDate > toDate) {
                //         $(`#error_dt_attendance_to_date`).text(`To Date must be after From Date.`);
                //         $(`#error_dt_attendance_to_date`).show();
                //         $(`#error_dt_attendance_from_date`).hide();

                //     } else {
                //         $(`#error_dt_attendance_to_date`).hide();
                //         $(`#error_dt_attendance_from_date`).hide();
                //     }
                // }
                break;

            default:
                break;
        }
    }
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberPercentInput} />
            <FrmValidations ref={frmValidation} />
            <ExcelExport ref={exlsExp} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span>Loading...</span>
                    </div>
                </div> :
                ''}
            <div>
                <div className="erp_top_Form">
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Manual Attendance </label>
                        </div>
                        <form id='attendanceFormId'>
                            <div className="row erp_transporter_div ">
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Employee Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_employee_type" className="form-select form-select-sm" value={cmb_employee_type} onChange={e => { FnComboOnchange('employee_type'); setEmployeeType(e.target.value); validateErrorMsgs() }} optional="optional" maxLength="255">
                                                <option value="" disabled>Select</option>
                                                <option value="All">All</option>
                                                {employeeTypeOptions?.map(employeeTypes => (
                                                    <option value={employeeTypes.field_name} property_value={employeeTypes.property_value}>{employeeTypes.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> Shift Name <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select size="sm" id="cmb_from_to_shift" className="form-select form-select-sm" value={cmb_from_to_shift} onChange={(e) => { setFromToShiftId(e.target.value); FnComboOnchange('shift_name'); validateErrorMsgs(); }}>
                                                <option value="" disabled="true">Select </option>
                                                {shiftOptions?.map(shift => (
                                                    <option value={shift.field_id} key={shift.field_id}>{shift.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_from_to_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Department </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Select ref={cmb_department_id_ref}
                                                options={departmentopt}
                                                inputId="txt_department_id"
                                                value={departmentopt.length > 0 ? departmentopt.find(option => option.value === txt_department_id) : null}
                                                onChange={(selectedOpt) => {
                                                    cmb_department_id_ref.current = selectedOpt;
                                                    setDepartmentId(selectedOpt.value);
                                                    FnComboOnchange('department');
                                                    // validateFields();

                                                }}
                                                placeholder="Search for a Department"
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_txt_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-sm-4 erp_form_col_div"> */}
                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            {/* <Select
                                                ref={cmb_department_id_ref}
                                                options={departmentOpts}
                                                inputId="cmb_departmentId"
                                                // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                value={departmentOpts.length > 1 ? departmentOpts.find(option => option.value === cmb_departmentId) : null}
                                                onChange={(selectedOpt) => {
                                                    setDepartmentId(selectedOpt.value);
                                                    cmb_department_id_ref.current = selectedOpt;
                                                    FnComboOnchange("department");
                                                }}
                                                placeholder="Search for a Department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            /> */}
                                            <Select
                                                ref={cmb_department_id_ref}
                                                options={departmentOpts}
                                                inputId="cmb_departmentId"
                                                value={cmb_departmentId === 0 || cmb_department_id_ref.current === null ? null : departmentOpts.find(option => option.value === cmb_departmentId)}
                                                onChange={(selectedOpt) => {
                                                    setDepartmentId(selectedOpt.value);
                                                    cmb_department_id_ref.current = selectedOpt;
                                                    FnComboOnchange("department");
                                                }}
                                                placeholder="Search for a Department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px'
                                                    })
                                                }}
                                            />

                                            <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> Sub-Department <span className="required">*</span></Form.Label> </div>
                                        <div className='col'>
                                            <Select ref={subDepartmentComboRef}
                                                options={subDepartmentOpts}
                                                inputId="cmb_sub_department_id"
                                                value={cmb_sub_department_id === 0 || subDepartmentComboRef.current === null ? null : subDepartmentOpts.find(option => option.value === cmb_sub_department_id)}
                                                // value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                                onChange={(selectedOpt) => {
                                                    setSubDepartmentId(selectedOpt.value);
                                                    subDepartmentComboRef.current = selectedOpt;
                                                    FnComboOnchange("subDepartment");
                                                    // FnValidateFields();
                                                }}
                                                placeholder="Search for a sub-department..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    {/* </div> */}
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> Employee Name :<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-2">
                                            <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}

                                                onChange={e => { FnComboOnchange('EmployeeCode'); validateErrorMsgs() }}
                                                onBlur={(e) => { FnComboOnchange('EmplCode'); }}
                                                maxlength="255" />
                                            <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_employee_id" className="form-select form-select-sm" value={cmb_employee_id} onChange={e => { FnComboOnchange('employee_name'); setEmployeeName(e.target.value); validateErrorMsgs() }} maxLength="255">
                                                <option value="">Select</option>
                                                {employeeListData?.map(employee => (
                                                    <option value={employee.field_id} employeeCode={employee.employee_code}>{employee.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label"> Employee Name  <span className="required">*</span></Form.Label>
                                        </div>

                                        <div className="col">
                                            <select id="cmb_employee_id" className="form-select form-select-sm" value={cmb_employee_id} onChange={e => { FnComboOnchange('employee_name'); setEmployeeName(e.target.value); validateErrorMsgs() }} maxLength="20" optional="optional">
                                                <option value="" disabled>Select</option>
                                                <option value="All">All</option>
                                                {employeeListData?.map(employeename => (
                                                    <option value={employeename.field_id}>{employeename.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                </div>
                                {/* second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    {/* <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> From Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id="dt_attendance_from_date" className="erp_input_field" value={dt_attendance_from_date} onChange={e => { setFromDate(e.target.value); validatedatetimeFields('from_date'); }} max={attendanceValidDate} />
                                            <MDTypography variant="button" id="error_dt_attendance_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                    <div className="row mb-1">
                                        <div className="col-sm-2">
                                            <Form.Label className="erp-form-label"> From Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <DatePicker selected={dt_attendance_from_date} id="dt_attendance_from_date" onChange={(date) => {
                                                handleDateChange('dt_attendance_from_date', date);

                                            }} maxDate={attendanceValidDate}
                                                dateFormat="dd-MM-yyyy" placeholderText="dd-mm-yyyy" className="erp_input_field"
                                                selectsStart
                                                startDate={dt_attendance_from_date ? new Date(dt_attendance_from_date) : null}
                                                endDate={dt_attendance_to_date ? new Date(dt_attendance_to_date) : null}
                                            />
                                            <MDTypography variant="button" id="error_dt_attendance_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> To Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id="dt_attendance_to_date" className="erp_input_field" value={dt_attendance_to_date} onChange={e => { setToDate(e.target.value); validatedatetimeFields('to_date'); }} max={attendanceValidDate} />
                                            <MDTypography variant="button" id="error_dt_attendance_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}  >
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                        {/* <div className="row mb-1"> */}
                                        <div className="col-sm-2">
                                            <Form.Label className="erp-form-label"> To Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <DatePicker selected={dt_attendance_to_date} id="dt_attendance_to_date" onChange={(date) => {
                                                handleDateChange('dt_attendance_to_date', date);
                                            }} maxDate={attendanceValidDate}
                                                dateFormat="dd-MM-yyyy" placeholderText="dd-mm-yyyy" className="erp_input_field"
                                                selectsEnd
                                                startDate={dt_attendance_from_date ? new Date(dt_attendance_from_date) : null}
                                                endDate={dt_attendance_to_date ? new Date(dt_attendance_to_date) : null}
                                                minDate={dt_attendance_from_date ? new Date(dt_attendance_from_date) : null}
                                            />
                                            <MDTypography variant="button" id="error_dt_attendance_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-2">
                                            <Form.Label className="erp-form-label">From Time <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="time" id="dt_start_time" className="erp_input_field" value={dt_start_time} onChange={e => { setStartTime(e.target.value); validate_time(); }} />
                                            <MDTypography variant="button" id="error_dt_start_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {/* </div> */}

                                        {/* <div className="row"> */}
                                        <div className="col-sm-2">
                                            <Form.Label className="erp-form-label">To Time <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="time" id="dt_end_time" className="erp_input_field" value={dt_end_time} onChange={e => { setEndTime(e.target.value);; validate_time(); }} />
                                            <MDTypography variant="button" id="error_dt_end_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div>
                                            <MDButton onClick={() => FnShowAttendanceDetails()} className={`erp-gb-button ms-2 mt-1`} variant="button"
                                                fontWeight="regular">show</MDButton>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <div className="row col-12">
                            <div style={{ height: '250px', overflowY: 'auto' }}>
                                {renderLeavesDetailsTable}
                            </div>
                            <MDTypography variant="button" id="error_select_earningHead" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>

                            <div className="erp_frm_Btns  py-2">
                                <MDButton type="submit" id="saveBtn" onClick={() => FnAddManualAttendanceData()} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                                    fontWeight="regular">Save</MDButton>
                                <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                <MDButton type="button" className="erp-gb-button ms-2" onClick={() => { navigate(`/DashBoard`) }} variant="button"
                                    fontWeight="regular">Exit</MDButton>
                                <MDButton className="erp-gb-button ms-2" variant="button" fontWeight="regular" onClick={ClearPage}>Clear</MDButton>

                                <MDButton type="button" id="export-btn" className={`erp-gb-button ms-2 ${attendanceDetails.length === 0 ? 'd-none' : ''}`}
                                    variant="button" onClick={ExportToExcel} fontWeight="regular">Export</MDButton>
                                <MDButton type="button" id="import-btn" className="erp-gb-button ms-2" variant="button" onClick={onImportClick} fontWeight="regular"> Import
                                </MDButton>

                            </div>
                            {/* Success Msg Popup */}
                            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                            {/* Error Msg Popup */}
                            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default FrmManualAttendanceEntry;

