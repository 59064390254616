import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import react icons
import { useLocation, useNavigate } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from 'FrmGeneric/FrmValidations';
import ConfigConstants from 'assets/Constants/config-constant';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';

export default function FrmMProductRejectionParametersEntry() {
  //changes by ujjwala on 5/1/2024 case no . 1
  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;
  const { state } = useLocation();

  const { productRejectionParametersId = 0, keyForViewUpdate, compType } = state || {}
  //end
  var activeValue = '';

  const validate = useRef();
  const comboDataAPiCall = useRef();
  const ValidateNumberInput = useRef();

  const [product_rejection_parameters_id, setproductRejectionParametersId] = useState(productRejectionParametersId);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  // Add Product Type Form Fields

  const [cmb_product_type_id, setProductTypeId] = useState('');
  const [cmb_product_rejection_type, setproduct_rejection_type] = useState('ALL');
  const [txt_product_rejection_parameters_name, setProductRejectionParametersNameId] = useState('');
  const [txt_product_rejection_parameters_short_name, setProductRejectionParametersShortNameId] = useState('');
  const [txt_from_range, setFromRangeId] = useState('');
  const [txt_to_range, setToRangeId] = useState('');
  const [txt_from_deviation_percent, setFromDeviationPercentId] = useState('');
  const [txt_to_deviation_percent, setToDeviationPercentId] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')
  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    await fillComobos()
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductRejectionParametersListing`);
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');
  // For navigate
  const navigate = useNavigate();
  //changes by ujjwala on 5/1/2024 case no . 1
  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      await fillComobos();
      if (productRejectionParametersId !== 0) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])

  //end
  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        }
        setProductTypeId(productTpVal)
        break;

      case 'productRejectionType':
        var productRejectionTypeVal = document.getElementById('cmb_product_rejection_type').value;
        setproduct_rejection_type(productRejectionTypeVal)
        break;


    }
  }

  const fillComobos = async () => {
    debugger
    // const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    // setProductTypeOptions(productTypeApiCall)

    resetGlobalQuery();
    globalQuery.columns.push("field_id");
    globalQuery.columns.push("field_name");
    globalQuery.columns.push("product_type_group");
    globalQuery.table = "smv_product_type";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
    const productTypeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    setProductTypeOptions(productTypeApiCall);
  }

  const handleSubmit = async () => {
    debugger
    try {
      const checkIsValidate = await validate.current.validateForm("productrejectionparameterFormId");
      if (checkIsValidate === true) {
        var active;
        // var qatype;
        activeValue = document.querySelector('input[name=isactive]:checked').value
        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
        }

        // qatypeValue = document.querySelector('input[name=isqatypepurchase]:checked').value
        // switch (qatypeValue) {
        //   case 'Purchase': qatype = 'Purchase'; break;
        //   case 'Sales': qatype = 'Sales'; break;
        //   case 'Production': qatype = 'Production'; break;
        //   case 'All': qatype = 'All'; break;
        // }

        const data = {
          company_id: COMPANY_ID,
          product_rejection_parameters_id: product_rejection_parameters_id,
          created_by: UserName,
          modified_by: product_rejection_parameters_id === 0 ? null : UserName,
          product_type_id: cmb_product_type_id,
          product_type_group: $('#cmb_product_type_id').find(":selected").text(),
          product_rejection_type: cmb_product_rejection_type,
          product_rejection_parameters_name: txt_product_rejection_parameters_name,
          product_rejection_parameters_short_name: txt_product_rejection_parameters_short_name,
          from_range: txt_from_range,
          to_range: txt_to_range,
          from_deviation_percent: txt_from_deviation_percent,
          to_deviation_percent: txt_to_deviation_percent,
          remark: txt_remark,
          is_active: active,
        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Productrejectionparameter/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };

  //changes by ujjwala on 5/1/2024 case no . 1
  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $('#txt_product_rejection_parameters_name').attr('disabled', true);
        $('#cmb_product_type_id').attr('disabled', true)
        $('[name="isqatypepurchase"]').attr('disabled', true)
        $('#cmb_product_rejection_type').attr('disabled', true)
        $('#txt_product_rejection_parameters_short_name').attr('disabled', true)

        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productrejectionparameterFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };
  //end
  const FnCheckUpdateResponce = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Productrejectionparameter/FnShowParticularRecordForUpdate/${product_rejection_parameters_id}`)
      const updateRes = await apiCall.json();
      let resp = JSON.parse(updateRes.data)
      await fillComobos();
      setproductRejectionParametersId(resp.product_rejection_parameters_id);
      setProductTypeId(resp.product_type_id);
      setProductRejectionParametersNameId(resp.product_rejection_parameters_name);
      setProductRejectionParametersShortNameId(resp.product_rejection_parameters_short_name);
      setFromRangeId(resp.from_range);
      setToRangeId(resp.to_range);
      setFromDeviationPercentId(resp.from_deviation_percent);
      setToDeviationPercentId(resp.to_deviation_percent);
      setRemark(resp.remark);
      setproduct_rejection_type(resp.product_rejection_type)

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }


    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  //changes by ujjwala on 5/1/2024 case no . 1
  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{4}$/
    var dot = '.';
    switch (key) {
      case 'pFromRange':
        var pFromRangeVal = $('#txt_from_range').val();
        if (!regexNo.test(pFromRangeVal) && pFromRangeVal.length <= 10 || pFromRangeVal.indexOf(dot) === 10) {
          if (numCheck.test(pFromRangeVal)) {
            setFromRangeId(pFromRangeVal)
          }

        }
        break;

      case 'pToRange':
        var pToRangeVal = $('#txt_to_range').val();
        if (!regexNo.test(pToRangeVal) && pToRangeVal.length <= 10 || pToRangeVal.indexOf(dot) === 10) {
          if (numCheck.test(pToRangeVal)) {
            setToRangeId(pToRangeVal)
          }

        }
        break;
    }
  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('productrejectionparameterFormId')
  }
  //end


  return (
    <>
      <ValidateNumberDateInput ref={ValidateNumberInput} />
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validate} />
      <div className="erp_top_Form">
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Rejection Parameters {actionType} </label>
          </div>
          <form id="productrejectionparameterFormId">
            <div className="row erp_transporter_div">
              {/* first row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record +</option>
                      {productTypeOptions?.map(productType => (
                        // <option value={productType.field_id}>{productType.field_name}</option>
                        <option value={productType.field_id} producttypegroup={productType.product_type_group} >{productType.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Rejection Type</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Purchase"
                          type="radio"
                          value="Purchase"
                          name="isqatypepurchase"

                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Sales"
                          value="Sales"
                          type="radio"
                          name="isqatypepurchase"
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Production"
                          value="Production"
                          type="radio"
                          name="isqatypepurchase"
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="All"
                          value="All"
                          type="radio"
                          name="isqatypepurchase"
                          defaultChecked
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Rejection Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_rejection_type" className="form-select form-select-sm" value={cmb_product_rejection_type} onChange={() => { comboOnChange('productRejectionType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="ALL">ALL</option>
                      <option value="Purchase">Purchase</option>
                      <option value="Production">Production</option>
                      <option value="Audits">Audits</option>
                      <option value="Services">Services</option>
                      <option value="Sales">Sales</option>
                    </select>
                    <MDTypography variant="button" id="error_cmb_consumption_mode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Rejection Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_rejection_parameters_name" className="erp_input_field" value={txt_product_rejection_parameters_name} onChange={e => { setProductRejectionParametersNameId(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_rejection_parameters_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_rejection_parameters_short_name" className="erp_input_field" value={txt_product_rejection_parameters_short_name} onChange={e => { setProductRejectionParametersShortNameId(e.target.value.toUpperCase()); validateFields(); }} maxLength="20" />
                    <MDTypography variant="button" id="error_txt_product_rejection_parameters_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">From Range</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_from_range" className="erp_input_field text-end" value={txt_from_range} onChange={e => { validateNo('pFromRange'); }} maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_from_range" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* second row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">To Range</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_to_range" className="erp_input_field text-end" value={txt_to_range} onChange={e => { validateNo('pToRange'); }} maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_to_range" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">From Deviation (%)</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="number" id="txt_from_deviation_percent" className="erp_input_field text-end" value={txt_from_deviation_percent}
                      onChange={(e) => {
                        if (ValidateNumberInput.current.percentValidate(e.target.value)) {
                          setFromDeviationPercentId(e.target.value)
                        }; validateFields();
                      }}
                      maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="txt_from_deviation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> To Deviation (%)</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="number" id="txt_to_deviation_percent" className="erp_input_field text-end" value={txt_to_deviation_percent}
                      onChange={(e) => {
                        if (ValidateNumberInput.current.percentValidate(e.target.value)) {
                          setToDeviationPercentId(e.target.value)
                        }; validateFields();
                      }} maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="txt_to_deviation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </form>
          {/* //changes by ujjwala on 5/1/2024 case no . 1 */}
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductRejectionParametersListing/reg' : '/Masters/ProductRejectionParametersListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular">Back</MDButton>
            <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>
          </div >
        </div>

        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            <FrmMProductTypeEntry btn_disabled={true} />
          </Modal.Body>
        </Modal >

        {/* end by ujjwala */}
        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
      </div>
    </>
  )
}
