import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import { FaCheck } from 'react-icons/fa';

const noOfCols = 6;

{/* Horizontal Sequence Logic */ }
const createMatrixHorizontalSeq = (start, end, columns) => {
  start = start + 1
  end = end + 1
  const numRows = Math.ceil((end - start + 1) / columns);
  const matrix = [];

  for (let i = 0; i < numRows; i++) {
    const row = [];
    for (let j = 0; j < columns; j++) {
      const cellIndex = i * columns + j;
      if (start + cellIndex - 1 < end) {
        row.push(start + cellIndex - 1);
      }
    }
    matrix.push(row);
  }
  return matrix;
};

{/* Horizontal Sequence Logic */ }
const Row = ({ data, rowIndex }) => {
  return (
    <tr rowIndx={rowIndex}>
      {data.map((cell, cellIndex) => (
        <>
          <td key={cellIndex} className="erp_invoice_table_td">{cell}</td>
          <td key={`blank-${cellIndex}`} className="erp_invoice_table_td" cellNo={`blank-${cellIndex}`} />
        </>
      ))}
    </tr >
  );
}

{/* Vertical Sequence Logic */ }
function createMatrixVerticalSeq(start, end, cols, rows) {
  debugger;
  var matrix = [];
  var num = start;
  for (var i = 0; i < rows; i++) { // looping over rows
    var row = [];
    for (var j = 0; j < cols; j++) { // looping over columns
      if (num <= end) {
        row.push(num);
        num += rows; // incrementing by the number of rows
      }
    }
    matrix.push(row);
    num = start + i + 1;
  }
  console.table(matrix);
  return matrix;
}


function createMatrices(start, end, cols, maxRows) {
  let resultMatrices = [];
  let remainingStart = start;
  while (remainingStart <= end) {
    let matrix = [];
    let numRows = Math.min(maxRows, Math.ceil((end - remainingStart + 1) / cols));
    for (let i = 0; i < numRows; i++) {
      let row = [];
      let currentNum = remainingStart + i; // Adjust the starting number for each row
      for (let j = 0; j < cols; j++) {
        if (currentNum <= end) {
          row.push(currentNum);
          currentNum += numRows; // Increment by the number of rows
        }
      }
      matrix.push(row);
    }
    resultMatrices.push(matrix);
    remainingStart += cols * numRows; // Increment start by the number of elements filled in the matrix
  }
  return resultMatrices;
}


const YarnLoadingSlip = React.forwardRef(({ invoiceContent, loadingSlipIndex }, ref) => {
  const fixedNoOfRows = 33;
  const data = invoiceContent.items
  let pkgStartNo = invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].pkgStartNo
  let pkgEndNo = invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].pkgEndNo

  {/* Horizontal Sequence Logic */ }
  // const matrix = createMatrixHorizontalSeq(pkgStartNo, pkgEndNo, noOfCols);

  // Logic For Vertical Sequence Printing Logic.
  // let start = 25; let end = 94;
  // let cols = 6;
  // let noOfRows = Math.ceil((end - start) / cols)
  // let resultMatrix = createMatrixVerticalSeq(start, end, noOfCols, noOfRows);
  // For Dynamic Creation
  // let noOfRows = Math.ceil(totalCartonNos / noOfCols)
  // let resultMatrix = createMatrixVerticalSeq(pkgStartNo, pkgEndNo, noOfCols, noOfRows);

  // Example usage: For Multiple Matrices
  // let start = 25; let end = 94;
  // let cols = 6;
  // let maxRows = 3;
  // let resultMatrix = createMatrices(start, end, cols, maxRows);
  // For Dynamic Creation
  let resultMatrices = createMatrices(pkgStartNo, pkgEndNo, noOfCols, fixedNoOfRows);

  const invoiceHeader = (
    <>
      <div className=''> {/*className='bordered border-bottom-0 px-0'*/} {/* removed this classes because header wants in all pages.*/}
        <div className='row mb-0 p-2'>
          <div className="col-sm-1 text-center text-sm-start">
            <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
          </div>
          <div className="col-sm-11 transformed">
            <div className='erp-invoice-print-label text-center'>
              <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
              <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
            </div>
            <div className='erp-invoice-print-label-lg text-center'> Vehicle Loading Slip </div>
          </div>
        </div>
        <div className='row border-top border-dark p-0 m-0'>
          <div className='col-sm-12'>
            <dt className='erp-invoice-print-label-md-lg text-end'>Date :  {invoiceContent.loadingDetails.date} </dt>
          </div>
        </div>
        {/* Loading Details */}
        <div className='row  border-top border-dark p-0 m-0'>
          <dt className='erp-invoice-print-label-md-lg'>Loading Details:</dt>
          <dl className="row pb-0 mb-0">
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>G.P. / D.O No:</dt>
                <dd className='col-sm-7 erp-invoice-print-label'> {invoiceContent.loadingDetails.gatePassNo}</dd>

                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>Vehicle No:</dt>
                <dd className='col-sm-7 erp-invoice-print-label'> {invoiceContent.loadingDetails.vehicleNo}</dd>

                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>Driver Name:</dt>
                <dd className='col-sm-7 erp-invoice-print-label'>{invoiceContent.loadingDetails.driverName}</dd>

                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>Vehicle Reporting Time:</dt>
                <dd className='col-sm-7 erp-invoice-print-label'>{invoiceContent.loadingDetails.vehicleReportingTime}</dd>

                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>Vehicle Loading Started Time:</dt>
                <dd className='col-sm-7 erp-invoice-print-label'>{invoiceContent.loadingDetails.vehicleLoadingStartTime}</dd>

                <dt className='col-sm-5 erp-invoice-print-label-md-lg pe-0'>Vehicle Loading Finished Time:</dt>
                <dd className='col-sm-7 erp-invoice-print-label'>{invoiceContent.loadingDetails.vehicleLoadingFinishTime}</dd>
              </dl>
            </div>

            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Count No:</dt>
                <dd className='col-sm-9 erp-invoice-print-label ps-1'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].countNo}</dd>

                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Lot No:</dt>
                <dd className='col-sm-9 erp-invoice-print-label ps-1'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].lotNo}</dd>

                <dl className="row pb-0 mb-0">
                  <div className="col-sm-6">
                    <dl className="row pb-0 mb-0">
                      <dt className='col-sm-6 erp-invoice-print-label-md-lg'>From Carton:</dt>
                      <dd className='col-sm-6 erp-invoice-print-label'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].pkgStartNo}</dd>
                    </dl>
                  </div>
                  <div className="col-sm-6">
                    <dl className="row pb-0 mb-0">
                      <dt className='col-sm-6 erp-invoice-print-label-md-lg'>To Carton:</dt>
                      <dd className='col-sm-6 erp-invoice-print-label'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].pkgEndNo}</dd>
                    </dl>
                  </div>
                </dl>

                <dl className="row pb-0 mb-0">
                  <div className="col-sm-6">
                    <dl className="row pb-0 mb-0">
                      <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Gross Weight:</dt>
                      <dd className='col-sm-6 erp-invoice-print-label'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].grossWeight}</dd>
                    </dl>
                  </div>
                  <div className="col-sm-6">
                    <dl className="row pb-0 mb-0">
                      <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Net Weight:</dt>
                      <dd className='col-sm-6 erp-invoice-print-label'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].netWeight}</dd>
                    </dl>
                  </div>
                </dl>

                <dl className="row pb-0 mb-0">
                  <div className="col-sm-6">
                    <dl className="row pb-0 mb-0">
                      <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Tare Weight:</dt>
                      <dd className='col-sm-6 erp-invoice-print-label'>{invoiceContent.packingDetailsForLoadingSlip[loadingSlipIndex].tareWeight}</dd>
                    </dl>
                  </div>
                </dl>

              </dl>
            </div>
          </dl>
        </div>
      </div>
    </>
  )

  const invoiceFooter = (
    <div className="invoice-footer">
      <div className='row'>
        <div className='col-sm-6'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For </dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">Vehicle Loaded By Signatory</dt>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg">For </dt>
          </div>
          <div className='align-bottom-center mt-3'>
            <dt className="erp-invoice-print-label-md-lg">Checked By Signatory</dt>
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <>
      {/* <div className="container"> */}
      <div className="row">
        <div className="col-12">
          <div className="container-invoice">
            <div id="content">
              <div className="invoice p-0">

                {/* <!--BILL HEADER--> */}
                {/* <div className="row headerContentHt" style={{ padding: '0px 15px 0px' }}>
                  {invoiceHeader}
                </div> */}
                {/* <!--PARCEL DETAILS--> */}
                <div className="row" style={{ padding: '0px 15px 15px' }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 px-0" >
                        <div className="row p-0">
                          <div className="table-responsive">
                            <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                              <thead id="loadingTblHeader">
                                <tr>
                                  <td colSpan={24} className='px-0'>
                                    <div className="row headerContentHt">
                                      {invoiceHeader}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th className="erp_invoice_table_th text-center col-1">CTN. SN.</th>
                                  <th className="erp_invoice_table_th text-center"><FaCheck /></th>
                                  <th className="erp_invoice_table_th text-center col-1">CTN. SN.</th>
                                  <th className="erp_invoice_table_th text-center"><FaCheck /></th>
                                  <th className="erp_invoice_table_th text-center col-1">CTN. SN.</th>
                                  <th className="erp_invoice_table_th text-center"><FaCheck /></th>
                                  <th className="erp_invoice_table_th text-center col-1">CTN. SN.</th>
                                  <th className="erp_invoice_table_th text-center"><FaCheck /></th>
                                  <th className="erp_invoice_table_th text-center col-1">CTN. SN.</th>
                                  <th className="erp_invoice_table_th text-center"><FaCheck /></th>
                                  <th className="erp_invoice_table_th text-center col-1">CTN. SN.</th>
                                  <th className="erp_invoice_table_th text-center"><FaCheck /></th>
                                </tr>
                              </thead>
                              <tbody class='detailstableId' id="loadingTblBody">
                                {/* Horizontal Sequence Logic */}
                                {/* {matrix.map((row, rowIndex) => (
                                  <Row key={rowIndex} data={row} rowIndex={rowIndex} />
                                ))} */}

                                {/* Giving Proper Values For Single Matrix*/}
                                {/* {resultMatrix?.map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                      <>
                                        <td key={colIndex} className="erp_invoice_table_td">{cell}</td>
                                        <td key={`blank-${colIndex}`} className="erp_invoice_table_td" cellNo={`blank-${colIndex}`} />
                                      </>
                                    ))}
                                  </tr>
                                ))} */}

                                {/* For Multiple Matrices */}
                                {resultMatrices.map((matrix, matrixIndex) => (
                                  <React.Fragment key={`matrix-${matrixIndex}`}>
                                    {matrix.map((row, rowIndex) => (
                                      <tr key={`${matrixIndex}-${rowIndex}`}>
                                        {row.map((cell, colIndex) => (
                                          <React.Fragment key={`${matrixIndex}-${rowIndex}-${colIndex}`}>
                                            <td className="erp_invoice_table_td">{cell}</td>
                                            <td className="erp_invoice_table_td" key={`blank-${colIndex}`} cellNo={`blank-${colIndex}`} />
                                          </React.Fragment>
                                        ))}
                                      </tr>
                                    ))}
                                    {/* Add the blank row after each matrix, except for the last one */}
                                    {matrixIndex !== resultMatrices.length - 1 && (
                                      <tr key={`blank-row-${matrixIndex}`} className='hideOnPrint' style={{height: '20px'}}>
                                        <td colSpan={24}></td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}

                                {/* {resultMatrices.length > 0 && resultMatrices[resultMatrices.length - 1].length > 29 && resultMatrices[resultMatrices.length - 1].length <= fixedNoOfRows && (
                                  <tr style={{ backgroundColor: 'red', height: '30px' }}>
                                    <td colSpan={24} style={{ backgroundColor: 'black' }}></td>
                                  </tr>
                                )} */}

                                <tr className='invoiceFooterTr' id="footerTr">
                                  <td colSpan={24}> {invoiceFooter} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
})
export default YarnLoadingSlip;

