import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const CustMaterialReceipt = React.forwardRef(({ invoiceContent }, ref) => {
    const data = invoiceContent.items
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;
    let AFTER_DECIMAL_PLACES = 2;


    function numberToWords(number) {

        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }

        const ones = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(number) {
            if (number === 0) {
                return "";
            }

            if (number < 20) {
                return ones[number];
            }

            if (number < 100) {
                const ten = Math.floor(number / 10);
                const remainder = number % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (number < 1000) {
                const hundred = Math.floor(number / 100);
                const remainder = number % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (number < 1000000) {
                const thousand = Math.floor(number / 1000);
                const remainder = number % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (number < 1000000000) {
                const million = Math.floor(number / 1000000);
                const remainder = number % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` Point ${convertIntegerToWords(decimalPart)}`;
        }
        return words;
    }

    function numberToWordsWithCurrency(number) {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }

    const cgstTotal = invoiceContent.footer.cgst_total;
    const cgstTotalInWordsWithCurrency = numberToWordsWithCurrency(cgstTotal);

    console.log(`CGST Total: ${cgstTotalInWordsWithCurrency}`);

    const amountInWords = numberToWordsWithCurrency(14.3999);
    console.log(amountInWords);
    // // Example usage:
    // const amountInWords = numberToWordsWithCurrency(324.14);
    // console.log(amountInWords);  // Outputs: Three Hundred and Twenty Four Rupees and Fourteen Paise


    // const cgstTotal = invoiceContent.footer.cgst_total;
    const sgstTotal = invoiceContent.footer.sgst_total;
    const igstTotal = invoiceContent.footer.igst_total;
    const basicTotal = invoiceContent.footer.basic_total;
    const taxable_total = invoiceContent.footer.taxable_total
    const grand_total = invoiceContent.footer.grand_total

    const cgstTotalInWords = numberToWords(cgstTotal);
    const sgstTotalInWords = numberToWords(sgstTotal);
    const igstTotalInWords = numberToWords(igstTotal);

    console.log(`CGST Total: ${cgstTotalInWords}`);
    console.log(`SGST Total: ${sgstTotalInWords}`);
    console.log(`IGST Total: ${igstTotalInWords}`);

    // const cgstTotalInWordsWithCurrency = numberToWordsWithCurrency(cgstTotalInWords);
    const sgstTotalInWordsWithCurrency = numberToWordsWithCurrency(sgstTotal);
    const igstTotalInWordsWithCurrency = numberToWordsWithCurrency(igstTotal);
    const basicTotalInWordsWithCurrency = numberToWordsWithCurrency(basicTotal);
    const taxabletotalInWordsWithCurrency = numberToWordsWithCurrency(taxable_total);
    const grandtotalInWordsWithCurrency = numberToWordsWithCurrency(grand_total);

    // console.log(invoiceContent.customerDetails.is_sez);



    const itemData = extractItemData(invoiceContent.items);
    function extractItemData(items) {
        // Extract relevant data from the first item in the array
        if (items.length > 0) {
            const firstItem = items[0];
            return {
                purchase_order_no: firstItem.purchase_order_no,
                purchase_order_date: firstItem.purchase_order_date,
                purchase_order_version: firstItem.purchase_order_version,
            };
        }
        // Return default values or handle the case where the array is empty
        return {
            purchase_order_date: 0,
            purchase_order_no: 0,
            purchase_order_version: 0,

        };
    }

    let paymentTermsList = null
    if (invoiceContent.paymentTerms && invoiceContent.paymentTerms.length > 0) {
        paymentTermsList = invoiceContent.paymentTerms.map(paymentTerm => paymentTerm.payment_terms_name).join(', ');
    }

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-3">
                        <img src={DakshabhiLogo} alt="master card" width="150" height="auto" mt={1} />
                    </div>
                    <div className="col-sm-5">
                        <div className='erp-invoice-print-label text-center ps-4'>
                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                            <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className='erp-invoice-print-label-lg mt-2 text-center'>
                            <span className='erp-invoice-print-label-lg ps-2'>Customer Material Receipt</span><br />
                        </div>
                    </div>
                </div>

                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '11px' }}> CMR No. & version : {invoiceContent.cgrnDetails.customer_goods_receipt_no} &  {invoiceContent.cgrnDetails.customer_goods_receipt_version}</dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '11px' }}>CMR Date : {invoiceContent.cgrnDetails.customer_goods_receipt_date}</dt>
                    </div>
                </div>
                <div className='row border-bottom border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0 d-flex align-items-cente">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Customer Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }} > {invoiceContent.customerDetails.name}</dd>

                                <dl className="row pb-0 mb-0">
                                    <dt class="col-1 erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}>State:</dt>
                                    <dd class="col-sm-10 p-0 m-0">
                                        <dl class="row m-0 p-0">
                                            <dt class="col-sm-4 erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}> {invoiceContent.customerDetails.state}</dt>
                                            <dd class="col-1 erp-invoice-print-label-md-lg ps-2 pr-0 mr-0" style={{ fontSize: '9px' }}>City:</dd>
                                            <dt class="col-sm-3 erp-invoice-print-label-md-lg pr-0 mr-0 " style={{ fontSize: '9px' }}>{invoiceContent.customerDetails.City}</dt>
                                        </dl>
                                    </dd>
                                </dl>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }} >Customer Order No :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label-md-lg ps-2' style={{ fontSize: '9px' }}>{invoiceContent.customerDetails.customer_order_no}({invoiceContent.customerDetails.customer_order_date})</dd>

                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Address:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> </dd> */}
                                {/* <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.customerDetails.address}</dd> */}

                                {/* <dl className="row mb-0 mt-0">
                                    <div className="col-sm-12 d-flex justify-content-between">
                                        <div className="col-sm-6 d-flex justify-content-between">
                                            <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>
                                                Email:<span className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}> </span>
                                             </dt>
                                        </div>
                                        <div className="col-sm-6 d-flex justify-content-between">
                                            <dt className='erp-invoice-print-label-md-lg ps-4' style={{ fontSize: '9px' }}>
                                                Contact No: <span className="erp-invoice-print-label-md-lg" style={{ fontSize: '9px' }}> </span>
                                             </dt>
                                        </div>
                                    </div>
                                </dl> */}
                            </dl>
                        </div>

                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Customer Challan No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label-md-lg ps-2' style={{ fontSize: '9px' }}>{invoiceContent.customerDetails.customer_challan_no} ({invoiceContent.customerDetails.customer_challan_Date})</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>QA By Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label-md-lg ps-2' style={{ fontSize: '9px' }}> {invoiceContent.customerDetails.qa_by_name} ({invoiceContent.customerDetails.qa_date})</dd>

                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>Is Sez:</dt>
                                <dd className='col-sm-7 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>  </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className='row p-0 m-0'>
                    <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> Dear Sir</span>
                    <span className='erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>We received material from you as follows.</span>
                </div>

            </div>

            <hr className='m-0 p-0 hr-line' />
        </>
    )

    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row mt-2'>
                <div className='col-sm-3'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.customerDetails.name}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Receiver Signature</dt>
                    </div>
                </div>
                <div className='col-sm-3 offset-sm-6'> {/* This offsets the third column */}
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.company.company}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {/* <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" style={{ tableLayout: 'fixed', width: '100%' }}> */}
                                                            <thead>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} style={{ width: '40px' }}  >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" style={{ width: '240px' }} rowspan={2} >Material Name (Technical Spect.)</th>
                                                                    <th className="erp_invoice_table_th text-center col-1 mb-2" rowspan={2}>Order Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center col-1 mb-2" style={{ width: '70px' }} rowspan={2} >Accepted Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center col-1 mb-2" style={{ width: '70px' }} rowspan={2} >Rejected Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center mb-2" style={{ width: '50px' }} rowspan={2}>Unit</th>
                                                                    <th className="erp_invoice_table_th text-center mb-2 col-1" style={{ width: '50px' }} rowspan={2} >Rate</th>
                                                                    {/* <th className="erp_invoice_table_th text-center " style={{ width: '100px' }} colSpan={3}>Taxes Extra</th> */}
                                                                    {/* <th className="erp_invoice_table_th text-center col-1" rowspan={2} >Item Total</th> */}
                                                                </tr>
                                                                {/* <tr>
                                                                    <th className="erp_invoice_table_th text-center-md-lg" style={{ fontSize: '8px', width: '30px' }}>CGST %</th>
                                                                    <th className="erp_invoice_table_th text-center-md-lg" style={{ fontSize: '8px', width: '30px' }}>SGST %</th>
                                                                    <th className="erp_invoice_table_th text-center-md-lg" style={{ fontSize: '8px', width: '30px' }}>IGST %</th>
                                                                </tr> */}
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td" >{item.material_name}
                                                                                    <span>
                                                                                        {
                                                                                            (item.material_tech_spect === '' || item.material_tech_spect === "NA")
                                                                                                ? ''
                                                                                                : <>
                                                                                                    ({
                                                                                                        [item.material_tech_spect].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                                                                </>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="erp_invoice_table_td text-end">{item.order_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.grn_accepted_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.grn_rejected_quantity}</td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                                                                {/* <td className="erp_invoice_table_td text-end">{item.cgst_percent}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.sgst_percent}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.igst_percent}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.basic_amount}</td> */}
                                                                            </tr>
                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    <tr id='footerTr'>
                                                                                        {/* <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Basic Total (in words):</dt>
                                                                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{basicTotalInWordsWithCurrency}</dd>
                                                                                            </div>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{cgstTotalInWordsWithCurrency}</dd>
                                                                                            </div>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{sgstTotalInWordsWithCurrency}</dd>
                                                                                            </div>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{igstTotalInWordsWithCurrency}</dd>
                                                                                            </div>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Taxable Total(in words):</dt>
                                                                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{taxabletotalInWordsWithCurrency}</dd>
                                                                                            </div>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Grand Total(in words):</dt>
                                                                                                <dd className="col-sm-9 erp-invoice-print-label-md-lg">{grandtotalInWordsWithCurrency}</dd>
                                                                                            </div> */}
                                                                                        <td className="erp_invoice_table_td p-0 m-0" colSpan={4}>
                                                                                            <table className="table p-0 m-0 table-borderless">
                                                                                                <tbody>
                                                                                                    {/* <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Basic Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">CGST Total :</span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.cgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">SGST Total :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.sgst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">IGST Total:</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.igst_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Freight Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.freight_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.discount_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount (%) :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.discount_percent).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Taxable Total :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.taxable_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr>

                                                                                                    <tr className='border-0'>
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Grand Total: </span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                    </tr> */}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>
                                                                                </> : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})


export default CustMaterialReceipt;

