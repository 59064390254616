import React, { useState, useRef, useMemo, useEffect } from "react";
import $ from 'jquery';

// React Icons
import { MdDelete } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { Modal, Table } from "react-bootstrap";
import MDButton from "components/MDButton";
import { useLocation } from "react-router-dom";
import MDTypography from "components/MDTypography";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";


export default function FrmSizingOrderEntry({ onGetFormSizingData, sizingData, generatedSZNo, sizingJObRate, getsizingJObRate }) {
    const { state } = useLocation();
    const { keyForViewUpdate } = state || {}
    // For Refs
    const validateNumberDateInput = useRef();

    //FrmsizingEntry
    const [cmb_indent_type, setIndentType] = useState('');
    const [txt_sizing_order_no, setSizingOrderNO] = useState(generatedSZNo !== null && generatedSZNo.length !== 0 ? generatedSZNo : [])
    const [sizingfilteredData, setSizingfilteredData] = useState(sizingData !== null && sizingData.length !== 0 ? sizingData : [])
    const [showSZFilterForm, setShowSZFilterForm] = useState(false);
    const [txt_sizing_job_rate, setJobWorkRate] = useState(sizingJObRate !== null && sizingJObRate.length !== 0 ? sizingJObRate : [])
    useEffect(() => {
        removeSessions('onload');
        if (keyForViewUpdate !== "Add") {
            if (sizingData && sizingData[0]?.sizing_order_no) {
                setSizingOrderNO(sizingData[0].sizing_order_no);
            }
        }
    }, [sizingData])

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
            localStorage.removeItem('indentScheduleData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');
        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');

    }
    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }
    let requiredCols = ['product_id', 'product_name', 'product_tech_spect', 'product_material_code', 'product_std_weight', 'product_unit_name', 'indent_quantity', 'indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
        "approval_remark", "indent_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
        "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time',
    ];

    const viewSizingFilterForm = async () => {
        $('#error_cmb_indent_type').hide();
        sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
        setShowSZFilterForm(true);

    }

    const handleCloseSizingFilterForm = () => {
        setShowSZFilterForm(false)
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        if (getData.length !== 0) {
            setSizingfilteredData((prevArray) => {
                const updatedArray = [
                    ...prevArray,
                    ...getData.filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id)).map((item) => {
                        return {
                            ...item,

                            sizing_order_no: txt_sizing_order_no
                        };
                    })
                ];
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
                onGetFormSizingData(updatedArray);
                return updatedArray;
            });

            sessionStorage.setItem('isComboFilterExist', false)

            var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))
            console.log('LocalArr: ', LocalArr)
            if (LocalArr != null) {
                for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                    localStorage.removeItem(LocalArr[localArrIndex])
                }
            }
        } else {
           // setSizingfilteredData([])
            //onGetFormSizingData([]);
          //  sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
        }
        removeSessions();
        setTimeout(() => { categoryDisplay(); }, 300);
    };

    const handleInputChange = (indentItem, e) => {
        const updatedValue = validateNumberDateInput.current.decimalNumber(e.target.value, 4);
        const updatedBomfilteredData = sizingfilteredData.map(item => {
            if (item.product_id === indentItem.product_id) {
                return { ...item, product_material_approved_quantity: updatedValue };
            }
            return item;
        });
        setSizingfilteredData(updatedBomfilteredData);
        onGetFormSizingData(updatedBomfilteredData);
    };

    // fn to remove material
    const removeMaterial = (row, e) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            const detailData = [...sizingfilteredData]
            const findElementIndex = detailData.findIndex(record => record.product_id === row.product_id);
            if (findElementIndex !== -1) { detailData.splice(findElementIndex, 1); }
            setSizingfilteredData(detailData);
            onGetFormSizingData(detailData);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));

        }
    }

    const renderDetailsTable = useMemo(() => {
        return <>
            <div style={{ overflowX: "hidden", flex: 0 }}>
                <Table className="erp_table erp_table_scroll" style={{ height: sizingfilteredData.length <= 5 ? 'auto' : '175px' }} responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>Actions</th>
                            <th className="erp_table_th" >Sr.no</th>
                            <th className="erp_table_th" >Material Code</th>
                            <th className="erp_table_th" >Material Name</th>
                            <th className="erp_table_th" >Material Tech Specification</th>
                            <th className="erp_table_th" >Material Unit</th>
                            <th className="erp_table_th" >Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sizingfilteredData.map((indentItem, Index) =>
                            <tr rowindex={Index} className="sticky-column">
                                <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`} >
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => removeMaterial(indentItem, e)} />
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td text-end sticky-column" >{Index + 1}</td>
                                <td className="erp_table_td "  >{indentItem.product_material_code}</td>
                                <td className={`erp_table_td sticky-column`} >{indentItem.product_name}</td>
                                <td className="erp_table_td "  >{indentItem.product_tech_spect}</td>
                                <td className="erp_table_td "  >{indentItem.product_unit_name}</td>
                                <td className="erp_table_td" style={{ width: '15%' }}>
                                    <input
                                        type="text" id={`product_material_approved_quantity_${indentItem.product_id}`}
                                        className="erp_input_field_table_txt mb-0 approveField text-end"
                                        style={{ width: '100px', backgroundColor: '#AFE1AF'}}
                                        value={indentItem.product_material_approved_quantity}
                                        onChange={(e) => handleInputChange(indentItem, e)}
                                        Headers="product_material_approved_quantity"
                                        disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    }, [sizingfilteredData])

    $(document).on('click', '.erp_input_field.remove0', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".erp_input_field.remove0");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });
    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <div className="row mb-2">
                <div className="col-md-4">
                    {!(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') &&
                        <SearchItemFilter getSearchData={handleCloseSizingFilterForm} formIdForValidate="" requiredCols={requiredCols} />
                    }
                </div>
                <div className="col-md-2">
                    <MDButton onClick={viewSizingFilterForm} className={`erp-gb-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">
                        Add Materials
                    </MDButton>
                </div>
                <div className="col text-end">
                    <Form.Label className="erp-form-label"> Job Work Rate </Form.Label>
                </div>
                <div className="col">
                    <Form.Control type="text" id="txt_txt_sizing_job_rate" className="erp_input_field remove0" value={txt_sizing_job_rate}
                        onChange={(e) => {
                            const { value } = e.target;
                            const isValidInput = /^\d{1,18}(?:\.\d{0,2})?$/.test(value) || value === '';
                            isValidInput && setJobWorkRate(value);
                            isValidInput && getsizingJObRate(value);
                        }} maxLength="18" disabled={keyForViewUpdate === 'view'}></Form.Control>
                    <MDTypography variant="button" id="error_txt_sizing_job_rate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                    </MDTypography>
                </div>
                <div className="col text-end">
                    <Form.Label className="erp-form-label"> Sizing Order No <span className="required">*</span> </Form.Label>
                </div>
                <div className="col">
                    <Form.Control type="text" id="txt_sizing_order_no" className="erp_input_field" value={txt_sizing_order_no} />
                    <MDTypography variant="button" id="error_txt_sizing_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                    </MDTypography>
                </div>

            </div>

            <div className="mt-2">
                {sizingfilteredData.length !== 0 ?
                    <>
                        {renderDetailsTable}
                    </> : ""
                }
            </div>
            <Modal size="lg" className='erp_document_Form' show={showSZFilterForm} onHide={handleCloseSizingFilterForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Product List</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSizingFilterForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <ProductListProductTypeWise closeModal={handleCloseSizingFilterForm}
                            props={{ product_type_id: cmb_indent_type }}
                        />
                    </React.Fragment>
                </Modal.Body>
            </Modal>

        </>

    )
}
