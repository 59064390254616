import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";
let PurchaseOrderTermstable = null;

const RawCottonPurchaseSleep = React.forwardRef(({ invoiceContent }, ref) => {
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;
    let AFTER_DECIMAL_PLACES = 2;

    const data = invoiceContent.items
    const deductionData = invoiceContent.rawCottonDeductionData
    const squareBorder = {
        border: '2px solid #000',
        // padding: '10px',
    };


    // check Payment Terms name each element in the Payment Terms array
    let paymentTermsList = null
    if (invoiceContent.paymentTerms && invoiceContent.paymentTerms.length > 0) {
        paymentTermsList = invoiceContent.paymentTerms.map(paymentTerm => paymentTerm.payment_terms_name).join(', ');
    }

    // Iterate over each element in the PurchaseOrderTerms array
    const PurchaseOrderTerms = invoiceContent.PurchaseOrderTerms;
    PurchaseOrderTerms.forEach(POTerms => {
        const { purchase_order_terms_parameters_name, purchase_order_terms_parameters_value } = POTerms;
        // Now you can use purchase_order_terms_parameters_name
        console.log('purchase_order_terms_parameters_name:', purchase_order_terms_parameters_name);
    });


    const contactDetails = invoiceContent.contactDetails;
    // Iterate over each element in the contactDetails array
    contactDetails.forEach(contact => {
        const { supp_contact_person, supp_contact_no } = contact;
        // Now you can use cust_contact_person and cust_contact_no
        console.log('Contact Person:', supp_contact_person);
        console.log('Contact Number:', supp_contact_no);
    });

    const itemData = extractItemData(invoiceContent.items);
    function extractItemData(items) {
        // Extract relevant data from the first item in the array
        if (items.length > 0) {
            const firstItem = items[0];
            return {
                department_name: firstItem.department_name,
                company: firstItem.company,
                company_branch: firstItem.company_branch,
                address: firstItem.address,
                phone: firstItem.phone,
                cell: firstItem.cell,
                email: firstItem.email,
                website: firstItem.website,
                GSTIN: firstItem.GSTIN,
                pan_no: firstItem.pan_no,
                state_name: firstItem.state_name,
                company_pincode: firstItem.company_pincode,
            };
        }
        // Return default values or handle the case where the array is empty
        return {
            department_name: 0,
            company: 0,
            company_branch: 0,
            address: 0,
            phone: 0,
            cell: 0,
            email: 0,
            website: 0,
            GSTIN: 0,
            pan_no: 0,
            state_name: 0,
            company_pincode: 0,
        };
    }

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>

                <div className='row mb-0 p-2'>
                    <div className="col-sm-3 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-9 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {itemData.company}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                                    </div>

                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.website}>{itemData.website}</a> || Email: {itemData.email} </span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'> Phone: {itemData.phone} || Cell:{itemData.cell} </span>
                                    </div>
                                    {/* <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no} </span>
                                    </div> */}
                                    <div className='erp-invoice-print-label-lg'> Purchase Slip </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-5'>
                        <dt className='erp-invoice-print-label-md-lg '> Purchase Slip No. : {invoiceContent.purchaseDetails.purchase_order_no} </dt>
                    </div>
                    <div className='col-sm-4'>
                        {/* <dt className='erp-invoice-print-label-md-lg '> Purchase Order Type: {invoiceContent.purchaseDetails.purchase_order_type}</dt> */}
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-md-lg text-end'>Purchase Slip Date : {invoiceContent.purchaseDetails.purchase_order_date}</dt>
                    </div>
                </div>
                <div className='row  border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Supplier Details:</dt>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Supplier Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.name}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Supplier Address:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.supplierDetails.address}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Grader Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.grader_by_name}</dd>

                            </dl>
                        </div>
                    </div>
                    <div className='row  border-top border-dark p-0 m-0'>
                        <dt className='erp-invoice-print-label-md-lg'>Vehical Details:</dt>
                        <dl className="row pb-0 mb-0">
                            <div className="col-sm-6">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-3 erp-invoice-print-label-md-lg'>vehicale Type:</dt>
                                    <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.vehicale_type}</dd>

                                </dl>
                            </div>
                            <div className="col-sm-6">
                                <dl className="row pb-0 mb-0">

                                    <dt className='col-sm-3 erp-invoice-print-label-md-lg'>vehicale No:</dt>
                                    <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.vehicale_no}</dd>

                                </dl>
                            </div>
                        </dl>
                    </div>
                    <div className='row  border-top border-dark p-0 m-0'>
                        {/* <dt className='erp-invoice-print-label-md-lg'>Vehical Details:</dt> */}
                        <dl className="row pb-0 mb-0">
                            <div className="col-sm-6">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Gross Weight (Kgs) :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.gross_weight}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Tare Weight (Kgs) :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.tare_weight}</dd>
                                </dl>
                            </div>
                            <div className="col-sm-6">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Weight Difference (Kgs) :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.weight_difference}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Net Weight (Kgs) :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.rawCottonPurchaseDetails.net_weight}</dd>

                                </dl>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    )


    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row mt-0'>
                <div className='col-sm-4 border-end border-dark'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.supplierDetails.name}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">PO Accepted By</dt>
                    </div>
                </div>
                <div className='col-sm-4  border-end border-dark'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.rawCottonPurchaseDetails.grader_by_name}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Grader By</dt>
                    </div>
                </div>
                <div className='col-sm-4 '> {/* This offsets the third column */}
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.rawCottonPurchaseDetails.created_by_name}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Entered By</dt>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {/* <div className="container"> */}
            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row headerContentHt" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {data.length !== 0 && (
                                                                <thead className='tblHeaderContentHt'>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-5" rowspan={2}>Cotton Description</th>
                                                                        <th className="erp_invoice_table_th text-center col-2" rowspan={2}>Quantity in Qtl</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Quantity in Kgs</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Unit</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Rate/Unit</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Total Amount</th>
                                                                    </tr>
                                                                </thead>
                                                            )}

                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>

                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.material_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.quantity_in_quintal}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.quantity_in_kg}</td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.rate}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.material_basic_amount}</td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {/* <div className='row  m-0 p-0  border-end-0 border-start-0'>
                                                            <dt className="col-sm-3 erp-invoice-print-label-md-lg">Deduction Details :</dt>
                                                        </div> */}
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {deductionData.length !== 0 && (
                                                                <thead className='tblHeaderContentHt'>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2} >Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-5" rowspan={2}>Deduction Type</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" rowspan={2}>Percent</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" rowspan={2}>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                            )}

                                                            <tbody id='detailstableId'>
                                                                {
                                                                    deductionData.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>

                                                                                <td className="erp_invoice_table_td">{item.deduction_type}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.deduction_percent}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.deduction_amount}</td>
                                                                            </tr>
                                                                            {
                                                                                deductionData.length - 1 === index ? <>
                                                                                    <tr id='footerTr' className='footerContentHt'>
                                                                                        <td className="erp_invoice_table_td" colSpan={7}>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Transport Amount :</dt>
                                                                                                <dd className="col-sm-3 erp-invoice-print-label">{parseFloat(invoiceContent.footer.transport_amount).toFixed(AFTER_DECIMAL_PLACES)}</dd>

                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Discount percent & Amount :</dt>
                                                                                                <dd className="col-sm-1 erp-invoice-print-label">{invoiceContent.footer.discount_percent}</dd>
                                                                                                <dd className="col-sm-2 erp-invoice-print-label">{parseFloat(invoiceContent.footer.discount_amount).toFixed(AFTER_DECIMAL_PLACES)}</dd>
                                                                                            </div>
                                                                                            <div className='row  mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Unloading Amount :</dt>
                                                                                                <dd className="col-sm-3 erp-invoice-print-label">{parseFloat(invoiceContent.footer.packing_amount).toFixed(AFTER_DECIMAL_PLACES)}</dd>

                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Other Amount :</dt>
                                                                                                <dd className="col-sm-3 erp-invoice-print-label">{parseFloat(invoiceContent.footer.other_amount).toFixed(AFTER_DECIMAL_PLACES)}</dd>
                                                                                            </div>

                                                                                            <div className='row  mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Grand Total :</dt>
                                                                                                <dd className="col-sm-4 erp-invoice-print-label">{parseFloat(invoiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</dd>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className='pOrderContent'>
                                                                                <td colSpan={12} >
                                                                                    <div className='row m-0 p-0'>
                                                                                        <dt className="col-sm-2 erp-invoice-print-label-md-lg">Remark:</dt>
                                                                                        <dt className="col-sm-8 erp-invoice-print-label">{invoiceContent.footer.remark}</dt>
                                                                                    </div>
                                                                                    <dl className="row p-0 m-0 mt-1">
                                                                                        <dt className="col-sm-2 erp-invoice-print-label-md-lg">PO Payment Terms: </dt>
                                                                                        {paymentTermsList !== null ? (
                                                                                            <dd className="col-sm-6 m-0 p-0">
                                                                                                <dl className="row m-0 p-0" key={index}>
                                                                                                    <dd className="col-sm-8 erp-invoice-print-label">
                                                                                                        <dt className="erp-invoice-print-label">{paymentTermsList}</dt>
                                                                                                    </dd>
                                                                                                </dl>
                                                                                            </dd>
                                                                                        ) : (
                                                                                            <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                                                        )}
                                                                                    </dl>
                                                                                    <dl className="row p-0 m-0 mt-1">
                                                                                        <dt className="col-sm-2 erp-invoice-print-label-md-lg">PO Terms Name: </dt>
                                                                                        {PurchaseOrderTerms.length > 0 ? (
                                                                                            <dd className="col-sm-6 m-0 p-0">
                                                                                                {PurchaseOrderTerms.map((PurchaseOrderTerm, index) => (
                                                                                                    <dl className="row m-0 p-0" key={index}>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label">
                                                                                                            {PurchaseOrderTerm.purchase_order_terms_parameters_name !== undefined ? ` ${PurchaseOrderTerm.purchase_order_terms_parameters_name}` : ''}
                                                                                                        </dd>
                                                                                                    </dl>
                                                                                                ))}
                                                                                            </dd>
                                                                                        ) : (
                                                                                            <dt className="col-sm-6 erp-invoice-print-label-md-lg">nil</dt>
                                                                                        )}
                                                                                    </dl>
                                                                                </td>
                                                                            </tr>
                                                                                    <tr className='invoiceFooterTr'>
                                                                                        <td colSpan={12} className="border p-0">
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>
                                                                                </> : null
                                                                            }
                                                                            
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})
export default RawCottonPurchaseSleep;

