import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table } from "react-bootstrap";

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import FrmValidations from "FrmGeneric/FrmValidations";


function FrmSalaryCorrectionsEntry() {

    // *********** Get Data from listing Page **********
    const { state } = useLocation();
    const { masterTransId = 0, keyForViewUpdate = 'Add', compType = 'Transaction', deletionKey = false, } = state || {}

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    // ****** navigate
    const navigate = useNavigate();

    // ****** Common Ref Hooks
    const frmValidation = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    const isZero = (value) => value === 0 || value === '0';

    // Success Modal.
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");
    const FnCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();  // Go back after save/updated.
    };

    // Error Modal.
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const FnCloseErrModal = () => {
        setShowErrorMsgModal(false)
    };

    // *** Header Frm Hooks;
    const [salary_transaction_id, setSalaryTransactionId] = useState(masterTransId);
    const [txt_salary_month, setSalaryMonth] = useState('');
    const [txt_salary_year, setSalaryYear] = useState('');
    const [txt_department_id, setDepartmentId] = useState('');
    const [txt_department_name, setDepartmentName] = useState('');
    const [txt_sub_department_id, setSubDepartmentId] = useState('');
    const [txt_sub_department_name, setSubDepartmentName] = useState('');
    const [txt_employee_type, setEmployeeType] = useState('');
    const [txt_employee_id, setEmployeeId] = useState('');
    const [txt_employee_name, setEmployeeName] = useState('');
    const [txt_designation_id, setDesignationId] = useState('');
    const [txt_designation_name, setDesignationName] = useState('');
    const [txt_month_days, setMonthDays] = useState('');
    const [txt_salary_days, setSalaryDays] = useState('');
    const [txt_monthly_salary, setMonthlySalary] = useState('');
    const [txt_gross_salary, setGrossSalary] = useState('');
    const [txt_total_deduction, setTotalDeduction] = useState('');
    const [txt_net_salary, setNetSalary] = useState('');
    // *** Some Hidden Fields....
    const [employee_type_group, setEmployeeTypeGroup] = useState('');
    const [salary_perday, setSalaryPerDay] = useState('');
    const [salary_perhour, setSalaryPerHour] = useState('');
    const [worked_hours, setWorkedHours] = useState('');

    // *** Footer Input Fields
    const [txt_updated_gross_salary, setUpdatedGrossSalary] = useState('');
    const [txt_updated_total_deduction, setUpdatedTotalDeduction] = useState('');
    const [txt_updated_net_salary, setUpdatedNetSalary] = useState('');

    // *** Table Data Hooks.
    const [salaryEarningTblData, setSalaryEarningTblData] = useState([]);
    const [salaryDeductionTblData, setSalaryDeductionTblData] = useState([]);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            if (masterTransId !== 0) {
                await FnCheckUpdateResponce()
            }
            setIsLoading(false);
        }
        loadDataOnload();
    }, [])

    // ***----------- Helping Functions.
    const moveToListing = () => {
        navigate("/HRPayroll/FrmSalaryCorrectionsListing")
    }
    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1); // Adjust for zero-based index
        return date.toLocaleString('default', { month: 'long' });
    }

    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'update':
                $('#save-btn').text('Update');
                return '(Modification)';
            case 'view':
                if (deletionKey === true) {
                    return '(Deletion)';
                } else {
                    return '(View)';
                }
            default:
                return '(Creation)';
        }
    };


    // *** ----------- Tables Data Processing Functionality Starts ---------------------
    // *** Earnings Tbl
    const renderSalaryEarningsTbl = useMemo(() => {
        return <>
            {salaryEarningTblData.length !== 0 ? (
                <Table id='salaryEarningTbl' className="erp_table" bordered striped hover>
                    <thead className="erp_table_head">
                        <tr>
                            <th className="erp_table_th">Sr No. </th>
                            <th className="erp_table_th col-10">Earning Name </th>
                            <th className="erp_table_th col-2">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            salaryEarningTblData.map((earningDtl, Index) =>
                                <tr rowIndex={Index}>
                                    <td className="erp_table_td">{Index + 1}</td>
                                    <td className="erp_table_td">{earningDtl.earning_head_name}</td>
                                    <td className="erp_table_td text-end">
                                        {
                                            ['view'].includes(keyForViewUpdate) || deletionKey
                                                ? earningDtl.earning_amount
                                                : <>
                                                    <Form.Control type="text" className="erp_input_field text-end mb-0" id={`earning_amount_${earningDtl.earning_heads_id}`}
                                                        value={earningDtl.earning_amount} Headers='earning_amount'
                                                        onFocus={(e) => { FnUpdateEarningsTblData(earningDtl, e); }}
                                                        onChange={(e) => { FnUpdateEarningsTblData(earningDtl, e); }}
                                                        onBlur={(e) => { FnUpdateEarningsTblData(earningDtl, e); }}
                                                        maxLength='19' disabled={earningDtl.calculation_type === 'Formula'}
                                                    />
                                                    {/* <input type="text" className="erp_input_field mb-0 text-end" id={`earning_amount_${earningDtl.earning_heads_id}`}
                                                        value={earningDtl.earning_amount} Headers='earning_amount'
                                                        onFocus={(e) => { FnUpdateEarningsTblData(earningDtl, e); }}
                                                        onChange={(e) => { FnUpdateEarningsTblData(earningDtl, e); }}
                                                        onBlur={(e) => { FnUpdateEarningsTblData(earningDtl, e); }}
                                                    /> */}
                                                </>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            ) : null}
        </>
    }, [salaryEarningTblData]);

    const FnUpdateEarningsTblData = (currentEarningDetails, event) => {
        delete event.target.parentElement.dataset.tip;
        let clickedColName = event.target.getAttribute('Headers');
        const currentObjIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        let inputVal = event.target.value;

        if (event._reactName === 'onFocus' && isZero(currentEarningDetails[clickedColName])) {
            inputVal = '';  // set input field blank.
        } else if (event._reactName === 'onChange') {
            inputVal = validateNumberDateInput.current.decimalNumber(inputVal, 4) || 0;
        } else if (event._reactName === 'onBlur') {
            inputVal = inputVal.endsWith('.') ? inputVal.slice(0, -1) : inputVal
            inputVal = validateNumberDateInput.current.decimalNumber(inputVal, 4) || 0;
        }

        const updateEarningDetails = [...salaryEarningTblData]    // Existing tbl Data
        // Updating new data.
        updateEarningDetails[currentObjIndex] = {
            ...currentEarningDetails,
            [clickedColName]: inputVal,   // Update input field value
        };
        setSalaryEarningTblData(updateEarningDetails);
        if (event._reactName === 'onChange' || event._reactName === 'onBlur') {
            debugger
            FnUpdateFooterCalculations(updateEarningDetails, salaryDeductionTblData)
        }
    }

    // *** Deductions Tbl
    const renderSalaryDeductionTbl = useMemo(() => {
        return <>
            {salaryDeductionTblData.length !== 0 ? (
                <Table id='salaryEarningTbl' className="erp_table" bordered striped hover>
                    <thead className="erp_table_head">
                        <tr>
                            <th className="erp_table_th">Sr No. </th>
                            <th className="erp_table_th col-10">Deduction Name </th>
                            <th className="erp_table_th col-2">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            salaryDeductionTblData.map((deductionDtl, Index) =>
                                <tr rowIndex={Index}>
                                    <td className="erp_table_td">{Index + 1}</td>
                                    <td className="erp_table_td">{deductionDtl.deduction_head_name}</td>
                                    <td className="erp_table_td text-end">
                                        {
                                            ['view'].includes(keyForViewUpdate) || deletionKey
                                                ? deductionDtl.deduction_amount
                                                : <>
                                                    <Form.Control type="text" className="erp_input_field text-end mb-0" id={`deduction_amount_${deductionDtl.deduction_heads_id}`}
                                                        value={deductionDtl.deduction_amount} Headers='deduction_amount'
                                                        onFocus={(e) => { FnUpdateDeductionsTblData(deductionDtl, e); }}
                                                        onChange={(e) => { FnUpdateDeductionsTblData(deductionDtl, e); }}
                                                        onBlur={(e) => { FnUpdateDeductionsTblData(deductionDtl, e); }}
                                                        maxLength='19' disabled={deductionDtl.calculation_type === 'Formula'}
                                                    />
                                                    {/* <input type="text" className="erp_input_field mb-0 text-end" id={`deduction_amount_${deductionDtl.deduction_heads_id}`}
                                                        value={deductionDtl.deduction_amount} Headers='deduction_amount'
                                                        onFocus={(e) => { FnUpdateDeductionsTblData(deductionDtl, e); }}
                                                        onChange={(e) => { FnUpdateDeductionsTblData(deductionDtl, e); }}
                                                        onBlur={(e) => { FnUpdateDeductionsTblData(deductionDtl, e); }}
                                                    /> */}
                                                </>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            ) : null}
        </>
    }, [salaryDeductionTblData]);

    const FnUpdateDeductionsTblData = (currentDeductionDetails, event) => {
        delete event.target.parentElement.dataset.tip;
        let clickedColName = event.target.getAttribute('Headers');
        const currentObjIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        let inputVal = event.target.value;

        if (event._reactName === 'onFocus' && isZero(currentDeductionDetails[clickedColName])) {
            inputVal = '';  // set input field blank.
        } else if (event._reactName === 'onChange') {
            inputVal = validateNumberDateInput.current.decimalNumber(inputVal, 4) || 0;
        } else if (event._reactName === 'onBlur') {
            inputVal = inputVal.endsWith('.') ? inputVal.slice(0, -1) : inputVal
            inputVal = validateNumberDateInput.current.decimalNumber(inputVal, 4) || 0;
        }

        const updateDeductionDetails = [...salaryDeductionTblData]    // Existing tbl Data
        // Updating new data.
        updateDeductionDetails[currentObjIndex] = {
            ...currentDeductionDetails,
            [clickedColName]: inputVal,   // Update input field value

        };
        setSalaryDeductionTblData(updateDeductionDetails);
        if (event._reactName === 'onChange' || event._reactName === 'onBlur') {
            FnUpdateFooterCalculations(salaryEarningTblData, updateDeductionDetails)
        }
    }


    // ********* Update the Footer Fields.
    const FnUpdateFooterCalculations = (updatedEarningsDtls, updatedDeductionsDtls) => {
        // Earnings Summations
        let excludedEarningHeadsInSummation = ['Salary'];
        const totalEarningsAmount = Math.ceil(
            updatedEarningsDtls
                .filter(earning => !excludedEarningHeadsInSummation.includes(earning.earning_head_name))
                .reduce((sum, earning) => sum + earning.earning_amount, 0)
        );

        // Deductions Summations
        let excludedDeductionHeadsInSummation = ['E-PF1', 'E-PF2'];
        const totalDeductionsAmount = Math.ceil(
            updatedDeductionsDtls
                .filter(deduction => !excludedDeductionHeadsInSummation.includes(deduction.deduction_head_name))
                .reduce((sum, deduction) => sum + deduction.deduction_amount, 0)
        );

        const netSalary = totalEarningsAmount - totalDeductionsAmount;

        setUpdatedGrossSalary(totalEarningsAmount);
        setUpdatedTotalDeduction(totalDeductionsAmount);
        setUpdatedNetSalary(Math.ceil(netSalary));

        $('#error_txt_updated_gross_salary').hide();
        $('#error_txt_updated_total_deduction').hide();
        $('#error_txt_updated_net_salary').hide();
    }
    // *** ----------- Tables Data Processing Functionality Ends -----------------------

    // *** ----------- CRUD Operations Functionality Starts ------------------
    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtSalarySummary/FnShowParticularRecordForUpdate/${masterTransId}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            if (updateRes !== null) {
                console.log('updateRes: ', updateRes);
                let salarySummaryData = updateRes.SummaryData;
                let salaryEarningData = updateRes.EarningData || [];
                let salaryDeductionData = updateRes.DeductionData || [];
                if (salarySummaryData !== null && salarySummaryData !== undefined) {
                    // Set Master Header Data;  
                    setSalaryTransactionId(salarySummaryData.salary_transaction_id);
                    setSalaryMonth(salarySummaryData.salary_month);
                    setSalaryYear(salarySummaryData.salary_year);
                    setDepartmentId(salarySummaryData.department_id);
                    setDepartmentName(salarySummaryData.department_name);
                    setSubDepartmentId(salarySummaryData.sub_department_id);
                    setSubDepartmentName(salarySummaryData.sub_department_name);
                    setEmployeeType(salarySummaryData.employee_type);
                    setEmployeeId(salarySummaryData.employee_id);
                    setEmployeeName(salarySummaryData.employee_name);
                    setDesignationId(salarySummaryData.designation_id);
                    setDesignationName(salarySummaryData.designation_name);
                    setMonthDays(salarySummaryData.month_days);
                    setSalaryDays(salarySummaryData.salary_days);
                    setMonthlySalary(salarySummaryData.monthly_salary);
                    setGrossSalary(salarySummaryData.gross_salary);
                    setTotalDeduction(salarySummaryData.total_deduction);
                    setNetSalary(salarySummaryData.net_salary);

                    setSalaryEarningTblData(salaryEarningData);
                    setSalaryDeductionTblData(salaryDeductionData);

                    setUpdatedGrossSalary(salarySummaryData.gross_salary);
                    setUpdatedTotalDeduction(salarySummaryData.total_deduction);
                    setUpdatedNetSalary(salarySummaryData.net_salary);

                    setEmployeeTypeGroup(salarySummaryData.employee_type_group);
                    setSalaryPerDay(salarySummaryData.salary_perday);
                    setSalaryPerHour(salarySummaryData.salary_perhour);
                    setWorkedHours(salarySummaryData.worked_hours);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnSaveSalaryCorrections = async () => {
        try {
            let allDetailsIsValid = FnValidateSalaryCorrectionFrm();
            if (allDetailsIsValid) {
                setIsLoading(true);

                let JsonForSave = { 'AllEmployeeSalarySummaryData': [], 'AllEmployeeSalaryEarningsData': [], 'AllEmployeeSalaryDeductionsData': [], 'commonFields': {} };
                JsonForSave.commonFields = {
                    company_id: parseInt(COMPANY_ID),
                    company_branch_id: parseInt(COMPANY_BRANCH_ID),
                    financial_year: FINANCIAL_SHORT_NAME,
                    salary_transaction_id: 0,
                    salary_version: 1,
                    department_id: txt_department_id,
                    sub_department_id: txt_sub_department_id,
                    employee_type: txt_employee_type,
                    employee_id: txt_employee_id,
                    designation_id: txt_designation_id,
                    salary_year: txt_salary_year,
                    salary_month: txt_salary_month,
                    created_by: UserName,
                }

                JsonForSave.AllEmployeeSalarySummaryData = [
                    {
                        ...JsonForSave.commonFields,
                        month_days: txt_month_days,
                        salary_days: txt_salary_days,
                        monthly_salary: txt_monthly_salary,
                        gross_salary: txt_updated_gross_salary,
                        total_deduction: txt_updated_total_deduction,
                        net_salary: txt_updated_net_salary,
                        worked_hours : worked_hours,        // Added for workers.
                        salary_perday : salary_perday,      // Added for workers.
                        salary_perhour : salary_perhour,    // Added for workers.
                    },
                ]

                JsonForSave.AllEmployeeSalaryEarningsData = salaryEarningTblData?.map((earningDtl) => {
                    return {
                        ...JsonForSave.commonFields,
                        salary_earnings_id: 0,
                        earning_heads_id: earningDtl.earning_heads_id,
                        earning_amount: earningDtl.earning_amount,
                    }
                });

                JsonForSave.AllEmployeeSalaryDeductionsData = salaryDeductionTblData?.map((deductionDtl) => {
                    return {
                        ...JsonForSave.commonFields,
                        salary_earnings_id: 0,
                        deduction_heads_id: deductionDtl.deduction_heads_id,
                        deduction_amount: deductionDtl.deduction_amount,
                    }
                });
                console.log('JsonForSave: ', JsonForSave)

                const formData = new FormData();
                formData.append(`MtSalaryProcessingData`, JSON.stringify(JsonForSave));
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtSalarySummary/FnAddUpdateRecord/${COMPANY_ID}`, requestOptions)
                const responce = await apicall.json();
                console.log(responce);
                if (responce !== null && apicall.status === 200) {
                    if (responce.success === '0') {
                        setErrMsg(responce.error)
                        setShowErrorMsgModal(true)
                    } else {
                        setSuccMsg(responce.message)
                        setShowSuccessMsgModal(true)
                    }
                    setIsLoading(false);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // *** ----------- CRUD Operations Functionality Ends ---------------------


    // *** ----------- CRUD Operations Functionality Starts -------------------
    const FnValidateSalaryCorrectionFrm = () => {
        let correctionFrmIsValid = true;

        if (isNaN(txt_updated_gross_salary) || txt_updated_gross_salary < 0) {
            $('#error_txt_updated_gross_salary').text("Gross salary must be a non-negative number.");
            $('#error_txt_updated_gross_salary').show();
            correctionFrmIsValid = false;
            return false;
        }
        if (isNaN(txt_updated_total_deduction) || txt_updated_total_deduction < 0) {
            $('#error_txt_updated_total_deduction').text("Total deduction amount must be a non-negative number.");
            $('#error_txt_updated_total_deduction').show();
            correctionFrmIsValid = false;
            return false;
        }
        if (txt_updated_total_deduction > txt_updated_gross_salary) {
            $('#error_txt_updated_total_deduction').text("Total deduction amount cannot be greater than gross salary.");
            $('#error_txt_updated_total_deduction').show();
            correctionFrmIsValid = false;
            return false;
        }
        if (isNaN(txt_updated_net_salary) || txt_updated_net_salary < 0) {
            $('#error_txt_updated_net_salary').text("Net salary must be a non-negative number.");
            $('#error_txt_updated_net_salary').show();
            correctionFrmIsValid = false;
            return false;
        }
        return correctionFrmIsValid;
    }
    // *** ----------- CRUD Operations Functionality Ends ---------------------


    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <FrmValidations ref={validate} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Salary Correction {ActionType()}</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        {/* Header Form Row Starts */}
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Salary Year</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_salary_year} </Form.Label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Salary Month</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label">
                                            {
                                                txt_salary_month !== ''
                                                    ? <>{getMonthName(txt_salary_month)} {`(${txt_salary_month})`}</>
                                                    : null
                                            }

                                        </Form.Label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Department</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_department_name} </Form.Label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Sub Department</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_sub_department_name} </Form.Label>
                                    </div>
                                </div>

                            </div>
                            {/* First Column Ends*/}

                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Employee Name</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_employee_name} </Form.Label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Designation Name</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_designation_name} </Form.Label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Month Days</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_month_days} </Form.Label>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Present Days</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_salary_days} </Form.Label>
                                    </div>
                                </div> */}
                                {
                                    employee_type_group == 'Staffs'
                                        ? <>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label"> <strong>Present Days</strong> </Form.Label>
                                                </div>
                                                <div className='col-12 col-sm-8'>
                                                    <Form.Label className="erp-form-label"> {txt_salary_days} </Form.Label>
                                                </div>
                                            </div>
                                        </>
                                        : <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Form.Label className="erp-form-label"> <strong>Present Days</strong> </Form.Label>
                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <Form.Label className="erp-form-label"> {txt_salary_days} </Form.Label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Form.Label className="erp-form-label"> <strong>Worked Hours</strong> </Form.Label>
                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <Form.Label className="erp-form-label"> {worked_hours} </Form.Label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                }

                            </div>
                            {/* Second Column Ends*/}

                            {/* Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                {
                                    employee_type_group == 'Staffs'
                                        ? <>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label"> <strong>Monthly Salary</strong> </Form.Label>
                                                </div>
                                                <div className='col-12 col-sm-8'>
                                                    <Form.Label className="erp-form-label"> {txt_monthly_salary} </Form.Label>
                                                </div>
                                            </div>
                                        </>
                                        : <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Form.Label className="erp-form-label"> <strong>PerDay</strong> </Form.Label>
                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <Form.Label className="erp-form-label"> {salary_perday} </Form.Label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Form.Label className="erp-form-label"> <strong>PerHour</strong> </Form.Label>
                                                        </div>
                                                        <div className='col-12 col-sm-6'>
                                                            <Form.Label className="erp-form-label"> {salary_perhour} </Form.Label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                }

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Gross Salary</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_gross_salary} </Form.Label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Total Deductions</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_total_deduction} </Form.Label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> <strong>Net Salary</strong> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Form.Label className="erp-form-label"> {txt_net_salary} </Form.Label>
                                    </div>
                                </div>
                            </div>
                            {/* Third Column Ends*/}

                        </div>
                        {/* Header Form Row Ends */}

                        {/* Salary Processing Data */}
                        <hr />
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-lg-6 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-lg">Earnings</label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">
                                        <div className={`table-responsive ${salaryEarningTblData.length > 5 ? 'erp_table_scroll' : ''}`} style={{ height: '350px' }}>
                                            {renderSalaryEarningsTbl}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* First Column Ends*/}

                            {/* Second Column Starts*/}
                            <div className="col-lg-6 col-12 erp_form_col_div">
                                <div className="card mt-lg-0 mt-2">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-lg">Deductions</label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">
                                        <div className={`table-responsive ${salaryDeductionTblData.length > 5 ? 'erp_table_scroll' : ''}`} style={{ height: '350px' }}>
                                            {renderSalaryDeductionTbl}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Second Column Ends*/}
                        </div>

                        {/* Calculated Fields Row */}
                        <hr />
                        <label className="erp-form-label-lg">Updated Salary Totals</label>
                        <div className="row">
                            <form id="updatedCalculationsFrmId">
                                <div className="row">
                                    {/* First Column Starts*/}
                                    <div className="col-lg-4 col-12 erp_form_col_div">
                                        <div className="row">
                                            <div className='col-6'>
                                                <Form.Label className="erp-form-label">Gross Salary</Form.Label>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Control type="text" id="txt_updated_gross_salary " className="erp_input_field text-end" value={txt_updated_gross_salary} disabled />
                                                <MDTypography variant="button" id="error_txt_updated_gross_salary" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                    {/* First Column Ends*/}

                                    {/* Second Column Starts*/}
                                    <div className="col-lg-4 col-12 erp_form_col_div">
                                        <div className="row">
                                            <div className='col-6'>
                                                <Form.Label className="erp-form-label">Total Deductions</Form.Label>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Control type="text" id="txt_updated_total_deduction " className="erp_input_field text-end" value={txt_updated_total_deduction} disabled />
                                                <MDTypography variant="button" id="error_txt_updated_total_deduction" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Second Column Ends*/}

                                    {/* Third Column Starts*/}
                                    <div className="col-lg-4 col-12 erp_form_col_div">
                                        <div className="row">
                                            <div className='col-6'>
                                                <Form.Label className="erp-form-label">Net Salary</Form.Label>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Control type="text" id="txt_updated_net_salary " className="erp_input_field text-end" value={txt_updated_net_salary} disabled />
                                                <MDTypography variant="button" id="error_txt_updated_net_salary " className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Third Column Ends*/}
                                </div>
                            </form>
                        </div>
                        {/* /Calculated Fields Row */}


                        {/* Action Buttons Row */}
                        <hr />
                        <div className="row text-center">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { moveToListing(); }}  > Back </MDButton>

                                {
                                    !['view'].includes(keyForViewUpdate) && !deletionKey
                                        ? <>
                                            <MDButton type="button" id="save-btn" variant="button" fontWeight="regular"
                                                className={`erp-gb-button float-center ms-1`}
                                                onClick={(e) => { FnSaveSalaryCorrections(); }}  > Save </MDButton>
                                        </>
                                        : null
                                }

                            </div>
                        </div>
                        {/* /Action Buttons Row */}

                    </div>
                    {/* Card's Body Ends*/}

                </div>

                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => FnCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>
        </>
    )
}
export default FrmSalaryCorrectionsEntry