import React from 'react'
import { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// import react icons
import { useNavigate, useLocation } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import { Accordion, Table } from "react-bootstrap";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { globalQuery } from 'assets/Constants/config-constant';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { CircularProgress } from "@material-ui/core";

export default function FrmMProductCategory1Entry(props) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;

  const { state } = useLocation();
  const { productcategory1ID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};


  var activeValue = '';
  const comboDataAPiCall = useRef();
  const validate = useRef();

  // combobox option
  const [productTypeOptions, setProductTypeOptions] = useState([]);

  // Add Product Type Form Fields
  const [product_cat1_category1_id, setCat1ProductCategory1Id] = useState(productcategory1ID);
  const [cmb_cat1_product_type_id, setCat1ProductTypeId] = useState();
  const [txt_cat1_product_category1_name, setCat1ProductCategory1Name] = useState('');
  const [txt_cat1_product_category1_short_name, setCat1ProductCategory1ShortName] = useState('');
  const [txt_cat1_remark, setCat1Remark] = useState('');
  const [actionType, setActionType] = useState('')

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [actionLabel, setActionLabel] = useState('Save')

  //----product Type Parameter Group Value
  const [productTypeGroupData, setProductTypeGroupData] = useState([])
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [ptGroupOptions, setProductTypeGroupOption] = useState([])
  const [rowCount, setRowCount] = useState(1)
  const [isLoading, setIsLoading] = useState(false);

  // Show ADD record Modal
  const handleCloseRecModal = () => {
    comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypeApiCall) => {
      setProductTypeOptions(productTypeApiCall)
    })
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')

    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductCategory1Listing`)
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // For navigate
  const navigate = useNavigate();

  useEffect(async () => {
    ActionType();
    addmodalproduct();

    await fillComobos();
    if (productcategory1ID !== 0) {
      await FnCheckUpdateResponce();
    }

  }, [])
  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');
        setActionLabel('Update');
        break;
      case 'view':
        setActionType('(View)');
        // comboOnChange();
        await validate.current.readOnly("productcategory1FormId");

        break;
      default:
        setActionType('(Create)');
        break;
    }
  };

  const addmodalproduct = () => {
    if (props.product_type !== "" && props.product_type !== undefined) {
      setCat1ProductTypeId(props.product_type)
      // $('#cmb_cat1_product_type_id').attr('disabled', true);
    }
    // else {
    //   $('#cmb_cat1_product_type_id').attr('disabled', false);
    // }

  }

  const comboOnChange = async (key) => {
    debugger
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_cat1_product_type_id').value;
        setCat1ProductTypeId(productTpVal)
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)

        }
        break;
    }
    var productTypeId = document.getElementById('cmb_cat1_product_type_id').value;
    resetGlobalQuery();
    globalQuery.columns.push("field_id");
    globalQuery.columns.push("field_name");
    globalQuery.columns.push("product_type_group_parameters_name");
    globalQuery.columns.push("product_type_group_parameters_id");
    globalQuery.table = "smv_product_type_group_parameters"
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });

    const ptGroupApiResponse = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)

    setProductTypeGroupOption(ptGroupApiResponse);
    setAccordionOpen(ptGroupApiResponse.length > 0);

  }

  const fillComobos = async () => {
    try {

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "smv_product_type"
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const productTypes = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setProductTypeOptions(productTypes)


      // comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypes) => {
      //   setProductTypeOptions(productTypes)
      // })

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }

  const FnAddUpdateCategory1 = async () => {
    try {
      let checkIscalculation = false;
      checkIscalculation = await FnValidateForm()

      const json = { 'TransHeaderData': {}, 'ProductCategory1GroupParameterData': [], 'commonIds': { 'company_id': COMPANY_ID, 'product_category1_id': product_cat1_category1_id } }
      const checkIsValidate = await validate.current.validateForm('productcategory1FormId');
      if (checkIsValidate && checkIscalculation) {

        var active;
        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;

        }
        if (keyForViewUpdate === "add") {

          // Push modified productTypeGroupData items into json.ProductCategory1GroupParameterData
          productTypeGroupData.forEach(item => {
            const modifiedProductParameter = {
              company_id: COMPANY_ID,
              product_type_group_parameters_id: item.product_type_group_parameters_id,
              prefix: item.prefix,
              suffix: item.suffix,
              production: item.production,
              print: item.print,
              calculation_type: item.calculation_type,
              value1: item.value1,
              value2: item.value2,
              product_type_id: cmb_cat1_product_type_id,
              product_category1_id: product_cat1_category1_id,
              created_by: UserName,
            };

            json.ProductCategory1GroupParameterData.push(modifiedProductParameter);
          });
        } else {
          productTypeGroupData.forEach(item => {
            delete item.product_type_group_parameters_name;
            delete item.product_type_group_parameters_code;
          });
          json.ProductCategory1GroupParameterData = productTypeGroupData
        }

        const data = {
          company_id: COMPANY_ID,
          product_category1_id: product_cat1_category1_id,
          created_by: UserName,
          modified_by: product_cat1_category1_id === 0 ? null : UserName,
          product_type_id: cmb_cat1_product_type_id,
          product_category1_name: txt_cat1_product_category1_name,
          product_category1_short_name: txt_cat1_product_category1_short_name.trim(),
          remark: txt_cat1_remark,
          is_active: active,
        };
        setIsLoading(true);
        json.TransHeaderData = data
        const formData = new FormData()
        formData.append('ProductCategory1AllData', JSON.stringify(json))

        const forwardData = {
          method: 'POST',
          body: formData,
        }
        const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory1/FnAddUpdateRecord`, forwardData)
        const responce = await agentApiCall.json();
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();
        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false);
    }

  };
  const clearFormFields = () => {
    setCat1ProductTypeId('');
    setCat1ProductCategory1Name('');
    setCat1ProductCategory1ShortName('');
    setCat1Remark('');

  }

  const FnValidateForm = async () => {
    let allFieldsValid = true;

    $('#productTypeGroupTbl tbody tr').each(function () {
      let currentTblRow = $(this);
      //let CalculationType = currentTblRow.find('input[id^="calculation_type_"]').val();
      let CalculationType = currentTblRow.find('.calculationType').val();
      let value1 = currentTblRow.find('input[id^="value1_"]').val();
      let value2 = currentTblRow.find('input[id^="value2_"]').val();

      if (CalculationType !== "between") {
        if (CalculationType === '') {
          return true; // Skip to the next iteration if CalculationType is empty
        }

        if (value1 === '') {
          currentTblRow.find('input[id^="value1_"]').parent().attr('data-tip', 'Please fill this field');
          currentTblRow.find('input[id^="value1_"]').focus();
          allFieldsValid = false;
          return false; // Break the loop
        }
      } else { // When CalculationType is "between"
        if (value1 === '' || value2 === '') {
          if (value1 === '') {
            currentTblRow.find('input[id^="value1_"]').parent().attr('data-tip', 'Please fill this field');
            currentTblRow.find('input[id^="value1_"]').focus();
          }
          if (value2 === '') {
            currentTblRow.find('input[id^="value2_"]').parent().attr('data-tip', 'Please fill this field');
            currentTblRow.find('input[id^="value2_"]').focus();
          }
          allFieldsValid = false;
          return false; // Break the loop
        }
      }
    });

    if (allFieldsValid) {
      console.log("All fields are filled.");
      return true;
    } else {
      console.log("There are empty fields. Please fill them.");
      return false;
    }
  };



  const handleProductTypeChange = (event) => {
    comboOnChange('productType');
    validateFields();

  };


  const validateFields = () => {
    validate.current.validateFieldsOnChange('productcategory1FormId')
  }


  //------------product Type parameter Group And value functinality-------------------------
  const FnCheckUpdateResponce = async () => {
    debugger
    try {
      const UpadatePtCategory1RecrdsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory1/FnShowProductCategory1AndGroupParameterRecordForUpdate/${product_cat1_category1_id}/${COMPANY_ID}`)
      const responce = await UpadatePtCategory1RecrdsApiCall.json();

      let resp = responce.productCategory1Record
      setCat1ProductCategory1Id(resp.product_category1_id)
      setCat1ProductTypeId(resp.product_type_id);
      await comboOnChange('productType');
      setCat1ProductCategory1Name(resp.product_category1_name);
      setCat1ProductCategory1ShortName(resp.product_category1_short_name);
      setCat1Remark(resp.remark);
      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }

      let ptCategoryMapRecords = responce.ProductCategory1GroupParameterRecords;

      const updatedproductTypeGroupData = ptCategoryMapRecords.map(item => ({
        company_id: item.company_id,
        created_by: item.created_by,
        product_type_group_parameters_id: item.product_type_group_parameters_id,
        product_type_group_parameters_code: item.product_type_group_parameters_code,
        product_type_group_parameters_name: item.product_type_group_parameters_name,
        prefix: item.prefix,
        suffix: item.suffix,
        production: item.production,
        print: item.print,

        product_type_id: item.product_type_id,
        product_category1_id: item.product_category1_id,
        calculation_type: item.calculation_type,
        value1: item.value1,
        value2: item.value2,

      }));
      setProductTypeGroupData(updatedproductTypeGroupData);
      setAccordionOpen(updatedproductTypeGroupData.length > 0);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }


  const updatePTGroupTblData = (currentProductTypeGroup, e) => {
    let clickedColName = e.target.getAttribute('Headers');
    delete e.target.parentElement.dataset.tip;

    switch (clickedColName) {

      case 'prefix':
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'suffix':
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'print':
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'production':
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'product_type_group_parameters_id':
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'value1':
        $('#error_value1').hide();
        $('#error_value2').hide();
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'value2':
        $('#error_value1').hide();
        $('#error_value2').hide();
        currentProductTypeGroup[clickedColName] = e.target.value
        break;
      case 'calculation_type':
        currentProductTypeGroup[clickedColName] = e.target.value;
        $('#error_value1').hide();

        $('#error_value2').hide();
        break;
      default:
        break;
    }
    // update the DeductionData table data.
    const updatePtGroupData = [...productTypeGroupData]
    const productTypeIndexArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
    updatePtGroupData[productTypeIndexArray] = currentProductTypeGroup;
    setProductTypeGroupData(updatePtGroupData);

  }


  const PTBlankObject = {
    company_id: COMPANY_ID,
    product_type_group_parameters_id: 0,
    prefix: '',
    suffix: '',
    print: 'true',
    production: 'true',
    product_type_id: '',
    product_category1_id: '',
    calculation_type: '',
    value1: '',
    value2: '',
    created_by: UserName,

  }

  const removeFirstRow = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedAgentContactData = productTypeGroupData.filter((item, index) => index !== indexToRemove);
      setProductTypeGroupData(updatedAgentContactData)
    } else {
      const updatedAgentContactData = [...productTypeGroupData];  // Create a copy of the array
      updatedAgentContactData[0] = { ...PTBlankObject }; // Set values of 0th index to the PTBlankObject
      setProductTypeGroupData(updatedAgentContactData);
    }
  }

  const setRowCountAndAddRow = (item) => {
    const lastRowIndex = productTypeGroupData.length - 1;
    const lastRowProductTypeGroup = productTypeGroupData[lastRowIndex].product_type_group_parameters_id;

    if (lastRowProductTypeGroup !== '0' && lastRowProductTypeGroup !== undefined && lastRowProductTypeGroup !== 0) {
      setRowCount(rowCount + 1);
    } else {
      const tableRows = document.querySelectorAll('#productTypeGroupTbl tbody tr');
      let hasEmptyField = false;

      tableRows.forEach(row => {
        const productTypeGroupName = row.querySelector('select[id^="product_type_group_parameters_name_"]').value;

        if (productTypeGroupName === '') {
          row.querySelector('select[id^="product_type_group_parameters_name_"]').parentElement.dataset.tip = 'Please select a value.';
          hasEmptyField = true;
        } else {
          delete row.querySelector('select[id^="product_type_group_parameters_name_"]').parentElement.dataset.tip;
        }
      });

      if (hasEmptyField) {
        console.log("There are empty fields. Please fill them.");
      }
    }
  };




  useLayoutEffect(() => {
    const getExistingAgentContactData = [...productTypeGroupData]
    getExistingAgentContactData.push(PTBlankObject)
    setProductTypeGroupData(getExistingAgentContactData)
  }, [rowCount])
  const renderProductTypeGroupTable = useMemo(() => {
    return <Table id='productTypeGroupTbl' className={`erp_table ${productTypeGroupData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
      <thead className="erp_table_head">
        <tr>
          <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
          <th className="erp_table_th">Sr.NO</th>
          <th className="erp_table_th">Name</th>
          <th className="erp_table_th">Prefix</th>
          <th className="erp_table_th">Sufix</th>
          <th className="erp_table_th">VAlue Type</th>
          <th className="erp_table_th">Value1</th>
          <th className="erp_table_th">Value2</th>
          <th className="erp_table_th">Print</th>
          <th className="erp_table_th">Production</th>
        </tr>
      </thead>
      <tbody>
        {productTypeGroupData.map((item, index) =>
          <tr rowindex={index} className="sticky-column" >

            <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
              <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
              <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(item)} />
            </td>

            <td className='erp_table_td' style={{ width: "10px" }} >
              {index + 1}
            </td>
            <td className='erp_table_td'>

              <select id={`product_type_group_parameters_name_${index}`} value={item.product_type_group_parameters_id} className="form-select form-select-sm" style={{ width: "auto" }}
                onChange={(e) => { updatePTGroupTblData(item, e); validateFields(); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); }}
                Headers="product_type_group_parameters_id">
                <option value="">Select</option>
                {ptGroupOptions?.map(ptGroup => (
                  <option value={ptGroup.field_id}>{ptGroup.field_name}</option>

                ))}
              </select>
              <MDTypography variant="button" id="error_product_type_group_parameters_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
              </MDTypography>

            </td>


            <td className="erp_table_td">
              <input type="text" className="erp_input_field_table_txt mb-0" value={item.prefix} id={"prefix_" + item.product_type_group_parameters_id} onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); }}
                Headers='prefix' disabled={keyForViewUpdate === 'view'} />
            </td>
            <td className="erp_table_td">
              <input type="text" className="erp_input_field_table_txt mb-0" value={item.suffix} id={"sufix_" + item.product_type_group_parameters_id} onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); }}
                Headers='suffix' disabled={keyForViewUpdate === 'view'} />
            </td>
            <td className="erp_table_td">
              <select className="form-select form-select-sm calculationType mb-0" style={{ width: "auto" }} value={item.calculation_type} id={"calculation_type_" + item.product_type_group_parameters_id}
                onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); }}
                Headers='calculation_type' disabled={keyForViewUpdate === 'view'}
              >
                <option value="">Select</option>
                <option value="equals">equals</option>
                <option value="less than">less than</option>
                <option value="gretter than">gretter than</option>
                <option value="between">between</option>
              </select>
            </td>
            <td className="erp_table_td">
              <input type="text" className="erp_input_field_table_txt value1 mb-0" value={item.value1} id={"value1_" + item.product_type_group_parameters_id} onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); validateFields(); }}
                Headers='value1' disabled={keyForViewUpdate === 'view'} />
              <MDTypography variant="button" id="error_value1_" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
              </MDTypography>
            </td>
            <td className="erp_table_td">
              <input type="text" className="erp_input_field_table_txt value2 mb-0" value={item.value2} id={"value2_" + item.product_type_group_parameters_id} onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); validateFields(); }}
                Headers='value2' disabled={keyForViewUpdate === 'view'} />
              <MDTypography variant="button" id="error_value2_" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
              </MDTypography>
            </td>
            <td className="erp_table_td">
              <select className="form-select form-select-sm mb-0" style={{ width: "auto" }} value={item.print} id={"print_" + item.product_type_group_parameters_id}
                onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); }}
                Headers='print' disabled={keyForViewUpdate === 'view'}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </td>

            <td className="erp_table_td">
              <select className="form-select form-select-sm mb-0" style={{ width: "auto" }} value={item.production} id={"production_" + item.product_type_group_parameters_id}
                onChange={(e) => { updatePTGroupTblData(item, e); }}
                onBlur={(e) => { updatePTGroupTblData(item, e); }}
                Headers='production' disabled={keyForViewUpdate === 'view'}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </td>

          </tr>
        )}
      </tbody>
    </Table>
  }, [productTypeGroupData, ptGroupOptions])

  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validate} />
      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span>Loading...</span>
          </div>
        </div> :
        ''}
      <div className='erp_top_Form'>
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Product Category 1 {actionType}  </label>
          </div>
          <form id="productcategory1FormId">
            <div className='row erp_transporter_div'>

              {/* first row */}
              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat1_product_type_id" className="form-select form-select-sm" value={cmb_cat1_product_type_id} onChange={handleProductTypeChange} disabled={keyForViewUpdate === 'update' || (props.product_type !== "" && props.product_type !== undefined)}>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record +</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_cat1_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label"> Category1 Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_cat1_product_category1_name" className="erp_input_field" value={txt_cat1_product_category1_name} onChange={e => { setCat1ProductCategory1Name(e.target.value); validateFields(); }} maxLength="255" optional='optional' />
                    <MDTypography variant="button" id="error_txt_cat1_product_category1_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_cat1_product_category1_short_name" className="erp_input_field" value={txt_cat1_product_category1_short_name} onChange={e => { setCat1ProductCategory1ShortName(e.target.value.toUpperCase()); validateFields(); }} optional="optional" />
                    <MDTypography variant="button" id="error_product_category1_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>
              </div>

              {/* second row */}

              <div className="col-sm-6 erp_form_col_div">
                <div className="row">
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_cat1_remark" value={txt_cat1_remark} onChange={e => { setCat1Remark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check className="erp_radio_button" label="Yes" type="radio" value="1" name="isactive" defaultChecked />
                      </div>
                      <div className="sCheck">
                        <Form.Check className="erp_radio_button" label="No" value="0" type="radio" name="isactive" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
          <div className='col-md-12'>
            {accordionOpen && (
              <div className="row">
                <div className="col-sm-12">
                  <Accordion defaultActiveKey="1">
                    {/* <Accordion defaultActiveKey={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' ? "1" : null} className="mt-3"> */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="erp-form-label-md">Product Type Group</Accordion.Header>
                      <Accordion.Body className="p-0">
                        <div className="mt-10">
                          {renderProductTypeGroupTable ? renderProductTypeGroupTable : <p>No data available</p>}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button" onClick={() => {
              const path = compType === 'Register' ? '/Masters/ProductCategory1Listing/reg' : '/Masters/ProductCategory1Listing';
              navigate(path);
            }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}
            >Back</MDButton>
            {/* keyForViewUpdate */}
            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" className="erp-gb-button erp_MLeft_btn" id="saveBtn" variant="button" fontWeight="regular" onClick={FnAddUpdateCategory1}> {actionLabel} </MDButton>) : null}
          </div>
        </div>

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          {/* <Modal.Header>
            <Modal.Title className='erp_modal_title'>{cat2ModalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecCat2Modal}></button></span>
          </Modal.Header> */}
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            <FrmMProductTypeEntry btn_disabled />
          </Modal.Body>
          {/* <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecCat2Modal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer> */}
        </Modal >
        {/* // chnages by tushar */}

        {/* Success Msg Popup */}
        < SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()
        } show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        < ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
      </div >

    </>
  )
}
