import React from 'react'
import { useState } from "react";
import $ from 'jquery';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// imports react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FrmMGodownEntry from './components/FrmMGodownEntry';
import FrmMGodownBinsEntry from './components/FrmMGodownBinsEntry';
import FrmMGodownSectionEntry from './components/FrmMGodownSectionEntry';
import { useLocation } from 'react-router-dom';



function getSteps() {
  return ["Godown Information", "Godown Section Information", "Godown Bins Information"];
}


export default function MGodownEntry() {
  const { state } = useLocation();
  const { godownID = 0, keyForViewUpdate, compType = 'Masters' } = state || {}


  const [activeStep, setActiveStep] = useState(0);
  const [godown_id, setGodownId] = useState(godownID)
  const [key, setkey] = useState(keyForViewUpdate)

  const steps = getSteps();

  const handleNext = (godownID, keyForViewUpdate) => {
    setGodownId(godownID)
    setkey(keyForViewUpdate)
    if (godownID !== 0) {
      setActiveStep(parseInt(activeStep) + 1)
    }
  };

  const handleBack = (godown_id) => {
    setGodownId(godown_id)
    setActiveStep((parseInt(activeStep) - 1))
  };

  const moveByTabs = (eventKey) => {
    if (godown_id !== 0) {
      setActiveStep(eventKey)
    }
  }

  function getStepContent() {
    switch (parseInt(activeStep)) {
      case 0:
        return <FrmMGodownEntry goToNext={handleNext} godownID={godown_id} keyForViewUpdate={key} compType={compType} />;
      case 1:
        return <FrmMGodownSectionEntry goBack={handleBack} goToNext={handleNext} godownID={godown_id} keyForViewUpdate={key} compType={compType} />;
      case 2:
        return <FrmMGodownBinsEntry goBack={handleBack} godownID={godown_id} keyForViewUpdate={key} compType={compType} />
      default:
        return null;
    }
  }
  return (
    <>
      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'> Godown Information</label>
        </div>
        <MDBox pt={3} pb={8} className="erp_form_tab_div">
          <Grid justifyContent="center" sx={{ my: 4 }} >
            <Grid item xs={12} lg={8} className="erp_form_container_tabs">

              <MDBox mt={-3} mx={2}>
                <Tabs
                  activeKey={activeStep}
                  id="uncontrolled-tab-example"
                  className="mb-3 selectedTab erp-form-Tabs" onSelect={eventKey => moveByTabs(eventKey)}>
                  {steps.map((label, index) => (
                    <Tab eventKey={index} title={label} ></Tab>
                  ))}
                </Tabs>
                {getStepContent()}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </>
  );
}
