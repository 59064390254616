/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import pashupatiGroup from "assets/images/pashupatigroup_loader.gif";
import pasupati_banner from "assets/images/pasupati_banner.jpg";

// File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import { useAppContext } from "context/AppContext";
import ConfigConstants from "assets/Constants/config-constant";

function Login() {
    const [rememberMe, setRememberMe] = useState(false);

    const configConstants = ConfigConstants();
    const { IS_AUTHENTICATED } = configConstants;

    // Set values in the context
    const { dispatch } = useAppContext();

    const navigate = useNavigate();
    const validate = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    // Loader
    const [isLoading, setIsLoading] = useState(false);

    // Pashupati banner
    const [loading, setLoading] = useState(true); // Initially set loading to true


    useEffect(() => {
        // Simulate loading for 2 seconds, then hide the loading spinner
        if (IS_AUTHENTICATED) {
            navigate("/DashBoard")
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 7000);
            // Clean up the timer to prevent memory leaks
            return () => clearTimeout(timer);
        }

    }, [])

    const FnLogin = async () => {
        try {
            setIsLoading(true)
            const checkIsValidate = await validate.current.validateForm('loginId');
            if (checkIsValidate === true) {
                const loginInfo = {
                    // company_id: parseInt(cmb_company_id),
                    username: username,
                    password: password,
                    remember_me: rememberMe
                }

                const method = {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginInfo)
                }
                const signin = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/signin`, method)
                if (signin.status === 200) {
                    debugger
                    const response = await signin.json();
                    const apiResponse = response

                    localStorage.setItem('isAuthenticated', true);
                    localStorage.setItem('userType', apiResponse.user_type);
                    localStorage.setItem('userId', apiResponse.user_id);
                    localStorage.setItem('user_code', apiResponse.user_code);
                    localStorage.setItem('userName', apiResponse.username);
                    localStorage.setItem('user', apiResponse.user);
                    localStorage.setItem('companyID', apiResponse.company_id)
                    // await FnGetCompanySettings(apiResponse.company_id)
                    localStorage.setItem('companyBranchID', apiResponse.company_branch_id)
                    localStorage.setItem('company_name', apiResponse.company_name)
                    localStorage.setItem('company_branch_name', apiResponse.company_branch_name)
                    localStorage.setItem('companyAddress', apiResponse.company_branch_address)
                    sessionStorage.setItem('companyAddress', apiResponse.company_branch_address)
                    localStorage.setItem('branch_short_name', apiResponse.branch_short_name)
                    localStorage.setItem('company_branch_type', apiResponse.company_branch_type)
                    localStorage.setItem('user_access', JSON.stringify(response.userAccessDetails))
                    localStorage.setItem('Listing_FilterPreferenceDetails', JSON.stringify(response.listingFilterPreferenceDetails))
                    localStorage.setItem('page_routes', JSON.stringify(response.pageRoutes))
                    localStorage.setItem('department_id', JSON.stringify(response.department_id))
                    localStorage.setItem('department_name', response.department_name)
                    localStorage.setItem('company_access', response.company_access)

                    dispatch({ type: 'SET_IS_AUTHENTICATED', payload: true });
                    dispatch({ type: 'SET_COMPANY_ID', payload: apiResponse.company_id });
                    dispatch({ type: 'SET_COMPANY_BRANCH_ID', payload: apiResponse.company_branch_id });
                    dispatch({ type: 'SET_COMPANY_NAME', payload: apiResponse.company_name });
                    dispatch({ type: 'SET_COMPANY_BRANCH_NAME', payload: apiResponse.company_branch_name });
                    dispatch({ type: 'SET_COMPANY_ADDRESS', payload: apiResponse.company_branch_address });
                    dispatch({ type: 'SET_BRANCH_SHORT_NAME', payload: apiResponse.branch_short_name });
                    dispatch({ type: 'SET_UserId', payload: apiResponse.user_id });
                    dispatch({ type: 'SET_USER_CODE', payload: apiResponse.user_code });
                    dispatch({ type: 'SET_UserName', payload: apiResponse.username });
                    dispatch({ type: 'SET_USER', payload: apiResponse.user });
                    dispatch({ type: 'SET_COMPANY_BRANCH_TYPE', payload: apiResponse.company_branch_type });
                    dispatch({ type: 'SET_USER_ACCESS', payload: response.userAccessDetails });
                    dispatch({ type: 'SET_PAGE_ROUTES', payload: response.pageRoutes });
                    dispatch({ type: 'SET_LISTING_FILTERPREFERENCEDETAILS', payload: response.listingFilterPreferenceDetails });
                    dispatch({ type: 'SET_DEPARTMENT_ID', payload: response.department_id });
                    dispatch({ type: 'SET_DEPARTMENT_NAME', payload: response.department_name });
                    dispatch({ type: 'SET_COMPANY_ACCESS', payload: response.company_access });

                    // await checkComRMCatCount();
                    navigate("/DashBoard")

                } else {
                    alert('Please check the login info.');
                    localStorage.setItem('isAuthenticated', false);
                }

            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('loginId');
    }

    return (
        <>
            <FrmValidations ref={validate} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}
            {!IS_AUTHENTICATED ?
                <BasicLayout image={pashupatiGroup}>
                    {
                        loading ? (<>
                            <img className="card mt-2 mb-5 border-0" src={pashupatiGroup} alt="Animated GIF" style={{ width: '500px', height: 'auto', backgroundColor: '#782156', }} />
                        </>) : (<>
                            {/* <img className="card mt-2 mb-5 border-0" src={pashupatiGroup} alt="Animated GIF" style={{ width: '500px', height: '100px' }} /> */}

                            <Card style={{ width: '500px' }}>
                                <MDBox
                                    variant="gradient"
                                    bgColor="#ffff"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    mx={2}
                                    mt={-3}
                                    p={1}
                                    mb={1}
                                    textAlign="center"
                                    style={{
                                        boxShadow: '0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgba(131, 22, 87, 1)'
                                    }}
                                >
                                    <MDTypography variant="h4" fontWeight="medium" style={{ color: "#831657" }} mt={1}>
                                        Sign in
                                    </MDTypography>
                                </MDBox>

                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form" id="loginId">
                                        {/* <MDGrid container spacing={1}> */}

                                        {/* <MDGrid item xs={6}> */}
                                        <MDBox mb={1}>
                                            <MDInput
                                                type="UserName"
                                                id="usernameId"
                                                label="User Name"
                                                value={username}
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                    validateFields();
                                                }}
                                                style={{
                                                    '& label.Mui-focused': {
                                                        color: '#831657', // Change this to the color you want
                                                    },
                                                }}
                                                fullWidth
                                            />
                                            <MDTypography
                                                variant="button"
                                                id="error_usernameId"
                                                className="erp_validation"
                                                fontWeight="regular"
                                                color="error"
                                                style={{ display: "none" }}
                                            />
                                        </MDBox>

                                        <MDBox mb={0}>
                                            <MDBox mb={2}>
                                                <MDInput
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="passwordId"
                                                    label="Password"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                        validateFields();
                                                    }}
                                                    fullWidth
                                                />
                                            </MDBox>

                                            <MDTypography
                                                variant="button"
                                                id="error_passwordId"
                                                className="erp_validation"
                                                fontWeight="regular"
                                                color="error"
                                                style={{ display: "none" }}
                                            />
                                        </MDBox>

                                        <MDBox mt={0} mb={0}>
                                            <MDButton
                                                className='sign-in-btn'
                                                type="button"
                                                variant="gradient"
                                                onClick={FnLogin}
                                                style={{
                                                    backgroundColor: '#831657',
                                                    backgroundImage: 'unset'

                                                }}
                                                color="info"
                                                size="large"
                                                fullWidth
                                                onMouseOver={(e) => { e.target.style.backgroundColor = '#d5278f'; }}
                                                onMouseOut={(e) => { e.target.style.backgroundColor = '#831657'; }}
                                                onFocus={(e) => { e.target.style.backgroundColor = '#d5278f'; }}
                                                onBlur={(e) => { e.target.style.backgroundColor = ''; }}
                                            >
                                                Login
                                            </MDButton>

                                        </MDBox>

                                        {/* <MDBox mt={1} textAlign="center">
                                                <MDTypography variant="button" color="text" to="/authentication/New Customer/cover">
                                                    <a href=""> New Customer</a> /<a href="">  Supplier Registration? {" "}</a>

                                                    <MDTypography
                                                        component={Link}
                                                        to="/Reset"
                                                        variant="button"
                                                        color="info"
                                                        fontWeight="medium"
                                                        textGradient
                                                    >
                                                        Forgot Password
                                                    </MDTypography>
                                                </MDTypography>
                                            </MDBox> */}

                                        {/* </MDGrid> */}
                                    </MDBox>
                                </MDBox>


                            </Card>


                            <img className="card mt-3" src={pasupati_banner} alt="Animated GIF" style={{ width: '500px', height: 'auto' }} />


                        </>)
                    }


                </BasicLayout>
                : null}
        </>
    );
}

export default Login;
