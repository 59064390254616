import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom';
import $, { data } from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from 'react-router-dom';

import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { Form, Table } from 'react-bootstrap';
import Tooltip from "@mui/material/Tooltip";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import FrmValidations from 'FrmGeneric/FrmValidations';
import GenerateTAutoNo from 'FrmGeneric/GenerateTAutoNo';
import { CircularProgress } from '@material-ui/core';
import { RxCrossCircled } from "react-icons/rx";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { Modal } from 'react-bootstrap';


function FrmProductionEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, SHORT_COMPANY, UserName, FINANCIAL_SHORT_NAME } = configConstants;


    const { state } = useLocation();
    const { productionRFId = 0, keyForViewUpdate = 'Add', compType } = state || {};

    const [action_Label, setActionLabel] = useState('(Add)');

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    ////useRef Hooks
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const navigate = useNavigate();

    //Shift wise Ring Frame Prduction hooks
    const [dt_spinning_production_date, setProdPlanDate] = useState(today);
    const [txt_prod_month, setProdMonth] = useState();
    const [txt_prod_year, setProdYear] = useState();
    const [cmb_plant_id, setProdPlantName] = useState('');
    const [txt_prod_rf_code, setProdRfCode] = useState();
    const [cmb_section_id, setProdSection] = useState();
    const [cmb_sub_section_id, setProdSubSection] = useState();
    const [cmb_shift, setShift] = useState();
    const [txt_target_efficiency, setProdTargetEff] = useState();
    const [txt_no_of_machine, setProdNoOfMachine] = useState();
    const [cmb_spinn_plan_code, setSpinPlanCode] = useState();
    const [cmb_prod_supervisor, setProdSupervisor] = useState('');
    const [prodRfMasterId, setprodRfMasterId] = useState(productionRFId);


    //Shift wise Ring Frame Prduction comboboxes
    const [plantOptions, setPlantOptions] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [spinPlanCodeOptions, setSpinPlanCodeOptions] = useState([]);


    ///Shiftwise Machine Stoppage Entry(Ring Frame) Hooks
    const [cmb_machine_id, setMachineNo] = useState();
    const [cmb_stoppage_reason, setMachineStoppageReason] = useState();
    const [txt_stoppage_time, setMachineStoppageTime] = useState();
    const [txt_std_prod_loss, setStdProdLoss] = useState();
    const [txt_act_prod_loss, setActProdLoss] = useState();
    const [labelForStoppage, setLabelForStoppage] = useState('Add');
    const [prevdataindexforStoppage, setPrevDataIndexForStoppage] = useState();

    ///Shiftwise Machine Stoppage Entry(Ring Frame) Comboboxes
    const [machineOptions, setProdMachineOptions] = useState([]);
    const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
    const [stoppageDetails, setStoppageDetails] = useState([]);


    ///Shiftwise Machine Wastage Entry(Ring Frame) Hooks
    const [cmb_prod_wastage_type, setProdWastageType] = useState();
    const [txt_act_wastages, setActWastage] = useState();
    const [txt_act_wastage_percent, setActWastagePercent] = useState();
    const [txt_std_wastages, setStdWastages] = useState();
    const [txt_std_wastage_percent, setStdWastagePercent] = useState();
    const [labelForWastage, setLabelForWastage] = useState('Add');
    const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();

    ///Shiftwise Machine Wastage Entry(Ring Frame) Combobox
    const [prodWastageOptions, setProdWastageOptions] = useState([]);
    const [wastageDetails, setWastageDetails] = useState([]);

    ///Production summary Hooks
    const [prodSummaryData, setProductionSummaryData] = useState([]);
    const [upToDateShiftDetails, setUpToDateShiftDetails] = useState();
    const [shiftNames, setShiftNames] = useState([]);
    const [prod_Rf_SummaryId, setProdRfSummaryId] = useState(0);

    //loader
    const [isLoading, setIsLoading] = useState(false);

    ///////Ring Frame Production Shift Details
    const [machineNumData, setMachineNumData] = useState([]);
    const [spinningProdCountOptions, setSpinngProdCountOptions] = useState([]);
    const [ringFrameProdShiftData, setRingFrameProdShiftData] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate('/Transactions/TProduction/FrmProductionListing');
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    ////For Delete
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        navigate('/Transactions/TProduction/FrmProductionListing')
    }


    useEffect(async () => {
        setIsLoading(true)
        await FnFillComboBox();
        setMonthYear();
        setIsLoading(false)
    }, [])


    const FnFillComboBox = async () => {
        $('.txt_target_efficiency').hide();
        $('.txt_no_of_machine').hide();
        try {
            //Plant Options
            comboDataAPiCall.current.fillMasterData("cmv_plant", "", "").then((getProdPlantApiCall) => {
                setPlantOptions(getProdPlantApiCall);
                console.log(getProdPlantApiCall)
            })

            //Setting Production Section Options, sub section field & plant 
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xmv_production_section"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getProdSectionApiCall) => {
                setProdSectionOptions(getProdSectionApiCall);
                getProdSectionApiCall.filter((prod_Sec) => {
                    if (prod_Sec.field_name === 'Ring Frame  (Spinning)') {
                        setProdSection(prod_Sec.field_id);
                        setProdPlantName(prod_Sec.plant_id);
                    }
                })
                comboOnChange('cmb_section_id');
            })

            ///Setting Spin Plan Code Options
            const getProdPlanCodeApiCall = await comboDataAPiCall.current.fillMasterData("xtv_spinning_prod_spinn_plan_summary", "plant_id", cmb_plant_id);
            setSpinPlanCodeOptions(getProdPlanCodeApiCall);
            console.log(getProdPlanCodeApiCall)

            //Setting Shift Options
            const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts');
            setShiftOptions(shiftsApiCall);
            console.log(shiftsApiCall)
            let shift_Names = [];
            shiftsApiCall.map((shift) => {
                shift_Names.push(shift.property_name)
            })
            setShiftNames(shift_Names);

            //Set Employee Options
            comboDataAPiCall.current.fillMasterData(" cmv_employee", "", "").then((getEmployeeApiCall) => {
                setEmployeeOptions(getEmployeeApiCall);
                console.log(getEmployeeApiCall)
            })

            //Setting Machines Options
            resetGlobalQuery();
            globalQuery.columns = ['machine_name', 'machine_id', 'machine_short_name', 'field_id', 'field_name'];
            globalQuery.table = "cmv_machine"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "sub_section_name", operator: "=", value: "Ring Frame (Spinning)" });
            let getProdMachineApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setProdMachineOptions(getProdMachineApiCall);
            console.log(getProdMachineApiCall)

            //Stoppage Reasons
            comboDataAPiCall.current.fillMasterData("xmv_production_stoppage_reasons", "", "").then((getProdStoppageReasonsApiCall) => {
                setProdStoppageReasonOptions(getProdStoppageReasonsApiCall);
            })

            //Wastage Types
            comboDataAPiCall.current.fillMasterData("xmv_production_wastage_types", "", "").then((getProdWastageReasonsApiCall) => {
                setProdWastageOptions(getProdWastageReasonsApiCall);
            })

            //////Ring Frame Production Shift Details
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xmv_spinning_prod_count_levels"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getSpinningProdCountCall) => {
                setSpinngProdCountOptions(getSpinningProdCountCall);
            })

            if (productionRFId !== 0) {
                await FnCheckUpdateResponce(shift_Names);
            }
        } catch {
            navigate('/Error')
        }
    }

    const setMonthYear = async () => {
        let prod_Plan_Dt = $("#dt_spinning_production_date").val();
        setProdPlanDate(prod_Plan_Dt);
        var dateComponents = prod_Plan_Dt.split('-');
        setProdMonth(dateComponents[1]);
        let year = (dateComponents[0]).toString();
        setProdYear(year);
    }

    const FnCheckUpdateResponce = async (namesOfTheShift) => {
        debugger
        try {
            const apiCallForRFProduction = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProductionRfMaster/FnShowParticularRecordUpdate/${prodRfMasterId}/${COMPANY_ID}`);
            const apiResponse = await apiCallForRFProduction.json();

            //Set RF Details
            let rfDetailsData = apiResponse.SpinningProductionRfMasterRecord
            setProdPlanDate(rfDetailsData.spinning_production_date);
            setProdMonth(rfDetailsData.prod_month);
            setProdYear(rfDetailsData.prod_year);
            setProdPlantName(rfDetailsData.plant_id);
            setProdRfCode(rfDetailsData.spinning_production_rf_code);
            setProdSection(rfDetailsData.section_id);
            await comboOnChange('cmb_section_id');
            setProdSubSection(rfDetailsData.sub_section_id);
            await comboOnChange('cmb_sub_section_id');
            setShift(rfDetailsData.shift);
            setProdTargetEff(rfDetailsData.target_efficiency);
            setProdNoOfMachine(rfDetailsData.production_supervisor_id);
            setSpinPlanCode(rfDetailsData.spinn_plan_code);
            setProdSupervisor(rfDetailsData);

            //Set RF Summary Details
            let jsonForSummaryData = apiResponse.SpinningProductionRfSummaryRecord

            let upToShiftDetailsToBeApppended = (keyToUpdateData) => {
                return Object.values(jsonForSummaryData).reduce((acc, currentValue) => {
                    const value = isNaN(currentValue[keyToUpdateData]) ? 0 : parseFloat(currentValue[keyToUpdateData]);
                    return parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(acc), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(value), 4));
                }, 0);
            };
            for (let key = 0; key < namesOfTheShift.length; key++) {
                let shiftData = apiResponse.SpinningProductionRfSummaryRecord[namesOfTheShift[key]];

                let data = {
                    ...shiftData,
                    ///////////////Todays totals/////////
                    total_no_of_machine: upToShiftDetailsToBeApppended('no_of_machine'),
                    total_shift_production_100: upToShiftDetailsToBeApppended('shift_production_100'),
                    total_shift_production_target: upToShiftDetailsToBeApppended('shift_production_target'),
                    total_shift_production_actual: upToShiftDetailsToBeApppended('shift_production_actual'),
                    total_shift_production_loss: upToShiftDetailsToBeApppended('shift_production_loss'),
                    total_shift_stopage_time: upToShiftDetailsToBeApppended('shift_stopage_time'),
                    total_shift_wastage_kgs: upToShiftDetailsToBeApppended('shift_wastage_kgs'),
                    total_shift_spindles_utilization: upToShiftDetailsToBeApppended('shift_spindles_utilization'),

                    ////UpTo Days Totals/////////
                    total_shift_production_upto_date_100: upToShiftDetailsToBeApppended('shift_production_upto_date_100'),
                    total_shift_production_upto_date_target: upToShiftDetailsToBeApppended('shift_production_upto_date_target'),
                    total_shift_production_upto_date_actual: upToShiftDetailsToBeApppended('shift_production_upto_date_actual'),
                    total_shift_production_upto_date_loss: upToShiftDetailsToBeApppended('shift_production_upto_date_loss'),
                    total_shift_stopage_upto_date_time: upToShiftDetailsToBeApppended('shift_stopage_upto_date_time'),
                    total_shift_wastage_upto_date_kgs: upToShiftDetailsToBeApppended('shift_wastage_upto_date_kgs'),
                    total_shift_spindles_upto_date_utilization: upToShiftDetailsToBeApppended('shift_spindles_upto_date_utilization'),
                }
                jsonForSummaryData[namesOfTheShift[key]] = data;
            }
            setProductionSummaryData(jsonForSummaryData);
            //Set RF Master Details
            setRingFrameProdShiftData(apiResponse.SpinningProductionRfDetailsRecord);
            //Set RF Wastage Details
            setWastageDetails(apiResponse.SpinningProductionRfWastageRecord);
            //Set RF Stoppage Details
            setStoppageDetails(apiResponse.SpinningProductionRfStoppageRecord);

            //hide & disabling the forms & actions
            $('.view_hide').hide();
            $('.disable-button').attr('disabled', 'disabled');
            await validate.current.readOnly('productionmasterId');
            $('.rfdetail-disable').attr('disabled', 'disabled');
            await validate.current.readOnly('stoppageFormId');
            await validate.current.readOnly('wastageFormId');

        } catch (error) {
            navigate('/Error');
        }
    }

    const getRingFrameDetails = async (event) => {
        let selected_Sub_Section = $('#cmb_sub_section_id option:selected').val();

        let rfDetails = ringFrameProdShiftData;

        if (rfDetails.length === 0) {
            resetGlobalQuery();
            globalQuery.columns = ['machine_name', 'machine_id', 'machine_short_name', 'machine_working_capacity'];
            globalQuery.table = "cmv_machine"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: selected_Sub_Section });
            let getMachineNosApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setProdNoOfMachine(getMachineNosApiCall.length);

            const prodShiftDataArray = (getMachineNosApiCall.map((machine, index) => {
                return {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    spinning_production_rf_details_id: 0,
                    spinning_production_rf_master_id: '',
                    spinning_production_rf_code: '',
                    spinning_production_date: dt_spinning_production_date,
                    sub_section_id: selected_Sub_Section,
                    shift: $('#cmb_shift option:selected').text(),
                    machine_id: machine.machine_id,
                    machine_short_name: machine.machine_short_name,
                    production_operator_id: 0,
                    production_count_id: 0,
                    target_level: 0,
                    yarn_count: 0,
                    actual_count: 0,
                    speed: 0,
                    tpi: 0,
                    tm: 0,
                    efficiency_target: 0,
                    work_spindles: 0,
                    grams_100: 0,
                    grams_target: 0,
                    grams_actual: 0,
                    kgs_100: 0,
                    kgs_target: 0,
                    kgs_actual: 0,
                    efficiency_percent: 0,
                    prod_loss_with_reason: 0,
                    prod_loss_with_out_reason: 0,
                    ideal_spindle: 0,
                    install_spindles: machine.machine_working_capacity,
                    spindle_utilisation_Per: 0,
                    total_stopage_spindles: 0,
                    waste_percent: 0,
                    production_mixing: 0,
                    production_count_desc: 0,
                    conversion_40s: 0,
                    total_stopage_time: 0,
                    yarn_construction: 0
                };
            }))
            setRingFrameProdShiftData(prodShiftDataArray);
        }
    }


    const FnGetShiftDetails = async (selected_Shift) => {
        debugger
        try {
            setIsLoading(true)
            let namesOfTheShift = [...shiftNames];
            let shiftName = $("#cmb_shift option:selected").text();
            await fillProdRfCode();

            const getShiftApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProductionRfMaster/FnShowParticularShiftSummary/${shiftName}/${dt_spinning_production_date}/${COMPANY_ID}`);
            const respForShiftApiCall = await getShiftApiCall.json();
            let last_ShiftDetails = respForShiftApiCall.LastShiftProductionDetails

            if (respForShiftApiCall.data[shiftName].shift_production_target !== 0) {
                //////Showing Previous Shift Details
                setRingFrameProdShiftData(last_ShiftDetails)
                $('.rfdetail-disable, .disable-button').prop('disabled', true);
                $('#save_Button').prop('disabled', true);
                await validate.current.readOnly('stoppageFormId');
                await validate.current.readOnly('wastageFormId');
                $('#error_cmb_shift').css({ 'display': 'block', 'margin-top': '9px' }).text('You already filled ' + dt_spinning_production_date + " " + shiftName + ' shift data');

            } else {
                ////For Adding new details
                let index_ExcludeLastShiftDetails = [];
                let prevShift_MachineId;
                last_ShiftDetails.map((details, index) => {
                    if (details.machine_id === prevShift_MachineId) {
                        index_ExcludeLastShiftDetails.push(index - 1);
                    }
                    prevShift_MachineId = details.machine_id;
                })

                index_ExcludeLastShiftDetails.forEach(index => {
                    last_ShiftDetails.splice(index, 1);
                });
                if (last_ShiftDetails.length !== 0) {
                    last_ShiftDetails.map((shiftDetails) => {
                        shiftDetails.yarn_count = 0;
                        shiftDetails.actual_count = 0;
                        shiftDetails.work_spindles = 0;
                        shiftDetails.spindle_utilisation_Per = 0;
                        shiftDetails.ideal_spindle = 0;
                        shiftDetails.total_stopage_spindles = 0;
                        shiftDetails.kgs_100 = 0;
                        shiftDetails.kgs_target = 0;
                        shiftDetails.grams_actual = 0;
                        shiftDetails.kgs_actual = 0;
                        shiftDetails.total_stopage_time = 0;
                        shiftDetails.prod_loss_with_out_reason = 0;
                        shiftDetails.prod_loss_with_reason = 0;
                        shiftDetails.efficiency_percent = 0;
                        shiftDetails.yarn_construction = 0;
                        shiftDetails.production_count_id = 0;
                        shiftDetails.efficiency_target = 0;
                        shiftDetails.speed = 0;
                        shiftDetails.tpi = 0;
                        shiftDetails.tm = 0;
                        shiftDetails.conversion_40s = 0;
                        shiftDetails.production_mixing = 0;
                        shiftDetails.production_count_desc = 0;

                    })
                    setRingFrameProdShiftData(last_ShiftDetails);
                }
                $('.rfdetail-disable, .disable-button').prop('disabled', false);
                $('#save_Button').prop('disabled', false);
                await validate.current.removeReadOnlyAttr('stoppageFormId');
                await validate.current.removeReadOnlyAttr('wastageFormId');
            }

            let productSummaryData = {};
            productSummaryData = respForShiftApiCall.data;
            let upToDateShift_Details = {};
            namesOfTheShift.map((shift) => {
                if (productSummaryData[shift] && productSummaryData[shift].hasOwnProperty('shift_production_upto_date_100')) {
                    upToDateShift_Details[shift] = {
                        shift_production_upto_date_100: productSummaryData[shift].shift_production_upto_date_100,
                        shift_production_upto_date_loss: productSummaryData[shift].shift_production_upto_date_loss,
                        shift_production_upto_date_target: productSummaryData[shift].shift_production_upto_date_target,
                        shift_production_upto_date_actual: productSummaryData[shift].shift_production_upto_date_actual,
                        shift_spindles_upto_date_utilization: productSummaryData[shift].shift_spindles_upto_date_utilization,
                        shift_stopage_upto_date_time: productSummaryData[shift].shift_stopage_upto_date_time,
                        shift_wastage_upto_date_kgs: productSummaryData[shift].shift_wastage_upto_date_kgs
                    };
                } else {
                    upToDateShift_Details[shift] = {};
                }
            })
            setProductionSummaryData(productSummaryData);
            setUpToDateShiftDetails(upToDateShift_Details);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    const fillProdRfCode = async () => {
        try {
            const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("xt_spinning_production_rf_master", "spinning_production_rf_code",
                "", "RF", "4");
            let plantName = $('#cmb_plant_id option:selected').text();
            let shiftName = $('#cmb_shift option:selected').text();
            let prod_Plan_Dt = $("#dt_spinning_production_date").val();
            var dateComponents = prod_Plan_Dt.split('-');
            var splitAPICall = autoNoApiCall.split('/')

            let prodRfCode = splitAPICall[0] + '/' + splitAPICall[1] + '/' + plantName + '/' + dateComponents[2] + dateComponents[1] + dateComponents[0] + '/' + shiftName + '/' + splitAPICall[2];
            setProdRfCode(prodRfCode);
        } catch (error) {

        }
    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'cmb_section_id':
                const prod_section_Id = $('#cmb_section_id').val();
                const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_id", prod_section_Id);
                setProdSubSectionOptions(getProdSubSectionApiCall);
                // setProdSubSection(getProdSubSectionApiCall[0].field_id);
                break;
            case 'cmb_plant_id':
                await fillProdRfCode();
                break;
            case 'shift':
                let shiftId = $('#cmb_shift option:selected').val();
                await FnGetShiftDetails(shiftId);
                break;
        }
    }

    const clearFormFields = (form_type) => {
        switch (form_type) {
            case 'stoppage':
                setMachineNo('')
                setMachineStoppageReason('')
                setMachineStoppageTime('')
                setStdProdLoss('')
                setActProdLoss('')
                setLabelForStoppage('Add');
                break;
            case 'wastage':
                setProdWastageType('');
                setActWastage('');
                setActWastagePercent('');
                setStdWastages('');
                setStdWastagePercent('');
                setLabelForWastage('Add');
                break;
        }
    }

    /////////////////////////////////////////////////////Machine Stoppage Details////////////////////////////////////////////////////////////////
    const FnUpdateStoppageReason = async (row_data, index_Of_Row, action) => {

        if (action === 'update') {
            setLabelForStoppage('Update');
        }
        const machineStoppageDetails = [...stoppageDetails];
        let ringFrameDetails = [...ringFrameProdShiftData];
        let checkActProdLoss = true;
        switch (action) {
            case 'add':
                const validateStoppageReason = await validate.current.validateForm('stoppageFormId');

                if (validateStoppageReason) {
                    const isDuplicate = machineStoppageDetails.some(stoppageDetails =>
                        stoppageDetails.machine_id === cmb_machine_id &&
                        stoppageDetails.production_stoppage_reasons_id === cmb_stoppage_reason &&
                        stoppageDetails.stoppage_time === txt_stoppage_time
                    );

                    if (isDuplicate) {
                        $('#error_cmb_stoppage_reason').text('Stoppage Reason already exists...').css({ 'display': 'block', 'margin-top': '8px' });
                    } else {
                        $('#error_cmb_stoppage_reason').hide();

                        ///To Update Row
                        const rowNeedToBeUpdated = machineStoppageDetails.find((stoppage_Detail, index) => (
                            prevdataindexforStoppage === index
                        ));

                        if (rowNeedToBeUpdated) {
                            rowNeedToBeUpdated.machine_id = cmb_machine_id;
                            rowNeedToBeUpdated.machine_name = $('#cmb_machine_id option:selected').text();
                            rowNeedToBeUpdated.production_stoppage_reasons_id = cmb_stoppage_reason;
                            rowNeedToBeUpdated.production_stoppage_reasons_name = $('#cmb_stoppage_reason option:selected').text();
                            rowNeedToBeUpdated.stoppage_time = txt_stoppage_time;
                            rowNeedToBeUpdated.std_stoppage_loss_kg = txt_std_prod_loss;
                            rowNeedToBeUpdated.actual_production_loss_kg = txt_act_prod_loss;
                            setPrevDataIndexForStoppage('');
                        }

                        if (!rowNeedToBeUpdated) {
                            const newStoppageDetail = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                financial_year: FINANCIAL_SHORT_NAME,
                                spinning_production_rf_stoppage_id: 0,
                                spinning_production_rf_master_id: 0,
                                spinning_production_rf_code: txt_prod_rf_code,
                                spinning_production_date: dt_spinning_production_date,
                                prod_month: txt_prod_month,
                                prod_year: txt_prod_year,
                                plant_id: cmb_plant_id,
                                spinn_plan_code: $('#cmb_spinn_plan_code option:selected').text(),
                                section_id: cmb_section_id,
                                sub_section_id: cmb_sub_section_id,
                                shift: $('#cmb_shift option:selected').text(),
                                machine_id: cmb_machine_id,
                                machine_name: $('#cmb_machine_id option:selected').text(),
                                production_stoppage_reasons_id: cmb_stoppage_reason,
                                production_stoppage_reasons_name: $('#cmb_stoppage_reason option:selected').text(),
                                stoppage_time: txt_stoppage_time,
                                std_stoppage_loss_kg: txt_std_prod_loss,
                                actual_production_loss_kg: txt_act_prod_loss,
                            };
                            machineStoppageDetails.push(newStoppageDetail);
                        }

                        const ringFrameIdMatchedArray = machineStoppageDetails.filter(frameDetails => frameDetails.machine_id === cmb_machine_id);
                        let prodRingFrameMatchedId = ringFrameDetails.find(frame_Details => {
                            return frame_Details.machine_id === parseInt(cmb_machine_id)
                        });
                        const total_StoppageTime = ringFrameIdMatchedArray.reduce((total, ring_FrameId) =>
                            total + parseInt(ring_FrameId.stoppage_time), 0);

                        const total_ActProdLoss = ringFrameIdMatchedArray.reduce((total, ring_FrameId) =>
                            total + (parseFloat(ring_FrameId.actual_production_loss_kg) || 0), 0);

                        let ringFrameMatchedTotLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(prodRingFrameMatchedId.kgs_target), 4) - validateNumberDateInput.current.decimalNumber(JSON.stringify(prodRingFrameMatchedId.kgs_actual), 4);
                        if (parseFloat(ringFrameMatchedTotLoss) < parseFloat(total_ActProdLoss)) {
                            $('#error_txt_act_prod_loss').text('Act. Prod. loss cannot be greater than Loss(Kg)(Without Reason)').css({ 'display': 'block', 'margin-top': '10px' });
                            checkActProdLoss = false;
                        } else {
                            $('#error_txt_act_prod_loss').hide();
                            const matchedMachineNo = ringFrameIdMatchedArray[0]?.machine_id;
                            const parsedMachineNo = parseInt(matchedMachineNo);

                            if (!isNaN(parsedMachineNo)) {
                                const findMachineId = ringFrameDetails.find(frameDetails => frameDetails.machine_id === parsedMachineNo);

                                if (findMachineId) {
                                    findMachineId.total_stopage_time = String(total_StoppageTime);
                                    findMachineId.prod_loss_with_reason = validateNumberDateInput.current.decimalNumber(JSON.stringify(total_ActProdLoss), 4);
                                    let prod_loss_without_reason = (findMachineId.kgs_target - findMachineId.kgs_actual) - total_ActProdLoss;
                                    findMachineId.prod_loss_with_out_reason =
                                        validateNumberDateInput.current.decimalNumber(JSON.stringify(prod_loss_without_reason), 4);
                                }
                            }
                            setStoppageDetails(machineStoppageDetails);
                            clearFormFields('stoppage');
                        }
                    }
                    setLabelForStoppage('Add');
                }
                break;
            case 'update':
                setMachineNo(row_data.machine_id)
                setMachineStoppageReason(row_data.production_stoppage_reasons_id)
                setMachineStoppageTime(row_data.stoppage_time)
                setStdProdLoss(row_data.std_stoppage_loss_kg)
                setActProdLoss(row_data.actual_production_loss_kg)
                setPrevDataIndexForStoppage(index_Of_Row);
                break;

            case 'remove':
                const findIdtoBeRemove = machineStoppageDetails.find(stoppageDetails => (
                    stoppageDetails.machine_id === row_data.machine_id && stoppageDetails.production_stoppage_reasons_id === row_data.production_stoppage_reasons_id && stoppageDetails.stoppage_time === row_data.stoppage_time
                ));

                if (findIdtoBeRemove) {
                    const ringFrameIdMatchedArray = ringFrameDetails.find(frameDetails => (frameDetails.machine_id === parseInt(findIdtoBeRemove.machine_id)));
                    const total_StoppageTime = (parseFloat(ringFrameIdMatchedArray.total_stopage_time) - parseFloat(findIdtoBeRemove.stoppage_time));
                    const total_ActProdLoss = (parseFloat(ringFrameIdMatchedArray.prod_loss_with_reason) - parseFloat(findIdtoBeRemove.actual_production_loss_kg));

                    const parsedMachineNo = parseInt(findIdtoBeRemove.machine_id);
                    if (!isNaN(parsedMachineNo)) {
                        const findMachineId = ringFrameDetails.find(frameDetails => (frameDetails.machine_id === parsedMachineNo));

                        if (findMachineId) {
                            findMachineId.total_stopage_time = String(total_StoppageTime);
                            findMachineId.prod_loss_with_reason = validateNumberDateInput.current.decimalNumber(JSON.stringify(total_ActProdLoss), 4);
                            let prod_loss_without_reason = (findMachineId.kgs_target - findMachineId.kgs_actual) - validateNumberDateInput.current.decimalNumber(JSON.stringify(total_ActProdLoss), 4);
                            findMachineId.prod_loss_with_out_reason =
                                validateNumberDateInput.current.decimalNumber(JSON.stringify(prod_loss_without_reason), 4);
                        }
                    }
                    const updatedMachineStoppageDetails = machineStoppageDetails.filter(stoppageDetails => (
                        !(stoppageDetails.machine_id === row_data.machine_id &&
                            stoppageDetails.production_stoppage_reasons_id === row_data.production_stoppage_reasons_id &&
                            stoppageDetails.stoppage_time === row_data.stoppage_time)
                    ));
                    setStoppageDetails(updatedMachineStoppageDetails);
                }
                clearFormFields('stoppage');
                break;
        }


        ///Calculating Total Summary Data for Shiftwise & Setting in summary Details table
        if (checkActProdLoss === true) {
            let production_Loss = ringFrameDetails.reduce((loss, ring_FrameId) =>
                loss + parseFloat(ring_FrameId.prod_loss_with_out_reason) + parseFloat(ring_FrameId.prod_loss_with_reason), 0);

            let stoppage_Time = ringFrameDetails.reduce((time, ring_FrameId) => {
                return time + parseFloat(ring_FrameId.total_stopage_time);
            }, 0);


            let selectedShift = $('#cmb_shift option:selected').val();
            if (selectedShift === "") {
                $('#cmb_shift').on('focus', function () {
                    $('#error_cmb_shift').text('Select the shift').css({ 'display': 'block', 'margin-top': '6px' });
                });
            } else {
                const summaryData = prodSummaryData;

                let selectedShift = $('#cmb_shift option:selected').text();

                setProductionSummaryData((prevData) => {
                    const updateData = { ...prevData };
                    for (const key of Object.keys(updateData)) {
                        let uptodateShiftDetails = upToDateShiftDetails;
                        if (key === selectedShift) {
                            let shiftUpToDateDetail = uptodateShiftDetails[selectedShift];
                            let upToShiftDetailsToBeApppended = (keyToUpdateData) => {
                                return Object.values(summaryData).reduce((acc, currentValue) => {
                                    const value = isNaN(currentValue[keyToUpdateData]) ? 0 : parseFloat(currentValue[keyToUpdateData]);
                                    return acc + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(value), 4));
                                }, 0);
                            };
                            /////Calculating Todays Summary & Details
                            updateData[selectedShift].shift_production_loss = parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(production_Loss), 4));
                            updateData[selectedShift].shift_stopage_time = stoppage_Time;
                            updateData[selectedShift].total_shift_production_loss = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_loss')), 4);
                            updateData[selectedShift].total_shift_stopage_time = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_stopage_time')), 4);

                            /////Calculating UpTo Day's Summary Details & its Total's
                            updateData[selectedShift].shift_production_upto_date_loss = isNaN(shiftUpToDateDetail.shift_production_upto_date_loss) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_production_upto_date_loss), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_production_loss), 4));
                            updateData[selectedShift].shift_stopage_upto_date_time = isNaN(shiftUpToDateDetail.shift_stopage_upto_date_time) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_stopage_upto_date_time), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_stopage_time), 4));
                            updateData[selectedShift].total_shift_production_upto_date_loss = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_upto_date_loss')), 4);
                            updateData[selectedShift].total_shift_stopage_upto_date_time = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_stopage_upto_date_time')), 4);
                            return updateData;
                        }
                    }
                });
            }
            setRingFrameProdShiftData(ringFrameDetails);
        }
    }

    const validateFields = (formname) => {
        switch (formname) {
            case 'Stoppage Form':
                validate.current.validateFieldsOnChange('stoppageFormId');
                break;
            case 'Wastage Form':
                validate.current.validateFieldsOnChange('wastageFormId');
                break;
            case 'Production Master':
                validate.current.validateFieldsOnChange('productionmasterId');
                break;
        }
    }

    const calculateProdLoss = async (event) => {
        let prod_shift_Details = [...ringFrameProdShiftData];
        let machineId = cmb_machine_id;
        let tot_Stoppage_Time = event.target.value;
        let noOfShifts = shiftOptions.length;

        const req_Id = prod_shift_Details.find((shiftDetails) => shiftDetails.machine_id === parseInt(machineId));

        if (req_Id) {
            if (req_Id.kgs_target === 0 || req_Id.kgs_target === "") {
                $('#error_txt_stoppage_time').text("Please fill the details of " + req_Id.machine_id + " Machine Number").css({ 'display': 'block', 'margin-top': '7px' });
            } else {
                let stdProdCountLossPerMinute = req_Id.kgs_target / ((24 / parseInt(noOfShifts)) * 60);
                let stdProdLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(stdProdCountLossPerMinute * tot_Stoppage_Time), 4);
                setStdProdLoss(stdProdLoss);

                let actProdCountLossPerMinute = req_Id.kgs_actual / ((24 / parseInt(noOfShifts)) * 60);
                let actProdLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(actProdCountLossPerMinute * tot_Stoppage_Time), 4);
                setActProdLoss(actProdLoss);
            }
        }
    };

    const renderMachineStoppageTable = useMemo(() => {
        return <Table id="machinestoppagetableId" style={{ display: "block", overflow: "auto" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className="erp_table_th view_hide" style={{}}>Action</td>
                    <td className="erp_table_th" style={{ width: "150px", }}>Machine No</td>
                    <td className="erp_table_th" style={{ width: "300px", }}>Stoppage Reason</td>
                    <td className="erp_table_th" style={{ width: "100px", }}>Stoppage Time</td>
                    <td className="erp_table_th" style={{ width: "100px", }}>Std. Prod. Loss</td>
                    <td className="erp_table_th" style={{ width: "100px", }}>Act. Prod. Loss</td>
                </tr>
            </thead>
            <tbody>
                {stoppageDetails?.map((details, indexOfItem) => (
                    <tr rowindex={indexOfItem}>
                        <td className="erp_table_td view_hide">
                            <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateStoppageReason(details, indexOfItem, 'update')} />
                            <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateStoppageReason(details, indexOfItem, 'remove')} />
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.machine_name}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.production_stoppage_reasons_name}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.stoppage_time}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.std_stoppage_loss_kg}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.actual_production_loss_kg}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

    }, [stoppageDetails]);

    /////////////////////////////////////////////////////////////Machine Wastage Details///////////////////////////////////////////////////////////
    const FnUpdateWastageReason = async (rowData, rowIndex, action) => {
        if (action === 'update') {
            setLabelForWastage('Update');
        }
        const machineWastageDetails = [...wastageDetails];
        let ringFrameDetails = [...ringFrameProdShiftData];
        switch (action) {
            case 'add':
                const validateWastageReason = await validate.current.validateForm('wastageFormId');
                if (validateWastageReason === true) {
                    const findIdtoAdd = machineWastageDetails.some((wastage_Details) => (
                        wastage_Details.production_wastage_types_id === cmb_prod_wastage_type && wastage_Details.actual_wastage_production_kg === txt_act_wastages
                    ));
                    if (findIdtoAdd) {
                        $('#error_cmb_prod_wastage_type').text('Wastage Reason already exists...').css({ 'display': 'block', 'margin-top': '8px' });
                    } else {
                        $('#error_cmb_prod_wastage_type').hide();

                        ///To Update Row
                        const rowNeedToBeUpdated = machineWastageDetails.find((stoppage_Detail, index) => (
                            prevdataindexforWastage === index
                        ));

                        if (rowNeedToBeUpdated) {
                            rowNeedToBeUpdated.production_wastage_types_id = cmb_prod_wastage_type;
                            rowNeedToBeUpdated.production_wastage_types_type = $('#cmb_prod_wastage_type option:selected').text();
                            rowNeedToBeUpdated.actual_wastage_production_kg = txt_act_wastages;
                            rowNeedToBeUpdated.actual_wastage_production_percent = txt_act_wastage_percent;
                            rowNeedToBeUpdated.std_wastage_kg = txt_std_wastages;
                            rowNeedToBeUpdated.std_wastage_percent = txt_std_wastage_percent;
                            setPrevDataIndexForWastage('');
                        }

                        if (!rowNeedToBeUpdated) {
                            const newWastageDetail = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                financial_year: FINANCIAL_SHORT_NAME,
                                spinning_production_rf_wastage_id: 0,
                                spinning_production_rf_master_id: 0,
                                spinning_production_rf_code: txt_prod_rf_code,
                                spinning_production_date: dt_spinning_production_date,
                                prod_month: txt_prod_month,
                                prod_year: txt_prod_year,
                                plant_id: cmb_plant_id,
                                spinn_plan_code: $('#cmb_spinn_plan_code option:selected').text(),
                                section_id: cmb_section_id,
                                sub_section_id: cmb_sub_section_id,
                                shift: $('#cmb_shift option:selected').text(),
                                production_wastage_types_id: cmb_prod_wastage_type,
                                production_wastage_types_type: $('#cmb_prod_wastage_type option:selected').text(),
                                actual_wastage_production_kg: txt_act_wastages,
                                actual_wastage_production_percent: txt_act_wastage_percent,
                                std_wastage_kg: txt_std_wastages,
                                std_wastage_percent: txt_std_wastage_percent,
                            };
                            machineWastageDetails.push(newWastageDetail);
                        }
                        clearFormFields('wastage');
                        setLabelForWastage('Add')
                    }
                }

                break;
            case 'update':
                setProdWastageType(rowData.production_wastage_types_id);
                setActWastage(rowData.actual_wastage_production_kg);
                setActWastagePercent(rowData.actual_wastage_production_percent);
                setStdWastages(rowData.std_wastage_kg);
                setStdWastagePercent(rowData.std_wastage_percent);
                setPrevDataIndexForWastage(rowIndex);
                break;
            case 'remove':
                if (rowIndex !== -1) {
                    machineWastageDetails.splice(rowIndex, 1);
                }
                clearFormFields('wastage');
                break;
        }
        setWastageDetails(machineWastageDetails);

        ///Calculations for Summary Details
        let shift_Wastage = machineWastageDetails.reduce((time, machineWastage) =>
            time + parseFloat(machineWastage.actual_wastage_production_kg), 0);

        let selected_Shift = $('#cmb_shift option:selected').val();
        if (selected_Shift === "") {
            $('#cmb_shift').on('focus', function () {
                $('#error_cmb_shift').text('Select the shift').css({ 'display': 'block', 'margin-top': '6px' });
            });
        } else {

            let selectedShift = $('#cmb_shift option:selected').text();
            const summaryData = prodSummaryData;

            setProductionSummaryData((prevData) => {
                const updateData = { ...prevData };
                for (const key of Object.keys(updateData)) {
                    let uptodateShiftDetails = upToDateShiftDetails;
                    if (key === selectedShift) {
                        let shiftUpToDateDetail = uptodateShiftDetails[selectedShift];
                        let upToShiftDetailsToBeApppended = (keyToUpdateData) => {
                            return Object.values(summaryData).reduce((acc, currentValue) => {
                                const value = isNaN(currentValue[keyToUpdateData]) ? 0 : parseFloat(currentValue[keyToUpdateData]);
                                return acc + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(value), 4));
                            }, 0);
                        };

                        //Calculating Todays Summary Details
                        updateData[selectedShift].shift_wastage_kgs = parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shift_Wastage), 4));
                        updateData[selectedShift].total_shift_wastage_kgs = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_wastage_kgs')), 4);

                        //Calculating UpTo Day's Summary Details
                        updateData[selectedShift].shift_wastage_upto_date_kgs = isNaN(shiftUpToDateDetail.shift_wastage_upto_date_kgs) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_wastage_upto_date_kgs), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_wastage_kgs), 4));
                        updateData[selectedShift].total_shift_wastage_upto_date_kgs = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_wastage_upto_date_kgs')), 4);

                        return updateData;
                    }
                }
            });
        }
    }

    const calculateMachineWastage = async (value) => {

        const wastage_Type = $('#cmb_prod_wastage_type option:selected').val();
        setProdWastageType(wastage_Type);

        resetGlobalQuery();
        globalQuery.columns = ['std_wastage_percent'];
        globalQuery.table = "xmv_production_wastage_types"
        globalQuery.conditions = [
            { field: "is_delete", operator: "=", value: 0 },
            { field: "production_wastage_types_id", operator: "=", value: wastage_Type }
        ];

        const getSpinningProdCountCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setStdWastagePercent(getSpinningProdCountCall[0]?.std_wastage_percent);

        setActWastage(value);
        validateFields('Wastage Form');

        const total_ActualKg = ringFrameProdShiftData.reduce((act_Kg, { kgs_actual }) => {
            return act_Kg + parseFloat(kgs_actual);
        }, 0);

        if (!(total_ActualKg > 0)) {
            $('#error_txt_act_wastages').text('Please fill the Ring Frame Production Details').css({ 'display': 'block', 'margin-top': '8px' });
            return;
        }

        const actWastagesVal = $('#txt_act_wastages').val();
        const act_WastagePer = validateNumberDateInput.current.decimalNumber(JSON.stringify((actWastagesVal / total_ActualKg) * 100), 4);
        setActWastagePercent(act_WastagePer);

        const stdWastagePerVal = $('#txt_std_wastage_percent').val();
        const std_WasatgeKg = validateNumberDateInput.current.decimalNumber(JSON.stringify((stdWastagePerVal / 100) * total_ActualKg), 4);
        setStdWastages(std_WasatgeKg);
    };


    const renderMachineWastageTable = useMemo(() => {
        return <Table id="machinewastagetableId" style={{ display: "block", width: '600px', overflow: "auto" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className="erp_table_th view_hide" style={{}}>Action</td>
                    <td className="erp_table_th " style={{ width: "150px" }}>Wastage Type</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Act. Wastage(Kg)</td>
                    <td className="erp_table_th " style={{ width: "100px" }}>Act. Wastage(%)</td>
                    <td className="erp_table_th " style={{ width: "100px" }}>Std. Wastage(Kg)</td>
                    <td className="erp_table_th " style={{ width: "100px" }}>Std. Wastage(%)</td>
                </tr>

            </thead>
            <tbody>
                {wastageDetails?.map((details, indexOfItem) => (
                    <tr>
                        <td className="erp_table_td view_hide">
                            <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'update')} />
                            <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'remove')} />
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.production_wastage_types_type}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.actual_wastage_production_kg}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.actual_wastage_production_percent}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.std_wastage_kg}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.std_wastage_percent}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

    }, [wastageDetails]);


    ////////////////////////////Production Shift Functionality Starts here///////////////////////////////
    const EditMachineDetails = (action, row_Data, index) => {
        const ring_Frame_Details = [...ringFrameProdShiftData];
        try {
            setIsLoading(true);

            switch (action) {
                case 'create':
                    if (row_Data && Object.keys(row_Data).length !== 0) {
                        const modifiedRowData = { ...row_Data };
                        const numericFields = [
                            "actual_count", "yarn_count", "production_count_id", "efficiency_target",
                            "efficiency_percent", "speed", "tpi", "tm", "yarn_construction",
                            "conversion_40s", "production_mixing", "production_count_desc",
                            "grams_100", "kgs_100", "grams_target", "kgs_target", "grams_actual",
                            "kgs_actual", "ideal_spindle", "prod_loss_with_out_reason",
                            "prod_loss_with_reason", "total_stopage_spindles", "total_stopage_time",
                            "spindle_utilisation_Per", "work_spindles"
                        ];
                        numericFields.forEach(field => {
                            modifiedRowData[field] = 0;
                        });

                        ring_Frame_Details.splice(index + 1, 0, modifiedRowData);
                    }
                    break;
                default:
                    break;
            }

            setRingFrameProdShiftData(ring_Frame_Details);
        } catch (error) {
            // Handle error
        } finally {
            setIsLoading(false);
        }
    }


    const renderProductionShiftDetails = useMemo(() => {
        debugger
        return <Table id="productionshifttableId" style={{ display: "block", overflowY: "auto", height: ringFrameProdShiftData.length <= 6 ? 'auto' : '400px', }} bordered striped>
            <thead className="erp_table_head" style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#f2f2f2', padding: '8px', border: '1px solid #ddd' }}>
                <tr id="headerRowId">
                    <th className="erp_table_th view_hide" style={{}} rowSpan={2}>Action</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Machine<br />Name</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Machine Operator</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Yarn Count</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Target Level</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Target<br></br>Efficiency%</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Actual<br></br>Count</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>TPI</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>TM</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Speed</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Work<br></br>Spindles</th>

                    <th className="erp_table_th" style={{}} rowSpan={2}>Spindle<br></br>Utilisation%</th>
                    <th scope="col" colspan="2" style={{}} className="erp_table_th text-center">100 Percent</th>
                    <th scope="col" colspan="2" style={{}} className="erp_table_th text-center">Target</th>
                    <th scope="col" colspan="2" style={{}} className="erp_table_th text-center">Actual</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">Actual<br></br>Efficiency%</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">Loss(Kg)<br></br>(With Reason)</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">Loss(Kg)<br></br>(Without Reason)</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">Install<br></br>Spindles</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Stoppage<br></br>Spindles</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Ideal<br></br>Spindle %</th>

                    <th className="erp_table_th" style={{}} rowSpan="2">Yarn<br></br>Construction</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">Stoppage<br></br>Time</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Mixing</th>
                    <th className="erp_table_th" style={{}} rowSpan={2}>Count</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">40's<br></br>Conversion</th>
                    <th className="erp_table_th" style={{}} rowSpan="2">Waste<br></br>Percent</th>
                </tr>
                <tr>
                    <th className="erp_table_th" style={{}}>GSM</th>
                    <th className="erp_table_th" style={{}}>KG</th>
                    <th className="erp_table_th" style={{}}>GSM</th>
                    <th className="erp_table_th" style={{}}>KG</th>
                    <th className="erp_table_th" style={{}}>GSM</th>
                    <th className="erp_table_th" style={{}}>KG</th>
                </tr>
            </thead>
            <tbody>
                {ringFrameProdShiftData?.map((shiftData, index) => (
                    <tr rowindex={index}>
                        <td className="erp_table_td view_hide text-center">
                            <Tooltip title="Add" placement="top">
                                <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => EditMachineDetails('create', shiftData, index)} />
                            </Tooltip>
                        </td>
                        <td className="erp_table_td" >
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field mb-0"
                                id={`machine_short_name_${index}`}
                                value={shiftData.machine_short_name} Headers='machine_short_name'
                                disabled
                            />
                        </td>
                        <td className="erp_table_td" >
                            <select
                                id={`production_operator_id_${index}`} style={{ width: '130px', backgroundColor: '#AFE1AF' }}
                                className="erp_input_field_table_txt zero_removal form-select form-select-sm rfdetail-disable"
                                value={shiftData.production_operator_id}
                                onChange={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                Headers='production_operator_id'
                            >
                                <option style={{ backgroundColor: 'white' }} productCountId={0}>Select</option>
                                {employeeOptions?.map((prodCount) => (
                                    <option style={{ backgroundColor: 'white' }} key={prodCount.field_id} value={prodCount.field_id}>
                                        {prodCount.field_name}
                                    </option>
                                ))}
                            </select>
                        </td>


                        <td className="erp_table_td">
                            <select
                                id={`yarn_count_${index}`} style={{ width: '80px', backgroundColor: '#AFE1AF' }}
                                className="erp_input_field_table_txt zero_removal form-select form-select-sm rfdetail-disable"
                                value={shiftData.yarn_count}
                                onChange={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                Headers='yarn_count'
                            >
                                <option style={{ backgroundColor: 'white' }} productCountId={0}>Select</option>
                                {spinningProdCountOptions?.map((prodCount) => (
                                    <option style={{ backgroundColor: 'white' }} key={prodCount.field_id} value={prodCount.field_name} productCountId={prodCount.production_actual_count}>
                                        {prodCount.field_name}
                                    </option>
                                ))}
                            </select>
                        </td>

                        <td className="erp_table_td">
                            <select
                                id={`target_level_${index}`} style={{ width: '110px', backgroundColor: '#AFE1AF' }}
                                className="erp_input_field_table_txt zero_removal form-select form-select-sm rfdetail-disable"
                                value={shiftData.target_level}
                                onChange={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                Headers='target_level'
                            >
                                <option style={{ backgroundColor: 'white' }} >Select</option>
                                <option style={{ backgroundColor: 'white' }} value='Target Level1'>Target Level 1</option>
                                <option style={{ backgroundColor: 'white' }} value='Target Level2'>Target Level 2</option>
                                <option style={{ backgroundColor: 'white' }} value='Target Level3'>Target Level 3</option>
                                <option style={{ backgroundColor: 'white' }} value='Target Level4'>Target Level 4</option>
                                <option style={{ backgroundColor: 'white' }} value='Target Level5'>Target Level 5</option>
                            </select>
                        </td>

                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '60px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`efficiency_target_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.efficiency_target}
                                Headers='efficiency_target' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '55px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`actual_count_${index}`} value={shiftData.actual_count}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                Headers='actual_count'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '55px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`tpi_${index}`} value={shiftData.tpi}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                Headers='tpi'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '55px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                value={shiftData.tm}
                                id={`tm_${index}`} Headers='tm'
                                disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '58px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`speed_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.speed} Headers='speed'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '55px', backgroundColor: '#AFE1AF' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end rfdetail-disable"
                                id={`work_spindles_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.work_spindles}
                                Headers='work_spindles'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`spindle_utilisation_Per_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.spindle_utilisation_Per}
                                Headers='spindle_utilisation_Per' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '65px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`grams_100_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.grams_100}
                                Headers='grams_100'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '65px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`kgs_100_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.kgs_100}
                                Headers='kgs_100'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '65px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`grams_target_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.grams_target}
                                Headers='grams_target'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '65px' }} disabled
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`kgs_target_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.kgs_target}
                                Headers='kgs_target'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '65px', backgroundColor: '#AFE1AF' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end rfdetail-disable"
                                id={`grams_actual_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.grams_actual}
                                Headers='grams_actual'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '65px', backgroundColor: '#AFE1AF' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end rfdetail-disable"
                                id={`kgs_actual_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.kgs_actual}
                                Headers='kgs_actual'
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`efficiency_percent_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.efficiency_percent}
                                Headers='efficiency_percent' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '90px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`prod_loss_with_reason_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.prod_loss_with_reason}
                                Headers='prod_loss_with_reason' disabled
                            />
                        </td>
                        <td className="erp_table_td text-center">
                            <input
                                type="text" style={{ width: '90px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`prod_loss_with_out_reason_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.prod_loss_with_out_reason}
                                Headers='prod_loss_with_out_reason' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '90px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`install_spindles_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.install_spindles}
                                Headers='install_spindles' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`total_stopage_spindles_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.total_stopage_spindles}
                                Headers='total_stopage_spindles' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '90px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`ideal_spindle_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.ideal_spindle}
                                Headers='ideal_spindle' disabled
                            />
                        </td>

                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`yarn_construction_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.yarn_construction}
                                Headers='yarn_construction' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`total_stopage_time_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.total_stopage_time}
                                Headers='total_stopage_time' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`production_mixing_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.production_mixing}
                                Headers='production_mixing' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`production_count_desc_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.production_count_desc}
                                Headers='production_count_desc' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`conversion_40s_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.conversion_40s}
                                Headers='conversion_40s' disabled
                            />
                        </td>
                        <td className="erp_table_td">
                            <input
                                type="text" style={{ width: '70px' }}
                                className="erp_input_field_table_txt zero_removal mb-0 text-end"
                                id={`waste_percent_${index}`}
                                onInput={(e) => calculateShiftDetailsTblRow(e, shiftData)}
                                value={shiftData.waste_percent}
                                Headers='waste_percent' disabled
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    }, [ringFrameProdShiftData, upToDateShiftDetails, EditMachineDetails])


    const calculateShiftDetailsTblRow = async (event, rowData) => {
        debugger
        let uptodateShiftDetails = upToDateShiftDetails;
        let clickedColName = event.target.getAttribute('Headers');
        let eventId = document.getElementById(event.target.id);
        let enteredValue = event.target.value;

        if (clickedColName !== 'target_level') {
            if (isNaN(enteredValue)) {
                enteredValue = 0;
            }
        } else if (enteredValue != 0 || enteredValue != "") {
            delete eventId.parentElement.dataset.tip;
        }


        let shiftProductionData = [...ringFrameProdShiftData];

        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        rowData.spinning_production_rf_code = txt_prod_rf_code;

        switch (clickedColName) {
            case 'production_operator_id':
                rowData[clickedColName] = enteredValue;
                if ($('#production_operator_id_' + arrayIndex + ' option:selected').text() !== 'Select') {
                    let eventId_Operator = document.getElementById('production_operator_id_' + arrayIndex);
                    delete eventId_Operator.parentElement.dataset.tip;
                }
                break;
            case 'target_level':
                if (rowData.yarn_count === '0' || rowData.yarn_count === 0) {
                    let eventId_YarnCount = document.getElementById('yarn_count_' + arrayIndex);
                    eventId_YarnCount.parentElement.dataset.tip = 'Select Yarn Count!';
                } else {
                    rowData[clickedColName] = enteredValue;
                }
                break;
            case 'yarn_count':
                const productCountId = parseFloat(event.target.options[event.target.selectedIndex].getAttribute('productCountId'));
                let selected_YarnCount = document.getElementById('yarn_count_' + arrayIndex).options[document.getElementById('yarn_count_' + arrayIndex).selectedIndex].text;
                let findMatchedShiftData = shiftProductionData.find((shiftData) => {
                    return shiftData.yarn_count === selected_YarnCount && shiftData.machine_id === rowData.machine_id;
                });

                if (findMatchedShiftData) {
                    let eventId_YarnCount = document.getElementById('yarn_count_' + arrayIndex);
                    eventId_YarnCount.parentElement.dataset.tip = 'Select different yarn count';
                } else {
                    //Validating Machine Operator
                    if ($('#production_operator_id_' + arrayIndex + ' option:selected').text() === 'Select') {
                        let eventId_Operator = document.getElementById('production_operator_id_' + arrayIndex);
                        eventId_Operator.parentElement.dataset.tip = 'Select Machine Operator!';
                    } else {
                        if (productCountId !== 0) {
                            let eventId_YarnCount = document.getElementById('yarn_count_' + arrayIndex);
                            delete eventId_YarnCount.parentElement.dataset.tip;
                            rowData.actual_count = parseFloat(productCountId);
                            rowData[clickedColName] = $('#yarn_count_' + arrayIndex + ' option:selected').text();
                            let prodCountId = spinningProdCountOptions.find((prod_Count) => {
                                return parseFloat(rowData.actual_count) === parseFloat(prod_Count.production_actual_count) && prod_Count.production_count_target_level === rowData.target_level && rowData[clickedColName] === prod_Count.field_name;
                            });
                            if (prodCountId) {
                                rowData.production_count_id = prodCountId.field_id;
                                rowData.efficiency_target = prodCountId.count_target_efficiency_percent;
                                rowData.speed = prodCountId.count_speed;
                                rowData.tpi = prodCountId.count_tpi;
                                rowData.tm = prodCountId.count_tm;
                                rowData.yarn_construction = prodCountId.count_yarn_construction;
                                rowData.conversion_40s = prodCountId.count_40conversion;
                                rowData.production_mixing = prodCountId.production_mixing;
                                rowData.production_count_desc = prodCountId.production_count_desc;
                            }
                            rowData.grams_100 = validateNumberDateInput.current.decimalNumber(JSON.stringify((7.2 * rowData.speed / rowData.actual_count / rowData.tpi)), 4);
                            rowData.grams_target = validateNumberDateInput.current.decimalNumber(JSON.stringify((7.2 * rowData.speed / rowData.actual_count / rowData.tpi * 0.94)), 4);
                        } else {
                            rowData.actual_count = 0;
                            rowData[clickedColName] = 0;
                            rowData.production_count_id = 0;
                            rowData.efficiency_target = 0;
                            rowData.efficiency_percent = 0;
                            rowData.speed = 0;
                            rowData.tpi = 0;
                            rowData.tm = 0;
                            rowData.yarn_construction = 0;
                            rowData.conversion_40s = 0;
                            rowData.production_mixing = 0;
                            rowData.production_count_desc = 0;
                            rowData.grams_100 = 0;
                            rowData.kgs_100 = 0;
                            rowData.grams_target = 0;
                            rowData.kgs_target = 0;
                            rowData.grams_actual = 0;
                            rowData.kgs_actual = 0;
                            rowData.ideal_spindle = 0;
                            rowData.prod_loss_with_out_reason = 0;
                            rowData.prod_loss_with_reason = 0;
                            rowData.total_stopage_spindles = 0;
                            rowData.total_stopage_time = 0;
                            rowData.spindle_utilisation_Per = 0;
                            rowData.work_spindles = 0;
                        }
                    }
                }
                break;
            // case 'tpi':
            //     rowData[clickedColName] = enteredValue;
            //     rowData.tm = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue / (Math.sqrt(rowData.actual_count))), 4);
            //     break;
            case 'speed':
                rowData[clickedColName] = enteredValue;
                break
            case 'work_spindles':
                rowData[clickedColName] = enteredValue;

                if (rowData.yarn_count === '0' || rowData.yarn_count === 0) {
                    let eventId_YarnCount = document.getElementById('yarn_count_' + arrayIndex);
                    eventId_YarnCount.parentElement.dataset.tip = 'Select Yarn Count!';
                } else {
                    if ((parseInt(rowData.install_spindles) - parseInt(rowData[clickedColName])) < 0) {
                        eventId.parentElement.dataset.tip = 'Enter Work Spindles less than or equal to Install Spindles'
                    } else {
                        rowData.total_stopage_spindles = (parseInt(rowData.install_spindles) - parseInt(rowData[clickedColName]));
                        rowData.ideal_spindle = validateNumberDateInput.current.decimalNumber(JSON.stringify((rowData.total_stopage_spindles / rowData.install_spindles) * 100), 4);
                        rowData.spindle_utilisation_Per = validateNumberDateInput.current.decimalNumber(JSON.stringify((rowData[clickedColName] / rowData.install_spindles) * 100), 4);
                        rowData.kgs_100 = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.grams_100 * rowData[clickedColName] / 1000), 4);
                        rowData.kgs_target = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.grams_target * rowData[clickedColName] / 1000), 4);
                    }
                }
                break;
            case 'grams_actual':
                rowData[clickedColName] = enteredValue;
                if (parseFloat(rowData.grams_actual) > parseFloat(rowData.grams_100) || parseFloat(rowData.grams_actual) > parseFloat(rowData.grams_target)) {
                    eventId.parentElement.dataset.tip = 'Enter Actual GSM less than or equal to 100 GSM & Target GSM'
                } else {
                    rowData.kgs_actual = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.work_spindles * enteredValue / 1000), 4);
                    rowData.prod_loss_with_out_reason = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.kgs_target - rowData.kgs_actual), 4);
                    rowData.efficiency_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.kgs_actual / rowData.kgs_100 * 100), 4);
                }
                break;
            case 'kgs_actual':
                rowData[clickedColName] = enteredValue;
                if (parseFloat(rowData.kgs_actual) > parseFloat(rowData.kgs_100) || parseFloat(rowData.kgs_actual) > parseFloat(rowData.kgs_target)) {
                    eventId.parentElement.dataset.tip = 'Enter Actual KGS less than or equal to 100 KGS & Target KGS'
                } else {
                    rowData.grams_actual = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.kgs_actual * 1000 / rowData.work_spindles), 4);
                    rowData.prod_loss_with_out_reason = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.kgs_target - rowData[clickedColName]), 4);
                    rowData.efficiency_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.kgs_actual / rowData.kgs_100 * 100), 4);
                }
                break;
        }
        if (rowData.kgs_target !== 0 && rowData.kgs_target !== "") {
            $('#error_txt_stoppage_time').hide();
        }
        const total_ActualKg = shiftProductionData.reduce((act_Kg, ring_FrameId) =>
            act_Kg + parseInt(ring_FrameId.kgs_actual), 0);
        if (total_ActualKg > 0) {
            $('#error_txt_act_wastages').hide();
        }
        shiftProductionData[arrayIndex] = rowData;
        setRingFrameProdShiftData(shiftProductionData);

        ///Calculations for Summary Details
        let machineWorking = shiftProductionData.filter((shiftDetails) =>
            shiftDetails.actual_count !== 0
        )
        let machinesWorking = parseInt(machineWorking.length);

        let production_100Per = shiftProductionData.reduce((prod, ring_FrameId) =>
            prod + parseFloat(ring_FrameId.kgs_100), 0);

        let target_Production = shiftProductionData.reduce((target, ring_FrameId) =>
            target + parseFloat(ring_FrameId.kgs_target), 0);

        let actual_Production = shiftProductionData.reduce((actual, ring_FrameId) =>
            actual + parseFloat(ring_FrameId.kgs_actual), 0);

        let sum_workSpindles = shiftProductionData.reduce((spindles, ring_FrameId) => {
            return spindles + parseFloat(ring_FrameId.work_spindles);
        }, 0);

        let sum_InstallSpindles = shiftProductionData.reduce((spindles, ring_FrameId) => {
            return spindles + parseFloat(ring_FrameId.install_spindles);
        }, 0)

        let total_Spindle_Utilise = validateNumberDateInput.current.decimalNumber(JSON.stringify((sum_workSpindles / sum_InstallSpindles) * 100), 4);

        let selected_Shift = $('#cmb_shift option:selected').val();
        if (selected_Shift === "") {
            $('#cmb_shift').on('focus', function () {
                $('#error_cmb_shift').text('Select the shift').css({ 'display': 'block', 'margin-top': '6px' });
            });
        } else {
            let selectedShift = $('#cmb_shift option:selected').text();

            setProductionSummaryData((prevData) => {
                const updateData = { ...prevData };
                for (const key of Object.keys(updateData)) {
                    if (key === selectedShift) {

                        let shiftUpToDateDetail = uptodateShiftDetails[selectedShift];
                        let upToShiftDetailsToBeApppended = (keyToUpdateData) => {
                            return Object.values(updateData).reduce((acc, currentValue) => {
                                const value = isNaN(currentValue[keyToUpdateData]) ? 0 : parseFloat(currentValue[keyToUpdateData]);
                                return parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(acc), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(value), 4));
                            }, 0);
                        };

                        ///Calculating Today's Summary Details & its total...............
                        updateData[selectedShift].no_of_machine = machinesWorking;
                        updateData[selectedShift].shift_production_100 = parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(production_100Per), 4));
                        updateData[selectedShift].shift_production_target = parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(target_Production), 4));
                        updateData[selectedShift].shift_production_actual = parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(actual_Production), 4));
                        updateData[selectedShift].shift_spindles_utilization = parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(total_Spindle_Utilise), 4));
                        updateData[selectedShift].total_no_of_machine = upToShiftDetailsToBeApppended('no_of_machine');
                        updateData[selectedShift].total_shift_production_100 = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_100')), 4);
                        updateData[selectedShift].total_shift_production_target = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_target')), 4);
                        updateData[selectedShift].total_shift_production_actual = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_actual')), 4);
                        updateData[selectedShift].total_shift_spindles_utilization = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_spindles_utilization')), 4);

                        //////Calculating UpTo Day's Summary Details & its Total..............
                        updateData[selectedShift].shift_production_upto_date_100 = isNaN(shiftUpToDateDetail.shift_production_upto_date_100) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_production_upto_date_100), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_production_100), 4));
                        updateData[selectedShift].shift_production_upto_date_target = isNaN(shiftUpToDateDetail.shift_production_upto_date_target) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_production_upto_date_target), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_production_target), 4));
                        updateData[selectedShift].shift_production_upto_date_actual = isNaN(shiftUpToDateDetail.shift_production_upto_date_actual) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_production_upto_date_actual), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_production_actual), 4));
                        updateData[selectedShift].shift_spindles_upto_date_utilization = isNaN(shiftUpToDateDetail.shift_spindles_upto_date_utilization) ? 0 : parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(shiftUpToDateDetail.shift_spindles_upto_date_utilization), 4)) + parseFloat(validateNumberDateInput.current.decimalNumber(JSON.stringify(updateData[selectedShift].shift_spindles_utilization), 4));
                        updateData[selectedShift].total_shift_production_upto_date_100 = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_upto_date_100')), 4);
                        updateData[selectedShift].total_shift_production_upto_date_target = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_upto_date_target')), 4);
                        updateData[selectedShift].total_shift_production_upto_date_actual = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_production_upto_date_actual')), 4);
                        updateData[selectedShift].total_shift_spindles_upto_date_utilization = validateNumberDateInput.current.decimalNumber(JSON.stringify(upToShiftDetailsToBeApppended('shift_spindles_upto_date_utilization')), 4);
                        return updateData;
                    }
                }
            });
        }
    }

    ////////////////////////////////////////Production Summary Starts Here////////////////////////////////////////////

    const renderproductionsummary = useMemo(() => {
        let namesOfTheShift = [...shiftNames];
        let selectedShift = $('#cmb_shift option:selected').text();
        const prodSummaryRowsFirstCol = ['No Of Machine', '100% Production', 'Target Production', 'Actual Production', 'Production Loss', 'Stoppage Time(mins)', 'Shift Wastage(Kgs)', 'Spindle Utilize'];
        return (<div style={{ display: "block", overflow: "auto" }}>
            <Table id="todaysProdSummaryTableId" bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th"></th>
                        <th className="erp_table_th text-center" colSpan={3}>
                            Today's Production Summary
                        </th>
                        <th className="erp_table_th text-center" colSpan={3}>
                            Up To Day's Production Summary
                        </th>
                    </tr>
                </thead>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Description</th>
                        {shiftOptions?.map((header, indexOfItem) => (
                            <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                                {header.field_name}
                            </th>
                        ))}
                        <th className="erp_table_th">Total</th>
                        {shiftOptions?.map((header, indexOfItem) => (
                            <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                                {header.field_name}
                            </th>
                        ))}
                        <th className="erp_table_th">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {prodSummaryData?.map((summaryData, index) => ( */}
                    {Object.keys(prodSummaryData).length > 0 ? (
                        <React.Fragment>
                            {/* First Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[0]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].no_of_machine}
                                        </td>

                                    ))}
                                    {prodSummaryData[selectedShift].total_no_of_machine !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_no_of_machine}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>

                                {namesOfTheShift.map((key, index) => (
                                    <>
                                        <td key={key} className="erp_table_td text-center">
                                            ----
                                        </td>
                                        {
                                            index === namesOfTheShift.length - 1 ?
                                                <td className="erp_table_td text-center">----</td> : null
                                        }
                                    </>
                                ))}
                            </tr>
                            {/* Second Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[1]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_100}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_100 !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_100}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_upto_date_100}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_upto_date_100 !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_upto_date_100}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                            {/* Third Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[2]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_target}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_target !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_target}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_upto_date_target}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_upto_date_target !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_upto_date_target}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                            {/* /Forth Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[3]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_actual}
                                        </td>
                                    ))}
                                    {
                                        prodSummaryData[selectedShift].total_shift_production_actual !== undefined ? (
                                            <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_actual}</td>
                                        ) : (
                                            <td className="erp_table_td">{null}</td>
                                        )
                                    }
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_upto_date_actual}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_upto_date_actual !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_upto_date_actual}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                            {/* Fifth Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[4]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_loss}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_loss !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_loss}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_production_upto_date_loss}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_production_upto_date_loss !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_production_upto_date_loss}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                            {/* Sixth Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[5]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_stopage_time}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_stopage_time !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_stopage_time}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_stopage_upto_date_time}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_stopage_upto_date_time !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_stopage_upto_date_time}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                            {/* Seventh Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[6]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_wastage_kgs}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_wastage_kgs !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_wastage_kgs}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_wastage_upto_date_kgs}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_wastage_upto_date_kgs !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_wastage_upto_date_kgs}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                            {/* 8th Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[7]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_spindles_utilization}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_spindles_utilization !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_spindles_utilization}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_spindles_upto_date_utilization}
                                        </td>
                                    ))}
                                    {prodSummaryData[selectedShift].total_shift_spindles_upto_date_utilization !== undefined ? (
                                        <td className="erp_table_td">{prodSummaryData[selectedShift].total_shift_spindles_upto_date_utilization}</td>
                                    ) : (<td className="erp_table_td">{null}</td>)}
                                </>
                            </tr>
                        </React.Fragment>
                    ) : (<tr></tr>)}
                    {/* ))} */}
                </tbody>
            </Table>
        </div >)
    }, [prodSummaryData, cmb_section_id, shiftNames]);


    // const validateInputs = () => {
    //     const inputs = document.querySelectorAll('#productionshifttableId input[type="text"]');
    //     const enabledInputs = Array.from(inputs).filter(input => input.disabled === false);

    //     let isValid = true;
    //     for (const input of enabledInputs) {
    //         const value = parseFloat(input.value);
    //         if (value === undefined || value === 0 || value === ' ' || isNaN(value)) {
    //             input.focus();
    //             input.parentElement.dataset.tip = "Please enter a valid value...";
    //             isValid = false;
    //             break
    //         } else {
    //             delete input.parentElement.dataset.tip;
    //         }
    //     };
    //     return isValid;
    // };

    $(document).on('click', '.zero_removal', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".zero_removal");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });



    const saveRingFrameProductionDetails = async () => {
        let validateRfHeaderForm = await validate.current.validateForm('productionmasterId');
        // let validateShiftProdDetails = validateInputs();
        if (validateRfHeaderForm === true) {
            try {
                setIsLoading(true)
                let prodRfDetailsJson = {
                    TransHeaderData: {}, TransDetailData: [], TransSpinProdSummaryData: {}, TransSpinProdStoppageData: [], TransSpinProdWastageeData: [], 'commonIds': {
                        'company_id': COMPANY_ID, spinning_production_rf_master_id: 0
                    }
                }

                ///Production Header Data
                const prodHeaderData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    spinning_production_rf_master_id: prod_Rf_SummaryId !== undefined ? prod_Rf_SummaryId : 0,
                    spinning_production_rf_code: txt_prod_rf_code,
                    spinning_production_date: dt_spinning_production_date,
                    production_supervisor_id: cmb_prod_supervisor,
                    prod_month: txt_prod_month,
                    prod_year: txt_prod_year,
                    plant_id: cmb_plant_id,
                    section_id: cmb_section_id,
                    sub_section_id: cmb_sub_section_id,
                    shift: $('#cmb_shift option:selected').text(),
                    target_efficiency: txt_target_efficiency,
                    no_of_machine: txt_no_of_machine,
                    spinn_plan_code: $('#cmb_spinn_plan_code option:selected').text(),
                    spinning_production_rf_stopage_id: "",
                    spinning_production_rf_wastage_id: "",
                    created_by: UserName,
                    modified_by: UserName,
                }
                prodRfDetailsJson.TransHeaderData = prodHeaderData;

                //Production Summary Data // Removing all the unnecassary keys & passing necessary keys to backend
                let selectedShift = $('#cmb_shift option:selected').text();
                let { total_no_of_machine, total_shift_production_100, total_shift_production_actual, total_shift_production_loss, total_shift_production_target, total_shift_production_upto_date_100, total_shift_production_upto_date_loss, total_shift_production_upto_date_target, total_shift_production_upto_date_actual, total_shift_spindles_upto_date_utilization, total_shift_spindles_utilization, total_shift_stopage_time, total_shift_stopage_upto_date_time, total_shift_wastage_kgs, total_shift_wastage_upto_date_kgs, ...req_SummaryData } = prodSummaryData[selectedShift];
                const { spinning_production_rf_stopage_id, production_supervisor_id, target_efficiency, spinning_production_rf_wastage_id, no_of_machine, ...newDetails } = prodHeaderData;
                req_SummaryData.spinning_production_rf_summary_id = 0;
                prodRfDetailsJson.TransSpinProdSummaryData = { ...req_SummaryData, ...newDetails };

                ///Production Shift Details //Updating date & shift here
                let ring_Frame_Details = ringFrameProdShiftData.map((details) => {
                    details.spinning_production_rf_details_id = 0;
                    details.spinning_production_rf_master_id = 0;
                    details.spinning_production_date = $('#dt_spinning_production_date').val();
                    details.plant_id = cmb_plant_id;
                    details.section_id = cmb_section_id;
                    details.sub_section_id = cmb_sub_section_id;
                    details.prod_month = txt_prod_month;
                    details.prod_year = txt_prod_year;
                    details.spinn_plan_code = $('#cmb_spinn_plan_code option:selected').text();
                    details.shift = $('#cmb_shift option:selected').text();
                    details.production_supervisor_id = cmb_prod_supervisor;
                    details.created_by = UserName;
                    details.modified_by = UserName;
                    return details;
                });
                prodRfDetailsJson.TransDetailData = ring_Frame_Details;


                ///Production Stoppage Details  ///Removing unnecessary Keys
                let stoppage_Details = [...stoppageDetails];
                let requiredStoppageDetailsJson = stoppage_Details.map((details) => {
                    const { machine_name, production_stoppage_reasons_name, ...newDetails } = details;
                    return newDetails;
                });

                for (let index = 0; index < requiredStoppageDetailsJson.length; index++) {

                    let element = requiredStoppageDetailsJson[index];
                    let jsonStoppageDetails = {};
                    jsonStoppageDetails.company_id = COMPANY_ID;
                    jsonStoppageDetails.company_branch_id = COMPANY_BRANCH_ID;
                    jsonStoppageDetails.financial_year = FINANCIAL_SHORT_NAME;
                    jsonStoppageDetails.spinning_production_rf_stoppage_id = 0;
                    jsonStoppageDetails.spinning_production_rf_master_id = 0;
                    jsonStoppageDetails.spinning_production_rf_code = txt_prod_rf_code;
                    jsonStoppageDetails.spinning_production_date = dt_spinning_production_date;
                    jsonStoppageDetails.prod_month = txt_prod_month;
                    jsonStoppageDetails.prod_year = txt_prod_year;
                    jsonStoppageDetails.plant_id = cmb_plant_id;
                    jsonStoppageDetails.spinn_plan_code = $('#cmb_spinn_plan_code option:selected').text();
                    jsonStoppageDetails.section_id = cmb_section_id;
                    jsonStoppageDetails.sub_section_id = cmb_sub_section_id;
                    jsonStoppageDetails.shift = $('#cmb_shift option:selected').text();
                    jsonStoppageDetails.machine_id = element.machine_id;
                    jsonStoppageDetails.production_stoppage_reasons_id = element.production_stoppage_reasons_id;
                    jsonStoppageDetails.stoppage_time = element.stoppage_time;
                    jsonStoppageDetails.std_stoppage_loss_kg = element.std_stoppage_loss_kg;
                    jsonStoppageDetails.actual_production_loss_kg = element.actual_production_loss_kg;
                    jsonStoppageDetails.created_by = UserName;
                    jsonStoppageDetails.modified_by = UserName;
                    prodRfDetailsJson.TransSpinProdStoppageData.push(jsonStoppageDetails);
                }


                //Production Wastage Details 
                for (let index = 0; index < wastageDetails.length; index++) {
                    const element = wastageDetails[index];
                    let jsonWastageDetails = {};
                    jsonWastageDetails.company_id = COMPANY_ID;
                    jsonWastageDetails.company_branch_id = COMPANY_BRANCH_ID;
                    jsonWastageDetails.financial_year = FINANCIAL_SHORT_NAME;
                    jsonWastageDetails.spinning_production_rf_wastage_id = 0;
                    jsonWastageDetails.spinning_production_rf_master_id = 0;
                    jsonWastageDetails.spinning_production_rf_code = txt_prod_rf_code;
                    jsonWastageDetails.spinning_production_date = dt_spinning_production_date;
                    jsonWastageDetails.prod_month = txt_prod_month;
                    jsonWastageDetails.prod_year = txt_prod_year;
                    jsonWastageDetails.plant_id = cmb_plant_id;
                    jsonWastageDetails.spinn_plan_code = $('#cmb_spinn_plan_code option:selected').text();
                    jsonWastageDetails.section_id = cmb_section_id;
                    jsonWastageDetails.sub_section_id = cmb_sub_section_id;
                    jsonWastageDetails.shift = $('#cmb_shift option:selected').text();
                    jsonWastageDetails.production_wastage_types_id = element.production_wastage_types_id;
                    jsonWastageDetails.production_wastage_types_type = element.production_wastage_types_type;
                    jsonWastageDetails.actual_wastage_production_kg = element.actual_wastage_production_kg;
                    jsonWastageDetails.actual_wastage_production_percent = element.actual_wastage_production_percent;
                    jsonWastageDetails.std_wastage_kg = element.std_wastage_kg;
                    jsonWastageDetails.std_wastage_percent = element.std_wastage_percent;
                    jsonWastageDetails.created_by = UserName;
                    jsonWastageDetails.modified_by = UserName;

                    prodRfDetailsJson.TransSpinProdWastageeData.push(jsonWastageDetails);
                }


                const formData = new FormData();
                formData.append(`SpinningProductionRfData`, JSON.stringify(prodRfDetailsJson))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                try {
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProductionRfMaster/FnAddUpdateRecord`, requestOptions)
                    const apiResponse = await apiCall.json();

                    if (apiResponse.success === 1) {
                        const evitCache = await comboDataAPiCall.current.evitCache();
                        console.log(evitCache);
                        setSuccMsg(apiResponse.message);
                        setShowSuccessMsgModal(true);
                    } else if (apiResponse.success === 0) {
                        setErrMsg(apiResponse.error)
                        setShowErrorMsgModal(true);
                    }
                } catch (error) {
                    console.log("error", error)
                }
            } catch (error) {
                navigate('/Error');
            } finally {
                setIsLoading(false)
            }
        }
    }

    ///Delete Ring Frame Production 
    const deleteshow = () => { setShow(true) }

    async function DeleteRecord() {
        try {
            const method = { method: 'DELETE' }
            await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnDeleteRecord/${prodRfMasterId}/${UserName}`, method)
            setShow(false)
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <FrmValidations ref={validate} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span id="spinner_text" className="text-dark">Loading...</span>
                        </div>
                    </div> :
                    null}


                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Ring Frame Production{action_Label} </label>
                    </div>

                    <form id='productionmasterId'>
                        <div className='row p-1'>
                            {/*................ 1st Column .............*/}
                            <div className='col-sm-6 erp_form_col_div'>
                                <div className='row'>
                                    <div className='col-sm-6 erp_form_col_div'>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Plant Name</Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields('Production Master') }} disabled>
                                                    <option value="" disabled>Select</option>
                                                    {plantOptions.length !== 0 ? (
                                                        <>{plantOptions?.map(plant => (
                                                            <option value={plant.field_id}>{plant.field_name}</option>
                                                        ))} </>
                                                    ) : null
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Prod. Month</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_prod_month' className="erp_input_field" value={txt_prod_month} disabled />
                                                <MDTypography variant="button" id="error_txt_prod_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Prod. Date <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id='dt_spinning_production_date' className="erp_input_field" value={dt_spinning_production_date} onChange={(e) => { setMonthYear(); validateFields('Production Master') }} />
                                                <MDTypography variant="button" id="error_dt_spinning_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Sub Section<span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { setProdSubSection(e.target.value); getRingFrameDetails(e); validateFields('Production Master') }}>
                                                    <option value="">Select</option>
                                                    {prodsubsectionOptions.length !== 0 ? (
                                                        <>
                                                            {prodsubsectionOptions?.map(subproduction => (
                                                                <option value={subproduction.field_id}>{subproduction.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null

                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-sm-6 erp_form_col_div'>

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Section</Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id'); validateFields('Production Master') }} disabled>
                                                    <option value="">Select</option>
                                                    {prodsectionOptions.length !== 0 ? (
                                                        <>
                                                            {prodsectionOptions?.map(production => (
                                                                <option value={production.field_id}>{production.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null

                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Prod. Year <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_prod_year' className="erp_input_field" value={txt_prod_year} disabled />
                                                <MDTypography variant="button" id="error_txt_prod_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Prod. Shift <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_shift" className="form-select form-select-sm" value={cmb_shift} onChange={(e) => { setShift(e.target.value); comboOnChange("shift"); validateFields('Production Master') }}>
                                                    <option value="">Select</option>
                                                    {shiftOptions.length !== 0 ? (
                                                        <>
                                                            {shiftOptions?.map(shift => (
                                                                <option value={shift.field_name}>{shift.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null

                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>




                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Supervisor <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_prod_supervisor" className="form-select form-select-sm" value={cmb_prod_supervisor} onChange={(e) => { setProdSupervisor(e.target.value); validateFields('Production Master') }}>
                                                    <option value="" disabled>Select</option>
                                                    {employeeOptions.length !== 0 ? (
                                                        <>{employeeOptions?.map(employee => (
                                                            <option value={employee.field_id}>{employee.field_name}</option>
                                                        ))} </>
                                                    ) : null
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_prod_supervisor" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">RF Prod. Code <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id='txt_prod_rf_code' className="erp_input_field" value={txt_prod_rf_code} onChange={(e) => { validateFields('Production Master') }} onInput={(e) => { setProdRfCode(e.target.value) }} disabled />
                                        <MDTypography variant="button" id="error_txt_prod_rf_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Spin Plan Code <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_spinn_plan_code" className="form-select form-select-sm" value={cmb_spinn_plan_code} onChange={(e) => { setSpinPlanCode(e.target.value); validateFields('Production Master') }}>
                                            <option value="">Select</option>
                                            {spinPlanCodeOptions.length !== 0 ? (
                                                <>
                                                    {spinPlanCodeOptions?.map(plancode => (
                                                        <option value={plancode.field_name}>{plancode.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spinn_plan_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row txt_target_efficiency'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target Efficiency</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_target_efficiency' className="erp_input_field" value={txt_target_efficiency} onChange={(e) => { setProdTargetEff(e.target.value); validateFields('Production Master') }} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_target_efficiency" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row txt_no_of_machine'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">No Of Machines</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_no_of_machine' className="erp_input_field" value={txt_no_of_machine} onChange={(e) => { setProdNoOfMachine(e.target.value); validateFields('Production Master') }} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_txt_no_of_machine" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/*.......................2nd Column.......................... */}
                            <div className='col-sm-6 erp_form_col_div'>
                                <div className='erp-form-label-lg text-center'>Production Summary</div>
                                {renderproductionsummary}
                            </div>
                        </div>
                    </form>

                    <hr />
                    {/*.................................. Production Shift Accordian............................................. */}

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="prodRFDetailsAccordian">
                            <Accordion.Header className="erp-form-label-md">Ring Frame Production Shift Details</Accordion.Header>
                            <Accordion.Body>
                                {renderProductionShiftDetails}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <hr />

                    {/*..........................Shiftwise Machine Stoppage Entry(Ring Frame)...................................... */}
                    <div className='col-sm-5 erp_form_col_div'>
                        <div className='erp-form-label-lg'>Shiftwise Machine Stoppage Entry(Ring Frame)</div>
                    </div>

                    <form id='stoppageFormId'>
                        <div className='row p-1'>
                            {/* 1 st Column */}

                            <div className='col-sm-6 erp_form_col_div'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Machine No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-4">
                                        <select id="cmb_machine_id" className="form-select form-select-sm" value={cmb_machine_id} onChange={(e) => { setMachineNo(e.target.value); validateFields('Stoppage Form'); }}>
                                            <option value="">Select</option>
                                            {machineOptions.length !== 0 ? (
                                                <>
                                                    {machineOptions?.map(machine => (
                                                        <option value={machine.field_id}>{machine.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_machine_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Applicable To All Machines"
                                                    type="checkbox"
                                                    value="0"
                                                    name="isTestCertRequired"
                                                    defaultChecked
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Stoppage Reason <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select id="cmb_stoppage_reason" className="form-select form-select-sm" value={cmb_stoppage_reason} onChange={(e) => { setMachineStoppageReason(e.target.value); validateFields('Stoppage Form'); }}>
                                            <option value="">Select</option>
                                            {stoppagereasonOptions.length !== 0 ? (
                                                <>
                                                    {stoppagereasonOptions?.map(stoppage => (
                                                        <option value={stoppage.field_id}>{stoppage.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_stoppage_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Stopp. Time (Mins)<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_stoppage_time' className="erp_input_field" value={txt_stoppage_time} onChange={(e) => { setMachineStoppageTime(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateProdLoss(e); }} />
                                        <MDTypography variant="button" id="error_txt_stoppage_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Std. Prod. Loss </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_std_prod_loss' className="erp_input_field" value={txt_std_prod_loss} onChange={(e) => { setStdProdLoss(e.target.value); }} disabled />
                                        <MDTypography variant="button" id="error_txt_std_prod_loss" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Act. Prod. Loss </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_act_prod_loss' className="erp_input_field" value={txt_act_prod_loss} onChange={(e) => { setActProdLoss(e.target.value); }} disabled />
                                        <MDTypography variant="button" id="error_txt_act_prod_loss" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="erp_frm_Btns">
                                    <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" onClick={() => FnUpdateStoppageReason('', '', 'add')} variant="button" fontWeight="regular">{labelForStoppage}</MDButton>
                                    <MDButton type='button' className="erp-gb-button ms-2 disable-button" variant="button" id='back_Button' onClick={(e) => clearFormFields('stoppage')} fontWeight="regular" >Clear</MDButton>
                                </div >
                            </div>


                            <div className='col-sm-6 erp_form_col_div'>
                                <div className='row'>
                                    {renderMachineStoppageTable}
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr />
                    {/*..............Shiftwise Machine Wastage Entry(Ring Frame)............................... */}
                    <div className='col-sm-5 erp_form_col_div'>
                        <div className='erp-form-label-lg'>Shiftwise Machine Wastage Entry(Ring Frame)</div>
                    </div>

                    <form id='wastageFormId'>
                        <div className='row p-1'>
                            {/* 1 st Column */}

                            <div className='col-sm-6 erp_form_col_div'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Prod. Wastage Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <select id="cmb_prod_wastage_type" className="form-select form-select-sm" value={cmb_prod_wastage_type} onChange={(e) => { calculateMachineWastage(''); }}>
                                            <option value="">Select</option>
                                            {prodWastageOptions.length !== 0 ? (
                                                <>
                                                    {prodWastageOptions?.map(machine => (
                                                        <option value={machine.field_id}>{machine.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_prod_wastage_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Act. Wastages(Kg)<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_act_wastages' className="erp_input_field" value={txt_act_wastages} onChange={(e) => calculateMachineWastage(e.target.value)} />
                                        <MDTypography variant="button" id="error_txt_act_wastages" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Act. Wastage (%)<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_act_wastage_percent' className="erp_input_field" value={txt_act_wastage_percent} onChange={(e) => { setActWastagePercent(e.target.value); }} disabled />
                                        <MDTypography variant="button" id="error_txt_act_wastage_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Std. Wastage(%)</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_std_wastage_percent' className="erp_input_field" value={txt_std_wastage_percent} disabled />
                                        <MDTypography variant="button" id="error_txt_std_wastage_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Std. Wastage(Kg)</Form.Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Form.Control type="text" id='txt_std_wastages' className="erp_input_field" value={txt_std_wastages} onChange={(e) => { validateFields('Wastage Form') }} disabled />
                                        <MDTypography variant="button" id="error_txt_std_wastages" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="erp_frm_Btns">
                                    <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnUpdateWastageReason('', '', 'add')} fontWeight="regular">{labelForWastage}</MDButton>
                                    <MDButton type='button' className="erp-gb-button ms-2 disable-button" variant="button" id='back_Button' onClick={(e) => clearFormFields('wastage')} fontWeight="regular" >Clear</MDButton>
                                </div >
                            </div>

                            <div className='col-sm-6 erp_form_col_div'>
                                <div className='row'>
                                    {renderMachineWastageTable}
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr />

                    <div className="erp_frm_Btns">
                        <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Transactions/TProduction/FrmProductionListing')}>Back</MDButton>
                        <MDButton type="submit" id="save_Button" className="erp-gb-button ms-2 view_hide" variant="button"
                            onClick={() => saveRingFrameProductionDetails()} fontWeight="regular">Save</MDButton>
                        <MDButton className="erp-gb-button ms-2" style={{ display: keyForViewUpdate === 'delete' ? 'inline' : 'none' }}
                            variant="button" fontWeight="regular" onClick={() => deleteshow()}> Delete</MDButton>
                    </div >

                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <MDButton variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </MDButton>&nbsp;
                        <MDButton variant="danger" className='erp-gb-button' onClick={DeleteRecord}>Delete</MDButton>
                    </Modal.Footer>
                </Modal>

            </DashboardLayout >
        </>
    )
}

export default FrmProductionEntry