import React, { useState, useRef, useMemo, useEffect } from "react";
import $ from 'jquery';
// import react icons
import { MdDelete } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { Modal, Table } from "react-bootstrap";
import MDButton from "components/MDButton";
import { useLocation } from "react-router-dom";
import MDTypography from "components/MDTypography";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { resetGlobalQuery } from "assets/Constants/config-constant";
import { globalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";

export default function FrmWeavingOrderEntry({ onGetFormWeavingData, weavingData, generatedWVNo, scheduleQuntity }) {

    const { state } = useLocation();
    const { keyForViewUpdate } = state || {}

    // For Refs
    const validateNumberDateInput = useRef();
    const comboDataAPiCall = useRef();
    const configConstants = ConfigConstants();
    const { COMPANY_ID } = configConstants;
    const [cmb_indent_type, setIndentType] = useState('');
    const [txt_weaving_order_no, setWeavingOrderNO] = useState(generatedWVNo !== null && generatedWVNo.length !== 0 ? generatedWVNo : [])

    const [weavingfilteredData, setWeavingfilteredData] = useState(weavingData !== null && weavingData.length !== 0 ? weavingData : [])
    const [showWVFilterForm, setShowWVFilterForm] = useState(false);

    useEffect(() => {
        removeSessions('onload');
        if (keyForViewUpdate !== "Add") {
            if (weavingData && weavingData[0]?.weaving_order_no) {
                setWeavingOrderNO(weavingData[0].weaving_order_no);
            }
        }
    }, [weavingData])

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
            localStorage.removeItem('indentScheduleData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');
        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }
    let requiredCols = ['product_id', 'product_name', 'product_tech_spect', 'product_material_code', 'product_std_weight', 'product_unit_name', 'indent_quantity', 'indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
        "approval_remark", "indent_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
        "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time',
    ];

    const viewWeavingFilterForm = async () => {
        $('#error_cmb_indent_type').hide();
        sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
        setShowWVFilterForm(true);
    }

    //calculate the material quantity= weft_req_per_mtr*schedule quantity 
    useEffect(() => {
        calculateQuantity();
    }, [scheduleQuntity]);

    let previousQuantities = {}; // Store previously calculated quantities
    const calculateQuantity = async () => {
        try {
            setShowWVFilterForm(false);
            if (weavingfilteredData.length !== 0) {
                const updatedArray = await Promise.all(
                    weavingfilteredData.map(async (item) => {
                        resetGlobalQuery();
                        globalQuery.columns = ['weft_req_per_mtr'];
                        globalQuery.table = "xmv_weaving_production_plan_summary";
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "product_id", operator: "=", value: item.product_id });
                        const getWeftReqperMtrValue = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                        // Calculate new quantity
                        let newQuantity;
                        if (getWeftReqperMtrValue.length > 0 && getWeftReqperMtrValue[0].weft_req_per_mtr !== 0) {
                            newQuantity = getWeftReqperMtrValue[0].weft_req_per_mtr * scheduleQuntity;
                            // Store previous value if not already stored
                            if (!previousQuantities[item.product_id]) {
                                previousQuantities[item.product_id] = item.product_material_approved_quantity;
                            }
                        } else {
                            // Use previous quantity if available, otherwise set to ''
                            newQuantity = previousQuantities[item.product_id] !== undefined ? previousQuantities[item.product_id] : '';
                        }

                        // Update product_material_approved_quantity only if a new value is provided
                        if (newQuantity !== '') {
                            item.product_material_approved_quantity = validateNumberDateInput.current.decimalNumber(JSON.stringify(newQuantity), 4);
                        }

                        return item; // Return the updated item
                    })
                );

                // Combine updatedArray with existing weavingfilteredData
                const combinedArray = [...weavingfilteredData, ...updatedArray];

                // Remove duplicates based on product_id
                const filteredArray = combinedArray.filter((item, index, self) =>
                    index === self.findIndex((nextItem) => (
                        nextItem.product_id === item.product_id
                    ))
                );

                // Replace the existing rows with the updated ones
                setWeavingfilteredData(filteredArray);
                onGetFormWeavingData(filteredArray);
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(filteredArray));
            }
        } catch (error) {
            console.error('Error in calculateQuantity:', error);
        }
    };

    // fn to add material data
    const handleCloseWeavingFilterForm = async () => {
        try {
            setShowWVFilterForm(false);

            const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
            if (getData.length !== 0) {
                const updatedArray = await Promise.all(
                    getData.map(async (item) => {
                        // resetGlobalQuery();
                        // globalQuery.columns = ['weft_req_per_mtr'];
                        // globalQuery.table = "xmv_weaving_production_plan_summary";
                        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        // globalQuery.conditions.push({ field: "product_id", operator: "=", value: item.product_id });
                        // const getWeftReqperMtrValue = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);


                        // const existingItem = weavingfilteredData.find(prevItem => prevItem.product_id === item.product_id);
                        // const newQuantity = existingItem ? existingItem.product_material_approved_quantity : ((getWeftReqperMtrValue.length > 0 ? getWeftReqperMtrValue[0].weft_req_per_mtr : 0) * scheduleQuntity);

                        setWeavingfilteredData(prevArray => [
                            ...prevArray,
                            {
                                ...item,
                                weaving_order_no: txt_weaving_order_no, 
                                // product_material_approved_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(newQuantity), 4)
                            }
                        ]);

                        return {
                            ...item,
                            weaving_order_no: txt_weaving_order_no, 
                            // product_material_approved_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(newQuantity), 4)
                        };
                    })
                );
                // Combine updatedArray with existing weavingfilteredData
                const combinedArray = [...weavingfilteredData, ...updatedArray];
                // Remove duplicates based on product_id
                const filteredArray = combinedArray.filter((item, index, self) =>
                    index === self.findIndex((nextItem) => (
                        nextItem.product_id === item.product_id
                    ))
                );
                setWeavingfilteredData(filteredArray);
                onGetFormWeavingData(filteredArray);
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(filteredArray));
            }

            sessionStorage.setItem('isComboFilterExist', false);
            const LocalArr = JSON.parse(localStorage.getItem('localStorageKeyArr'));
            console.log('LocalArr: ', LocalArr);
            if (LocalArr !== null) {
                for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                    localStorage.removeItem(LocalArr[localArrIndex]);
                }
            }

            removeSessions();
            setTimeout(() => { categoryDisplay(); }, 300);
        } catch (error) {
            console.error('Error in handleCloseWarpingFilterForm:', error);
        }
    };

    const handleInputChange = (indentItem, e) => {
        const updatedValue = validateNumberDateInput.current.decimalNumber(e.target.value, 4);
        const updatedBomfilteredData = weavingfilteredData.map(item => {
            if (item.product_id === indentItem.product_id) {
                return { ...item, product_material_approved_quantity: updatedValue };
            }
            return item;
        });
        setWeavingfilteredData(updatedBomfilteredData);
        onGetFormWeavingData(updatedBomfilteredData);
    };

    // fn to remove material
    const removeMaterial = (row, e) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            const detailData = [...weavingfilteredData]
            const findElementIndex = detailData.findIndex(record => record.product_id === row.product_id);
            if (findElementIndex !== -1) { detailData.splice(findElementIndex, 1); }
            setWeavingfilteredData(detailData);
            onGetFormWeavingData(detailData);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));

        }
    }

    const renderDetailsTable = useMemo(() => {
        return <>
            <div style={{ overflowX: "hidden", flex: 0 }}>
                <Table className="erp_table erp_table_scroll" style={{ height: weavingfilteredData.length <= 5 ? 'auto' : '175px' }} responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>Actions</th>
                            <th className="erp_table_th" >Sr.no</th>
                            <th className="erp_table_th" >Material Code</th>
                            <th className="erp_table_th" >Material Name</th>
                            <th className="erp_table_th" >Material Tech Specification</th>
                            <th className="erp_table_th" >Material Unit</th>
                            <th className="erp_table_th" >Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {weavingfilteredData.map((indentItem, Index) =>
                            <tr rowindex={Index} className="sticky-column">
                                <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`} >
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => removeMaterial(indentItem, e)} />
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td text-end sticky-column" >{Index + 1}</td>
                                <td className="erp_table_td "  >{indentItem.product_material_code}</td>

                                <td className={`erp_table_td sticky-column`} >{indentItem.product_name}</td>
                                <td className="erp_table_td "  >{indentItem.product_tech_spect}</td>

                                <td className="erp_table_td "  >{indentItem.product_unit_name}</td>

                                <td className="erp_table_td">
                                    <input
                                        type="text" id={`product_material_approved_quantity_${indentItem.product_material_approved_quantity}`}
                                        className="erp_input_field_table_txt mb-0 approveField text-end"
                                        style={{ width: '100px', backgroundColor: '#AFE1AF'}}
                                        value={indentItem.product_material_approved_quantity}
                                        onChange={(e) => handleInputChange(indentItem, e)}
                                        Headers="product_material_approved_quantity"
                                        disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    />
                                </td>


                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    }, [weavingfilteredData])

    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={comboDataAPiCall} />

            <div className="row mb-2">
                <div className="col-md-4">

                    {!(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') &&
                        <SearchItemFilter getSearchData={handleCloseWeavingFilterForm} formIdForValidate="" requiredCols={requiredCols} />
                    }
                </div>
                <div className="col-md-2">
                    <MDButton onClick={viewWeavingFilterForm} className={`erp-gb-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">
                        Add Materials
                    </MDButton>
                </div>
                <div className="col text-end">
                    <Form.Label className="erp-form-label"> Weaving Order No <span className="required">*</span> </Form.Label>
                </div>
                <div className="col">
                    <Form.Control type="text" id="txt_weaving_order_no" className="erp_input_field" value={txt_weaving_order_no} />
                    <MDTypography variant="button" id="error_txt_weaving_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                    </MDTypography>
                </div>
            </div>

            <div className="mt-2">
                {weavingfilteredData.length !== 0 ?
                    <>
                        {renderDetailsTable}
                    </> : ""
                }
            </div>

            <Modal size="lg" className='erp_document_Form' show={showWVFilterForm} onHide={handleCloseWeavingFilterForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Product List</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseWeavingFilterForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <ProductListProductTypeWise closeModal={handleCloseWeavingFilterForm}
                            props={{ product_type_id: cmb_indent_type }}
                        />
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        </>
    )
}
