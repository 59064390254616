// React Imports.
import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $, { event } from 'jquery';

// Material UI Imports.
import { CircularProgress } from "@material-ui/core";


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";

// Required Common Components.
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ConfigConstants from 'assets/Constants/config-constant';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ComboBox from 'Features/ComboBox';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import { AiOutlineSchedule } from "react-icons/ai";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";


function TServicePlanningEntry() {

    // Required Constant's Fields For Form.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_NAME, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

    // Accessing Hooks From listing page.
    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType = "Transaction", deletionKey = false } = state || {};


    const navigator = useNavigate();
    const validateFrms = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    const FnFormattedDateForInputField = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = FnFormattedDateForInputField(new Date());


    // Form's Combo's Options hooks.
    const [customersOpts, setCustomerOpts] = useState([]);
    const [servicesOpts, setServicesOpts] = useState([]);
    const [assignToEmplsOpts, setAssignToEmplsOpts] = useState([]);


    // Form Fields hooks.
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_service_id, setServiceId] = useState('');
    const [dt_from_date, setFromDate] = useState('');
    const [dt_to_date, setToDate] = useState('');

    const [cmb_assign_all_to, setAssignAllTo] = useState('');


    // Table's Data Hooks.
    const [serviceSchedulingTblData, setServiceSchedulingTblData] = useState([]);
    const [activitiesTblData, setActivitiesTblData] = useState([]);


    // helping hooks.
    const [isLoading, setIsLoading] = useState(false);
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                if (deletionKey === true) {
                    return '(Deletion)';
                } else {
                    return '(View)';
                }
            case 'copy':
                return '(Copy Sales Order)';
            default:
                return '(Creation)';
        }
    };

    //Error Msg Modal
    const FnCloseErrorModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg Modal
    const FnCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const initPageLayout = async () => {
            await FnFillComboOnPageLoad();
        };
        initPageLayout()
    }, []);

    // Function for filling the combo-boxes on page load.
    const FnFillComboOnPageLoad = async () => {
        try {
            // Load the customer options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(customerList => {
                    setCustomerOpts(customerList);
                });

            // Load the Services options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "smv_product_sr"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(servicesList => {
                    setServicesOpts(servicesList);
                });

            // Load the Services options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_employee_list"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(employeeList => {
                    setAssignToEmplsOpts(employeeList);
                });

        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }



    // ******************************* Service Scheduling Functions Starts. *************************************
    const FnGetSchedules = async () => {
        try {
            setIsLoading(true);
            resetGlobalQuery();
            globalQuery.columns = ['product_sr_name as product_material_name', 'customer_order_no', 'process_duration', 'expected_schedule_start_date as expected_service_schedule_start_date', 'expected_schedule_end_date as expected_service_schedule_end_date', 'product_sr_std_hours', 'product_material_id', 'product_type_id', 'sales_order_schedules_transaction_id', 'sales_order_no', 'sales_order_version']
            globalQuery.table = "mtv_sales_order_schedules_services"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "sales_order_schedules_services_item_status", operator: "IN", values: ['A'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["expected_schedule_start_date"];
            if (cmb_customer_id !== '' && cmb_customer_id !== '0') {
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id });
            }

            if (cmb_service_id !== '' && cmb_service_id !== '0') {
                globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: cmb_service_id });
            }
            if (dt_from_date !== '') {
                globalQuery.conditions.push({ field: "expected_schedule_start_date", operator: ">=", value: dt_from_date });
            }
            if (dt_to_date !== '') {
                globalQuery.conditions.push({ field: "expected_schedule_end_date", operator: "<=", value: dt_to_date });
            }
            const getSchedules = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            if (getSchedules.length > 0) {
                getSchedules.forEach((schedule) => {
                    schedule.assign_to_employee_id = '';
                    // schedule.expected_service_schedule_start_date = schedule.expected_schedule_start_date;
                    // schedule.expected_service_schedule_end_date = schedule.expected_schedule_end_date;
                });
                setServiceSchedulingTblData(getSchedules);
                $("input[type='checkbox'][name='selectServiceSchedule']").prop('checked', false);   // uncheck all previous service schedules.
                $('#error_schedules').hide();
                setActivitiesTblData([]);
            } else {
                $('#error_schedules').text('No schedules are available...!');
                $('#error_schedules').show();
                setServiceSchedulingTblData([]);
                setActivitiesTblData([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnDisplayServiceSchedulingTbl = useMemo(() => {
        return <>
            {
                serviceSchedulingTblData.length > 0
                    ? <>
                        <Table className="erp_table" id='serviceSchedulesTbl' bordered striped>
                            <thead className="erp_table_head">
                                <tr>
                                    <th className={`erp_table_th`}>Action</th>
                                    <th className={`erp_table_th`}>Service Name</th>
                                    <th className={`erp_table_th`}>Cust. Ord. No.</th>
                                    <th className={`erp_table_th`}>Process Duration</th>
                                    <th className={`erp_table_th`}>STD. Hrs.</th>
                                    <th className={`erp_table_th`}>Exp. Start Date</th>
                                    <th className={`erp_table_th`}>Exp. End Date</th>
                                    <th className={`erp_table_th`} style={{ width: "200px" }}>
                                        <div style={{ display: "flex" }}>
                                            <span>Assign To</span>
                                            {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                ? <>
                                                    <select id={`cmb_assign_all_to`} value={cmb_assign_all_to}
                                                        onChange={(e) => { FnAssignedToAllSchedules(e); setAssignAllTo(e.target.value); }}
                                                        className="form-select form-select-sm ms-2 mb-0 erp_input_field" >
                                                        <option value="">Select</option>
                                                        {assignToEmplsOpts?.map(employee => (<option value={employee.field_id}>{employee.field_name}</option>))}
                                                    </select>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </th>
                                    <th className={`erp_table_th`}>Service ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    serviceSchedulingTblData?.map((serviceSchedule, Index) =>
                                        <tr rowIndex={Index} serviceScheduleId={serviceSchedule.sales_order_schedules_transaction_id}>
                                            <td className={`erp_table_td`}>
                                                <div style={{ display: "flex" }}>
                                                    <Form.Check className="erp-form-label selectServiceSchedule" type="checkbox"
                                                        name="selectServiceSchedule" id={'selectServiceSchedule_' + Index}
                                                        serviceScheduleId={serviceSchedule.sales_order_schedules_transaction_id}
                                                        onChange={async (e) => { FnCheckBoxOnChage('PartiallyServiceSchedulesSelection'); FnRemoveActivityOnUnCheck(serviceSchedule, Index); }} optional="optional"
                                                        {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                    />
                                                    <AiOutlineSchedule className="erp-schedule-btn mt-1 ms-1" onClick={(e) => { FnShowActivities(serviceSchedule, Index); }} />
                                                </div>
                                            </td>
                                            <td className={`erp_table_td`}>{serviceSchedule.product_material_name}</td>
                                            <td className={`erp_table_td`}>{serviceSchedule.customer_order_no}</td>
                                            <td className={`erp_table_td`}>{serviceSchedule.process_duration}</td>
                                            <td className={`erp_table_td text-end`}>{serviceSchedule.product_sr_std_hours}</td>

                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control type="date" id={'expected_service_schedule_start_date_' + Index} className="erp_input_field mb-0"
                                                                value={serviceSchedule.expected_service_schedule_start_date} min={currentDate}
                                                                onChange={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}
                                                                Headers='expected_service_schedule_start_date' />
                                                        </>
                                                        : validateNumberDateInput.current.formatDateToDDMMYYYY(serviceSchedule.expected_service_schedule_start_date)
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control type="date" id={'expected_service_schedule_end_date_' + Index} className="erp_input_field mb-0"
                                                                value={serviceSchedule.expected_service_schedule_end_date} min={currentDate}
                                                                onChange={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}
                                                                Headers='expected_service_schedule_end_date' />
                                                        </>
                                                        : validateNumberDateInput.current.formatDateToDDMMYYYY(serviceSchedule.expected_service_schedule_end_date)
                                                }
                                            </td>

                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`assign_to_employee_id_${Index}`} value={serviceSchedule.assign_to_employee_id} className="form-select form-select-sm emplCombo"
                                                                Headers="assign_to_employee_id" onChange={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}>
                                                                <option value="">Select</option>
                                                                {assignToEmplsOpts?.map(employee => (<option value={employee.field_id}>{employee.field_name}</option>))}
                                                            </select>
                                                        </>
                                                        : serviceSchedule.assign_to_employee_name
                                                }
                                            </td>

                                            <td className={`erp_table_td`}>{serviceSchedule.product_material_id}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </>
                    : null
            }
        </>
    }, [serviceSchedulingTblData, assignToEmplsOpts, activitiesTblData]);


    // Set all service schedule to particular employee.
    const FnAssignedToAllSchedules = (event) => {
        // Update the Service Schedules tbl data.
        const updatedShedulesData = serviceSchedulingTblData.map(serviceSchedule => ({ ...serviceSchedule, assign_to_employee_id: event.target.value }));
        setServiceSchedulingTblData(updatedShedulesData);

        // Also Update the Service Schedules Activity tbl data.
        const updatedSheduleActivityData = activitiesTblData.map(serviceSchedule => ({ ...serviceSchedule, assign_to_employee_id: event.target.value }));
        setActivitiesTblData(updatedSheduleActivityData);

        // Remove all error tooltips from assign_to_employee combo.
        let assignToEmplCombos = document.querySelectorAll('.emplCombo');
        assignToEmplCombos.forEach(assignToEmplCombo => {
            delete assignToEmplCombo.parentElement.dataset.tip;
        });
    }

    const FnUpdateSchedulingTblData = (currentSchedule, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        switch (clickedColName) {
            case 'assign_to_employee_id':
            case 'expected_service_schedule_start_date':
            case 'expected_service_schedule_end_date':
            case 'activities':
                currentSchedule[clickedColName] = event.target.value.toString();
                break;

            default:
                break;
        }

        const updatedShedulingData = [...serviceSchedulingTblData]
        const currentScheduleIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedShedulingData[currentScheduleIndex] = currentSchedule;
        setServiceSchedulingTblData(updatedShedulingData);
        // Check the checkbox on input-field change.
        $('#selectServiceSchedule_' + currentScheduleIndex).prop('checked', true);
        delete event.target.parentElement.dataset.tip;              // Delete the tooltip.
    }
    // ******************************* Service Scheduling Functions Ends. *************************************

    // ******************************* Show Service Activities Functionality Starts.  *************************
    // ----------------- Schedule Activities Table In Accordion. ---------------------
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [selectedServSchForActivity, setSelectedServSchForActivity] = useState(null);
    const [activitiesTblDataForPopUpModal, setActivitiesTblDataForPopUpModal] = useState([]);
    const FnCloseActivityModal = () => {
        setShowActivityModal(false);
        setActivitiesTblDataForPopUpModal([]);
        setSelectedServSchForActivity(null);
    }

    // load the activities related selected service schedule and show it in modal.
    const FnShowActivities = async (selectedServiceSchedule, rowIndex) => {
        $('#selectServiceSchedule_' + rowIndex).prop('checked', true);

        // First validate that service schedule.
        if (selectedServiceSchedule.assign_to_employee_id === '') {
            $(`#assign_to_employee_id_${rowIndex}`)[0].parentElement.dataset.tip = 'Please select assignee...!';
            return false;
        } else if (selectedServiceSchedule.expected_service_schedule_start_date === '') {
            $(`#expected_service_schedule_start_date_${rowIndex}`)[0].parentElement.dataset.tip = 'Please select expected start date...!';
            return false;
        } else if (selectedServiceSchedule.expected_service_schedule_end_date === '') {
            $(`#expected_service_schedule_end_date_${rowIndex}`)[0].parentElement.dataset.tip = 'Please select expected end date...!';
            return false;
        }

        setIsLoading(true);
        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "mtv_sales_order_service_activities"
        globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: selectedServiceSchedule.product_material_id });
        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: selectedServiceSchedule.customer_order_no });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

        const getServiceActivities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        // Check Already Activities are selected or not?.
        let alreadySelectedActivities = activitiesTblData.filter(
            activity => activity.product_material_id === selectedServiceSchedule.product_material_id && activity.customer_order_no === selectedServiceSchedule.customer_order_no
        );

        if (alreadySelectedActivities.length > 0) {
            // Iterate over the second array
            getServiceActivities.forEach((serviceActivityFromDb) => {
                // Find the matching object in the first array
                const matchedActivity = alreadySelectedActivities.find((activity) => activity.sales_order_activity_transaction_id === serviceActivityFromDb.sales_order_activity_transaction_id);
                // If a match is found, add the remark from the first array to the second array object
                if (matchedActivity) {
                    serviceActivityFromDb.remark = matchedActivity.remark;
                }
            });
            setActivitiesTblDataForPopUpModal(getServiceActivities);
        } else {
            setActivitiesTblDataForPopUpModal(getServiceActivities);        // Set the selected material's activities.
        }

        // assign_to_employee_name
        let assignedEmployee = assignToEmplsOpts.find(empl => empl.field_id === parseInt(selectedServiceSchedule.assign_to_employee_id));
        selectedServiceSchedule.assign_to_employee_name = assignedEmployee ? assignedEmployee.field_name : '';
        setSelectedServSchForActivity(selectedServiceSchedule);         // set the selected material.

        setShowActivityModal(true);
        // Check the checked checkboxes if already services added..
        if (alreadySelectedActivities.length > 0) {
            alreadySelectedActivities.forEach(function (alreadySelectedActivities) {
                $('#selectServiceSchActivity_' + alreadySelectedActivities.sales_order_activity_transaction_id).prop('checked', true);
            });
        }
        setIsLoading(false);
    }

    const FnDisplayActivitiesTblForPopupModal = useMemo(() => {
        return <>
            <Table className="erp_table px-0" id="scheduleTable" bordered striped hover>
                <thead className='erp_table_head'>
                    <tr>
                        <th className="erp_table_th" style={{ paddingRight: '10px' }}>Action</th>
                        <th className="erp_table_th">Activity Name</th>
                        <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>Activity Desc.</th>
                        <th className="erp_table_th">Activity Std. Hrs.</th>
                        <th className="erp_table_th">From Range</th>
                        <th className="erp_table_th">To Range</th>
                        <th className="erp_table_th" style={{ paddingRight: '200px' }}>Remark</th>
                    </tr>
                </thead>
                <tbody className="erp_table_td">
                    {
                        activitiesTblDataForPopUpModal?.map((activity, index) =>
                            <tr rowIndex={index}>
                                <td className={`erp_table_td`}>
                                    <div style={{ display: "flex" }}>
                                        <input type="checkbox" className="selectServiceSchActivity form-check-input" name="selectServiceSchActivity" value={activity.sales_order_activity_transaction_id} id={`selectServiceSchActivity_${activity.sales_order_activity_transaction_id}`}  {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})} />
                                    </div>
                                </td>
                                <td className="erp_table_td ">{activity.product_material_activity_name}</td>
                                <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_description}</td>
                                <td className="erp_table_td text-end">{activity.product_sr_activity_std_hour}</td>
                                <td className="erp_table_td ">{activity.from_range}</td>
                                <td className="erp_table_td ">{activity.to_range}</td>
                                <td className={`erp_table_td`}>
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <>
                                                <Form.Control as="textarea" id={'remark_' + index} className="erp_input_field mb-0" value={activity.remark}
                                                    Headers="remark" onChange={(e) => { FnUpdateActivityTblDataInModal(activity, e); }} maxlength="1000" />
                                            </>
                                            : activity.remark
                                    }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [activitiesTblDataForPopUpModal]);

    // Modified the Data of activities table in modal.
    const FnUpdateActivityTblDataInModal = (currentActivity, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentActivity[clickedColName] = event.target.value.toString();
        const updatedActivityTblDataInModal = [...activitiesTblDataForPopUpModal]
        const currentActivityIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedActivityTblDataInModal[currentActivityIndex] = currentActivity;
        setActivitiesTblDataForPopUpModal(updatedActivityTblDataInModal);

        // Checked the checkbox on input field change.
        $('#selectServiceSchActivity_' + currentActivity.sales_order_activity_transaction_id).prop('checked', true);
    }

    const FnMapActivitiesAgainstSRSch = (selectedSRSch, activitiesAgainstSrSch) => {
        setIsLoading(true);

        let existingActivities = [...activitiesTblData].filter(
            activity => activity.product_material_id !== selectedSRSch.product_material_id || activity.customer_order_no !== selectedSRSch.customer_order_no
        );

        $("input:checkbox[name=selectServiceSchActivity]:checked").each(function () {
            let foundedActivity = activitiesAgainstSrSch.find(item => item.sales_order_activity_transaction_id === parseInt($(this).val()));
            if (foundedActivity) {
                const acitivity = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    product_material_id: selectedSRSch.product_material_id,
                    sales_order_master_transaction_id: foundedActivity.sales_order_master_transaction_id,               // SO Service Master Id.
                    sales_order_schedules_transaction_id: selectedSRSch.sales_order_schedules_transaction_id,           // SO Schedule Id.
                    sales_order_activity_transaction_id: foundedActivity.sales_order_activity_transaction_id,           // SO Service Activity Id.
                    product_activity_id: foundedActivity.product_activity_id,
                    customer_order_no: foundedActivity.customer_order_no,
                    customer_name: foundedActivity.customer_name,
                    customer_id: foundedActivity.customer_id,
                    product_material_name: selectedSRSch.product_material_name,
                    product_material_activity_name: foundedActivity.product_material_activity_name,
                    product_material_activity_description: foundedActivity.product_material_activity_description,
                    product_sr_activity_std_hour: foundedActivity.product_sr_activity_std_hour,
                    from_range: foundedActivity.from_range,
                    to_range: foundedActivity.to_range,
                    remark: foundedActivity.remark,
                    assign_to_employee_id: selectedSRSch.assign_to_employee_id,
                    assign_to_employee_name: selectedSRSch.assign_to_employee_name,
                    created_by: UserName,
                }
                existingActivities.push(acitivity);
            }
        });
        setActivitiesTblData([...existingActivities]);
        setShowActivityModal(false);
        setIsLoading(false);
    }

    // ********************* Schedule Activities Table In Accordion. ************************************
    // Table In Accordion.
    const FnDisplayActivitiesTbl = useMemo(() => {
        return <>
            {
                activitiesTblData.length > 0
                    ? <>
                        <Table className="erp_table px-0" id="scheduleTable" bordered striped hover>
                            <thead className='erp_table_head'>
                                <tr>
                                    <th className="erp_table_th">Cust. Ord. No.</th>
                                    <th className="erp_table_th">Assign To</th>
                                    <th className="erp_table_th">Service Name</th>
                                    <th className="erp_table_th">Activity Name</th>
                                    <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>Activity Desc.</th>
                                    <th className="erp_table_th">Std. Hrs.</th>
                                    <th className="erp_table_th" style={{ paddingRight: '200px' }}>From Range</th>
                                    <th className="erp_table_th" style={{ paddingRight: '200px' }}>To Range</th>
                                    <th className="erp_table_th" style={{ paddingRight: '200px' }}>Remark</th>
                                </tr>
                            </thead>
                            <tbody className="erp_table_td">
                                {
                                    activitiesTblData?.map((activity, index) =>
                                        <tr rowIndex={index}>
                                            <td className="erp_table_td ">{activity.customer_order_no}</td>
                                            <td className="erp_table_td ">{activity.assign_to_employee_name}</td>
                                            <td className="erp_table_td ">{activity.product_material_name}</td>
                                            <td className="erp_table_td ">{activity.product_material_activity_name}</td>
                                            <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_description}</td>
                                            <td className="erp_table_td text-end">{activity.product_sr_activity_std_hour}</td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'from_range_' + index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.from_range}
                                                                Headers="from_range" onChange={(e) => { FnUpdateActivityTblDataInAccordion(activity, e); }}
                                                                maxlength="1000" />
                                                        </>
                                                        : activity.from_range
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'to_range_' + index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.to_range}
                                                                Headers="to_range" onChange={(e) => { FnUpdateActivityTblDataInAccordion(activity, e); }}
                                                                maxlength="1000" />
                                                        </>
                                                        : activity.to_range
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'remark_' + index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.remark}
                                                                Headers="remark" onChange={(e) => { FnUpdateActivityTblDataInAccordion(activity, e); }}
                                                                maxlength="1000" />
                                                        </>
                                                        : activity.remark
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </>
                    : null
            }
        </>
    }, [activitiesTblData]);

    // For make the changes into data of activity table in accordion.
    const FnUpdateActivityTblDataInAccordion = (currentActivity, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentActivity[clickedColName] = event.target.value.toString();
        const updatedActivityTblDataInAccordion = [...activitiesTblData]
        const currentActivityIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedActivityTblDataInAccordion[currentActivityIndex] = currentActivity;
        setActivitiesTblData(updatedActivityTblDataInAccordion);
    }

    // If Uncheck the Service Schedule then remove it's realated activities.
    const FnRemoveActivityOnUnCheck = (currentServiceSchedule, currentRowIndex) => {
        let serviceSheduleIsChecked = $(`selectServiceSchedule_${currentRowIndex}`).prop('checked')
        if (!serviceSheduleIsChecked) {
            let existingActivities = activitiesTblData.filter(
                activity => activity.product_material_id !== currentServiceSchedule.product_material_id || activity.customer_order_no !== currentServiceSchedule.customer_order_no
            );
            setActivitiesTblData(existingActivities);
        }
    }
    // ******************************* Show Service Activities Functionality Ends.  *************************

    // ******************************* Common Functions Ends. *************************************
    const FnCheckBoxOnChage = (checkboxType) => {
        switch (checkboxType) {
            // For Services Schedules
            case "selectServiceSchedules":
                $('.selectServiceSchedule').prop('checked', $('#selectServiceSchedules').is(":checked"));
                break;
            case 'PartiallyServiceSchedulesSelection':
                $('#selectServiceSchedules').prop('checked', $('input:checkbox.selectServiceSchedule:checked').length === $('input:checkbox.selectServiceSchedule').length);
                break;

            default:
                break;
        }
    }

    // Validate Form. (For remove the error msgs.)
    const validateFields = () => {
        validateFrms.current.validateFieldsOnChange('SalesInvoiceHeaderFormId')
        validateFrms.current.validateFieldsOnChange('SalesInvoiceFooterFormId')
    }

    // Set form as readonly.
    const formAsReadOnly = async () => {
        await validateFrms.current.readOnly("SalesInvoiceHeaderFormId");
        await validateFrms.current.readOnly("SalesInvoiceFooterFormId");
    }

    // Validate Service Planning.
    const FnValidateServicePlanning = () => {
        let isValidServicePlanning = false;
        // First Check atleast one Service Schedule will be selected.
        // let totalSelectedServiceSchedules = $('input:checkbox.selectServiceSchedule:checked').length;        // Using classname.
        const totalSelectedServiceSchedules = $("input[type='checkbox'][name='selectServiceSchedule']:checked").length;
        if (totalSelectedServiceSchedules === 0) {
            setErrMsg('Please Select atleast one service schedule....!');
            setShowErrorMsgModal(true);
            return false;
        }


        // Now Validate atleast one activity will be present into the service activity.
        let checkedServiceScheduleIds = [];
        let checkedServiceSchedules = $('input[type="checkbox"][name="selectServiceSchedule"]:checked');
        checkedServiceSchedules.each(function () {
            if ($(this).attr('serviceScheduleId')) {
                checkedServiceScheduleIds.push(parseInt($(this).attr('serviceScheduleId')));
            }
        });

        let availableActivityDataMap = new Map(activitiesTblData.map(activity => [activity.sales_order_schedules_transaction_id, activity]));
        checkedServiceScheduleIds.forEach(scheduleId => {
            if (!availableActivityDataMap.has(scheduleId)) {
                let nonAddedActivitySchedule = serviceSchedulingTblData.find(schedule => schedule.sales_order_schedules_transaction_id === scheduleId);
                console.log('NonSelected For: ', nonAddedActivitySchedule);
                isValidServicePlanning = false;

                setErrMsg(`Please Select atleast one  activity For....! Service Name: ${nonAddedActivitySchedule.product_material_name} & Customer Ord. No. : ${nonAddedActivitySchedule.customer_order_no}`,);
                setShowErrorMsgModal(true);

                return false;
            }
        });

        if (!isValidServicePlanning) {
            return false;
        }


    }

    // ******************************* Common Functions Ends. *************************************

    // ******************************* CRUD Functionality Starts. ********************************
    const FnSaveServicePlanning = () => {
        let isValidServicePlanning = FnValidateServicePlanning();
    }

    // ******************************* CRUD Functionality Ends. ********************************

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TServicePlanning/ServicePlanningListing/reg' : `/Transactions/TServicePlanning/ServicePlanningListing`;
        navigator(Path);
    }

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validateFrms} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />

                {isLoading
                    ? <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div>
                    : ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-1'>
                        <label className='erp-form-label-lg text-center'>Services Planning {ActionType()}</label>
                    </div>

                    <div className="card-body">
                        <div className="row p-1">
                            {/* First column */}
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Customer </Form.Label>
                                    </div>
                                    <div className='col-sm-9'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { setCustomerId(e.target.value); }} optional="optional">
                                            <option value="">Select</option>
                                            <option value="0">All</option>
                                            {customersOpts?.map(customer => (
                                                <option value={customer.field_id}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">From Date </Form.Label>
                                    </div>
                                    <div className='col-sm-9'>
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDate(e.target.value); }} max={dt_to_date} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* Second column */}
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Service </Form.Label>
                                    </div>
                                    <div className='col-sm-9'>
                                        <select id="cmb_service_id" className="form-select form-select-sm" value={cmb_service_id} onChange={(e) => { setServiceId(e.target.value); }} optional="optional">
                                            <option value="">Select</option>
                                            <option value="0">All</option>
                                            {servicesOpts?.map(service => (
                                                <option value={service.field_id}>{service.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_service_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">To Date </Form.Label>
                                    </div>
                                    <div className='col-sm-9'>
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDate(e.target.value); }} min={dt_from_date} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* Third column */}
                            <div className="col-sm-4">

                            </div>
                        </div>

                        <div className="text-start mb-3">
                            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => { FnGetSchedules(); }}>Show Schedules</MDButton>
                        </div >


                        <Accordion defaultActiveKey='1'>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header className="erp-form-label-md">Services Schedules</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="row">
                                        <div className={`table-responsive ${serviceSchedulingTblData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                            <> {FnDisplayServiceSchedulingTbl}</>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <MDTypography variant="button" id="error_schedules" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <hr />
                        <Accordion defaultActiveKey='0'>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header className="erp-form-label-md">Activities</Accordion.Header>
                                <Accordion.Body className="py-1">
                                    <div className="row">
                                        <div className={`table-responsive ${activitiesTblData.length > 0 ? 'erp_table_scroll' : ''} px-0`}>
                                            <> {FnDisplayActivitiesTbl}</>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <MDTypography variant="button" id="error_activities_tbl" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>

                </div>

                <hr />
                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => FnSaveServicePlanning()}>Save</MDButton>
                </div>


                {/* Modal For Show Activities */}
                <Modal size="lg" className='erp_document_Form' show={showActivityModal} onHide={FnCloseActivityModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header className="py-2">
                        <Modal.Title className='erp_modal_title'>Service Activities</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseActivityModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className="">
                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Service Name:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? selectedServSchForActivity.product_material_name : ''}</dd>
                                </dl>
                            </div>
                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Customer Ord. No.:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? selectedServSchForActivity.customer_order_no : ''}</dd>
                                </dl>
                            </div>
                        </dl>

                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Assign To:</dt>
                                    <dd className="col-sm-8 erp-form-label"> {selectedServSchForActivity !== null ? selectedServSchForActivity.assign_to_employee_name : ''} </dd>
                                </dl>
                            </div>
                            {/* <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Actual Start Date:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? selectedServSchForActivity.actual_start_date : ''}</dd>
                                </dl>
                            </div> */}
                        </dl>

                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Exp. Start Date:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(selectedServSchForActivity.expected_service_schedule_start_date) : ''}</dd>
                                </dl>
                            </div>
                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Exp. Start End:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(selectedServSchForActivity.expected_service_schedule_end_date) : ''}</dd>
                                </dl>
                            </div>
                        </dl>

                        <div className='row px-1'>
                            <div className="table-responsive erp_table_scroll px-1">
                                {FnDisplayActivitiesTblForPopupModal}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => FnMapActivitiesAgainstSRSch(selectedServSchForActivity, activitiesTblDataForPopUpModal)}>Add Activities</MDButton>
                    </Modal.Footer>
                </Modal>

                {/* Generic Response Messaage Modals */}
                <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => FnCloseErrorModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>

        </>
    )
}

export default TServicePlanningEntry;