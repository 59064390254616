import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $, { event } from "jquery";

//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";

//React Bootstrap components
import Tooltip from "@mui/material/Tooltip";
import { Form, Button } from "react-bootstrap";
import { Accordion, Modal, Table } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

// React icons
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FrmTaxationEntry from "Masters/MTaxation/FrmTaxationEntry";
import { Padding } from "@mui/icons-material";
import TaxSummary from "Transactions/TPurchaseOrder/GoodsReceiptNote/FrmGoodsReceiptNoteEntry/TaxSummary";


function FrmBillBookEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;


    var expanded = false;
    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType, requestfor, modules_forms_id } = state || {};

    // UseRefs
    const navigate = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();

    //combo options
    const [producttypeOptions, setProductTypeOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [good_receiptNoList, setGoodReceiptNoList] = useState([])
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [tax1Options, setTax1Options] = useState([])
    // const [tax2Options, setTax2Options] = useState([])
    const [checkedGoodReciptNoLength, setCheckedGoodReciptNoLength] = useState(0);

    //common hooks
    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const [isApprove, setIsApprove] = useState(false);
    const [isView, setIsView] = useState(false);
    const [financial_year, setFinancial_year] = useState(idList !== '' ? idList.financial_year : FINANCIAL_SHORT_NAME);


    //master's hooks
    const [supplier_bill_booking_master_transaction_id, setSupplier_bill_booking_master_transaction_id] = useState(idList !== '' ? idList.supplier_bill_booking_master_transaction_id : 0)
    const [chk_biilBook_creation_type, setchk_biilBook_creation_type] = useState('A')
    const [txt_supplier_bill_booking_no, setsupplier_bill_booking_no] = useState(idList !== '' ? idList.supplier_bill_booking_no : '0')
    const [txt_supplier_bill_booking_version, setSupplier_bill_booking_version] = useState(idList !== '' ? idList.supplier_bill_booking_version : 1)
    const [txt_supplier_bill_booking_date, setSupplier_bill_booking_date] = useState(currentDate);
    const [cmb_supplier_bill_booking_type_id, setSupplier_bill_booking_type_id] = useState('')
    const [cmb_supplier_id, setSupplier_id] = useState("");
    const [txt_supplier_contacts_ids, setsupplier_contacts_ids] = useState('')
    const [cmb_expected_branch_id, setExpected_branch_id] = useState(COMPANY_BRANCH_ID);
    const [cmb_approved_by_id, setApproved_by_id] = useState("");
    const [txt_approved_date, setApproved_date] = useState("");
    const [cmb_finance_approved_by_id, setFinance_approved_by_id] = useState("");
    const [txt_finance_approved_date, setFinance_approved_date] = useState("");
    const [chk_billBook_status, setBillBook_status] = useState('B')
    const [txt_total_amount, setTotal_amount] = useState(0)
    const [txt_tax1_id, setTax1_id] = useState('')
    const [txt_tax1_percent, setTax1_percent] = useState(0)
    const [txt_tax1_amount, setTax1_amount] = useState(0)
    const [txt_tax2_id, setTax2_id] = useState('')
    const [txt_tax2_percent, setTax2_percent] = useState(0)
    const [txt_tax2_amount, setTax2_amount] = useState(0)
    const [txt_deduction_amount, setDeduction_amount] = useState(0)
    const [txt_payable_amount, setPayable_amount] = useState(0)
    const [txt_deduction_remark, setDeduction_remark] = useState('')
    const [txt_remark, setRemark] = useState('')
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'financeApprove':
                return '(Finance Approve)';
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                return '(View)';
            case 'cancel':
                return '(cancelation)';
            case 'delete':
                return '(Delete)';
            default:
                return '(Creation)';
        }
    }
    //key for get Request type header
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'RC':
                return 'Raw Cotton';
            case 'YN':
                return 'Cotton Yarn';
            case 'GF':
                return 'Greige Fabric';
            default:
                return '';
        }
    }
    // ------------------------------------------------------------- Master hook Ends-------------------------------------------------

    // Supplier contact details table data hooks
    const [suppContactDetails, setSuppContactDetails] = useState([]);
    // bill book Table Data
    const [billBookDetailsData, setBillBookDetailsData] = useState([])
    const [storedBillBookDetailsData, setStoredBillBookDetailsData] = useState([])
    const [paymentTaxData, setPaymentTaxData] = useState([]);

    // Popup Fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // Document Form
    let docGroup = "Bill Book"
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => {
        showDocumentRecords();
        setShowDocumentForm(false)
    }
    const viewDocumentForm = () => setShowDocumentForm(true);
    // doc list
    const [docData, setDocumentData] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    // Confirmation Modal fields
    const [modalOrderDetails, setModalOrderDetails] = useState('')
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState('')
    const closeConfirmationModal = () => {
        setShowConfiemationModal(false);
        moveToListing();
    }
    // Fn to get GRN modal with items
    const [showGRNModal, setViewGRNModal] = useState(false);
    const viewGRNModal = async () => {
        let checkIsValidate = await validate.current.validateForm("billBook_header_formId");
        if (checkIsValidate === true) {
            loadGRNotesList();
            setViewGRNModal(true);
        }
    }

    // // After approved mail sent confirmation modal
    // const handleCloseMailSentModal = () => {
    //     setShowMailSentModal(false);
    //     moveToListing();
    // }
    // const [showMailSentModal, setShowMailSentModal] = useState(false);

    useEffect(async () => {
        try {
            setIsLoading(true)
            ActionType();
            await FnloadDefaultData();
            if (idList !== '') {
                await FnCheckUpdateResponse();
            }
            FnSetFieldsByUserAccess();

        } catch (error) {
            console.error(error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            var container = $("#good-receipt-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [requestfor])

    useEffect(() => {

        if (billBookDetailsData.lenghth !== 0) {
            FnCalculatePayableAmount();
        }
    }, [billBookDetailsData])

    // This edffect call when Grn Details Data hook update
    // useEffect(() => {
    //     const FunctionCall = () => {
    //         if (billBookDetailsData.length !== 0) {
    //             calculateTaxationSummary(billBookDetailsData)
    //         }
    //     }
    //     FunctionCall();
    // }, [billBookDetailsData])



    const FnClearFormFields = () => {
        setSupplier_bill_booking_master_transaction_id(0);
        setchk_biilBook_creation_type('A');
        setsupplier_bill_booking_no('0');
        // GenerateBillBookNo();
        setSupplier_bill_booking_version('1');
        setSupplier_bill_booking_date(currentDate);
        setSupplier_bill_booking_type_id('');
        setSupplier_id('');
        setsupplier_contacts_ids('');
        setExpected_branch_id(COMPANY_BRANCH_ID);
        setApproved_by_id('');
        setApproved_date('');
        setFinance_approved_by_id('');
        setFinance_approved_date('');
        setBillBook_status('B');
        setTotal_amount('');
        setTax1_id('');
        setTax1_percent(0);
        setTax1_amount(0);
        setTax2_id('');
        setTax2_percent(0);
        setTax2_amount(0);
        setDeduction_amount('');
        setPayable_amount('');
        setDeduction_remark('');
        setRemark('');

        // Set default values for other state variables
        setSuppContactDetails([]);
        setBillBookDetailsData([]);
        setStoredBillBookDetailsData([]);
        setPaymentTaxData([])
        setGoodReceiptNoList([])
        setCheckedGoodReciptNoLength(0)
        $('#error_supplier_contact_persons').hide();
    }

    const validateFields = (form_id) => {
        var formObj = $("#" + form_id);
        var inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === "text" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "select-one" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "textarea" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "date" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            }
        }
    };
    const refreshData = async (key) => {

        switch (key) {
            case 'get_expectedBranch':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("company_branch_id");
                globalQuery.columns.push("company_branch_name");
                globalQuery.columns.push("is_sez");
                globalQuery.columns.push("branch_EmailId");
                globalQuery.table = "cmv_company_branch";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const exptBranchApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setExpectedBranchOptions(exptBranchApiCall);
                break;
            case 'get_supplier':
                // get suppliers list with default state & city
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_supplier";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setSupplierOptions(suppliersApiCall)
                break;
            case 'get_tax':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("tax_value");
                globalQuery.table = "cmv_taxation";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const taxationApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setTax1Options(taxationApiCall);
                break;
            default:
                break;
        }
    }
    //Fn to get default preload data
    const FnloadDefaultData = async () => {
        // get product types list
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const getProductType = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setProductTypeOptions(getProductType);
        if (requestfor !== "NA") {
            if (getProductType.length !== 0) {
                const productType = getProductType.find((item) => item.product_type_short_name === requestfor);
                if (productType) {
                    setSupplier_bill_booking_type_id(productType.field_id);
                    await GenerateBillBookNo(productType.product_type_short_name);
                }
            }
        }

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_supplier";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((suppliersApiCall) => {
            setSupplierOptions(suppliersApiCall)
        })


        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("company_branch_id");
        globalQuery.columns.push("company_branch_name");
        globalQuery.columns.push("is_sez");
        globalQuery.columns.push("branch_EmailId");
        globalQuery.table = "cmv_company_branch";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((exptBranchApiCall) => {
            setExpectedBranchOptions(exptBranchApiCall);
        })


        comboDataAPiCall.current.fillMasterData("cmv_employee", "", "").then((getEmployees) => {
            setApprovedByOptions(getEmployees);
        })


        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("tax_value");
        globalQuery.table = "cmv_taxation";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((taxationApiCall) => {
            setTax1Options(taxationApiCall);
        })
    }
    //Fn for generate bill booking no
    const GenerateBillBookNo = async (product_typeShortName) => {
        const shortname = product_typeShortName === undefined ? $('#cmb_supplier_bill_booking_type_id option:selected').attr('shortname') : product_typeShortName;
        const productTypeId = $('#cmb_supplier_bill_booking_type_id option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("ft_supplier_bill_booking_master", "supplier_bill_booking_no", shortname, "SB", "5", "supplier_bill_booking_type_id", productTypeId);
        setsupplier_bill_booking_no(autoNoApiCall);
        return autoNoApiCall;
    }
    // fn for fields onchange action
    const comboOnChange = async (key) => {
        switch (key) {
            case "productType":
                var productTypeVal = document.getElementById("cmb_supplier_bill_booking_type_id").value;
                setSupplier_bill_booking_type_id(productTypeVal);
                if (productTypeVal !== '0' && productTypeVal !== "") {
                    $("#error_cmb_supplier_bill_booking_type_id").hide();
                    GenerateBillBookNo();
                } else if (productTypeVal === '0') {
                    setSupplier_bill_booking_type_id(productTypeVal)
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setsupplier_bill_booking_no("0");

                } else {
                    setsupplier_bill_booking_no("0");
                }
                break;
            case "supplier":
                let supplierTpVal = document.getElementById("cmb_supplier_id").value;
                setSupplier_id(supplierTpVal);
                if (supplierTpVal === '0') {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Supplier')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (supplierTpVal != null && supplierTpVal != '' & supplierTpVal != '0') {
                    await FnGetSupplierContacts(parseInt(supplierTpVal));
                    setBillBookDetailsData([])
                } else {
                    setCheckedGoodReciptNoLength(0)
                    setGoodReceiptNoList([])
                    setSuppContactDetails([])
                    setBillBookDetailsData([])
                }
                break;
            case "expectBranch":
                let exptBranchIdVal = document.getElementById("cmb_expected_branch_id").value;
                setExpected_branch_id(exptBranchIdVal);
                if (exptBranchIdVal === '0') {
                    const newTab = window.open('Masters/Company', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (exptBranchIdVal != null && exptBranchIdVal != '' & exptBranchIdVal != '0') {
                    loadGRNotesList();
                    setBillBookDetailsData([])
                } else {
                    setCheckedGoodReciptNoLength(0)
                    setGoodReceiptNoList([])
                    setBillBookDetailsData([])
                }
                break;

                break;
            case "tax1":
                var tax_1Val = document.getElementById("txt_tax1_id").value;
                setTax1_id(tax_1Val);
                if (tax_1Val !== '0' && tax_1Val !== "") {
                    const tax_percent = $('#txt_tax1_id option:selected').attr('tax_value');
                    setTax1_percent(tax_percent)
                    FnCalculateTaxAmount(tax_percent, 'tax1amount');
                } else if (tax_1Val === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Taxation')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setTax1_percent(0);
                    setTax1_amount(0);
                } else {
                    setTax1_percent(0);
                    setTax1_amount(0);
                }
                setTimeout(() => {
                    FnCalculatePayableAmount();
                }, 300);
                break;
            case "tax2":
                var tax_2Val = document.getElementById("txt_tax2_id").value;
                setTax2_id(tax_2Val);
                if (tax_2Val !== '0' && tax_2Val !== "") {
                    const tax_percent = $('#txt_tax2_id option:selected').attr('tax_value');
                    setTax2_percent(tax_percent)
                    FnCalculateTaxAmount(tax_percent, 'tax2amount')
                } else if (tax_2Val === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Taxation')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setTax2_percent(0);
                    setTax2_amount(0);
                } else {
                    setTax2_percent(0);
                    setTax2_amount(0);
                }
                setTimeout(() => {
                    FnCalculatePayableAmount();
                }, 300);
                break;
            default:
                break;
        }
    };
    //fn for User Access Manage .
    const FnSetFieldsByUserAccess = () => {
        if (keyForViewUpdate != null) {
            setIsApprove(keyForViewUpdate === 'approve' ? true : false);
            $('#cancel-btn').hide();
            $('#finance-btn').hide();

            switch (keyForViewUpdate) {
                case 'update':
                    $('#cmb_supplier_bill_booking_type_id').attr('disabled', true);
                    $('#txt_supplier_bill_booking_date').attr('disabled', true);
                    $('#cmb_supplier_id').attr('disabled', true);
                    $('#cmb_expected_branch_id').attr('disabled', true);
                    $('#save-btn').text('Update');
                    break;
                case 'cancel':
                case 'financeApprove':
                case 'view':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    // Disable specific options
                    // $("form").find('select#chk_billBook_status option').each(function () {
                    //     if ($(this).val() !== 'Z' && $(this).val() !== po_status) {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    // $('#add-grn-id').hide();
                    $('#clearFormBtn').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    if (keyForViewUpdate === 'cancel') {
                        $('#cancel-btn').show();
                    } else if (keyForViewUpdate === 'financeApprove') {
                        $('#finance-btn').show();
                    }

                    break;
                case 'approve':
                    setIsView(true)
                    $('input[type="checkbox"]').prop('readonly', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('table .approveField').removeAttr("disabled");
                    // $("form").find('select#chk_billBook_status').removeAttr("disabled");
                    $('#save-btn').text('Approve');

                    // $("form").find('select#chk_billBook_status option').each(function () {
                    //     if ($(this).val() === 'Z') {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    break;
                case 'delete':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#clearFormBtn').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    if (keyForViewUpdate === 'cancel') {
                        $('#cancel-btn').show();
                    } else if (keyForViewUpdate === 'financeApprove') {
                        $('#finance-btn').show();
                    }
                    break;
            }
        }
    }
    const FnCalculateTaxAmount = (tax_percent, tax_type) => {
        const tax_amount = txt_total_amount * tax_percent / 100;
        switch (tax_type) {
            case 'tax1amount':
                setTax1_amount(tax_amount)
                break;
            case 'tax2amount':
                setTax2_amount(tax_amount)
                break;
            default:
                break;
        }

    }
    // navigate to back listing page 
    const moveToListing = () => {
        let Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/TBillBook/BillBookListing/reg' : `/Transactions/TPurchaseOrder/TBillBook/BillBookListing`;
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigate(Path);

    }
    const loadGRNotesList = async () => {

        resetGlobalQuery();
        globalQuery.columns = ["goods_receipt_no", "goods_receipt_date", "purchase_order_no", "purchase_order_date", "supplier_challan_no", "supplier_challan_date", "goods_receipt_master_transaction_id"]
        globalQuery.table = "ptv_goods_receipt_summary"
        globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: document.getElementById('cmb_supplier_id').value });
        globalQuery.conditions.push({ field: "expected_branch_id", operator: "=", value: document.getElementById('cmb_expected_branch_id').value });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        // globalQuery.conditions.push({ field: "goods_receipt_type_id", operator: "=", value: document.getElementById('cmb_supplier_bill_booking_type_id').value });
        globalQuery.conditions.push({ field: "goods_receipt_status", operator: "IN", values: ['G', 'C'] });
        const getGoodsReceipSummarytList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        if (getGoodsReceipSummarytList.length !== 0) {
            setGoodReceiptNoList(getGoodsReceipSummarytList)
            FnGrnChkBoxCheck(getGoodsReceipSummarytList)

        } else {
            // setCheckedGoodReciptNoLength(0)
            setGoodReceiptNoList([])
        }
    }

    // Hide show customer order no's
    const FnTogglegood_receiptNoList = () => {
        const headerDataIsValid = validate.current.validateForm("billBook_header_formId");
        if (headerDataIsValid) {
            var checkboxes = document.getElementById("good-receipt-order-ul");
            const items = document.querySelectorAll(".good_receipt_no");
            if (!expanded) {
                if (items.length !== 0) {

                    checkboxes.style.display = "block";
                    expanded = true;
                }
            } else {
                checkboxes.style.display = "none";
                expanded = false;
            }
        }
    }


    const FnPurchaseOrderNoSelection = (good_receipt_no) => {
        if (parseInt(good_receipt_no) === 0) {
            if ($("#all_grn_no_id").is(":checked") === false) {
                selectAllPurchaseOrderNo('uncheck');
            } else {
                selectAllPurchaseOrderNo('check');
            }
        }
        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=goodReceiptNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=goodReceiptNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_grn_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_grn_no_id').checked = false;
        }
        setCheckedGoodReciptNoLength(totalChkBoxesChked)
    }
    const selectAllPurchaseOrderNo = (key) => { // fn to check all checkbox of contact ids
        const fliterCheckbox = document.getElementsByName('goodReceiptNoCheckBox');
        if (key === 'check') {
            for (let chkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${chkAllCheckbox.id}`).checked = true;
            }
        } else {
            for (let unchkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${unchkAllCheckbox.id}`).checked = false;
            }
        }
    }
    function renderGRNNosCheckbox(item, index) {
        if (index === 0) {
            return (
                <>
                    <li className="item good_receipt_no" key={`All-${index}`}>
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                id="all_grn_no_id"
                                value={index}
                                className="erp_radio_button filterCheckBox"
                                onChange={(event) => FnPurchaseOrderNoSelection(event.target.value)}
                                disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                optional='optional'
                            />
                        </span>
                        <span className="item-text">All</span>
                    </li>
                    <li className="item good_receipt_no" key={index}>
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                name="goodReceiptNoCheckBox"
                                value={item.goods_receipt_no}
                                id={`filterCheck-${item.goods_receipt_no}`}
                                className="erp_radio_button goodReceiptNoCheckBox"
                                onChange={(event) => FnPurchaseOrderNoSelection(event.target.value)}
                                disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                optional='optional'
                            />
                        </span>
                        <span className="item-text">{item.goods_receipt_no}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item good_receipt_no" key={index}>
                <span className="checkbox">
                    <input
                        type="checkbox"
                        fieldName={item.goods_receipt_no}
                        name="goodReceiptNoCheckBox"
                        value={item.goods_receipt_no}
                        id={`filterCheck-${item.goods_receipt_no}`}
                        className="erp_radio_button goodReceiptNoCheckBox"
                        onChange={(event) => FnPurchaseOrderNoSelection(event.target.value)}
                        disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                    />
                </span>
                <span className="item-text">{item.goods_receipt_no}</span>
            </li>
        );
    }
    //------------------------------------------------Starts Supplier Contact------------------------------------------
    //Function for get contact ids,name & mobile nos of supplier
    const FnGetSupplierContacts = async (SelectedSupplier) => {

        if (SelectedSupplier !== '' || SelectedSupplier !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
                "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

            globalQuery.table = "cmv_supplier_contacts";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: SelectedSupplier });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                setSuppContactDetails(getContactDtlsApiCall);
            } else {
                setSuppContactDetails([]);
            }
        }
        // Onchange event listener for the customer contact Selection
        $('.selectSuppContactPerson').on('change', function () {
            toggleChkAllBoxes('PartiallySuppContactSelection');
        });

    }

    //Fn to render supplier Contact details table
    const renderSupplierContactsTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='supplier-contacts-table erp_table_scroll' responsive bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Select</th>
                        <th className="erp_table_th">Branch Name</th>
                        <th className="erp_table_th">Contact Person</th>
                        <th className="erp_table_th">Contact No</th>
                        <th className="erp_table_th">Alternate Contact No</th>
                        <th className="erp_table_th">Email</th>
                        <th className="erp_table_th">Alternate Email</th>
                        <th className="erp_table_th">Designation</th>
                    </tr>
                </thead>
                <tbody>
                    {suppContactDetails.map((contactItem, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td">
                                <div style={{ display: "flex" }}>
                                    <div className="text-center">
                                        <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + contactItem.supplier_contact_id} contact_personId={contactItem.supplier_contact_id} value={contactItem.supplier_contact_id} />
                                    </div>
                                </div>
                            </td>
                            <td className="erp_table_td text-end">{contactItem.supplier_branch_name}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_contact_person}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_contact_no}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_alternate_contact}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_email_id}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_alternate_EmailId}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_designation}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [suppContactDetails]);
    //function to check All checkBoxes of po terms and payment terms
    function toggleChkAllBoxes(key) {
        switch (key) {
            case "selectAllGRN_nos":
                $('.selectGRNNo').prop('checked', $('#selectAllGRN_nos').is(":checked"));
                break;
            case 'PartiallyselectGRN_noSelection':
                $('#selectAllGRN_nos').prop('checked', $('input:checkbox.selectGRNNo:checked').length == $('input:checkbox.selectGRNNo').length);
                break;
            // For Supplier's contact
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;
            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
            case 'selectAllGrnRecords':
                $('.selectGrnRecord').prop('checked', $('#selectAllGrnRecords').is(":checked"));
                break;
            case 'PartiallySelectGrnRecord':
                $('#selectAllGrnRecords').prop('checked', $('input:checkbox.selectGrnRecord:checked').length === $('input:checkbox.selectGrnRecord').length);
                break;
        }
    }

    //------------------------------------------------End Supplier Contact------------------------------------------
    //------------------------------------------------Add New Record start-------------------------------------
    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Product Type':
                return <FrmMProductTypeEntry />;
            case 'Add Taxation':
                return <FrmTaxationEntry />;
            default:
                return null;
        }
    }

    // Show ADD record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Product Type':
                setSupplier_bill_booking_type_id('');
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

                const poTypeApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setProductTypeOptions(poTypeApiCall);
                break;
            case 'Add Taxation':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("tax_value");
                globalQuery.table = "cmv_taxation";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((taxationApiCall) => {
                    setTax1Options(taxationApiCall);
                })

                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }
    //------------------------------------------------Add New Record End--------------------------------------------------------

    //------------------------------------------------bill Book Details Start ------------------------------------------------- 
    const showGRNDetails = async (storedBillBookDetailsData) => {

        if (good_receiptNoList.length !== 0) {
            setIsLoading(true)
            let json = { 'good_receipt_nos': [], 'Ids': {} }
            $("input:checkbox[name=selectGrnRecord]:checked").each(function () {
                json.good_receipt_nos.push($(this).val())
            })
            json.Ids.company_id = COMPANY_ID
            json.Ids.expected_branch_id = cmb_expected_branch_id
            json.Ids.supplier_id = cmb_supplier_id

            if (json.good_receipt_nos.length !== 0) {
                const formData = new FormData();
                formData.append('goodReceiptNos', JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnShowGoodReceiptSummaryRecords`, requestOptions)

                const response = await apicall.json()
                console.log(response);
                if (response.GoodReceiptSummaryDetailsRecords.length !== '0') {
                    $('#error_good_receipt_orderNoEmpty').hide();
                    displaySBBDetailsData(response, json.good_receipt_nos, storedBillBookDetailsData);
                } else {
                    console.log('GRN Data Error: ', response.error);
                }
            } else {
                setTotal_amount(0)
                setTax1_amount(0)
                setTax2_amount(0)
                setPayable_amount(0)
                setBillBookDetailsData([])
            }
            setIsLoading(false)
        }
    }
    const displaySBBDetailsData = (response, good_receipt_nos, storedBillDetailsData) => {

        setBillBookDetailsData((prevArray) => {
            const calculateValues = (item) => {
                return {
                    ...item,
                    supplier_bill_booking_no: txt_supplier_bill_booking_no,
                    supplier_bill_booking_version: txt_supplier_bill_booking_version,
                    supplier_bill_booking_date: txt_supplier_bill_booking_date,
                    discount_amount: item.goods_receipt_discount_amount,
                    supplier_bill_item_booking_status: 'B',
                };
            };
            if (supplier_bill_booking_master_transaction_id === 0) {

                const prevData = [...prevArray.filter(item => good_receipt_nos.includes(item.goods_receipt_no))]

                const grn_DetailsData = FngroupedGRNDetailsProducts(response.GoodReceiptSummaryDetailsRecords, response.GoodReceiptDetailsRecords, response.PurchaseOrderPaymentTermsData)
                const responseBillData = [...grn_DetailsData
                    .filter(item => good_receipt_nos.includes(item.goods_receipt_no) &&
                        !prevArray.some(prevItem => prevItem.goods_receipt_no === item.goods_receipt_no))
                    .map(calculateValues)]
                return [...prevData, ...responseBillData];
            } else {
                const uniqueGoodReceiptNos = new Set(prevArray.map(item => item.goods_receipt_no));
                const matchingItems = prevArray.filter(item => good_receipt_nos.includes(item.goods_receipt_no));

                const processedItems = response.GoodReceiptSummaryDetailsRecords.filter(item => {
                    return (
                        good_receipt_nos.includes(item.goods_receipt_no) &&
                        !uniqueGoodReceiptNos.has(item.goods_receipt_no) &&
                        !storedBillDetailsData.some(storedItem => storedItem.goods_receipt_no === item.goods_receipt_no)
                    );
                }).map(calculateValues)
                const additionalItems = storedBillDetailsData.filter(item => good_receipt_nos.includes(item.goods_receipt_no) && !uniqueGoodReceiptNos.has(item.goods_receipt_no));
                return [...matchingItems, ...processedItems, ...additionalItems];
            }
        });
        calculateTaxationSummary(response.GoodReceiptTaxSummaryRecords)
    }
    function addDaysToDate(inputDate, numberOfDays) {
        var newDate = new Date(inputDate);
        newDate.setDate(newDate.getDate() + numberOfDays);
        // Formatting the date to YYYY-MM-DD
        var formattedDate = newDate.toISOString().substr(0, 10);
        return formattedDate;
    }
    function divideTotal(total, percentage) {
        // Check if the provided percentage is valid
        if (percentage < 0 || percentage > 100) {
            console.error('Percentage should be between 0 and 100.');
            return [];
        }
        const portion = (total * percentage) / 100;
        return portion;
    }


    // Fn to get po no, date & version from grn details
    const FngroupedGRNDetailsProducts = (bill_detailsData, GoodReceiptDetailsRecords, PurchaseOrderPaymentTermsData) => {

        try {
            let updatedBilDetailsData = [];
            const detailsGroupedData = GoodReceiptDetailsRecords.reduce((acc, item) => {
                const key = item.goods_receipt_no;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});


            const updateGRNDetails = (detailsGroupedData, paymentGroupedData) => {
                const {
                    goods_receipt_master_transaction_id,
                    purchase_order_no,
                    purchase_order_date,
                    purchase_order_version,
                    supplier_bill_booking_version,
                } = detailsGroupedData[0];
                const findBillDetailsIndex = bill_detailsData.findIndex(
                    (item) => item.goods_receipt_master_transaction_id === goods_receipt_master_transaction_id);
                ;
                const paymentData = paymentGroupedData.filter(item => item.purchase_order_no === purchase_order_no)

                // changes according to  prashant need to do functionality for only one payment terms // 14-02-2024
                // paymentData.forEach((payItem) => {
                // var payment_due_date = addDaysToDate(payItem.payment_expected_date, parseInt(payItem.payment_terms_days));
                // const bill_total = divideTotal(bill_detailsData[findBillDetailsIndex].grand_total, parseInt(payItem.payment_percent));
                // const basic_total = divideTotal(bill_detailsData[findBillDetailsIndex].basic_total, parseInt(payItem.payment_percent));
                // const taxable_total = divideTotal(bill_detailsData[findBillDetailsIndex].taxable_total, parseInt(payItem.payment_percent));
                // const freight_amount = divideTotal(bill_detailsData[findBillDetailsIndex].freight_amount, parseInt(payItem.payment_percent));
                // const packing_amount = divideTotal(bill_detailsData[findBillDetailsIndex].packing_amount, parseInt(payItem.payment_percent));
                // const goods_receipt_discount_amount = divideTotal(bill_detailsData[findBillDetailsIndex].goods_receipt_discount_amount, parseInt(payItem.payment_percent));
                // const other_amount = divideTotal(bill_detailsData[findBillDetailsIndex].other_amount, parseInt(payItem.payment_percent));
                // const cgst_total = divideTotal(bill_detailsData[findBillDetailsIndex].cgst_total, parseInt(payItem.payment_percent));
                // const sgst_total = divideTotal(bill_detailsData[findBillDetailsIndex].sgst_total, parseInt(payItem.payment_percent));
                // const igst_total = divideTotal(bill_detailsData[findBillDetailsIndex].igst_total, parseInt(payItem.payment_percent));
                // const roundoff = divideTotal(bill_detailsData[findBillDetailsIndex].roundoff, parseInt(payItem.payment_percent));

                var payment_due_date = addDaysToDate(paymentData[0].payment_expected_date, parseInt(paymentData[0].payment_terms_days));
                const bill_total = bill_detailsData[findBillDetailsIndex].grand_total;
                const basic_total = bill_detailsData[findBillDetailsIndex].basic_total;
                const taxable_total = bill_detailsData[findBillDetailsIndex].taxable_total;
                const freight_amount = bill_detailsData[findBillDetailsIndex].freight_amount;
                const packing_amount = bill_detailsData[findBillDetailsIndex].packing_amount;
                const goods_receipt_discount_amount = bill_detailsData[findBillDetailsIndex].goods_receipt_discount_amount;
                const other_amount = bill_detailsData[findBillDetailsIndex].other_amount;
                const cgst_total = bill_detailsData[findBillDetailsIndex].cgst_total;
                const sgst_total = bill_detailsData[findBillDetailsIndex].sgst_total;
                const igst_total = bill_detailsData[findBillDetailsIndex].igst_total;
                const roundoff = bill_detailsData[findBillDetailsIndex].roundoff;


                const updatedBillBookObject = {
                    ...bill_detailsData[findBillDetailsIndex],
                    expected_schedule_date: paymentData[0].payment_expected_date,
                    payment_due_date,
                    purchase_order_no,
                    purchase_order_date,
                    purchase_order_version,
                    supplier_bill_booking_version,
                    bill_total,
                    basic_total,
                    taxable_total,
                    freight_amount,
                    packing_amount,
                    goods_receipt_discount_amount,
                    other_amount,
                    cgst_total,
                    sgst_total,
                    igst_total,
                    roundoff
                };
                updatedBilDetailsData.push(updatedBillBookObject)
                // })
            };
            Object.values(detailsGroupedData).forEach(item => updateGRNDetails(item, PurchaseOrderPaymentTermsData));
            console.log("cumulativeData: ", updatedBilDetailsData);
            return updatedBilDetailsData;
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    };

    //Fn to render bill book  details table
    const renderBillBookDetailsTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='billBook-details-table' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        {/* <th className="erp_table_th">Bill Booking  No</th> */}
                        <th className="erp_table_th">Goods Receipt No</th>
                        <th className="erp_table_th">Purchase Order No</th>
                        <th className="erp_table_th">Supplier Challan No</th>
                        <th className="erp_table_th">Supplier Challan Date</th>
                        <th className="erp_table_th">Supplier Invoice No</th>
                        <th className="erp_table_th">Supplier Invoice Date</th>
                        <th className="erp_table_th">Bill Book Total</th>
                        <th className="erp_table_th">Expected Payment Date</th>
                        <th className="erp_table_th">Payment Due Date</th>
                        <th className="erp_table_th">Basic Total</th>
                        <th className="erp_table_th">Discount Amount</th>
                        <th className="erp_table_th">Taxable Total</th>
                        <th className="erp_table_th">Cgst Total</th>
                        <th className="erp_table_th">Sgst Total</th>
                        <th className="erp_table_th">Igst Total</th>
                        <th className="erp_table_th">Freight Amount</th>
                        <th className="erp_table_th">Packing Amount</th>
                        <th className="erp_table_th">Other Amount</th>
                        <th className="erp_table_th"> Round Off </th>
                        {/* <th className="erp_table_th">Bill Booking  Date</th>
                        <th className="erp_table_th">Bill Booking  Version</th> */}
                        <th className="erp_table_th">Goods Receipt Date</th>
                        <th className="erp_table_th">Goods Receipt Version</th>
                        <th className="erp_table_th">purchase Order Date</th>
                        <th className="erp_table_th">purchase Order Version</th>
                        <th className="erp_table_th">Bill Booking Item Status</th>
                        <th className="erp_table_th" style={{ paddingRight: "40px" }}>Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {billBookDetailsData.map((grnItem, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            {/* <td className="erp_table_td ">{grnItem.supplier_bill_booking_no}</td> */}
                            <td className="erp_table_td ">{grnItem.goods_receipt_no}</td>
                            <td className="erp_table_td ">{grnItem.purchase_order_no}</td>
                            <td className="erp_table_td ">{grnItem.supplier_challan_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.supplier_challan_date)}</td>
                            <td className="erp_table_td ">{grnItem.supplier_invoice_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.supplier_invoice_date)}</td>
                            <td className="erp_table_td text-end">{grnItem.bill_total}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.expected_schedule_date)}  </td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.payment_due_date)}</td>
                            <td className="erp_table_td text-end">{grnItem.basic_total}</td>
                            <td className="erp_table_td text-end">{grnItem.discount_amount}</td>
                            <td className="erp_table_td text-end">{grnItem.taxable_total}</td>
                            <td className="erp_table_td text-end">{grnItem.cgst_total}</td>
                            <td className="erp_table_td text-end text-end">{grnItem.sgst_total}</td>
                            <td className="erp_table_td text-end" text-end>{grnItem.igst_total}</td>
                            <td className="erp_table_td text-end">{grnItem.freight_amount}</td>
                            <td className="erp_table_td text-end">{grnItem.packing_amount}</td>
                            <td className="erp_table_td text-end">{grnItem.other_amount}</td>
                            <td className="erp_table_td text-end text-end">{grnItem.roundoff}</td>
                            {/* <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.supplier_bill_booking_date)}</td>
                            <td className="erp_table_td text-end">{grnItem.supplier_bill_booking_version}</td> */}
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.goods_receipt_date)}</td>
                            <td className="erp_table_td text-end">{grnItem.goods_receipt_version}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.purchase_order_date)}</td>
                            <td className="erp_table_td text-end">{grnItem.purchase_order_version}</td>
                            <td className="erp_table_td ">
                                <select id={`supplier_bill_item_booking_status_${index}`} disabled={!isApprove} value={grnItem.supplier_bill_item_booking_status} className="form-select form-select-sm approveField"
                                    Headers='supplier_bill_item_booking_status' onChange={(e) => { updateBillBookDTblRows(grnItem, e); }} >
                                    <option value="B">B-Bill Booked</option>
                                    <option value="A">Approved</option>
                                    <option value="F">Finanace Approved</option>
                                    <option value="R">Partial Payment Done</option>
                                    <option value="C">Payment Done</option>
                                    <option value="X">Canceled</option>
                                    <option value="P">Payment Book</option>
                                </select>
                            </td>
                            <td className="erp_table_td ">
                                <input type="text" className="erp_input_field mb-0" Headers="remark" id={`remark_${index}`}
                                    value={grnItem.remark} onChange={(e) => { updateBillBookDTblRows(grnItem, e); }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [billBookDetailsData, isView, isApprove])

    const updateBillBookDTblRows = (rowData, event) => {

        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = parseInt(event.target.value);
        if (isNaN(enteredValue)) {
            enteredValue = 0;
        }

        switch (clickedColName) {

            case 'supplier_bill_item_booking_status':
            case 'remark':
                rowData[clickedColName] = event.target.value;
                break;
        }

        const detailData = [...billBookDetailsData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        detailData[arrayIndex] = rowData
        setBillBookDetailsData(detailData);
    }

    // to scroll suppliers contact ids box
    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('billBook-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#billBook-details-table').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('billBook-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const FnCheckUpdateResponse = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnShowAllDetailsAndMastermodelRecords/${txt_supplier_bill_booking_version}/${FINANCIAL_SHORT_NAME}/${COMPANY_ID}?supplier_bill_booking_no=${txt_supplier_bill_booking_no}`)
            const response = await apiCall.json();
            if (response.SupplierBillBookingMasterRecord !== null) {
                let billBookMasterData = response.SupplierBillBookingMasterRecord;
                let billBookDetailsRecords = response.SupplierBillBookingDetailsRecord;
                let billBookTaxData = response.PurchaseBillTaxSummaryRecords;

                let { supplier_bill_booking_master_transaction_id, supplier_bill_booking_creation_type, supplier_bill_booking_no, supplier_bill_booking_date,
                    supplier_bill_booking_version, supplier_bill_booking_type_id,
                    supplier_id, supplier_contacts_ids, expected_branch_id, approved_by_id, approved_date,
                    finance_approved_by_id, finance_approved_date, supplier_bill_booking_status, total_amount, tax1_id,
                    tax1_percent, tax1_amount, tax2_id, tax2_percent, tax2_amount, deduction_amount, payable_amount,
                    deduction_remark, remark,

                } = billBookMasterData;

                setSupplier_bill_booking_master_transaction_id(supplier_bill_booking_master_transaction_id)
                setchk_biilBook_creation_type(supplier_bill_booking_creation_type)
                setsupplier_bill_booking_no(supplier_bill_booking_no)
                setSupplier_bill_booking_date(supplier_bill_booking_date)
                setSupplier_bill_booking_version(supplier_bill_booking_version)
                setSupplier_bill_booking_type_id(supplier_bill_booking_type_id)
                setSupplier_id(supplier_id)
                await comboOnChange("supplier");
                setsupplier_contacts_ids(supplier_contacts_ids)
                setExpected_branch_id(expected_branch_id)

                setBillBook_status(supplier_bill_booking_status)
                setTotal_amount(total_amount)
                setTax1_id(tax1_id)
                setTax1_percent(tax1_percent)
                setTax1_amount(tax1_amount)
                setTax2_id(tax2_id)
                setTax2_percent(tax2_percent)
                setTax2_amount(tax2_amount)
                setDeduction_amount(deduction_amount)
                setPayable_amount(payable_amount)
                setDeduction_remark(deduction_remark)
                setRemark(remark)

                if (supplier_contacts_ids !== '') {
                    FnSetExistingSupplierContactPersonIds(supplier_contacts_ids)
                }
                if (keyForViewUpdate === 'approve') {
                    setApproved_by_id(UserId)
                    setApproved_date(currentDate)
                } else if (keyForViewUpdate === 'financeApprove') {
                    setApproved_by_id(approved_by_id)
                    setApproved_date(approved_date)
                    setFinance_approved_by_id(UserId)
                    setFinance_approved_date(currentDate)
                } else {
                    setApproved_by_id(approved_by_id)
                    setApproved_date(approved_date)
                    setFinance_approved_by_id(finance_approved_by_id)
                    setFinance_approved_date(finance_approved_date)
                }

                if (keyForViewUpdate === 'cancel' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove') {
                    setBillBook_status(keyForViewUpdate === 'approve' ? 'A' : keyForViewUpdate === 'cancel' ? 'X' : 'F')
                    // Assuming grnDetailsRecords is an array of objects
                    const updatedBillBookDetailsRecords = billBookDetailsRecords.map(item => {
                        // Update the keys you want to modify
                        return {
                            ...item,
                            supplier_bill_item_booking_status: keyForViewUpdate === 'approve' ? 'A' : keyForViewUpdate === 'cancel' ? 'X' : 'F',
                        };
                    });
                    // Set the state with the updated array
                    setBillBookDetailsData(updatedBillBookDetailsRecords)
                    setStoredBillBookDetailsData(updatedBillBookDetailsRecords)
                } else {
                    setBillBookDetailsData(billBookDetailsRecords)
                    setStoredBillBookDetailsData(billBookDetailsRecords)
                }
                setPaymentTaxData(billBookTaxData)
                if (keyForViewUpdate === 'view' || isApprove) {
                    setApproved_by_id(approved_by_id);
                    setApproved_date(approved_date);
                }
                loadGRNotesList();
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    //-----------------------------------------------------Calculations Starts---------------------------------------------------------
    const FnCalculatePayableAmount = () => {

        let totalAmount = 0;
        let tax1_amount = 0;
        let tax2_amount = 0;
        const tax1Percent = document.getElementById('txt_tax1_percent').value;
        const tax2Percent = document.getElementById('txt_tax2_percent').value;
        let deductionAmount = document.getElementById('txt_deduction_amount').value;
        let payable_amount = 0;

        // calculate total amount of bill book
        totalAmount = [...billBookDetailsData].reduce((total, item) => total + (parseFloat(item.bill_total) + parseFloat(item.roundoff)), 0);
        console.log('total_amount: ', totalAmount);
        setTotal_amount(validateNumberDateInput.current.decimalNumber((totalAmount).toString(), 4));

        tax1_amount = totalAmount * tax1Percent / 100;
        tax2_amount = totalAmount * tax2Percent / 100;
        payable_amount = totalAmount - tax1_amount - tax2_amount - deductionAmount;

        setTax1_amount(validateNumberDateInput.current.decimalNumber((tax1_amount).toString(), 4))
        setTax2_amount(validateNumberDateInput.current.decimalNumber((tax2_amount).toString(), 4))
        setPayable_amount(validateNumberDateInput.current.decimalNumber((payable_amount).toString(), 4))

    }
    //-----------------------------------------------------Calculations Ends---------------------------------------------------------
    //---------------------------------------------------Taxation Functionality Starts ----------------------------------------------------------

    // Function to normalize hsnCode values for grouping
    function normalizeHsnCode(hsnCode) {
        return hsnCode.replace(/\s+/g, '').toUpperCase();
    }
    const calculateTaxationSummary = (goodsReceiptDetailsData) => {
        try {

            const taxableData = [];
            // Use reduce to group data by HSN rate
            const groupedData = goodsReceiptDetailsData.reduce((acc, item) => {
                const hsnCode = normalizeHsnCode(item.hsn_sac_code);
                // const key = item.hsnCode; // Group by HSN rate
                if (!acc[hsnCode]) {
                    acc[hsnCode] = [];
                }
                acc[hsnCode].push(item);
                return acc;
            }, {});

            // Convert the grouped data object into an array of arrays
            const groupedDataArray = Object.values(groupedData);

            groupedDataArray.forEach(group => {
                const {
                    hsn_sac_code,
                    hsn_code_id,
                    summary_cgst_percent,
                    summary_sgst_percent,
                    summary_igst_percent,
                    goods_receipts_notes_tax_summary_transaction_id,
                } = group[0];

                const groupTotalCGSTAmt = group.reduce((total, item) => total + parseFloat(item.summary_cgst_total), 0);
                const groupTotalSGSTAmt = group.reduce((total, item) => total + parseFloat(item.summary_sgst_total), 0);
                const groupTotalIGSTAmt = group.reduce((total, item) => total + parseFloat(item.summary_igst_total), 0);
                const groupTotalAmt = group.reduce((total, item) => total + parseFloat(item.summary_total_amount), 0);
                const groupTaxableAmt = group.reduce((total, item) => total + parseFloat(item.summary_taxable_amount), 0);

                // Create an object for each group
                const groupObject = {
                    hsn_sac_code,
                    hsn_code_id,
                    goods_receipts_notes_tax_summary_transaction_id,
                    summary_taxable_amount: groupTaxableAmt,
                    summary_cgst_percent: summary_cgst_percent,
                    summary_cgst_total: groupTotalCGSTAmt,
                    summary_sgst_percent: summary_sgst_percent,
                    summary_sgst_total: groupTotalSGSTAmt,
                    summary_igst_percent: summary_igst_percent,
                    summary_igst_total: groupTotalIGSTAmt,
                    summary_total_amount: groupTotalAmt,
                };

                taxableData.push(groupObject);
            });
            debugger
            setPaymentTaxData(taxableData)
        } catch (error) {
            console.log("error: ", error);
        }
    }

    const memoizedTaxSummaryComponent = useMemo(() => (
        <TaxSummary grnTaxSummaryData={paymentTaxData} validateNumberDateInput={validateNumberDateInput} />
    ), [paymentTaxData]);
    //---------------------------------------------------Taxation Functionality Ends ----------------------------------------------------------

    //---------------------------------------------------save Functionality Starts----------------------------------------------------------
    const addBillBook = async (bill_status) => {
        try {
            const validateForm = await FnValidateBillBook();
            if (validateForm) {
                setIsLoading(true)
                let json = { 'TransHeaderFooterData': {}, 'TransDetailData': [], 'TransPurchaseBillTaxData': [], 'commonIds': {} }
                let supplierContactPersonsIds = await FnGetSupplierContactPersonIds();
                let supplier_bill_booking_no = txt_supplier_bill_booking_no;

                if (keyForViewUpdate === '') {
                    // let shortname = $('#cmb_supplier_bill_booking_type_id option:selected').attr('shortname');
                    supplier_bill_booking_no = await GenerateBillBookNo();
                }
                // TransHeaderData
                const billBook_form_data = {
                    supplier_bill_booking_master_transaction_id: isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? supplier_bill_booking_master_transaction_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    supplier_bill_booking_creation_type: chk_biilBook_creation_type,
                    supplier_bill_booking_no: supplier_bill_booking_no,
                    supplier_bill_booking_date: txt_supplier_bill_booking_date,
                    supplier_bill_booking_version: txt_supplier_bill_booking_version,
                    supplier_bill_booking_type_id: cmb_supplier_bill_booking_type_id,
                    supplier_bill_booking_type: $('#cmb_supplier_bill_booking_type_id').find(":selected").text(),
                    supplier_id: cmb_supplier_id,
                    supplier_contacts_ids: supplierContactPersonsIds,
                    expected_branch_id: cmb_expected_branch_id,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: txt_approved_date,
                    finance_approved_by_id: cmb_finance_approved_by_id,
                    finance_approved_date: txt_finance_approved_date,
                    supplier_bill_booking_status: bill_status === 'X' ? bill_status : chk_billBook_status,
                    total_amount: txt_total_amount,
                    tax1_id: txt_tax1_id,
                    tax1_percent: txt_tax1_percent,
                    tax1_amount: txt_tax1_amount,
                    tax2_id: txt_tax2_id,
                    tax2_percent: txt_tax1_percent,
                    tax2_amount: txt_tax1_amount,
                    deduction_amount: txt_deduction_amount,
                    payable_amount: txt_payable_amount,
                    deduction_remark: txt_deduction_remark,
                    remark: txt_remark,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' ? UserName : ''
                }
                json.TransHeaderFooterData = billBook_form_data;
                console.log('HeaderData: ', json.TransHeaderFooterData);

                for (let grnItem = 0; grnItem < billBookDetailsData.length; grnItem++) {
                    const billBookDetailsRow = billBookDetailsData[grnItem];

                    const billBookRow = {};
                    // Set values for each column
                    billBookRow['supplier_bill_booking_details_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? billBookDetailsRow.supplier_bill_booking_details_transaction_id : 0
                    billBookRow['supplier_bill_booking_master_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? supplier_bill_booking_master_transaction_id : 0
                    billBookRow['company_id'] = COMPANY_ID
                    billBookRow['company_branch_id'] = COMPANY_BRANCH_ID
                    billBookRow['financial_year'] = FINANCIAL_SHORT_NAME
                    billBookRow['supplier_bill_booking_no'] = supplier_bill_booking_no
                    billBookRow['supplier_bill_booking_date'] = txt_supplier_bill_booking_date
                    billBookRow['supplier_bill_booking_version'] = txt_supplier_bill_booking_version
                    billBookRow['purchase_order_no'] = billBookDetailsRow.purchase_order_no
                    billBookRow['goods_receipt_no'] = billBookDetailsRow.goods_receipt_no
                    billBookRow['supplier_challan_no'] = billBookDetailsRow.supplier_challan_no
                    billBookRow['supplier_challan_date'] = billBookDetailsRow.supplier_challan_date
                    billBookRow['supplier_invoice_no'] = billBookDetailsRow.supplier_invoice_no
                    billBookRow['supplier_invoice_date'] = billBookDetailsRow.supplier_invoice_date
                    billBookRow['bill_total'] = billBookDetailsRow.bill_total
                    billBookRow['expected_schedule_date'] = billBookDetailsRow.expected_schedule_date
                    billBookRow['payment_due_date'] = billBookDetailsRow.payment_due_date
                    billBookRow['basic_total'] = billBookDetailsRow.basic_total
                    billBookRow['freight_amount'] = billBookDetailsRow.freight_amount
                    billBookRow['packing_amount'] = billBookDetailsRow.packing_amount
                    billBookRow['discount_amount'] = billBookDetailsRow.discount_amount
                    billBookRow['other_amount'] = billBookDetailsRow.other_amount
                    billBookRow['taxable_total'] = billBookDetailsRow.taxable_total
                    billBookRow['cgst_total'] = billBookDetailsRow.cgst_total
                    billBookRow['sgst_total'] = billBookDetailsRow.sgst_total
                    billBookRow['igst_total'] = billBookDetailsRow.igst_total
                    billBookRow['roundoff'] = billBookDetailsRow.roundoff
                    // billBookRow['purchase_order_date'] = billBookDetailsRow.purchase_order_date
                    billBookRow['supplier_bill_booking_version'] = billBookDetailsRow.supplier_bill_booking_version
                    billBookRow['goods_receipt_date'] = billBookDetailsRow.goods_receipt_date
                    billBookRow['goods_receipt_version'] = billBookDetailsRow.goods_receipt_version
                    billBookRow['purchase_order_date'] = billBookDetailsRow.purchase_order_date
                    billBookRow['purchase_order_version'] = billBookDetailsRow.purchase_order_version
                    billBookRow['supplier_bill_item_booking_status'] = bill_status === 'X' ? bill_status : billBookDetailsRow.supplier_bill_item_booking_status
                    billBookRow['remark'] = billBookDetailsRow.remark
                    billBookRow['created_by'] = keyForViewUpdate === '' ? UserName : billBookDetailsRow.created_by
                    billBookRow['modified_by'] = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' ? UserName : ''
                    json.TransDetailData.push(billBookRow);
                }
                console.log(json.TransDetailData);

                debugger
                for (let taxItem = 0; taxItem < paymentTaxData.length; taxItem++) {
                    const paymentTaxDataRow = paymentTaxData[taxItem];
                    let paymentTaxObject = {};

                    paymentTaxObject.purchase_bill_tax_summary_transaction_id = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? paymentTaxDataRow.purchase_bill_tax_summary_transaction_id : 0;
                    paymentTaxObject.company_id = COMPANY_ID
                    paymentTaxObject.company_branch_id = COMPANY_BRANCH_ID
                    paymentTaxObject.financial_year = FINANCIAL_SHORT_NAME
                    paymentTaxObject.supplier_bill_booking_master_transaction_id = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? supplier_bill_booking_master_transaction_id : 0
                    paymentTaxObject.supplier_id = cmb_supplier_id
                    paymentTaxObject.supplier_state_id = ''
                    paymentTaxObject.supplier_city_id = ''
                    paymentTaxObject.supplier_contacts_ids = supplierContactPersonsIds
                    paymentTaxObject.expected_branch_id = cmb_expected_branch_id
                    paymentTaxObject.expected_branch_state_id = ''
                    paymentTaxObject.expected_branch_city_id = ''
                    paymentTaxObject.supplier_bill_booking_no = supplier_bill_booking_no
                    paymentTaxObject.supplier_bill_booking_date = txt_supplier_bill_booking_date
                    paymentTaxObject.supplier_bill_booking_version = txt_supplier_bill_booking_version
                    paymentTaxObject.hsn_code_id = paymentTaxDataRow.hsn_code_id
                    paymentTaxObject.summary_taxable_amount = paymentTaxDataRow.summary_taxable_amount
                    paymentTaxObject.summary_cgst_percent = paymentTaxDataRow.summary_cgst_percent
                    paymentTaxObject.summary_cgst_total = paymentTaxDataRow.summary_cgst_total
                    paymentTaxObject.summary_sgst_percent = paymentTaxDataRow.summary_sgst_percent
                    paymentTaxObject.summary_sgst_total = paymentTaxDataRow.summary_sgst_total
                    paymentTaxObject.summary_igst_percent = paymentTaxDataRow.summary_igst_percent
                    paymentTaxObject.summary_igst_total = paymentTaxDataRow.summary_igst_total
                    paymentTaxObject.summary_total_amount = paymentTaxDataRow.summary_total_amount
                    paymentTaxObject.remark = paymentTaxDataRow.remark === undefined || paymentTaxDataRow.remark === "" ? "" : paymentTaxDataRow.remark
                    paymentTaxObject.created_by = keyForViewUpdate === '' ? UserName : paymentTaxDataRow.created_by
                    paymentTaxObject.modified_by = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' ? UserName : '';

                    json.TransPurchaseBillTaxData.push(paymentTaxObject)
                }

                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.supplier_bill_booking_no = supplier_bill_booking_no
                json.commonIds.supplier_bill_booking_version = txt_supplier_bill_booking_version
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME

                console.log("json of Bill Book New: ", json);
                let isApproveOrCancel = 'AddUpdate';
                if (keyForViewUpdate === 'approve') {
                    isApproveOrCancel = 'approve';
                } else if (keyForViewUpdate === 'cancel') {
                    isApproveOrCancel = 'cancel';
                } else if (keyForViewUpdate === 'financeApprove') {
                    isApproveOrCancel = 'financeApprove';
                }

                const formData = new FormData();
                formData.append(`PtsupplierBillBookingData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnAddUpdateRecord/${isApproveOrCancel}`, requestOptions);
                const response = await apicall.json();
                if (response.success === "0") {
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setMessage(response.message)
                    if (response.data.hasOwnProperty('supplier_bill_booking_no') && response.data.hasOwnProperty('supplier_bill_booking_version')) {
                        setModalOrderDetails(`Bill Booking No: ${response.data.supplier_bill_booking_no} and Version: ${response.data.supplier_bill_booking_version}`)
                    }
                    setMessageForConfirmation('')
                    setShowConfiemationModal(true);
                }
            }
        } catch (error) {
            console.log('error on addBill: ', error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }

    //validate po form 
    const FnValidateBillBook = async () => {
        let headerDataIsValid = await validate.current.validateForm("billBook_header_formId");//billBook_footer_formId
        if (!headerDataIsValid) { return false; }
        if ($('input:checkbox.selectSuppContactPerson:checked').length === 0) {
            $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
            $('#error_supplier_contact_persons').show();
            $('#chk_billBook_status').focus();
            return false;
        } else {
            $('#error_supplier_contact_persons').hide();
        }

        let billBookDetailsIsValid = false;
        let addedMaterials = $('#billBook-details-table tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Good Receipt Note...!');
            setShowErrorMsgModal(true);
            return billBookDetailsIsValid = false;
        } else {
            billBookDetailsIsValid = true;
        }

        if (txt_deduction_amount > 0) {
            if (parseInt(txt_deduction_amount) > parseInt(txt_total_amount)) {
                $('#error_txt_deduction_amount').text('Please Enter Less then Total Amount...!');
                $('#error_txt_deduction_amount').show();
                return billBookDetailsIsValid = false;
            } else if (txt_deduction_remark === '') {
                $('#error_txt_deduction_remark').text('Please Enter Deduction Remark...!');
                $('#error_txt_deduction_remark').show();
                return billBookDetailsIsValid = false;
            } else {
                billBookDetailsIsValid = true;
            }
        } else {
            setDeduction_remark("");
        }
        return billBookDetailsIsValid;
    }
    const FnGetSupplierContactPersonIds = async () => {
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingSupplierContactPersonIds = (contactIds) => {
        const supplierContactPersonsIds = contactIds.split(':');
        supplierContactPersonsIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
        });
        toggleChkAllBoxes('PartiallySuppContactSelection');
    }
    //---------------------------------------------------save Functionality Ends----------------------------------------------------------
    // //---------------------------------------------------Email functionality Starts----------------------------------------------------------
    // function dataURItoBlob(dataURI) {
    //     // convert base64/URLEncoded data component to raw binary data held in a string
    //     var byteString;
    //     if (dataURI.split(',')[0].indexOf('base64') >= 0)
    //         byteString = atob(dataURI.split(',')[1]);
    //     else
    //         byteString = unescape(dataURI.split(',')[1]);

    //     // separate out the mime component
    //     var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    //     // write the bytes of the string to a typed array
    //     var ia = new Uint8Array(byteString.length);
    //     for (var i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }

    //     return new Blob([ia], { type: mimeString });
    // }
    // const sendEmail = async () => {
    //     try {
    //         setIsLoading(true);
    //         setShowMailSentModal(false);
    //         $('#spinner_text').text('Sending Email...');
    //         const EmailData = await FngenerateMailJson();

    //         // Generate the sales-order PDF.
    //         let purchaseOrderContent = await printInvoice(false);
    //         const fileName = `${txt_supplier_bill_booking_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
    //         const billBookingContainer = document.createElement('div');
    //         billBookingContainer.style.margin = '20px';
    //         billBookingContainer.innerHTML = purchaseOrderContent;
    //         let worker = html2pdf().from(billBookingContainer).set({
    //             margin: 0,
    //             filename: fileName,
    //             html2canvas: { scale: 2 },
    //             jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
    //         }).toPdf();

    //         let pages = [];
    //         pages.slice(1).forEach(function (page) {
    //             worker = worker.get('pdf').then(function (pdf) {
    //                 pdf.addPage();
    //             }).from(page).toContainer().toCanvas().toPdf();
    //         });

    //         worker = worker.output("datauristring").then(async function (pdf) {
    //             const preBlob = dataURItoBlob(pdf);
    //             const file = new File([preBlob], fileName, { type: 'application/pdf' });

    //             const formData = new FormData();
    //             formData.append(`EmailData`, JSON.stringify(EmailData))
    //             formData.append("purchaseOrderPDF", file);

    //             const requestOptions = { method: 'POST', body: formData };
    //             const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnSendEmail/${supplier_bill_booking_master_transaction_id}/${COMPANY_ID}`, requestOptions);
    //             const emailingApiCallResponse = await emailingApiCall.json();

    //             if (emailingApiCallResponse.success === "1") {
    //                 // Show success message.
    //                 setMessage(emailingApiCallResponse.message);
    //                 setShowConfiemationModal(true);
    //             } else {
    //                 // Show error message.
    //                 setErrMsg(emailingApiCallResponse.message);
    //                 setShowErrorMsgModal(true);
    //             }
    //             setIsLoading(false);
    //         });
    //     } catch (error) {
    //         console.log('Error in email sending: ', error);
    //     }
    // }

    // const FngenerateMailJson = async () => {
    //     let EmailData = { 'EmailType': "Bill Booking Status Updatation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
    //     const checkboxes = $('.selectSuppContactPerson:checked');
    //     checkboxes.each(function () {
    //         let selectedContact_id = parseInt($(this).val());
    //         const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
    //         if (supplierContact) {
    //             EmailData.toMailData.push(supplierContact.supp_email_id);
    //         }
    //     });
    //     EmailData.subject = `Bill Booking Approval Confirmation - Order ${txt_supplier_bill_booking_no}`;    // Subject
    //     EmailData.parameters.push("Bill Booking  Information");                            // Param0 It is a heading.
    //     EmailData.parameters.push($('#cmb_supplier_id').find(":selected").text());          // Param1
    //     EmailData.parameters.push(`${txt_supplier_bill_booking_no}`);                                          // Param2
    //     EmailData.parameters.push($('#chk_po_status').find(":selected").text());            // Param3
    //     EmailData.parameters.push(`${txt_supplier_bill_booking_date}`);                                        // Param4
    //     EmailData.parameters.push(sessionStorage.getItem('companyLegalName'));              // Param6
    //     EmailData.parameters.push(sessionStorage.getItem('companyAddress'));                // Param7
    //     EmailData.parameters.push($('#cmb_approved_by_id').find(":selected").text());       // Param8
    //     console.log('emailData: ', EmailData);
    //     return EmailData;
    // }
    // //---------------------------------------- Email functionality Ends----------------------------------------------------------
    // // --------------------------------------- Bill Booking Print generate Starts ------------------------------------------------------------------
    // const billBookingInvoiceContent = {
    //     company: {
    //         company: '',
    //         company_branch: '',
    //         address: '',
    //         phone: '',
    //         email: '',
    //         cell: '',
    //         website: '',
    //         GSTIN: '',
    //         pan_no: '',
    //         state_name: '',
    //         company_pincode: ''
    //     },
    //     supplierDetails: {
    //         name: '',
    //         address: '',
    //         state: '',
    //         GSTIN: '',
    //         pan_no: '',
    //         contact: '',
    //     },
    //     customerDetails: {
    //         customer: '',
    //         address: '',
    //         state: '',
    //         GSTIN: '',
    //         pan_no: '',
    //         contact: '',
    //         email: ''
    //     },
    //     purchaseDetails: {
    //         purchase_order_no: '',
    //         purchase_order_date: '',
    //         purchase_order_version: ''
    //     },

    //     footer: {
    //         cgst_words: '',
    //         sgst_words: '',
    //         igst_words: '',
    //         grand_total_words: '',

    //         basic_total: '',
    //         cgst_total: '',
    //         sgst_total: '',
    //         igst_total: '',
    //         taxable_total: '',
    //         grand_total: '',
    //         transport_amount: '',
    //         packing_amount: '',
    //     },

    //     items: [],
    // };
    // const printInvoice = async (openPrintModal) => {
    //     console.log( idList.supplier_bill_booking_version)
    //     console.log( idList.supplier_bill_booking_no)
    //     const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnShowAllDetailsAndMastermodelRecords/${txt_supplier_bill_booking_version}/${financial_year}/${COMPANY_ID}?supplier_bill_booking_no=${txt_supplier_bill_booking_no}`)
    //     const response = await apiCall.json();
    //     console.log("Response PO Invoice : ", response);

    //     let billBookMasterDatabillBookMasterData = response.SupplierBillBookingMasterRecord;
    //     let billBookDetailsRecords = response.SupplierBillBookingDetailsRecord;

    //     // //Bill Booking details
    //     // billBookingInvoiceContent.billBookingDetails.supplier_bill_booking_no = billBookMasterDatabillBookMasterData.supplier_bill_booking_no
    //     // billBookingInvoiceContent.billBookingDetails.purchase_order_date = billBookMasterDatabillBookMasterData.purchase_order_date
    //     // billBookingInvoiceContent.billBookingDetails.supplier_bill_booking_version = billBookMasterDatabillBookMasterData.supplier_bill_booking_version

    //     // //Company details
    //     // billBookingInvoiceContent.company.company = billBookMasterDatabillBookMasterData.company_name
    //     // billBookingInvoiceContent.company.company_branch = billBookMasterDatabillBookMasterData.company_branch_name
    //     // billBookingInvoiceContent.company.address = billBookMasterDatabillBookMasterData.company_address1
    //     // billBookingInvoiceContent.company.phone = billBookMasterDatabillBookMasterData.company_phone_no
    //     // billBookingInvoiceContent.company.cell = billBookMasterDatabillBookMasterData.company_cell_no
    //     // billBookingInvoiceContent.company.email = billBookMasterDatabillBookMasterData.company_EmailId
    //     // billBookingInvoiceContent.company.website = billBookMasterDatabillBookMasterData.company_website
    //     // billBookingInvoiceContent.company.GSTIN = billBookMasterDatabillBookMasterData.company_gst_no
    //     // billBookingInvoiceContent.company.pan_no = billBookMasterDatabillBookMasterData.company_pan_no
    //     // billBookingInvoiceContent.company.state_name = billBookMasterDatabillBookMasterData.company_branch_state
    //     // billBookingInvoiceContent.company.company_pincode = billBookMasterDatabillBookMasterData.company_pincode

    //     // //supplier details
    //     // billBookingInvoiceContent.supplierDetails.name = billBookMasterDatabillBookMasterData.supplier_name
    //     // billBookingInvoiceContent.supplierDetails.address = billBookMasterDatabillBookMasterData.supp_branch_address1
    //     // billBookingInvoiceContent.supplierDetails.state = billBookMasterDatabillBookMasterData.state_name
    //     // billBookingInvoiceContent.supplierDetails.GSTIN = billBookMasterData.supp_branch_gst_no
    //     // billBookingInvoiceContent.supplierDetails.contact = billBookMasterData.supp_branch_phone_no
    //     // billBookingInvoiceContent.supplierDetails.pan_no = billBookMasterData.supp_branch_pan_no

    //     // //Customer details
    //     // billBookingInvoiceContent.company.company = billBookMasterData.company_name
    //     // billBookingInvoiceContent.company.company_branch = billBookMasterData.company_branch_name
    //     // billBookingInvoiceContent.customerDetails.customer = billBookMasterData.customer_name
    //     // billBookingInvoiceContent.customerDetails.address = billBookMasterData.cust_branch_address1
    //     // billBookingInvoiceContent.customerDetails.state = billBookMasterData.customer_state_name
    //     // billBookingInvoiceContent.customerDetails.GSTIN = billBookMasterData.cust_branch_gst_no
    //     // billBookingInvoiceContent.customerDetails.pan_no = billBookMasterData.cust_branch_pan_no
    //     // billBookingInvoiceContent.customerDetails.contact = billBookMasterData.cust_branch_phone_no
    //     // billBookingInvoiceContent.customerDetails.email = billBookMasterData.customer_email

    //     // //footer details    
    //     // billBookingInvoiceContent.footer.basic_total = billBookMasterData.basic_total
    //     // billBookingInvoiceContent.footer.cgst_total = billBookMasterData.cgst_total
    //     // billBookingInvoiceContent.footer.sgst_total = billBookMasterData.sgst_total
    //     // billBookingInvoiceContent.footer.igst_total = billBookMasterData.igst_total
    //     // billBookingInvoiceContent.footer.taxable_total = billBookMasterData.taxable_total
    //     // billBookingInvoiceContent.footer.grand_total = billBookMasterData.grand_total
    //     // billBookingInvoiceContent.footer.transport_amount = billBookMasterData.transport_amount
    //     // billBookingInvoiceContent.footer.packing_amount = billBookMasterData.packing_amount

    //     // for (let index = 0; index < billBookDetailsRecords.length; index++) {
    //     //     const element = billBookDetailsRecords[index];

    //     //     const detailsData = {
    //     //         sr_no: element.sr_no,
    //     //         material_name: element.product_material_name,
    //     //         material_tech_spect: element.product_material_tech_spect,
    //     //         hsn_code: element.hsn_sac_code,
    //     //         quantity: element.product_material_po_quantity,
    //     //         unit: element.product_material_stock_unit_name,
    //     //         rate: element.hsn_sac_rate,
    //     //         discount_percent: element.material_discount_percent,
    //     //         discount_amount: element.material_discount_amount,
    //     //         cgst_percent: element.material_cgst_percent,
    //     //         sgst_percent: element.material_sgst_percent,
    //     //         igst_percent: element.material_igst_percent,
    //     //         material_total_amount: element.material_total_amount,
    //     //         company_name: element.company_name,
    //     //         company_branch_name: element.company_branch_name,

    //     //     }
    //     //     billBookingInvoiceContent.items.push(detailsData)
    //     // }

    //     // for (let index = 0; index < PurchaseOrderPaymentTermsData.length; index++) {
    //     //     const element = PurchaseOrderPaymentTermsData[index];
    //     //     const paymentTermsData = {
    //     //         payment_terms_name: element.payment_terms_name,
    //     //         payment_terms_days: element.payment_terms_days,
    //     //     }
    //     //     billBookingInvoiceContent.paymentTerms.push(paymentTermsData)
    //     // }

    //     if (openPrintModal) {
    //         navigate('/Invoice', { state: { invoiceData: billBookingInvoiceContent, navigationLink: '/Transactions/TPurchaseOrder/ManualPO/FrmPurchaseOrderEntry', invoiceType: 'PO', title: 'Bill Booking ' } });

    //     } else {
    //         // return the html code.
    //         return renderToString(<PurchaseOrderInvoice invoiceContent={billBookingInvoiceContent} />);
    //     }

    // };
    // // --------------------------------------- Bill Booking Print generate End ------------------------------------------------------------------

    //---------------------------------------------------- New GRN Modal -------------------------------------------------------------------------------------------------
    // function for PO Details table
    const handleCloseGRNModal = () => {
        showGRNDetails();
        setViewGRNModal(false)
    };
    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }
    //--------------------------------- Document List Starts--------------------------------------------------

    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                // alert("idlist data: ",idList.supplier_bill_booking_no)
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_supplier_bill_booking_no !== 0 ? txt_supplier_bill_booking_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }
    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {
            // alert("idlist data: ",idList.supplier_bill_booking_no)
            const formData = new FormData()
            const data = {
                document_group: docItem.document_group,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                {/* <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td> */}
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = 'info'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = 'initial'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);




    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------

    async function deletebill() {
        debugger
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnDeleteRecord/${idList.supplier_bill_booking_version}/${COMPANY_ID}?supplier_bill_booking_no=${idList.supplier_bill_booking_no}&user_name=${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("Bill book Transaction Deleted: ", responce);
            setShow(false)
            moveToListing();
        } catch (error) {
            console.log("error: ", error)
        }
    }
    const reload = () => {
        window.location.reload();
    }

    //Fn to render bill book  details table
    const renderGRNTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='grn-table-id' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'></th>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Goods Receipt No</th>
                        <th className="erp_table_th">Goods Receipt Date</th>
                        <th className="erp_table_th">Purchase Order No</th>
                        <th className="erp_table_th">purchase Order Date</th>
                        <th className="erp_table_th">Supplier Challan No</th>
                        <th className="erp_table_th">Supplier Challan Date</th>
                    </tr>
                </thead>
                <tbody>
                    {good_receiptNoList.map((grnItem, index) => (
                        <tr rowIndex={index}>
                            <td>
                                <div style={{ display: "flex" }}>
                                    <input type='checkbox' class="selectGrnRecord" name="selectGrnRecord" id={'selectGrnRecord_' + grnItem.goods_receipt_master_transaction_id}
                                        value={grnItem.goods_receipt_no} onClick={(e) => toggleChkAllBoxes('PartiallySelectGrnRecord')}>
                                    </input>
                                </div>
                            </td>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{grnItem.goods_receipt_no}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.goods_receipt_date)}</td>
                            <td className="erp_table_td ">{grnItem.purchase_order_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.purchase_order_date)}</td>
                            <td className="erp_table_td ">{grnItem.supplier_challan_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.supplier_challan_date)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [good_receiptNoList])

    const FnGrnChkBoxCheck = (goodsReceipSummarytList) => {
        debugger
        const filterData = [...billBookDetailsData].map(existFGItem => {
            goodsReceipSummarytList.forEach(newFgItem => {
                if (existFGItem.goods_receipt_master_transaction_id === newFgItem.goods_receipt_master_transaction_id) {
                    const checkbox = $(`input:checkbox[name="selectGrnRecord"][value="${newFgItem.goods_receipt_no}"]`);
                    checkbox.prop('checked', true);
                }
            });
        });

        // Check if all individual checkboxes are checked and update the "Check All" checkbox state
        const allChecked = $('.selectGrnRecord:checked').length === $('.selectGrnRecord').length;
        $('#selectAllGrnRecords').prop('checked', allChecked);
    };


    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                {
                    isLoading ?
                        <div className="spinner-overlay">
                            < div className="spinner-container" >
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div >
                        </div > :
                        ''
                }
                <div className='main_heding'>
                    <label className='erp-form-label-lg main_heding'>
                        {requestTypeHeading()} Bill Book (GRN Based){ActionType()}</label>
                </div>

                <div className='card p-4'>
                    <form id="billBook_header_formId">
                        <div className="row">
                            {/* //first column */}
                            <div className="col-sm-6 erp_form_col_div">
                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Bill Book Creation Type </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="GRN" type="radio" lbl="GRN" value="G" name="chk_biilBook_creation_type" checked={chk_biilBook_creation_type === "A"} /> </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_supplier_bill_booking_type_id" className="form-select form-select-sm" value={cmb_supplier_bill_booking_type_id} onChange={(e) => { setSupplier_bill_booking_type_id(e.target.value); comboOnChange('productType'); validateFields('billBook_header_formId'); }} disabled={requestfor !== "NA"}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {producttypeOptions.length !== 0 ?
                                                <>
                                                    {producttypeOptions?.map(poTypeId => (
                                                        <option value={poTypeId.field_id} shortname={poTypeId.product_type_short_name}>{poTypeId.field_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_supplier_bill_booking_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Bill Book No,Version & Date</Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <div className="row">
                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <Form.Control type="text" id="txt_supplier_bill_booking_no" className="erp_input_field" value={txt_supplier_bill_booking_no} optional='optional' disabled />

                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3">
                                                <Form.Control type="text" id='txt_supplier_bill_booking_version' className="erp_input_field text-end" value={txt_supplier_bill_booking_version} optional='optional' disabled />

                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                <Form.Control type="date" id='txt_supplier_bill_booking_date' className="erp_input_field" value={txt_supplier_bill_booking_date} onChange={e => { setSupplier_bill_booking_date(e.target.value); validateFields('billBook_header_formId'); }} min={currentDate} />
                                                <MDTypography variant="button" id="error_txt_billBook_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Supplier<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_supplier_id" className="form-select form-select-sm" value={cmb_supplier_id} onChange={() => { comboOnChange("supplier"); validateFields('billBook_header_formId'); }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {supplierOptions.length !== 0 ?
                                                <>
                                                    {supplierOptions?.map(cmb_supplier_id => (
                                                        <option value={cmb_supplier_id.field_id} >{cmb_supplier_id.field_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_supplier') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="row">
                                    <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Supplier's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">

                                                {
                                                    cmb_supplier_id !== '' && cmb_supplier_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}>
                                                                <div className={` d-flex justify-content-end align-items-center`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_id) }}>Refresh</MDButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    suppContactDetails.length > 0
                                                        ? <>
                                                            <div className='row ps-2'>
                                                                <div className="col-12">
                                                                    <div class="ps-2">
                                                                        <input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes('selectAllSuppContact'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                                                    </div>
                                                                    {renderSupplierContactsTbl}
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <>
                                                            <div className='row text-center'>
                                                                <div className="col-12">
                                                                    <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_supplier_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>
                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Company Branch<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { setExpected_branch_id(e.target.value); comboOnChange('expectBranch'); validateFields('billBook_header_formId'); }} >
                                            <option value="">Select</option>
                                            {expectedBranchOptions.length !== 0 ?
                                                <>
                                                    {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                        <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_expectedBranch') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                {(((chk_billBook_status === 'F' || chk_billBook_status === 'A') && (keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' || keyForViewUpdate === 'view'))) ? (
                                    <>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Approved By</Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                                                    <option value="">Select</option>
                                                    {approvedByOptions.length !== 0 ?
                                                        <>
                                                            {approvedByOptions?.map(cmb_approved_by_id => (
                                                                <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                            ))}
                                                        </>
                                                        : ''
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        {((chk_billBook_status === 'F' && (keyForViewUpdate === 'financeApprove' || keyForViewUpdate === 'view'))) ? (
                                            <>
                                                <div className='row'>
                                                    <div className='col-sm-4'>
                                                        <Form.Label className="erp-form-label">Finanace Approved By</Form.Label>
                                                    </div>
                                                    <div className='col'>
                                                        <select id="cmb_finance_approved_by_id" className="form-select form-select-sm " value={cmb_finance_approved_by_id} onChange={(e) => { setFinance_approved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                                                            <option value="">Select</option>
                                                            {approvedByOptions.length !== 0 ?
                                                                <>
                                                                    {approvedByOptions?.map(cmb_approved_by_id => (
                                                                        <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                                    ))}
                                                                </>
                                                                : ''
                                                            }
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_finance_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-sm-4">
                                                        <Form.Label className="erp-form-label">Finanace Approved Date </Form.Label>
                                                    </div>
                                                    <div className="col">
                                                        <Form.Control type="date" id='txt_finance_approved_date' className="erp_input_field" value={txt_finance_approved_date} onChange={e => { setFinance_approved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                        <MDTypography variant="button" id="error_txt_finance_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </>)
                                            : ''
                                        }
                                    </>)
                                    : ''
                                }
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Bill Book Status </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="chk_billBook_status" className="form-select form-select-sm " value={chk_billBook_status} onChange={(e) => { setBillBook_status(e.target.value); }} disabled >
                                            <option value="B">Bill Booked</option>
                                            <option value="A">Approved</option>
                                            <option value="F">Finanace Approved</option>
                                            <option value="R">Partial Payment Done</option>
                                            <option value="C">Payment Done</option>
                                            <option value="X">Canceled</option>
                                            <option value="P">Payment Book</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Bill Book Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex row">
                                <div className="col-md-4 col-12">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <MDButton type="button" className={`erp-gb-button ${keyForViewUpdate === "" ? 'dispaly' : 'd-none'}`} variant="button" id='add-grn-id' fontWeight="regular" onClick={viewGRNModal}>Add GRN's</MDButton>
                                        </div>
                                        <div className="col-sm-6 d-flex">
                                            <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field txt-filter-bom ${billBookDetailsData.length === 0 ? 'd-none' : 'display'}`} />
                                            <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${billBookDetailsData.length === 0 ? 'd-none' : 'display'}`} variant="button" onClick={() => scrollToTableRow()}
                                                fontWeight="regular">Search</MDButton>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                </div>
                            </div>
                            <div className="erp-Mt-10">
                                {billBookDetailsData.length !== 0 ?
                                    <>
                                        {renderBillBookDetailsTbl}
                                    </> :
                                    <div className='row'>
                                        <div className="col-12  text-center">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                {keyForViewUpdate !== '' ?
                    <> <hr />
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                <Accordion.Body>
                                    {docData.length !== 0 ? (
                                        renderDocumentTable
                                    ) : (
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    : null
                }
                {paymentTaxData.length !== 0 ?
                    <> <hr />
                        {paymentTaxData.length !== 0 ? (
                            memoizedTaxSummaryComponent
                        ) : (
                            <div className='row text-center'>
                                <div className="col-12">
                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                        No Records Found...
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                    : null
                }
                <hr />
                {/* footer Section Starts*/}
                <div className="row">
                    <div className='main_heding text-start'>
                        <label className='erp-form-label-lg'>Bill Book Totals:</label>
                    </div>
                    <form id="billBook_footer_formId">
                        <div className="row">
                            {/* //first column */}
                            <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Total Amount</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_total_amount" className="erp_input_field text-end" value={txt_total_amount} disabled onChange={(e) => setTotal_amount(e.target.value)} />
                                        <MDTypography variant="button" id="error_txt_total_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Tax 1</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_tax1_id" className="form-select form-select-sm" value={txt_tax1_id} onChange={(e) => { comboOnChange('tax1'); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {tax1Options?.map(txt_tax1_id => (
                                                <option value={txt_tax1_id.field_id} tax_value={txt_tax1_id.tax_value}>{txt_tax1_id.field_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_tax') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Tax 1 Percent & Amount</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax1_percent" className="erp_input_field text-end" value={txt_tax1_percent} onChange={(e) => setTax1_percent(e.target.value)} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax1_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax1_amount" className="erp_input_field text-end" value={txt_tax1_amount} onChange={(e) => { setTax1_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax1_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Tax 2</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_tax2_id" className="form-select form-select-sm" value={txt_tax2_id} onChange={(e) => { comboOnChange('tax2'); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {tax1Options?.map(txt_tax2_id => (
                                                <option value={txt_tax2_id.field_id} tax_value={txt_tax2_id.tax_value}>{txt_tax2_id.field_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_tax') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Tax 2 Percent & Amount</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax2_percent" className="erp_input_field text-end" value={txt_tax2_percent} onChange={(e) => setTax2_percent(e.target.value)} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax2_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax2_amount" className="erp_input_field text-end" value={txt_tax2_amount} onChange={(e) => { setTax2_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax2_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* //second column */}
                            <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Deduction Amount</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_deduction_amount" className="erp_input_field text-end" value={txt_deduction_amount} maxLength={19} onChange={(e) => { setDeduction_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnCalculatePayableAmount(); validateFields('billBook_footer_formId'); }} />
                                        <MDTypography variant="button" id="error_txt_deduction_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Payable Amount</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_payable_amount' className="erp_input_field text-end" value={txt_payable_amount} disabled />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Deduction Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_deduction_remark' className="erp_input_field " value={txt_deduction_remark} disabled={txt_deduction_amount > 0 ? '' : 'disabled'} onChange={(e) => { txt_deduction_amount > 0 && txt_deduction_amount !== '' ? setDeduction_remark(e.target.value) : setDeduction_remark(""); validateFields('billBook_footer_formId'); }} optional={txt_deduction_amount > 0 && txt_deduction_amount !== '' ? 'optional' : ''} />
                                        <MDTypography variant="button" id="error_txt_deduction_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value) }} />
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">PO IsActive</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_po_isactive" checked={chk_po_isactive === true} onClick={() => { setPOIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="chk_po_isactive" checked={chk_po_isactive === false} onClick={() => { setPOIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </form>
                    {/* Billing Section Ends*/}

                </div>
                <div className="erp_frm_Btns">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => addBillBook('A')} fontWeight="regular"  >Save</MDButton>
                    <MDButton type="button" id="cancel-btn" className={`erp-gb-button erp_MLeft_btn `} variant="button" onClick={() => addBillBook('X')}>Cancel</MDButton>
                    <MDButton type="button" id="finance-btn" className={`erp-gb-button erp_MLeft_btn`}
                        variant="button" onClick={() => addBillBook('F')}>Finanace approve</MDButton>
                    <MDButton type="button" id="viewdocument-id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'approve' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm}>Upload Document</MDButton>&nbsp;
                    <MDButton type="button" id="clearFormBtn" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' ? 'display' : 'd-none'} `} variant="button" onClick={FnClearFormFields} fontWeight="regular"
                        disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}
                    >Clear</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>

                    {/* <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular"
                     onClick={() => {
                        printInvoice();
                    }} disabled={chk_po_status === "A" ? '' : 'disabled'}
                    >Print Invoice<FiDownload className="erp-download-icon-btn" /></MDButton> */}
                    {/* {
                        keyForViewUpdate === 'view' && chk_po_status === 'A' && chk_PO_acceptance_status !== 'A'
                            ? <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => acceptPurchaseOrder()}>Accept</MDButton>

                            : null
                    } */}
                </div >
                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showGRNModal} onHide={handleCloseGRNModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title text-center'>Goods Receipt Notes</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseGRNModal}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <div className='row p-2'>
                                <div className="col-12">
                                    {good_receiptNoList.length !== 0 ?
                                        <>
                                            <div class="col  pl-2">
                                                <input type='checkbox' class="me-1" name="selectAllGrnRecords" id="selectAllGrnRecords" onClick={(e) => { toggleChkAllBoxes('selectAllGrnRecords'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                            </div>
                                            {renderGRNTbl}
                                        </> :
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {good_receiptNoList.length !== 0 ?
                                <>
                                    <div className="erp_frm_Btns ">
                                        <MDButton type="button" onClick={handleCloseGRNModal} className="btn erp-gb-button" variant="button"
                                            fontWeight="regular">Add</MDButton>
                                    </div>
                                </> : null
                            }
                        </React.Fragment>
                    </Modal.Body>
                </Modal>

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal >
                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_supplier_bill_booking_no.replaceAll('/', '_')} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Do you wish to delete this record ?</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deletebill}>Delete</Button>
                    </Modal.Footer>

                </Modal>
                {showErrorMsgModal ?
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                    : null
                }
                {/* Send Email On Approval Modal */}
                {showConfirmationModal ?
                    <ConfirmationModal close={() => closeConfirmationModal()} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
                    : null
                }
                {/* <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, message, modalOrderDetails]} /> */}
            </DashboardLayout >
        </>
    )
}

export default FrmBillBookEntry