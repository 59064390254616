import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $, { event } from "jquery";

//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";


//React Bootstrap components
import Tooltip from "@mui/material/Tooltip";
import { Form, Button } from "react-bootstrap";
import { Accordion, Modal, Table } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { RxCrossCircled } from "react-icons/rx";
import { Link } from "react-router-dom";

// React icons
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FrmTaxationEntry from "Masters/MTaxation/FrmTaxationEntry";


function FrmManualBillBookEnrty() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

    var expanded = false;
    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType, requestfor, modules_forms_id } = state || {};
    // UseRefs
    const navigate = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef(null);
    const exlsExp = useRef();

    //combo options
    const [producttypeOptions, setProductTypeOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [tax1Options, setTax1Options] = useState([])
    // const [tax2Options, setTax2Options] = useState([])

    //common hooks
    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const [isApprove, setIsApprove] = useState(false);
    const [isView, setIsView] = useState(false);

    //master's hooks
    const [supplier_bill_booking_master_transaction_id, setSupplier_bill_booking_master_transaction_id] = useState(idList !== '' ? idList.supplier_bill_booking_master_transaction_id : 0)
    const [chk_biilBook_creation_type, setchk_biilBook_creation_type] = useState('M')
    const [txt_supplier_bill_booking_no, setsupplier_bill_booking_no] = useState(idList !== '' ? idList.supplier_bill_booking_no : '0')
    const [txt_supplier_bill_booking_version, setSupplier_bill_booking_version] = useState(idList !== '' ? idList.supplier_bill_booking_version : 1)
    const [txt_supplier_bill_booking_date, setSupplier_bill_booking_date] = useState(currentDate);
    const [cmb_supplier_bill_booking_type_id, setSupplier_bill_booking_type_id] = useState('')
    const [cmb_supplier_id, setSupplier_id] = useState('');
    const [txt_supplier_contacts_ids, setsupplier_contacts_ids] = useState('')
    const [cmb_expected_branch_id, setExpected_branch_id] = useState(COMPANY_BRANCH_ID);
    const [cmb_approved_by_id, setApproved_by_id] = useState("");
    const [txt_approved_date, setApproved_date] = useState("");
    const [cmb_finance_approved_by_id, setFinance_approved_by_id] = useState("");
    const [txt_finance_approved_date, setFinance_approved_date] = useState("");
    const [chk_billBook_status, setBillBook_status] = useState('B')
    const [txt_total_amount, setTotal_amount] = useState(0)
    const [txt_tax1_id, setTax1_id] = useState('')
    const [txt_tax1_percent, setTax1_percent] = useState(0)
    const [txt_tax1_amount, setTax1_amount] = useState(0)
    const [txt_tax2_id, setTax2_id] = useState('')
    const [txt_tax2_percent, setTax2_percent] = useState(0)
    const [txt_tax2_amount, setTax2_amount] = useState(0)
    const [txt_deduction_amount, setDeduction_amount] = useState(0)
    const [txt_payable_amount, setPayable_amount] = useState(0)
    const [txt_deduction_remark, setDeduction_remark] = useState('')
    const [txt_remark, setRemark] = useState('')



    //supplier contacts 
    const [rowCount, setRowCount] = useState(1);


    // ------------------------------------------------------------- Master hook Ends-------------------------------------------------

    // Customers contact details table data hooks
    const [suppContactDetails, setSuppContactDetails] = useState([]);
    // bill book Table Data
    const [billBookDetailsData, setBillBookDetailsData] = useState([])
    const [storedBillBookDetailsData, setStoredBillBookDetailsData] = useState([])

    // Document Form
    let docGroup = "Bill Book"
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => { showDocumentRecords(); setShowDocumentForm(false) };
    const viewDocumentForm = () => setShowDocumentForm(true);
    // doc list
    const [docData, setDocumentData] = useState([]);
    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    // Confirmation Modal fields
    const [modalOrderDetails, setModalOrderDetails] = useState('')
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState('')
    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
        moveToListing();
    }

    // Popup Fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'financeApprove':
                return '(Finance Approve)';
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                return '(View)';
            case 'cancel':
                return '(cancelation)';
            case 'delete':
                return '(Delete)';
            default:
                return '(Create)';
        }
    }
    //key for get Request type header
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'RC':
                return 'Raw Cotton';
            case 'YN':
                return 'Cotton Yarn';
            case 'GF':
                return 'Greige Fabric';
            default:
                return '';
        }
    }

    useEffect(async () => {
        setIsLoading(true);
        try {
            await FnloadDefaultData();
            // FnSetFieldsByUserAccess();
            if (idList !== '') {
                await FnCheckUpdateResponse();
            }
            FnSetFieldsByUserAccess();
        } catch (error) {
            console.error(error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            var container = $("#good-receipt-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [requestfor])

    useEffect(() => {
        const getExistingbillBookDetailsData = [...billBookDetailsData]
        getExistingbillBookDetailsData.push(BookDetailBlankObject)
        setBillBookDetailsData(getExistingbillBookDetailsData)
    }, [rowCount])

    useEffect(() => {
        if (billBookDetailsData.lenghth !== 0) {
            FnCalculatePayableAmount();
        }
    }, [billBookDetailsData, txt_tax1_amount, txt_tax2_amount, txt_deduction_amount, rowCount])

    const BookDetailBlankObject = {
        objectIndex: rowCount,
        purchase_order_no: '',
        goods_receipt_no: '',
        supplier_challan_no: '',
        supplier_challan_date: '',
        supplier_invoice_no: '',
        supplier_invoice_date: '',
        bill_total: '0',
        expected_schedule_date: '',
        payment_due_date: '',
        basic_total: '0',
        freight_amount: '0',
        packing_amount: '0',
        discount_amount: '0',
        other_amount: '0',
        taxable_total: '0',
        cgst_total: '0',
        sgst_total: '0',
        igst_total: '0',
        roundoff: '0',
        purchase_order_date: '',
        purchase_order_version: '',
        goods_receipt_date: '',
        goods_receipt_version: '',
        supplier_bill_item_booking_status: '',
        remark: '',

    }
    const setRowCountAndAddRow = () => {
        debugger;
        const lastRowIndex = billBookDetailsData.length - 1;
        const lastRowContactPerson = billBookDetailsData[lastRowIndex].basic_total;
        if (lastRowContactPerson !== '' && lastRowContactPerson !== '0') {
            setRowCount(rowCount + 1);
        } else {
            const tableRows = document.querySelectorAll('#billBook-details-table tbody tr');
            tableRows.forEach(row => {
                const bankContactName = row.querySelector('input[id^="basic_total_"]').value;
                if (bankContactName === '0' || bankContactName === '') {
                    row.querySelector('input[id^="basic_total_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="basic_total_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="basic_total_"]').parentElement.dataset.tip;
                }
            }
            )
        }
    };
    const FnCheckUpdateResponse = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnShowAllDetailsAndMastermodelRecords/${txt_supplier_bill_booking_version}/${FINANCIAL_SHORT_NAME}/${COMPANY_ID}?supplier_bill_booking_no=${txt_supplier_bill_booking_no}`)
            const response = await apiCall.json();
            if (response.SupplierBillBookingMasterRecord !== null) {
                setIsLoading(true)

                let billBookMasterData = response.SupplierBillBookingMasterRecord;
                let billBookDetailsRecords = response.SupplierBillBookingDetailsRecord;

                let { supplier_bill_booking_master_transaction_id, supplier_bill_booking_creation_type, supplier_bill_booking_no, supplier_bill_booking_date,
                    supplier_bill_booking_version, supplier_bill_booking_type_id,
                    supplier_id, supplier_contacts_ids, expected_branch_id, approved_by_id, approved_date,
                    finance_approved_by_id, finance_approved_date, supplier_bill_booking_status, total_amount, tax1_id,
                    tax1_percent, tax1_amount, tax2_id, tax2_percent, tax2_amount, deduction_amount, payable_amount,
                    deduction_remark, remark,

                } = billBookMasterData;

                setSupplier_bill_booking_master_transaction_id(supplier_bill_booking_master_transaction_id)
                setchk_biilBook_creation_type(supplier_bill_booking_creation_type)
                setsupplier_bill_booking_no(supplier_bill_booking_no)
                setSupplier_bill_booking_date(supplier_bill_booking_date)
                setSupplier_bill_booking_version(supplier_bill_booking_version)
                setSupplier_bill_booking_type_id(supplier_bill_booking_type_id)
                setSupplier_id(supplier_id)
                await comboOnChange("supplier");
                setsupplier_contacts_ids(supplier_contacts_ids)
                setExpected_branch_id(expected_branch_id)
                setApproved_by_id(approved_by_id)
                setApproved_date(approved_date)
                setFinance_approved_by_id(finance_approved_by_id)
                setFinance_approved_date(finance_approved_date)
                setBillBook_status(supplier_bill_booking_status)
                setTotal_amount(total_amount)
                setTax1_id(tax1_id)
                setTax1_percent(tax1_percent)
                setTax1_amount(tax1_amount)
                setTax2_id(tax2_id)
                setTax2_percent(tax2_percent)
                setTax2_amount(tax2_amount)
                setDeduction_amount(deduction_amount)
                setPayable_amount(payable_amount)
                setDeduction_remark(deduction_remark)
                setRemark(remark)

                if (supplier_contacts_ids !== '') {
                    FnSetExistingSupplierContactPersonIds(supplier_contacts_ids)
                }
                if (keyForViewUpdate === 'approve') {
                    setApproved_by_id(UserId)
                    setApproved_date(currentDate)
                } else if (keyForViewUpdate === 'financeApprove') {
                    setApproved_by_id(approved_by_id)
                    setApproved_date(approved_date)
                    setFinance_approved_by_id(UserId)
                    setFinance_approved_date(currentDate)
                } else {
                    setApproved_by_id(approved_by_id)
                    setApproved_date(approved_date)
                    setFinance_approved_by_id(finance_approved_by_id)
                    setFinance_approved_date(finance_approved_date)
                }
                if (keyForViewUpdate === 'cancel' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove') {
                    setBillBook_status(keyForViewUpdate === 'approve' ? 'A' : keyForViewUpdate === 'cancel' ? 'X' : 'F')
                    // Assuming grnDetailsRecords is an array of objects
                    const updatedBillBookDetailsRecords = billBookDetailsRecords.map(item => {
                        // Update the keys you want to modify
                        return {
                            ...item,
                            supplier_bill_item_booking_status: keyForViewUpdate === 'approve' ? 'A' : keyForViewUpdate === 'cancel' ? 'X' : 'F',
                        };
                    });
                    // Set the state with the updated array
                    setBillBookDetailsData(updatedBillBookDetailsRecords)
                    setStoredBillBookDetailsData(updatedBillBookDetailsRecords)
                } else {
                    setBillBookDetailsData(billBookDetailsRecords)
                    setStoredBillBookDetailsData(billBookDetailsRecords)
                }
                if (keyForViewUpdate === 'view' || isApprove) {
                    setApproved_by_id(approved_by_id);
                    setApproved_date(approved_date);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')

        } finally {
            setIsLoading(false)
        }
    }

    //fn for User Access Manage .
    const FnSetFieldsByUserAccess = () => {
        debugger
        if (keyForViewUpdate != null) {
            setIsApprove(keyForViewUpdate === 'approve' ? true : false);
            $('#cancel-btn').hide();
            $('#finance-btn').hide();

            switch (keyForViewUpdate) {
                case 'update':
                    $('#cmb_supplier_bill_booking_type_id').attr('disabled', true);
                    $('#txt_supplier_bill_booking_date').attr('disabled', true);
                    $('#cmb_supplier_id').attr('disabled', true);
                    $('#cmb_expected_branch_id').attr('disabled', true);
                    $('#save-btn').text('Update');
                    break;
                case 'financeApprove':
                case 'cancel':
                case 'view':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);

                    // // Disable specific options
                    // $("form").find('select#chk_billBook_status option').each(function () {
                    //     if ($(this).val() !== 'Z' && $(this).val() !== chk_billBook_status) {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    $('#clearFormBtn').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();

                    if (keyForViewUpdate === 'cancel') {
                        $('#cancel-btn').show();
                    } else if (keyForViewUpdate === 'financeApprove') {
                        $('#finance-btn').show();
                    }

                    break;
                case 'approve':
                    setIsView(true)
                    $('input[type="checkbox"]').prop('readonly', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('table .approveField').removeAttr("disabled");
                    $("form").find('select#chk_billBook_status').removeAttr("disabled");
                    $('#save-btn').text('Approve');
                    $('#clearFormBtn').hide();
                    // $("form").find('select#chk_billBook_status option').each(function () {
                    //     if ($(this).val() === 'Z') {// Assuming you want to disable options with values 'option2' and 'option3'
                    //         $(this).prop('disabled', true);
                    //     } else {
                    //         $(this).prop('disabled', false); // Enable the specific option
                    //     }
                    // });
                    break;
                case 'delete':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#clearFormBtn').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    if (keyForViewUpdate === 'cancel') {
                        $('#cancel-btn').show();
                    } else if (keyForViewUpdate === 'financeApprove') {
                        $('#finance-btn').show();
                    }
                    break;
            }
        }
    }


    const FnClearFormFields = async () => {
        setSupplier_bill_booking_master_transaction_id(0);
        setchk_biilBook_creation_type('M');
        // await GenerateBillBookNo();
        setsupplier_bill_booking_no('0');
        setSupplier_bill_booking_version(1);
        setSupplier_bill_booking_date(currentDate);
        setSupplier_bill_booking_type_id('');
        setSupplier_id('');
        setsupplier_contacts_ids('');
        setExpected_branch_id(COMPANY_BRANCH_ID);
        setApproved_by_id('');
        setApproved_date('');
        setFinance_approved_by_id('');
        setFinance_approved_date('');
        setBillBook_status('B');
        setTotal_amount('0');
        setTax1_id('');
        setTax1_percent(0);
        setTax1_amount(0);
        setTax2_id('');
        setTax2_percent(0);
        setTax2_amount(0);
        setDeduction_amount('0');
        setPayable_amount('0');
        setDeduction_remark('');
        setRemark('');

        // Set default values for other state variables
        setSuppContactDetails([]);
        let billBookClearedData = [];
        billBookClearedData.push(BookDetailBlankObject);
        setBillBookDetailsData(billBookClearedData);
        setRowCount(1);
        setStoredBillBookDetailsData([]);
        $('#error_supplier_contact_persons').hide();
        $('#error_good_receipt_order_no').hide();
    }

    const validateFields = (form_id) => {
        var formObj = $("#" + form_id);
        var inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === "text" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "select-one" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "textarea" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "date" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            }
        }
    };
    const refreshData = async (key) => {
        debugger
        switch (key) {
            case 'get_expectedBranch':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("company_branch_id");
                globalQuery.columns.push("company_branch_name");
                globalQuery.columns.push("is_sez");
                globalQuery.columns.push("branch_EmailId");
                globalQuery.table = "cmv_company_branch";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((exptBranchApiCall) => {
                    setExpectedBranchOptions(exptBranchApiCall);
                })

                break;
            case 'get_supplier':
                // get suppliers list with default state & city
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_supplier";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((suppliersApiCall) => {
                    setSupplierOptions(suppliersApiCall)
                })

                break;
            case 'get_tax':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("tax_value");
                globalQuery.table = "cmv_taxation";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((taxationApiCall) => {
                    setTax1Options(taxationApiCall);
                })

                break;
            default:
                break;
        }
    }
    //Fn to get default preload data
    const FnloadDefaultData = async () => {
        // get product types list
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const getProductType = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setProductTypeOptions(getProductType);
        if (requestfor !== "NA") {
            if (getProductType.length !== 0) {
                const productType = getProductType.find((item) => item.product_type_short_name === requestfor);
                if (productType) {
                    setSupplier_bill_booking_type_id(productType.field_id);
                    await GenerateBillBookNo(productType.product_type_short_name);
                }
            }
        }

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_supplier";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((suppliersApiCall) => {
            setSupplierOptions(suppliersApiCall)
        })


        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("company_branch_id");
        globalQuery.columns.push("company_branch_name");
        globalQuery.columns.push("is_sez");
        globalQuery.columns.push("branch_EmailId");
        globalQuery.table = "cmv_company_branch";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((exptBranchApiCall) => {
            setExpectedBranchOptions(exptBranchApiCall);
        })

        comboDataAPiCall.current.fillMasterData("cmv_employee", "", "").then((getEmployees) => {
            setApprovedByOptions(getEmployees);
        })

        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("tax_value");
        globalQuery.table = "cmv_taxation";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((taxationApiCall) => {
            setTax1Options(taxationApiCall);
        })

    }
    //Fn for generate bill bookrd no
    const GenerateBillBookNo = async (product_typeShortName) => {
        const shortname = product_typeShortName === undefined ? $('#cmb_supplier_bill_booking_type_id option:selected').attr('shortname') : product_typeShortName;
        const productTypeId = $('#cmb_supplier_bill_booking_type_id option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("ft_supplier_bill_booking_master", "supplier_bill_booking_no", shortname, "SB", "5", "supplier_bill_booking_type_id", productTypeId);
        setsupplier_bill_booking_no(autoNoApiCall);
        return autoNoApiCall;
    }
    // fn for fields onchange action
    const comboOnChange = async (key) => {
        switch (key) {
            case "productType":
                var productTypeVal = document.getElementById("cmb_supplier_bill_booking_type_id").value;
                setSupplier_bill_booking_type_id(productTypeVal);
                if (productTypeVal !== '0' && productTypeVal !== "") {
                    $("#error_cmb_supplier_bill_booking_type_id").hide();
                    await GenerateBillBookNo();
                } else if (productTypeVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setsupplier_bill_booking_no("0");

                } else {
                    setsupplier_bill_booking_no("0");
                }
                break;
            case "supplier":
                let supplierTpVal = document.getElementById("cmb_supplier_id").value;
                setSupplier_id(supplierTpVal);
                if (supplierTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Supplier')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (supplierTpVal != null && supplierTpVal != '' & supplierTpVal != '0') {
                    await FnGetSupplierContacts(parseInt(supplierTpVal));
                } else {
                    setSuppContactDetails([])
                }
                break;
            case "expectBranch":
                let exptBranchIdVal = document.getElementById("cmb_expected_branch_id").value;
                setExpected_branch_id(exptBranchIdVal);
                if (exptBranchIdVal === '0') {
                    const newTab = window.open('Masters/Company', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case "tax1":
                var tax_1Val = document.getElementById("txt_tax1_id").value;
                setTax1_id(tax_1Val);
                if (tax_1Val !== '0' && tax_1Val !== "") {
                    const tax_percent = $('#txt_tax1_id option:selected').attr('tax_value');
                    setTax1_percent(tax_percent)
                    FnCalculateTaxAmount(tax_percent, 'tax1amount');
                } else if (tax_1Val === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Taxation')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setTax1_percent(0);
                    setTax1_amount(0);
                } else {
                    setTax1_percent(0);
                    setTax1_amount(0);
                }
                setTimeout(() => {
                    FnCalculatePayableAmount();
                }, 300);
                break;
            case "tax2":
                var tax_2Val = document.getElementById("txt_tax2_id").value;
                setTax2_id(tax_2Val);
                if (tax_2Val !== '0' && tax_2Val !== "") {
                    const tax_percent = $('#txt_tax2_id option:selected').attr('tax_value');
                    setTax2_percent(tax_percent)
                    FnCalculateTaxAmount(tax_percent, 'tax2amount')
                } else if (tax_2Val === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Taxation')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setTax2_percent(0);
                    setTax2_amount(0);
                } else {
                    setTax2_percent(0);
                    setTax2_amount(0);
                }
                setTimeout(() => {
                    FnCalculatePayableAmount();
                }, 300);
                break;
            default:
                break;
        }
    };
    const FnCalculateTaxAmount = (tax_percent, tax_type) => {
        const tax_amount = txt_total_amount * tax_percent / 100;
        switch (tax_type) {
            case 'tax1amount':
                setTax1_amount(tax_amount)
                break;
            case 'tax2amount':
                setTax2_amount(tax_amount)
                break;
            default:
                break;
        }

    }
    // navigate to back listing page 
    const moveToListing = () => {
        let Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookListing/reg' : `/Transactions/TPurchaseOrder/TManualBillBook/FrmManualBillBookListing`;
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigate(Path);
    }

    //------------------------------------------------End Supplier Contact------------------------------------------
    //Function for get contact ids,name & mobile nos of supplier
    const FnGetSupplierContacts = async (SelectedSupplier) => {
        if (SelectedSupplier !== '' || SelectedSupplier !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
                "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

            globalQuery.table = "cmv_supplier_contacts";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: SelectedSupplier });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                setSuppContactDetails(getContactDtlsApiCall);
            } else {
                setSuppContactDetails([]);
            }
        }
        // Onchange event listener for the customer contact Selection
        $('.selectSuppContactPerson').on('change', function () {
            toggleChkAllBoxes('PartiallySuppContactSelection');
        });

    }

    //Fn to render supplier Contact details table
    const renderSupplierContactsTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='supplier-contacts-table' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Select</th>
                        <th className="erp_table_th">Branch Name</th>
                        <th className="erp_table_th">Contact Person</th>
                        <th className="erp_table_th">Contact No</th>
                        <th className="erp_table_th">Alternate Contact No</th>
                        <th className="erp_table_th">Email</th>
                        <th className="erp_table_th">Alternate Email</th>
                        <th className="erp_table_th">Designation</th>
                    </tr>
                </thead>
                <tbody>
                    {suppContactDetails.map((contactItem, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td">
                                <div style={{ display: "flex" }}>
                                    <div className="text-center">
                                        <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + contactItem.supplier_contact_id} contact_personId={contactItem.supplier_contact_id} value={contactItem.supplier_contact_id} />
                                    </div>
                                </div>
                            </td>
                            <td className="erp_table_td text-end">{contactItem.supplier_branch_name}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_contact_person}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_contact_no}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_alternate_contact}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_email_id}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_alternate_EmailId}</td>
                            <td className="erp_table_td text-end">{contactItem.supp_designation}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [suppContactDetails]);
    //function to check All checkBoxes of po terms and payment terms
    function toggleChkAllBoxes(key) {
        switch (key) {
            // For Supplier's contact
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;
            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
        }
    }

    //------------------------------------------------End Supplier Contact------------------------------------------
    //------------------------------------------------Add New Record start-------------------------------------
    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Product Type':
                return <FrmMProductTypeEntry />;
            case 'Add Taxation':
                return <FrmTaxationEntry />;
            default:
                return null;
        }
    }

    // Show ADD record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Product Type':
                setSupplier_bill_booking_type_id('');
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

                const poTypeApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setProductTypeOptions(poTypeApiCall);
                break;
            case 'Add Taxation':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("tax_value");
                globalQuery.table = "cmv_taxation";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((taxationApiCall) => {
                    setTax1Options(taxationApiCall);
                })

                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }
    //------------------------------------------------Add New Record End--------------------------------------------------------

    //------------------------------------------------bill Book Details End ------------------------------------------------- 






    //Fn to render bill book  details table
    const renderBillBookDetailsTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='billBook-details-table' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        {keyForViewUpdate === 'update' || keyForViewUpdate === "" ?
                            <th className="erp_table_th">Action</th> :
                            null
                        }
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Purchase Order No</th>
                        <th className="erp_table_th">Goods Receipt No</th>
                        <th className="erp_table_th">Supplier Challan No</th>
                        <th className="erp_table_th">Supplier Challan Date</th>
                        <th className="erp_table_th">Supplier Invoice No</th>
                        <th className="erp_table_th">Supplier Invoice Date</th>
                        <th className="erp_table_th">Bill Book Total</th>
                        <th className="erp_table_th">Expected Payment Date</th>
                        <th className="erp_table_th">Payment Due Date</th>
                        <th className="erp_table_th">Basic Total</th>
                        <th className="erp_table_th">Discount Amount</th>
                        <th className="erp_table_th">Taxable Total</th>
                        <th className="erp_table_th">Cgst Total</th>
                        <th className="erp_table_th">Sgst Total</th>
                        <th className="erp_table_th">Igst Total</th>
                        <th className="erp_table_th">Freight Amount</th>
                        <th className="erp_table_th">Packing Amount</th>
                        <th className="erp_table_th">Other Amount</th>
                        <th className="erp_table_th"> Round Off </th>
                        <th className="erp_table_th">Purchase Order Date</th>
                        <th className="erp_table_th">Purchase Order Version</th>
                        <th className="erp_table_th">Goods Receipt Date</th>
                        <th className="erp_table_th">Goods Receipt Version</th>
                        <th className="erp_table_th">Bill Booking Item Status</th>
                        <th className="erp_table_th">Bill Booking Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {billBookDetailsData.map((billItem, index) => (
                        <tr rowIndex={index}>
                            {keyForViewUpdate === 'update' || keyForViewUpdate === "" ?
                                <td>
                                    <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => keyForViewUpdate === '' || keyForViewUpdate === 'update' ? removeFirstRow(index) : null} />
                                    {
                                        <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => keyForViewUpdate === '' || keyForViewUpdate === 'update' ? setRowCountAndAddRow(rowCount + 1) : null} />
                                    }
                                </td> :
                                null
                            }

                            <td className="erp_table_td ">{index + 1}</td>

                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0" Headers='purchase_order_no'
                                    id={`purchase_order_no_${index}`}
                                    value={billItem.purchase_order_no}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>

                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0" Headers='goods_receipt_no'
                                    id={`goods_receipt_no_${index}`}
                                    value={billItem.goods_receipt_no}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0" Headers='supplier_challan_no'
                                    id={`supplier_challan_no_${index}`}
                                    value={billItem.supplier_challan_no}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td">
                                <input type="date" className="erp_input_field mb-0"
                                    value={billItem.supplier_challan_date}
                                    id={"supplier_challan_date_" + index}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }}
                                    min={currentDate} Headers='supplier_challan_date' />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0" Headers='supplier_invoice_no'
                                    id={`supplier_invoice_no_${index}`}
                                    value={billItem.supplier_invoice_no}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td">
                                <input type="date" className="erp_input_field mb-0" value={billItem.supplier_invoice_date}
                                    id={"supplier_invoice_date_" + index}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }}
                                    min={currentDate} Headers='supplier_invoice_date' />
                            </td>
                            <td className="erp_table_td text-end">{billItem.bill_total}
                                {/* <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    id={`bill_total_${index}`} Headers="bill_total"
                                    value={billItem.bill_total} readOnly
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} /> */}
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="date" className="erp_input_field mb-0"
                                    id={`expected_schedule_date_${index}`}
                                    Headers="expected_schedule_date"
                                    value={billItem.expected_schedule_date} min={currentDate}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="date" Headers="payment_due_date"
                                    className="erp_input_field mb-0"
                                    id={`payment_due_date_${index}`}
                                    value={billItem.payment_due_date}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td  ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="basic_total"
                                    id={`basic_total_${index}`}
                                    value={billItem.basic_total}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="discount_amount"
                                    id={`discount_amount_${index}`}
                                    value={billItem.discount_amount}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td text-end ">{billItem.taxable_total}
                                {/* <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="taxable_total"
                                    id={`taxable_total_${index}`}
                                    value={billItem.taxable_total} readOnly
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} /> */}
                            </td>
                            <td className="erp_table_td  ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="cgst_total"
                                    id={`cgst_total_${index}`}
                                    value={billItem.cgst_total}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="sgst_total"
                                    id={`sgst_total_${index}`}
                                    value={billItem.sgst_total}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>

                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end "
                                    Headers="igst_total"
                                    id={`igst_total_${index}`}
                                    value={billItem.igst_total}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>

                            <td className="erp_table_td  ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="freight_amount"
                                    id={`freight_amount_${index}`}
                                    value={billItem.freight_amount}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td  ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="packing_amount"
                                    id={`packing_amount_${index}`}
                                    value={billItem.packing_amount}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>

                            <td className="erp_table_td  ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="other_amount"
                                    id={`other_amount_${index}`}
                                    value={billItem.other_amount}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }}
                                />
                            </td>

                            <td className="erp_table_td text-end">{billItem.roundoff}
                                {/* <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="roundoff"
                                    id={`roundoff_${index}`} readOnly
                                    value={billItem.roundoff} /> */}
                            </td>

                            <td className="erp_table_td ">
                                <input
                                    type="date" Headers="purchase_order_date" className="erp_input_field mb-0"
                                    id={`purchase_order_date_${index}`}
                                    value={billItem.purchase_order_date}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>

                            <td className="erp_table_td  ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="purchase_order_version"
                                    id={`purchase_order_version_${index}`}
                                    value={billItem.purchase_order_version}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }} />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="date" Headers="goods_receipt_date"
                                    className="erp_input_field mb-0"
                                    id={`goods_receipt_date${index}`}
                                    value={billItem.goods_receipt_date}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }}
                                />
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0 text-end"
                                    Headers="goods_receipt_version"
                                    id={`goods_receipt_version_${index}`}
                                    value={billItem.goods_receipt_version}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }}
                                />
                            </td>

                            <td className="erp_table_td ">
                                <select id={`supplier_bill_item_booking_status_${index}`} disabled={!isApprove} value={billItem.supplier_bill_item_booking_status} className="form-select form-select-sm approveField"
                                    Headers='supplier_bill_item_booking_status' onChange={(e) => { updateBillBookDTblRows(billItem, e); }} >
                                    <option value="B">B-Bill Booked</option>
                                    <option value="A">Approved</option>
                                    <option value="F">Finanace Approved</option>
                                    <option value="R">Partial Payment Done</option>
                                    <option value="C">Payment Done</option>
                                    <option value="X">Canceled</option>
                                </select>
                            </td>
                            <td className="erp_table_td ">
                                <input
                                    type="text"
                                    className="erp_input_field mb-0"
                                    Headers="remark"
                                    id={`remark_${index}`}
                                    value={billItem.remark}
                                    onChange={(e) => { updateBillBookDTblRows(billItem, e); }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [billBookDetailsData, isView, isApprove]);

    const updateBillBookDTblRows = (rowData, event) => {
        debugger
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        let {
            bill_total
        } = rowData;

        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;

        switch (clickedColName) {
            case 'purchase_order_no':
            case 'goods_receipt_no':
            case 'supplier_challan_no':
            case 'supplier_challan_date':
            case 'supplier_invoice_no':
            case 'expected_schedule_date':
            case 'payment_due_date':
            case 'purchase_order_date':
            case 'purchase_order_version':
            case 'goods_receipt_date':
            case 'goods_receipt_version':
            case 'supplier_invoice_date':
            case 'supplier_bill_item_booking_status':
            case 'remark':
                rowData[clickedColName] = event.target.value;
                break

            case 'basic_total':
                if (enteredValue <= '0' && enteredValue === "") {
                    let actualValidation = document.querySelector(`#${event.target.id}`);
                    actualValidation.parentElement.dataset.tip = `Bill Amount Cannot be 0...!`;
                    return false;
                } else {
                    const discAmount = rowData['discount_amount'];
                    if (discAmount > enteredValue) {
                        rowData['discount_amount'] = '0';
                    }
                    rowData[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue).toString(), 4);

                    fnCalculateBillItem(rowData);
                    delete event.target.parentElement.dataset.tip;
                }
                break;
            case 'other_amount':
            case 'packing_amount':
            case 'freight_amount':
            case 'cgst_total':
            case 'sgst_total':
                rowData[clickedColName] = event.target.value;
                if (event.target.value !== "" && event.target.value !== "0") {
                    $(`#igst_total_${arrayIndex}`).attr('disabled', 'disabled');
                    rowData['igst_total'] = '0';
                } else {
                    $(`#igst_total_${arrayIndex}`).removeAttr("disabled");

                }
                fnCalculateBillItem(rowData);
                break;
            case 'igst_total':
                rowData[clickedColName] = event.target.value;
                if (event.target.value !== "" && event.target.value !== "0") {
                    $(`#cgst_total_${arrayIndex}`).attr('disabled', 'disabled');
                    $(`#sgst_total_${arrayIndex}`).attr('disabled', 'disabled');
                    rowData['cgst_total'] = '0';
                    rowData['sgst_total'] = '0';
                } else {
                    $(`#cgst_total_${arrayIndex}`).removeAttr("disabled");
                    $(`#sgst_total_${arrayIndex}`).removeAttr("disabled");
                }
                fnCalculateBillItem(rowData);
                break;
            case 'discount_amount':
                let discountamountValidation = document.querySelector(`#${event.target.id}`);
                const basicval = rowData.basic_total;
                if (enteredValue !== '') {
                    if (parseFloat(basicval) >= parseFloat(enteredValue)) {
                        delete discountamountValidation.parentElement.dataset.tip;
                        rowData[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue).toString(), 4);
                        fnCalculateBillItem(rowData);
                    } else {

                        discountamountValidation.parentElement.dataset.tip = `Discount Amount Cannot be greter than basic amount ...!`;
                        return false;
                    }
                } else {
                    rowData[clickedColName] = '';
                    discountamountValidation.parentElement.dataset.tip = `Please fill this field...!`;
                }
                break;
        }
        const detailData = [...billBookDetailsData]
        detailData[arrayIndex] = rowData
        setBillBookDetailsData(detailData);
    }
    const fnCalculateBillItem = (rowData) => {
        debugger
        let basic_total = isNaN(parseFloat(rowData.basic_total)) ? 0 : parseFloat(rowData.basic_total);
        let discount_amount = isNaN(parseFloat(rowData.discount_amount)) ? 0 : parseFloat(rowData.discount_amount);
        let cgst_total = isNaN(parseFloat(rowData.cgst_total)) ? 0 : parseFloat(rowData.cgst_total);
        let sgst_total = isNaN(parseFloat(rowData.sgst_total)) ? 0 : parseFloat(rowData.sgst_total);
        let igst_total = isNaN(parseFloat(rowData.igst_total)) ? 0 : parseFloat(rowData.igst_total);
        let other_amount = isNaN(parseInt(rowData.other_amount)) ? 0 : parseInt(rowData.other_amount);
        let packing_amount = isNaN(parseInt(rowData.packing_amount)) ? 0 : parseInt(rowData.packing_amount);
        let freight_amount = isNaN(parseFloat(rowData.freight_amount)) ? 0 : parseFloat(rowData.freight_amount);

        let taxable_total = 0;
        let bill_total = 0;
        let roundoff = 0;

        taxable_total = basic_total - discount_amount;
        bill_total = taxable_total + cgst_total + sgst_total + igst_total + freight_amount + other_amount + packing_amount;
        const billTotal = Math.round(bill_total);
        roundoff = (bill_total - Math.floor(bill_total));
        if (roundoff >= 0.50) {
            roundoff = (roundoff - 1); // Subtract 1 to get negative round-off
        }
        rowData.taxable_total = validateNumberDateInput.current.decimalNumber((taxable_total).toString(), 4);
        rowData.bill_total = validateNumberDateInput.current.decimalNumber((billTotal).toString(), 4);
        rowData.roundoff = validateNumberDateInput.current.decimalNumber((roundoff).toString(), 4);
    }
    // to scroll suppliers contact ids box
    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('billBook-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#billBook-details-table').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('billBook-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    //-----------------------------------------------------Calculations Starts---------------------------------------------------------
    const FnCalculatePayableAmount = () => {
        let total_amount = 0;
        let tax1_amount = 0;
        let tax2_amount = 0;
        let payable_amount = 0;

        // calculate total amount of bill book
        total_amount = [...billBookDetailsData].reduce((total, item) => total + parseFloat(item.bill_total) + parseFloat(item.roundoff), 0);
        console.log('total_amount: ', total_amount);
        setTotal_amount(validateNumberDateInput.current.decimalNumber((total_amount).toString(), 4));

        tax1_amount = parseFloat(total_amount) * parseInt(txt_tax1_percent) / 100;
        tax2_amount = parseFloat(total_amount) * parseInt(txt_tax2_percent) / 100;
        payable_amount = total_amount - tax1_amount - tax2_amount - txt_deduction_amount;

        setPayable_amount(validateNumberDateInput.current.decimalNumber((payable_amount).toString(), 4))
        setTax1_amount(validateNumberDateInput.current.decimalNumber((tax1_amount).toString(), 4))
        setTax2_amount(validateNumberDateInput.current.decimalNumber((tax2_amount).toString(), 4))
    }
    //-----------------------------------------------------Calculations Ends---------------------------------------------------------

    //---------------------------------------------------save Functionality Starts----------------------------------------------------------

    const addBillBook = async (billbookstatus) => {
        debugger
        try {
            const validateForm = await FnValidateBillBook();
            if (validateForm) {
                setIsLoading(true)
                let json = { 'TransHeaderFooterData': {}, 'TransDetailData': [], 'TransPurchaseBillTaxData': [], 'commonIds': {} }
                let supplierContactPersonsIds = await FnGetSupplierContactPersonIds();
                let supplier_bill_booking_no = txt_supplier_bill_booking_no;

                if (keyForViewUpdate === '') {
                    supplier_bill_booking_no = await GenerateBillBookNo();
                }
                // TransHeaderData
                const billBook_form_data = {
                    supplier_bill_booking_master_transaction_id: isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? supplier_bill_booking_master_transaction_id : 0, company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    supplier_bill_booking_creation_type: chk_biilBook_creation_type,
                    supplier_bill_booking_no: supplier_bill_booking_no,
                    supplier_bill_booking_date: txt_supplier_bill_booking_date,
                    supplier_bill_booking_version: txt_supplier_bill_booking_version,
                    supplier_bill_booking_type_id: cmb_supplier_bill_booking_type_id,
                    supplier_bill_booking_type: $('#cmb_supplier_bill_booking_type_id').find(":selected").text(),
                    supplier_id: cmb_supplier_id,
                    supplier_contacts_ids: supplierContactPersonsIds,
                    expected_branch_id: cmb_expected_branch_id,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: txt_approved_date,
                    finance_approved_by_id: cmb_finance_approved_by_id,
                    finance_approved_date: txt_finance_approved_date,
                    supplier_bill_booking_status: billbookstatus === "X" ? billbookstatus : chk_billBook_status,
                    total_amount: txt_total_amount,
                    tax1_id: txt_tax1_id,
                    tax1_percent: txt_tax1_percent,
                    tax1_amount: txt_tax1_amount,
                    tax2_id: txt_tax2_id,
                    tax2_percent: txt_tax1_percent,
                    tax2_amount: txt_tax1_amount,
                    deduction_amount: txt_deduction_amount,
                    payable_amount: txt_payable_amount,
                    deduction_remark: txt_deduction_remark,
                    remark: txt_remark,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' ? UserName : ''
                }
                json.TransHeaderFooterData = billBook_form_data;
                console.log('HeaderData: ', json.TransHeaderFooterData);

                for (let billItem = 0; billItem < billBookDetailsData.length; billItem++) {
                    const billBookDetailsRow = billBookDetailsData[billItem];

                    const billBookRow = {};
                    // Set values for each column
                    billBookRow['supplier_bill_booking_details_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? billBookDetailsRow.supplier_bill_booking_details_transaction_id : 0
                    billBookRow['supplier_bill_booking_master_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? supplier_bill_booking_master_transaction_id : 0
                    billBookRow['company_id'] = COMPANY_ID
                    billBookRow['company_branch_id'] = COMPANY_BRANCH_ID
                    billBookRow['financial_year'] = FINANCIAL_SHORT_NAME
                    billBookRow['supplier_bill_booking_no'] = supplier_bill_booking_no
                    billBookRow['supplier_bill_booking_date'] = txt_supplier_bill_booking_date
                    billBookRow['supplier_bill_booking_version'] = txt_supplier_bill_booking_version
                    billBookRow['purchase_order_no'] = billBookDetailsRow.purchase_order_no
                    billBookRow['goods_receipt_no'] = billBookDetailsRow.goods_receipt_no
                    billBookRow['supplier_challan_no'] = billBookDetailsRow.supplier_challan_no
                    billBookRow['supplier_challan_date'] = billBookDetailsRow.supplier_challan_date
                    billBookRow['supplier_invoice_no'] = billBookDetailsRow.supplier_invoice_no
                    billBookRow['supplier_invoice_date'] = billBookDetailsRow.supplier_invoice_date
                    billBookRow['bill_total'] = billBookDetailsRow.bill_total
                    billBookRow['expected_schedule_date'] = billBookDetailsRow.expected_schedule_date
                    billBookRow['payment_due_date'] = billBookDetailsRow.payment_due_date
                    billBookRow['basic_total'] = billBookDetailsRow.basic_total
                    billBookRow['freight_amount'] = billBookDetailsRow.freight_amount
                    billBookRow['packing_amount'] = billBookDetailsRow.packing_amount
                    billBookRow['discount_amount'] = billBookDetailsRow.discount_amount
                    billBookRow['other_amount'] = billBookDetailsRow.other_amount
                    billBookRow['taxable_total'] = billBookDetailsRow.taxable_total
                    billBookRow['cgst_total'] = billBookDetailsRow.cgst_total
                    billBookRow['sgst_total'] = billBookDetailsRow.sgst_total
                    billBookRow['igst_total'] = billBookDetailsRow.igst_total
                    billBookRow['roundoff'] = billBookDetailsRow.roundoff
                    billBookRow['purchase_order_date'] = billBookDetailsRow.purchase_order_date
                    billBookRow['purchase_order_version'] = billBookDetailsRow.purchase_order_version
                    billBookRow['goods_receipt_date'] = billBookDetailsRow.goods_receipt_date
                    billBookRow['goods_receipt_version'] = billBookDetailsRow.goods_receipt_version
                    billBookRow['supplier_bill_item_booking_status'] = billbookstatus === "X" ? billbookstatus : billBookDetailsRow.supplier_bill_item_booking_status
                    billBookRow['remark'] = billBookDetailsRow.remark
                    billBookRow['created_by'] = UserName
                    billBookRow['modified_by'] = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' ? UserName : ''

                    json.TransDetailData.push(billBookRow);
                }
                console.log(json.TransDetailData);
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.supplier_bill_booking_no = supplier_bill_booking_no
                json.commonIds.supplier_bill_booking_version = txt_supplier_bill_booking_version
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log("json of Bill Book New: ", json);
                let isApproveOrCancel = 'AddUpdate';
                if (keyForViewUpdate === 'approve') {
                    isApproveOrCancel = 'approve';
                } else if (keyForViewUpdate === 'cancel') {
                    isApproveOrCancel = 'cancel';
                } else if (keyForViewUpdate === 'financeApprove') {
                    isApproveOrCancel = 'financeApprove';
                }
                const formData = new FormData();
                formData.append(`PtsupplierBillBookingData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnAddUpdateRecord/${isApproveOrCancel}`, requestOptions);
                const response = await apicall.json();
                if (response.success === "0") {
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setMessage(response.message)
                    if (response.data.hasOwnProperty('supplier_bill_booking_no') && response.data.hasOwnProperty('supplier_bill_booking_version')) {
                        setModalOrderDetails(`Bill Booking No: ${response.data.supplier_bill_booking_no} and Version: ${response.data.supplier_bill_booking_version}`)
                    }
                    if (supplier_bill_booking_master_transaction_id === 0) {
                        setMessageForConfirmation('')
                    }
                    setShowConfirmationModal(true);
                }
            }
        } catch (error) {
            console.log('error on addBill: ', error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }

    //validate po form 
    const FnValidateBillBook = async () => {
        let headerDataIsValid = await validate.current.validateForm("billBook_header_formId");//billBook_footer_formId
        if (!headerDataIsValid) { return false; }
        if ($('input:checkbox.selectSuppContactPerson:checked').length === 0) {
            $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
            $('#error_supplier_contact_persons').show();
            $('#chk_billBook_status').focus();
            return false;
        } else {
            $('#error_supplier_contact_persons').hide();
        }

        let billBookDetailsIsValid = true;
        let addedMaterials = $('#billBook-details-table tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Good Receipt Note...!');
            setShowErrorMsgModal(true);
            return billBookDetailsIsValid = false;
        } else {
            $('#billBook-details-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let basic_total = parseFloat(currentTblRow.find('input[id^="basic_total_"]').val());
                if (isNaN(basic_total) || basic_total <= 0 || basic_total === '') {
                    $(currentTblRow.find('input[id^="basic_total_"]'))[0].parentElement.dataset.tip = 'Please Enter Basic Amount...!';
                    $(currentTblRow.find('input[id^="basic_total_"]'))[0].focus();
                    return billBookDetailsIsValid = false;
                }
                // billBookDetailsIsValid = true;
            });
            if (txt_deduction_amount > 0) {
                if (parseInt(txt_deduction_amount) > parseInt(txt_total_amount)) {
                    $('#error_txt_deduction_amount').text('Please Enter Less then Total Amount...!');
                    $('#error_txt_deduction_amount').show();
                    return billBookDetailsIsValid = false;
                } else if (txt_deduction_remark === '') {
                    $('#error_txt_deduction_remark').text('Please Enter Deduction Remark...!');
                    $('#error_txt_deduction_remark').show();
                    return billBookDetailsIsValid = false;
                }
            } else {
                setDeduction_remark("");
            }
            return billBookDetailsIsValid;
        }
    }
    const FnGetSupplierContactPersonIds = async () => {
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingSupplierContactPersonIds = async (contactIds) => {
        const supplierContactPersonsIds = contactIds.split(':');
        supplierContactPersonsIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
        });
        toggleChkAllBoxes('PartiallySuppContactSelection');
    }
    //---------------------------------------------------save Functionality Ends----------------------------------------------------------

    //    ...................supplier contacts new .........................................
    const removeFirstRow = (indexToRemove) => {
        if (keyForViewUpdate !== "approve") {

            if (indexToRemove !== 0) {
                const updatedBillBookData = billBookDetailsData.filter((item, index) => index !== indexToRemove);
                setBillBookDetailsData(updatedBillBookData)
            } else {
                let updatedBillBookData = [...billBookDetailsData];  // Create a copy of the array
                updatedBillBookData[0] = { ...BookDetailBlankObject }; // Set values of 0th index to the contactBlankObject
                setBillBookDetailsData(updatedBillBookData);
            }
        }

    }


    // const validateMaterialDetails = (event) => {
    //     const mailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //     let supplierMailCheckQtyinp = document.querySelector('#' + event.target.id);
    //     if (event.target.value === '' || event.target.value === null || !mailCheck.test(event.target.value)) {
    //         supplierMailCheckQtyinp.parentElement.dataset.tip = 'Please enter valid Mail...!';
    //         return false;
    //     } else {
    //         delete supplierMailCheckQtyinp.parentElement.dataset.tip;
    //         return true;
    //     }
    // }


    const updateCountState = () => {
        // var count = rowCount + 1;
        // addTableRow(count);
        setRowCount(rowCount + 1)
    }
    async function deleteBillBook() {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnDeleteRecord/${idList.supplier_bill_booking_version}/${COMPANY_ID}?supplier_bill_booking_no=${idList.supplier_bill_booking_no}&user_name=${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("Manual Bill Book entry deleted : ", responce);
            setShow(false)
        } catch (error) {
            console.log("error: ", error)
        }
    }
    const reload = () => {
        window.location.reload();
    }
    //............................supplier contacts new endss..................................................................................................................................


    // const fnCheckValidDeductionAmt = (e) =>{
    //     let enteredValue = parseInt(e.target.value);
    //     const deductionAmt = validateNumberDateInput.current.decimalNumber((enteredValue).toString(), 4);
    //     setDeduction_amount(deductionAmt )
    //     if (deductionAmt > txt_total_amount ){
    //         setDeduction_amount(0)
    //         $('#error_txt_deduction_amount').text('Deduction Amount must be less than Total Amount');
    //         $('#error_txt_deduction_amount').show();
    //     }else if(deductionAmt <txt_total_amount ){
    //         $('#error_txt_deduction_amount').hide();
    //     }
    // }

    //..................................................................................................................................................................................

    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }

    }
    //--------------------------------- Document List Starts--------------------------------------------------

    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_supplier_bill_booking_no !== 0 ? txt_supplier_bill_booking_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                {/* <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td> */}
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = 'info'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = 'initial'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);



    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.document_group,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }

    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------

    // const FnGetBlackRowClear = () =>{
    //     {const getExistingbillBookDetailsData = [...billBookDetailsData]
    //         getExistingbillBookDetailsData.push(BookDetailBlankObject)
    //         setBillBookDetailsData(getExistingbillBookDetailsData)}
    // }
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            {/* <ExcelExport ref={exlsExp} /> */}
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                {
                    isLoading ?
                        <div className="spinner-overlay">
                            < div className="spinner-container" >
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div >
                        </div > :
                        ''
                }
                <div className='main_heding'>
                    <label className='erp-form-label-lg main_heding'>
                        {requestTypeHeading()} Bill Book (Direct) {ActionType()}</label>
                </div>

                <div className='card p-4'>
                    <form id="billBook_header_formId">
                        <div className="row">
                            {/* //first column */}
                            <div className="col-sm-6 erp_form_col_div">
                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Bill Book Creation Type </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Direct" type="radio" lbl="Direct" value="M" name="chk_biilBook_creation_type" checked={chk_biilBook_creation_type === "M"} onClick={() => { setchk_biilBook_creation_type("M"); comboOnChange('by_direct') }} /> </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_supplier_bill_booking_type_id" className="form-select form-select-sm" value={cmb_supplier_bill_booking_type_id} onChange={(e) => { setSupplier_bill_booking_type_id(e.target.value); comboOnChange('productType'); validateFields('billBook_header_formId'); }} disabled={requestfor !== "NA"} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {producttypeOptions.length !== 0 ?
                                                <>
                                                    {producttypeOptions?.map(poTypeId => (
                                                        <option value={poTypeId.field_id} shortname={poTypeId.product_type_short_name}>{poTypeId.field_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_supplier_bill_booking_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Bill Book No,Version & Date</Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <div className="row">
                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <Form.Control type="text" id="txt_supplier_bill_booking_no" className="erp_input_field" value={txt_supplier_bill_booking_no} optional='optional' disabled />

                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3">
                                                <Form.Control type="text" id='txt_supplier_bill_booking_version' className="erp_input_field" value={txt_supplier_bill_booking_version} optional='optional' disabled />

                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                <Form.Control type="date" id='txt_supplier_bill_booking_date' className="erp_input_field" value={txt_supplier_bill_booking_date} onChange={e => { setSupplier_bill_booking_date(e.target.value); validateFields('billBook_header_formId'); }} min={currentDate} />
                                                <MDTypography variant="button" id="error_txt_billBook_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Supplier<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_supplier_id" className="form-select form-select-sm" value={cmb_supplier_id} onChange={() => { comboOnChange("supplier"); validateFields('billBook_header_formId'); }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {supplierOptions.length !== 0 ?
                                                <>
                                                    {supplierOptions?.map(cmb_supplier_id => (
                                                        <option value={cmb_supplier_id.field_id} >{cmb_supplier_id.field_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_supplier') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="row">
                                    <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">supplier's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                {
                                                    cmb_supplier_id !== '' && cmb_supplier_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}>
                                                                <div className={` d-flex justify-content-end align-items-center`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_id) }}>Refresh</MDButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    suppContactDetails.length > 0
                                                        ? <>
                                                            <div className='row ps-2'>
                                                                <div className="col-12 erp_table_scroll">
                                                                    <div class="col  pl-2">
                                                                        <input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes('selectAllSuppContact'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                                                    </div>
                                                                    {renderSupplierContactsTbl}
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <>
                                                            <div className='row text-center'>
                                                                <div className="col-12">
                                                                    <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                                                </div>
                                                            </div></>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_supplier_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>

                            </div>
                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Company Branch<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { setExpected_branch_id(e.target.value); comboOnChange('expectBranch'); validateFields('billBook_header_formId'); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add Record+</option>
                                            {expectedBranchOptions.length !== 0 ?
                                                <>
                                                    {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                        <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_expectedBranch') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                {(((keyForViewUpdate !== '' || keyForViewUpdate !== 'cancel') && chk_billBook_status !== 'B')) ? (
                                    <>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Approved By</Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                                                    <option value="">Select</option>
                                                    {approvedByOptions.length !== 0 ?
                                                        <>
                                                            {approvedByOptions?.map(cmb_approved_by_id => (
                                                                <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                            ))}
                                                        </>
                                                        : ''
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        {((chk_billBook_status === 'F' && (keyForViewUpdate === 'financeApprove' || keyForViewUpdate === 'view'))) ? (
                                            <>
                                                <div className='row'>
                                                    <div className='col-sm-4'>
                                                        <Form.Label className="erp-form-label">Finanace Approved By</Form.Label>
                                                    </div>
                                                    <div className='col'>
                                                        <select id="cmb_finance_approved_by_id" className="form-select form-select-sm " value={cmb_finance_approved_by_id} onChange={(e) => { setFinance_approved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                                                            <option value="">Select</option>
                                                            {approvedByOptions.length !== 0 ?
                                                                <>
                                                                    {approvedByOptions?.map(cmb_approved_by_id => (
                                                                        <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                                    ))}
                                                                </>
                                                                : ''
                                                            }
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_finance_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-sm-4">
                                                        <Form.Label className="erp-form-label">Finanace Approved Date </Form.Label>
                                                    </div>
                                                    <div className="col">
                                                        <Form.Control type="date" id='txt_finance_approved_date' className="erp_input_field" value={txt_finance_approved_date} onChange={e => { setFinance_approved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                        <MDTypography variant="button" id="error_txt_finance_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </>)
                                            : ''
                                        }
                                    </>)
                                    : ''
                                }
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Bill Book Status </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="chk_billBook_status" className="form-select form-select-sm " value={chk_billBook_status} onChange={(e) => { setBillBook_status(e.target.value); }} disabled >
                                            <option value="B">B-Bill Booked</option>
                                            <option value="A">Approved</option>
                                            <option value="F">Finanace Approved</option>
                                            <option value="R">Partial Payment Done</option>
                                            <option value="C">Payment Done</option>
                                            <option value="X">Canceled</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Bill Book Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex row">
                                <div className="col-md-4 col-12">
                                    <div className="row">
                                        <div className="col-sm-6 d-flex">
                                            <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field txt-filter-bom ${billBookDetailsData.length === 0 ? 'd-none' : 'display'}`} />
                                            <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${billBookDetailsData.length === 0 ? 'd-none' : 'display'}`} variant="button" onClick={() => scrollToTableRow()}
                                                fontWeight="regular">Search</MDButton>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="erp-Mt-10">
                                {billBookDetailsData.length !== 0 ?
                                    <>
                                        {renderBillBookDetailsTbl}
                                    </> :
                                    ""
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {keyForViewUpdate !== '' ?
                    <> <hr />
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                <Accordion.Body>
                                    {docData.length !== 0 ? (
                                        renderDocumentTable
                                    ) : (
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    : null
                }
                <hr />
                {/* footer Section Starts*/}
                <div className="row">
                    <div className='main_heding text-start'>
                        <label className='erp-form-label-lg'>Bill Book Totals:</label>
                    </div>
                    <form id="billBook_footer_formId">
                        <div className="row">
                            {/* //first column */}
                            <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Total Amount</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_total_amount" className="erp_input_field text-end" value={txt_total_amount} disabled onChange={(e) => setTotal_amount(e.target.value)} />
                                        <MDTypography variant="button" id="error_txt_total_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Tax 1</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_tax1_id" className="form-select form-select-sm" value={txt_tax1_id} onChange={(e) => { comboOnChange('tax1'); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {tax1Options?.map(txt_tax1_id => (
                                                <option value={txt_tax1_id.field_id} tax_value={txt_tax1_id.tax_value}>{txt_tax1_id.field_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_tax') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Tax 1 Percent</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax1_percent" className="erp_input_field text-end" value={txt_tax1_percent} onChange={(e) => setTax1_percent(e.target.value)} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax1_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax1_amount" className="erp_input_field text-end" value={txt_tax1_amount} onChange={(e) => { setTax1_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax1_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Tax 2</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_tax2_id" className="form-select form-select-sm" value={txt_tax2_id} onChange={(e) => { comboOnChange('tax2'); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {tax1Options?.map(txt_tax2_id => (
                                                <option value={txt_tax2_id.field_id} tax_value={txt_tax2_id.tax_value}>{txt_tax2_id.field_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_tax') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Tax 2 Percent</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax2_percent" className="erp_input_field text-end" value={txt_tax2_percent} onChange={(e) => setTax2_percent(e.target.value)} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax2_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_tax2_amount" className="erp_input_field text-end" value={txt_tax2_amount} onChange={(e) => { setTax2_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} readOnly />
                                        <MDTypography variant="button" id="error_txt_tax2_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* //second column */}
                            <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Deduction Amount</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_deduction_amount" className="erp_input_field text-end" value={txt_deduction_amount} maxLength={19} onChange={(e) => { setDeduction_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); validateFields('billBook_footer_formId'); }} />
                                        <MDTypography variant="button" id="error_txt_deduction_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Payable Amount</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_payable_amount' className="erp_input_field text-end" value={txt_payable_amount} disabled />
                                        <MDTypography variant="button" id="error_txt_payable_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Deduction Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_deduction_remark' className="erp_input_field " value={txt_deduction_remark} disabled={txt_deduction_amount > "0" && txt_deduction_amount !== '' ? '' : 'disabled'} onChange={(e) => { txt_deduction_amount > 0 && txt_deduction_amount !== '' ? setDeduction_remark(e.target.value) : setDeduction_remark(""); validateFields('billBook_footer_formId'); }} optional={txt_deduction_amount > 0 && txt_deduction_amount !== '' ? 'optional' : ''} />
                                        <MDTypography variant="button" id="error_txt_deduction_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_remark' className="erp_input_field " value={txt_remark} onChange={(e) => { setRemark(e.target.value) }} />
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">PO IsActive</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_po_isactive" checked={chk_po_isactive === true} onClick={() => { setPOIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="chk_po_isactive" checked={chk_po_isactive === false} onClick={() => { setPOIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </form>
                    {/* Billing Section Ends*/}

                </div>
                <div className="erp_frm_Btns">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => addBillBook('A')} fontWeight="regular"  >Save</MDButton>
                    <MDButton type="button" id="cancel-btn" className={`erp-gb-button erp_MLeft_btn  `} variant="button" onClick={() => addBillBook('X')}>Cancel </MDButton>
                    <MDButton type="button" id="finance-btn" className={`erp-gb-button erp_MLeft_btn`} variant="button" onClick={() => addBillBook('F')}>Finanace approve</MDButton>
                    <MDButton type="button" id="viewdocument-id" className={`erp-gb-button erp_MLeft_btn ${supplier_bill_booking_master_transaction_id === 0 ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;
                    <MDButton type="button" id="clearFormBtn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={FnClearFormFields} fontWeight="regular"
                        disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'financeApprove' || keyForViewUpdate === 'approve' ? 'disabled' : ''}
                    >Clear</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>

                </div >


                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal >
                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Do you wish to delete this record ?</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteBillBook}>Delete</Button>
                    </Modal.Footer>

                </Modal>
                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_supplier_bill_booking_no.replaceAll('/', '_')} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {showErrorMsgModal ?
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                    : null
                }
                {showConfirmationModal ?
                    <ConfirmationModal close={() => closeConfirmationModal()} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
                    : null
                }

            </DashboardLayout >
        </>
    )
}

export default FrmManualBillBookEnrty