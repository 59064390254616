import React from 'react'

function FrmJobAssignListing() {
  return (
    <div>FrmJobAssignListing
    
    </div>
  )
}

export default FrmJobAssignListing