import { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Imports React bootstra
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import { resetGlobalQuery } from "assets/Constants/config-constant";
import { globalQuery } from "assets/Constants/config-constant";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { Tab, Table, Tabs } from "react-bootstrap";
import FrmInspectionOrderEntry from "../component/FrmInspectionOrderEntry";
import FrmSizingOrderEntry from "../component/FrmSizingOrderEntry";
import FrmWeavingOrderEntry from "../component/FrmWeavingOrderEntry";
import FrmWarpingOrderEntry from "../component/FrmWarpingOrderEntry";
import { CircularProgress } from "@material-ui/core";
import Select from 'react-select';
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import FrmWeavingProductionPlanEntry from "Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanEntry";
import Modal from 'react-bootstrap/Modal';

function FrmMWeavingProductionPlanEntry(props) {
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [orderType, setOrderType] = useState('S');
    const [activeStep, setActiveStep] = useState(0);

    function getSteps() {
        if (orderType === 'J') {
            // If order type is 'Job Work', exclude Warping and Sizing steps    
            return ["Warping", "Sizing"];
        } else {
            return ["Warping", "Sizing", "Weaving", "Inspection"];
        }
    }

    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId } = configConstants;
    const { state } = useLocation();
    const { weavingProductionOrderPlanId = 0, keyForViewUpdate } = state || {}

    // Use Refs to call external functions

    const validateNumberDateInput = useRef();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateMaterialIdAPiCall = useRef();
    const navigate = useNavigate()

    const steps = getSteps();
    const handleNext = () => { setActiveStep((parseInt(activeStep) + 1)) };
    const handleBack = () => setActiveStep((parseInt(activeStep) - 1));
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleTbl, setIsVisibleTbl] = useState(false);
    const [showTabs, setShowTabs] = useState(false);

    //  add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // const [date_sales_order_date, setSalesOrderDate] = useState([]);
    // const [txt_sales_order_no_options, setSalesOrderNoOptions] = useState([]);
    // const [txt_SO_materialName_options, setSOMaterialNameOption] = useState([]);
    // const [txt_customer_name, setCustomerName] = useState('');
    const [txt_order_quantity, setMaterialQuantity] = useState('0');
    const [txt_no_of_ends, setNo_OfEnds] = useState('');
    const [txt_sort_no, setSalesSortNo] = useState('');
    const [txt_sales_order_no, setSalesOrderNo] = useState([]);
    const [txt_So_materialId, setSOMaterialId] = useState();
    const [txt_unitName, setProductUnit] = useState('');
    const [txt_unitID, setProductUnitId] = useState();
    const [txt_schedule_quantity, setScheduleQuntity] = useState('');
    const [dt_start_date, setStartDate] = useState(today);
    const [dt_end_date, setEndDate] = useState(today);
    const [txt_set_no, setSetNO] = useState(1001);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    const [cmb_section_id, setProdSection] = useState('');
    const [txt_product_material_id, setProdMaterialId] = useState('');
    const [salesOrderData, setSalesOrderData] = useState([]);
    const [HeaderStyleAbbData, setHeaderStyleAbbData] = useState({});
    const [headerData, setHeaderData] = useState([]);
    const [styleCount, setStyleCount] = useState('0');
    const [txt_glm, setGLM] = useState();
    const [txt_gsm, setGSM] = useState();

    const [txt_weaving_production_order_id, setWeavingProductionOrderPlanId] = useState(weavingProductionOrderPlanId === null ? 0 : weavingProductionOrderPlanId);
    const [warpingdata, setWarpingData] = useState([])
    const [sizingdata, setSizingData] = useState([])
    const [weavingdata, setWeavingData] = useState([])
    const [inspectiondata, setInspectionData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [productionOrderAllSummary, setProductionOrderAllSummary] = useState([])
    const [weavingDataByMaterialId, setWeavingDataByMaterialId] = useState([])
    const [ramainingBalance, setBalance] = useState('');
    const [showTextarea, setShowTextarea] = useState(false);
    const [status_remark, setStatusRemark] = useState('');
    const [weavingProdYarnCountOptions, setWeavingProdYarnCountOptions] = useState([]);
    const [cmb_yarn_count, setYarnCount] = useState('');
    const [txt_actual_count, setActualCount] = useState('');
    const [warpingOrderNo, setWarpingOrderNO] = useState('');
    const [sizingOrderNo, setSizingOrderNO] = useState('');
    const [weavingOrderNo, setWeavingOrderNO] = useState('');

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    const [txt_sizing_job_rate, setJobWorkRate] = useState(0);

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MWeavingProductionPlan/FrmMWeavingProductionListing`)
        }
    }

    let requiredCols = ['product_material_name', 'product_material_id', 'product_type_group'];

    const [showBomFilterForm, setShowBomFilterForm] = useState(false);

    const viewBomFilterForm = async () => {
        setShowBomFilterForm(true);
    }

    const handleCloseBomFilterForm = async () => {
        try {
            setIsLoading(true)
            const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];

            // const filteredWeavingData = getData.filter((data) => {
            //     return data.product_type_group === 'WV';
            // });
            let material_id = getData[0].product_material_id
            setProdMaterialId(getData[0].product_material_name);

            // resetGlobalQuery();
            // globalQuery.columns = ['product_gsm, product_glm'];
            // globalQuery.table = "sm_product_fg_technical"
            // globalQuery.conditions.push({ field: "product_fg_id", operator: "=", value: material_id });
            // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // let glmgsmAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // if (glmgsmAPICall.length !== 0) {
            //     setGLM(glmgsmAPICall[0].product_glm);
            //     setGSM(glmgsmAPICall[0].product_gsm);
            // }


            resetGlobalQuery();
            globalQuery.columns = ['product_fg_stock_unit_id'];
            globalQuery.table = "sm_product_fg"
            globalQuery.conditions.push({ field: "product_fg_id", operator: "=", value: material_id });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let productUnitIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setProductUnitId(productUnitIdApiCall[0].product_fg_stock_unit_id);

            resetGlobalQuery();
            globalQuery.columns = ['product_parameter_name, product_parameter_value'];
            globalQuery.table = "sm_product_dynamic_parameters"
            globalQuery.conditions.push({ field: "product_id", operator: "=", value: material_id });
            globalQuery.conditions.push({ field: 'product_parameter_name', operator: 'IN', values: ['No. Of Ends', 'Sort Number'] });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let sortNo_NoOfEndsAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            if (sortNo_NoOfEndsAPICall.length > 0) {
                sortNo_NoOfEndsAPICall.forEach(prop => {
                    if (prop.product_parameter_name === "No. Of Ends") {
                        setNo_OfEnds(prop.product_parameter_value);
                        return;
                    } else if (prop.product_parameter_name === "Sort Number") {
                        // Do nothing
                        setSalesSortNo(prop.product_parameter_value);
                        return;
                    }
                });

            }
            handleChange(material_id)

        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    };

    useEffect(async () => {
        // debugger
        // const loadDataOnload = async () => {
        setIsLoading(true);
        try {
            await ActionType();
            if (keyForViewUpdate === "Add") {
                await FnGenerateWarpingId();
                await GenerateSizingNo();
                await FnGenerateWeavingId();
            }
            await fillComboBox();
            if (weavingProductionOrderPlanId !== 0) {
                await FnCheckUpdateResponce();
            }
            if (keyForViewUpdate === "Add") {
                await FngetSetNo();
            }
        } catch (error) {
            console.error(error);
            navigate('/Error');
        } finally {
            setIsLoading(false);
        }
        // };

        // loadDataOnload();
    }, []);

    // funtion for AutoGenerate Order No warping ,sizing, weaving
    const FnGenerateWarpingId = async () => {
        const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("xt_weaving_production_order", "warping_order_no", '', '', 'WR', "5");
        setWarpingOrderNO(autoNoApiCall);
        return autoNoApiCall;
    }
    const GenerateSizingNo = async () => {
        const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("xt_weaving_production_order", "sizing_order_no", '', '', 'SZ', "5");
        setSizingOrderNO(autoNoApiCall);
        return autoNoApiCall;
    }
    const FnGenerateWeavingId = async () => {
        const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("xt_weaving_production_order", "weaving_order_no", '', '', 'WV', "5");
        setWeavingOrderNO(autoNoApiCall);
        return autoNoApiCall;
    }

    const fillComboBox = async () => {
        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "xmv_spinning_prod_count"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWeavingProdYarnCount) => {
            setWeavingProdYarnCountOptions(getWeavingProdYarnCount);
        })

        resetGlobalQuery();
        globalQuery.columns = ['field_id, field_name'];
        globalQuery.table = "xmv_production_section"
        globalQuery.conditions.push({ field: "production_section_name", operator: "=", value: 'Weaving (Weaving)' });
        // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const getProdSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setProdSectionOptions(getProdSectionApiCall);
        setProdSection('18')

        // const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_name", "Weaving (Weaving)");
        resetGlobalQuery();
        globalQuery.columns = ['field_id, field_name'];
        globalQuery.table = "xmv_production_sub_section"
        globalQuery.conditions.push({ field: "production_section_name", operator: "=", value: 'Weaving (Weaving)' });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setProdSubSectionOptions(getProdSubSectionApiCall);


        // resetGlobalQuery();
        // globalQuery.columns = ['product_material_name', 'product_material_id'];
        // globalQuery.table = "smv_product_rm_fg_sr";
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        // globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'WV' });
        // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        // const salesOrderDetail = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        // // setCustomerName(salesOrderDetail[0].customer_name);
        // if (salesOrderDetail.length !== 0) {
        //     setSalesOrderDate(salesOrderDetail[0].sales_order_date);
        //     setSOMaterialNameOption(salesOrderDetail);
        // }


        // resetGlobalQuery();
        // globalQuery.columns.push("DISTINCT sales_order_no");
        // globalQuery.table = "mtv_sales_order_details_trading";
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        // globalQuery.conditions.push({ field: "sales_order_status", operator: "IN", values: ['A', 'I'] });
        // globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: "WV" });
        // const getsalesordernoUpdatedApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        // setSalesOrderNoOptions(getsalesordernoUpdatedApiCall);
        setShowTabs(false);

    }

    // fill combobox onchange function
    const fillComboBoxOnChange = async (comboName) => {
        // debugger

        switch (comboName) {
            // case 'SalesOrders':
            //     setSOMaterialId('');
            //     setSOMaterialNameOption([]);
            //     setCustomerName('');
            //     setSalesOrderDate('');
            //     setMaterialQuantity('');
            //     setSalesSortNo('');
            //     setNo_OfEnds('');
            //     setProductUnit('');
            //     setSOMaterialNameOption([]);
            //     setProductionOrderAllSummary([]);
            //     setIsVisible(false);
            //     setIsVisibleTbl(false);
            //     setShowTabs(false);
            //     $(`#error_scheduleQty`).hide();
            //     let selectedSalesOrder = $('#txt_sales_order_no').val();
            //     if (selectedSalesOrder !== "") {
            //         resetGlobalQuery();
            //         globalQuery.columns = ['sales_order_date', 'product_material_name', 'product_material_id'];
            //         globalQuery.table = "mtv_sales_order_details_trading";
            //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            //         globalQuery.conditions.push({ field: "sales_order_no", operator: "=", value: selectedSalesOrder });
            //         const salesOrderDetail = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            //         // setCustomerName(salesOrderDetail[0].customer_name);
            //         setSalesOrderDate(salesOrderDetail[0].sales_order_date);
            //         setSOMaterialNameOption(salesOrderDetail);

            //     }
            //     break;
            // case 'MaterialName':

            //     let selectedMaterialId = $('#txt_So_materialId').val();
            //     let selectedSalesOrderNo = $('#txt_sales_order_no').val();
            //     setMaterialQuantity('');
            //     setSalesSortNo('');
            //     setNo_OfEnds('');
            //     $(`#error_scheduleQty`).hide();
            //     setProductUnit('');
            //     if (selectedMaterialId !== "" && selectedSalesOrderNo !== "") {
            //         resetGlobalQuery();
            //         globalQuery.columns.push('material_quantity');
            //         globalQuery.table = "mtv_sales_order_details_trading";
            //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            //         globalQuery.conditions.push({ field: "sales_order_no", operator: "=", value: selectedSalesOrderNo });
            //         globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: selectedMaterialId });
            //         const salesOrderDetail = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            //         setMaterialQuantity(salesOrderDetail[0].material_quantity);
            //     }
            //     if (selectedMaterialId !== "") {
            //         resetGlobalQuery();
            //         setIsVisible(false);
            //         setIsVisibleTbl(false);
            //         setShowTabs(false);
            //         globalQuery.columns = ['field_id', 'field_name', 'sort_no', 'no_of_ends', 'product_fg_stock_unit_name', 'product_fg_stock_unit_id', 'warp_yarn_count'];
            //         globalQuery.table = "xmv_weaving_production_spec_sheet_summary";
            //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            //         globalQuery.conditions.push({ field: "product_id", operator: "=", value: selectedMaterialId });
            //         const salesweavingPlanDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            //         setWeavingDataByMaterialId(salesweavingPlanDetails);
            //         if (salesweavingPlanDetails.length !== 0) {
            //             // setIsVisible(true);
            //             setSalesSortNo(salesweavingPlanDetails[0].sort_no);
            //             setNo_OfEnds(salesweavingPlanDetails[0].no_of_ends);
            //             setProductUnit(salesweavingPlanDetails[0].product_fg_stock_unit_name);
            //             setProductUnitId(salesweavingPlanDetails[0].product_fg_stock_unit_id);
            //             setYarnCount(salesweavingPlanDetails[0].warp_yarn_count);
            //             setActualCount(salesweavingPlanDetails[0].warp_yarn_count);
            //         } else {
            //             setSalesSortNo('0');
            //             setNo_OfEnds('0');
            //         }
            //     }
            //     if (selectedMaterialId !== "") {
            //         resetGlobalQuery();
            //         globalQuery.columns.push("*");
            //         globalQuery.table = "xtv_weaving_production_order_summary";
            //         globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: selectedMaterialId });
            //         globalQuery.conditions.push({ field: "weaving_order_status", operator: "=", value: "A" });
            //         globalQuery.conditions.push({ field: "sales_order_no", operator: "=", value: selectedSalesOrderNo });
            //         const getAllWPOrderDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            //         setProductionOrderAllSummary(getAllWPOrderDetails);

            //         if (keyForViewUpdate === 'Add') {
            //             const totalOrderQuantity = parseInt(document.getElementById('txt_order_quantity').getAttribute('value'), 10);
            //             const totalsScheduleQuantity = getAllWPOrderDetails.reduce((acc, item) => acc + item.schedule_quantity, 0);
            //             const balance = totalOrderQuantity - totalsScheduleQuantity;
            //             $(`#error_txt_schedule_quantity`).hide();

            //             if (balance > 0) {
            //                 setScheduleQuntity(balance);
            //                 setIsVisible(true);
            //                 $(`#createBtnId`).show();
            //                 $(`#error_scheduleQty`).hide();
            //             } else {
            //                 $(`#createBtnId`).hide();
            //                 $(`#error_scheduleQty`).text(` Order Quantity is already Schedule ...`);
            //                 $(`#error_scheduleQty`).show();
            //             }
            //             setBalance(balance);
            //         }
            //     }
            //     break;
            case 'cmb_section_id':
                const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_name", "Weaving (Weaving)");
                setProdSubSectionOptions(getProdSubSectionApiCall);
                break;

            // case 'actualCount':
            //     $(`#error_cmb_yarn_count`).hide();
            //     const actualCont = document.getElementById('cmb_yarn_count').value;
            //     setActualCount(actualCont);
            //     break;
            case 'scheduleQuntity':
                let txt_schedule_quantity = $('#txt_schedule_quantity').val();
                // let txt_order_quantity = $('#txt_order_quantity').val();

                if (parseInt(txt_schedule_quantity) <= txt_order_quantity) {
                    setScheduleQuntity(validateNumberDateInput.current.decimalNumber(txt_schedule_quantity), 4);
                    $(`#error_txt_schedule_quantity`).hide();
                    return true;
                } else {
                    $(`#error_txt_schedule_quantity`).text(`Schedule quantity cannot exceed order quantity`).show();
                    return false;
                }


                // const numCheck = /^[0-9]*\.?[0-9]*$/;
                // const regexNo = /^[0-9]*\.[0-9]{5}$/
                // var dot = '.';

                // var pFromRangeVal = $('#txt_schedule_quantity').val();
                // if (!regexNo.test(pFromRangeVal) && pFromRangeVal.length <= 14 || pFromRangeVal.indexOf(dot) === 14) {
                //     if (numCheck.test(pFromRangeVal)) {
                //         setScheduleQuntity(pFromRangeVal)
                //     }

                // }
                // let scheduleQuntity = document.getElementById('txt_schedule_quantity').value;

                // if (scheduleQuntity !== "" && scheduleQuntity !== '0') {
                //     scheduleQuntity = parseInt(document.getElementById('txt_schedule_quantity').value, 10);
                // }
                // const OrderQuantity = parseInt(document.getElementById('txt_order_quantity').getAttribute('value'), 10);
                // const balance = ramainingBalance
                // let checkBalance = true;
                // if (keyForViewUpdate === 'Add') {
                //     if (scheduleQuntity === '0' || scheduleQuntity === "") {
                //         $(`#error_txt_schedule_quantity`).text(`Schedule quantity cannot be 0 OR Empty`);
                //         $(`#error_txt_schedule_quantity`).show();
                //         checkBalance = false;
                //         return false;
                //     } else if (balance >= scheduleQuntity) {
                //         $(`#error_txt_schedule_quantity`).hide();
                //         checkBalance = true;
                //         return true;
                //     } else {
                //         $(`#error_txt_schedule_quantity`).text(`Schedule quantity cannot exceed order quantity`);
                //         $(`#error_txt_schedule_quantity`).show();
                //         setScheduleQuntity(balance);
                //         checkBalance = false;
                //         return false;
                //     }

                // } else {
                //     if (scheduleQuntity === '0' || scheduleQuntity === "") {
                //         $(`#error_txt_schedule_quantity`).text(`Schedule quantity cannot be 0 OR Empty`);
                //         $(`#error_txt_schedule_quantity`).show();
                //         checkBalance = false;
                //         return false;
                //     } else if (OrderQuantity >= scheduleQuntity) {
                //         $(`#error_txt_schedule_quantity`).hide();
                //         checkBalance = true;
                //         return true;
                //     } else {
                //         $(`#error_txt_schedule_quantity`).text(`Schedule quantity cannot exceed order quantity`);
                //         $(`#error_txt_schedule_quantity`).show();

                //         checkBalance = false;
                //         return false;
                //     }
                // }
                break;
            case 'validDate':
                const startDate = document.getElementById('dt_start_date').value;
                const endDate = document.getElementById('dt_end_date').value;
                let checkIsValidate = false;

                if (startDate && endDate) {
                    if (startDate > endDate) {
                        $(`#error_dt_end_date`).text(`Start Date should not be greater than End Date`);
                        $(`#error_dt_end_date`).show();
                        checkIsValidate = false;
                        return false;
                    } else {
                        $(`#error_dt_end_date`).hide();
                        checkIsValidate = true;
                        return true;
                    }
                }

                break;
            case 'remarkmsg':
                const statusRemark = document.getElementById('status_remark').value;

                let remark = true;
                if (statusRemark === "" || statusRemark === null) {
                    $(`#error_status_remark`).text(`Please filed Status Remark`);
                    $(`#error_status_remark`).show();
                    remark = false;
                    return false;
                } else {
                    $(`#error_status_remark`).hide();
                    remark = true;
                    return true;
                }
                break;
            // case 'yarnCount':
            //     const yarnCount = document.getElementById('cmb_yarn_count').value;

            //     let count = true;
            //     if (yarnCount === "" || yarnCount === null || yarnCount === 'Select') {
            //         $(`#error_cmb_yarn_count`).text(`Please select at least one option`);
            //         $(`#error_cmb_yarn_count`).show();
            //         count = false;
            //         return false;
            //     } else {
            //         $(`#error_cmb_yarn_count`).hide();
            //         count = true;
            //         return true;
            //     }
            //     break;

            default:
                break;
        }
    }

    const ActionType = async () => {
        // debugger
        switch (keyForViewUpdate) {
            case 'view':

                setActionType('(View)');
                $('.view-hide').hide();
                $("#WeavingProductionform input[type='text'], #WeavingProductionform select").attr('readonly', true);
                break;
            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve')
                await validate.current.readOnly("WeavingProductionform");
                break;
            default:
                setActionType('(Creation)');
                // FngetSetNo();
                break;
        }
    };

    //open production plan master
    const addRecordInProperty = () => {
        if (txt_no_of_ends === '' || txt_no_of_ends === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo');
            setShowAddRecModal(true);
            setHeaderName('planMaster');
            setTimeout(() => {
                $(".erp_top_Form").eq(1).css("padding-top", "0px");
            }, 100);
        }
    };
    const handleCloseRecModal = () => {
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo');
        fillComboBoxOnChange('MaterialName');
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 100);
    };
    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'planMaster':
                return <FrmWeavingProductionPlanEntry btn_disabled={true} />;
            default:
                return null;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('WeavingProductionform')
    }


    // validation for the when Order Quantity is zero for warping,sizing,weaving,inspection,
    const validateOrderQuantity = async () => {
        let validStatus = true;
        if (orderType !== 'J') {
            for (const rowData of inspectiondata) {
                if (rowData.product_material_approved_quantity === 0 || rowData.product_material_approved_quantity === '0' || rowData.product_material_approved_quantity === '') {
                    setErrMsg('Please Enter Inspection Order Quantity');
                    setShowErrorMsgModal(true);
                    validStatus = false;
                    setActiveStep(3);
                    break;
                }
            }
            for (const rowData of weavingdata) {
                if (rowData.product_material_approved_quantity === 0 || rowData.product_material_approved_quantity === '0' || rowData.product_material_approved_quantity === '') {
                    setErrMsg('Please Enter Weaving Order Quantity');
                    setShowErrorMsgModal(true);
                    validStatus = false;
                    setActiveStep(2);
                    break;
                }
            }
        }
        for (const rowData of sizingdata) {
            if (rowData.product_material_approved_quantity === 0 || rowData.product_material_approved_quantity === '0' || rowData.product_material_approved_quantity === '') {
                setErrMsg('Please Enter Sizing Order Quantity');
                setShowErrorMsgModal(true);
                validStatus = false;
                setActiveStep(1);
                break;
            }
        }
        for (const rowData of warpingdata) {
            if (rowData.product_material_approved_quantity === 0 || rowData.product_material_approved_quantity === '0' || rowData.product_material_approved_quantity === '') {
                setErrMsg('Please Enter Warping Order Quantity');
                setShowErrorMsgModal(true);
                validStatus = false;
                setActiveStep(0);
                break;
            }
        }
        return validStatus;
    };

    // Add(save) weaving Production Order Plan
    const addWeavingProductionOrderPlan = async (keyForRejected) => {
        try {

            setIsLoading(true);
            const json = { 'TransHeaderData': {}, 'SalesOrderData': [], 'WeaingProductMaterialData': [], 'wvProductiondetailsData': {}, 'commonIds': { 'company_id': COMPANY_ID, 'weaving_production_order_id': txt_weaving_production_order_id, 'financial_year': FINANCIAL_SHORT_NAME } }

            let checkBalance = false;
            let checkvalidDate = false;
            let checkvalidremak = false;
            let checkvalidYarnCount = false;
            let checkvalidQuantity = false;
            const checkIsValidate = await validate.current.validateForm('WeavingProductionform');
            // const checkIsValidate = true
            checkBalance = await fillComboBoxOnChange('scheduleQuntity');
            // checkvalidDate = await fillComboBoxOnChange('validDate');
            // checkvalidYarnCount = await fillComboBoxOnChange('yarnCount');
            checkvalidQuantity = await validateOrderQuantity()
            if (keyForRejected === "rejected") {
                setShowTextarea(true);
                checkvalidremak = await fillComboBoxOnChange('remarkmsg');
            }

            if (checkvalidQuantity === true && checkIsValidate === true && checkBalance === true && (keyForRejected !== "rejected" || checkvalidremak)) {
                // if (checkvalidQuantity === true && checkBalance === true && checkvalidDate === true && (keyForRejected !== "rejected" || checkvalidremak)) {
                const warping_sub_section = prodsubsectionOptions.find(item => item.field_name === "Warping (Weaving)")
                const sizing_sub_section = prodsubsectionOptions.find(item => item.field_name === "Sizing (Weaving)")
                const weaving_sub_section = prodsubsectionOptions.find(item => item.field_name === "Weaving (Weaving)")
                const inspection_sub_section = prodsubsectionOptions.find(item => item.field_name === "Inspection (Weaving)")

                //................Warping Data.................//
                warpingdata.forEach(item => {
                    const modifiedProductParameter = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        product_material_id: item.product_id,
                        product_material_quantity: item.product_material_approved_quantity,
                        product_material_unit_id: item.product_unit_id,
                        financial_year: FINANCIAL_SHORT_NAME,
                        production_order_no: item.warping_order_no,
                        created_by: UserName,
                        production_sub_section_id: warping_sub_section.field_id,
                        production_order_material_id: item.production_order_material_id,
                        production_order_date: today(),
                    };
                    json.WeaingProductMaterialData.push(modifiedProductParameter);
                });

                //.................Sizing Data................//
                sizingdata.forEach(item => {
                    const modifiedProductParameter = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        product_material_id: item.product_id,
                        product_material_quantity: item.product_material_approved_quantity,
                        product_material_unit_id: item.product_unit_id,
                        financial_year: FINANCIAL_SHORT_NAME,
                        production_order_no: item.sizing_order_no,
                        created_by: UserName,
                        production_sub_section_id: sizing_sub_section.field_id,
                        production_order_material_id: item.production_order_material_id,
                        production_order_date: today(),
                    };
                    json.WeaingProductMaterialData.push(modifiedProductParameter);
                });

                //.............Weaving Data..............//
                if (orderType !== 'J') {
                    weavingdata.forEach(item => {
                        const modifiedProductParameter = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            product_material_id: item.product_id,
                            product_material_quantity: item.product_material_approved_quantity,
                            product_material_unit_id: item.product_unit_id,
                            financial_year: FINANCIAL_SHORT_NAME,
                            production_order_no: item.weaving_order_no,
                            created_by: UserName,
                            production_sub_section_id: weaving_sub_section.field_id,
                            production_order_material_id: item.production_order_material_id,
                            production_order_date: today(),
                        };

                        json.WeaingProductMaterialData.push(modifiedProductParameter);
                    });

                    //................Inspection Data.............//
                    inspectiondata.forEach(item => {
                        const modifiedProductParameter = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            product_material_id: item.product_id,
                            product_material_quantity: item.product_material_approved_quantity,
                            product_material_unit_id: item.product_unit_id,
                            financial_year: FINANCIAL_SHORT_NAME,
                            created_by: UserName,
                            production_sub_section_id: inspection_sub_section.field_id,
                            production_order_no: txt_set_no,
                            production_order_material_id: item.production_order_material_id,
                            production_order_date: today(),
                        };

                        json.WeaingProductMaterialData.push(modifiedProductParameter);
                    });
                }

                //................Sales Order Table Data..............//
                const salesOrderArr = [];
                const emptyKey = 'BASIC';

                $('.checkboxForSalesOrderDetails:checked').each(function () {
                    const salesOrderNo = $(this).val();

                    salesOrderData.forEach(salesdata => {

                        if (salesdata.sales_order_details_transaction_id === parseInt(salesOrderNo)) {
                            salesOrderArr.push(salesdata.sales_order_no);

                            const salesOrderDetails = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                product_material_id: salesdata.product_material_id,
                                // sales_order_details_transaction_id: salesdata.sales_order_details_transaction_id,
                                sales_order_no: salesdata.sales_order_no,
                                // customer_name: salesdata.customer_name,
                                set_no: txt_set_no,
                                sort_no: txt_sort_no,
                                schedule_quantity: txt_schedule_quantity,
                                schedule_date: today(),
                                quantities: {}
                            };

                            Object.keys(salesdata).forEach(key => {
                                if (!['sales_order_details_transaction_id', 'customer_name', 'sales_order_no', 'product_material_stock_unit_name', 'total'].includes(key)) {
                                    const adjustedKey = key === '' ? emptyKey : key;
                                    salesOrderDetails.quantities[adjustedKey] = salesdata[key];
                                }
                            });

                            json.SalesOrderData.push(salesOrderDetails);
                        }
                    });
                });



                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    created_by: UserName,
                    modified_by: txt_weaving_production_order_id === 0 ? null : UserName,
                    weaving_production_order_id: txt_weaving_production_order_id,
                    product_material_id: txt_So_materialId,
                    order_quantity: txt_order_quantity,
                    sort_no: txt_sort_no,
                    no_of_ends: txt_no_of_ends,
                    // sales_order_no: salesOrderArr,
                    order_type: orderType,
                    sizing_job_rate: txt_sizing_job_rate,
                    product_unit_id: txt_unitID,
                    set_no: txt_set_no,
                    start_date: dt_start_date,
                    end_date: dt_end_date,
                    schedule_date: today(),
                    schedule_quantity: txt_schedule_quantity,
                    production_section_id: cmb_section_id,
                    // yarn_count: $('#cmb_yarn_count option:selected').text(),
                    // actual_count: txt_actual_count,
                    glm: txt_glm,
                    gsm: txt_gsm
                };
                const detailData = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    created_by: UserName,
                    product_material_id: txt_So_materialId,
                    // sales_order_no: salesOrderArr,
                    product_unit_id: txt_unitID,
                    set_no: txt_set_no,
                    schedule_date: today(),
                    schedule_quantity: txt_schedule_quantity,
                    production_section_id: cmb_section_id,
                };
                json.TransHeaderData = data
                json.wvProductiondetailsData = detailData
                if (warpingdata.length > 0) {
                    json.TransHeaderData.warping_order_no = warpingdata[0].warping_order_no;
                } else {
                    json.TransHeaderData.warping_order_no = warpingOrderNo;
                }
                if (sizingdata.length > 0) {
                    json.TransHeaderData.sizing_order_no = sizingdata[0].sizing_order_no;
                } else {
                    json.TransHeaderData.sizing_order_no = sizingOrderNo;
                }
                if (weavingdata.length > 0) {
                    json.TransHeaderData.weaving_order_no = weavingdata[0].weaving_order_no;
                } else {
                    json.TransHeaderData.weaving_order_no = weavingOrderNo;
                }

                if (keyForViewUpdate === "approve") {
                    json.TransHeaderData.weaving_order_status = 'A';
                    json.TransHeaderData.approved_date = today();
                    json.TransHeaderData.approved_by_id = UserId;
                    json.TransHeaderData.status_remark = status_remark;
                } else {
                    json.TransHeaderData.weaving_order_status = 'P';
                }
                if (keyForRejected === "rejected") {
                    json.TransHeaderData.weaving_order_status = 'R';
                    json.TransHeaderData.status_remark = status_remark;
                }

                const formData = new FormData()
                formData.append('WeavingProductionOrderData', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const WPOApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionOrder/FnAddUpdateRecord`, forwardData)
                const responce = await WPOApiCall.json()
                if (responce.success === "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            alert('Error');
            // navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    };

    // Generate the Set no
    const FngetSetNo = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionOrder/GetLastRecordSetNoWeavingProductionOrder`)
            const updateRes = await apiCall.json();
            const data = updateRes.data
            const setno = parseInt(data.set_no);
            setSetNO(setno + 1);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    /// Get data update functionality 
    const FnCheckUpdateResponce = async () => {
        try {
            debugger;
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionOrder/FnShowParticularRecordForUpdate/${txt_weaving_production_order_id}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.WeavingProductionOrderRecord
            setWeavingProductionOrderPlanId(data.weaving_production_order_id)
            setSalesOrderNo(data.sales_order_no);
            // await fillComboBoxOnChange('SalesOrders');
            setSOMaterialId(data.product_material_id);

            if (keyForViewUpdate !== 'Add') {
                resetGlobalQuery();
                globalQuery.columns.push('field_name', 'field_id', 'product_material_name');
                globalQuery.table = "smv_product_rm_fg_sr";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: data.product_material_id });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getProdMaterialIdApiCall) => {
                    setProdMaterialId(getProdMaterialIdApiCall[0].product_material_name);
                })
            }


            // await fillComboBoxOnChange('MaterialName');
            setMaterialQuantity(data.order_quantity);
            setNo_OfEnds(data.no_of_ends);
            setSalesSortNo(data.sort_no);
            setGLM(data.glm)
            setGSM(data.gsm)
            setProductUnit(data.product_unit_name);
            setProdSection(data.production_section_id);
            setProductUnitId(data.product_unit_id);
            setJobWorkRate(data.sizing_job_rate);
            setOrderType(data.order_type);
            setScheduleQuntity(data.schedule_quantity);
            setWarpingOrderNO(data.warping_order_no);
            setWeavingOrderNO(data.weaving_order_no);
            setSizingOrderNO(data.sizing_order_no);
            if (data.status_remark !== '' && data.status_remark !== null) {
                setShowTextarea(true);
                setStatusRemark(data.status_remark);
            }
            setEndDate(data.end_date);
            setStartDate(data.start_date);
            setSetNO(data.set_no)
            setYarnCount(data.actual_count);
            setActualCount(data.actual_count)
            setIsVisible(true);
            setIsVisibleTbl(true);
            setShowTabs(true);

            //Sales Order Details
            let header_data = new Set();
            let salesOrderData = updateRes.WeavingProductionSalesOrderDetails;
            let excludeKeys = ['sales_order_details_transaction_id', 'company_branch_id', 'customer_name', 'sort_no', 'set_no', 'company_id', 'schedule_date',  'sales_order_no', 'material_quantity', 'product_material_stock_unit_name', 'total'];

            salesOrderData.forEach((data) => {
                Object.keys(data).forEach((key) => {
                    if (!excludeKeys.includes(key)) {
                        header_data.add(key);
                    }
                });
            });
            // Convert the set to an array

            let headerStyleAbbrevationArr = {};

            salesOrderData.forEach((data) => {
                let salesOrderNo = data['sales_order_no'];
                if (!headerStyleAbbrevationArr[salesOrderNo]) {
                    headerStyleAbbrevationArr[salesOrderNo] = {};
                }

                Object.entries(data).forEach(([key, value]) => {
                    headerStyleAbbrevationArr[salesOrderNo][key] = value;
                });
            });

            header_data = Array.from(header_data);
            setHeaderData(header_data)
            setSalesOrderData(salesOrderData);
            setHeaderStyleAbbData(headerStyleAbbrevationArr);

            // set warping,sizing, weaving data
            if (updateRes.WeavingProductionMaterialRecordWithShortNames['WVWP'] !== undefined) {
                setWarpingData(updateRes.WeavingProductionMaterialRecordWithShortNames['WVWP'].map(item => ({
                    product_id: item.product_material_id,
                    product_material_approved_quantity: item.product_material_quantity,
                    product_name: item.product_material_name,
                    product_unit_name: item.product_material_unit_name,
                    warping_order_no: item.field_name,
                    product_unit_id: item.product_material_unit_id,
                    product_tech_spect: item.product_material_tech_spect,
                    product_material_code: item.product_material_code,
                    production_order_material_id: item.production_order_material_id
                })));
            }

            setActiveStep(1);
            setActiveStep(0);
            if (updateRes.WeavingProductionMaterialRecordWithShortNames['WVSZ'] !== undefined) {
                setSizingData(updateRes.WeavingProductionMaterialRecordWithShortNames['WVSZ'].map(item => ({
                    product_id: item.product_material_id,
                    product_material_approved_quantity: item.product_material_quantity,
                    product_name: item.product_material_name,
                    product_unit_name: item.product_material_unit_name,
                    sizing_order_no: item.field_name,
                    production_sub_section_id: item.production_sub_section_id,
                    product_unit_id: item.product_material_unit_id,
                    product_tech_spect: item.product_material_tech_spect,
                    product_material_code: item.product_material_code,
                    production_order_material_id: item.production_order_material_id
                })));
            }
            // set WeavingData
            if (updateRes.WeavingProductionMaterialRecordWithShortNames['WVWV'] !== undefined) {
                setWeavingData(updateRes.WeavingProductionMaterialRecordWithShortNames['WVWV'].map(item => ({
                    product_id: item.product_material_id,
                    product_material_approved_quantity: item.product_material_quantity,
                    product_name: item.product_material_name,
                    product_unit_name: item.product_material_unit_name,
                    weaving_order_no: item.field_name,
                    production_sub_section_id: item.production_sub_section_id,
                    product_unit_id: item.product_material_unit_id,
                    product_tech_spect: item.product_material_tech_spect,
                    product_material_code: item.product_material_code,
                    production_order_material_id: item.production_order_material_id
                })));
            }
            // set InspectionData
            if (updateRes.WeavingProductionMaterialRecordWithShortNames['WVIN'] !== undefined) {
                setInspectionData(updateRes.WeavingProductionMaterialRecordWithShortNames['WVIN'].map(item => ({
                    product_id: item.product_material_id,
                    product_material_approved_quantity: item.product_material_quantity,
                    product_name: item.product_material_name,
                    product_unit_name: item.product_material_unit_name,
                    warping_order_no: item.field_name,
                    production_sub_section_id: item.production_sub_section_id,
                    product_unit_id: item.product_material_unit_id,
                    product_tech_spect: item.product_material_tech_spect,
                    product_material_code: item.product_material_code,
                    production_order_material_id: item.production_order_material_id
                })));
            }
            $('.view-hide').hide();

        } catch (error) {
            console.log("error: ", error)
        }
    }

    const moveByTabs = (eventKey) => {
        setActiveStep(eventKey)
    }

    // set and get  all component data 
    const storedReceiveWarpingData = (warpingData) => {
        setWarpingData(warpingData)

    }
    const storedReceiveSizingData = (sizingData) => {
        setSizingData(sizingData)
    }

    const storedReceiveSJobRate = (sizingJObRate) => {
        setJobWorkRate(sizingJObRate)
    }
    const storedReceiveWeavingData = (weavingData) => {
        setWeavingData(weavingData)
    }
    const storedReceiveInspectionData = (inspectionData) => {
        setInspectionData(inspectionData)
    }

    function getStepContent() {

        switch (parseInt(activeStep)) {
            case 0:
                return <FrmWarpingOrderEntry goBack={handleBack} onGetFormWarpingData={storedReceiveWarpingData} warpingData={warpingdata} generatedWRNo={warpingOrderNo} scheduleQuntity={txt_schedule_quantity} />;
            case 1:
                return <FrmSizingOrderEntry goToNext={handleNext} goBack={handleBack} onGetFormSizingData={storedReceiveSizingData}
                    getsizingJObRate={storedReceiveSJobRate} sizingJObRate={txt_sizing_job_rate} sizingData={sizingdata} generatedSZNo={sizingOrderNo} />;
            case 2:
                return <FrmWeavingOrderEntry goToNext={handleNext} onGetFormWeavingData={storedReceiveWeavingData} weavingData={weavingdata} generatedWVNo={weavingOrderNo} scheduleQuntity={txt_schedule_quantity} />;
            case 3:
                return <FrmInspectionOrderEntry goBack={handleBack} onGetFormInspectionData={storedReceiveInspectionData} inspectionData={inspectiondata} />;
            default:
                return null;
        }
    }

    const handleReject = () => {
        setShowTextarea(true);
        const rejected = 'rejected'
        addWeavingProductionOrderPlan(rejected);
    };

    const uncheckboxes = () => {
        $('.checkboxForSalesOrderDetails').each(function () {
            $(this).prop('checked', false);
        });
        setMaterialQuantity('0');
        setScheduleQuntity('');
    }

    const calculateQuantityNoOfEnds = () => {
        // debugger
        let sales_details = salesOrderData;
        let quantity = 0;
        $('.checkboxForSalesOrderDetails:checked').each(function () {
            const salesOrderNo = $(this).val();
            const matchingDetails = sales_details.find(details => {
                return details.sales_order_details_transaction_id === parseInt(salesOrderNo)
            });

            if (matchingDetails) {
                quantity += matchingDetails.total;
            }
        });
        setMaterialQuantity(quantity);
        setScheduleQuntity(quantity);
        if (quantity > 0) {
            setIsVisibleTbl(true)
            setShowTabs(true);
        } else {
            setIsVisibleTbl(false)
            setShowTabs(false);
        }
    }


    const handleChange = async (txt_So_materialId) => {
        try {
            setIsLoading(true)
            if (txt_So_materialId !== '0' && txt_So_materialId !== '') {
                $('#error_txt_product_material_id').hide()
            }
            setSOMaterialId(txt_So_materialId);
            resetGlobalQuery();
            globalQuery.columns.push('customer_name', 'sales_order_no', 'material_quantity', 'product_material_stock_unit_name', 'sales_order_details_transaction_id', 'weft_color', 'material_style_abbrevation', 'product_material_name');
            globalQuery.table = "mtv_sales_order_details_trading";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "sales_order_status", operator: "IN", values: ['A', 'I'] });
            globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: txt_So_materialId });
            globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: "WV" });
            const getsalesorderDataApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);


            let frequencyMap = {}
            getsalesorderDataApiCall.forEach(element => {
                if (frequencyMap[element.sales_order_no]) {
                    frequencyMap[element.sales_order_no]++;
                } else {
                    frequencyMap[element.sales_order_no] = 1;
                }
            });

            // Find the element with the highest
            let maxCount = 0;
            let mostFrequent = null;
            for (let element in frequencyMap) {
                if (frequencyMap[element] > maxCount) {
                    maxCount = frequencyMap[element];
                    mostFrequent = element;
                }
            }

            setStyleCount(maxCount)

            let salesOrderDataArr = [];
            let headerdataArr = [];

            for (let rowIndex = 0; rowIndex < getsalesorderDataApiCall.length; rowIndex++) {
                let salesOrderElement = getsalesorderDataApiCall[rowIndex];
                ////For header Data
                if (!headerdataArr.includes(salesOrderElement.material_style_abbrevation)) {
                    headerdataArr.push(salesOrderElement.material_style_abbrevation)
                }
                // Check if the sales order already exists in the salesOrderDataArr
                let existingSalesOrder = salesOrderDataArr.find(
                    (salesOrderArrayData) => salesOrderArrayData.sales_order_no === salesOrderElement.sales_order_no
                );

                if (!existingSalesOrder) {
                    // Create a new sales order data object
                    let salesOrderDataObj = {
                        sales_order_details_transaction_id: salesOrderElement.sales_order_details_transaction_id,
                        customer_name: salesOrderElement.customer_name,
                        sales_order_no: salesOrderElement.sales_order_no,
                        // material_quantity: salesOrderElement.material_quantity,
                        product_material_stock_unit_name: salesOrderElement.product_material_stock_unit_name,
                        [`${salesOrderElement.material_style_abbrevation}`]: salesOrderElement.material_quantity // Using the material_style_abbrevation dynamically
                    };
                    salesOrderDataArr.push(salesOrderDataObj);
                } else {
                    // Modify existing sales order data object
                    existingSalesOrder[salesOrderElement.material_style_abbrevation] = salesOrderElement.material_quantity;
                }

            }

            let headerStyleAbbrevationArr = {};
            let emptyKey = 'BASIC'
            salesOrderDataArr.forEach((data) => {
                let salesOrderNo = data['sales_order_no'];
                if (!headerStyleAbbrevationArr[salesOrderNo]) {
                    headerStyleAbbrevationArr[salesOrderNo] = {};
                }

                Object.entries(data).forEach(([key, value]) => {
                    // if (key === '') {
                    //     key = emptyKey
                    // }
                    headerStyleAbbrevationArr[salesOrderNo][key] = value;
                });
            });


            salesOrderDataArr = salesOrderDataArr.map(obj => {
                let excludeKeys = [
                    'sales_order_details_transaction_id',
                    'customer_name',
                    'sales_order_no',
                    'material_quantity',
                    'product_material_stock_unit_name'
                ];

                let total = Object.entries(obj).reduce((acc, [key, value]) => {
                    if (!excludeKeys.includes(key)) {
                        return acc + value;
                    } else {
                        return acc;
                    }
                }, 0);

                obj['total'] = total;

                return obj;
            });

            setHeaderStyleAbbData(headerStyleAbbrevationArr);
            setHeaderData(headerdataArr)
            setSalesOrderData(salesOrderDataArr);

            uncheckboxes();

            // resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name', 'sort_no', 'no_of_ends', 'product_fg_stock_unit_name', 'product_fg_stock_unit_id', 'warp_yarn_count'];
            // globalQuery.table = "xmv_weaving_production_spec_sheet_summary";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: txt_So_materialId });
            // const salesweavingPlanDetails = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setSalesSortNo(salesweavingPlanDetails[0].sort_no);
            // setNo_OfEnds(salesweavingPlanDetails[0].no_of_ends);
            // setProductUnitId(salesweavingPlanDetails[0].product_fg_stock_unit_id);
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    };


    const renderSalesOrderDetails = useMemo(() => {
        return (
            <Table id="salesOrderDetailsId" className="erp_table erp_table_scroll" style={{ display: "block", width: 'auto', overflow: "auto", height: salesOrderData.length <= 5 ? 'auto' : '125px', }} bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <td className="erp_table_th">Sr No</td>
                        <td className="erp_table_th view-hide">Action</td>
                        <td className="erp_table_th" style={{ width: "100px" }}>Customer Name</td>
                        <td className="erp_table_th" style={{ width: "100px" }}>Sales Order No</td>
                        <td className="erp_table_th" style={{ width: "100px" }}>Total</td>
                        {headerData.length > 0 && headerData.map((header, index) => (
                            <td key={index} className="erp_table_th" style={{ width: "100px" }}>
                                {header !== '' ? header : 'BASIC'}
                            </td>
                        ))}
                        <td className="erp_table_th view-hide" style={{ width: "100px" }}>Unit Name</td>
                    </tr>
                </thead>

                <tbody>
                    {salesOrderData.length > 0 ? (
                        salesOrderData.map((salesdetails, indexOfItem) => (
                            <tr key={indexOfItem}>
                                <td className="erp_table_td">{indexOfItem + 1}</td>
                                <td className="erp_table_td view-hide">
                                    <input
                                        type="checkbox"
                                        className="checkboxForSalesOrderDetails"
                                        name="selectProperty"
                                        value={salesdetails.sales_order_details_transaction_id}
                                        onChange={(e) => calculateQuantityNoOfEnds()}
                                    />
                                </td>
                                <td className="erp_table_td" id={`customer_name_${indexOfItem}`}>
                                    {salesdetails.customer_name}
                                </td>
                                <td className="erp_table_td" id={`sales_order_no_${indexOfItem}`}>
                                    {salesdetails.sales_order_no}
                                </td>
                                <td className="erp_table_td" id={`sales_order_no_${indexOfItem}`}>
                                    {salesdetails.total}
                                </td>

                                {headerData.map((header, index) => (
                                    <td
                                        key={index}
                                        className="erp_table_td"
                                        id={`product_material_stock_unit_name_${index}`}
                                    >
                                        {HeaderStyleAbbData[salesdetails.sales_order_no]?.[header] ?? 0}
                                    </td>
                                ))}



                                <td className="erp_table_td view-hide" id={`product_material_stock_unit_name_${indexOfItem}`}>
                                    {salesdetails.product_material_stock_unit_name}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="erp_table_td text-center" colSpan={6 + styleCount}>No Records Found..!</td>
                        </tr>
                    )}
                </tbody>
            </Table>

        );
    }, [salesOrderData, HeaderStyleAbbData]);


    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />
            <GenerateMaterialId ref={generateMaterialIdAPiCall} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span>Loading...</span>
                    </div>
                </div> :
                ''}
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Weaving Production Order (Plan)  {actionType} </label>
                    </div>

                    <div className="row erp_transporter_div">

                        <div className="col-sm-4 erp_form_col_div">
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Financial year  </Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Label className="erp-form-label"> {FINANCIAL_SHORT_NAME}</Form.Label>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Prod. Section <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col">
                                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); fillComboBoxOnChange('cmb_section_id') }}>
                                        <option value="">Select</option>
                                        {prodsectionOptions.length !== 0 ? (
                                            <>
                                                {prodsectionOptions?.map(production => (
                                                    <option value={production.field_id}>{production.field_name}</option>
                                                ))}
                                            </>
                                        ) : null

                                        }
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row view-hide'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">	S.O Item <span className="required">*</span></Form.Label>
                                </div>

                                <div className="col ">
                                    <SearchItemFilter getSearchData={handleCloseBomFilterForm} formIdForValidate="" requiredCols={requiredCols} />
                                    <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            {txt_product_material_id !== '' ?
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">	Material Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col ">
                                        <Form.Label className="erp-form-label" id="txt_product_material_id" value={txt_product_material_id}> {txt_product_material_id}</Form.Label>
                                    </div>
                                </div> : null}

                            <form id="WeavingProductionform">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Quantity<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Label className="erp-form-label" id="txt_order_quantity" value={txt_order_quantity}> {txt_order_quantity}</Form.Label>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">No Of Ends<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_no_of_ends' className="erp_input_field" value={txt_no_of_ends} onChange={(e) => { setNo_OfEnds(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled />
                                        <MDTypography variant="button" id="error_txt_no_of_ends" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>



                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Sort No</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_sort_no' className="erp_input_field" value={txt_sort_no} onChange={(e) => { setSalesSortNo(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled />
                                        <MDTypography variant="button" id="error_txt_sort_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                        {/* {weavingDataByMaterialId.length === 0 && txt_So_materialId && txt_no_of_ends === '0' && (
                                            <MDButton
                                                onClick={addRecordInProperty}
                                                className="erp-gb-button ms-2"
                                                variant="button"
                                                fontWeight="regular"
                                            >
                                                Create Plan Master
                                            </MDButton>
                                        )}
                                        {weavingDataByMaterialId.length !== 0 && (
                                            <Form.Label className="erp-form-label">{txt_sort_no}</Form.Label>
                                        )} */}
                                    </div>
                                </div>

                                {/*   <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">GLM<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_glm' className="erp_input_field" value={txt_glm} onChange={(e) => { setGLM(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} />
                                        <MDTypography variant="button" id="error_txt_glm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">GSM<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_gsm' className="erp_input_field" value={txt_gsm} onChange={(e) => { setGSM(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} />
                                        <MDTypography variant="button" id="error_txt_gsm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>*/}
                            </form>
                        </div>



                        {/* <div className="col-sm-3 erp_form_col_div"> */}

                        {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">S.O Date </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Label className="erp-form-label"> {date_sales_order_date}</Form.Label>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Label className="erp-form-label"> {txt_customer_name}</Form.Label>

                                    </div>
                                </div> */}
                        {/* </div> */}


                        <div className="col-sm-7 erp_form_col_div ms-4">
                            <div className='erp-form-label-lg'>Sales Order Details</div>
                            {renderSalesOrderDetails}
                        </div>
                    </div>



                    <div className="row erp_transporter_div">
                        <div className="col erp_form_col_div">
                            {isVisibleTbl && (
                                <>
                                    <hr />
                                    <div className="row">
                                        <div className='erp-form-label-lg mb-1'>Create Weaving Production Order</div>

                                        <div className="row">
                                            <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Form.Label className="erp-form-label"> Set No <span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <Form.Control type="text" id="txt_set_no" className="erp_input_field" value={txt_set_no} disabled />
                                                        <MDTypography variant="button" id="error_txt_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Form.Label className="erp-form-label">Schedule Qty. <span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <Form.Control type="text" id="txt_schedule_quantity" className="erp_input_field" value={txt_schedule_quantity} onChange={e => { fillComboBoxOnChange('scheduleQuntity'); validateFields(); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} />
                                                    </div>
                                                    <MDTypography variant="button" id="error_txt_schedule_quantity" className="erp_validation ms-3" fontWeight="regular" color="error" style={{ display: "none" }} />
                                                </div>

                                            </div>

                                            <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Form.Label className="erp-form-label"> Start Date <span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <Form.Control type="date" id="dt_start_date" className="erp_input_field" value={dt_start_date} onChange={e => { setStartDate(e.target.value); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} />
                                                        <MDTypography variant="button" id="error_dt_start_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Form.Label className="erp-form-label"> End Date <span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <Form.Control type="date" id="dt_end_date" className="erp_input_field" value={dt_end_date} onChange={e => { setEndDate(e.target.value); fillComboBoxOnChange('validDate') }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} />
                                                        <MDTypography variant="button" id="error_dt_end_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className='col-sm-5'>
                                                        <Form.Label className="erp-form-label">Yarn Count<span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <select id="cmb_yarn_count" className="form-select form-select-sm" value={cmb_yarn_count} onChange={(e) => { setYarnCount(e.target.value); validateFields(); fillComboBoxOnChange('actualCount') }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                                                            <option >Select</option>
                                                            {weavingProdYarnCountOptions?.map((prodCount) => (
                                                                <option key={prodCount.field_id} value={prodCount.production_actual_count}>
                                                                    {prodCount.field_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_yarn_count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Form.Label className="erp-form-label">Actual Count<span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <Form.Control type="text" id="txt_actual_count" className="erp_input_field" value={txt_actual_count} onChange={e => { setActualCount(e.target.value); validateFields(); }} disabled />
                                                        <MDTypography variant="button" id="error_txt_actual_count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div className="col-sm-3 erp_form_col_div">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Form.Label className="erp-form-label">No Of Ends<span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <Form.Control type="text" id='txt_no_of_ends' className="erp_input_field" value={txt_no_of_ends} onChange={(e) => { setNo_OfEnds(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} />
                                                        <MDTypography variant="button" id="error_txt_no_of_ends" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                    </div>
                                </>
                            )}
                            {showTextarea && (
                                <>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Status Remark <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-3">
                                            <Form.Control as="textarea" id="status_remark" className="erp_txt_area" value={status_remark} onChange={e => { setStatusRemark(e.target.value); fillComboBoxOnChange('remarkmsg') }} disabled={keyForViewUpdate === 'view'} maxlength="500" />
                                            <MDTypography variant="button" id="error_status_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </>
                            )}

                            {showTabs && (
                                <Grid justifyContent="center" sx={{ my: 4 }}>
                                    <Grid item xs={12} lg={8} className="erp_form_container_tabs">
                                        <MDBox mt={-3} mx={2}>
                                            <Tabs
                                                activeKey={activeStep}
                                                id="uncontrolled-tab-example"
                                                className="mb-3 selectedTab erp-form-Tabs"
                                                onSelect={eventKey => moveByTabs(eventKey)}>
                                                {steps.map((label, index) => (
                                                    <Tab eventKey={index} title={label}></Tab>
                                                ))}
                                            </Tabs>
                                            {getStepContent()}
                                        </MDBox>
                                    </Grid>
                                </Grid>

                            )}
                        </div>
                    </div>

                    <div className="card-footer pb-4 text-center">
                        <MDButton type="button" className="erp-gb-button ms-2" onClick={() => { navigate(`/Masters/MWeavingProductionPlan/FrmMWeavingProductionListing`) }} variant="button"
                            fontWeight="regular">Back</MDButton>
                        <MDButton type="submit" onClick={addWeavingProductionOrderPlan} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                        <MDButton type="submit" onClick={handleReject} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'approve' ? '' : 'd-none'}`} variant="button" fontWeight="regular">
                            Rejected
                        </MDButton>
                    </div >
                </div >
                {/* Success Msg Popup */}
                < SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()
                } show={[showSuccessMsgModal, succMsg]} />
                {/* Error Msg Popup */}
                < ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}

                    </Modal.Body>
                </Modal >
            </div >
        </>
    )
}

export default FrmMWeavingProductionPlanEntry;
