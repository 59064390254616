import React, { useState } from 'react'
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import { useEffect } from 'react';
import ConfigConstants from "assets/Constants/config-constant";
import { PauseCircleFilledTwoTone } from '@mui/icons-material';

//convert cgst,igst,sgst percent in words
function numberToWordsWithCurrency(number) {
   

    // Check if the input number is NA, null, or an empty string
    if (number === null || number === "NA" || number === "" || number === undefined) {
        return "-";
    }

    const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    // Function to convert the integer part of the number to words
    function convertIntegerToWords(integer) {
        if (integer === 0) {
            return "";
        }

        if (integer < 20) {
            return ones[integer];
        }

        if (integer < 100) {
            const ten = Math.floor(integer / 10);
            const remainder = integer % 10;
            return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
        }

        if (integer < 1000) {
            const hundred = Math.floor(integer / 100);
            const remainder = integer % 100;
            return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
        }

        if (integer < 1000000) {
            const thousand = Math.floor(integer / 1000);
            const remainder = integer % 1000;
            return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
        }
        if (integer < 1000000000) {
            const million = Math.floor(integer / 1000000);
            const remainder = integer % 1000000;
            return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
        }

        return "Number too large to convert";
    }

    // Function to convert the decimal part of the number to words
    function convertDecimalToWords(decimal) {
        const tensValue = Math.floor(decimal / 10);
        const onesValue = decimal % 10;
        let words = "";

        if (tensValue > 0) {
            words += tens[tensValue];
        }

        if (onesValue > 0) {
            words += ` ${ones[onesValue]}`;
        }

        return words;
    }

    // Split the number into integer and decimal parts
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

    // Convert the integer part to words
    let words = convertIntegerToWords(integerPart);

    // Add currency
    if (words) {
        words += " Rupees";
    }

    // Add the decimal part to the words
    if (decimalPart > 0) {
        words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
    }

    return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
}

const SalesInvoice = React.forwardRef(({ invoiceContent }, ref) => {
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;
    let AFTER_DECIMAL_PLACES = 2;

    
    const [data, setData] = useState([])
    useEffect(() => {
        setData(invoiceContent.items)
    }, [])

    const invoiceHeader = (
        <>
            <div className='row border-top border border-bottom-0 p-0 m-0'>
                {/* <div className="col-sm-4">
                    <img src={DakshabhiLogo} alt="master card" width="150" height="150" mt={1} />
                </div> */}

                <div className="col-sm-9 text-center border-end border-dark">
                    <div className='erp-invoice-print-label-md-lg'>
                        Tax Invoice
                    </div>
                    <div className=''>
                        <dt className='erp-invoice-print-label'>(REMOVAL OF GOODS FROM FACTORY UNDER RULE 7 SECTION 31 OF GST RULES 2024)</dt>
                    </div>
                    <hr className='m-0' />
                    <div className='erp-invoice-print-label'>
                        {invoiceContent.company.branch_address1}
                        <div>
                            <span className='erp-invoice-print-label'>Email:  {invoiceContent.company.email}</span>
                        </div>
                        <div>
                            <span className='erp-invoice-print-label'>Phone:  {invoiceContent.company.phone}</span>
                        </div>
                        {/* <div>
                            <span className='erp-invoice-print-label'>WebSite:  <a href={invoiceContent.company.website}>{invoiceContent.company.website}</a></span>
                        </div> */}

                    </div>

                    <div className='row  border-bottom border-top border-dark text-start'>
                        <div className='col-sm-6'>
                            <dt className='erp-invoice-print-label-md-lg'>Invoice No: {invoiceContent.invoicedetails.invoice_no}</dt>
                        </div>
                        <div className='col-sm-6'>
                            <dt className='erp-invoice-print-label-md-lg'>Invoice Date: {invoiceContent.invoicedetails.invoice_date}</dt>
                        </div>
                    </div>

                    <div className='row text-start'>
                        <div className='col-sm-6'>
                            <dt className='erp-invoice-print-label-md-lg'>GSTN No: {invoiceContent.gstdetails.gst_no}</dt>
                            <dt className='erp-invoice-print-label-md-lg'>State Name: {`${invoiceContent.company.state_name} (${invoiceContent.company.state_code})`}</dt>
                            <dt className='erp-invoice-print-label-md-lg'>Tax Is Pay. On reverse Charge: {invoiceContent.gstdetails.tax_is_pay}</dt>
                            <dt className='erp-invoice-print-label-md-lg'>Udyog Aadhar No.: {invoiceContent.gstdetails.udyog_adhar_no}</dt>
                            <dt className='erp-invoice-print-label-md-lg'>Income Tax PAN No.: {invoiceContent.gstdetails.pan_no}</dt>
                        </div>
                        <div className='col-sm-6'>
                            <dt className='erp-invoice-print-label-md-lg'>PO No & Date: {`${invoiceContent.podetails.purchase_order_no}  ${invoiceContent.podetails.purchase_order_date}`} </dt>
                            <dt className='erp-invoice-print-label-md-lg'>CH. No & Date: {`${invoiceContent.podetails.dispatch_challan_no}  ${invoiceContent.podetails.dispatch_challan_date}`} </dt>
                            <dt className='erp-invoice-print-label-md-lg'>Vendor Code: {invoiceContent.podetails.vendor_code}</dt>
                            <dt className='erp-invoice-print-label-md-lg'>Time of Issue : {invoiceContent.podetails.time_of_issue}</dt>
                            <dt className='erp-invoice-print-label-md-lg'>Time of Removal: {invoiceContent.podetails.time_of_removal}</dt>

                            {/* <dt className='erp-invoice-print-label-md-lg'>Commissionerate: {invoiceContent.gstdetails.commissionerate}</dt> */}
                        </div>
                    </div>

                </div>

                <div className="col-sm-3">
                    <div className='text-center'>
                        <img src={DakshabhiLogo} alt="master card" width="150" height="150" mt={1} />
                    </div>
                </div>
            </div>

            <div className="row border-dark border-start border-end p-0 m-0">
                <div className="col-sm-6 border-end border-dark">
                    <dt className='erp-invoice-print-label-md-lg'>Bill To Details:</dt>
                    <div className='erp-invoice-print-label-md-lg'>
                        {invoiceContent.billAddress.billToDetails.company}
                    </div>
                    <div className="erp-invoice-print-label">
                        {invoiceContent.billAddress.billToDetails.address}
                    </div>
                    <dl className="row mb-0">
                        <dt className='col-sm-2 erp-invoice-print-label-md-lg'>State:</dt>
                        <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.billAddress.billToDetails.customer_state_name}</dd>
                        <dt className='col-sm-6'>
                            <dl class="row">
                                <dt class="col-sm-2 erp-invoice-print-label-md-lg">GSTIN:</dt>
                                <dd class="col-sm-10 erp-invoice-print-label">{invoiceContent.billAddress.billToDetails.customer_gst_no}</dd>
                            </dl>
                        </dt>
                        <dt className='col-sm-6'>
                            <dl class="row">
                                <dt class="col-sm-2 erp-invoice-print-label-md-lg">PAN:</dt>
                                <dd class="col-sm-10 erp-invoice-print-label">{invoiceContent.billAddress.billToDetails.customer_pan_no}</dd>
                            </dl>
                        </dt>
                    </dl>
                </div>

                <div className="col-sm-6">
                    <dt className='erp-invoice-print-label-md-lg'>Shipped To Details:</dt>
                    <div className='erp-invoice-print-label-md-lg'>
                        {invoiceContent.billAddress.shipToDetails.company}
                    </div>
                    <div className="erp-invoice-print-label">
                        {invoiceContent.billAddress.shipToDetails.address}
                    </div>
                    <dl className="row">
                        <dt className='col-sm-2 erp-invoice-print-label-md-lg'>State:</dt>
                        <dd className='col-sm-10 erp-invoice-print-label'> {invoiceContent.billAddress.shipToDetails.consignee_state_name}</dd>
                        <dt className='col-sm-6'>
                            <dl class="row">
                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">GSTIN:</dt>
                                <dd className="col-sm-10 erp-invoice-print-label">{invoiceContent.billAddress.shipToDetails.consignee_gst_no}</dd>
                            </dl>
                        </dt>
                        <dt className='col-sm-6'>
                            <dl className="row">
                                <dt className="col-sm-2 erp-invoice-print-label-md-lg">PAN:</dt>
                                <dd className="col-sm-10 erp-invoice-print-label">{invoiceContent.billAddress.shipToDetails.consignee_pan_no}</dd>
                            </dl>
                        </dt>
                    </dl>

                </div>
            </div>

            {/* <hr className='m-0' /> */}
            {/* <hr className='m-0' /> */}
            <div className='row border-dark border-start border-end border-top p-0 m-0'>
                <dt className='erp-invoice-print-label-md-lg'>IRN No: {invoiceContent.invoicedetails.irn_no} &nbsp; {invoiceContent.invoicedetails.irn_date}</dt>
                <dt className='erp-invoice-print-label-md-lg'>Eway Bill Details: {invoiceContent.invoicedetails.eway_bill_no} &nbsp; {invoiceContent.invoicedetails.eway_bill_date}</dt>

                <div className='row p-0 m-0'>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>Place Of Supply: {invoiceContent.invoicedetails.place_of_supply}</dt>
                    </div>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>Vehical No:  {invoiceContent.invoicedetails.vehical_no}</dt>
                    </div>
                </div>

                <div className='row p-0 m-0'>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>Transporter:{invoiceContent.invoicedetails.transporter} </dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>L.R. No: NIL</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>L.R. Date: NIL</dt>
                    </div>
                </div>

                <div className='row p-0 m-0'>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>LC No: </dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>Insu. No:</dt>
                    </div>
                </div>
            </div>

            {/* <div className='row border-dark border-bottom-0 border p-0 m-0'>
                <div className='col-sm-6'>
                    <dt className='erp-invoice-print-label-md-lg'>Ack. No & Date:{invoiceContent.invoicedetails.ack_no_date} </dt>
                </div>
                <div className='col-sm-6'>
                    <dt className='erp-invoice-print-label-md-lg'>Eway Bill Details: {invoiceContent.invoicedetails.eway_bill_no} &nbsp; {invoiceContent.invoicedetails.eway_bill_date}</dt>
                </div>
            </div> */}

            {/* <div className='row border-dark border-bottom-0 border p-0 m-0'>
                <div className='col-sm-3'>
                    <dt className='erp-invoice-print-label-md-lg'>Vehical No:  {invoiceContent.invoicedetails.vehical_no}</dt>
                </div>
                <div className='col-sm-3'>
                    <dt className='erp-invoice-print-label-md-lg'>Issue Time : {invoiceContent.podetails.time_of_issue}</dt>
                </div>
                <div className='col-sm-3'>
                    <dt className='erp-invoice-print-label-md-lg'>Removal Time: {invoiceContent.podetails.time_of_removal}</dt>
                </div>
            </div> */}
            {/* <hr className='m-0' /> */}
            {/* <div className='row border-dark border-bottom-0 border p-0 m-0'>
                <div className='col-sm-4'>
                    <dt className='erp-invoice-print-label-md-lg'>P.O. Details: {invoiceContent.podetails.purchase_order_no} </dt>
                </div>
                <div className='col-sm-4'>
                    <dt className='erp-invoice-print-label-md-lg'>Vendor Code: {invoiceContent.podetails.vendor_code}</dt>
                </div>
            </div> */}

            {/* <hr className='m-0' /> */}
        </>
    )

    const invoiceFooter = (
        <div className="invoice-footer m-0 p-0">
            {/* <div className='row m-0 p-0'>
                <div className='col-sm-12 px-0'>
                    <div className="erp-invoice-print-label border-bottom border-dark">
                        Certified that the particulars given above are ture and correct and the amount indicated represents the price actualy charged and that there is no flow of additional consideration directly or indirectly from the buyer.
                    </div>
                </div>
            </div> */}
            <div className='row mt-1 m-0 p-0 px-0 border-bottom border-dark'>
                <dt className="col-sm-12 erp-invoice-print-label">
                    Certified that the particulars given above are true and correct and the amount indicated represents the price actualy charged and that there is no flow of additional consideration directly or indirectly from the buyer.
                </dt>
            </div>

            <div className='row m-0 p-0'>
                <div className='col-sm-4 border-end border-dark'>
                    <dt className="col-sm-9 erp-invoice-print-label-md-lg mt-2">Bank Details:</dt>
                    <dl className='row mb-1'>
                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Bank Name:</dt>
                        <dd className="col-sm-9 erp-invoice-print-label">{invoiceContent.footer.bank_details.bank_name}</dd>

                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Account No:</dt>
                        <dd className="col-sm-9 erp-invoice-print-label">{invoiceContent.footer.bank_details.account_no}</dd>

                        <dt className="col-sm-3 erp-invoice-print-label-md-lg">IFSC Code:</dt>
                        <dd className="col-sm-9 erp-invoice-print-label ">{invoiceContent.footer.bank_details.ifsc_code}</dd>
                    </dl>
                </div>
                <div className='col-sm-4 border-end border-dark text-center'>
                    <div className='align-items-end mt-5 pt-4'>
                        <span className="erp-invoice-print-label-md-lg mt-3"> Receiver's Signatory</span>
                    </div>
                </div>

                <div className='col-sm-4 text-center'> {/* This offsets the third column */}
                    {/* <div class="d-flex align-items-end">...</div> */}
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg mt-2">For {invoiceContent.company.company_name}</dt>
                    </div>
                    <div className='mt-3'>
                        <dt className="erp-invoice-print-label-md-lg mt-5 pt-3">Authorised Signatory</dt>
                    </div>
                </div>

            </div>
        </div>


    );

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">
                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {data.length !== 0 && (
                                                                <thead>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-7" rowspan={2}>Description of Goods</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>HSN Code</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Qty.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Unit</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Rate Per Unit</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" colSpan={3}>Total Amount (Rs.)</th>
                                                                    </tr>

                                                                </thead>
                                                            )}
                                                            <tbody id="detailstableId">
                                                                {data.map((item, index) =>
                                                                    <>
                                                                        <tr className={`tblBodyTrHt-${index}`}>
                                                                            <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                            <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_material_name}<span>({item.material_tech_spect})</span></td>
                                                                            <td className="erp_invoice_table_td">{item.hsn_sac_code}</td>
                                                                            <td className="erp_invoice_table_td text-end">{item.invoice_quantity}</td>
                                                                            <td className="erp_invoice_table_td">{item.product_unit_name}</td>
                                                                            <td className="erp_invoice_table_td text-end">{item.item_rate}</td>
                                                                            <td className="erp_invoice_table_td text-end">{item.item_amount}</td>
                                                                        </tr>
                                                                        {
                                                                            data.length - 1 === index ? <>

                                                                                <tr id='footerTr'>
                                                                                    <td className="erp_invoice_table_td px-0" colSpan={4}>
                                                                                        <table className="table p-0 m-0 table-borderless">
                                                                                            <tbody>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">Delivery Terms :</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg"></dd>
                                                                                                    </div>
                                                                                                </tr>

                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg">{numberToWordsWithCurrency(invoiceContent.footer.central_tax_value)}</dd>
                                                                                                    </div>
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg">{numberToWordsWithCurrency(invoiceContent.footer.state_tax_value)}</dd>
                                                                                                    </div>
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg">{numberToWordsWithCurrency(invoiceContent.footer.integrated_tax_value)}</dd>
                                                                                                    </div>
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">Taxable Total:</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.footer.taxable_total}</dd>
                                                                                                    </div>
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">Grand Total :</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.footer.grand_total}</dd>
                                                                                                    </div>
                                                                                                </tr>

                                                                                                <tr className="border-bottom border-dark" rowspan={2}>
                                                                                                    <div className='row mt-1 m-0 p-0'>
                                                                                                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">Payment Terms :</dt>
                                                                                                        <dd className="col-sm-8 erp-invoice-print-label-md-lg"></dd>
                                                                                                    </div>
                                                                                                </tr>

                                                                                            </tbody>
                                                                                        </table>

                                                                                        {/* <div className='row mt-1 m-0 p-0'>
                                                                                            <dt className="col-sm-4 erp-invoice-print-label-md-lg">Basic Total :</dt>
                                                                                            <dd className="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.footer.basic_total}</dd>
                                                                                        </div>
                                                                                        <div className='row mt-1 m-0 p-0'>
                                                                                            <dt className="col-sm-4 erp-invoice-print-label-md-lg">CGST(in words):</dt>
                                                                                            <dd className="col-sm-8 erp-invoice-print-label-md-lg">{numberToWordsWithCurrency(invoiceContent.footer.central_tax_value)}</dd>
                                                                                        </div>
                                                                                        <div className='row mt-1 m-0 p-0'>
                                                                                            <dt className="col-sm-4 erp-invoice-print-label-md-lg">SGST(in words):</dt>
                                                                                            <dd className="col-sm-8 erp-invoice-print-label-md-lg">{numberToWordsWithCurrency(invoiceContent.footer.state_tax_value)}</dd>
                                                                                        </div>
                                                                                        <div className='row mt-1 m-0 p-0'>
                                                                                            <dt className="col-sm-4 erp-invoice-print-label-md-lg">IGST(in words):</dt>
                                                                                            <dd className="col-sm-8 erp-invoice-print-label-md-lg">{numberToWordsWithCurrency(invoiceContent.footer.integrated_tax_value)}</dd>
                                                                                        </div>
                                                                                        <div className='row mt-1 m-0 p-0'>
                                                                                            <dt className="col-sm-4 erp-invoice-print-label-md-lg">Taxable Total:</dt>
                                                                                            <dd className="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.footer.taxable_total}</dd>
                                                                                        </div>
                                                                                        <div className='row mt-1 m-0 p-0'>
                                                                                            <dt className="col-sm-4 erp-invoice-print-label-md-lg">Grand Total :</dt>
                                                                                            <dd className="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.footer.grand_total}</dd>
                                                                                        </div> */}
                                                                                    </td>
                                                                                    <td className="erp_invoice_table_td p-0 m-0" colSpan={3}>
                                                                                        <table className="table p-0 m-0 table-borderless">
                                                                                            <tbody>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Basic Total :</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.basic_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">CGST Total :</span></td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.central_tax_value).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">SGST Total :</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.state_tax_value).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">IGST Total:</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.integrated_tax_value).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Packing Amount :</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.packing_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Freight Amount :</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.freight_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                {/* <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Discount Amount</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.basic_total}</td>
                                                                                                </tr> */}
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Taxable Total :</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.taxable_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Other Amount:</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.other_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className="border-bottom border-dark">
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">TCS Amount:</span> </td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.tcs_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                                <tr className=''>
                                                                                                    <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Grand Total: </span></td>
                                                                                                    <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{parseFloat(invoiceContent.footer.grand_total).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td className='m-0 p-0' colSpan={12}>
                                                                                        {invoiceFooter}
                                                                                    </td>
                                                                                </tr>

                                                                            </> : null
                                                                        }

                                                                    </>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});
export default SalesInvoice
