import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useTable, useGroupBy, useExpanded } from 'react-table';


import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Table from 'react-bootstrap/Table';
import { Form } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';

import ComboBox from 'Features/ComboBox';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfigConstants from 'assets/Constants/config-constant';

function FrmProductionReportEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID } = configConstants;

    const validateNumberDateInput = useRef();

    const [productionReportDetails, setProductionReportDetails] = useState([])
    const [columns, setProductionReportColumns] = useState([])
    const [totalsColumns, setProductionReportTotalsColumns] = useState([])
    const [shifts, setProductionShifts] = useState([])
    const [machines, setMachines] = useState([])
    const [headerPartDetails, setHeaderPartDetails] = useState()

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    //Hooks for data
    const [dt_spinning_production_date, setProdPlanDate] = useState(today);
    const [cmb_plant_id, setProdPlantName] = useState('');
    const [plantOptions, setPlantOptions] = useState([]);

    //loader
    const [isLoading, setIsLoading] = useState(false);

    ////useRef Hooks
    const comboDataAPiCall = useRef();
    const validate = useRef();

    useEffect(async () => {
        setIsLoading(true)
        await fillComboBoxes()
        setIsLoading(false)
    }, [])

    const fillComboBoxes = async () => {
        try {
            const getProdPlantApiCall = await comboDataAPiCall.current.fillMasterData("cmv_plant", "", "");
            setPlantOptions(getProdPlantApiCall)

        } catch (error) {

        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('productionReportFormId');
    }

    const FnGetReportDetails = async () => {
        try {
            setIsLoading(true);
            let validateProdRptForm = await validate.current.validateForm('productionReportFormId');
            if (validateProdRptForm) {
                const reportRequest = {
                    company_id: COMPANY_ID,
                    plant_id: cmb_plant_id,
                    spinning_production_date: dt_spinning_production_date
                }

                const formdata = new FormData();
                formdata.append("reportRequest", JSON.stringify(reportRequest));

                const requestOptions = {
                    method: "POST",
                    body: formdata,
                };

                const getProductionDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProductionRfMaster/FnShowProductionReport`, requestOptions)
                const response = await getProductionDetails.json()

                const productionReportDetails = response.productionReportDetails

                setProductionShifts(response.productionShift)
                setMachines(response.machines)
                setProductionReportColumns(response.productionReportColumns)
                setProductionReportDetails(productionReportDetails)
                setProductionReportTotalsColumns(response.productionReportTotalColumns)
                setHeaderPartDetails(response.headerPartDetails)

            }
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setIsLoading(false)
        }
    }

    // Export to excel
    const FnExportToExcel = async () => {
        try {
            setIsLoading(true)
            var selectElement = document.getElementById("cmb_plant_id");
            var selectedText = selectElement.options[selectElement.selectedIndex].text;

            const reportRequest = {
                company_id: COMPANY_ID,
                plant_name: selectedText,
                spinning_production_date: dt_spinning_production_date,
                productionReportDetails: productionReportDetails,
                productionReportColumns: columns,
                productionReportTotalColumns: totalsColumns,
                productionShift: shifts,
                machines: machines,
                headerPartDetails: headerPartDetails
            }

            const formdata = new FormData();
            formdata.append("reportExportRequest", JSON.stringify(reportRequest));

            const requestOptions = {
                method: "POST",
                body: formdata,
            };

            const getProductionDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProductionRfMaster/FnProductionReportExport`, requestOptions)
            const blob = await getProductionDetails.blob()
            if (blob.size !== 0) {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "test.xlsx",);
                document.body.appendChild(link);

                link.click();
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setIsLoading(false)
        }
    }

    // Function to render the table rows
    const renderRows = () => {
        return machines.map((machine, mcIndex) => {
            const machineData = productionReportDetails[machine];

            return (
                <>
                    <tr key={machine}>
                        <td className='erp_table_td'>{machine}</td>
                        {shifts.map((shift, index) => {
                            const productionDetail = machineData[0]?.production_details.find(item => item.shift === shift?.property_name);
                            return (
                                <>
                                    {columns.map((column, index) => (
                                        column.accessor !== 'machine_short_name' &&
                                        <td className='erp_table_td' key={index}>{productionDetail[column.accessor]}</td>
                                    ))}
                                    {shifts.length - 1 === index && (
                                        <>
                                            {totalsColumns.map((columnHead, columnIndex) => (
                                                <td className='erp_table_td' key={columnIndex}>{validateNumberDateInput.current.decimalNumber(JSON.stringify(machineData[0]?.totals[columnHead.accessor]))}</td>
                                            ))}
                                        </>
                                    )}
                                </>
                            );


                        })}

                    </tr>
                    {/* <tr>
                        {
                            machines.length - 1 === mcIndex ?
                                <td className='erp_table_td'>{}</td> : null
                        }
                    </tr> */}
                </>
            );
        });
    };

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Production Report</label>
                    </div>

                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div>
                        </div> :
                        null}

                    <form id='productionReportFormId'>
                        <div className='row pt-1'>
                            <div className='col-sm-9 erp_form_col_div'>
                                <div className='row pt-1'>
                                    <div className='col-sm-5'>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Plant<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { setProdPlantName(e.target.value); validateFields() }}>
                                                    <option value="" disabled>Select</option>
                                                    {plantOptions.length !== 0 ? (
                                                        <>{plantOptions?.map(plant => (
                                                            <option value={plant.field_id}>{plant.field_name}</option>
                                                        ))} </>
                                                    ) : null
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-4'>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Production Date <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id='dt_spinning_production_date' className="erp_input_field" value={dt_spinning_production_date} onChange={(e) => { setProdPlanDate(e.target.value) }} />
                                                <MDTypography variant="button" id="error_dt_spinning_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-3'>
                                        <div className="d-flex">
                                            <MDButton type='button' className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => { FnGetReportDetails() }} >Show</MDButton>
                                            <MDButton type='button' className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => { FnExportToExcel() }} >Export</MDButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {productionReportDetails.length !== 0 ?
                        <Table className="erp_table mt-1" striped bordered hover responsive>
                            <thead className="erp_table_head">
                                <tr>
                                    <th className='erp_table_th'>Ring Frame Details</th>
                                    {shifts.map((shift, index) => (
                                        <React.Fragment key={index}>
                                            <th colSpan={columns.length - 1} className='erp_table_th text-center'>{shift.property_name}</th>
                                            {shifts.length - 1 === index &&

                                                (<th colSpan={totalsColumns.length} className='erp_table_th text-center'>Totals</th>)

                                            }
                                        </React.Fragment>
                                    ))}

                                </tr>
                                <tr>
                                    <th className='erp_table_th'>M/C No</th>
                                    {shifts.map((shift, index) => (
                                        <React.Fragment key={`shift-${index}`}>
                                            {columns.map((column, columnIndex) => (
                                                column.accessor !== 'machine_short_name' ?
                                                    <th key={`column-${columnIndex}`} className='erp_table_th'>{column.Header}</th> : null
                                            ))}
                                            {shifts.length - 1 === index && (
                                                totalsColumns.map((columnHead, columnIndex) => (
                                                    <th key={`total-column-${columnIndex}`} className='erp_table_th'>{columnHead.Header}</th>
                                                ))
                                            )}
                                        </React.Fragment>
                                    ))}



                                </tr>
                            </thead>
                            <tbody>{renderRows()}</tbody>
                        </Table>
                        : null}
                </div>



            </DashboardLayout >
        </>
    )
}

export default FrmProductionReportEntry