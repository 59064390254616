import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
// import valid formate
const Enquiry = React.forwardRef(({ invoiceContent }, ref) => {


    const itemData = extractItemData(invoiceContent.items);
    function extractItemData(items) {
        // Extract relevant data from the first item in the array
        if (items.length > 0) {
            const firstItem = items[0];
            return {
                customer_city_name: firstItem.customer_city_name,
                customer_state_name: firstItem.customer_state_name,

            };
        }
        // Return default values or handle the case where the array is empty
        return {
            customer_city_name: 0,
            customer_state_name: 0,

        };
    }

    const data = invoiceContent.items
    debugger
    const exist_expct_funct_data = invoiceContent.enquiryExistingExpectedFunctionalityDetails
    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-2">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-10">
                        <div className='erp-invoice-print-label text-center'>
                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                            <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
                        </div>
                        <div className='erp-invoice-print-label-lg text-center'>
                            Enquiry
                        </div>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-5'>
                        <dt className='erp-invoice-print-label-md-lg'> Enquiry No & version : {invoiceContent.enquiryDetails.enquiry_no} & {invoiceContent.enquiryDetails.enquiry_version}</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'> Enquiry Type : {invoiceContent.enquiryDetails.enquiry_type}</dt>
                    </div>
                    <div className='col-sm-3'>
                        <dt className='erp-invoice-print-label-md-lg text-end'> Enquiry Date : {invoiceContent.enquiryDetails.enquiry_date}</dt>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <dt className='erp-invoice-print-label-md-lg'>Customer Details:</dt>
                        <div className="col-sm-5">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Customer:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.customer}</dd>

                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Address:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.address}</dd>

                                <dl className="row pb-0 mb-0">
                                    <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
                                    <dd class="col-sm-10 m-0 p-0">
                                        <dl class="row m-0 p-0">
                                            <dt class="col-sm-4  erp-invoice-print-label"> {invoiceContent.customerDetails.state}</dt>
                                            <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                                            <dt class="col-sm-3 erp-invoice-print-label">{invoiceContent.customerDetails.city}</dt>
                                        </dl>
                                    </dd>
                                </dl>
                                {/* <dt className='col-sm-3 erp-invoice-print-label-md-lg'>State:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.state}</dd> */}
                            </dl>
                        </div>
                        <div className="col-sm-7">
                            <dl className="row">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Email:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.email}</dd>
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Contact No:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.contact}</dd>
                                {/* <dt className='col-sm-3 erp-invoice-print-label-md-lg'>City:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.city}</dd> */}
                            </dl>
                        </div>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <dt className='erp-invoice-print-label-md-lg'>Our Details:</dt>
                        <div className="col-sm-5">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Branch name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.expected_branch_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Department:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.department_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Enquiry by:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.enquiry_by}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved By:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.approved_by}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Agent Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.agent_name}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-7">
                            <dl className="row">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Assign To Head:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.assign_to_head_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Overall Scheduled Date:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.overall_schedule_date}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved On:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.approved_on}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved Remark:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.remark}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>
                <div className='col-sm-6'>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.company.company}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );


    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable'>
                                                            <thead>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th  text-center">Sr No.</th>
                                                                    <th className="erp_invoice_table_th col-5 text-center">Material Name(Technical Spect.)</th>
                                                                    <th className="erp_invoice_table_th col-3 text-center">Material Notes</th>
                                                                    <th className="erp_invoice_table_th col-1 text-center">Unit</th>
                                                                    <th className="erp_invoice_table_th col-1 text-center">Moq. Qty.</th>
                                                                    <th className="erp_invoice_table_th col-1 text-center">Material Qty.</th>
                                                                    <th className="erp_invoice_table_th col-1 text-center">Enquiry Wt.</th>
                                                                </tr>
                                                            </thead>
                                                            {/* <tbody id='detailstableId'>  */}
                                                            <tbody id={`${exist_expct_funct_data.length === 0 ? 'detailstableId' : ''}`}>
                                                                {data.map((item, index) => (
                                                                    <tr className={`tblBodyTrHt-${index}`}>
                                                                        <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                        <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_material_name}<span>( {item.product_material_tech_spect} )</span></td>
                                                                        <td className="erp_invoice_table_td">{item.material_notes}</td>
                                                                        <td className="erp_invoice_table_td">{item.product_material_unit_name}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.material_moq_quantity}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.product_material_enquiry_quantity}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.product_material_enquiry_weight}</td>
                                                                    </tr>
                                                                ))}
                                                                {
                                                                    exist_expct_funct_data.length === 0
                                                                    ? <>
                                                                    <tr id='footerTr'> </tr>
                                                                    <tr>
                                                                        <td className="erp_invoice_table_td" colSpan={7}>
                                                                            <div className='row mt-1'>
                                                                                <dt className="col-sm-3 erp-invoice-print-label-md-lg">Other Terms & Conditions:</dt>
                                                                                <dd className="col-sm-9 erp-invoice-print-label">{invoiceContent.footer.other_terms_conditions}</dd>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr> <td colSpan={7}> {invoiceFooter} </td> </tr>
                                                                    </>
                                                                    : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {/* Existing Expected Functionality */}
                                                        {
                                                                    exist_expct_funct_data.length > 0
                                                                    ? <>
                                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="erp_invoice_table_th text-center">Sr No.</th>
                                                                                    <th className="erp_invoice_table_th col-5">Existing Functionality</th>
                                                                                    <th className="erp_invoice_table_th col-4">Expected Functionality</th>
                                                                                    <th className="erp_invoice_table_th col-3">Expected Value</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody id='detailstableId'>
                                                                                {exist_expct_funct_data.map((enquiryExistingExpectedFunctionalityDetails, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="erp_invoice_table_td text-center">{enquiryExistingExpectedFunctionalityDetails.sr_no}</td>
                                                                                        <td className="erp_invoice_table_td">{enquiryExistingExpectedFunctionalityDetails.existing_functionality}</td>
                                                                                        <td className="erp_invoice_table_td">{enquiryExistingExpectedFunctionalityDetails.expected_functionality}</td>
                                                                                        <td className="erp_invoice_table_td">{enquiryExistingExpectedFunctionalityDetails.expected_value}</td>
                                                                                    </tr>
                                                                                ))}
                                                                                <tr id='footerTr'> </tr>
                                                                                <tr>
                                                                                    <td className="erp_invoice_table_td" colSpan={4}>
                                                                                        <div className='row mt-1'>
                                                                                            <dt className="col-sm-3 erp-invoice-print-label-md-lg">Other Terms & Conditions:</dt>
                                                                                            <dd className="col-sm-9 erp-invoice-print-label">{invoiceContent.footer.other_terms_conditions}</dd>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={4}>
                                                                                        {/* Your invoice footer content */}
                                                                                        {invoiceFooter}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                    : null
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})

export default Enquiry;

