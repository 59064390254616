import React, { useState, useRef, useMemo, useEffect } from "react";
import $ from 'jquery';

// React Icons
import { MdDelete } from 'react-icons/md';
// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Modal, Table, Tooltip } from "react-bootstrap";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import { CircularProgress } from "@material-ui/core";
import ComboBox from 'Features/ComboBox';


export default function TGenericAddmaterialProductionfrm({ generatedSZNo, setNO, productionmaterialDate, subSectionId }) {
  const { state } = useLocation();
  const { keyForViewUpdate } = state || {}

  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;


  // For Refs
  const validateNumberDateInput = useRef();
  const navigate = useNavigate();
  const comboDataAPiCall = useRef();


  //FrmsizingEntry
  const [cmb_indent_type, setIndentType] = useState('');
  const [txt_set_no, setSetNo] = useState(setNO);
  const [txt_Sub_Section_id, setSub_Section_id] = useState(subSectionId);
  const [dt_productionmaterialDate, SetProductionMaterialDate] = useState(productionmaterialDate);
  const [txt_order_no, setSizingOrderNO] = useState(generatedSZNo !== null && generatedSZNo.length !== 0 ? generatedSZNo : '')
  const [txt_MasterProduction_id, setMasterProduction_id] = useState('')

  const [sizingfilteredData, setSizingfilteredData] = useState([])
  const [showSZFilterForm, setShowSZFilterForm] = useState(false);

  const handleCloseErrModal = () => setShowErrorMsgModal(false);

  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
  }



  useEffect(() => {
    removeSessions('onload');
    if (keyForViewUpdate !== "Add") {
      if (sizingfilteredData && sizingfilteredData[0]?.sizingfilteredData) {
        setSizingOrderNO(sizingfilteredData[0].sizing_order_no);
      }
    }
    fillComboBox();
  }, [sizingfilteredData])


  const fillComboBox = async () => {
    debugger
    try {
      resetGlobalQuery();
      globalQuery.columns.push("weaving_production_order_id");
      globalQuery.table = "xt_weaving_production_order"
      globalQuery.conditions.push({ field: "set_no", operator: "=", value: setNO });
      if (parseInt(subSectionId) === 22) {
        globalQuery.conditions.push({ field: "sizing_order_no", operator: "=", value: generatedSZNo });
      } else if (parseInt(subSectionId) === 21) {
        globalQuery.conditions.push({ field: "warping_order_no", operator: "=", value: generatedSZNo });
      }
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      var getMasterIDApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setMasterProduction_id(getMasterIDApiCall[0].weaving_production_order_id)
      console.log('getMasterIDApiCall', getMasterIDApiCall[0].weaving_production_order_id);
    } catch (error) {
      console.log('error', error);
    }
  }


  const removeSessions = (reloadData) => {
    sessionStorage.removeItem(`comboFilterData`)
    sessionStorage.removeItem('isComboFilterExist')
    sessionStorage.removeItem('smv_product_type');
    sessionStorage.removeItem('smv_product_material_type');
    sessionStorage.removeItem('smv_product_material_grade');
    sessionStorage.removeItem('smv_product_material_shape');
    if (reloadData === 'onload') {
      sessionStorage.removeItem('filteredMaterialData')
      localStorage.removeItem('indentScheduleData')
    }
    for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
      sessionStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex);
      localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
    }

    localStorage.removeItem('smv_product_material_type');
    localStorage.removeItem('smv_product_material_grade');
    localStorage.removeItem('smv_product_material_shape');
    localStorage.removeItem('smv_product_type');
    localStorage.removeItem('smv_product_material_typeName');
    localStorage.removeItem('smv_product_material_gradeName');
    localStorage.removeItem('smv_product_material_shapeName');
    localStorage.removeItem('smv_product_typeName');

  }
  const categoryDisplay = () => {
    const catCount = sessionStorage.getItem('compRMCatCount')
    for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
      $('.cat' + catCountIndex + 'Id').show();
    }
  }
  let requiredCols = ['product_id', 'product_name', 'product_tech_spect', 'product_material_code', 'product_std_weight', 'product_unit_name', 'indent_quantity', 'indent_weight',
    "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
    "approval_remark", "indent_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
    "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight",
    "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time',
  ];

  const viewSizingFilterForm = async () => {
    $('#error_cmb_indent_type').hide();
    sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
    setShowSZFilterForm(true);

  }

  const handleCloseSizingFilterForm = () => {
    debugger
    setShowSZFilterForm(false)
    const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
    if (getData.length !== 0) {
      setSizingfilteredData((prevArray) => {
        const updatedArray = [
          ...prevArray,
          ...getData.filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id)).map((item) => {
            return {
              ...item,

              sizing_order_no: txt_order_no
            };
          })
        ];
        sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
        return updatedArray;
      });

      sessionStorage.setItem('isComboFilterExist', false)

      var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))
      console.log('LocalArr: ', LocalArr)
      if (LocalArr != null) {
        for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
          localStorage.removeItem(LocalArr[localArrIndex])
        }
      }
    }
    removeSessions();
    setTimeout(() => { categoryDisplay(); }, 300);
  };

  const handleInputChange = (indentItem, e) => {
    debugger
    const updatedValue = e.target.value;
    const updatedBomfilteredData = sizingfilteredData.map(item => {
      if (item.product_id === indentItem.product_id) {
        return { ...item, product_material_approved_quantity: updatedValue };
      }
      return item;
    });
    setSizingfilteredData(updatedBomfilteredData);
  };

  // fn to remove material
  const removeMaterial = (row, e) => {
    debugger
    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
      const detailData = [...sizingfilteredData]
      const findElementIndex = detailData.findIndex(record => record.product_id === row.product_id);
      if (findElementIndex !== -1) { detailData.splice(findElementIndex, 1); }
      setSizingfilteredData(detailData);
      sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));

    }
  }

  const renderDetailsTable = useMemo(() => {
    return <>
      <div style={{ overflowX: "hidden", flex: 0 }}>
        <Table className="erp_table erp_table_scroll" style={{ height: "145px" }} responsive bordered striped>
          <thead className="erp_table_head">
            <tr>
              <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>Actions</th>
              <th className="erp_table_th" >Sr.no</th>
              <th className="erp_table_th" >Material Code</th>
              <th className="erp_table_th" >Material Name</th>
              <th className="erp_table_th" >Material Tech Specification</th>
              <th className="erp_table_th" >Material Unit</th>
              <th className="erp_table_th" >Quantity</th>
            </tr>
          </thead>
          <tbody>
            {sizingfilteredData.map((indentItem, Index) =>
              <tr rowindex={Index} className="sticky-column">
                <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`} >
                  <MDTypography className="erp-view-btn">
                    <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => removeMaterial(indentItem, e)} />
                  </MDTypography>

                </td>
                <td className="erp_table_td text-end sticky-column" >{Index + 1}</td>
                <td className="erp_table_td "  >{indentItem.product_material_code}</td>

                <td className={`erp_table_td sticky-column`} >{indentItem.product_name}</td>
                <td className="erp_table_td "  >{indentItem.product_tech_spect}</td>

                <td className="erp_table_td "  >{indentItem.product_unit_name}</td>

                <td className="erp_table_td" style={{ width: '15%' }}>
                  <input
                    type="text" id={`product_material_approved_quantity_${indentItem.product_id}`}
                    className="erp_input_field mb-0 approveField text-end"
                    value={indentItem.product_material_approved_quantity}
                    onChange={(e) => handleInputChange(indentItem, e)}
                    Headers="product_material_approved_quantity"
                    disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                  />
                </td>


              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  }, [sizingfilteredData])

  $(document).on('click', '.erp_input_field.remove0', function () {
    if ($(this).val() === "0") {
      $(this).val("")
    }
  });
  $(document).on('mouseup mousedown', function (e) {
    let inputBoxes = $(".erp_input_field.remove0");
    inputBoxes.each(function () {
      if ($(this).val() === "") {
        $(this).val("0");
      }
    });
  });


  const validateProdQty = async () => {
    debugger
    if (sizingfilteredData.length > 0) {
      const isQuantityNonZero = !sizingfilteredData.find(item => item.product_material_approved_quantity === 0);


      resetGlobalQuery();
      globalQuery.columns.push("product_material_id");
      globalQuery.table = "xt_weaving_production_material"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "production_order_no", operator: "=", value: txt_order_no });
      const checkduplicateMaterial = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      console.log('checkduplicateMaterial', checkduplicateMaterial);
      // Extract product IDs from checkduplicateMaterial
      const duplicateProductIds = checkduplicateMaterial.map(item => item.product_material_id);
      // Filter sizingfilteredData based on product IDs
      const commonMaterials = sizingfilteredData.filter(item => duplicateProductIds.includes(item.product_id));
      // let dulicateMaterialArray = checkduplicateMaterial.map(item => {
      //   return {
      //     ...item,
      //     product_id: item.product_material_id,
      //   };
      // });

      // const commonMaterials = sizingfilteredData.filter(item => dulicateMaterialArray.includes(item.product_id));
      // let existingProductmaterial = checkduplicateMaterial.find((item) => item.product_material_id === sizingfilteredData[0].product_id);
      if (!isQuantityNonZero) {
        setErrMsg('product material quantity cannot be zero...!');
        setShowErrorMsgModal(true);
        return false;
      } else if (commonMaterials.length > 0) {
        setErrMsg('product material already exist...!');
        setShowErrorMsgModal(true);
        return false;
      }
      //  else if (!existingProductmaterial !== '') {
      //   setErrMsg('product material already exist...!');
      //   setShowErrorMsgModal(true);
      // }
      else {
        return true;
      }
    } else {
      setErrMsg('Please enter at least one production material...!');
      setShowErrorMsgModal(true);
      return false;
    }
  };


  const addProductionmaterial = async () => {
    debugger
    try {
      setIsLoading(true)
      let checkValidProdQty = false;
      checkValidProdQty = await validateProdQty()
      const json = { 'WVProductMaterialData': [], 'commonIds': {} }

      if (checkValidProdQty === true) {

        for (let index = 0; index < sizingfilteredData.length; index++) {
          const element = sizingfilteredData[index];
          const addSizingProductionData = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            production_order_material_id: 0,
            weaving_production_order_id: txt_MasterProduction_id,
            production_sub_section_id: element.production_sub_section_id,
            product_material_id: element.product_id,
            product_material_unit_id: element.product_unit_id,
            production_order_no: txt_order_no,
            production_order_date: dt_productionmaterialDate,
            production_sub_section_id: txt_Sub_Section_id,
            set_no: txt_set_no,
            product_material_quantity: element.product_material_approved_quantity,
            created_by: UserName
          }
          json.WVProductMaterialData.push(addSizingProductionData)
        }
        json.commonIds.company_id = COMPANY_ID

        console.log('sizing production Details', json);

        const formData = new FormData()
        formData.append('WeavingProductionMaterialData', JSON.stringify(json))

        const forwardData = {
          method: 'POST',
          body: formData,
        }
        const ProductionMaterialApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionOrder/FnAddMaterialRecord`, forwardData)
        const response = await ProductionMaterialApiCall.json();

        if (response.success === '0') {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = await comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(response.message)
          setShowSuccessMsgModal(true)
        }
      }
    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>

      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <ComboBox ref={comboDataAPiCall} />
      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span>Loading...</span>
          </div>
        </div> :
        ''}
      <div className='erp_top_Form'>
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Add Production Material </label>
          </div>
          <div className="row mb-3 mt-2">
            <div className="col-md-4">
              {!(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') &&
                <SearchItemFilter getSearchData={handleCloseSizingFilterForm} formIdForValidate="" requiredCols={requiredCols} />
              }
            </div>
            <div className="col-md-2">
              <MDButton onClick={viewSizingFilterForm} className={`erp-gb-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">
                Add Materials
              </MDButton>
            </div>

            <div className="col">
              <Form.Label className="erp-form-label">Order No<span className="required">*</span> </Form.Label>
            </div>
            <div className="col">
              <Form.Control type="text" id="txt_order_no" className="erp_input_field" value={txt_order_no} disabled />
              <MDTypography variant="button" id="error_txt_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
              </MDTypography>
            </div>

          </div>

          <div className="mt-10">
            {sizingfilteredData.length !== 0 ?
              <>
                {renderDetailsTable}
              </> : ""
            }
          </div>
          <div className="erp_frm_Btns ">
            <MDButton type="submit" onClick={addProductionmaterial} className={`erp-gb-button ms-2 ${sizingfilteredData.length !== 0 ? 'display' : 'd-none'}`} variant="button" fontWeight="regular">Save </MDButton>
          </div >

          <Modal size="lg" className='erp_document_Form' show={showSZFilterForm} onHide={handleCloseSizingFilterForm} backdrop="static" keyboard={false} centered>
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>Product List</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSizingFilterForm}></button></span>
            </Modal.Header>
            <Modal.Body>
              <React.Fragment>
                <ProductListProductTypeWise closeModal={handleCloseSizingFilterForm}
                  props={{ product_type_id: cmb_indent_type }}
                />
              </React.Fragment>
            </Modal.Body>
          </Modal>
          <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
          <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
        </div >
      </div >
    </>

  )
}
