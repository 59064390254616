import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import html2pdf from 'html2pdf.js';


// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";

// import valid formate
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { FiPrinter } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import Enquiry from "FrmGeneric/Invoice/EnquiryInvoice";

//import Branch from "Masters/MCompany/FrmCompany/components/FrmMBranchEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import { CircularProgress, Tooltip } from "@material-ui/core";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ConfigConstants from "assets/Constants/config-constant";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import ProductList from "FrmGeneric/ProductList";
import MailSentModal from "components/Modals/MailSentModal";
import { RxCrossCircled } from "react-icons/rx";
import Datatable from "components/DataTable";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";

//FrmEnquiryTradingEntry
export default function FrmEnquiryTradingEntry(prop) {
    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    let docGroup = "Trading Enquiry"
    let commonTermMstName = 'Enquiry Commercial Terms'

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType, deletionKey } = state || {};

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    var updateData = idList;
    const [enquiry_master_transaction_id, setEnquiryMasterTransactionId] = useState(0);

    //combo options
    const [customerOptions, setCustomerOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [enquiryByIdOptions, setEnquiryByIdOptions] = useState([])
    const [enquirytypeOptions, setEnquiryTypeOptions] = useState([])
    const [stateOpts, setStateOpts] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [expectedBranchStateOptions, setExpectedBranchStateOptions] = useState([]);
    const [expectedBranchCityOptions, setExpectedBranchCityOptions] = useState([]);
    const [assignToHead, setAssignToHead] = useState([]);
    const [assignToEmployeeOpts, setAssignToEmployeeOpts] = useState([]);
    const [approvedbyid, setApproved] = useState([]);
    const [agentOpts, setAgeentOpts] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // From am_company_settings.
    const [expected_schedule_grace_days, setExpectedScheduleGraceDays] = useState(0);
    let expectedScheduleGraceDays = 0;

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Transactions/TEnquiryManagement/FrmEnquiryListing`);
        }
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Bom Filter 
    let requiredColsForOpenFilter = ['product_type_name', 'product_material_short_name', 'product_id', 'product_name', 'product_tech_spect',
        'product_unit_name', 'product_packing_name', 'product_moq', 'product_lead_time', 'product_mrp', 'product_std_weight', 'product_type_id', 'product_unit_id', 'product_packing_id',
    ];
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);

    const FnGetExpectedScheduleGraceDate = (afterDays) => {
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);

        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const viewBomFilterForm = async () => {
        let checkIsValidate = await validate.current.validateForm("enquiryFormId");
        if (checkIsValidate === true && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            sessionStorage.setItem('requiredCols', JSON.stringify(requiredColsForOpenFilter));
            setShowBomFilterForm(true);
        }
    }
    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        setShowDocumentForm(false);
        await showDocumentRecords();
    };
    // doc list
    const [docData, setDocumentData] = useState([]);
    const [docColumns, setDocColumns] = useState([]);
    var columnHeads;
    const viewDocumentForm = async () => {
        if (keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }

    // For navigate
    const navigate = useNavigate();

    //Form Fields
    const [txt_enquiry_no, setEnquiryno] = useState(0);
    const [txt_enquiry_version, setEnquiryVersion] = useState(1);
    // const [cmb_enquiry_type_id, setEnquiryTypeId] = useState("");    // Hardcoded company type id from am_properties.
    // const [enquiry_type, setEnquiryType] = useState('');
    const [cmb_enquiry_type_id, setEnquiryTypeId] = useState("");       // For ProductTypeBased.
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_department, setDepartmentId] = useState('');
    const [cmb_enquiry_by, setEnquiryById] = useState('');
    const [cmb_enquiry_status, setEnquiryStatus] = useState('P');
    const [txt_status_remark, setStatusremark] = useState('');
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [chk_is_active, setIsActive] = useState('true');
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [cmb_expected_branch_id, setExpected_branch_id] = useState();
    const [cmb_expected_branch_state_id, setExpected_branch_state_id] = useState("")
    const [cmb_expected_branch_city_id, setExpected_branch_city_id] = useState("")
    const [rb_enquiry_life, setEnquiryOrdLife] = useState('C');
    const [cmb_assign_to_head_id, setAssigntoHeadId] = useState('');
    const [cmb_assign_to_id, setAssigToId] = useState('');
    const [cmb_approved_by_id, setApprovedById] = useState('');
    const [cmb_agent_id, setAgentId] = useState('');
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [rb_enquiry_mail_sent_status, setEnquiryMailSentStatus] = useState('P');
    const [productUnitOpts, setProductUnitOpts] = useState([]);
    const [productPackingOpts, setProductPackingOpts] = useState([]);

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [txt_enquiry_date, setEnquiryDate] = useState(today);
    const [dt_approved_date, setApprovedDate] = useState('');

    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState(today);
    // Enquiryterms Table Data
    const [EnquiryTermsdata, setEnquiryTermsdata] = useState([]);
    const [enqTermsResponse, setEnqTermsResponse] = useState([]);

    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);

    //this  hook is used for thesxt the fild nodaes 
    const [bomfilteredData, setBomfilteredData] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];

    //Enquiry Functionality Details Table Hooks
    const [functionalityrowcount, setFunctionalityrowcount] = useState(1);
    var existingFunctionRowCount = 1;

    const [showAddRecEnquiryModal, setShowAddRecEnquiryModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    const closeRecordAddEnquiryModal = async () => {
        switch (modalHeaderName) {
            case 'Enquiry Type':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                const enquiryTYpeApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setEnquiryTypeOptions(enquiryTYpeApiCall)
                break;

            case 'Add Customer City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getCustomerUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustomerCityOpts(getCustomerUpdatedCities);
                break;

            case 'Add Department':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedDepartments = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setDepartmentIdOptions(getUpdatedDepartments);
                break;

            case 'Enquiry By':
                comboOnChange('departmentId')
                break;

            case 'Department':
                const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0")
                setDepartmentIdOptions(departmentIdApiCall)
                break;

            case 'Common Parameter':
                await FnGetDefaultEnquiryTerms();
                break;

            default:
                break;
        }
        setShowAddRecEnquiryModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
    }

    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldsByUserAccess();
            await fillComobos();
            await removeSessions();
            if (updateData !== null) {
                await FnCheckUpdateResponce();
            }
            // else {
            //     await fillAutoNo(enquiry_type);
            // }
            FnSetFieldsByUserAccess();
        } catch (error) {
            console.error(error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }, [])

    const updateEnquiryCommonTermsTblData = (row, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        row[clickedColName] = event.target.value;
        if (event._reactName === 'onBlur') {
            row[clickedColName] = event.target.value.trim();
        }
        let termsData = [...EnquiryTermsdata];
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        delete event.target.parentElement.dataset.tip;
        termsData[arrayIndex] = row
        setEnquiryTermsdata(termsData);
    }

    const fillComobos = async () => {
        try {
            FnSetFieldsByUserAccess();

            resetGlobalQuery();
            globalQuery.columns.push('so_schedule_grace_days');
            globalQuery.table = "am_company_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getScheduleGraceDaysApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            expectedScheduleGraceDays = getScheduleGraceDaysApiCall.length === 0 ? 0 : getScheduleGraceDaysApiCall[0].so_schedule_grace_days;
            setExpectedScheduleGraceDays(expectedScheduleGraceDays)
            setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // const enquiryTYpeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            // setEnquiryTypeOptions(enquiryTYpeApiCall)
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(productTypeList => {
                    setEnquiryTypeOptions(productTypeList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'is_sez'];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setCustomerOptions(getCustomersApiCall);
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(customersList => {
                    setCustomerOptions(customersList);
                });

            // const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
            // setDepartmentIdOptions(departmentIdApiCall)
            // // Set the default Department-option.
            // const defaultDepartment = departmentIdApiCall.find(department => department.field_name === "Marketing & Proposals Department");
            // if (defaultDepartment) { // Set the default department.
            //     setDepartmentId(defaultDepartment.field_id);
            //     // Load the enquiry-by list.
            //     resetGlobalQuery();
            //     globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            //     globalQuery.table = "cmv_employee"
            //     globalQuery.conditions.push({ field: "department_id", operator: "=", value: defaultDepartment.field_id });
            //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            //     const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            //     setEnquiryByIdOptions(enquirybyIdApiCall)
            // }
            comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0")
                .then(departmentList => {
                    setDepartmentIdOptions(departmentList);
                    // const defaultDepartment = departmentList.find(department => department.field_name === "Marketing & Proposals Department");
                    const defaultDepartment = departmentList.find(department => department.field_name === "Marketing & Exports Department");
                    if (defaultDepartment) { // Set the default department.
                        setDepartmentId(defaultDepartment.field_id);
                        // Load the enquiry-by list.
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                        globalQuery.table = "cmv_employee"
                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: defaultDepartment.field_id });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                            .then(marketingDeptEmpls => {
                                setEnquiryByIdOptions(marketingDeptEmpls);
                            })
                    }
                });

            // const stateApiCall = await comboDataAPiCall.current.fillMasterData("cmv_state", "is_delete", "0");
            // setStateOpts(stateApiCall);
            // setExpectedBranchStateOptions(stateApiCall);
            comboDataAPiCall.current.fillMasterData("cmv_state", "is_delete", "0")
                .then(statesList => {
                    setStateOpts(statesList);
                    setExpectedBranchStateOptions(statesList);
                })

            resetGlobalQuery();
            globalQuery.columns = ['company_branch_id', 'company_branch_name', 'branch_state_id', 'state_name', 'branch_city_id', 'city_name', 'is_sez', 'branch_EmailId'];
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setExpectedBranchOptions(exptBranchApiCall);
            if (exptBranchApiCall !== null) {
                setExpected_branch_id(COMPANY_BRANCH_ID);
                const loggedInCompanyBranchObj = exptBranchApiCall.find(obj => obj.company_branch_id === parseInt(COMPANY_BRANCH_ID));
                setExpected_branch_state_id(loggedInCompanyBranchObj.state_name);
                $('#cmb_expected_branch_state_id').attr('state_id', loggedInCompanyBranchObj.branch_state_id);
                setExpected_branch_city_id(loggedInCompanyBranchObj.city_name)
                $('#cmb_expected_branch_city_id').attr('city_id', loggedInCompanyBranchObj.branch_city_id);
            }

            // This calling not required because we set it already commented by sachin.
            // const extBranchApiCall = await comboDataAPiCall.current.fillMasterData("cmv_company_branch", "is_delete", "0");
            // setExpectedBranchOptions(extBranchApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            // const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setAssignToEmployeeOpts(getEmployeeListApiCall);
            // setAssignToHead(getEmployeeListApiCall);
            // setApproved(getEmployeeListApiCall);
            // // Set the logged in employee as a assigned employee and it's head employee.
            // setAssigToId(UserId);
            // const defaultAssignedEmployee = getEmployeeListApiCall.find(employee => employee.field_id === UserId);
            // if (defaultAssignedEmployee) {   // Set Head Name for Assigned Employee. // reporting_to
            //     setAssigntoHeadId(defaultAssignedEmployee.reporting_to);
            // }
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(employeesList => {
                    setAssignToEmployeeOpts(employeesList);
                    setAssignToHead(employeesList);
                    setApproved(employeesList);
                    // Set the logged in employee as a assigned employee and it's head employee.
                    setAssigToId(UserId);
                    const defaultAssignedEmployee = employeesList.find(employee => employee.field_id === parseInt(UserId));
                    if (defaultAssignedEmployee) {   // Set Head Name for Assigned Employee. // reporting_to
                        setAssigntoHeadId(defaultAssignedEmployee.reporting_to);
                    }
                })


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
            globalQuery.table = "cmv_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            // const getAgentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setAgeentOpts(getAgentApiCall);
            // // Set the Default Agent as NA and load it's releated %.
            // const defaultAgent = getAgentApiCall.find(agent => agent.field_name === 'NA');
            // if (defaultAgent) {   // Set Head Name for Assigned Employee. // reporting_to
            //     setAgentId(defaultAgent.field_id);
            //     setAgentPercent(defaultAgent.agent_std_percent);
            // }
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(agentsList => {
                    setAgeentOpts(agentsList);
                    // Set the Default Agent as NA and load it's releated %.
                    const defaultAgent = agentsList.find(agent => agent.field_name === 'NA');
                    if (defaultAgent) {  
                        setAgentId(defaultAgent.field_id);
                        setAgentPercent(defaultAgent.agent_std_percent);
                    }
                })

            // const getProductUnitsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit ", "is_delete", "0");
            // setProductUnitOpts(getProductUnitsApiCall);
            comboDataAPiCall.current.fillMasterData("smv_product_unit", "is_delete", "0")
                .then(unitList => {
                    setProductUnitOpts(unitList);
                })

            // const getProductPackingsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_packing ", "is_delete", "0");
            // setProductPackingOpts(getProductPackingsApiCall);
            comboDataAPiCall.current.fillMasterData("smv_product_packing", "is_delete", "0")
                .then(packingsList => {
                    setProductPackingOpts(packingsList);
                })


        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnSetFieldsByUserAccess = () => {
        let userAccessKey = keyForViewUpdate;
        setIsApprove(userAccessKey === 'approve' ? true : false);
        switch (userAccessKey) {
            case 'update':
                $('#submain_heading').append('');
                $('#submain_heading').text('(Modification)');
                $('#cmb_approved_by_id').attr('disabled', true);
                $('#dt_approved_date').attr('disabled', true);
                $('#cmb_enquiry_type_id').attr('disabled', true);
                $('#cmb_customer_id').attr('disabled', true);
                $('#cmb_expected_branch_id').attr('disabled', true);
                $('#save-btn').text('Update');
                $('#save-btn').attr('BtnType', 'update');
                break;

            case 'view':
                $('#submain_heading').append('');
                if (deletionKey === true) {
                    $('#submain_heading').text('(Deletion)')
                } else {
                    $('#submain_heading').text('(View)')
                }
                setIsApprove(false)
                $("input[type=radio]").attr('disabled', true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('#save-btn').attr('disabled', true);
                $('#import-btn').attr('disabled', true);
                $('#export-btn').attr('disabled', true);
                $('#import-btn').attr('disabled', true);
                $('#txt_schedule_date').attr('disabled', true);
                $('#viewdocument-id').attr('disabled', true);
                $('#chkAllEnqTerms').attr('disabled', true);
                $('#selectAllCustContact').attr('disabled', true);
                $('#cmb_approved_by_id').attr('disabled', true);
                $("input[name=checkEnquiryTerms]").attr('disabled', true);
                let count = 1;
                $(`#existingfunctionality-${count}`).attr('disabled', true);
                $(`#expectedfunctionality-${count}`).attr('disabled', true);
                $(`#expectedvalue-${count}`).attr('disabled', true);
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                $('#uploadDocBtn').attr('disabled', 'disabled');
                break;

            case 'approve':
                setIsApprove(true)
                $('#submain_heading').append('');
                $('#submain_heading').text('(Approval)');
                $('#save-btn').text('Approve');
                $('#save-btn').attr('BtnType', 'approve');
                $('#import-btn').attr('disabled', true);
                $('#export-btn').attr('disabled', true);
                $('#import-btn').attr('disabled', true);
                $('#viewdocument-id').attr('disabled', true);
                $('#chkAllEnqTerms').attr('disabled', true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                $('#selectAllCustContact').attr('disabled', true);
                $("input[type=radio]").attr('disabled', true);
                $("input[name=checkEnquiryTerms]").attr('disabled', true);
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                let countno = 1;
                $(`#existingfunctionality-${countno}`).attr('disabled', true);
                $(`#expectedfunctionality-${countno}`).attr('disabled', true);
                $(`#expectedvalue-${countno}`).attr('disabled', true);
                setApprovedDate(today);
                $('#txt_status_remark').removeAttr("disabled");
                $('#txt_status_remark').removeAttr("readonly");
                $('#uploadDocBtn').attr('disabled', 'disabled');
                break;

            case 'copy':
                $('#submain_heading').append('');
                $('#submain_heading').text('(Copy)');
                $('#cmb_approved_by_id').attr('disabled', true);
                $('#dt_approved_date').attr('disabled', true);
                $('#cmb_enquiry_type_id').attr('disabled', true);
                $('#save-btn').text('Save');
                $('#save-btn').attr('BtnType', 'copy');
                break;

            default:
                break;
        }
    }


    const FnCheckUpdateResponce = async () => {
        try {
            debugger
            let todayDateForInputField = today;
            let defaultExpecScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);

            let enquiry_no;
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryDetailsTrading/FnShowAllDetailsAndMastermodelRecords/${updateData.enquiry_version}/${updateData.financial_year}/${COMPANY_ID}?enquiry_no=${updateData.enquiry_no}`)
            const responce = await apiCall.json();

            let newEnquiryNoForCopiedEnquiry;
            if (responce.EnquiryMasterRecord !== null) {
                let enquiryMasterData = responce.EnquiryMasterRecord;
                if (enquiryMasterData !== null && enquiryMasterData !== "") {
                    setEnquiryMasterTransactionId(enquiryMasterData.enquiry_master_transaction_id)
                    setEnquiryno(enquiryMasterData.enquiry_no);
                    setEnquiryVersion(enquiryMasterData.enquiry_version);
                    setEnquiryTypeId(enquiryMasterData.enquiry_type_id);
                    setCustomerId(enquiryMasterData.customer_id);

                    // Load the customer state and city.   
                    let custContactData = await FnGetCustomersContactPersonsDetails(enquiryMasterData.customer_id);
                    // const customerContactPersonsIds = enquiryMasterData.customer_contacts_ids.split(':');
                    const customerContactPersonsIds = enquiryMasterData.customer_contacts_ids.split(":").map(Number);
                    // Set the checked checkboxes first.
                    const rearrangedContactsRows = [
                        ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                        ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                    ];
                    setCustContactDetails(rearrangedContactsRows);

                    setCustomerState(enquiryMasterData.customer_state_name);
                    $('#cmb_customer_state_id').attr('state_id', enquiryMasterData.customer_state_id);
                    setCustomerCity(enquiryMasterData.customer_city_name);
                    $('#cmb_customer_city_id').attr('city_id', enquiryMasterData.customer_city_id);
                    await FnSetExistingCustomerContactPersonIds(enquiryMasterData.customer_contacts_ids);

                    setEnquiryDate(enquiryMasterData.enquiry_date);
                    setApprovedDate(enquiryMasterData.approved_date);
                    setOverAllScheduleDate(enquiryMasterData.overall_schedule_date);
                    setDepartmentId(enquiryMasterData.department_id);
                    await comboOnChange('departmentId');
                    setEnquiryById(enquiryMasterData.enquiry_by_id);
                    if (keyForViewUpdate === 'approve') {
                        setEnquiryStatus('A');
                    } else {
                        setEnquiryStatus(enquiryMasterData.enquiry_status);
                    }
                    setStatusremark(enquiryMasterData.status_remark);
                    setOtherTermsConditions(enquiryMasterData.other_terms_conditions);
                    setRemark(enquiryMasterData.remark);
                    setIsActive(enquiryMasterData.is_active.toString());
                    setEnquiryMailSentStatus(enquiryMasterData.enquiry_mail_sent_status);

                    setExpected_branch_id(enquiryMasterData.expected_branch_id);
                    setExpected_branch_state_id(enquiryMasterData.expected_branch_state_name);
                    $('#cmb_expected_branch_state_id').attr('state_id', enquiryMasterData.expected_branch_state_id);
                    setExpected_branch_city_id(enquiryMasterData.expected_branch_city_name);
                    $('#cmb_expected_branch_city_id').attr('city_id', enquiryMasterData.expected_branch_city_id);

                    setEnquiryOrdLife(enquiryMasterData.enquiry_life);
                    setAssigntoHeadId(enquiryMasterData.assign_to_head_id);
                    setAssigToId(enquiryMasterData.assign_to_id);

                    if (keyForViewUpdate === 'approve' && (enquiryMasterData.approved_by_id === 0 || enquiryMasterData.approved_by_id === null)) {
                        setApprovedById(parseInt(UserId));
                    } else {
                        setApprovedById(enquiryMasterData.approved_by_id);
                    }
                    setAgentId(enquiryMasterData.agent_id);
                    setAgentPercent(enquiryMasterData.agent_percent);

                    if (keyForViewUpdate === 'copy') {
                        let enquiryTypeShortName = $('#cmb_enquiry_type_id option:selected').attr('shortName');
                        // newEnquiryNoForCopiedEnquiry = await fillAutoNo(enquiry_type);           // For without product_type based.
                        newEnquiryNoForCopiedEnquiry = await fillAutoNo(enquiryTypeShortName);     
                        

                        setEnquiryMasterTransactionId(0);
                        setEnquiryDate(todayDateForInputField);
                        setEnquiryStatus('P');
                        setOverAllScheduleDate(defaultExpecScheduleDate);
                        setApprovedById('');
                        setApprovedDate('');
                        setStatusremark('');
                        setEnquiryVersion(1);
                        setEnquiryMailSentStatus('P');
                    }

                    // Function to update enquiry Terms
                    if (responce.EnquiryTermsTradingPOTerms !== null) {
                        setEnqTermsResponse(responce.EnquiryTermsTradingPOTerms);
                        responce.EnquiryTermsTradingPOTerms.forEach(function (existingCommonTerm) {
                            $('#checkEnquiryTerms_' + existingCommonTerm.enquiry_terms_parameters_id).prop('checked', true);
                        });
                    }

                    // EnquiryExistingExpectedFunctionalityData update
                    let enquiryExpectedFunctionalityData = responce.EnquiryExistingExpectedFunctionality;
                    if (enquiryExpectedFunctionalityData.length > 0) {
                        let count = 1;
                        for (let idCounter = 0; idCounter < enquiryExpectedFunctionalityData.length; idCounter++) {
                            let EnquiryExistingFun = enquiryExpectedFunctionalityData[idCounter].enquiry_existing_functionality
                            let EnquiryExpectedFun = enquiryExpectedFunctionalityData[idCounter].enquiry_expected_functionality
                            let EnquiryExpectedFunvalue = enquiryExpectedFunctionalityData[idCounter].enquiry_expected_value
                            if (idCounter === 0) {
                                $(`#existingfunctionality-${count}`).val(EnquiryExistingFun);
                                $(`#expectedfunctionality-${count}`).val(EnquiryExpectedFun);
                                $(`#expectedvalue-${count}`).val(EnquiryExpectedFunvalue);
                            } else {
                                addfunctionalityTblRow(count)
                                $(`#existingfunctionality-${count}`).val(EnquiryExistingFun);
                                $(`#expectedfunctionality-${count}`).val(EnquiryExpectedFun);
                                $(`#expectedvalue-${count}`).val(EnquiryExpectedFunvalue);
                            }
                            count++;
                        }
                        setFunctionalityrowcount(count)
                    }
                    // Function to update keys dynamically
                    if (responce.EnquiryDetailsRecords !== null) {
                        let updatedBomFilteredDataArray = responce.EnquiryDetailsRecords;

                        if (keyForViewUpdate === 'copy') {
                            // Update the data for copied sales order.
                            updatedBomFilteredDataArray = updatedBomFilteredDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                product_id : ordDetail.product_material_id,
                                enquiry_item_status: 'P',
                                enquiry_master_transaction_id: 0,
                                enquiry_details_transaction_id: 0,
                                enquiry_no: enquiry_no,
                                material_enquiry_approval_remark: '',
                                enquiry_date: todayDateForInputField,
                                enquiry_version: 1,
                                material_schedule_date: defaultExpecScheduleDate,
                            }));
                        } else if (keyForViewUpdate === 'approve') {
                            updatedBomFilteredDataArray = updatedBomFilteredDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                product_id : ordDetail.product_material_id,
                                enquiry_item_status: ordDetail.enquiry_item_status === 'P'
                                    ? "A"
                                    : ordDetail.enquiry_item_status,
                            }));
                        }else {
                            updatedBomFilteredDataArray = updatedBomFilteredDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                product_id : ordDetail.product_material_id,
                            }));
                        }
                        setBomfilteredData(updatedBomFilteredDataArray);
                        sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedBomFilteredDataArray));
                    }
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // For update the combobox data after add new record.
    const FnUpdateComboBoxData = async (key) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
            return false;
        }
        switch (key) {
            case 'Customer':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez']
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setCustomerOptions(getUpdatedCustomerData);
                break;

            case 'EnquiryBy':
                // Load the enquiry-by list.
                if (cmb_department !== '' && cmb_department !== '0') {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const enquirybyIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    setEnquiryByIdOptions(enquirybyIdApiCall)
                }
                break;

            case 'AssignToHead':
            case 'AssignToemployee':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAssignToHeadData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setAssignToHead(getUpdatedAssignToHeadData);
                setAssignToEmployeeOpts(getUpdatedAssignToHeadData);
                break;

            case 'Agent':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
                globalQuery.table = "cmv_agent"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAgentData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setAgeentOpts(getUpdatedAgentData);
                break;

            default:
                break;
        }
    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecEnquiryModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEnquiryByIdOptions(enquirybyIdApiCall)
                    if (enquirybyIdApiCall.length <= 0) {
                        setEnquiryById('');
                    }
                    $('#error_cmb_department').hide();
                } else {
                    setEnquiryByIdOptions([])
                    setEnquiryById('');
                }
                break;

            case 'AssignToHead':
                var assignToheadVal = document.getElementById('cmb_assign_to_head_id').value;
                if (assignToheadVal === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'AssignToemployee':
                var assignToIdVal = document.getElementById('cmb_assign_to_id').value;
                if (assignToIdVal === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (assignToIdVal !== "0" && assignToIdVal !== "") {
                    // Set the it's head-name.
                    const assignedEmployee = assignToEmployeeOpts.find(employee => parseInt(employee.field_id) === parseInt(assignToIdVal));
                    if (assignedEmployee) { // Set Head Name for Assigned Employee. // reporting_to
                        setAssigntoHeadId(assignedEmployee.reporting_to);
                    } else {
                        setAssigntoHeadId('');
                    }
                } else {
                    setAssigntoHeadId('');
                }
                break;

            case 'Agent':
                let selectedAgent = $('#cmb_agent_id').val();
                if ($('#cmb_agent_id').val() === "0") {
                    const newTab = window.open('/Masters/Agent', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (selectedAgent !== '0' && selectedAgent !== '') {
                    const selectedAgentData = agentOpts.find(agent => parseInt(agent.field_id) === parseInt(selectedAgent));
                    if (selectedAgentData) {
                        setAgentPercent(selectedAgentData.agent_std_percent);
                    } else {
                        setAgentPercent(0);
                    }
                } else {
                    setAgentPercent(0);
                }
                break;

            case 'enquiryById':
                var enquiryBYIdVal = document.getElementById('cmb_enquiry_by').value;
                if (enquiryBYIdVal === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'enquiryTypeId':
                // var enquiryTypeIdval = $('#cmb_enquiry_type_id').val();
                // setCompanyBranchType(enquiryTypeIdval);
                // var companyTypeVal = document.getElementById('cmb_enquiry_type_id').value;
                // if (companyTypeVal === '0') {
                //   sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                //   setHeaderName('Enquiry Type')
                //   setShowAddRecModal(true)
                //   setTimeout(() => {
                //     $(".erp_top_Form").eq(0).css("padding-top", "0px");
                //   }, 100)
                // }
                // if (enquiryTypeIdval !== "") { $('#error_cmb_enquiry_type_id').hide(); }
                // break;

                var enquiryTypeIdval = document.getElementById('cmb_enquiry_type_id').value;
                setEnquiryTypeId(enquiryTypeIdval);
                if (enquiryTypeIdval !== "0" && enquiryTypeIdval !== "") {
                    $('#error_cmb_enquiry_type_id').hide();
                    let enquiryTypeShortName = $('#cmb_enquiry_type_id option:selected').attr('shortName');
                    await fillAutoNo(enquiryTypeShortName);
                }
                else if (enquiryTypeIdval === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Enquiry Type')
                    setShowAddRecEnquiryModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    setEnquiryno("0");
                } else {
                    setEnquiryno("0");
                }
                break;

            case 'CustomerState':
                let customerState = parseInt($('#cmb_customer_state_id').val());
                if ($('#cmb_customer_state_id').val() === '0') {

                } else if (customerState != null && customerState != '') {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name'];
                    globalQuery.table = "cmv_city"
                    globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerCityOpts(getCustomerCitiesApiCall);
                }
                else {
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case "expt_branch":
                var exptBrachTpVal = document.getElementById("cmb_expected_branch_id").value;
                if (exptBrachTpVal !== '0' && exptBrachTpVal !== "") {
                    setExpected_branch_id(exptBrachTpVal)
                    // resetGlobalQuery();
                    // globalQuery.columns.push("branch_state_id");
                    // globalQuery.columns.push("branch_city_id");
                    // globalQuery.table = "cm_company_branch";
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    // globalQuery.conditions.push({ field: "company_branch_id", operator: "=", value: exptBrachTpVal, });
                    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                    // const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    // setExpected_branch_state_id(exptBranchApiCall[0].branch_state_id)
                    // const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + exptBranchApiCall[0].branch_state_id + "");
                    // setExpectedBranchCityOptions(cityApiCall);
                    // setExpected_branch_city_id(exptBranchApiCall[0].branch_city_id)

                    resetGlobalQuery();
                    globalQuery.columns = ['branch_state_id', 'state_name', 'branch_city_id', 'city_name'];
                    globalQuery.table = "cmv_company_branch"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "company_branch_id", operator: "=", value: exptBrachTpVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    if (exptBranchApiCall.length > 0) {
                        setExpected_branch_state_id(exptBranchApiCall[0].state_name);
                        $('#cmb_expected_branch_state_id').attr('state_id', exptBranchApiCall[0].branch_state_id);
                        setExpected_branch_city_id(exptBranchApiCall[0].city_name)
                        $('#cmb_expected_branch_city_id').attr('city_id', exptBranchApiCall[0].branch_city_id);
                    }
                } else {
                    setExpected_branch_id('')
                    setExpected_branch_state_id("")
                    setExpected_branch_city_id("")
                }
                break;

            case "expt_branchState":
                var exptBrchStateTpVal = document.getElementById("cmb_expected_branch_state_id").value;
                setExpected_branch_state_id(exptBrchStateTpVal)
                const exptBranchcityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", exptBrchStateTpVal);
                setExpectedBranchCityOptions(exptBranchcityApiCall);
                break;

            case "company_city":
                var companycityTpVal = document.getElementById("cmb_expected_branch_city_id").value;
                if (companycityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Customer City')
                    setShowAddRecEnquiryModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;

            case 'Customer':
                let selectedCustomer = parseInt($('#cmb_customer_id').val());
                if ($('#cmb_customer_id').val() === '0') {
                    // localStorage.removeItem('dataAddedByCombo');
                    // localStorage.removeItem('customerIDs');
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setCustomerId('')
                } else if (selectedCustomer !== "" && selectedCustomer !== NaN) {
                    resetGlobalQuery();
                    globalQuery.columns = ['cust_branch_state_id', 'state_name', 'cust_branch_city_id', 'city_name'];
                    globalQuery.table = "cmv_customer"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    if (getCustomerDetailsApiCall.length > 0) {
                        // // Commented to reduce the api calling. (converted combo to the text-boxes)
                        // setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                        // comboOnChange('CustomerState');
                        // setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                        setCustomerState(getCustomerDetailsApiCall[0].state_name);
                        $('#cmb_customer_state_id').attr('state_id', getCustomerDetailsApiCall[0].cust_branch_state_id);
                        setCustomerCity(getCustomerDetailsApiCall[0].city_name)
                        $('#cmb_customer_city_id').attr('city_id', getCustomerDetailsApiCall[0].cust_branch_city_id);
                    } else {
                        setCustomerState('');
                        setCustomerCity('');
                        // setCustomerCityOpts([]);
                    }
                    await FnGetCustomersContactPersonsDetails(parseInt(selectedCustomer));
                    $('.selectCustContactPerson').prop('checked', false);
                    $('#selectAllCustContact').prop('checked', false);

                } else {
                    setCustomerState('');
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case 'CustomerCity':
                const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "is_delete", "0");
                setExpectedBranchCityOptions(cityApiCall);
                if ($('#cmb_customer_city_id').val() === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Customer City')
                    setShowAddRecEnquiryModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setCustomerCity('');
                }
                break;

            default:
                break;
        }
    }


    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);
                // toggleEnquiryTermsChkBoxes('PartiallyCustContactSelection');
                return getContactDtlsApiCall;
            } else {
                // toggleEnquiryTermsChkBoxes('PartiallyCustContactSelection');
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            toggleEnquiryTermsChkBoxes('PartiallyCustContactSelection');
        });
    }

    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingCustomerContactPersonIds = async (contactIds) => {
        const customerContactPersonsIds = contactIds.split(':');
        customerContactPersonsIds.forEach(function (contactId, index) {
            $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
        });
        toggleEnquiryTermsChkBoxes('PartiallyCustContactSelection')
    }

    const validateFields = () => {
        var formObj = $('#enquiryFormId');
        var inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }
    }

    const addReadOnlyAttr = () => {
        var formObj = $('#enquiryFormId');
        var inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'select-one') {
                $('#' + inputObj.id).attr('disabled', true);
            } else if (inputObj.type === 'file') {
                $('#' + inputObj.id).attr('disabled', true);
            } else if (inputObj.type === 'textarea') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'date') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'email') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'number') {
                $('#' + inputObj.id).attr('readonly', true);
            }
        }
    }

    const removeSessions = async () => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }
        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');
        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    const FnDeleteSelectedMaterial = (row, e) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            const detailData = [...bomfilteredData]
            const arrayIndex = detailData.findIndex(item => item.product_material_id === row.product_material_id);
            if (arrayIndex !== -1) { detailData.splice(arrayIndex, 1); }
            setBomfilteredData(detailData);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(detailData));
        }
    }

    const onImportClick = () => {
        importFile.current.click();
    };

    const ExportToExcel = async () => {
        let headerFormIsValid = await validate.current.validateForm("enquiryFormId");

        if (bomfilteredData.length !== 0 && headerFormIsValid) {
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [8] } // , 25 for remark;
            let columnExport = ['sr_no', 'product_type_name', 'product_material_id', 'product_material_name', 'product_material_tech_spect', 'product_material_unit_name',
                'product_packing_name', 'material_schedule_date', 'product_material_enquiry_quantity', 'product_material_moq_quantity', 'expected_lead_time', 'material_std_rate',
                'product_material_notes', 'enquiry_item_status', 'material_enquiry_approval_remark', 'remark', 'product_material_unit_id', 'product_material_packing_id', 'product_type_id', 'product_type_short_name'
            ];

            let filtrKeyValue = {};
            filtrKeyValue['0'] = "Enquiry Type : " + $('#cmb_enquiry_type_id').find(":selected").text() + "(" + cmb_enquiry_type_id + ")"
            // filtrKeyValue['0'] = "Enquiry Type : " + 'Trading' + "(" + enquiry_type + ")";       // For Without product_type based.
            filtrKeyValue['1'] = "Enquiry No : " + txt_enquiry_no
            filtrKeyValue['2'] = "Enquiry Version : " + txt_enquiry_version
            filtrKeyValue['3'] = "Enquiry Date : " + await validateNumberDateInput.current.formatDateToDDMMYYYY(txt_enquiry_date)
            filtrKeyValue['4'] = "Enquiry Life : " + $('input[name="rb_enquiry_life"]:checked').attr("lbl") + "(" + rb_enquiry_life + ")"
            filtrKeyValue['5'] = "Customer : " + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")"
            filtrKeyValue['6'] = "Department : " + (cmb_department === '0' || cmb_department === '' || cmb_department === null ? '' : $('#cmb_department').find(":selected").text() + "(" + cmb_department + ")")
            filtrKeyValue['7'] = "Enquiry By : " + (cmb_enquiry_by === '0' || cmb_enquiry_by === '' || cmb_enquiry_by === null ? '' : $('#cmb_enquiry_by').find(":selected").text() + "(" + cmb_enquiry_by + ")")
            filtrKeyValue['8'] = "Enquiry Status : " + $('#cmb_enquiry_status').find(":selected").text() + "(" + cmb_enquiry_status + ")"
            filtrKeyValue['9'] = "Customer State : " + $('#cmb_customer_state_id').val() + `(${$('#cmb_customer_state_id').val() !== '' ? $('#cmb_customer_state_id').attr('state_id') : ''})`
            filtrKeyValue['10'] = "Customer City : " + $('#cmb_customer_city_id').val() + `(${$('#cmb_customer_city_id').val() !== '' ? $('#cmb_customer_city_id').attr('city_id') : ''})`
            filtrKeyValue['11'] = "Expected Company Branch : " + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")"
            // filtrKeyValue['12'] = "Expected Branch State : " + $('#cmb_expected_branch_state_id').val() + `(${$('#cmb_expected_branch_state_id').val() !== '' ? $('#cmb_expected_branch_state_id').attr('state_id') : ''})`
            // filtrKeyValue['13'] = "Expected Branch City : " + $('#cmb_expected_branch_city_id').val() + `(${$('#cmb_expected_branch_city_id').val() !== '' ? $('#cmb_expected_branch_city_id').attr('city_id') : ''})`
            filtrKeyValue['12'] = "Assign To ID : " + (cmb_assign_to_id === '0' || cmb_assign_to_id === '' || cmb_assign_to_id === null ? '' : $('#cmb_assign_to_id').find(":selected").text() + "(" + cmb_assign_to_id + ")")
            filtrKeyValue['13'] = "Assign To Head : " + (cmb_assign_to_head_id === '0' || cmb_assign_to_head_id === '' || cmb_assign_to_head_id === null ? '' : $('#cmb_assign_to_head_id').find(":selected").text() + "(" + cmb_assign_to_head_id + ")")
            filtrKeyValue['14'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")")
            filtrKeyValue['15'] = "Expected Schedule Date : " + await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date)
            filtrKeyValue['16'] = "Approved Date : " + (dt_approved_date === '0' || dt_approved_date === '' || dt_approved_date === null ? '' : await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_approved_date))
            filtrKeyValue['17'] = "Agent : " + (cmb_agent_id === '0' || cmb_agent_id === '' || cmb_agent_id === null ? '' : $('#cmb_agent_id').find(":selected").text() + "(" + cmb_agent_id + ")")
            filtrKeyValue['18'] = "Agent Percentage  : " + txt_agent_percent
            filtrKeyValue['19'] = "Remark : " + txt_remark
            filtrKeyValue['20'] = 'Other Terms & Conditions : ' + txt_other_terms_conditions;
            filtrKeyValue['21'] = "Is Active : " + $("input[name='chk_is_active']:checked").attr("lbl") + "(" + chk_is_active + ")"
            jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

            // set columns name.
            for (let col = 0; col < columnExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": columnExport[col], "accessor": columnExport[col] })
            }

            bomfilteredData.map((enqValDetail, Index) => {
                enqValDetail.sr_no = Index + 1;
                jsonToExportExcel['allData'][Index] = enqValDetail;
            });

            jsonToExportExcel['headings']['ReportName'] = "Enquiry Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_enquiry_no + "@" + COMPANY_ID)
        } else {
            if (bomfilteredData.length === 0) {
                setErrMsg('Please add at least one material...! ')
                setShowErrorMsgModal(true)
            }
        }
    }

    const importedExcelFile = async (event) => {
        debugger
        setIsLoading(true);
        try {
            if (event.target.files) {
                var file = event.target.files[0];
                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryDetailsTrading/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();
                console.log(fetchRes)
                if (fetchRes.success === '1') {
                    let enquiryNo = fetchRes.formFieldData[6].split(':')[1].trim();
                    let comboNames = ['Enquiry Type', 'Enquiry Life', 'Enquiry Status', 'Customer', 'Customer State', 'Customer City', 'Expected Company Branch', 'Department', 'Enquiry By', 'Assign To Head', 'Assign To ID', 'Agent', 'Is Active']

                    if (fetchRes.data.length !== 0) {
                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                const dateParts = fieldVal.split("-");
                                let comboVal;

                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                    // const splitVal = fieldVal.split('(')
                                    // comboVal = splitVal[1].replace(')', '')
                                }

                                switch (fieldName) {
                                    case 'Enquiry Type': 
                                        setEnquiryTypeId(comboVal);
                                        let currentEnqType = enquirytypeOptions.find((item) => item.field_id === parseInt(comboVal));
                                        await fillAutoNo(currentEnqType.product_type_short_name);
                                        break;
                                    // case 'Enquiry No': setEnquiryno(fieldVal); break;
                                    // case 'Enquiry Version': setEnquiryVersion(fieldVal); break;
                                    // case 'Enquiry Date': setEnquiryDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Enquiry Life': setEnquiryOrdLife(comboVal); break;
                                    // case 'Enquiry Status': setEnquiryStatus(comboVal); break;

                                    case 'Customer':
                                        setCustomerId(comboVal);
                                        await FnGetCustomersContactPersonsDetails(parseInt(comboVal));
                                        break;
                                    case 'Customer State':
                                        setCustomerState(fieldVal.match(/^[^(]+/)[0]);
                                        $('#cmb_customer_state_id').attr('state_id', comboVal);
                                        break;
                                    case 'Customer City':
                                        setCustomerCity(fieldVal.match(/^[^(]+/)[0]);
                                        $('#cmb_customer_city_id').attr('city_id', comboVal);
                                        break;

                                    case 'Expected Company Branch':
                                        setExpected_branch_id(comboVal);
                                        await comboOnChange('expt_branch');
                                        break;
                                    case 'Department':
                                        setDepartmentId(comboVal)
                                        await comboOnChange('departmentId')
                                        break;

                                    case 'Enquiry By': setEnquiryById(comboVal); break;
                                    case 'Assign To Head': setAssigntoHeadId(comboVal); break;
                                    case 'Assign To ID': setAssigToId(comboVal); break;
                                    // case 'Expected Schedule Date': setOverAllScheduleDate(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`); break;
                                    case 'Agent': setAgentId(comboVal); break;
                                    case 'Agent Percentage': setAgentPercent(fieldVal); break;
                                    case 'Other Terms & Conditions': setOtherTermsConditions(fieldVal); break;
                                    case 'Remark': setRemark(fieldVal); break;
                                    case 'Is Active': setIsActive(comboVal); break;
                                }
                            }
                        }

                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            if (isNaN(parseFloat(orderDetail.product_material_enquiry_quantity))) {
                                orderDetail.product_material_enquiry_quantity = 0;
                            }
                            orderDetail.product_id = orderDetail.product_material_id;
                            orderDetail.product_material_print_name = orderDetail.product_material_name;
                            let calculatedWt = parseFloat(orderDetail.product_material_enquiry_quantity) * parseFloat(orderDetail.product_std_weight);
                            orderDetail.product_material_enquiry_weight = validateNumberDateInput.current.decimalNumber(calculatedWt.toString(), 4);
                            return orderDetail;
                        });

                        // setEnquiryVersion(fetchRes.enquiry_version)
                        console.table(readedOrderDetailsFromExcel)
                        sessionStorage.setItem('filteredMaterialData', JSON.stringify(readedOrderDetailsFromExcel))
                        setBomfilteredData(readedOrderDetailsFromExcel)
                        $('.showData').show();
                        setIsLoading(false);
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }
            } else {
                $('.showData').hide()
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val().trim();
        if (keywords !== '') {
            var table = document.getElementById('erp-enquirydetails-table');
            var rows = table.getElementsByTagName('tr');
            for (var trNum = 1; trNum < rows.length; trNum++) {
                var row = rows[trNum];
                if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                    var w = $(window);
                    var row = $('#erp-enquirydetails-table').find('tr').eq(trNum);
                    if (row.length) {
                        w.scrollTop(row.offset().top - (w.height() / 2));
                    }
                    $(row).find('td').each(function () {
                        $(this).addClass('highlight');
                    });
                    removeOtherTrHighlightClass(trNum)
                    break;
                } else {
                    $(row).find('td').each(function () { $(this).removeClass('highlight'); });
                    $("#erp-enquirydetails-table td.highlight").removeClass("highlight");
                }
            }
        } else {
            $("#erp-enquirydetails-table td.highlight").removeClass("highlight");
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('erp-enquirydetails-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const fillAutoNo = async (product_type_short_name) => {
        const ptShortName = enquirytypeOptions.find((item) => item.field_id === parseInt($("#cmb_enquiry_type_id").val()));
        // const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_enquiry_master_trading", "enquiry_no", ptShortName.product_type_short_name, "EQT", "6");
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_enquiry_master_trading", "enquiry_no", product_type_short_name === undefined ? ptShortName.product_type_short_name : product_type_short_name, 'EQ', "6");
        setEnquiryno(autoNoApiCall);
        return autoNoApiCall;
    };

    const addEnquiry = async (e) => {
        try {
            setIsLoading(true)
            let EnquiryIsValid;
            let NewEnquiryNo = '';
            let clickedBtnType = e.target.getAttribute('BtnType')
            if ((clickedBtnType === 'reject' || clickedBtnType === 'approve') && clickedBtnType !== 'save') {
                if (clickedBtnType === 'reject' && $('#txt_status_remark').val().trim() === '') {
                    $('#error_txt_status_remark').text('Please enter the remark.');
                    $('#error_txt_status_remark').show();
                    $('#txt_status_remark').focus();
                    setIsLoading(false)
                    return false;
                } else if (clickedBtnType === 'approve') {
                    EnquiryIsValid = await FnValidateEnquiry();
                } else {
                    EnquiryIsValid = true;
                }
            } else if (clickedBtnType !== 'approve' && clickedBtnType !== 'reject') {
                EnquiryIsValid = await FnValidateEnquiry();
            }
            if (EnquiryIsValid) {
                let custStateId = $('#cmb_customer_state_id').attr('state_id');
                let custCityId = $('#cmb_customer_city_id').attr('city_id');
                let expCompanyBranchStateId = $('#cmb_expected_branch_state_id').attr('state_id');
                let expCompanyBranchCityId = $('#cmb_expected_branch_city_id').attr('city_id');

                if (enquiry_master_transaction_id === 0){
                    // NewEnquiryNo = await fillAutoNo(enquiry_type);       // For without product-type based.
                    let enquiryTypeShortName = $('#cmb_enquiry_type_id option:selected').attr('shortName');
                    NewEnquiryNo = await fillAutoNo(enquiryTypeShortName);
                }

                let checkIsValidate = await validate.current.validateForm("enquiryFormId");
                if (checkIsValidate === true) {
                    let json = { 'EnquiryDetailData': [], 'EnquiryHeaderData': {}, 'EnquiryTermsData': [], 'EnquiryExistingExpectedFunctionalityData': [], 'commonIds': {} }
                    // Define the fields to add
                    const commonFieldsJson = {
                        company_id: parseInt(COMPANY_ID),
                        company_branch_id: parseInt(COMPANY_BRANCH_ID),
                        financial_year: FINANCIAL_SHORT_NAME,
                        enquiry_master_transaction_id: isApprove ? enquiry_master_transaction_id : 0,
                        enquiry_type_id: cmb_enquiry_type_id,
                        // enquiry_type: enquiry_type,      // For without product-type based.
                        enquiry_type : $('#cmb_enquiry_type_id').find(":selected").text(),
                        enquiry_no: enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no,
                        enquiry_date: txt_enquiry_date,
                        enquiry_version: txt_enquiry_version,
                        expected_branch_id: cmb_expected_branch_id,
                        expected_branch_state_id: expCompanyBranchStateId,
                        expected_branch_city_id: expCompanyBranchCityId,
                    };

                    // First create the header section json.
                    let customerContactIds = FnGetCustomerContactPersonIds();
                    const enquiryMasterFormData = {
                        // enquiry_master_transaction_id: isApprove ? enquiry_master_transaction_id : 0,
                        // company_id: COMPANY_ID,
                        // company_branch_id: COMPANY_BRANCH_ID,
                        // financial_year: FINANCIAL_SHORT_NAME,
                        // enquiry_master_transaction_id: 0,
                        // enquiry_no: enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no,
                        // enquiry_version: txt_enquiry_version,
                        // enquiry_type_id: 0,
                        // enquiry_type: cmb_enquiry_type_id,
                        customer_id: cmb_customer_id,
                        // enquiry_date: txt_enquiry_date,
                        department_id: cmb_department,
                        enquiry_by_id: cmb_enquiry_by,
                        enquiry_status: cmb_enquiry_status,
                        status_remark: txt_status_remark,
                        other_terms_conditions: txt_other_terms_conditions,
                        overall_schedule_date: dt_overall_schedule_date,
                        remark: txt_remark,
                        is_active: chk_is_active,
                        customer_state_id: custStateId,
                        customer_city_id: custCityId,
                        expected_branch_id: cmb_expected_branch_id,
                        expected_branch_state_id: expCompanyBranchStateId,
                        expected_branch_city_id: expCompanyBranchCityId,
                        enquiry_life: rb_enquiry_life,
                        assign_to_head_id: cmb_assign_to_head_id,
                        assign_to_id: cmb_assign_to_id,
                        approved_by_id: isApprove ? cmb_approved_by_id : '',
                        approved_date: isApprove ? dt_approved_date : '',
                        created_by: UserName,
                        agent_id: cmb_agent_id,
                        agent_percent: txt_agent_percent,
                        customer_contacts_ids: customerContactIds,
                        enquiry_mail_sent_status: rb_enquiry_mail_sent_status,

                        ...commonFieldsJson,    // set all common fields.
                    }
                    if (isApprove) {
                        enquiryMasterFormData['enquiry_master_transaction_id'] = enquiry_master_transaction_id !== undefined ? enquiry_master_transaction_id : 0;
                        const countOfP = $('#erp-enquirydetails-table tbody tr select[id^="enquiry_item_status_"]').toArray()
                            .filter(selectElement => $(selectElement).val() === 'P')
                            .length;
                        if (clickedBtnType === 'reject') {
                            enquiryMasterFormData.enquiry_status = 'R'
                        } else if (clickedBtnType === 'approve' && countOfP > 0) {
                            enquiryMasterFormData.enquiry_status = 'P'
                        } else if (clickedBtnType === 'approve' && countOfP === 0) {
                            enquiryMasterFormData.enquiry_status = 'A'
                        }
                    }
                    json.EnquiryHeaderData = enquiryMasterFormData;

                    // JSON Objects Array For Details Data.
                    let enquiryMaterialsJSONArray = bomfilteredData?.map((enquiryMaterial, index) => ({
                        ...enquiryMaterial,             // Add all challan-material's existing fields.
                        enquiry_details_transaction_id: isApprove && keyForViewUpdate !== 'copy' ? enquiryMaterial.enquiry_details_transaction_id : 0,
                        sr_no: index + 1,
                        department_id: cmb_department,
                        approved_by_id: isApprove && keyForViewUpdate !== 'copy' ? cmb_approved_by_id : '',
                        approved_date: isApprove && keyForViewUpdate !== 'copy' ? dt_approved_date : '',
                        product_material_conversion_factor: 1,
                        is_active: chk_is_active,
                        created_by: UserName,
                        enquiry_item_status: isApprove && clickedBtnType === 'reject' ? 'R' : enquiryMaterial.enquiry_item_status,

                        ...commonFieldsJson     // set all common fields.

                    }));
                    json.EnquiryDetailData = enquiryMaterialsJSONArray;

                    // EnquiryDetailData
                    // for (let bomDetailIndex = 0; bomDetailIndex < bomfilteredData.length; bomDetailIndex++) {
                    //     var crJson = {}
                    //     const bomfilteredDataElement = bomfilteredData[bomDetailIndex];
                    //     crJson['company_id'] = COMPANY_ID
                    //     crJson['company_branch_id'] = COMPANY_BRANCH_ID
                    //     crJson['enquiry_no'] = enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no
                    //     crJson['enquiry_date'] = txt_enquiry_date
                    //     crJson['financial_year'] = FINANCIAL_SHORT_NAME
                    //     crJson['department_id'] = cmb_department
                    //     crJson['enquiry_type'] = $('#cmb_enquiry_type_id').find(":selected").text()
                    //     crJson['enquiry_type_id'] = document.getElementById("cmb_enquiry_type_id").value
                    //     crJson['enquiry_version'] = parseInt($('#txt_enquiry_version').val())
                    //     crJson['enquiry_master_transaction_id'] = 0
                    //     crJson['sr_no'] = bomDetailIndex + 1
                    //     crJson['product_material_id'] = bomfilteredDataElement.product_material_id
                    //     crJson['product_material_tech_spect'] = bomfilteredDataElement.product_material_tech_spect
                    //     crJson['product_material_packing_id'] = bomfilteredDataElement.product_material_packing_id
                    //     crJson['product_material_moq_quantity'] = bomfilteredDataElement.product_material_moq_quantity
                    //     crJson['product_material_notes'] = bomfilteredDataElement.product_material_notes   //Show technical specification in this
                    //     crJson['product_material_conversion_factor'] = bomfilteredDataElement.product_material_conversion_factor
                    //     crJson['product_material_enquiry_quantity'] = bomfilteredDataElement.product_material_enquiry_quantity
                    //     crJson['product_material_enquiry_weight'] = bomfilteredDataElement.product_material_enquiry_weight
                    //     crJson['product_material_unit_id'] = bomfilteredDataElement.product_material_unit_id
                    //     crJson['expected_lead_time'] = bomfilteredDataElement.expected_lead_time
                    //     crJson['material_std_rate'] = bomfilteredDataElement.material_std_rate
                    //     crJson['material_schedule_date'] = bomfilteredDataElement.material_schedule_date//try
                    //     crJson['enquiry_item_status'] = bomfilteredDataElement.enquiry_item_status
                    //     crJson['approved_by_id'] = isApprove ? cmb_approved_by_id : '';
                    //     crJson['approved_date'] = isApprove ? dt_approved_date : '';
                    //     crJson['material_enquiry_approval_remark'] = bomfilteredDataElement.material_enquiry_approval_remark
                    //     crJson['remark'] = bomfilteredDataElement.remark
                    //     crJson['is_active'] = chk_is_active
                    //     crJson['created_by'] = UserName
                    //     if (isApprove) {
                    //         crJson['enquiry_master_transaction_id'] = bomfilteredDataElement.enquiry_master_transaction_id
                    //         crJson['enquiry_details_transaction_id'] = bomfilteredDataElement.enquiry_details_transaction_id !== undefined ? bomfilteredDataElement.enquiry_details_transaction_id : 0;
                    //         if (clickedBtnType === 'reject') {
                    //             crJson['enquiry_item_status'] = 'R'
                    //         }
                    //     }
                    //     json.EnquiryDetailData.push(crJson)
                    // }
                    // // EnquiryHeaderData

                    //EnquiryTermsData
                    if (!isApprove) {
                        $("input:checkbox[name=checkEnquiryTerms]:checked").each(function () {
                            let findEnquiryTermsData = EnquiryTermsdata.find(item => item.common_parameters_id === parseInt($(this).val()));
                            const EnquiryTermsDataval = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                enquiry_master_transaction_id: 0,
                                enquiry_details_transaction_id: 0,
                                enquiry_terms_transaction_id: 0,
                                enquiry_date: txt_enquiry_date,
                                enquiry_no: enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no,
                                created_by: UserName,
                                enquiry_version: parseInt($('#txt_enquiry_version').val()),
                                enquiry_terms_parameters_id: parseInt($(this).val()),
                                expected_schedule_date: $('#txt_schedule_date').val(),
                                enquiry_terms_parameters_name: findEnquiryTermsData.common_parameters_name,
                                enquiry_terms_parameters_value: findEnquiryTermsData.common_parameters_value,
                                enquiry_terms_parameters_expected_value: $("#common_parameters_expected_value_" + $(this).val()).val() === "" ? findEnquiryTermsData.enquiry_terms_parameters_value : $("#common_parameters_expected_value_" + $(this).val()).val(),
                                remark: findEnquiryTermsData.remark,
                                is_active: chk_is_active,
                            }
                            json.EnquiryTermsData.push(EnquiryTermsDataval);
                        });

                        if (json.EnquiryTermsData.length === 0) {
                            json.EnquiryTermsData = enqTermsResponse.map((enquiry_term) => {
                                enquiry_term.enquiry_no = keyForViewUpdate === 'copy' ? NewEnquiryNo : enquiry_term.enquiry_no;
                                enquiry_term.enquiry_version = keyForViewUpdate === 'copy' ? 1 : enquiry_term.enquiry_version;
                                enquiry_term.enquiry_date = txt_enquiry_date;
                                enquiry_term.enquiry_details_transaction_id = 0;
                                enquiry_term.enquiry_terms_transaction_id = 0
                                return enquiry_term;
                            })
                        }

                        //EnquiryEnquiryExistingExpectedFunctionalityData
                        let table = document.getElementById('ExpectedFunctionality');
                        let tbody = table.querySelector('tbody');
                        let trElements = tbody.querySelectorAll('tr');
                        for (let count = 0; count < trElements.length; count++) {
                            let trElement = trElements[count];
                            let FunctionalityTrCount = trElement.getAttribute('existingFuncTrCount');
                            let existingfunc = $('#existingfunctionality-' + FunctionalityTrCount).val().trim();
                            let expectedfunc = $('#expectedfunctionality-' + FunctionalityTrCount).val().trim();
                            let expectedvalue = $('#expectedvalue-' + FunctionalityTrCount).val().trim();
                            const TContactJsonData = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                enquiry_functionality_transaction_id: 0,
                                enquiry_details_transaction_id: 0,
                                created_by: UserName,
                                enquiry_no: enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no,
                                enquiry_date: txt_enquiry_date,
                                enquiry_version: parseInt($('#txt_enquiry_version').val()),
                                enquiry_existing_functionality: existingfunc || '',
                                enquiry_expected_functionality: expectedfunc || '',
                                enquiry_expected_value: expectedvalue || '',
                                is_active: chk_is_active,
                            };
                            if (existingfunc !== '' && expectedfunc !== '' && expectedvalue !== '') {
                                json.EnquiryExistingExpectedFunctionalityData.push(TContactJsonData);
                            }
                        }
                    }
                    json.commonIds.company_id = COMPANY_ID
                    json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                    json.commonIds.enquiry_no = enquiry_master_transaction_id === 0 ? NewEnquiryNo : txt_enquiry_no
                    json.commonIds.enquiry_version = parseInt($('#txt_enquiry_version').val());
                    json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                    const formData = new FormData();
                    formData.append(`StEnquiryData`, JSON.stringify(json))
                    const requestOptions = {
                        method: 'POST',
                        body: formData
                    };
                    const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryDetailsTrading/FnAddUpdateRecord/${isApprove}`, requestOptions)
                    const responce = await apicall.json();
                    console.log('Save Response: ')
                    console.log(responce)
                    if (responce.success === "1") {
                        const evitCache = comboDataAPiCall.current.evitCache();
                        console.log(evitCache);
                        setSuccMsg(responce.message)
                        if (responce.data.hasOwnProperty('enquiry_version') && responce.data.hasOwnProperty('enquiry_no')) {
                            setModalOrderDetails(`Enquiry No: ${responce.data.enquiry_no} and Version: ${responce.data.enquiry_version}`)
                        }
                        if (isApprove && responce.data.enquiry_status === 'A') {
                            setShowMailSentModal(true);
                        } else {
                            setSuccMsg(responce.message)
                            setShowSuccessMsgModal(true);
                        }

                        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                            $('#uploadDocBtn').removeAttr('disabled');
                        }
                        $('#save-btn').attr('disabled', 'disabled');
                        $('#reject-btn').attr('disabled', 'disabled');
                        $('#clearFormBtn').attr('disabled', 'disabled');
                        setEnquiryMasterTransactionId(responce.data.enquiry_master_transaction_id)
                        setEnquiryVersion(responce.data.enquiry_version);
                    } else {
                        setErrMsg(responce.error);
                        setShowErrorMsgModal(true);
                    }
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnValidateEnquiry = async () => {
        let headerDataIsValid = await validate.current.validateForm("enquiryFormId");
        if (!headerDataIsValid) {
            return false;
        }
        //customer contact validate
        const checkboxes = $('.selectCustContactPerson:checked');
        if (checkboxes.length === 0) {
            $('#error_customer_contact_persons').text('Please select atleast one contact person.');
            $('#error_customer_contact_persons').show();
            setErrMsg('Please select at least one customer contact.');
            setShowErrorMsgModal(true);
            return null;
        }
        //Detailsvalidation
        let EnquiryApproveIsValid = false;
        let addedMaterials = $('#erp-enquirydetails-table tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add at least one material.');
            setShowErrorMsgModal(true);
            return false;
        }

        //let EnquiryCommonTermsIsValid = false;
        let selectedCommonTerms = $('#erp-enquiryterms-table tbody tr .checkEnquiryTerms:checked')
        let EnquiryCommonTermsIsValid = true;
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            selectedCommonTerms.each(function () {
                let currentTblRow = $(this.parentElement.parentElement)
                let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val().trim();
                if (expectedValue === '') {
                    $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                    $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                    EnquiryCommonTermsIsValid = false;
                    return false;
                }
            });
            if (!EnquiryCommonTermsIsValid) {
                return EnquiryCommonTermsIsValid;
            }
        }
        //is aprovevalidate
        if (keyForViewUpdate === 'approve') {
            $('#erp-enquirydetails-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let enquiryStatus = currentTblRow.find('select[id^="enquiry_item_status_"]').val();
                let materialApproval = currentTblRow.find('input[id^="material_enquiry_approval_remark_"]').val();
                const countOfA = $('#erp-enquirydetails-table tbody tr select[id^="enquiry_item_status_"]').toArray().filter(selectElement => $(selectElement).val() === 'A').length;
                const countOfP = $('#erp-enquirydetails-table tbody tr select[id^="enquiry_item_status_"]').toArray().filter(selectElement => $(selectElement).val() === 'P').length;
                // if (materialApproval === '' && enquiryStatus === 'A' && countOfA >= 0) {
                //     currentTblRow.find('input[id^="material_enquiry_approval_remark_"]')[0].parentElement.dataset.tip = 'Please enter the approval remark....!';
                //     currentTblRow.find('input[id^="material_enquiry_approval_remark_"]')[0].focus();
                //     EnquiryApproveIsValid = false;
                //     return false;
                // } else 
                if (materialApproval === '' && enquiryStatus !== 'A' && countOfA <= 0) {
                    setErrMsg('Please Approve at least one Enquiry item status .');
                    setShowErrorMsgModal(true);
                    EnquiryApproveIsValid = false;
                    return false;
                } else {
                    EnquiryApproveIsValid = true;
                }
            });
            if (!EnquiryApproveIsValid) {
                return EnquiryApproveIsValid;
            }

        } else {
            // validate details table for add_enquiry.
            $('#erp-enquirydetails-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialQty = parseFloat(currentTblRow.find('input[id^="product_material_enquiry_quantity_"]').val());

                if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                    $(currentTblRow.find('input[id^="product_material_enquiry_quantity_"]'))[0].parentElement.dataset.tip = 'Enquiry quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="product_material_enquiry_quantity_"]'))[0].focus();
                    return EnquiryApproveIsValid = false;
                }
                EnquiryApproveIsValid = true;
            });
        }
        return EnquiryApproveIsValid;
    }

    const clearFormFields = async () => {
        setEnquiryTypeId('');
        setEnquiryno(0)
        // await fillAutoNo(enquiry_type);      // For Without-Product type based.
        setEnquiryVersion(1);
        setEnquiryOrdLife('C')
        setEnquiryDate(today);
        setCustomerId('');
        const defaultDepartment = departmentIdOPtions.find(department => department.field_name === "Marketing & Exports Department");
        if (defaultDepartment) { // Set the default department.
            setDepartmentId(defaultDepartment.field_id);
            // Load the enquiry-by list.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "department_id", operator: "=", value: defaultDepartment.field_id });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setEnquiryByIdOptions(enquirybyIdApiCall)
        }

        setAssigToId(parseInt(UserId));
        const defaultAssignedEmployee = assignToHead.find(employee => parseInt(employee.field_id) === parseInt(UserId));
        if (defaultAssignedEmployee) {   // Set Head Name for Assigned Employee. // reporting_to
            setAssigntoHeadId(defaultAssignedEmployee.reporting_to);
        }
        setEnquiryById('');
        setEnquiryStatus('P');
        setRemark('');
        setStatusremark('');
        setOtherTermsConditions('');
        setIsActive('true');
        const defaultAgent = agentOpts.find(agent => agent.field_name === 'NA');
        if (defaultAgent) {   // Set Head Name for Assigned Employee. // reporting_to
            setAgentId(defaultAgent.field_id);
            setAgentPercent(defaultAgent.agent_std_percent);
        }

        setApprovedById('');
        setApprovedDate('');
        setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expected_schedule_grace_days));
        setCustomerCityOpts([]);
        // sessionStorage.removeItem('bomfilteredData');
        setBomfilteredData([]);
        setCustContactDetails([]);
        // setCustContactDetailsCols([]);
        // setEnquiryDetailsDataCols([]);
        await FnGetDefaultEnquiryTerms();
        $('#cmb_enquiry_type_id').removeAttr('disabled');
        $('#chkAllEnqTerms').prop('checked', false);
        // $('#unchkAllEnqTerms').prop('checked', false);
        $('.checkEnquiryTerms').prop('checked', false);
        $('#ExpectedFunctionality :input').val('');

        setCustomerState('');
        $('#cmb_customer_state_id').attr('state_id', '0');
        setCustomerCity('');
        $('#cmb_customer_city_id').attr('city_id', '0');

        setExpected_branch_id(COMPANY_BRANCH_ID);
        const loggedInCompanyBranchObj = expectedBranchOptions.find(obj => obj.company_branch_id === parseInt(COMPANY_BRANCH_ID));
        setExpected_branch_state_id(loggedInCompanyBranchObj.state_name);
        $('#cmb_expected_branch_state_id').attr('state_id', loggedInCompanyBranchObj.branch_state_id);
        setExpected_branch_city_id(loggedInCompanyBranchObj.city_name)
        $('#cmb_expected_branch_city_id').attr('city_id', loggedInCompanyBranchObj.branch_city_id)
        sessionStorage.removeItem('filteredMaterialData');
    }

    const moveToListing = () => {
        sessionStorage.removeItem('enquiry_no')
        sessionStorage.removeItem('filteredMaterialData')
        const Path = compType === 'Register' ? '/Transactions/TEnquiryManagement/FrmEnquiryListing/reg' : `/Transactions/TEnquiryManagement/FrmEnquiryListing`;
        navigate(Path);
    }

    function toggleEnquiryTermsChkBoxes(key) {
        switch (key) {
            // For Common terms
            case 'chkAllEnqTerms':
                $('.checkEnquiryTerms').prop('checked', $('#chkAllEnqTerms').is(":checked"));
                // $('#unchkAllEnqTerms').prop('checked', false);
                break;
            case 'unchkAllEnqTerms':
                $('.checkEnquiryTerms').prop('checked', false);
                $('#chkAllEnqTerms').prop('checked', false);
                break;
            case 'PartiallyCommonTermSelection':
                $('#chkAllEnqTerms').prop('checked', $('input:checkbox.checkEnquiryTerms:checked').length === $('input:checkbox.checkEnquiryTerms').length);
                // $('#unchkAllEnqTerms').prop('checked', false);
                break;
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;

            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;

            default:
                break;
        }
    }

    const FnCloseFilterModal = async () => {
        setShowBomFilterForm(false)
        let matsDtlsRecFromFilter = JSON.parse(sessionStorage.getItem('filteredMaterialData'));
        if (matsDtlsRecFromFilter !== null) {
            let updatedEnquiryMaterials = matsDtlsRecFromFilter
                .filter(receivedMaterial =>
                    !bomfilteredData.some(existingMaterial => existingMaterial.product_material_id === receivedMaterial.product_id)
                )
                .map(function (receivedMaterial, index) {
                    // first check that material already exist or not?
                    const materialIsPresent = bomfilteredData.some((existingMaterial) => existingMaterial.product_material_id === receivedMaterial.product_id);
                    if (!materialIsPresent) {
                        // If Exist then convert that received material keys.
                        const newMaterial = {
                            product_id : receivedMaterial.product_id,
                            product_material_id: receivedMaterial.product_id,
                            product_type_short_name: receivedMaterial.product_material_short_name,
                            product_type_id : receivedMaterial.product_type_id,
                            product_type_name : receivedMaterial.product_type_name,
                            product_material_name: receivedMaterial.product_name,
                            product_material_tech_spect: receivedMaterial.product_tech_spect,
                            product_material_unit_id: receivedMaterial.product_unit_id,          // id
                            product_material_unit_name: receivedMaterial.product_unit_name,
                            product_material_packing_id: receivedMaterial.product_packing_id,    // id
                            product_packing_name: receivedMaterial.product_packing_name,
                            material_schedule_date: dt_overall_schedule_date,
                            product_material_enquiry_quantity: 1,
                            product_material_moq_quantity: receivedMaterial.product_moq,
                            expected_lead_time: receivedMaterial.product_lead_time,
                            material_std_rate: receivedMaterial.product_mrp,
                            enquiry_item_status: 'P',
                            product_material_notes: receivedMaterial.product_tech_spect,
                            product_material_conversion_factor: 1,
                            material_enquiry_approval_remark: '',
                            remark: '',
                            product_material_enquiry_weight: receivedMaterial.product_std_weight,
                            product_std_weight: receivedMaterial.product_std_weight,
                        }
                        return newMaterial;
                    }
                });
            // sessionStorage.setItem('bomfilteredData', JSON.stringify([...bomfilteredData, ...updatedEnquiryMaterials]));
            setBomfilteredData(([...bomfilteredData, ...updatedEnquiryMaterials]) === null ? [] : [...bomfilteredData, ...updatedEnquiryMaterials])
        }
        // sessionStorage.removeItem('filteredMaterialData');
    };

    const FnUpdateEnquiryDetails = (propertyName, currentMaterial, event) => {
        // let data = JSON.parse(sessionStorage.getItem('bomfilteredData'))
        let data = [...bomfilteredData];
        switch (propertyName) {
            case 'product_material_enquiry_quantity':
                if (event._reactName === 'onChange') {
                    currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                    let calculationenquiryWt = currentMaterial.product_std_weight * parseFloat(currentMaterial.product_material_enquiry_quantity);
                    currentMaterial['product_material_enquiry_weight'] = validateNumberDateInput.current.decimalNumber(calculationenquiryWt.toString(), 4);
                    delete event.target.parentElement.dataset.tip;
                } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                    event.target.parentElement.dataset.tip = 'Enquiry quantity should not be a zero or blank...!'
                    $(`#${event.target.id}`).focus();
                }
                break;

            case 'material_schedule_date':
            case 'product_material_notes':
            case 'product_material_tech_spect':
            case 'material_enquiry_approval_remark':
            case 'product_material_unit_id':
            case 'product_material_packing_id':
            case 'remark':
                currentMaterial[propertyName] = event.target.value
                delete event.target.parentElement.dataset.tip;
                break;

            case 'enquiry_item_status':
                currentMaterial['enquiry_item_status'] = event.target.value
                // On change remove the tooltip on approval remark.
                if (event.target.value !== 'A') {
                    let approvalRemarkField = $(`#material_enquiry_approval_remark_` + currentMaterial.product_material_id);
                    delete approvalRemarkField[0].parentElement.dataset.tip
                }
                break;

            default:
                break;
        }
        // updateObjectAtIndex(index, currentMaterial, data)
        const updatedDetailsData = [...bomfilteredData]
        const materialIndex = data.findIndex(material => material.product_material_id === currentMaterial.product_material_id);
        updatedDetailsData[materialIndex] = currentMaterial;
        setBomfilteredData(updatedDetailsData);
    };

    const displayRecordEnquiryComponent = () => {
        switch (modalHeaderName) {
            case 'Enquiry Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;

            // case 'Enquiry By':
            //     return <FrmMEmployeesEntry btn_disabled={true} />;

            case 'Department':
                return <FrmDepartmentEntry btn_disabled={true} />;

            case 'Add Customer City':
                return <FrmCity btn_disabled={true} />;

            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />;

            default:
                return null;
        }
    }

    const addNewFunctionalityRow = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setFunctionalityrowcount(functionalityrowcount + 1)
            addfunctionalityTblRow(functionalityrowcount + 1);
        }
    }

    const removeFirstRow = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            $('#existingfunctionality-' + 1).val("");
            $('#expectedfunctionality-' + 1).val("");
            $('#expectedvalue-' + 1).val("");
        }
    }

    const addfunctionalityTblRow = (functionalityrowcount) => {
        if (functionalityrowcount !== 1) {
            let newRow = $(`<tr rowIndex="${functionalityrowcount}" role="row" existingFuncTrCount="${functionalityrowcount}" id="RMSchedulesEntry-tr-${functionalityrowcount}"></tr>`);
            let secondLastRow = $("#ExpectedFunctionality tr").eq(-1);
            newRow.insertAfter(secondLastRow);
            $('#RMSchedulesEntry-tr-' + functionalityrowcount).append($("<td>").append('<span class="RemoveTrBtn"  id="RemoveTrBtn-' + functionalityrowcount + '" name=' + functionalityrowcount + ' value=' + functionalityrowcount + '><svg stroke="currentColor" Au="currentColor" stroke-width="0" viewBox="0 0 512 512" class="erp_trRemove_icon"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-miterlimit="10" stroke-width="32" d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 256H176"></path></svg></span>'))
        }
        const createTd1 = $('<textarea type="textarea" id="existingfunctionality-' + functionalityrowcount + '" maxLength="1000"/>').addClass("erp_input_field mb-0 form-control")
        const createTd2 = $('<textarea type="textarea" id="expectedfunctionality-' + functionalityrowcount + '" maxLength="1000"/>').addClass("erp_input_field mb-0 form-control")
        const createTd3 = $('<textarea type="textarea" id="expectedvalue-' + functionalityrowcount + '" maxLength="1000"/>').addClass("erp_input_field mb-0 form-control")
        // const createTd1 = $('<input type="textarea" id="existingfunctionality-' + functionalityrowcount + '" maxLength="1000"/>').addClass("erp_input_field mb-0")
        // const createTd2 = $('<input type="textarea" id="expectedfunctionality-' + functionalityrowcount + '" maxLength="1000"/>').addClass("erp_input_field mb-0")
        // const createTd3 = $('<input type="textarea" id="expectedvalue-' + functionalityrowcount + '" maxLength="1000"/>').addClass("erp_input_field mb-0")
        $('#RMSchedulesEntry-tr-' + functionalityrowcount).append($("<td>").append(createTd1));
        $('#RMSchedulesEntry-tr-' + functionalityrowcount).append($("<td>").append(createTd2));
        $('#RMSchedulesEntry-tr-' + functionalityrowcount).append($("<td>").append(createTd3));
        $('#ExpectedFunctionality tbody').on('click', '.RemoveTrBtn', function () {
            if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
                var $tr = $(this).closest('tr');
                $tr.remove();
            }
        });
    }

    const renderDetailsTable = useMemo(() => {
        return <> {bomfilteredData.length > 0
            ? <> <Table className="erp_table erp_table_scroll" id='erp-enquirydetails-table' bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>Action</th>
                        <th className="erp_table_th">Sr. No.</th>
                        <th className="erp_table_th">Product Type</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Technical Specification</th>
                        <th className="erp_table_th pe-5">Unit Name</th>
                        <th className="erp_table_th">Packing Name</th>
                        <th className="erp_table_th">Expec. Sch. Date</th>
                        {/* <th className="erp_table_th">Std Weight</th> */}
                        <th className="erp_table_th">Enquiry Quantity</th>
                        {/* <th className="erp_table_th">Enquiry Weight</th> */}
                        <th className="erp_table_th">MOQ Quantity</th>
                        <th className="erp_table_th">Lead Time</th>
                        <th className="erp_table_th">Material Notes</th>
                        {/* <th className="erp_table_th">Conversion Factor</th> */}
                        <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>Enquiry Item Status</th>
                        <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>Enquiry Approval Remark</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">S.T.D Rate</th>
                        <th className="erp_table_th">Product ID</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        bomfilteredData.map((enquiryMaterial, Index) =>
                            <tr rowIndex={Index}>
                                <td className={`erp_table_td text-center ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                        <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => FnDeleteSelectedMaterial(enquiryMaterial, e)} />
                                    </div>
                                </td>
                                <td className={`erp_table_td `}>{Index + 1}</td>
                                <td className={`erp_table_td`}>{enquiryMaterial.product_type_name}</td>
                                <td className={`erp_table_td`}>{enquiryMaterial.product_material_name}</td>
                                {/* <td className="erp_table_td">{enquiryMaterial.product_material_tech_spect}</td> */}
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" Headers='product_material_tech_spect' maxLength='1000' id={`product_material_tech_spect_${enquiryMaterial.product_material_id}`}
                                                    className="erp_input_field_table_txt mb-0 " value={enquiryMaterial.product_material_tech_spect} onChange={(e) => { FnUpdateEnquiryDetails("product_material_tech_spect", enquiryMaterial, e); }}
                                                />
                                            </>
                                            : enquiryMaterial.product_material_tech_spect
                                    }
                                </td>

                                <td className={`erp_table_td`}>
                                    {/* {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <select id={`product_material_unit_${enquiryMaterial.product_material_id}`} value={enquiryMaterial.product_material_unit_id} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                Headers="product_material_unit_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_unit_id', enquiryMaterial, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                                {productUnitOpts?.map(unit => (<option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>))}
                                            </select>
                                            : enquiryMaterial.product_material_unit_name
                                    } */}
                                    <select id={`product_material_unit_${enquiryMaterial.product_material_id}`} value={enquiryMaterial.product_material_unit_id} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                        Headers="product_material_unit_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_unit_id', enquiryMaterial, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                        {productUnitOpts?.map(unit => (<option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>))}
                                    </select>
                                </td>

                                <td className="erp_table_td">
                                    {/* {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <select id={`product_packing_name_${enquiryMaterial.product_material_id}`} value={enquiryMaterial.product_material_packing_id} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                Headers="product_material_packing_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_packing_id', enquiryMaterial, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                                {productPackingOpts?.map(packing => (<option value={packing.field_id} lbl={packing.field_name} >{packing.field_name}</option>))}
                                            </select>
                                            : enquiryMaterial.product_packing_name
                                    } */}
                                    <select id={`product_packing_name_${enquiryMaterial.product_material_id}`} value={enquiryMaterial.product_material_packing_id} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                        Headers="product_material_packing_id" onChange={(e) => { FnUpdateEnquiryDetails('product_material_packing_id', enquiryMaterial, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                        {productPackingOpts?.map(packing => (<option value={packing.field_id} lbl={packing.field_name} >{packing.field_name}</option>))}
                                    </select>
                                </td>

                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="date" Headers='material_schedule_date' id={`material_schedule_date_${enquiryMaterial.product_material_id}`} className="erp_input_field mb-0 "
                                                    value={enquiryMaterial.material_schedule_date} min={new Date().toISOString().split('T')[0]} onChange={(e) => { FnUpdateEnquiryDetails("material_schedule_date", enquiryMaterial, e); }} />
                                            </>
                                            : validateNumberDateInput.current.formatDateToDDMMYYYY(enquiryMaterial.material_schedule_date)
                                    }
                                </td>
                                {/* <td className="erp_table_td mb-0 text-end">{enquiryItem.product_rm_std_weight}</td> */}

                                <td className="erp_table_td text-end">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" id={`product_material_enquiry_quantity_${enquiryMaterial.product_material_id}`} className="erp_input_field mb-0 text-end" value={enquiryMaterial.product_material_enquiry_quantity}
                                                    onChange={(e) => { FnUpdateEnquiryDetails("product_material_enquiry_quantity", enquiryMaterial, e); }} onBlur={(e) => { FnUpdateEnquiryDetails("product_material_enquiry_quantity", enquiryMaterial, e); }}
                                                    maxLength='19' disabled={isApprove === true ? true : false} Headers='product_material_enquiry_quantity' />
                                            </>
                                            : enquiryMaterial.product_material_enquiry_quantity
                                    }
                                </td>

                                {/* <td className={`erp_table_td text-end`}>{enquiryItem.product_material_enquiry_weight}</td> */}
                                <td className="erp_table_td text-end">{enquiryMaterial.product_material_moq_quantity}</td>
                                <td className="erp_table_td text-end">{enquiryMaterial.expected_lead_time}</td>

                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" Headers='product_material_notes' maxLength='1000' id={`product_material_notes_${enquiryMaterial.product_material_id}`}
                                                    className="erp_input_field_table_txt mb-0 " value={enquiryMaterial.product_material_notes} onChange={(e) => { FnUpdateEnquiryDetails("product_material_notes", enquiryMaterial, e); }}
                                                />
                                            </>
                                            : enquiryMaterial.product_material_notes
                                    }
                                </td>
                                {/* <td className="erp_table_td text-end">{enquiryItem.product_rm_conversion_factor}</td> */}
                                <td className={`erp_table_td approveField ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>
                                    <select id={`enquiry_item_status_${enquiryMaterial.product_material_id}`} className={`form-select form-select-sm approveField m-0 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`} value={enquiryMaterial.enquiry_item_status}
                                        Headers='enquiry_item_status' onChange={(e) => { FnUpdateEnquiryDetails("enquiry_item_status", enquiryMaterial, e); }} onBlur={(e) => { FnUpdateEnquiryDetails("enquiry_item_status", enquiryMaterial, e); }}>
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                        <option value="R">Rejected</option>
                                        <option value="I">Partial Issue</option>
                                        <option value="C">Completed</option>
                                        <option value="X">Canceled</option>
                                        <option value="Z">PreeClosed</option>
                                    </select>
                                </td>

                                <td className={`erp_table_td approveField ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`}>
                                    {
                                        keyForViewUpdate === 'approve'
                                            ? <>
                                                <input type="text" id={`material_enquiry_approval_remark_${enquiryMaterial.product_material_id}`} Headers='material_enquiry_approval_remark'
                                                    className="erp_input_field_table_txt mb-0  approveField" value={enquiryMaterial.material_enquiry_approval_remark}
                                                    onChange={(e) => { FnUpdateEnquiryDetails("material_enquiry_approval_remark", enquiryMaterial, e); }} maxLength='500' />
                                            </>
                                            : enquiryMaterial.material_enquiry_approval_remark
                                    }
                                </td>

                                < td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                            ? <>
                                                <input type="text" maxLength='1000' id={`remark_${enquiryMaterial.product_material_id}`} Headers='remark' className="erp_input_field_table_txt mb-0"
                                                    value={enquiryMaterial.remark} onChange={(e) => { FnUpdateEnquiryDetails("remark", enquiryMaterial, e); }} />
                                            </>
                                            : enquiryMaterial.remark
                                    }
                                </td>
                                <td className="erp_table_td text-end">{enquiryMaterial.material_std_rate}</td>
                                <td className={`erp_table_td`}>{enquiryMaterial.product_material_id}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            </>
            : null
        }
        </>
    }, [bomfilteredData, isApprove])

    const renderTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='erp-enquiryterms-table' bordered striped hover>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        {/* <th className="erp_table_th">Common Parameters Master </th> */}
                        <th className="erp_table_th">Technical Parameters Term </th>
                        <th className="erp_table_th">Technical Parameters Value</th>
                        <th className="erp_table_th">Technical Prameters Expected Value</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        EnquiryTermsdata.map((enquiryTerms, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td">
                                    <input type="checkbox" className="checkEnquiryTerms" name="checkEnquiryTerms"
                                        id={'checkEnquiryTerms_' + enquiryTerms.common_parameters_id}
                                        value={enquiryTerms.common_parameters_id}
                                        onChange={() => toggleEnquiryTermsChkBoxes('PartiallyCommonTermSelection')}
                                        disabled={isApprove || keyForViewUpdate === 'view' ? true : false} />
                                </td>
                                {/* <td className="erp_table_td">{enquiryTerms.common_parameters_master_name}</td> */}
                                <td className="erp_table_td">{enquiryTerms.common_parameters_name}</td>
                                <td className="erp_table_td">{enquiryTerms.common_parameters_value}</td>

                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <>
                                                <input type="text" id={`common_parameters_expected_value_${enquiryTerms.common_parameters_id}`}
                                                    className="erp_input_field mb-0 " maxLength='255' value={enquiryTerms.common_parameters_expected_value}
                                                    onChange={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} onBlur={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} Headers='common_parameters_expected_value' />
                                            </>
                                            : enquiryTerms.common_parameters_expected_value
                                    }
                                </td>

                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <>
                                                <input type="text" id={`remark_${enquiryTerms.common_parameters_id}`}
                                                    className="erp_input_field mb-0 " value={enquiryTerms.remark} maxLength='255'
                                                    onChange={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} onBlur={(e) => { updateEnquiryCommonTermsTblData(enquiryTerms, e); }} Headers='remark' />
                                            </>
                                            : enquiryTerms.remark
                                    }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [EnquiryTermsdata]);

    //Enquiry Prinout Start
    const EnquiryContent = {
        company: {
            company: '',
            company_branch: '',
            company_address1: ''
        },

        customerDetails: {
            customer: '',
            address: '',
            state: '',
            city: '',
            contact: '',
            email: ''
        },
        ourDetails: {
            expected_branch_name: '',
            department_name: '',
            approved_by: '',
            approved_on: '',
            enquiry_by: '',
            agent_name: '',
            assign_to_head_name: '',
            overall_schedule_date: '',
            remark: '',

        },
        enquiryDetails: {
            enquiry_no: '',
            enquiry_date: '',
            enquiry_version: '',
            enquiry_type: '',
        },
        footer: {
            other_terms_conditions: '',
        },

        items: [],
        enquiryExistingExpectedFunctionalityDetails: [],
    };

    const printInvoice = async (openPrintModal) => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryDetailsTrading/FnShowAllDetailsAndMastermodelRecords/${updateData.enquiry_version}/${updateData.financial_year}/${COMPANY_ID}?enquiry_no=${updateData.enquiry_no}`)
            const response = await apiCall.json();
            console.log("Response Enquiry  : ", response);
            let enquiryMasterData = response.EnquiryMasterRecord;
            let enquiryDetailsData = response.EnquiryDetailsRecords;
            let enquiryExistingExpectedFunctionality = response.EnquiryExistingExpectedFunctionality;
            console.log(enquiryExistingExpectedFunctionality);

            //Enquiry details
            EnquiryContent.enquiryDetails.enquiry_no = enquiryMasterData.enquiry_no
            EnquiryContent.enquiryDetails.enquiry_date = validateNumberDateInput.current.formatDateToDDMMYYYY(enquiryMasterData.enquiry_date)
            EnquiryContent.enquiryDetails.enquiry_version = enquiryMasterData.enquiry_version
            EnquiryContent.enquiryDetails.enquiry_type = enquiryMasterData.enquiry_type

            //Company details
            EnquiryContent.company.company = enquiryMasterData.company_name
            EnquiryContent.company.company_branch = enquiryMasterData.company_branch_name
            EnquiryContent.company.company_address1 = enquiryMasterData.company_address1

            //Customer details
            EnquiryContent.customerDetails.customer = enquiryMasterData.customer_name
            EnquiryContent.customerDetails.address = enquiryMasterData.cust_branch_address1
            EnquiryContent.customerDetails.state = enquiryMasterData.customer_state_name
            EnquiryContent.customerDetails.contact = enquiryMasterData.cust_branch_phone_no
            EnquiryContent.customerDetails.email = enquiryMasterData.customer_email
            EnquiryContent.customerDetails.city = enquiryMasterData.customer_city_name

            //Our details
            EnquiryContent.ourDetails.expected_branch_name = enquiryMasterData.expected_branch_name
            EnquiryContent.ourDetails.department_name = enquiryMasterData.department_name
            EnquiryContent.ourDetails.agent_name = enquiryMasterData.agent_name
            EnquiryContent.ourDetails.assign_to_head_name = enquiryMasterData.assign_to_head_name
            EnquiryContent.ourDetails.approved_by = enquiryMasterData.approved_by_name
            EnquiryContent.ourDetails.approved_on = validateNumberDateInput.current.formatDateToDDMMYYYY(enquiryMasterData.approved_date)
            EnquiryContent.ourDetails.enquiry_by = enquiryMasterData.enquiry_by_name
            EnquiryContent.ourDetails.overall_schedule_date = validateNumberDateInput.current.formatDateToDDMMYYYY(enquiryMasterData.overall_schedule_date)
            EnquiryContent.ourDetails.remark = enquiryMasterData.remark

            //footer details    

            EnquiryContent.footer.other_terms_conditions = enquiryMasterData.other_terms_conditions

            //Enquiry details
            for (let index = 0; index < enquiryDetailsData.length; index++) {
                const element = enquiryDetailsData[index];

                const detailsData = {
                    sr_no: element.sr_no,
                    product_material_name: element.product_material_name,
                    product_material_tech_spect: element.product_material_tech_spect,
                    product_material_enquiry_quantity: element.product_material_enquiry_quantity,
                    product_material_enquiry_weight: element.product_material_enquiry_weight,
                    product_material_unit_name: element.product_material_unit_name,
                    material_std_rate: element.material_std_rate,
                    material_notes: element.product_material_notes,
                    material_moq_quantity: element.product_material_moq_quantity,
                }
                EnquiryContent.items.push(detailsData)
            }

            //Enquiry Existing Expected Functionality
            for (let index = 0; index < enquiryExistingExpectedFunctionality.length; index++) {
                const element = enquiryExistingExpectedFunctionality[index];

                const enquiryExistingExpectedFunctionalityData = {
                    sr_no: index + 1,
                    existing_functionality: element.enquiry_existing_functionality,
                    expected_functionality: element.enquiry_expected_functionality,
                    expected_value: element.enquiry_expected_value,

                }
                EnquiryContent.enquiryExistingExpectedFunctionalityDetails.push(enquiryExistingExpectedFunctionalityData)
            }


            if (openPrintModal) {
                navigate('/Invoice', { state: { invoiceData: EnquiryContent, navigationLink: '/Transactions/TEnquiryManagement/FrmEnquiryTradingEntry', invoiceType: 'EN', title: ' Enquiry Management', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
            } else {
                // return the html code.
                return renderToString(<Enquiry invoiceContent={EnquiryContent} />);
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    };

    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber(JSON.stringify(percentageInput), 4);

        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill digits this field...!';
        } else if (parseFloat(percentageInput) > 100) {

        } else {
            return true;
        }
        return percentageValidateMsg;
    }

    // -----------------------------------------------------------------------------------------------------------------------
    // Load the data on the accordion selection....
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'EnquiryTradingCommonTermsAccord':
                setIsLoading(true);
                checkedLength = $("input:checkbox[name=checkEnquiryTerms]:checked").length;
                if (checkedLength === 0) {
                    await FnGetDefaultEnquiryTerms();
                }

                if (idList !== null) {
                    // Then set the received common terms.

                }
                setIsLoading(false);
                break;

            case 'documentList':
                await showDocumentRecords();
                break;

            default:
                break;
        }
    }

    // ----------------------------- For Common Terms --------------------------------------------------------------------------------
    // Set the Common terms data on update action.
    const FnGetDefaultEnquiryTerms = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            const enquiryTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const responce = await enquiryTermsApiCall.json();
            if (responce.content.length !== 0) {
                // // Map and sort responce.content in a single step
                // responce.content = responce.content
                //     .map(({ common_parameters_value, ...rest }) => ({
                //         ...rest,
                //         common_parameters_expected_value: common_parameters_value,
                //         remark: '',
                //     }))
                //     .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                // const existingCommonTermsData = new Map(EnquiryTermsdata.map(item => [item.enquiry_terms_parameters_id, item]));

                // responce.content.forEach((item, index, renderedCommonTermsData) => {
                //     const existingCommonTerm = existingCommonTermsData.get(item.common_parameters_id);
                //     if (existingCommonTerm) {
                //         // Move the found object to the first index
                //         renderedCommonTermsData.unshift(renderedCommonTermsData.splice(index, 1)[0]);
                //         // Update values
                //         renderedCommonTermsData[0].common_parameters_expected_value = existingCommonTerm.enquiry_terms_parameters_expected_value;
                //         renderedCommonTermsData[0].remark = existingCommonTerm.remark;
                //     }
                // });

                // setEnquiryTermsdata(responce.content);
                // setTimeout(() => {
                //     existingCommonTermsData.forEach(function (existingCommonTerm) {
                //         $('#checkEnquiryTerms_' + existingCommonTerm.enquiry_terms_parameters_id).prop('checked', true);
                //     });
                // }, 200);

                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm,
                    common_parameters_expected_value: commonTerm.common_parameters_value,
                    remark: '',
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonTermsData = [...enqTermsResponse];
                let renderedCommonTermsData = responce.content
                for (let existCommonCounter = 0; existCommonCounter < existingCommonTermsData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonTermsData[existCommonCounter].enquiry_terms_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.common_parameters_id === existingCommonTermId);
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonTermsData[existCommonCounter].enquiry_terms_parameters_expected_value;
                        renderedCommonTermsData[0].remark = existingCommonTermsData[existCommonCounter].remark;
                    }
                }
                setEnquiryTermsdata(renderedCommonTermsData);
                setTimeout(() => {
                    existingCommonTermsData.forEach(function (existingCommonTerm) {
                        $('#checkEnquiryTerms_' + existingCommonTerm.enquiry_terms_parameters_id).prop('checked', true);
                    });
                }, 200);
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        $('.checkEnquiryTerms').on('change', function () {
            toggleEnquiryTermsChkBoxes('PartiallyCommonTermSelection');
        });
    }


    // ------------------------------------- Start Emailing Functionality -----------------------------------
    // After approved mail sent confirmation modal
    // Emailing Modal.
    const [showMailSentModal, setShowMailSentModal] = useState(false);

    const handleCloseMailSentModal = () => {
        setShowMailSentModal(false);
        moveToListing();
    }

    const sendEmail = async () => {
        try {
            setIsLoading(true);
            setShowMailSentModal(false);

            let emailContent = await generateMailJson();

            // Generate the sales-order PDF.
            let enquiryPDFContent = await printInvoice(false);
            const fileName = `${txt_enquiry_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const enquiryPDFContainer = document.createElement('div');
            enquiryPDFContainer.style.margin = '20px';
            enquiryPDFContainer.innerHTML = enquiryPDFContent;
            let worker = html2pdf().from(enquiryPDFContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(emailContent))
                formData.append("EnquiryPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryDetailsTrading/FnSendEmail/${enquiry_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
            });
            setIsLoading(false);
        } catch (error) {
            console.log('Error in email sending: ', error);
            navigate('/Error')
        }
    }

    const generateMailJson = async () => {
        let EmailData = { 'EmailType': "Enquiry Received Email Internal", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        const enquiryByEmpl = enquiryByIdOptions.find(employee => parseInt(employee.field_id) === parseInt(cmb_enquiry_by));
        if (enquiryByEmpl) { EmailData.toMailData.push(enquiryByEmpl.email_id1); }

        const assignedEmpl = assignToEmployeeOpts.find(employee => parseInt(employee.field_id) === parseInt(cmb_assign_to_id));
        if (assignedEmpl) { EmailData.toMailData.push(assignedEmpl.email_id1); }

        const assignedHeadEmpl = assignToHead.find(employee => parseInt(employee.field_id) === parseInt(cmb_assign_to_head_id));
        if (assignedHeadEmpl) { EmailData.toMailData.push(assignedHeadEmpl.email_id1); }

        const approvedByEmpl = approvedbyid.find(employee => parseInt(employee.field_id) === parseInt(cmb_approved_by_id));
        if (approvedByEmpl) { EmailData.toMailData.push(approvedByEmpl.email_id1); }

        const selectedAgent = agentOpts.find(agent => parseInt(agent.field_id) === parseInt(cmb_agent_id));
        if (selectedAgent) { EmailData.toMailData.push(selectedAgent.agent_EmailId); }

        EmailData.subject = `Received the new enquiry ${txt_enquiry_no} ...!`;                          // Subject
        EmailData.parameters.push("Trading Enquiry Details");                                                   // Param0 It is a heading.
        EmailData.parameters.push(`${txt_enquiry_no}`);
        // EmailData.parameters.push($('#cmb_customer_id').find(":selected").text());
        EmailData.parameters.push(cmb_customer_id === '0' || cmb_customer_id === '' || cmb_customer_id === null ? '' : $('#cmb_customer_id').find(":selected").text());
        EmailData.parameters.push(cmb_department === '0' || cmb_department === '' || cmb_department === null ? '' : $('#cmb_department').find(":selected").text());
        EmailData.parameters.push(cmb_enquiry_by === '0' || cmb_enquiry_by === '' || cmb_enquiry_by === null ? '' : $('#cmb_enquiry_by').find(":selected").text());
        EmailData.parameters.push(cmb_assign_to_id === '0' || cmb_assign_to_id === '' || cmb_assign_to_id === null ? '' : $('#cmb_assign_to_id').find(":selected").text());
        EmailData.parameters.push(cmb_assign_to_head_id === '0' || cmb_assign_to_head_id === '' || cmb_assign_to_head_id === null ? '' : $('#cmb_assign_to_head_id').find(":selected").text());
        EmailData.parameters.push(await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date));
        EmailData.parameters.push(COMPANY_NAME);
        console.log('enquiry emailData: ', EmailData);
        return EmailData;
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    // ------------------------------------- Ends Emailing Functionality -----------------------------------

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtEnquiryDetailsTrading/FnDeleteRecord/${updateData.enquiry_version}/${COMPANY_ID}?enquiry_no=${updateData.enquiry_no}`, method)
            const responce = await deleteApiCall.json();
            // console.log("Company Deleted: ", responce);
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const deleteshow = () => {
        setShowDeleteModal(true)
    }

    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_enquiry_no !== 0 ? txt_enquiry_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    // let column = [];
                    // columnHeads = Object.keys(resp.content[0]);
                    // console.log("Document Records: ", resp)
                    // for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    //     if (!excludedcustFields.some(field => columnHeads[colKey].includes(field))) {
                    //         column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    //     }
                    // }
                    setDocumentData(resp.content);
                    // setDocColumns(column);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='document-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">{docItem.document_registration_no}</td>
                                <td className="erp_table_td">{docItem.document_registration_date}</td>
                                <td className="erp_table_td">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td">{docItem.document_path}</td>
                                <td className="erp_table_td">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}> {docItem.file_name} </Link>
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------



    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ExcelExport ref={exlsExp} />
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Trading Enquiry <span id="submain_heading">(Creation)</span></label>
                    </div>

                    <form id="enquiryFormId">
                        <div className="row p-1">
                            {/* <div className='main_heding'>
                            <label className='erp-form-label-lg main_heding'> Trading <span id="submain_heading">(ADD)</span></label>
                        </div> */}

                            {/* //first column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Enquiry Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_enquiry_type_id" className="form-select form-select-sm" value={cmb_enquiry_type_id} onChange={() => { comboOnChange('enquiryTypeId'); validateFields(); }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {enquirytypeOptions?.map(enquiryTypeId => (
                                                <option value={enquiryTypeId.field_id} shortName={`${enquiryTypeId.product_type_short_name}`}>{enquiryTypeId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_enquiry_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Enquiry No., Ver.& Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_enquiry_no" className="erp_input_field" value={txt_enquiry_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_enquiry_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='txt_enquiry_version' className="erp_input_field text-end" value={txt_enquiry_version} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_enquiry_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='txt_enquiry_date' className="erp_input_field" value={txt_enquiry_date} onChange={e => { setEnquiryDate(e.target.value); validateFields(); }} {...(keyForViewUpdate === '' || keyForViewUpdate === 'copy' ? {} : { readOnly: true })} />
                                                <MDTypography variant="button" id="error_txt_enquiry_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Enquiry Life <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_enquiry_life" checked={rb_enquiry_life === "C"} onClick={() => setEnquiryOrdLife("C")} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_enquiry_life" checked={rb_enquiry_life === "O"} onClick={() => setEnquiryOrdLife("O")} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Enquiry Status<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_enquiry_status" className="form-select form-select-sm" value={cmb_enquiry_status} onChange={() => { comboOnChange('enquirydById'); validateFields(); }} disabled={true} >
                                            <option value="P" selected>Pending</option>
                                            <option value="A" disabled>Approved</option>
                                            <option value="R" disabled>Rejected</option>
                                            <option value="Z" disabled> PreeClosed</option>
                                            <option value="C" disabled>Completed</option>
                                            <option value="X" disabled>Canceled</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_enquiry_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-10'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={e => { setCustomerId(e.target.value); comboOnChange('Customer'); validateFields(); }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerOptions?.map(customer => (
                                                <option value={customer.field_id} is_sez={`${customer.is_sez}`}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col refreshIconCol">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnUpdateComboBoxData('Customer')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id='cmb_customer_state_id' className="erp_input_field" value={cmb_customer_state_id} optional="optional" maxlength="500" disabled />
                                                {/* // For reduce the api calling converted combo to the text-box */}
                                                {/* <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); comboOnChange('CustomerState') }} disabled='disabled'>
                                                <option value="">Select</option>
                                                {stateOpts?.map(state => (
                                                    <option value={state.field_id}>{state.field_name}</option>
                                                ))}
                                            </select> */}
                                                <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>

                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="text" id='cmb_customer_city_id' className="erp_input_field" value={cmb_customer_city_id} optional="optional" maxlength="500" disabled />
                                                {/* // For reduce the api calling converted combo to the text-box */}
                                                {/* <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); comboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled='disabled'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerCityOpts?.map(city => (
                                                    <option value={city.field_id}>{city.field_name}</option>
                                                ))}
                                            </select> */}
                                                <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Our Company Branch<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange("expt_branch"); validateFields(); }} >
                                            <option value="">Select</option>
                                            {expectedBranchOptions.length !== 0 ?
                                                <>
                                                    {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                        <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row d-none'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Our Branch State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id='cmb_expected_branch_state_id' className="erp_input_field" value={cmb_expected_branch_state_id} optional="optional" maxlength="500" disabled />
                                                {/* // For reduce the api calling converted combo to the text-box */}
                                                {/* <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} onChange={(e) => { comboOnChange("expt_branchState"); validateFields(); }} disabled='disabled'>
                                                <option value="">Select</option>
                                                {expectedBranchStateOptions?.map(cmb_expected_branch_state_id => (
                                                    <option value={cmb_expected_branch_state_id.field_id}>{cmb_expected_branch_state_id.field_name}</option>
                                                ))}
                                            </select> */}
                                                <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>

                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="text" id='cmb_expected_branch_city_id' className="erp_input_field" value={cmb_expected_branch_city_id} optional="optional" maxlength="500" disabled />
                                                {/* // For reduce the api calling converted combo to the text-box */}
                                                {/* <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} onChange={(e) => { setExpected_branch_city_id(e.target.value); comboOnChange('company_city'); validateFields(); }} disabled='disabled' >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {expectedBranchCityOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchCityOptions?.map(cmb_expected_branch_city_id => (
                                                            <option value={cmb_expected_branch_city_id.field_id}>{cmb_expected_branch_city_id.field_name}</option>
                                                        ))}</>
                                                    : ''
                                                }
                                            </select> */}
                                                <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }} optional='optional'>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {departmentIdOPtions?.map(departmentId => (
                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Enquiry By </Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_enquiry_by" className="form-select form-select-sm" value={cmb_enquiry_by} onChange={(e) => { setEnquiryById(e.target.value); comboOnChange('enquiryById'); validateFields(); }} optional='optional' >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {enquiryByIdOptions?.map(enquirydById => (
                                                <option value={enquirydById.field_id}>{enquirydById.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_enquiry_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* <div className="col-sm-1">
                                    <Tooltip title="Refresh" placement="right">
                                        <MDTypography >
                                            <MdRefresh onClick={() => comboOnChange('enquiryById')} style={{ color: 'black' }} />
                                        </MDTypography>
                                    </Tooltip>
                                </div> */}
                                    <div className="col refreshIconCol">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnUpdateComboBoxData('EnquiryBy')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-0">
                                    <Accordion defaultActiveKey="0" >
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                                <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                }
                                                                }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                            </div>
                                                        </>
                                                        : null
                                                }

                                                {
                                                    custContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <Table className="erp_table erp_table_scroll" id='customerContactPersonsTable' responsive bordered striped>
                                                                        <thead className="erp_table_head">
                                                                            <tr>
                                                                                <th className={`erp_table_th`}> <div className="col-1"><input type='checkbox' className="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { toggleEnquiryTermsChkBoxes('selectAllCustContact'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />Select</div></th>
                                                                                <th className='erp_table_th'>Customer Branch</th>
                                                                                <th className='erp_table_th'>Contact Person</th>
                                                                                <th className='erp_table_th'>Contact No</th>
                                                                                <th className={`erp_table_th `}>Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                custContactDetails.map((item, index) =>
                                                                                    <tr rowindex={index}>
                                                                                        <td className='erp_table_td'>
                                                                                            <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id} value={item.customer_contact_id}
                                                                                                onClick={() => toggleEnquiryTermsChkBoxes('PartiallyCustContactSelection')}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="erp_table_td">{item.customer_branch_name}</td>
                                                                                        <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                        <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                        <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>

                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Assign To Employee</Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_assign_to_id" className="form-select form-select-sm" value={cmb_assign_to_id} onChange={(e) => { setAssigToId(e.target.value); comboOnChange('AssignToemployee') }} optional='optional' >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {assignToEmployeeOpts?.map(employee => (
                                                <option value={employee.field_id}>{employee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_assign_to_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col refreshIconCol">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnUpdateComboBoxData('AssignToemployee')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Assign To Head</Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_assign_to_head_id" className="form-select form-select-sm" value={cmb_assign_to_head_id} onChange={(e) => { setAssigntoHeadId(e.target.value); comboOnChange('AssignToHead') }} optional='optional' >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {assignToHead?.map(branchId => (
                                                <option value={branchId.field_id}>{branchId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_assign_to_head_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                    <div className="col refreshIconCol">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnUpdateComboBoxData('AssignToHead')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Exp. Scheduled Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_overall_schedule_date' className="erp_input_field" value={dt_overall_schedule_date} onChange={(e) => { setOverAllScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                        <MDTypography variant="button" id="error_dt_overall_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Agent & Percentage</Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <div className="row">
                                            <div className="col-12 col-md-8 pe-md-0">
                                                <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); comboOnChange('Agent') }} optional="optional" >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {agentOpts?.map(agent => (
                                                        <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                            <div className="col-10 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="number" id='txt_agent_percent' className="erp_input_field erp_align-right" value={txt_agent_percent}
                                                    onChange={(e) => {
                                                        let isValidPercentageInput = validatePercentageInput(e.target.value);
                                                        if (isValidPercentageInput === true) {
                                                            setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
                                                            validateFields();
                                                            delete e.target.parentElement.dataset.tip;
                                                        }
                                                    }} optional="optional" />
                                                <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                            <div className="col-2 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1 text-center refreshIconCol">
                                                <Tooltip title="Refresh" placement="right">
                                                    <MDTypography >
                                                        <MdRefresh onClick={() => FnUpdateComboBoxData('Agent')} style={{ color: 'black' }} />
                                                    </MDTypography>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Agent</Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10'>
                                        <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); comboOnChange('Agent') }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {agentOpts?.map(agent => (
                                                <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnUpdateComboBoxData('Agent')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4 pe-0">
                                        <Form.Label className="erp-form-label"> Agent's % </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id='txt_agent_percent' className="erp_input_field erp_align-right" value={txt_agent_percent}
                                            onChange={(e) => {
                                                let isValidPercentageInput = validatePercentageInput(e.target.value);
                                                if (isValidPercentageInput === true) {
                                                    setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
                                                    validateFields();
                                                    delete e.target.parentElement.dataset.tip;
                                                }
                                            }} optional="optional" />
                                        <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}

                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? <>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label"> Approved By & Date</Form.Label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 pe-md-0">
                                                            <select id="cmb_approved_by_id" className="form-select form-select-sm" name="approved_by" value={cmb_approved_by_id} disabled={true}
                                                                onChange={(e) => { setApprovedById(e.target.value); comboOnChange('ApprovedBy') }} optional='optional' >
                                                                <option value="">Select</option>
                                                                {approvedbyid?.map(branchId => (
                                                                    <option value={branchId.field_id}>{branchId.field_name}</option>
                                                                ))}
                                                            </select>
                                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>

                                                        <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApprovedDate(e.target.value); validateFields(); }} optional='optional' disabled='disabled' />
                                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label">Status Remark</Form.Label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <Form.Control as="textarea" id='txt_status_remark' className="erp_input_field mb-md-1" value={txt_status_remark} onChange={e => { setStatusremark(e.target.value); validateFields(); }} optional='optional' disabled={true} />
                                                    <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                        </>
                                        : null
                                }

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Other Terms & Conditions</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id='txt_other_terms_conditions' className="erp_input_field mb-md-1" value={txt_other_terms_conditions} onChange={e => setOtherTermsConditions(e.target.value)} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" id='txt_remark' className="erp_input_field mb-md-1" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Mail Send Status</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_enquiry_mail_sent_status" checked={rb_enquiry_mail_sent_status === "P"} onClick={(e) => { setEnquiryMailSentStatus('P'); }} disabled='disabled' optional="optional" /> </div>
                                            <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Sent" type="radio" lbl="Sent" value="S" name="rb_enquiry_mail_sent_status" checked={rb_enquiry_mail_sent_status === "S"} onClick={(e) => { setEnquiryMailSentStatus('S') }} disabled='disabled' optional="optional" /> </div>
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Failed" type="radio" lbl="Failed" value="F" name="rb_enquiry_mail_sent_status" checked={rb_enquiry_mail_sent_status === "F"} onClick={(e) => { setEnquiryMailSentStatus('F') }} disabled='disabled' optional="optional" /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_is_active" checked={chk_is_active === "true"} onClick={() => { setIsActive("true"); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_is_active" checked={chk_is_active === "false"} onClick={() => { setIsActive("false"); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Enquiry Details</Accordion.Header>
                        <Accordion.Body className="p-1 px-md-3 pb-0">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="row">
                                        {
                                            keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                ? <>
                                                    <div className="col-md-6 col-12">
                                                        <SearchItemFilter getSearchData={FnCloseFilterModal} formIdForValidate="enquiryFormId" requiredCols={requiredColsForOpenFilter} />
                                                    </div>
                                                    <div className="col-md-4  col-12 pt-1" >
                                                        <MDButton type="button" className={`erp-gb-button float-start`} variant="button" fontWeight="regular" onClick={viewBomFilterForm} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Add Material</MDButton>
                                                    </div>
                                                    {/* <div className="col-sm-3 pt-1">
                                                        <MDButton type="button" className={`erp-gb-button float-start`} variant="button" fontWeight="regular" onClick={viewBomFilterForm} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Add Material</MDButton>
                                                    </div> */}
                                                </>
                                                : null
                                        }

                                        {/* <div className="col-sm-9 d-flex pt-1">
                                            <Form.Control type="text" id="txt-detail-data-highliter" className="erp_input_field showData float-start" />
                                            <MDButton type="button" className="erp-gb-button erp_MLeft_btn showData float-start" variant="button" onClick={() => scrollToTableRow()}
                                                fontWeight="regular" style={{ float: "left" }}>Search</MDButton>
                                        </div> */}


                                    </div>
                                </div>

                                <div className="col-md-6 align-self-end pt-1">
                                    <MDButton type="button" id="import-btn"
                                        className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || isApprove ? 'd-none' : 'display'}`}
                                        variant="button" onClick={onImportClick}
                                        fontWeight="regular" disabled={isApprove === false ? false : true}
                                    > Import </MDButton>
                                    <MDButton type="button" id="export-btn"
                                        className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || isApprove ? 'd-none' : 'display'}`}
                                        variant="button" onClick={ExportToExcel} fontWeight="regular"
                                        disabled={enquiry_master_transaction_id === 0 || enquiry_master_transaction_id === '' || isApprove === false ? false : true}
                                    > Export </MDButton>
                                </div>
                            </div>

                            <div className="row py-2">
                                {renderDetailsTable}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >
                <hr />
                <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                    <Accordion.Item eventKey="EnquiryTradingCommonTermsAccord">
                        <Accordion.Header className="erp-form-label-md"> Enquiry Terms</Accordion.Header>
                        <Accordion.Body className="p-1 pt-0">
                            <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <div className="col-12 col-sm-6">
                                    <input type='checkbox' class="" id="chkAllEnqTerms" onClick={(e) => toggleEnquiryTermsChkBoxes('chkAllEnqTerms')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /> <label class="erp-form-label pb-1 me-2"> Check All </label>
                                    {/* <input type='checkbox' class="" id="unchkAllEnqTerms" onClick={(e) => toggleEnquiryTermsChkBoxes('unchkAllEnqTerms')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /> <label class="erp-form-label pb-1"> Uncheck All </label> */}
                                </div>
                                <div className="col-12 col-sm-6">
                                    <MDButton type="button" className={`erp-gb-button float-sm-end col-1`} variant="button" fontWeight="regular"
                                        onClick={() => {
                                            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                            setHeaderName('Common Parameter'); // CommonParamterEntry
                                            setShowAddRecEnquiryModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                        }}>Add
                                    </MDButton>
                                </div>
                            </div>

                            {/* <div className='row ps-3 py-1'>
                                <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                    setHeaderName('Common Parameter') // CommonParamterEntry
                                    setShowAddRecEnquiryModal(true)
                                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                }}>Add</MDButton>
                            </div>

                            <div class="col pt-sm-1">
                                <input type='checkbox' class="" id="chkAllEnqTerms" onClick={(e) => toggleEnquiryTermsChkBoxes('chkAllEnqTerms')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /> <label class="erp-form-label pb-1"> Check All. </label>
                            </div> */}
                            <div className={`table-responsive ${EnquiryTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                <>{renderTermsTable}</>
                            </div>
                            {/* <div className='row'>
                                {renderTermsTable}
                            </div> */}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />

                <Accordion defaultActiveKey="1" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Expected Functionality</Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className='table-responsive erp_table_scroll'>
                                    <Table className="erp_table" id="ExpectedFunctionality" bordered striped hover>
                                        <thead className='erp_table_head erp_table_th'>
                                            <tr>
                                                <th>Action</th>
                                                <th> Existing Functionality</th>
                                                <th> Expected Functionality </th>
                                                <th> Expected Value</th>
                                            </tr>
                                        </thead>
                                        <tbody className="erp_table_td">
                                            <tr rowindex='1' existingFuncTrCount={existingFunctionRowCount} id="RMSchedulesEntry-tr-1">
                                                <td><IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow()} /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewFunctionalityRow()} /></td>
                                                <td><Form.Control as="textarea" id='existingfunctionality-1' className="erp_input_field mb-0" maxLength="1000" /></td>
                                                <td><Form.Control as="textarea" id='expectedfunctionality-1' className="erp_input_field mb-0" maxLength="1000" /></td>
                                                <td><Form.Control as="textarea" id='expectedvalue-1' className="erp_input_field mb-0" maxLength="1000" /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                {keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ?
                    <> <hr />
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md">Document List</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`table-responsive ${docData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion></> : null
                }

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                        onClick={() => deleteshow()}
                    >  Delete</MDButton>
                    <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" BtnType="save" onClick={(e) => addEnquiry(e)}
                        fontWeight="regular">Save</MDButton>
                    {
                        keyForViewUpdate === 'approve'
                            ? <>
                                <MDButton type="button" id="reject-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='reject' onClick={(e) => addEnquiry(e)} fontWeight="regular">Reject</MDButton>
                            </>
                            : ""
                    }

                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || isApprove ? 'd-none' : 'display'}`} id='uploadDocBtn' variant="button" fontWeight="regular" onClick={viewDocumentForm} disabled={enquiry_master_transaction_id === 0 || enquiry_master_transaction_id === '' ? true : false}>Upload Document</MDButton>&nbsp;
                    {
                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update'
                            ? <>
                                <MDButton type="button" id="clearFormBtn" className={`erp-gb-button ms-2`} variant="button" onClick={e => { clearFormFields(); }} fontWeight="regular" >Clear</MDButton>
                            </>
                            : null
                    }

                    {
                        keyForViewUpdate === 'view' && cmb_enquiry_status === 'A' && !deletionKey
                            ? <>
                                <MDButton className={`erp-gb-button ms-2`} variant="button" fontWeight="regular"
                                    onClick={() => { printInvoice(true); }} disabled={cmb_enquiry_status === "A" ? '' : 'disabled'}> Print  <FiPrinter className="erp-download-icon-btn" />
                                </MDButton>
                            </>
                            : null
                    }
                </div >


                {/* ADD Filter modal */}
                {
                    showBomFilterForm ?

                        <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={FnCloseFilterModal} backdrop="static" keyboard={false} centered>
                            <Modal.Header className="justify-content-between">
                                <Modal.Title className='erp_modal_title'>Products List</Modal.Title>
                                <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseFilterModal}></button></span>
                            </Modal.Header>
                            <Modal.Body>
                                <React.Fragment>
                                    <ProductListProductTypeWise closeModal={FnCloseFilterModal} props={{ product_type_id: cmb_enquiry_type_id }} />
                                    {/* <ProductList closeModal={FnCloseFilterModal} props={{ selectedProduct: ['smv_product_rm_summary', 'smv_product_fg_summary'] }} /> */}
                                    {/* <FrmMBOMEntry closeModal={FnCloseFilterModal} ptSelected={{ product_type: cmb_enquiry_type_id, master_val: 'smv_product_type' }} /> */}
                                </React.Fragment>
                            </Modal.Body>
                        </Modal> : null
                }

                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Documents For {txt_enquiry_no}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_enquiry_no !== 0 ? txt_enquiry_no.replaceAll('/', '_') : null} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                {/* Add new Record Popup */}
                {/* <Modal size="lg" show={showAddRecEnquiryModal} onHide={closeRecordAddEnquiryModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddEnquiryModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordEnquiryComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={closeRecordAddEnquiryModal} className="btn erp-gb-button" variant="button" fontWeight="regular">Close</MDButton>
                    </Modal.Footer>
                </Modal > */}

                {/* Send Email On Approval Modal */}
                <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, succMsg, modalOrderDetails]} />

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecEnquiryModal} onHide={closeRecordAddEnquiryModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'>
                                <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddEnquiryModal}></button>
                            </div>
                        </div>
                        {displayRecordEnquiryComponent()}
                    </Modal.Body>
                </Modal >

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

            </DashboardLayout>

        </>
    )
}