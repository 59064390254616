import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";

export default function SalaryEarningRegister() {

  // Global Constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

  // UseRefs Hooks.
  const comboDataFunc = useRef();
  const exlsExp = useRef();

  // Ref Hooks For Print-Out.    
  const printComponentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);

  // ****** navigate
  const navigate = useNavigate();

  // combo's options hooks;
  const [employeeProps, setEmployeeProps] = useState([]);   // Storing all employee_types and the the employee_groups.
  const [allEarningOpts, setAllEarningOpts] = useState([]); // Storing all earnings.

  const [earningOpts, setEarningOpts] = useState([]);
  const [monthOpts, setMonthOpts] = useState([
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ]);
  const [yearOpts, setYearOpts] = useState(
    Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
      .map((year) => ({ label: year, value: year }))
  );
  const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
  const [departmentOpts, setDepartmentOpts] = useState([]);
  const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
  const [employeeOpts, setEmployeeOpts] = useState([]);

  // Input field's Hooks;
  const [cmb_year, setYear] = useState('');
  const [cmb_month, setMonth] = useState('');
  const [cmb_earning_id, setEarningId] = useState('');
  const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
  const [cmb_department_id, setDepartmentId] = useState('');
  const [cmb_sub_department_id, setSubDepartmentId] = useState('');
  const [cmb_employee_id, setEmployeeId] = useState('');

  // Hooks For table;
  const [salaryEarningsDtlTblData, setSalaryEarningsDtlTblData] = useState([]);

  // UseRefs Hooks For Searchable combos.
  let monthComboRef = useRef(null);
  let yearComboRef = useRef(null);
  let employeeTypesComboRef = useRef(null);
  let earningsComboRef = useRef(null);
  let departmentComboRef = useRef(null);
  let subDepartmentComboRef = useRef(null);
  let employeeComboRef = useRef(null);

  // Helping hooks;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true);
      await FnFillCombos();
      setIsLoading(false);
    }
    loadDataOnload();
  }, []);

  const FnFillCombos = async () => {
    try {
      // Set Default Year.
      let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
      setYear(defaultYear.value);
      yearComboRef.current = defaultYear;
      // Set Default Month.
      let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
      setMonth(defaultMonth.value);
      monthComboRef.current = defaultMonth;

      // Load employee types
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
      globalQuery.table = "amv_properties"
      globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdEmplTypes => {
          setEmployeeProps(rcvdEmplTypes);
          let emplTypes = rcvdEmplTypes
            .filter(prop => prop.properties_master_name === 'EmployeeType')
            .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
          setEmployeeTypesOpts(emplTypes);
        });

      // Load All Earnings and show it by using filter of the employee_type
      resetGlobalQuery();
      globalQuery.columns = ["earning_heads_id", "earning_group", "earning_head_name"];
      globalQuery.table = "hm_earning_heads"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdEarnings => {
          rcvdEarnings = rcvdEarnings.map(prop => ({ ...prop, value: prop.earning_heads_id, label: prop.earning_head_name }));
          setAllEarningOpts(rcvdEarnings);
        });

      // Load Department & Sub-Department
      resetGlobalQuery();
      globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
      globalQuery.table = "cm_department"
      globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataFunc.current.fillFiltersCombo(globalQuery)
        .then(rcvdDepts => {
          const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
            if (department.department_type === "M") {
              acc.mainDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            } else if (department.department_type === "S") {
              acc.subDepartments.push({
                ...department,
                label: department.department_name,
                value: department.department_id,
              });
            }
            return acc;
          }, { mainDepartments: [], subDepartments: [] });
          setAllDepartmentsList([...mainDepartments, ...subDepartments]);

          mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
          setDepartmentOpts(mainDepartments);
        });

    } catch (error) {
      console.log("error on filling combos: ", error)
      navigate('/Error')
    }
  }

  const FnCombosOnChange = async (comboName, comboVal) => {
    try {
      setIsLoading(true);
      switch (comboName) {
        case "EmployeeType":
          await FnSetDefaultData('EmployeeType');
          let selectedEmplGrp = employeeProps.find(prop => prop.properties_master_name === 'EmployeeTypeGroup' && prop.property_value === employeeTypesComboRef.current.property_group);
          // Set the earnings options as per the employee type.
          let filteredEarnings = allEarningOpts
            .filter(earning => ['All', selectedEmplGrp?.field_name || ''].includes(earning.earning_group));
          setEarningOpts(filteredEarnings);
          earningsComboRef.current = null
          setEarningId('');
          break;

        case "Department":
          let selectedDepartment = departmentComboRef.current.department_id;
          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          // remove the already selected sub-department
          subDepartmentComboRef.current = null;
          setSubDepartmentId('');
          await FnSetDefaultData('Department');
          break;

        case "SubDepartment":
          await FnSetDefaultData('SubDepartment');
          break;

        default:
          break;
      }
      setIsLoading(false);

    } catch (error) {
      console.log("error on combos change: ", error)
      navigate('/Error')
    }
  }

  const FnSetDefaultData = async (key) => {
    try {
      // Filters for load employee query.
      let filterConditions = [
        { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "is_delete", operator: "=", value: 0 },
      ];

      let selectedDepartment = departmentComboRef.current?.department_id;
      if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
        filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
      }

      let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
      if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
        filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
      }


      if (key === 'All') {
        // First Set Default Department.
        if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
          let defaultDept = departmentOpts.find(department => department.value == 'All');
          setDepartmentId(defaultDept.value);
          departmentComboRef.current = defaultDept;

          // Also Load All Sub-Department
          const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
          setSubDepartmentId('All');
          selectedDepartment = '';
        }

        if (selectedSubDepartment === '' || selectedSubDepartment === undefined || selectedSubDepartment === null) {
          const subDepartmentList = allDepartmentsList.filter(department =>
            (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
          );
          subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
          setSubDepartmentOpts(subDepartmentList);
          subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
          setSubDepartmentId('All');
        }
      }

      let selectedEmplType = employeeTypesComboRef.current.field_name;
      if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
        filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
      }

      resetGlobalQuery();
      globalQuery.columns = ["employee_id", "employee_code", "employee_name"];
      globalQuery.table = "cmv_employee"
      globalQuery.conditions = filterConditions;
      let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      if (getEmployeeList.length > 0) {
        getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
        getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
        setEmployeeOpts(getEmployeeList);
        setEmployeeId('All');
        employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
      } else {
        setEmployeeOpts([]);
        setEmployeeId('');
        employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // ****-------Get Salary Earnings Data Functions Starts--------------***
  const FnGetSalaryEarningDetailsData = async () => {
    try {
      let isValidFilters = await FnChkIsValidFilters();
      if (!isValidFilters) { return false; }

      // *** Means Filters are valid then filter data as per the filters.
      setIsLoading(true);
      let filterConditions = [
        { field: "empl.company_id", operator: "=", value: parseInt(COMPANY_ID) },
        { field: "empl.is_delete", operator: "=", value: 0 },
        { field: "hts_earn.earning_amount", operator: ">", value: 0 },
      ];

      // *** Apply Filters Conditions;
      let selectedDepartment = departmentComboRef.current?.department_id;
      let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
      let selectedEmplType = employeeTypesComboRef.current.field_name;
      let selectedEmployee = employeeComboRef.current.employee_id;

      // Check it selected one employee or not? 
      if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
        filterConditions.push({ field: "empl.employee_id", operator: "=", value: selectedEmployee });
      } else {
        // Means load data without employee filter.
        // If Valid Then set the default data if not selected any filter.
        await FnSetDefaultData('All');

        if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
          filterConditions.push({ field: "empl.department_id", operator: "=", value: selectedDepartment });
        }
        if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
          filterConditions.push({ field: "empl.sub_department_id", operator: "=", value: selectedSubDepartment });
        }
        if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
          filterConditions.push({ field: "empl.employee_type", operator: "=", value: selectedEmplType });
        }
      }

      resetGlobalQuery();
      globalQuery.columns = [`empl.employee_code`, `empl.employee_name`, `hts_earn.earning_amount`,
        `empl.department_name`, `empl.sub_department_name`, `empl.designation_name`,];

      globalQuery.table = "cmv_employee empl";
      globalQuery.conditions = filterConditions;
      globalQuery.joins = [
        {
          "table": `ht_salary_earnings hts_earn`,
          "type": "LEFT",
          "on": [
            { "left": "empl.employee_id", "right": "hts_earn.employee_id", },
            { "left": "hts_earn.earning_heads_id", "right": cmb_earning_id, },
            { "left": "hts_earn.salary_month", "right": cmb_month, },
            { "left": "hts_earn.salary_year", "right": cmb_year, },
            { "left": "hts_earn.is_delete", "right": 0, },
          ]
        },
      ]
      globalQuery.orderBy = ['empl.department_id DESC', 'empl.sub_department_id DESC', 'empl.employee_code Asc'];
      let receivedEarningsData = await comboDataFunc.current.fillFiltersCombo(globalQuery);

      if (receivedEarningsData.length > 0) {
        receivedEarningsData = receivedEarningsData.map((record, index) => ({
          ...record,
          sr_no: index + 1,
        }));
        setSalaryEarningsDtlTblData(receivedEarningsData);
      } else {
        setSalaryEarningsDtlTblData([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const FnRenderSalaryEarningDetailsTbl = useMemo(() => {
    if (salaryEarningsDtlTblData.length === 0) { return null; }

    return (
      <>
        <style>
          {`
              @media print {
                  .print-content {
                      padding: 0;
                      box-sizing: border-box;
                  }
                  @page {
                      margin: 0;
                      size: A4;
                      margin: 2%;
                      border: 2px solid black;
                  }
                  html, body {
                      width: 100%;
                      height: 100%;
                  }
                  body {
                      margin: 0;
                      padding: 0;
                  }
              }
              .erp_table {
                  font-size: 3px !important; /* Default font size */
              }
              .erp_table_th, .erp_table_td {
                  border: 1px solid black;
                  padding-top: 2px !important;
                  padding-bottom: 2px !important;
              }
          `}
        </style>

        <Table className="erp_table" id='EarningDtlsTbl' style={{ whiteSpace: 'normal' }} ref={printComponentRef}>
          <thead className="erp_table_head thead-light text-center" style={{ whiteSpace: 'normal' }} >
            <tr style={{ whiteSpace: 'normal' }}>
              <th className="erp_table_th">Sr. No.</th>
              <th className="erp_table_th col-2">Department</th>
              <th className="erp_table_th col-2">SubDepartment</th>
              <th className="erp_table_th col-1">Code</th>
              <th className="erp_table_th col-4">Employee Name</th>
              <th className="erp_table_th col-2">Designation</th>
              <th className="erp_table_th col-1">Earning Amount</th>
            </tr>
          </thead>

          <tbody>
            {
              salaryEarningsDtlTblData?.map((salaryEarningRecord, index) => {
                return (
                  <tr key={index} rowIndex={index}>
                    <td className="erp_table_td">{index + 1}</td>
                    <td className="erp_table_td">{salaryEarningRecord.department_name}</td>
                    <td className="erp_table_td">{salaryEarningRecord.sub_department_name}</td>
                    <td className="erp_table_td">{salaryEarningRecord.employee_code}</td>
                    <td className="erp_table_td">{salaryEarningRecord.employee_name} </td>
                    <td className="erp_table_td">{salaryEarningRecord.designation_name}</td>
                    <td className="erp_table_td text-end">{salaryEarningRecord.earning_amount}</td>
                  </tr>
                )
              })
            }

            {
              // Footer Summary Row.
              <tr className='table-light' key={salaryEarningsDtlTblData.length + 1} rowIndex={salaryEarningsDtlTblData.length + 1}>
                <th className="erp_table_th" colSpan={2}>Grand Totals: </th>
                <th className="erp_table_th text-end">{salaryEarningsDtlTblData.length}</th>
                <th className="erp_table_th"></th>
                <th className="erp_table_th"></th>
                <th className="erp_table_th"></th>
                <th className="erp_table_th text-end" > {salaryEarningsDtlTblData.reduce((sum, summary) => sum + summary.earning_amount, 0).toFixed(2)} </th>
              </tr>
            }
          </tbody>
        </Table>
      </>
    )
  }, [salaryEarningsDtlTblData])


  // ***----------- Helping Functions.
  const goBack = () => { navigate("/Dashboard") }

  // Get Current DateTime as "DD-MM-YYYY HH:mm:ss"
  const FnGetCurrentDateTime = () => {
    let currentDate = new Date();
    let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
    return formattedDateTime;
  }

  // ****-------Validation Functions Starts--------------***
  // Function for validate the fields at the time of button click;
  const FnChkIsValidFilters = async () => {
    if (cmb_employee_type_id === '') {
      $('#error_cmb_employee_type_id').text('Please select the atleast one...!')
      $('#error_cmb_employee_type_id').show();
      return false;
    } else {
      $('#error_cmb_employee_type_id').hide();
    }

    if (cmb_earning_id === '') {
      $('#error_cmb_earning_id').text('Please select the atleast one...!')
      $('#error_cmb_earning_id').show();
      return false;
    } else {
      $('#error_cmb_earning_id').hide();
    }

    if (cmb_year === '') {
      $('#error_cmb_year').text('Please select the atleast one...!')
      $('#error_cmb_year').show();
      return false;
    } else {
      $('#error_cmb_year').hide();
    }

    if (cmb_month === '') {
      $('#error_cmb_month').text('Please select the atleast one...!')
      $('#error_cmb_month').show();
      return false;
    } else {
      $('#error_cmb_month').hide();
    }
    return true;
  }

  // Function for validate the fields on change
  const FnValidateFields = () => {
    setSalaryEarningsDtlTblData([]);
    const isEmpty = str => {
      if (typeof str === 'number' && Number.isInteger(str)) {
        str = str.toString();
      }
      return str === undefined || str === null || str.trim() !== '';
    };
    if (isEmpty(cmb_month)) { $('#error_dt_att_to').hide(); }
    if (isEmpty(cmb_year)) { $('#error_dt_att_from').hide(); }
    if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
    if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
    if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
    if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    if (isEmpty(earningsComboRef.current?.earning_heads_id || '')) { $('#error_cmb_earning_id').hide(); }
  }

  // ****-------Validation Functions Ends--------------***


  // ********* PrintOut Functionalities Starts. ****************************************
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const FnPrintReport = useReactToPrint({
    content: () => {
      const headerHtml = printOutHeader(); // Call function to get header HTML

      const contentWrapper = document.createElement('div');
      contentWrapper.className = 'printable-content';
      contentWrapper.innerHTML = headerHtml; // Add generated header HTML

      // Append the main content to the wrapper
      const mainContentClone = printComponentRef.current.cloneNode(true);
      contentWrapper.appendChild(mainContentClone);

      return contentWrapper;
    },
    onBeforePrint: () => {
      // Apply the desired font size before printing
      if (printComponentRef.current) {
        const table = printComponentRef.current.querySelector('.erp_table');
        if (table) {
          table.style.fontSize = '7px'; // Set your desired font size
        }
      }
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    documentTitle: `Salary Earning Details For ${monthComboRef.current?.label}-${yearComboRef.current?.value}`
  });

  const printOutHeader = () => {
    return ReactDOMServer.renderToString(
      <>
        <div className='px-0 border border-dark'> {/* border border-dark */}
          <div className='row mb-0 p-1 border-bottom border-dark'>
            <div className="col-sm-1">
              <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
            </div>
            <div className="col-sm-11">
              <div className='erp-invoice-print-label text-center'>
                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
              </div>
              <div className='erp-invoice-print-label-lg text-center'> Salary Earning Details </div>
              <div className='erp-invoice-print-label-lg text-center'>
                For Month: {monthComboRef.current?.label}   Year: {yearComboRef.current?.value}
              </div>
            </div>
          </div>

          <dl className="row pb-0 mb-0 px-1">
            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Employee Type:</dt>
                <dd className='col-sm-9 erp-invoice-print-label'> {employeeTypesComboRef.current?.label || ""}</dd>
              </dl>
            </div>

            <div className="col-sm-6">
              <dl className="row pb-0 mb-0">
                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Earning Type:</dt>
                <dd className='col-sm-9 erp-invoice-print-label'> {earningsComboRef.current?.label || ""}</dd>
              </dl>
            </div>
          </dl>

        </div>
      </>
    );
  };
  // ********* PrintOut Functionalities Ends. ****************************************


  // ********* ExportToExcel Functionalities Starts. ****************************************
  const addExcelFilter = (label, key, value, existingFilters) => {
    if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
  };

  const FnExportToExcel = async () => {
    // Means No Data For Export. 
    if (salaryEarningsDtlTblData.length === 0) { return null; }

    setIsLoading(true);
    // Preparing Data For ExportFile.
    let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

    // Excel Headers;
    jsonToExportExcel['headings'] = {
      'ReportName': `Salary Earning Details`,
      'CompanyName': COMPANY_NAME,
      'CompanyAddress': sessionStorage.getItem('companyAddress'),
    }

    // Applied Filters.
    let appliedFilters = {
      '0': `Employee Type: ${employeeTypesComboRef.current?.label || ""}`,
      '1': `Earning Type: ${earningsComboRef.current?.label || ""}`,
      '2': `Year: ${yearComboRef.current?.label || ""}`,
      '3': `Month: ${monthComboRef.current?.label}(${monthComboRef.current?.value || ""})`,
    };
    addExcelFilter(departmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Department', appliedFilters);
    addExcelFilter(subDepartmentComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Sub Department', appliedFilters);
    addExcelFilter(employeeComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Employee', appliedFilters);
    appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
    jsonToExportExcel['filtrKeyValue'] = appliedFilters;

    // Details Table Columns and Accessors.
    jsonToExportExcel['columns'] = [
      { "Headers": 'Sr No', "accessor": 'sr_no' },
      { "Headers": 'Department', "accessor": 'department_name' },
      { "Headers": 'SubDepartment', "accessor": 'sub_department_name' },
      { "Headers": 'Employee code', "accessor": 'employee_code' },
      { "Headers": 'Employee Name', "accessor": 'employee_name' },
      { "Headers": 'Designation', "accessor": 'designation_name' },
      { "Headers": 'Earning Amount', "accessor": 'earning_amount' },
    ]
    // Data For Table.
    salaryEarningsDtlTblData.map((earningRecord, Index) => {
      earningRecord.sr_no = Index + 1;
      jsonToExportExcel['allData'][Index] = earningRecord;

      // Check if it is the last item then push the last sum-of-totals object.
      if (Index === salaryEarningsDtlTblData.length - 1) {
        jsonToExportExcel['allData'][salaryEarningsDtlTblData.length] = {
          sr_no: '',
          department_name: 'Grand Totals: ',
          sub_department_name: Index + 1,
          employee_code: '',
          employee_name: '',
          designation_name: '',
          earning_amount: parseFloat(salaryEarningsDtlTblData.reduce((sum, summary) => sum + summary.earning_amount, 0).toFixed(2)),
        }
      }
    });

    await exlsExp.current.excel(jsonToExportExcel, `Salary_Earning_Details_${monthComboRef.current?.label}-${yearComboRef.current?.value}`);
    setIsLoading(false);
  }
  // ********* ExportToExcel Functionalities Ends. ****************************************


  return (
    <>

      <ComboBox ref={comboDataFunc} />
      <ExcelExport ref={exlsExp} />

      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div> :
          ''}

        {/* Card Starts*/}
        <div className="card mt-4">
          <div className="card-header py-0 main_heding">
            <label className="erp-form-label-lg">Salary Earnings Register</label>
          </div>

          {/* Card's Body Starts*/}
          <div className="card-body">
            <div className="row">
              {/* First Column Starts*/}
              <div className="col-sm-4 erp_form_col_div">

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee Type<span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeTypesComboRef}
                      options={employeeTypesOpts}
                      inputId="cmb_employee_type_id"
                      value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                      onChange={(selectedOpt) => {
                        setEmployeeTypeId(selectedOpt.value);
                        employeeTypesComboRef.current = selectedOpt;
                        FnCombosOnChange('EmployeeType');
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee type..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Earning Type <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={earningsComboRef}
                      options={earningOpts}
                      inputId="cmb_earning_id"
                      value={earningOpts.find(option => option.value == cmb_earning_id) || null}
                      onChange={(selectedOpt) => {
                        setEarningId(selectedOpt.value);
                        earningsComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a earning type..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_earning_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* First Column Ends*/}


              {/* Second Column Starts*/}
              <div className="col-sm-4 erp_form_col_div">


                <div className="row">
                  <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                  <div className='col'>
                    <Select ref={yearComboRef}
                      options={yearOpts}
                      inputId="cmb_year"
                      value={yearOpts.find(option => option.value == cmb_year) || null}
                      onChange={(selectedOpt) => {
                        setYear(selectedOpt.value);
                        yearComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a year..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                  <div className='col'>
                    <Select ref={monthComboRef}
                      options={monthOpts}
                      inputId="cmb_month"
                      value={monthOpts.find(option => option.value == cmb_month) || null}
                      onChange={(selectedOpt) => {
                        setMonth(selectedOpt.value);
                        monthComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a month..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* Second Column Ends*/}

              {/* First Third Column Starts*/}
              <div className="col-sm-4 erp_form_col_div">

                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={departmentComboRef}
                      options={departmentOpts}
                      inputId="cmb_department_id"
                      value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                      onChange={(selectedOpt) => {
                        setDepartmentId(selectedOpt.value);
                        departmentComboRef.current = selectedOpt;
                        FnCombosOnChange("Department");
                        FnValidateFields();
                      }}
                      placeholder="Search for a department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                  <div className='col'>
                    <Select ref={subDepartmentComboRef}
                      options={subDepartmentOpts}
                      inputId="cmb_sub_department_id"
                      value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                      onChange={(selectedOpt) => {
                        setSubDepartmentId(selectedOpt.value);
                        subDepartmentComboRef.current = selectedOpt;
                        FnCombosOnChange("SubDepartment");
                        FnValidateFields();
                      }}
                      placeholder="Search for a sub-department..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Employee </Form.Label>
                  </div>
                  <div className='col-12 col-sm-8'>
                    <Select ref={employeeComboRef}
                      options={employeeOpts}
                      inputId="cmb_employee_id"
                      value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                      onChange={(selectedOpt) => {
                        setEmployeeId(selectedOpt.value);
                        employeeComboRef.current = selectedOpt;
                        FnValidateFields();
                      }}
                      placeholder="Search for a employee..."
                      className="form-search-custom"
                      classNamePrefix="custom-select"
                      styles={{
                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                      }}
                    />
                    <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>

              </div>
              {/* First Third Column Ends*/}
            </div>

            <div className="row justify-content-center mt-2">
              <div className="col text-center">
                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                  className={`erp-gb-button float-center me-1`}
                  onClick={(e) => { goBack(); }}  > Back </MDButton>

                <MDButton type="button" id="show-data-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                  onClick={(e) => { FnGetSalaryEarningDetailsData(); }}
                >Show Data</MDButton>
              </div>
            </div>

            {/* Attendance Details Table */}
            {
              salaryEarningsDtlTblData.length === 0
                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                : <> <hr />

                  <div className="row py-1">
                    <div className="text-end">
                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                        onClick={(e) => { FnExportToExcel(); }} >Export Excel</MDButton>

                      <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                        onClick={(e) => { FnPrintReport(); }} >Print </MDButton>
                    </div>
                  </div>

                  <div className="row px-lg-2 d-block">
                    <div className="card p-0">
                      <div className="card-header py-0 main_heding mb-0">
                        <label className="erp-form-label-md-lg">Salary Earnings Details</label>
                      </div>
                      {/* Card's Body Starts*/}
                      <div className="card-body p-0 print-content" ref={printComponentRef}>
                        {FnRenderSalaryEarningDetailsTbl}
                      </div>
                    </div>
                  </div>
                </>
            }

            <hr />
            <div className="row text-center mt-3">
              <div className=''>
                {
                  salaryEarningsDtlTblData.length === 0
                    ? null
                    : <>
                      {
                        salaryEarningsDtlTblData.length > 50
                          ? <>
                            <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                              className={`erp-gb-button float-center`}
                              onClick={(e) => { goBack(); }}  > Back </MDButton>

                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-2"
                              onClick={(e) => { FnPrintReport(); }} >Print</MDButton>
                          </>
                          : null
                      }
                    </>
                }
              </div>
            </div>

          </div>
          {/* Card's Body Ends*/}

        </div>
        {/* Card Ends*/}

      </DashboardLayout>

    </>
  )
}
