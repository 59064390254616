import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';  // Import for the searchable combo box.

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstra
import Form from 'react-bootstrap/Form';

// File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from "FrmGeneric/FrmValidations";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";

function FrmProductTypeDynamicControlsEntry({ for_product_type = 0, btn_disabled = false }) {

    // var updateData = JSON.parse(sessionStorage.getItem('earning_headsID'));

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { producttypedynamicControl = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {}

    // useReff
    const navigate = useNavigate()
    const comboDataAPiCall = useRef();
    const validate = useRef();
    const validateNumberDateInput = useRef();
    const generateMaterialIdAPiCall = useRef();
    const controlMstComboRef = useRef(null);


    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsListing`)
        }
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    //MHMEarningHeads form Feilds   

    const [product_type_dynamic_controls_id, setProductTypeCOntolId] = useState(0)
    const [chk_ControlType, setControlType] = useState("T")
    const [txt_control_name, setcontrolname] = useState("")
    const [cmb_control_master_id, setControlMasterName] = useState("")
    const [txt_remark, setremark] = useState("")
    const [txt_display_sequence, setDisplaySequence] = useState("")
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [cmb_product_type_id, setProductTypeId] = useState(for_product_type);
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [controlMasterOptions, setControlMasterOptions] = useState([]);
    const [productCategory, setProductCategoryOptions] = useState([]);
    const [cmb_product_category_id, setProductCategory] = useState("");
    const [actionType, setActionType] = useState('')

    useEffect(async () => {
        await fillComobos();
        if (producttypedynamicControl !== 0) {
            await FnCheckUpdateResponce();
        }
        await ActionType()
    }, [])

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');

                break;
            case 'view':
                setActionType('(View)');

                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('ProductTypeDynamicControls')
    }
    // const fillOnchangeComobos = async (productType) => {

    //     resetGlobalQuery();
    //     globalQuery.columns.push("field_id");
    //     globalQuery.columns.push("field_name");
    //     globalQuery.table = "sm_product_category2"
    //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //     globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productType });
    //     const productCategory = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
    //     setProductCategoryOptions(productCategory)
    // }


    const fillComobos = () => {
        comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
            .then((getProductTypes) => {
                setProductTypeOptions(getProductTypes)
            })

    }

    const AddDynamicControlsData = async () => {
        debugger
        try {
            let checkIsValidate = await validate.current.validateForm("ProductTypeDynamicControls");
            if (chk_ControlType === "C" || chk_ControlType === "P") {
                let selectedMstName = cmb_control_master_id || '';
                if (selectedMstName === "") {
                    $("#error_cmb_control_master_id").text("Please fill this field...!");
                    $("#error_cmb_control_master_id").show();
                    $("#cmb_control_master_id").focus();
                    checkIsValidate = false;
                    return false;
                }
            }

            if (checkIsValidate === true) {
                var active;
                var activeValue = document.querySelector('input[name=isearningheadsActive]:checked').value
                switch (activeValue) {
                    case '0': active = false; break;
                    case '1': active = true; break;
                }
                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    product_type_dynamic_controls_id: product_type_dynamic_controls_id,
                    control_name: txt_control_name,
                    control_master: cmb_control_master_id,
                    control_type: chk_ControlType,
                    product_type_id: cmb_product_type_id,
                    product_category_id: cmb_product_category_id,
                    remark: txt_remark,
                    display_sequence: txt_display_sequence,
                    is_active: active,
                    created_by: UserName,
                    modified_by: producttypedynamicControl === 0 ? null : UserName,
                };
                console.log(data);

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductTypeDynamicControls/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce.data);
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    const evitCache = await comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    console.log("product_type_dynamic_controls_id", responce.data);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }

            }
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const handleControlTypeChange = (type) => {
        switch (type) {
            case 'C':
                setControlMasterName('');
                resetGlobalQuery();
                globalQuery.columns.push("table_name");
                globalQuery.table = "information_schema.tables"
                globalQuery.conditions.push({ field: "table_schema", operator: "=", value: 'ERP_PASHUPATI_PROD_1_0' });
                globalQuery.conditions.push({ field: "table_type", operator: "=", value: 'VIEW' });
                comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                    .then((getMasterNames) => {
                        if (getMasterNames.length !== 0) {
                            const modifiedMasterNames = getMasterNames.map((name) => {
                                return { ...name, label: name.table_name, value: name.table_name, };
                            });
                            setControlMasterOptions(modifiedMasterNames)
                        } else {
                            setControlMasterOptions([])
                        }
                    })
                break;
            case 'P':
                setControlMasterName('');
                debugger
                resetGlobalQuery();
                globalQuery.columns.push("DISTINCT(properties_master_name)");
                globalQuery.table = "amv_properties"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                    .then((getMasterNames) => {
                        if (getMasterNames.length !== 0) {
                            const modifiedMasterNames = getMasterNames.map((name) => {
                                return { ...name, table_name: name.properties_master_name, label: name.properties_master_name, value: name.properties_master_name, };
                            });
                            setControlMasterOptions(modifiedMasterNames)
                        } else {
                            setControlMasterOptions([])
                        }
                    })
                break;

            default:
                break;
        }
    };

    {/* Function for fetch details for view or edit */ }
    const FnCheckUpdateResponce = async () => {

        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductTypeDynamicControls/FnShowParticularRecordForUpdate/${producttypedynamicControl}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.data
            if (data !== null && data !== "") {

                setProductTypeCOntolId(data.product_type_dynamic_controls_id)
                setcontrolname(data.control_name)
                setControlType(data.control_type)
                handleControlTypeChange(data.control_type)
                setProductTypeId(data.product_type_id);
                comboOnChange('productType');
                setProductCategory(data.product_category_id);
                setControlMasterName(data.control_master)
                setremark(data.remark)
                setDisplaySequence(data.display_sequence)
                switch (data.calculation_type) {
                    case true:
                        document.querySelector('input[name="chk_ControlType"][value="Amount"]').checked = true;
                        break;
                    case false:
                        document.querySelector('input[name="chk_ControlType"][value="Formula"]').checked = true;
                        break;
                }

                switch (data.is_active) {
                    case true:
                        document.querySelector('input[name="isearningheadsActive"][value="1"]').checked = true;
                        break;
                    case false:
                        document.querySelector('input[name="isearningheadsActive"][value="0"]').checked = true;
                        break;
                }

                // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');
                // switch (keyForViewUpdate) {
                //     case 'update':
                //         // $("input[type=radio]").attr('disabled', true);
                //         $('#cmb_control_master_id').attr('disabled', true);
                //         $('#chk_CalculationtypeFormula').attr('disabled', true);
                //         $('#chk_CalculationtypeAmt').attr('disabled', true);
                //         $('#btn_save').attr('disabled', false);
                //         $('#txt_control_name').attr('disabled', true);
                //         if (data.calculation_type !== 'Amount') {
                //             await validate.current.removeReadOnlyAttr("ProductTypeDynamicControls");
                //         }
                //         break;
                //     case 'view':
                //         await validate.current.readOnly("ProductTypeDynamicControls");
                //         break;
                // }
                // }

            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }


    // Show ADD record Modal
    const handleCloseRecModal = async () => {
        await fillComobos()
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')

        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }
    const comboOnChange = async (key) => {
        switch (key) {
            case 'productType':
                let productTpVal = document.getElementById('cmb_product_type_id').value;
                if (productTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 200)
                }
                setProductTypeId(productTpVal)

                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "smv_product_category2"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
                const productCategory = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                setProductCategoryOptions(productCategory)
                break;

        }
    }
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <GenerateMaterialId ref={generateMaterialIdAPiCall} />

            <div className='erp_top_Form'>
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Product Type Dynamic Controls{actionType}</label>
                    </div>
                    <form id="ProductTypeDynamicControls">
                        <div className="row erp_transporter_div">
                            {/* First Collumn */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }} disabled={for_product_type !== 0 || keyForViewUpdate === "view"} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record +</option>
                                            {productTypeOptions?.map(productType => (
                                                <option value={productType.field_id}>{productType.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Control Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_control_name" value={txt_control_name} onChange={e => { setcontrolname(e.target.value); validateFields(); }} maxLength="500" disabled={keyForViewUpdate === "view"} />
                                        <MDTypography variant="button" id="error_txt_control_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Control Type <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" id="chk_CalculationtypeAmt" label="Text Box" type="radio" lbl="Text Box" value="T" name="chk_ControlType" checked={chk_ControlType === "T"} onClick={() => setControlType("T")} disabled={keyForViewUpdate === 'view'} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" id="chk_CalculationtypeFormula" label="Combo Box" type="radio" lbl="Combo Box" value="C" name="chk_ControlType" checked={chk_ControlType === "C"} onClick={() => { setControlType("C"); handleControlTypeChange('C') }} disabled={keyForViewUpdate === 'view'} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" id="chk_CalculationtypeFormula" label="Date" type="radio" lbl="Date" value="D" name="chk_ControlType" checked={chk_ControlType === "D"} onClick={() => setControlType("D")} disabled={keyForViewUpdate === 'view'} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" id="chk_CalculationtypeFormula" label="Common Properties" type="radio" lbl="Common Properties" value="P" name="chk_ControlType" checked={chk_ControlType === "P"} onClick={() => { setControlType("P"); handleControlTypeChange('P') }} disabled={keyForViewUpdate === 'view'} /> </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    chk_ControlType === "C" || chk_ControlType === "P" ?
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Control Master<span className="required">*</span></Form.Label>
                                                </div>
                                                <div className='col'>
                                                    {/* <select id="cmb_control_master_id" className="form-select form-select-sm" value={cmb_control_master_id} onChange={(e) => { setControlMasterName(e.target.value); validateFields(); }}>
                                                        <option value="">Select</option>
                                                        {controlMasterOptions?.map(control_master => (
                                                            <option value={control_master.table_name}>{control_master.table_name}</option>
                                                        ))}
                                                    </select> */}

                                                    <Select ref={controlMstComboRef}
                                                        options={controlMasterOptions}
                                                        isDisabled={['view'].includes(keyForViewUpdate)}
                                                        inputId="cmb_control_master_id" // Provide the ID for the input box
                                                        value={controlMasterOptions.find(option => option.value == cmb_control_master_id)}
                                                        onChange={(selectedOpt) => {
                                                            setControlMasterName(selectedOpt.value);
                                                            controlMstComboRef.current = selectedOpt;
                                                            validateFields();
                                                        }}
                                                        placeholder={`Search from the ${chk_ControlType === "C" ? 'views' : 'common properties'}`}
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided,
                                                                fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_control_master_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </> : ""
                                }

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Product Category</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_product_category_id" className="form-select form-select-sm" value={cmb_product_category_id} onChange={e => { setProductCategory(e.target.value); validateFields(); }} disabled={keyForViewUpdate === "view"} optional='optional'>
                                            <option value="">Select</option>
                                            {/* <option value="0">Add New Record +</option> */}
                                            {productCategory?.map(productType => (
                                                <option value={productType.field_id}>{productType.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_category_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">

                                {/* Remark Row */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Remark </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_remark" value={txt_remark} onChange={e => { setremark(e.target.value); }} maxLength="255" optional='optional'disabled={ keyForViewUpdate === "view"}/>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Dispaly Sequence </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" className="erp_input_field" id="txt_display_sequence" value={txt_display_sequence} onChange={e => { setDisplaySequence(e.target.value); validateFields(); }} optional='optional'disabled={ keyForViewUpdate === "view"} />
                                        <MDTypography variant="button" id="error_txt_display_sequence" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                {/* Earning Head Active Row */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Dynamic Controls Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check className="erp_radio_button" label="Yes" type="radio" value="1" name="isearningheadsActive" defaultChecked disabled={keyForViewUpdate === "view"}/>
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check className="erp_radio_button" label="No" value="0" type="radio" name="isearningheadsActive" disabled={keyForViewUpdate === "view"}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsListing/reg' : '/Masters/MProductTypeDynamicControls/FrmProductTypeDynamicControlsListing';
                                navigate(path);
                            }} variant="button" fontWeight="regular" disabled={btn_disabled ? true : false} >Back</MDButton>

                        {keyForViewUpdate !== 'view' ? (
                            <MDButton type="submit" onClick={AddDynamicControlsData} id="btn_save" className="erp-gb-button erp_MLeft_btn" variant="button"
                                fontWeight="regular">save</MDButton>
                        ) : null}
                    </div >
                </div>
                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </div>

        </>
    )
}

export default FrmProductTypeDynamicControlsEntry
