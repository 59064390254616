import ConfigConstants from 'assets/Constants/config-constant';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useMemo } from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { useNavigate } from "react-router-dom";


function FrmEInvoicingAndEWayBillingEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID} = configConstants;

    const [invoice_status, setInvoiceStatus] = useState('I')
    const [salesInvoiceMasterData, setSalesInvoiceMasterData] = useState([]);
    const [invoiceJsons, setInvoiceJsons] = useState();
    const [companySettings, setCompanySettings] = useState();
    const navigate = useNavigate();

    const [txt_sales_invoice_no, setSalesInvoiceNo] = useState('');
    const [txt_e_inv_irn_no, setInvoiceIrnNo] = useState('');
    const [txt_e_inv_ack_no, setInvoiceAckNo] = useState('');
    const [txt_e_inv_ewb_no, setInvoiceEwbNo] = useState('');
    const [txt_e_inv_auth_token, setInvoiceToken] = useState('');
    const [txt_e_inv_token_expiry, setInvoiceTokenExpiry] = useState('');



    useEffect(() => {
        FnGetSalesInvoiceData()
    }, [invoice_status])

    const FnGetSalesInvoiceData = async () => {
        try {
            debugger;
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesInvoiceMasterTrading/FnGetSaleInvoiceDataByStatus/${invoice_status}/${COMPANY_ID}`)
            const response = await apiCall.json();

            setSalesInvoiceMasterData(response.SalesInvoiceMasterAllRecordsByStatus)
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnGenerateToken =  (invoiceItem) => {
        try {
            const { sales_invoice_no, e_inv_irn_no, e_inv_ack_no, e_inv_ewb_no } = invoiceItem
            setSalesInvoiceNo(sales_invoice_no)
            setInvoiceIrnNo(e_inv_irn_no)
            setInvoiceAckNo(e_inv_ack_no)
            setInvoiceEwbNo(e_inv_ewb_no)
            console.log('Company Settings: ', companySettings);
            console.log(txt_e_inv_auth_token);
            if (txt_e_inv_token_expiry !== '') {
                // Attempt to create a Date object from the string
                const dateObject = new Date(txt_e_inv_token_expiry);
                // Check if the date is valid
                if (!isNaN(dateObject.getTime())) {
                    console.log("Valid token:", dateObject);
                } else {
                    FnGetToken()
                }

            } else {
                FnGetToken()
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnGetToken = async () => {
        debugger;
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesInvoiceMasterTrading/FnGenerateTokenForEInvoice/${COMPANY_ID}`)
            const response = await apiCall.json();
            const { AuthToken, TokenExpiry } = response.data
            if (response.success === 1) {
                setInvoiceToken(AuthToken)
                setInvoiceTokenExpiry(TokenExpiry)
                setCompanySettings(response.company_settings)
            }
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    }

    const FnGenerateEInvoice = async () => {
        debugger
        console.log('Company Settings: ', companySettings);
        // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesInvoiceMasterTrading/FnGetSalesInvoiceDetails/${COMPANY_ID}?sales_invoice_no=${sales_invoice_no}`)
        // const response = await apiCall.json();
        const eInvoiceJson = {
            'Version': '1.1',
            'TranDtls': {
                'TaxSch': 'GST',
                'SupTyp': 'B2B',
                'RegRev': 'N',
                'EcmGstin': null,
                'IgstOnIntra': 'N'
            },
            'DocDtls': {
                'Typ': 'INV',
                'No': '23-24/0013',
                'Dt': '08/05/2023'
            },
            'SellerDtls': {
                'Gstin': '27AACFF6974E1ZA',
                'LglNm': 'FabriTek Engineers',
                'TrdNm': 'FabriTek Engineers',
                'Addr1': 'S 151 MIDC Behind Century Enka,',
                'Addr2': 'Bhosari, Maharashtra 411026',
                'Loc': 'MAHATASHTRA',
                'Pin': 411026,
                'Stcd': '27',
                'Ph': '2027122151',
                'Em': 'info@fabritekengineers.com'
            },
            'BuyerDtls': {
                'Gstin': '27AAACA4074D1Z2',
                'LglNm': 'Atlas Copco India Ltd (GAP)',
                'TrdNm': 'Atlas Copco India Ltd (GAP)',
                'Pos': '27',
                'Addr1': 'Gas & Process DivisionD1 BLOCK, Plot 24, Opp. Bird V',
                'Addr2': 'lley Garden, Telco Road 411019 MIDC, Chinchwad, Pun',
                'Loc': 'Maharashtra',
                'Pin': 411019,
                'Stcd': '27',
                'Ph': '8530187400',
                'Em': 'vivek.desai@atlascopco.com'
            },
            'DispDtls': {
                'Nm': 'FabriTek Engineers',
                'Addr1': 'S 151 MIDC Behind Century Enka,',
                'Addr2': 'Bhosari, Maharashtra 411026',
                'Loc': 'MAHATASHTRA',
                'Pin': 411026,
                'Stcd': '27'
            },
            'ShipDtls': {
                'Gstin': '27AAACA4074D1Z2',
                'LglNm': 'Atlas Copco India Ltd (GAP)',
                'TrdNm': 'Atlas Copco India Ltd (GAP)',
                'Addr1': 'Gas & Process DivisionD1 BLOCK, Plot 24, Opp. Bird V',
                'Addr2': 'lley Garden, Telco Road 411019 MIDC, Chinchwad, Pun',
                'Loc': 'Maharashtra',
                'Pin': 411019,
                'Stcd': '27'
            },
            'ItemList': [
                {
                    'SlNo': '1',
                    'PrdDesc': 'Base frame Print name DCBase frameTech Spect  SO',
                    'IsServc': 'N',
                    'HsnCd': '181818',
                    'Barcde': null,
                    'Qty': 5.00,
                    'FreeQty': 0,
                    'Unit': 'Nos',
                    'UnitPrice': 11.65,
                    'TotAmt': 58.25,
                    'Discount': 0,
                    'PreTaxVal': 0,
                    'AssAmt': 58.25,
                    'GstRt': 18.00,
                    'IgstAmt': 0.00,
                    'CgstAmt': 5.24,
                    'SgstAmt': 5.24,
                    'CesRt': 0.00,
                    'CesAmt': 0.00,
                    'CesNonAdvlAmt': 0.00,
                    'StateCesRt': 0,
                    'StateCesAmt': 0,
                    'StateCesNonAdvlAmt': 0,
                    'OthChrg': 0.00,
                    'TotItemVal': 68.73,
                    'OrdLineRef': null,
                    'OrgCntry': null,
                    'PrdSlNo': null,
                    'BchDtls': {
                        'Nm': 'N/A',
                        'ExpDt': '18/05/2023',
                        'WrDt': '09/05/2023'
                    },
                    'AttribDtls': [
                        {
                            'Nm': null,
                            'Val': null
                        }
                    ]
                }
            ],
            'ValDtls': {
                'AssVal': 58.25,
                'CgstVal': 5.24,
                'SgstVal': 5.24,
                'IgstVal': 0.00,
                'CesVal': 0.00,
                'StCesVal': 0.00,
                'Discount': 0,
                'OthChrg': 0.00,
                'RndOffAmt': 0.00,
                'TotInvVal': 68.73,
                'TotInvValFc': 68.73
            },
            'PayDtls': {
                'Nm': null,
                'AccDet': null,
                'Mode': null,
                'FinInsBr': null,
                'PayTerm': null,
                'PayInstr': null,
                'CrTrn': null,
                'DirDr': null,
                'CrDay': 0,
                'PaidAmt': 0,
                'PaymtDue': 0
            },
            'RefDtls': {
                'InvRm': '23-24/0013',
                'PrecDocDtls': [
                    {
                        'InvNo': '23-24/0013',
                        'InvDt': '08/05/2023',
                        'OthRefNo': null
                    }
                ],
                'ContrDtls': [
                    {
                        'RecAdvRefr': null,
                        'RecAdvDt': '08/05/2023',
                        'TendRefr': null,
                        'ContrRefr': null,
                        'ExtRefr': null,
                        'ProjRefr': null,
                        'PORefr': null,
                        'PORefDt': null
                    }
                ]
            },
            'ExpDtls': {
                'ShipBNo': null,
                'ShipBDt': '08/05/2023',
                'Port': null,
                'RefClm': 'N',
                'ForCur': null,
                'CntCode': null,
                'ExpDuty': 0
            }
        }
        setInvoiceJsons(eInvoiceJson)

        const forwardJson = { 'E-InvoiceJson': eInvoiceJson, invoiceNos: { sales_invoice_no: txt_sales_invoice_no, company_id: COMPANY_ID } }

        const formData = new FormData();
        formData.append(`E-InvoiceJson`, JSON.stringify(forwardJson))
        const request = { method: 'POST', body: formData }

        const eInvoiceJsonApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesInvoiceMasterTrading/FnGenerateEInvoice`, request)
        // const eInvoiceResponse = await eInvoiceJsonApiCall.json()
    }

    const generateEWayBill = () => {

    }

    const generateEWayBillAndEInvoice = () => {

    }

    const cancel = () => {

    }

    const debitNote = () => {

    }

    const creditNote = () => {

    }

    const renderSalesInvoiceDetailsTable = useMemo(() => {
        return <>
            {
                salesInvoiceMasterData.length !== 0 ? <>
                    <Table className={`erp_table erp_table_scroll mt-2`} id='salesInvoiceDetailsTbl' responsive bordered striped hover>
                        <thead className="erp_table_head">
                            <tr>

                                <th className='erp_table_th'>Customer Name</th>
                                <th className='erp_table_th'>Invoice No.</th>
                                <th className='erp_table_th'>Invoice Date</th>
                                <th className='erp_table_th'>Customer Order No.</th>
                                <th className='erp_table_th'>Customer Order Date</th>
                                <th className='erp_table_th'>Grand Total</th>
                                <th className='erp_table_th'>Location</th>
                                <th className="erp_table_th">IRN No.</th>
                                <th className="erp_table_th">IRN Date</th>
                                <th className="erp_table_th">Acknowledgement No.</th>
                                <th className="erp_table_th">Acknowledgement Date</th>
                                <th className="erp_table_th">E-Way Bill No.</th>
                                <th className="erp_table_th">E-WB Valid Till</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesInvoiceMasterData.map((invoiceItem) =>
                                    <tr onClick={() => FnGenerateToken(invoiceItem)}>
                                        <td className="erp_table_td">{invoiceItem.customer_name}</td>
                                        <td className="erp_table_td">{invoiceItem.sales_invoice_no}</td>
                                        <td className="erp_table_td">{invoiceItem.sales_invoice_date}</td>
                                        <td className="erp_table_td">{invoiceItem.customer_order_no}</td>
                                        <td className="erp_table_td">{invoiceItem.customer_order_Date}</td>
                                        <td className="erp_table_td">{invoiceItem.grand_total}</td>
                                        <td className="erp_table_td">{invoiceItem.sales_invoice_creation_type}</td>
                                        <td className="erp_table_td">{invoiceItem.e_inv_irn_no}</td>
                                        <td className="erp_table_td">{invoiceItem.e_inv_irn_date}</td>
                                        <td className="erp_table_td">{invoiceItem.e_inv_ack_no}</td>
                                        <td className="erp_table_td">{invoiceItem.e_inv_ack_date}</td>
                                        <td className="erp_table_td">{invoiceItem.e_inv_ewb_no}</td>
                                        <td className="erp_table_td">{invoiceItem.e_inv_ewb_valid_till_date}</td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>
                </> : <div className='erp_no_recrd_card'>
                    <label>No records found...</label>
                </div>
            }

        </>
    }, [salesInvoiceMasterData])



    return (
        <>
            <DashboardLayout>
                <div className='card mt-4 px-2 py-2'>
                    <div className='main_heding'>
                        <label className='erp-form-label-lg main_heding'>E-Invoicing & E-Way bills</label>
                    </div>
                    <div className='card px-2 py-2'>
                        <div className="row">
                            <div className="col-sm-3">
                                <Form.Label className="erp-form-label-md-lg">Status</Form.Label>
                            </div>
                            <div className="col-sm-9">
                                <div className="erp_form_radio">
                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Pending" type="radio" value="I" name="invoice_status" checked={invoice_status === "I"} onClick={() => { setInvoiceStatus("I"); }} /> </div>
                                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="Invoice Uploaded" type="radio" value="E" name="invoice_status" checked={invoice_status === "E"} onClick={() => { setInvoiceStatus("E"); }} /> </div>
                                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="E-Way Bill And E-Invoicing Uploaded" type="radio" value="B" name="invoice_status" checked={invoice_status === "B"} onClick={() => { setInvoiceStatus("B"); }} /> </div>
                                    <div className="ms-2"> <Form.Check className="erp_radio_button" label="Cancel" type="radio" value="C" name="invoice_status" checked={invoice_status === "C"} onClick={() => { setInvoiceStatus("C"); }} /> </div>
                                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="All" type="radio" value="A" name="invoice_status" checked={invoice_status === "A"} onClick={() => { setInvoiceStatus("A"); }} /> </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {renderSalesInvoiceDetailsTable}
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <Form.Label className="erp-form-label">JSON</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="card">
                                            <div className="card-body" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                {/* <h5 className="card-title">JSON Data</h5> */}
                                                <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
                                                    <ReactJson
                                                        src={invoiceJsons}
                                                        name={false}
                                                        displayDataTypes={false}
                                                        displayObjectSize={false}
                                                        indentWidth={2}
                                                        // collapsed={1}
                                                        collapsed={false} // Set to false to display JSON in an expanded state by default
                                                        enableClipboard={true}
                                                        displayArrayKey={false}
                                                        style={{ fontSize: '13px', fontWeight: '400', fontFamily: "Roboto" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Sales Invoice No</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_sales_invoice_no" className="erp_input_field" value={txt_sales_invoice_no} optional='optional' onChange={(e) => { setSalesInvoiceNo(e.target.value); }} />
                                    </div>
                                </div>

                                <div className='row mb-1'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">IRN No.</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_e_inv_irn_no" className="erp_input_field" value={txt_e_inv_irn_no} optional='optional' />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Acknowledgement No.</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_e_inv_ack_no" className="erp_input_field" value={txt_e_inv_ack_no} optional='optional' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">E-Way Bill No.</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_e_inv_ewb_no" className="erp_input_field" value={txt_e_inv_ewb_no} optional='optional' />

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Token</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_e_inv_auth_token" className="erp_input_field" value={txt_e_inv_auth_token} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Token Validity</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_e_inv_token_expiry" className="erp_input_field" value={txt_e_inv_token_expiry} disabled />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={FnGetToken} >Generate Token</MDButton>
                                    </div>
                                    {/* <div className="col-sm-3">
                                        <MDButton type="button" className={`erp-gb-button`} variant="button" onClick={validateJson}
                                            fontWeight="regular">JSON Validator</MDButton>
                                    </div> */}
                                    <div className="col-sm-4">
                                        <MDButton type="button" className={`erp-gb-button`}
                                            variant="button" onClick={FnGenerateEInvoice}
                                            fontWeight="regular">Generate E-Invoice</MDButton>
                                    </div>



                                    {/* <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={debitNote} >Debit Note</MDButton>
                                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={creditNote} >Credit Note</MDButton> */}

                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <MDButton className={`erp-gb-button`} onClick={generateEWayBill} variant="button" fontWeight="regular">Generate E-Way Bill</MDButton>&nbsp;
                                    </div>
                                    <div className="col-sm-3">
                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={generateEWayBillAndEInvoice} >Generate E-Way bill & E-Invoice</MDButton>

                                    </div>
                                    <div className="col-sm-3">
                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={cancel} >Cancel</MDButton>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </DashboardLayout>


        </>
    )
}

export default FrmEInvoicingAndEWayBillingEntry
