import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery'
import html2pdf from 'html2pdf.js';

// Imports React bootstrap & stylling required
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//File Imports & Generic components imports
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";

import { FiPrinter } from "react-icons/fi";

// React icons
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineSchedule } from "react-icons/ai";
import { MdDelete, MdRefresh } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";

// Popup modal components imports.
import FrmCity from "FrmGeneric/MCity/FrmCity";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import FrmMBOMEntry from "FrmGeneric/MBOMFilter/FrmMBOMEntry";
import { DatatableWithId } from "components/DataTable";
import ConfigConstants from "assets/Constants/config-constant";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import DocumentComponent from "Features/Document";
import SuccessModal from "components/Modals/SuccessModal";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { UserName } from "assets/Constants/config-constant";

export default function TYarnDispatchChallanEntry() {
    let expandedSalesOrderCombo = false;
    let expandedDispatchScheduleCombo = false;

    let docGroup = "Yarn Dispatch Challan";

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType = 'Transactions', deletionKey = false, cancellationKey = false } = state || {};
    const newIDList = idList;

    let requiredColsForFilter = ['product_name', 'product_tech_spect', 'product_unit_name', 'product_type_group',
        'product_packing_name', 'product_std_weight', 'product_hsn_sac_code', 'product_hsn_sac_rate', 'product_mrp',
        'product_id', 'product_unit_id', 'product_packing_id', 'product_hsn_sac_code_id',
    ]

    //for get the current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    // UseRefs Hooks.
    const validate = useRef();
    const comboDataFunc = useRef();
    const exlsExp = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef(null)
    const navigator = useNavigate();

    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')
    const [isApprove, setIsApprove] = useState(false);

    // Combo-box options hooks.
    const [dispatchChallanTypeOpts, setDispatchChallanTypeOpts] = useState([]);
    const [customerOpts, setCustomerOpts] = useState([]);
    const [consigneeOpts, setConsigneeOpts] = useState([]);
    const [transporterOpts, setTransporterOpts] = useState([]);
    const [stateOpts, setStateOpts] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [companyBranchOpts, setCompanyBranchOpts] = useState([]);
    const [expectedBranchCityOpts, setExpectedBranchCityOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [transporterCityOpts, setTransporterCityOpts] = useState([]);
    const [approvedByOpts, setApprovedByOpts] = useState([]);
    const [agentOpts, setAgentOpts] = useState([]);
    const [HSNCodeOpts, setHSNCodeOpts] = useState([]);
    // const [inspectionSummatryList, setInspectionSummaryList] = useState([])
    // const [inspectionDetailsList, setInspectionDetailsList] = useState([])
    //changes by tushar
    const [packingSummaryList, setPackingSummaryList] = useState([])

    const [PackingDetailsList, setPackingDetailsList] = useState([])
    const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);


    // Master Input Field Hooks.
    const [dispatch_challan_master_transaction_id, setDispatchChallanMasterTransactionId] = useState(newIDList !== null ? newIDList.dispatch_challan_master_transaction_id : 0);
    const [cmb_dispatch_challan_type_id, setDispatchChallanTypeId] = useState(17);
    const [rb_dispatch_challan_creation_type, setDispatchChallanCreationType] = useState('M');
    const [txt_dispatch_challan_no, setDispatchChallanNo] = useState(newIDList !== null ? newIDList.dispatch_challan_no : '0');
    const [dt_dispatch_challan_date, setDispatchChallanDate] = useState(today());
    const [txt_dispatch_challan_version, setDispatchChallanVersion] = useState(newIDList !== null ? newIDList.dispatch_challan_version : 1);
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_customer_state_id, setCustomerStateId] = useState('');
    const [cmb_customer_city_id, setCustomerCityId] = useState('');
    const [txt_customer_gst_no, setCustomerGSTNo] = useState('');
    const [txt_customer_pan_no, setCustomerPANNo] = useState('');
    const [cmb_expected_branch_id, setExpectedBranchId] = useState('');
    const [cmb_expected_branch_state_id, setExpectedBranchStateId] = useState('');
    const [cmb_expected_branch_city_id, setExpectedBranchCityId] = useState('');
    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState(today());
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeStateId] = useState('');
    const [cmb_consignee_city_id, setConsigneeCityId] = useState('');
    const [txt_consignee_gst_no, setConsigneeGSTNo] = useState('');
    const [txt_consignee_pan_no, setConsigneePANNo] = useState('');
    const [cmb_transporter_id, setTransporterId] = useState('');
    const [cmb_transporter_state_id, setTransporterStateId] = useState('');
    const [txt_transporter_gst_no, setTransporterGSTNo] = useState('');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [txt_vehicle_type, setVehicleType] = useState('');
    const [txt_transporter_challan_no, setTransporterChallanNo] = useState('');
    const [dt_transporter_challan_date, setTransporterChallanDate] = useState(today());
    const [cmb_transport_mode, setTransportMode] = useState('1');
    const [cmb_transaction_type, setTransactionType] = useState('1');
    const [rb_is_service, setIsService] = useState('1');
    const [rb_is_sez, setIsSez] = useState('1');
    const [rb_interim, setInterim] = useState('1');
    const [dt_loading_time, setLoadingTime] = useState(() => {
        const currentDate = new Date();
        currentDate.setMinutes((currentDate.getMinutes() + 30) - currentDate.getTimezoneOffset());
        return currentDate.toISOString().slice(0, 16);
    });
    const [cmb_approved_by_id, setApprovedById] = useState(parseInt(UserId));
    const [dt_approved_date, setApprovedDate] = useState(today());
    const [cmb_dispatch_challan_status, setDispatchChallanStatus] = useState('P');
    const [txt_dispatch_challan_remark, setDispatchChallanRemark] = useState('');
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [rb_is_active, setIsActive] = useState('true');

    // Footer Calculation Fields.
    const [txt_basic_total, setBasicTotal] = useState(0);
    const [txt_transport_amount, setTransportAmount] = useState(0);
    const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
    const [txt_freight_amount, setFreightAmount] = useState(0);
    const [cmb_freight_hsn_code_id, setFreightHSNCodeId] = useState('');
    const [txt_packing_amount, setPackingAmount] = useState(0);
    const [txt_discount_percent, setDiscountPercent] = useState(0);
    const [txt_discount_amount, setDiscountAmt] = useState(0);
    const [txt_other_amount, setOtherAmount] = useState(0);
    const [txt_taxable_total, setTaxableTotal] = useState(0);
    const [txt_cgst_total, setCGSTTotal] = useState(0);
    const [txt_sgst_total, setSGSTTotal] = useState(0);
    const [txt_igst_total, setIGSTTotal] = useState(0);
    const [txt_roundoff, setRoundOff] = useState(0);
    const [txt_grand_total, setGrandTotal] = useState(0);
    const [cmb_agent_id, setAgentId] = useState('');
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [txt_agent_amount, setAgentAmount] = useState(0);
    const [cmb_agent_paid_status, setAgentPaidStatus] = useState('P');

    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);
    const [custContactDetailsCols, setCustContactDetailsCols] = useState([]);

    // Details table hooks.
    const [dispatchChallanMaterials, setDispatchChallanMaterials] = useState([]);
    const [productUnitOpts, setProductUnitOpts] = useState([]);

    // Batch-wise stock Table Data Hooks
    let [batchWiseStockTblData, setBatchWiseStockTblData] = useState([]);

    // Taxation Table Data Hooks
    let [taxationSummaryData, setTaxationSummaryData] = useState([]);

    // For Uploaded Document Table
    const [uploadedDocData, setUploadedDocData] = useState([]);

    // Popup Modal Fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [modalMsg, setModalMsg] = useState('');
    const [modalConfirmationAction, setModalConfirmationAction] = useState('');

    //Error Msg
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const FnCloseErrModal = () => setShowErrorMsgModal(false);
    const [errMsg, setErrMsg] = useState("");

    // Fn to get Inspection modal with items
    const [showSetNosModal, setViewSetNosModal] = useState(false);
    // changes by tushar
    // Fn to get Yarn packing  modal with items
    const [showPackingDetailsModal, setViewPackingDetailsModal] = useState(false);

    // Confirmation Modal fields
    const [modalOrderDetails, setModalOrderDetails] = useState('')
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = () => {
        setShowConfiemationModal(false);
        moveToListing();
    }

    // Succ Msg Modal
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TYarnDispatchChallan/TYarnDispatchChallanListing/reg' : `/Transactions/TYarnDispatchChallan/TYarnDispatchChallanListing`;
        navigator(Path);
    }

    // Document Form Modal
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const viewDocumentForm = () => { setShowDocumentForm(true); }
    const FnCloseDocumentUploadModal = async () => {
        setShowDocumentForm(false)
        await showDocumentRecords();
    };

    // Add new record in masters
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const FnCloseAddRecordModal = async () => {
        try {
            switch (modalHeaderName) {
                case 'Dispatch Challan Type':
                    resetGlobalQuery();
                    globalQuery.columns = ["field_id", "field_name", "product_type_short_name"];
                    globalQuery.table = "smv_product_type";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["WV"] });
                    const productTypes = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    setDispatchChallanTypeOpts(productTypes);
                    break;

                case 'Add Consignee City':
                    if (cmb_consignee_state_id !== '') {
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name'];
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: cmb_consignee_state_id });
                        const getConsigneeUpdatedCities = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                        setConsigneeCityOpts(getConsigneeUpdatedCities);
                    }
                    break;

                default:
                    break;

            }
        } catch (error) {
            console.log('Error in refreshing combos data: ', error);
            navigator('/Error')
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }

    const FnRenderAddNewRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Dispatch Challan Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;

            case 'Add Consignee City':
                return <FrmCity btn_disabled={true} />;

            default:
                return null;
        }
    }

    // Bom Filter Modal
    const [showBomFilter, setShowBomFilter] = useState(false);
    const FnOpenFilter = async () => {
        let checkIsValidate = await validate.current.validateForm("DispatchChallanHeaderFormId");
        if (checkIsValidate === true && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            sessionStorage.setItem('requiredCols', JSON.stringify(requiredColsForFilter));
            setShowBomFilter(true);
        }
    }

    useEffect(() => {
        debugger
        const loadDataOnload = async () => {
            console.log('Data From Listing: ', newIDList);
            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');
            await ActionType()
            await FnFillComobos();
            if (newIDList !== null) {
                await FnCheckUpdateResponse()
            }
            await ActionType();
            $('#spinner_id').hide();
        }
        loadDataOnload();

        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            let custOrdContainer = $("#customer-order-ul");
            if (!custOrdContainer.is(e.target) && custOrdContainer.has(e.target).length === 0) {
                custOrdContainer.hide();
            }

            let dispatchSchContainer = $("#dispatch-schedule-ul");
            if (!dispatchSchContainer.is(e.target) && dispatchSchContainer.has(e.target).length === 0) {
                dispatchSchContainer.hide();
            }
        });
    }, [])

    // For generate the dispatch_challan_order number.
    const FnGenerateDispatchChallanNo = async (product_typeShortName) => {
        // const ptShortName = dispatchChallanTypeOpts.find((item) => item.field_id === parseInt($("#cmb_dispatch_challan_type_id").val()));
        // const productTypeId = parseInt($("#cmb_dispatch_challan_type_id").val());
        const ptShortName = product_typeShortName === undefined ? $('#cmb_dispatch_challan_type_id option:selected').attr('shortname') : product_typeShortName;
        const productTypeId = parseInt($('#cmb_dispatch_challan_type_id').val());
        const dispatchChallanNo = await generateAutoNoAPiCall.current.generateTAutoNo("mt_dispatch_challan_master_trading", "dispatch_challan_no", ptShortName, 'DC', "5", "dispatch_challan_type_id", productTypeId);
        setDispatchChallanNo(dispatchChallanNo);
        // setDispatchChallanVersion(1);
        return dispatchChallanNo;
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'view':
                if (deletionKey === true) {
                    setActionType('(Deletion)');
                } else if (cancellationKey === true) {
                    setActionType('(Cancellation)');
                } else {
                    setActionType('(View)');
                }
                $('#save-btn').attr('disabled', 'disabled');
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('input[type="checkbox"]').attr("disabled", "disabled");
                $('#selectAllCustContact').attr("disabled", "disabled");
                $('.selectCustContactPerson').attr("disabled", "disabled");
                break;

            default:
                setActionType('(Creation)');
                setActionLabel('Save')
                break;
        }
    };

    const FnFillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["WV"] });
            const productTypeList = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setDispatchChallanTypeOpts(productTypeList);
            console.log("Product Type List: ", productTypeList);
            if (productTypeList.length !== 0 && keyForViewUpdate === "") {
                const productType = productTypeList.find((item) => item.product_type_short_name === "YN");
                if (productType && keyForViewUpdate === '') {
                    setDispatchChallanTypeId(productType.field_id);
                    await FnGenerateDispatchChallanNo(productType.product_type_short_name);
                }
            }

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code'];
            globalQuery.table = "cmv_state"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getStatesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setStateOpts(getStatesApiCall);
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(stateList => {
                    setStateOpts(stateList);
                });

            resetGlobalQuery();
            globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id"]
            globalQuery.table = "cmv_company_branch"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCompanyBranchesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setCompanyBranchOpts(getCompanyBranchesApiCall);
            setExpectedBranchId(parseInt(COMPANY_BRANCH_ID));
            const branchInSession = getCompanyBranchesApiCall.find((branch) => branch.company_branch_id === parseInt(COMPANY_BRANCH_ID));
            if (branchInSession !== null) {
                setExpectedBranchStateId(branchInSession.branch_state_id)
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(branchInSession.branch_state_id) });
                const cityApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                setExpectedBranchCityOpts(cityApiCall);
                setExpectedBranchCityId(branchInSession.branch_city_id)
            }

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getCustomersApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setCustomerOpts(getCustomersApiCall);
            // setConsigneeOpts(getCustomersApiCall);
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(customerList => {
                    setCustomerOpts(customerList);
                    const consignees = customerList.filter(customer => customer.cust_branch_type === "Consignee");
                    setConsigneeOpts(consignees);
                });


            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "transporter_gst_no", "transporter_state_id", "transporter_city_id"]
            globalQuery.table = "cmv_transporter"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(transporterList => {
                    setTransporterOpts(transporterList);
                });

            // const getProductUnitsApiCall = await comboDataFunc.current.fillMasterData("smv_product_unit ", "is_delete", "0");
            // setProductUnitOpts(getProductUnitsApiCall);
            comboDataFunc.current.fillMasterData("smv_product_unit", "is_delete", "0")
                .then(unitList => {
                    setProductUnitOpts(unitList);
                });

            // const getAgentsApiCall = await comboDataFunc.current.fillMasterData("cmv_agent", "is_delete", "0");
            // setAgentOpts(getAgentsApiCall);
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
            globalQuery.table = "cmv_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(agentList => {
                    setAgentOpts(agentList);
                    // Set the Default Agent as NA and load it's releated %.
                    const defaultAgent = agentList.find(agent => agent.field_name === 'NA');
                    if (defaultAgent) {
                        setAgentId(defaultAgent.field_id);
                        setAgentPercent(defaultAgent.agent_std_percent);
                    }
                });

            resetGlobalQuery();
            globalQuery.columns = ["hsn_sac_id", "hsn_sac_code", "hsn_sac_rate"]
            globalQuery.table = "cmv_hsn_sac"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getHSNCodesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setHSNCodeOpts(getHSNCodesApiCall);
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(hsnList => {
                    setHSNCodeOpts(hsnList);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const getEmployeesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            // setApprovedByOpts(getEmployeesApiCall)
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(employeeList => {
                    setApprovedByOpts(employeeList);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigator('/Error')
        }
    }

    // Function for the dependent-combo changes.
    const FnComboOnChange = async (comboName) => {
        try {
            let challanCreationType = $('input[name="rb_dispatch_challan_creation_type"]:checked').val();
            switch (comboName) {
                case 'ChallanCreationType':
                    // First remove all materials from the details-section also recalculate the calculations.
                    setDispatchChallanMaterials([]);
                    // FnCalculateGrandTotal([]);
                    FnCalculateTaxationSummary([]);

                    // setDispatchChallanCreationType(challanCreationType);
                    if (challanCreationType !== 'M') {
                        await FnLoadMultiSelectComboData('Customer');
                    }

                    break;

                case 'DispatchChallanType':
                    let selectedDispatchChallanType = parseInt($('#cmb_dispatch_challan_type_id').val());
                    if (selectedDispatchChallanType !== 0 && !isNaN(selectedDispatchChallanType)) {
                        setDispatchChallanTypeId(selectedDispatchChallanType);
                        await FnGenerateDispatchChallanNo();
                        $('#error_cmb_dispatch_challan_type_id').hide();
                        if (challanCreationType !== 'M') {
                            await FnLoadMultiSelectComboData('Customer');
                        }

                    } else if (selectedDispatchChallanType === 0) {
                        setDispatchChallanTypeId('');
                        setDispatchChallanNo('0')
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Dispatch Challan Type')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css("padding-top", "0px");
                        }, 100)
                    } else {
                        setDispatchChallanTypeId('');
                        setDispatchChallanNo('0');
                        setDispatchChallanVersion(0);
                    }
                    break;

                case 'CompanyBranch':
                    let companyBranchID = parseInt($('#cmb_expected_branch_id').val());
                    if ($('#cmb_expected_branch_id').val() === '0') {
                        const newTab = window.open('/Masters/Company', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (!isNaN(companyBranchID)) {
                        setExpectedBranchId(companyBranchID);
                        const selectedBranchObj = companyBranchOpts.find((branch) => branch.company_branch_id === companyBranchID);
                        if (selectedBranchObj) {
                            setExpectedBranchStateId(selectedBranchObj.branch_state_id);
                            const cityApiCall = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", (selectedBranchObj.branch_state_id).toString());
                            setExpectedBranchCityOpts(cityApiCall);
                            setExpectedBranchCityId(selectedBranchObj.branch_city_id)
                        }
                        // Update the calculations as per states.
                        FnUpdateMaterialCalculationOnStateChange(dispatchChallanMaterials);
                    }
                    break;

                case 'CompanyBranchState':
                    let selectedBranchState = parseInt($('#cmb_expected_branch_state_id').val());
                    if (selectedBranchState !== "") {
                        resetGlobalQuery();
                        globalQuery.columns = ["field_id", "field_name"]
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(selectedBranchState) });
                        const getCompanyCitiesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                        setExpectedBranchCityOpts(getCompanyCitiesApiCall);
                        if (getCompanyCitiesApiCall.length <= 0) { setExpectedBranchCityId(''); }

                        // Update the calculations as per states.
                        FnUpdateMaterialCalculationOnStateChange(dispatchChallanMaterials);
                    } else {
                        setExpectedBranchCityOpts([]);
                    }
                    break;

                case 'Customer':
                    let customer = parseInt($('#cmb_customer_id').val());
                    if ($('#cmb_customer_id').val() === '0') {
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                        setCustContactDetailsCols([]);
                        setCustContactDetails([]);
                        setcustomerOrderNos(0);
                    } else if (customer !== "0" && !isNaN(customer)) {
                        setCustomerId(customer);
                        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
                        setIsSez(customerIsSez === 'true' ? '0' : '1');

                        resetGlobalQuery();
                        globalQuery.columns = ["cust_branch_state_id", "cust_branch_city_id"];
                        globalQuery.table = "cmv_customer";
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customer });
                        const getCustomerDetailsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                        if (getCustomerDetailsApiCall.length > 0) {
                            setCustomerStateId(getCustomerDetailsApiCall[0].cust_branch_state_id);
                            $('#error_cmb_customer_state_id').hide();
                            await FnComboOnChange('CustomerState');
                            setCustomerCityId(getCustomerDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setCustomerStateId('');
                            setCustomerCityId('');
                            setCustomerCityOpts([]);
                        }

                        // Get customer-contact persons ids.
                        FnGetCustomersContactPersonsDetails(parseInt(customer));
                        let challanCreationType = $('input[name="rb_dispatch_challan_creation_type"]:checked').val();
                        if (challanCreationType !== 'M') {
                            await FnLoadMultiSelectComboData('Customer');
                        }

                        // Update the calculations as per states.
                        FnUpdateMaterialCalculationOnStateChange(dispatchChallanMaterials);
                        setcustomerOrderNos(0);

                    } else {
                        setCustomerStateId('');
                        setCustomerCityId('');
                        setCustomerCityOpts([]);
                        setCustContactDetailsCols([]);
                        setCustContactDetails([]);
                    }
                    $('#error_customer_contact_persons').hide();
                    break;

                case 'CustomerState':
                    let customerState = parseInt($('#cmb_customer_state_id').val());
                    if (customerState !== null && !isNaN(customerState)) {
                        resetGlobalQuery();
                        globalQuery.columns = ["field_id", "field_name"]
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                        const getCustomerCitiesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                        setCustomerCityOpts(getCustomerCitiesApiCall);
                        if (getCustomerCitiesApiCall.length <= 0) {
                            setCustomerCityId('');
                        }
                        // Update the calculations as per states.
                        FnUpdateMaterialCalculationOnStateChange(dispatchChallanMaterials);
                    } else {
                        setCustomerCityId('');
                        setCustomerCityOpts([]);
                    }
                    break;

                case 'Consignee':
                    let consignee = parseInt($('#cmb_consignee_id').val());
                    if ($('#cmb_consignee_id').val() === '0') {
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (consignee !== "0" && !isNaN(consignee)) {
                        setConsigneeId(consignee);
                        resetGlobalQuery();
                        globalQuery.columns = ["cust_branch_state_id", "cust_branch_city_id"];
                        globalQuery.table = "cmv_customer";
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: consignee });
                        const getConsigneeDetailsApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

                        if (getConsigneeDetailsApiCall.length > 0) {
                            setConsigneeStateId(getConsigneeDetailsApiCall[0].cust_branch_state_id);
                            $('#error_cmb_consignee_state_id').hide();
                            await FnComboOnChange('ConsigneeState');
                            setConsigneeCityId(getConsigneeDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setConsigneeStateId('');
                            setConsigneeCityId('');
                            setConsigneeCityOpts([]);
                        }
                    } else {
                        setConsigneeStateId('');
                        setConsigneeCityId('');
                        setConsigneeCityOpts([]);
                    }
                    break;

                case 'ConsigneeState':
                    let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                    if (consigneeState !== null && !isNaN(consigneeState)) {
                        resetGlobalQuery();
                        globalQuery.columns = ["field_id", "field_name"]
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(consigneeState) });
                        const getConsigneeCitiesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                        setConsigneeCityOpts(getConsigneeCitiesApiCall);
                        if (getConsigneeCitiesApiCall.length <= 0) {
                            setConsigneeCityId('');
                        }
                    } else {
                        setConsigneeCityId('');
                        setConsigneeCityOpts([]);
                    }
                    break;

                case 'ConsigneeCity':
                    if ($('#cmb_consignee_city_id').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Consignee City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setConsigneeCityId('');
                    }
                    break;

                case 'Agent':
                    let selectedAgent = $('#cmb_agent_id').val();
                    if ($('#cmb_agent_id').val() === "0") {
                        const newTab = window.open('/Masters/Agent', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (selectedAgent !== '0' && selectedAgent !== '') {
                        const selectedAgentData = agentOpts.find(agent => parseInt(agent.field_id) === parseInt(selectedAgent));
                        if (selectedAgentData) {
                            setAgentPercent(selectedAgentData.agent_std_percent);
                        } else {
                            setAgentPercent(0);
                        }
                    } else {
                        setAgentPercent(0);
                    }
                    break;

                case 'Transporter':
                    let selectedTransporter = parseInt($('#cmb_transporter_id').val());
                    if ($('#cmb_transporter_id').val() === '0') {
                        const newTab = window.open('/Masters/Transporter', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (selectedTransporter !== "0" && !isNaN(selectedTransporter)) {
                        setTransporterId(selectedTransporter);
                        const selectedTransporterData = transporterOpts.find((item) => parseInt(item.field_id) === selectedTransporter);
                        if (selectedTransporter) {
                            setTransporterStateId(selectedTransporterData.transporter_state_id);
                        } else {
                            setTransporterStateId('');
                        }
                    } else {
                        setTransporterStateId('');
                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("error in combo-change: ", error)
            navigator('/Error')
        }
    }

    // For update the combobox data after add new record.
    const FnRefreshComboData = async (key) => {
        if (keyForViewUpdate === 'view') {
            return false;
        }
        switch (key) {
            case 'UpdateCompanyBranches':
                resetGlobalQuery();
                globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id"]
                globalQuery.table = "cmv_company_branch"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getCompanyBranchesApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setCompanyBranchOpts(getCompanyBranchesApiCall);
                break;

            case 'UpdateConsignees':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez', "cust_branch_gst_no", 'cust_branch_type'];
                globalQuery.table = "cmv_customer_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "cust_branch_type", operator: "=", value: 'Consignee' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedConsigneesData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setConsigneeOpts(getUpdatedConsigneesData);
                break;

            case 'UpdateCustomers':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
                globalQuery.table = "cmv_customer_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setCustomerOpts(getUpdatedCustomerData);
                break;

            case 'UpdateAgents':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
                globalQuery.table = "cmv_agent"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAgentData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setAgentOpts(getUpdatedAgentData);
                break;

            case 'UpdateTransporters':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "transporter_gst_no", "transporter_state_id", "transporter_city_id"]
                globalQuery.table = "cmv_transporter"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getTransporterApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                setTransporterOpts(getTransporterApiCall);
                break;
            default:
                break;
        }

    }

    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);
                // comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                // .then(custContactList => {
                //     setCustContactDetails(custContactList);
                // });

                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                return getContactDtlsApiCall;
            } else {
                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            // navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            FnCheckBoxesSelection('PartiallyCustContactSelection');
        });
    }

    function FnCheckBoxesSelection(key) {
        switch (key) {
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'selectAllSetNosRecords':
                $('.selectInspectionRecord').prop('checked', $('#selectAllSetNosRecords').is(":checked"));
                break;
            case 'PartiallySelectInspectionRecord':
                $('#selectAllSetNosRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);
                break;

            default:
                break;
        }
    }

    // --------------------------------For Multi-Select checkbox.-----------------------------------
    const [customerOrderNoList, setCustomerOrderNoList] = useState([]);
    const [customerOrderNos, setcustomerOrderNos] = useState(0);

    const [dispatchSchedulesList, setDispatchSchedulesList] = useState([]);
    const [selectedDispatchSchedulesNos, setSelectedDispatchSchedulesNos] = useState(0);

    // Function to load the data for the combo-box from the db.
    const FnLoadMultiSelectComboData = async (comboName) => {
        $('#spinner_id').show();
        $('#spinner_text').text('Loading...');
        try {
            switch (comboName) {
                case 'Customer':
                    const customerval = document.getElementById("cmb_customer_id").value
                    // if ($('#cmb_customer_id').val() !== "" || $('#cmb_customer_id').val() !== "0") {
                    if (customerval !== "" || customerval !== "0") {
                        resetGlobalQuery();
                        globalQuery.columns = ["sales_order_master_transaction_id", "sales_order_no", "sales_order_date", "sales_order_version", "customer_order_no"]
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerval });
                        globalQuery.conditions.push({ field: "product_type_short_name", operator: "=", value: "YN" });
                        // globalQuery.conditions.push({ field: "sales_order_type", operator: "=", value: "Yarn" });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                        globalQuery.conditions.push({ field: "sales_order_status", operator: "IN", values: ['A', 'I'] });
                        const getCustomerOrderNos = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

                        let customerOrderOpts = getCustomerOrderNos.map(FnRenderCustomerOrderNosCheckbox);
                        setCustomerOrderNoList(customerOrderOpts);
                        if (cmb_customer_id !== '' && cmb_customer_id !== '0' && customerOrderOpts.length <= 0) {
                            $('#error_customer_orders_list').show();
                            $('#error_customer_orders_list').text('No customer orders are available...!');
                        } else {
                            $('#error_customer_orders_list').hide();
                        }
                        $('#selectAllCustomerOrders').prop('checked', false);
                        $('.selectCustomerOrder').prop('checked', false);
                    }
                    break;

                case 'CustomerOrder':
                    // If challan creation type is dispatch-schedule-based.
                    if (rb_dispatch_challan_creation_type === 'D') {
                        // First collect the selected customer-order-nos to get dispatch schedules.
                        let customerOrderNosJson = { 'cust_order_nos': [] }
                        $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                            let checkBoxMstId = $(this).val();
                            if (checkBoxMstId !== undefined) {
                                customerOrderNosJson.cust_order_nos.push(checkBoxMstId);
                            }
                        });

                        if (customerOrderNosJson.cust_order_nos.length > 0) {
                            const formData = new FormData();
                            formData.append(`customerOrderNos`, JSON.stringify(customerOrderNosJson))
                            const requestOptions = { method: 'POST', body: formData };
                            const getDispatchSchedulesAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnShowDispatchScheduleMasterNos/${COMPANY_ID}`, requestOptions)
                            const dispatchShedulesResponse = await getDispatchSchedulesAPICall.json();

                            console.log('dispatchShedulesResponse list: ', dispatchShedulesResponse);
                            if (dispatchShedulesResponse.success === '1') {
                                // Then Fill that received data into dispatch-schedules-list
                                let dispatchSheduleOpts = dispatchShedulesResponse.data?.map(FnRenderDispatchScheduleNosCheckbox);
                                setDispatchSchedulesList(dispatchSheduleOpts)
                            }
                            if (dispatchShedulesResponse.data.length <= 0) {
                                $('#error_dispatch_notes_list').show();
                                $('#error_dispatch_notes_list').text('No dispatch schedules are available...!');
                            } else {
                                $('#error_dispatch_notes_list').hide();
                            }
                            $('#selectAllDispatchShedules').prop('checked', false);
                            $('.selectDispatchShedule').prop('checked', false);
                        } else {
                            // Then remove all materials and update the calculations.
                            // setDispatchChallanMaterials([]);
                            // // FnCalculateGrandTotal([]);
                            // FnCalculateTaxationSummary([]);
                            $('#error_dispatch_notes_list').hide();
                        }
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log("error in multi-select-combo-change: ", error)
            navigator('/Error')
        } finally {
            $('#spinner_id').hide();
        }
    }

    // Function for create html of for the select-box option in sales_order_nos.
    function FnRenderCustomerOrderNosCheckbox(salesOrder, index) {
        // This case for if index one then before that add the select all options option.
        if (index === 0) {
            return (
                <>
                    <li className="item customer_order_no" key={`All-${index}`}>
                        <span className="checkbox">
                            <input type="checkbox" id="selectAllCustomerOrders" value={index} className="erp_radio_button filterCheckBox"
                                onChange={(event) => { FnMultiSelectComboBoxOptionSelection('CustomerOrderNos', event.target.value); FnLoadMultiSelectComboData('CustomerOrder'); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} optional='optional' />
                        </span> <span className="item-text">All</span>
                    </li>
                    <li className="item customer_order_no" key={index}>
                        <span className="checkbox">
                            <input type="checkbox" name="customerOrderNoCheckBox" value={salesOrder.customer_order_no} mst_id={salesOrder.sales_order_master_transaction_id} id={`filterCheckCustOrd-${salesOrder.customer_order_no}`} className="erp_radio_button selectCustomerOrder"
                                onChange={(event) => { FnMultiSelectComboBoxOptionSelection('CustomerOrderNos', event.target.value); FnLoadMultiSelectComboData('CustomerOrder'); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} optional='optional' />
                        </span> <span className="item-text">{salesOrder.customer_order_no}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item customer_order_no" key={index}>
                <span className="checkbox">
                    <input type="checkbox" fieldName={salesOrder.customer_order_no} name="customerOrderNoCheckBox" value={salesOrder.customer_order_no} id={`filterCheckCustOrd-${salesOrder.customer_order_no}`} mst_id={salesOrder.sales_order_master_transaction_id}
                        className="erp_radio_button selectCustomerOrder" onChange={(event) => { FnMultiSelectComboBoxOptionSelection('CustomerOrderNos', event.target.value); FnLoadMultiSelectComboData('CustomerOrder'); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} optional='optional' />
                </span> <span className="item-text">{salesOrder.customer_order_no}</span>
            </li>
        );
    }

    // Function for create html of for the select-box option in dispatch_schedule_nos.
    function FnRenderDispatchScheduleNosCheckbox(dispatchSchedule, index) {
        // dispatchSchedule is a array it's at 0 position schedule_no and at 1 position schedule_ver
        // This case for if index one then before that add the select all options option.
        if (index === 0) {
            return (
                <>
                    <li className="item dispatch_schedule_no" key={`All-${index}`}>
                        <span className="checkbox">
                            <input type="checkbox" id="selectAllDispatchShedules" value={index} className="erp_radio_button filterCheckBox"
                                onChange={(event) => { FnMultiSelectComboBoxOptionSelection('DispatchScheduleNos', event.target.value); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} optional='optional' />
                        </span> <span className="item-text">All</span>
                    </li>
                    <li className="item dispatch_schedule_no" key={index}>
                        <span className="checkbox">
                            <input type="checkbox" name="dispatchScheduleNoCheckBox" value={dispatchSchedule[0]} mst_id={dispatchSchedule[1]} id={`filterCheckDispSch-${dispatchSchedule[0]}`} className="erp_radio_button selectDispatchShedule"
                                onChange={(event) => { FnMultiSelectComboBoxOptionSelection('DispatchScheduleNos', event.target.value); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} optional='optional' />
                        </span> <span className="item-text">{dispatchSchedule[0]}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item dispatch_schedule_no" key={index}>
                <span className="checkbox">
                    <input type="checkbox" fieldName={dispatchSchedule[0]} name="dispatchScheduleNoCheckBox" value={dispatchSchedule[0]} id={`filterCheckDispSch-${dispatchSchedule[0]}`} mst_id={dispatchSchedule[1]}
                        className="erp_radio_button selectDispatchShedule" onChange={(event) => { FnMultiSelectComboBoxOptionSelection('DispatchScheduleNos', event.target.value); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} optional='optional' />
                </span> <span className="item-text">{dispatchSchedule[0]}</span>
            </li>
        );
    }

    // Function for the select the options in the multiselect combo.
    const FnMultiSelectComboBoxOptionSelection = (comboType, checkBoxValue) => {
        switch (comboType) {
            case 'CustomerOrderNos':
                if (parseInt(checkBoxValue) === 0) {
                    // For select all.
                    $('.selectCustomerOrder').prop('checked', $('#selectAllCustomerOrders').is(":checked"));
                    if ($('#selectAllCustomerOrders').is(":checked")) {
                        let checkedSalesOrdersOpts = $('input:checkbox.selectCustomerOrder:checked').length;
                        setcustomerOrderNos(checkedSalesOrdersOpts)
                    } else {
                        setcustomerOrderNos(0);
                    }
                } else {
                    //Check partially the sales_orders_nos.
                    let totalSalesOrdersOpts = $('input:checkbox.selectCustomerOrder').length;
                    let checkedSalesOrdersOpts = $('input:checkbox.selectCustomerOrder:checked').length;
                    $('#selectAllCustomerOrders').prop('checked', checkedSalesOrdersOpts === totalSalesOrdersOpts);
                    setcustomerOrderNos(checkedSalesOrdersOpts)
                }
                break;

            case 'DispatchScheduleNos':
                if (parseInt(checkBoxValue) === 0) {
                    // For select all.
                    $('.selectDispatchShedule').prop('checked', $('#selectAllDispatchShedules').is(":checked"));
                    if ($('#selectAllDispatchShedules').is(":checked")) {
                        let checkedDispatchSchedulesOpts = $('input:checkbox.selectDispatchShedule:checked').length;
                        setSelectedDispatchSchedulesNos(checkedDispatchSchedulesOpts)
                    } else {
                        setSelectedDispatchSchedulesNos(0);
                    }
                } else {
                    //Check partially the sales_orders_nos.
                    let totalDispatchSchedulesOpts = $('input:checkbox.selectDispatchShedule').length;
                    let checkedDispatchSchedulesOpts = $('input:checkbox.selectDispatchShedule:checked').length;
                    $('#selectAllDispatchShedules').prop('checked', checkedDispatchSchedulesOpts === totalDispatchSchedulesOpts);
                    setSelectedDispatchSchedulesNos(checkedDispatchSchedulesOpts)
                }
                break;
            default:
                break;
        }
    }

    // Function for hide and show the multi-select combos.
    const FnToggleMultiSelectCombos = (comboName) => {
        switch (comboName) {
            case "customerOrderNos":
                let custOrdCheckBoxesContainer = $('#customer-order-ul');
                let custOrdAllCheckBoxes = $('.customer_order_no');

                if (!expandedSalesOrderCombo && custOrdAllCheckBoxes.length !== 0) {
                    custOrdCheckBoxesContainer.css('display', 'block');
                    expandedSalesOrderCombo = true;
                } else {
                    custOrdCheckBoxesContainer.css('display', 'none');
                    expandedSalesOrderCombo = false;
                }
                break;

            case "dispatchScheduleNos":
                let checkBoxesContainer = $('#dispatch-schedule-ul');
                let allCheckBoxes = $('.dispatch_schedule_no');

                if (!expandedSalesOrderCombo && allCheckBoxes.length !== 0) {
                    checkBoxesContainer.css('display', 'block');
                    expandedDispatchScheduleCombo = true;
                } else {
                    checkBoxesContainer.css('display', 'none');
                    expandedDispatchScheduleCombo = false;
                }
                break;

            default:
                break;
        }
    }

    // Ends--------------------------------For Multi-Select checkbox.


    // --------------------------Functions for the details section----------------------
    function scrollToTableRow() {
        let keywords = $("#txt-detail-data-highliter").val();
        if (keywords !== '') {
            // First remove highlight class from where it had applied.
            $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");

            let table = document.getElementById('salesOrdMaterialDtlsTbl');
            let tblRows = table.getElementsByTagName('tr');
            for (let trNum = 1; trNum < tblRows.length; trNum++) {
                let row = tblRows[trNum];
                if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                    let w = $(window);
                    let row = $('#salesOrdMaterialDtlsTbl').find('tr').eq(trNum);
                    if (row.length) {
                        w.scrollTop(row.offset().top - (w.height() / 2));
                    }
                    $(row).find('td').each(function () {
                        $(this).addClass('highlight');
                    });
                    break;
                } else {
                    // $(row).find('td').each(function () { $(this).removeClass('highlight'); });
                    $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");
                }
            }
        } else {
            $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");
        }
    }

    //------------------------ Functions for filling the dispatch-challan-materials table.
    const FnCloseBomFilter = () => {
        setShowBomFilter(false)
        // let addedMaterialsForDispatch = JSON.parse(sessionStorage.getItem('bomfilteredData'));
        const addedMaterialsForDispatch = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        if (addedMaterialsForDispatch !== null && addedMaterialsForDispatch !== undefined) {
            FnConvert2ChallanDetails('Filter2ChallanDetails', addedMaterialsForDispatch);

            // Remove the received data from filter popup.
            sessionStorage.removeItem('filteredMaterialData');
        }
    }

    // Function for get the order-materials from the customer-order when challan creation type is sales-order-based.
    const FnShowMaterialsFromCustomerOrders = async () => {
        $('#spinner_id').show();
        $('#spinner_text').text('Loading order details...');

        try {
            let customerOrderNosJson = { 'cust_order_nos': [] }
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                let checkBoxMstId = $(this).val();
                if (checkBoxMstId !== undefined) {
                    customerOrderNosJson.cust_order_nos.push(checkBoxMstId);
                }
            });

            if (customerOrderNosJson.cust_order_nos.length > 0) {
                const formData = new FormData();
                formData.append(`customerOrderNos`, JSON.stringify(customerOrderNosJson))
                const requestOptions = { method: 'POST', body: formData };
                const getCustomerOrderDetaiAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnShowSalesOrderDetails/${COMPANY_ID}`, requestOptions)
                const customerOrderDataiAPICallResponse = await getCustomerOrderDetaiAPICall.json();

                console.log(customerOrderDataiAPICallResponse);
                if (customerOrderDataiAPICallResponse.success === '1') {
                    FnConvert2ChallanDetails('SalesOrder2ChallanDetails', customerOrderDataiAPICallResponse.data);
                }
            } else {
                // Then remove all materials and update the calculations.
                setDispatchChallanMaterials([]);
                FnCalculateTaxationSummary([]);
            }
            $('#spinner_id').hide();
        } catch (error) {
            $('#spinner_id').hide();
            console.log("error in getting order-details from the customer order: ", error)
            navigator('/Error')
        }

    }

    // Function for get the order-materials from the dispatch-shedules when challan creation type is dispatch-shedule-based.
    const FnShowMaterialsFromDispatchSchedules = async () => {
        debugger
        $('#spinner_id').show();
        $('#spinner_text').text('Loading order details...');

        try {
            let dispatchScheduleNosJson = { 'dispatch_schedule_nos': [] }
            $("input:checkbox[name=dispatchScheduleNoCheckBox]:checked").each(function () {
                let checkBoxMstId = $(this).val();
                if (checkBoxMstId !== undefined) {
                    dispatchScheduleNosJson.dispatch_schedule_nos.push(checkBoxMstId);
                }
            });

            if (dispatchScheduleNosJson.dispatch_schedule_nos.length > 0) {
                const formData = new FormData();
                formData.append(`dispatchScheduleNos`, JSON.stringify(dispatchScheduleNosJson))
                const requestOptions = { method: 'POST', body: formData };
                const getCustomerOrderDetaiAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnShowDispatchScheduleDetails/${COMPANY_ID}`, requestOptions)
                const customerOrderDataiAPICallResponse = await getCustomerOrderDetaiAPICall.json();
                console.log(customerOrderDataiAPICallResponse);

                if (customerOrderDataiAPICallResponse.success === 1) {
                    FnConvert2ChallanDetails('DispatchSchedule2ChallanDetails', customerOrderDataiAPICallResponse.data);
                    setPackingDetailsList(customerOrderDataiAPICallResponse.DispatchSchedulePackingDetailsRecord)
                    if (customerOrderDataiAPICallResponse.DispatchSchedulePropertiesDetailsRecord.length !== 0) {
                        await fnGetProductProperties(customerOrderDataiAPICallResponse.DispatchSchedulePropertiesDetailsRecord)
                    }
                }
            } else {
                // Then remove all materials and update the calculations.
                setDispatchChallanMaterials([]);
                // FnCalculateGrandTotal([]);
                FnCalculateTaxationSummary([]);
            }
            // $('#spinner_id').hide();
        } catch (error) {
            // $('#spinner_id').hide();
            console.log("error in getting order-details from the customer order: ", error)
            navigator('/Error')
        } finally {
            $('#spinner_id').hide();
        }
    }

    // For render the details-table.
    const FnRenderDetailsTable = useMemo(() => {
        return <>
            {
                dispatchChallanMaterials.length > 0
                    ? <Table className="erp_table erp_table_scroll" id='DispatchChallanMaterialDtlsTbl' bordered striped hover>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : ''}`}>Action</th>
                                <th className="erp_table_th">Sr No</th>

                                <th className="erp_table_th">SO Sr No</th>
                                <th className="erp_table_th" style={{ textAlign: 'center', paddingRight: "100px" }}>Material Print Name</th>

                                {
                                    rb_dispatch_challan_creation_type !== 'M'
                                        ? <>
                                            <th className="erp_table_th">Customer Order No.</th>
                                        </>
                                        : ''
                                }

                                <th className="erp_table_th">Material Unit Name</th>
                                <th className="erp_table_th">Packing Name</th>
                                <th className="erp_table_th">HSN Code</th>
                                {/* {
                                    rb_dispatch_challan_creation_type === 'D'
                                        ? <>
                                            <th className={`erp_table_th`}>Actual Dispatch Quantity</th>
                                         </>
                                        : ''
                                }

                                {
                                    rb_dispatch_challan_creation_type === 'O'
                                        ? <>
                                            <th className={`erp_table_th`}>Order Quantity</th>
                                         </>
                                        : ''
                                } */}

                                <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : ''}`}>Avl. Stock Qty.</th>
                                <th className={`erp_table_th`}>Dispatch Quantity</th>
                                <th className="erp_table_th">Dispatch Weight</th>
                                <th className={`erp_table_th`}>Material Rate</th>
                                <th className="erp_table_th">Material Amount</th>

                                {
                                    // if challan creation type is mannual then only shows.
                                    rb_dispatch_challan_creation_type === 'M'
                                        ? <>
                                            <th className="erp_table_th">Discount %</th>
                                            <th className="erp_table_th">Discount Amount</th>
                                        </>
                                        : ''
                                }
                                <th className="erp_table_th">HSN Rate %</th>
                                <th className="erp_table_th">Taxable Amount</th>
                                <th className="erp_table_th">CGST %</th>
                                <th className="erp_table_th">CGST Amount</th>
                                <th className="erp_table_th">SGST %</th>
                                <th className="erp_table_th">SGST Amount</th>
                                <th className="erp_table_th">IGST %</th>
                                <th className="erp_table_th">IGST Amount</th>
                                <th className="erp_table_th">Freight Amount</th>
                                <th className="erp_table_th">Total Amount</th>
                                <th className="erp_table_th">Return Qty.</th>
                                <th className="erp_table_th">Return Wt.</th>
                                <th className="erp_table_th">Pending Qty.</th>
                                <th className="erp_table_th">Pending Wt.</th>
                                <th className="erp_table_th">Invoice Qty.</th>
                                <th className="erp_table_th">Invoice Wt.</th>
                                <th className="erp_table_th">Expected Scheduled Date</th>
                                <th className="erp_table_th">Delayed Days</th>
                                {
                                    rb_dispatch_challan_creation_type !== 'M'
                                        ? <>
                                            <th className="erp_table_th" >Pree-Close</th>
                                        </>
                                        : ''
                                }
                                <th className="erp_table_th">Disp. Challan Status</th>
                                <th className="erp_table_th">Disp. Challan Remark</th>
                                <th className="erp_table_th">Remark</th>
                                {
                                    rb_dispatch_challan_creation_type !== 'M'
                                        ? <>
                                            <th className="erp_table_th">Sales Order No.</th>
                                        </>
                                        : ''
                                }
                                <th className={`erp_table_th`}>Technical Specification</th>
                                <th className="erp_table_th">Material Std. Weight</th>
                                {
                                    rb_dispatch_challan_creation_type !== 'M'
                                        ? <>
                                            <th className="erp_table_th">Customer Order Date</th>
                                            <th className="erp_table_th">Sales Order Ver.</th>
                                            <th className="erp_table_th">Sales Order Date</th>
                                        </>
                                        : ''
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {
                                dispatchChallanMaterials?.map((dispatchChallanItem, Index) =>
                                    <tr rowIndex={Index} rowSrNo={dispatchChallanItem.sr_no} rowMaterialId={dispatchChallanItem.product_material_id}>
                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'd-none' : ''}`}>
                                            <div className="d-flex justify-content-center">
                                                {/* <MdDelete className="erp-delete-btn" onClick={(e) => removeMaterial(Index)} /> */}
                                                <MdDelete className="erp-delete-btn" onClick={(e) => removeMaterial(dispatchChallanItem)} />
                                                <Tooltip title="Schedule" placement="top">
                                                    <AiOutlineSchedule className="erp-schedule-btn" onClick={() => openPackingDetailsModal(dispatchChallanItem)} />
                                                </Tooltip>
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{dispatchChallanItem.sr_no}</td>


                                        <td className="erp_table_td text-end">
                                            {
                                                keyForViewUpdate === 'view' || rb_dispatch_challan_creation_type !== 'M'
                                                    ? dispatchChallanItem.so_sr_no
                                                    : <input type="text" className="erp_input_field mb-0 text-end" id={`so_sr_no_${Index}`} value={dispatchChallanItem.so_sr_no}
                                                        Headers='so_sr_no' onFocus={(e) => { handleSoSrNumberChange(dispatchChallanItem, e); }} onChange={(e) => { handleSoSrNumberChange(dispatchChallanItem, e); }} onBlur={(e) => { handleSoSrNumberChange(dispatchChallanItem, e); }} />
                                            }
                                        </td>
                                        <td className={`erp_table_td`}>
                                            {
                                                keyForViewUpdate === 'view'
                                                    ? dispatchChallanItem.product_material_print_name
                                                    : <input type="text" className="erp_input_field mb-0" id={`product_material_print_name_${Index}`} value={dispatchChallanItem.product_material_print_name} maxLength='1000'
                                                        Headers='product_material_print_name' onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                            }
                                        </td>

                                        {
                                            rb_dispatch_challan_creation_type !== 'M'
                                                ? <>
                                                    <td className="erp_table_td">{dispatchChallanItem.customer_order_no}</td>
                                                    {/* <td className="erp_table_td">{dispatchChallanItem.sales_order_no}</td> */}
                                                </>
                                                : ''
                                        }



                                        <td className={`erp_table_td`}>
                                            {
                                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                    ? <select id={`product_material_unit_id_${Index}`} value={dispatchChallanItem.product_material_unit_id} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                        Headers="product_material_unit_id" onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false}  >
                                                        {
                                                            productUnitOpts?.map(unit => (
                                                                <option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    : dispatchChallanItem.product_material_unit_name
                                            }
                                        </td>

                                        <td className="erp_table_td">{dispatchChallanItem.product_packing_name}</td>
                                        <td className="erp_table_td">{dispatchChallanItem.product_material_hsn_sac_code}</td>

                                        {/* {
                                            rb_dispatch_challan_creation_type !== 'M'
                                                ? <>
                                                    <td className="erp_table_td text-end">{dispatchChallanItem.order_quantity}</td>
                                                 </>
                                                : ''
                                        } */}
                                        {/* {
                                            rb_dispatch_challan_creation_type !== 'O'
                                                ? <>
                                                    <td className="erp_table_td text-end">{dispatchChallanItem.order_quantity}</td>
                                                 </>
                                                : ''
                                        } */}




                                        <td className={`erp_table_td text-end ${keyForViewUpdate === 'view' ? 'd-none' : ''}`}>{dispatchChallanItem.available_stock_qty}</td>
                                        <td className={`erp_table_td text-end`}>{dispatchChallanItem.dispatch_quantity}</td>

                                        {/* <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view'
                                                    ? dispatchChallanItem.dispatch_quantity
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`dispatch_quantity_${Index}`} value={dispatchChallanItem.dispatch_quantity} maxLength='19' maxDispatchQty={dispatchChallanItem.max_dispatch_quantity} availDispatchQty={dispatchChallanItem.available_stock_qty}
                                                        Headers='dispatch_quantity' onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }}  />
                                            }
                                        </td> */}

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view'
                                                    ? dispatchChallanItem.dispatch_weight
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`dispatch_weight_${Index}`} value={dispatchChallanItem.dispatch_weight} maxLength='19'
                                                        Headers='dispatch_weight' onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                            }
                                        </td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view'
                                                    ? dispatchChallanItem.item_rate
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`item_rate_${Index}`} value={dispatchChallanItem.item_rate} maxLength='19'
                                                        Headers='item_rate' onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_amount}</td>

                                        {
                                            // if challan creation type is mannual then only shows.
                                            rb_dispatch_challan_creation_type === 'M'
                                                ? <>
                                                    <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'text-end' : ""}`}>
                                                        {
                                                            keyForViewUpdate === 'view'
                                                                ? dispatchChallanItem.item_discount_percent
                                                                : <input type="text" className="erp_input_field text-end mb-0" id={`item_discount_percent_${Index}`} value={dispatchChallanItem.item_discount_percent}
                                                                    Headers='item_discount_percent' onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                                        }
                                                    </td>

                                                    <td className={`erp_table_td ${keyForViewUpdate === 'view' ? 'text-end' : ""}`}>
                                                        {
                                                            keyForViewUpdate === 'view'
                                                                ? dispatchChallanItem.item_discount_amount
                                                                : <input type="text" className="erp_input_field text-end mb-0" id={`item_discount_amount_${Index}`} value={dispatchChallanItem.item_discount_amount}
                                                                    Headers='item_discount_amount' onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                                        }
                                                    </td>
                                                </>
                                                : ''
                                        }
                                        <td className="erp_table_td text-end">{dispatchChallanItem.hsn_sac_percent}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_taxable_amount}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_cgst_percent}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_cgst_amount}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_sgst_percent}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_sgst_amount}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_igst_percent}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_igst_amount}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_freight_amount}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.item_total_amount}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.dispatch_return_quantity}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.dispatch_return_weight}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.pending_quantity}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.pending_weight}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.invoice_quantity}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.invoice_weight}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(dispatchChallanItem.expected_schedule_date)}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.delayed_days}</td>

                                        {
                                            rb_dispatch_challan_creation_type !== 'M'
                                                ? <>
                                                    <td className="erp_table_td">
                                                        <select id={`pree_close_flag_${Index}`} value={dispatchChallanItem.pree_close_flag} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                            Headers="pree_close_flag" onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }}>
                                                            <option value="false" lbl='No'>No</option>
                                                            <option value="true" lbl='Yes'>Yes</option>
                                                        </select>
                                                    </td>
                                                </>
                                                : ''
                                        }

                                        <td className="erp_table_td">
                                            <select id={`dispatch_challan_item_status_${Index}`} value={dispatchChallanItem.dispatch_challan_item_status} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                Headers="dispatch_challan_item_status" onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} disabled={keyForViewUpdate !== 'approve' ? true : false}  >
                                                <option value="P" lbl='Pending'>Pending</option>
                                                <option value="A" lbl='Approved'>Approved</option>
                                                <option value="R" lbl='Rejected'>Rejected</option>
                                                <option value="Z" lbl='PreeClosed'>PreeClosed</option>
                                                <option value="U" lbl='Partial Sales Issue'>Partial Sales Issue</option>
                                                <option value="C" lbl='Completed'>Completed</option>
                                                <option value="X" lbl='Canceled'>Canceled</option>
                                                <option value="D" lbl='Dispath Challan Created'>Dispath Challan Created</option>
                                                <option value="I" lbl='Invoice Created'>Invoice Created</option>
                                            </select>
                                        </td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'approve'
                                                    ? <input type="text" className="erp_input_field_table_txt mb-0" id={`dispatch_challan_remark_${Index}`} value={dispatchChallanItem.dispatch_challan_remark} Headers='dispatch_challan_remark'
                                                        placeholder="dispatch challan status remark...!" onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                                    : dispatchChallanItem.dispatch_challan_remark
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view'
                                                    ? dispatchChallanItem.remark
                                                    : <input type="text" className="erp_input_field_table_txt mb-0 MaterialWiseRemark" id={`remark_${Index}`} value={dispatchChallanItem.remark} Headers='remark'
                                                        onChange={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} onBlur={(e) => { updateChallanDetailsTblData(dispatchChallanItem, e); }} />
                                            }
                                        </td>
                                        {
                                            rb_dispatch_challan_creation_type !== 'M'
                                                ? <>
                                                    <td className="erp_table_td">{dispatchChallanItem.sales_order_no}</td>
                                                </>
                                                : ''
                                        }


                                        <td className="erp_table_td">{dispatchChallanItem.product_material_tech_spect}</td>
                                        <td className="erp_table_td text-end">{dispatchChallanItem.product_material_std_weight}</td>
                                        {
                                            rb_dispatch_challan_creation_type !== 'M'
                                                ? <>
                                                    <td className="erp_table_td">{dispatchChallanItem.customer_order_Date}</td>
                                                    <td className="erp_table_td text-end">{dispatchChallanItem.sales_order_version}</td>
                                                    <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(dispatchChallanItem.sales_order_Date)}</td>
                                                </>
                                                : ''
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [dispatchChallanMaterials, productTypePropertiesData]);

    // Render the Taxation Summary table.
    const FnRenderTaxationSummaryTable = useMemo(() => {
        return <>
            {
                taxationSummaryData.length > 0
                    ? <Table className="erp_table" id='dispatchChallanTaxationSummaryTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">Sr No.</th>
                                <th className="erp_table_th">HSN Sac Code </th>
                                <th className="erp_table_th">Taxable Total </th>
                                <th className="erp_table_th">CGST %</th>
                                <th className="erp_table_th">CGST Total</th>
                                <th className="erp_table_th">SGST %</th>
                                <th className="erp_table_th">SGST Total</th>
                                <th className="erp_table_th">IGST %</th>
                                <th className="erp_table_th">IGST Total</th>
                                <th className="erp_table_th">Total Amount</th>
                                {/* <th className="erp_table_th">Items Count</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                taxationSummaryData?.map((taxSummary, Index) =>
                                    <tr rowIndex={Index} hsnId={taxSummary.hsn_code_id}>
                                        <td className="erp_table_td">{Index + 1}</td>
                                        <td className="erp_table_td">{taxSummary.product_material_hsn_sac_code}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_taxable_amount}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_cgst_percent}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_cgst_total}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_sgst_percent}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_sgst_total}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_igst_percent}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_igst_total}</td>
                                        <td className="erp_table_td text-end">{taxSummary.summary_total_amount}</td>
                                        {/* <td className="erp_table_td text-end">{taxSummary.total_items_count}</td> */}
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [taxationSummaryData]);

    // Render the Taxation Summary table.
    const FnRenderBatchWiseStockReleaseTable = useMemo(() => {
        return <>
            {
                batchWiseStockTblData.length > 0
                    ? <Table className="erp_table" id='dispatchChallanBatchWiseStockReleaseTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">Sr No.</th>
                                <th className="erp_table_th">SO Sr No.</th>
                                <th className="erp_table_th">Dispatch Challan No. </th>
                                <th className="erp_table_th">Dispatch Challan Ver. </th>
                                <th className="erp_table_th">Dispatch Challan Date </th>
                                <th className="erp_table_th">Product Material Name</th>
                                <th className="erp_table_th">Batch No.</th>
                                <th className="erp_table_th"> Batch Dispatch Quantity</th>
                                <th className="erp_table_th">Batch Dispatch Weight</th>
                                <th className="erp_table_th"> Customer Order No</th>
                                <th className="erp_table_th">Customer Order Date</th>
                                <th className="erp_table_th">Sales Order No </th>
                                <th className="erp_table_th">Sales Order Date</th>
                                <th className="erp_table_th">Sales Order Version</th>
                                <th className="erp_table_th">Dispatch Schedule No</th>
                                <th className="erp_table_th"> Dispatch Schedule Date</th>
                                <th className="erp_table_th"> Dispatch Schedule Version</th>
                                <th className="erp_table_th">Dispatch Challan Batch Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                batchWiseStockTblData?.map((batchWiseMaterial, Index) =>
                                    <tr rowIndex={Index}>
                                        <td className="erp_table_td">{Index + 1}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.so_sr_no}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.dispatch_challan_no}</td>
                                        <td className="erp_table_td text-end">{batchWiseMaterial.dispatch_challan_version}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(batchWiseMaterial.dispatch_challan_date)}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.product_material_name}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.batch_no}</td>
                                        <td className="erp_table_td text-end">{batchWiseMaterial.batch_dispatch_quantity}</td>
                                        <td className="erp_table_td text-end">{batchWiseMaterial.batch_dispatch_weight}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.customer_order_no}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(batchWiseMaterial.customer_order_Date)}</td>
                                        <td className="erp_table_td text-end">{batchWiseMaterial.sales_order_no}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(batchWiseMaterial.sales_order_Date)}</td>
                                        <td className="erp_table_td text-end">{batchWiseMaterial.sales_order_version}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.dispatch_schedule_no}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(batchWiseMaterial.dispatch_schedule_date)}</td>
                                        <td className="erp_table_td text-end">{batchWiseMaterial.dispatch_schedule_version}</td>
                                        <td className="erp_table_td">{batchWiseMaterial.dispatch_challan_batch_status_desc}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [batchWiseStockTblData]);

    // For remove the dispatch-challan-material.
    // const removeMaterial = (materialIndex) => {
    //     if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
    //         let detailTblRows = [...dispatchChallanMaterials];      // Store all table rows original object in array.

    //         // Retrieve the product material ID of the material at the specified index
    //         const productMaterialId = detailTblRows[materialIndex].product_material_id;

    //         detailTblRows.splice(materialIndex, 1);     // remove the material-object using rowIndex.
    //         detailTblRows.forEach((material, index) => {
    //             material.sr_no = index + 1;
    //         });
    //         setDispatchChallanMaterials(detailTblRows);
    //         if (inspectionDetailsList.length > 0) {
    //             const updatedInspectionDetailsList = inspectionDetailsList.filter(item => item.product_material_id !== productMaterialId);
    //             setInspectionDetailsList(updatedInspectionDetailsList);
    //         }
    //         FnCalculateTaxationSummary(detailTblRows);
    //         // setTimeout(() => { FnCalculateGrandTotal(detailTblRows); }, 100);
    //     }
    // }
    const removeMaterial = (dispatchChallanItem) => {
        debugger
        if (!isApprove && keyForViewUpdate !== 'view') {
            const filterdedData = dispatchChallanMaterials.filter(item => item.product_material_id !== dispatchChallanItem.product_material_id || item.customer_order_no !== dispatchChallanItem.customer_order_no);
            setDispatchChallanMaterials(filterdedData)
            console.log("filterdedData", filterdedData);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(filterdedData));

            if (productTypePropertiesData.length !== 0) {
                const updatedMaterialProperties = [...productTypePropertiesData].filter(item => item.product_id !== dispatchChallanItem.product_material_id || item.customer_order_no !== dispatchChallanItem.customer_order_no);
                setProductTypePropertiesData(updatedMaterialProperties);
                console.log("updatedMaterialProperties", updatedMaterialProperties);
            }
            if (PackingDetailsList.length > 0) {
                const updatedPackingDetailsList = PackingDetailsList.filter(item => item.product_material_id !== dispatchChallanItem.product_material_id);
                setPackingDetailsList(updatedPackingDetailsList);
                console.log("updatedPackingDetailsList", updatedPackingDetailsList);
            }
            FnUpdateMaterialCalculationOnStateChange(filterdedData);

        }
    }


    // Function for any state change for 
    const FnUpdateMaterialCalculationOnStateChange = (detailTblRows) => {
        const updatedTblData = detailTblRows.map(row => FnMaterialWiseCalculations(row));

        // FnCalculateGrandTotal(updatedTblData);
        FnCalculateTaxationSummary(updatedTblData);
        setDispatchChallanMaterials(updatedTblData);
    }
    // const openInspectionModal = (dispatchchallanObj) => {
    //     loadInspectionList(dispatchchallanObj);

    // }
    // function for PO Details table
    // const handleCloseInspectionSummaryModal = () => {  // fnShowInspectionDetails(); 
    //     setViewSetNosModal(false)
    // };

    // const fnShowInspectionDetails = () => {
    //     const isvalidate = validateInspectionDispatch();
    //     if (isvalidate) {
    //         let createInspectionDetailsList = [];
    //         $("input:checkbox[name=selectInspectionRecord]:checked").each(function () {
    //             const inspectionDetailsRow = inspectionSummatryList.find(item => item.weaving_production_inspection_details_id === parseInt($(this).val()));

    //             const inspectionRow = {
    //                 ...inspectionDetailsRow,
    //                 company_id: COMPANY_ID,
    //                 company_branch_id: COMPANY_BRANCH_ID,
    //                 financial_year: FINANCIAL_SHORT_NAME,
    //                 dispatch_challan_no: txt_dispatch_challan_no,
    //                 dispatch_challan_date: dt_dispatch_challan_date,
    //                 dispatch_challan_version: txt_dispatch_challan_version,
    //                 dispatch_inspection_details_transaction_id: 0,
    //                 dispatch_challan_master_transaction_id: dispatch_challan_master_transaction_id,
    //             };
    //             createInspectionDetailsList.push(inspectionRow)
    //         })
    //         setInspectionDetailsList(createInspectionDetailsList)
    //         setViewSetNosModal(false)
    //     }
    // }

    // const loadInspectionList = async (dispatchchallanObj) => {
    //     setViewSetNosModal(true);
    //     resetGlobalQuery();
    //     globalQuery.columns = ['*'];
    //     globalQuery.table = "xtv_weaving_production_inspection_details";
    //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
    //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //     globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: dispatchchallanObj.product_material_id });
    //     globalQuery.conditions.push({ field: "sales_order_no", operator: "=", value: dispatchchallanObj.sales_order_no });
    //     globalQuery.conditions.push({ field: "inspection_production_status", operator: "IN", values: ['A', 'PD'] });
    //     globalQuery.orderBy = ["weaving_production_inspection_details_id desc"];
    //     const getInspectionSummaryList = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
    //     if (getInspectionSummaryList.length !== 0) {
    //         if (dispatchchallanObj.dispatch_quantity > 0) {
    //             let dispatchQuantity = dispatchchallanObj.dispatch_quantity;
    //             const updatedDetailsData = getInspectionSummaryList.map((item) => {
    //                 const newItem = {
    //                     ...item,
    //                     prev_dispatch_quantity: item.dispatch_quantity,
    //                     dispatch_quantity: validateNumberDateInput.current.decimalNumber((item.inspection_mtr - item.dispatch_quantity).toString(), 4),
    //                     item_pending_quantity: 0,
    //                     dispatch_challan_details_transaction_id: item.dispatch_challan_details_transaction_id,
    //                     customer_order_no: dispatchchallanObj.customer_order_no,
    //                 };
    //                 if (parseFloat(dispatchQuantity) !== 0) {
    //                     if (parseFloat(dispatchQuantity) > parseFloat(newItem.dispatch_quantity)) {
    //                         dispatchQuantity -= newItem.dispatch_quantity;
    //                         newItem.item_pending_quantity = 0;
    //                     } else {
    //                         newItem.item_pending_quantity = newItem.dispatch_quantity - dispatchQuantity;
    //                         newItem.dispatch_quantity = validateNumberDateInput.current.decimalNumber((dispatchQuantity).toString(), 4);
    //                         dispatchQuantity = 0;
    //                     }
    //                     return newItem; // Ensure you return the updated item in the map function
    //                 } else {
    //                     newItem.dispatch_quantity = 0;
    //                     newItem.item_pending_quantity = 0;
    //                     return newItem;
    //                 }
    //             });
    //             setInspectionSummaryList(updatedDetailsData)
    //             console.log('Inspection Rolles: ', updatedDetailsData);
    //             setTimeout(() => {
    //                 $('#inspection_material_name_id').text(dispatchchallanObj.product_material_name);
    //                 $('#inspection_dispatch_qty_id').text(dispatchchallanObj.dispatch_quantity);
    //                 updatedDetailsData.forEach(item => {
    //                     if (item.dispatch_quantity > 0) {
    //                         $("input:checkbox[name='selectInspectionRecord'][value='" + item.weaving_production_inspection_details_id + "']").prop("checked", true);
    //                     } else {
    //                         $("input:checkbox[name='selectInspectionRecord'][value='" + item.weaving_production_inspection_details_id + "']").prop("checked", false);
    //                     }
    //                 })
    //                 $('#selectAllSetNosRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);

    //             }, 100);
    //         }

    //         // FnInspectionChkBoxCheck(getInspectionSummaryList)
    //     } else {
    //         setInspectionSummaryList([])
    //     }
    // }

    // For update the row-level calculation of materials.
    const updateChallanDetailsTblData = async (currentRowData, event) => {
        debugger
        let clickedColName = event.target.getAttribute('Headers');
        const excludedColumnsInCalculation = ['product_material_print_name', 'product_material_unit_id', 'dispatch_weight', 'pree_close_flag', 'dispatch_challan_item_status', 'dispatch_challan_remark', 'remark'];

        let updateCalculations = false;
        let inputValue = event.target.value;

        if (!excludedColumnsInCalculation.includes(clickedColName)) {
            inputValue = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
        }

        // Remove error msgs on input change.
        if (event._reactName === 'onChange') { delete event.target.parentElement.dataset.tip; }

        switch (clickedColName) {
            case 'dispatch_quantity':
                debugger;
                if (parseFloat(inputValue) <= parseFloat(currentRowData.available_stock_qty)) {   // 10 <= 100 
                    if (parseFloat(inputValue) <= parseFloat(currentRowData.max_dispatch_quantity)) {
                        let dispatch_weight = parseFloat(inputValue) * parseFloat(currentRowData.product_material_std_weight);
                        currentRowData.dispatch_quantity = inputValue
                        currentRowData.dispatch_weight = validateNumberDateInput.current.decimalNumber(dispatch_weight.toString(), 4);
                        currentRowData.item_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.item_rate)).toString(), 4);
                        FnCalculateMaterialWiseDiscount(currentRowData);
                        updateCalculations = true;
                        delete event.target.parentElement.dataset.tip;
                    } else {
                        currentRowData.dispatch_quantity = 0;
                        event.target.parentElement.dataset.tip = `Dispatch quantity not greater than ${parseFloat(currentRowData.max_dispatch_quantity)} quantity...!`;

                    }
                } else {
                    event.target.parentElement.dataset.tip = 'Dispatch quantity not greater than availble stock quantity...!';
                }
                break;

            case 'item_rate':
                currentRowData.item_rate = inputValue;
                currentRowData.item_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.dispatch_quantity)).toString(), 4);
                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;

            case 'item_discount_percent':
                let isValidPercentageInput = validatePercentageInput(inputValue);
                if (isValidPercentageInput === true) {
                    const calculatedDiscountAmount = (inputValue / 100) * currentRowData.item_amount;
                    // Then set the discount amount and discount percentage.
                    currentRowData.item_discount_percent = inputValue;
                    currentRowData.item_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedDiscountAmount), 4);
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = currentRowData.item_discount_percent;
                    event.target.parentElement.dataset.tip = isValidPercentageInput;
                }
                updateCalculations = true;
                break;

            case 'item_discount_amount':
                const calculatedPercent = (inputValue * 100) / currentRowData.item_amount;
                // Then set the discount amount and discount percentage.
                currentRowData.item_discount_amount = inputValue;
                currentRowData.item_discount_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 4);
                updateCalculations = true;
                break;

            // Non Calculated Fields.
            case 'pree_close_flag':
            case 'product_material_print_name':
            case 'dispatch_challan_item_status':
            case 'remark':
            case 'dispatch_challan_remark':
                currentRowData[clickedColName] = inputValue;
                break;

            case 'dispatch_weight':
                currentRowData.dispatch_weight = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
                break;

            case 'product_material_unit_id':
                currentRowData[clickedColName] = inputValue;
                currentRowData['product_material_unit_name'] = event.target.selectedOptions[0].getAttribute('lbl');
                break;

            default:
                break;
        }

        // Calculate the other dependent Fields on changed field.
        if ((updateCalculations || event._reactName === 'onBlur') && !excludedColumnsInCalculation.includes(clickedColName)) {
            currentRowData = FnMaterialWiseCalculations(currentRowData);
            const updatedDetailsData = [...dispatchChallanMaterials]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            // FnCalculateGrandTotal(updatedDetailsData);
            // Update the footer discount fields
            if (['item_discount_amount', 'item_discount_percent'].includes(clickedColName)) {
                FnSetFooterDiscountsByDetails(updatedDetailsData);
            }
            FnCalculateTaxationSummary(updatedDetailsData)
            setDispatchChallanMaterials(updatedDetailsData);
        } else {
            const updatedDetailsData = [...dispatchChallanMaterials]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setDispatchChallanMaterials(updatedDetailsData);

            // dispatchChallanMaterials
        }
    }

    // Update the footer discount calculations.
    const FnSetFooterDiscountsByDetails = (dispatchChallanMaterialsDetails) => {
        let footerDiscPercent = parseFloat(dispatchChallanMaterialsDetails.reduce((total, item) => total + parseFloat(item.item_discount_percent), 0));
        let footerDiscAmt = parseFloat(dispatchChallanMaterialsDetails.reduce((total, item) => total + parseFloat(item.item_discount_amount), 0));
        footerDiscPercent = footerDiscPercent / dispatchChallanMaterialsDetails.length;
        setDiscountPercent(validateNumberDateInput.current.decimalNumber((footerDiscPercent).toString(), 2));
        setDiscountAmt(validateNumberDateInput.current.decimalNumber((footerDiscAmt).toString(), 4));
    }

    // For handle the so-sr-no change.
    const handleSoSrNumberChange = async (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');

        if (clickedColName === 'so_sr_no') {
            if (event._reactName === 'onFocus') {
                // OnFocus add the lastSoSrNo Attribute on element. which is used in onBlur event.
                let initialSOSrNo = currentRowData.so_sr_no;
                event.target.setAttribute('lastSoSrNo', initialSOSrNo);
                return;

            } else if (event._reactName === 'onChange') {

                // First set it last srNo in input field.
                const isDuplicateSoSrNo = dispatchChallanMaterials.find(challanMaterial => challanMaterial.product_material_id === currentRowData.product_material_id && challanMaterial.so_sr_no === parseInt(event.target.value));

                if (isDuplicateSoSrNo) {
                    event.target.parentElement.dataset.tip = `So_sr_no is duplicate for product ${currentRowData.product_material_print_name}...!`;
                } else if (event.target.value === '') {
                    event.target.parentElement.dataset.tip = `Please enter so_sr_no ${currentRowData.product_material_print_name}...!`;
                } else {
                    delete event.target.parentElement.dataset.tip;
                }

                if (!isNaN(parseInt(event.target.value, 10))) {
                    currentRowData[clickedColName] = parseInt(event.target.value, 10);
                } else {
                    currentRowData[clickedColName] = '';
                }

            } else if (event._reactName === 'onBlur') {
                // update the schedules and taxations objects.
                let initialSOSrNo = parseInt(event.target.getAttribute('lastSoSrNo'));
                let updatedSoSrNo = parseInt(event.target.value, 10);

                if (event.target.value === '' || isNaN(updatedSoSrNo)) {
                    currentRowData.so_sr_no = initialSOSrNo;
                } else {
                    const similarMaterialsBySoSrNo = dispatchChallanMaterials.filter((material) => material.product_material_id === currentRowData.product_material_id && parseInt(material.so_sr_no) === parseInt(updatedSoSrNo));
                    if (similarMaterialsBySoSrNo.length > 1) {
                        currentRowData.so_sr_no = initialSOSrNo;
                    } else {
                        currentRowData.so_sr_no = updatedSoSrNo;
                    }
                }
                delete event.target.parentElement.dataset.tip;
            }

            const updatedDetailsData = [...dispatchChallanMaterials]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setDispatchChallanMaterials(updatedDetailsData);
        }
    }

    // Calculate discount amount 
    const FnCalculateMaterialWiseDiscount = (currentMaterial) => {
        const { material_discount_percent, item_amount, } = currentMaterial;
        const calculatedAmount = (material_discount_percent / 100) * item_amount;
        currentMaterial.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
    }

    const FnMaterialWiseCalculations = (currentRowData) => {
        let custState = parseInt($('#cmb_customer_state_id').val());
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = parseInt($('#cmb_expected_branch_id').val());
        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');

        if (!isNaN(custState)) {
            let discAmt = isNaN(parseFloat(currentRowData.item_discount_amount)) ? 0 : parseFloat(currentRowData.item_discount_amount);
            let hsn_code_rate = isNaN(parseFloat(currentRowData.hsn_sac_percent)) ? 0 : parseFloat(currentRowData.hsn_sac_percent);

            let total_amount = 0; let cgst_sgst_per = 0; let cgst_amt = 0;
            let sgst_amt = 0; let igst_per = 0; let igst_amt = 0;

            const item_taxable_amount = currentRowData.item_amount - discAmt;

            if (custStateCode === 99) {
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                total_amount = item_taxable_amount;

            } else if (customerIsSez === 'true') {
                // If customer is sezzed
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                total_amount = item_taxable_amount;

            } else if (companyBranchStateId === custState) {
                cgst_sgst_per = hsn_code_rate / 2;
                const gst_amt = (item_taxable_amount * hsn_code_rate) / 100;
                cgst_amt = gst_amt / 2;
                sgst_amt = gst_amt / 2;
                total_amount = item_taxable_amount + gst_amt;
                // remove the igst taxation
                igst_per = 0; igst_amt = 0;

            } else {
                igst_per = hsn_code_rate;
                igst_amt = (item_taxable_amount * hsn_code_rate) / 100;
                total_amount = item_taxable_amount + igst_amt;
                // remove the cgtst, sgst taxation
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0;
            }

            currentRowData['item_taxable_amount'] = validateNumberDateInput.current.decimalNumber(item_taxable_amount.toString(), 4);
            currentRowData['item_cgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
            currentRowData['item_cgst_amount'] = validateNumberDateInput.current.decimalNumber(cgst_amt.toString(), 4);
            currentRowData['item_sgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
            currentRowData['item_sgst_amount'] = validateNumberDateInput.current.decimalNumber(sgst_amt.toString(), 4);
            currentRowData['item_igst_percent'] = validateNumberDateInput.current.decimalNumber(igst_per.toString(), 4);
            currentRowData['item_igst_amount'] = validateNumberDateInput.current.decimalNumber(igst_amt.toString(), 4);
            currentRowData['item_total_amount'] = validateNumberDateInput.current.decimalNumber(total_amount.toString(), 4);

            $('#error_cmb_customer_state_id').hide();
        } else {
            $('#error_cmb_customer_state_id').text('Please select customer state...!');
            $('#error_cmb_customer_state_id').show();
        }
        return currentRowData;
    };


    // Function to convert all objects to the challan-details keys object.
    const FnConvert2ChallanDetails = async (convertFrom, recievedData) => {
        let currentDate = new Date();
        let materialIds = [];
        let getAvlStockQty = [];
        switch (convertFrom) {
            case 'Filter2ChallanDetails':
                let todayDate = today();
                let srNoForFilterBased = dispatchChallanMaterials.length + 1;
                let convertedDataFilter2ChallanDetails = recievedData?.map((challanDetailFromFilter, index) => {
                    const {
                        product_type_group: product_type_group,
                        product_name: product_material_name,
                        product_tech_spect: product_material_tech_spect,
                        product_unit_name: product_material_unit_name,
                        product_packing_name: product_packing_name,
                        product_hsn_sac_code: product_material_hsn_sac_code,
                        product_hsn_sac_rate: hsn_sac_percent,
                        product_std_weight: product_material_std_weight,
                        product_mrp: item_rate,
                        product_id: product_material_id,
                        product_unit_id: product_material_unit_id,
                        product_packing_id: product_material_packing_id,
                        product_hsn_sac_code_id: hsn_sac_id,
                        ...rest
                    } = challanDetailFromFilter;

                    let keysUpdatedForChallanDetail = {
                        product_type_group, product_material_name, product_material_tech_spect, product_material_unit_name, product_packing_name, product_material_hsn_sac_code, hsn_sac_percent,
                        product_material_std_weight, item_rate, product_material_id, product_material_unit_id, product_material_packing_id, hsn_sac_id,
                        product_material_print_name: product_material_name,
                        ...rest
                    };

                    // Check material is already added or not if added then change so_sr_no.
                    const materialIsAlreadyAdded = dispatchChallanMaterials.filter((existingMaterials) => existingMaterials.product_material_id === keysUpdatedForChallanDetail.product_material_id);

                    if (materialIsAlreadyAdded.length > 0) {
                        const existingSoSrNo = materialIsAlreadyAdded.map((material) => material.so_sr_no);
                        const maxSoSrNo = Math.max(...existingSoSrNo);
                        keysUpdatedForChallanDetail.so_sr_no = maxSoSrNo + 1;
                    } else {
                        keysUpdatedForChallanDetail.so_sr_no = 1;
                    }

                    // add the new keys which are not received from the filter popup.
                    keysUpdatedForChallanDetail.sr_no = srNoForFilterBased;
                    keysUpdatedForChallanDetail.available_stock_qty = 0;
                    keysUpdatedForChallanDetail.dispatch_quantity = 0;
                    keysUpdatedForChallanDetail.dispatch_weight = 0;
                    keysUpdatedForChallanDetail.item_amount = validateNumberDateInput.current.decimalNumber((keysUpdatedForChallanDetail.item_rate * parseFloat(keysUpdatedForChallanDetail.dispatch_quantity)).toString(), 4);
                    keysUpdatedForChallanDetail.item_freight_amount = 0;
                    keysUpdatedForChallanDetail.item_discount_percent = 0;
                    keysUpdatedForChallanDetail.item_discount_amount = 0;
                    keysUpdatedForChallanDetail.item_taxable_amount = 0;
                    keysUpdatedForChallanDetail.item_cgst_percent = 0;
                    keysUpdatedForChallanDetail.item_cgst_amount = 0;
                    keysUpdatedForChallanDetail.item_sgst_percent = 0;
                    keysUpdatedForChallanDetail.item_sgst_amount = 0;
                    keysUpdatedForChallanDetail.item_igst_percent = 0;
                    keysUpdatedForChallanDetail.item_igst_amount = 0;
                    keysUpdatedForChallanDetail.item_total_amount = 0;
                    keysUpdatedForChallanDetail.dispatch_return_quantity = 0;
                    keysUpdatedForChallanDetail.dispatch_return_weight = 0;
                    keysUpdatedForChallanDetail.pending_quantity = 0;
                    keysUpdatedForChallanDetail.pending_weight = 0;
                    keysUpdatedForChallanDetail.invoice_quantity = 0;
                    keysUpdatedForChallanDetail.invoice_weight = 0;
                    keysUpdatedForChallanDetail.expected_schedule_date = todayDate;
                    keysUpdatedForChallanDetail.delayed_days = 0
                    keysUpdatedForChallanDetail.dispatch_challan_item_status = "P";
                    keysUpdatedForChallanDetail.dispatch_challan_remark = '';
                    keysUpdatedForChallanDetail.remark = '';
                    srNoForFilterBased++;

                    // send material-item for calculation.
                    keysUpdatedForChallanDetail = FnMaterialWiseCalculations(keysUpdatedForChallanDetail);
                    materialIds.push(product_material_id);
                    return keysUpdatedForChallanDetail;
                });
                let updatedMaterialDetail = [...dispatchChallanMaterials, ...convertedDataFilter2ChallanDetails];

                if (updatedMaterialDetail.length > 0) {
                    // Get the available stock qty.
                    updatedMaterialDetail = await FnUpdateStockAvailability(updatedMaterialDetail, false);
                    updatedMaterialDetail = updatedMaterialDetail.map(material => {
                        return { ...material, max_dispatch_quantity: material?.available_stock_qty };
                    });
                }

                setDispatchChallanMaterials(updatedMaterialDetail);
                // FnCalculateGrandTotal([...dispatchChallanMaterials, ...convertedDataFilter2ChallanDetails]);
                FnCalculateTaxationSummary(updatedMaterialDetail);
                return updatedMaterialDetail;

            case 'SalesOrder2ChallanDetails':
                let srNoForSalesOrderBased = 1;
                let expectedSOMaterialScheduleDate;
                let convertedDataSalesOrder2ChallanDetails = [];
                // Iterate the loop on received data and change the keys.
                recievedData?.map((challanDetailFromSalesOrder, index) => {
                    let dispatchQty = parseFloat(challanDetailFromSalesOrder.material_quantity) - parseFloat(challanDetailFromSalesOrder.previous_dispatch_quantity);
                    let dispatchWt = parseFloat(challanDetailFromSalesOrder.material_weight) - parseFloat(challanDetailFromSalesOrder.previous_dispatch_weight);
                    // let dispatchWt = parseFloat(dispatchQty) * parseFloat(challanDetailFromSalesOrder.product_rm_std_weight)
                    // Calculate the delayed days.
                    expectedSOMaterialScheduleDate = new Date(challanDetailFromSalesOrder.material_schedule_date);  // expected date.
                    let delayedDays = Math.floor((currentDate - expectedSOMaterialScheduleDate) / (1000 * 60 * 60 * 24));

                    let requiredJSONForChallanMaterial = {
                        sr_no: srNoForSalesOrderBased,
                        so_sr_no: challanDetailFromSalesOrder.so_sr_no,
                        customer_order_no: challanDetailFromSalesOrder.customer_order_no,
                        customer_order_Date: challanDetailFromSalesOrder.customer_order_Date,
                        sales_order_no: challanDetailFromSalesOrder.sales_order_no,
                        sales_order_version: challanDetailFromSalesOrder.sales_order_version,
                        sales_order_Date: challanDetailFromSalesOrder.sales_order_date,
                        product_type_group: challanDetailFromSalesOrder.product_type_group,
                        product_material_id: challanDetailFromSalesOrder.product_material_id,
                        product_material_name: challanDetailFromSalesOrder.product_material_name,
                        product_material_print_name: challanDetailFromSalesOrder.product_material_print_name,
                        product_material_tech_spect: challanDetailFromSalesOrder.product_material_tech_spect,
                        product_material_unit_id: challanDetailFromSalesOrder.product_material_unit_id,
                        product_material_unit_name: challanDetailFromSalesOrder.product_material_stock_unit_name,
                        product_material_packing_id: challanDetailFromSalesOrder.product_material_packing_id,
                        product_packing_name: challanDetailFromSalesOrder.product_material_packing_name,
                        hsn_sac_id: challanDetailFromSalesOrder.product_material_hsn_code_id,
                        product_material_hsn_sac_code: challanDetailFromSalesOrder.product_material_hsn_sac_code,
                        hsn_sac_percent: challanDetailFromSalesOrder.product_material_hsn_sac_rate,
                        product_material_std_weight: challanDetailFromSalesOrder.product_material_std_weight,
                        available_stock_qty: 0,
                        order_quantity: validateNumberDateInput.current.decimalNumber((challanDetailFromSalesOrder.material_quantity), 4),
                        previous_dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(challanDetailFromSalesOrder.previous_dispatch_quantity), 4),
                        max_dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchWt), 4),
                        // item_rate: validateNumberDateInput.current.decimalNumber(JSON.stringify(singleMaterialRate), 4),
                        item_rate: challanDetailFromSalesOrder.so_rate,
                        item_amount: validateNumberDateInput.current.decimalNumber((challanDetailFromSalesOrder.so_rate * parseFloat(dispatchQty)).toString(), 4),
                        item_discount_percent: 0,          // Not applicable for sales-order-based
                        item_discount_amount: 0,          // Not applicable for sales-order-based
                        item_freight_amount: 0,
                        expected_schedule_date: challanDetailFromSalesOrder.material_schedule_date,
                        delayed_days: delayedDays,
                        dispatch_return_quantity: 0,
                        dispatch_return_weight: 0,
                        pending_quantity: 0,
                        pending_weight: 0,
                        invoice_quantity: 0,
                        invoice_weight: 0,
                        pree_close_flag: 'false',
                        dispatch_challan_item_status: "P",
                        dispatch_challan_remark: '',
                        remark: '',
                    }

                    // send material-item for calculation.
                    requiredJSONForChallanMaterial = FnMaterialWiseCalculations(requiredJSONForChallanMaterial);
                    convertedDataSalesOrder2ChallanDetails.push(requiredJSONForChallanMaterial);
                    materialIds.push(requiredJSONForChallanMaterial.product_material_id);
                    srNoForSalesOrderBased++;
                });

                if (convertedDataSalesOrder2ChallanDetails.length > 0) {
                    // Get the available stock qty.
                    convertedDataSalesOrder2ChallanDetails = await FnUpdateStockAvailability(convertedDataSalesOrder2ChallanDetails, false);
                }

                setDispatchChallanMaterials(convertedDataSalesOrder2ChallanDetails);
                // FnCalculateGrandTotal(convertedDataSalesOrder2ChallanDetails);
                FnCalculateTaxationSummary(convertedDataSalesOrder2ChallanDetails);
                return convertedDataSalesOrder2ChallanDetails;

            case 'DispatchSchedule2ChallanDetails':
                let srNoForDispatchScheduleBased = 1;
                let expectedDispScheduleMaterialScheduleDate;
                let convertedDataDispatchSchedule2ChallanDetails = [];
                console.log('Received Data For DispatchSchedule2ChallanDetails: ', recievedData)
                // Iterate the loop on received data and change the keys.
                recievedData?.map((challanDetailFromDispatchShedule, index) => {
                    // let dispatchQty = challanDetailFromDispatchShedule.material_quantity;           // Temporary allocate this chage it after discuss with prashant sir.
                    let dispatchQty = challanDetailFromDispatchShedule.actual_dispatch_quantity - challanDetailFromDispatchShedule.dispatched_quantity;           // Temporary allocate this chage it after discuss with prashant sir.
                    // let dispatchQty = parseFloat(challanDetailFromDispatchShedule.material_quantity) - parseFloat(challanDetailFromDispatchShedule.dispatched_quantity);
                    let dispatchWt = parseFloat(dispatchQty) * parseFloat(challanDetailFromDispatchShedule.product_material_std_weight)
                    // let singleMaterialRate = parseFloat(challanDetailFromDispatchShedule.material_taxable_amount) / parseFloat(challanDetailFromDispatchShedule.material_quantity)
                    // let singleMaterialRate = parseFloat(challanDetailFromDispatchShedule.material_taxable_amount) / parseFloat(dispatchQty)
                    let singleMaterialRate = validateNumberDateInput.current.decimalNumber((challanDetailFromDispatchShedule.so_rate * parseFloat(dispatchQty)).toString(), 4)

                    // Calculate the delayed days.
                    expectedDispScheduleMaterialScheduleDate = new Date(challanDetailFromDispatchShedule.material_schedule_date);  // expected date.
                    let delayedDays = Math.floor((currentDate - expectedDispScheduleMaterialScheduleDate) / (1000 * 60 * 60 * 24));

                    let requiredJSONForChallanMaterial = {
                        sr_no: srNoForDispatchScheduleBased,
                        so_sr_no: challanDetailFromDispatchShedule.so_sr_no,
                        customer_order_no: challanDetailFromDispatchShedule.customer_order_no,
                        customer_order_Date: challanDetailFromDispatchShedule.customer_order_Date,
                        sales_order_no: challanDetailFromDispatchShedule.sales_order_no,
                        sales_order_version: challanDetailFromDispatchShedule.sales_order_version,
                        sales_order_Date: challanDetailFromDispatchShedule.sales_order_date,
                        dispatch_schedule_no: challanDetailFromDispatchShedule.dispatch_schedule_no,
                        dispatch_schedule_version: challanDetailFromDispatchShedule.dispatch_schedule_version,
                        dispatch_schedule_date: challanDetailFromDispatchShedule.dispatch_schedule_date,
                        product_type_group: challanDetailFromDispatchShedule.product_type_group,
                        product_material_id: challanDetailFromDispatchShedule.product_material_id,
                        product_material_name: challanDetailFromDispatchShedule.product_material_name,
                        product_material_print_name: challanDetailFromDispatchShedule.product_material_print_name,
                        product_material_tech_spect: challanDetailFromDispatchShedule.product_material_tech_spect,
                        product_material_unit_id: challanDetailFromDispatchShedule.product_material_unit_id,
                        product_material_unit_name: challanDetailFromDispatchShedule.product_material_stock_unit_name,
                        product_material_packing_id: challanDetailFromDispatchShedule.product_material_packing_id,
                        product_packing_name: challanDetailFromDispatchShedule.product_material_packing_name,
                        hsn_sac_id: challanDetailFromDispatchShedule.product_material_hsn_code_id,
                        product_material_hsn_sac_code: challanDetailFromDispatchShedule.product_material_hsn_code,
                        hsn_sac_percent: challanDetailFromDispatchShedule.product_material_hsn_rate,
                        product_material_std_weight: challanDetailFromDispatchShedule.product_material_std_weight,
                        available_stock_qty: 0,
                        max_dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchWt), 4),
                        item_rate: challanDetailFromDispatchShedule.so_rate,
                        item_amount: validateNumberDateInput.current.decimalNumber((challanDetailFromDispatchShedule.so_rate * parseFloat(dispatchQty)).toString(), 4),
                        // item_rate: validateNumberDateInput.current.decimalNumber(JSON.stringify(singleMaterialRate), 4),
                        // item_amount: validateNumberDateInput.current.decimalNumber((singleMaterialRate * parseFloat(dispatchQty)).toString(), 4),
                        item_discount_percent: 0,          // Not applicable for sales-order-based
                        item_discount_amount: 0,          // Not applicable for sales-order-based
                        item_freight_amount: 0,
                        expected_schedule_date: challanDetailFromDispatchShedule.material_schedule_date,
                        expected_dispatch_quantity: challanDetailFromDispatchShedule.expected_dispatch_quantity,
                        delayed_days: delayedDays,
                        dispatch_return_quantity: 0,
                        dispatch_return_weight: 0,
                        pending_quantity: 0,
                        pending_weight: 0,
                        invoice_quantity: 0,
                        invoice_weight: 0,
                        pree_close_flag: 'false',
                        dispatch_challan_item_status: "P",
                        dispatch_challan_remark: '',
                        remark: '',
                    }

                    // send material-item for calculation.
                    requiredJSONForChallanMaterial = FnMaterialWiseCalculations(requiredJSONForChallanMaterial);
                    convertedDataDispatchSchedule2ChallanDetails.push(requiredJSONForChallanMaterial);
                    materialIds.push(requiredJSONForChallanMaterial.product_material_id);
                    srNoForDispatchScheduleBased++;
                });
                // console.log('Converted Data After DispatchSchedule2ChallanDetails: ', convertedDataDispatchSchedule2ChallanDetails)

                if (convertedDataDispatchSchedule2ChallanDetails.length > 0) {
                    // Get the available stock qty.
                    convertedDataDispatchSchedule2ChallanDetails = await FnUpdateStockAvailability(convertedDataDispatchSchedule2ChallanDetails, false);
                }

                setDispatchChallanMaterials(convertedDataDispatchSchedule2ChallanDetails);
                // FnCalculateGrandTotal(convertedDataDispatchSchedule2ChallanDetails);
                FnCalculateTaxationSummary(convertedDataDispatchSchedule2ChallanDetails);
                return convertedDataDispatchSchedule2ChallanDetails;

            default:
                break;
        }

    }

    // For footer discount percentage change.
    const FnFooterDiscountPercentChange = async (e) => {
        const percentInput = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
        const amount = (percentInput / 100) * txt_basic_total;
        setDiscountAmt((amount).toFixed(2));
        setTimeout(() => {
            FnSplitFooterAmtsInDetails(dispatchChallanMaterials);
        }, 200);
    };

    // For footer discount amount change.
    const FnFooterDiscountAmountChange = async (e) => {
        const amount = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
        const percent = (amount * 100) / txt_basic_total;
        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountAmt(0);
        } else {
            setDiscountPercent((percent).toFixed(2));
            setDiscountAmt(amount);
        }
        setTimeout(() => {
            FnSplitFooterAmtsInDetails(dispatchChallanMaterials);
        }, 200);
        // setTimeout(() => { FnCalculateGrandTotal(dispatchChallanMaterials); }, 200);
    };

    const FnCalculateGrandTotal = (dispatchChallanMaterials, taxationSummaryData) => {
        let basic_total = parseFloat(dispatchChallanMaterials.reduce((total, item) => total + parseFloat(item.item_taxable_amount), 0));
        let cgst_total = parseFloat(taxationSummaryData.reduce((total, item) => total + parseFloat(item.summary_cgst_total), 0));
        let sgst_total = parseFloat(taxationSummaryData.reduce((total, item) => total + parseFloat(item.summary_sgst_total), 0));
        let igst_total = parseFloat(taxationSummaryData.reduce((total, item) => total + parseFloat(item.summary_igst_total), 0));
        let grandTotal = taxationSummaryData.reduce((total, item) => total + parseFloat(item.summary_total_amount), 0);

        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))
        const transport_amount = isNaN(parseFloat($('#txt_transport_amount').val())) ? 0 : parseFloat($('#txt_transport_amount').val())
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) ? 0 : parseFloat($('#txt_packing_amount').val())
        const other_amount = isNaN(parseFloat($('#txt_other_amount').val())) ? 0 : parseFloat($('#txt_other_amount').val())

        const taxableAmount = basic_total - discount_amount;
        grandTotal = taxableAmount + transport_amount + freightAmt + packingAmt + other_amount + cgst_total + sgst_total + igst_total;

        let roundOffValue = (grandTotal - Math.floor(grandTotal));
        if (roundOffValue >= 0.50) {
            roundOffValue = (roundOffValue - 1); // Subtract 1 to get negative round-off
        }

        // Check if freight is taxable
        let freightIsTaxable = $('#chk_freight_isTaxable').is(":checked")
        let freightTaxHSNIsSelected = $('#cmb_freight_hsn_code_id').val();

        if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
            $('#cmb_freight_hsn_code_id').removeAttr('optional', 'optional');
            $('#error_cmb_freight_hsn_code_id').text('Please select the freight tax hsn code.');
            $('#error_cmb_freight_hsn_code_id').show();
        } else if (!freightIsTaxable) {
            setFreightHSNCodeId('');
        } else {
            $('#error_cmb_freight_hsn_code_id').hide();
        }

        if (freightIsTaxable) {
            let freightTaxRate = isNaN(parseFloat($('#cmb_freight_hsn_code_id option:selected').attr('rate'))) === true ? 0 : parseFloat($('#cmb_freight_hsn_code_id option:selected').attr('rate'));
            const freightTaxAmt = (freightAmt * freightTaxRate) / 100;
            if (cmb_expected_branch_state_id === cmb_customer_state_id) {
                // Set inter state tax totals
                cgst_total += freightTaxAmt / 2;
                sgst_total += freightTaxAmt / 2;

            } else {
                // Set outer state tax totals
                igst_total += freightTaxAmt;
            }
        }

        setBasicTotal(validateNumberDateInput.current.decimalNumber(JSON.stringify(basic_total), 4));
        setCGSTTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
        setSGSTTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
        setIGSTTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4))
        setTaxableTotal(validateNumberDateInput.current.decimalNumber((taxableAmount).toString(), 4))
        setGrandTotal(Math.round(grandTotal));
        setRoundOff((roundOffValue).toFixed(2));
    }

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const FnSplitFooterAmtsInDetails = (dispatchChallanMaterialsDetails) => {
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))

        if (dispatchChallanMaterialsDetails.length !== 0) {

            const updatedDetailsData = dispatchChallanMaterialsDetails?.map(material => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = 0;
                if (freightAmt !== 0) {
                    itemFreightAmount = (parseFloat(material.item_taxable_amount) / parseFloat(txt_basic_total)) * freightAmt;
                }
                let discountAmount;
                let calculatedPercent;
                if (discountAmount !== 0 && rb_dispatch_challan_creation_type === 'M') {
                    discountAmount = (material.item_amount / parseFloat(txt_basic_total)) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / material.item_amount;
                } else {
                    discountAmount = 0; calculatedPercent = 0;
                }
                // Create a new object with the updated properties
                return {
                    ...material,
                    item_discount_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    item_discount_percent: validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
                    item_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 2)
                };
            });
            setDispatchChallanMaterials(updatedDetailsData);
            let freightIsTaxable = $('#chk_freight_isTaxable').is(":checked")
            if (freightIsTaxable) {
                // FnCalculateTaxationSummary(updatedDetailsData);
            }
            FnCalculateTaxationSummary(updatedDetailsData);

        }
    }

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    // const reverseCalculationForFreightAmt = (dispatchChallanMaterialsDetails) => {
    //     const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
    //     if (dispatchChallanMaterialsDetails.length !== 0) {
    //         const updatedData = dispatchChallanMaterialsDetails.map(material => {
    //             // Calculate item_freight_amount and discount_amount
    //             let itemFreightAmount = (material.material_basic_amount / txt_basic_total) * freightAmt;
    //             return {
    //                 ...material,
    //                 item_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
    //             };
    //         });
    //         setDispatchChallanMaterials(updatedDetailsData);
    //         let freightIsTaxable = $('#chk_freight_isTaxable').is(":checked")
    //         if (freightIsTaxable) {
    //             FnCalculateTaxationSummary(updatedDetailsData);
    //         }
    //     }
    // }

    const FnCalculateTaxationSummary = (dispatchChallanMaterials) => {
        try {
            const taxableData = [];
            // Use reduce to group data by HSN rate
            const groupedData = dispatchChallanMaterials.reduce((acc, item) => {
                const key = item.hsn_sac_percent; // Group by HSN rate
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});

            // Convert the grouped data object into an array of arrays
            const groupedDataArray = Object.values(groupedData);

            groupedDataArray.forEach(group => {
                const {
                    product_material_hsn_sac_code,
                    item_cgst_percent,
                    item_sgst_percent,
                    item_igst_percent,
                    hsn_sac_id,
                } = group[0];

                const groupTotalCGSTAmt = group.reduce((total, item) => total + parseFloat(item.item_cgst_amount), 0);
                const groupTotalSGSTAmt = group.reduce((total, item) => total + parseFloat(item.item_sgst_amount), 0);
                const groupTotalIGSTAmt = group.reduce((total, item) => total + parseFloat(item.item_igst_amount), 0);
                const groupTotalAmt = group.reduce((total, item) => total + parseFloat(item.item_total_amount), 0);
                const groupTaxableAmt = group.reduce((total, item) => total + parseFloat(item.item_taxable_amount), 0);

                // Create a rows objects for the taxation summary data.
                const groupObject = {
                    product_material_hsn_sac_code,
                    hsn_sac_id,
                    summary_taxable_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(groupTaxableAmt), 4),
                    summary_cgst_percent: validateNumberDateInput.current.decimalNumber(JSON.stringify(item_cgst_percent), 4),
                    summary_cgst_total: validateNumberDateInput.current.decimalNumber(JSON.stringify(groupTotalCGSTAmt), 4),
                    summary_sgst_percent: validateNumberDateInput.current.decimalNumber(JSON.stringify(item_sgst_percent), 4),
                    summary_sgst_total: validateNumberDateInput.current.decimalNumber(JSON.stringify(groupTotalSGSTAmt), 4),
                    summary_igst_percent: validateNumberDateInput.current.decimalNumber(JSON.stringify(item_igst_percent), 4),
                    summary_igst_total: validateNumberDateInput.current.decimalNumber(JSON.stringify(groupTotalIGSTAmt), 4),
                    summary_total_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(groupTotalAmt), 4),
                    total_items_count: group.length,
                };
                taxableData.push(groupObject);
            });

            // Check if freight is taxable
            let freightIsTaxable = $('#chk_freight_isTaxable').is(":checked")
            if (freightIsTaxable) {
                FnCalculateFreightTaxation(taxableData)
            } else {
                setFreightHSNCodeId('');
            }
            setTaxationSummaryData(taxableData);
            FnCalculateGrandTotal(dispatchChallanMaterials, taxableData);
        } catch (error) {
            console.log("error in generating taxation summary: ", error);
            navigator('/Error')
        }
    }

    // Function for calculate fright as a tax and update in tax-summary.
    const FnCalculateFreightTaxation = (taxableData) => {
        let customerState = $('#cmb_customer_state_id').val();
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = $('#cmb_expected_branch_state_id').val();
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) === true ? 0 : parseFloat($('#txt_freight_amount').val())

        const taxSummary = {
            product_material_hsn_sac_code: $('#cmb_freight_hsn_code_id option:selected').attr('lbl'),
            hsn_sac_id: $('#cmb_freight_hsn_code_id').val(),
            summary_taxable_amount: freightAmt,
            summary_cgst_percent: 0, summary_cgst_total: 0,
            summary_sgst_percent: 0, summary_sgst_total: 0,
            summary_igst_percent: 0, summary_igst_total: 0,
            summary_total_amount: freightAmt,
        };

        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
        $('#cmb_freight_hsn_code_id').removeAttr('optional');
        let freightTaxRate = isNaN(parseFloat($('#cmb_freight_hsn_code_id option:selected').attr('rate'))) === true ? 0 : parseFloat($('#cmb_freight_hsn_code_id option:selected').attr('rate'));
        const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

        if (customerIsSez === 'true' || custStateCode === 99) {
            // If customer is sezzed OR outer teritory tax totals
            taxSummary.summary_cgst_total = 0;
            taxSummary.summary_sgst_total = 0;
            taxSummary.summary_igst_total = 0;

        } else if (companyBranchStateId === customerState) {
            // Set inter state tax totals
            taxSummary.summary_cgst_total = (freightTaxAmt / 2);
            taxSummary.summary_cgst_percent = (freightTaxRate / 2);

            taxSummary.summary_sgst_total = (freightTaxAmt / 2);
            taxSummary.summary_sgst_percent = (freightTaxRate / 2);
        } else {
            // Set outer state tax totals
            taxSummary.summary_igst_total = freightTaxAmt;
            taxSummary.summary_igst_percent = freightTaxRate;
        }

        const existingFreightTaxationObj = taxableData.findIndex((hsnGroup) => hsnGroup.hsn_sac_id === parseInt(taxSummary.hsn_sac_id));
        if (existingFreightTaxationObj !== -1) {
            let object = taxableData[existingFreightTaxationObj]
            let taxableAmt = parseFloat(object.summary_taxable_amount) + parseFloat(taxSummary.summary_taxable_amount)
            let cgstTotal = parseFloat(object.summary_cgst_total) + parseFloat(taxSummary.summary_cgst_total);
            let sgstTotal = parseFloat(object.summary_sgst_total) + parseFloat(taxSummary.summary_sgst_total);
            let igstTotal = parseFloat(object.summary_igst_total) + parseFloat(taxSummary.summary_igst_total);
            let totalAmt = parseFloat(object.summary_total_amount) + parseFloat(taxSummary.summary_total_amount);

            object.summary_taxable_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(taxableAmt), 4)
            object.summary_cgst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgstTotal), 4)
            object.summary_cgst_percent = taxSummary.summary_cgst_percent;
            object.summary_sgst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(sgstTotal), 4)
            object.summary_sgst_percent = taxSummary.summary_cgst_percent;
            object.summary_igst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(igstTotal), 4)
            object.summary_igst_percent = taxSummary.summary_cgst_percent;
            object.summary_total_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(totalAmt), 4)

        } else {
            taxableData.push(taxSummary);                           // Push the new object if not found
        }
    }



    // -------------------------------------Validations.

    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber(JSON.stringify(percentageInput), 4);

        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill this field...!';
        } else if (parseFloat(percentageInput) > 100) {
            percentageValidateMsg = 'Percentage should not be greater than 100%...!';
        } else {
            return true;
        }
        return percentageValidateMsg;
    }

    // Validations for remove the error-msg.
    const validateFields = () => {
        let formObj = $('#DispatchChallanHeaderFormId');
        let inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }
    }

    const FnGetCustomerContactIds = () => {
        let selectedCustomerContactPersonIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            selectedCustomerContactPersonIds += $(this).val() + ":";
        });
        return selectedCustomerContactPersonIds.replace(/:$/, '');
    }

    const FnUpdateStockAvailability = async (materialDetailsData, setInTable) => {
        let uniqueMaterialIds = [...new Set(materialDetailsData.map(material => material.product_material_id))];

        // Get the available stock qty.
        resetGlobalQuery();
        globalQuery.columns = ["product_rm_id", "closing_balance_quantity",]
        globalQuery.table = "smv_product_rm_stock_summary"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "product_rm_id", operator: "IN", values: [...new Set(uniqueMaterialIds)] });
        globalQuery.groupBy = ['product_rm_id'];
        let getAvlStockQty = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        // get the available stock-qty and set in details.
        let materialDetailsUpdatedData = materialDetailsData.map(material => {
            const stock = getAvlStockQty.find(stock => stock.product_rm_id === material.product_material_id);
            return stock ? { ...material, available_stock_qty: stock?.closing_balance_quantity || 0, dispatch_quantity: stock.closing_balance_quantity >= material.max_dispatch_quantity ? material.max_dispatch_quantity : stock.closing_balance_quantity } : material;
        });

        if (setInTable) {
            setDispatchChallanMaterials(materialDetailsUpdatedData);
        }
        return materialDetailsUpdatedData;
    }

    // -------------------------------- CRUD Operations for the Dispatch-challan.
    const generateDispatchChallanYarn = async () => {
        debugger
        $('#spinner_id').show();
        $('#spinner_text').text('Validating Challan Details...');
        try {
            let latestDispatchChallanNo = txt_dispatch_challan_no;
            let dispatchChallanIsValid = await FnValidateDispatchChallan();

            // If all data is valid then create json and save it.
            if (dispatchChallanIsValid) {
                let dispatchChallanTransJSON = { 'TransHeaderData': {}, 'TransDetailData': [], 'TaxSummaryData': [], 'TransBatchwiseData': [], 'TransInspectionDetailData': [], 'commonIds': {}, 'TransPackingDetailData': [], 'TransProductParametersData': [] }
                // First get the latest Dispatch-Challan-No Before saving.
                if (dispatch_challan_master_transaction_id === 0) { latestDispatchChallanNo = await FnGenerateDispatchChallanNo(); }

                // *** Now Start for JSON Creation:
                let customerContactsIds = FnGetCustomerContactIds();
                // JSON Object For Master Data.
                let dispatchChallanMstData = {
                    dispatch_challan_master_transaction_id: dispatch_challan_master_transaction_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_challan_type_id: cmb_dispatch_challan_type_id,
                    dispatch_challan_type: $('#cmb_dispatch_challan_type_id').find(":selected").text(),
                    dispatch_challan_creation_type: rb_dispatch_challan_creation_type,
                    dispatch_challan_no: latestDispatchChallanNo,
                    dispatch_challan_date: dt_dispatch_challan_date,
                    dispatch_challan_version: txt_dispatch_challan_version,
                    customer_id: cmb_customer_id,
                    customer_contacts_ids: customerContactsIds,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_gst_no: txt_customer_gst_no.trim(),
                    overall_schedule_date: dt_overall_schedule_date,
                    customer_pan_no: txt_customer_pan_no.trim(),
                    consignee_id: cmb_consignee_id,
                    consignee_state_id: cmb_consignee_state_id,
                    consignee_city_id: cmb_consignee_city_id,
                    consignee_gst_no: txt_consignee_gst_no.trim(),
                    consignee_pan_no: txt_consignee_pan_no.trim(),
                    transporter_id: cmb_transporter_id,
                    transporter_state_id: cmb_transporter_state_id,
                    transporter_gst_no: cmb_transporter_id,
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    // Footer fields.
                    vehicle_no: txt_vehicle_no,
                    vehicle_type: txt_vehicle_type.trim(),
                    transporter_challan_no: txt_transporter_challan_no.trim(),
                    transporter_challan_date: dt_transporter_challan_date,
                    transport_mode: cmb_transport_mode,
                    transaction_type: cmb_transaction_type,
                    is_service: rb_is_service,
                    is_sez: rb_is_sez,
                    interim: rb_interim,
                    loading_time: dt_loading_time,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: dt_approved_date,
                    dispatch_challan_status: 'A',
                    basic_total: txt_basic_total,
                    transport_amount: txt_transport_amount,
                    freight_amount: txt_freight_amount,
                    is_freight_taxable: chk_freight_isTaxable,
                    freight_hsn_code_id: cmb_freight_hsn_code_id,
                    packing_amount: txt_packing_amount,
                    discount_percent: txt_discount_percent,
                    discount_amount: txt_discount_amount,
                    other_amount: txt_other_amount,
                    taxable_total: txt_taxable_total,
                    cgst_total: txt_cgst_total,
                    sgst_total: txt_sgst_total,
                    igst_total: txt_igst_total,
                    roundoff: txt_roundoff,
                    grand_total: txt_grand_total,
                    agent_id: cmb_agent_id,
                    agent_percent: txt_agent_percent,
                    agent_amount: txt_agent_amount,
                    agent_paid_status: cmb_agent_paid_status,
                    dispatch_challan_remark: txt_dispatch_challan_remark.trim(),
                    other_terms_conditions: txt_other_terms_conditions.trim(),
                    mail_sent_status: 'P',
                    remark: txt_remark.trim(),
                    is_active: rb_is_active,
                    created_by: UserName
                }
                dispatchChallanTransJSON.TransHeaderData = dispatchChallanMstData;  // In JSON Add the master data.

                // Define the fields to add
                const additionalMasterFields = {
                    company_id: parseInt(COMPANY_ID),
                    company_branch_id: parseInt(COMPANY_BRANCH_ID),
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_challan_type_id: cmb_dispatch_challan_type_id,
                    dispatch_challan_type: $('#cmb_dispatch_challan_type_id').find(":selected").text(),
                    dispatch_challan_no: latestDispatchChallanNo,
                    dispatch_challan_date: dt_dispatch_challan_date,
                    dispatch_challan_version: txt_dispatch_challan_version,
                    dispatch_challan_master_transaction_id: dispatch_challan_master_transaction_id,
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    created_by: UserName
                };

                // JSON Objects Array For Details Data.
                let dispatchChallanMaterialsJSONArray = dispatchChallanMaterials?.map(challanMaterial => ({
                    ...challanMaterial,             // Add all challan-material's existing fields.

                    dispatch_challan_details_transaction_id: keyForViewUpdate === 'approve' ? challanMaterial.dispatch_challan_details_transaction_id : 0,
                    product_material_print_name: challanMaterial.product_material_print_name.trim() === '' ? challanMaterial.product_material_name : challanMaterial.product_material_print_name.trim(),
                    dispatch_challan_remark: challanMaterial.dispatch_challan_remark,
                    dispatch_challan_item_status: 'A',
                    remark: challanMaterial.remark,

                    ...additionalMasterFields     // Added default additional which is not in table json array.
                }));
                dispatchChallanTransJSON.TransDetailData = dispatchChallanMaterialsJSONArray;  // In JSON Add the Dispatch-challan-details data.

                let taxationSummaryJSONArray = taxationSummaryData?.map(taxSummary => ({
                    ...taxSummary,                  // Add all taxSummary's existing fields.

                    dispatch_challan_tax_summary_transaction_id: keyForViewUpdate === 'approve' ? taxSummary.dispatch_challan_tax_summary_transaction_id : 0,
                    customer_id: cmb_customer_id,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_contacts_ids: customerContactsIds,
                    hsn_code_id: taxSummary.hsn_sac_id,

                    ...additionalMasterFields     // Added default additional which is not in table json array.
                }));
                dispatchChallanTransJSON.TaxSummaryData = taxationSummaryJSONArray;  // In JSON Add the Dispatch-challan-taxation-summary data.

                // let inspectionDetailsJsonArray = inspectionDetailsList?.map(inspectionItem => ({
                //     company_id: COMPANY_ID,
                //     company_branch_id: COMPANY_BRANCH_ID,
                //     financial_year: FINANCIAL_SHORT_NAME,
                //     dispatch_challan_no: txt_dispatch_challan_no,
                //     dispatch_challan_date: dt_dispatch_challan_date,
                //     dispatch_challan_version: txt_dispatch_challan_version,
                //     dispatch_inspection_details_transaction_id: 0,
                //     dispatch_challan_master_transaction_id: dispatch_challan_master_transaction_id,
                //     sales_order_no: inspectionItem.sales_order_no,
                //     sales_order_Date: inspectionItem.sales_order_Date,
                //     sales_order_version: inspectionItem.sales_order_version,
                //     customer_id: cmb_customer_id,
                //     customer_order_no: inspectionItem.customer_order_no,
                //     customer_order_Date: inspectionItem.customer_order_Date,
                //     product_material_id: inspectionItem.product_material_id,
                //     product_material_unit_id: inspectionItem.product_material_stock_unit_id,
                //     inspection_production_code: inspectionItem.inspection_production_code,
                //     inspection_order_no: inspectionItem.inspection_order_no,
                //     inspection_production_date: inspectionItem.inspection_production_date,
                //     weaving_production_inspection_details_id: inspectionItem.weaving_production_inspection_details_id,
                //     inspection_production_set_no: inspectionItem.inspection_production_set_no,
                //     inspection_mtr: inspectionItem.inspection_mtr,
                //     dispatch_quantity: inspectionItem.dispatch_quantity,
                //     sort_no: inspectionItem.sort_no,
                //     roll_no: inspectionItem.roll_no,
                //     godown_id: inspectionItem.godown_id,
                //     created_by: UserName,
                //     modified_by: UserName
                // }), [])

                // dispatchChallanTransJSON.TransInspectionDetailData = inspectionDetailsJsonArray; //Add Inspection List for dispatch

                let packingDetailsJsonArray = PackingDetailsList?.map((packingItem, Index) => ({

                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_challan_no: txt_dispatch_challan_no,
                    dispatch_challan_date: dt_dispatch_challan_date,
                    dispatch_challan_version: txt_dispatch_challan_version,
                    dispatch_schedule_packing_id: packingItem.dispatch_schedule_packing_id,
                    dispatch_challan_packing_id: 0,
                    dispatch_challan_master_transaction_id: dispatch_challan_master_transaction_id,
                    // sales_order_details_transaction_id
                    customer_order_no: packingItem.customer_order_no,
                    so_sr_no: packingItem.so_sr_no,
                    sr_no: Index + 1,
                    product_material_id: packingItem.product_material_id,
                    product_unit_id: packingItem.product_unit_id,
                    production_count_id: packingItem.production_count_id,
                    yarn_packing_date: packingItem.yarn_packing_date,
                    lot_no: packingItem.lot_no,
                    sub_lot_no: packingItem.sub_lot_no,
                    cone_weight: packingItem.cone_weight,
                    cone_quantity: packingItem.cone_quantity,
                    per_packing_weight: packingItem.per_packing_weight,
                    packing_gross_weight: packingItem.packing_gross_weight,
                    packing_net_weight: packingItem.packing_net_weight,
                    packing_quantity: packingItem.packing_quantity,
                    packing_starting_no: packingItem.packing_starting_no,
                    packing_ending_no: packingItem.packing_ending_no,
                    item_dispatch_quantity: packingItem.item_dispatch_quantity,
                    item_dispatch_weight: packingItem.item_dispatch_weight,
                    yarn_packing_details_id: packingItem.yarn_packing_details_id,
                    yarn_packing_types_id: packingItem.yarn_packing_types_id,
                    created_by: keyForViewUpdate === "" ? UserName : packingItem.created_by,
                    modified_by: keyForViewUpdate === "update" ? UserName : null
                }), [])
                dispatchChallanTransJSON.TransPackingDetailData = packingDetailsJsonArray; //Add Inspection List for dispatch


                for (let index = 0; index < productTypePropertiesData.length; index++) {
                    const parameterDetailsRow = productTypePropertiesData[index];
                    const parameterObj = {

                        // customer_order_no
                        // so_sr_no
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        dispatch_challan_master_transaction_id: dispatch_challan_master_transaction_id,
                        // dispatch_challan_details_transaction_id
                        dispatch_challan_parameter_id: 0,
                        product_parameter_id: parameterDetailsRow.product_parameter_id,
                        dispatch_challan_no: txt_dispatch_challan_no,
                        dispatch_challan_date: dt_dispatch_challan_date,
                        dispatch_challan_version: txt_dispatch_challan_version,
                        product_id: parameterDetailsRow.product_id,
                        product_type_id: cmb_dispatch_challan_type_id,
                        product_parameter_name: parameterDetailsRow.product_parameter_name,
                        product_parameter_value: parameterDetailsRow.product_parameter_value,
                        product_parameter_actual_value: parameterDetailsRow.product_parameter_actual_value,
                        product_parameter_sufix: parameterDetailsRow.product_parameter_sufix,
                        product_parameter_prefix: parameterDetailsRow.product_parameter_prefix,
                        product_parameter_calculation_type: parameterDetailsRow.product_parameter_calculation_type,
                        product_parameter_from_value: parameterDetailsRow.product_parameter_from_value,
                        product_parameter_to_value: parameterDetailsRow.product_parameter_to_value,
                        product_parameter_formula: parameterDetailsRow.product_parameter_formula,
                        remark: parameterDetailsRow.remark,
                        created_by: keyForViewUpdate === "" ? UserName : parameterDetailsRow.created_by,
                    }
                    dispatchChallanTransJSON.TransProductParametersData.push(parameterObj)
                }


                // Common Ids
                dispatchChallanTransJSON.commonIds = additionalMasterFields;
                console.log('Sending JSON For Save Transaction: ', dispatchChallanTransJSON);

                const formData = new FormData();
                formData.append(`MtDispatchChallanData`, JSON.stringify(dispatchChallanTransJSON))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json();

                console.log('Response after Save Dispatch-Challan: ', responce)
                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataFunc.current.evitCache();
                    console.log(evitCache);
                    setMessage(responce.message)
                    if (responce.data.hasOwnProperty('dispatch_challan_version') && responce.data.hasOwnProperty('dispatch_challan_no')) {
                        setDispatchChallanMasterTransactionId(responce.data.dispatch_challan_master_transaction_id);
                        setDispatchChallanVersion(responce.data.dispatch_challan_version);
                        setModalOrderDetails(`Dispatch-Challan No: ${responce.data.dispatch_challan_no} and Version: ${responce.data.dispatch_challan_version}`)
                    }
                    if (dispatch_challan_master_transaction_id === 0) {
                        setMessageForConfirmation('Do you want to send email...!')
                    }
                    setShowConfiemationModal(true);
                }
            }
            $('#spinner_id').hide();
        } catch (error) {
            $('#spinner_id').hide();
            console.log("error in saving dispatch-challan: ", error)
            navigator('/Error')
        }
    }

    const FnCheckUpdateResponse = async () => {
        debugger
        try {
            const getDispatchChallanAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnShowAllDetailsAndMastermodelRecords/${txt_dispatch_challan_version}/${newIDList.financial_year}/${COMPANY_ID}?dispatch_challan_no=${txt_dispatch_challan_no}`)
            const getDispatchChallanAPICallResponse = await getDispatchChallanAPICall.json();
            console.log('Response for render data:', getDispatchChallanAPICallResponse);



            let challanMstData = getDispatchChallanAPICallResponse.DispatchChallanMasterRecord;
            // Set Dispatch-Challan-Mst Data
            setDispatchChallanCreationType(challanMstData.dispatch_challan_creation_type);
            setDispatchChallanTypeId(challanMstData.dispatch_challan_type_id);
            setDispatchChallanDate(challanMstData.dispatch_challan_date);
            setOverAllScheduleDate(challanMstData.overall_schedule_date);
            setDispatchChallanStatus(challanMstData.dispatch_challan_status);
            setExpectedBranchId(challanMstData.expected_branch_id);
            setExpectedBranchStateId(challanMstData.expected_branch_state_id);
            await FnComboOnChange('CompanyBranchState');
            setExpectedBranchCityId(challanMstData.expected_branch_city_id);

            setCustomerId(challanMstData.customer_id);
            let custContactData = await FnGetCustomersContactPersonsDetails(challanMstData.customer_id);
            // Set the checked checkboxes first.
            const customerContactPersonsIds = challanMstData.customer_contacts_ids.split(":").map(Number);
            const rearrangedContactsRows = [
                ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
            ];
            setCustContactDetails(rearrangedContactsRows);

            setCustomerStateId(challanMstData.customer_state_id);
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_city"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "state_id", operator: "=", value: challanMstData.customer_state_id });
            const getCustomerCitiesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setCustomerCityOpts(getCustomerCitiesApiCall);
            setCustomerCityId(challanMstData.customer_city_id);

            // set customer selected-contact-persons chk boxes.
            customerContactPersonsIds.forEach(function (contactId, index) {
                $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
            });
            setCustomerGSTNo(challanMstData.customer_gst_no);
            setCustomerPANNo(challanMstData.customer_pan_no);
            if (challanMstData.dispatch_challan_creation_type !== 'M') {
                await FnLoadMultiSelectComboData('Customer');
            }
            setConsigneeId(challanMstData.consignee_id);
            setConsigneeStateId(challanMstData.consignee_state_id);
            await FnComboOnChange('ConsigneeState')
            setConsigneeCityId(challanMstData.consignee_city_id);
            setConsigneeGSTNo(challanMstData.consignee_gst_no);
            setConsigneePANNo(challanMstData.consignee_pan_no);
            setApprovedById(challanMstData.approved_by_id);
            setApprovedDate(challanMstData.approved_date);
            setDispatchChallanRemark(challanMstData.dispatch_challan_remark);
            setOtherTermsConditions(challanMstData.other_terms_conditions);
            setRemark(challanMstData.remark);
            setIsActive((challanMstData.is_active).toString());
            setIsService(challanMstData.is_service);
            setIsSez(challanMstData.is_sez);
            setInterim(challanMstData.interim);
            // Footer Fields.
            setBasicTotal(challanMstData.basic_total);
            setTransportAmount(challanMstData.transport_amount);
            setFreightIsTaxable(challanMstData.is_freight_taxable);
            setFreightAmount(challanMstData.freight_amount);
            setFreightHSNCodeId(challanMstData.freight_hsn_code_id);
            setPackingAmount(challanMstData.packing_amount);
            setDiscountPercent(challanMstData.discount_percent);
            setDiscountAmt(challanMstData.discount_amount);
            setOtherAmount(challanMstData.other_amount);
            setTaxableTotal(challanMstData.taxable_total);
            setCGSTTotal(challanMstData.cgst_total);
            setSGSTTotal(challanMstData.sgst_total);
            setIGSTTotal(challanMstData.igst_total);
            setGrandTotal(challanMstData.grand_total);
            setRoundOff(challanMstData.roundoff);
            setAgentId(challanMstData.agent_id);
            setAgentPercent(challanMstData.agent_percent);
            setAgentAmount(challanMstData.agent_amount);
            setAgentPaidStatus(challanMstData.agent_paid_status);
            setTransporterId(challanMstData.transporter_id);
            if (transporterOpts.length <= 0) {
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "transporter_gst_no", "transporter_state_id", "transporter_city_id"]
                globalQuery.table = "cmv_transporter"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // const getTransporterApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                // setTransporterOpts(getTransporterApiCall);
                comboDataFunc.current.fillFiltersCombo(globalQuery)
                    .then(transporterList => {
                        setTransporterOpts(transporterList);
                    });
            } else {
                const selectedTransporterData = transporterOpts.find((item) => parseInt(item.field_id) === challanMstData.transporter_id);
                if (selectedTransporterData) {
                    setTransporterStateId(selectedTransporterData.transporter_state_id);
                }
            }
            setTransporterGSTNo(challanMstData.transporter_gst_no);
            setTransporterChallanNo(challanMstData.transporter_challan_no);
            setTransporterChallanDate(challanMstData.transporter_challan_date);
            setTransportMode(challanMstData.transport_mode);
            setTransactionType(challanMstData.transaction_type);
            setLoadingTime(challanMstData.loading_time);
            setVehicleType(challanMstData.vehicle_type);
            setVehicleNo(challanMstData.vehicle_no);


            if (challanMstData.dispatch_challan_creation_type !== 'M') {
                const distinctCustOrderNos = [...new Set(getDispatchChallanAPICallResponse.DispatchChallanDetailsRecords
                    ?.map(item => item.customer_order_no))];

                console.log('distinctCustOrderNos: ', distinctCustOrderNos)

                $("input:checkbox[name=customerOrderNoCheckBox]").each(function () {
                    const checkboxValue = $(this).val();
                    const orderNoIndex = distinctCustOrderNos.findIndex((customer_order_no) => customer_order_no === checkboxValue)
                    if (orderNoIndex !== -1) {
                        $(this).prop('checked', true);
                    }
                });

                // Loop through each checkbox with the name "customerOrderNoCheckBox"
                // distinctCustOrderNos.forEach(custOrderNo => {
                //     const checkboxId = `#filterCheckCustOrd-${escapeSelectorValue(custOrderNo)}`;
                //     $(checkboxId).prop('checked', true);
                // });
                setcustomerOrderNos(distinctCustOrderNos.length);

                if (challanMstData.dispatch_challan_creation_type === 'D') {
                    await FnLoadMultiSelectComboData('CustomerOrder');
                }

                // Load the dispatch-schedules nos.
                setTimeout(() => {
                    if (challanMstData.dispatch_challan_creation_type === 'D') {
                        const distinctDispScheduleNos = [...new Set(getDispatchChallanAPICallResponse.DispatchChallanDetailsRecords
                            ?.map(item => item.dispatch_schedule_no))];

                        distinctDispScheduleNos.forEach(dispScheduleNo => {
                            const escapedDispScheduleNo = $.escapeSelector(dispScheduleNo);
                            const checkboxId = `#filterCheckDispSch-${escapedDispScheduleNo}`;
                            $(checkboxId).prop('checked', true);
                        });
                        setSelectedDispatchSchedulesNos(distinctDispScheduleNos.length);
                    }
                }, 500);
            }

            // Set Dispatch-Challan-Details data.
            // First update the keys.
            let updatedDispChallanDetails = getDispatchChallanAPICallResponse.DispatchChallanDetailsRecords?.map((receivedChallanDetails, index) => {
                receivedChallanDetails.product_material_unit_name = receivedChallanDetails.product_unit_name;
                receivedChallanDetails.product_material_hsn_sac_code = receivedChallanDetails.hsn_sac_code;
                // receivedChallanDetails.product_material_std_weight = receivedChallanDetails.product_unit_name;
                delete receivedChallanDetails.product_unit_name;
                delete receivedChallanDetails.hsn_sac_code;
                return receivedChallanDetails;
            });
            setDispatchChallanMaterials(updatedDispChallanDetails);

            // Set the Dispatch-Challan-Batchwise data.
            setBatchWiseStockTblData(getDispatchChallanAPICallResponse.DispatchChallanBatchwiseRecords);

            // setInspectionDetailsList(getDispatchChallanAPICallResponse.DispatchInspectionDetailsRecords);
            //changes by tushar
            setPackingDetailsList(getDispatchChallanAPICallResponse.DispatchChallanPackingDetailsRecord);

            await fnGetProductProperties(getDispatchChallanAPICallResponse.DispatchChallanProductDynamicParametersRecords);
            // Set the Dispatch-Challan-Taxation-Summary data.
            FnCalculateTaxationSummary(updatedDispChallanDetails);

        } catch (error) {
            console.log("error in rendering data for dispatch-challan: ", error)
            navigator('/Error')
        }
    }

    function escapeSelectorValue(value) {
        // Escape special characters (including spaces) with double backslashes
        return value.replace(/[!"#$%&'()*+,.\/:;<=>?@[\\\]^`{|}~]/g, "\\$&");
    }

    const FnGenerateMailJson = async () => {
        let EmailData = { 'EmailType': "Dispatch Challan Generation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        const custContPersCheckBoxes = $('.selectCustContactPerson:checked');
        custContPersCheckBoxes.each(function () {
            let selectedContact_id = parseInt($(this).attr('contact_personId'));
            const customerContactPersonDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
            if (customerContactPersonDetails) {
                EmailData.toMailData.push(customerContactPersonDetails.cust_email_id);
            }
        });
        let cust_order_nos = [];
        $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
            if ($(this).val() !== undefined) {
                cust_order_nos.push($(this).val());
            }
        });

        EmailData.subject = `${sessionStorage.getItem('companyLegalName')} Dispatch Challan for Order ${rb_dispatch_challan_creation_type === 'M' ? txt_dispatch_challan_no : cust_order_nos.join(', ')}`;      // Subject
        EmailData.parameters.push("Dispatch Challan Information");                                          // Param0 It is a heading.
        EmailData.parameters.push($('#cmb_customer_id').find(":selected").text());                          // Param1
        EmailData.parameters.push(`${rb_dispatch_challan_creation_type === 'M' ? txt_dispatch_challan_no : cust_order_nos.join(', ')}`);// Param2
        EmailData.parameters.push(`${dt_loading_time}`);                                                    // Param3
        EmailData.parameters.push(`${txt_dispatch_challan_no}`);                                            // Param4
        EmailData.parameters.push(`${dt_dispatch_challan_date}`);                                           // Param5
        EmailData.parameters.push($('#cmb_transporter_id').find(":selected").text());                       // Param6
        EmailData.parameters.push(`${txt_vehicle_no}`);                                                     // Param7
        EmailData.parameters.push(sessionStorage.getItem('companyLegalName'));                              // Param8
        return EmailData;
    }

    const FnSendEmail = async () => {
        try {
            $('#spinner_id').show();
            $('#spinner_text').text('Sending mail...');
            let emailContent = await FnGenerateMailJson();

            // Generate the dispatch-challan PDF.
            // let dispatchChallanContent = await printInvoice(false);
            let dispatchChallanContent = 'dispatch-challan printouts.';
            const fileName = `${txt_dispatch_challan_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const dispatchChallanContainer = document.createElement('div');
            dispatchChallanContainer.style.margin = '20px';
            dispatchChallanContainer.innerHTML = dispatchChallanContent;
            let worker = html2pdf().from(dispatchChallanContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(emailContent))
                formData.append("dispatchChallanPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnSendEmail/${dispatch_challan_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    closeConfirmationModal();

                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
            });
            $('#spinner_id').hide();
        } catch (error) {
            $('#spinner_id').hide();
            console.log('Error in email sending: ', error);
            navigator('/Error')
        }
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    // --------------------------------- Validations Functions--------------------------------------
    const FnValidateDispatchChallan = async () => {
        let dispatchChallanTransactionDataIsValid = true
        let headerDataIsValid = await validate.current.validateForm("DispatchChallanHeaderFormId");
        if (!headerDataIsValid) { return false; }
        let footerDataIsValid = await validate.current.validateForm("DispatchChallanFooterFormId");
        if (!footerDataIsValid) { return false; }

        if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
            $('#error_customer_contact_persons').text('Please select atleast one contact person.');
            $('#error_cmb_customer_city_id').focus();
            $('#error_customer_contact_persons').show();
            setErrMsg('Please select atleast one contact person.');
            setShowErrorMsgModal(true);
            return false;
        } else {
            $('#error_customer_contact_persons').hide();
        }

        // Check Freight Taxation checkbox selected if yes the check the code is selected or not?
        let freightIsTaxable = $('#chk_freight_isTaxable').is(":checked")
        if (freightIsTaxable && ($('#cmb_freight_hsn_code_id').val() === '' || $('#cmb_freight_hsn_code_id').val() === null)) {
            $('#cmb_freight_hsn_code_id').removeAttr('optional', 'optional');
            $('#error_cmb_freight_hsn_code_id').text('Please select the freight tax hsn code.');
            $('#error_cmb_freight_hsn_code_id').show();
            return false;
        }

        let addedMaterials = $('#DispatchChallanMaterialDtlsTbl tbody tr').length;
        if (addedMaterials <= 0) {  // checked the materials are added or not?
            setErrMsg('Please Add atleast one material...!');
            setShowErrorMsgModal(true);
            dispatchChallanTransactionDataIsValid = false;
            return dispatchChallanTransactionDataIsValid;
        }
        else {
            // First update the stock-availability for materials.
            await FnUpdateStockAvailability(dispatchChallanMaterials, true);

            // // Now check the validate the details-table.
            $('#DispatchChallanMaterialDtlsTbl tbody tr').each(function (index) {
                let currentTblRow = $(this);
                // maxDispatchQty={dispatchChallanItem.max_dispatch_quantity} availDispatchQty={dispatchChallanItem.available_stock_qty}
                let dispQtyField = currentTblRow.find('input[id^="dispatch_quantity_"]');
                let dispatchQty = parseFloat(dispQtyField.val());
                let maxQty = parseFloat(dispQtyField.attr('maxDispatchQty'));
                let avlStockQty = parseFloat(dispQtyField.attr('availDispatchQty'));

                if (dispatchQty >= avlStockQty + 1) {
                    $(currentTblRow.find('input[id^="dispatch_quantity_"]'))[0].parentElement.dataset.tip = 'Dispatch quantity should not be greater than available stock qty...!';
                    $(currentTblRow.find('input[id^="dispatch_quantity_"]'))[0].focus();
                    dispatchChallanTransactionDataIsValid = false;
                    return dispatchChallanTransactionDataIsValid;
                }

                // For mannual dispatch
                if (rb_dispatch_challan_creation_type !== 'M') {
                    // For when challan creation type is not a mannnual.
                    if (dispatchQty >= maxQty + 1) {
                        $(currentTblRow.find('input[id^="dispatch_quantity_"]'))[0].parentElement.dataset.tip = `Dispatch quantity should not be greater than actual order qty : ${maxQty}...!`;
                        $(currentTblRow.find('input[id^="dispatch_quantity_"]'))[0].focus();
                        dispatchChallanTransactionDataIsValid = false;
                        return dispatchChallanTransactionDataIsValid;
                    }
                }
            });
        }
        return dispatchChallanTransactionDataIsValid;
    }

    // ********************************************* Dispatch Challan Document Upload Functionality Starts. *****************************************************
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'documentList':
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                await showDocumentRecords();
                $('#spinner_id').hide();
                break;

            default:
                break;
        }
    }

    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_dispatch_challan_no !== 0 ? txt_dispatch_challan_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setUploadedDocData(resp.content);
                } else {
                    setUploadedDocData([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='document-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        uploadedDocData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">{docItem.document_registration_no}</td>
                                <td className="erp_table_td">{docItem.document_registration_date}</td>
                                <td className="erp_table_td">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td">{docItem.document_path}</td>
                                <td className="erp_table_td">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}> {docItem.file_name} </Link>
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [uploadedDocData]);

    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // ********************************************* Dispatch Challan Document Upload Functionality Ends. *****************************************************

    // ********************************************* Dispatch Challan Cancel & Delete Functionality Starts. *******************************
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnDeleteRecord/${txt_dispatch_challan_version}/${COMPANY_ID}?dispatch_challan_no=${txt_dispatch_challan_no}?deleted_by=${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("Dispatch Challan Deleted: ", responce);
            if (responce.success == '1') {
                setShow(false)
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnCancelDispatchChallan = async () => {
        setShow(false);
        $('#spinner_id').show();
        $('#spinner_text').text('Canceling Dispatch-Challan ...');
        try {
            const method = { method: 'GET' }
            const cancelDispChallanApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnCancelDispatchChallan/${newIDList.dispatch_challan_version}/${newIDList.financial_year}/${COMPANY_ID}?dispatch_challan_no=${newIDList.dispatch_challan_no}&modified_by=${UserName}`, method)
            const cancelDispChallanApiCallResponse = await cancelDispChallanApiCall.json();
            console.log("Dispatch Challan Cancelled: ", cancelDispChallanApiCallResponse);
            if (cancelDispChallanApiCallResponse.success === "0") {
                setErrMsg(cancelDispChallanApiCallResponse.error)
                setShowErrorMsgModal(true)

            } else {
                const evitCache = comboDataFunc.current.evitCache();
                console.log(evitCache);

                setSuccMsg(cancelDispChallanApiCallResponse.message);
                setShowSuccessMsgModal(true);
            }
            $('#spinner_id').hide();
        } catch (error) {
            $('#spinner_id').hide();
            console.log("error in canceling the dispatch-challan: ", error)
        }
    }

    // ********************************************* Dispatch Challan Cancel & Delete Functionality Ends. *****************************************************
    // ********************************************* Dispatch Challan Print Start. ****************************************************************************

    const dispatchChallanContent = {
        customerDetails: {
            customer: '',
            customer_address: '',
            state: '',
            contact: '',
            customer_email: '',
            customer_city: '',
            customer_gst_no: '',
            customer_pan_no: '',

        },
        consigneeDetails: {
            consignee: '',
            consignee_address: '',
            state: '',
            contact: '',
            consignee_email: '',
            consignee_city: '',
            consignee_gst_no: '',
            consignee_pan_no: '',
        },
        dispatchDetails: {
            dispatch_challan_no: '',
            dispatch_challan_date: '',
            dispatch_challan_version: '',
            dispatch_challan_creation_type_desc: '',
            transporter_name: '',
            vehicle_no: '',
        },

        items: [],
    };

    const printInvoice = async (openPrintModal) => {
        debugger
        // Here this api is required because on every save it requires the print-out. 
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchChallanDetails/FnShowAllDetailsAndMastermodelRecords/${txt_dispatch_challan_version}/${newIDList.financial_year}/${COMPANY_ID}?dispatch_challan_no=${txt_dispatch_challan_no}`)
        const response = await apiCall.json();
        let dispatchChallanMasterData = response.DispatchChallanMasterRecord;
        let dispatchChallanDetailsData = response.DispatchChallanDetailsRecords;

        // DispatchMasterJson
        dispatchChallanContent.customerDetails.customer = dispatchChallanMasterData.customer_name
        dispatchChallanContent.customerDetails.customer_address = dispatchChallanMasterData.customer_address1
        dispatchChallanContent.customerDetails.state = dispatchChallanMasterData.customer_state_name
        dispatchChallanContent.customerDetails.contact = dispatchChallanMasterData.customer_phone
        dispatchChallanContent.customerDetails.customer_email = dispatchChallanMasterData.customer_email
        dispatchChallanContent.customerDetails.customer_city = dispatchChallanMasterData.customer_city_name
        dispatchChallanContent.customerDetails.customer_gst_no = dispatchChallanMasterData.customer_gst_no
        dispatchChallanContent.consigneeDetails.customer_pan_no = dispatchChallanMasterData.customer_pan_no

        dispatchChallanContent.consigneeDetails.consignee = dispatchChallanMasterData.consignee_name
        dispatchChallanContent.consigneeDetails.consignee_address = dispatchChallanMasterData.consignee_address1
        dispatchChallanContent.consigneeDetails.state = dispatchChallanMasterData.consignee_state_name
        dispatchChallanContent.consigneeDetails.consignee_email = dispatchChallanMasterData.consignee_email
        dispatchChallanContent.consigneeDetails.consignee_city = dispatchChallanMasterData.consignee_city_name
        dispatchChallanContent.consigneeDetails.consignee_gst_no = dispatchChallanMasterData.consignee_gst_no
        dispatchChallanContent.consigneeDetails.consignee_pan_no = dispatchChallanMasterData.consignee_pan_no


        dispatchChallanContent.dispatchDetails.dispatch_challan_no = dispatchChallanMasterData.dispatch_challan_no
        dispatchChallanContent.dispatchDetails.dispatch_challan_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dispatchChallanMasterData.dispatch_challan_date)
        dispatchChallanContent.dispatchDetails.dispatch_challan_version = dispatchChallanMasterData.dispatch_challan_version
        dispatchChallanContent.dispatchDetails.dispatch_challan_creation_type_desc = dispatchChallanMasterData.dispatch_challan_creation_type_desc

        dispatchChallanContent.dispatchDetails.transporter_name = $("#cmb_transporter_id option:selected").text();
        dispatchChallanContent.dispatchDetails.vehicle_no = txt_vehicle_no


        for (let index = 0; index < dispatchChallanDetailsData.length; index++) {
            const element = dispatchChallanDetailsData[index];

            const detailsData = {
                // sr_no: element.sr_no,
                sr_no: index + 1,
                product_material_print_name: element.product_material_print_name,
                product_material_tech_spect: element.product_material_tech_spect,
                hsn_code: element.hsn_sac_code,
                so_sr_no: element.so_sr_no,
                product_unit_name: element.product_unit_name,
                dispatch_quantity: element.dispatch_quantity,
                dispatch_weight: element.dispatch_weight,
                expected_schedule_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.expected_schedule_date),
                cgst_amount: element.item_cgst_amount,
                sgst_amount: element.item_sgst_amount,
                igst_amount: element.item_igst_amount,
                company: element.company_name,
                company_branch: element.company_branch_name,
                address: element.company_address1,
                phone: element.company_phone_no,
                cell: element.company_cell_no,
                email: element.company_EmailId,
                website: element.company_website,
                GSTIN: element.company_pan_no,
                pan_no: element.company_pan_no,
                state_name: element.company_state,
                company_pincode: element.company_pincode,
                company_udyog_adhar_no: element.company_udyog_adhar_no,
            }
            dispatchChallanContent.items.push(detailsData)
            console.log(detailsData);
        }
        if (openPrintModal) {
            let redirectTo = compType === 'Register' ? '/Transactions/TYarnDispatchChallan/TYarnDispatchChallanListing/reg' : `/Transactions/TYarnDispatchChallan/TYarnDispatchChallanListing`
            navigator('/Invoice', { state: { invoiceData: dispatchChallanContent, navigationLink: redirectTo, idList: newIDList, keyForViewUpdate: keyForViewUpdate, invoiceType: 'DC', title: 'Dispatch Challan', compType: compType } });
        }
    };


    // ********************************************* Dispatch Challan Print Ends. ****************************************************************************

    //***********************************************packing Details *********************************** */

    //Fn to render Packing details table
    const renderPackingDetailsTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='packing-details-table-id' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Lot No</th>
                        <th className="erp_table_th">Count</th>
                        <th className="erp_table_th">Actual Count</th>
                        <th className="erp_table_th">Packing Types</th>
                        <th className="erp_table_th">Packing Quantity</th>
                        {/* <th className="erp_table_th">Prev. Dispatch Quantity</th> */}
                        <th className="erp_table_th">Dispatch Quantity</th>
                        <th className="erp_table_th">Dispatch Weight</th>
                        <th className="erp_table_th">Starting No</th>
                        <th className="erp_table_th">Ending No</th>
                        <th className="erp_table_th">Gross Weight</th>
                        <th className="erp_table_th">Net Weight</th>
                        <th className="erp_table_th">Cone Quantity</th>
                        <th className="erp_table_th">Cone Weight</th>
                        <th className="erp_table_th">Per Packing Weight</th>
                        <th className="erp_table_th">Yarn Packing Date</th>
                        <th className="erp_table_th">Unit</th>
                    </tr>
                </thead>
                <tbody>
                    {PackingDetailsList.map((packingItem, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{packingItem.lot_no}</td>
                            <td className="erp_table_td ">{packingItem.production_count_name}</td>
                            <td className="erp_table_td text-end">{packingItem.production_actual_count}</td>
                            <td className="erp_table_td ">{packingItem.yarn_packing_types_name}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_quantity}</td>
                            {/* <td className="erp_table_td text-end">{packingItem.prev_dispatch_quantity}</td> */}
                            <td className="erp_table_td text-end">{packingItem.item_dispatch_quantity}</td>
                            <td className="erp_table_td text-end">{packingItem.item_dispatch_weight}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_starting_no}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_ending_no}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_gross_weight}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_net_weight}</td>
                            <td className="erp_table_td text-end">{packingItem.cone_quantity}</td>
                            <td className="erp_table_td text-end">{packingItem.cone_weight}</td>
                            <td className="erp_table_td text-end">{packingItem.per_packing_weight}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(packingItem.yarn_packing_date)}</td>
                            <td className="erp_table_td ">{packingItem.product_unit_name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [PackingDetailsList])



    // ---------------------product base properties----------------------------------------------------------------------------------------------------------------------------------
    //Fn to render propertie details of material  table
    const renderProductPropertiesTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='productProperties-table-id' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className='erp_table_th'>Material Name</th>
                        <th className="erp_table_th">Parameter Name</th>
                        <th className="erp_table_th">Parameter Value</th>
                        <th className="erp_table_th">Prefix</th>
                        <th className="erp_table_th">Sufix</th>
                        <th className="erp_table_th">Calculation Type</th>
                        <th className="erp_table_th">From Value</th>
                        <th className="erp_table_th">To Value</th>
                        <th className="erp_table_th">Actual Value</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {productTypePropertiesData.map((filter, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td ">{index + 1}</td>
                            <td className="erp_table_td ">{filter.product_material_name}</td>
                            <td className="erp_table_td ">{filter.product_parameter_name}</td>
                            <td className="erp_table_td ">
                                {filter.newFilter.type === 'C' || filter.newFilter.type === 'P' ? (
                                    <select id={`product_parameter_value_${index}`} className="form-select form-select-sm" value={filter.product_parameter_value} disabled>
                                        <option value=''>Select</option>
                                        {filter.newFilter.dataArray.map((item, index) => (
                                            <option key={index} value={item.field_id}>
                                                {item.field_name}
                                            </option>
                                        ))}
                                    </select>
                                ) : filter.newFilter.type === 'T' ? (<>
                                    {filter.product_parameter_value}
                                </>
                                ) : ""}
                            </td>
                            <td className="erp_table_td "> {filter.product_parameter_prefix}</td>
                            <td className="erp_table_td "> {filter.product_parameter_sufix}</td>
                            <td className="erp_table_td ">
                                <select id={`product_parameter_calculation_type_${index}`} className="form-select form-select-sm"
                                    value={filter.product_parameter_calculation_type} disabled>
                                    <option value=''>Select</option>
                                    <option value='Equals'>Equals</option>
                                    <option value='Less than'>Less than</option>
                                    <option value='Greater than'>Greater than</option>
                                    <option value='Between'>Between</option>
                                </select>
                            </td>
                            <td className="erp_table_td "> {filter.product_parameter_from_value}</td>
                            <td className="erp_table_td  "> {filter.product_parameter_to_value} </td>
                            <td className="erp_table_td  ">
                                <input type="text" className="erp_input_field mb-0 "
                                    Headers="product_parameter_actual_value" id={`product_parameter_actual_value_${index}`} value={filter.product_parameter_actual_value}
                                // onChange={(e) => { updatePropertiesTblRows(filter, e); }} 
                                />
                            </td>
                            <td className="erp_table_td  ">{filter.remark}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [productTypePropertiesData]);


    const fnGetProductProperties = async (getProductProperties) => {

        if (getProductProperties !== null && getProductProperties.length !== 0) {
            const modifyProductPropertiesData = [];
            for (let i = 0; i < getProductProperties.length; i++) {
                const propertiesItem = getProductProperties[i];
                let masterList;
                const initializeKeys = {
                    product_id: propertiesItem.product_id,
                    product_parameter_value: propertiesItem.product_parameter_value,
                    product_parameter_calculation_type: propertiesItem.product_parameter_calculation_type,
                    product_parameter_from_value: propertiesItem.product_parameter_from_value,
                    product_parameter_id: propertiesItem.product_parameter_id,
                    product_parameter_prefix: propertiesItem.product_parameter_prefix,
                    product_parameter_sufix: propertiesItem.product_parameter_sufix,
                    product_parameter_to_value: propertiesItem.product_parameter_to_value,
                    remark: propertiesItem.remark
                }
                const newFilter = {
                    type: propertiesItem.control_type,
                    dataArray: []    // new array for if there is combo 
                };
                if (propertiesItem.control_type === "P") {
                    resetGlobalQuery();
                    globalQuery.columns = ["field_name", "field_id"];
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: propertiesItem.control_master });
                    globalQuery.orderBy = ["property_id"];
                    globalQuery.table = "amv_properties";
                    masterList = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                    newFilter.dataArray = masterList;
                }
                if (propertiesItem.control_type === "C") {
                    masterList = await comboDataFunc.current.fillMasterData(propertiesItem.control_master, "", "")
                    newFilter.dataArray = masterList;
                }
                modifyProductPropertiesData.push({ ...getProductProperties[i], ...initializeKeys, newFilter })
            }
            setProductTypePropertiesData(modifyProductPropertiesData)
            return modifyProductPropertiesData;
        } else {
            setProductTypePropertiesData([])
        }
    }

    // ---------------------product base properties----------------------------------------------------------------------------------------------------------------------------------
    const openPackingDetailsModal = (dispatchchallanObj) => {
        loadPackingList(dispatchchallanObj);
    }

    const handleCloseYarnSummaryModal = () => {
        setViewPackingDetailsModal(false)
        setPackingSummaryList([]);

    };
    const loadPackingList = async (dispatchchallanObj) => {
        debugger
        setViewPackingDetailsModal(true);
        let filteredPackingDetails = [];
        let updatedDetailsData = [...PackingDetailsList];
        if (updatedDetailsData.length !== 0) {
            filteredPackingDetails = updatedDetailsData.filter(item => {
                return item.product_material_id === dispatchchallanObj.product_material_id
                    && parseInt(item.so_sr_no) === parseInt(dispatchchallanObj.so_sr_no)
                    && item.customer_order_no === dispatchchallanObj.customer_order_no;
            });
        }
        if (filteredPackingDetails.length !== 0) {
            // setPackingSummaryList(filteredPackingDetails)
            console.log('filteredPackingDetails Packing Yarns: ', filteredPackingDetails);

            if (dispatchchallanObj.dispatch_quantity > 0) {
                let dispatchQuantity = dispatchchallanObj.dispatch_quantity;
                updatedDetailsData = filteredPackingDetails.map((item) => {
                    const newItem = {
                        ...item,
                        prev_dispatch_quantity: isNaN(!item.item_dispatch_quantity) ? item.item_dispatch_quantity : 0,
                        // item_dispatch_quantity: validateNumberDateInput.current.decimalNumber((item.packing_quantity - item.item_dispatch_quantity).toString(), 4),
                        item_dispatch_quantity: validateNumberDateInput.current.decimalNumber((item.item_dispatch_quantity).toString(), 4),

                        item_pending_quantity: 0,
                        // dispatch_schedule_details_transaction_id: item.dispatch_schedule_details_transaction_id,
                        customer_order_no: dispatchchallanObj.customer_order_no,
                        product_material_id: dispatchchallanObj.product_material_id,
                        so_sr_no: dispatchchallanObj.so_sr_no,
                        customer_order_no: dispatchchallanObj.customer_order_no,
                    };
                    if (parseFloat(dispatchQuantity) !== 0) {
                        if (parseFloat(dispatchQuantity) > parseFloat(newItem.item_dispatch_quantity)) {
                            dispatchQuantity -= newItem.item_dispatch_quantity;
                            newItem.item_pending_quantity = 0;
                        } else {
                            newItem.item_pending_quantity = newItem.item_dispatch_quantity - dispatchQuantity;
                            newItem.item_dispatch_quantity = validateNumberDateInput.current.decimalNumber((dispatchQuantity).toString(), 4);
                            dispatchQuantity = 0;
                        }
                        return newItem; // Ensure you return the updated item in the map function
                    } else {
                        newItem.item_dispatch_quantity = 0;
                        newItem.item_pending_quantity = 0;
                        return newItem;
                    }
                });
                setPackingSummaryList(updatedDetailsData)
                setTimeout(() => {
                    $('#packing_material_name_id').text(dispatchchallanObj.product_material_name);
                    $('#packing_material_name_id').attr('product_id', dispatchchallanObj.product_material_id);
                    $('#packing_material_name_id').attr('so_sr_no', dispatchchallanObj.so_sr_no);
                    $('#packing_material_name_id').attr('customer_order_no', dispatchchallanObj.customer_order_no);
                    $('#packing_dispatch_qty_id').text(dispatchchallanObj.dispatch_quantity);
                    updatedDetailsData.forEach(item => {
                        if (item.item_dispatch_quantity > 0) {
                            $("input:checkbox[name='selectPackingRecord'][value='" + item.yarn_packing_details_id + "']").prop("checked", true);
                        } else {
                            $("input:checkbox[name='selectPackingRecord'][value='" + item.yarn_packing_details_id + "']").prop("checked", false);
                        }
                    })
                    $('#selectAllPackingRecords').prop('checked', $('input:checkbox.selectPackingRecord:checked').length === $('input:checkbox.selectPackingRecord').length);

                }, 100);


                // setTimeout(() => {
                //     $('#packing_material_name_id').text(dispatchchallanObj.product_material_name);
                //     $('#packing_dispatch_qty_id').text(dispatchchallanObj.dispatch_quantity);
                //     updatedDetailsData.forEach(item => {
                //         if (item.item_dispatch_quantity > 0) {
                //             $("input:checkbox[name='selectPackingRecord'][value='" + item.yarn_packing_details_id + "']").prop("checked", true);
                //         } else {
                //             $("input:checkbox[name='selectPackingRecord'][value='" + item.yarn_packing_details_id + "']").prop("checked", false);
                //         }
                //     })
                //     $('#selectAllPackingRecords').prop('checked', $('input:checkbox.selectPackingRecord:checked').length === $('input:checkbox.selectPackingRecord').length);

                // }, 100);
            }

        }

        // else {
        //     resetGlobalQuery();
        //     globalQuery.columns = ['*'];
        //     globalQuery.table = "xtv_spinning_yarn_packing_details";
        //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        //     // globalQuery.conditions.push({ field: "production_count_id", operator: "=", value: dispatchchallanObj.so_sr_no }); // Before count will be stored in sosrno.
        //     // globalQuery.conditions.push({ field: "production_count_id", operator: "=", value: dispatchchallanObj.production_count_id });
        //     globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: dispatchchallanObj.product_material_id });
        //     globalQuery.conditions.push({ field: "packing_details_status", operator: "IN", values: ["W", "C", "P"] });
        //     globalQuery.orderBy = ["yarn_packing_date"];
        //     const getPackingSummaryList = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

        //     if (getPackingSummaryList.length !== 0) {

        //         if (dispatchchallanObj.expected_dispatch_quantity > 0) {
        //             let dispatchQuantity = dispatchchallanObj.expected_dispatch_quantity;
        //             updatedDetailsData = getPackingSummaryList.map((item) => {
        //                 const newItem = {
        //                     ...item,
        //                     prev_dispatch_quantity: isNaN(!item.item_dispatch_quantity) ? item.item_dispatch_quantity : 0,
        //                     item_dispatch_quantity: validateNumberDateInput.current.decimalNumber((item.packing_quantity - item.item_dispatch_quantity).toString(), 4),
        //                     item_pending_quantity: 0,
        //                     dispatch_schedule_details_transaction_id: item.dispatch_schedule_details_transaction_id,
        //                     customer_order_no: dispatchchallanObj.customer_order_no,
        //                     product_material_id: dispatchchallanObj.product_material_id,
        //                     so_sr_no: dispatchchallanObj.so_sr_no,
        //                     customer_order_no: dispatchchallanObj.customer_order_no,
        //                 };
        //                 if (parseFloat(dispatchQuantity) !== 0) {
        //                     if (parseFloat(dispatchQuantity) > parseFloat(newItem.item_dispatch_quantity)) {
        //                         dispatchQuantity -= newItem.item_dispatch_quantity;
        //                         newItem.item_pending_quantity = 0;
        //                     } else {
        //                         newItem.item_pending_quantity = newItem.item_dispatch_quantity - dispatchQuantity;
        //                         newItem.item_dispatch_quantity = validateNumberDateInput.current.decimalNumber((dispatchQuantity).toString(), 4);
        //                         dispatchQuantity = 0;
        //                     }
        //                     return newItem; // Ensure you return the updated item in the map function
        //                 } else {
        //                     newItem.item_dispatch_quantity = 0;
        //                     newItem.item_pending_quantity = 0;
        //                     return newItem;
        //                 }
        //             });
        //             setPackingSummaryList(updatedDetailsData)

        //             setTimeout(() => {
        //                 $('#packing_material_name_id').text(dispatchchallanObj.product_material_name);
        //                 $('#packing_dispatch_qty_id').text(dispatchchallanObj.dispatch_quantity);
        //                 updatedDetailsData.forEach(item => {
        //                     if (item.item_dispatch_quantity > 0) {
        //                         $("input:checkbox[name='selectPackingRecord'][value='" + item.yarn_packing_details_id + "']").prop("checked", true);
        //                     } else {
        //                         $("input:checkbox[name='selectPackingRecord'][value='" + item.yarn_packing_details_id + "']").prop("checked", false);
        //                     }
        //                 })
        //                 $('#selectAllPackingRecords').prop('checked', $('input:checkbox.selectPackingRecord:checked').length === $('input:checkbox.selectPackingRecord').length);

        //             }, 100);
        //         }
        //     } else {
        //         setPackingSummaryList([])
        //     }
        // }

    }

    //Fn to render Inspection  Summary table
    const renderPackingSummaryTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='packing-summary-table-id' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'></th>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Lot No</th>
                        <th className="erp_table_th">Count</th>
                        <th className="erp_table_th">Actual Count</th>
                        <th className="erp_table_th">Packing Types</th>
                        <th className="erp_table_th">Packing Quantity</th>
                        {/* <th className="erp_table_th">Prev. Dispatch Quantity</th> */}
                        <th className="erp_table_th">Dispatch Quantity</th>
                        <th className="erp_table_th">Dispatch Weight</th>
                        <th className="erp_table_th">Starting No</th>
                        <th className="erp_table_th">Ending No</th>
                        <th className="erp_table_th">Gross Weight</th>
                        <th className="erp_table_th">Net Weight</th>
                        <th className="erp_table_th">Cone Quantity</th>
                        <th className="erp_table_th">Cone Weight</th>
                        <th className="erp_table_th">Per Packing Weight</th>
                        <th className="erp_table_th">Yarn Packing Date</th>
                        <th className="erp_table_th">Unit</th>
                    </tr>
                </thead>
                <tbody>
                    {packingSummaryList.map((packingItem, index) => (
                        <tr rowIndex={index}>
                            <td>
                                <div style={{ display: "flex" }}>
                                    <input type='checkbox' class="selectPackingRecord" name="selectPackingRecord" id={'selectPackingRecord_' + packingItem.yarn_packing_details_id}
                                        value={packingItem.yarn_packing_details_id} onClick={(e) => FnCheckBoxesSelection('PartiallySelectPackingRecord')}>
                                    </input>
                                </div>
                            </td>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{packingItem.lot_no}</td>
                            <td className="erp_table_td ">{packingItem.production_count_name}</td>
                            <td className="erp_table_td ">{packingItem.production_actual_count}</td>
                            <td className="erp_table_td text-end">{packingItem.yarn_packing_types_name}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_quantity}</td>
                            {/* <td className="erp_table_td text-end">{packingItem.prev_dispatch_quantity}</td> */}
                            <td className="erp_table_td ">
                                <input type="text" className="erp_input_field mb-0" id={`item_dispatch_quantity_${index}`} value={packingItem.item_dispatch_quantity}
                                    Headers='item_dispatch_quantity' onChange={(e) => { updatePackingSummaryTblData(packingItem, e); }} onBlur={(e) => { updatePackingSummaryTblData(packingItem, e); }} disabled />
                            </td>
                            <td className="erp_table_td text-end">{packingItem.item_dispatch_weight}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_starting_no}</td>
                            <td className="erp_table_td text-end">{packingItem.packing_ending_no}</td>
                            <td className="erp_table_td ">{packingItem.packing_gross_weight}</td>
                            <td className="erp_table_td ">{packingItem.packing_net_weight}</td>
                            <td className="erp_table_td ">{packingItem.cone_quantity}</td>
                            <td className="erp_table_td ">{packingItem.cone_weight}</td>
                            <td className="erp_table_td ">{packingItem.per_packing_weight}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(packingItem.yarn_packing_date)}</td>
                            <td className="erp_table_td ">{packingItem.product_unit_name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [packingSummaryList])

    const updatePackingSummaryTblData = (currentRowData, event) => {
        debugger
        let clickedColName = event.target.getAttribute('Headers');
        const inputValue = event.target.value;
        // Remove error msgs on input change.
        if (event._reactName === 'onChange') { delete event.target.parentElement.dataset.tip; }

        switch (clickedColName) {
            case 'item_dispatch_quantity':
                if (inputValue !== "") {
                    // const quantityForDispatch = currentRowData.packing_quantity - currentRowData.prev_dispatch_quantity;
                    const quantityForDispatch = currentRowData.packing_quantity;
                    if (inputValue <= quantityForDispatch) {
                        currentRowData.item_dispatch_quantity = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4);
                        delete event.target.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.dataset.tip = 'Dispatch quantity should not greater than Packing quantity...!';
                    }
                } else {
                    currentRowData.item_dispatch_quantity = "";
                    event.target.parentElement.dataset.tip = 'Please fill this field...!';
                }
                break;
            default:
                break;
        }
        const updatedDetailsData = [...packingSummaryList]
        const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedDetailsData[itemArrayIndex] = currentRowData;
        setPackingSummaryList(updatedDetailsData);
    }


    const validatePackingDispatch = () => {
        let result = true;
        let addedQty = 0;
        $('#packing-summary-table-id tbody tr').each(function () {
            let currentTblRow = $(this);
            let isChecked = currentTblRow.find("input:checkbox[name='selectPackingRecord']:checked").length > 0;
            if (isChecked) {
                let dispatch_qty = parseFloat(currentTblRow.find('input[id^="item_dispatch_quantity_"]').val());
                addedQty += dispatch_qty;
                if (isNaN(dispatch_qty)) {
                    $(currentTblRow.find('input[id^="item_dispatch_quantity_"]'))[0].parentElement.dataset.tip = 'Dispatch Quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="item_dispatch_quantity_"]'))[0].focus();
                    return result = false;
                }
            }
            result = true;
        });
        if (addedQty > $('#packing_dispatch_qty_id').text()) {
            setErrMsg('Dispatch quantity not more than Total Quantity ' + parseFloat($('#packing_dispatch_qty_id').text()) + ' ...!')
            setShowErrorMsgModal(true)
            return false;
        } else if (addedQty < $('#packing_dispatch_qty_id').text()) {
            setErrMsg('Dispatch quantity not less than Total Quantity ' + parseFloat($('#packing_dispatch_qty_id').text()) + ' ...!')
            setShowErrorMsgModal(true)
            return false;
        }
        return result;
    }


    // --------------------------------------------------------------------------------------------------------
    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={comboDataFunc} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />

            <DashboardLayout >
                <div className="spinner-overlay" id='spinner_id' style={{ display: "none" }}>
                    <div className="spinner-container">
                        <CircularProgress color="primary" className="text-primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div>

                <div className='card mt-4'>
                    <form id="DispatchChallanHeaderFormId">

                        <div className='main_heding'>
                            <label className='erp-form-label-lg'>Dispatch Challan Yarn {actionType}</label>
                        </div>

                        <div className="card-body px-1 py-lg-0">
                            <div className="row">
                                {/* First Column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label"> Challan Creation Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="row erp_form_radio">
                                                <div className="col-12 col-md-3">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_dispatch_challan_creation_type" checked={rb_dispatch_challan_creation_type === "M"} onClick={(e) => { FnComboOnChange('ChallanCreationType'); setDispatchChallanCreationType('M'); validateFields(); }} /> </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Sales order Based" type="radio" lbl="Sales order Based" value="O" name="rb_dispatch_challan_creation_type" checked={rb_dispatch_challan_creation_type === "O"} onClick={(e) => { FnComboOnChange('ChallanCreationType'); setDispatchChallanCreationType('O'); validateFields(); }} /> </div>
                                                </div>
                                                <div className="col-12 col-md-5">
                                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Dispatch Schedule Based" type="radio" lbl="Dispatch Schedule Based" value="D" name="rb_dispatch_challan_creation_type" checked={rb_dispatch_challan_creation_type === "D"} onClick={(e) => { FnComboOnChange('ChallanCreationType'); setDispatchChallanCreationType('D'); validateFields(); }} /> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label"> Challan Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_dispatch_challan_type_id" className="form-select form-select-sm" value={cmb_dispatch_challan_type_id} onChange={(e) => { FnComboOnChange('DispatchChallanType'); validateFields(); }} disabled >
                                                <option value="" selected disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {dispatchChallanTypeOpts?.map(dispatchChallanType => (
                                                    <option value={dispatchChallanType.field_id} lbl={dispatchChallanType.field_name} shortname={dispatchChallanType.product_type_short_name} >{dispatchChallanType.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_dispatch_challan_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Challan No., Ver. & Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_dispatch_challan_no" className="erp_input_field" value={txt_dispatch_challan_no} disabled />
                                                </div>
                                                <div className="col-12 col-md-2 pe-md-0 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id='txt_dispatch_challan_version' className="erp_input_field text-end" value={txt_dispatch_challan_version} disabled />
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="date" id='dt_dispatch_challan_date' className="erp_input_field" value={dt_dispatch_challan_date} onChange={(e) => { setDispatchChallanDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_dispatch_challan_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label"> Challan Status <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_dispatch_challan_status" className="form-select form-select-sm" value={cmb_dispatch_challan_status} disabled>
                                                <option value="P">Pending</option>
                                                <option value="A">Aprroved</option>
                                                <option value="R">Rejected</option>
                                                <option value="U">Partial Sales Issue</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                                <option value="D">Dispath Challan Created</option>
                                                <option value="I">Invoice Created</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_dispatch_challan_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Expected Branch <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { FnComboOnChange('CompanyBranch'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {companyBranchOpts?.map(companyBranch => (
                                                    <option value={companyBranch.company_branch_id} lbl={companyBranch.company_branch_name}>{companyBranch.company_branch_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateCompanyBranches'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Branch State & City <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} disabled>
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(ConsigneeState => (
                                                            <option value={ConsigneeState.field_id} lbl={ConsigneeState.field_name}>{ConsigneeState.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} optional='optional' disabled>
                                                        <option value="">Select</option>
                                                        {expectedBranchCityOpts?.map(ExpectedBranchCity => (
                                                            <option value={ExpectedBranchCity.field_id} lbl={ExpectedBranchCity.field_name}>{ExpectedBranchCity.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { FnComboOnChange('Customer'); validateFields(); }}>
                                                <option value="" disabled selected>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerOpts?.map(customer => (
                                                    <option value={customer.field_id} lbl={customer.field_name} is_sez={`${customer.is_sez}`}>{customer.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateCustomers'); }} />
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Customer State & City <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} disabled>
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(CustomerState => (
                                                            <option value={CustomerState.field_id} stateCode={CustomerState.state_code} lbl={CustomerState.field_name}>{CustomerState.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} optional='optional' disabled>
                                                        <option value="">Select</option>
                                                        {customerCityOpts?.map(CustomerCity => (
                                                            <option value={CustomerCity.field_id} lbl={CustomerCity.field_name}>{CustomerCity.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Customer GST & PAN No. </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_customer_gst_no" className="erp_input_field" value={txt_customer_gst_no} onChange={e => { setCustomerGSTNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='15' />
                                                    <MDTypography variant="button" id="error_txt_customer_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id="txt_customer_pan_no" className="erp_input_field" value={txt_customer_pan_no} onChange={e => { setCustomerPANNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='10' />
                                                    <MDTypography variant="button" id="error_txt_customer_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Expec. Sch. Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='dt_overall_schedule_date' className="erp_input_field" value={dt_overall_schedule_date} onChange={(e) => { setOverAllScheduleDate(e.target.value); validateFields(); }} />
                                            <MDTypography variant="button" id="error_dt_overall_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Customer GST No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_customer_gst_no" className="erp_input_field" value={txt_customer_gst_no} onChange={e => { setCustomerGSTNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='100' />
                                            <MDTypography variant="button" id="error_txt_customer_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Customer PAN No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_customer_pan_no" className="erp_input_field" value={txt_customer_pan_no} onChange={e => { setCustomerPANNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='10' />
                                            <MDTypography variant="button" id="error_txt_customer_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className="row">

                                        {/* <Accordion defaultActiveKey="0" className="mt-3 my-md-1">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    {
                                                        custContactDetails.length > 0
                                                            ? <>
                                                                <div className='row'>
                                                                    <div className="col-12 erp_table_scroll">
                                                                        <DatatableWithId data={custContactDetails} columns={custContactDetailsCols} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ""
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion > */}
                                        <Accordion defaultActiveKey="0" >
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    {
                                                        cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                            ? <>
                                                                <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                                </div>
                                                            </>
                                                            : null
                                                    }

                                                    {
                                                        custContactDetails.length > 0
                                                            ? <>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <Table className="erp_table erp_table_scroll" id='customerContactPersonsTable' responsive bordered striped>
                                                                            <thead className="erp_table_head">
                                                                                <tr>
                                                                                    <th className={`erp_table_th`}>
                                                                                        <div className="col-1">
                                                                                            <input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { FnCheckBoxesSelection('selectAllCustContact'); }} />
                                                                                            &nbsp; Select All
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className='erp_table_th'>Customer Branch</th>
                                                                                    <th className='erp_table_th'>Contact Person</th>
                                                                                    <th className='erp_table_th'>Contact No</th>
                                                                                    <th className={`erp_table_th `}>Email</th>
                                                                                    <th className={`erp_table_th`}>Alternate Email</th>
                                                                                    <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    custContactDetails.map((item, index) =>
                                                                                        <tr rowindex={index}>
                                                                                            <td className='erp_table_td'>
                                                                                                {/* <input type='checkbox' class="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + row.original.customer_contact_id} contact_personId={row.original.customer_contact_id} value={row.original.customer_contact_id} /> */}
                                                                                                <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson"
                                                                                                    id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id}
                                                                                                    value={item.customer_contact_id} onClick={() => FnCheckBoxesSelection('PartiallyCustContactSelection')} />
                                                                                            </td>
                                                                                            <td className="erp_table_td">{item.customer_branch_name}</td>
                                                                                            <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                            <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                            <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                            <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                            <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ""
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                        <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>

                                </div>
                                {/* first column ending */}

                                {/* second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Consignee </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={(e) => { FnComboOnChange('Consignee'); validateFields(); }} optional='optional'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {consigneeOpts?.map(consignee => (
                                                    <option value={consignee.field_id} lbl={consignee.field_name} is_sez={`${consignee.is_sez}`}>{consignee.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateConsignees'); }} />
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={(e) => { setConsigneeStateId(e.target.value); FnComboOnChange('ConsigneeState'); validateFields(); }} optional='optional'>
                                                        <option value="">Select</option>
                                                        {stateOpts?.map(ConsigneeState => (
                                                            <option value={ConsigneeState.field_id} lbl={ConsigneeState.field_name}>{ConsigneeState.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={(e) => { setConsigneeCityId(e.target.value); FnComboOnChange('ConsigneeCity'); validateFields(); }} optional='optional'>
                                                        <option value="">Select</option>
                                                        {consigneeCityOpts?.map(ConsigneeCity => (
                                                            <option value={ConsigneeCity.field_id} lbl={ConsigneeCity.field_name}>{ConsigneeCity.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Consignee GST & PAN No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_consignee_gst_no" className="erp_input_field" value={txt_consignee_gst_no} onChange={e => { setConsigneeGSTNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='15' />
                                                    <MDTypography variant="button" id="error_txt_consignee_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id="txt_consignee_pan_no" className="erp_input_field" value={txt_consignee_pan_no} onChange={e => { setConsigneePANNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='10' />
                                                    <MDTypography variant="button" id="error_txt_consignee_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Consignee GST No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_consignee_gst_no" className="erp_input_field" value={txt_consignee_gst_no} onChange={e => { setConsigneeGSTNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='100' />
                                            <MDTypography variant="button" id="error_txt_consignee_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Consignee PAN No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_consignee_pan_no" className="erp_input_field" value={txt_consignee_pan_no} onChange={e => { setConsigneePANNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='10' />
                                            <MDTypography variant="button" id="error_txt_consignee_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div> */}

                                    {
                                        ["O", "D"].includes(rb_dispatch_challan_creation_type)
                                            ? <>
                                                <div className='row'>
                                                    <div className="col-sm-3">
                                                        <Form.Label className="erp-form-label">Customer Ord. No.<span className="required">*</span></Form.Label>
                                                    </div>

                                                    {/* <div className='col'>
                                                        <div className="row">
                                                            <div className={`${rb_dispatch_challan_creation_type === 'D' ? 'col-sm-9' : 'col-sm-6 pe-md-0'}`}>
                                                                <div className="select-btn" onClick={() => FnToggleMultiSelectCombos('customerOrderNos')} optional="optional">
                                                                    <span className="form-select form-select-sm">{customerOrderNos !== 0 ? customerOrderNos + ' Customer Order Selected' : 'Select'}</span>
                                                                </div>
                                                                <ul className="list-items" id="customer-order-ul">
                                                                    {customerOrderNoList}
                                                                </ul>
                                                            </div>
                                                            {
                                                                rb_dispatch_challan_creation_type === 'O'
                                                                    ? <div className="col-sm-3 col-12 pt-md-0 pt-3">
                                                                        <MDButton type="button" className="erp-gb-button float-end" variant="button" fontWeight="regular" onClick={(e) => { FnShowMaterialsFromCustomerOrders(); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false}> Show Materials </MDButton>
                                                                    </div >
                                                                    : null
                                                            }
                                                        </div>
                                                        <MDTypography variant="button" id="" className="erp_validation" fontWeight="regular" color="error"> sdfas </MDTypography>

                                                    </div> */}
                                                    <div className="col-sm-9 col-12">
                                                        <div className="row">
                                                            <div className={`${rb_dispatch_challan_creation_type === 'D' ? 'col-sm-12' : 'col-sm-6 col-lg-9 pe-md-0'}`}>
                                                                <div className="select-btn" onClick={() => FnToggleMultiSelectCombos('customerOrderNos')} optional="optional">
                                                                    <span className="form-select form-select-sm">{customerOrderNos !== 0 ? customerOrderNos + ' Customer Order Selected' : 'Select'}</span>
                                                                </div>
                                                                <ul className="list-items" id="customer-order-ul">
                                                                    {customerOrderNoList}
                                                                </ul>
                                                                <MDTypography variant="button" id="error_customer_orders_list" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-3 pt-md-0 pt-3 ps-md-1">
                                                                {
                                                                    rb_dispatch_challan_creation_type === 'O'
                                                                        ? <><MDButton type="button" className="erp-gb-button float-end" variant="button" fontWeight="regular" onClick={(e) => { FnShowMaterialsFromCustomerOrders(); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false}> Show Materials </MDButton></>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }

                                    {
                                        rb_dispatch_challan_creation_type === 'D'
                                            ? <>
                                                <div className='row'>
                                                    <div className="col-sm-3">
                                                        <Form.Label className="erp-form-label">Dispatch Schedules<span className="required">*</span></Form.Label>
                                                    </div>

                                                    {/* <div className={`col-sm-6 pe-md-0`}>
                                                        <div className="select-btn" onClick={() => FnToggleMultiSelectCombos('dispatchScheduleNos')} optional="optional">
                                                            <span className="form-select form-select-sm">{selectedDispatchSchedulesNos !== 0 ? selectedDispatchSchedulesNos + ' Dispatch Shedules Selected' : 'Select'}</span>
                                                        </div>
                                                        <ul className="list-items" id="dispatch-schedule-ul">
                                                            {dispatchSchedulesList}
                                                        </ul>
                                                    </div>
                                                    <div className="col-sm-3 col-12 pt-md-0 pt-3">
                                                        <MDButton type="button" className="erp-gb-button float-end" variant="button" fontWeight="regular" onClick={(e) => { FnShowMaterialsFromDispatchSchedules(); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false}> Show Materials </MDButton>
                                                    </div > */}

                                                    <div className="col-sm-9 col-12">
                                                        <div className="row">
                                                            <div className={`col-sm-9 pe-md-0`}>
                                                                <div className="select-btn" onClick={() => FnToggleMultiSelectCombos('dispatchScheduleNos')} optional="optional">
                                                                    <span className="form-select form-select-sm">{selectedDispatchSchedulesNos !== 0 ? selectedDispatchSchedulesNos + ' Dispatch Shedules Selected' : 'Select'}</span>
                                                                </div>
                                                                <ul className="list-items" id="dispatch-schedule-ul">
                                                                    {dispatchSchedulesList}
                                                                </ul>
                                                                <MDTypography variant="button" id="error_dispatch_notes_list" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-3 pt-md-0 pt-3 ps-md-1">
                                                                <MDButton type="button" className="erp-gb-button float-end" variant="button" fontWeight="regular" onClick={(e) => { FnShowMaterialsFromDispatchSchedules(); }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false}> Show Materials </MDButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Status Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_dispatch_challan_remark" className="erp_txt_area" value={txt_dispatch_challan_remark} onInput={e => { setDispatchChallanRemark(e.target.value); }} maxlength="1000" optional="optional" placeholder="any remark for dispatch supervisior..." />
                                            {/* {...(keyForViewUpdate === 'approve' ? {} : { optional: 'optional', disabled: true })} /> */}
                                            <MDTypography variant="button" id="error_txt_dispatch_challan_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Other Terms Conditions</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_other_terms_conditions" className="erp_txt_area" value={txt_other_terms_conditions} onInput={e => { setOtherTermsConditions(e.target.value); }} maxlength="1000" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_remark" className="erp_txt_area" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Challan IsActive</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="rb_is_active" checked={rb_is_active === "true"} onClick={() => setIsActive("true")} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="rb_is_active" checked={rb_is_active === "false"} onClick={() => setIsActive("false")} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Is Service</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="1" name="rb_is_service" checked={rb_is_service === "1"} onClick={() => setIsService("1")} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="0" name="rb_is_service" checked={rb_is_service === "0"} onClick={() => setIsService("0")} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Is Sez</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="1" name="rb_is_sez" checked={rb_is_sez === "1"} disabled /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="0" name="rb_is_sez" checked={rb_is_sez === "0"} disabled /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Interim</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="true" name="rb_interim" checked={rb_interim === "1"} onClick={() => setInterim("1")} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="false" name="rb_interim" checked={rb_interim === "0"} onClick={() => setInterim("0")} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* second column ending */}
                            </div>
                            {/* header section row ending */}
                        </div>
                    </form>
                </div>
                {/* header card ending */}

                <hr />
                <Accordion defaultActiveKey={'0'}>
                    <Accordion.Item eventKey={'0'}>
                        <Accordion.Header className="erp-form-label-md">Dispatch-Challan Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-12">
                                    {/* <div className="row">
                                        <div className="col-md-3 col-12">
                                            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={FnOpenFilter} disabled={keyForViewUpdate === 'view' || ["O", "D"].includes(rb_dispatch_challan_creation_type) ? 'disabled' : ''}>Add Materials</MDButton>
                                        </div>
                                        <div className="col-md-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-sm-9 pt-1">
                                                    <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field ${dispatchChallanMaterials.length <= 0 ? 'd-none' : ""}`} />
                                                </div>
                                                <div className="col-12 col-sm-3 pt-2 pt-md-0">
                                                    <MDButton type="button" className={`erp-gb-button ${dispatchChallanMaterials.length <= 0 ? 'd-none' : ""}`} variant="button" onClick={() => scrollToTableRow()} fontWeight="regular">Search</MDButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {
                                        keyForViewUpdate === 'view' || ["O", "D"].includes(rb_dispatch_challan_creation_type)
                                            ? null
                                            : <>
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                        <SearchItemFilter getSearchData={FnCloseBomFilter} formIdForValidate="DispatchChallanHeaderFormId" requiredCols={requiredColsForFilter} />
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={FnOpenFilter} >Add Materials</MDButton>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>

                            </div>
                            <div className="row py-1 py-md-3">
                                {
                                    dispatchChallanMaterials.length !== 0
                                        ? <>
                                            {FnRenderDetailsTable}
                                        </>
                                        : <>
                                            <div className='row text-center'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...!
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                            {/* {inspectionDetailsList.length !== 0
                                ? <>
                                    <hr />
                                    <Accordion defaultActiveKey="0" >
                                        <Accordion.Item eventKey={`0`}>
                                            <Accordion.Header className="erp-form-label-md">Inspection Details</Accordion.Header>
                                            <Accordion.Body className="">
                                                <div className="row">
                                                    <div className={`table-responsive ${inspectionDetailsList.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                        <>{renderInspectionDetailsTbl}</>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                                : null
                            } */}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >

                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey={`0`}>
                        <Accordion.Header className="erp-form-label-md">Yarn Packing Details</Accordion.Header>
                        <Accordion.Body className="">
                            <div className="row">
                                {PackingDetailsList.length !== 0 ?
                                    <>
                                        <div className={`table-responsive ${PackingDetailsList.length > 0 ? 'erp_table_scroll' : ''}`}>
                                            <>{renderPackingDetailsTbl}</>
                                        </div>
                                    </> :
                                    <>
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...!
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey={`0`}>
                        <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
                        <Accordion.Body className="">
                            {/* <div className="row">
                                {productTypePropertiesData.length !== 0 ? (<>
                                     {productTypePropertiesData.length !== 0
                                        ? (
                                            <>
                                                <div className="table-responsive erp_table_scroll">
                                                    {renderProductPropertiesTbl}
                                                </div>
                                            </>
                                        ) : (
                                            <div className='row text-center'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...!
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                </>) : null}
                            </div> */}
                            {
                                productTypePropertiesData.length !== 0
                                    ? <>
                                        <div className="row">
                                            <div className={`table-responsive ${productTypePropertiesData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                <>{renderProductPropertiesTbl}</>
                                            </div>
                                        </div>
                                    </>
                                    : <>
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...!
                                                </span>
                                            </div>
                                        </div>
                                    </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                {
                    keyForViewUpdate === 'view'
                        ? <>
                            <hr />
                            <Accordion defaultActiveKey="0" >
                                <Accordion.Item eventKey={`0`}>
                                    <Accordion.Header className="erp-form-label-md">Batch-Wise Stock Release</Accordion.Header>
                                    <Accordion.Body className="">
                                        <div className="row">
                                            <div className={`table-responsive ${batchWiseStockTblData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                <>{FnRenderBatchWiseStockReleaseTable}</>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                        : null
                }

                <hr />
                <Accordion defaultActiveKey="1" >
                    <Accordion.Item eventKey={`0`}>
                        <Accordion.Header className="erp-form-label-md">Taxation Summary</Accordion.Header>
                        <Accordion.Body className="">
                            <div className="row">
                                <div className={`table-responsive ${taxationSummaryData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                    <>{FnRenderTaxationSummaryTable}</>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >
                <hr />

                {keyForViewUpdate !== '' ?
                    <>
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md">Document List</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`table-responsive ${uploadedDocData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                        {uploadedDocData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> <hr /></> : null
                }

                {/* Card for Footer Dispatch challan fields. */}
                <div className='card mt-1'>
                    <form id="DispatchChallanFooterFormId">

                        <div className="card-header py-0 text-start">
                            <label className='erp-form-label-lg main_heding text-start'>Dispatch-Challan Billing</label>
                        </div>

                        {/* Footer Section Row */}
                        <div className="card-body px-1">
                            <div className="row">
                                {/* First Column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Basic Total <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_basic_total' className="erp_input_field text-end" value={txt_basic_total} maxLength='19' disabled />
                                            <MDTypography variant="button" id="error_txt_basic_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Trans. Amount </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_transport_amount' className="erp_input_field text-end" value={txt_transport_amount} maxLength='19' onChange={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnCalculateGrandTotal(dispatchChallanMaterials, taxationSummaryData); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_transport_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Freight Amount {chk_freight_isTaxable ? <span className="required">*</span> : null} </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-3">
                                                    <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={async (e) => { setFreightIsTaxable(e.target.checked); validateFields(); }} />
                                                </div>
                                                <div className="col-12 col-md-3 pt-md-0 pt-2 pe-md-0 me-md-0">
                                                    <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength='19'
                                                        onChange={async (e) => {
                                                            setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
                                                            FnSplitFooterAmtsInDetails(dispatchChallanMaterials);
                                                            // FnCalculateTaxationSummary(dispatchChallanMaterials); 
                                                            validateFields();
                                                        }}
                                                        onBlur={async (e) => { setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4) === '' ? 0 : validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnCalculateTaxationSummary(dispatchChallanMaterials); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                    <select id="cmb_freight_hsn_code_id" className="form-select form-select-sm" value={cmb_freight_hsn_code_id}
                                                        onChange={async (e) => { setFreightHSNCodeId(e.target.value); validateFields(); FnCalculateTaxationSummary(dispatchChallanMaterials); }} disabled={chk_freight_isTaxable ? false : true} {...(chk_freight_isTaxable ? {} : { optional: 'optional' })}>
                                                        <option value="" selected disabled>Select</option>
                                                        {HSNCodeOpts?.map(hsnCode => (
                                                            <option value={hsnCode.hsn_sac_id} rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_freight_hsn_code_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Packing Amount </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_packing_amount' className="erp_input_field text-end" value={txt_packing_amount} maxLength='19' onChange={(e) => { setPackingAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnCalculateGrandTotal(dispatchChallanMaterials, taxationSummaryData); validateFields(); }} optional="optional" />
                                            <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Discount % & Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_discount_percent" className="erp_input_field text-end" value={txt_discount_percent} onChange={e => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setDiscountPercent(e.target.value); FnFooterDiscountPercentChange(e); FnCalculateGrandTotal(dispatchChallanMaterials, taxationSummaryData); validateFields(); } }} optional='optional' maxlength='19' />
                                                    <MDTypography variant="button" id="error_txt_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                    <Form.Control type="text" id="txt_discount_amount" className="erp_input_field text-end" value={txt_discount_amount} onChange={e => { setDiscountAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnFooterDiscountAmountChange(e); FnCalculateGrandTotal(dispatchChallanMaterials, taxationSummaryData); validateFields(); }} optional='optional' maxlength='19' />
                                                    <MDTypography variant="button" id="error_txt_discount_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Other Amount </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_other_amount' className="erp_input_field text-end" value={txt_other_amount} maxLength='19' onChange={(e) => { setOtherAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnCalculateGrandTotal(dispatchChallanMaterials, taxationSummaryData); validateFields(); }} optional="optional" />
                                            <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Taxable Total <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_taxable_total' className="erp_input_field text-end" value={txt_taxable_total} maxLength='19' disabled />
                                            <MDTypography variant="button" id="error_txt_taxable_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> CGST Total <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_cgst_total' className="erp_input_field text-end" value={txt_cgst_total} disabled maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_cgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> SGST Total <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_sgst_total' className="erp_input_field text-end" value={txt_sgst_total} disabled maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_sgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> IGST Total <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_igst_total' className="erp_input_field text-end" value={txt_igst_total} disabled maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_igst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Grand Total <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_grand_total' className="erp_input_field text-end" value={txt_grand_total} disabled maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_grand_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Round Off <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_roundoff' className="erp_input_field text-end" value={txt_roundoff} disabled maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_roundoff" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                </div>
                                {/* first column ending */}

                                {/* second column */}
                                <div className="col-sm-6 erp_form_col_div">


                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} disabled>
                                                        <option value="" disabled>Select</option>
                                                        {approvedByOpts?.map(employee => (
                                                            <option value={employee.field_id}>{employee.field_name}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApprovedDate(e.target.value); validateFields(); }} disabled />
                                                    <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Agent</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); FnComboOnChange('Agent') }} optional="optional" >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {agentOpts?.map(agent => (
                                                    <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateAgents'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Agent's Disc. % & Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id='txt_agent_percent' className="erp_input_field text-end" value={txt_agent_percent}
                                                        onChange={e => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setAgentPercent(e.target.value); validateFields(); } }} maxLength='5' optional="optional" />
                                                    <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1 ">
                                                    <Form.Control type="text" id='txt_agent_amount' className="erp_input_field text-end" value={txt_agent_amount} onChange={(e) => { setAgentAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} maxLength='19' optional="optional" />
                                                    <MDTypography variant="button" id="error_txt_agent_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Agent's Paid Status</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); }} optional="optional"  >
                                                <option value="">Select</option>
                                                <option value="P" lbl="Pending">Pending</option>
                                                <option value="A" lbl="Approved">Approved</option>
                                                <option value="C" lbl="Completed">Completed</option>
                                                <option value="X" lbl="Canceled">Canceled</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_paid_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Transporter </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_transporter_id" className="form-select form-select-sm" value={cmb_transporter_id} onChange={(e) => { FnComboOnChange('Transporter'); validateFields(); }} optional='optional'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {transporterOpts?.map(transporter => (
                                                    <option value={transporter.field_id} lbl={transporter.field_name}>{transporter.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_transporter_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2 refreshIconCol'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateTransporters'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Transporter State</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_transporter_state_id" className="form-select form-select-sm" value={cmb_transporter_state_id} optional='optional' disabled>
                                                <option value="">Select</option>
                                                {stateOpts?.map(TransporterState => (
                                                    <option value={TransporterState.field_id} lbl={TransporterState.field_name}>{TransporterState.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_transporter_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Trans. GST No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_transporter_gst_no" className="erp_input_field" value={txt_transporter_gst_no} onChange={e => { setTransporterGSTNo(e.target.value.split(' ').join('').toUpperCase()); validateFields(); }} optional='optional' maxlength='100' />
                                            <MDTypography variant="button" id="error_txt_transporter_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Trans. Challan No. & Date</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_transporter_challan_no" className="erp_input_field" value={txt_transporter_challan_no} onChange={e => { setTransporterChallanNo(e.target.value.trim()); validateFields(); }} optional='optional' maxlength='100' />
                                                    <MDTypography variant="button" id="error_txt_transporter_challan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1 ">
                                                    <Form.Control type="date" id='dt_transporter_challan_date' className="erp_input_field" value={dt_transporter_challan_date} onChange={(e) => { setTransporterChallanDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_transporter_challan_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label"> Transport Mode </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_transport_mode" className="form-select form-select-sm" value={cmb_transport_mode} optional='optional' onChange={(e) => { setTransportMode(e.target.value.trim()); }}>
                                                <option value="" lbl='' disabled>Select</option>
                                                <option value="1" lbl='By Road'>By Road</option>
                                                <option value="2" lbl='By Rail'>By Rail</option>
                                                <option value="3" lbl='By Ship'>By Ship</option>
                                                <option value="4" lbl='By Air'>By Air</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_transport_mode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label"> Transaction Type </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_transaction_type" className="form-select form-select-sm" value={cmb_transaction_type} onChange={(e) => { setTransactionType(e.target.value.trim()); }} optional='optional'>
                                                <option value="" lbl='' disabled>Select</option>
                                                <option value="1" lbl='Regular'>Regular</option>
                                                <option value="2" lbl='Bill To - Ship To'>Bill To - Ship To</option>
                                                <option value="3" lbl='Bill From - Dispatch From'>Bill From - Dispatch From</option>
                                                <option value="4" lbl='Combination of 2 and 3'>Combination of 2 and 3</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_transaction_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Loading Time</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="datetime-local" id="dt_loading_time" className="erp_input_field" value={dt_loading_time} onChange={e => { setLoadingTime(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_dt_loading_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Vehicle Type & No.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_vehicle_type" className="erp_input_field" value={txt_vehicle_type} onChange={e => { setVehicleType(e.target.value.trim()); validateFields(); }} optional='optional' maxlength='1000' />
                                                    <MDTypography variant="button" id="error_txt_vehicle_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1 ">
                                                    <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={e => { setVehicleNo(e.target.value.trim()); validateFields(); }} optional='optional' maxlength='1000' />
                                                    <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* second column ending */}
                            </div>
                            {/* footer section row ending */}
                        </div>
                    </form>
                </div>
                {/* Card for Footer Dispatch challan fields ending. */}

                <div className="text-center">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => { moveToListing(); }} >Back</MDButton>
                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' && cmb_dispatch_challan_status === 'A' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel' ? 'display' : 'd-none'}`} id='uploadDocBtn' variant="button" fontWeight="regular" onClick={viewDocumentForm} disabled={dispatch_challan_master_transaction_id === 0}>Upload Document</MDButton>&nbsp;
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={() => generateDispatchChallanYarn()} fontWeight="regular">{actionLabel}</MDButton>

                    {
                        deletionKey === true
                            ? <>
                                <MDButton type="button" className={`erp-gb-button ms-2`} variant="button" fontWeight="regular"
                                    onClick={() => {
                                        setModalConfirmationAction('delete');
                                        setModalMsg("Do you wish to delete this record ?");
                                        setShow(true);
                                    }}>  Delete
                                </MDButton>
                            </>
                            : cancellationKey === true
                                ? <>
                                    <MDButton type="button" className={`erp-gb-button ms-2`} variant="button" fontWeight="regular"
                                        onClick={() => {
                                            setModalConfirmationAction('cancel');
                                            setModalMsg("Do you wish to cancel this record ?");
                                            setShow(true);
                                        }}> Cancel
                                    </MDButton>
                                </>
                                : null
                    }
                </div >

            </DashboardLayout >

            {/* ADD Filter modal */}
            <Modal size="lg" className='erp_document_Form' show={showBomFilter} onHide={FnCloseBomFilter} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Materials Filter</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseBomFilter}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <ProductListProductTypeWise closeModal={FnCloseBomFilter} props={{ product_type_id: cmb_dispatch_challan_type_id }} />
                    </React.Fragment>
                </Modal.Body>
            </Modal>


            {/* Delete Modal */}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                <Modal.Body className='erp_modal_body'>
                    <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                    <h6>Are you sure?</h6>
                    {/* <div className="erp-form-label">Do you wish to delete this record ?</div> */}
                    <div className="erp-form-label"> {modalMsg} </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="danger" className='erp-gb-button' onClick={() => {
                        if (modalConfirmationAction === 'delete') { deleteRecords() }
                        else if (modalConfirmationAction === 'cancel') { FnCancelDispatchChallan() }
                    }}>{modalConfirmationAction === 'cancel' ? 'Confirm' : 'Delete'}</Button>
                </Modal.Footer>
            </Modal>

            {/* Document modal */}
            <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={FnCloseDocumentUploadModal} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Document For {txt_dispatch_challan_no}</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDocumentUploadModal}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <DocumentComponent group_id={txt_dispatch_challan_no !== 0 ? txt_dispatch_challan_no.replaceAll('/', '_') : null} document_group={docGroup} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn erp-gb-button" onClick={FnCloseDocumentUploadModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add new Record Popup */}
            {showAddRecModal ?
                <Modal size="lg" show={showAddRecModal} onHide={FnCloseAddRecordModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseAddRecordModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {FnRenderAddNewRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={FnCloseAddRecordModal} className="btn erp-gb-button" variant="button" fontWeight="regular">Close</MDButton>
                    </Modal.Footer>
                </ Modal> : null
            }

            {/* ADD Filter modal */}
            <Modal size="lg" className='erp_document_Form' show={showPackingDetailsModal} onHide={handleCloseYarnSummaryModal} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title text-center'>Packing Dispatch Stock</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseYarnSummaryModal}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <div className='row p-2'>
                            <div className="col-12">
                                {packingSummaryList.length !== 0 ?
                                    <> <dl className="row mb-0">
                                        <div className="col-md-8 col-sm-12">
                                            <dl className="row mb-0">
                                                <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                                <dd className="col-sm-12 col-md-9 erp-form-label" id='packing_material_name_id'></dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <dl className="row mb-0">
                                                <dt className="col-sm-12 col-md-6 erp-form-label-md-lg"> Total Quantity:</dt>
                                                <dd className="col-sm-12 col-md-6 erp-form-label" id='packing_dispatch_qty_id'></dd>
                                            </dl>
                                        </div>

                                    </dl>
                                        <div class="col  pl-2">
                                            <input type='checkbox' class="me-1" name="selectAllPackingRecords" id="selectAllPackingRecords" onClick={(e) => { FnCheckBoxesSelection('selectAllPackingRecords'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                        </div>
                                        {renderPackingSummaryTbl}
                                    </> :
                                    <div className='row text-center'>
                                        <div className="col-12">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* {packingSummaryList.length !== 0 ?
                            <>
                                <div className="erp_frm_Btns ">
                                    <MDButton type="button" onClick={() => fnShowPackingDetails()} className="btn erp-gb-button" variant="button"
                                        fontWeight="regular">Add</MDButton>
                                </div>
                                <span id="error_addPackingDetailsId"></span>

                            </> : null
                        } */}
                    </React.Fragment>
                </Modal.Body>
            </Modal>

            {/* Send Email On Approval Modal */}
            <ConfirmationModal close={() => closeConfirmationModal()} confirmation={() => FnSendEmail()} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
            {/* Error Modal  */}
            <ErrorModal handleCloseErrModal={() => FnCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />

        </>
    )
}