import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Card, Form, Table, } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ExcelExport from "Features/Exports/ExcelExport";

import ComboBox from "Features/ComboBox";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import DeleteModal from "components/Modals/DeleteModal";

function FrmMonthlyDeductionEntry() {

    // *********** Get Data from listing Page **********
    const { state } = useLocation();
    const { masterTransId = 0, keyForViewUpdate = 'Add', compType = 'Transaction', deletionKey = false, } = state || {}

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    // ****** Navigator
    const navigator = useNavigate();

    // ****** Common Ref Hooks
    const exlsExp = useRef();
    const importFile = useRef(null)
    const comboDataFunc = useRef();
    const frmValidation = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");
    const FnCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };

    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const FnCloseErrModal = () => {
        setShowErrorMsgModal(false)
    };

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => {
        setShowDeleteModal(false)
    };

    // UseRefs Hooks For Searchable combos.
    let employeeTypeComboRef = useRef(null);
    let deductionTypeComboRef = useRef(null);
    let salaryMonthComboRef = useRef(null);
    let salaryYearComboRef = useRef(null);

    // **** Table Data Hook
    const [deductionDetails, setMonthlyDeductionDetails] = useState([]);

    // **** Form Combo options hooks.
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [deductionTypeOptions, setDeductionTypeOpts] = useState([]);
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 'January', monthNo: 1 },
        { label: 'February', value: 'February', monthNo: 2 },
        { label: 'March', value: 'March', monthNo: 3 },
        { label: 'April', value: 'April', monthNo: 4 },
        { label: 'May', value: 'May', monthNo: 5 },
        { label: 'June', value: 'June', monthNo: 6 },
        { label: 'July', value: 'July', monthNo: 7 },
        { label: 'August', value: 'August', monthNo: 8 },
        { label: 'September', value: 'September', monthNo: 9 },
        { label: 'October', value: 'October', monthNo: 10 },
        { label: 'November', value: 'November', monthNo: 11 },
        { label: 'December', value: 'December', monthNo: 12 },
    ]);
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );

    // **** Form input hooks.
    const [cmb_employee_type, setEmployeeType] = useState('');
    const [cmb_deduction_type_id, setDeductionTypeId] = useState(null);
    const [cmb_salary_year, setSalaryYear] = useState(null);
    const [cmb_salary_month, setSalaryMonth] = useState(null);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillComobos();
            if (masterTransId !== 0) {
                await FnCheckUpdateResponce()
            }
            setIsLoading(false);
        }
        loadDataOnload()
    }, []);

    // Load default combos data  ----------------
    const FnFillComobos = async () => {
        try {
            // setSalaryMonth(getMonthName(new Date()));   // set Default Month as current month.
            // setSalaryYear(new Date().getFullYear());    // set default year as current year.
            // Set Default Year;
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setSalaryYear(defaultYear.value);
            salaryYearComboRef.current = defaultYear;
            // Set Default Month;
            let defaultMonth = monthOpts.find(month => month.monthNo == (new Date().getMonth() + 1));
            setSalaryMonth(defaultMonth.value);
            salaryMonthComboRef.current = defaultMonth;

            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getEmployeeTypes = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            getEmployeeTypes = getEmployeeTypes
                .filter(prop => prop.properties_master_name === 'EmployeeType')
                .map(prop => ({ ...prop, value: prop.field_name, label: prop.field_name }));
            getEmployeeTypes.unshift({ field_id: '', value: 'All', label: 'All' });
            setEmployeeTypesOpts(getEmployeeTypes);

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"]
            globalQuery.table = "hmv_deduction_heads";
            globalQuery.conditions.push({ field: "calculation_type", operator: "=", value: 'Amount' });
            globalQuery.conditions.push({ field: "deduction_head_name", operator: "NOT IN", values: ['E-PF1', 'E-PF2'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(deductionHeads => {
                    deductionHeads = deductionHeads
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    setDeductionTypeOpts([
                        { value: '', label: 'Select', field_id: '', field_name: '', },
                        ...deductionHeads,]
                    );
                });
        } catch (error) {
            console.log("error: ", error);
            navigator('/Error')
        }
    }

    // ***----------- Helping Functions.
    const getMonthName = (date) => {
        return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    };

    const moveToListing = () => {
        navigator("/FrmMonthlyDeductionListing")
    }
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'update':
                $('#save-btn').text('Update');
                return '(Modification)';
            case 'view':
                if (deletionKey === true) {
                    return '(Deletion)';
                } else {
                    return '(View)';
                }
            default:
                return '(Creation)';
        }
    };

    // *** ----------- Data Processing starts for the Employee Monthly Deductions Table --------------
    const FnGetEmployees = async () => {
        try {

            if (FnValidateMonthlyDeductionFrm()) {
                setIsLoading(true);

                resetGlobalQuery();
                globalQuery.columns = ['employee_id', 'employee_code', 'department_name', 'employee_name', 'calculation_value as deduction_amount'];
                globalQuery.table = "hmv_employee_deduction_mapping";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

                if (cmb_employee_type !== 'All' && cmb_employee_type !== '' && cmb_employee_type !== null && cmb_employee_type !== undefined) {
                    globalQuery.conditions.push({ field: 'employee_type', operator: "=", value: cmb_employee_type })
                } else {
                    let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                    setEmployeeType(defaultEmplType.value);
                    employeeTypeComboRef.current = defaultEmplType;
                }

                globalQuery.conditions.push({ field: "deduction_heads_id", operator: "=", value: cmb_deduction_type_id });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getDeductionMappingApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                resetGlobalQuery();
                globalQuery.columns = ['employee_id', 'employee_code', 'deduction_amount'];
                globalQuery.table = "ht_monthly_deduction_details";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "deduction_type_id", operator: "=", value: cmb_deduction_type_id });
                globalQuery.conditions.push({ field: "salary_month", operator: "=", value: cmb_salary_month });
                globalQuery.conditions.push({ field: "salary_year", operator: "=", value: cmb_salary_year });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmplMonthlyDeductionsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                let employeeDeductions = getDeductionMappingApiCall;
                employeeDeductions = employeeDeductions?.map(emplMappingDeduction => {
                    // Check monthly deduction already added for that employee.
                    const alreadyAddedMonthlyDeduction = getEmplMonthlyDeductionsApiCall.find(monthlyDeduction => monthlyDeduction.employee_code === emplMappingDeduction.employee_code);
                    // If found, update the deduction_amount
                    if (alreadyAddedMonthlyDeduction) {
                        return {
                            ...emplMappingDeduction,
                            deduction_amount: alreadyAddedMonthlyDeduction.deduction_amount
                        };
                    }
                    // Otherwise, return the object unchanged
                    return emplMappingDeduction;
                });

                setMonthlyDeductionDetails(employeeDeductions)
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error: ", error);
            navigator('/Error')
        }
    }

    const renderEmployeeMonthlyDeductionTbl = useMemo(() => {
        return <>
            {deductionDetails.length !== 0 ? (
                <Table id='employeeMonthlyDeductionTbl' className="erp_table" bordered striped hover>
                    <thead className="erp_table_head">
                        <tr>
                            <th className="erp_table_th">Sr No </th>
                            <th className="erp_table_th col-4">Employee Name </th>
                            <th className="erp_table_th col-2">Employee Code </th>
                            <th className="erp_table_th col-4">Department </th>
                            <th className="erp_table_th col-2">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            deductionDetails.map((deductionDtl, Index) =>
                                <tr rowIndex={Index}>
                                    <td className="erp_table_td">{Index + 1}</td>
                                    <td className="erp_table_td">{deductionDtl.employee_name}</td>
                                    <td className="erp_table_td">{deductionDtl.employee_code}</td>
                                    <td className="erp_table_td">{deductionDtl.department_name}</td>
                                    <td className="erp_table_td text-end">
                                        {
                                            ['view'].includes(keyForViewUpdate) || deletionKey
                                                ? deductionDtl.deduction_amount
                                                : <input type="text" className="erp_input_field mb-0 text-end" id={`deduction_amount_${deductionDtl.employee_code}`}
                                                    value={deductionDtl.deduction_amount} Headers='deduction_amount'
                                                    onFocus={(e) => { updateDeductionDetailsTblData(deductionDtl, e); }}
                                                    onChange={(e) => { updateDeductionDetailsTblData(deductionDtl, e); }}
                                                    onBlur={(e) => { updateDeductionDetailsTblData(deductionDtl, e); }}
                                                />
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            ) : null}
        </>
    }, [deductionDetails]);

    const isZero = (value) => value === 0 || value === '0';
    const updateDeductionDetailsTblData = (currentDeductionDetails, event) => {
        delete event.target.parentElement.dataset.tip;
        let clickedColName = event.target.getAttribute('Headers');
        const currentObjIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        let inputVal = event.target.value;

        if (event._reactName === 'onFocus' && isZero(currentDeductionDetails[clickedColName])) {
            inputVal = '';  // set input field blank.
        } else if (event._reactName === 'onChange' || event._reactName === 'onBlur') {
            inputVal = validateNumberDateInput.current.decimalNumber(inputVal, 4) || 0;
        }

        const updateDeductionDetails = [...deductionDetails]    // Existing tbl Data
        // Updating new data.
        updateDeductionDetails[currentObjIndex] = {
            ...currentDeductionDetails,
            [clickedColName]: inputVal,   // Update input field value

        };
        setMonthlyDeductionDetails(updateDeductionDetails);
    }

    // *** ----------- Data Processing Ends for the Employee Monthly Deductions Table --------------


    // *** ----------- CRUD Operations Functionality Starts ---------------------
    const FnSaveEmployeeMonthlyDeduction = async () => {
        let masterFormIsValid = FnValidateMonthlyDeductionFrm();
        // Check atleast one employee having deduction amount greater than zero;
        if (deductionDetails.filter(deduction => Number(deduction.deduction_amount) > 0).length === 0) {
            setErrMsg("Kindly add a deduction amount for at least one employee...!");
            setShowErrorMsgModal(true);
            return false;
        }

        try {
            if (masterFormIsValid) {
                setIsLoading(true);
                let JsonForSave = { 'MonthlyDeductionMst': {}, 'MonthlyDeductionData': [], 'commonIds': {} }

                let commonFieldsJson = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    // monthly_deduction_master_transaction_id: masterTransId,  // Sending 0 because on every save adding new entry.
                    monthly_deduction_master_transaction_id: 0,
                    deduction_type_id: cmb_deduction_type_id,
                    employee_type: cmb_employee_type,
                    salary_year: cmb_salary_year,
                    salary_month: cmb_salary_month,
                    is_active: true,
                    created_by: UserName
                };

                // Master form JSON Obj;
                JsonForSave.MonthlyDeductionMst = commonFieldsJson;

                // Details form JSON Array;
                JsonForSave.MonthlyDeductionData = deductionDetails
                    .filter(deduction => Number(deduction.deduction_amount) > 0)
                    .map(emplDeduction => ({
                        ...emplDeduction,
                        ...commonFieldsJson,
                        monthly_deduction_transaction_id: 0,   // Sending 0 because on every save adding new entry.
                    }));

                // Some common IDS.
                JsonForSave.commonIds = {
                    company_id: COMPANY_ID,
                    deduction_type_id: cmb_deduction_type_id,
                    salary_year: cmb_salary_year,
                    salary_month: cmb_salary_month,
                }

                console.log('Sending JSON For Save: ', JsonForSave);

                const formData = new FormData();
                formData.append(`HmMonthlyDeductionData`, JSON.stringify(JsonForSave))
                const requestOptions = { method: 'POST', body: formData, };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtMonthlyDeduction/FnAddUpdateRecord`, requestOptions)
                const resp = await apiCall.json()
                console.log('Save Response: ', resp);
                if (resp.success === '0') {
                    setErrMsg(resp.error)
                    setShowErrorMsgModal(true)
                } else {
                    setSuccMsg(resp.message)
                    setShowSuccessMsgModal(true)
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    // *** ------ 
    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtMonthlyDeduction/FnShowParticularRecordForUpdate/${masterTransId}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            if (updateRes !== null) {
                const masterData = updateRes.MasterData;
                setEmployeeType(masterData.employee_type);
                employeeTypeComboRef.current = employeeTypesOpts.find(option => option.value === masterData.employee_type)
                setDeductionTypeId(masterData.deduction_type_id);
                deductionTypeComboRef.current.value = masterData.deduction_type_id;
                setSalaryMonth(masterData.salary_month);
                salaryMonthComboRef.current = monthOpts.find(option => option.value === masterData.salary_month)
                setSalaryYear(masterData.salary_year);
                salaryYearComboRef.current = yearOpts.find(option => option.value === masterData.salary_year)


                // Set Table Data.
                setMonthlyDeductionDetails(updateRes.DetailsData);
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }

    }

    const FnDeleteRecord = async () => {
        try {
            setShowDeleteModal(false);
            setIsLoading(true);
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtMonthlyDeduction/FnDeleteRecord/${masterTransId}/${COMPANY_ID}/${UserName}`, method)
            const resp = await deleteApiCall.json()
            console.log('Save Response: ', resp);
            if (resp.success === '0') {
                setErrMsg(resp.error)
                setShowErrorMsgModal(true)
            } else {
                setSuccMsg(resp.message)
                setShowSuccessMsgModal(true)
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }


    // *** ----------- CRUD Operations Functionality Ends ---------------------


    // ****-------Validation Functions Starts--------------
    // Function for validate the fields at the time of button click;
    const FnValidateMonthlyDeductionFrm = () => {
        // if (cmb_employee_type === '' || cmb_employee_type === null) {
        //     $('#error_cmb_employee_type').text('Please select the atleast one...!')
        //     $('#error_cmb_employee_type').show();
        //     return false;
        // }
        // $('#error_cmb_employee_type').hide();

        if (cmb_deduction_type_id === '' || cmb_deduction_type_id === null) {
            $('#error_cmb_deduction_type_id').text('Please select the atleast one...!')
            $('#error_cmb_deduction_type_id').show();
            return false;
        }
        $('#error_cmb_deduction_type_id').hide();

        if (cmb_salary_month === '' || cmb_salary_month === null) {
            $('#error_cmb_salary_month').text('Please select the atleast one...!')
            $('#error_cmb_salary_month').show();
            return false;
        }
        $('#error_cmb_salary_month').hide();

        if (cmb_salary_year === '' || cmb_salary_year === null) {
            $('#error_cmb_salary_year').text('Please select the atleast one...!')
            $('#error_cmb_salary_year').show();
            return false;
        }
        $('#error_cmb_salary_year').hide();
        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setMonthlyDeductionDetails([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(employeeTypeComboRef.current?.label || '')) { $('#error_cmb_employee_type').hide(); }
        if (isEmpty(deductionTypeComboRef?.current?.value || '')) { $('#error_cmb_deduction_type_id').hide(); }
        if (isEmpty(salaryMonthComboRef?.current?.value || '')) { $('#error_cmb_salary_month').hide(); }
        if (isEmpty(salaryYearComboRef?.current?.value || '')) { $('#error_cmb_salary_year').hide(); }
    }


    // ****-------Validation Functions Ends--------------


    // ---------------------------- Export2Excel Functionality Start. -------------------------------------
    const FnExportToExcel = async () => {
        try {
            let masterFormIsValid = FnValidateMonthlyDeductionFrm();
            if (!masterFormIsValid || deductionDetails.length < 0) { return masterFormIsValid; }  // Means Header is not valid.
            // Check atleast one employee having deduction amount greater than zero;
            // if (deductionDetails.filter(deduction => Number(deduction.deduction_amount) > 0).length === 0) {
            //     setErrMsg("Kindly add a deduction amount for at least one employee...!");
            //     setShowErrorMsgModal(true);
            //     return false;
            // }

            setIsLoading(true);
            let requiredTblCols = ['employee_name', 'employee_code', 'department_name', 'deduction_amount', 'employee_id',];
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] }
            let filtrKeyValue = {};
            filtrKeyValue['0'] = "Employee Type" + ' : ' + (cmb_employee_type !== "" && cmb_employee_type !== null ? employeeTypeComboRef.current?.label + "(" + employeeTypeComboRef.current?.field_id + ")" : '');
            filtrKeyValue['1'] = "Deduction Type" + ' : ' + (cmb_deduction_type_id !== "" && cmb_deduction_type_id !== '0' && cmb_deduction_type_id !== null ? deductionTypeComboRef.current?.label + "(" + deductionTypeComboRef.current?.value + ")" : '');
            filtrKeyValue['2'] = "Month" + ' : ' + (cmb_salary_month !== "" && cmb_salary_month !== null ? salaryMonthComboRef.current?.label + "(" + salaryMonthComboRef.current?.monthNo + ")" : '');
            filtrKeyValue['3'] = "Year" + ' : ' + (cmb_deduction_type_id !== "" && cmb_deduction_type_id !== null ? salaryYearComboRef.current?.label + "(" + salaryYearComboRef.current?.value + ")" : '');
            jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;
            const editableColumnNames = ['deduction_amount'];
            // set columns name.
            for (let colIndex = 0; colIndex < requiredTblCols.length; colIndex++) {
                jsonToExportExcel.columns.push({ "Headers": requiredTblCols[colIndex], "accessor": requiredTblCols[colIndex] })
                if (editableColumnNames.includes(requiredTblCols[colIndex])) {
                    jsonToExportExcel.editable_cols.push(colIndex);
                }
            }
            // set table data.  
            deductionDetails.map((deductionDtl, Index) => {
                jsonToExportExcel['allData'][Index] = deductionDtl;
            });

            jsonToExportExcel.headings = {
                ReportName: "Employee Monthly Deduction Upload",
                CompanyName: COMPANY_NAME,
                CompanyAddress: sessionStorage.getItem('companyAddress'),
            }
            console.log("JSON For Export: ", jsonToExportExcel)

            exlsExp.current.excel(jsonToExportExcel, "Employee Monthly Deductions" + "@" + COMPANY_ID)
            setIsLoading(false);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            navigator('/Error')
        }
    }
    // ---------------------------- Export2Excel Functionality Ends. -------------------------------------

    // ---------------------------- Export2Excel Functionality Start. -------------------------------------
    const onImportClick = () => {
        importFile.current.click();
    };

    const FnImportExcelFile = async (event) => {
        try {
            if (event.target.files) {
                setIsLoading(true);

                let file = event.target.files[0];
                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtMonthlyDeduction/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                if (fetchRes.success === '1') {
                    let comboNames = ['Employee Type', 'Deduction Type', 'Month', 'Year']

                    if (fetchRes.data.length !== 0) {

                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];

                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                const dateParts = fieldVal.split("-");

                                let comboVal;
                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }

                                switch (fieldName) {
                                    case 'Employee Type':
                                        employeeTypeComboRef.current = employeeTypesOpts.find(option => option.field_id == comboVal) || null;
                                        setEmployeeType(employeeTypeComboRef.current.field_name || '');
                                        break;
                                    case 'Deduction Type':
                                        deductionTypeComboRef.current = deductionTypeOptions.find(option => option.value == comboVal) || null;
                                        setDeductionTypeId(comboVal);
                                        break;
                                    case 'Month':
                                        salaryMonthComboRef.current = monthOpts.find(option => option.monthNo == comboVal) || null;
                                        setSalaryMonth(salaryMonthComboRef.current.value|| null);
                                        break;
                                    case 'Year':
                                        salaryYearComboRef.current = yearOpts.find(option => option.value == comboVal) || null;
                                        setSalaryYear(comboVal);
                                        break;
                                    default: break;
                                }
                            }
                        }

                        const deductionDtls = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            return orderDetail;
                        });

                        setMonthlyDeductionDetails(deductionDtls);
                    }
                }
                else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }

                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error Importing to Excel:', error);
            navigator('/Error')
        }
    }
    // ---------------------------- Export2Excel Functionality Ends. -------------------------------------



    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Monthly Deduction Upload {ActionType()}</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type</Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypeComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeType(selectedOpt.value);
                                                employeeTypeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* First Column Ends*/}

                            {/* Second Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Deduction Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={deductionTypeComboRef}
                                            isDisabled={['view'].includes(keyForViewUpdate)}
                                            options={deductionTypeOptions}
                                            inputId="cmb_deduction_type_id"
                                            value={deductionTypeOptions.find(option => option.value == cmb_deduction_type_id)}
                                            onChange={(selectedOpt) => {
                                                setDeductionTypeId(selectedOpt.value);
                                                deductionTypeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                                setMonthlyDeductionDetails([]); // Set Blank Employee details.
                                            }}
                                            placeholder="Search for a deduction type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_deduction_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* Third Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryMonthComboRef}
                                            isDisabled={['view'].includes(keyForViewUpdate)}
                                            options={monthOpts}
                                            inputId="cmb_salary_month"
                                            value={monthOpts.find(option => option.value == cmb_salary_month)}
                                            onChange={(selectedOpt) => {
                                                setSalaryMonth(selectedOpt.value);
                                                salaryMonthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Third Column Ends*/}

                            {/* Fourth Column Starts*/}
                            <div className="col-sm-3 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={salaryYearComboRef}
                                            isDisabled={['view'].includes(keyForViewUpdate)}
                                            options={yearOpts}
                                            inputId="cmb_salary_year"
                                            value={yearOpts.find(option => option.value == cmb_salary_year)}
                                            onChange={(selectedOpt) => {
                                                setSalaryYear(selectedOpt.value);
                                                salaryYearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Fourth Column Ends*/}

                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                {
                                    ['', 'Add'].includes(keyForViewUpdate)
                                        ? <>
                                            <MDButton type="button" id="showEmployeeData" variant="button" fontWeight="regular"
                                                className="erp-gb-button"
                                                onClick={(e) => { FnGetEmployees(); }} >Show Employees</MDButton>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                        <hr />

                        {
                            ['', 'Add'].includes(keyForViewUpdate)
                                ? <>
                                    <div className="row py-2">
                                        <div className='justify-content-end'>
                                            <MDButton type="button" id="export-btn" variant="button" fontWeight="regular"
                                                className={`erp-gb-button float-end ms-1 ${deductionDetails.length > 0 ? 'display' : 'd-none'}`}
                                                onClick={(e) => { FnExportToExcel() }}> Export </MDButton>

                                            <input type="file" id="Excelfile" ref={importFile} onChange={(e) => { FnImportExcelFile(e); }} style={{ display: "none" }}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                            <MDButton type="button" id="import-btn" variant="button" fontWeight="regular"
                                                className={`erp-gb-button float-end ms-1 ${!['view'].includes(keyForViewUpdate) && !deletionKey ? 'display' : 'd-none'}`}
                                                onClick={(e) => { onImportClick() }}> Import </MDButton>
                                        </div>
                                    </div>
                                </>
                                : null
                        }

                        {
                            deductionDetails.length === 0
                                ? <><Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <>
                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">Deductions Details</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 table-responsive" style={{ maxHeight: '400px' }} >
                                                {renderEmployeeMonthlyDeductionTbl}
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }


                        <div className="row text-center">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { moveToListing(); }}  > Back </MDButton>

                                <MDButton type="button" id="save-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center ms-1  ${deductionDetails.length > 0 && !['view'].includes(keyForViewUpdate) && !deletionKey ? 'display' : 'd-none'}`}
                                    onClick={(e) => { FnSaveEmployeeMonthlyDeduction(); }}  > Save </MDButton>

                                <MDButton type="button" id="delete-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button ms-1 ${deletionKey ? 'display' : 'd-none'}`}
                                    onClick={() => { setShowDeleteModal(true); }}> Delete </MDButton>
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout>

            {/* Success Msg Popup */}
            <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            {/* Error Msg Popup */}
            <ErrorModal handleCloseErrModal={() => FnCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            {/* Delete Modal */}
            {/* <Modal show={showDeleteModal} onHide={(e) => {FnCloseDeleteModal()}} backdrop="static" keyboard={false} centered>
                <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={(e) => {FnCloseDeleteModal()}}></button></span>
                <Modal.Body className='erp_modal_body'>
                    <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                    <h6>Are you sure?</h6>
                    <div className="erp-form-label">Do you wish to delete this record ?</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" className='erp-gb-button' onClick={(e) => {FnCloseDeleteModal()}}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="danger" className='erp-gb-button' onClick={(e) => {FnDeleteRecord()}}>Delete</Button>
                </Modal.Footer>
            </Modal> */}
            <DeleteModal show={showDeleteModal} closeModal={FnCloseDeleteModal} deleteRecord={FnDeleteRecord} />

        </>
    )
}

export default FrmMonthlyDeductionEntry;