
import { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ComboBox from 'Features/ComboBox';
import { Form, Table } from 'react-bootstrap';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import Card from 'react-bootstrap/Card';

// File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"

import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from "FrmGeneric/FrmValidations";

function FrmDailyAttendanceEntry(props) {

    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { attendance_device_id = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {}
    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    // Use Refs to call external functions
    const validate = useRef();
    const navigate = useNavigate()
    const comboDataAPiCall = useRef();
    const [errMsg, setErrMsg] = useState('');

    const [employeeCodeOptions, setEmployeeCodeOptions] = useState([]);

    const [actionType, setActionType] = useState('')
    const [cmb_employee_code, setEmployeeCode] = useState('');
    const [employeeID, setEmployeeID] = useState('');
    const [text_job_type_id, setJobTypeId] = useState('1');
    const [night_shift_present_flag, setNightShiftFlag] = useState('N');
    const [job_type, setJobType] = useState('AB');
    const [week_off_present_flag, setWeekOffPresentFlag] = useState('N');
    const [shift_present, setShiftPresent] = useState('');
    const [shift_scheduled, setShiftScheduled] = useState('');
    const [gate_pass_flag, setGatePassFlag] = useState('N');
    const [late_mark_flag, setlateMarkFlag] = useState('N');
    const [early_go_flag, setEarlyGoFlag] = useState('N');
    const [actionLabel, setActionLabel] = useState('Save')
    const [attendance_date, setAttendanceDate] = useState(today);

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [attendanceDetails, setattendanceDetailsData] = useState([]);
    const [dailyAttendanceDetailsData, setDailyAttendanceDetailsData] = useState([]);

    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const handleCloseErrModal = () => { setShowErrorMsgModal(false) };

    const frmValidation = useRef();

    useEffect(() => {
        const loadDataOnload = async () => {
            await fillComboBox();
        }
        loadDataOnload()
    }, [])


    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
    }

    const fillComboBox = async () => {

        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "cmv_employee"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getSpinningProdCountCall) => {
            setEmployeeCodeOptions(getSpinningProdCountCall);
        })
    }
    // const addDailyAttendanceProcess = () => {
    //     debugger
    //     fetch(`${process.env.ATTENDANCE_URL}/process_attendance`, {
    //         method: 'POST'
    //     })
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(data => {
    //             console.log(data);
    //         })
    //         .catch(error => {
    //             console.error('Error Daily attendance Process:', error);
    //         });
    // };

    const addDailyAttendanceProcess = async () => {
        const cmbEmployeeCode = document.getElementById('cmb_employee_code');
        const attendanceDate = document.getElementById('attendance_date').value;
        const selectedEmployeeCode = cmbEmployeeCode.options[cmbEmployeeCode.selectedIndex].textContent;
        setEmployeeID(selectedEmployeeCode);
        debugger
        const attendanceDataApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmAttendanceDevice/FnShowParticularEmployeeCode/${selectedEmployeeCode}/${attendanceDate}`)
        const responce = await attendanceDataApiCall.json();
        setattendanceDetailsData(responce.employeeAllDetails);
        setDailyAttendanceDetailsData(responce.data);
        if (responce.success === 0) {
            console.log("resp: ", responce)
            setErrMsg(responce.error)
            setShowErrorMsgModal(true)
        }
    }

    const renderAttendanceDetailsTable = useMemo(() => {
        return <>
            {attendanceDetails.length !== 0 ? (
                <Table id='leavesDetails-table' className="erp_table" bordered striped >
                    <thead className="  ">
                        <tr>
                            <th className="erp_table_th col-1">Attendance Device Id </th>
                            <th className="erp_table_th col-2">Attendance Device Ip </th>
                            <th className="erp_table_th col-2">Employee Code </th>
                            <th className="erp_table_th col-1">Attendance DateTime</th>
                            <th className="erp_table_th col-1">Punch Status</th>
                            <th className="erp_table_th col-1">Punch Type</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            attendanceDetails.map((leavesItem, Index) =>
                                <tr payTermItemIndex={Index} rowIndex={Index}>
                                    <td className="erp_table_td col-1">{leavesItem.att_device_id}</td>
                                    <td className="erp_table_td col-2">{leavesItem.att_device_ip}</td>
                                    <td className="erp_table_td col-2">{leavesItem.att_device_emp_code}</td>
                                    <td className="erp_table_td col-1">{leavesItem.att_date_time}</td>
                                    <td className="erp_table_td col-1">{leavesItem.att_punch_status}</td>
                                    <td className="erp_table_td col-1">{leavesItem.att_punch_type}</td>

                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            ) : null}
        </>
    }, [attendanceDetails]);



    const addEmployeeDetailsData = async () => {
        const checkIsValidate = await validateEmployeeForm();
        if (checkIsValidate === true) {
            try {
                const employeeAllData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    created_by: UserName,
                    job_type_id: text_job_type_id,
                    employee_code: employeeID,
                    employee_id: cmb_employee_code,
                    shift_scheduled: shift_scheduled,
                    shift_present: shift_present,
                    late_mark_flag: late_mark_flag,
                    early_go_flag: early_go_flag,
                    gate_pass_flag: gate_pass_flag,
                    week_off_present_flag: week_off_present_flag,
                    night_shift_present_flag: night_shift_present_flag,
                    job_type: job_type,
                };

                const mergedDetails = { ...employeeAllData, ...dailyAttendanceDetailsData };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(mergedDetails)
                };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmAttendanceDevice/FnAddDailyAttendance`, requestOptions)
                const resp = await apiCall.json()
                console.log(resp);
                if (resp.success === 1) {
                    setSuccMsg(resp.message)
                    setShowSuccessMsgModal(true)
                } else {
                    console.log("resp: ", resp)
                    setErrMsg(resp.error)
                    setShowErrorMsgModal(true)
                }
            } catch (error) {
                console.log("error: ", error)
            }
        }
    }
    const validateEmployeeForm = async () => {
        debugger
        const employee_form = await validate.current.validateForm('employeeFormId')
        if (!employee_form) {
            return false
        }
        if (cmb_employee_code === '' || cmb_employee_code === '0') {
            $("#error_cmb_employee_code").text("Please select Employee code...!");
            $("#error_cmb_employee_code").show();
            $("#cmb_employee_code").focus();
            return false;
        }
        return true;
    }
    const validateEmpInfoFields = () => {
        validate.current.validateFieldsOnChange('employeeFormId')
      }
    
   
    return (
        <>
            <FrmValidations ref={validate} />
            <ComboBox ref={comboDataAPiCall} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Daily Attendance Process{actionType} </label>
                    </div>
                    <form id='employeeFormId'>
                        <div className="row erp_transporter_div ">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    {/* <div className='row'> */}
                                    <div className="col">
                                        <Form.Label className="erp-form-label">Employee Code<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_employee_code" className="form-select form-select-sm" value={cmb_employee_code} onChange={e => { setEmployeeCode(e.target.value); addDailyAttendanceProcess();validateEmpInfoFields(); }} maxLength="255">
                                            <option value="" disabled>Select</option>
                                            {employeeCodeOptions?.map(groupcode => (
                                                <option value={groupcode.employee_id}>{groupcode.employee_code}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {/* </div>
                                    <div className="row"> */}
                                    <div className="col">
                                        <Form.Label className="erp-form-label">Attendance Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id="attendance_date" className="erp_input_field" value={attendance_date} onChange={e => { setAttendanceDate(e.target.value); addDailyAttendanceProcess(); }} optional="optional" />
                                        <MDTypography variant="button" id="error_attendance_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>

                        </div>
                    </form>
                    <div class="col-12 col-lg-12 " style={{ minHeight: "500px;", overflowY: 'auto' }}>
                        <div>
                            {renderAttendanceDetailsTable}
                        </div>
                    </div>
                    <div className="card-footer py-0 text-center">

                        <MDButton type="submit" onClick={() => addEmployeeDetailsData()} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>

                    </div >

                </div>
                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </div>
        </>
    )
}

export default FrmDailyAttendanceEntry;
