import React from 'react'
import underConstruction from 'assets/images/under-construction.jpg'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'

function FrmDispatchChallanProjectsListing() {
  return (
    <>
        <DashboardLayout>
                <div className='row'>
                    {/* <div className='col-12'> */}
                      <img id='maintainenceImgId' src={underConstruction} alt='Under Construction' height='500px' width='1000px' />
                    {/* </div> */}
                </div>
            </DashboardLayout>
    </>
  )
}

export default FrmDispatchChallanProjectsListing
