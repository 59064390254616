import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

// Import layout containers
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Tooltip } from "@material-ui/core";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";


// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";
import Select from 'react-select';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Import created functions 
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";

function FrmSalesOrderStockTransferEntry() {
    // FnGet Today Date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Config Constant
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_NAME, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

    // From listing to here
    const { state } = useLocation();
    const { salesOrderTransferId = 0, keyForViewUpdate } = state || {}

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('data');

    // Form hook's for array
    const [customerOrderList, setCustomerOrderList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [materialTypeList, setMaterialTypeList] = useState([]);
    const [custMaterialStockList, setCustMaterialStockList] = useState([]);
    const [allGodownList, setAllGodownList] = useState([]);



    // Form states
    const [txt_sales_order_transfer_no, setSoTransferNo] = useState();
    const [dt_sales_order_transfer_date, setSoTransferDate] = useState(today);
    const [cmb_sales_order_transfer_material_type_id, setSoTransferTypeId] = useState('');
    const [cmb_customer_id, setCustomerId] = useState('');
    const cmb_customer_id_ref = useRef(null);
    const [cmb_customer_order, setCustomerOrder] = useState();
    const [product_material_id, setProductMaterialId] = useState();
    const [product_material_name, setProductMaterialName] = useState('');
    const [product_material_code, setProductMaterialCode] = useState('');

    // Loading state
    const [isLoading, setIsLoading] = useState(false);

    // For Add, View Update Button Label
    const [actionLabel, setActionLabel] = useState('Save');

    // Success Msg Modal
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate(`/salesorder/stocktransferlisting`)

    }

    //Error Msg Modal
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Ref's
    const combobox_api_ref = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const generateAutoNo = useRef();
    const navigate = useNavigate();

    const requiredCols = ['product_material_name', 'product_material_id', 'product_type_group', 'product_material_code'];

    useEffect(() => {
        const loadDataOnload = async () => {
            try {
                await FnFillCombosData();
                if (salesOrderTransferId !== 0) {
                    FnCheckUpdateResponse()
                }
            } catch (error) {
                console.error(error);
                navigate('/Error')
            } finally {
                setIsLoading(false);
            }
        }
        loadDataOnload()

    }, [])

    const FnGenerateSalesOrderTransferNo = async (sales_order_type) => {
        const getSoTransferNo = await generateAutoNo.current.generateTAutoNo("mt_sales_order_stock_transfer_master", "sales_order_transfer_no", sales_order_type, 'SO', "5", "sales_order_transfer_type_id", document.getElementById('cmb_sales_order_transfer_material_type_id').value);
        setSoTransferNo(getSoTransferNo);
        return getSoTransferNo;
    };

    const FnFillCombosData = async () => {
        try {
            // Get customer details
            combobox_api_ref.current.fillMasterData("cmv_customer", "", "").then((getCustomerDetails) => {
                if (getCustomerDetails.length !== 0) {
                    const customers = [
                        { value: '', label: 'Select' },
                        { value: '0', label: 'Add New Record +' },
                        ...getCustomerDetails.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                    ];
                    setCustomerList(customers);
                }
            })

            // Get Material Type details
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
            globalQuery.table = "smv_product_type";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getOrderTypesUpdatedApiCall = await combobox_api_ref.current.fillFiltersCombo(globalQuery);
            setMaterialTypeList(getOrderTypesUpdatedApiCall);

            // Get Godowns list
            resetGlobalQuery();
            globalQuery.columns = ['godown_id', 'godown_section_id', 'godown_section_beans_id', 'godown_name', 'godown_section_name', 'godown_section_beans_name'];
            globalQuery.table = "cmv_godown_section_beans";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getGodownList = await combobox_api_ref.current.fillFiltersCombo(globalQuery);
            setAllGodownList(getGodownList);

            console.log('data: - ', data);

            // Get Params
            if (data !== null) {
                const requiredData = JSON.parse(data);
                console.log('requiredData: -', requiredData);

                const { product_type_id, customer_order_no, customer_id, product_material_id, product_material_name, product_material_code } = requiredData;
                setSoTransferTypeId(product_type_id);
                setProductMaterialId(product_material_id)
                setProductMaterialName(product_material_name)
                setProductMaterialCode(product_material_code)

                const selectedSOType = getOrderTypesUpdatedApiCall.find(orderType => orderType.field_id === parseInt(product_type_id));
                await FnGenerateSalesOrderTransferNo(selectedSOType.product_type_short_name);

                setCustomerId(parseInt(customer_id));
                if (cmb_customer_id_ref.current) {
                    cmb_customer_id_ref.current.value = parseInt(customer_id) || '';
                }
                await FnComboOnChange('Customer');
                setCustomerOrder(customer_order_no);
                await FnShowCustomerStockDetails()
            }
        } catch (error) {
            console.log('error:- ', error);
        }
    }

    const FnCheckUpdateResponse = async () => {
        try {
            setIsLoading(true);

            await validate.current.readOnly('customerStockDetailsForm');

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderStockTransfer/FnShowParticularRecordForUpdate/${salesOrderTransferId}`)
            const response = await apiCall.json();
            if (response.success === 1) {
                const {
                    soStockTransferHeaderData,
                    soStockTransferDetailsData
                } = response;

                // Destructuring `soStockTransferHeaderData` object
                const {
                    sales_order_transfer_id,
                    sales_order_transfer_type_id,
                    sales_order_transfer_no,
                    sales_order_transfer_date,
                    to_customer_id,
                    to_customer_order_no,
                    product_material_name,
                    product_material_id,
                } = soStockTransferHeaderData;

                setSoTransferNo(sales_order_transfer_no || '');
                setSoTransferDate(sales_order_transfer_date || today); // Ensure `today` is defined
                setSoTransferTypeId(sales_order_transfer_type_id || '');
                setCustomerId(to_customer_id || null);
                setCustomerOrder(to_customer_order_no || '');
                setProductMaterialId(product_material_id || '');
                setProductMaterialName(product_material_name || '');

                if (cmb_customer_id_ref.current) {
                    cmb_customer_id_ref.current.value = cmb_customer_id || '';
                }

                // Destructuring `soStockTransferDetailsData` object (assuming it has similar structure)
                // Adjust this part according to the actual structure of `soStockTransferDetailsData`
                setCustMaterialStockList(soStockTransferDetailsData)


            }


        } catch (error) {
            console.log('error:- ', error);
        } finally {
            setIsLoading(false)
        }
    }
    const FnUpdateComboBoxData = () => {
        if (cmb_customer_id_ref.current) {
            cmb_customer_id_ref.current = null;
        }
        // Get customer details
        combobox_api_ref.current.fillMasterData("cmv_customer", "", "").then((getCustomerDetails) => {
            if (getCustomerDetails.length !== 0) {
                const customers = [
                    { value: '', label: 'Select' },
                    { value: '0', label: 'Add New Record +' },
                    ...getCustomerDetails.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                ];
                setCustomerList(customers);
                // setCustomerList(getCustomerDetails);
            }
        })
    }


    const FnComboOnChange = async (key) => {
        try {
            switch (key) {
                case 'Customer':
                    const getCustomerId = cmb_customer_id_ref.current.value || '';

                    // Onchange of customer set the dependent combo list to empty
                    setCustomerOrderList([]);
                    setCustomerOrder('');

                    if (getCustomerId !== '' && getCustomerId !== '0') {
                        document.getElementById('error_cmb_customer_id').style.display = 'none';

                        // Get customer order list
                        resetGlobalQuery();
                        globalQuery.columns = ['sales_order_no', 'sales_order_date', 'customer_order_no', 'customer_order_Date']
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: getCustomerId });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "sales_order_status", operator: "=", value: 'A' });
                        globalQuery.conditions.push({ field: "product_type_short_name", operator: "In", values: ['GF', 'YN', 'FF'] });

                        const customerOrderList = await combobox_api_ref.current.fillFiltersCombo(globalQuery);
                        setCustomerOrderList(customerOrderList);
                    } else if (getCustomerId === '0') {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) { newTab.focus(); }
                    }

                    if (getCustomerId !== '0') {
                        setCustomerId(getCustomerId)
                    }
                    break;

                case 'SalesOrderMaterialTransferType':
                    const transferMaterialTypeValue = document.getElementById('cmb_sales_order_transfer_material_type_id').value;
                    if (transferMaterialTypeValue !== '') {
                        const selectedSOType = materialTypeList.find(orderType => orderType.field_id === parseInt(transferMaterialTypeValue));
                        await FnGenerateSalesOrderTransferNo(selectedSOType.product_type_short_name)
                    }

                    break;

            }
        } catch (error) {
            console.log('error:- ', error);
        }
    }

    const FnGetMaterialsBySearch = async () => {
        try {
            // setIsLoading(true)
            const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];

            if (getData.length !== 0) {
                setProductMaterialId(getData[0].product_material_id);
                setProductMaterialName(getData[0].product_material_name);
                setProductMaterialCode(getData[0].product_material_code)
            }

        } catch (error) {
            console.log('error:- ', error);
        }
    };

    const FnUpdateCustMaterialDetailTblRow = async (event, rowdata, index) => {
        try {
            setIsLoading(true)

            let clickedColName = event.target.getAttribute('Headers');
            let eventId = document.getElementById(event.target.id);
            let enteredValue = parseFloat(event.target.value);

            if (isNaN(enteredValue)) {
                enteredValue = 0;
            }
            else if (enteredValue != 0 || enteredValue != "") {
                delete eventId.parentElement.dataset.tip;
            }

            switch (clickedColName) {
                case 'transfer_quantity':
                    if (!isNaN(enteredValue) && enteredValue <= rowdata['closing_balance_quantity']) {
                        rowdata[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue);
                        rowdata.transfer_weight = rowdata[clickedColName] * validateNumberDateInput.current.decimalNumber(rowdata.weight_per_box_item);
                        delete eventId.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.setAttribute('data-tip', 'Transfer quantity should be less than or equal to stock quantity');
                        return;
                    }
                    break;

                case 'transfer_weight':
                    if (!isNaN(enteredValue) && enteredValue <= rowdata['closing_balance_weight']) {
                        rowdata[clickedColName] = validateNumberDateInput.current.decimalNumber(enteredValue);
                        delete eventId.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.setAttribute('data-tip', 'Transfer weight should be less than or equal to stock weight');
                        return;
                    }
                    break;
                case 'godown_id':
                case 'godown_section_id':
                case 'godown_section_beans_id':
                    rowdata[clickedColName] = enteredValue;
                    break;
                default:
                    break;
            }

            let custMaterialStockDetails = [...custMaterialStockList];
            custMaterialStockDetails[index] = rowdata;

            if (clickedColName === 'godown_id' || clickedColName === 'godown_section_id' || clickedColName === 'godown_section_beans_id') {
                const updatedCustomerMaterialList = await FnGetRespectiveGodowns(custMaterialStockDetails)
                setCustMaterialStockList(updatedCustomerMaterialList)
            } else {
                setCustMaterialStockList(custMaterialStockDetails)
            }


        } catch (error) {
            console.log('error: ', error);
        } finally {
            setIsLoading(false);
        }
    }

    function showError(elementId, errorMessage) {
        const errorElement = document.getElementById(`error_${elementId}`);
        errorElement.textContent = errorMessage;
        errorElement.style.display = 'block';
        document.getElementById(elementId).focus();
    }

    const FnShowCustomerStockDetails = async () => {
        try {
            setIsLoading(true)
            // const cmbCustomerId = document.getElementById('cmb_customer_id').value;
            const cmbCustomerOrder = document.getElementById('cmb_customer_order').value;
            // Assuming you have only one element with the class and attribute you're interested in
            const labelElement = document.querySelector('label.erp-form-label[id="product_material_id"]');

            // Get the product_material_id attribute
            const productMaterialId = labelElement.getAttribute('product_material_id');



            if (cmb_customer_id_ref.current?.value === '' ||
                cmb_customer_id_ref.current?.value === '0' || cmb_customer_id_ref.current?.value === null) {
                showError('cmb_customer_id', 'Please fill this field...!');
                return false;
            }

            if (cmbCustomerOrder === '' || cmbCustomerOrder === '0') {
                showError('cmb_customer_order', 'Please fill this field...!');
                return false;
            }


            // Get customer order material list
            resetGlobalQuery();
            globalQuery.columns = ['sm.goods_receipt_no', 'sm.customer_name', 'sm.customer_order_no', 'sm.customer_id', 'sm.sales_order_no', 'sm.product_material_unit_name', 'sm.product_material_unit_id',
                'sm.closing_balance_quantity', 'sm.closing_balance_weight', 'sm.weight_per_box_item', 'sm.godown_name', 'sm.godown_section_name', 'sm.godown_section_beans_name',
                'sm.godown_id', 'sm.godown_section_id', 'sm.godown_section_beans_id', 'so.sales_order_no', 'so.sales_order_date', 'so.overall_schedule_date', 'so.customer_name']

            globalQuery.table = "smv_product_rm_stock_details sm"
            globalQuery.conditions.push({ field: "sm.customer_order_no", operator: "!=", value: cmbCustomerOrder });
            globalQuery.conditions.push({ field: "sm.customer_order_no", operator: "!=", value: '' });
            globalQuery.conditions.push({ field: "sm.product_rm_id", operator: "=", value: productMaterialId });
            globalQuery.conditions.push({ field: "sm.company_id", operator: "=", value: COMPANY_ID });
            globalQuery.joins = [
                {
                    "table": `mtv_sales_order_master_trading_summary so`,
                    "type": "LEFT",
                    "on": [
                        {
                            "left": "so.customer_order_no",
                            "right": "sm.customer_order_no",
                        },
                        {
                            "left": "so.company_id",
                            "right": "sm.company_id",  // Add single quotes around the string value 
                        },

                    ]
                },


            ];
            const customerMaterialList = await combobox_api_ref.current.fillFiltersCombo(globalQuery);


            const updatedCustomerMaterialList = await FnGetRespectiveGodowns(customerMaterialList)
            setCustMaterialStockList(updatedCustomerMaterialList)
        } catch (error) {
            console.log('error:- ', error);
        } finally {
            setIsLoading(false)
        }
    }

    const FnGetRespectiveGodowns = async (customerMaterialList) => {
        let allGodowns = [...allGodownList];

        if (allGodowns.length === 0) {
            resetGlobalQuery();
            globalQuery.columns = ['godown_id', 'godown_section_id', 'godown_section_beans_id', 'godown_name', 'godown_section_name', 'godown_section_beans_name'];
            globalQuery.table = "cmv_godown_section_beans";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getGodownList = await combobox_api_ref.current.fillFiltersCombo(globalQuery);

            allGodowns = getGodownList;
        }


        const updatedCustomerMaterialList = await Promise.all(customerMaterialList.map(async (item) => {
            // Get distinct godown_id rows
            const distinctGodownList = Object.values(allGodowns.reduce((acc, curr) => {
                if (!acc[curr.godown_id]) {
                    acc[curr.godown_id] = curr;
                }
                return acc;
            }, {}));

            // Get godown_section rows
            const sections = allGodowns.filter(godown => godown.godown_id === item.godown_id);

            // Get godown_section_beans rows
            const sectionsBeans = allGodowns.filter(godown => godown.godown_section_beans_id === item.godown_section_beans_id);

            return { ...item, godown_list: distinctGodownList, godown_section_list: sections, godown_section_beans_list: sectionsBeans };
        }));

        return updatedCustomerMaterialList;
    }

    const FnAddUpdateSoTransfer = async () => {
        try {
            setIsLoading(true)
            // let validation = await validate.current.validateForm('customerStockDetailsForm');

            if (cmb_customer_id === '' || cmb_customer_id === '0') {
                // Display an error message
                document.getElementById("error_cmb_customer_id").textContent = "Please fill this field...!";
                document.getElementById("error_cmb_customer_id").style.display = "block";

                // Set focus to the input element
                document.getElementById("cmb_customer_id").focus();

                // Prevent form submission
                return false;
            }

            let customerStockDetailsIsValid = true;

            const selectedCustomerStock = document.querySelectorAll('#customerStockId tbody tr .selectStockDetails:checked');

            selectedCustomerStock.forEach(function (checkbox) {
                const currentTblRow = checkbox.closest('tr');
                const transferQtyInput = currentTblRow.querySelector('input[id^="transfer_quantity_"]');
                const transferWtInput = currentTblRow.querySelector('input[id^="transfer_weight_"]');
                const transferQty = parseFloat(transferQtyInput.value);
                const transferWt = parseFloat(transferWtInput.value);

                if (isNaN(transferQty) || transferQty === '') {
                    transferQtyInput.parentElement.dataset.tip = 'Transfer quantity should not be a zero or blank...!';
                    transferQtyInput.focus();
                    customerStockDetailsIsValid = false;
                } else if (isNaN(transferWt) || transferWt === '') {
                    transferWtInput.parentElement.dataset.tip = 'Transfer weight should not be a zero or blank...!';
                    transferWtInput.focus();
                    customerStockDetailsIsValid = false;
                }

                return customerStockDetailsIsValid;
            });


            if (!customerStockDetailsIsValid) {
                return;
            }

            // if (validation === true) {
            const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': { 'company_id': COMPANY_ID } }

            let salesOrderTransferTypeElement = document.getElementById('cmb_sales_order_transfer_material_type_id');
            let selectedSalesOrderTransferType = salesOrderTransferTypeElement.options[salesOrderTransferTypeElement.selectedIndex].text;

            let transHeaderJson = {
                sales_order_transfer_id: 0,
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                sales_order_transfer_type_id: cmb_sales_order_transfer_material_type_id,
                sales_order_transfer_type: selectedSalesOrderTransferType,
                sales_order_transfer_no: txt_sales_order_transfer_no,
                sales_order_transfer_date: dt_sales_order_transfer_date,
                to_customer_id: cmb_customer_id,
                to_customer_order_no: cmb_customer_order,
                product_material_id: product_material_id,
                remark: '',
                created_by: UserName,
                modified_by: UserName
            }

            json.TransHeaderData = transHeaderJson;


            //Customer Stock Details
            const selectedItems = custMaterialStockList.filter((detail, index) => {
                if (!detail.isChecked) {
                    if (detail.transfer_quantity === 0 || detail.transfer_quantity === '' || detail.transfer_quantity === undefined) {
                        const getObject = document.getElementById(`transfer_quantity_${index}`)
                        getObject.parentElement.dataset.tip = 'Transfer quantity should not be a zero or blank...!';
                        getObject.focus();
                        return false;
                    }
                }
                return true;
            });

            if (selectedItems.length === 0) {
                setErrMsg('Please select at least one stock transfer.')
                setShowErrorMsgModal(true)
                return;
            }

            console.log('selectedItems :- ', selectedItems);

            const customerStockDetails = selectedItems.map(detail => ({
                sales_order_transfer_details_id: detail.sales_order_transfer_details_id,
                sales_order_transfer_id: detail.sales_order_transfer_id,
                goods_receipt_no: detail.goods_receipt_no,
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                customer_id: detail.customer_id,
                customer_order_no: detail.customer_order_no,
                transfer_quantity: detail.transfer_quantity,
                transfer_weight: detail.transfer_weight,
                godown_id: detail.godown_id,
                godown_section_id: detail.godown_section_id,
                godown_section_beans_id: detail.godown_section_beans_id,
                created_by: UserName,
                modified_by: UserName
            }));

            json.TransDetailData = customerStockDetails;


            const formData = new FormData()
            formData.append('RequestTransferStock', JSON.stringify(json))
            const forwardData = {
                method: 'POST',
                body: formData,
            }
            const transferApi = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderStockTransfer/FnAddUpdateRecord`, forwardData)
            const response = await transferApi.json()
            if (response.success === 0) {
                setErrMsg(response.error)
                setShowErrorMsgModal(true)
            } else {
                setSuccMsg(response.message)
                setShowSuccessMsgModal(true);
            }
            // }
        } catch (error) {
            console.log('error:- ', error);
        } finally {
            setIsLoading(false)
        }
    }

    const FnHandleCheckboxChange = (e, index) => {
        const updatedList = custMaterialStockList.map((item, idx) =>
            idx === index ? { ...item, isChecked: e.target.checked } : item
        );
        setCustMaterialStockList(updatedList);  // Update state if using React state
    };

    const renderCustMaterialStockList = useMemo(() => {
        return (
            <Table id='customerStockId' className="erp_table" bordered striped hover responsive>
                <thead className="erp_table_head">
                    <tr>
                        {keyForViewUpdate !== 'view' ?
                            <th className="erp_table_th">Action</th> : null}
                        <th className="erp_table_th">Customer Name</th>
                        <th className="erp_table_th">Sales Order</th>
                        <th className="erp_table_th">Customer Order</th>
                        {/* <th className="erp_table_th">SO Status</th> */}
                        <th className="erp_table_th">Unit</th>
                        <th className="erp_table_th">Wt per unit</th>
                        {keyForViewUpdate !== 'view' ?
                            <>
                                <th className="erp_table_th">Stock Qty</th>
                                <th className="erp_table_th">Stock Wt</th>
                            </> : null
                        }

                        <th className="erp_table_th">Transfer Qty</th>
                        <th className="erp_table_th">Transfer Wt</th>
                        {keyForViewUpdate !== 'view' ?
                            <>
                                <th className="erp_table_th">Godown</th>
                                <th className="erp_table_th">Godown Section</th>
                                <th className="erp_table_th">Godown Section Beans</th>
                            </> : null
                        }

                        <th className="erp_table_th">To Godown</th>
                        <th className="erp_table_th">To Godown Section</th>
                        <th className="erp_table_th">To Godown Section Beans</th>
                        <th className="erp_table_th">Sales Ord Date</th>
                        <th className="erp_table_th">Schedule Date</th>
                    </tr>
                </thead>
                <tbody>
                    {custMaterialStockList.length > 0 ? (
                        custMaterialStockList.map((stockDetails, indexOfItem) => (
                            <tr key={indexOfItem} rowindex={indexOfItem}>
                                {keyForViewUpdate !== 'view' ?
                                    <td className="erp_table_td">
                                        <input type="checkbox" name="selectStockDetails" className="form-check-input selectStockDetails"
                                            id={'selectStockDetails_' + indexOfItem}
                                            disabled={keyForViewUpdate === 'view'} onChange={(e) => FnHandleCheckboxChange(e, indexOfItem)}
                                        />
                                    </td> : null}
                                <td className="erp_table_td" id={`customer_name_${indexOfItem}`}>
                                    {stockDetails.customer_name}
                                </td>
                                <td className="erp_table_td" id={`sales_order_no_${indexOfItem}`}>
                                    {stockDetails.sales_order_no}
                                </td>
                                <td className="erp_table_td" id={`customer_order_no_${indexOfItem}`}>
                                    {stockDetails.customer_order_no}
                                </td>
                                {/* <td className="erp_table_td" id={`customer_id_${indexOfItem}`}>
                                  {stockDetails.customer_id} 
                                </td> */}

                                <td className="erp_table_td" id={`product_material_unit_name_${indexOfItem}`}>
                                    {stockDetails.product_material_unit_name}
                                </td>
                                <td className="erp_table_td" id={`product_material_unit_id_${indexOfItem}`}>
                                    {stockDetails.weight_per_box_item}
                                </td>
                                {keyForViewUpdate !== 'view' ?
                                    <>
                                        <td className="erp_table_td" id={`closing_balance_quantity_${indexOfItem}`}>
                                            {stockDetails.closing_balance_quantity}
                                        </td>
                                        <td className="erp_table_td" id={`closing_balance_weight_${indexOfItem}`}>
                                            {stockDetails.closing_balance_weight}
                                        </td>
                                    </> : null
                                }


                                <td className="erp_table_td">
                                    <input
                                        type="text" style={{ width: '100px', backgroundColor: '#AFE1AF' }}
                                        className="erp_input_field_table_txt mb-0 text-end "
                                        disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                        id={`transfer_quantity_${indexOfItem}`}
                                        onChange={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)}
                                        onBlur={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)}
                                        value={stockDetails.transfer_quantity}
                                        Headers='transfer_quantity'
                                    />
                                </td>
                                <td className="erp_table_td" id={`transfer_weight_${indexOfItem}`}>
                                    <input
                                        type="text"
                                        style={{ width: '78px', marginRight: '3px', backgroundColor: '#AFE1AF' }}
                                        className="erp_input_field_table_txt text-end mb-1"
                                        id={`transfer_weight_${indexOfItem}`}
                                        value={stockDetails.transfer_weight}
                                        onChange={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)}
                                        onBlur={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)}
                                        Headers='transfer_weight'
                                        disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                                    />
                                </td>

                                {keyForViewUpdate !== 'view' ?
                                    <>
                                        <td className='erp_table_td'>
                                            {stockDetails.godown_name}
                                        </td>
                                        <td className="erp_table_td">
                                            {stockDetails.godown_section_name}
                                        </td>
                                        <td className="erp_table_td">
                                            {stockDetails.godown_section_beans_name}
                                        </td>
                                    </> : null
                                }

                                {keyForViewUpdate !== 'view' ?
                                    <>
                                        <td className='erp_table_td'>
                                            <select id={`godown_id_${indexOfItem}`} value={stockDetails.godown_id || ''} className="erp_input_field_table_txt mb-0"
                                                onChange={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)} Headers='godown_id'
                                            >
                                                <option value='' >Select</option>
                                                {stockDetails.godown_list?.map((godown) => (
                                                    <option key={godown.godown_id} value={godown.godown_id}>{godown.godown_name}</option>
                                                ))}
                                            </select>
                                        </td>

                                        <td className="erp_table_td">
                                            <select id={`godown_section_id_${indexOfItem}`} value={stockDetails.godown_section_id || ''} className="erp_input_field_table_txt mb-0" onChange={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)} Headers='godown_section_id'>
                                                <option value='' >Select</option>
                                                {stockDetails.godown_section_list?.map((section) => (
                                                    <option key={section.godown_section_id} value={section.godown_section_id}>{section.godown_section_name}</option>
                                                ))}
                                            </select>
                                        </td>

                                        <td className="erp_table_td">
                                            <select id={`godown_section_beans_id_${indexOfItem}`} value={stockDetails.godown_section_beans_id || ''} className="erp_input_field_table_txt mb-0" onChange={(e) => FnUpdateCustMaterialDetailTblRow(e, stockDetails, indexOfItem)} Headers='godown_section_beans_id'>
                                                <option value='' >Select</option>
                                                {stockDetails.godown_section_beans_list?.map((section) => (
                                                    <option key={section.godown_section_beans_id} value={section.godown_section_beans_id}>{section.godown_section_beans_name}</option>
                                                ))}
                                            </select>
                                        </td></>
                                    : <>
                                        <td className='erp_table_td'>
                                            {stockDetails.godown_name}
                                        </td>
                                        <td className="erp_table_td">
                                            {stockDetails.godown_section_name}
                                        </td>
                                        <td className="erp_table_td">
                                            {stockDetails.godown_section_beans_name}
                                        </td>
                                    </>}
                                <td className="erp_table_td" id={`weight_per_box_item_${indexOfItem}`}>
                                    {stockDetails.sales_order_date}
                                </td>
                                <td className="erp_table_td" id={`overall_schedule_date_${indexOfItem}`}>
                                    {stockDetails.overall_schedule_date}
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="erp_table_td text-center" colSpan={19}>No Records Found..!</td>
                        </tr>
                    )}
                </tbody>

            </Table>

        );
    }, [custMaterialStockList]);


    return (
        <>
            <ComboBox ref={combobox_api_ref} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <FrmValidations ref={validate} />
            <GenerateTAutoNo ref={generateAutoNo} />

            <DashboardLayout>

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-1'>
                        <label className='erp-form-label-lg text-center'>Sales Order Stock Transfer</label>
                    </div>


                    <form id="customerStockDetailsForm">
                        <div className="row p-1">
                            {/* first column */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Material Type <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-12'>
                                        <select id="cmb_sales_order_transfer_material_type_id" className="form-select form-select-sm" value={cmb_sales_order_transfer_material_type_id} onChange={(event) => { setSoTransferTypeId(event.target.value); FnComboOnChange('SalesOrderMaterialTransferType'); }}  >
                                            <option value="" disabled>Select</option>
                                            <option value="All" >All</option>
                                            {materialTypeList.length !== 0 ? (
                                                <>
                                                    {materialTypeList.map(materialType => (
                                                        <option key={materialType.field_id} value={materialType.field_id} lbl={materialType.field_name} shortName={`${materialType.product_type_short_name}`}> {materialType.field_name} </option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sales_order_transfer_material_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">Transfer Code & Date <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_sales_order_transfer_no" className="erp_input_field" value={txt_sales_order_transfer_no} disabled />
                                                <MDTypography variant="button" id="error_txt_sales_order_transfer_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>

                                            <div className="col-10 col-md-3 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='dt_sales_order_transfer_date' className="erp_input_field" value={dt_sales_order_transfer_date} disabled />
                                                <MDTypography variant="button" id="error_dt_sales_order_transfer_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {keyForViewUpdate !== 'view' ?
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Search Material <span className="required">*</span></Form.Label>
                                        </div>

                                        <div className="col-sm-7">
                                            <SearchItemFilter
                                                getSearchData={FnGetMaterialsBySearch}
                                                formIdForValidate=""
                                                requiredCols={requiredCols}
                                                {...(cmb_sales_order_transfer_material_type_id !== '' && cmb_sales_order_transfer_material_type_id !== 'All' && {
                                                    product_type_id: cmb_sales_order_transfer_material_type_id
                                                })}
                                            />
                                            <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> :
                                    keyForViewUpdate === 'view' ?
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">	Material Name <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-7">
                                                <Form.Label className="erp-form-label" id="product_material_id" value={product_material_id}>{`[ ${product_material_code} ] ${product_material_name}`}</Form.Label>
                                            </div>
                                        </div> : null}


                            </div>
                            <div className="col-sm-6 erp_form_col_div">
                                {product_material_name !== '' && keyForViewUpdate !== 'view' ?
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">	Material Name <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-7">
                                            <Form.Label className="erp-form-label" product_material_id={product_material_id} id={`product_material_id`}>
                                                {`[ ${product_material_code} ] ${product_material_name}`}
                                            </Form.Label>
                                        </div>
                                    </div> : null}

                                <div className="row">
                                    <div className='col-sm-3 col-12'>
                                        <Form.Label className="erp-form-label">To Customer <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <Select
                                            ref={cmb_customer_id_ref}
                                            options={customerList}
                                            inputId="cmb_customer_id" // Provide the ID for the input box
                                            value={customerList.find(option => option.value === cmb_customer_id)}
                                            onChange={(selectedOpt) => {
                                                cmb_customer_id_ref.current = selectedOpt;
                                                setCustomerId(selectedOpt.value)
                                                FnComboOnChange("Customer");
                                            }}
                                            placeholder="Search for a customer..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={keyForViewUpdate === 'view'}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />

                                        {/* <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={e => { setCustomerId(e.target.value); FnComboBoxesOnChange("Customer"); validateFields(); }} >
                                        <option value="" disabled>Select</option>
                                        {customerList?.map(customerOrder => (
                                            <option value={customerOrder.field_id} >{customerOrder.field_name}</option>
                                        ))}
                                    </select> */}
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ?
                                            <div className='col-sm-1 col-2 refreshIconCol'>
                                                <Tooltip title="Refresh" placement="right">
                                                    <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData(); }} />
                                                </Tooltip>
                                            </div>
                                            : null
                                    }

                                </div>

                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">To Customer Order <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ?
                                            <select id="cmb_customer_order" className="form-select form-select-sm" value={cmb_customer_order} onChange={e => {
                                                setCustomerOrder(e.target.value); FnComboOnChange("CustomerOrder");
                                                // validateFields(); 
                                            }} >
                                                <option value="" disabled>Select</option>
                                                {customerOrderList?.map(customerOrder => (
                                                    <option value={customerOrder.customer_order_no} >{customerOrder.customer_order_no}</option>
                                                ))}
                                            </select>
                                            : <Form.Label className="erp-form-label">{cmb_customer_order}</Form.Label>
                                        }
                                        <MDTypography variant="button" id="error_cmb_customer_order" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {keyForViewUpdate !== 'view' ?
                        <div className='row'>
                            <div className='text-center'>
                                <Button variant="success" className={`erp-gb-button`} onClick={FnShowCustomerStockDetails}>
                                    Show Details
                                </Button>
                            </div>

                        </div> : null}

                    <hr />

                    <div className="col-sm-12 px-lg-2 d-block">
                        <div className="col-lg-12 col-12 erp_form_col_div">
                            <div className="card">
                                <div className="card-header py-0 main_heding mb-0">
                                    <label className="erp-form-label-md-lg">Customer Stock Details</label>
                                </div>
                                {/* Card's Body Starts*/}
                                <div className='mt-1'>
                                    {renderCustMaterialStockList}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer pb-4 text-center">
                        <MDButton type="button" className="erp-gb-button ms-2" onClick={() => { navigate(`/salesorder/stocktransferlisting`) }} variant="button"
                            fontWeight="regular">Back</MDButton>
                        <MDButton type="submit" onClick={FnAddUpdateSoTransfer} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>

                    </div >
                </div>


            </DashboardLayout>

            {/* Generic Response Messaage Modals */}
            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


        </>
    )
}

export default FrmSalesOrderStockTransferEntry;
