import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Modal, Table } from "react-bootstrap";

// File Imports
import ComboBox from "Features/ComboBox";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from "FrmGeneric/FrmValidations";
import Tooltip from "@mui/material/Tooltip";

// Import React icons
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMProductCategory1Entry from "Masters/MProductCategory1/FrmProductCategory1Entry";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import { MdRefresh, MdDelete } from "react-icons/md";
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductCategory2Entry from "Masters/MProductCategory2/FrmMProductCategory2Entry";
import FrmMProductCategory3Entry from "Masters/MProductCategory3/FrmMProductCategory3Entry";
import FrmMProductCategory4Entry from "Masters/MProductCategory4/FrmMProductCategory4Entry";
import FrmMProductCategory5Entry from "Masters/MProductCategory5/FrmMProductCategory5Entry";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";
// Import for the searchable combo box.
import Select from 'react-select';

function FrmStockAdjustmentEntry() {

  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

  const { state } = useLocation();
  const { stockAdjustmentId = 0, keyForViewUpdate, compType } = state || {};

  //Current date
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  //Hooks for Options
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productCategory1Options, setProductCategory1Options] = useState([])
  const [category2Options, setCategory2Options] = useState([])
  const [maingodownOptions, setmaingodownOptions] = useState([])
  const [godownsectionOptions, setgodownsectionOptions] = useState([])
  const [godownSecBeanOptions, setgodownSecBeanOptions] = useState([])
  const [employeeOptions, setEmployeeOptions] = useState([])

  ////Hooks for Master
  const [stock_adjustment_transaction_id, setStockAdjustmentTransactionId] = useState(stockAdjustmentId);
  const [cmb_product_type_id, setproductTypeId] = useState('')
  const [cmb_product_category1_id, setProductcategory1Id] = useState('');
  const [cmb_product_category2_id, setPCategory2Id] = useState('');
  const [cmb_main_godown_id, setmaingodown_id] = useState('2');
  const [cmb_godown_section_id, setgodown_section_id] = useState('2');
  const [cmb_godown_sec_beam_id, setgodown_sec_beam_id] = useState('');
  const [dt_stock_adjustment_date, setStockAdjustmentDate] = useState(today);
  const [cmb_adjustment_by, setAdjustmentBy] = useState('');
  const [cmb_approved_by, setApprovedBy] = useState('');
  const [txt_adjustment_reason, setAdjustmentReason] = useState('');


  const [search_material_id, setSearchMaterialId] = useState('');
  const [search_material_name, setSearchMaterialName] = useState('');
  //loader
  const [isLoading, setIsLoading] = useState(false);

  ////Hooks for Details 
  const [stockAdjustmentTableDetails, setStockAdjustmentTableDetails] = useState([]);
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')
  const [action_Label, setActionLabel] = useState(keyForViewUpdate);
  const [by_material, setByMaterial] = useState(false);

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    const path = compType === 'Register' ? '/Masters/MStockAdjustment/MStockAdjustmentListing' : '/Masters/MStockAdjustment/MStockAdjustmentListing';
    navigate(path);
  }

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

   // ------------------------ Changes for the Searchable Combos. --------------------------
   let adjustmentByComboRef = useRef(null);
   let approvedByComboRef = useRef(null);
   let category1ComboRef = useRef(null);
   let category2ComboRef = useRef(null);
   
  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled={true} />;
      case 'Product Category1':
        return <FrmMProductCategory1Entry product_type={cmb_product_type_id} btn_disabled={true} />;
      case 'Product Category-2':
        return <FrmMProductCategory2Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-3':
        return <FrmMProductCategory3Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-4':
        return <FrmMProductCategory4Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-5':
        return <FrmMProductCategory5Entry product_type={cmb_product_type_id} btn_disabled={true} />
      default:
        return null;
    }
  }

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Type':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboBoxRef.current.removeCatcheFillCombo(globalQuery).then((product_Types) => {
          setProductTypeOptions(product_Types)
        })
        break;

      case 'Product Category1':
        comboOnChange('productCategory1')
        break;

      case 'Product Category-2':
        comboOnChange('productCategory1')
        break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }

  //useRef Hooks
  const comboBoxRef = useRef();
  const validate = useRef();
  const validateNumberDateInput = useRef();
  const navigate = useNavigate();

  useEffect(async () => {
    setIsLoading(true)

    await fillComobos();
    if (stock_adjustment_transaction_id !== 0) {
      await FnCheckUpdateResponse();
      $('.remove_column').remove();
    }
    setIsLoading(false)
  }, [])

  const fillComobos = async () => {

    try {
      // get product types 
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("product_type_short_name");
      globalQuery.table = "smv_product_type";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboBoxRef.current.fillFiltersCombo(globalQuery).then((product_Types) => {
        setProductTypeOptions(product_Types)
      })
      //Set Godown Options
      var getGodownApiCall = await comboBoxRef.current.fillMasterData("cmv_godown", "", "");
      setmaingodownOptions(getGodownApiCall);

      //Set Godown section Options
      var getGodownSectionApiCall = await comboBoxRef.current.fillMasterData("cmv_godown_section", "godown_id", cmb_main_godown_id);
      setgodownsectionOptions(getGodownSectionApiCall);

      //Set Godown section beans Options
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      globalQuery.conditions.push({ field: "godown_id", operator: "=", value: cmb_main_godown_id });
      globalQuery.conditions.push({ field: "godown_section_id", operator: "=", value: cmb_godown_section_id });
      globalQuery.table = "cmv_godown_section_beans";
      let getGodownSecBeanApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery)
      setgodownSecBeanOptions(getGodownSecBeanApiCall);

      //Set Employee Options by  only staff
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_employee";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: 'Worker' });
      comboBoxRef.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
        const employeeOpts = [
          { value: '', label: 'Select', },
          ...getEmployeeApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
        ];
        setEmployeeOptions(employeeOpts)
      })

    } catch (error) {
      console.log('error: ', error)
      navigate('/Error')
    }
  }

  const FnCheckUpdateResponse = async () => {
    try {
      setIsLoading(true)
      const apiCallForStockAdjustmentResp = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductStockAdjustment/FnShowParticularRecordForUpdate/${stock_adjustment_transaction_id}/${COMPANY_ID}`);
      const apiResponse = await apiCallForStockAdjustmentResp.json();

      let ProductStockAdjustmentRecord = apiResponse.ProductStockAdjustmentRecord;
      let productStockAdjustmentDetailsRecord = apiResponse.productStockAdjustmentDetailsRecord;

      ///Setting hooks for header data
      setStockAdjustmentTransactionId(ProductStockAdjustmentRecord.stock_adjustment_transaction_id);
      setStockAdjustmentDate(ProductStockAdjustmentRecord.stock_date);
      setAdjustmentBy(parseInt(ProductStockAdjustmentRecord.adjustment_by));
      setApprovedBy(parseInt(ProductStockAdjustmentRecord.approved_by));
      setAdjustmentReason(ProductStockAdjustmentRecord.adjustment_reason);

      ///Setting Details data
      setStockAdjustmentTableDetails(productStockAdjustmentDetailsRecord);

      ///Disabling
      await validate.current.readOnly('stockAdjustmentFormId');
      $('.hide_col').hide();
      $('.erp_input_field_table_txt').attr('disabled', true);
      $('#save_Button, #show_button').hide();
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        let productTpVal = document.getElementById('cmb_product_type_id').value;
        setproductTypeId(productTpVal)
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Type')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({
              "padding-top": "0px"
            });
          }, 200)
        } else if (productTpVal !== '0' && productTpVal !== '') {
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name'];
          globalQuery.table = "smv_product_category1";
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
          const productCategory1ApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
          const productCat1Opts = [
            { value: '', label: 'Select' },
            ...productCategory1ApiCall.map(item => ({
              value: item.field_id,
              label: item.field_name,
            }))
          ];
          setProductCategory1Options(productCat1Opts);
          setCategory2Options([])
          setPCategory2Id('')
        } else {
          setProductCategory1Options([])
          setCategory2Options([])
          setProductcategory1Id('')
          setPCategory2Id('')
        }
        break;

      case 'productCategory1':
        // var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        var productCat1Val = category1ComboRef.current.value;
        setProductcategory1Id(productCat1Val)
        if (productCat1Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category1')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        } else if (productCat1Val !== '0' && productCat1Val !== '') {
          resetGlobalQuery();
          globalQuery.columns = ["field_id", "field_name"];
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
          globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
          globalQuery.table = "smv_product_category2";
          var productCategory2ApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery)
          const productCat2Opts = [
            { value: '', label: 'Select' },
            ...productCategory2ApiCall.map(item => ({
              value: item.field_id,
              label: item.field_name,
            }))
          ];
          setCategory2Options(productCat2Opts)
          setPCategory2Id('')
        } else {
          setCategory2Options([])
          setPCategory2Id('')
        }

        break;

      case 'productCategory2':
        // var productCat2Val = document.getElementById('cmb_product_category2_id').value;
        var productCat2Val = category2ComboRef.current.value;
        if (productCat2Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category-2')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        } else {
          setPCategory2Id(productCat2Val)
        }
        break;

      case 'maingodownid':
        var godownMainVal = document.getElementById('cmb_main_godown_id').value;
        setmaingodown_id(godownMainVal)
        if (godownMainVal !== '') {
          if (godownMainVal === '0') {
            const newTab = window.open('/Masters/Godown', '_blank');
            if (newTab) {
              newTab.focus();
            }
          } else {
            var getGodownSectionApiCall = await comboBoxRef.current.fillMasterData("cmv_godown_section", "godown_id", godownMainVal);
            setgodownsectionOptions(getGodownSectionApiCall);
          }
        } else {
          setgodown_section_id('')
          setgodown_sec_beam_id('')
          setgodownsectionOptions([])
          setgodownSecBeanOptions([])
        }

        break;

      case 'godownSecId':
        var godownMainidVal = document.getElementById('cmb_main_godown_id').value;
        var godownsectionval = document.getElementById('cmb_godown_section_id').value;
        setgodown_section_id(godownsectionval)
        if (godownsectionval !== '') {
          if (godownsectionval === '0') {
            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            localStorage.setItem('GoDownIDDetails', JSON.stringify({ GoDownID: godownMainidVal, keyForViewUpdate: 'update', compType: 'Master' }))
            const newTab = window.open('/Masters/Godown', '_blank');
            if (newTab) {
              newTab.focus();
            }
          } else {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "godown_id", operator: "=", value: godownMainidVal });
            globalQuery.conditions.push({ field: "godown_section_id", operator: "=", value: godownsectionval });
            globalQuery.table = "cmv_godown_section_beans";
            let getGodownSecBeanApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery)
            setgodownSecBeanOptions(getGodownSecBeanApiCall);
          }
        } else {
          setgodownSecBeanOptions([])
          setgodown_sec_beam_id('')
        }
        break;

      case 'godownSecBeanId':
        var godownSecBeanIdVal = document.getElementById('cmb_godown_sec_beam_id').value;
        var godownMainidVal = document.getElementById('cmb_main_godown_id').value;
        var godownsectionval = document.getElementById('cmb_godown_section_id').value;

        if (godownSecBeanIdVal === '0') {
          localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          localStorage.setItem('GoDownIDDetails', JSON.stringify({ GoDownID: godownMainidVal, godown_section_id: godownsectionval, keyForViewUpdate: 'update', compType: 'Master' }))
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        } else {
          setgodown_sec_beam_id(godownSecBeanIdVal)
        }
        break;
    }
  }

  //Validation starts here
  const validateFields = async () => {
    await validate.current.validateFieldsOnChange('stockAdjustmentFormId');
  }
  // fn to remove material
  const removeMaterial = (indexToRemove, setStateCallback) => {
    if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= stockAdjustmentTableDetails.length) {
      console.error("Invalid index provided.");
      return;
    }
    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
      // Create a new array excluding the element at indexToRemove
      const updatedDetailsData = stockAdjustmentTableDetails.filter((_, index) => index !== indexToRemove);
      // Update state using the provided callback
      setStateCallback(updatedDetailsData);
    } else {
      console.error("Cannot remove material in 'approve' or 'view' mode.");
    }
  }
  const renderStockAdjustmentTable = useMemo(() => {
    return <Table id="stockadjustmenttableId" className="erp-table-scroll" style={{ display: "block", overflow: "auto", height: stockAdjustmentTableDetails.length > 6 ? '300px' : 'auto' }} bordered striped>
      <thead className="erp_table_head">
        <tr>
          <td className={`erp_table_th ${keyForViewUpdate === 'Add' ? 'display' : 'd-none'}`} style={{ width: "auto" }}>Action</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Sr. No</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Material Code</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Material Name</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Stock Qty.</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Stock Wt.</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Opening Stock Qty.</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Opening Stock Wt.</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Operation<br />Type</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Adjustment<br /> Qantity</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Adjustment <br />Weight</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Closing<br /> Balance(Qty.)</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Closing<br /> Balance(Wt.)</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Godown</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Godown Section</td>
          <td className="erp_table_th " style={{ width: "auto" }}>Godown Section Beans </td>
        </tr>
      </thead>
      <tbody>
        {stockAdjustmentTableDetails?.length > 0 ? (
          stockAdjustmentTableDetails.map((stockDetail, index) => (
            <tr rowindex={index}>
              <td className={`erp_table_td ${keyForViewUpdate === 'Add' ? 'display' : 'd-none'}`} >
                <div style={{ display: "flex" }}>
                  <Tooltip title="Delete" placement="top">
                    <MDTypography className="erp-view-btn">
                      <MdDelete className="erp-delete-btn" id={`delete_btn_${index}`} onClick={() => removeMaterial(index, setStockAdjustmentTableDetails)} />
                    </MDTypography>
                  </Tooltip>
                </div>
              </td>
              <td className="erp_table_td text-end" >{index + 1} </td>
              <td className="erp_table_td">{stockDetail.product_material_code}</td>
              <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                {stockDetail.product_material_name}
              </td>
              <td className="erp_table_td text-end">
                {stockDetail.stock_quantity}
              </td>

              <td className="erp_table_td text-end">
                {stockDetail.stock_weight}
              </td>
              <td className="erp_table_td text-end">
                {stockDetail.opening_quantity}
              </td>
              <td className="erp_table_td text-end">
                {stockDetail.opening_weight}
              </td>
              <td className="erp_table_td">
                <select
                  id={`stock_adjustment_type_${index}`} style={{ width: '80px', backgroundColor: '#AFE1AF' }}
                  className="erp_input_field_table_txt rfdetail-disable"
                  value={stockDetail.stock_adjustment_type}
                  onChange={(e) => calculateStockDetailTblRow(e, stockDetail)}
                  Headers='stock_adjustment_type'
                >
                  <option style={{ backgroundColor: 'white' }} value='Add'>Add</option>
                  <option style={{ backgroundColor: 'white' }} value='Reduce'>Reduce</option>
                </select>
              </td>

              <td className="erp_table_td">
                <input
                  type="text" style={{ width: '80px', backgroundColor: '#AFE1AF' }}
                  className="erp_input_field_table_txt mb-0 text-end"
                  id={`stock_adjustment_quantity_${index}`}
                  onInput={(e) => calculateStockDetailTblRow(e, stockDetail)}
                  value={stockDetail.stock_adjustment_quantity} Headers='stock_adjustment_quantity'
                />
              </td>

              <td className="erp_table_td">
                <input
                  type="text" style={{ width: '80px', backgroundColor: '#AFE1AF' }}
                  className="erp_input_field_table_txt mb-0 text-end rfdetail-disable"
                  id={`stock_adjustment_weight_${index}`}
                  onInput={(e) => calculateStockDetailTblRow(e, stockDetail)}
                  value={stockDetail.stock_adjustment_weight}
                  Headers='stock_adjustment_weight'
                />
              </td>

              <td className="erp_table_td text-end">
                {stockDetail.closing_balance_quantity}
              </td>

              <td className="erp_table_td text-end">
                {stockDetail.closing_balance_weight}
              </td>

              <td className="erp_table_td">
                {stockDetail.godown_name}
              </td>
              <td className="erp_table_td">{stockDetail.godown_section_name} </td>
              <td className="erp_table_td">{stockDetail.godown_section_beans_name} </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="erp_table_th text-center" colSpan={16}>No Data Found</td>
          </tr>
        )}
      </tbody>
    </Table >

  }, [stockAdjustmentTableDetails]);

  const calculateStockDetailTblRow = (event, currentRow) => {
    try {
      setIsLoading(true)
      let clickedColName = event.target.getAttribute('Headers');
      let eventId = document.getElementById(event.target.id);
      let enteredValue = (event.target.value);

      if (clickedColName !== 'stock_adjustment_type') {
        if (isNaN(enteredValue)) {
          enteredValue = 0;
        } else if (parseFloat(enteredValue) !== 0 || parseFloat(enteredValue) !== "") {
          delete eventId.parentElement.dataset.tip;
        }
      }

      if (enteredValue === "") {
        enteredValue = 0;
      }

      let stockAdjustTblDetails = [...stockAdjustmentTableDetails];
      const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))

      let stock_Quantity = parseFloat(currentRow['stock_quantity']);
      let stock_Weight = parseFloat(currentRow['stock_weight']);
      let opening_quantity = parseFloat(currentRow['opening_quantity']);
      let opening_weight = parseFloat(currentRow['opening_weight']);

      switch (clickedColName) {
        case 'stock_adjustment_type':

          if (enteredValue === 'Reduce') {
            if (opening_quantity >= currentRow['stock_adjustment_quantity'] && opening_quantity !== '0' && opening_weight >= currentRow['stock_adjustment_weight']) {
              currentRow[clickedColName] = enteredValue;
              currentRow['closing_balance_quantity'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Quantity - parseFloat(currentRow['stock_adjustment_quantity'])), 4);
              currentRow['closing_balance_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Weight - parseFloat(currentRow['stock_adjustment_weight'])), 4);
            } else {
              currentRow['stock_adjustment_quantity'] = 0
              currentRow['stock_adjustment_weight'] = 0;
              currentRow['closing_balance_quantity'] = 0;
              currentRow['closing_balance_weight'] = 0;
            }
          } else {
            currentRow[clickedColName] = enteredValue;
            currentRow['closing_balance_quantity'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Quantity + parseFloat(currentRow['stock_adjustment_quantity'])), 4);
            currentRow['closing_balance_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Weight + parseFloat(currentRow['stock_adjustment_weight'])), 4);
          }
          break;
        case 'stock_adjustment_quantity':
          if (currentRow['stock_adjustment_type'] === 'Reduce') {
            if (parseFloat(enteredValue) <= opening_quantity || (enteredValue) === '') {
              currentRow[clickedColName] = enteredValue;
              if (currentRow['product_material_std_weight']) {
                currentRow.stock_adjustment_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(currentRow[clickedColName]) * parseFloat(currentRow['product_material_std_weight'])), 4);
                currentRow['closing_balance_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Weight - parseFloat(currentRow.stock_adjustment_weight)), 4);
              }
              currentRow['closing_balance_quantity'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Quantity - parseFloat(enteredValue)), 4);
            } else {
              eventId.parentElement.dataset.tip = 'Adjustment Quantity cannot be greater than Stock Quantity';
            }
          } else {
            currentRow[clickedColName] = enteredValue;
            if (currentRow['product_material_std_weight']) {
              currentRow.stock_adjustment_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(currentRow[clickedColName]) * parseFloat(currentRow['product_material_std_weight'])), 4);
              currentRow['closing_balance_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Weight + parseFloat(currentRow.stock_adjustment_weight)), 4);
            }
            currentRow['closing_balance_quantity'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Quantity + parseFloat(enteredValue)), 4);
          }

          break;
        case 'stock_adjustment_weight':

          if (currentRow['stock_adjustment_type'] === 'Reduce') {
            if (parseFloat(enteredValue) <= opening_weight || (enteredValue) === '') {
              currentRow[clickedColName] = enteredValue;
              currentRow['closing_balance_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Weight - parseFloat(enteredValue)), 4);
            } else {
              eventId.parentElement.dataset.tip = 'Adjustment Weight cannot be greater than Stock Weight';
            }
          } else {
            currentRow[clickedColName] = enteredValue;
            currentRow['closing_balance_weight'] = validateNumberDateInput.current.decimalNumber(JSON.stringify(stock_Weight + parseFloat(enteredValue)), 4);
          }
          break;
        
      }

      stockAdjustTblDetails[arrayIndex] = currentRow;
      setStockAdjustmentTableDetails(stockAdjustTblDetails);
    } catch (error) {

    } finally {
      setIsLoading(false)
    }

  }
  const handleCloseBomFilterForm = async (selectedData) => {

    if (selectedData !== null) {
      try {

        setIsLoading(true)
        let { product_id, product_name, product_code } = selectedData[0];

        let material_name = product_name;
        let material_code = product_code;
        let material_id = product_id;
        console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
        setSearchMaterialId(product_id)
        // setSearchMaterialName(product_name)
        await showStockDetails(product_id);
      } catch (error) {
        console.error("Error in Search Material: ", error);
        sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
        setIsLoading(false)
      } finally {
        setIsLoading(false)
        sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
      }
    } else {
      setSearchMaterialId('')
      // setSearchMaterialName('')
      setStockAdjustmentTableDetails([]);
      sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
    }
  };



  const showStockDetails = async (material_id) => {
    debugger
    try {
      setIsLoading(true)
      let productTypeId = $('#cmb_product_type_id option:selected').val();

      if (productTypeId !== "" && productTypeId !== "0") {
        // let category1 = $("#cmb_product_category1_id option:selected").val();
        // let category2 = $("#cmb_product_category2_id option:selected").val();
        let category1 = category1ComboRef.current.value || "";
        let category2 = category2ComboRef.current.value || "";
        resetGlobalQuery()
        globalQuery.columns = [
          "rm_fg.product_material_code",
          "rm_fg.product_material_id",
          "rm_fg.product_material_name",
          'rm_fg.product_material_std_weight',
          "sm.closing_balance_quantity as stock_quantity",
          "sm.closing_balance_weight  as stock_weight",
          "sm.godown_name",
          "sm.godown_section_name",
          "sm.godown_section_beans_name",
          "sm.godown_id",
          "sm.godown_section_id",
          "sm.godown_section_beans_id",
          "sm.stock_transaction_id",
          "st.goods_receipt_no",
          "st.closing_balance_quantity",
          "st.closing_balance_weight",
          // "sm.batch_expiry_date",
        ];
        globalQuery.table = "smv_product_rm_fg_sr rm_fg"
        globalQuery.joins.push({
          "table": "smv_product_rm_stock_summary sm",
          "type": "LEFT",
          "on": [
            {
              "left": "sm.product_rm_id",
              "right": "rm_fg.product_material_id"
            },
          ]
        })
        globalQuery.joins.push({
          "table": "sm_product_rm_stock_details st",
          "type": "LEFT",
          "on": [
            {
              "left": "st.product_rm_id",
              "right": "rm_fg.product_material_id"
            },
            {
              "left": "st.goods_receipt_no",
              "right": "'Opening Balance'"
            },
            {
              "left": "st.day_closed",
              "right": "0"
            }
          ]
        })

        globalQuery.conditions.push({ field: "rm_fg.is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "rm_fg.product_type_id", operator: "=", value: productTypeId });

        if (category1 !== 0 && category1 !== "") {
          globalQuery.conditions.push({ field: "rm_fg.product_material_category1_id", operator: "=", value: category1 });
        }
        if (category2 !== 0 && category2 !== "") {
          globalQuery.conditions.push({ field: "rm_fg.product_material_category2_id", operator: "=", value: category2 });
        }
        if (cmb_main_godown_id !== 0 && cmb_main_godown_id !== "") {
          globalQuery.conditions.push({ field: "sm.godown_id", operator: "=", value: cmb_main_godown_id });
        }
        if (cmb_godown_section_id !== 0 && cmb_godown_section_id !== "") {
          globalQuery.conditions.push({ field: "sm.godown_section_id", operator: "=", value: cmb_godown_section_id });
        }
        if (cmb_godown_sec_beam_id !== 0 && cmb_godown_sec_beam_id !== "") {
          globalQuery.conditions.push({ field: "sm.godown_section_beans_id", operator: "=", value: cmb_godown_sec_beam_id });
        }
        if (material_id === undefined && search_material_id !== "") {
          globalQuery.conditions.push({ field: "rm_fg.product_material_id", operator: "=", value: search_material_id });
        } else if (material_id !== undefined && material_id !== "") {
          globalQuery.conditions.push({ field: "rm_fg.product_material_id", operator: "=", value: material_id });
        }
        globalQuery.orderBy = ["sm.product_rm_id"];
        let showStockDetailsApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
        let stockAdjustmentDetailsArray = [];
        for (let index = 0; index < showStockDetailsApiCall.length; index++) {
          const element = showStockDetailsApiCall[index];
          let stockAdjustmentDetailJson = {};
          stockAdjustmentDetailJson['company_id'] = COMPANY_ID;
          stockAdjustmentDetailJson['company_branch_id'] = COMPANY_BRANCH_ID;
          stockAdjustmentDetailJson['financial_year'] = FINANCIAL_SHORT_NAME;
          stockAdjustmentDetailJson['godown_id'] = element.godown_id;
          stockAdjustmentDetailJson['godown_section_id'] = element.godown_section_id;
          stockAdjustmentDetailJson['godown_section_beans_id'] = element.godown_section_beans_id;
          stockAdjustmentDetailJson['goods_receipt_no'] = element.goods_receipt_no;
          stockAdjustmentDetailJson['spinning_production_rf_details_id'] = 0;
          stockAdjustmentDetailJson['product_material_id'] = element.product_material_id;
          stockAdjustmentDetailJson['product_material_code'] = element.product_material_code;
          stockAdjustmentDetailJson['product_material_name'] = element.product_material_name;
          stockAdjustmentDetailJson['product_material_std_weight'] = element.product_material_std_weight;
          stockAdjustmentDetailJson['stock_adjustment_type'] = 'Add';
          stockAdjustmentDetailJson['stock_quantity'] = element.stock_quantity === null ? 0 : element.stock_quantity;
          stockAdjustmentDetailJson['stock_weight'] = element.stock_weight === null ? 0 : element.stock_weight;
          stockAdjustmentDetailJson['opening_quantity'] = element.closing_balance_quantity === null ? 0 : element.closing_balance_quantity;
          stockAdjustmentDetailJson['opening_weight'] = element.closing_balance_weight === null ? 0 : element.closing_balance_weight;
          stockAdjustmentDetailJson['stock_adjustment_quantity'] = 0;
          stockAdjustmentDetailJson['stock_adjustment_weight'] = 0;
          stockAdjustmentDetailJson['closing_balance_weight'] = element.closing_balance_weight === null ? 0 : element.closing_balance_weight;
          stockAdjustmentDetailJson['closing_balance_quantity'] = element.closing_balance_quantity === null ? 0 : element.closing_balance_quantity;
          stockAdjustmentDetailJson['godown_name'] = element.godown_name;
          stockAdjustmentDetailJson['godown_section_name'] = element.godown_section_name;
          stockAdjustmentDetailJson['godown_section_beans_name'] = element.godown_section_beans_name;
          stockAdjustmentDetailJson['prev_godown_section_id'] = element.godown_section_id;
          stockAdjustmentDetailJson['prev_godown_section_beans_id'] = element.godown_section_beans_id;
          stockAdjustmentDetailJson['batch_expiry_date'] = element.batch_expiry_date;
          stockAdjustmentDetailJson['product_type_id'] = cmb_product_type_id;

          stockAdjustmentDetailsArray.push(stockAdjustmentDetailJson);
        }
        if (by_material) {
          setStockAdjustmentTableDetails(prevStockArray => [
            ...prevStockArray,
            ...stockAdjustmentDetailsArray
          ]);

        } else {
          setStockAdjustmentTableDetails(stockAdjustmentDetailsArray);
        }

      } else {
        $('#error_cmb_product_type_id').text('please select atleast one...!');
        $('#error_cmb_product_type_id').show();
        $('#cmb_product_type_id').focus();
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsLoading(false)
    }

  }

  $(document).on('click', '.erp_input_field_table_txt', function () {
    if ($(this).val() === "0") {
      $(this).val("")
    }
  });
  $(document).on('mouseup mousedown', function (e) {
    let inputBoxes = $(".erp_input_field_table_txt");
    inputBoxes.each(function () {
      if ($(this).val() === "") {
        $(this).val("0");
      }
    });
  });

  const saveStockAdjDetails = async () => {

    let validateRfHeaderForm = await validate.current.validateForm('stockAdjustmentFormId');

    if (cmb_adjustment_by === '0' || cmb_adjustment_by === '') {
      $("#error_cmb_adjustment_by").text("Please fill this field...!");
      $("#error_cmb_adjustment_by").show();
      $("#cmb_adjustment_by").focus();
      validateRfHeaderForm = false;
      return false;
    }
    if (cmb_approved_by === '0' || cmb_approved_by === '') {
      $("#error_cmb_approved_by").text("Please fill this field...!");
      $("#error_cmb_approved_by").show();
      $("#cmb_approved_by").focus();
      validateRfHeaderForm = false;
      return false;
    }
    if (validateRfHeaderForm === true) {
      try {
        setIsLoading(true)
        let stockAdjustmentDetailsJson = {
          StockAdjustmentMaster: {}, StockAdjustmentDetails: [], 'commonIds': {
            'company_id': COMPANY_ID, stock_adjustment_transaction_id: 0
          }
        }

        ///Stock Production Header Data
        const stockAdjustmentHeaderData = {
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          stock_adjustment_transaction_id: stockAdjustmentId !== undefined ? stockAdjustmentId : 0,
          stock_date: dt_stock_adjustment_date,
          adjustment_by: cmb_adjustment_by,
          adjustment_reason: txt_adjustment_reason,
          approved_by: cmb_approved_by,
          created_by: UserName,
          modified_by: UserName
        }
        stockAdjustmentDetailsJson.StockAdjustmentMaster = stockAdjustmentHeaderData;


        ///Production Stoppage Details  ///Removing unnecessary Keys
        let stockAdjustmentDetails = [...stockAdjustmentTableDetails];

        for (let index = 0; index < stockAdjustmentDetails.length; index++) {

          let element = stockAdjustmentDetails[index];
          if (element.stock_adjustment_quantity > 0) {
            let jsonStockAdjustmentDetails = {};
            jsonStockAdjustmentDetails.company_id = COMPANY_ID;
            jsonStockAdjustmentDetails.company_branch_id = COMPANY_BRANCH_ID;
            jsonStockAdjustmentDetails.financial_year = FINANCIAL_SHORT_NAME;
            jsonStockAdjustmentDetails.godown_id = element.godown_id;
            jsonStockAdjustmentDetails.godown_section_beans_id = element.godown_section_beans_id;
            jsonStockAdjustmentDetails.godown_section_id = element.godown_section_id;
            jsonStockAdjustmentDetails.goods_receipt_no = element.goods_receipt_no;
            jsonStockAdjustmentDetails.stock_adjustment_details_transaction_id = 0;
            jsonStockAdjustmentDetails.stock_adjustment_transaction_id = 0;
            jsonStockAdjustmentDetails.product_material_id = element.product_material_id;
            jsonStockAdjustmentDetails.stock_adjustment_type = element.stock_adjustment_type;
            jsonStockAdjustmentDetails.stock_quantity = element.stock_quantity;
            jsonStockAdjustmentDetails.stock_weight = element.stock_weight;
            jsonStockAdjustmentDetails.opening_quantity = element.opening_quantity;
            jsonStockAdjustmentDetails.opening_weight = element.opening_weight;
            jsonStockAdjustmentDetails.stock_adjustment_quantity = element.stock_adjustment_quantity;
            jsonStockAdjustmentDetails.stock_adjustment_weight = element.stock_adjustment_weight;
            jsonStockAdjustmentDetails.closing_balance_quantity = element.closing_balance_quantity;
            jsonStockAdjustmentDetails.closing_balance_weight = element.closing_balance_weight;
            jsonStockAdjustmentDetails.stock_date = dt_stock_adjustment_date;
            jsonStockAdjustmentDetails.created_by = UserName;
            jsonStockAdjustmentDetails.modified_by = UserName;
            jsonStockAdjustmentDetails.prev_godown_section_id = element.prev_godown_section_id;
            jsonStockAdjustmentDetails.prev_godown_section_beans_id = element.prev_godown_section_beans_id;
            jsonStockAdjustmentDetails.product_type_id = cmb_product_type_id;

            stockAdjustmentDetailsJson.StockAdjustmentDetails.push(jsonStockAdjustmentDetails);
          }
        }

        const formData = new FormData();
        formData.append(`requestStockAdjustment`, JSON.stringify(stockAdjustmentDetailsJson))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        try {
          const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductStockAdjustment/FnAddUpdateRecord`, requestOptions)
          const apiResponse = await apiCall.json();

          if (apiResponse.success === 1) {
            setSuccMsg(apiResponse.message);
            setShowSuccessMsgModal(true);
          } else if (apiResponse.success === 0) {
            setErrMsg(apiResponse.error)
            setShowErrorMsgModal(true);
          }
        } catch (error) {
          console.log("error", error)
        }
      } catch (error) {
        // navigate('/Error');
      } finally {
        setIsLoading(false)
      }
    }
  }

  // product filter columns
  let requiredCols = ['product_id', 'product_name', 'product_code'];

  return (
    <>
      <ComboBox ref={comboBoxRef} />
      <FrmValidations ref={validate} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <DashboardLayout>

        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span id="spinner_text" className="text-dark">Loading...</span>
            </div>
          </div> :
          null}

        <div className='card-header text-center py-0'>
          <label className='erp-form-label-lg text-center'>{`Stock Adjustment (${action_Label})`} </label>
        </div>

        <form id="stockAdjustmentFormId">

          <div className='row'>
            {/* first */}
            <div className='col-sm-6 erp_form_col_div remove_column'>
              <div className='row hide_col'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Product Type : <span className="required">*</span></Form.Label>
                </div>
                <div className='col'>
                  <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={(e) => { comboOnChange('productType'); validateFields(); }}>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {productTypeOptions?.map(productType => (
                      <option value={productType.field_id} ptShortName={productType.product_type_short_name}>{productType.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row hide_col'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Product Category 1 : </Form.Label>
                </div>
                <div className='col'>
                  <Select ref={category1ComboRef}
                    options={productCategory1Options}
                    isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                    inputId="cmb_product_category1_id" // Provide the ID for the input box
                    value={productCategory1Options.find(option => option.value === cmb_product_category1_id) || null}
                    onChange={(selectedOpt) => {
                      setProductcategory1Id(selectedOpt.value);
                      category1ComboRef.current = selectedOpt;
                      comboOnChange('productCategory1');
                      validateFields();
                    }}
                    placeholder="Search for category 1..."
                    className="form-search-custom"
                    classNamePrefix="custom-select" // Add custom prefix for class names
                    styles={{
                      option: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      singleValue: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      input: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      })
                    }}
                  />
                  <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row hide_col' >
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label" id="cat1Label"> Product Category 2</Form.Label>
                </div>
                <div className='col'>
                  <Select ref={category2ComboRef}
                    options={category2Options}
                    isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                    inputId="cmb_product_category2_id" // Provide the ID for the input box
                    value={category2Options.find(option => option.value === cmb_product_category2_id) || null}
                    onChange={(selectedOpt) => {
                      setPCategory2Id(selectedOpt.value);
                      category2ComboRef.current = selectedOpt;
                      comboOnChange('productCategory2');
                      validateFields();
                    }}
                    placeholder="Search for category 2..."
                    className="form-search-custom"
                    classNamePrefix="custom-select" // Add custom prefix for class names
                    styles={{
                      option: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      singleValue: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      input: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      })
                    }}
                  />
                  {/* <select id="cmb_product_category2_id" className="form-select form-select-sm" value={cmb_product_category2_id} onChange={(e) => { comboOnChange('productCategory2'); validateFields() }} optional='optional'>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {category2Options?.map((category2) => (
                      <option value={category2.field_id}>{category2.field_name}</option>
                    ))}
                  </select> */}
                  <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className='row hide_col'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Godown & Section: </Form.Label>
                </div>
                <div className='col-sm-4'>
                  <select id="cmb_main_godown_id" className="form-select form-select-sm" value={cmb_main_godown_id} onChange={(e) => { comboOnChange('maingodownid'); validateFields(); }} optional='optional'>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {maingodownOptions?.map(maingodownOption => (
                      <option value={maingodownOption.field_id}>{maingodownOption.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_main_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} optional='optional'>
                  </MDTypography>
                </div>
                <div className='col-sm-4'>
                  <select id="cmb_godown_section_id" className="form-select form-select-sm" value={cmb_godown_section_id} onChange={(e) => { comboOnChange('godownSecId'); validateFields(); }} optional='optional'>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {godownsectionOptions?.map(godownsectionOption => (
                      <option value={godownsectionOption.field_id} >{godownsectionOption.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_godown_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>

              </div>
              <div className='row hide_col'>
                <div className='col-sm-4'>
                  <Form.Label className="erp-form-label">Godown Section Beans : </Form.Label>
                </div>
                <div className='col'>
                  <select id="cmb_godown_sec_beam_id" className="form-select form-select-sm" value={cmb_godown_sec_beam_id} onChange={(e) => { comboOnChange('godownSecBeanId'); validateFields(); }} optional='optional'>
                    <option value="">Select</option>
                    <option value="0">Add New Record+</option>
                    {godownSecBeanOptions?.map(godownSecBeanOptions => (
                      <option value={godownSecBeanOptions.field_id} >{godownSecBeanOptions.field_name}</option>
                    ))}
                  </select>
                  <MDTypography variant="button" id="error_cmb_godown_sec_beam_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
            </div>

            {/* second */}
            <div className='col-sm-6 erp_form_col_div'>
              <div className="row">
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Adjustment Date</Form.Label>
                </div>
                <div className="col">
                  <Form.Control type="date" id='dt_stock_adjustment_date' className="erp_input_field" value={dt_stock_adjustment_date} onChange={(e) => { setStockAdjustmentDate(e.target.value); validateFields() }} />
                  <MDTypography variant="button" id="error_dt_stock_adjustment_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Adjustment By <span className="required">*</span></Form.Label>
                </div>
                <div className="col">
                  <Select ref={adjustmentByComboRef}
                    options={employeeOptions}
                    isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                    inputId="cmb_adjustment_by" // Provide the ID for the input box
                    value={employeeOptions.find(option => option.value === cmb_adjustment_by) || null}
                    onChange={(selectedOpt) => {
                      setAdjustmentBy(selectedOpt.value);
                      adjustmentByComboRef.current = selectedOpt;
                      validateFields();
                    }}
                    placeholder="Search for adjustment by..."
                    className="form-search-custom"
                    classNamePrefix="custom-select" // Add custom prefix for class names
                    styles={{
                      option: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      singleValue: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      input: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      })
                    }}
                  />
                  <MDTypography variant="button" id="error_cmb_adjustment_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Approved By <span className="required">*</span></Form.Label>
                </div>
                <div className="col">
                  <Select ref={approvedByComboRef}
                    options={employeeOptions}
                    isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                    inputId="cmb_approved_by" // Provide the ID for the input box
                    value={employeeOptions.find(option => option.value === cmb_approved_by) || null}
                    onChange={(selectedOpt) => {
                      setApprovedBy(selectedOpt.value);
                      adjustmentByComboRef.current = selectedOpt;
                      validateFields();
                    }}
                    placeholder="Search for Approve by..."
                    className="form-search-custom"
                    classNamePrefix="custom-select" // Add custom prefix for class names
                    styles={{
                      option: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      singleValue: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      }),
                      input: (provided, state) => ({
                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                      })
                    }}
                  />
                  <MDTypography variant="button" id="error_cmb_approved_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <Form.Label className="erp-form-label">Approved Reason<span className="required">*</span> </Form.Label>
                </div>
                <div className="col">
                  <Form.Control as="textarea" rows={1} id="txt_adjustment_reason" className="erp_input_field form-control" maxlength="255" value={txt_adjustment_reason} onInput={() => validateFields()} onChange={(e) => setAdjustmentReason(e.target.value)} />
                  <MDTypography variant="button" id="error_txt_adjustment_reason" className="erp_validation mb-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                  </MDTypography>
                </div>
              </div>

            </div>
          </div>
        </form>
        <div className={`erp_frm_Btns ${by_material ? 'd-none' : 'display'}`}>
          <MDButton type="button" id="show_button" className="erp-gb-button ms-2" variant="button"
            onClick={() => showStockDetails()} fontWeight="regular">Show</MDButton>
        </div >
        <hr />
        <div className={`row mt-2 ${keyForViewUpdate === 'Add' ? 'display' : 'd-none'}`}>
          <div className="col-sm-3 col-12">
            <input type='checkbox' class="me-1" name="selectMaterialRecords" id="selectMaterialRecords" onChange={(e) => { e.target.checked ? setByMaterial(true) : setByMaterial(false); setSearchMaterialId(''); }} /> <label class="erp-form-label pb-1">Search By Material </label>
          </div>
          <div className={`col-sm-3 col-12 ${by_material ? 'display' : 'd-none'}`}>
            <MaterialSearchBar getSearchData={handleCloseBomFilterForm} formIdForValidate="" requiredCols={requiredCols} product_type_id={2} />
          </div>
          <div className={`col text-end`}>
            <label class="erp-form-label" id="stock_adjust_note" style={{ color: "red" }} >(Note: The stock will be adjusted from the opening balance only.)</label>
          </div>
        </div>
        <div className='card-header text-start py-0'>
          <label className='erp-form-label-md text-center'>Stock Adjustment Details </label>
        </div>
        <div className='row mt-1'>
          {renderStockAdjustmentTable}
        </div>

        <div className="erp_frm_Btns">
          <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Masters/MStockAdjustment/MStockAdjustmentListing')}>Back</MDButton>
          <MDButton type="submit" id="save_Button" className="erp-gb-button ms-2 view_hide" variant="button"
            onClick={() => saveStockAdjDetails()} fontWeight="regular">Save</MDButton>
          {/* <MDButton className="erp-gb-button ms-2" style={{ display: keyForViewUpdate === 'delete' ? 'inline' : 'none' }}
            variant="button" fontWeight="regular" onClick={() => deleteshow()}> Delete</MDButton> */}
        </div >
      </DashboardLayout>
      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      {/* Add new Record Popup */}
      {
        showAddRecModal ?
          <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
            </Modal.Header>
            <Modal.Body className='erp_city_modal_body'>
              {displayRecordComponent()}
            </Modal.Body>
            <Modal.Footer>
              <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                fontWeight="regular">Close</MDButton>
            </Modal.Footer>
          </Modal > : null
      }
    </>
  )
}

export default FrmStockAdjustmentEntry
