import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'


export default function SalarySlipsRegister() {

    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, FINANCIAL_SHORT_NAME, COMPANY_NAME, } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // Some Common Data Hooks.
    const [employeeProps, setEmployeeProps] = useState([]);         // Storing employee's type and group.
    const [allEarningOpts, setAllEarningOpts] = useState([]);       // Storing all earnings.
    const [allDeductionOpts, setAllDeductionOpts] = useState([]);   // Storing all deductions.

    // combo's options hooks;
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );

    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);   // For storing all department and sub-departments.
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [employeeOpts, setEmployeeOpts] = useState([]);

    // Input field's Hooks;
    const [cmb_year, setYear] = useState('');
    const [cmb_month, setMonth] = useState('');
    const [cmb_employee_type_id, setEmployeeTypeId] = useState('');
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_sub_department_id, setSubDepartmentId] = useState('');
    const [cmb_employee_id, setEmployeeId] = useState('');

    // Hooks For table;
    const [employeeInfoDetailsData, setEmployeeInfoDetailsData] = useState([]);
    const [attendanceDetails, setAttendanceDetails] = useState([]);
    const [salarySummaryDetails, setSalarySummaryDetails] = useState([]);
    const [salaryEarningDetails, setSalaryEarningDetails] = useState([]);
    const [salaryDeductionDetails, setSalaryDeductionDetails] = useState([]);

    // UseRefs Hooks For Searchable combos.
    let monthComboRef = useRef(null);
    let yearComboRef = useRef(null);
    let employeeTypesComboRef = useRef(null);
    let departmentComboRef = useRef(null);
    let subDepartmentComboRef = useRef(null);
    let employeeComboRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillCombos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillCombos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setYear(defaultYear.value);
            yearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setMonth(defaultMonth.value);
            monthComboRef.current = defaultMonth;

            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "property_value", "property_group", "properties_master_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    setEmployeeProps(rcvdEmplTypes);
                    let emplTypes = rcvdEmplTypes
                        .filter(prop => prop.properties_master_name === 'EmployeeType')
                        .map(prop => ({ ...prop, value: prop.field_id, label: prop.field_name }));
                    emplTypes.unshift({ field_id: 0, value: 'All', label: 'All' });
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: '', value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                });


            // Load All Earnings and show it by using filter of the employee_type
            resetGlobalQuery();
            globalQuery.columns = ["earning_heads_id", "earning_group", "earning_head_name"];
            globalQuery.table = "hm_earning_heads"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["head_position ASC"];
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEarnings => {
                    rcvdEarnings = rcvdEarnings.map(prop => ({ ...prop, value: prop.earning_heads_id, label: prop.earning_head_name }));
                    setAllEarningOpts(rcvdEarnings);
                });

            // Load All Deductions and show it by using filter of the employee_type
            resetGlobalQuery();
            globalQuery.columns = ["deduction_heads_id", "deduction_group", "deduction_head_name"];
            globalQuery.table = "hm_deduction_heads"
            globalQuery.conditions.push({ field: "deduction_head_name", operator: "NOT IN", values: ['E-PF1', 'E-PF2'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["head_position ASC"];
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDeductions => {
                    rcvdDeductions = rcvdDeductions.map(prop => ({ ...prop, value: prop.deduction_heads_id, label: prop.deduction_head_name }));
                    setAllDeductionOpts(rcvdDeductions);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnCombosOnChange = async (comboName, comboVal) => {
        try {
            setIsLoading(true);

            switch (comboName) {
                case "Department":
                    let selectedDepartment = departmentComboRef.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // remove the already selected sub-department
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId('');
                    await FnSetDefaultData();
                    break;

                case "SubDepartment":
                    await FnSetDefaultData();
                    break;

                case "EmployeeType":
                    await FnSetDefaultData();
                    break;

                default:
                    break;
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error on combos change: ", error)
            navigate('/Error')
        }
    }

    const FnSetDefaultData = async () => {
        try {
            // Filters for load employee query.
            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
            ];

            // First Set Default Department.
            let selectedDepartment = departmentComboRef.current?.department_id;
            if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
            } else if (selectedDepartment === '' || selectedDepartment === undefined || selectedDepartment === null) {
                let defaultDept = departmentOpts.find(department => department.value == 'All');
                setDepartmentId(defaultDept.value);
                departmentComboRef.current = defaultDept;

                // Also Load All Sub-Department
                const subDepartmentList = allDepartmentsList.filter(department => department.department_type === "S");
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
                selectedDepartment = '';
            }

            // Second Set Default Sub-Department.
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
            } else {
                const subDepartmentList = allDepartmentsList.filter(department =>
                    (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                );
                subDepartmentList.unshift({ department_id: '', value: 'All', label: 'All' });
                setSubDepartmentOpts(subDepartmentList);
                subDepartmentComboRef.current = { department_id: '', value: 'All', label: 'All' };
                setSubDepartmentId('All');
            }

            // Third Set Default Employee Type.
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
            } else {
                let defaultEmplType = employeeTypesOpts.find(employee => employee.value == 'All');
                setEmployeeTypeId(defaultEmplType.value);
                employeeTypesComboRef.current = defaultEmplType;
                let emplTypes = employeeTypesOpts.filter(item => item.field_name !== "All" && item.value !== "All")
                    ?.map(item => item?.field_name)
                    ?.filter(id => id !== undefined && id !== null && id !== '');;
                filterConditions.push({ field: "employee_type", operator: "IN", values: emplTypes });
            }

            resetGlobalQuery();
            globalQuery.columns = ["employee_id", "employee_code", "employee_name"];
            globalQuery.table = "cm_employee"
            globalQuery.conditions = filterConditions;
            let getEmployeeList = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            if (getEmployeeList.length > 0) {
                getEmployeeList = getEmployeeList?.map(prop => ({ ...prop, value: prop.employee_id, label: prop.employee_name }));
                getEmployeeList.unshift({ employee_id: '', value: 'All', label: 'All' });
                setEmployeeOpts(getEmployeeList);
                setEmployeeId('All');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            } else {
                setEmployeeOpts([]);
                setEmployeeId('');
                employeeComboRef.current = { employee_id: '', value: 'All', label: 'All' };
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // ****-------Get Salary Slips Data Functions Starts--------------***
    const FnGetSalarySlipsDetailsData = async () => {
        try {
            let isValidFilters = await FnChkIsValidFilters();
            if (!isValidFilters) { return false; }

            // *** Means Filters are valid then filter data as per the filters.
            setIsLoading(true);
            let filterConditions = [
                { field: "company_id", operator: "=", value: parseInt(COMPANY_ID) },
                { field: "is_delete", operator: "=", value: 0 },
            ];

            // *** Apply Filters Conditions;
            let selectedDepartment = departmentComboRef.current?.department_id;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id;
            let selectedEmplType = employeeTypesComboRef.current.field_name;
            let selectedEmployee = employeeComboRef.current.employee_id;

            let selectedEmplGrp = employeeProps.find(prop => prop.properties_master_name === 'EmployeeTypeGroup' && prop.property_value === employeeTypesComboRef.current.property_group);


            // Check it selected one employee or not? 
            if (selectedEmployee !== undefined && selectedEmployee !== null && selectedEmployee !== '') {
                filterConditions.push({ field: "employee_id", operator: "=", value: selectedEmployee });
            } else {
                // Means load data without employee filter.
                // If Valid Then set the default data if not selected any filter.
                await FnSetDefaultData();

                if (selectedDepartment !== undefined && selectedDepartment !== null && selectedDepartment !== '') {
                    filterConditions.push({ field: "department_id", operator: "=", value: selectedDepartment });
                }
                if (selectedSubDepartment !== undefined && selectedSubDepartment !== null && selectedSubDepartment !== '') {
                    filterConditions.push({ field: "sub_department_id", operator: "=", value: selectedSubDepartment });
                }
                if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                    filterConditions.push({ field: "employee_type", operator: "=", value: selectedEmplType });
                }
            }

            // Get Employee's Attandance Details.
            resetGlobalQuery();
            globalQuery.columns = [
                "employee_id", "employee_code", "attendance_month", "attendance_year", "department_id",
                "sub_department_id", "month_days", "present_days", "night_days", "ot_days", "leaves_days",
                "od_days", "half_days", "holiday_days", "week_off_days", "coff_days", "absent_days",
                "total_salary_days", "monthly_hours", "attendance_flag",
            ];
            globalQuery.table = "ht_attendance_monthly";
            globalQuery.conditions = [...filterConditions]
                .filter(cond => cond.field !== "employee_type")
                .concat(
                    { field: "attendance_year", operator: "=", value: cmb_year },
                    { field: "attendance_month", operator: "=", value: cmb_month },
                );

            if (selectedEmplType !== undefined && selectedEmplType !== null && selectedEmplType !== 'All') {
                globalQuery.conditions.push({ field: "employee_type", operator: "=", value: selectedEmplGrp?.field_name });
            }
            globalQuery.orderBy = ['department_id DESC', 'sub_department_id DESC', 'employee_code Asc'];
            const recvdAttendanceData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setAttendanceDetails(recvdAttendanceData);

            // Get Employee's Salary Summary.
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_year", "salary_month", "department_name", "sub_department_name", "employee_type",
                "employee_name", "employee_code", "designation_name", "month_days", "salary_days", 'worked_hours', "monthly_salary",
                "gross_salary", "total_deduction", "net_salary", "salary_transaction_id", "employee_id",
                "department_id", "sub_department_id", "designation_id", "company_id", "company_branch_id"
            ];
            globalQuery.table = "htv_salary_summary";
            globalQuery.conditions = [...filterConditions];
            globalQuery.conditions.push({ field: "salary_year", operator: "=", value: cmb_year });
            globalQuery.conditions.push({ field: "salary_month", operator: "=", value: cmb_month });
            globalQuery.orderBy = ['department_id DESC', 'sub_department_id DESC', 'employee_code Asc'];
            const recvdSummaryData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setSalarySummaryDetails(recvdSummaryData);

            // Get Employee's Salary Earnigs.
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_earnings_id", "company_id", "company_branch_id", "salary_transaction_id",
                "salary_version", "salary_month", "salary_year", "department_id", "sub_department_id",
                "employee_type", "employee_id", "designation_id", "earning_heads_id", "earning_amount"
            ];
            globalQuery.table = "ht_salary_earnings";
            globalQuery.conditions = [...filterConditions];
            globalQuery.conditions.push({ field: "salary_year", operator: "=", value: cmb_year });
            globalQuery.conditions.push({ field: "salary_month", operator: "=", value: cmb_month });
            const recvdEarningData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setSalaryEarningDetails(recvdEarningData);

            // Get Employee's Salary Deductions.
            resetGlobalQuery();
            globalQuery.columns = [
                "salary_deduction_id", "company_id", "company_branch_id", "salary_transaction_id",
                "salary_version", "salary_month", "salary_year", "department_id", "sub_department_id",
                "employee_type", "employee_id", "designation_id", "deduction_heads_id", "deduction_amount"
            ];
            globalQuery.table = "ht_salary_deduction";
            globalQuery.conditions = [...filterConditions];
            globalQuery.conditions.push({ field: "salary_year", operator: "=", value: cmb_year });
            globalQuery.conditions.push({ field: "salary_month", operator: "=", value: cmb_month });
            const recvdDeductionData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setSalaryDeductionDetails(recvdDeductionData);

            // Get Employee's Informations.
            resetGlobalQuery();
            globalQuery.columns = [
                `company_name`,
                `employee_type_group`, `employee_type`,
                `employee_name`, `department_name`, `sub_department_name`, `designation_name`,
                `pf_flag`, `uan_no`, `ctc`, `salary`,
                `employee_id`, `employee_code`, `department_id`, `sub_department_id`, `employee_code`
            ];
            globalQuery.table = "cmv_employee";
            globalQuery.conditions = filterConditions;
            globalQuery.orderBy = ['department_id ASC', 'sub_department_id ASC', 'employee_code ASC'];
            let rcvdEmplInfos = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setEmployeeInfoDetailsData(rcvdEmplInfos);

            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // Filter Earning & Deduction Heads Names for the Staff;
    let { StaffEarningHeads, WorkersEarningHeads } = allEarningOpts.reduce((acc, obj) => {
        if (obj.earning_group === "All" || obj.earning_group === "Staffs") {
            acc.StaffEarningHeads.push(obj);
        }
        if (obj.earning_group === "All" || obj.earning_group === "Workers") {
            acc.WorkersEarningHeads.push(obj);
        }
        return acc;
    }, { StaffEarningHeads: [], WorkersEarningHeads: [] });

    // Filter Earning & Deduction Heads Names for the Staff;
    let { StaffDeductionHeads, WorkersDeductionHeads } = allDeductionOpts.reduce((acc, obj) => {
        if (obj.deduction_group === "All" || obj.deduction_group === "Staffs") {
            acc.StaffDeductionHeads.push(obj);
        }
        if (obj.deduction_group === "All" || obj.deduction_group === "Workers") {
            acc.WorkersDeductionHeads.push(obj);
        }
        return acc;
    }, { StaffDeductionHeads: [], WorkersDeductionHeads: [] });

    const FnGenerateSalarySlips = useMemo(() => {
        if (employeeInfoDetailsData.length === 0) { return null; }

        return (
            <>
                {
                    employeeInfoDetailsData?.map((employeeInfoRec) => {
                        const filteredAttendanceDtl = attendanceDetails.find(attendanceRecord => attendanceRecord.employee_id == employeeInfoRec.employee_id) || {};
                        // Get Current Empl's Salary Summary, Earnings & Deductions.
                        const filteredSalarySummaryDtl = salarySummaryDetails.find(salaryRecord => salaryRecord.employee_id == employeeInfoRec.employee_id) || {};
                        const filteredSalaryEarningsDtls = salaryEarningDetails.filter(record => record.employee_id == employeeInfoRec.employee_id) || [];
                        const filteredSalaryDeductionsDtls = salaryDeductionDetails.filter(record => record.employee_id == employeeInfoRec.employee_id) || [];


                        if (Object.keys(filteredAttendanceDtl).length > 0 && Object.keys(filteredSalarySummaryDtl).length > 0 && filteredSalaryEarningsDtls.length > 0 && filteredSalaryDeductionsDtls.length > 0) {
                            if (filteredSalarySummaryDtl.net_salary <= 0) { return null; }

                            filteredSalarySummaryDtl.salary_month_name = monthComboRef.current?.label;
                            return (
                                <>
                                    <SalarySlip
                                        employeeInfo={employeeInfoRec}
                                        attendanceDtl={filteredAttendanceDtl}
                                        salaryDtl={filteredSalarySummaryDtl}
                                        earningDtls={filteredSalaryEarningsDtls}
                                        deductionDtls={filteredSalaryDeductionsDtls}
                                        allEarningHeads={employeeInfoRec.employee_type_group === 'Workers' ? WorkersEarningHeads : StaffEarningHeads}
                                        allDeductionHeads={employeeInfoRec.employee_type_group === 'Workers' ? WorkersDeductionHeads : StaffDeductionHeads}
                                    />
                                    <hr />
                                </>
                            )
                        }
                        return null;
                    })

                }

            </>
        )
    }, [employeeInfoDetailsData])

    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }

    // Get Current DateTime as "DD-MM-YYYY HH:mm:ss"
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date();
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }

    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = async () => {
        if (cmb_year === '') {
            $('#error_cmb_year').text('Please select the atleast one...!')
            $('#error_cmb_year').show();
            return false;
        } else {
            $('#error_cmb_year').hide();
        }

        if (cmb_month === '') {
            $('#error_cmb_month').text('Please select the atleast one...!')
            $('#error_cmb_month').show();
            return false;
        } else {
            $('#error_cmb_month').hide();
        }
        return true;
    }

    // Function for validate the fields on change
    const FnValidateFields = () => {
        setEmployeeInfoDetailsData([]);
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(cmb_month)) { $('#error_dt_att_to').hide(); }
        if (isEmpty(cmb_year)) { $('#error_dt_att_from').hide(); }
        if (isEmpty(departmentComboRef.current?.department_id || '')) { $('#error_cmb_department_id').hide(); }
        if (isEmpty(subDepartmentComboRef.current?.department_id || '')) { $('#error_cmb_sub_department_id').hide(); }
        if (isEmpty(employeeTypesComboRef.current?.field_name || '')) { $('#error_cmb_employee_type_id').hide(); }
        if (isEmpty(employeeComboRef.current?.employee_id || '')) { $('#error_cmb_employee_id').hide(); }
    }

    // ****-------Validation Functions Ends--------------***

    // ********* PrintOut Functionalities Starts. ****************************************
    const pageHeight = 1055; // Adjust according to the actual print page height in pixels

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintReport = useReactToPrint({
        content: () => {
            debugger

            const printContent = printComponentRef.current;
            const formattedPrintContent = printComponentRef.current.cloneNode(true);

            if (printContent) {
                let remainingPageHeight = pageHeight;

                const pagesForFormat = formattedPrintContent.querySelectorAll('.salarySlipPage');
                const pages = printContent.querySelectorAll('.salarySlipPage');
                pages.forEach((page, index) => {
                    // If Current Node height is greater than the remaining page size; then create new page;
                    if (page.offsetHeight > remainingPageHeight) {
                        // Then set the props for next page;
                        // page.style.pageBreakBefore = 'always';
                        // const prevSibling = page.previousElementSibling;
                        // if (prevSibling && prevSibling.tagName === 'HR') { prevSibling.remove(); }

                        pagesForFormat[index].style.pageBreakBefore = 'always';
                        const prevSibling = pagesForFormat[index].previousElementSibling;
                        if (prevSibling && prevSibling.tagName === 'HR') { prevSibling.remove(); }

                        remainingPageHeight = pageHeight - page.offsetHeight;
                    } else {
                        // Means add this in existing page;
                        remainingPageHeight -= page.offsetHeight;
                    }
                });
            }
            // Return the formatted Page Content;
            return formattedPrintContent;
        },
        onBeforePrint: () => { setIsLoading(true) },
        onBeforeGetContent: () => {
            setIsLoading(true)
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            setIsLoading(false)
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Salary Slip For ${monthComboRef.current?.label}-${yearComboRef.current?.value}`
    });

    // ********* PrintOut Functionalities Ends. ****************************************



    return (
        <>
            <ComboBox ref={comboDataFunc} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Employee's Salary Slips Register</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={yearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_year"
                                            value={yearOpts.find(option => option.value == cmb_year) || null}
                                            onChange={(selectedOpt) => {
                                                setYear(selectedOpt.value);
                                                yearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={monthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_month"
                                            value={monthOpts.find(option => option.value == cmb_month) || null}
                                            onChange={(selectedOpt) => {
                                                setMonth(selectedOpt.value);
                                                monthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>


                            </div>
                            {/* First Column Ends*/}


                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={departmentComboRef}
                                            options={departmentOpts}
                                            inputId="cmb_department_id"
                                            value={departmentOpts.find(option => option.value == cmb_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                departmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("Department");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("SubDepartment");
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}

                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee Type </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type_id"
                                            value={employeeTypesOpts.find(option => option.value == cmb_employee_type_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeTypeId(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnCombosOnChange('EmployeeType');
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Employee </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeComboRef}
                                            options={employeeOpts}
                                            inputId="cmb_employee_id"
                                            value={employeeOpts.find(option => option.value == cmb_employee_id) || null}
                                            onChange={(selectedOpt) => {
                                                setEmployeeId(selectedOpt.value);
                                                employeeComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a employee..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* First Third Column Ends*/}
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center me-1`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnGetSalarySlipsDetailsData(); }}
                                >Show Data</MDButton>
                            </div>
                        </div>

                        <hr />

                        {/* Employee's Slips */}
                        <div className="print-content" ref={printComponentRef}>
                            {FnGenerateSalarySlips}
                        </div>

                        <div className="row text-center mt-3">
                            <div className=''>
                                {
                                    employeeInfoDetailsData.length === 0
                                        ? null
                                        : <>
                                            {
                                                employeeInfoDetailsData.length > 10
                                                    ? <>
                                                        <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                                            className={`erp-gb-button float-center`}
                                                            onClick={(e) => { goBack(); }}  > Back </MDButton>
                                                    </>
                                                    : null
                                            }
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintReport(); }} >Print</MDButton>
                                        </>
                                }

                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}

                </div>
                {/* Card Ends*/}

            </DashboardLayout >

        </>
    )
}




// Salary Slip Generic Component.
export const SalarySlip = ({ employeeInfo, attendanceDtl, salaryDtl, earningDtls, deductionDtls, allEarningHeads, allDeductionHeads, }) => {
    // All Employee Information.
    const {
        company_name, employee_type_group, employee_type,
        employee_code, employee_name, designation_name,
        department_name, sub_department_name,
        pf_flag, uan_no,
        salary, ctc
    } = employeeInfo;

    // Attendance Details;
    const { attendance_month, attendance_year, month_days, present_days, night_days, ot_days, leaves_days,
        od_days, half_days, holiday_days, week_off_days, coff_days, absent_days,
        total_salary_days, monthly_hours, } = attendanceDtl;

    // Salary Details
    const {
        salary_year, salary_month, salary_days, worked_hours,
        monthly_salary, gross_salary, total_deduction, net_salary,
        salary_month_name
    } = salaryDtl;


    function numberToWordsWithCurrency(number) {

        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }

        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }

    const StaffSalarySlipHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-1'>
                    <div className="col-sm-3 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width={150} height={55} mt={1} />
                    </div>
                    <div className="col-sm-6 ps-3">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {company_name}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-lg text-center'>Salary Slip {` (${salary_month_name}-${salary_year})`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dl className="row  border-top border-dark p-0 m-0">
                    <div className="col-sm-6 ps-3">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3 ps-0">
                                <dt className='erp-invoice-print-label-md-lg'>Employee Name</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {employee_name} </dt>
                            </div>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3">
                                <dt className='erp-invoice-print-label-md-lg'>Designation</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {designation_name} </dt>
                            </div>
                        </dl>
                    </div>
                </dl>

                <dl className="row p-0 m-0">
                    <div className="col-sm-6 ps-3">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3 ps-0">
                                <dt className='erp-invoice-print-label-md-lg'>Department</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {department_name} </dt>
                            </div>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3">
                                <dt className='erp-invoice-print-label-md-lg'>SubDepartment</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {sub_department_name} </dt>
                            </div>
                        </dl>
                    </div>
                </dl>

                <dl className="row p-0 m-0">
                    <div className="col-sm-6 ps-3">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-6">
                                <dl className="row mb-0 mt-0">
                                    <div className="col-sm-3 ps-0">
                                        <dt className='erp-invoice-print-label-md-lg'>Salary</dt>
                                    </div>
                                    <div className="col-sm-9">
                                        <dt className='erp-invoice-print-label-md-lg'> {salary.toFixed(2)} </dt>
                                    </div>
                                </dl>
                            </div>

                            <div className="col-sm-6">
                                <dl className="row mb-0 mt-0">
                                    <div className="col-sm-3 ps-0">
                                        <dt className='erp-invoice-print-label-md-lg'>CTC</dt>
                                    </div>
                                    <div className="col-sm-9">
                                        <dt className='erp-invoice-print-label-md-lg'> {ctc.toFixed(2)} </dt>
                                    </div>
                                </dl>
                            </div>

                        </dl>
                    </div>

                    <div className="col-sm-6">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3">
                                <dt className='erp-invoice-print-label-md-lg'>UAN No.</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {uan_no} </dt>
                            </div>
                        </dl>
                    </div>
                </dl>
            </div>
        </>
    )

    const WorkerSalarySlipHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-1'>
                    <div className="col-sm-3 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width={150} height={55} mt={1} />
                    </div>
                    <div className="col-sm-6 ps-3">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {company_name}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-lg text-center'>Salary Slip {` (${salary_month_name}-${salary_year})`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dl className="row  border-top border-dark p-0 m-0">
                    <div className="col-sm-6 ps-3">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3 ps-0">
                                <dt className='erp-invoice-print-label-md-lg'>Employee Name</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {employee_name} </dt>
                            </div>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3">
                                <dt className='erp-invoice-print-label-md-lg'>Department</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {`${sub_department_name} [${department_name}]`} </dt>
                            </div>
                        </dl>
                    </div>
                </dl>

                <dl className="row p-0 m-0">
                    <div className="col-sm-6 ps-3">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3 ps-0">
                                <dt className='erp-invoice-print-label-md-lg'>Salary</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {salary.toFixed(2)} </dt>
                            </div>
                        </dl>
                    </div>

                    <div className="col-sm-6">
                        <dl className="row mb-0 mt-0">
                            <div className="col-sm-3">
                                <dt className='erp-invoice-print-label-md-lg'>UAN No.</dt>
                            </div>
                            <div className="col-sm-9">
                                <dt className='erp-invoice-print-label-md-lg'> {uan_no} </dt>
                            </div>
                        </dl>
                    </div>
                </dl>
            </div>
        </>
    )


    return (
        <>
            <div className="row salarySlipPage" style={{ padding: '0px 15px 0px' }} key={employee_code}>
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0" >
                                {/* PrintOut HEADER Starts */}
                                <div className="row">
                                    {employee_type_group === 'Staffs' ? StaffSalarySlipHeader : WorkerSalarySlipHeader}
                                </div>
                                {/* PrintOut HEADER Close*/}

                                {/* Salary Slip Details Starts */}
                                <div className="row border border-dark">
                                    {/* All Earnings Details Starts*/}
                                    <div className="col-sm-4 py-0">
                                        <div className='row text-center border-bottom border-dark bg-light'>
                                            <dt className='erp-invoice-print-label-md-lg text-center' style={{ fontSize: '10px' }}> Earnings </dt>
                                        </div>

                                        {
                                            allEarningHeads?.map((earningHead) => {
                                                return (
                                                    <>
                                                        <dl className="row mb-0 mt-0">
                                                            <div className="col-sm-6 ps-1">
                                                                <dt className='erp-invoice-print-label-md-lg'>{earningHead?.earning_head_name}</dt>
                                                            </div>
                                                            <div className="col-sm-6 ps-0">
                                                                <dt className='erp-invoice-print-label-md-lg text-end'>
                                                                    {(earningDtls.find(earning => earning.earning_heads_id === earningHead?.earning_heads_id)?.earning_amount || 0).toFixed(2)}
                                                                </dt>
                                                            </div>
                                                        </dl>
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                    {/* All Earnings Details Ends*/}

                                    {/* All Deductions Details Starts */}
                                    <div className="col-sm-4 py-0 border-start border-end border-dark ">
                                        <div className='row text-center border-bottom border-dark bg-light'>
                                            <dt className='erp-invoice-print-label-md-lg text-center' style={{ fontSize: '10px' }}> Deductions </dt>
                                        </div>

                                        {
                                            allDeductionHeads?.map((deductionHead) => {
                                                return (
                                                    <>
                                                        <dl className="row mb-0 mt-0">
                                                            <div className="col-sm-6 ps-1">
                                                                <dt className='erp-invoice-print-label-md-lg'>{deductionHead?.deduction_head_name}</dt>
                                                            </div>
                                                            <div className="col-sm-6 ps-0">
                                                                <dt className='erp-invoice-print-label-md-lg text-end'>
                                                                    {(deductionDtls.find(deduction => deduction.deduction_heads_id === deductionHead?.deduction_heads_id)?.deduction_amount || 0).toFixed(2)}
                                                                </dt>
                                                            </div>
                                                        </dl>
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                    {/* All Deductions Details Ends */}

                                    {/* Summary Details Starts */}
                                    <div className="col-sm-4 py-0">
                                        <div className='row text-center border-bottom border-dark bg-light'>
                                            <dt className='erp-invoice-print-label-md-lg text-center' style={{ fontSize: '10px' }}> Attendance Summary </dt>
                                        </div>

                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8 ps-1">
                                                        <dt className='erp-invoice-print-label-md-lg'>Month Days</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {(month_days).toFixed(2)} </dt>
                                                    </div>
                                                </dl>
                                            </div>
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8">
                                                        {/* <dt className='erp-invoice-print-label-md-lg'>Salary Days {employee_type_group === 'Workers' ? ` (Worked Hours)` : ''}</dt> */}
                                                        <dt className='erp-invoice-print-label-md-lg'>Salary Days</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        {/* <dt className='erp-invoice-print-label-md-lg text-end'> {salary_days.toFixed(2)} {employee_type_group === 'Workers' ? ` (${worked_hours.toFixed(2)})` : ''}</dt> */}
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {salary_days.toFixed(2)}</dt>
                                                    </div>
                                                </dl>
                                            </div>
                                        </dl>

                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8 ps-1">
                                                        <dt className='erp-invoice-print-label-md-lg'>Present Days</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {(present_days).toFixed(2)} </dt>
                                                    </div>
                                                </dl>
                                            </div>
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8">
                                                        <dt className='erp-invoice-print-label-md-lg'>Absent Days</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {(absent_days).toFixed(2)} </dt>
                                                    </div>
                                                </dl>
                                            </div>
                                        </dl>

                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8 ps-1">
                                                        <dt className='erp-invoice-print-label-md-lg'>Half Days</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {(half_days).toFixed(2)} </dt>
                                                    </div>
                                                </dl>
                                            </div>
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8">
                                                        <dt className='erp-invoice-print-label-md-lg'>Leave Days</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {(leaves_days).toFixed(2)} </dt>
                                                    </div>
                                                </dl>
                                            </div>
                                        </dl>

                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6">
                                                <dl className="row mb-0 mt-0">
                                                    <div className="col-sm-8 ps-1">
                                                        <dt className='erp-invoice-print-label-md-lg'>Holidays</dt>
                                                    </div>
                                                    <div className="col-sm-4 px-2">
                                                        <dt className='erp-invoice-print-label-md-lg text-end'> {(holiday_days).toFixed(2)} </dt>
                                                    </div>
                                                </dl>
                                            </div>

                                            {
                                                employee_type_group === 'Staffs'
                                                    ? <>
                                                        <div className="col-sm-6">
                                                            <dl className="row mb-0 mt-0">
                                                                <div className="col-sm-8">
                                                                    <dt className='erp-invoice-print-label-md-lg'>C-OFF Days</dt>
                                                                </div>
                                                                <div className="col-sm-4 px-2">
                                                                    <dt className='erp-invoice-print-label-md-lg text-end'> {(coff_days).toFixed(2)} </dt>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className="col-sm-6">
                                                            <dl className="row mb-0 mt-0">
                                                                <div className="col-sm-8 pe-0">
                                                                    <dt className='erp-invoice-print-label-md-lg'>Worked Hours</dt>
                                                                </div>
                                                                <div className="col-sm-4 px-2">
                                                                    <dt className='erp-invoice-print-label-md-lg text-end'> {(Math.round(worked_hours)).toFixed(2)} </dt>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </>
                                            }
                                        </dl>

                                        {
                                            employee_type_group === 'Staffs'
                                                ? <>
                                                    <dl className="row mb-0 mt-0">
                                                        <div className="col-sm-6">
                                                            <dl className="row mb-0 mt-0">
                                                                <div className="col-sm-8 ps-1">
                                                                    <dt className='erp-invoice-print-label-md-lg'>Weekly Offs</dt>
                                                                </div>
                                                                <div className="col-sm-4 px-2">
                                                                    <dt className='erp-invoice-print-label-md-lg text-end'> {(week_off_days).toFixed(2)} </dt>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </dl>
                                                </>
                                                : null
                                        }


                                        {
                                            employee_type_group === 'Workers'
                                                ? <>


                                                    <dl className="row mb-0 mt-0 border-top border-dark">
                                                        <div className="col-sm-8 ps-1">
                                                            <dt className='erp-invoice-print-label-md-lg'>Attend. Allow. Days</dt>
                                                        </div>
                                                        <div className="col-sm-4 px-2">
                                                            <dt className='erp-invoice-print-label-md-lg text-end'> {(salary_days).toFixed(2)} </dt>
                                                        </div>
                                                    </dl>
                                                    <dl className={`row mb-0 mt-0`}>
                                                        <div className="col-sm-8 ps-1">
                                                            <dt className='erp-invoice-print-label-md-lg'>Night Allow. Days</dt>
                                                        </div>
                                                        <div className="col-sm-4 px-2">
                                                            <dt className='erp-invoice-print-label-md-lg text-end'> {(night_days).toFixed(2)} </dt>
                                                        </div>
                                                    </dl>
                                                </>
                                                : null
                                        }


                                        {/* Some Static Details Starts. (Prashant Sir told show it currently as 0.) */}
                                        <dl className="row mb-0 mt-0 border-bottom border-top border-dark bg-light">
                                            <div className="col-sm-6 ps-1"> <dt className='erp-invoice-print-label-md-lg'>Leaves</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg'>Given</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg'>Used</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg'>Balance</dt> </div>
                                        </dl>
                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6 ps-1"> <dt className='erp-invoice-print-label-md-lg'>Paid Leaves</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg text-end'>{(0).toFixed(2)}</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg text-end'>{(0).toFixed(2)}</dt> </div>
                                            <div className="col-sm-2 ps-0 px-2"> <dt className='erp-invoice-print-label-md-lg text-end'>{(0).toFixed(2)}</dt> </div>
                                        </dl>
                                        <dl className="row mb-0 mt-0 border-bottom border-dark">
                                            <div className="col-sm-6 ps-1"> <dt className='erp-invoice-print-label-md-lg'>Casual Leaves</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg text-end'>{(0).toFixed(2)}</dt> </div>
                                            <div className="col-sm-2 ps-0"> <dt className='erp-invoice-print-label-md-lg text-end'>{(0).toFixed(2)}</dt> </div>
                                            <div className="col-sm-2 ps-0 px-2"> <dt className='erp-invoice-print-label-md-lg text-end'>{(0).toFixed(2)}</dt> </div>
                                        </dl>


                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>Advance Deduct</dt>
                                            </div>
                                            <div className="col-sm-6 px-2">
                                                <dt className='erp-invoice-print-label-md-lg text-end'> {(0).toFixed(2)} </dt>
                                            </div>
                                        </dl>
                                        <dl className={`row mb-0 mt-0`}>
                                            <div className="col-sm-6 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>Advance Balance</dt>
                                            </div>
                                            <div className="col-sm-6 px-2">
                                                <dt className='erp-invoice-print-label-md-lg text-end'> {(0).toFixed(2)} </dt>
                                            </div>
                                        </dl>

                                        {/* Some Static Details Ends. */}


                                    </div>
                                    {/* Summary Details Ends */}

                                </div>


                                {/* Salary Slip Footer For Total's Row Starts */}
                                <div className="row border-bottom border-dark bg-light">
                                    {/* Earning's Total Starts*/}
                                    <div className="col-sm-4 py-0 border-start border-dark">
                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>Total Earnings </dt>
                                            </div>
                                            <div className="col-sm-6 ps-0">
                                                <dt className='erp-invoice-print-label-md-lg text-end'> {(gross_salary).toFixed(2)} </dt>
                                            </div>
                                        </dl>

                                        {/* <dl className="row mb-0 mt-0">
                                            <div className="col-sm-5 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>In Words </dt>
                                            </div>
                                            <div className="col-sm-7 ps-0">
                                                <dt className='erp-invoice-print-label-md-lg'> {numberToWordsWithCurrency(gross_salary)} </dt>
                                            </div>
                                        </dl> */}

                                    </div>
                                    {/* Earning's Total Ends*/}

                                    {/* Deductions's Total Starts */}
                                    <div className="col-sm-4 py-0 border-start border-end border-dark border-dark">
                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>Total Deductions </dt>
                                            </div>
                                            <div className="col-sm-6 ps-0">
                                                <dt className='erp-invoice-print-label-md-lg text-end'> {total_deduction.toFixed(2)} </dt>
                                            </div>
                                        </dl>

                                        {/* <dl className="row mb-0 mt-0">
                                            <div className="col-sm-5 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>In Words </dt>
                                            </div>
                                            <div className="col-sm-7 ps-0">
                                                <dt className='erp-invoice-print-label-md-lg'> {numberToWordsWithCurrency(total_deduction)} </dt>
                                            </div>
                                        </dl> */}

                                    </div>
                                    {/* Deductions's Total Ends */}


                                    {/* Summary Totals Starts */}
                                    <div className="col-sm-4 py-0 border-end border-dark">
                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-6 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>Net Payable </dt>
                                            </div>
                                            <div className="col-sm-6 px-2">
                                                <dt className='erp-invoice-print-label-md-lg text-end'> {net_salary.toFixed(2)} </dt>
                                            </div>
                                        </dl>

                                        {/* <dl className="row mb-0 mt-0">
                                            <div className="col-sm-5 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>In Words </dt>
                                            </div>
                                            <div className="col-sm-7 ps-0">
                                                <dt className='erp-invoice-print-label-md-lg'> {numberToWordsWithCurrency(net_salary)} </dt>
                                            </div>
                                        </dl> */}
                                    </div>
                                    {/* Summary Totals Ends */}
                                </div>
                                {/* Salary Slip Footer For Total's Row Ends */}

                                <div className="row border-bottom border-dark bg-light">
                                    <div className="col-sm-12 py-0 border-start border-end border-dark">
                                        <dl className="row mb-0 mt-0">
                                            <div className="col-sm-3 ps-1">
                                                <dt className='erp-invoice-print-label-md-lg'>Net Salary In Words</dt>
                                            </div>
                                            <div className="col-sm-9 ps-0">
                                                <dt className='erp-invoice-print-label-md-lg'> {numberToWordsWithCurrency(net_salary)} </dt>
                                            </div>
                                        </dl>

                                    </div>
                                </div>

                                {/* Salary Slip Details Ends */}

                                {/* Salary Slip Footer Section Starts */}
                                <div className="row border-dark border-bottom">
                                    <div className="col-sm-6 py-0 border-start border-end border-dark pt-3">
                                        <dt className='erp-invoice-print-label-md-lg text-center'>Signature {` (${employee_name})`} </dt>
                                    </div>

                                    <div className="col-sm-6 py-0 border-end border-dark pt-3">
                                        <dt className='erp-invoice-print-label-md-lg text-center'>Authorized Signatory  </dt>
                                    </div>
                                </div>
                                {/* Salary Slip Footer Section Ends */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}