import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { CircularProgress } from '@material-ui/core';
import ErrorModal from "components/Modals/ErrorModal";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import { Table } from 'react-bootstrap';

function FrmCountEntry() {
    var activeValue = '';

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME } = configConstants;
    const { state } = useLocation();
    const { production_count_id, keyForViewUpdate, compType } = state || {}


    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate(`/Masters/MCount/FrmCountListing`);
    }
    const validate = useRef();
    const combobox = useRef();
    const validateNumberDateInput = useRef();


    const navigate = useNavigate();
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');

    /////////useState hooks for Spinn Counts Master
    const [production_count_ID, settxt_Count_ID] = useState(production_count_id);
    const [txt_Mixing, settxt_Mixing] = useState(0);
    const [txt_Count_Desc, settxt_Count_Desc] = useState(0);
    const [txt_Count_name, settxt_Count_name] = useState();
    const [txt_Actual_GMS, settxt_Actual_GMS] = useState(0);
    const [txt_UKG_Conv_Factor, settxt_UKG_Conv_Factor] = useState(0);

    const [txt_Actual_Count, settxt_Actual_Count] = useState(0);
    const [txt_CF_Ring_Frame, settxt_CF_Ring_Frame] = useState(0);
    const [txt_Weaving_Cone_Weight, settxt_Weaving_Cone_Weight] = useState(0);
    const [txt_Single_Cone_Weight, settxt_Single_Cone_Weight] = useState(0);
    const [txt_TFO_Cone_Weight, settxt_TFO_Cone_Weight] = useState(0);

    const [txt_Std_Doffing_Time, setStdDoffingTime] = useState(0);
    const [txt_Std_Doffing_Loss, setStdDoffingLoss] = useState(0);
    const [txt_Std_Cop_Contents, setStdCopContents] = useState(0);
    const [txt_Std_Hard_Waste, setStdHardWaste] = useState(0);

    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    //Hooks for Table Data
    const [targetLevels, setTargetLevels] = useState(['Target Level1', 'Target Level2', 'Target Level3', 'Target Level4', 'Target Level5']);
    const [targetLevelsData, setTargetLevelsData] = useState([]);
    const [count_Tm_Events, setCountTmEvents] = useState({});
    const [targetLevelEmptyObjArray, setTargetLevelEmptyObjArray] = useState([]);
    const [tpi_From, setTPIFrom] = useState();
    const [tpi_To, setTPITo] = useState();

    //loader
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        const functionCall = async () => {
            await ActionType();
            await fillCombos();
            if (production_count_id !== 0) {
                await FnCheckUpdateResponce();
            }
        }
        functionCall()
        setIsLoading(false)
    }, [])
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);

    const fillCombos = async () => {

        ////Setting Std. Doffing Time (Mins), Std. Cop Contents (Gms) , Std. Hard Waste (%) , Std. Doffing Loss (%)
        resetGlobalQuery();
        globalQuery.columns = ['spinning_production_settings__from_values'];
        globalQuery.table = "xm_spinning_production_settings"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        combobox.current.fillFiltersCombo(globalQuery).then((getSpinningProdSettingsCall) => {
            if (getSpinningProdSettingsCall.length !== 0) {
                setStdDoffingTime(getSpinningProdSettingsCall[0].spinning_production_settings__from_values);
                setStdCopContents(getSpinningProdSettingsCall[1].spinning_production_settings__from_values);
                setStdHardWaste(getSpinningProdSettingsCall[2].spinning_production_settings__from_values);
                setStdDoffingLoss(getSpinningProdSettingsCall[3].spinning_production_settings__from_values)
            }
        })

        resetGlobalQuery();
        globalQuery.columns = ['tpi_from', 'tpi_to'];
        globalQuery.table = "xm_spinning_prod_tpi_twist_contraction"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        combobox.current.fillFiltersCombo(globalQuery).then((getSpinningProdTwistContractionDetails) => {
            if (getSpinningProdTwistContractionDetails.length !== 0) {
                let tpi_From = getSpinningProdTwistContractionDetails[0].tpi_from;
                let tpi_To = getSpinningProdTwistContractionDetails[getSpinningProdTwistContractionDetails.length - 1].tpi_to;
                setTPIFrom(tpi_From);
                setTPITo(tpi_To);
            }
        })



        let headerData = targetLevels;
        const target_Levels_DataArray = headerData.map((header, index) => {
            return {
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                production_count_levels_id: 0,
                production_count_id: 0,
                production_count_target_level: headerData[index],
                count_speed: 0,
                count_tpi: 0,
                count_tm: 0,
                count_std_ideal_spindle_percent: 0,
                count_std_pnumaphil_waste_percent: 0,
                count_std_twist_contraction: 0,
                count_target_doffing_loss_percent: 0,
                count_target_efficiency_loss_percent: 0,
                count_target_efficiency_percent: 0,
                count_std_gpss: 0,
                count_target_machine_utilization: 0,
                is_active: 0
            };
        });
        setTargetLevelEmptyObjArray(target_Levels_DataArray[0]);
        setTargetLevelsData(target_Levels_DataArray);
    }

    const renderCountMasterTable = useMemo(() => {

        return <Table id="CountFormCalculationsID" bordered striped>
            <thead>
                <tr>
                    <th className="erp_table_th">Paramater</th>
                    {targetLevels?.map((level, index) => (
                        <th key={index} className="erp_table_th">{level}</th>
                    ))}
                </tr>
            </thead>
            <tbody>

                <React.Fragment>
                    <tr>
                        <th className="erp_table_th" >Count Speed</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" style={{ width: '70px' }}>
                                <input
                                    type="text" style={{ width: '70px', backgroundColor: '#AFE1AF' }}
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_speed_${index}`}
                                    value={targetLevelsData[index]?.count_speed}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_speed'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Count TM</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" style={{ width: '70px' }}>
                                <input
                                    type="text" style={{ width: '70px', backgroundColor: '#AFE1AF' }}
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_tm_${index}`}
                                    value={targetLevelsData[index]?.count_tm}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    onBlur={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_tm'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Count TPI</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" >
                                <input
                                    type="text" style={{ width: '70px' }} disabled
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_tpi_${index}`}
                                    value={targetLevelsData[index]?.count_tpi}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_tpi'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Std. Idle Spindle (%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" style={{ width: '70px' }}>
                                <input
                                    type="text" style={{ width: '70px', backgroundColor: '#AFE1AF' }}
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_std_ideal_spindle_percent_${index}`}
                                    value={targetLevelsData[index]?.count_std_ideal_spindle_percent}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_std_ideal_spindle_percent'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Pnumaphil Wastage (%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" style={{ width: '70px' }}>
                                <input
                                    type="text" style={{ width: '70px', backgroundColor: '#AFE1AF' }}
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_std_pnumaphil_waste_percent_${index}`}
                                    value={targetLevelsData[index]?.count_std_pnumaphil_waste_percent}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_std_pnumaphil_waste_percent'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Twist Contraction (%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" style={{ width: '70px' }}>
                                <input
                                    type="text" style={{ width: '70px' }}
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_std_twist_contraction_${index}`}
                                    value={targetLevelsData[index]?.count_std_twist_contraction}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_std_twist_contraction'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Target Doffing Loss (%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" >
                                <input
                                    type="text" style={{ width: '70px' }}
                                    disabled
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_target_doffing_loss_percent_${index}`}
                                    value={targetLevelsData[index]?.count_target_doffing_loss_percent}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_target_doffing_loss_percent'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Target Efficiency Loss (%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" >
                                <input
                                    type="text" style={{ width: '70px' }}
                                    disabled
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_target_efficiency_loss_percent_${index}`}
                                    value={targetLevelsData[index]?.count_target_efficiency_loss_percent}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_target_efficiency_loss_percent'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Target Efficiency(%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" >
                                <input
                                    type="text" style={{ width: '70px' }}
                                    disabled
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_target_efficiency_percent_${index}`}
                                    value={targetLevelsData[index]?.count_target_efficiency_percent}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_target_efficiency_percent'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Standard GPSS <br></br>(On Target Efficiency)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" >
                                <input
                                    type="text" style={{ width: '70px' }}
                                    disabled
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_std_gpss_${index}`}
                                    value={targetLevelsData[index]?.count_std_gpss}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_std_gpss'
                                />
                            </td>
                        ))}
                    </tr>

                    <tr>
                        <th className="erp_table_th" >Target Machine<br></br>Utilization (%)</th>
                        {targetLevels?.map((level, index) => (
                            <td key={index} colIndex={index} className="erp_table_td" >
                                <input
                                    type="text" style={{ width: '70px', backgroundColor: '#AFE1AF' }}
                                    className="erp_input_field_table_txt mb-0 text-end alter_zero"
                                    id={`count_target_machine_utilization_${index}`}
                                    value={targetLevelsData[index]?.count_target_machine_utilization}
                                    onChange={(e) => calculateCountTargetLevelDetails(e, targetLevelsData[index], index)}
                                    Headers='count_target_machine_utilization'
                                />
                            </td>
                        ))}
                    </tr>

                </React.Fragment>
            </tbody>
        </Table>
    }, [txt_Actual_Count, targetLevels, targetLevelsData])

    const calculateCountTargetLevelDetails = async (event, rowdata, index) => {
        debugger
        try {
            setIsLoading(true);
            let txt_Actual_Count = $('#txt_Actual_Count').val();
            if (txt_Actual_Count !== 0) {
                let eventId = document.getElementById(event.target.id);

                let storeAllEventsCountTmObj = count_Tm_Events;
                let idOfEvent = event.target.id;
                if (idOfEvent.startsWith('count_tm_')) {
                    storeAllEventsCountTmObj[idOfEvent] = event;
                    setCountTmEvents(storeAllEventsCountTmObj);
                }


                let enteredValue = event.target.value;
                let clickedColName = event.target.getAttribute('Headers');
                const arrayIndex = parseInt(event.target.parentElement.getAttribute('colIndex'));
                if (isNaN(enteredValue)) {
                    enteredValue = 0;
                } else if (enteredValue != 0 || enteredValue != "") {
                    delete eventId.parentElement.dataset.tip;
                }

                switch (clickedColName) {
                    case 'count_speed':
                        rowdata[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue), 4);
                        break;
                    case 'count_tm':
                        rowdata[clickedColName] = validateNumberDateInput.current.decimalNumber((enteredValue), 4);
                        rowdata['count_tpi'] = validateNumberDateInput.current.decimalNumber((Math.round(Math.sqrt(txt_Actual_Count) * rowdata[clickedColName] * 100) / 100), 2);
                        const count_TM_Val = parseFloat(rowdata[clickedColName]);
                        if (count_TM_Val => tpi_From && count_TM_Val <= tpi_To) {
                            resetGlobalQuery();
                            globalQuery.columns = ['twist_contraction_percent'];
                            globalQuery.table = "xm_spinning_prod_tpi_twist_contraction"
                            globalQuery.conditions = [
                                { field: "is_delete", operator: "=", value: 0 },
                                { field: "tpi_from", operator: "<=", value: count_TM_Val },
                                { field: "tpi_to", operator: ">=", value: count_TM_Val },
                            ];
                            if (event._reactName === 'onBlur') {
                                let getTwistContraction = await combobox.current.fillFiltersCombo(globalQuery);
                                rowdata['count_std_twist_contraction'] = getTwistContraction[0].twist_contraction_percent;
                            }

                        } else if (count_TM_Val === '0' || count_TM_Val === 0 || count_TM_Val === "" || isNaN(count_TM_Val)) {
                            rowdata['count_std_twist_contraction'] = 0;
                        }
                        break;
                    case 'count_std_ideal_spindle_percent':
                        rowdata[clickedColName] = enteredValue;
                        break;
                    case 'count_std_pnumaphil_waste_percent':
                        rowdata[clickedColName] = enteredValue;
                        break;
                    case 'count_target_machine_utilization':
                        rowdata[clickedColName] = enteredValue;
                        break;
                    default:
                        break;
                }

                let val_Speed = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(rowdata.count_speed)), 2));
                let val_TPI = validateNumberDateInput.current.decimalNumber((parseFloat(rowdata.count_tpi)), 2);
                var parsedTPIVal = parseFloat(val_TPI);
                let val_ActCount = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(txt_Actual_Count)), 2));

                let val_StdIdleSpindle = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(rowdata.count_std_ideal_spindle_percent)), 2));
                let val_PnumaphilWaste = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(rowdata.count_std_pnumaphil_waste_percent)), 2));
                let val_TwistCont = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(rowdata.count_std_twist_contraction)), 2));
                let val_StdDoffLoss = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(txt_Std_Doffing_Loss)), 2));
                let val_StdDoffTime = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(txt_Std_Doffing_Time)), 2));
                let val_StdCop = parseFloat(validateNumberDateInput.current.decimalNumber((parseFloat(txt_Std_Cop_Contents)), 2));

                /////Calculating Doffing Loss/////
                let doffing_loss = ((((((val_Speed * 7.2) / parsedTPIVal / val_ActCount) * (1 - (val_StdDoffLoss / 100)) * (1 - (val_StdIdleSpindle / 100)) * (1 - (val_PnumaphilWaste / 100)) * (1 - (val_TwistCont / 100))) / val_StdCop) * (val_StdDoffTime / 480)) * 100);
                if (isNaN(doffing_loss) || doffing_loss === Infinity) {
                    rowdata.count_target_doffing_loss_percent = 0;
                } else {
                    rowdata.count_target_doffing_loss_percent = validateNumberDateInput.current.decimalNumber((Math.round(doffing_loss * 100) / 100), 2);
                }

                /////Calculating Target Efficiency Loss Precent//////
                let count_target_efficiency_loss_percent = (parseFloat(val_StdIdleSpindle) + parseFloat(val_PnumaphilWaste) + parseFloat(val_TwistCont) + parseFloat(val_StdDoffLoss));
                if (isNaN(count_target_efficiency_loss_percent) || count_target_efficiency_loss_percent === Infinity) {
                    rowdata.count_target_efficiency_loss_percent = 0;
                } else {
                    rowdata.count_target_efficiency_loss_percent = parseFloat(count_target_efficiency_loss_percent.toFixed(2));
                }


                /////Calculating Target Efficiency Percent//////
                let count_target_efficiency_percent = 100 - rowdata.count_target_efficiency_loss_percent;
                if (isNaN(count_target_efficiency_percent) || count_target_efficiency_percent === Infinity) {
                    rowdata.count_target_efficiency_percent = 0;
                } else {
                    rowdata.count_target_efficiency_percent = count_target_efficiency_percent;
                }

                /////Calculating STD GPSS/////
                let count_std_gpss = (validateNumberDateInput.current.decimalNumber((((7.2 * val_Speed) / (parseFloat(val_TPI) * parseFloat(val_ActCount))) * parseFloat(rowdata.count_target_efficiency_percent / 100)), 4));
                if (isNaN(count_std_gpss) || count_std_gpss === Infinity) {
                    rowdata.count_std_gpss = 0;
                } else {
                    rowdata.count_std_gpss = Math.round(count_std_gpss);
                }

                if (enteredValue === "" && clickedColName === 'count_speed') {
                    rowdata.count_tpi = 0;
                    rowdata.count_tm = 0;
                    rowdata.count_std_ideal_spindle_percent = 0;
                    rowdata.count_std_pnumaphil_waste_percent = 0;
                    rowdata.count_std_twist_contraction = 0;
                    rowdata.count_target_doffing_loss_percent = 0;
                    rowdata.count_target_efficiency_loss_percent = 0;
                    rowdata.count_target_efficiency_percent = 0;
                    rowdata.count_std_gpss = 0;
                    rowdata.count_target_machine_utilization = 0;
                }

                setTargetLevelsData(prevItems => {
                    return prevItems.map((item, i) => {
                        if (i === arrayIndex) {
                            return { ...rowdata };
                        }
                        return item;
                    });
                });
            } else {
                $('#error_txt_Actual_Count').text('Fill Actual Count...!').show();
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const FnCalculateTargetLevelsOnCountChange = async () => {
        debugger
        let existingTargetLvls = [...targetLevelsData]; // Copying targetLevelsData array
        let countTmObj = count_Tm_Events;

        for (let rowIndex = 0; rowIndex < existingTargetLvls.length; rowIndex++) {
            let eventId = `count_tm_${rowIndex}`; // Constructing the ID dynamically
            let countTmEvent = countTmObj[eventId]; // Retrieve the event object using the dynamic ID
            await calculateCountTargetLevelDetails(countTmEvent, existingTargetLvls[rowIndex], rowIndex);
        }
    }


    // const inputBoxesOnChange = async (key) => {
    //     switch (key) {
    //         case 'count':
    //             const txt_Count_Desc = document.getElementById('txt_Count_Desc').value.trim().toUpperCase();
    //             const txt_Mixing = document.getElementById('txt_Mixing').value.trim();
    //             settxt_Count_name(txt_Count_Desc + txt_Mixing);
    //             break;
    //     }
    // }


    const FnCheckUpdateResponce = async () => {
        try {
            setIsLoading(true)
            if (production_count_id !== "undefined" && production_count_id !== '' && production_count_id !== null) {

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmSpinningProdCount/FnShowParticularRecordForUpdate/${production_count_id}/${COMPANY_ID}`)
                const updateRes = await apiCall.json();

                let resp = (updateRes.spinningProdCountData)

                settxt_Count_ID(resp.production_count_id)
                settxt_Mixing(resp.production_mixing);
                settxt_Count_Desc(resp.production_count_desc);
                settxt_Count_name(resp.production_count_name);
                // settxt_Actual_GMS(resp.count_actual_grams);
                settxt_Actual_Count(resp.production_actual_count);
                settxt_CF_Ring_Frame(resp.count_40s_conversion_factor);
                settxt_UKG_Conv_Factor(resp.count_ukg_conversion_factor);
                settxt_Weaving_Cone_Weight(resp.count_weaving_cone_weight);
                settxt_Single_Cone_Weight(resp.count_single_cone_weight);
                settxt_TFO_Cone_Weight(resp.count_tfo_cone_weight);
                setStdDoffingTime(resp.count_std_doffing_time);
                setStdDoffingLoss(resp.count_std_doffing_loss_percent);
                setStdCopContents(resp.count_std_cop_contents_gms);
                setStdHardWaste(resp.count_std_hard_waste_percent);
                $('#saveBtn').show();
                switch (resp.is_active) {
                    case true:
                        document.querySelector('input[name="isactive"][value="1"]').checked = true;
                        break;
                    case false:
                        document.querySelector('input[name="isactive"][value="0"]').checked = true;
                        break;
                    default: break;
                }

                setTargetLevelsData(updateRes.spinningProdCountLevelsData);
            }

        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }
    //test case 5 shivanjali
    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_Mixing').attr('disabled', true);
                $('#txt_Count_Desc').attr('disabled', true);
                $('#txt_Count_name').attr('disabled', true);
                $('#txt_Actual_Count').attr('disabled', true);
                $('#saveBtn').attr('disabled', false);
                break;
            case 'view':
                setActionType('(View)');
                $('.erp_input_field, .erp_input_field_table_txt').attr('disabled', 'disabled');
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly("CountFormID");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const validateInputs = () => {
        ;
        const inputs = document.querySelectorAll("#CountFormCalculationsID td:nth-child(2) input[type='text']");
        const enabledInputs = Array.from(inputs).filter(input => input.disabled === false);

        let isValid = true;
        for (const input of enabledInputs) {

            const value = parseFloat(input.value);

            if (value === undefined || value === 0 || value === ' ') {
                input.parentElement.dataset.tip = "Please enter valid value...";
                isValid = false;
                break;
            } else {
                delete input.parentElement.dataset.tip;
            }
        }
        return isValid;
    };


    $(document).on('click', '.alter_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("");
        }
    });

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".alter_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });


    const validateFields = async () => {
        await validate.current.validateFieldsOnChange('CountFormID')
    }

    const handleSubmit = async () => {

        try {
            setIsLoading(true)
            const checkIsValidate = await validate.current.validateForm('CountFormID');
            let validateInputFields = validateInputs();

            if (checkIsValidate === true && validateInputFields === true) {
                let SpinningProdCountData = {
                    TransHeaderData: {}, SpinningProdCountLevelData: [], 'commonIds': {
                        'company_id': COMPANY_ID
                    }
                }

                ///Master Data
                var active;
                activeValue = document.querySelector('input[name=isactive]:checked').value
                switch (activeValue) {
                    case '1': active = true; break;
                    case '0': active = false; break;
                    default: break;
                }
                const data = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    created_by: UserName,
                    production_count_id: production_count_ID,
                    modified_by: production_count_ID === 0 ? null : UserName,
                    production_mixing: txt_Mixing,
                    production_count_desc: txt_Count_Desc,
                    production_count_name: txt_Count_name,
                    production_actual_count: txt_Actual_Count,
                    // count_actual_grams: txt_Actual_GMS,
                    count_40s_conversion_factor: txt_CF_Ring_Frame,

                    count_ukg_conversion_factor: txt_UKG_Conv_Factor,
                    count_weaving_cone_weight: txt_Weaving_Cone_Weight,
                    count_single_cone_weight: txt_Single_Cone_Weight,
                    count_tfo_cone_weight: txt_TFO_Cone_Weight,
                    count_std_doffing_time: txt_Std_Doffing_Time,
                    count_std_doffing_loss_percent: txt_Std_Doffing_Loss,
                    count_std_cop_contents_gms: txt_Std_Cop_Contents,
                    count_std_hard_waste_percent: txt_Std_Hard_Waste,
                    is_active: active,
                };
                SpinningProdCountData.TransHeaderData = data;

                ////Product count Target level Data
                for (let index = 0; index < targetLevelsData.length; index++) {
                    let element = targetLevelsData[index];

                    let targetProductionCountJsonArray = {};
                    targetProductionCountJsonArray.company_id = COMPANY_ID;
                    targetProductionCountJsonArray.company_branch_id = COMPANY_BRANCH_ID;
                    targetProductionCountJsonArray.production_count_levels_id = element.production_count_levels_id;
                    targetProductionCountJsonArray.production_count_id = element.production_count_id;
                    targetProductionCountJsonArray.production_count_target_level = element.production_count_target_level;
                    targetProductionCountJsonArray.count_speed = element.count_speed;
                    targetProductionCountJsonArray.count_tpi = element.count_tpi;
                    targetProductionCountJsonArray.count_tm = element.count_tm;
                    targetProductionCountJsonArray.count_std_ideal_spindle_percent = element.count_std_ideal_spindle_percent;
                    targetProductionCountJsonArray.count_std_pnumaphil_waste_percent = element.count_std_pnumaphil_waste_percent;
                    targetProductionCountJsonArray.count_std_twist_contraction = element.count_std_twist_contraction;
                    targetProductionCountJsonArray.count_target_doffing_loss_percent = element.count_target_doffing_loss_percent;
                    targetProductionCountJsonArray.count_target_efficiency_loss_percent = element.count_target_doffing_loss_percent;
                    targetProductionCountJsonArray.count_target_efficiency_percent = element.count_target_efficiency_percent;
                    targetProductionCountJsonArray.count_std_gpss = element.count_std_gpss;
                    targetProductionCountJsonArray.is_active = element.is_active;
                    targetProductionCountJsonArray.count_target_machine_utilization = element.count_target_machine_utilization;

                    SpinningProdCountData.SpinningProdCountLevelData.push(targetProductionCountJsonArray);
                }


                const formData = new FormData();
                formData.append(`SpinningProdCountData`, (JSON.stringify(SpinningProdCountData)))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmSpinningProdCount/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce);
                if (responce.success === 0) {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    clearFormFields();
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }

    };

    const clearFormFields = () => {
        settxt_Mixing('');
        settxt_Count_Desc('');
        settxt_Count_name('');
        settxt_Actual_GMS('');
        settxt_Actual_Count('');
    }

    //test case 4 shivanjali
    const validateNo = async (key) => {

        const numCheck = /^[0-9]*\.?[0-9]*$/;
        const regexNo = /^[0-9]*\.[0-9]{4}$/
        const alphabetRegex = /^[A-Za-z]*$/;
        var dot = '.';
        switch (key) {

            case 'txt_Mixing':
                var txt_Mixing = $('#txt_Mixing').val();

                var txt_Count_Desc = $('#txt_Count_Desc').val().toUpperCase();
                if (!regexNo.test(txt_Mixing) && txt_Mixing.length <= 14 || txt_Mixing.indexOf(dot) === 14 && alphabetRegex.test(txt_Count_Desc)) {
                    if (numCheck.test(txt_Mixing)) {
                        settxt_Mixing(txt_Mixing)
                        settxt_Count_name(txt_Count_Desc + txt_Mixing);
                    }
                }
                break;

            case 'txt_Count_Desc':
                var txt_Count_Desc = $('#txt_Count_Desc').val().toUpperCase();
                var txt_Mixing = $('#txt_Mixing').val();

                if (alphabetRegex.test(txt_Count_Desc) && !regexNo.test(txt_Mixing)) {
                    settxt_Count_Desc(txt_Count_Desc)
                    settxt_Count_name(txt_Count_Desc + txt_Mixing);
                }
                break;

            case 'txt_Actual_Count':
                var txt_Actual_Count = $('#txt_Actual_Count').val();
                settxt_Actual_Count(validateNumberDateInput.current.decimalNumber((txt_Actual_Count), 4))
                
                break;

            case 'txt_Actual_GMS':
                var txt_Actual_GMS = $('#txt_Actual_GMS').val();
                if (!regexNo.test(txt_Actual_GMS) && txt_Actual_GMS.length <= 14 || txt_Actual_GMS.indexOf(dot) === 14) {
                    if (numCheck.test(txt_Actual_GMS)) {
                        settxt_Actual_GMS(txt_Actual_GMS)
                    }
                }
                break;


            case 'txt_CF_Ring_Frame':
                var txt_CF_Ring_Frame = $('#txt_CF_Ring_Frame').val();
                if (!regexNo.test(txt_CF_Ring_Frame) && txt_CF_Ring_Frame.length <= 14 || txt_CF_Ring_Frame.indexOf(dot) === 14) {
                    if (numCheck.test(txt_CF_Ring_Frame)) {
                        settxt_CF_Ring_Frame(txt_CF_Ring_Frame)
                    }
                }
                break;



            case 'txt_UKG_Conv_Factor':
                var txt_UKG_Conv_Factor = $('#txt_UKG_Conv_Factor').val();
                if (!regexNo.test(txt_UKG_Conv_Factor) && txt_UKG_Conv_Factor.length <= 14 || txt_UKG_Conv_Factor.indexOf(dot) === 14) {
                    if (numCheck.test(txt_UKG_Conv_Factor)) {
                        settxt_UKG_Conv_Factor(txt_UKG_Conv_Factor)
                    }
                }
                break;

            case 'txt_TFO_Cone_Weight':
                var txt_TFO_Cone_Weight = $('#txt_TFO_Cone_Weight').val();
                if (!regexNo.test(txt_TFO_Cone_Weight)) {
                    if (numCheck.test(txt_TFO_Cone_Weight)) {
                        settxt_TFO_Cone_Weight(validateNumberDateInput.current.decimalNumber(txt_TFO_Cone_Weight, 4));
                        settxt_TFO_Cone_Weight(txt_TFO_Cone_Weight)
                    }
                }
                break;

            case 'txt_Single_Cone_Weight':
                var txt_Single_Cone_Weight = $('#txt_Single_Cone_Weight').val();
                settxt_Single_Cone_Weight(validateNumberDateInput.current.decimalNumber(txt_Single_Cone_Weight, 4));
                break;

            case 'txt_Weaving_Cone_Weight':
                var txt_Weaving_Cone_Weight = $('#txt_Weaving_Cone_Weight').val();
                settxt_Weaving_Cone_Weight(validateNumberDateInput.current.decimalNumber(txt_Weaving_Cone_Weight, 4));
                break;
        }
    }

    /////**********************Calculating Doffing loss, Efficiency[Target, Loss] && Standard GPSS***///////////////////////////////
    // const calculateDoffLoss = () => {
    //     let val_Speed = parseFloat(txt_Speed);
    //     let val_TPI = validateNumberDateInput.current.decimalNumber((parseFloat(txt_TPI)), 4);
    //     var parsedTPIVal = parseFloat(val_TPI);
    //     let val_ActCount = parseFloat(txt_Actual_Count);
    //     let val_StdDoffLoss = parseFloat(txt_Std_Doffing_Loss);
    //     let val_StdIdleSpindle = parseFloat(txt_Std_Idle_Spindle);
    //     let val_PnumaphilWaste = parseFloat(txt_Pnumaphil_Wastage);
    //     let val_TwistCont = parseFloat(txt_Twist_Contraction);
    //     let val_StdCop = parseFloat(txt_Std_Cop_Contents);
    //     let val_StdDoffTime = parseFloat(txt_Std_Doffing_Time);
    //     ;
    //     let doffing_loss = ((((((val_Speed * 7.2) / parsedTPIVal / val_ActCount) * (1 - (val_StdDoffLoss / 100)) * (1 - (val_StdIdleSpindle / 100)) * (1 - (val_PnumaphilWaste / 100)) * (1 - (val_TwistCont / 100))) / val_StdCop) * (val_StdDoffTime / 480)) * 100);
    //     let final_DoffLoss = validateNumberDateInput.current.decimalNumber(((doffing_loss)), 4);

    //     if (isNaN(final_DoffLoss) || final_DoffLoss === '' || final_DoffLoss === 0) {
    //         setExpDoffingLoss(0);
    //         setTargetEfficiencyLoss(0);
    //         setExpectedEfficiency(0);
    //         setStandardGPSS(0)
    //     } else {
    //         let target_Efficiency_Loss = parseFloat(val_StdIdleSpindle) + parseFloat(val_PnumaphilWaste) + parseFloat(val_TwistCont) + parseFloat(val_StdDoffLoss);
    //         setExpDoffingLoss(final_DoffLoss);
    //         setTargetEfficiencyLoss(parseFloat(txt_Std_Idle_Spindle) + parseFloat(txt_Pnumaphil_Wastage) + parseFloat(txt_Twist_Contraction) + parseFloat(final_DoffLoss));
    //         setExpectedEfficiency(validateNumberDateInput.current.decimalNumber((100 - target_Efficiency_Loss), 2));
    //         setStandardGPSS(validateNumberDateInput.current.decimalNumber((Math.round(((7.2 * val_Speed) / (parseFloat(val_TPI) * parseFloat(val_ActCount))) * parseFloat(txt_Expected_Efficiency / 100)), 2), 4));
    //     }
    // };


    return (
        //test case 2 shivanjali
        <>
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}

            <div className="erp_top_Form">
                <div className='card'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Production Count{actionType}</label>
                    </div>

                    <form id="CountFormID">
                        <div className="row erp_transporter_div">

                            {/* //first column */}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Production Mixing<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Mixing" className="erp_input_field alter_zero text-end" value={txt_Mixing} onInput={e => { validateNo('txt_Mixing'); validateFields() }} maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_Mixing" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Count Description<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Count_Desc" className="erp_input_field alter_zero text-end" value={txt_Count_Desc} onInput={e => { validateNo('txt_Count_Desc'); validateFields(); }} maxLength="500" />
                                        <MDTypography variant="button" id="error_txt_Count_Desc" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Count Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Count_name" className="erp_input_field alter_zero text-end" value={txt_Count_name} maxLength="500" readOnly />
                                        <MDTypography variant="button" id="error_txt_Count_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Actual Count<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Actual_Count" className="erp_input_field alter_zero text-end" value={txt_Actual_Count} onChange={e => { validateNo('txt_Actual_Count'); validateFields(); }} onBlur={e => FnCalculateTargetLevelsOnCountChange()} maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_Actual_Count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            {/* //second column */}
                            <div className="col-sm-4 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <Form.Label className="erp-form-label">40's Conversion Factor UKG</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_UKG_Conv_Factor" className="erp_input_field alter_zero text-end" value={txt_UKG_Conv_Factor} onChange={e => { validateNo('txt_UKG_Conv_Factor'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_UKG_Conv_Factor" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">40's Conv. Fact. Production</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_CF_Ring_Frame" className="erp_input_field alter_zero text-end" value={txt_CF_Ring_Frame} onChange={e => { validateNo('txt_CF_Ring_Frame'); validateFields() }} maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_CF_Ring_Frame" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">TFO Cone Weight (Kg)</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_TFO_Cone_Weight" className="erp_input_field alter_zero text-end" value={txt_TFO_Cone_Weight} onChange={e => { validateNo('txt_TFO_Cone_Weight'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_TFO_Cone_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Single Cone Weight (Kg)</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Single_Cone_Weight" className="erp_input_field alter_zero text-end" value={txt_Single_Cone_Weight} onChange={e => { validateNo('txt_Single_Cone_Weight'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Single_Cone_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Weaving Cone Weight (Kg)</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Weaving_Cone_Weight" className="erp_input_field alter_zero text-end" value={txt_Weaving_Cone_Weight} onChange={e => { validateNo('txt_Weaving_Cone_Weight'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Weaving_Cone_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row d-none">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Actual Grams (Kg)<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Actual_GMS" className="erp_input_field alter_zero text-end" value={txt_Actual_GMS} onChange={e => { validateNo('txt_Actual_GMS'); validateFields() }} maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_Actual_GMS" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/********* 3rd Column **************************** */}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-7">
                                        <Form.Label className="erp-form-label">Std. Doffing Time (Mins)</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Std_Doffing_Time" className="erp_input_field alter_zero text-end" value={txt_Std_Doffing_Time} onChange={e => { validateNo('txt_Std_Doffing_Time'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Std_Doffing_Time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-7">
                                        <Form.Label className="erp-form-label">Std. Cop Contents (Gms)</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Std_Cop_Contents" className="erp_input_field alter_zero text-end" value={txt_Std_Cop_Contents} onChange={e => { validateNo('txt_Std_Cop_Contents'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Std_Cop_Contents" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-7">
                                        <Form.Label className="erp-form-label">Std. Hard Waste (%)</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Std_Hard_Waste" className="erp_input_field alter_zero text-end" value={txt_Std_Hard_Waste} onChange={e => { validateNo('txt_Std_Hard_Waste'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Std_Hard_Waste" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-7">
                                        <Form.Label className="erp-form-label">Std. Doffing Loss (%)</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Std_Doffing_Loss" className="erp_input_field alter_zero text-end" value={txt_Std_Doffing_Loss} onChange={e => { validateNo('txt_Std_Doffing_Loss'); validateFields() }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Std_Doffing_Loss" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Yes"
                                                    type="radio"
                                                    value="1"
                                                    name="isactive"
                                                    defaultChecked
                                                />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="No"
                                                    value="0"
                                                    type="radio"
                                                    name="isactive"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </form>
                    <hr />
                    <div className="ms-2">
                        <div className="row align-items-center">
                            <div className="col">
                                <label className='erp-form-label-lg'>Calculations for Efficiency & GPSS </label>
                            </div>
                            <div className="col d-none justify-content-center">
                                <MDButton type="submit" className={`erp-gb-button`} variant="button" fontWeight="regular">Add Target Level</MDButton>
                            </div>
                        </div>

                        <div className="col-sm-6 erp_form_col_div">
                            {renderCountMasterTable}
                        </div>
                    </div>

                    <div className="text-center">
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MCount/FrmCountListing/reg' : '/Masters/MCount/FrmCountListing';
                                navigate(path);
                            }}
                            fontWeight="regular">Back</MDButton>
                        {/* <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /> */}
                        <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div>

                    <div className="card-footer mt-3"></div>
                </div>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </div >

        </>

    )
}

export default FrmCountEntry