import React from 'react'
import { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import $ from 'jquery';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';

// import react icons
import { useLocation, useNavigate } from "react-router-dom";
import FrmValidations from "FrmGeneric/FrmValidations";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import { Accordion, Table } from "react-bootstrap";
import { IoMdAddCircle } from "react-icons/io";
import { CircularProgress } from '@mui/material';


import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

export default function FrmMProductTypeEntry(props) {
    const validate = useRef();
    var activeValue = '';

    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName } = configConstants;

    const { state } = useLocation()
    const { producttypeID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};


    const child = useRef();

    // combobox Option
    const [productTypeGroupOption, setProductTypeGroupOption] = useState([]);

    // For navigate
    const navigate = useNavigate();

    // Add Product Type Form Fields
    const [product_type_id, setProducttypeId] = useState(producttypeID === '' ? 0 : producttypeID);
    const [txt_product_type_name, setProducttypeName] = useState('');
    const [txt_product_type_short_name, setProducttypeShortName] = useState('');
    const [txt_group_code, setGroupCode] = useState('');
    const [txt_product_type_group, setProducttypeGroup] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')
    const [isLoading, setIsLoading] = useState(false);


    // product Type parameter Value states
    const [productTypeGroupData, setProductTypeGroupData] = useState([])
    const [productTypeGroupParameterValueData, setProductTypeGroupParameterValueData] = useState([]);
    const [rowCount, setRowCount] = useState(1)
    const [rowCountValue, setRowCountValue] = useState(1)
    const [accordionOpenProductTypeparameterValue, setProductTypeAccordianValueOpen] = useState(false);


    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/ProductTypeListing`);
        }
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');


    useEffect(async () => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await FnFetchPropertyRecords();
                if (product_type_id !== 0) {
                    await FnCheckUpdateResponce();
                }
                await ActionType();
            } catch (error) {
                console.error(error);
                navigate('/Error');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [])

    const handleSubmit = async () => {
        
        try {
            setIsLoading(true)
            let checkIsValidate = false;
            checkIsValidate = await FnValidateForm()

            const json = { 'TransHeaderData': {}, 'ProductTypeGroupParameterData': [], 'ProductTypeGroupParameterValueData': [], 'commonIds': { 'company_id': COMPANY_ID, 'product_type_id': product_type_id, 'product_type_group': txt_product_type_group } }
            if (checkIsValidate === true) {
                var active;
                activeValue = document.querySelector('input[name=isactive]:checked').value

                switch (activeValue) {
                    case '1': active = true; break;
                    case '0': active = false; break;

                }
                const data = {
                    company_id: COMPANY_ID,
                    product_type_id: product_type_id,
                    created_by: UserName,
                    modified_by: product_type_id === 0 ? null : UserName,
                    product_type_name: txt_product_type_name,
                    product_type_short_name: txt_product_type_short_name,
                    group_code : txt_group_code,
                    product_type_group: txt_product_type_group,
                    remark: txt_remark,
                    is_active: active,

                };
                json.TransHeaderData = data
                json.ProductTypeGroupParameterData = productTypeGroupData
                json.ProductTypeGroupParameterValueData = productTypeGroupParameterValueData

                const formData = new FormData()
                formData.append('ProductTypeParameterGroupData', JSON.stringify(json))

                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/producttype/FnAddUpdateRecord`, forwardData)
                const response = await agentApiCall.json();
                console.log("response error: ", response);
                if (response.error !== "") {
                    console.log("response error: ", response.error);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)

                } else {
                    const evitCache = child.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(response.message)
                    setShowSuccessMsgModal(true);
                    clearFormFields();

                }

            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }

    };


    const FnValidateForm = async () => {
        
        const formValidationResult = await validate.current.validateForm("producttypeFormId");
        if (!formValidationResult) {
            return false;
        }
        // Validate the first table rows
        let productTypeValid = true;
        const tableRows1 = document.querySelectorAll('#productTypeGroupTbl tbody tr');
        tableRows1.forEach(row => {
            const productTypeGroupNameInput = row.querySelector('input[id^="product_type_group_parameters_name_"]');
            const productTypeGroupName = productTypeGroupNameInput.value.trim();
            if (productTypeGroupName === '') {
                // Set error message for empty product type group name
                productTypeGroupNameInput.parentElement.dataset.tip = 'Please fill this Field...!';
                productTypeGroupNameInput.focus();
                productTypeValid = false;
                return; // Exit the loop if there's an error
            } else {
                // Clear error message if product type group name is not empty
                delete productTypeGroupNameInput.parentElement.dataset.tip;
            }

            const productTypeGroupCodeInput = row.querySelector('input[id^="product_type_group_parameters_code_"]');
            const productTypeGroupCode = productTypeGroupCodeInput.value.trim();
            if (productTypeGroupCode === '') {
                // Set error message for empty product type group code
                productTypeGroupCodeInput.parentElement.dataset.tip = 'Please fill this Field...!';
                productTypeGroupCodeInput.focus();
                productTypeValid = false;
                return; // Exit the loop if there's an error
            } else {
                // Clear error message if product type group code is not empty
                delete productTypeGroupCodeInput.parentElement.dataset.tip;
            }
        });

        // Validate the second table rows
        const tableRows2 = document.querySelectorAll('#productTypeGroupValueTbl tbody tr');
        tableRows2.forEach(row => {
            const productTypeName = row.querySelector('input[id^="product_type_group_parameters_values_name_"]').value;
            const productTypeCode = row.querySelector('input[id^="product_type_group_parameters_values_code_"]').value;
            if (productTypeName === '') {
                row.querySelector('input[id^="product_type_group_parameters_values_name_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                row.querySelector('input[id^="product_type_group_parameters_values_name_"]').focus();
                productTypeValid = false;
                return;
            } else {
                delete row.querySelector('input[id^="product_type_group_parameters_values_name_"]').parentElement.dataset.tip;
            }
            if (productTypeCode === '') {
                row.querySelector('input[id^="product_type_group_parameters_values_code_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                row.querySelector('input[id^="product_type_group_parameters_values_code_"]').focus();
                productTypeValid = false;
                return;
            } else {
                delete row.querySelector('input[id^="product_type_group_parameters_values_code_"]').parentElement.dataset.tip;
            }
        });

        return productTypeValid;
    };


    const clearFormFields = () => {
        setProducttypeName('');
        setProducttypeShortName('');
        setGroupCode('');
        setProducttypeGroup('');
        setRemark('');

    }

    const FnCheckUpdateResponce = async () => {
        
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/producttype/FnShowAllDetailsAndMastermodelRecords/${producttypeID}/${COMPANY_ID}`)
            const resp = await apiCall.json();
            const productTypeGroupParameters = resp.ProductTypeGroupParametersRecord;
            setProducttypeId(resp.ProductTypeDetailsRecord.product_type_id);
            setProducttypeName(resp.ProductTypeDetailsRecord.product_type_name);
            setProducttypeShortName(resp.ProductTypeDetailsRecord.product_type_short_name);
            setGroupCode(resp.ProductTypeDetailsRecord.group_code);
            setProducttypeGroup(resp.ProductTypeDetailsRecord.product_type_group);
            setRemark(resp.ProductTypeDetailsRecord.remark);
            const productTypeGroupDataToSet = productTypeGroupParameters.map(item => ({
                company_id: item.company_id,
                created_by: item.created_by,
                product_type_group_parameters_code: item.product_type_group_parameters_code,
                product_type_group_parameters_name: item.product_type_group_parameters_name
            }));

            setProductTypeGroupData(productTypeGroupDataToSet);
            const productTypeGroupParameterValue = resp.ProductTypeGroupParametersValuesRecord;
            const productTypeGroupParameterValueDataToSet = productTypeGroupParameterValue.map(item => ({
                company_id: item.company_id,
                created_by: item.created_by,
                product_type_group_parameters_id: item.product_type_group_parameters_id,
                product_type_group_parameters_values_name: item.product_type_group_parameters_values_name,
                product_type_group_parameters_values_code: item.product_type_group_parameters_values_code,
                group_name: item.product_type_group_parameters_name,
                product_type_id: item.product_type_id
            }));
            setProductTypeGroupParameterValueData(productTypeGroupParameterValueDataToSet);
            setProductTypeAccordianValueOpen(true);
            $('#saveBtn').show();

            switch (resp.is_active) {
                case true:
                    document.querySelector('input[name="isactive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isactive"][value="0"]').checked = true;
                    break;
            }

        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }
    const FnFetchPropertyRecords = async () => {

        if (child.current) {
            const productTypeGroupList = await child.current.fillComboBox("ProductTypeGroups", "", "")
            setProductTypeGroupOption(productTypeGroupList)
        }
    }

    // test case 7 shivanjali
    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modify)');
                setActionLabel('Update')
                $('#saveBtn').attr('disabled', false);
                $('#txt_product_type_name').attr('disabled', true);
                break;
            case 'view':
                setActionType('(View)');
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly("producttypeFormId");
                // await validate.current.readOnly("productTypeGroupTbl");  // Redirecting to the ERROR Page So, commented it.
                // await validate.current.readOnly("productTypeGroupValueTbl");  // Redirecting to the ERROR Page So, commented it.
                break;
            default:
                setActionType('(Create)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('producttypeFormId')
    }


    //------------------- Product Type Group Functionality --------------------------------------- //
    // Event handler for changing product type
    const handleProductTypeChange = () => {
        setProductTypeAccordianValueOpen(false);
        const productType = document.getElementById('txt_product_type_group').value;
        if (productType !== 'WV') {
            setRowCount(1);
            setProductTypeGroupData([]);
            setRowCountValue(1);
            setProductTypeGroupParameterValueData([]);
        } else {
            //set blank list
            const updatedProductTypeGroupData = [productTypeGroupBlankObject];
            setProductTypeGroupData(updatedProductTypeGroupData);
        }

    };

    const setRowCountAndAddRow = () => {
        const lastRowIndex = productTypeGroupData.length - 1;
        const lastRowProductTypeGroup = productTypeGroupData[lastRowIndex].product_type_group_parameters_name;
        const lastRowProductTypeGroupCode = productTypeGroupData[lastRowIndex].product_type_group_parameters_code;
        if (lastRowProductTypeGroup !== '' && lastRowProductTypeGroup !== undefined && lastRowProductTypeGroupCode !== '' && lastRowProductTypeGroupCode !== undefined) {
            setRowCount(rowCount + 1);
        } else {
            const tableRows = document.querySelectorAll('#productTypeGroupTbl tbody tr');
            tableRows.forEach(row => {
                const productTypeGroupName = row.querySelector('input[id^="product_type_group_parameters_name_"]').value;
                const productTypeGroupCode = row.querySelector('input[id^="product_type_group_parameters_code_"]').value;
                if (productTypeGroupName === '') {
                    row.querySelector('input[id^="product_type_group_parameters_name_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="product_type_group_parameters_name_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="product_type_group_parameters_name_"]').parentElement.dataset.tip;
                }
                if (productTypeGroupCode === '') {
                    row.querySelector('input[id^="product_type_group_parameters_code_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="product_type_group_parameters_code_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="product_type_group_parameters_code_"]').parentElement.dataset.tip;
                }
            }

            )
        }
    };


    const FnUpdateProductTypeGroupTblRows = (rowData, event) => {
        
    //   const parameterExists = productTypeGroupParameterValueData.some(item => item.group_name === rowData.product_type_group_parameters_name);
        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;

        switch (clickedColName) {
            case 'product_type_group_parameters_name':
                rowData[clickedColName] = enteredValue;
                if (enteredValue !== '') {
                    delete event.target.parentElement.dataset.tip;
                }
                break;
            case 'product_type_group_parameters_code':
                rowData[clickedColName] = enteredValue;
                if (enteredValue !== '') {
                    delete event.target.parentElement.dataset.tip;
                }
                break;
        }

        const productTypeGroupDetails = [...productTypeGroupData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        productTypeGroupDetails[arrayIndex] = rowData
        setProductTypeGroupData(productTypeGroupDetails);

        // //Update productTypeGroupParameterValueData if the clicked column is 'product_type_group_parameters_name'
        // if (parameterExists && clickedColName === 'product_type_group_parameters_name' && productTypeGroupParameterValueData.length > 0) {
        //     const productTypeGroupValueDetails = [...productTypeGroupParameterValueData];
        //     productTypeGroupValueDetails[arrayIndex]['group_name'] = enteredValue; // Assuming the key in productTypeGroupParameterValueData is 'group_name'
        //     setProductTypeGroupParameterValueData(productTypeGroupValueDetails);
        // }
      

    }

    const removeFirstRow = (data, indexToRemove) => {
        
        const groupNameToRemove = data.product_type_group_parameters_name;
        if (indexToRemove !== 0) {
            const updatedProductTypeGroupData = productTypeGroupData.filter((item, index) => index !== indexToRemove);
            setProductTypeGroupData(updatedProductTypeGroupData);
            setProductTypeGroupParameterValueData(prevData =>
                prevData.filter(item => item.group_name !== groupNameToRemove)
            );
        } else {
            // Remove corresponding rows from productTypeGroupParameterValueData
            const updatedProductTypeGroupData = productTypeGroupData.filter((item, index) => index !== indexToRemove);
            setProductTypeGroupData(updatedProductTypeGroupData);
            setProductTypeGroupParameterValueData(prevData => {
                const filteredData = prevData.filter(item => item.group_name !== groupNameToRemove);

                // Check if productTypeGroupData is null or empty after filtering
                if (!updatedProductTypeGroupData || updatedProductTypeGroupData.length === 0) {
                    // Add an empty row to productTypeGroupData
                    const newProductTypeGroupData = [{ ...productTypeGroupBlankObject }];
                    setProductTypeGroupData(newProductTypeGroupData);
                }

                // Add an empty row to productTypeGroupData if it's still null or empty
                else if (!productTypeGroupData || productTypeGroupData.length === 0) {
                    const newProductTypeGroupData = [{ ...productTypeGroupBlankObject }];
                    setProductTypeGroupData(newProductTypeGroupData);
                }

                return filteredData;
            });
        }

    }


    const productTypeGroupBlankObject = {
        company_id: COMPANY_ID,
        product_type_group_parameters_name: '',
        product_type_group_parameters_code: '',
        created_by: UserName,
    }

    useLayoutEffect(() => {
        const getExistingProductTypeGroupData = [...productTypeGroupData]
        getExistingProductTypeGroupData.push(productTypeGroupBlankObject)
        setProductTypeGroupData(getExistingProductTypeGroupData)
    }, [rowCount])

    //--------productTypeGroupParameterValue functionality------------------

    const setPValueRowCountAndAddRow = (item) => {

       
        const lastRowIndex = productTypeGroupParameterValueData.length - 1;
        const lastRowProductTypeGroupValueName = productTypeGroupParameterValueData[lastRowIndex].product_type_group_parameters_values_name;
        const lastRowProductTypeGroupValueCode = productTypeGroupParameterValueData[lastRowIndex].product_type_group_parameters_values_code;
        if (lastRowProductTypeGroupValueName !== '' && lastRowProductTypeGroupValueCode !== '') {
            const getExistingProductTypeGroupValueData = [...productTypeGroupParameterValueData]
            productTypeGroupValueBlankObject.group_name = item.group_name
            getExistingProductTypeGroupValueData.push(productTypeGroupValueBlankObject)
            setProductTypeGroupParameterValueData(getExistingProductTypeGroupValueData);
            setRowCountValue(rowCountValue + 1);

        } else {
            const tableRows = document.querySelectorAll('#productTypeGroupValueTbl tbody tr');
            tableRows.forEach(row => {
                const productTypeName = row.querySelector('input[id^="product_type_group_parameters_values_name_"]').value;
                const productTypeCode = row.querySelector('input[id^="product_type_group_parameters_values_code_"]').value;
                if (productTypeName === '') {
                    row.querySelector('input[id^="product_type_group_parameters_values_name_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="product_type_group_parameters_values_name_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="product_type_group_parameters_values_name_"]').parentElement.dataset.tip;
                }
                if (productTypeCode === '') {
                    row.querySelector('input[id^="product_type_group_parameters_values_code_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="product_type_group_parameters_values_code_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="product_type_group_parameters_values_code_"]').parentElement.dataset.tip;
                }
            }
            )
        }

    };

    const FnUpdateProductTypeGroupValueTblRows = (rowData, event) => {
        
        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;

        switch (clickedColName) {
            case 'product_type_group_parameters_values_name':
                rowData[clickedColName] = enteredValue;
                if (enteredValue !== '') {
                    delete event.target.parentElement.dataset.tip;
                }
                break;
            case 'product_type_group_parameters_values_code':
                rowData[clickedColName] = enteredValue;
                if (enteredValue !== '') {
                    delete event.target.parentElement.dataset.tip;
                }
                break;
        }

        const productTypeParameterValue = [...productTypeGroupParameterValueData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        productTypeParameterValue[arrayIndex] = rowData
        setProductTypeGroupParameterValueData(productTypeParameterValue);
    }
    const productTypeGroupValueBlankObject = {
        company_id: COMPANY_ID,
        product_type_id: 0,
        product_type_group_parameters_id: 0,
        product_type_group_parameters_values_name: '',
        product_type_group_parameters_values_code: '',
        group_name: '',
        created_by: UserName,
    }
    const removePValueFirstRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            const updatedProductTypeGroupValueData = productTypeGroupParameterValueData.filter((item, index) => index !== indexToRemove);
            setProductTypeGroupParameterValueData(updatedProductTypeGroupValueData)
        } else {
            const updatedProductTypeGroupValueData = [...productTypeGroupParameterValueData];  // Create a copy of the array
            updatedProductTypeGroupValueData[0] = { ...productTypeGroupValueBlankObject }; // Set values of 0th index to the contactBlankObject
            setProductTypeGroupParameterValueData(updatedProductTypeGroupValueData);
        }
    }

    const handleOpenProductparameterValueAccordian = (rowData) => {
        const lastRowIndex = productTypeGroupData.length - 1;
        const lastRowProductTypeGroup = productTypeGroupData[lastRowIndex].product_type_group_parameters_name;
        const lastRowProductTypeGroupCode = productTypeGroupData[lastRowIndex].product_type_group_parameters_code;

        if (lastRowProductTypeGroup === '' || lastRowProductTypeGroup === undefined || lastRowProductTypeGroupCode === '' || lastRowProductTypeGroupCode === undefined) {
            const tableRows = document.querySelectorAll('#productTypeGroupTbl tbody tr');
            tableRows.forEach(row => {
                const productTypeGroupName = row.querySelector('input[id^="product_type_group_parameters_name_"]').value;
                const productTypeGroupCode = row.querySelector('input[id^="product_type_group_parameters_code_"]').value;
                if (productTypeGroupName === '') {
                    row.querySelector('input[id^="product_type_group_parameters_name_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="product_type_group_parameters_name_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="product_type_group_parameters_name_"]').parentElement.dataset.tip;
                }
                if (productTypeGroupCode === '') {
                    row.querySelector('input[id^="product_type_group_parameters_code_"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="product_type_group_parameters_code_"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="product_type_group_parameters_code_"]').parentElement.dataset.tip;
                }
            });
        } else {
            // Execute your else part only if no fields are null
            setProductTypeGroupParameterValueData(prevData => {
                let newData = prevData;
                if (rowData.product_type_group_parameters_name !== "" && rowData.product_type_group_parameters_name !== undefined) {
                    newData = [
                        ...prevData,
                        { ...productTypeGroupValueBlankObject, group_name: rowData.product_type_group_parameters_name }
                    ];
                }
                if (newData.length === 0 || newData[0].group_name === undefined) {
                    setProductTypeAccordianValueOpen(false);
                } else {
                    setProductTypeAccordianValueOpen(true);
                }
                return newData;
            });

            setRowCountValue(rowCountValue + 1);

        }
    };




    const renderProductTypeGroupTable = useMemo(() => {
        return <Table id='productTypeGroupTbl' className={`erp_table ${productTypeGroupData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr.NO</th>
                    <th className="erp_table_th">Name</th>
                    <th className="erp_table_th">Code</th>

                </tr>
            </thead>
            <tbody>
                {productTypeGroupData.map((item, index) =>
                    <tr rowindex={index} className="sticky-column" >
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(item, index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={setRowCountAndAddRow} />
                            <IoMdAddCircle className='erp_trAdd_icon' onClick={() => handleOpenProductparameterValueAccordian(item)} />

                        </td>

                        <td className='erp_table_td' style={{ width: "10px" }} >
                            {index + 1}
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field mb-0"
                                            id={`product_type_group_parameters_name_${index}`} value={item.product_type_group_parameters_name}
                                            onChange={(e) => {
                                                FnUpdateProductTypeGroupTblRows(item, e);

                                            }}
                                            Headers='product_type_group_parameters_name' maxLength="255" />
                                        <MDTypography variant="button" id="error_product_type_group_parameters_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </>
                                    : item.product_type_group_parameters_name
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" id={`product_type_group_parameters_code_${index}`} className="erp_input_field mb-0"
                                            value={item.product_type_group_parameters_code} Headers='product_type_group_parameters_code' maxLength="4"
                                            onChange={(e) => { FnUpdateProductTypeGroupTblRows(item, e); }}
                                        />
                                        <MDTypography variant="button" id="error_product_type_group_parameters_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </>
                                    : item.product_type_group_parameters_code
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [productTypeGroupData])

    const renderProductTypeGroupParameterValueTbl = useMemo(() => {
        return <Table id='productTypeGroupValueTbl' className={`erp_table ${productTypeGroupParameterValueData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr.NO</th>
                    <th className="erp_table_th">Group Name</th>
                    <th className="erp_table_th">Name</th>
                    <th className="erp_table_th">Code</th>

                </tr>
            </thead>
            <tbody>
                {productTypeGroupParameterValueData.map((item, index) =>
                    <tr rowindex={index} className="sticky-column">
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removePValueFirstRow(index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setPValueRowCountAndAddRow(item)} />

                        </td>

                        <td className='erp_table_td' style={{ width: "10px" }} >
                            {index + 1}
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" id={`group_name_${index}`} className="erp_input_field mb-0"
                                            value={item.group_name} Headers='group_name' maxLength="10"
                                            onChange={(e) => { FnUpdateProductTypeGroupValueTblRows(item, e); }}

                                        />
                                        <MDTypography variant="button" id="error_group_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </>
                                    : item.group_name
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field mb-0"
                                            id={`product_type_group_parameters_values_name_${index}`} value={item.product_type_group_parameters_values_name}
                                            onChange={(e) => { FnUpdateProductTypeGroupValueTblRows(item, e); }}

                                            Headers='product_type_group_parameters_values_name' maxLength="255" />

                                        <MDTypography variant="button" id="error_product_type_group_parameters_values_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </>

                                    : item.product_type_group_parameters_values_name
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" id={`product_type_group_parameters_values_code_${index}`} className="erp_input_field mb-0"
                                            value={item.product_type_group_parameters_values_code} Headers='product_type_group_parameters_values_code' maxLength="4"
                                            onChange={(e) => { FnUpdateProductTypeGroupValueTblRows(item, e); }}

                                        />
                                        <MDTypography variant="button" id="error_product_type_group_parameters_values_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </>
                                    : item.product_type_group_parameters_values_code
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [productTypeGroupParameterValueData, rowCountValue])



    //------------------- Product Type Group Functionality Ends --------------------------------------- //

    return (

        //test case 1,2 shivanjali
        <>
            <ComboBox ref={child} />
            <FrmValidations ref={validate} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                </div> : null}
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Product Type{actionType}</label>
                    </div>

                    <form id="producttypeFormId">
                        <div className="row erp_transporter_div text-start">

                            {/* first row */}

                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        {/* test 4 shivanjali */}
                                        <Form.Label className="erp-form-label">Product Type Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_product_type_name" className="erp_input_field" value={txt_product_type_name} onChange={e => { setProducttypeName(e.target.value); validateFields(); }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_product_type_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Short Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_product_type_short_name" className="erp_input_field" value={txt_product_type_short_name} onChange={e => { setProducttypeShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" />
                                        <MDTypography variant="button" id="error_txt_product_type_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Group Code</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_group_code" className="erp_input_field" value={txt_group_code} onChange={e => { setGroupCode(e.target.value.split(' ').join('')); validateFields(); }} maxLength="2" optional="optional"/>
                                        <MDTypography variant="button" id="error_txt_group_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type Group <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_product_type_group" value={txt_product_type_group} className="form-select form-select-sm erp_input_field" onChange={(e) => { setProducttypeGroup(e.target.value); handleProductTypeChange(); validateFields(); }}>
                                            <option value="">Select</option>
                                            {productTypeGroupOption?.map(product_type_group => (
                                                <option value={product_type_group.field_name}>{product_type_group.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_product_type_group" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            {/* second row */}

                            {/* test 3 shivanjali */}

                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Remark</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_txt_area" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} optional="optional" maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        {/* test case 5 shivanjali */}
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Yes"
                                                    type="radio"
                                                    value="1"
                                                    name="isactive"
                                                    defaultChecked
                                                />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="No"
                                                    value="0"
                                                    type="radio"
                                                    name="isactive"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </form>
                    {/* Conditionally render the Accordion based on the selected product type */}
                    <div className='col-md-12'>
                        <div className="row">
                            <div className="col-sm-6">
                                {txt_product_type_group === 'WV' ? (
                                    <Accordion defaultActiveKey={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' ? "1" : null} className="mt-3">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Product Type Group</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                <div className="mt-10">
                                                    {renderProductTypeGroupTable}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ) : null}
                            </div>

                            {/* Conditionally render the Accordion based on the add button*/}
                            {txt_product_type_group === 'WV' && (
                            <div className="col-sm-6" >
                                {accordionOpenProductTypeparameterValue && (
                                    <Accordion defaultActiveKey="1" className="mt-3">
                                        {/* </Accordion> <Accordion defaultActiveKey={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' ? "1" : null} className="mt-3"> */}
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Product Type Group Parameter</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                <div className="mt-10">
                                                    {renderProductTypeGroupParameterValueTbl}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/ProductTypeListing/reg' : '/Masters/ProductTypeListing';
                                navigate(path);
                            }} variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>

                    </div >
                </div>


                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </div >

        </>
    )

}
