import React, { useState, useRef, useEffect, useMemo } from 'react'
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from 'FrmGeneric/FrmValidations';
import ComboBox from 'Features/ComboBox';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { resetGlobalQuery, globalQuery } from 'assets/Constants/config-constant';

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";

import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import { FiPrinter } from 'react-icons/fi';
import ShortLeave from 'FrmGeneric/Invoice/ShortLeave';
import { renderToString } from "react-dom/server";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

function FrmGatePassEntry() {

    // Config Constant
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate, idList = 0, compType, modules_forms_id } = state || {}

    //Loader
    const [isLoading, setIsLoading] = useState(false);

    const [actionLabel, setActionLabel] = useState('Save');
    const navigator = useNavigate();
    const generateAutoNoAPiCall = useRef();

    //////useRef Hooks
    const comboBoxRef = useRef();
    const validate = useRef();
    const validateNumberDateInput = useRef();
    const navigate = useNavigate();
    let cmb_department_id_ref = useRef(null);
    let subDepartmentComboRef = useRef(null);

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;

    }

    ///Current Time
    const currentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    ////Array Options
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [employeeList, setEmployeeListOption] = useState([]);

    ///Hooks for Header data
    const [dt_gate_passdate, setGatePassDate] = useState(today);
    const [is_gatepasstype, setGatePassType] = useState('personal');
    const [gatepass_outtime, setGatePassOutTime] = useState(currentTime);
    const [gatepass_intime, setGatePassInTime] = useState('00:00');
    const [cmb_employee_id, setEmployeeId] = useState('');
    const [txt_employee_code, setEmployeeCode] = useState();
    const [machine_employee_code, setMachineEmployeeCode] = useState();
    const [short_leave_transaction_id, setShortLeavesTransactionId] = useState(idList);

    // const [cmb_department_id, setDepartmentId] = useState('');
    const [txt_approved_by, setApprovedBy] = useState('');
    const [cmb_approval_status, setApprovedStatus] = useState('Pending');
    const [txt_leave_reason, setLeaveReason] = useState('');
    const [departmentopt, setDepartmentOption] = useState([]);
    const [total_hours, setTotalHours] = useState('');
    const [ApproveFlag, setApproveFlag] = useState(false);
    const [actionType, setActionType] = useState('')
    const [short_leave_id, setShortLeaveId] = useState('')

    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [cmb_departmentId, setDepartmentId] = useState(0);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [cmb_sub_department_id, setSubDepartmentId] = useState(0);
    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        navigate('/Masters/MGatePass/MGatePassListing')
        setShowSuccessMsgModal(false)
    };

    useEffect(async () => {
        debugger
        await ActionType()
        await FillCombos();
        if (idList !== 0) {
            await FnCheckUpdateResponce()
        } else {
            await generateShortLeaveId();
        }
    }, [])


    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("shortLeaveFormId");
                break;
            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve');
                setApprovedStatus('Approved');
                setApproveFlag(true);
                await validate.current.readOnly("shortLeaveFormId");
                $('#txt_approved_by').attr('disabled', false);
                $('#cmb_approval_status').attr('disabled', false);
                break;

            case 'cancel':
                setActionType('(Cancel)');
                setActionLabel('cancel');
                setApprovedStatus('Cancelled');
                await validate.current.readOnly("shortLeaveFormId");
                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    const calculateTotalHours = () => {
        const inTimePunch = document.getElementById('gatepass_intime').value;
        const outTimePunch = document.getElementById('gatepass_outtime').value;
        if (inTimePunch && outTimePunch) {
            const [inHours, inMinutes] = inTimePunch.split(':').map(Number);
            const [outHours, outMinutes] = outTimePunch.split(':').map(Number);

            // Create Date objects for the out and in punch times using the current date
            const currentDate = new Date();

            const outTime = new Date(currentDate);
            outTime.setHours(outHours, outMinutes, 0, 0);

            const inTime = new Date(currentDate);
            inTime.setHours(inHours, inMinutes, 0, 0);

            // If inTime is earlier than outTime, it means it crossed midnight, so add a day to inTime
            if (inTime <= outTime) {
                inTime.setDate(inTime.getDate() + 1);
            }

            // Calculate the difference in milliseconds
            const diffMilliseconds = inTime - outTime;

            // Convert milliseconds to minutes
            const diffMinutes = Math.floor(diffMilliseconds / 60000);

            // Convert to hours and minutes
            const hours = Math.floor(diffMinutes / 60);
            const minutes = diffMinutes % 60;

            // Format the result in HH:MM
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');

            setTotalHours(`${formattedHours}:${formattedMinutes}`);
            return `${formattedHours}:${formattedMinutes}`;
        }
    };

    const FillCombos = async () => {
        try {
            debugger
            resetGlobalQuery();
            // globalQuery.columns.push("field_id");
            // globalQuery.columns.push("field_name");
            // globalQuery.columns.push("employee_code");
            // globalQuery.columns.push("reporting_to");
            // globalQuery.columns.push("reporting_to_name");
            // globalQuery.columns.push("machine_employee_code");
            globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code'];

            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.table = "cmv_employee";
            var employeesAPICall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
            setEmployeeOptions(employeesAPICall);

            // resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name'];
            // globalQuery.table = "cmv_department";
            // // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            // globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            // globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

            // const departmentIdApiCall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
            // setDepartmentOption(departmentIdApiCall);

            // const employeeListApiCall = await comboBoxRef.current.fillMasterData("cmv_employee_list", "", "");
            // setEmployeeListOption(employeeListApiCall);

            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboBoxRef.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    subDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartments);
                });

            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboBoxRef.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setEmployeeListOption(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

        } catch (error) {

        }
    }

    const comboBoxesOnChange = async (key) => {
        try {
            debugger
            switch (key) {
                case 'Employee':
                    debugger
                    var employeeID = $('#cmb_employee_id').val();
                    if (employeeID !== "") {
                        var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeID);
                        setEmployeeCode(employeeCode);

                        let findEmployee = employeeOptions.find((employee) => {
                            return employee.employee_code === employeeCode;
                        })

                        if (findEmployee) {
                            setApprovedBy(findEmployee.reporting_to);
                            setMachineEmployeeCode(findEmployee.machine_employee_code)
                        } else {
                            setApprovedBy('');
                        }

                    }
                    break;
                case "subDepartment":
                    setEmployeeCode('');
                    let selectedDepTS = cmb_department_id_ref.current.department_id;
                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentLists);
                    const departmentIDs = cmb_department_id_ref.current?.department_id || 'All';
                    const subdepartmentIDs = subDepartmentComboRef.current?.value || 'All';
                    if (departmentIDs !== "") {
                        resetGlobalQuery();
                        // globalQuery.columns.push("field_id");
                        // globalQuery.columns.push("field_name");
                        // globalQuery.columns.push("employee_code");
                        // globalQuery.columns.push("reporting_to");
                        // globalQuery.columns.push("reporting_to_name");
                        // globalQuery.columns.push("machine_employee_code");
                        globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code'];
                        globalQuery.table = "cmv_employee";
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        if (departmentIDs !== "" && departmentIDs !== "All") {
                            globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIDs });
                        }
                        if (subdepartmentIDs !== "" && subdepartmentIDs !== "All") {
                            globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIDs });
                        }

                        var employeesAPICall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
                        setEmployeeOptions(employeesAPICall);

                    }

                    break;

                case 'EmployeeCode':
                    // var employeeCode = $('#txt_employee_code').val();
                    var serachemployeeCode = $('#txt_employee_code').val();
                    setEmployeeCode(serachemployeeCode);

                    // let findEmployee = employeeOptions.find((employee) => {
                    //     return employee.employee_code === employeeCode;
                    // })
                    let findEmployee = null;

                    if (serachemployeeCode.length >= 3) {
                        findEmployee = employeeOptions.find((employee) => {
                            return employee.employee_code === serachemployeeCode ||
                                employee?.field_name?.toLowerCase().includes(serachemployeeCode.toLowerCase())
                                ||  employee?.old_employee_code?.includes(serachemployeeCode)
                        });
                    }
                    if (findEmployee) {
                        setEmployeeId(findEmployee.field_id);
                        setApprovedBy(findEmployee.reporting_to);
                        setMachineEmployeeCode(findEmployee.machine_employee_code)
                    } else {
                        setEmployeeId('');
                        setApprovedBy('');
                    }
                    break;
                case 'EmplCode':
                    var employeeID = $('#cmb_employee_id').val();
                    if (employeeID !== "") {
                        var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                        setEmployeeId(employeeID);
                        setEmployeeCode(employeeCode);
                    }
                    break;
                case 'EmployeeList':
                    debugger
                    setEmployeeCode('');
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId(0);
                    let selectedDepartment = cmb_department_id_ref.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);
                    // var departmentId = $('#cmb_departmentId').val();
                    // var subDepartmentId = $('#cmb_departmentId').val();
                    const departmentID = cmb_department_id_ref.current?.department_id || 'All';
                    const subdepartmentID = subDepartmentComboRef.current?.value || 'All';
                    if (departmentID !== "") {
                        resetGlobalQuery();
                        // globalQuery.columns.push("field_id");
                        // globalQuery.columns.push("field_name");
                        // globalQuery.columns.push("employee_code");
                        // globalQuery.columns.push("reporting_to");
                        // globalQuery.columns.push("reporting_to_name");
                        // globalQuery.columns.push("machine_employee_code");
                        globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'sub_department_name', 'machine_employee_code', 'reporting_to', 'reporting_to_name', 'old_employee_code'];
                        globalQuery.table = "cmv_employee";
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        if (departmentID !== "" && departmentID !== "All") {
                            globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentID });
                        }
                        if (subdepartmentID !== "" && subdepartmentID !== "All") {
                            globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentID });
                        }

                        var employeesAPICall = await comboBoxRef.current.fillFiltersCombo(globalQuery);
                        setEmployeeOptions(employeesAPICall);

                    }
                    break;
            }

        } catch (error) {
            console.log("error : ", error)
            navigate('/Error')
        }
    }
    const validateEmployeeForm = async () => {
        debugger
        if (cmb_departmentId === '' || cmb_departmentId === 0) {
            $("#error_cmb_department_id").text("Please fill this field...!");
            $("#error_cmb_department_id").show();
            $("#cmb_departmentId").focus();
            return false;
        } else {
            $("#error_cmb_department_id").hide();
        }
        if (cmb_sub_department_id === '' || cmb_sub_department_id === 0) {
            $("#error_cmb_sub_department_id").text("Please fill this field...!");
            $("#error_cmb_sub_department_id").show();
            $("#cmb_sub_department_id").focus();
            return false;
        } else {
            $("#error_cmb_sub_department_id").hide();
        }

        return true;
    }
    const saveShortLeaves = async () => {
        debugger
        try {
            const checkIsValidate = await validateEmployeeForm();
            if (checkIsValidate === true) {
                const validateForm = await validate.current.validateForm('shortLeaveFormId');
                if (validateForm === true) {
                    const gatePassHeaderData = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        machine_employee_code: machine_employee_code,
                        employee_id: cmb_employee_id,
                        short_leave_id: short_leave_id,
                        financial_year: FINANCIAL_SHORT_NAME,
                        short_leave_transaction_id: short_leave_transaction_id,
                        leave_type: is_gatepasstype,
                        out_time: gatepass_outtime,
                        in_time: gatepass_intime,
                        short_leave_date: dt_gate_passdate,
                        department_id: cmb_department_id_ref.current.department_id,
                        sub_department_id: subDepartmentComboRef.current.department_id,
                        employee_code: txt_employee_code,
                        leave_reason: txt_leave_reason,
                        approved_by: txt_approved_by,
                        approval_status: cmb_approval_status,
                        total_hours: total_hours,
                    }
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(gatePassHeaderData)
                    };
                    const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShortLeave/FnAddUpdateRecord/${ApproveFlag}`, requestOptions)
                    const resp = await apicall.json()
                    console.log(resp);
                    if (resp.success === '0') {
                        setErrMsg(resp.error)
                        setShowErrorMsgModal(true)
                    } else {
                        console.log("resp: ", resp)
                        setSuccMsg(resp.message)
                        setShowSuccessMsgModal(true)
                    }
                }
            }
        } catch (error) {

        }
    }
    const generateShortLeaveId = async () => {
        try {
            const transactionId = await generateAutoNoAPiCall.current.generateTAutoNo("ht_short_leave", "short_leave_id", "", 'SL', "5");
            setShortLeaveId(transactionId);
            return transactionId;
        } catch (error) {
            navigate('/Error')
        }
    }


    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShortLeave/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
            const response = await apiCall.json();
            const shortLeaves = response.data;
            setShortLeaveId(shortLeaves.short_leave_id);
            setDepartmentId(shortLeaves.department_id);

            cmb_department_id_ref.current.department_id = shortLeaves.department_id

            //    await comboBoxesOnChange('EmployeeList');
            setSubDepartmentId(shortLeaves.sub_department_id);
            subDepartmentComboRef.current.department_id = shortLeaves.sub_department_id
            // comboBoxesOnChange('subDepartment');
            setEmployeeCode(shortLeaves.employee_code);
            setEmployeeId(shortLeaves.employee_id);
            setMachineEmployeeCode(shortLeaves.machine_employee_code);
            setGatePassInTime(shortLeaves.in_time);
            setGatePassDate(shortLeaves.short_leave_date);
            setGatePassOutTime(shortLeaves.out_time);
            setLeaveReason(shortLeaves.leave_reason);
            setApprovedBy(shortLeaves.approved_by);
            setGatePassType(shortLeaves.leave_type);
            setTotalHours(shortLeaves.total_hours);
            setApprovedStatus(shortLeaves.approval_status);
            if (keyForViewUpdate === 'cancel') {
                setApprovedStatus('Cancelled');
            } if (keyForViewUpdate === 'approve') {
                setApprovedStatus('Approved');
            }
            if (keyForViewUpdate === 'update' && shortLeaves.approval_status !== 'Pending') {
                await validate.current.readOnly("shortLeaveFormId");
                $('#gatepass_intime').attr('disabled', false);
                $('#gatepass_outtime').attr('disabled', true);
                $('#dt_gate_passdate').attr('disabled', true);
                $('.erp_radio_button').attr('disabled', true);
                $('.erp_radio_button').attr('disabled', true);

            }
            if (keyForViewUpdate === 'update' && shortLeaves.approval_status === 'Pending') {
                document.getElementById('updateTimeNotShow').style.display = 'none';
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    ////Validation starts here
    const validateFields = async () => {
        await validate.current.validateFieldsOnChange('shortLeaveFormId');
    }

    const shortLeaveInvoiceContent = {

        company: '',
        company_branch: '',
        employee_code: '',
        employee_id: '',
        machine_employee_code: '',
        in_time: '',
        employee_code: '',
        department_name: '',
        sub_department_name: '',
        out_time: '',
        short_leave_date: '',
        leave_reason: '',
        approved_by: '',
        total_hours: '',
        approved_by_name: '',
        approval_status: '',
        employee_name: '',
        short_leave_id: '',
    };
    const printInvoice = async (openPrintModal) => {
        debugger
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HtShortLeave/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
        const response = await apiCall.json();
        const shortLeaves = response.data;

        shortLeaveInvoiceContent.company = shortLeaves.company_name
        shortLeaveInvoiceContent.company_branch = shortLeaves.company_branch_name
        shortLeaveInvoiceContent.department_name = shortLeaves.department_name
        shortLeaveInvoiceContent.sub_department_name = shortLeaves.sub_department_name
        shortLeaveInvoiceContent.employee_code = shortLeaves.employee_code
        shortLeaveInvoiceContent.leave_type = shortLeaves.leave_type
        shortLeaveInvoiceContent.employee_id = shortLeaves.employee_id
        shortLeaveInvoiceContent.machine_employee_code = shortLeaves.machine_employee_code
        shortLeaveInvoiceContent.in_time = shortLeaves.in_time
        shortLeaveInvoiceContent.out_time = shortLeaves.out_time
        shortLeaveInvoiceContent.short_leave_date = shortLeaves.short_leave_date
        shortLeaveInvoiceContent.leave_reason = shortLeaves.leave_reason
        shortLeaveInvoiceContent.approved_by = shortLeaves.approved_by
        shortLeaveInvoiceContent.total_hours = shortLeaves.total_hours
        shortLeaveInvoiceContent.approval_status = shortLeaves.approval_status
        shortLeaveInvoiceContent.employee_name = shortLeaves.employee_name
        shortLeaveInvoiceContent.approved_by_name = shortLeaves.approved_by_name
        shortLeaveInvoiceContent.short_leave_id = shortLeaves.short_leave_id


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: shortLeaveInvoiceContent, navigationLink: "/Masters/MGatePass/MGatePassEntry", invoiceType: 'SL', title: 'Short Leaves', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            return renderToString(<ShortLeave invoiceContent={shortLeaveInvoiceContent} />);
        }
    }


    const handleDateChange = (key, date) => {
        switch (key) {
            case 'GatePassDate':
                const GatePassDate = document.getElementById('dt_gate_passdate')
                if (GatePassDate !== '') {
                    $('#error_dt_gate_passdate').hide();
                    setGatePassDate(date);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <ComboBox ref={comboBoxRef} />
            <FrmValidations ref={validate} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                {/* <div className="erp_top_Form"> */}
                <div className='card p-1 mt-3'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Short Leave {actionType} </label>
                    </div>


                    <form id="shortLeaveFormId">

                        <div className='row mt-1'>
                            <div className='col-sm-6 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Short Leave Id :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='short_leave_id' className="erp_input_field" value={short_leave_id} onChange={(e) => { setShortLeaveId(e.target.value); validateFields() }} disabled />
                                        <MDTypography variant="button" id="error_short_leave_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Short Leave Type</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check className="erp_radio_button" label="Personal" type="radio" value="personal" name="gatePassType"
                                                    checked={is_gatepasstype === 'personal'} onClick={() => { setGatePassType('personal'); }} defaultChecked disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')} />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check className="erp_radio_button" label="Official" value="official" type="radio" name="gatePassType"
                                                    checked={is_gatepasstype === 'official'} onClick={() => { setGatePassType('official'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel' || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Department Name<span className="required">*</span></Form.Label>
                                    </div>

                                    <div className="col">
                                        <select id="cmb_department_id" className="form-select form-select-sm" value={cmb_department_id} onChange={e => { validateFields(); comboBoxesOnChange('EmployeeList'); setDepartmentId(e.target.value); }} >
                                            <option value="" disabled>Select</option>
                                            {departmentopt.map(department => (
                                                <option value={department.field_id}>{department.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div> */}
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Select
                                            ref={cmb_department_id_ref}
                                            options={departmentOpts}
                                            inputId="cmb_departmentId"
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate) || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')}
                                            value={departmentOpts.length > 1 ? departmentOpts.find(option => option.value === cmb_departmentId) : null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                cmb_department_id_ref.current = selectedOpt;
                                                // comboBoxesOnChange("department");
                                                comboBoxesOnChange('EmployeeList')
                                                validateFields();
                                            }}
                                            placeholder="Search for a Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Sub-Department <span className="required">*</span></Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate) || (keyForViewUpdate === 'update' && cmb_approval_status !== 'Pending')}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                comboBoxesOnChange("subDepartment");
                                                validateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Employee Code :<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-2">
                                        <Form.Control type="text" id="txt_employee_code" className="erp_input_field " value={txt_employee_code}
                                            onChange={(e) => { comboBoxesOnChange('EmployeeCode'); validateFields(); }}
                                            onBlur={(e) => { comboBoxesOnChange('EmplCode'); }}
                                            maxlength="255" />
                                        <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_employee_id" className="form-select form-select-sm" value={cmb_employee_id} onChange={e => { comboBoxesOnChange('Employee'); validateFields(); }} maxLength="255">
                                            <option value="">Select</option>
                                            {employeeOptions?.map(employee => (
                                                <option value={employee.field_id} employeeCode={employee.employee_code}>{employee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Out Time :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="time" id='gatepass_outtime' className="erp_input_field" value={gatepass_outtime} onChange={(e) => { setGatePassOutTime(e.target.value); validateFields() }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel'} />
                                        <MDTypography variant="button" id="error_gatepass_outtime" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">In Time :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="time" id='gatepass_intime' className="erp_input_field" value={gatepass_intime} onChange={(e) => { setGatePassInTime(e.target.value); calculateTotalHours(); validateFields() }} disabled={keyForViewUpdate === 'add' || keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel'} />
                                        <MDTypography variant="button" id="error_gatepass_intime" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Total Hours</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='total_hours' className="erp_input_field" value={total_hours} onChange={(e) => { setTotalHours(e.target.value); validateFields() }} optional="optional" />
                                        <MDTypography variant="button" id="error_total_hours" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            <div className='col-sm-6 erp_form_col_div'>
                                <div className="row mb-1">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Short Leave Date :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker selected={dt_gate_passdate} id="dt_gate_passdate" onChange={(date) => handleDateChange('GatePassDate', date)}
                                            dateFormat="dd-MM-yyyy" placeholderText="dd-mm-yyyy" className="erp_input_field" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'cancel'} />
                                        {/* <Form.Control type="date" id='dt_gate_passdate' className="erp_input_field" value={dt_gate_passdate} onChange={(e) => { setGatePassDate(e.target.value); validateFields() }} /> */}
                                        <MDTypography variant="button" id="error_dt_gate_passdate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className={`${keyForViewUpdate === 'add' ? 'd-none' : 'display'}`} id="updateTimeNotShow">

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved By<span className="required">*</span></Form.Label>
                                        </div>

                                        <div className="col">
                                            <select id="txt_approved_by" className="form-select form-select-sm optional" value={txt_approved_by}
                                                onChange={e => { setApprovedBy(e.target.value); validateFields(); }}
                                                optional={`${keyForViewUpdate !== 'approve' ? "optional" : ''}`}>
                                                <option value="" disabled>Select</option>
                                                {employeeList?.map(employee => (
                                                    <option value={employee.field_id}>{employee.field_name}</option>

                                                ))}

                                            </select>
                                            <MDTypography variant="button" id="error_txt_approved_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved Status <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="select" id="cmb_approval_status" className="form-select form-select-sm" value={cmb_approval_status} onChange={e => { setApprovedStatus(e.target.value); validateFields(); }} disabled={keyForViewUpdate !== 'approve'} maxLength="255" required>
                                                <option value="Pending">Pending</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Cancelled">Cancelled</option>

                                            </Form.Control>
                                            <MDTypography variant="button" id="error_cmb_approval_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Short Leave Reason
                                            {(is_gatepasstype === 'official') ? <span className="required">*</span> : ""}
                                        </Form.Label>
                                    </div>
                                    <div className="col">

                                        <Form.Control
                                            as="textarea"
                                            className="erp_input_field optional"
                                            id="txt_leave_reason"
                                            value={txt_leave_reason}
                                            onChange={e => { setLeaveReason(e.target.value); validateFields(); }}
                                            optional={`${is_gatepasstype !== 'official' ? "optional" : ''}`}
                                        />
                                        <MDTypography variant="button" id="error_txt_leave_reason" className={`erp_validation ${(is_gatepasstype !== 'official') ? "d-none" : 'display'}`} fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                {/* </div> */}

                                {/* <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Purpose : </Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control as="textarea" rows={2} id="txt_gatepassentrypurpose" className="erp_input_field" value={txt_gatepassentrypurpose} onChange={e => { setGatePassEntryPurpose(e.target.value); validateFields() }} maxLength="255" />
                                    <MDTypography variant="button" id="error_txt_gatepassentrypurpose" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </form>

                    {/* </div> */}
                    <div className="erp_frm_Btns py-2">
                        <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Masters/MGatePass/MGatePassListing')}>Back</MDButton>
                        <MDButton type="submit" id="save_Button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            onClick={() => saveShortLeaves()} fontWeight="regular">{actionLabel}</MDButton>
                        <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
                        </MDButton>
                    </div >
                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout >

        </>
    )
}

export default FrmGatePassEntry