import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const CustomerMaterialIssueSlip = React.forwardRef(({ invoiceContent }, ref) => {
    let AFTER_DECIMAL_PLACES = 2;
    const data = invoiceContent.items
    function numberToWordsWithCurrency(number) {
        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }


    // const AcceptedQuantity = invoiceContent.footer.sumofAcceptedQuantity;
    // const IndentQuantity = invoiceContent.footer.sumIndentQuantity;
    // const ApprovedQuantity = invoiceContent.footer.sumofApprovedQuantity;
    // const IssuedQuantity = invoiceContent.footer.sumofIssuedQuantity;

    // console.log("StockQuantity: ", StockQuantity);
    // console.log("IndentQuantity: ", IndentQuantity);
    // console.log("ApprovedQuantity: ", ApprovedQuantity);
    // console.log("IssuedQuantity: ", IssuedQuantity);


    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>

            </div>
            <div className='row mt-4'>
                <div className='row mt-0'>
                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg"> </dt>
                        {/* <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.requisition_by_name}</dt> */}
                    </div>

                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg ps-4"> </dt>
                        {/* <dt className="erp-invoice-print-label-md-lg ps-4">{invoiceContent.footer.qa_by_name}</dt> */}
                    </div>

                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg ps-3"> </dt>
                        {/* <dt className="erp-invoice-print-label-md-lg ps-3">{invoiceContent.footer.issued_by_name}</dt> */}
                    </div>

                    {/* <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg"></dt>
                    </div>*/}

                    <div className='col text-center'>
                        <dt className="erp-invoice-print-label-md-lg"></dt>
                    </div>
                </div>
            </div>

            <div className='row mt-0    '>
                <div className='col text-center'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg me-2">Requested By</dt>
                    </div>
                </div>

                <div className='col text-center'>
                    <div className='align-bottom-center '>
                        <dt className="erp-invoice-print-label-md-lg">Approved By</dt>
                    </div>
                </div>

                <div className='col text-center me-2'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg">Issued By</dt>
                    </div>
                </div>

                {/* <div className='col text-center'>
                    <div className='align-bottom-center '>
                        <dt className="erp-invoice-print-label-md-lg">Received By</dt>
                    </div>
                </div>*/}

                <div className='col text-center'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg">Store Manager</dt>
                    </div>
                </div>
            </div>


        </div>
    );

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">

                <div className="col-12 pt-2">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-10">
                                                <div className='row'>
                                                    <div className="col-sm-9">
                                                        <div className='erp-invoice-print-label text-center'>
                                                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                            {/* <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span> */}
                                                        </div>
                                                        <div className='erp-invoice-print-label-lg text-center'>
                                                            Customer Material Issue Slip
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <span className='erp-invoice-print-label-md'>Department Copy</span><br />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>CMI No. & version :  {invoiceContent.issuedetails.customer_material_issue_no} & {invoiceContent.issuedetails.customer_material_issue_version} </dt>
                                            </div>

                                            <div className='col-sm-6'>

                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Issue Date : {invoiceContent.issuedetails.customer_material_issue_date}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">
                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue Type Group :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label' style={{ fontSize: '9px' }}> {invoiceContent.header.issue_group_type}</dd> */}

                                                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '10px', height: '20px' }}>Customer :</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg ps-0' style={{ fontSize: '10px', height: '20px' }}> {invoiceContent.header.customer_name} </dd>

                                                                <dt className='col-sm-5  erp-invoice-print-label-md-lg ' style={{ fontSize: '10px', height: '20px' }}>Customer Order No :</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg ps-0' style={{ fontSize: '10px', height: '20px' }}> {invoiceContent.header.customer_order_no}  {invoiceContent.header.customer_order_date}  </dd>

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Customer :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.customer_name} </dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Customer Order No :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.customer_order_no}  {invoiceContent.header.customer_order_date}  </dd> */}


                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">
                                                                <dt className='col-sm-5  erp-invoice-print-label-md-lg ' style={{ fontSize: '10px', height: '20px' }}>Cust Goods Receipt No:</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg ps-0' style={{ fontSize: '10px', height: '20px' }}> {invoiceContent.header.customer_goods_receipt_no}({invoiceContent.header.customer_goods_receipt_date})</dd>


                                                                <dt className='col-sm-5 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Qa by :</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg ps-0' style={{ fontSize: '10px' }}> {invoiceContent.header.qa_by_name}({invoiceContent.header.qa_date})</dd>
                                                                {/* <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue By:</dt> */}
                                                                {/* <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> */}

                                                                {/* {invoiceContent.header.issued_by_name
                                                                        ? `${invoiceContent.header.issued_by_name} (${invoiceContent.issuedetails.issue_date})`
                                                                        : invoiceContent.header.issued_by_name} */}
                                                                {/* </dd> */}
                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Remark :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> </dd> */}

                                                                {/* <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.remark} </dd> */}
                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Raw Material Name(Technical Spect.)</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Unit</th>
                                                                    {/* <th className="erp_invoice_table_th text-center" rowspan={2} style={{ whiteSpace: 'normal', width: '80px' }}>
                                                                        Lead Time <br /> (Days)
                                                                    </th> */}
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Order Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Accepted Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Issued Qty.</th>
                                                                    {/* <th className="erp_invoice_table_th text-center" rowspan={2}>Approved Qty.</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>

                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_material_name}
                                                                                    <span>
                                                                                        {
                                                                                            item.material_tech_spect === ''
                                                                                                ? ''
                                                                                                : <>
                                                                                                    ({
                                                                                                        [item.material_tech_spect].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                                                                </>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                {/* <td className="erp_invoice_table_td">  </td> */}
                                                                                <td className="erp_invoice_table_td text-end">{item.customer_material_order_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_accepted_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_issue_quantity}</td>
                                                                                {/* <td className="erp_invoice_table_td text-end">{item.product_material_approved_quantity}</td> */}
                                                                            </tr>

                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_th">Total</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        {/* <td className="erp_invoice_table_td text-end"></td> */}
                                                                                        <td className="erp_invoice_table_td text-end">{(invoiceContent.footer.sumIndentQuantity)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{(invoiceContent.footer.sumofAcceptedQuantity)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{(invoiceContent.footer.sumofIssuedQuantity)} </td>
                                                                                        {/* <td className="erp_invoice_table_td text-end"> </td> */}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>














                <div className="col-12 pt-2">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-10">
                                                <div className='row'>
                                                    <div className="col-sm-10">
                                                        <div className='erp-invoice-print-label text-center'>
                                                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                            {/* <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span> */}
                                                        </div>
                                                        <div className='erp-invoice-print-label-lg text-center'>
                                                            Customer Material Issue Slip
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <span className='erp-invoice-print-label-md'>Store Copy</span><br />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>CMI No. & version :  {invoiceContent.issuedetails.customer_material_issue_no} & {invoiceContent.issuedetails.customer_material_issue_version} </dt>
                                            </div>

                                            <div className='col-sm-6'>

                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Issue Date : {invoiceContent.issuedetails.customer_material_issue_date}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">
                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue Type Group :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label' style={{ fontSize: '9px' }}> {invoiceContent.header.issue_group_type}</dd> */}

                                                                <dt className='col-sm-5 erp-invoice-print-label-md-lg pt-2 ' style={{ fontSize: '10px', height: '20px' }}>Customer :</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg pt-2 ps-0' style={{ fontSize: '10px', height: '20px' }}> {invoiceContent.header.customer_name} </dd>

                                                                <dt className='col-sm-5  erp-invoice-print-label-md-lg pt-2' style={{ fontSize: '10px', height: '20px' }}>Customer Order No :</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg pt-2 ps-0' style={{ fontSize: '10px', height: '20px' }}> {invoiceContent.header.customer_order_no}  {invoiceContent.header.customer_order_date}  </dd>

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Customer :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.customer_name} </dd>

                                                                <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Customer Order No :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> {invoiceContent.header.customer_order_no}  {invoiceContent.header.customer_order_date}  </dd> */}


                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">
                                                                <dt className='col-sm-5  erp-invoice-print-label-md-lg pt-2' style={{ fontSize: '10px', height: '20px' }}>Cust Goods Receipt No:</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg pt-2 ps-0' style={{ fontSize: '10px', height: '20px' }}> {invoiceContent.header.customer_goods_receipt_no}({invoiceContent.header.customer_goods_receipt_date})</dd>


                                                                <dt className='col-sm-5 erp-invoice-print-label-md-lg pt-2' style={{ fontSize: '10px' }}>Qa by :</dt>
                                                                <dd className='col-sm-7 erp-invoice-print-label-md-lg pt-2 ps-0' style={{ fontSize: '10px' }}> {invoiceContent.header.qa_by_name}({invoiceContent.header.qa_date})</dd>
                                                                {/* <dt className='col-sm-4  erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Issue By:</dt> */}
                                                                {/* <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> */}

                                                                {/* {invoiceContent.header.issued_by_name
                                                                        ? `${invoiceContent.header.issued_by_name} (${invoiceContent.issuedetails.issue_date})`
                                                                        : invoiceContent.header.issued_by_name} */}
                                                                {/* </dd> */}
                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}>Remark :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}> </dd> */}

                                                                {/* <dd className='col-sm-8 erp-invoice-print-label-md-lg' style={{ fontSize: '9px' }}>{invoiceContent.header.remark} </dd> */}
                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Raw Material Name(Technical Spect.)</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Unit</th>
                                                                    {/* <th className="erp_invoice_table_th text-center" rowspan={2} style={{ whiteSpace: 'normal', width: '80px' }}>
                                                                        Lead Time <br /> (Days)
                                                                    </th> */}
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Order Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Accepted Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Issued Qty.</th>
                                                                    {/* <th className="erp_invoice_table_th text-center" rowspan={2}>Approved Qty.</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>

                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_material_name}
                                                                                    <span>
                                                                                        {
                                                                                            item.material_tech_spect === ''
                                                                                                ? ''
                                                                                                : <>
                                                                                                    ({
                                                                                                        [item.material_tech_spect].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                                                                </>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                {/* <td className="erp_invoice_table_td">  </td> */}
                                                                                <td className="erp_invoice_table_td text-end">{item.customer_material_order_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_accepted_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_issue_quantity}</td>
                                                                                {/* <td className="erp_invoice_table_td text-end">{item.product_material_approved_quantity}</td> */}
                                                                            </tr>

                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_th">Total</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        {/* <td className="erp_invoice_table_td text-end"></td> */}
                                                                                        <td className="erp_invoice_table_td text-end">{(invoiceContent.footer.sumIndentQuantity)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{(invoiceContent.footer.sumofAcceptedQuantity)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{(invoiceContent.footer.sumofIssuedQuantity)} </td>
                                                                                        {/* <td className="erp_invoice_table_td text-end"> </td> */}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <style>
                {`
                .erp-invoice-print-label-md-lg,
                .erp_invoice_table_td,
                .erp-invoice-print-label {
                  line-height: 1.5;  
                    }
                `}
            </style>
        </>
    );
})


export default CustomerMaterialIssueSlip;

