import React, { useState, useEffect } from "react";

// File imports
import Datatable from 'components/DataTable';

function FrmMProcessEntry({ data, columns }) {
    function toggleBankChkBoxes(key) {
        switch (key) {
            case "chkAllProcess":
                const selectAll = document.getElementById('chkAllProcess').checked
                if (selectAll) {
                    const bankChkBoxes = document.querySelectorAll('input[name=checkProcess]');
                    for (var checkbox of bankChkBoxes) {
                        checkbox.checked = true;
                    }
                } else {
                    const bankChkBoxes = document.querySelectorAll('input[name=checkProcess]:checked');
                    for (var checkbox of bankChkBoxes) {
                        checkbox.checked = false;
                    }
                }
                break;
        }
    }
    return (
        <>
            <div class="row">
                <div class="col pt-sm-1">
                    <input type='checkbox' id="chkAllProcess" onClick={(e) => toggleBankChkBoxes('chkAllProcess')} /> <label class="erp-form-label pb-1"> Select All </label>
                </div>
            </div>
            <div className="erp_table_scroll">
                <Datatable data={data} columns={columns} />
            </div>
        </>
    )
}
export default FrmMProcessEntry;