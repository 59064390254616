import React from 'react'

// Import React icons
import { RxCheckCircled } from "react-icons/rx";

// React Bootstrap imports
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

function MailSentModal(props) {


    return (
        <>
            <Modal show={props.show[0]} onHide={props.handleCloseMailSentModal} backdrop="static" keyboard={false} centered>
                <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={props.handleCloseMailSentModal}></button></span>
                <Modal.Body className='erp_modal_body'>
                    <span className='erp_modal_success_icon'><RxCheckCircled /></span>
                    <h6>{props.show[1]}</h6>
                    {
                        props.show.length > 2
                            ? <h6>{props.show[2]}</h6>
                            : ""
                    }
                    <h6>Do you wish to Send Mail...?</h6>
                    <Modal.Footer className='erp-modal-footer'>
                        <Button variant="success" className="erp-gb-button" onClick={props.sendConfirm}>
                            Yes
                        </Button>
                        <Button variant="success" className="erp-gb-button ms-2" onClick={props.handleCloseMailSentModal}>
                            NO
                        </Button>

                    </Modal.Footer>
                </Modal.Body>

            </Modal >
        </>
    )
}

export default MailSentModal