import React, { useLayoutEffect, useMemo } from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FrmValidations from "FrmGeneric/FrmValidations";
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from 'Features/ComboBox';
import ErrorModal from 'components/Modals/ErrorModal';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import GenerateTAutoNo from 'FrmGeneric/GenerateTAutoNo';
import { Accordion, Table } from 'react-bootstrap';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { CircularProgress } from '@material-ui/core';
import { item } from 'examples/Sidenav/styles/sidenavItem';
// crated by ujjwala at 11/04/2024
export default function FrmPackingMaterialEntry(props) {
    const validate = useRef();
    const combobox = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const { state } = useLocation();
    const configConstants = ConfigConstants();
    const validateNumberDateInput = useRef();
    const { COMPANY_ID, UserName, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME } = configConstants;
    const { yarnPackingTypesId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

    // Add Yarn Paking Types Form Fields
    const [packingMaterialData, setPackingMaterialData] = useState([])
    const [rowCount, setRowCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false);

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    //master filed hooks 
    const [dt_yarn_packing_date, setYarnPackingDate] = useState(today);
    const [int_sub_lot_no, setSubLotNo] = useState('');

    const [int_lot_starting_no, setLotStartingNo] = useState('');
    const [int_lot_ending_no, setLotEndingNo] = useState('');


    const [cmb_plant_id, setProdPlantName] = useState(1);
    const [dt_yarn_packing_start_date, setYarnPackingStartDate] = useState(today);
    const [dt_yarn_packing_end_date, setYarnPackingEndDate] = useState(today)
    const [cmb_spinn_plan_id, setSpinnPlanId] = useState('')
    const [cmb_customername, setSpinPlanCustomerName] = useState();
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('')
    const [rb_sales_type, setSalesType] = useState('D')
    const [cmb_country_id, setCountryId] = useState('');
    const [rb_production_type, setProductionType] = useState('F')
    const [rb_packing_nature, setPackingNature] = useState('W')
    const [chk_is_active, setIsActive] = useState(true);
    // const [rb_lot_type, setLotType] = useState('N')
    const [cmb_lot_no, setComboLotNo] = useState('');

    const [int_remening_qun, setRemeningQun] = useState('');
    //footer fileds
    const [txt_total_cones, setTotalCones] = useState(0)
    const [txt_total_packages, setTotalPackages] = useState(0)
    const [txt_gross_weight, setGrossWeight] = useState(0)
    const [txt_net_weight, setNetWeight] = useState(0)
    const [txt_dispatched_packages, setDispatchedPackages] = useState(0)
    const [txt_dispatched_weight, setDispatchedWeight] = useState(0)
    const [txt_packing_status, setPackingStatus] = useState('p')

    //options hook
    const [plantOptions, setPlantOptions] = useState([]);
    const [spinnplanOption, setSpinnPlanOption] = useState('')
    const [customerOptions, setCustomerOptions] = useState([]);
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [godownNameOptions, setGodownNameOptions] = useState([]);
    const [lotNoOptions, setLotNoOptions] = useState([]);

    const [unitOptions, setUintOptions] = useState([]);
    const [yarnPackingTypeOptions, setYarnPackingTypeOptions] = useState([]);
    const [weavingProdYarnCountOptions, setWeavingProdYarnCountOptions] = useState([]);
    const [costYarnPackingTypesOption, setCostYarnPackingTypesOption] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);

    const [yarn_packing_master_id, setYarnPackingMasterId] = useState(yarnPackingTypesId);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Show ADD record Modal
    const handleCloseRecModal = async () => {
        await FillComobos()
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MPackingMaterial/FrmPackingMaterialListing`);
        }

    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // For navigate
    const navigate = useNavigate();
    useEffect(async () => {
        setIsLoading(true)
        await ActionType();
        await FillComobos();
        await FnCheckUpdateResponce();
        setIsLoading(false)
    }, [])

    // fill combo onload
    const FillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name',];
            globalQuery.table = "amv_properties";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'YarnPackingTypes' });
            globalQuery.orderBy = ['property_name']
            const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
            setCostYarnPackingTypesOption(profitCenterList);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'customer_name',];
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOptions(getCustomersApiCall);

            combobox.current.fillMasterData("cmv_country", "", "").then((countriesApiCall) => {
                setCountryOptions(countriesApiCall);
            })

            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xm_production_lot_master";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.groupBy = ['lot_no', 'lot_id'];
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((lotTypeApiCall) => {
                setLotNoOptions(lotTypeApiCall)
                // FnSetLotStartAndEndNo(lotTypeApiCall)
            });

            combobox.current.fillMasterData("smv_product_unit", "", "").then((unitApiCall) => {
                setUintOptions(unitApiCall);
            })

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'cone_weight', 'cone_quantity', 'packing_weight', 'yarn_packing_types_id']
            globalQuery.table = "xmv_yarn_packing_types"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((yarnTypeApiCall) => {
                setYarnPackingTypeOptions(yarnTypeApiCall)
            })

            const getProdPlantApiCall = await comboDataAPiCall.current.fillMasterData("cmv_plant", "", "");
            setPlantOptions(getProdPlantApiCall);

            const getSpinnPlantApiCall = await comboDataAPiCall.current.fillMasterData("xtv_spinning_prod_spinn_plan_summary", "", "");
            setSpinnPlanOption(getSpinnPlantApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'production_count_name', 'production_actual_count']
            globalQuery.table = "xmv_spinning_prod_count"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWeavingProdYarnCount) => {
                setWeavingProdYarnCountOptions(getWeavingProdYarnCount);
            })

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "smv_product_rm_fg_sr"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'TX' });

            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((materialList) => {
                setMaterialOptions(materialList);
            })
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'godown_name']
            globalQuery.table = "cmv_godown"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            //globalQuery.conditions.push({ field: "production_sub_section_name", operator: "=", value: 'Weaving (Weaving)' });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((godownTypeApiCall) => {
                setGodownNameOptions(godownTypeApiCall)
            })

            resetGlobalQuery();
            globalQuery.columns.push("customer_order_no");
            globalQuery.columns.push("sales_order_no");
            globalQuery.table = "mtv_sales_order_master_trading_list"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            //globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOrdOpts(getCustomerOrdersApiCall);




        } catch (error) {
            console.log("error : ", error)
        }
    }

    const FnSetLotStartAndEndNo = (lotNoList) => {
        const getLotNoDetails = lotNoList?.find(item => item.lot_id === parseInt(document.getElementById('cmb_lot_no').value))

        if (getLotNoDetails) {
            setLotStartingNo(getLotNoDetails.lot_starting_no)
            setLotEndingNo(getLotNoDetails.lot_ending_no)
        }
    }


    const comboOnChange = async (key) => {
        switch (key) {
            case 'cmb_customername':
                let customerVal = document.getElementById("cmb_customername").value;
                setSpinPlanCustomerName(customerVal);
                if ($('#cmb_customername').val() === '0') {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                else if (customerVal !== "" & customerVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_order_no");
                    globalQuery.columns.push("sales_order_no");
                    globalQuery.table = "mtv_sales_order_master_trading_list"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });


                    const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerOrdOpts(getCustomerOrdersApiCall);
                    setCustomerOrderNo('');

                } else {
                    setCustomerOrdOpts([]);
                    setCustomerOrderNo('');

                }
                break;

            case 'cmb_plant_id':
                let plantId = $('#cmb_plant_id').val();
                setProdPlantName(plantId);
                break;

            case 'cmb_spinn_plan_id':
                let SpinnplantId = $('#cmb_spinn_plan_id').val();
                setSpinnPlanId(SpinnplantId);
                break;

            case 'cmb_lot_no':
                let cmbLotNo = $('#cmb_lot_no').val();
                setComboLotNo(cmbLotNo);

                resetGlobalQuery();
                globalQuery.columns = ['*', 'MAX(yarn_packing_master_id) AS max_yarn_packing_master_id'];
                globalQuery.table = "xtv_spinning_yarn_packing_details";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "lot_id", operator: "=", value: cmbLotNo });

                const lotNoApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log(lotNoApiCall);

                if (lotNoApiCall.some(item => item.lot_id !== null)) {
                    let packingMaterialData = lotNoApiCall.map((item) => {
                        let packingEndingNo = parseInt(item.packing_ending_no);
                        let packingStartingNo = packingEndingNo + 1;
                        //delete item.packing_ending_no;
                        item.packing_quantity = 0;
                        item.packing_gross_weight = 0;
                        item.packing_net_weight = 0;
                        item.packing_ending_no = 0;
                        item.packing_starting_no = packingStartingNo;
                        item.shipping_mark = 'Continue';
                        item.packing_details_status = 'P';
                        item.godown_id = '';
                        item.product_material_id='';
                        console.log(item);
                        return item;
                    });

                    setRemeningQun(packingMaterialData[0].remening_quantity);
                    console.log(packingMaterialData);
                    setSubLotNo(packingMaterialData[0].sub_lot_no);
                    setLotStartingNo(packingMaterialData[0].packing_starting_no)
                    setLotEndingNo(packingMaterialData[0].packing_ending_no)
                    // const [int_lot_starting_no, setLotStartingNo] = useState('');
                    // const [int_lot_ending_no, setLotEndingNo] = useState('');
                    setPackingMaterialData(packingMaterialData);
                    setTotalCones(0);
                    setTotalPackages(0);
                    setNetWeight(0);
                    setGrossWeight(0);
                } else {
                    resetGlobalQuery();
                    globalQuery.columns = ['lot_id', 'sub_lot_no', 'lot_starting_no', 'lot_ending_no', 'production_count_id', 'production_actual_count', 'yarn_packing_types_id', 'product_unit_id', 'cone_weight', 'per_packing_weight', 'lot_starting_no', 'cone_quantity', 'lot_quantity'];

                    globalQuery.table = "xm_production_lot_master";
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.groupBy = ['lot_no'];
                    globalQuery.conditions.push({ field: "lot_id", operator: "=", value: cmbLotNo });

                    const lotNoApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                    let packingMaterialData = lotNoApiCall.map((item) => {
                        //delete item.lot_ending_no;
                        let starno = item.lot_starting_no;
                        item.packing_starting_no = starno;
                        item.packing_quantity = 0;
                        item.packing_gross_weight = 0;
                        item.packing_net_weight = 0;
                        item.packing_ending_no = 0;
                        item.shipping_mark = 'Continue';
                        item.packing_details_status = 'P';
                        item.godown_id = '';
                        item.product_material_id='';
                        console.log(item.lot_quantity);
                        return item;
                    });

                    setRemeningQun(packingMaterialData[0].lot_quantity);
                    setSubLotNo(packingMaterialData[0].sub_lot_no);

                    setPackingMaterialData(packingMaterialData);
                    setTotalCones(0);
                    setTotalPackages(0);
                    setNetWeight(0);
                    setGrossWeight(0);
                }

                FnSetLotStartAndEndNo(lotNoOptions)
        }
    }




    const validateYarnPacking = async () => {
        const headerDataIsValid = await validate.current.validateForm("MaterialPackingFormId");
        if (!headerDataIsValid) {
            return false;
        }
        let addedMaterials = $('#storeLocationTbl tbody tr').length;
        let isValid = true;
        // if (addedMaterials <= 0 && keyForViewUpdate === 'Add') {
        $('#storeLocationTbl tbody tr').each(function () {

            let currentTblRow = $(this).closest('tr');
            let unitId = currentTblRow.find('select[id^="product_unit_id_"]').val();
            let productionOperatorId = currentTblRow.find('input[id^="packing_quantity_"]').val();
            let yarnPackingInput = currentTblRow.find('select[id^="yarn_packing_types_id_"]').val();
            let productionCountInput = currentTblRow.find('select[id^="production_count_id_"]').val();
            let materialId = currentTblRow.find('select[id^="product_material_id_"]').val();
            let godownId = currentTblRow.find('select[id^="godown_id_"]').val();

            if (materialId === '') {
                $(currentTblRow.find('input[id^="product_material_id_"]'))[0].focus();
                $(currentTblRow.find('input[id^="product_material_id_"]'))[0].parentElement.dataset.tip = 'Please select a Material...!';
                isValid = false;
                return false;
            }

            if (productionCountInput === '') {
                $(currentTblRow.find('select[id^="production_count_id_"]'))[0].parentElement.dataset.tip = 'Please select a production count ...!';
                $(currentTblRow.find('select[id^="production_count_id_"]'))[0].focus();
                isValid = false;
                return false;
            }
            if (unitId === '') {
                $(currentTblRow.find('select[id^="product_unit_id_"]'))[0].parentElement.dataset.tip = 'Please select a Product Unit...!';
                $(currentTblRow.find('select[id^="product_unit_id_"]'))[0].focus();
                isValid = false;
                return false;
            }
            if (yarnPackingInput === '') {
                $(currentTblRow.find('select[id^="yarn_packing_types_id_"]'))[0].parentElement.dataset.tip = 'Please select a Yarn Packing ...!';
                $(currentTblRow.find('select[id^="yarn_packing_types_id_"]'))[0].focus();
                isValid = false;
                return false;
            }
            if (productionOperatorId === '' || productionOperatorId === '0') {
                $(currentTblRow.find('input[id^="packing_quantity_"]'))[0].focus();
                $(currentTblRow.find('input[id^="packing_quantity_"]'))[0].parentElement.dataset.tip = 'Packing Quantity should not be zero OR blank ...!';
                isValid = false;
                return false;
            }

            if (godownId === '') {
                $(currentTblRow.find('input[id^="godown_id_"]'))[0].focus();
                $(currentTblRow.find('input[id^="godown_id_"]'))[0].parentElement.dataset.tip = 'Please select a Godown...!';
                isValid = false;
                return false;
            }

        });

        return isValid;
    }



    const handleSubmit = async (e) => {
        try {
            setIsLoading(true)
            let packingIsValid;

            packingIsValid = await validateYarnPacking();

            if (packingIsValid === true) {
                const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': { 'company_id': COMPANY_ID, 'yarn_packing_master_id': yarnPackingTypesId } }
                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    yarn_packing_master_id: yarnPackingTypesId,
                    yarn_packing_date: dt_yarn_packing_date,
                    lot_id: cmb_lot_no,
                    sub_lot_no: int_sub_lot_no,
                    plant_id: cmb_plant_id,
                    yarn_packing_start_date: dt_yarn_packing_start_date,
                    yarn_packing_end_date: dt_yarn_packing_end_date,
                    spinn_plan_id: cmb_spinn_plan_id,
                    customer_id: cmb_customername,
                    customer_order_no: cmb_customer_order_no,
                    sales_type: rb_sales_type,
                    country_id: cmb_country_id,
                    production_type: rb_production_type,
                    packing_nature: rb_packing_nature,
                    remening_quantity: int_remening_qun,
                    //lot_type: rb_lot_type,
                    total_cones: txt_total_cones,
                    total_packages: txt_total_packages,
                    gross_weight: txt_gross_weight,
                    net_weight: txt_net_weight,
                    dispatched_packages: txt_dispatched_packages,
                    dispatched_weight: txt_dispatched_weight,
                    packing_status: txt_packing_status,
                    is_active: chk_is_active,
                    created_by: UserName,
                    modified_by: yarnPackingTypesId === 0 ? null : UserName,
                };

                for (let index = 0; index < packingMaterialData.length; index++) {
                    const element = packingMaterialData[index];
                    const yarnPackingdetailsAddData = {
                        company_branch_id: COMPANY_BRANCH_ID,
                        financial_year: FINANCIAL_SHORT_NAME,
                        company_id: COMPANY_ID,
                        lot_id: cmb_lot_no,
                        yarn_packing_master_id: yarnPackingTypesId,
                        yarn_packing_details_id: element.yarn_packing_details_id,
                        modified_by: yarnPackingTypesId === 0 ? null : UserName,
                        created_by: UserName,
                        yarn_packing_date: dt_yarn_packing_date,
                        sr_no: index + 1,
                        product_material_id: element.product_material_id,
                        production_count_id: element.production_count_id,
                        production_actual_count: element.production_actual_count,
                        product_unit_id: element.product_unit_id,
                        yarn_packing_types_id: element.yarn_packing_types_id,
                        cone_weight: element.cone_weight,
                        cone_quantity: element.cone_quantity,
                        per_packing_weight: element.per_packing_weight,
                        packing_quantity: element.packing_quantity,
                        packing_gross_weight: element.packing_gross_weight,
                        packing_net_weight: element.packing_net_weight,
                        packing_starting_no: element.packing_starting_no,
                        packing_ending_no: element.packing_ending_no,
                        godown_id: element.godown_id,
                        shipping_mark: element.shipping_mark,
                        packing_details_status: element.packing_details_status,
                    }

                    json.TransDetailData.push(yarnPackingdetailsAddData)
                }

                json.TransHeaderData = data;
                console.log('Yarn Packing data', json);
                const formData = new FormData()
                formData.append('SpinningYarnPackingData', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningYarnPackingMaster/FnAddUpdateRecord/`, forwardData)
                const response = await agentApiCall.json();
                if (response.success === 0 || agentApiCall.status !== 200) {
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = await combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(response.message)
                    setShowSuccessMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error", error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    };


    const FnCheckUpdateResponce = async () => {
        try {
            if (yarnPackingTypesId !== 0) {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningYarnPackingMaster/FnShowParticularRecordForUpdate/${yarnPackingTypesId}/${COMPANY_ID}`)
                const updateRes = await apiCall.json();
                const detailsData = updateRes.spinningYarnPackingDetailsRecord
                const masterData = updateRes.spinningYarnPackingMasterRecord
                setYarnPackingMasterId(masterData.yarnPackingTypesId)
                setYarnPackingDate(masterData.yarn_packing_date);
                setSubLotNo(masterData.sub_lot_no);
                setLotStartingNo(masterData.packing_starting_no)
                setLotEndingNo(masterData.packingEndingNo)
                setProdPlantName(masterData.plant_id);
                setYarnPackingStartDate(masterData.yarn_packing_start_date);
                setYarnPackingEndDate(masterData.yarn_packing_end_date);
                setSpinnPlanId(masterData.spinn_plan_id);
                setSpinPlanCustomerName(masterData.customer_id);
                await comboOnChange('cmb_customername');
                setCustomerOrderNo(masterData.customer_order_no);
                setSalesType(masterData.sales_type);
                setCountryId(masterData.country_id);
                setComboLotNo(masterData.lot_id);
                setProductionType(masterData.production_type);
                setPackingNature(masterData.packing_nature);
                setTotalCones(masterData.total_cones);
                setTotalPackages(masterData.total_packages);
                setGrossWeight(masterData.gross_weight);
                setPackingStatus(masterData.packing_status);
                setIsActive(masterData.is_active);
                setNetWeight(masterData.net_weight);
                setRemeningQun(masterData.remening_quantity);
                setDispatchedPackages(masterData.dispatched_packages);
                setDispatchedWeight(masterData.dispatched_weight);
                //updatedata for detail 
                setPackingMaterialData(detailsData);
                //let remQforupdate = (detailsData[0].remening_quantity)
                // setRemeningQun(detailsData[0].remening_quantity)
            }
        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('MaterialPackingFormId')
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#saveBtn').attr('disabled', false);
                $('#jol').attr('disabled', true);
                $('#rrdfg').attr('disabled', true);
                $('#int_sub_lot_no').attr('disabled', true)
                $('#int_lot_starting_no').attr('disabled', true)
                $('#int_lot_ending_no').attr('disabled', true)
                $('#cmb_lot_no').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly("MaterialPackingFormId");
                await validate.current.readOnly("packingMaterialTotalsFrm");

                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };
    // *************************************************Yarn Packing Details***********************************


    const handleYarnPackingTypeChange = (currentProductTypeGroup, e) => {

        let clickedColName = e.target.getAttribute('Headers');
        delete e.target.parentElement.dataset.tip;
        switch (clickedColName) {
            case 'production_count_id':
                currentProductTypeGroup[clickedColName] = parseInt(e.target.value);

                const selectedGodown = weavingProdYarnCountOptions.find(yarnCount => yarnCount.field_id === currentProductTypeGroup[clickedColName]);
                if (selectedGodown) {
                    currentProductTypeGroup.production_actual_count = selectedGodown.production_actual_count
                } else {
                    currentProductTypeGroup.production_actual_count = 0;
                }

                break;


            case 'yarn_packing_types_id':
                currentProductTypeGroup[clickedColName] = parseInt(e.target.value);
                const selectedyarnType = yarnPackingTypeOptions.find(packingType => packingType.field_id === currentProductTypeGroup[clickedColName]);
                if (selectedyarnType) {
                    currentProductTypeGroup.cone_weight = selectedyarnType.cone_weight
                    currentProductTypeGroup.cone_quantity = selectedyarnType.cone_quantity
                    currentProductTypeGroup.per_packing_weight = selectedyarnType.packing_weight
                    let totalCone = 0;
                    packingMaterialData.forEach((item) => {
                        totalCone += parseFloat(item.cone_quantity) || 0;
                    });
                    setTotalCones(validateNumberDateInput.current.decimalNumber(totalCone.toString(), 2));
                }
                else {
                    currentProductTypeGroup.cone_weight = 0;
                    currentProductTypeGroup.cone_quantity = 0;
                    currentProductTypeGroup.per_packing_weight = 0;

                }
                break;
            default:
                break;
        }
        const stopCountTimeDetails = [...packingMaterialData]
        const arrayIndex = parseInt(e.target.parentElement.parentElement.getAttribute('rowindex'))
        stopCountTimeDetails[arrayIndex] = currentProductTypeGroup
        setPackingMaterialData(stopCountTimeDetails);
    }


    const setRowCountAndAddRow = () => {
        debugger

        const tableRows = $('#storeLocationTbl tbody tr');
        let lastRowProductioncount = '';
        let lastRowYarnpacking = '';
        let lastRowProductUnit = '';
        let lastRowMaterial = '';
        let lastRowgodounName = '';
        let lotConVar;

        // if (keyForViewUpdate === 'update') {

        //     lotConVar = packingMaterialData[0].remening_quantity;

        // } else {
        //     lotConVar = packingMaterialData[0].lot_quantity;
        //     if (lotConVar === undefined) {
        //         lotConVar = packingMaterialData[0].remening_quantity
        //     }

        //     // packingMaterialData[0].remening_quantity
        // }
        const remeningQuantity = document.getElementById("int_remening_qun").value;
        //parseInt($('#int_remening_qun').val());
        if (tableRows.length > 0 && remeningQuantity !== 0) {
            const totalPackingQuantity = Array.from(tableRows).reduce((acc, row) => {
                const packingQuantity = parseFloat(row.querySelector('input[id^="packing_quantity_"]').value) || 0;
                return acc + packingQuantity;
            }, 0);

            //   let lotForNext = lotConVar - totalPackingQuantity;
            const lastRowIndex = tableRows.length - 1;
            lastRowProductioncount = tableRows.eq(lastRowIndex).find('select[id^="production_count_id_"]').val();
            lastRowYarnpacking = tableRows.eq(lastRowIndex).find('select[id^="yarn_packing_types_id_"]').val();
            lastRowProductUnit = tableRows.eq(lastRowIndex).find('select[id^="product_unit_id_"]').val();


            lastRowMaterial = tableRows.eq(lastRowIndex).find('select[id^="product_material_id_"]').val();
            lastRowgodounName = tableRows.eq(lastRowIndex).find('select[id^="godown_id_"]').val();
            const lastRowConeQuantity = parseFloat(tableRows.eq(lastRowIndex).find('input[id^="packing_quantity_"]').val());


            if (lastRowMaterial !== '') {
                if (lastRowProductioncount !== '') {
                    if (lastRowYarnpacking !== '') {
                        if (lastRowProductUnit !== '') {
                            if (lastRowConeQuantity !== 0) {
                                if (remeningQuantity !== 0) {
                                    if (lastRowgodounName !== '') {
                                        setRowCount(rowCount + 1);
                                    } else {
                                        const godounNameInput = tableRows.eq(lastRowIndex).find('select[id^="godown_id_"]');
                                        godounNameInput.parent().attr('data-tip', 'Please fill Godown.');
                                        godounNameInput.focus();

                                    }
                                } else {
                                    const coneQuantityInput = tableRows.eq(lastRowIndex).find('input[id^="packing_quantity_"]');
                                    coneQuantityInput.parent().attr('data-tip', 'Packing Quantity fullfil.');
                                    coneQuantityInput.focus();

                                }
                            } else {
                                const productQuantityInput = tableRows.eq(lastRowIndex).find('input[id^="packing_quantity_"]');
                                productQuantityInput.parent().attr('data-tip', 'Please fill Packing Quantity.');
                                productQuantityInput.focus();
                            }
                        } else {
                            const productUnitInput = tableRows.eq(lastRowIndex).find('select[id^="product_unit_id_"]');
                            productUnitInput.parent().attr('data-tip', 'Please fill Product Unit.');
                            productUnitInput.focus();
                        }
                    } else {
                        const yarnPackingInput = tableRows.eq(lastRowIndex).find('select[id^="yarn_packing_types_id_"]');
                        yarnPackingInput.parent().attr('data-tip', 'Please fill Yarn Packing Types.');
                        yarnPackingInput.focus();
                    }
                } else {
                    const productionCountInput = tableRows.eq(lastRowIndex).find('select[id^="production_count_id_"]');
                    productionCountInput.parent().attr('data-tip', 'Please fill Production Count.');
                    productionCountInput.focus();
                }

            } else {
                const productionMaterialInput = tableRows.eq(lastRowIndex).find('select[id^="product_material_id_"]');
                productionMaterialInput.parent().attr('data-tip', 'Please fill Material.');
                productionMaterialInput.focus();
            }
        }

    };


    const removeFirstRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            let lotConVar

            if (keyForViewUpdate === 'update') {

                lotConVar = packingMaterialData[0].remening_quantity;

            } else {
                lotConVar = packingMaterialData[0].lot_quantity;
                if (lotConVar === undefined) {
                    lotConVar = packingMaterialData[0].remening_quantity
                }
                lotConVar = packingMaterialData[0].lot_quantity;
                // packingMaterialData[0].remening_quantity
            }
            const updatedStoreLocationData = packingMaterialData.filter((item, index) => index !== indexToRemove);
            setPackingMaterialData(updatedStoreLocationData);
            let totalcons = 0;
            let totalPackingQuantity = 0;
            let totalNetWeight = 0;
            let totalGrossWeight = 0;
            let remeningQuantity = 0;
            updatedStoreLocationData.forEach((item) => {
                totalcons += parseFloat(item.cone_quantity) || 0;
                totalPackingQuantity += parseFloat(item.packing_quantity) || 0;
                totalNetWeight += parseFloat(item.packing_net_weight) || 0;
                totalGrossWeight += parseFloat(item.packing_gross_weight) || 0;
                remeningQuantity = lotConVar - totalPackingQuantity
            });
            setTotalCones(validateNumberDateInput.current.decimalNumber(totalcons.toString(), 2));
            setTotalPackages(validateNumberDateInput.current.decimalNumber(totalPackingQuantity.toString(), 2));
            setNetWeight(validateNumberDateInput.current.decimalNumber(totalNetWeight.toString(), 2));
            setGrossWeight(validateNumberDateInput.current.decimalNumber(totalGrossWeight.toString(), 2));
            setRemeningQun(remeningQuantity);

        } else {
            const updatedStoreLocationData = [...packingMaterialData];
            updatedStoreLocationData[0] = { ...godownBlankObject };
            setPackingMaterialData(updatedStoreLocationData);
        }
    }


    const godownBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        financial_year: FINANCIAL_SHORT_NAME,
        yarn_packing_date: today(),
        sr_no: 0,
        product_material_id: '',
        production_count_id: 0,
        production_actual_count: 0,
        product_unit_id: 1,
        yarn_packing_types_id: 0,
        cone_weight: 0,
        cone_quantity: 0,
        per_packing_weight: 0,
        packing_quantity: 0,
        packing_gross_weight: 0,
        packing_net_weight: 0,
        packing_starting_no: 1,
        packing_ending_no: 0,
        shipping_mark: 'Continue',
        packing_details_status: 'P',
        godown_id: '',
        created_by: UserName,
    }

    useLayoutEffect(() => {
        const getExistingStoreLocationData = [...packingMaterialData]
        getExistingStoreLocationData.push(godownBlankObject)
        setPackingMaterialData(getExistingStoreLocationData)
    }, [rowCount])

    const FnUpdateStopCountDateAndTimeTblRows = (propertyName, currentMaterial, event) => {
        switch (propertyName) {

            case 'yarn_packing_date':
            case 'production_actual_count':

            case 'cone_weight':
            case 'packing_ending_no':
            case 'packing_details_status':

                currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 2);
                delete event.target.parentElement.dataset.tip;
                break;
            case 'product_unit_id':
            case 'godown_id':
            case 'product_material_id':
                currentMaterial[propertyName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'per_packing_weight':
                currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 2);
                let calTotaledweight = parseFloat(currentMaterial['per_packing_weight']) * currentMaterial['packing_quantity'];
                currentMaterial['packing_gross_weight'] = validateNumberDateInput.current.decimalNumber(calTotaledweight.toString(), 2);
                currentMaterial['packing_net_weight'] = validateNumberDateInput.current.decimalNumber(calTotaledweight.toString(), 2);
                break;


            case 'packing_quantity':
                let inputQuantity = parseFloat(event.target.value);

                let lotConVar;

                if (keyForViewUpdate === 'update') {

                    lotConVar = packingMaterialData[0].remening_quantity + currentMaterial['packing_quantity']

                } else {
                    lotConVar = packingMaterialData[0].lot_quantity;
                    if (lotConVar === undefined) {
                        lotConVar = packingMaterialData[0].remening_quantity
                    }
                }

                if (inputQuantity !== 0 && inputQuantity < lotConVar && lotConVar !== 0 || inputQuantity === lotConVar) {
                    currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(inputQuantity.toString(), 2);

                    let calTotaletimeH = parseFloat(currentMaterial['packing_quantity']) * currentMaterial['per_packing_weight'];
                    currentMaterial['packing_gross_weight'] = validateNumberDateInput.current.decimalNumber(calTotaletimeH.toString(), 2);
                    currentMaterial['packing_net_weight'] = validateNumberDateInput.current.decimalNumber(calTotaletimeH.toString(), 2);

                    let calculetEndNo = parseInt(currentMaterial['packing_quantity']) + parseInt(currentMaterial['packing_starting_no']);
                    currentMaterial['packing_ending_no'] = validateNumberDateInput.current.validateNumber(calculetEndNo.toString(), 2);

                    let totalcons = 0;
                    let totalNetWeight = 0;
                    let totalGrossWeight = 0;
                    let totalPackingQuantity = 0;
                    let remeningQuantity = 0;
                    // lotConVar - inputQuantity;

                    packingMaterialData.forEach((item) => {

                        totalcons += parseFloat(item.cone_quantity) || 0;
                        totalNetWeight += parseFloat(item.packing_net_weight) || 0;
                        totalGrossWeight += parseFloat(item.packing_gross_weight) || 0;
                        totalPackingQuantity += parseFloat(item.packing_quantity) || 0;
                        remeningQuantity = lotConVar - totalPackingQuantity
                    });
                    setTotalCones(validateNumberDateInput.current.decimalNumber(totalcons.toString(), 2));
                    setTotalPackages(validateNumberDateInput.current.decimalNumber(totalPackingQuantity.toString(), 2));
                    setNetWeight(validateNumberDateInput.current.decimalNumber(totalNetWeight.toString(), 2));
                    setGrossWeight(validateNumberDateInput.current.decimalNumber(totalGrossWeight.toString(), 2));

                    setRemeningQun(remeningQuantity);

                    delete event.target.parentElement.dataset.tip;


                    if (event.target.parentElement.dataset.tip) {
                        delete event.target.parentElement.dataset.tip;
                    }
                } else {
                    event.target.parentElement.dataset.tip = 'Packing Quantity should not be Zero OR greater than available Total lot Packing Quantity to fill' + lotConVar;
                    $(`#${event.target.id}`).focus();
                    if (currentMaterial['packing_quantity'] === lotConVar) {
                        delete event.target.parentElement.dataset.tip;

                    }

                }
                break;


            case 'packing_starting_no':
                currentMaterial[propertyName] = validateNumberDateInput.current.validateNumber(event.target.value.toString(), 2);
                let calTotatEndNo = parseInt(currentMaterial['packing_starting_no']) + parseInt(currentMaterial['packing_quantity'])
                currentMaterial['packing_ending_no'] = validateNumberDateInput.current.validateNumber(calTotatEndNo.toString(), 2);
                break;

            case 'shipping_mark':
                currentMaterial[propertyName] = event.target.value;
                if (event.target.value === 'Continue') {
                    currentMaterial['packing_details_status'] = 'P';

                } else if (event.target.value === 'Complete') {
                    currentMaterial['packing_details_status'] = 'W';
                }

                delete event.target.parentElement.dataset.tip;
                break;


            default:
                break;

        }
        const stopCountTimeDetails = [...packingMaterialData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        stopCountTimeDetails[arrayIndex] = currentMaterial
        const anyPackingStatusP = stopCountTimeDetails.some((item) => item['packing_details_status'] === 'P');
        const packingStatus = anyPackingStatusP ? 'P' : 'W';
        setPackingMaterialData(stopCountTimeDetails);
        setPackingStatus(packingStatus);
    }

    const renderStoreLocation = useMemo(() => {
        if (!packingMaterialData) {
            return null;
        }

        return <Table id='storeLocationTbl' className={`erp_table ${packingMaterialData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr No</th>
                    <th className="erp_table_th pe-5">Material</th>
                    <th className="erp_table_th">Prod. Count</th>
                    <th className="erp_table_th">Prod. Actual Count</th>
                    <th className="erp_table_th">Product Unit</th>
                    <th className="erp_table_th">Yarn Packing Types</th>
                    <th className="erp_table_th">Cone Weight</th>
                    <th className="erp_table_th">Cone Quantity</th>
                    <th className="erp_table_th">Per Packing Weight</th>
                    <th className="erp_table_th">Packing Quantity</th>
                    <th className="erp_table_th">Packing Gross Weight</th>
                    <th className="erp_table_th">Packing Net Weight</th>
                    <th className="erp_table_th">Packing Starting No</th>
                    <th className="erp_table_th">Packing Ending No</th>
                    <th className="erp_table_th"> Godown</th>
                    <th className="erp_table_th">Shipping Mark</th>
                    <th className="erp_table_th">Packing Details Status</th>

                </tr>
            </thead>
            <tbody>
                {packingMaterialData.map((item, index) =>
                    <tr rowindex={index} className="sticky-column">
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(index)} />
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`product_material_id_${index}`} value={item.product_material_id} className="erp_input_field mb-0"
                                        Headers="product_material_id" onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("product_material_id", item, e); }} >
                                        <option value="">Select</option>
                                        {materialOptions?.map(material => (<option value={material.field_id}>{material.field_name}</option>))}
                                    </select>
                                    : item.product_material_name
                            }
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`production_count_id_${index}`} value={item.production_count_id} className="erp_input_field mb-0"
                                        Headers="production_count_id"
                                        onChange={(e) => { handleYarnPackingTypeChange(item, e); }}
                                    >
                                        <option value="">Select</option>
                                        {weavingProdYarnCountOptions?.map(yarnCount => (
                                            <option value={yarnCount.field_id}
                                            >{yarnCount.field_name}</option>
                                        ))}

                                    </select>
                                    : item.production_count_id
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <span className="erp_input_field mb-0 text-end" Headers="production_actual_count" id={`production_actual_count_${index}`}>{item.production_actual_count}</span>
                                    </>
                                    : item.production_actual_count
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`product_unit_id_${index}`} value={item.product_unit_id} className="erp_input_field mb-0"
                                        Headers="product_unit_id"
                                        onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("product_unit_id", item, e) }}
                                    >
                                        <option value="">Select</option>
                                        {unitOptions?.map(unitOpt => (
                                            <option value={unitOpt.field_id} >{unitOpt.field_name}</option>
                                        ))}

                                    </select>
                                    : item.product_unit_id
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`yarn_packing_types_id_${index}`} value={item.yarn_packing_types_id} className="erp_input_field mb-0 "
                                        Headers="yarn_packing_types_id"
                                        onChange={(e) => { handleYarnPackingTypeChange(item, e); }}
                                    >
                                        <option value="">Select</option>
                                        {yarnPackingTypeOptions?.map(packingType => (
                                            <option value={packingType.field_id}>{packingType.field_name}</option>
                                        ))}

                                    </select>
                                    : item.yarn_packing_types_id
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <span className="erp_input_field mb-0 text-end" Headers="cone_weight" id={`cone_weight_${index}`}>{item.cone_weight}</span>
                                    </>
                                    : item.cone_weight
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input className="erp_input_field mb-0 text-end" Headers="cone_quantity" id={`cone_quantity_${index}`} value={item.cone_quantity}
                                        />
                                    </>
                                    : item.cone_quantity
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <span className="erp_input_field mb-0 text-end" Headers="per_packing_weight" id={`per_packing_weight_${index}`}>{item.per_packing_weight}</span>
                                    </>
                                    : item.per_packing_weight
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`packing_quantity_${index}`} value={item.packing_quantity}
                                            style={{ backgroundColor: '#AFE1AF', width: '100px' }}
                                            onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("packing_quantity", item, e); }} Headers='packing_quantity' />
                                    </>
                                    : item.packing_quantity
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                    ? <><input
                                        className="erp_input_field_table_txt mb-0 text-end" value={item.packing_gross_weight}
                                        id={`packing_gross_weight_${index}`}
                                        style={{ backgroundColor: '#e9ecef' }}
                                        Headers='packing_gross_weight' onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("packing_gross_weight", item, e); }} />
                                    </>
                                    : item.packing_gross_weight
                            }
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                    ? <><input
                                        className="erp_input_field_table_txt mb-0 text-end"
                                        id={`packing_net_weight_${index}`} value={item.packing_net_weight}
                                        style={{ backgroundColor: '#e9ecef' }}
                                        Headers='packing_net_weight' onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("packing_net_weight", item, e); }} />
                                    </>
                                    : item.packing_net_weight
                            }
                        </td>




                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                    ? <>
                                        <span type="text" className="erp_input_field mb-0 text-end" style={{ backgroundColor: '#e9ecef' }} Headers='packing_starting_no' id={`packing_starting_no_${index}`}>{item.packing_starting_no}</span>
                                        {/* //  onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("packing_starting_no", item, e); }}  */}
                                    </>
                                    : item.packing_starting_no
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                    ? <span
                                        className="erp_input_field_table_txt mb-0 text-end"
                                        id={`packing_ending_no_${index}`}
                                        style={{ backgroundColor: '#e9ecef' }}
                                        Headers='packing_ending_no' >{item.packing_ending_no}
                                    </span>
                                    : item.packing_ending_no
                            }
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`godown_id_${index}`} value={item.godown_id} className="erp_input_field_table_txt mb-0"
                                        Headers="godown_id" onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("godown_id", item, e); }}
                                    >
                                        <option value="">Select</option>
                                        {godownNameOptions?.map(godownType => (
                                            <option value={godownType.field_id}>{godownType.godown_name}</option>
                                        ))}
                                    </select>
                                    : item.godown_id
                            }
                        </td>

                        <td className="erp_table_td">
                            {
                                keyForViewUpdate !== 'view'

                                    ? <select id={`shipping_mark_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.shipping_mark}
                                        onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("shipping_mark", item, e); }} Headers='shipping_mark'>
                                        <option value="Continue">Continue</option>
                                        <option value="Complete">Complete</option>
                                    </select>
                                    : item.shipping_mark

                            }

                        </td>

                        <td className="erp_table_td">
                            <select id={`packing_details_status_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.packing_details_status}
                                onChange={(e) => { FnUpdateStopCountDateAndTimeTblRows("packing_details_status", item, e); }}
                                disabled
                                Headers='packing_details_status'>
                                <option value="P" lbl="Pending">Pending</option>
                                <option value="W" lbl="WIP">WIP</option>
                                <option value="C" lbl="Completed">Completed</option>
                                <option value="D" lbl="Dispatched">Dispatched</option>
                                <option value="PD" lbl="Partial Dispatched">Partial Dispatched</option>
                                <option value="I" lbl="Invoice">Invoice</option>
                                <option value="PC" lbl="PreeClosed">PreeClosed</option>
                                <option value="X" lbl="Canceled">Canceled</option>
                                <option value="DG" lbl="Dispatch Note Generated">Dispatch Note Generated</option>
                            
                            </select>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [packingMaterialData, weavingProdYarnCountOptions, unitOptions, yarnPackingTypeOptions, materialOptions, godownNameOptions])

    return (
        <>
            <FrmValidations ref={validate} />
            <ComboBox ref={combobox} />
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <GenerateMaterialId ref={generateAutoNoAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                </div> : null}

            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'> Packing Material Entry{actionType}</label>
                    </div>
                    <form id="MaterialPackingFormId">
                        <div className="row erp_transporter_div  text-start">
                            {/* first row */}
                            <div className="col-lg-6">

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Yarn Packing Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_yarn_packing_date' className="erp_input_field" value={dt_yarn_packing_date} onChange={e => { setYarnPackingDate(e.target.value); validateFields(); }}

                                        />
                                        <MDTypography variant="button" id="error_dt_yarn_packing_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Prod.Plant Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields(); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id} shortName={plant.field_name}> {plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Lot no.<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_lot_no" className="form-select form-select-sm" value={cmb_lot_no} onChange={(e) => { comboOnChange('cmb_lot_no'); validateFields(); }}>
                                            <option value="" >Select</option>
                                            {lotNoOptions?.map(lotno => (
                                                <option value={lotno.lot_id}> {lotno.lot_no}</option>
                                            ))}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_lot_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Sub Lot no.<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="int_sub_lot_no" maxLength="500" value={int_sub_lot_no}
                                            onChange={e => { setSubLotNo(e.target.value); validateFields(); }}
                                        />
                                        <MDTypography variant="button" id="error_int_sub_lot_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Lot Starting no.</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="int_lot_starting_no" value={int_lot_starting_no}
                                            readOnly maxLength='19'
                                        />
                                        <MDTypography variant="button" id="error_int_lot_starting_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Lot Ending No.</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="int_lot_ending_no" value={int_lot_ending_no}
                                            readOnly maxLength='19'
                                        />
                                        <MDTypography variant="button" id="error_int_lot_ending_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Yarn Packing Start Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_yarn_packing_start_date' className="erp_input_field" value={dt_yarn_packing_start_date} onChange={e => { setYarnPackingStartDate(e.target.value); validateFields(); }}
                                        />
                                        <MDTypography variant="button" id="error_dt_yarn_packing_start_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Yarn Packing End Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_yarn_packing_end_date' className="erp_input_field" value={dt_yarn_packing_end_date} onChange={e => { setYarnPackingEndDate(e.target.value); validateFields(); }}
                                        />
                                        <MDTypography variant="button" id="error_dt_yarn_packing_end_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* second row */}
                            <div className="col-lg-6 ">
                                <div className="row">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Spinn Plan Name<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_spinn_plan_id" className="form-select form-select-sm" value={cmb_spinn_plan_id} onChange={(e) => { comboOnChange('cmb_spinn_plan_id'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                {spinnplanOption.length !== 0 ? (
                                                    <>{spinnplanOption?.map(plant => (
                                                        <option value={plant.field_id}> {plant.field_name}</option>
                                                    ))} </>
                                                ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_spinn_plan_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Customer Name </Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_customername" className="form-select form-select-sm" value={cmb_customername} onChange={(e) => { setSpinPlanCustomerName(e.target.value); comboOnChange('cmb_customername'); validateFields(); }}optional="optional" >
                                                <option value="">Select</option>
                                                {customerOptions.length !== 0 ? (
                                                    <>{customerOptions?.map(customer => (
                                                        <option value={customer.field_id}>{customer.field_name}</option>
                                                    ))}
                                                    </>
                                                ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customername" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Customer order no. </Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); comboOnChange('CustomerOrder'); validateFields(); }} optional="optional">
                                                <option value="">Select</option>
                                                {customerOrdOpts.length !== 0 ?
                                                    <>
                                                        {customerOrdOpts?.map(order => (
                                                            <option value={order.sales_order_no} >{order.customer_order_no}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Country <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select size="sm" id="cmb_country_id" className="form-select form-select-sm" value={cmb_country_id} onChange={(e) => { setCountryId(e.target.value); validateFields(); }}>
                                                <option value="" disabled>Select </option>
                                                {countryOptions?.map(country => (
                                                    <option value={country.field_id} countryname={country.field_name}>{country.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_country_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Sales Type</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Domestic" type="radio" lbl="Domestic" value="D" name="rb_sales_type" checked={rb_sales_type === "D"} onClick={(e) => { setSalesType('D'); }} optional="optional" /> </div>
                                                <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Export" type="radio" lbl="Export" value="E" name="rb_sales_type" checked={rb_sales_type === "E"} onClick={(e) => { setSalesType('E') }} optional="optional" /> </div>
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Sample" type="radio" lbl="Sample" value="S" name="rb_sales_type" checked={rb_sales_type === "S"} onClick={(e) => { setSalesType('S') }} optional="optional" /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Production Type</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Fresh" type="radio" lbl="Fresh" value="F" name="rb_production_type" checked={rb_production_type === "F"} onClick={(e) => { setProductionType('F'); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="RePacking" type="radio" lbl="RePacking" value="R" name="rb_production_type" checked={rb_production_type === "R"} onClick={(e) => { setProductionType('R') }} /> </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Packing Nature</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Waxed" type="radio" lbl="Waxed" value="W" name="rb_packing_nature" checked={rb_packing_nature === "W"} onClick={(e) => { setPackingNature('W'); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="UnWaxed" type="radio" lbl="UnWaxed" value="U" name="rb_packing_nature" checked={rb_packing_nature === "U"} onClick={(e) => { setPackingNature('U') }} /> </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Is Active</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_is_active" checked={chk_is_active === true} onClick={() => { setIsActive(true); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_is_active" checked={chk_is_active === false} onClick={() => { setIsActive(false); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr />
                    <Accordion defaultActiveKey="1" className="mt-3">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="erp-form-label-md">Spinning Yarn Packing Details</Accordion.Header>
                            <Accordion.Body className="p-0">
                                <div className="mt-10">
                                    <>
                                        {renderStoreLocation}
                                    </>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className="card">
                        <div className="card-header main_heding text-start py-1">
                            <label className='erp-form-label-lg'>Totals</label>
                        </div>
                        <div className="card-body">
                            <form id="packingMaterialTotalsFrm">
                                <div className="row">
                                    {/* first column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Total Cones<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_total_cones' className="erp_input_field text-end" value={txt_total_cones} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_total_cones" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Total Packages <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_total_packages' className="erp_input_field  text-end" value={txt_total_packages} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_total_packages" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                    </div>


                                    {/* second column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label">Gross Weight<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_gross_weight' className="erp_input_field text-end" value={txt_gross_weight} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_gross_weight" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label">Net Weight<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_net_weight' className="erp_input_field text-end" value={txt_net_weight} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_net_weight" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>

                                    {/* third column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">PackingStatus</Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="txt_packing_status" className="form-select form-select-sm" value={txt_packing_status} onChange={(e) => { setPackingStatus(e.target.value); }} optional="optional"  >
                                                    <option value="P" lbl="Pending">Pending</option>
                                                    <option value="W" lbl="WIP">WIP</option>
                                                    <option value="C" lbl="Completed">Completed</option>
                                                    <option value="D" lbl="Dispatched">Dispatched</option>
                                                    <option value="I" lbl="Invoice">Invoice</option>
                                                    <option value="PC" lbl="PreeClosed">PreeClosed</option>
                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_sales_order_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                        <div className='row' style={{ display: 'none' }}>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Remening Quantity <span className="required">*</span></Form.Label>
                                            </div>

                                            <div className="col">
                                                <Form.Control type="text" id='int_remening_qun' className="erp_input_field  text-end" value={int_remening_qun} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_int_remening_qun" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Total Section Ends*/}
                    <div className="card-footer py-3 text-center">
                        <MDButton type="button" className="erp-gb-button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MPackingMaterial/FrmPackingMaterialListing/reg' : '/Masters/MPackingMaterial/FrmPackingMaterialListing';

                                navigate(path);
                            }} variant="button" fontWeight="regular"
                        //disabled={props.btn_disabled ? true : false}
                        >Back</MDButton>
                        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>
            </div>

            {/* Add new Record Popup */}
            <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                <Modal.Body className='erp_city_modal_body'>
                    <div className='row'>
                        <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                    </div>
                    <FrmMProductTypeEntry btn_disabled />
                </Modal.Body>

            </Modal >

            {/* Success Msg Popup */}
            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            {/* Error Msg Popup */}
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
        </>
    )

}
