import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { useReactToPrint } from 'react-to-print';
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";

export default function ProfessionalTaxChallanRegister() {
    // Global Constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, FINANCIAL_SHORT_NAME, companyName } = configConstants;

    // UseRefs Hooks.
    const comboDataFunc = useRef();

    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    // ****** navigate
    const navigate = useNavigate();

    // combo's options hooks;
    const [monthOpts, setMonthOpts] = useState([
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ]);
    const [yearOpts, setYearOpts] = useState(
        Array.from({ length: 21 }, (_, i) => parseInt(FINANCIAL_SHORT_NAME || new Date().getFullYear()) - 10 + i)
            .map((year) => ({ label: year, value: year }))
    );
    const [companyOpts, setCompanyOpts] = useState([]);

    // Input field's Hooks;
    const [cmb_year, setYear] = useState('');
    const [cmb_month, setMonth] = useState('');
    const [cmb_company_id, setCompanyId] = useState('');

    // UseRefs Hooks For Searchable combos.
    let monthComboRef = useRef(null);
    let yearComboRef = useRef(null);
    let companyComboRef = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    // Hooks required for the Table.
    const [showPTChallan, setShowPTChallan] = useState(false);
    const [PTChallanData, setPTChallanData] = useState(
        {
            count_less_than_12000: 0,
            count_greater_than_12000: 0,
            pt_amt_below12000: 0,
            pt_amt_above12000: 0,
        }
    );


    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true);
            await FnFillCombos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, []);

    const FnFillCombos = async () => {
        try {
            // Set Default Year.
            let defaultYear = yearOpts.find(year => year.value == new Date().getFullYear());
            setYear(defaultYear.value);
            yearComboRef.current = defaultYear;
            // Set Default Month.
            let defaultMonth = monthOpts.find(month => month.value == (new Date().getMonth() + 1));
            setMonth(defaultMonth.value);
            monthComboRef.current = defaultMonth;

            // Load Companies
            resetGlobalQuery();
            globalQuery.columns = ["company_id", "company_name", "company_address1", "company_address2", "company_pincode", "city_name", "district_name", "state_name"];
            globalQuery.table = "cmv_company"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdCompanies => {
                    rcvdCompanies = rcvdCompanies
                        .map(prop => ({ ...prop, value: prop.company_id, label: prop.company_name }));
                    setCompanyOpts(rcvdCompanies);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigate('/Error')
        }
    }

    const FnGetDataForPTChallan = async () => {
        try {
            if (FnChkIsValidFilters()) {
                setIsLoading(true);
                let dataForPTChallan = {}

                let filterConditions = [
                    { field: "salary_month", operator: "=", value: cmb_month },
                    { field: "salary_year", operator: "=", value: cmb_year },
                    { field: "company_id", operator: "=", value: cmb_company_id },
                    { field: "is_delete", operator: "=", value: 0 },
                ];

                // Take the No. of employee's whose salary is less than or equal to 12000
                resetGlobalQuery();
                globalQuery.columns = [`COALESCE(COUNT(*), 0) AS count_less_than_12000`]
                globalQuery.table = "ht_salary_summary";
                globalQuery.conditions = [...filterConditions]
                globalQuery.conditions.push({ field: "gross_salary", operator: "<=", value: 12000 })
                let getCountSalaryUnder12KApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                dataForPTChallan.count_less_than_12000 = getCountSalaryUnder12KApiCall?.[0]?.count_less_than_12000 ?? 0;

                // Take the No. of employee's whose salary is greater than 12000
                resetGlobalQuery();
                globalQuery.columns = [`COALESCE(COUNT(*), 0) AS count_greater_than_12000`]
                globalQuery.table = "ht_salary_summary";
                globalQuery.conditions = [...filterConditions]
                globalQuery.conditions.push({ field: "gross_salary", operator: ">", value: 12000 })
                let getCountSalaryUpper12KApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                dataForPTChallan.count_greater_than_12000 = getCountSalaryUpper12KApiCall?.[0]?.count_greater_than_12000 ?? 0;

                // // Take the PT Amount for salary less than 12000;
                // resetGlobalQuery();
                // globalQuery.columns = [`professional_tax as pt_amt_below12000`]
                // globalQuery.table = "hm_professional_tax";
                // globalQuery.conditions = [
                //     { field: "lower_limit", operator: "=", value: 0 },
                //     { field: "upper_limit", operator: "=", value: 12000 },
                //     { field: "is_delete", operator: "=", value: 0 },
                // ]
                // let getPtAmtBelow12KApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                // dataForPTChallan.pt_amt_below12000 = getPtAmtBelow12KApiCall?.[0]?.pt_amt_below12000 ?? 0;
                dataForPTChallan.pt_amt_below12000 = 0;

                // Take the PT Amount for salary greater than 12000;
                resetGlobalQuery();
                globalQuery.columns = [`professional_tax as pt_amt_above12000`]
                globalQuery.table = "hm_professional_tax";
                globalQuery.conditions = [
                    { field: "lower_limit", operator: ">", value: 12000 },
                    { field: "is_delete", operator: "=", value: 0 },
                ]
                let getPtAmtAbove12KApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                dataForPTChallan.pt_amt_above12000 = getPtAmtAbove12KApiCall?.[0]?.pt_amt_above12000 ?? 0;


                setPTChallanData(dataForPTChallan);
                setShowPTChallan(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error on FnGetDataForPTChallan: ", error)
            navigate('/Error')
        }
    }


    // resetGlobalQuery();
    // globalQuery.columns = [
    //     `COALESCE(SUM(CASE WHEN gross_salary <= 12000 THEN 1 ELSE 0 END), 0) AS count_less_than_12000`,
    //     `COALESCE(SUM(CASE WHEN gross_salary > 12000 THEN 1 ELSE 0 END), 0) AS count_greater_than_12000`
    // ];
    // globalQuery.table = "ht_salary_summary";
    // globalQuery.conditions = filterConditions;
    // let getEmplSalaryCountApi = await comboDataFunc.current.fillFiltersCombo(globalQuery);
    // if (getEmplSalaryCountApi.length > 0) {
    //     dataForPTChallan.count_less_than_12000 = getEmplSalaryCountApi[0].count_less_than_12000
    //     dataForPTChallan.count_greater_than_12000 = getEmplSalaryCountApi[0].count_greater_than_12000
    // }

    // resetGlobalQuery();
    // globalQuery.columns = [`lower_limit`, `upper_limit`, `professional_tax`];
    // globalQuery.table = "hm_professional_tax";
    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 })
    // let getPTAmtLimitsApi = await comboDataFunc.current.fillFiltersCombo(globalQuery);
    // if (getPTAmtLimitsApi.length > 0) {
    //     dataForPTChallan.pt_amt_below12000 = (getPTAmtLimitsApi.find(bracket => bracket.upper_limit === 12000) || { professional_tax: 0 }).professional_tax;
    //     dataForPTChallan.pt_amt_above12000 = (getPTAmtLimitsApi.find(bracket => bracket.lower_limit === 12001) || { professional_tax: 0 }).professional_tax;
    // }

    function getMonthStartAndEnd(monthNumber, year) {
        const startDate = new Date(year, monthNumber - 1, 1);
        const endDate = new Date(year, monthNumber, 0);
        function formatDate(date) {
            const options = { day: '2-digit', month: 'short', year: '2-digit' };
            const [month, day, year] = new Intl.DateTimeFormat('en-US', options)
                .format(date)
                .replace(',', '')
                .split(' ');
            return `${day}-${month}-${year}`;
        }
        return `${formatDate(startDate)} To ${formatDate(endDate)}`;
    }

    const FnCreatePTChallan = useMemo(() => {
        debugger

        if (showPTChallan === false) { return }
        let fromDateToDate = getMonthStartAndEnd(monthComboRef.current?.value, yearComboRef.current?.value);
        let selectedCompany = companyComboRef?.current;
        let companyName = '';
        let companyAddress = '';
        if (selectedCompany) {
            companyAddress += selectedCompany?.company_address1 || ''
            companyAddress += selectedCompany?.company_address2 || ''

            companyName += selectedCompany?.label || ''
        }


        return (
            <>
                <style>
                    {`
                        .erp-invoice-print-label-md-lg,
                        .erp_invoice_table_td,
                        .erp-invoice-print-label {
                            font-size: 10px;
                        }
                        .erp_table_th, 
                        .erp_table_td{
                            font-size: 11px !important;
                        }
                    `}
                </style>
                <div className="row" style={{ padding: '0px 0px 0px' }}>
                    <div className="col-12">
                        <div className="container-invoice">
                            <div id="content">
                                <div className="invoice p-1" >

                                    {/* Print Header Start*/}
                                    <div className="row mb-2">
                                        <div className="row text-center">
                                            <u><span className='erp-invoice-print-label-lg text-center'>Form 5</span></u>
                                        </div>
                                        <div className="row text-center erp-invoice-print-label">
                                            <span className='erp-invoice-print-label'>
                                                Returns of tax payable by employer under Sub-section (1) of Section 6 of the
                                                <br />Gujarat State , Tax on Professions , Trades, Callings and Employments Act, 1976
                                                <br />(See Rule 11)
                                            </span>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-2 erp-invoice-print-label-md"> <span>AMC-RC No</span> </div>
                                            {/* **************** Add the AMC_RC_NO ** This Number will be hardcoded and told about this to prashant sir on 24-07-24 on call at night.*/}
                                            <div className="col-sm-3 erp-invoice-print-label-md border border-dark ps-1"> <span>RC-04020130040</span> </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4 erp-invoice-print-label">
                                                <span>Return of tax payble for the month ending on:-</span>
                                            </div>
                                            <div className="col-sm-8 erp-invoice-print-label-md">
                                                <span>From Date {fromDateToDate} </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3 erp-invoice-print-label">
                                                <span>Name of the Employer:-</span>
                                            </div>
                                            <div className="col-sm-9 erp-invoice-print-label-md">
                                                <span>{companyName}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3 erp-invoice-print-label">
                                                <span>Address:-</span>
                                            </div>
                                            <div className="col-sm-9 erp-invoice-print-label-md">
                                                <span>{companyAddress}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Print Header End*/}


                                    <div className="row">
                                        <span className='erp-invoice-print-label'>
                                            Details of employes during the month in respect of whom tax is payable are as under :
                                        </span>
                                    </div>

                                    <div className="row">
                                        <span className='erp-invoice-print-label'>
                                            I Details for tax calculation for tax payable in respect pf salaries for the month ending on
                                            <span className="erp-invoice-print-label-md"> From Date {fromDateToDate}</span>
                                        </span>
                                    </div>

                                    <div className="row pt-2">
                                        <div className="col-12">
                                            <Table className="erp_table" style={{ whiteSpace: 'normal' }} bordered>
                                                <thead className="erp_table_head text-center align-top" style={{ whiteSpace: 'normal' }} >
                                                    <tr style={{ whiteSpace: 'normal' }}>
                                                        <th className="erp_table_th">Employees whose <br />monthly salaries or <br />wages are</th>
                                                        <th className="erp_table_th">No of <br />employees</th>
                                                        <th className="erp_table_th">Number of <br />employees for whom <br />no tax is payable <br />under provision to <br />section 4</th>
                                                        <th className="erp_table_th">Number of <br />employees in <br />respect of whom <br />tax is payable <br />{`(i.e. Col.2 minus)`}</th>
                                                        <th className="erp_table_th">Rate of tax per <br />month per <br />employees</th>
                                                        <th className="erp_table_th">Amount of <br />tax <br />deducted</th>
                                                    </tr>
                                                    <tr style={{ whiteSpace: 'normal' }}>
                                                        <th className="erp_table_th">1</th>
                                                        <th className="erp_table_th">2</th>
                                                        <th className="erp_table_th">3</th>
                                                        <th className="erp_table_th">4</th>
                                                        <th className="erp_table_th">5</th>
                                                        <th className="erp_table_th">6</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="erp_table_td">{`i) Rs. 0 to 12000`}</td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.count_less_than_12000} </td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.count_less_than_12000} </td>
                                                        <td className="erp_table_td text-end"> 0 </td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.pt_amt_below12000} </td>
                                                        <td className="erp_table_td text-end"> 0 </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="erp_table_td">{`ii) Rs. 12000 t & above`}</td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.count_greater_than_12000} </td>
                                                        <td className="erp_table_td text-end">  </td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.count_greater_than_12000} </td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.pt_amt_above12000} </td>
                                                        <td className="erp_table_td text-end"> {PTChallanData.count_greater_than_12000 * PTChallanData.pt_amt_above12000} </td>
                                                    </tr>
                                                    <tr className=" ">
                                                        <th className="erp_table_th">Total A Rs</th>
                                                        <th className="erp_table_th text-end"> {PTChallanData.count_less_than_12000 + PTChallanData.count_greater_than_12000} </th>
                                                        <th className="erp_table_th text-end">  {PTChallanData.count_less_than_12000} </th>
                                                        <th className="erp_table_th text-end"> {PTChallanData.count_greater_than_12000} </th>
                                                        <th className="erp_table_th text-end">  </th>
                                                        <th className="erp_table_th text-end"> {PTChallanData.count_greater_than_12000 * PTChallanData.pt_amt_above12000} </th>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>

                                    <div className="row pt-2">
                                        <span className='erp-invoice-print-label'>
                                            11. Details of employess in respect of whom tax is payble at the enhanced rate for previous period on
                                            <br />account of arrears salaries or wages paid during the month.
                                        </span>
                                    </div>

                                    <div className="row pt-2">
                                        <div className="col-12">
                                            <Table className="erp_table" style={{ whiteSpace: 'normal' }}>
                                                <thead className="erp_table_head text-center align-top" style={{ whiteSpace: 'normal' }} >
                                                    <tr style={{ whiteSpace: 'normal' }}>
                                                        <th className="erp_table_th" rowSpan={2}> Number of employees <br />liable to tax at <br /> enhanced rate to be <br />shown separately <br />according to column 4 <br />and column 5   </th>
                                                        <th className="erp_table_th" colSpan={2}> Rate Of Tax</th>
                                                        <th className="erp_table_th" rowSpan={2}> Difference of Rate <br />{`(Col.2 minus Col.3)`}</th>
                                                        <th className="erp_table_th" rowSpan={2}> No. of <br />month for <br />which arrears <br />is paid<br />{`(Col.5)`}</th>
                                                        <th className="erp_table_th" rowSpan={2}> Additional tax <br />payble(Col.1. <br />col 4) </th>
                                                    </tr>

                                                    <tr style={{ whiteSpace: 'normal' }}>
                                                        <th className="erp_table_th"> payble on <br />account of <br />arrears salaries <br />and wages</th>
                                                        <th className="erp_table_th"> At which tax <br />was paid</th>
                                                    </tr>

                                                    <tr style={{ whiteSpace: 'normal' }}>
                                                        <th className="erp_table_th">1</th>
                                                        <th className="erp_table_th">2</th>
                                                        <th className="erp_table_th">3</th>
                                                        <th className="erp_table_th">4</th>
                                                        <th className="erp_table_th">5</th>
                                                        <th className="erp_table_th">6</th>
                                                    </tr>

                                                </thead>
                                                <tbody>

                                                    <tr style={{ height: '40px' }}>
                                                        <td className="erp_table_td"> </td>
                                                        <td className="erp_table_td text-end">  </td>
                                                        <td className="erp_table_td text-end">  </td>
                                                        <td className="erp_table_td text-end">  </td>
                                                        <td className="erp_table_td text-end">  </td>
                                                        <td className="erp_table_td text-end">  </td>
                                                    </tr>
                                                    <tr>
                                                        <th className="erp_table_th"></th>
                                                        <th className="erp_table_th text-end"> </th>
                                                        <th className="erp_table_th text-end"> </th>
                                                        <th className="erp_table_th text-end"> </th>
                                                        <th className="erp_table_th" colSpan={2}> Total B Rs</th>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>

                                    <div className="row pt-2">
                                        <div className="col-sm-10 erp-invoice-print-label-md">
                                            <span>Total Tax payble i.e. Total A+B</span>
                                        </div>
                                        <div className="col-sm-1 erp-invoice-print-label-md"> <span>Rs</span> </div>
                                        <div className="col-sm-1 erp-invoice-print-label-md"> <span>{PTChallanData.count_greater_than_12000 * PTChallanData.pt_amt_above12000}</span> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-10 erp-invoice-print-label">
                                            <span>Add SIimple interest payable (if any) on the above
                                                <br />amount at * [one and a half percent ] per month
                                                <br />or part there of (vide section 9(2)of the Act.)
                                            </span>
                                        </div>
                                        <div className="col-sm-1 erp-invoice-print-label-md pt-4"><span>Rs</span> </div>
                                        <div className="col-sm-1 erp-invoice-print-label-md pt-4"><span></span> </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-10 erp-invoice-print-label">
                                            <span>Total Tax and Interest Payable </span>
                                        </div>
                                        <div className="col-sm-1 erp-invoice-print-label-md"> <span>Rs</span> </div>
                                        <div className="col-sm-1 erp-invoice-print-label-md"> <span>{PTChallanData.count_greater_than_12000 * PTChallanData.pt_amt_above12000}</span> </div>
                                    </div>

                                    <div className="row erp-invoice-print-label">
                                        <span> Amount paid by Receipt No.:- </span>
                                    </div>

                                    <div className="row pt-1">
                                        <div className="col-sm-6 erp-invoice-print-label">
                                            <span>Bank Name :- </span>
                                        </div>
                                        <div className="col-sm-6 erp-invoice-print-label">
                                            <span>Chq.No & Dt:- </span>
                                        </div>
                                    </div>

                                    <div className="row pt-2 erp-invoice-print-label">
                                        <span>
                                            I certify that all the employees who ae liable to pay the tax in my employ during the period of return have
                                            <br />been convered by the foregoing particulars. I also certify that the necessary revision in the amount of the
                                            <br />tax deductable from the salary or wages of the employees on account of variation in the salary or wages by
                                            <br />them has been made where necessary.
                                        </span>
                                    </div>

                                    <div className="row pt-2 erp-invoice-print-label">
                                        <span>
                                            I.Shri.......................................................................................................................................................................
                                        </span>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-8 erp-invoice-print-label">
                                            <span>
                                                Declare that the above statements are true to the best of my knowledge
                                                <br />Place:- Ahmedabad
                                                <br />Date:-
                                                <br />Stamp
                                            </span>
                                        </div>
                                        <div className="col-sm-4 erp-invoice-print-label">
                                            <div>
                                                <span className="text-start">Signature:-</span>
                                                <br /><span className="erp-invoice-print-label-md">For, {companyName}</span>
                                            </div>

                                            <div className="pt-2">
                                                <span className="erp-invoice-print-label">Partner</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }, [showPTChallan, PTChallanData])



    // ****-------Validation Functions Starts--------------***
    // Function for validate the fields at the time of button click;
    const FnChkIsValidFilters = () => {
        if (cmb_company_id === '') {
            $('#error_cmb_company_id').text('Please select the atleast one...!')
            $('#error_cmb_company_id').show();
            return false;
        } else {
            $('#error_cmb_company_id').hide();
        }

        if (cmb_year === '') {
            $('#error_cmb_year').text('Please select the atleast one...!')
            $('#error_cmb_year').show();
            return false;
        } else {
            $('#error_cmb_year').hide();
        }

        if (cmb_month === '') {
            $('#error_cmb_month').text('Please select the atleast one...!')
            $('#error_cmb_month').show();
            return false;
        } else {
            $('#error_cmb_month').hide();
        }
        return true;
    }


    // Function for validate the fields on change
    const FnValidateFields = () => {
        setShowPTChallan(false)
        const isEmpty = str => {
            if (typeof str === 'number' && Number.isInteger(str)) {
                str = str.toString();
            }
            return str === undefined || str === null || str.trim() !== '';
        };
        if (isEmpty(cmb_month)) { $('#error_dt_att_to').hide(); }
        if (isEmpty(cmb_year)) { $('#error_dt_att_from').hide(); }
        if (isEmpty(cmb_company_id)) { $('#error_cmb_company_id').hide(); }

    }
    // ****-------Validation Functions Ends--------------***

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintReport = useReactToPrint({
        content: () => {
            const contentWrapper = document.createElement('div');
            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);
            return contentWrapper;
        },
        onBeforePrint: () => {
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Profssional Tax Challan For ${monthComboRef.current?.label}-${yearComboRef.current?.value}`
    });



    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }

    return (
        <>
            <ComboBox ref={comboDataFunc} />


            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-lg">Professional Tax Challan Register</label>
                    </div>
                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Year<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={yearComboRef}
                                            options={yearOpts}
                                            inputId="cmb_year"
                                            value={yearOpts.find(option => option.value == cmb_year) || null}
                                            onChange={(selectedOpt) => {
                                                setYear(selectedOpt.value);
                                                yearComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a year..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* First Column Ends*/}

                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Month<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={monthComboRef}
                                            options={monthOpts}
                                            inputId="cmb_month"
                                            value={monthOpts.find(option => option.value == cmb_month) || null}
                                            onChange={(selectedOpt) => {
                                                setMonth(selectedOpt.value);
                                                monthComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a month..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_salary_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* Second Column Ends*/}

                            {/* Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3"> <Form.Label className="erp-form-label"> Company<span className="required">*</span> </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={companyComboRef}
                                            options={companyOpts}
                                            inputId="cmb_company_id"
                                            value={companyOpts.find(option => option.value == cmb_company_id) || null}
                                            onChange={(selectedOpt) => {
                                                setCompanyId(selectedOpt.value);
                                                companyComboRef.current = selectedOpt;
                                                FnValidateFields();
                                            }}
                                            placeholder="Search for a company..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_company_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* Third Column Ends*/}

                        </div>

                        <hr />
                        <div className="row justify-content-center">
                            <div className="col text-center">
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                <MDButton type="button" id="show-data-btn" className="erp-gb-button ms-1" variant="button" fontWeight="regular"
                                    onClick={(e) => { FnGetDataForPTChallan(); }}
                                >Generate</MDButton>
                            </div>
                        </div>

                        {
                            showPTChallan
                                ? <>
                                    <hr />
                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            <div className="card-header py-0 main_heding mb-0">
                                                <label className="erp-form-label-md-lg">Professional Tax Challan</label>
                                            </div>
                                            {/* Card's Body Starts*/}
                                            <div className="card-body print-content" ref={printComponentRef}>
                                                {FnCreatePTChallan}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row text-center mt-3">
                                        <div className=''>
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintReport(); }} >Print</MDButton>
                                        </div>
                                    </div>
                                </>
                                : <></>
                        }
                    </div>
                </div>


            </DashboardLayout>

        </>
    )
}
