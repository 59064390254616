import { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Imports React bootstra
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import { resetGlobalQuery } from "assets/Constants/config-constant";
import { globalQuery } from "assets/Constants/config-constant";
import { Table } from "react-bootstrap";

function FrmWeavingProductionPlanEntry(props) {
    //changes by ujjwala on 4/3/2024
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { weavingProductionPlanMasterId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}
    // Use Refs to call external functions
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const navigate = useNavigate();

    // production section Feilds
    const [txt_weaving_production_plan_master_id, setWeavingProductionPlanMasterId] = useState(weavingProductionPlanMasterId === null ? 0 : weavingProductionPlanMasterId);
    const [cmb_machine_type_id, setMachineTypeId] = useState('');
    const [cmb_product_id, setProductId] = useState('');
    const [cmb_product_description, setProductDescription] = useState('');
    const [cmb_product_fg_stock_unit_id, setProductUnitId] = useState('');
    const [cmb_product_fg_stock_unit_name, setProductUnitName] = useState('');
    const [txt_target_efficiency, setTargetEfficiency] = useState(0);
    const [txt_target_rpm, setTargetRpm] = useState(0);
    const [int_Width, setWidth] = useState(0);
    const [txt_warp_glm, setWarpGlm] = useState(0);
    const [txt_weft_glm, setWeftGlm] = useState(0);
    const [cmb_warp_Count, setWarpCount] = useState('');
    const [txt_warp_actual_count, setWarpActualCount] = useState('');
    const [cmb_weft_Count, setWeftCount] = useState('');
    const [txt_weft_actual_count, setWeftActualCount] = useState('');
    const [txt_epi, setEPI] = useState(0);
    const [txt_ppi, setPPI] = useState(0);
    const [txt_warp_crimp_per, setWarpCrimpPer] = useState(0);
    const [txt_weft_crimp_per, setWeftCrimpPer] = useState(0);
    const [txt_warp_waste_per, setWarpWastePer] = useState(0);
    const [txt_weft_waste_per, setWeftWastePer] = useState(0);
    const [txt_Warp_requirement_for_1_mtr_cloth_KG, setWarpRequirementFor1MtrClothKg] = useState(0);
    const [txt_Weft_requirement_for_1_mtr_cloth_KG, setWeftRequirementFor1MtrClothKg] = useState(0);
    const [txt_GLM_without_size, setGLMWithoutSize] = useState(0);
    const [txt_gsm, setGsm] = useState(0);
    const [txt_glm, setGlm] = useState(0);
    const [txt_sort_no, setSortNo] = useState();
    const [txt_no_of_ends, setNoOfEnds] = useState();
    //option Box
    const [productTypeOptions, setProductTypeOptions] = useState([])
    const [weavingProdYarnCountOptions, setWeavingProdYarnCountOptions] = useState([]);
    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [WarpWeftTableDetails, setWarpWeftTableDetails] = useState([])

    const [Warpct, setWarp] = useState([])

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanListing`)
        }
    }

    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [picks_ends_per_mtr, setPicksPerMtr] = useState('');
    const [endsPerMtr, setEndsPerMtr] = useState('');
    const [actualCount, setActualCout] = useState('');
    const [yarnReqPerMtr, setYarnReqPerMtr] = useState('');
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await fillComobos();
            if (weavingProductionPlanMasterId !== 0) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])


    $(document).on('click', '.erp_input_field.remove0', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".erp_input_field.remove0");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });


    const validateNo = (key, value, setter) => {
        const regexNo = /^[0-9]*\.?[0-9]{0,4}$/;
        if (regexNo.test(value)) {
            setter(value);
        }
    };

    const handleFieldChange = (e, field, setter) => {
        const value = e.target.value;
        validateNo(field, value, setter);
    };


    const addWeavingProductionPlan = async () => {
        debugger
        try {
            // const checkIsValidate = await validate.current.validateForm('WeavingProductionform');
            // const checkPlanValidation = await validateWaevingplan();
            let checkPlanValidation = true;
            if (keyForViewUpdate === 'Add') {
                checkPlanValidation = await validateWaevingplan();
            }

            if (checkPlanValidation === true) {
                const json = { 'WeavingProdSpecSheetMaster': {}, 'WeavingProdSpecSheetDetails': [], 'commonIds': { 'company_id': COMPANY_ID, 'weaving_production_plan_master_id': txt_weaving_production_plan_master_id, } }

                const data = {
                    company_id: COMPANY_ID,
                    weaving_production_plan_master_id: txt_weaving_production_plan_master_id,
                    product_id: cmb_product_id,
                    created_by: UserName,
                    modified_by: txt_weaving_production_plan_master_id === 0 ? null : UserName,
                    machine_type_id: cmb_machine_type_id,
                    product_fg_stock_unit_id: cmb_product_fg_stock_unit_id,
                    width: int_Width,
                    warp_glm: 0,
                    weft_glm: 0,
                    gsm: txt_gsm,
                    glm: txt_glm,
                    sort_no: txt_sort_no,
                    no_of_ends: txt_no_of_ends,
                    warp_yarn_count_id: cmb_warp_Count,
                    weft_yarn_count_id: cmb_weft_Count,
                    warp_yarn_count: txt_warp_actual_count,
                    weft_yarn_count: txt_weft_actual_count,
                    warp_crimp: txt_warp_crimp_per,
                    weft_crimp: txt_weft_crimp_per,
                    warp_waste: txt_warp_waste_per,
                    weft_waste: txt_weft_waste_per,
                    epi: txt_epi,
                    ppi: txt_ppi,
                    target_rpm: txt_target_rpm,
                    target_efficiency: txt_target_efficiency,
                    warp_req_per_mtr: txt_Warp_requirement_for_1_mtr_cloth_KG,
                    weft_req_per_mtr: txt_Weft_requirement_for_1_mtr_cloth_KG,
                    glm_without_size: txt_GLM_without_size,
                };

                json.WeavingProdSpecSheetMaster = data;
                json.WeavingProdSpecSheetDetails = WarpWeftTableDetails;
                console.log('Weaving data', json);
                const formData = new FormData()
                formData.append('WeavingProdSpecSheet', JSON.stringify(json))
                const forwardData = {
                    method: 'POST',
                    body: formData,
                }
                const agentApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmWeavingProductionPlanMaster/FnAddUpdateRecord`, forwardData)

                const response = await agentApiCall.json();
                if (response.error !== "") {
                    console.log("response error: ", response.error);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    clearFormFields()
                    setSuccMsg(response.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    };

    const fillComobos = async () => {
        resetGlobalQuery();
        globalQuery.columns = ['field_id', 'field_name', 'product_fg_name', 'product_fg_tech_spect', 'product_fg_stock_unit_name', 'product_fg_stock_unit_id']
        globalQuery.table = "smv_product_fg_summary"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'WV' });
        comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((productTypeApiCall) => {
            setProductTypeOptions(productTypeApiCall)
        })

        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "xmv_spinning_prod_count"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWeavingProdYarnCount) => {
            setWeavingProdYarnCountOptions(getWeavingProdYarnCount);
        })
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#cmb_product_id').attr('disabled', true)
                $('#cmb_machine_type_id').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("WeavingProductionform");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('WeavingProductionform')
    }

    //************************************add fuctionalty******************************************* */
    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmWeavingProductionPlanMaster/FnShowParticularRecordForUpdate/${txt_weaving_production_plan_master_id}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.WeavingProductionPlanSummaryRecords
            const fabricSpecDetails = updateRes.WeavingProductionSpecSheetDetailsRecord
            setWeavingProductionPlanMasterId(data.weaving_production_plan_master_id)
            setProductId(data.product_id);
            setProductDescription(data.product_fg_tech_spect);
            setMachineTypeId(data.machine_type_id);
            setProductUnitId(data.product_fg_stock_unit_id);
            setProductUnitName(data.product_fg_stock_unit_name);
            setTargetEfficiency(data.target_efficiency)
            setTargetRpm(data.target_rpm);
            setWidth(data.width);
            setWarpGlm(data.warp_glm);
            setWeftGlm(data.weft_glm);
            setGsm(data.gsm);
            setGlm(data.glm);
            setSortNo(data.sort_no);
            setNoOfEnds(data.no_of_ends);
            setWarpCount(data.warp_yarn_count_id)
            setWarpActualCount(data.warp_yarn_count)
            setWeftCount(data.weft_yarn_count_id)
            setWeftActualCount(data.weft_yarn_count)
            setEPI(data.epi)
            setPPI(data.ppi)
            setWarpCrimpPer(data.warp_crimp)
            setWeftCrimpPer(data.weft_crimp)
            setWarpWastePer(data.warp_waste)
            setWeftWastePer(data.weft_waste)
            setWarpRequirementFor1MtrClothKg(data.warp_req_per_mtr)
            setWeftRequirementFor1MtrClothKg(data.weft_req_per_mtr)
            setGLMWithoutSize(data.glm_without_size)

            setWarpWeftTableDetails(fabricSpecDetails);
        } catch (error) {
            console.log("error: ", error)
        }
    }


    const comboOnChange = async (key) => {
        debugger

        switch (key) {
            case 'productType':
                let productTpVal = document.getElementById('cmb_product_id').value;
                if (productTpVal === '0') {
                    setProductId(productTpVal)
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");

                    }, 200)
                    setProductDescription('');
                    setProductUnitId('');
                    setProductUnitName('');
                    setTargetEfficiency('');
                    setTargetRpm('');
                    setYarnReqPerMtr('');


                } else if (productTpVal !== "" && productTpVal !== NaN) {
                    setWarpWeftTableDetails([]);
                    clearFormFields();
                    let selectedProdType = productTypeOptions.find((type) => type.field_id === productTpVal);
                    if (selectedProdType) {
                        setProductDescription(selectedProdType.product_fg_tech_spect);
                        setProductUnitId(selectedProdType.product_fg_stock_unit_id);
                        setProductUnitName(selectedProdType.product_fg_stock_unit_name)

                        resetGlobalQuery();
                        globalQuery.columns.push('*');
                        globalQuery.table = "smv_product_dynamic_parameters"
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                        globalQuery.conditions.push({ field: "product_id", operator: "=", value: selectedProdType.field_id });
                        const getPropertyvaluesApicall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                        console.log('get property name', getPropertyvaluesApicall);

                        // Check if either warpCount or weftCount arrays are not empty
                        const warpCount = getPropertyvaluesApicall.filter(property => property.product_parameter_name.includes("Warp Count"));
                        const weftCount = getPropertyvaluesApicall.filter(property => property.product_parameter_name.includes("Weft Count"));

                        // Check if either warpCount or weftCount arrays are not empty
                        if (warpCount.length > 0 || weftCount.length > 0) {
                            // Combine both arrays
                            const combinedCounts = [...warpCount, ...weftCount];

                            // Iterate over combinedCounts to find the matching data
                            combinedCounts.forEach(count => {
                                const option = weavingProdYarnCountOptions.find(option => option.production_count_id.toString() === count.product_parameter_value);
                                if (option !== undefined) {
                                    // Update the final production count name and actual count
                                    count.prod_count_name = option.production_count_name;
                                    count.actual_count = option.production_actual_count;
                                }
                            });

                            // Set the modified array to your state or variable
                            setWarpWeftTableDetails(combinedCounts);
                        } else {
                            setWarpWeftTableDetails([]);
                        }


                        const noOfEndsProperty = getPropertyvaluesApicall.find(property => property.product_parameter_name === "No. of ends");
                        if (noOfEndsProperty) {
                            setNoOfEnds(noOfEndsProperty.product_parameter_value);
                        } else {
                            setNoOfEnds('')
                        }
                        const sortNoProperty = getPropertyvaluesApicall.find(property => property.product_parameter_name === "Sort Number");
                        if (sortNoProperty) {
                            setSortNo(sortNoProperty.product_parameter_value);
                        } else {
                            setSortNo('')
                        }

                    } else {
                        setProductDescription('');
                        setProductUnitId('');
                        setProductUnitName('');
                        setWarpCount('');
                        setWeftCount('');
                        setSortNo('');
                    }
                } else {
                    setProductDescription('');
                    setProductUnitId('');
                    setProductUnitName('');
                    setWarpCount('');
                    setWeftCount('');
                    setSortNo('');
                    setWarpActualCount(0)
                    setWeftActualCount(0)

                }
                break;
        }
    }

    const clearFormFields = () => {
        setWeavingProductionPlanMasterId(0)
        //  setMachineTypeId('');
        //setProductId('');
        setProductDescription('');
        setProductUnitId('');
        setProductUnitName('');
        setTargetEfficiency(0);
        setTargetRpm(0);
        setWidth(0);
        setWarpGlm(0);
        setWeftGlm(0);
        setGsm(0);
        setGlm(0);
        setSortNo('');
        setNoOfEnds('');
        setPPI(0);
        setEPI(0);
        setWarpCrimpPer(0)
        setWeftCrimpPer(0);
        setWarpWastePer(0);
        setWeftWastePer(0);
        setGLMWithoutSize(0);
    }

    const handleInputChange = (e) => {
        debugger
        const { id } = e.target;
        const value = document.getElementById(id).value;
        switch (id) {
            case 'txt_warp_actual_count':
                setWarpActualCount(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_weft_actual_count':
                setWeftActualCount(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_epi':
                setEPI(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_ppi':
                setPPI(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'int_Width':
                setWidth(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_warp_crimp_per':
                if (validateNumberDateInput.current.percentValidate(value)) {
                    setWarpCrimpPer(value);
                }
                break;
            case 'txt_weft_crimp_per':
                if (validateNumberDateInput.current.percentValidate(value)) {
                    setWeftCrimpPer(value);
                }
                break;
            case 'txt_warp_waste_per':
                if (validateNumberDateInput.current.percentValidate(value)) {
                    setWarpWastePer(value);
                }
                break;
            case 'txt_weft_waste_per':
                if (validateNumberDateInput.current.percentValidate(value)) {
                    setWeftWastePer(value);
                }
                break;

            //--------------//
            case 'txt_GLM_without_size':
                setGLMWithoutSize(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_glm':
                setGlm(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_gsm':
                setGsm(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_target_efficiency':
                setTargetEfficiency(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_target_rpm':
                setTargetRpm(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            case 'txt_no_of_ends':
                setNoOfEnds(validateNumberDateInput.current.decimalNumber(value, 4));
                break;
            default:
                break;

        }
        // validateFields();
    }



    const validateWaevingplan = async () => {
        debugger
        let productTpVal = document.getElementById('cmb_product_id').value;
        try {
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xm_weaving_production_spec_sheet_master";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "machine_type_id", operator: "=", value: cmb_machine_type_id });
            globalQuery.conditions.push({ field: "product_id", operator: "=", value: productTpVal });

            const productvalidationCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (productvalidationCall.length !== 0) {
                $('#error_cmb_product_id').text("Plan combination is already exist, please choose a different plan");
                $('#error_cmb_product_id').show();
                return false;
            } else {
                $(`#error_cmb_product_id`).hide();
                return true;
            }
        } catch (error) {
            console.log("error: ", error);
            return false;
        }
    };
    const renderDetailsTable = useMemo(() => {
        return <>
            <div >
                <Table className="erp_table erp_table_scroll" responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className="erp_table_th" rowSpan={2}>Sr.no</th>
                            <th className="erp_table_th" rowSpan={2}>Type</th>
                            <th className="erp_table_th" rowSpan={2}>Prod.Count Name</th>
                            <th className="erp_table_th" rowSpan={2}>Picks/Ends per Mtr</th>
                            <th className="erp_table_th" rowSpan={2}>Actual Count</th>
                            <th className="erp_table_th" rowSpan={2}>Yarn Req.Per Mtr</th>
                        </tr>
                    </thead>
                    <tbody>
                        {WarpWeftTableDetails.map((Item, Index) =>

                            <tr rowindex={Index} className="sticky-column">
                                <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                                <td className="erp_table_td">{Item.product_parameter_name}</td>
                                <td className="erp_table_td">{Item.prod_count_name}</td>

                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field_table_txt mb-0" value={Item.picks_ends_per_mtr}
                                        onChange={e => { updateLeavesDetailsTblData(Item, e); }}
                                        Headers='picks_ends_per_mtr'
                                        disabled={keyForViewUpdate === 'view'} />
                                </td>

                                <td className="erp_table_td">
                                    <input type="text" id="actual_count" className="erp_input_field_table_txt mb-0" value={Item.actual_count}
                                        onChange={e => { updateLeavesDetailsTblData(Item, e); }}
                                        Headers='actual_count'
                                        disabled={keyForViewUpdate === 'view'} />
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field_table_txt mb-0" value={Item.yarn_req_per_mtr}
                                        onChange={e => { setYarnReqPerMtr(e.target.value); }}
                                        style={{ backgroundColor: '#e9ecef' }}
                                        Headers='yarn_req_per_mtr'
                                        maxLength="19" disabled />
                                </td>
                            </tr>

                        )}

                    </tbody>
                </Table>
            </div>
        </>
    }, [WarpWeftTableDetails, int_Width, txt_weft_crimp_per, txt_warp_waste_per, txt_warp_crimp_per, txt_weft_waste_per])
    const updateLeavesDetailsTblData = (currentLeavesDetails, e) => {
        debugger
        let clickedColName = e.target.getAttribute('Headers');
        delete e.target.parentElement.dataset.tip;
        const width = parseFloat(document.getElementById('int_Width').value);
        const warpWaste = parseFloat(document.getElementById('txt_warp_waste_per').value);
        const warpCrimp = parseFloat(document.getElementById('txt_warp_crimp_per').value);
        const weftWaste = parseFloat(document.getElementById('txt_weft_waste_per').value);
        const weftCrimp = parseFloat(document.getElementById('txt_weft_crimp_per').value);

        let warpCount, weftCount;

        if (Array.isArray(currentLeavesDetails)) {
            warpCount = currentLeavesDetails.filter(property => property.product_parameter_name.includes("Warp Count"));
            weftCount = currentLeavesDetails.filter(property => property.product_parameter_name.includes("Weft Count"));
        } else if (typeof currentLeavesDetails === 'object') {
            warpCount = currentLeavesDetails.product_parameter_name;
            weftCount = currentLeavesDetails.product_parameter_name;
        }

        switch (clickedColName) {
            case 'actual_count':
                if (e.target.value === '') {
                    e.target.value = 0
                }
                if (warpCount.includes("Warp Count")) {
                    const yarn_ReqWarp = ((currentLeavesDetails['picks_ends_per_mtr'] * width + 24 * 0) * 1.093614 / 840 / 2.205 / e.target.value * 100 / (100 - (warpCrimp + warpWaste + 0))) * 1000;
                    currentLeavesDetails[clickedColName] = e.target.value;

                    if (yarn_ReqWarp === Infinity || isNaN(yarn_ReqWarp)) {
                        currentLeavesDetails['yarn_req_per_mtr'] = 0.00;
                    } else {
                        currentLeavesDetails['yarn_req_per_mtr'] = yarn_ReqWarp.toFixed(2);
                    }
                }
                else if (weftCount.includes("Weft Count")) {
                    const yarn_ReqWeft = (+currentLeavesDetails['picks_ends_per_mtr'] * 39.37 * (width + 1.2 * 0) / 36 / 840 / 2.205 / e.target.value * 100 / (100 - (weftCrimp + weftWaste))) * 1000
                    currentLeavesDetails[clickedColName] = e.target.value;
                    if (yarn_ReqWeft === Infinity || isNaN(yarn_ReqWeft)) {
                        currentLeavesDetails['yarn_req_per_mtr'] = 0.00;
                    } else {
                        currentLeavesDetails['yarn_req_per_mtr'] = yarn_ReqWeft.toFixed(2);
                    }
                }
                currentLeavesDetails[clickedColName] = e.target.value;
                break;

            case 'picks_ends_per_mtr':
                if (warpCount.includes("Warp Count")) {
                    const yarn_ReqWarp = ((e.target.value * width + 24 * 0) * 1.093614 / 840 / 2.205 / currentLeavesDetails['actual_count'] * 100 / (100 - (warpCrimp + warpWaste + 0))) * 1000;
                    currentLeavesDetails[clickedColName] = e.target.value;

                    if (yarn_ReqWarp === Infinity || isNaN(yarn_ReqWarp)) {
                        currentLeavesDetails['yarn_req_per_mtr'] = 0.00;
                    } else {
                        currentLeavesDetails['yarn_req_per_mtr'] = yarn_ReqWarp.toFixed(2);
                    }
                } else if (weftCount.includes("Weft Count")) {
                    const yarn_ReqWeft = (e.target.value * 39.37 * (width + 1.2 * 0) / 36 / 840 / 2.205 / currentLeavesDetails['actual_count'] * 100 / (100 - (weftCrimp + weftWaste))) * 1000
                    currentLeavesDetails[clickedColName] = e.target.value;

                    if (yarn_ReqWeft === Infinity || isNaN(yarn_ReqWeft)) {
                        currentLeavesDetails['yarn_req_per_mtr'] = 0.00;
                    } else {
                        currentLeavesDetails['yarn_req_per_mtr'] = yarn_ReqWeft.toFixed(2);
                    }
                }
                currentLeavesDetails[clickedColName] = e.target.value;

                break;

            case 'yarn_req_per_mtr':
                currentLeavesDetails[clickedColName] = e.target.value;
                break;

            default:
                break;
        }

        const updateWarpWeftDetails = [...WarpWeftTableDetails]
        const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        updateWarpWeftDetails[pmtTermIndexInArray] = currentLeavesDetails;
        setWarpWeftTableDetails(updateWarpWeftDetails);
        const totalYarnReqAll = updateWarpWeftDetails.reduce((total, current) => {
            return total + (parseFloat(current['yarn_req_per_mtr']) || 0);
        }, 0);

        /// calculate GLM and GSM
        const roundedTotalYarnReqAll = totalYarnReqAll.toFixed(2);
        setGlm(roundedTotalYarnReqAll);
        const gsmValue = (totalYarnReqAll / width * 39.37).toFixed(2);
        setGsm(gsmValue);
    }

    const onchangeWarpWeftTableData = async () => {
        debugger

        const width = parseFloat(document.getElementById('int_Width').value) || 0;
        const warpWaste = parseFloat(document.getElementById('txt_warp_waste_per').value) || 0;
        const warpCrimp = parseFloat(document.getElementById('txt_warp_crimp_per').value) || 0;
        const weftWaste = parseFloat(document.getElementById('txt_weft_waste_per').value) || 0;
        const weftCrimp = parseFloat(document.getElementById('txt_weft_crimp_per').value) || 0;

        let tabledetails = WarpWeftTableDetails;
        let warpCount, weftCount;
        tabledetails.map((detail) => {
            warpCount = detail.product_parameter_name;
            weftCount = detail.product_parameter_name;
            if (warpCount.includes("Warp Count")) {
                let result1 = ((parseFloat(detail.picks_ends_per_mtr) * width + 24 * 0) * 1.093614 / 840 / 2.205 / parseFloat(detail.actual_count) * 100 / (100 - (parseFloat(warpCrimp) + parseFloat(warpWaste) + 0))) * 1000;
                if (isNaN(result1)) {
                    detail.yarn_req_per_mtr = 0
                } else {
                    detail.yarn_req_per_mtr = result1
                }
            }
            else if (weftCount.includes("Weft Count")) {
                let result2 = (parseFloat(detail.picks_ends_per_mtr) * 39.37 * (width + 1.2 * 0) / 36 / 840 / 2.205 / parseFloat(detail.actual_count) * 100 / (100 - (parseFloat(weftCrimp) + parseFloat(weftWaste) + 0))) * 1000;
                if (isNaN(result2)) {
                    detail.yarn_req_per_mtr = 0
                } else {
                    detail.yarn_req_per_mtr = result2
                }

            }
            detail.yarn_req_per_mtr = parseFloat(detail.yarn_req_per_mtr).toFixed(2);
        })

    }

    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Fabric Spec Sheet {actionType} </label>
                    </div>
                    <form id="WeavingProductionform">
                        <div className="row erp_transporter_div">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Machine Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_machine_type_id" className="form-select form-select-sm" value={cmb_machine_type_id} onChange={(e) => { validateFields(); setMachineTypeId(e.target.value); validateWaevingplan(); }} disabled={keyForViewUpdate === 'update'} >
                                            <option value="">Select</option>
                                            {/* <option value="Picanol">Picanol</option>
                                            <option value="Dornier">Dornier</option> */}
                                            <option value="Toyota">Toyota</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_machine_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Product <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_product_id" className="form-select form-select-sm" value={cmb_product_id}
                                            onChange={e => { setProductId(e.target.value); comboOnChange('productType'); validateFields(); validateWaevingplan(); }}>
                                            <option value="">Select</option>
                                            {productTypeOptions?.map(productType => (
                                                <option value={productType.field_id} ptShortName={productType.product_type_short_name}>{productType.field_name}</option>
                                            ))}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_product_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Product Description <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="cmb_product_description" className="erp_input_field" value={cmb_product_description} maxLength="255" disabled="disabled" />
                                        <MDTypography variant="button" id="error_cmb_product_description" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Sort No. <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sort_no" className="erp_input_field erp_align-right" value={txt_sort_no}
                                            onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSortNo(e.target.value) } }} maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_sort_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Unit <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="cmb_product_fg_stock_unit_name" className="erp_input_field" value={cmb_product_fg_stock_unit_name} maxLength="255" disabled="disabled" />
                                        <MDTypography variant="button" id="error_cmb_product_fg_stock_unit_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Target Efficiency <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_target_efficiency" className="erp_input_field remove0 erp_align-right" value={txt_target_efficiency} maxLength="255"
                                            onChange={handleInputChange}
                                        />
                                        <MDTypography variant="button" id="error_txt_target_efficiency" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Target rpm <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_target_rpm" className="erp_input_field remove0 erp_align-right" value={txt_target_rpm}
                                            onChange={handleInputChange}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_target_rpm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> EPI <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_epi" className="erp_input_field remove0 erp_align-right" value={txt_epi}
                                            onChange={handleInputChange}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_epi" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> PPI <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_ppi" className="erp_input_field remove0 erp_align-right" value={txt_ppi}
                                            onChange={handleInputChange}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_ppi" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Width(Inch) <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="int_Width" className="erp_input_field remove0 erp_align-right" value={int_Width}
                                            onChange={e => { setWidth(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); onchangeWarpWeftTableData(); }}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_int_Width" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Warp Crimp(%) <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id="txt_warp_crimp_per" className="erp_input_field remove0 erp_align-right" value={txt_warp_crimp_per}
                                            onChange={e => {
                                                if (validateNumberDateInput.current.percentValidate(e.target.value)) {
                                                    setWarpCrimpPer(e.target.value);
                                                    onchangeWarpWeftTableData();
                                                }
                                            }}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_warp_crimp_per" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Weft Crimp(%) <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id="txt_weft_crimp_per" className="erp_input_field remove0 erp_align-right" value={txt_weft_crimp_per}
                                            onChange={e => {
                                                if (validateNumberDateInput.current.percentValidate(e.target.value)) {
                                                    setWeftCrimpPer(e.target.value);
                                                    onchangeWarpWeftTableData();
                                                }
                                            }}

                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_weft_crimp_per" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Warp Waste(%) <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id="txt_warp_waste_per" className="erp_input_field remove0 erp_align-right" value={txt_warp_waste_per}

                                            onChange={e => {
                                                if (validateNumberDateInput.current.percentValidate(e.target.value)) {
                                                    setWarpWastePer(e.target.value);
                                                    onchangeWarpWeftTableData();
                                                }
                                            }}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_warp_waste_per" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Weft Waste(%) <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="number" id="txt_weft_waste_per" className="erp_input_field remove0 erp_align-right" value={txt_weft_waste_per}
                                            onChange={e => {
                                                if (validateNumberDateInput.current.percentValidate(e.target.value)) {
                                                    setWeftWastePer(e.target.value);
                                                    onchangeWarpWeftTableData();
                                                }
                                            }}
                                            maxLength="19" />
                                        <MDTypography variant="button" id="error_txt_weft_waste_per" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> GLM without size <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_GLM_without_size" className="erp_input_field erp_align-right"
                                            value={txt_GLM_without_size}

                                            onChange={handleInputChange}
                                        />
                                        <MDTypography variant="button" id="error_txt_GLM_without_size" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">GLM <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_glm" className="erp_input_field erp_align-right"
                                            value={txt_glm}
                                            onChange={handleInputChange}
                                        />
                                        <MDTypography variant="button" id="error_txt_glm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> GSM <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_gsm" className="erp_input_field erp_align-right"
                                            value={txt_gsm}
                                            onChange={handleInputChange}
                                        />
                                        <MDTypography variant="button" id="error_txt_gsm" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> No Of Ends <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">

                                        <Form.Control
                                            type="text"
                                            id="txt_no_of_ends"
                                            className="erp_input_field erp_align-right"
                                            value={txt_no_of_ends}
                                            onChange={handleInputChange}

                                        />
                                        <MDTypography variant="button" id="error_txt_no_of_ends" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-sm-8 erp_form_col_div" style={{ marginTop: "10px" }}>
                            {WarpWeftTableDetails.length !== 0 ?
                                <>
                                    {renderDetailsTable}
                                </> : ""
                            }
                        </div>
                    </form>

                    <div className="card-footer py-0 text-center mb-5">
                        <MDButton type="button" className="erp-gb-button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanListing/reg' : '/Masters/MWeavingProductionPlanMaster/FrmWeavingProductionPlanListing';
                                navigate(path);
                            }} variant="button" fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={addWeavingProductionPlan} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>

                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


            </div>
        </>
    )
}

export default FrmWeavingProductionPlanEntry;
