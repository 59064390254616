import React, { useEffect, useRef, useMemo, useLayoutEffect } from 'react'
import { useState } from 'react'
import $, { data } from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { Tab, Table, Tabs, Form, Card } from "react-bootstrap";
import SizingProductionDetails from './component/SizingProductionDetails';
import FrmSizingMaterialMovementEntry from './component/materialMovement';
import Accordion from "react-bootstrap/Accordion";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

// Import for the searchable combo box.
import Select from 'react-select';

function getSteps() {
    return ["Sizing Production Details", "Material Movement ",];
}

function FrmTSizingProductionEntry(props) {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const [actionLabel, setActionLabel] = useState('Save');
    const [actionType, setActionType] = useState('')


    const { state } = useLocation();
    const { WeavingProductionSizingMasterId = 0, keyForViewUpdate, compType } = state || {}
    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const handleNext = () => { setActiveStep((parseInt(activeStep) + 1)) };
    const handleBack = () => setActiveStep((parseInt(activeStep) - 1));
    const [showTabs, setShowTabs] = useState(false);

    //useRef Hooks
    const selectRef = useRef(null);
    const comboDataAPiCall = useRef();
    const cmb_prod_supervisor_id_ref = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const navigate = useNavigate();
    //for Approve
    const [isApprove, setIsApprove] = useState(false);


    //Sizing Production master hooks
    const [txt_weaving_production_sizing_master_id, setWeavingProductionSizingMasterId] = useState(WeavingProductionSizingMasterId);
    // const [txt_weaving_production_sizing_details_id, setWeavingProductionSizingDetailsId] = useState(0);
    const [dt_sizing_production_date, setSizingProductionDate] = useState(today);
    const [txt_production_month, setProdctionMonth] = useState();
    const [cmb_set_no, setProdSetNo] = useState();
    const [txt_production_year, setProductionYear] = useState();
    const [cmb_plant_id, setProductionPlantName] = useState(1);
    const [txt_sizing_production_code, setSizingProductionCode] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [calculationsTotal, setCalculationsTotal] = useState([])
    const [shiftNames, setShiftNames] = useState([]);
    const [txt_Production_Sizing_Status, setProductionSizingStatus] = useState('P');
    const [cmb_section_id, setProdSection] = useState();
    const [cmb_sub_section_id, setProdSubSection] = useState();
    const [cmb_shift, setShift] = useState('');
    const [cmb_prod_supervisor_id, setProdSupervisor] = useState();
    const [txt_set_length, setWarpingSetLength] = useState();
    const [txt_prod_material_name, setProdMaterialName] = useState();
    const [productMaterialId, setProdMaterialId] = useState();
    const [production_count, setProductionCount] = useState();
    const [no_of_ends, setNoOfEnds] = useState();
    const [warping_creel_ends, setWarpingCreelEnds] = useState();
    const [warping_length, setWarpingLength] = useState();
    const [warping_no_of_creels, setWarpingCreels] = useState();

    ///Shiftwise Machine Stoppage Entry(Ring Frame) Hooks
    const [cmb_machine_no, setMachineNo] = useState();
    const [cmb_stoppage_reason, setMachineStoppageReason] = useState();
    const [txt_stoppage_time, setMachineStoppageTime] = useState();
    const [cmb_loss_type, setLossType] = useState();
    const [txt_std_stoppage_loss_kg, setStdProdLoss] = useState();
    const [txt_actual_production_loss_kg, setActProdLoss] = useState();
    const [txt_stoppage_production_loss_kg, setStoppageProductionLossKg] = useState();
    const [txt_std_stoppage_loss_per_hour, setStdStoppageLossPerHour] = useState();

    //Sizing Production comboboxes
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
    const [beamWarpingOptions, setBeamWarpingOptions] = useState([]);
    const [beamSizingOptions, setBeamSizingOptions] = useState([]);



    ///Sizing Production Hooks
    const [prodSummaryData, setProductionSummaryData] = useState([]);
    const [machineNameOption, setMachineNameOption] = useState([]);
    const [prevdataindexforStoppage, setPrevDataIndexForStoppage] = useState();
    const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
    const [rowCount, setRowCount] = useState(1)
    const [labelForStoppage, setLabelForStopage] = useState('Add');

    //loader
    const [isLoading, setIsLoading] = useState(false);

    ///////RIng Frame Production Shift Details
    const [machineNumData, setMachineNumData] = useState([]);
    // const [spinningProdCountOptions, setSpinngProdCountOptions] = useState([]);
    const [stoppageDetails, setStoppageDetails] = useState([]);
    // const [ringFrameProdShiftData, setRingFrameProdShiftData] = useState([]);

    //array sent by props
    const [sizingProductionData, setsizingProductionData] = useState([])
    const [sizingProductionMaterialData, setsizingProductionMaterialData] = useState([])

    //accordion open close
    const [checkIsValidate, setCheckIsValidate] = useState(false);

    ///Shiftwise Machine Wastage Entry(Ring Frame) Combobox
    const [prodWastageOptions, setProdWastageOptions] = useState([]);
    const [godownNameOptions, setGodownNameOptions] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Shiftwise Machine Wastage Entry Hooks
    const [cmb_prod_wastage_type, setProdWastageType] = useState();
    const [txt_act_wastages, setActWastage] = useState();
    const [labelForWastage, setLabelForWastage] = useState('Add');
    const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();
    const [upToDateShiftDetails, setUpToDateShiftDetails] = useState();
    const [txt_prod_rf_code, setProdRfCode] = useState();
    const [txt_wastage_quantity, setWastageQuantity] = useState();
    const [cmb_godownId, setgodownId] = useState();
    const [cmb_shift_wastage, setShiftWastage] = useState();

    const [wastageDetails, setWastageDetails] = useState([]);

    const sizingProductionBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        sizing_production_date: today(),
        machine_id: 0,
        weaving_production_set_no: 0,
        product_material_id: '',
        production_operator_id: 0,
        shift: 0,
        sizing_production_order_no: 0,
        sizing_production_status: 'P',
        status_remark: '',
        yarn_count: 0,
        actual_count: 0,
        warping_length: 0,
        sizing_rs: 0,
        beam_no: 0,
        sizing_length: 0,
        total_ends: 0,
        no_of_creels: 0,
        gross_weight: 0,
        tare_weight: 0,
        net_weight: 0,
        unsize_beam_weight: 0,
        size_pickup: 0,
        rate: 0,
        amount: 0,
        created_by: UserName,
        size_waste: 0,
        unsize_waste: 0,
        creel_waste: 0,
        godown_id: 0
    }


    const sizingMaterialBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        sizing_production_date: today(),
        weaving_production_set_no: 0,
        shift: 0,
        sizing_production_order_no: 0,
        product_material_sz_balance_quantity: 0,
        consumption_quantity: 0,
        status_remark: '',
        material_status: 'P',
        weaving_production_sizing_material_id: 0,
        product_material_unit_id: 0,
        created_by: UserName,
    }

    useEffect(() => {
        setIsLoading(true);
        const loadData = async () => {
            ActionType();
            await fillComboBox();
            setMonthYear();

            if (WeavingProductionSizingMasterId !== 0) {
                await FnCheckUpdateResponce();
            }

            FnGetShiftDetails(today)

            setIsLoading(false);
        }
        loadData()
    }, [])

    const ActionType = async () => {

        switch (keyForViewUpdate) {

            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                setIsApprove(false);
                break;

            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("Sizingproduction");
                await validate.current.readOnly("stoppageFormId");
                $("input[type=radio]").attr('disabled', true);
                setIsApprove(false);
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;

            case 'delete':
                setActionType('(Delete)');
                await validate.current.readOnly("Sizingproduction");
                $("input[type=radio]").attr('disabled', true);
                setIsApprove(false);
                // $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;

            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve')
                await validate.current.readOnly("Sizingproduction");
                await validate.current.readOnly("stoppageFormId");
                $("input[type=radio]").attr('disabled', true);
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");

                setIsApprove(true)
                break;


            default:
                setActionType('(Creation)');
                setIsApprove(false);
                break;
        }

    };


    const fillComboBox = async () => {
        debugger
        try {
            setIsLoading(true);

            //Plant Options
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "cmv_plant"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getProdPlantApiCall) => {
                setPlantOptions(getProdPlantApiCall);
            })


            const beamSizeSizingNoAPIcall = await comboDataAPiCall.current.fillComboBox('SizedBeams')
            setBeamSizingOptions(beamSizeSizingNoAPIcall);

            comboDataAPiCall.current.fillMasterData("xmv_production_stoppage_reasons", "", "").then((getProdStoppageReasonsApiCall) => {
                setProdStoppageReasonOptions(getProdStoppageReasonsApiCall);
            })


            ////Production Section
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "xmv_production_section"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
                setProdSectionOptions(getWarpingProdSecCall);
                setProdSection('18');
                comboOnChange('cmb_section_id');
            })

            const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
            setShiftOptions(shiftsApiCall);

            let shift_Names = [];
            shiftsApiCall.map((shift) => {
                shift_Names.push(shift.property_name)
            })
            setShiftNames(shift_Names);


            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'production_wastage_types_name']
            globalQuery.table = "xmv_production_wastage_types"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then((godownTypeApiCall) => {
                setProdWastageOptions(godownTypeApiCall)
            })



            resetGlobalQuery();
            globalQuery.columns.push('set_no');
            globalQuery.table = "xtv_warping_production_order";
            globalQuery.conditions.push({ field: "warping_order_status", operator: "=", value: 'A', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.orderBy = ['set_no DESC']
            const SetNoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSetNoOptions(SetNoAPICall);


            const getExistingsizingProductionData = [...sizingProductionData]
            getExistingsizingProductionData.push(sizingProductionBlankObject)
            setsizingProductionData(getExistingsizingProductionData)

            const getExistingsizingMaterialMomentData = [...sizingProductionMaterialData]
            getExistingsizingMaterialMomentData.push(sizingMaterialBlankObject)
            setsizingProductionMaterialData(getExistingsizingMaterialMomentData)


            let jsonForSummaryData = {};
            for (let key = 0; key < shift_Names.length; key++) {
                let data = {

                    shift_sizing_total_length: 0,
                    shift_size_total_waste: 0,
                    shift_unsize_total_waste: 0,
                    shift_creel_total_waste: 0,
                    shift_net_total_weight: 0,
                    shift_creel_total_upto_date_waste: 0,
                    shift_net_total_upto_date_weight: 0,
                    shift_size_total_upto_date_waste: 0,
                    shift_sizing_total_upto_date_length: 0,
                    shift_unsize_total_upto_date_waste: 0,
                    //changes to done
                    total: 0,
                    total_up_date: 0
                }
                jsonForSummaryData[shift_Names[key]] = data;
            }
            setProductionSummaryData(jsonForSummaryData);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }

    const comboOnChange = async (key) => {
        try {
            setIsLoading(false)
            switch (key) {
                case 'cmb_section_id':
                    const prod_section_Id = $('#cmb_section_id').val();
                    ///// Production sub section Arr[]
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_type');
                    globalQuery.table = "xmv_production_sub_section"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
                    let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    setProdSubSectionOptions(getProdSubSectionApiCall)
                    setProdSubSection('23');


                    const prodsubSectionId = $('#cmb_sub_section_id').val();

                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name');
                    globalQuery.table = "cmv_machine"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "section_id", operator: "=", value: prod_section_Id });
                    globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
                        setMachineNameOption(getProdSubSectionApiCall)
                        console.log("sub section list: ", getProdSubSectionApiCall);
                    });

                    ////Employees in Warping Production
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name');
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: 23 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
                        const prodSupervisorList = [
                            { value: '', label: 'Select' },
                            ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
                        ];
                        setMachineOperatorOptions(prodSupervisorList);
                    })


                    ////Godown Options
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'godown_name']
                    globalQuery.table = "cmv_godown"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((godownTypeApiCall) => {
                        setGodownNameOptions(godownTypeApiCall)
                    })


                    //Generating Production Code no
                    if (keyForViewUpdate === '') {
                        await generateSizingProductionCode();
                    }

                    break;

                case 'cmb_plant_id':
                    let plantId = $('#cmb_plant_id').val();
                    setProductionPlantName(plantId);
                    break;

                case 'cmb_set_no':
                    debugger
                    let set_no = $("#cmb_set_no option:selected").text();
                    setProdSetNo(set_no);

                    ///Setting schedule quantity , noof creels, set lengt, product material name, material style & material ID
                    resetGlobalQuery();
                    globalQuery.columns = ['creel_ends', 'no_of_creels', 'product_material_name', 'actual_count', 'product_material_id', 'beam_name', 't_ends', 'warping_set_length', 'length']
                    globalQuery.table = "xtv_weaving_production_warping_details"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "set_no", operator: "=", value: set_no });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    let getSetNoDataApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    setProdMaterialName(getSetNoDataApiCall[0]['product_material_name']);
                    setProdMaterialId(getSetNoDataApiCall[0]['product_material_id']);
                    setProductionCount(getSetNoDataApiCall[0]['actual_count']);
                    setWarpingSetLength(getSetNoDataApiCall[0]['warping_set_length']);
                    setNoOfEnds(getSetNoDataApiCall[0]['t_ends']);

                    let beamOptions = [];
                    let warpingNoOfCreels = 0;
                    let warpingCreelEnds = 0;
                    let warpingLength = 0;

                    getSetNoDataApiCall.map((data) => {
                        if (data['beam_name']) {
                            let emptyObj = {};
                            emptyObj['beam_name'] = data['beam_name'];
                            beamOptions.push(emptyObj); // Add the empty object to beamOptions
                        }

                        if (data['creel_ends']) {
                            warpingCreelEnds += data['creel_ends'];
                        }

                        if (data['no_of_creels']) {
                            warpingNoOfCreels += data['no_of_creels'];
                        }

                        if (data['length']) {
                            warpingLength += data['length'];
                        }

                        return data;
                    });

                    setWarpingCreelEnds(warpingCreelEnds);
                    setWarpingLength(warpingLength);
                    setWarpingCreels(warpingNoOfCreels);
                    setBeamWarpingOptions(beamOptions);
                    break;
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TSizingProduction/FrmTSizingProductionListing/reg' : `/Transactions/TSizingProduction/FrmTSizingProductionListing`;
        navigate(Path);
    }

    const setMonthYear = () => {
        let prod_Plan_Dt = $("#dt_sizing_production_date").val();
        if (prod_Plan_Dt != '') {
            setSizingProductionDate(prod_Plan_Dt);
            let dateComponents = prod_Plan_Dt.split('-');
            setProdctionMonth(dateComponents[1]);
            setProductionYear(dateComponents[0])
        }
    }

    const validateFields = () => {
        validate.current.validateFieldsOnChange('Sizingproduction')
        validate.current.validateFieldsOnChange('stoppageFormId')
        validate.current.validateFieldsOnChange('wastageFormId')
    }

    const renderproductionsummary = useMemo(() => {

        let namesOfTheShift = [...shiftNames];
        const prodSummaryRowsFirstCol = ['Sizing Length (mtr)', 'Sizing Weight (kg)', 'Sizing Waste (kg)', 'Unsized Waste (kg)', 'Creels Waste (kg)'];
        return (
            <Table id="todaysProdSummaryTableId" bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th"></th>
                        <th className="erp_table_th text-center" colSpan={3}>
                            Today's Production Summary
                        </th>
                        <th className="erp_table_th text-center" colSpan={3}>
                            Up To Day's Production Summary
                        </th>
                    </tr>
                </thead>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Description</th>
                        {shiftOptions?.map((header, indexOfItem) => (
                            <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                                {header.field_name}
                            </th>
                        ))}
                        <th className="erp_table_th">Total</th>
                        {shiftOptions?.map((header, indexOfItem) => (
                            <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                                {header.field_name}
                            </th>
                        ))}
                        <th className="erp_table_th">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {namesOfTheShift.length === 0 ? (<tr></tr>) : (
                        <React.Fragment>
                            {/* First Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[0]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_sizing_total_length}
                                        </td>

                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_sizing_total_length, 0)}
                                    </td>
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_sizing_total_upto_date_length}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_sizing_total_upto_date_length, 0)}
                                    </td>
                                </>
                            </tr>
                            {/* Second Row */}

                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[1]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_net_total_weight}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_net_total_weight, 0)}
                                    </td>
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_net_total_upto_date_weight}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_net_total_upto_date_weight, 0)}
                                    </td>
                                </>
                            </tr>
                            {/* Third Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[2]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_size_total_waste}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_size_total_waste, 0)}
                                    </td>
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_size_total_upto_date_waste}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_size_total_upto_date_waste, 0)}
                                    </td>
                                </>
                            </tr>
                            {/* Four Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[3]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_unsize_total_waste}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_unsize_total_waste, 0)}
                                    </td>
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_unsize_total_upto_date_waste}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_unsize_total_upto_date_waste, 0)}
                                    </td>
                                </>
                            </tr>
                            {/* five Row */}
                            <tr>
                                <th className="erp_table_th">{prodSummaryRowsFirstCol[4]}</th>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_creel_total_waste}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_creel_total_waste, 0)}
                                    </td>
                                </>
                                <>
                                    {namesOfTheShift.map((key, index) => (
                                        <td key={key} className="erp_table_td">
                                            {prodSummaryData[key].shift_creel_total_upto_date_waste}
                                        </td>
                                    ))}
                                    <td className="erp_table_td">
                                        {namesOfTheShift.reduce((total, key) => total + prodSummaryData[key].shift_creel_total_upto_date_waste, 0)}
                                    </td>
                                </>
                            </tr>

                        </React.Fragment>
                    )}
                </tbody>
            </Table>
        )
    }, [prodSummaryData]);


    const FnGetShiftDetails = async () => {
        setShift("I");
        let namesOfTheShift = [...shiftOptions];
        let sizing_production_date = $('#dt_sizing_production_date').val();
        try {
            //change API
            const getShiftApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnShowParticularSizingShiftSummary/${sizing_production_date}/${COMPANY_ID}`);
            const respForShiftApiCall = await getShiftApiCall.json();
            let productSummaryData = respForShiftApiCall.data;
            let upToDateShift_Details = {};
            namesOfTheShift.map((shift) => {
                if (productSummaryData[shift] && productSummaryData[shift].hasOwnProperty('shift_sizing_total_length')) {
                    upToDateShift_Details[shift] = {
                        shift_sizing_total_length: productSummaryData[shift].shift_sizing_total_length,
                        shift_net_total_weight: productSummaryData[shift].shift_net_total_weight,
                        shift_size_total_waste: productSummaryData[shift].shift_size_total_waste,
                        shift_unsize_total_waste: productSummaryData[shift].shift_unsize_total_waste,
                        shift_creel_total_waste: productSummaryData[shift].shift_creel_total_waste,
                        shift_sizing_total_upto_date_length: productSummaryData[shift].shift_sizing_total_upto_date_length,
                        shift_net_total_upto_date_weight: productSummaryData[shift].shift_net_total_upto_date_weight,
                        shift_size_total_upto_date_waste: productSummaryData[shift].shift_size_total_upto_date_waste,
                        shift_unsize_total_upto_date_waste: productSummaryData[shift].shift_unsize_total_upto_date_waste,
                        shift_creel_total_upto_date_waste: productSummaryData[shift].shift_creel_total_upto_date_waste,
                    };
                } else {
                    upToDateShift_Details[shift] = {};
                }
            })
            setProductionSummaryData(productSummaryData);
        } catch (error) {
            console.log('error', error);
        }
    }
    // ------------------------------------------save validation---------------------------------------------------------------

    const validateProdDetails = async () => {
        // debugger

        let headerDataIsValid = await validate.current.validateForm("Sizingproduction");
        if (!headerDataIsValid) { return false; }


        let lastRowSelectSetNo = '';
        let lastRowSelectMachinesNo = '';
        // let lastRowSelectMachineOperator = '';
        let lastRowSelectShift = '';
        let lastRowSelectbeamno = '';
        let lastRowSelectsizinglength = '';
        let lastRowSelectgrossweight = '';
        let lastRowGodownId = '';

        if (sizingProductionData.length > 0) {
            const lastRowIndex = sizingProductionData.length - 1;
            lastRowSelectSetNo = sizingProductionData[lastRowIndex].weaving_production_set_no;
            lastRowSelectMachinesNo = sizingProductionData[lastRowIndex].machine_id;
            // lastRowSelectMachineOperator = sizingProductionData[lastRowIndex].production_operator_id;
            lastRowSelectShift = sizingProductionData[lastRowIndex].shift;
            lastRowSelectbeamno = sizingProductionData[lastRowIndex].beam_no;
            lastRowSelectsizinglength = sizingProductionData[lastRowIndex].sizing_length;
            lastRowSelectgrossweight = sizingProductionData[lastRowIndex].gross_weight;
            lastRowGodownId = sizingProductionData[lastRowIndex].godown_id;


        }
        if ((lastRowSelectSetNo !== '' && parseInt(lastRowSelectSetNo) !== 0) &&
            (lastRowSelectMachinesNo !== '' && parseInt(lastRowSelectMachinesNo) !== 0) &&
            // (lastRowSelectMachineOperator !== '' && lastRowSelectMachineOperator !== 0) &&
            (lastRowSelectbeamno !== '' && lastRowSelectbeamno !== 0) &&
            (lastRowSelectsizinglength !== '' && lastRowSelectsizinglength !== 0) &&
            (lastRowSelectgrossweight !== '' && lastRowSelectgrossweight !== 0) &&
            (lastRowSelectShift !== '' && lastRowSelectShift !== 0) &&
            (lastRowGodownId !== '' && lastRowGodownId !== 0)) {
            return true;
        }
        else if (lastRowSelectMachinesNo === '' || parseInt(lastRowSelectMachinesNo) === 0) {
            setErrMsg('Please select Machine No. in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        }
        // else if (lastRowSelectMachineOperator === '' || parseInt(lastRowSelectMachineOperator) === 0) {
        //     setErrMsg('Please select Machine Operator in sizing details...!');
        //     setShowErrorMsgModal(true);
        //     return false;
        // }
        else if (lastRowSelectShift === '' || parseInt(lastRowSelectShift) === 0) {
            setErrMsg('Please select Select Shift in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        }
        else if (lastRowSelectSetNo === '' || parseInt(lastRowSelectSetNo) === 0) {
            setErrMsg('Please select Set No in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        }
        else if (lastRowSelectbeamno === '' || parseInt(lastRowSelectbeamno) === 0) {
            setErrMsg('Please enter Beam No. in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        } else if (lastRowSelectsizinglength === '' || parseInt(lastRowSelectsizinglength) === 0) {
            setErrMsg('Please enter Sizing Length  in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        } else if (lastRowSelectgrossweight === '' || parseInt(lastRowSelectgrossweight) === 0) {
            setErrMsg('Please enter Gross Weight in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        } else if (lastRowGodownId === '' || parseInt(lastRowGodownId) === 0) {
            setErrMsg('Please Select Godown in sizing details...!');
            setShowErrorMsgModal(true);
            return false;
        } else {
            setErrMsg('Please enter at least one production Details');
            setShowErrorMsgModal(true);
            return false;
        }
    };



    const validateMaterialDetails = async () => {
        debugger;
        if (sizingProductionMaterialData.length > 0) {
            for (let index = 0; index < sizingProductionMaterialData.length; index++) {
                const element = sizingProductionMaterialData[index];
                const { weaving_production_set_no, shift, product_material_id = '', consumptionQtyInfo = 0 } = element;

                if (weaving_production_set_no !== 0 || (shift !== '' && shift !== 0) || (product_material_id !== '' &&
                    product_material_id !== undefined) || consumptionQtyInfo !== 0) {
                    if (shift === '') {
                        setErrMsg('Please Select at Shift in sizing material movement...!');
                        setShowErrorMsgModal(true);
                        return false;
                    } else if (weaving_production_set_no === 0) {
                        setErrMsg('Please Select at Set NO. in sizing material movement...!');
                        setShowErrorMsgModal(true);
                        return false;
                    } else if (product_material_id === '') {
                        setErrMsg('Please Select Material Name in sizing material movement...!');
                        setShowErrorMsgModal(true);
                        return false;
                    } else if (consumptionQtyInfo === 0) {
                        setErrMsg(`Please properly consume the stock for Shift-${shift} & Set No. - ${weaving_production_set_no} in the material movement section.`);
                        setShowErrorMsgModal(true);
                        return false;
                    }
                    return true;
                } else {
                    return true;
                }

            }
        } else {
            return true;
        }
        // let lastRowSelectSetNo = '';
        // let lastRowSelectShift = '';
        // let lastRowSelectMaterialname = '';
        // let lastRowSelectconsumptionqty = '';

        // if (sizingProductionMaterialData.length > 0) {

        //     const lastRowIndex = sizingProductionMaterialData.length - 1;
        //     lastRowSelectSetNo = sizingProductionMaterialData[lastRowIndex].weaving_production_set_no;
        //     lastRowSelectShift = sizingProductionMaterialData[lastRowIndex].shift;
        //     lastRowSelectMaterialname = sizingProductionMaterialData[lastRowIndex].product_material_id;
        //     lastRowSelectconsumptionqty = sizingProductionMaterialData[lastRowIndex].consumption_quantity;


        //     if (lastRowSelectSetNo !== '' || lastRowSelectShift !== '' || lastRowSelectMaterialname !== '' || lastRowSelectconsumptionqty !== 0) {
        //         if (lastRowSelectShift === '') {
        //             setErrMsg('Please Select at Shift in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         } else if (lastRowSelectSetNo === '') {
        //             setErrMsg('Please Select at Set NO. in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         } else if (lastRowSelectMaterialname === '') {
        //             setErrMsg('Please Select Material Name in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         } else if (lastRowSelectconsumptionqty === 0) {
        //             setErrMsg('Please Consumption Qty in Sizing Material Movement...!');
        //             setShowErrorMsgModal(true);
        //             return false;
        //         }
        //         return true;
        //     } else {
        //         return true;
        //     }
        // } else {
        //     return true;
        // }
    }

    //--------------------------------------Production Details------------------------------------------------------//
    const setRowCountAndAddRow = () => {
        debugger
        const tableRows = document.querySelectorAll('#sizingProductionTbl tbody tr');
        let lastRowSetNo = '';
        let lastRowMachinesNo = '';
        // let lastRowMachineOperator = '';
        let lastRowShift = '';

        // let lastRowBeamno = '';
        let lastRowsizinglength = '';
        let lastRowgrossweight = '';



        if (tableRows.length > 0) {
            const lastRowIndex = tableRows.length - 1;
            const lastRowSelectSetNo = tableRows[lastRowIndex].querySelector('select[id^="weaving_production_set_no_"]');
            if (lastRowSelectSetNo) {
                lastRowSetNo = lastRowSelectSetNo.value;
            }

            const lastRowSelectMachinesNo = tableRows[lastRowIndex].querySelector('select[id^="machine_id_"]');
            if (lastRowSelectMachinesNo) {
                lastRowMachinesNo = lastRowSelectMachinesNo.value;
            }

            // const lastRowSelectMachineOperator = tableRows[lastRowIndex].querySelector('select[id^="production_operator_id_"]');
            // if (lastRowSelectMachineOperator) {
            //     lastRowMachineOperator = lastRowSelectMachineOperator.value;
            // }

            const lastRowSelectShift = tableRows[lastRowIndex].querySelector('select[id^="shift_"]');
            if (lastRowSelectShift) {
                lastRowShift = lastRowSelectShift.value;
            }

            // const lastRowSelectBeamno = tableRows[lastRowIndex].querySelector('input[id^="beam_no_"]');
            // if (lastRowSelectBeamno) {
            //     lastRowBeamno = lastRowSelectBeamno.value;
            // }

            const lastRowSelectsizinglength = tableRows[lastRowIndex].querySelector('input[id^="sizing_length_"]');
            if (lastRowSelectsizinglength) {
                lastRowsizinglength = lastRowSelectsizinglength.value;
            }
            const lastRowSelectgrossweight = tableRows[lastRowIndex].querySelector('input[id^="gross_weight_"]');
            if (lastRowSelectgrossweight) {
                lastRowgrossweight = lastRowSelectgrossweight.value;
            }

        }

        if (lastRowSetNo !== '' && lastRowMachinesNo !== '' && lastRowShift !== '' && (lastRowsizinglength !== '' && parseInt(lastRowsizinglength) !== 0) && (lastRowgrossweight !== '' && parseInt(lastRowgrossweight) !== 0)) {
            const getExistingsizingProductionData = [...sizingProductionData]
            getExistingsizingProductionData.push(sizingProductionBlankObject)
            setsizingProductionData(getExistingsizingProductionData)

            setRowCount(rowCount + 1);
        } else {
            const lastRow = tableRows[tableRows.length - 1];
            const setNoInput = lastRow.querySelector('select[id^="weaving_production_set_no_"]');
            const machinesNoInput = lastRow.querySelector('select[id^="machine_id_"]');
            const machineOperatorInput = lastRow.querySelector('select[id^="production_operator_id_"]');
            const shiftInput = lastRow.querySelector('select[id^="shift_"]');

            const sizinglengthInput = lastRow.querySelector('input[id^="sizing_length_"]');
            const grossweightInput = lastRow.querySelector('input[id^="gross_weight_"]');



            if (machinesNoInput) {
                const selectedOption = machinesNoInput.options[machinesNoInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    machinesNoInput.parentElement.dataset.tip = 'Please select Machine';
                    machinesNoInput.focus();
                    return;
                } else {
                    delete machinesNoInput.parentElement.dataset.tip;
                }
            }

            if (machineOperatorInput) {
                const selectedOption = machineOperatorInput.options[machineOperatorInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    machineOperatorInput.parentElement.dataset.tip = 'Please select  Machine Operator';
                    machineOperatorInput.focus();
                    return;
                } else {
                    delete machineOperatorInput.parentElement.dataset.tip;
                }
            }

            // if (setNoInput) {
            //     const selectedOption = setNoInput.options[setNoInput.selectedIndex];
            //     if (!selectedOption || !selectedOption.value) {
            //         setNoInput.parentElement.dataset.tip = 'Please select SetNO.';
            //         setNoInput.focus();
            //         return;
            //     } else {
            //         delete setNoInput.parentElement.dataset.tip;
            //     }
            // }

            if (shiftInput) {
                const selectedOption = shiftInput.options[shiftInput.selectedIndex];
                if (!selectedOption || !selectedOption.value) {
                    shiftInput.parentElement.dataset.tip = 'Please select Shift';
                    shiftInput.focus();
                    return;
                } else {
                    delete shiftInput.parentElement.dataset.tip;
                }
            }

            if (sizinglengthInput.value === '0' || sizinglengthInput.value === '') {
                sizinglengthInput.parentElement.dataset.tip = 'Please enter Sizing Length...!';
                sizinglengthInput.focus();
                return;
            } else {
                delete sizinglengthInput.parentElement.dataset.tip;
            }

            if (grossweightInput.value === '0' || grossweightInput.value === '') {
                grossweightInput.parentElement.dataset.tip = 'Please enter Gross Weight...!';
                grossweightInput.focus();
                return;
            } else {
                delete grossweightInput.parentElement.dataset.tip;
            }


        }
    };


    const removeFirstRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            const updatedSizingProductionData = sizingProductionData.filter((item, index) => index !== indexToRemove);
            setsizingProductionData(updatedSizingProductionData)
        } else {
            const updatedSizingProductionData = [...sizingProductionData];
            updatedSizingProductionData[0] = { ...sizingProductionBlankObject };
            setsizingProductionData(updatedSizingProductionData);
        }
    }


    const SizingrenderProductionDetails = useMemo(() => {
        debugger
        if (!sizingProductionData) {
            return null;
        }

        return <Table id='sizingProductionTbl' className={`erp_table ${sizingProductionData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >Action</th>
                    <th className="erp_table_th" >SR NO.</th>
                    <th className="erp_table_th" >Date</th>
                    <th className="erp_table_th" >MACHINE NO.</th>
                    <th className="erp_table_th" >SHIFT</th>
                    <th className="erp_table_th" >MACHINE OPERATOR</th>
                    {/* <th className="erp_table_th" >SET NO.</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>WARPING <br></br>BEAM NO.</th> */}
                    {/* <th className="erp_table_th" style={{ textAlign: 'center', paddingRight: "100px" }}>MATERIAL NAME</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>Sizing Production <br></br> Order No</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>WARPING <br></br> LENGTH</th>
                    <th className="erp_table_th" rowSpan={2}>NO OF <br></br> CREELS</th>
                    <th className="erp_table_th" rowSpan={2}>TOTAL NO<br></br> OF ENDS</th> */}
                    {/* <th className="erp_table_th" rowSpan={2}>YARN <br></br> COUNT</th>
                    <th className="erp_table_th" rowSpan={2}>ACTUAL <br></br>  COUNT</th> */}

                    <th className="erp_table_th" >R.S(Inches)</th>
                    <th className="erp_table_th" rowSpan={2}>SIZING <br></br> BEAM NO.</th>
                    <th className="erp_table_th" rowSpan={2}>SIZING <br></br> LENGTH</th>
                    <th className="erp_table_th" rowSpan={2}>GROSS <br></br> WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>TARE <br></br> WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>NET <br></br> WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>UNSIZE <br></br> BEAM WEIGHT</th>
                    <th className="erp_table_th" rowSpan={2}>SIZE <br></br> PICK-UP %</th>
                    <th className="erp_table_th" rowSpan={2}>SIZE <br></br> WASTE</th>
                    <th className="erp_table_th" rowSpan={2}>UNSIZE <br></br> WASTE</th>
                    <th className="erp_table_th" rowSpan={2}>CREEL <br></br> WASTE</th>
                    <th className="erp_table_th" >RATE</th>
                    <th className="erp_table_th" >AMOUNT</th>
                    <th className="erp_table_th" rowSpan={2}>Sizing Prod. Status</th>
                    <th className="erp_table_th" >Status Remark</th>
                    <th className="erp_table_th" >Godown</th>
                </tr>
            </thead>
            <tbody>
                {sizingProductionData.map((item, index) =>

                    <tr rowindex={index} className={`sticky-column ${item.sizing_production_status === 'A' ? 'disabled-row' : ''}`}>
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={setRowCountAndAddRow} />
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="date" className="erp_input_field mb-0" Headers="sizing_production_date" id={`sizing_production_date_${index}`} value={item.sizing_production_date} onChange={(e) => updatedSizingProductionDetails("sizing_production_date", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.sizing_production_date
                            }
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`machine_id_${index}`} value={item.machine_id} onChange={(e) => updatedSizingProductionDetails("machine_id", item, e)} className="erp_input_field_table_txt mb-0" disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        Headers="machine_id" >
                                        <option value="">Select</option>
                                        {machineNameOption?.map(machine => (
                                            <option value={machine.field_id}>{machine.machine_id}{machine.field_name}</option>
                                        ))}
                                    </select>
                                    : item.machine_name
                            }
                        </td>

                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`shift_${index}`} value={item.shift} onChange={(e) => updatedSizingProductionDetails("shift", item, e)} className="erp_input_field_table_txt mb-0" Headers="shift" disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} >
                                        <option value="">Select</option>
                                        {shiftOptions?.map(shift => (
                                            <option value={shift.field_name}>{shift.field_name}</option>
                                        ))}
                                    </select>
                                    : item.shift
                            }
                        </td>

                        <td className='erp_table_td' id={`tooltipforsupervisor_${index}`}>
                            {/* <Tooltip title="Select Supervisor..." id={`tooltipforsupervisor_${index}`} placement="top"> */}
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? (
                                        <Select

                                            ref={selectRef}
                                            options={machineOperatorOptions}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            inputId={`cmb_prod_supervisor_id_${index}`}
                                            value={machineOperatorOptions.find(option => option.field_id === parseInt(item.production_operator_id))}
                                            onChange={(e) => updatedSizingProductionDetails('production_operator_id', item, selectRef.current, e)}
                                            placeholder="Search Operator here..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    width: '200px', // Set the width of the option box
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    width: '200px', // Set the width of the menu box
                                                    zIndex: 1000, // Ensure this is higher than the table's zIndex
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 1000, // Ensure this is higher than the table's zIndex
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    width: '160px',
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                            menuPortalTarget={document.body}
                                        />
                                    ) : item.production_operator_name
                            }
                            {/* </Tooltip> */}
                        </td>



                        {/* <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`weaving_production_set_no_${index}`} value={item.weaving_production_set_no} className="erp_input_field_table_txt mb-0"
                                        Headers="weaving_production_set_no"
                                        disabled={item.warping_production_status_desc === 'Approved'}
                                        onChange={(e) => { handleSetNoChange(item, e); }}
                                        style={{ width: '100px' }}
                                    >
                                        <option value=''>Select</option>
                                        {
                                            //array in ascending order
                                            setNoOptions.sort((a, b) => a.set_no - b.set_no).map(setNO => (
                                                <option value={setNO.weaving_production_set_no}>{setNO.weaving_production_set_no}</option>
                                            ))}
                                    </select>
                                    : item.weaving_production_set_no
                            }
                        </td> */}

                        {/* <td className={`erp_table_td`} style={{ position: "relative" }}>
                            <div className='col-auto'>
                                <div className="form-select form-select-sm"
                                    onClick={() => FnToggleBeamSizeList(index, item)}
                                    data-optional="optional">
                                    {'Select'}
                                </div>
                                <ul className="list-items beam-no-ul" id={`beam-no-order-ul_${index}`}
                                    style={{
                                        marginTop: '1px',
                                        position: "fixed",
                                        top: "100px", // Adjust this value to position the dropdown correctly
                                        left: "0",
                                        zIndex: 1000, // High z-index to ensure it appears above other elements
                                        backgroundColor: "#fff",
                                        border: "1px solid #ccc",
                                        width: "100px",
                                        display: "none"  // Initially hidden
                                    }}>
                                    {beamWarpingOptions.length !== 0 ?
                                        beamWarpingOptions.map((beamNo, rowindex) =>
                                            <li className={`item beamno_order_no`} key={index}>
                                                <span className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name={`beamnumberCheckBox`}
                                                        value={beamNo.field_id}
                                                        onClick={(e) => (GetBeamNotoArr('beam_no', item, e, `beam-no-order-ul_${index}`))}
                                                        className="erp_radio_button beamnumberCheckBox"
                                                        optional='optional'
                                                    />
                                                </span>
                                                <span className="item-text">{beamNo.field_name}</span>
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </td> */}



                        {/* <td className={`erp_table_td `}>
                            <div className='col-auto'>
                                <div className="form-select form-select-sm"
                                    onClick={() => FnToggleBeamSizeList(index, item)}
                                    data-optional="optional">
                                    {'Select'}
                                </div>
                                <ul className="list-items beam-no-ul" id={`beam-no-order-ul_${index}`} style={{ width: "100px" }}>
                                    {beamWarpingOptions.length !== 0 ?
                                        beamWarpingOptions.map((beamNo, rowindex) =>
                                            <li className={`item beamno_order_no`} key={index}>
                                                <span className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name={`beamnumberCheckBox`}
                                                        value={beamNo.field_id}
                                                        onClick={(e) => (GetBeamNotoArr('beam_no', item, e, `beam-no-order-ul_${index}`))}
                                                        className="erp_radio_button beamnumberCheckBox"
                                                        optional='optional'
                                                    />
                                                </span>
                                                <span className="item-text">{beamNo.field_name}</span>
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </td> */}

                        {/* <td className='erp_table_td'>

                            <input type="text" className="erp_input_field mb-0" id={`product_material_name_${index}`} value={item.product_material_name} disabled />

                        </td> */}

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field mb-0" Headers="sizing_production_date" id={`sizing_production_order_no_${index}`} value={item.sizing_production_order_no} onChange={(e) => updatedSizingProductionDetails("sizing_production_order_no", item, e)} disabled />
                                    </>
                                    : item.sizing_production_order_no
                            }
                        </td> */}

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`warping_length_${index}`} value={item.warping_length} maxLength='19' onChange={(e) => updatedSizingProductionDetails("warping_total_length", item, e)} disabled />
                                    </>
                                    : item.warping_length
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`no_of_creels_${index}`} value={item.no_of_creels} onChange={(e) => updatedSizingProductionDetails("no_of_creels", item, e)} disabled />
                                    </>
                                    : item.no_of_creels
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`total_ends_${index}`} value={item.total_ends} onChange={(e) => updatedSizingProductionDetails("total_ends", item, e)} disabled />
                                    </>
                                    : item.total_ends
                            }
                        </td> */}

                        {/* <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" style={{ width: '75px' }} id={`production_count_id_${index}`} value={item.yarn_count} onChange={(e) => updatedSizingProductionDetails("yarn_count", item, e)} disabled />
                                    </>
                                    : item.yarn_count
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`actual_count_${index}`} value={item.actual_count} onChange={(e) => updatedSizingProductionDetails("actual_count", item, e)} disabled />
                                    </>
                                    : item.actual_count
                            }
                        </td> */}



                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`sizing_rs_${index}`} value={item.sizing_rs} onChange={(e) => updatedSizingProductionDetails("sizing_rs", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.sizing_rs
                            }
                        </td>
                        {/* beamSizingOptions */}

                        <td className={`erp_table_td `}>
                            <select id={`production_sub_section_godown_id_${index}`} value={item.sizing_beam_no} className="erp_input_field_table_txt mb-0" Headers="sizing_beam_no" onChange={(e) => { updatedSizingProductionDetails('sizing_beam_no', item, e); }}
                                disabled={item.sizing_production_status_desc === 'Approved'} >
                                <option value="">Select</option>
                                {beamSizingOptions?.map(beamSize => (
                                    <option value={beamSize.field_id}>{beamSize.field_name}</option>
                                ))}
                            </select>
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`sizing_length_${index}`} value={item.sizing_length}
                                            disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedSizingProductionDetails("sizing_length", item, e)} />
                                    </>
                                    : item.sizing_length
                            }
                        </td>



                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ?
                                    <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`gross_weight_${index}`} value={item.gross_weight}
                                            disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedSizingProductionDetails("gross_weight", item, e)} />
                                    </>
                                    : item.gross_weight

                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'

                                    ? <>
                                        <input type="text" style={{ backgroundColor: '#AFE1AF', width: '75px' }} className="erp_input_field_table_txt mb-0 remove0 text-end" id={`tare_weight_${index}`} value={item.tare_weight}
                                            disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            onChange={(e) => updatedSizingProductionDetails("tare_weight", item, e)} />
                                    </>
                                    : item.tare_weight
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`net_weight_${index}`} style={{ width: '75px' }} value={item.net_weight} onChange={(e) => updatedSizingProductionDetails("net_weight", item, e)} disabled />
                                    </>
                                    : item.net_weight
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0text-end" style={{ width: '75px' }} id={`unsize_beam_weight_${index}`} value={item.unsize_beam_weight} onChange={(e) => updatedSizingProductionDetails("unsize_beam_weight", item, e)} disabled />
                                    </>
                                    : item.unsize_beam_weight
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`size_pickup_${index}`} value={item.size_pickup} onChange={(e) => updatedSizingProductionDetails("size_pickup", item, e)} disabled />
                                    </>
                                    : item.size_pickup
                            }
                        </td>



                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 remove0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`size_waste_${index}`} value={item.size_waste} onChange={(e) => updatedSizingProductionDetails("size_waste", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.size_waste
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 remove0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`unsize_waste_${index}`} value={item.unsize_waste} onChange={(e) => updatedSizingProductionDetails("unsize_waste", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </>
                                    : item.unsize_waste
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 remove0 text-end" style={{ backgroundColor: '#AFE1AF', width: '75px' }} id={`creel_waste_${index}`} value={item.creel_waste} onChange={(e) => updatedSizingProductionDetails("creel_waste", item, e)} disabled={item.sizing_production_status_desc === 'Approved' || keyForViewUpdate === 'approve'} />
                                    </> : item.creel_waste
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" id={`rate_${index}`} value={item.rate} style={{ width: '75px' }} Headers='rate'
                                            disabled={item.sizing_production_status_desc === 'Approved'} onChange={(e) => updatedSizingProductionDetails("rate", item, e)} />
                                    </> : item.rate
                            }
                        </td>


                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0 text-end" style={{ width: '75px' }} id={`amount_${index}`} value={item.amount} onChange={(e) => updatedSizingProductionDetails("amount", item, e)} disabled={item.sizing_production_status_desc === 'Approved'} />
                                    </>
                                    : item.amount
                            }
                        </td>
                        <td className="erp_table_td">
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <select id={`sizing_production_status_${index}`} value={item.sizing_production_status} className="form-select form-select-sm mb-0 " Headers='sizing_production_status'
                                            disabled={isApprove ? item.sizing_production_status_desc === 'Approved' : true}
                                            onChange={(e) => { updatedSizingProductionDetails("sizing_production_status", item, e); FnValidateForm(); }}>
                                            <option value="P">Pending</option>
                                            <option value="A">Approved</option>
                                            <option value="R">Rejected</option>
                                            <option value="C">Completed</option>
                                            <option value="X">Canceled</option>
                                            <option value="I">Partial</option>
                                        </select>
                                    </>
                                    : item.sizing_production_status_desc
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <>
                                        <input type="text" className="erp_input_field_table_txt mb-0" id={`status_remark_${index}`} value={item.status_remark} disabled={!isApprove} onChange={(e) => updatedSizingProductionDetails("status_remark", item, e)} />
                                    </>
                                    : item.status_remark
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <select id={`production_sub_section_godown_id_${index}`} value={item.godown_id} className="erp_input_field_table_txt mb-0" Headers="godown_id" onChange={(e) => { updatedSizingProductionDetails('godown_id', item, e); }}
                                        disabled={item.sizing_production_status_desc === 'Approved'} >
                                        <option value="">Select</option>
                                        {godownNameOptions?.map(godownType => (
                                            <option value={godownType.godown_id}>{godownType.godown_name}</option>
                                        ))}
                                    </select>
                                    : item.godown_name
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [sizingProductionData]);

    const updatedSizingProductionDetails = (propertyName, currentMaterial, event, selectedOptionForMachineOperator, beamSizeArr) => {
        try {
            setIsLoading(true);
            let updatedDetailsData = [...sizingProductionData]
            debugger
            let arrayIndex = 0;
            // let setNoBeamData = selectedSetNoBeamData;
            if (propertyName !== 'production_operator_id') {
                arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
            } else {
                arrayIndex = parseInt(event.inputRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getAttribute('rowindex'))
                let machineOperatorId = $('#tooltipforsupervisor_' + arrayIndex);
                if (selectedOptionForMachineOperator.label !== 'Select') {
                    currentMaterial[propertyName] = selectedOptionForMachineOperator.field_id;
                    machineOperatorId.removeAttr('data-tip');
                } else {
                    machineOperatorId.attr('data-tip', 'Select Machine Operator');
                }
            }

            let warping_length = $("#warping_length").val();
            let warping_creel_ends = $("#warping_creel_ends").val();
            let warping_no_of_creels = $("#warping_no_of_creels").val();

            switch (propertyName) {
                case 'sizing_beam_no':
                    currentMaterial['sizing_beam_no'] = event.target.value;
                    break;
                // case 'beam_no':
                //     currentMaterial[propertyName] = beamSizeArr;
                //     let warpingLength = 0; let warpingCreels = 0; let totalNoOfEnds = 0;
                //     beamSizeArr.forEach((index) => {
                //         let selectedBeamdata = currentMaterial['selectedSetNoBeamdata'].find((data) => {
                //             return data['beam_no'] === index;
                //         });
                //         if (selectedBeamdata) {
                //             warpingLength += selectedBeamdata.length;
                //             totalNoOfEnds += selectedBeamdata.creel_ends;
                //             warpingCreels += selectedBeamdata.no_of_creels;
                //         }
                //     });

                //     currentMaterial['warping_length'] = warpingLength;
                //     currentMaterial['total_ends'] = totalNoOfEnds;
                //     currentMaterial['no_of_creels'] = warpingCreels;
                //     break;

                case 'sizing_length':
                    if (event._reactName === 'onChange') {
                        //check value is not equal to empty or not
                        if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
                            //checking for not to exceed warping length
                            let totalSizingLength = updatedDetailsData.reduce((acc, data, index) => {
                                if (arrayIndex !== index) {
                                    acc += data['sizing_length'];
                                }
                                return acc;  // Always return the accumulator
                            }, 0);

                            totalSizingLength += parseInt(event.target.value) || 0;  // Ensure it's a valid number

                            if (parseInt(totalSizingLength) > parseInt(warping_length)) {
                                event.target.parentElement.dataset.tip = 'Cant exceed Warping length...!'
                                // currentMaterial[propertyName] = currentMaterial.warping_length
                                $(`#${event.target.id}`).focus();
                                setTimeout(function () {
                                    delete event.target.parentElement.dataset.tip;
                                }, 4000);
                            } else {
                                currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                                fnCalculateWeight(currentMaterial)
                                delete event.target.parentElement.dataset.tip;
                                currentMaterial.sizing_production_status = 'P'
                            }
                        }
                        // checking value if it is '' or 0 then dispplay error msg 
                        else if (parseInt(event.target.value) === 0 || event.target.value === '') {
                            currentMaterial[propertyName] = '';
                            event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                            $(`#${event.target.id}`).focus();
                        }
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'gross_weight':
                    if (event._reactName === 'onChange') {
                        if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
                            currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                            fnCalculateWeight(currentMaterial)
                            delete event.target.parentElement.dataset.tip;
                            currentMaterial.sizing_production_status = 'P'
                        } else if (parseInt(event.target.value) === 0) {
                            currentMaterial[propertyName] = '';
                            event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                            $(`#${event.target.id}`).focus();
                        }
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'tare_weight':
                    debugger
                    if (event._reactName === 'onChange') {
                        if (parseInt(event.target.value) > parseInt(currentMaterial.gross_weight)) {
                            event.target.parentElement.dataset.tip = 'Tare Weight Cannot exceed Gross Weight...!'
                            $(`#${event.target.id}`).focus();
                            setTimeout(function () {
                                delete event.target.parentElement.dataset.tip;
                            }, 4000);
                        } else {
                            currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                            fnCalculateWeight(currentMaterial)
                            delete event.target.parentElement.dataset.tip;
                            currentMaterial.sizing_production_status = 'P'

                        }
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'total_ends':
                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                        fnCalculateWeight(currentMaterial)
                        delete event.target.parentElement.dataset.tip;
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;


                case 'net_weight':
                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                        fnCalculateWeight(currentMaterial)
                        delete event.target.parentElement.dataset.tip;
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'unsize_beam_weight':
                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                        fnCalculateWeight(currentMaterial)
                        delete event.target.parentElement.dataset.tip;
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'rate':
                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                        fnCalculateWeight(currentMaterial)
                        currentMaterial.sizing_production_status = 'P'
                        delete event.target.parentElement.dataset.tip;
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'sizing_production_status':
                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = event.target.value;
                        delete event.target.parentElement.dataset.tip;
                        if (event.target.value === "A") {
                            currentMaterial.status_remark = '';
                        }
                    }
                    break;

                case 'status_remark':
                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = event.target.value;
                        fnCalculateWeight(currentMaterial)

                        delete event.target.parentElement.dataset.tip;
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        event.target.parentElement.dataset.tip = 'field should not be a zero or blank...!'
                        $(`#${event.target.id}`).focus();
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;

                case 'godown_id':
                case 'shift':
                    delete event.target.parentElement.dataset.tip;
                    if (event.target.value !== '') {
                        const combinationExists = sizingProductionData.find(item =>
                            item.shift === event.target.value && item.weaving_production_set_no === currentMaterial.weaving_production_set_no
                        );
                        if (combinationExists) {
                            event.target.parentElement.dataset.tip = '"Shift and set number combination already in use. Please select a different combination...!';
                            currentMaterial[propertyName] = "";
                            break;
                        } else {
                            currentMaterial[propertyName] = event.target.value
                        }
                    } else {
                        currentMaterial[propertyName] = event.target.value;
                    }
                    break;
                // case 'shift':
                //     currentMaterial[propertyName] = event.target.value
                //     currentMaterial.sizing_production_status = 'P'
                //     delete event.target.parentElement.dataset.tip;
                //     break;
                case 'product_material_id':
                    currentMaterial[propertyName] = event.target.value
                    currentMaterial.sizing_production_status = 'P'
                    delete event.target.parentElement.dataset.tip;
                    break;
                case 'sizing_production_date':
                    currentMaterial['sizing_production_date'] = event.target.value;
                    break;
                case 'unsize_waste':
                case 'creel_waste':
                case 'size_waste':

                case 'machine_id':
                // case 'production_operator_id':
                case 'weaving_production_set_no':
                case 'yarn_count':
                case 'actual_count':
                case 'warping_length':

                case 'size_pickup':

                    if (event._reactName === 'onChange') {
                        currentMaterial[propertyName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                        fnCalculateWeight(currentMaterial)
                        currentMaterial.sizing_production_status = 'P'
                        delete event.target.parentElement.dataset.tip;
                    } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                        currentMaterial[propertyName] = 0;
                        event.target.value = 0; // Set the value to 0
                        fnCalculateWeight(currentMaterial)
                        delete event.target.parentElement.dataset.tip;
                    } else {
                        currentMaterial[propertyName] = 0;
                    }
                    break;
                case 'sizing_rs':
                    if (event.target.value > 0 && event.target.value < 100) {
                        currentMaterial[propertyName] = event.target.value
                        delete event.target.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.dataset.tip = 'Enter R.S. properly....'
                    }
                    break;
                default:
                    break;
            }


            updatedDetailsData[arrayIndex] = currentMaterial;
            setsizingProductionData(updatedDetailsData);

        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const FnValidateForm = async () => {
        debugger
        let validStatus = true;
        const tableRows1 = document.querySelectorAll('#sizingProductionTbl tbody tr');
        tableRows1.forEach(row => {
            const statusRemark = row.querySelector('input[id^="status_remark_"]');
            const SizingStatus = row.querySelector(`select[id^="sizing_production_status_"]`);
            const status = statusRemark.value.trim();
            const SizingStatusValue = SizingStatus.value.trim();

            if (SizingStatusValue === "R") {
                if (status === '') {
                    statusRemark.parentElement.dataset.tip = 'Please enter the Rejection remark...!';
                    statusRemark.focus();
                    validStatus = false;
                    return;
                } else {
                    delete statusRemark.parentElement.dataset.tip;
                }
            } else {
                delete statusRemark.parentElement.dataset.tip;
            }

        });
        return validStatus;
    };

    const fnCalculateWeight = (currentMaterial) => {
        // debugger

        let grossWeight = parseFloat(currentMaterial.gross_weight)
        let tareWeight = parseFloat(currentMaterial.tare_weight)

        let sizingLength = parseFloat(currentMaterial.sizing_length);
        // let totalNoOfEnds = parseFloat(currentMaterial.total_ends);
        let totalNoOfEnds = parseInt($("#warping_creel_ends").val());
        // let actualCount = parseFloat(currentMaterial.actual_count);
        let actualCount = parseFloat($("#production_count").val());
        let newNetWeight = 0;
        newNetWeight = parseFloat(currentMaterial.net_weight);
        // let unsizeBeamWeight = parseFloat(currentMaterial.unsize_beam_weight);
        let rate = parseFloat(currentMaterial.rate);
        let calculateUnsizeBeamWeight = 0

        //to calculate net weight
        if (grossWeight != '' || tareWeight != '') {
            newNetWeight = grossWeight - tareWeight
            currentMaterial['net_weight'] = validateNumberDateInput.current.decimalNumber(newNetWeight.toString(), 4);
        } else {
            currentMaterial['net_weight'] = 0;
        }

        //to calcualte unsize beam weight 

        if (sizingLength !== '' && totalNoOfEnds !== '' && actualCount !== '') {
            sizingLength = parseFloat(sizingLength);
            totalNoOfEnds = parseFloat(totalNoOfEnds);
            actualCount = parseFloat(actualCount);

            if (!isNaN(sizingLength) && !isNaN(totalNoOfEnds) && !isNaN(actualCount) && actualCount !== 0) {
                let calculateDenominator = 1693 * actualCount;
                let calculateNumerator = sizingLength * totalNoOfEnds;
                calculateUnsizeBeamWeight = calculateNumerator / calculateDenominator;
                currentMaterial['unsize_beam_weight'] = validateNumberDateInput.current.decimalNumber(calculateUnsizeBeamWeight.toString(), 4);
            } else {
                currentMaterial['unsize_beam_weight'] = 0;
            }
        } else {
            currentMaterial['unsize_beam_weight'] = 0;
        }
        //calculte  SIZE PICK-UP %
        // ((Net Weight - Unsize Beam weight)/Unsize Beam weight)*100
        if (newNetWeight !== '' && calculateUnsizeBeamWeight !== '') {
            if (!isNaN(newNetWeight) && !isNaN(calculateUnsizeBeamWeight) && calculateUnsizeBeamWeight !== 0) {
                // let calculatedDifference = Difference=Net Weight−Unsize Beam Weight
                let calculatedDifference = newNetWeight - calculateUnsizeBeamWeight
                let calculateratio = (calculatedDifference / calculateUnsizeBeamWeight);
                let calculateSizePickUp = calculateratio * 100;
                currentMaterial['size_pickup'] = validateNumberDateInput.current.decimalNumber(calculateSizePickUp.toString(), 4);
            } else {
                currentMaterial['size_pickup'] = 0;
            }
        } else {
            currentMaterial['size_pickup'] = 0;
        }
        // calculate Amount 
        if (rate !== '' && calculateUnsizeBeamWeight !== '') {
            if (!isNaN(rate) && !isNaN(calculateUnsizeBeamWeight) && calculateUnsizeBeamWeight !== 0) {
                let calculateAmt = rate * calculateUnsizeBeamWeight
                currentMaterial['amount'] = validateNumberDateInput.current.decimalNumber(calculateAmt.toString(), 4);
            } else {
                currentMaterial['amount'] = 0;
            }
        } else {
            currentMaterial['amount'] = 0;
        }

        return currentMaterial;
    }


    //Material Moment//
    const SizingrenderMaterialMomentDetails = useMemo(() => {
        debugger

        return <Table id='sizinigProductionTbl' className={`erp_table ${sizingProductionMaterialData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                    <th className="erp_table_th">Sr no .</th>
                    <th className="erp_table_th">Date</th>
                    <th className="erp_table_th">Shift</th>
                    {/* <th className="erp_table_th">Set no</th>
                <th className="erp_table_th">Material Name</th> */}
                    <th className="erp_table_th">Material Unit</th>
                    <th className="erp_table_th">Quantity</th>
                    <th className="erp_table_th">Balance Quantity</th>
                    {/* changes by tushar */}
                    <th className="erp_table_th">Stock Qty</th>
                    <th className="erp_table_th">Consumption Quantity</th>
                    <th className="erp_table_th">Material Status</th>
                    <th className="erp_table_th">Status Remark</th>
                </tr>
            </thead>
            <tbody>
                {sizingProductionMaterialData.map((item, index) =>

                    <tr rowindex={index} className="sticky-column">
                        <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                            <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                            <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(index)} />
                        </td>

                        <td className='erp_table_td'>
                            {index + 1}
                        </td>
                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <>
                                        <input
                                            type="date"
                                            className="erp_input_field mb-0"
                                            disabled={item.material_status_desc === 'Approved'}
                                            Headers="sizing_production_date"
                                            id={`sizing_production_date_${index}`}
                                            value={item.sizing_production_date}
                                        // onChange={(e) => handleSetNoChange(item, e, index)}
                                        />
                                    </>
                                    : item.sizing_production_date
                            }
                        </td>
                        <td className={`erp_table_td `}>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <select id={`shift_${index}`} value={item.shift} className="erp_input_field_table_txt mb-0"
                                        Headers="shift"
                                        disabled={(keyForViewUpdate === 'update' || keyForViewUpdate === 'approve') && (item.material_status_desc === 'Approved' || item.product_material_unit_name) ? true : false}
                                    // onChange={(e) => handleSetNoChange(item, e, index)}
                                    >
                                        <option value="">Select</option>
                                        {shiftOptions?.map(shift => (
                                            <option value={shift.field_name}>{shift.field_name}</option>
                                        ))}
                                    </select>
                                    : item.shift
                            }
                        </td>

                        {/* <td className={`erp_table_td `}>
                        {
                            keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                ? <select id={`weaving_production_set_no_${index}`} value={item.weaving_production_set_no} className="erp_input_field_table_txt mb-0"
                                    Headers="weaving_production_set_no"
                                    disabled={item.material_status_desc === 'Approved'}
                                    onChange={(e) => { handleSetNoChange(item, e, index); }}
                                >
                                    <option value="">Select</option>
                                    {setNoOptions?.map(setNO => (
                                        <option value={setNO.set_no}>{setNO.set_no}</option>
                                    ))}

                                </select>
                                : item.weaving_production_set_no
                        }
                    </td>

                    <td className="erp_table_td text-end">
                        <select className="form-select form-select-sm mb-0" id={`product_material_id_${index}`} value={item.product_material_id} disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                            onChange={(e) => { handleSetNoChange(item, e, index); }}
                            Headers='product_material_id' >
                            <option value="">Select</option>
                        </select>

                    </td> */}

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`product_material_unit_name_${index}`}>{item.product_material_unit_name}</span>
                                    : item.product_material_unit_name
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <span className="erp_input_field_table_txt mb-0" id={`product_material_quantity_${index}`}>{item.product_material_quantity}</span>
                                    : item.product_material_quantity
                            }
                        </td>

                        <td className='erp_table_td'>
                            {
                                keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                                    ? <input className="erp_input_field_table_txt mb-0" id={`product_material_sz_balance_quantity_${index}`}
                                        Headers="product_material_sz_balance_quantity"
                                        value={item.product_material_sz_balance_quantity}
                                    />
                                    : item.product_material_sz_balance_quantity
                            }
                        </td>

                        <td className={`erp_table_td`}>{item?.stock_quantity ? item.stock_quantity : 0}</td>

                        {/* <td className='erp_table_td'>
                        {
                            keyForViewUpdate !== 'view'
                                ? <div className='d-flex' style={{ alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        className="erp_input_field_table_txt remove0 mb-0 ms-1"
                                        id={`consumption_quantity_${index}`}
                                        value={item.consumption_quantity}
                                        Headers="consumption_quantity"
                                        style={{ backgroundColor: '#AFE1AF' }}
                                        onChange={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); }}
                                        disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                        maxLength='19'
                                    />
                                    <FaDatabase onClick={() => FnMaterialConsumption(item, index)} />
                                </div>
                                : item.consumption_quantity
                        }
                    </td> */}

                        <td className='erp_table_td'>
                            {keyForViewUpdate !== 'view'
                                ? <>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <input
                                            type="text"
                                            className="erp_input_field_table_txt remove0 mb-0 ms-1 text_end"
                                            id={`consumption_quantity_${index}`}
                                            value={item.consumption_quantity}
                                            Headers="consumption_quantity"
                                            style={{ backgroundColor: '#AFE1AF' }}
                                            // onChange={(e) => { handleSetNoChange(item, e, index); }}
                                            disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                                            maxLength='19' />
                                        {/* <FaDatabase onClick={() => FnMaterialConsumption(item, index)} /> */}

                                    </div>
                                </>
                                : item.consumption_quantity}
                        </td>

                        <td className="erp_table_td">
                            <select id={`material_status${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.material_status}
                                disabled={isApprove ? item.material_status_desc === 'Approved' : true}
                                // onChange={(e) => { handleSetNoChange(item, e, index); FnValidateForm(); }}
                                Headers='material_status'>
                                <option value="P">Pending</option>
                                <option value="A">Approved</option>
                                <option value="R">Rejected</option>
                                <option value="I">Partial Issue</option>
                                <option value="C">Completed</option>
                                <option value="X">Canceled</option>
                                <option value="Z">PreeClosed</option>
                            </select>
                        </td>

                        <td className='erp_table_td'>
                            {keyForViewUpdate === 'approve' ? (
                                <input
                                    type="text" id={`status_remark_${index}`}
                                    disabled={!isApprove}
                                    className="erp_input_field_table_txt mb-0" value={item.status_remark}
                                // Headers='status_remark'
                                // onChange={(e) => { handleSetNoChange(item, e, index); }}
                                />
                            ) : (
                                item.status_remark
                            )}
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    }, [sizingProductionMaterialData])


    // ------------------------------------------save---------------------------------------------------------------

    const addSizingProduction = async () => {
        debugger
        try {
            setIsLoading(true)
            let isCalculationValid = false;
            if (keyForViewUpdate === 'approve') {
                isCalculationValid = await FnValidateForm()
            }

            if (cmb_prod_supervisor_id === '' || cmb_prod_supervisor_id === '0') {
                $("#error_cmb_prod_supervisor_id").text("Please fill this field...!");
                $("#error_cmb_prod_supervisor_id").show();
                $("#cmb_prod_supervisor_id").focus();
                return false;
            }

            // let checkValidProdDetails = false;
            // if (keyForViewUpdate !== 'approve')
            //     checkValidProdDetails = await validateProdDetails()

            // let checkValidMaterialDetails = false;
            // if (keyForViewUpdate !== 'approve')
            //     checkValidMaterialDetails = await validateMaterialDetails()


            // if ((checkValidMaterialDetails === true || keyForViewUpdate === 'approve') && (checkValidProdDetails === true || keyForViewUpdate === 'approve') && (keyForViewUpdate !== "approve" || isCalculationValid === true)) {
            let lastestStatus = txt_Production_Sizing_Status;
            if (keyForViewUpdate === 'approve') {
                const allApprovedWeaving = sizingProductionData.every(item => item.sizing_production_status === 'A');
                const allApprovedMaterial = sizingProductionMaterialData.every(item => item.material_status === 'A');
                const anyApproved = sizingProductionData.some(item => item.sizing_production_status === 'A') || sizingProductionMaterialData.some(item => item.material_status === 'A');
                const allPending = sizingProductionData.every(item => item.sizing_production_status === 'P') && sizingProductionMaterialData.every(item => item.material_status === 'P');
                const anyRejected = sizingProductionData.some(item => item.sizing_production_status === 'R') || sizingProductionMaterialData.some(item => item.material_status === 'R');

                if (anyRejected) {
                    lastestStatus = 'R';
                } else if (allApprovedWeaving && allApprovedMaterial) {
                    lastestStatus = 'A';
                } else if (anyApproved) {
                    lastestStatus = 'I';
                } else if (allPending) {
                    lastestStatus = 'P';
                } else {
                    lastestStatus('p');
                }
            }

            const json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransSZStoppageData': [], 'TransSZMaterialData': [], 'TransSZWastageData': [], 'commonIds': {} }
            //master data
            const sizingProductionMasterData = {
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                weaving_production_sizing_master_id: txt_weaving_production_sizing_master_id,
                sizing_production_code: txt_sizing_production_code,
                sizing_production_date: dt_sizing_production_date,
                prod_month: txt_production_month,
                prod_year: txt_production_year,
                plant_id: cmb_plant_id,
                section_id: cmb_section_id,
                sub_section_id: cmb_sub_section_id,
                production_supervisor_id: cmb_prod_supervisor_id,
                is_active: chk_isactive,
                created_by: UserName,
                modified_by: txt_weaving_production_sizing_master_id !== 0 ? UserName : null,
                sizing_production_master_status: lastestStatus
            }
            json.TransHeaderData = sizingProductionMasterData;
            console.log("sizingProductionMasterData", sizingProductionMasterData);
            //Details data
            for (let index = 0; index < sizingProductionData.length; index++) {
                const element = sizingProductionData[index];
                const addSizingProductionData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    weaving_production_sizing_details_id: element.weaving_production_sizing_details_id,
                    sizing_production_order_no: element.sizing_production_order_no,
                    sizing_production_status: element.sizing_production_status,
                    sizing_production_date: element.sizing_production_date,
                    prod_month: txt_production_month,
                    prod_year: txt_production_year,
                    shift: element.shift,
                    weaving_production_set_no: $("#cmb_set_no option:selected").val(),
                    // product_material_id: element.product_material_id, godown_id
                    beam_no: JSON.stringify(element.beam_no),
                    sizing_beam_no: element.sizing_beam_no,
                    actual_count: element.actual_count,
                    total_ends: element.total_ends,
                    warping_length: element.warping_length,
                    sizing_length: element.sizing_length,
                    net_weight: element.net_weight,
                    gross_weight: element.gross_weight,
                    tare_weight: element.tare_weight,
                    unsize_beam_weight: element.unsize_beam_weight,
                    size_pickup: element.size_pickup,
                    sizing_rs: element.sizing_rs,
                    rate: element.rate,
                    amount: element.amount,
                    plant_id: cmb_plant_id,
                    yarn_count: element.yarn_count,
                    production_operator_id: element.production_operator_id,
                    production_supervisor_id: cmb_prod_supervisor_id,
                    section_id: cmb_section_id,
                    sub_section_id: cmb_sub_section_id,
                    machine_id: element.machine_id,
                    sizing_production_code: txt_sizing_production_code,
                    size_waste: element.size_waste,
                    unsize_waste: element.unsize_waste,
                    creel_waste: element.creel_waste,
                    godown_id: parseInt(element.godown_id),
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: element.weaving_production_sizing_details_id !== 0 ? UserName : null,
                    status_remark: element.status_remark
                }
                json.TransDetailData.push(addSizingProductionData)
            }
            //stoppage Details data
            for (let index = 0; index < stoppageDetails.length; index++) {
                const stoppageDataElement = stoppageDetails[index];
                const stoppageData = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    sizing_production_code: txt_sizing_production_code,
                    sizing_production_date: dt_sizing_production_date,
                    prod_month: txt_production_month,
                    prod_year: txt_production_year,
                    plant_id: cmb_plant_id,
                    production_supervisor_id: cmb_prod_supervisor_id,
                    production_stoppage_reasons_id: stoppageDataElement.production_stoppage_reasons_id,
                    shift: stoppageDataElement.shift,
                    section_id: cmb_section_id,
                    sub_section_id: cmb_sub_section_id,
                    weaving_production_sizing_stoppage_id: stoppageDataElement.weaving_production_sizing_stoppage_id,
                    weaving_production_sizing_master_id: txt_weaving_production_sizing_master_id,
                    std_stoppage_loss_per_hour: stoppageDataElement.std_stoppage_loss_per_hour,
                    stoppage_time: stoppageDataElement.stoppage_time,
                    loss_type: stoppageDataElement.loss_type,
                    std_stoppage_loss_kg: stoppageDataElement.std_stoppage_loss_kg,
                    stoppage_production_loss_kg: stoppageDataElement.stoppage_production_loss_kg,
                    actual_production_loss_kg: stoppageDataElement.actual_production_loss_kg,
                    machine_id: stoppageDataElement.machine_id,
                    created_by: UserName,
                    modified_by: stoppageDataElement.weaving_production_sizing_stoppage_id !== 0 ? UserName : null,
                };
                json.TransSZStoppageData.push(stoppageData)
            }

            //material movement data
            if (sizingProductionMaterialData.length > 0) {
                let lastIndexOfMaterialMovement = sizingProductionMaterialData.length - 1
                if (sizingProductionMaterialData[lastIndexOfMaterialMovement].weaving_production_set_no !== 0) {
                    for (let index = 0; index < sizingProductionMaterialData.length; index++) {
                        const materialDataElement = sizingProductionMaterialData[index];
                        const materialMovementData = {
                            company_branch_id: COMPANY_BRANCH_ID,
                            company_id: COMPANY_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            plant_id: cmb_plant_id,
                            section_id: cmb_section_id,
                            sub_section_id: cmb_sub_section_id,
                            weaving_production_sizing_material_id: materialDataElement.weaving_production_sizing_material_id === undefined || materialDataElement.weaving_production_sizing_material_id === null ? 0 : materialDataElement.weaving_production_sizing_material_id,
                            weaving_production_sizing_details_id: 0,
                            weaving_production_sizing_master_id: 0,
                            sizing_production_date: materialDataElement.sizing_production_date,
                            sizing_production_code: txt_sizing_production_code,
                            sizing_production_order_no: materialDataElement.sizing_production_order_no,
                            prod_month: txt_production_month,
                            prod_year: txt_production_year,
                            shift: materialDataElement.shift,
                            weaving_production_set_no: $("#cmb_set_no option:selected").val(),
                            product_material_id: materialDataElement.product_material_id,
                            product_material_unit_id: materialDataElement.product_material_unit_id,
                            product_material_quantity: materialDataElement.product_material_quantity,
                            consumption_quantity: materialDataElement.consumption_quantity,
                            material_status: materialDataElement.material_status,
                            material_status_remark: materialDataElement.material_status_remark,
                            is_active: chk_isactive,
                            created_by: UserName,
                            modified_by: txt_weaving_production_sizing_master_id === 0 ? null : UserName,
                        };
                        json.TransSZMaterialData.push(materialMovementData)
                    }
                }
            }

            for (let index = 0; index < wastageDetails.length; index++) {
                const wastageDataElement = wastageDetails[index];
                const wastageData = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    weaving_production_sizing_wastage_id: wastageDataElement.weaving_production_sizing_wastage_id,
                    weaving_production_sizing_master_id: txt_weaving_production_sizing_master_id,
                    sizing_production_code: txt_sizing_production_code,
                    sizing_production_date: dt_sizing_production_date,
                    prod_month: txt_production_month,
                    prod_year: txt_production_year,
                    plant_id: cmb_plant_id,
                    section_id: cmb_section_id,
                    sub_section_id: cmb_sub_section_id,
                    shift: wastageDataElement.shift,
                    // machine_id : 
                    production_wastage_types_id: wastageDataElement.production_wastage_types_id,
                    godown_id: wastageDataElement.godown_id,
                    production_wastage_types_type: wastageDataElement.production_wastage_types_type,
                    wastage_quantity: wastageDataElement.wastage_quantity,
                    created_by: UserName,
                    modified_by: txt_weaving_production_sizing_master_id === 0 ? null : UserName,
                };
                json.TransSZWastageData.push(wastageData)
            }

            json.commonIds.company_id = COMPANY_ID
            json.commonIds.weaving_production_sizing_master_id = WeavingProductionSizingMasterId
            console.log('sizing production Details', json);

            const formData = new FormData()
            formData.append('WeavingProductionSizingData', JSON.stringify(json))

            const forwardData = {
                method: 'POST',
                body: formData,
            }
            const sizingProductionApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnAddUpdateRecord`, forwardData)
            const response = await sizingProductionApiCall.json();

            if (response.success === '0') {
                setErrMsg(response.error)
                setShowErrorMsgModal(true)
            } else {
                setSuccMsg(response.message)
                setShowSuccessMsgModal(true)
            }
            // }
        } catch (error) {
            console.log("error", error);
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    }

    // ---------------------------------fn check update ends--------------------------------------------------------------------

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionSizingDetails/FnShowParticularRecordForUpdate/${WeavingProductionSizingMasterId}/${COMPANY_ID}`)
            const responce = await apiCall.json();
            console.log(' Sizing Production : ', responce);

            let updateSizingProductionDetailsData = responce.WeavingProductionSizingDetailsRecord;
            let updateSizingProductionMasterData = responce.WeavingProductionSizingMasterModelRecord
            let updateSizingProductionMaterialMovement = responce.WeavingProductionSizingMaterialRecord

            if (updateSizingProductionMasterData != '' && updateSizingProductionMasterData != null) {

                setProdctionMonth(updateSizingProductionMasterData.prod_month)
                setProductionYear(updateSizingProductionMasterData.prod_year)
                setProductionPlantName(updateSizingProductionMasterData.plant_id)
                setProdSection(updateSizingProductionMasterData.section_id)
                setProdSubSection(updateSizingProductionMasterData.sub_section_id)
                await comboOnChange('subSectionOnChange');
                setSizingProductionDate(updateSizingProductionMasterData.sizing_production_date)
                setProdSupervisor(updateSizingProductionMasterData.production_supervisor_id)
                setWeavingProductionSizingMasterId(updateSizingProductionMasterData.weaving_production_sizing_master_id)
                setSizingProductionCode(updateSizingProductionMasterData.sizing_production_code)
                setIsActive(updateSizingProductionMasterData.is_active)
            }
            // debugger

            if (updateSizingProductionDetailsData !== null && updateSizingProductionDetailsData !== "") {
                setsizingProductionData(prevArray => {
                    return updateSizingProductionDetailsData.map(item => {
                        return {
                            ...item,
                            beam_no: JSON.parse(item.beam_no),
                            product_material_name: item.so_item_name,
                            godown_id: item.godown_id,
                            product_material_sz_balance_quantity: item.product_material_quantity - item.consumption_quantity
                        };
                    });
                });
            }
            if (responce.WeavingProductionSizingStoppageRecord !== null && responce.WeavingProductionSizingStoppageRecord !== "") {
                setStoppageDetails(responce.WeavingProductionSizingStoppageRecord)
            }
            if (updateSizingProductionMaterialMovement !== null && updateSizingProductionMaterialMovement !== "") {
                setsizingProductionMaterialData(updateSizingProductionMaterialMovement)
            }
            if (responce.WeavingProductionSizingWastageRecord !== null && responce.WeavingProductionSizingWastageRecord !== "") {
                setWastageDetails(responce.WeavingProductionSizingWastageRecord)
            }
            fnManageSizingStatus(updateSizingProductionDetailsData, updateSizingProductionMaterialMovement)


            setActiveStep(1);
            setActiveStep(0);


        } catch (error) {
            console.log('error: ', error);
        }
    }
    // ---------------------------------fn check update ends--------------------------------------------------------------------
    // generate autono
    const generateSizingProductionCode = async () => {
        // debugger
        const productionDate = $('#dt_sizing_production_date').val();
        const [year, month, day] = productionDate.split('-');
        const formatted_date = `${day}${month}${year.slice(2)}`;
        const subSectionVal = $('#cmb_sub_section_id').val();
        const shortName = $('#cmb_sub_section_id option:selected').attr('shortName');
        comboOnChange('subSectionOnChange')
        const SizingProductionCode = await generateAutoNoAPiCall.current.generateProductionCode({
            "entity_name": "xt_weaving_production_sizing_master",
            "sub_section": subSectionVal,
            "sub_section_short_name": shortName,
            "field_name": "sizing_production_code",
            "transaction_date": formatted_date,
            "trans_no_length": "4",
            "company_id": COMPANY_ID,
        });
        console.log(' Sizing Production : ', SizingProductionCode);
        setSizingProductionCode(SizingProductionCode)
    }
    // --------------------------------- Machine production stoppage------------------------------------------------------------------------------------------------

    const clearFormFields = (form_type) => {
        switch (form_type) {
            case 'stoppage':
                setMachineNo('')
                setMachineStoppageReason('')
                setMachineStoppageTime('')
                setStdProdLoss('')
                setLossType('')
                setShift('')
                setActProdLoss('')
                setStoppageProductionLossKg('')
                setStdStoppageLossPerHour('')
                break;
            case 'wastage':
                setProdWastageType('');
                setWastageQuantity('');
                setgodownId('');
                setShiftWastage('');
                setLabelForWastage('Add');
                $('#wastageFormId input, #wastageFormId select').val('');
                break;
                break;
        }
    }


    const FnUpdateStoppageReason = async (row_data, index_Of_Row, action) => {
        // debugger
        if (action === 'update') {
            setLabelForStopage('Update')
        } else {
            setLabelForStopage('Add')
        }

        const machineStoppageDetails = [...stoppageDetails];
        // let ringFrameDetails = [...ringFrameProdShiftData];
        switch (action) {
            case 'add':
                const validateStoppageReason = await validate.current.validateForm('stoppageFormId');
                if (validateStoppageReason) {

                    const isDuplicate = machineStoppageDetails.some(stoppageDetails =>
                        stoppageDetails.machine_id === cmb_machine_no &&
                        stoppageDetails.production_stoppage_reasons_id === cmb_stoppage_reason &&
                        stoppageDetails.shift === cmb_shift &&
                        stoppageDetails.stoppage_time === txt_stoppage_time &&
                        stoppageDetails.loss_type === cmb_loss_type &&
                        stoppageDetails.std_stoppage_loss_per_hour === txt_std_stoppage_loss_per_hour &&
                        stoppageDetails.stoppage_production_loss_kg === txt_stoppage_production_loss_kg &&
                        stoppageDetails.std_stoppage_loss_kg === txt_std_stoppage_loss_kg &&
                        stoppageDetails.actual_production_loss_kg === txt_actual_production_loss_kg

                    );
                    if (isDuplicate) {
                        $('#error_cmb_stoppage_reason').text('Stoppage Reason already exists...').css({ 'display': 'block', 'margin-top': '8px' });
                    } else {
                        $('#error_cmb_stoppage_reason').hide();

                        ///To Update Row
                        const currentRowForUpdate = machineStoppageDetails.find((stoppage_Detail, index) => (
                            prevdataindexforStoppage === index
                        ));

                        if (currentRowForUpdate) {
                            currentRowForUpdate.machine_id = cmb_machine_no;
                            currentRowForUpdate.machine_name = $('#cmb_machine_no option:selected').text();
                            currentRowForUpdate.production_stoppage_reasons_id = cmb_stoppage_reason;
                            currentRowForUpdate.production_stoppage_reasons_name = $('#cmb_stoppage_reason option:selected').text();
                            currentRowForUpdate.shift = $('#cmb_shift option:selected').text();
                            currentRowForUpdate.loss_type = $('#cmb_loss_type option:selected').text();
                            currentRowForUpdate.stoppage_time = txt_stoppage_time;
                            currentRowForUpdate.std_stoppage_loss_kg = txt_stoppage_production_loss_kg;
                            currentRowForUpdate.actual_production_loss_kg = txt_actual_production_loss_kg;
                            setPrevDataIndexForStoppage('');
                        }

                        if (!currentRowForUpdate) {
                            const newStoppageDetail = {
                                machine_id: cmb_machine_no,
                                machine_name: $('#cmb_machine_no option:selected').text(),
                                production_stoppage_reasons_id: cmb_stoppage_reason,
                                production_stoppage_reasons_name: $('#cmb_stoppage_reason option:selected').text(),
                                shift: $('#cmb_shift option:selected').text(),
                                stoppage_time: txt_stoppage_time,
                                loss_type: cmb_loss_type,
                                std_stoppage_loss_kg: txt_std_stoppage_loss_kg,
                                actual_production_loss_kg: txt_actual_production_loss_kg,
                                std_stoppage_loss_per_hour: txt_std_stoppage_loss_per_hour,
                                stoppage_production_loss_kg: txt_stoppage_production_loss_kg,
                            };
                            machineStoppageDetails.push(newStoppageDetail);
                        }

                        const ringFrameIdMatchedArray = machineStoppageDetails.filter(frameDetails => frameDetails.machine_id === cmb_machine_no);
                        const total_StoppageTime = ringFrameIdMatchedArray.reduce((total, ring_FrameId) =>
                            total + parseInt(ring_FrameId.stoppage_time), 0);

                        const matchedMachineNo = ringFrameIdMatchedArray[0]?.machine_id;
                        const parsedMachineNo = parseInt(matchedMachineNo);

                        // if (!isNaN(parsedMachineNo)) {
                        //     const findMachineId = ringFrameDetails.find(frameDetails => frameDetails.machine_id === parsedMachineNo);

                        //     if (findMachineId) {
                        //         findMachineId.total_stoppage_time = String(total_StoppageTime);
                        //     }
                        // }
                        clearFormFields('stoppage');
                    }
                }
                break;
            case 'update':
                setMachineNo(row_data.machine_id);
                setMachineStoppageReason(row_data.production_stoppage_reasons_id);
                setMachineStoppageTime(row_data.stoppage_time);
                setLossType(row_data.loss_type);
                setShift(row_data.shift);
                setStdProdLoss(row_data.std_stoppage_loss_kg);
                setActProdLoss(row_data.actual_production_loss_kg);
                setStoppageProductionLossKg(row_data.stoppage_production_loss_kg);
                setStdStoppageLossPerHour(row_data.std_stoppage_loss_per_hour);
                setPrevDataIndexForStoppage(index_Of_Row);
                break;

            case 'remove':
                const findIdtoBeRemove = machineStoppageDetails.findIndex(stoppageDetails => (
                    stoppageDetails.machine_id === row_data.machine_id && stoppageDetails.production_stoppage_reasons_id === row_data.production_stoppage_reasons_id && stoppageDetails.stoppage_time === row_data.stoppage_time &&
                    stoppageDetails.loss_type === row_data.loss_type &&
                    stoppageDetails.std_stoppage_loss_kg === row_data.std_stoppage_loss_kg &&
                    stoppageDetails.actual_production_loss_kg === row_data.actual_production_loss_kg &&
                    stoppageDetails.stoppage_production_loss_kg === row_data.stoppage_production_loss_kg &&
                    stoppageDetails.std_stoppage_loss_per_hour === row_data.std_stoppage_loss_per_hour
                ));

                if (findIdtoBeRemove !== -1) {
                    machineStoppageDetails.splice(findIdtoBeRemove, 1);
                }
                clearFormFields('stoppage');
                break;
        }
        setStoppageDetails(machineStoppageDetails);
        // setRingFrameProdShiftData(ringFrameDetails);
    }

    const validateFormFields = (formname) => {
        switch (formname) {
            case 'Stoppage Form':
                validate.current.validateFieldsOnChange('stoppageFormId');
                break;
            case 'Wastage Form':
                validate.current.validateFieldsOnChange('wastageFormId');
                break;
        }
    }

    // const calculateProdLoss = async (event) => {

    //     // let prod_shift_Details = [...ringFrameProdShiftData];
    //     let machine_id = cmb_machine_no;
    //     let tot_Stoppage_Time = event.target.value;
    //     setMachineStoppageTime(validateNumberDateInput.current.decimalNumber(tot_Stoppage_Time, 4));

    //     const req_Id = await prod_shift_Details.find((shiftDetails) => {
    //         return shiftDetails.machine_id === parseInt(machine_id)
    //     }
    //     );

    //     if (req_Id) {
    //         let stdProdCountLossPerMinute = req_Id.kgs_target / 720;
    //         let stdProdLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(stdProdCountLossPerMinute * tot_Stoppage_Time), 4);
    //         setStdProdLoss(stdProdLoss);
    //         setStdStoppageLossPerHour(stdProdLoss);
    //         setStoppageProductionLossKg(stdProdLoss);
    //         let actProdCountLossPerMinute = req_Id.kgs_actual / 720;
    //         let actProdLoss = validateNumberDateInput.current.decimalNumber(JSON.stringify(actProdCountLossPerMinute * tot_Stoppage_Time), 4);
    //         setActProdLoss(actProdLoss);
    //     }
    //     // setRingFrameProdShiftData(prod_shift_Details);
    // };

    const renderMachineStoppageTable = useMemo(() => {
        return <Table id="machinestoppagetableId" className="erp_table" responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                    <td className="erp_table_th" style={{ width: "auto" }} >Machine No</td>
                    <td className="erp_table_th" style={{ width: "auto" }} >Stoppage Reason</td>
                    <td className="erp_table_th" style={{ width: "auto" }} >Shift</td>
                    <td className="erp_table_th" style={{ width: "auto" }} >Loss Type</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Stoppage <br></br> Time</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Standard <br></br> Prod. Loss</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Actual <br></br> Prod. Loss</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Stoppage <br></br> Prod.Loss Kg</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Standard <br></br> Stopp. Loss/KG</td>

                </tr>
            </thead>
            <tbody>

                {
                    stoppageDetails.length > 0 ? (
                        stoppageDetails?.map((details, indexOfItem) => (
                            <tr>
                                <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateStoppageReason(details, indexOfItem, 'update')} />
                                    <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateStoppageReason(details, indexOfItem, 'remove')} />
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.machine_name}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.production_stoppage_reasons_name}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.shift}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.loss_type}
                                </td>

                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.stoppage_time}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.std_stoppage_loss_kg}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.actual_production_loss_kg}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.std_stoppage_loss_per_hour}
                                </td>
                                <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                    {details.stoppage_production_loss_kg}
                                </td>
                            </tr>
                        ))) : (
                        <tr>
                            <td colSpan="10">
                                <Card className="erp_no_recrd_card">
                                    <Card.Body className="text-center">No records found...</Card.Body>
                                </Card>
                            </td>
                        </tr>
                    )}
            </tbody>
        </Table>

    }, [stoppageDetails]);

    // --------------------------------- Machine production stoppage end------------------------------------------------------------------------------------------------


    //   ------------------------------------------------------------------------------------------------------------------------------
    //manage master status 
    // const fnManageSizingStatus = (sizingProductionData) => {
    //     const hasRejected = sizingProductionData.some(item => item.sizing_production_status === 'R');
    //     const hasPending = sizingProductionData.some(item => item.sizing_production_status === 'P');
    //     const hasApprove = sizingProductionData.every(item => item.sizing_production_status === 'A');

    //     if (hasRejected) {
    //         setProductionSizingStatus('R');
    //         setActionLabel('Rejected');
    //     }
    //     else if (hasPending) {
    //         setProductionSizingStatus('P');
    //         if (keyForViewUpdate === '') {
    //             setActionLabel('Rejected');
    //         } else {
    //             if (keyForViewUpdate === 'update') {
    //                 setActionLabel('Update');
    //             } else {
    //                 setActionLabel('Approved');
    //             }
    //         }
    //     } else if (hasApprove) {
    //         setProductionSizingStatus('A');
    //         setActionLabel('Approved');
    //     } else {
    //         setProductionSizingStatus('p');
    //     }
    // };
    const fnManageSizingStatus = (sizingProductionData, inspectionMaterialData) => {
        // debugger
        const hasRejected = sizingProductionData.some(item => item.sizing_production_status === 'R') || sizingProductionMaterialData.some(item => item.material_status === 'R');
        const hasPending = sizingProductionData.some(item => item.sizing_production_status === 'P') || sizingProductionMaterialData.some(item => item.material_status === 'P');
        const hasApprove = sizingProductionData.every(item => item.sizing_production_status === 'A') && sizingProductionMaterialData.every(item => item.material_status === 'A');
        if (hasRejected) {
            setProductionSizingStatus('R');
            setActionLabel('Rejected');
        } else if (hasPending) {
            setProductionSizingStatus('P');
            if (keyForViewUpdate === 'Add') {
                setActionLabel('Rejected');
            } else {
                if (keyForViewUpdate === 'update') {
                    setActionLabel('Update');
                } else {
                    setActionLabel('Approved');
                }
            }
        } else if (hasApprove) {
            setProductionSizingStatus('A');
            setActionLabel('Approved');
        } else {
            setProductionSizingStatus('p');
        }
    };

    // ----------------------------------shift wise wasteage------------------------------------------------------------------------------------------------
    const FnUpdateWastageReason = async (rowData, rowIndex, action) => {
        if (action === 'update') {
            setLabelForWastage('Update');
        }
        const machineWastageDetails = [...wastageDetails];
        switch (action) {
            case 'add':
                const validateWastageReason = await validate.current.validateForm('wastageFormId');
                if (validateWastageReason === true) {
                    const findIdtoAdd = machineWastageDetails.some((wastage_Details) => (
                        wastage_Details.production_wastage_types_id === cmb_prod_wastage_type
                        // && wastage_Details.wastage_quantity === txt_wastage_quantity
                        && wastage_Details.godown_id === cmb_godownId
                        && wastage_Details.shift === $('#cmb_shift_wastage option:selected').text()
                    ));
                    if (findIdtoAdd) {
                        $('#error_cmb_prod_wastage_type').text('Wastage Reason already exists...').css({ 'display': 'block', 'margin-top': '8px' });
                    } else {
                        $('#error_cmb_prod_wastage_type').hide();

                        ///To Update Row
                        const rowNeedToBeUpdated = machineWastageDetails.find((wastage_Details, rowIndex) => (
                            prevdataindexforWastage === rowIndex
                        ));

                        if (rowNeedToBeUpdated) {
                            rowNeedToBeUpdated.production_wastage_types_id = cmb_prod_wastage_type;
                            rowNeedToBeUpdated.production_wastage_types_type = $('#cmb_prod_wastage_type option:selected').text();
                            rowNeedToBeUpdated.wastage_quantity = txt_wastage_quantity;
                            rowNeedToBeUpdated.godown_id = cmb_godownId;
                            rowNeedToBeUpdated.godown_name = $('#cmb_godownId option:selected').text();
                            rowNeedToBeUpdated.shift = $('#cmb_shift_wastage option:selected').text();
                            setPrevDataIndexForWastage('');
                        }

                        if (!rowNeedToBeUpdated) {
                            const newWastageDetail = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                financial_year: FINANCIAL_SHORT_NAME,
                                spinning_production_rf_wastage_id: 0,
                                spinning_production_rf_master_id: 0,
                                spinning_production_rf_code: txt_prod_rf_code,
                                spinning_production_date: dt_sizing_production_date,
                                prod_month: txt_production_month,
                                prod_year: txt_production_year,
                                plant_id: cmb_plant_id,
                                spinn_plan_code: $('#cmb_spinn_plan_code option:selected').text(),
                                section_id: cmb_section_id,
                                sub_section_id: cmb_sub_section_id,
                                production_wastage_types_id: cmb_prod_wastage_type,
                                production_wastage_types_type: $('#cmb_prod_wastage_type option:selected').text(),
                                actual_wastage_production_kg: txt_act_wastages,
                                wastage_quantity: txt_wastage_quantity,
                                godown_id: cmb_godownId,
                                godown_name: $('#cmb_godownId option:selected').text(),
                                shift: $('#cmb_shift_wastage option:selected').text(),

                            };
                            machineWastageDetails.push(newWastageDetail);
                        }
                        clearFormFields('wastage');
                        setLabelForWastage('Add')
                    }
                }

                break;
            case 'update':
                setProdWastageType(rowData.production_wastage_types_id);
                setWastageQuantity(rowData.wastage_quantity);
                setgodownId(rowData.godown_id)
                setShiftWastage(rowData.shift)
                setPrevDataIndexForWastage(rowIndex);

                break;
            case 'remove':
                if (rowIndex !== -1) {
                    machineWastageDetails.splice(rowIndex, 1);
                }
                clearFormFields('wastage');
                break;
        }
        setWastageDetails(machineWastageDetails);
    }

    const calculateMachineWastage = async (value) => {
        const wastage_Type = $('#cmb_prod_wastage_type option:selected').val();
        setProdWastageType(wastage_Type);

        resetGlobalQuery();
        globalQuery.columns = ['std_wastage_percent'];
        globalQuery.table = "xmv_production_wastage_types"
        globalQuery.conditions = [
            { field: "is_delete", operator: "=", value: 0 },
            { field: "production_wastage_types_id", operator: "=", value: wastage_Type }
        ];
        validateFields('Wastage Form');

    };



    const renderMachineWastageTable = useMemo(() => {
        return <Table id="machinewastagetableId" style={{ display: "block", width: '600px', overflow: "auto" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>Action</td>
                    <td className="erp_table_th " style={{ width: "150px" }}>Wastage Type</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Wastage Quantity</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Godown</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Shift</td>

                </tr>
            </thead>
            <tbody>
                {wastageDetails.length > 0 ? (
                    wastageDetails?.map((details, indexOfItem) => (
                        <tr key={indexOfItem}>
                            <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <MdModeEdit className="erp-edit-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'update')} />
                                <MdDelete className="erp-delete-btn" onClick={(e) => FnUpdateWastageReason(details, indexOfItem, 'remove')} />
                            </td>
                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                {details.production_wastage_types_type}
                            </td>
                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                {details.wastage_quantity}
                            </td>
                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                {details.godown_name}
                            </td>
                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                {details.shift}
                            </td>
                        </tr>
                    ))) : (
                    <tr>
                        <td colSpan="10">
                            <Card className="erp_no_recrd_card">
                                <Card.Body className="text-center">No records found...</Card.Body>
                            </Card>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>

    }, [wastageDetails]);
    //   --------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <GenerateMaterialId ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <DashboardLayout>


                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Sizing Production {actionType} </label>
                    </div>

                    <form id='Sizingproduction'>
                        <div className='row p-1'>
                            {/* 1 st Column */}
                            <div className='col-sm-3 erp_form_col_div'>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Set No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_set_no" className="form-select form-select-sm" value={cmb_set_no} onChange={(e) => { comboOnChange('cmb_set_no'); validateFields(); }}>
                                            <option >Select</option>
                                            {setNoOptions.length !== 0 ? (
                                                <>
                                                    {setNoOptions?.map(production => (
                                                        <option value={production.set_no}>{production.set_no}</option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Production Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_sizing_production_date' className="erp_input_field" value={dt_sizing_production_date} onChange={(e) => { setMonthYear(); validateFields(); generateSizingProductionCode(); FnGetShiftDetails(); }} disabled={keyForViewUpdate !== ''} />
                                        <MDTypography variant="button" id="error_dt_sizing_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields(); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Prod. supervisor <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select ref={cmb_prod_supervisor_id_ref}
                                            options={machineOperatorOptions}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            inputId="cmb_prod_supervisor_id" // Provide the ID for the input box
                                            value={machineOperatorOptions.length > 1 ? machineOperatorOptions.find(option => option.value === cmb_prod_supervisor_id) : null}
                                            onChange={(selectedOpt) => {
                                                setProdSupervisor(selectedOpt.value);
                                                cmb_prod_supervisor_id_ref.current = selectedOpt;
                                            }}
                                            placeholder="Search Supervisor here..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_prod_supervisor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">No Of Ends<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" className="erp_input_field" id="no_of_ends" value={no_of_ends} disabled />
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Production Count<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" className="erp_input_field" id="production_count" value={production_count} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Beam No's <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_beam_nos" className="form-select form-select-sm" >
                                            {beamWarpingOptions.length !== 0 ? (
                                                <>
                                                    {beamWarpingOptions?.map(beamNo => (
                                                        <option >{beamNo.beam_name}</option>
                                                    ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label"> Warping Creels Ends <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id='warping_creel_ends' className="erp_input_field" value={warping_creel_ends} disabled />
                                    </div>
                                </div>



                            </div>

                            <div className='col-sm-3 erp_form_col_div'>

                                <div className='row'>
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Month & Year <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6 d-flex">
                                        <Form.Control type="text" id='txt_production_month' className="erp_input_field" value={txt_production_month} disabled />
                                        <Form.Control type="text" id='txt_production_year' className="erp_input_field ms-1" value={txt_production_year} disabled />

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Prod. Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id'); validateFields(); }} disabled>
                                            <option value="">Select</option>
                                            {prodsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsectionOptions?.map(production => (
                                                        <option value={production.field_id}>{production.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { validateFields(); comboOnChange('subSectionOnChange'); }} disabled>
                                            <option value="">Select</option>
                                            {prodsubsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsubsectionOptions?.map(subproduction => (
                                                        <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_type} value={subproduction.field_id}>
                                                            {subproduction.field_name}
                                                        </option>
                                                    ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>




                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label"> Sizing Prod. Code <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id='txt_sizing_production_code' className="erp_input_field" value={txt_sizing_production_code} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Product Material <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="text" id="txt_prod_material_name" className="erp_input_field" value={txt_prod_material_name} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Set Length <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control id="txt_set_length" type="text" className="erp_input_field" value={txt_set_length} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Warping Length <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control id="warping_length" type="text" className="erp_input_field" value={warping_length} disabled />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Label className="erp-form-label">Warping Creels <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control id="warping_no_of_creels" type="text" className="erp_input_field" value={warping_no_of_creels} disabled />
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-5">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="erp_form_radio">
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            {/* ..............2nd Column.......................... */}
                            <div className="col-sm-5 px-lg-2 d-block">
                                <div className="col-lg-12 col-12 erp_form_col_div">
                                    <div className="card">
                                        <div className="card-header py-0 main_heding mb-0">
                                            <label className="erp-form-label-md-lg">Sizing Production Summary</label>
                                        </div>
                                        {/* Card's Body Starts*/}
                                        <div className="card-body p-0">
                                            <div className={`table-responsive`}>
                                                {renderproductionsummary}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                    <hr />

                    {/* <MDBox pt={3} pb={8} className="erp_form_tab_div">
                        <Grid justifyContent="center" sx={{ my: 4 }}>
                            <Grid item xs={12} lg={8} className="erp_form_container_tabs">
                                <MDBox mt={-3} mx={2}>
                                    <Tabs
                                        activeKey={activeStep}
                                        id="uncontrolled-tab-example"
                                        className="mb-3 selectedTab erp-form-Tabs"
                                        onSelect={eventKey => moveByTabs(eventKey)}>
                                        {steps.map((label, index) => (
                                            <Tab eventKey={index} title={label}></Tab>
                                        ))}
                                    </Tabs>
                                    {getStepContent()}
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox> */}

                    <Accordion defaultActiveKey='1'>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="erp-form-label-md">Sizing Production Details</Accordion.Header>
                            <Accordion.Body>
                                <div className='row mt-1'><label className="erp-form-label-md-lg">Production Details</label></div>
                                {SizingrenderProductionDetails}
                                <hr />
                                <div className='row mt-2'><label className="erp-form-label-md-lg">Material Moment</label></div>
                                {SizingrenderMaterialMomentDetails}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card">
                            <div className="card-header py-0 main_heding mb-0">
                                <label className="erp-form-label-md-lg">Shiftwise Machine Stoppage Entry</label>
                            </div>
                            {/* Card's Body Starts*/}
                            <div className="card-body p-0">
                                <form id='stoppageFormId'>
                                    <div className='row p-1'>

                                        <div className='col-sm-3 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Machine No <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_machine_no" className="form-select form-select-sm" value={cmb_machine_no} onChange={(e) => { setMachineNo(e.target.value); comboOnChange('machineName'); validateFormFields('Stoppage Form'); }}>
                                                        <option value="">Select</option>
                                                        {machineNameOption.length !== 0 ? (
                                                            <>
                                                                {machineNameOption?.map(machine => (
                                                                    <option value={machine.field_id}>{machine.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_machine_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Stopp. Reason <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_stoppage_reason" className="form-select form-select-sm" value={cmb_stoppage_reason} onChange={(e) => { setMachineStoppageReason(e.target.value); validateFormFields('Stoppage Form'); }}>
                                                        <option value="">Select</option>
                                                        {stoppagereasonOptions.length !== 0 ? (
                                                            <>
                                                                {stoppagereasonOptions?.map(stoppage => (
                                                                    <option value={stoppage.field_id}>{stoppage.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null

                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_stoppage_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Shift <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_shift" className="form-select form-select-sm" value={cmb_shift} onChange={(e) => { setShift(e.target.value); }}>
                                                        <option value="">Select</option>
                                                        {shiftOptions.length !== 0 ? (
                                                            <>
                                                                {shiftOptions?.map(shift => (
                                                                    <option value={shift.field_name}>{shift.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null

                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Loss Type</Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_loss_type" className="form-select form-select-sm" value={cmb_loss_type} onChange={(e) => { setLossType(e.target.value); validateFields(); }}  >
                                                        <option value="">Select</option>
                                                        <option value="Utilization">Utilization</option>
                                                        <option value="Efficiency">Efficiency</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_loss_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Stoppage Time <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Control type="text" id='txt_stoppage_time' className="erp_input_field" value={txt_stoppage_time} onChange={(e) => { setMachineStoppageTime(e.target.value); validateFormFields('Stoppage Form'); }} />
                                                    <MDTypography variant="button" id="error_txt_stoppage_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-3 erp_form_col_div'>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Std. Prod. Loss </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Control type="text" id='txt_std_stoppage_loss_kg' className="erp_input_field" value={txt_std_stoppage_loss_kg} onChange={(e) => { setStdProdLoss(e.target.value); }} disabled optional='optional' />
                                                    <MDTypography variant="button" id="error_txt_std_stoppage_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Act. Prod. Loss </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Control type="text" id='txt_actual_production_loss_kg' className="erp_input_field" value={txt_actual_production_loss_kg} onChange={(e) => { setActProdLoss(e.target.value); }} disabled optional='optional' />
                                                    <MDTypography variant="button" id="error_txt_actual_production_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Prod.Loss Kg </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Control type="text" id='txt_stoppage_production_loss_kg' className="erp_input_field" value={txt_stoppage_production_loss_kg} onChange={(e) => { setStoppageProductionLossKg(e.target.value); }} disabled optional='optional' />
                                                    <MDTypography variant="button" id="error_stoppage_production_loss_kg" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Std.Stopp. Loss/KG</Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Control type="text" id='txt_std_stoppage_loss_per_hour' className="erp_input_field" value={txt_std_stoppage_loss_per_hour} onChange={(e) => { setStdStoppageLossPerHour(e.target.value); }} disabled optional='optional' />
                                                    <MDTypography variant="button" id="error_std_stoppage_loss_per_hour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col'>
                                                    <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                        <MDButton type="button" id="action_Button" className="erp-gb-button ms-2" onClick={() => FnUpdateStoppageReason('', '', 'add')} variant="button" fontWeight="regular">{labelForStoppage}</MDButton>
                                                        <MDButton type='button' className="erp-gb-button ms-2" variant="button" id='back_Button' onClick={(e) => clearFormFields('stoppage')} fontWeight="regular" >Clear</MDButton>
                                                    </div >
                                                </div >
                                            </div >
                                        </div>

                                        {/* Machine Stoppage Table */}

                                        <div className='col-sm-6 erp_form_col_div'>
                                            {renderMachineStoppageTable}
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>



                    {/* ///////////////////////////ShiftWise Wastage Entry/////////////////////////// */}
                    <hr />
                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card">
                            <div className="card-header py-0 main_heding mb-0">
                                <label className="erp-form-label-md-lg">Shiftwise Machine Wastage Entry</label>
                            </div>
                            {/* Card's Body Starts*/}
                            <div className="card-body p-0">

                                <form id='wastageFormId'>
                                    <div className='row p-1'>
                                        <div className='col-sm-4 erp_form_col_div'>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Prod. Wastage Type<span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_prod_wastage_type" className="form-select form-select-sm" value={cmb_prod_wastage_type} onChange={(e) => { calculateMachineWastage(''); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                                                        <option value="">Select</option>
                                                        {prodWastageOptions.length !== 0 ? (
                                                            <>
                                                                {prodWastageOptions?.map(machine => (
                                                                    <option value={machine.field_id}>{machine.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_prod_wastage_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Wastage Quantity<span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Form.Control type="text" id='txt_wastage_quantity' className="erp_input_field" value={txt_wastage_quantity} onChange={(e) => setWastageQuantity(validateNumberDateInput.current.decimalNumber(e.target.value, 4))} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} />
                                                    <MDTypography variant="button" id="error_txt_wastage_quantity" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Godown<span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_godownId" className="form-select form-select-sm" value={cmb_godownId} onChange={(e) => setgodownId(e.target.value)} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                                                        <option value="">Select</option>
                                                        {godownNameOptions.length !== 0 ? (
                                                            <>
                                                                {godownNameOptions?.map(godownType => (
                                                                    <option value={godownType.field_id}>{godownType.godown_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null

                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_godownId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Shift <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_shift_wastage" className="form-select form-select-sm" value={cmb_shift_wastage} onChange={(e) => { setShiftWastage(e.target.value); validateFormFields('wastageFormId'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}>
                                                        <option value="">Select</option>
                                                        {shiftOptions.length !== 0 ? (
                                                            <>
                                                                {shiftOptions?.map(shift => (
                                                                    <option value={shift.field_name}>{shift.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                <MDButton type="button" id="action_Button" className="erp-gb-button ms-2 disable-button" variant="button" onClick={() => FnUpdateWastageReason('', '', 'add')} fontWeight="regular">{labelForWastage}</MDButton>
                                                <MDButton type='button' className="erp-gb-button ms-2 disable-button" variant="button" id='back_Button' onClick={(e) => clearFormFields('wastage')} fontWeight="regular" >Clear</MDButton>
                                            </div >
                                        </div>

                                        {/* Machine wise Wastage Details table */}
                                        <div className='col-sm-7 erp_form_col_div'>
                                            <div className='row'>
                                                {renderMachineWastageTable}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="erp_frm_Btns mb-5">
                        <MDButton type="button" className="erp-gb-button erp_MLeft_btn ms-4" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                        <MDButton type="submit" onClick={addSizingProduction} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout>
        </>
    )
}

export default FrmTSizingProductionEntry
