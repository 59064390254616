import React from 'react'
import { useState } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// imports react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// File imports
import Customer from "Masters/MCustomer/FrmCustomerEntry/components/FrmMCustomerEntry";
import FrmCustomerAccessRightsEntry from './components/FrmCustomerAccessRightsEntry';

function getSteps() {
    return ["Customer Information", "Access Rights"];
}

export default function FrmCustomer() {
    const { state } = useLocation();
    let { customerID = 0, keyForViewUpdate = 'Add', customer_code, customer_name, compType = 'Masters' } = state || {}

    if (localStorage.getItem('dataAddedByCombo') === 'dataAddedByCombo') {
        const customerIDs = JSON.parse(localStorage.getItem('customerIDs'));
        customerID = customerIDs.customerID;
        keyForViewUpdate = customerIDs.keyForViewUpdate;
    }

    const [customerId, setCustomerId] = useState(customerID)
    const [customerCode, setCustomerCode] = useState(customer_code)
    const [customerName, setCustomerName] = useState(customer_name)

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = (customer_id, txt_customer_code, txt_customer_name) => {
        if (customer_id !== 0) {
            setCustomerId(customer_id)
            setCustomerCode(txt_customer_code)
            setCustomerName(txt_customer_name)
            setActiveStep((parseInt(activeStep) + 1))
        }
    };

    const handleBack = (customer_id) => {
        setCustomerId(customer_id)
        setActiveStep((parseInt(activeStep) - 1))
    };

    const moveByTabs = (eventKey) => {
        if (customerId !== 0) {
            setActiveStep(eventKey)
        }
    }

    function getStepContent() {
        switch (parseInt(activeStep)) {
            case 0:
                return <Customer goToNext={handleNext} customerId={customerId} />;
            case 1:
                return <FrmCustomerAccessRightsEntry goBack={handleBack} customerId={customerId} customerCode={customerCode} customerName={customerName} compType={compType} keyForViewUpdate={keyForViewUpdate} />;
            default:
                return null;
        }
    }

    return (
        <>
            <DashboardLayout>
                <div className='main_heding'>
                    <label className='erp-form-label-lg main_heding'> Customer Information {keyForViewUpdate === 'view' ? ' (View)' : keyForViewUpdate === 'update' ? ' (Modify)' : ' (Create)'}</label>
                </div>
                <MDBox pt={3} pb={8} className="erp_form_tab_div">
                    <Grid justifyContent="center" sx={{ my: 4 }} >
                        <Grid item xs={12} lg={8} className="erp_form_container_tabs">
                            <MDBox mt={-3} mx={2}>
                                <Tabs
                                    activeKey={activeStep}
                                    id="uncontrolled-tab-example"
                                    className="mb-3 selectedTab erp-form-Tabs" onSelect={eventKey => moveByTabs(eventKey)}>
                                    {steps.map((label, index) => (
                                        <Tab eventKey={index} title={label} ></Tab>
                                    ))}
                                </Tabs>
                                {getStepContent()}
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout>
        </>
    )
}