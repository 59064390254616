import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import react icons
import { useLocation, useNavigate } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import SuccessModal from "components/Modals/SuccessModal";

import ConfigConstants from 'assets/Constants/config-constant';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { validate } from 'uuid';

export default function FrmMProductMaterialTypeEntry(props) {
  //changes by ujjwala on 4/1/2024 case no .1
  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;
  const { state } = useLocation();
  const { productmaterialtypeID = 0, keyForViewUpdate, compType } = state || {}

  const comboDataAPiCall = useRef();
  const validate = useRef();
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  // Add Product Type Form Fields
  const [product_material_type_id, setproductmaterialtypeID] = useState(productmaterialtypeID);
  const [cmb_product_type_id, setProductTypeId] = useState();
  const [txt_product_material_type_name, setProductMaterialTypeName] = useState('');
  const [txt_product_material_type_short_name, setProductMaterialTypeShortName] = useState('');
  const [txt_product_material_type_density, setProductMaterialTypeDensityId] = useState('');
  const [txt_product_material_type_rate, setProductMaterialTypeRateId] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    await fillComobos()
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')

    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigate(`/Masters/ProductMaterialTypeListing`)
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // For navigate
  const navigate = useNavigate();

  useEffect(() => {
    const loadDataOnload = async () => {
      await ActionType()
      await fillComobos();
      if (productmaterialtypeID !== 0) {
        await FnCheckUpdateResponce()
      }
    }
    loadDataOnload()
  }, [])

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $('#txt_product_material_type_name').attr('disabled', true);
        $('#cmb_product_type_id').attr('disabled', true)
        $('#txt_product_material_type_short_name').attr('disabled', true)

        // $('#departmentGroup').attr('disabled', true)
        // $('#departmentName').attr('disabled', true)
        // $('#shortName').attr('disabled', true)
        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productmaterialtypeFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Creation)');
        break;
    }

  };

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        }
        setProductTypeId(productTpVal)
        break;
    }
  }

  const fillComobos = async () => {
    const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    setProductTypeOptions(productTypeApiCall)

  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("productmaterialtypeFormId");
      if (checkIsValidate === true) {
        var active;

        const activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;

        }

        const data = {
          company_id: COMPANY_ID,
          product_material_type_id: product_material_type_id,
          created_by: UserName,
          modified_by: product_material_type_id === 0 ? null : UserName,
          product_type_id: cmb_product_type_id,
          product_material_type_name: txt_product_material_type_name,
          product_material_type_short_name: txt_product_material_type_short_name,
          product_material_type_density: txt_product_material_type_density,
          product_material_type_rate: txt_product_material_type_rate,
          remark: txt_remark,
          is_active: active,

        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productMaterialtype/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          var data1 = JSON.parse(responce.data)
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };
  const clearFormFields = () => {
    setProductTypeId('');
    setProductMaterialTypeName('');
    setProductMaterialTypeShortName('');
    setProductMaterialTypeDensityId('');
    setProductMaterialTypeRateId('');
    setRemark('');

  }

  const FnCheckUpdateResponce = async () => {
    try {
      debugger
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productMaterialtype/FnShowParticularRecordForUpdate/${product_material_type_id}`)
      const updateRes = await apiCall.json();

      let resp = JSON.parse(updateRes.data)
      await fillComobos();
      setproductmaterialtypeID(resp.product_material_type_id)
      setProductTypeId(resp.product_type_id);
      setProductMaterialTypeName(resp.product_material_type_name);
      setProductMaterialTypeShortName(resp.product_material_type_short_name);
      setProductMaterialTypeDensityId(resp.product_material_type_density);
      setProductMaterialTypeRateId(resp.product_material_type_rate);
      setRemark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }

    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const validateNo = (key) => {

    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{7}$/
    const regexNo1 = /^[0-9]*\.[0-9]{3}$/
    var dot = '.';
    switch (key) {
      case 'pMaterialTypeDensity':
        var pMaterialTypeDenVal = $('#txt_product_material_type_density').val();
        if (!regexNo.test(pMaterialTypeDenVal) && pMaterialTypeDenVal.length <= 8 || pMaterialTypeDenVal.indexOf(dot) === 8) {
          if (numCheck.test(pMaterialTypeDenVal)) {
            setProductMaterialTypeDensityId(pMaterialTypeDenVal)
          }

        }
        break;

      case 'pMaterialTypeRate':
        var pMaterialTypeRateVal = $('#txt_product_material_type_rate').val();
        if (!regexNo1.test(pMaterialTypeRateVal) && pMaterialTypeRateVal.length <= 8 || pMaterialTypeRateVal.indexOf(dot) === 8) {
          if (numCheck.test(pMaterialTypeRateVal)) {
            setProductMaterialTypeRateId(pMaterialTypeRateVal)
          }

        }
        break;
    }
  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('productmaterialtypeFormId')

  }

  return (
    <>
      <FrmValidations ref={validate} />
      <ComboBox ref={comboDataAPiCall} />
      <div className="erp_top_Form">
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg text-center'>Product Material Type {actionType} </label>
          </div>

          <form id="productmaterialtypeFormId">
            <div className="row erp_transporter_div">
              {/* first row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record +</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>

                      ))}


                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Material Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_material_type_name" className="erp_input_field" value={txt_product_material_type_name} onChange={e => { setProductMaterialTypeName(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_material_type_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_material_type_short_name" className="erp_input_field" value={txt_product_material_type_short_name} onChange={e => { setProductMaterialTypeShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" />
                    <MDTypography variant="button" id="error_txt_product_material_type_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Material Density</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_material_type_density" className="erp_input_field text-end" value={txt_product_material_type_density} onChange={e => { validateNo('pMaterialTypeDensity'); }} maxLength="15" optional="optional" />
                    <MDTypography variant="button" id="error_txt_product_material_type_density" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

              </div>

              {/* second row */}
              <div className="col-sm-6">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Material Rate</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_material_type_rate" className="erp_input_field text-end" value={txt_product_material_type_rate} onChange={e => { validateNo('pMaterialTypeRate'); }} maxLength="11" optional="optional" />
                    <MDTypography variant="button" id="error_txt_product_material_type_rate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_remark" className="erp_input_field" value={txt_remark} onChange={e => { setRemark(e.target.value); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductMaterialTypeListing/reg' : '/Masters/ProductMaterialTypeListing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
            <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
              fontWeight="regular">{actionLabel}</MDButton>

          </div >
        </div>

        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            <FrmMProductTypeEntry btn_disabled />

          </Modal.Body>
        </Modal >
        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


      </div>
    </>
  )
}
