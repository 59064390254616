
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import $ from 'jquery';
import ReactPaginate from "react-paginate";
import { COMPANY_ID, COMPANY_BRANCH_ID, globalQuery, resetGlobalQuery, FINANCIAL_SHORT_NAME } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { MdDelete, MdRefresh } from 'react-icons/md';
import Datatable from 'components/DataTable';
import { AiOutlineSchedule } from "react-icons/ai";
import DocumentF from "Features/Document";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import { CircularProgress } from "@material-ui/core";
import ConfirmationModal from "components/Modals/ConfirmationModal";


// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Card, Modal, Table } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import { Link } from "react-router-dom";

//File imports
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import PurchaseRequisition from "FrmGeneric/Invoice/PurchaseRequisition";
import { item } from "examples/Sidenav/styles/sidenavItem";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";

function FrmPurchaseRequisitionEntry() {

    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, COMPANY_NAME } = configConstants;

    // var productchildrmid = sessionStorage.getItem('FgTypePartCode');
    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType, requestfor } = state || {};


    //Fn for new current date 
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];
    //Form Fields
    const [indent_master_id, setIndentMasterId] = useState(0)
    const [txt_indent_no, setIndentno] = useState(0)
    const [txt_indent_version, setIndentVersion] = useState(1)
    const [txt_indent_date, setIndentDate] = useState(todayDate);
    const [customer_order_date, setCustomerOrderDate] = useState('');
    const [cmb_indent_type, setIndentType] = useState('')
    const [chk_indent_source, setIndentSource] = useState('S')
    const [cmb_customer, setCustomerId] = useState('')
    const [cmb_customer_order_no, setCustomerOrderNo] = useState()
    const [cmb_department, setDepartmentId] = useState('')
    const [cmb_sub_department, setSubDepartmentId] = useState('')
    const [cmb_cost_center, setCostCenter] = useState("");
    const [cmb_indented_by, setIndentedById] = useState('')
    const [cmb_fgtype_part_code, setFgTypePartCode] = useState('')
    const [chk_indent_status, setIndentStatus] = useState("P")
    const [txt_remark, setRemark] = useState('')
    const [chk_isactive, setIsActive] = useState(true)
    const [order_quantity, setOrderQuantity] = useState('0')
    const [selectedText, setSelectedText] = useState('')
    const [isApprove, setIsApprove] = useState(false)
    const [IsView, setIsView] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Poterms Table Data
    const [POTermsdata, setPOTermsData] = useState([])
    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const comboBox = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();
    const navigate = useNavigate();


    //combo options
    const [customerOptions, setCustomerOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [subDepartmentOPtions, setSubDepartmentOptions] = useState([])
    const [cost_centerOptions, setCostCenterOptions] = useState([]);
    const [indentedByIdOptions, setIndentedByIdOptions] = useState([])
    const [indenttypeOptions, setIndentTypeOptions] = useState([])
    const [fgtypepartcodeOptions, setFgTypePartCodeOptions] = useState([])
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [txt_schedule_date, setScheduleDate] = useState(todayDate);
    const [cmb_approved_by_id, setApproved_by_id] = useState("");
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [txt_approved_date, setApproved_date] = useState("");

    const [existingCommonTermsData, setExistingCommonTermsData] = useState([]);
    let commonTermMstName = "Purchase Orders Technical Terms"


    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState();
    // Pagination Variables 
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
    ]
    var [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[0].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();;
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    //   const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Export&Exit':
                await ExportToExcel();
                setShowConfiemationModal(false);
                break;
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }

    //Add Schedules popup open 

    const [scheduleDataArray, setScheduleDataArray] = useState([]);
    const [showSChedules, setSchedules] = useState(false);
    const handleCloseSchedulesPopup = () => setSchedules(false)
    const [schedulerowcount, setSchedulerowcount] = useState(1);
    var scheduleRowCount = 1;
    var remainingSchduleQty = 0;

    const [showSChedulesRm, setSchedulesRm] = useState(false);
    const handleCloseSchedulesPopupRm = () => setSchedulesRm(false)

    // Document Form
    let docGroup = "Requisition (BOM Based)"
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        await FnShowDocumentRecords();
        setShowDocumentForm(false)
    }
    const viewDocumentForm = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }
    // doc list
    const [docData, setDocumentData] = useState([]);
    var columnHeads;
    // Table Data
    const [data, setFgBomData] = useState([]);
    const [columns, setColumns] = useState([]);
    let requiredCols = ["product_parent_fg_name", "product_child_rm_name", "product_child_rm_tech_spect", "product_child_rm_weight",
        "product_child_rm_quantity",
        "product_material_stock_quantity",
        "product_material_stock_weight",
        "product_material_approved_quantity",
        "product_material_approved_weight",
        "indent_item_status",
        "product_material_rejected_quantity",
        "product_material_rejected_weight",
        "product_material_reserve_quantity",
        "product_material_reserve_weight",
        "product_material_return_quantity",
        "product_material_return_weight",
        "approval_remark",
        "lead_time",
        "product_rm_std_weight",
        "product_child_rm_stock_unit_name", "product_child_rm_drawing_no", "product_child_rm_oem_part_code", "product_child_rm_our_part_code", 'product_child_rm_unit_id', 'product_parent_fg_id', 'product_child_rm_id', 'product_child_bom_quantity', 'product_child_bom_weight', 'product_fg_material_quantity', 'product_fg_material_weight'];


    const [addfgProductData, setAddFgProductData] = useState([]);

    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    localStorage.setItem('requiredCols', JSON.stringify(requiredCols));
    // const viewBomFilterForm = () => {
    //     if (cmb_indent_type !== "" && cmb_indent_type !== "0") {
    //         $('#error_cmb_indent_type').hide();
    //         sessionStorage.setItem('requiredCols', JSON.stringify(requiredCols));
    //         setShowBomFilterForm(true);
    //     } else {
    //         $('#error_cmb_indent_type').text('Please fill this field...!')
    //         $('#error_cmb_indent_type').show();
    //     }
    // }

    let fin_year = "";
    let ind_version = "";

    const closeRecordAddIndentModal = async () => {
        switch (modalHeaderName) {
            case 'Indent By':
                comboOnChange('subDepartment')
                break;
            case 'Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
                const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                setDepartmentIdOptions(departmentIdApiCall)
                break;
            case 'Sub Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: cmb_department });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setSubDepartmentOptions(subDepartmentApiCall)
                break;

            case 'Common Parameter':
                await FnShowPOTermsRecords();
                break;
            default:
                break;
        }
        setShowAddRecIndentModal(false);
        sessionStorage.removeItem('dataAddedByCombo')

    }
    const [showAddRecIndentModal, setShowAddRecIndentModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldUserAccess();
            await fillComobos();
            removeSessions('onload');
            if (idList !== '') {
                await FnCheckUpdateResponce();
            }
            FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
            navigate('/Error')
        }
        finally {
            // End loading
            setIsLoading(false);
        }
    }, [])

    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate != '') {
            setIsApprove(keyForViewUpdate === 'approve' ? true : false);
            var saveBtn = document.getElementById('save-btn');                    // const getData = JSON.parse(sessionStorage.getItem('bomfilteredData'))
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(View)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $("table").find('input[id^="product_child_rm_quantity_"]').attr("disabled", "disabled");
                    break;
                    break;
                case 'approve':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    saveBtn.textContent = 'Approve';
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Approval)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $("form").find('textarea#txt_remark').removeAttr("disabled");
                    $('table .approveField').removeAttr("disabled");
                    $('#chk_indent_status').attr('disabled', false);
                    $('#clearbutton-id').hide();
                    $("table").find('input[id^="product_child_rm_quantity_"]').attr("disabled", "disabled");
                    break;
                    break;
                case 'update':
                    saveBtn.textContent = 'Update';
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Modification)');
                    $('#cmb_indent_type').attr('disabled', true);
                    $('#txt_indent_date').attr('disabled', true);
                    $('input[name="chk_indent_source"]').prop('disabled', true);
                    setIsApprove(false)
                    break;
                case 'delete':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Delete)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $("table").find('input[id^="product_child_rm_quantity_"]').attr("disabled", "disabled");
                    break;
                    break;
            }
        }
    }


    const FnCheckUpdateResponce = async () => {

        try {
            fin_year = idList.financial_year;
            ind_version = idList.indent_version;
            setIndentno(idList.indent_no)
            setIndentMasterId(idList.indent_master_id)
            setIndentVersion(ind_version)
            if (idList !== '') {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnShowAllDetailsAndMastermodelRecords/${ind_version}/${fin_year}/${COMPANY_ID}?indent_no=${idList.indent_no}`)
                const responce = await apiCall.json();
                console.log(responce);

                let indentMasterData = responce.IndentMasterRecord;
                if (indentMasterData !== null && indentMasterData !== "") {
                    setIndentMasterId(idList.indent_master_id)
                    setIndentno(indentMasterData.indent_no)
                    setIndentDate(indentMasterData.indent_date)
                    setIndentVersion(indentMasterData.indent_version)
                    setIndentType(indentMasterData.indent_type_id)
                    setIndentSource(indentMasterData.indent_source)
                    setCustomerId(indentMasterData.customer_id)
                    setOrderQuantity("0")
                    await comboOnChange('customer')
                    setCustomerOrderNo(indentMasterData.customer_order_no)
                    setCustomerOrderDate(indentMasterData.customer_order_Date)
                    setScheduleDate(indentMasterData.expected_schedule_date)
                    await comboOnChange('CustomerOrder')
                    setIndentDate(indentMasterData.indent_date)
                    setDepartmentId(indentMasterData.department_id)
                    await comboOnChange('departmentId');
                    setSubDepartmentId(indentMasterData.sub_department_id)
                    await comboOnChange('subDepartment');
                    setCostCenter(indentMasterData.cost_center_id);
                    setIndentedById(indentMasterData.indented_by_id)
                    setIndentStatus(indentMasterData.indent_status)
                    setFgTypePartCode(indentMasterData.product_fg_id)
                    setApproved_by_id(indentMasterData.approved_by_id)
                    setApproved_date(indentMasterData.approved_date)
                    // setOrderQuantity(indentMasterData.product_material_quantity);
                    setRemark(indentMasterData.remark)
                    setIsActive(indentMasterData.is_active)

                    if (keyForViewUpdate === 'approve') {
                        setIndentStatus('A');
                        setApproved_by_id(UserId)
                        setApproved_date(today)
                    }
                    if (indentMasterData.indent_source === 'O') {
                        $('#cmb_customer').attr('disabled', 'disabled');
                        $('#cmb_customer_order_no').attr('disabled', 'disabled');
                        $('#customer_order_date').attr('disabled', 'disabled');
                        $('#material_quantity').removeAttr('disabled');
                        await FnGetFGListForDirect();
                    } else {
                        $('#cmb_customer').removeAttr('disabled');
                        $('#cmb_customer_order_no').removeAttr('disabled');
                        $('#material_quantity').attr('disabled', 'disabled');
                    }
                    // Function to update keys dynamically
                    const updateKeys = (object) => {
                        object['id'] = `${object.product_fg_id}:${object.product_material_id}`
                        const updatedObject = {};
                        for (const key in object) {
                            if (key === 'product_material_quantity') {
                                updatedObject[`product_child_rm_quantity`] = object[key];
                            }
                            else if (key === 'product_material_weight') {
                                updatedObject[`indent_weight`] = object[key];
                            }
                            else if (key === 'product_material_unit_id') {
                                updatedObject[`product_child_rm_unit_id`] = object[key];
                            } else if (key === 'product_material_id') {
                                updatedObject[`product_child_rm_id`] = object[key];
                            } else if (key === 'product_fg_id') {
                                updatedObject[`product_parent_fg_id`] = object[key];
                            } else if (key === 'product_rm_name') {
                                updatedObject[`product_child_rm_name`] = object[key];
                            } else if (key === 'product_rm_tech_spect') {
                                updatedObject[`product_child_rm_tech_spect`] = object[key];
                            } else if (key === 'product_rm_stock_unit_name') {
                                updatedObject[`product_child_rm_stock_unit_name`] = object[key];
                            } else if (key === 'product_rm_drawing_no') {
                                updatedObject[`product_child_rm_drawing_no`] = object[key];
                            } else if (key === 'product_rm_oem_part_code') {
                                updatedObject[`product_child_rm_oem_part_code`] = object[key];
                            } else if (key === 'product_rm_our_part_code') {
                                updatedObject[`product_child_rm_our_part_code`] = object[key];
                            }

                            else {
                                updatedObject[`${key}`] = object[key];
                            }
                        }
                        return updatedObject;
                    };
                    if (responce.IndentDetailsRecords != null) {
                        const updatedIndentDetailsDataArray = responce.IndentDetailsRecords.map(updateKeys);
                        setFgTypePartCode(updatedIndentDetailsDataArray[0].product_fg_id)
                        setOrderQuantity(updatedIndentDetailsDataArray[0].product_fg_material_quantity)
                        let updateWithIndentItemStatus = [];
                        if (keyForViewUpdate === 'approve') {
                            updateWithIndentItemStatus = updatedIndentDetailsDataArray.map(function (rowData) {
                                return {
                                    ...rowData,
                                    indent_item_status: 'A',
                                    product_material_approved_quantity: rowData.product_child_rm_quantity,
                                    product_material_approved_weight: rowData.indent_weight,


                                };
                            });
                        }
                        // await materialColumns(isApprove);
                        setAddFgProductData(updateWithIndentItemStatus.length === 0 ? updatedIndentDetailsDataArray : updateWithIndentItemStatus)
                        sessionStorage.setItem('addfgProductData', JSON.stringify(updateWithIndentItemStatus.length === 0 ? updatedIndentDetailsDataArray : updateWithIndentItemStatus))
                        $('.showData').show();
                    }
                    if (responce.IndentSchedules !== null && responce.IndentSchedules.length > 0) {

                        const existingpoOrdSchedules = responce.IndentSchedules;
                        const updatedSchedulesData = [];
                        const materialWiseSchedules = {};
                        existingpoOrdSchedules.forEach((item) => {
                            const key = `${item.product_fg_id}:${item.product_material_id}`;
                            if (!materialWiseSchedules[key]) {
                                materialWiseSchedules[key] = [];
                            }
                            materialWiseSchedules[key].push(item);
                        });
                        // Looping for set the id for schedule
                        for (const key of Object.keys(materialWiseSchedules)) {
                            const [fgId, rm_id] = key.split(':');
                            const materialGroup = materialWiseSchedules[key];
                            materialGroup.forEach((schedule, scheduleCounter) => {
                                schedule.id = `${fgId}:${rm_id}:${scheduleCounter}`;
                                schedule.modified_by = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '';
                                if (keyForViewUpdate === 'approve') {
                                    schedule.indent_item_status = 'A';
                                    schedule.indent_item_status_desc = 'Aprroved';
                                    schedule.indent_schedule_id = 0;
                                }
                                updatedSchedulesData.push(schedule);
                            });
                        }
                        setScheduleDataArray(updatedSchedulesData)
                        localStorage.setItem('indentScheduleData', JSON.stringify(updatedSchedulesData));
                    }
                }
                setExistingCommonTermsData(responce.IndentPOTerms)
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    const FnShowPOTermsRecords = async () => {
        try {
            const MSTName = encodeURIComponent("Purchase Orders Technical Terms ");
            const poTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const responce = await poTermsApiCall.json();
            if (responce.content.length !== 0) {

                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm,
                    common_parameters_expected_value: commonTerm.common_parameters_value,
                    remark: '',
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonData = existingCommonTermsData;
                let renderedCommonTermsData = responce.content
                for (let existCommonCounter = 0; existCommonCounter < existingCommonData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonData[existCommonCounter].common_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.common_parameters_id === existingCommonTermId);
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonData[existCommonCounter].common_parameters_expected_value;
                    }
                }
                setPOTermsData(renderedCommonTermsData)
                localStorage.setItem('purchaseOrdCommonTermsData', JSON.stringify(renderedCommonTermsData));
                setTimeout(() => {
                    existingCommonTermsData.forEach(function (existingCommonTerm) {  // mark the checked checkboxes for order common term.
                        $('#selectPOTerm_' + existingCommonTerm.common_parameters_id).prop('checked', true);
                    });
                }, 200);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the POTerm Selection
        $('.selectPOTerm').on('change', function () {
            checkBoxesSelection('PartiallyCommonTermSelection');
        });
    }
    //table for common terms
    const renderPOTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-commonTerms-table' responsive bordered>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Technical Parameters Master </th>
                        <th className="erp_table_th">Technical Parameters </th>
                        <th className="erp_table_th">Technical Parameters Value</th>
                        <th className="erp_table_th">Technical Prameters Expected Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        POTermsdata.map((poTerms, Index) =>
                            <tr rowIndex={Index}>

                                <td className="erp_table_td">
                                    <input type="checkbox" className="selectPOTerm" name="selectPOTerm" value={poTerms.common_parameters_id} id={'selectPOTerm_' + poTerms.common_parameters_id} />
                                </td>
                                <td className="erp_table_td">{poTerms.common_parameters_master_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_value}</td>
                                <td className="erp_table_td">
                                    <input type="text" id={`common_parameters_expected_value_${poTerms.common_parameters_id}`}
                                        className="erp_input_field mb-0"
                                        value={poTerms.common_parameters_expected_value}
                                        onChange={(e) => { updatePOOrdCommonTermsTblData(poTerms, e); }}
                                        disabled={IsView}
                                        Headers='common_parameters_expected_value'
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [POTermsdata]);
    // Onchange event listener for the POTerm Selection
    $('.selectPOTerm').on('change', function () {
        checkBoxesSelection('PartiallyPOTermsSelection');
    });
    const updatePOOrdCommonTermsTblData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        const exptvalueId = document.getElementById(event.target.id);
        if (event.target.value === '') {
            exptvalueId.parentElement.dataset.tip = 'Please fill this field...!';
        } else {
            delete exptvalueId.parentElement.dataset.tip;
        }
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const poTermsData = [...POTermsdata]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        poTermsData[arrayIndex] = currentRowData
        setPOTermsData(poTermsData);
    }
    function checkBoxesSelection(key) {
        switch (key) {
            // For Common terms 
            case "selectAllPOTerms":
                $('.selectPOTerm').prop('checked', $('#selectAllPOTerms').is(":checked"));
                break;
            case 'PartiallyPOTermsSelection':
                $('#selectAllPOTerms').prop('checked', $('input:checkbox.selectPOTerm:checked').length == $('input:checkbox.selectPOTerm').length);
                break;

            default:
                break;
        }
    }

    const addRequisition = async (indentStatus) => {
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateIndentOrder();
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransScheduleData': [], 'TransPOTermsData': [], 'commonIds': {} }
                const lastIndentNo = await fillAutoNo();

                for (let bomDetailIndex = 0; bomDetailIndex < addfgProductData.length; bomDetailIndex++) {
                    var crJson = {}
                    const bomfilteredDataElement = addfgProductData[bomDetailIndex];
                    if (isApprove) {
                        crJson['indent_details_id'] = bomfilteredDataElement.indent_details_id !== undefined ? bomfilteredDataElement.indent_details_id : 0;
                        crJson['indent_master_id'] = indent_master_id;
                    }
                    crJson['company_id'] = COMPANY_ID
                    crJson['company_branch_id'] = COMPANY_BRANCH_ID
                    crJson['indent_no'] = keyForViewUpdate === '' ? lastIndentNo : txt_indent_no
                    crJson['indent_version'] = bomfilteredDataElement.indent_version
                    crJson['indent_date'] = txt_indent_date
                    crJson['financial_year'] = FINANCIAL_SHORT_NAME
                    crJson['so_sr_no'] = 0
                    crJson['customer_order_no'] = cmb_customer_order_no
                    crJson['product_material_id'] = bomfilteredDataElement.product_child_rm_id
                    crJson['product_fg_material_quantity'] = bomfilteredDataElement.product_fg_material_quantity
                    crJson['product_fg_material_weight'] = bomfilteredDataElement.product_fg_material_weight
                    crJson['product_material_quantity'] = bomfilteredDataElement.product_child_rm_quantity
                    crJson['product_material_unit_id'] = bomfilteredDataElement.product_child_rm_unit_id
                    crJson['product_material_weight'] = bomfilteredDataElement.indent_weight
                    crJson['product_material_stock_quantity'] = bomfilteredDataElement.product_material_stock_quantity
                    crJson['product_material_stock_weight'] = bomfilteredDataElement.product_material_stock_weight
                    crJson['product_material_reserve_quantity'] = bomfilteredDataElement.product_material_reserve_quantity
                    crJson['product_material_reserve_weight'] = bomfilteredDataElement.product_material_reserve_weight
                    crJson['product_material_approved_quantity'] = bomfilteredDataElement.product_material_approved_quantity
                    crJson['product_material_approved_weight'] = bomfilteredDataElement.product_material_approved_weight
                    crJson['product_material_rejected_quantity'] = bomfilteredDataElement.product_material_rejected_quantity
                    crJson['product_material_rejected_weight'] = bomfilteredDataElement.product_material_rejected_weight
                    crJson['product_material_receipt_quantity'] = bomfilteredDataElement.product_material_receipt_quantity
                    crJson['product_material_receipt_weight'] = bomfilteredDataElement.product_material_receipt_weight
                    crJson['product_material_return_quantity'] = bomfilteredDataElement.product_material_return_weight
                    crJson['product_material_return_weight'] = bomfilteredDataElement.product_material_return_weight
                    crJson['product_child_bom_quantity'] = bomfilteredDataElement.product_child_bom_quantity
                    crJson['product_child_bom_weight'] = bomfilteredDataElement.product_child_bom_weight
                    crJson['approval_remark'] = bomfilteredDataElement.approval_remark
                    crJson['product_fg_id'] = bomfilteredDataElement.product_parent_fg_id
                    crJson['indent_item_status'] = indentStatus === 'R' || indentStatus === 'A' ? indentStatus : bomfilteredDataElement.indent_item_status
                    crJson['created_by'] = UserName
                    crJson['modified_by'] = keyForViewUpdate === 'update' ? UserName : '';
                    json.TransDetailData.push(crJson)
                }
                // TransHeaderData
                const indentMasterFormData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    indent_master_id: isApprove ? indent_master_id : 0,
                    indent_no: keyForViewUpdate === '' ? lastIndentNo : txt_indent_no,
                    indent_version: txt_indent_version,
                    indent_type_id: cmb_indent_type,
                    indent_type: $('#cmb_indent_type').find(":selected").text(),
                    indent_source: chk_indent_source,
                    customer_id: cmb_customer,
                    customer_order_no: cmb_customer_order_no,
                    customer_order_Date: customer_order_date,
                    indent_date: txt_indent_date,
                    department_id: cmb_department,
                    sub_department_id: cmb_sub_department,
                    cost_center_id: cmb_cost_center,
                    indented_by_id: cmb_indented_by,
                    indent_status: indentStatus === 'R' || indentStatus === 'A' ? indentStatus : 'P',
                    indent_transaction_type: "B",
                    remark: txt_remark,
                    is_active: chk_isactive,
                    approved_by_id: isApprove ? cmb_approved_by_id : '',
                    approved_date: isApprove ? txt_approved_date : '',
                    expected_schedule_date: txt_schedule_date,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' ? UserName : ''
                }
                json.TransHeaderData = indentMasterFormData;

                if (!isApprove) {
                    // json.TransScheduleData = scheduleDataArray;
                    if (keyForViewUpdate === '') {
                        json.TransScheduleData = scheduleDataArray.map(function (schedulerow) {// have to update PO no while add new entry
                            return {
                                ...schedulerow,
                                indent_no: lastIndentNo
                            };
                        });
                    } else {
                        json.TransScheduleData = scheduleDataArray
                    }
                    for (let index = 0; index < json.TransDetailData.length; index++) {
                        const element = json.TransDetailData[index];

                        let scData = json.TransScheduleData.find(item => item.product_material_id === element.product_material_id && item.product_fg_id === element.product_fg_id)

                        if (scData === undefined) {
                            const scheduleData = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                indent_no: keyForViewUpdate === '' ? lastIndentNo : txt_indent_no,
                                indent_date: txt_indent_date,
                                indent_version: txt_indent_version,
                                product_material_id: element.product_material_id,
                                product_fg_id: element.product_fg_id,
                                product_material_unit_id: element.product_material_unit_id,
                                product_material_schedule_quantity: element.product_material_quantity,
                                product_material_schedule_weight: element.product_material_weight,
                                expected_schedule_date: $('#txt_schedule_date').val(),
                                product_material_receipt_quantity: 0,
                                product_material_receipt_weight: 0,
                                material_receipt_date: "",
                                created_by: UserName,
                                modified_by: keyForViewUpdate === 'update' ? UserName : '',
                                indent_item_status: element.indent_item_status,
                                so_sr_no: 0,
                                customer_order_no: cmb_customer_order_no,
                                indent_item_status: element.indentStatus === 'R' || indentStatus === 'A' ? indentStatus : 'P',
                                material_quantity: element.product_material_quantity,
                                material_weight: element.product_material_weight
                            }
                            json.TransScheduleData.push(scheduleData)
                        }

                    }
                    // Trans PO Terms Data
                    $("input:checkbox[name=selectPOTerm]:checked").each(function () {
                        let findPoTermsData = POTermsdata.find(item => item.common_parameters_id === parseInt($(this).val()));

                        const poTermsData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            indent_no: keyForViewUpdate === '' ? lastIndentNo : txt_indent_no,
                            indent_version: txt_indent_version,
                            common_parameters_id: parseInt($(this).val()),
                            common_parameters_name: findPoTermsData.common_parameters_name,
                            common_parameters_value: findPoTermsData.common_parameters_value,
                            common_parameters_expected_value: $("#common_parameters_expected_value_" + $(this).val()).val() === "" ? findPoTermsData.common_parameters_value : $("#common_parameters_expected_value_" + $(this).val()).val(),
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' ? UserName : ''
                        }
                        json.TransPOTermsData.push(poTermsData);
                    });
                } else {

                    if (indentStatus === 'R') {
                        setScheduleDataArray(prevData => {
                            const updatedData = prevData.map(schedulerow => ({
                                ...schedulerow,
                                indent_item_status: 'R'
                            }));

                            json.TransScheduleData.push(...updatedData);

                            return updatedData;
                        });
                    } else {
                        json.TransDetailData.map(element => {
                            let foundElement = scheduleDataArray.find(item => {
                                return (
                                    parseInt(item.product_material_id) === parseInt(element.product_material_id)
                                )
                            });
                            if (indentStatus === 'R') {
                                foundElement.indent_item_status = 'R';
                            }
                            setScheduleDataArray(prevData => {
                                if (element.indent_item_status === 'R') {
                                    const updatedData = prevData.map(schedulerow => {
                                        if (element.product_material_id === schedulerow.product_material_id && element.product_fg_id === schedulerow.product_fg_id) {
                                            return {
                                                ...schedulerow,
                                                indent_item_status: 'R'
                                            };
                                        } else {
                                            return schedulerow;
                                        }
                                    });
                                    json.TransScheduleData.push(...updatedData);
                                    return updatedData;
                                } else {
                                    const updatedData = prevData.map(schedulerow =>
                                        schedulerow === foundElement ? { ...foundElement } : schedulerow
                                    );
                                    json.TransScheduleData.push(...updatedData);
                                    return updatedData;
                                }
                            });

                        });
                    }
                }
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.indent_no = keyForViewUpdate === '' ? lastIndentNo : txt_indent_no
                json.commonIds.indent_date = txt_indent_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                const formData = new FormData();
                formData.append(`StIndentData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const responce = await apicall.json();

                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    // const evitCache = comboBox.current.evitCache();
                    // console.log(evitCache);
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('indent_no') && responce.data.hasOwnProperty('indent_version')) {
                        setModalOrderDetails(`Indent No: ${responce.data.indent_no} and Version: ${responce.data.indent_version}`)
                    }
                    // if (indent_master_id === 0) {
                    //     setMessageForConfirmation("")
                    //     setShowConfiemationModal(true);
                    // } else {
                    setIndentMasterId(responce.data.indent_master_id)
                    setIndentno(responce.data.indent_no)
                    setIndentDate(responce.data.indent_date)
                    setIndentVersion(responce.data.indent_version)
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    // }
                }
                setIsLoading(false)
                console.log(json)
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const FnValidateIndentOrder = async () => {

        // Validating header footer forms data.
        let headerDataIsValid = await validate.current.validateForm("purchaseRequisitionID");
        if (!headerDataIsValid) { return false; }
        if (order_quantity === '' && order_quantity === '0') {
            $('#error_material_quantity').text("Please Enter greater than 0 ...!")
            $('#error_material_quantity').show();
            return false;
        } else {
            $('#error_material_quantity').hide();
        }
        let indentDetailsIsValid = false;
        let addedMaterials = $('#indent-details-tbl tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one material.');
            setShowErrorMsgModal(true);
            return indentDetailsIsValid = false;
        } else {
            $('#indent-details-tbl tbody tr').each(function () {
                let currentTblRow = $(this);

                let materialQty = parseFloat(currentTblRow.find('input[id^="product_child_rm_quantity_"]').val());
                let materialApproveQty = parseFloat(currentTblRow.find('input[id^="product_material_approved_quantity_"]').val());
                let materialRemark = (currentTblRow.find('input[id^="approval_remark_"]')).val();


                if (keyForViewUpdate === 'approve') {
                    if (isNaN(materialApproveQty) || materialApproveQty <= 0) {
                        $(currentTblRow.find('input[id^="product_material_approved_quantity_"]'))[0].parentElement.dataset.tip = 'Product Quantity should not be a zero or blank on approval...!';
                        $(currentTblRow.find('input[id^="product_material_approved_quantity_"]'))[0].focus();
                        return indentDetailsIsValid = false;
                    } else if (materialQty !== materialApproveQty) {
                        if (materialRemark === "") {
                            $(currentTblRow.find('input[id^="approval_remark_"]'))[0].parentElement.dataset.tip = 'Rejection Remark should not be blank on approval...!';
                            $(currentTblRow.find('input[id^="approval_remark_"]'))[0].focus();
                            return indentDetailsIsValid = false;
                        }
                    }
                } else {
                    if (chk_indent_source !== 'S') {
                        if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                            $(currentTblRow.find('input[id^="product_child_rm_quantity_"]'))[0].parentElement.dataset.tip = 'Material quantity should not be a zero or blank...!';
                            $(currentTblRow.find('input[id^="product_child_rm_quantity_"]'))[0].focus();
                            // openAccordion('orderDetailsAccordionItem', 'orderDetailsAccordionHeader');
                            return indentDetailsIsValid = false;
                        }
                    }
                }
                indentDetailsIsValid = true;
            });

            if (keyForViewUpdate !== 'view' && indentDetailsIsValid) {
                let ScheduleIsvalid = false;
                let scheduledata = JSON.parse(localStorage.getItem('indentScheduleData'));
                // const groupedData = groupDataByMaterialId(scheduledata);
                if (scheduledata != null) {
                    const groupedData = scheduledata.reduce((result, item) => {
                        const { product_fg_id, product_material_id, product_material_schedule_quantity } = item;
                        const key = `${item.product_fg_id}:${item.product_material_id}`;

                        if (!result[key]) {
                            result[key] = {
                                product_fg_id,
                                product_material_id,
                                total_product_material_po_schedule_quantity: 0,
                            };
                        }
                        result[key].total_product_material_po_schedule_quantity += parseInt(product_material_schedule_quantity);
                        return result;
                    }, {});
                    let groupedArray = Object.values(groupedData);
                    console.log(groupedArray);


                    for (let detailItem = 0; detailItem < groupedArray.length; detailItem++) {
                        let groupedElement = groupedArray[detailItem];
                        let isDetailpressent = addfgProductData.find(item => item.product_child_rm_id === groupedElement.product_material_id && item.product_parent_fg_id
                            === groupedElement.product_fg_id);
                        if (isDetailpressent) {

                            if (keyForViewUpdate !== 'approve') {
                                if (parseInt(isDetailpressent.product_child_rm_quantity) !== parseInt(groupedElement.total_product_material_po_schedule_quantity)) {
                                    setErrMsg(`The schedule quantity for ${isDetailpressent.product_child_rm_name} is not properly allocated...! (Total Quanity is "${isDetailpressent.product_child_rm_quantity}" and are you scheduling "${groupedElement.total_product_material_po_schedule_quantity}")`)
                                    setShowErrorMsgModal(true)
                                    ScheduleIsvalid = false;
                                    return ScheduleIsvalid = false;
                                }
                                ScheduleIsvalid = true;
                            } else {
                                if (parseInt(isDetailpressent.product_material_approved_quantity) !== parseInt(groupedElement.total_product_material_po_schedule_quantity)) {
                                    setErrMsg(`The schedule quantity for ${isDetailpressent.product_name} is not properly allocated...! (Total Approved Quanity is "${isDetailpressent.product_material_approved_quantity}" and you are scheduling "${groupedElement.total_product_material_po_schedule_quantity}")`)
                                    setShowErrorMsgModal(true)
                                    ScheduleIsvalid = false;
                                    return ScheduleIsvalid = false;
                                }
                                ScheduleIsvalid = true;
                            }
                        }
                    }
                }
                // POcommon terms validations

                let PoCommonTermsIsValid = true;
                if (indentDetailsIsValid) {
                    PoCommonTermsIsValid = true;
                    let selectedCommonTerms = $('#po-commonTerms-table tbody tr .selectPOTerm:checked')
                    selectedCommonTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement.parentElement)
                        let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val();
                        if (expectedValue === '') {
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                            return PoCommonTermsIsValid = false;
                        }
                    });
                    return PoCommonTermsIsValid;
                }
                return indentDetailsIsValid === true && PoCommonTermsIsValid === true;
            } else if (keyForViewUpdate === 'approve' && indentDetailsIsValid) {
                return true;
            } else {
                return keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && indentDetailsIsValid
            }

        }
    }


    // --------------------------------------- indent Import Start ------------------------------------------------------------------

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {

        try {
            if (event.target.files) {
                setIsLoading(true)
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                console.log(fetchRes)
                if (fetchRes.success === '1') {
                    if (fetchRes.data.length !== 0) {
                        let creation_type = "";
                        let indent_no = "";
                        let order_qty = 0;
                        let order_no = "";
                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            if (isNaN(parseFloat(orderDetail.product_child_rm_quantity))) {
                                orderDetail.product_child_rm_quantity = 0;
                            }
                            let calculatedWt = parseFloat(orderDetail.product_child_rm_quantity) * parseFloat(orderDetail.product_std_weight);
                            orderDetail.indent_weight = validateNumberDateInput.current.decimalNumber(calculatedWt.toString(), 4);
                            return orderDetail;
                        });

                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                let comboVal;
                                if (fieldVal !== "" && fieldName === 'Indented By' || fieldName === 'Indent Status' || fieldName === 'Indent Source' || fieldName === 'Department' || fieldName === 'Department' || fieldName === 'Customer' || fieldName === 'Customer Order No.' || fieldName === 'Customer Order Date') {
                                    if (fieldVal !== "") {
                                        const splitVal = fieldVal.split('(')
                                        comboVal = splitVal[1].replace(')', '')
                                    }
                                }
                                switch (fieldName) {
                                    case 'Indent Source':
                                        let keyforSource = '';
                                        setIndentSource(comboVal)
                                        if (comboVal === 'S') {
                                            keyforSource = 'so_based';
                                        } else {
                                            keyforSource = 'Direct_based';
                                        }
                                        await comboOnChange(keyforSource)
                                        creation_type = comboVal;
                                        break;
                                    case 'Customer':

                                        setCustomerId(comboVal)
                                        await comboOnChange("customer")
                                        setCustomerOrderNo(order_no)
                                        await comboOnChange('CustomerOrder')
                                        break;
                                    case 'Customer Order No.':
                                        order_no = comboVal;
                                        setCustomerOrderNo(comboVal)
                                        break;

                                    case 'Customer Order Date':
                                        setCustomerOrderDate(fieldVal)
                                        break;
                                    case 'Indent Date':
                                        setIndentDate(fieldVal)
                                        break;
                                    case 'Department':
                                        setDepartmentId(comboVal)
                                        await comboOnChange('departmentId')
                                        break;

                                    case 'Indent Status':
                                        setIndentStatus(comboVal)
                                        break;
                                    case 'Remark':
                                        setRemark(fieldVal)
                                        break;
                                    // case 'FG Part Code':
                                    //     cmb_fgtype_part_code(comboVal)
                                    //     await comboOnChange('FgTypePartCode')
                                    //     break;
                                    case 'Order Quantity':
                                        setOrderQuantity(fieldVal)
                                        order_qty = fieldVal;
                                        break;
                                }


                            }
                        }
                        setAddFgProductData(() => {
                            let modifiedImportData = [
                                ...readedOrderDetailsFromExcel.map((ordDetail) => {
                                    // const product_child_rm_quantity = ordDetail.product_child_rm_quantity * parseInt(order_qty);
                                    // const indent_weight = ordDetail.product_rm_std_weight * product_child_rm_quantity;

                                    return {
                                        ...ordDetail,
                                        indent_item_status: 'P',
                                        indent_master_id: 0,
                                        indent_version: 1,
                                        indent_no: indent_no,
                                        product_child_bom_quantity: item.product_child_rm_quantity,
                                        product_child_bom_weight: item.indent_weight,
                                        prev_fg_quantity: item.product_child_rm_quantity,
                                        prev_fg_weight: item.indent_weight
                                    };
                                })
                            ]
                            sessionStorage.setItem('addfgProductData', JSON.stringify(modifiedImportData))
                            return [...modifiedImportData];
                        })
                        categoryDisplay();
                        $(".showData").show()
                        setIsLoading(false)
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setIsLoading(false)
                    setShowErrorMsgModal(true)
                }
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }


    // --------------------------------------- PO Import End -------------------------------------------------------
    const ExportToExcel = async () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        var counter = 1;
        if (addfgProductData.length !== 0) {
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [4] }

            let customerOrdDate = validateNumberDateInput.current.formatDateToDDMMYYYY(customer_order_date);
            jsonToExportExcel['filtrKeyValue']['0'] = "Indent No" + ' : ' + txt_indent_no
            jsonToExportExcel['filtrKeyValue']['1'] = "Indent Version" + ' : ' + txt_indent_version
            jsonToExportExcel['filtrKeyValue']['2'] = "Indent Source" + ' : ' + $('input[name="chk_indent_source"]:checked').attr("lbl") + "(" + chk_indent_source + ")"
            jsonToExportExcel['filtrKeyValue']['3'] = "Customer" + ' : ' + (cmb_customer !== '' && cmb_customer !== '0' && cmb_customer !== null ? $('#cmb_customer').find(":selected").text() + "(" + cmb_customer + ")" : '');
            jsonToExportExcel['filtrKeyValue']['4'] = "Customer Order No." + ' : ' + (cmb_customer_order_no === '' ? '' : cmb_customer_order_no + "(" + cmb_customer_order_no + ")");
            jsonToExportExcel['filtrKeyValue']['5'] = "Customer Order Date : " + (customer_order_date === '' ? '' : customerOrdDate + "(" + customerOrdDate + ")")
            jsonToExportExcel['filtrKeyValue']['6'] = "Department" + ' : ' + $('#cmb_department').find(":selected").text() + "(" + cmb_department + ")"
            jsonToExportExcel['filtrKeyValue']['7'] = "Indented By" + ' : ' + $('#cmb_indented_by').find(":selected").text() + "(" + cmb_indented_by + ")"
            jsonToExportExcel['filtrKeyValue']['8'] = "Indent Date" + ' : ' + txt_indent_date
            jsonToExportExcel['filtrKeyValue']['9'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")")
            jsonToExportExcel['filtrKeyValue']['10'] = "Approved Date : " + (txt_approved_date !== '' && txt_approved_date !== undefined ? validateNumberDateInput.current.formatDateToDDMMYYYY(txt_approved_date) : "");
            jsonToExportExcel['filtrKeyValue']['11'] = "Expected Schedule Date : " + txt_schedule_date
            jsonToExportExcel['filtrKeyValue']['12'] = "Indent Status : " + $('#chk_indent_status').find(":selected").text() + "(" + chk_indent_status + ")";
            jsonToExportExcel['filtrKeyValue']['13'] = "Remark" + ' : ' + txt_remark
            jsonToExportExcel['filtrKeyValue']['14'] = "FG Part Code" + ' : ' + $('#cmb_fgtype_part_code').find(":selected").text() + "(" + cmb_fgtype_part_code + ")"
            jsonToExportExcel['filtrKeyValue']['15'] = "Order Quantity" + ' : ' + order_quantity
            jsonToExportExcel['filtrKeyValue']['16'] = "Active : " + $("input[name='chk_isactive']:checked").attr("lbl") + "(" + chk_isactive + ")"

            for (let col = 0; col < requiredCols.length; col++) {

                if (requiredCols[col] !== 'product_child_bom_quantity' && requiredCols[col] !== 'product_child_bom_weight') {
                    if (requiredCols[col] !== 'prev_fg_quantity' && requiredCols[col] !== 'prev_fg_weight') {
                        jsonToExportExcel.columns.push({ "Headers": requiredCols[col], "accessor": requiredCols[col] })
                    }
                }

            }
            addfgProductData.map((orderDetail, Index) => {

                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    product_rm_id: orderDetail.product_material_id,
                    product_material_unit_id: orderDetail.product_child_rm_unit_id,
                    indent_item_status: orderDetail.indent_item_status,
                    product_child_rm_weight: orderDetail.indent_weight,
                    product_child_bom_quantity: orderDetail.product_child_bom_quantity,
                    product_child_bom_weight: orderDetail.product_child_bom_weight,
                    prev_fg_quantity: orderDetail.prev_fg_quantity,
                    prev_fg_weight: orderDetail.prev_fg_weight,

                };
                // keysConvertedOrderDetail['material_schedule_date'] = await validateNumberDateInput.current.formatDateToDDMMYYYY(orderDetail.material_schedule_date)
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });
            jsonToExportExcel['headings']['ReportName'] = "Requisition (BOM Based) Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_indent_no + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }
    // Fn for clear all fields while add
    const FnclearFormFields = async () => {
        // setIndentMasterId(0)
        if (requestfor !== "NA") {
            await fillAutoNo();
        } else {
            setIndentno(0)
            setIndentType('');
        }
        setIndentVersion(1);
        setIndentSource('S')
        setCustomerId('');
        setCustomerOrderNo('')
        setDepartmentId('');
        setSubDepartmentId('')
        setIndentedById('');
        setIndentStatus("P");
        setFgTypePartCode('')
        setOrderQuantity("0")
        setRemark('');
        setIsActive(true);
        setIndentDate(today);
        setCustomerOrderDate('');
        sessionStorage.removeItem('addfgProductData')
        localStorage.removeItem('indentScheduleData')
        setAddFgProductData([])
        setScheduleDataArray([])

        $('#cmb_customer').removeAttr('disabled');
        $('#cmb_customer_order_no').removeAttr('disabled');
        $('#material_quantity').attr('disabled', 'disabled');

    }


    const fillComobos = async () => {
        try {
            comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                .then(getCustomers => {
                    setCustomerOptions(getCustomers)
                    console.log("Customers List: ", getCustomers);
                });

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getDepartment => {
                    setDepartmentIdOptions(getDepartment)
                    console.log("Department List: ", getDepartment);
                });
            // comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
            //     .then(getDepartment => {
            //         setDepartmentIdOptions(getDepartment)
            //         console.log("Department List: ", getDepartment);
            //     });
            comboDataAPiCall.current.fillMasterData("cmv_employee", "", "")
                .then(getEmployee => {
                    setApprovedByOptions(getEmployee)
                    console.log("Employee List: ", getEmployee);
                });

            //Costcenter List
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
            globalQuery.table = "fmv_cost_center";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then((getCostCenterList) => {
                    setCostCenterOptions(getCostCenterList);
                })

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getProductType = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setIndentTypeOptions(getProductType);
            if (requestfor !== "NA") {
                if (getProductType.length !== 0) {
                    const productType = getProductType.find((item) => item.product_type_short_name === requestfor);
                    if (productType) {
                        setIndentType(productType.field_id);
                        await fillAutoNo(productType.product_type_short_name);
                    }
                }
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }


    const comboOnChange = async (key) => {

        switch (key) {
            case 'customer':
                let customerVal = document.getElementById("cmb_customer").value;
                setCustomerId(customerVal);
                if ($('#cmb_customer').val() === '0') {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setCustomerId("");
                    setCustomerOrdOpts([]);
                    setFgTypePartCodeOptions([])
                    setAddFgProductData([])
                    setCustomerOrderNo('');
                    setCustomerOrderDate('');
                    setFgTypePartCode("")
                    setOrderQuantity('0');
                }
                else if (customerVal !== "" & customerVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns = ["DISTINCT customer_order_no", "customer_order_Date", "sum(material_quantity) as order_quantity"];
                    globalQuery.table = "mtv_sales_order_details_trading"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "sales_order_status", operator: "=", value: 'A' });
                    // globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: '3' });
                    globalQuery.groupBy = ["customer_order_no"];
                    const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerOrdOpts(getCustomerOrdersApiCall);
                    setCustomerOrderNo('');
                    setCustomerOrderDate('');
                    setOrderQuantity('0');
                    setFgTypePartCodeOptions([])
                    setAddFgProductData([])
                    setFgTypePartCode("")
                } else {
                    setCustomerOrdOpts([]);
                    setFgTypePartCodeOptions([])
                    setAddFgProductData([])
                    setCustomerOrderNo('');
                    setCustomerOrderDate('');
                    setOrderQuantity('0');
                    setFgTypePartCode("")
                }
                break;
            case 'CustomerOrder':
                if ($('#cmb_customer_order_no').val() != null && $('#cmb_customer_order_no').val() != '') {
                    let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                    let selectedValue = selectedOption.val();
                    let selectedOrderDate = selectedOption.attr('ord_date');
                    setCustomerOrderDate(selectedOrderDate);
                    let selectedOrederQty = selectedOption.attr('ord_qty');
                    setOrderQuantity(selectedOrederQty);
                    await FnGetFGListForSObased(selectedValue);
                    setFgTypePartCode("")
                    setAddFgProductData([])
                    // FnCalculateIndentQty(selectedOrederQty);
                } else {
                    setCustomerOrderDate('');
                    setOrderQuantity('0');
                    setFgTypePartCode("")
                    setFgTypePartCodeOptions([])
                    // setAddFgProductData([])
                }
                break;
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id");
                    globalQuery.columns.push("field_name");
                    globalQuery.table = "cmv_department"
                    globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                    globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setSubDepartmentOptions(subDepartmentApiCall)
                    setIndentedByIdOptions([])
                    setIndentedById('')
                    setSubDepartmentId('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_department').hide();
                    }
                } else {
                    setSubDepartmentOptions([])
                    setIndentedByIdOptions([])
                    setIndentedById('')
                    setSubDepartmentId('')
                }
                break;
            case 'subDepartment':
                var subdepartmentIdVal = document.getElementById('cmb_sub_department').value;
                setSubDepartmentId(subdepartmentIdVal)
                if (subdepartmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Sub Department')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (subdepartmentIdVal !== "" && subdepartmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id");
                    globalQuery.columns.push("field_name");
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: document.getElementById('cmb_department').value });
                    globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subdepartmentIdVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setIndentedByIdOptions(identedbyIdApiCall)
                    setIndentedById('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_sub_department').hide();
                    }
                } else {
                    setIndentedByIdOptions([])
                    setIndentedById('')
                }
                break;

            case 'identedById':
                var indentedBYIdVal = document.getElementById('cmb_indented_by').value;
                setIndentedById(indentedBYIdVal)
                if (indentedBYIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Indent By')
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (indentedBYIdVal !== "" && indentedBYIdVal !== "0") {
                    $('#error_cmb_indented_by').hide();
                }
                break;
            case 'indentTypeId':
                var indentTypeId = document.getElementById('cmb_indent_type').value;
                setIndentType(indentTypeId)
                if (indentTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Indent Type')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (indentTypeId !== '0' && indentTypeId !== "") {
                    $('#error_cmb_indent_type').hide();
                    await fillAutoNo();
                } else {
                    setIndentno("0")
                }
                break;

            case 'FgTypePartCode':
                var FgTypePartCode = document.getElementById('cmb_fgtype_part_code').value;
                var selectElement = document.getElementById("cmb_fgtype_part_code");
                var selectedIndex = selectElement.selectedIndex;
                var selectedText = selectElement.options[selectedIndex].text;
                if (order_quantity !== "" && order_quantity !== "0") {
                    $('#error_material_quantity').hide();
                    setSelectedText(selectedText)
                    setFgTypePartCode(FgTypePartCode)
                    if (FgTypePartCode !== 0 && FgTypePartCode !== "") {

                        $('#error_cmb_fgtype_part_code').hide();
                        setHeaderName('Fg Part Type')
                        setSchedulesRm(true)
                        await FnShowAllBomMstRecords(FgTypePartCode);
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200)
                    } else {
                        setFgTypePartCode("")
                    }
                } else {
                    $('#error_material_quantity').text("Please Enter greater than 0 ...!")
                    $('#error_material_quantity').show();
                }
                break;
            case 'so_based':

                $('#cmb_customer').removeAttr('disabled');
                $('#cmb_customer_order_no').removeAttr('disabled');
                $('#material_quantity').attr('disabled', 'disabled');
                setOrderQuantity("0")
                setFgTypePartCodeOptions([])
                setAddFgProductData([])
                setScheduleDataArray([])
                sessionStorage.setItem('addfgProductData', JSON.stringify([]));
                sessionStorage.setItem('indentScheduleData', JSON.stringify([]));
                break;
            case 'Direct_based':
                setCustomerId("")
                setCustomerOrderNo("")
                setCustomerOrderDate("")
                setAddFgProductData([])
                setScheduleDataArray([])
                sessionStorage.setItem('addfgProductData', JSON.stringify([]));
                sessionStorage.setItem('indentScheduleData', JSON.stringify([]));
                $('#cmb_customer').attr('disabled', 'disabled');
                $('#cmb_customer_order_no').attr('disabled', 'disabled');
                $('#customer_order_date').attr('disabled', 'disabled');
                $('#material_quantity').removeAttr('disabled');
                await FnGetFGListForDirect();
                setOrderQuantity("1")
                break;
            case 'Order_quantity':

                const orderQtyValue = document.getElementById('material_quantity').value;
                if (orderQtyValue !== '' && orderQtyValue !== '0') {
                    const OrderQty = parseInt(orderQtyValue)
                    await FnCalculateIndentQty(OrderQty);
                    $('#error_material_quantity').hide();
                } else {
                    $('#error_material_quantity').text("Please Enter greater than 0 ...!")
                    $('#error_material_quantity').show();
                }
                break;
            case 'cost_center':
                const costcenter = document.getElementById('cmb_cost_center').value;
                setCostCenter(costcenter)
                $('#error_cmb_cost_center').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecIndentModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 200)
                }
            default:
                break;
        }
    }
    const FnGetFGListForSObased = async (customer_order_no) => {
        resetGlobalQuery();
        globalQuery.columns.push("DISTINCT(product_parent_fg_name)");
        globalQuery.columns.push("product_parent_fg_id");
        globalQuery.table = "smv_product_fg_bom_summary"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "customer_order_id", operator: "=", value: customer_order_no });
        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: document.getElementById('cmb_customer').value });
        globalQuery.conditions.push({ field: "bom_applicable", operator: "=", value: "yes" }); //is bom applicable only yes
        const product_fgList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setFgTypePartCodeOptions(product_fgList)
    }

    const FnGetFGListForDirect = async () => {
        resetGlobalQuery();
        globalQuery.columns.push("DISTINCT(product_parent_fg_name)");
        globalQuery.columns.push("product_parent_fg_id");
        globalQuery.table = "smv_product_fg_bom_summary"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "bom_applicable", operator: "=", value: "yes" });
        const product_fgList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setFgTypePartCodeOptions(product_fgList)
    }
    const displayRecordIndentComponent = () => {
        switch (modalHeaderName) {
            case 'Indent Type':
                return <FrmMProductTypeEntry />;

            case 'Indent By':
                return <FrmMEmployeesEntry />;

            case 'Department':
                return <FrmDepartmentEntry />;
            case 'Sub Department':
                const props = { btn_disabled: true, departmentType: "S", parent_department: cmb_department, parentDepIs_disable: true, deptTypeIs_disable: true };
                return <FrmDepartmentEntry {...props} />;
            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />
            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;
            default:
                return null;

        }
    }

    // const addReadOnlyAttr = () => {
    //     var formObj = $('#purchaseRequisitionID');
    //     var inputObj;

    //     for (var i = 0; i <= formObj.get(0).length - 1; i++) {
    //         inputObj = formObj.get(0)[i];
    //         if (inputObj.type === 'text') {
    //             $('#' + inputObj.id).attr('readonly', true);
    //         } else if (inputObj.type === 'select-one') {
    //             $('#' + inputObj.id).attr('disabled', true);
    //         } else if (inputObj.type === 'file') {
    //             $('#' + inputObj.id).attr('disabled', true);
    //         } else if (inputObj.type === 'textarea') {
    //             $('#' + inputObj.id).attr('readonly', true);
    //         } else if (inputObj.type === 'date') {
    //             $('#' + inputObj.id).attr('readonly', true);
    //         } else if (inputObj.type === 'email') {
    //             $('#' + inputObj.id).attr('readonly', true);
    //         } else if (inputObj.type === 'number') {
    //             $('#' + inputObj.id).attr('readonly', true);
    //         }

    //     }
    // }

    // // Close Modal Fg Part Code
    // const handleCloseRecModal = async () => {
    //     setShowAddRecModal(false);
    //     setTimeout(() => {
    //         $(".erp_top_Form").css({ "padding-top": "110px" });
    //     }, 200)

    // }

    const fillAutoNo = async (product_typeShortName) => {
        const ptShortName = indenttypeOptions.find(item => item.field_id === parseInt($('#cmb_indent_type').val()));
        const productTypeId = $('#cmb_indent_type option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_master", "indent_no", product_typeShortName === undefined ? ptShortName.product_type_short_name : product_typeShortName,
            "PR", "5", "indent_type_id", productTypeId);
        setIndentno(autoNoApiCall);
        return autoNoApiCall;
    };

    const validateFields = () => {
        var formObj = $('#purchaseRequisitionID');
        var inputObj;


        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            }
        }


    }

    const handlePageClick = async (pageNo) => {
        console.log(pageNo.selected)
        let currentPage = pageNo.selected;
        console.log("current Page: ", currentPage)
        setcurrentPage(currentPage);
        // const commentsFormServer = await fetchComments(currentPage, entriesPerPage);
        // console.log("commentsFormServer: ", commentsFormServer)

    }

    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        entriesPerPage = count;
        setEntriesPerPage(count)
        console.log(pageCount)
        setcurrentPage(0)

    }

    const moveToListing = () => {
        sessionStorage.removeItem('addfgProductData')
        let Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/PurchaseRequisition/FrmPurchaseRequisitionListing/reg' : `/Transactions/TPurchaseOrder/PurchaseRequisition/FrmPurchaseRequisitionListing`;
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigate(Path);
    }
    //key for get Request type header
    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'YN':
                return '(Cotton Yarn)';
            case 'RC':
                return '(Raw Cotton)';
            case 'BL':
                return '(Cotton Bales)';
            case 'GF':
                return '(Greige Fabric)';
            case 'FF':
                return '(Finish Fabric)';
            case 'RM':
                return '(Raw Material)';
            default:
                return '(Material Indent)';
        }
    }

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('addfgProductData');
            localStorage.removeItem('indentScheduleData')
        }

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }
    const updateApprQuantity = (product_rm_id, event) => {
        console.log(event)
        $('#' + event.target.id).val(event.target.value)
    }
    const RequiredQty = () => {
        let orderQty = document.getElementById('material_quantity');
    }
    const checkboxState = {};
    const showCheckedCheckBoxes = async () => {
        const getData = JSON.parse(sessionStorage.getItem('addfgProductData'))
        let checkedData = [];
        $("input:checkbox[name=FGMaterialRec]").each(function () {
            const checkboxValue = $(this).val();
            checkboxState[checkboxValue] = false; // Initialize to unchecked
        });
        // Update the checkbox state when a checkbox is checked or unchecked
        $("input:checkbox[name=FGMaterialRec]").each(getData, function () {
            const checkboxValue = $(this).val();
            checkboxState[checkboxValue] = $(this).is(":checked");
        });
    };


    function toggleFGMaterialChkBoxes(key) {

        switch (key) {
            case "selectAllFGMaterials":
                $(".FGMaterialRec").prop('checked', $('#selectAllFGMaterials').is(":checked"));
                break;
            case "checkFGMaterialSelection":
                $('#selectAllFGMaterials').prop('checked', $('input:checkbox.FGMaterialRec:checked').length == $('input:checkbox.FGMaterialRec').length);
                break;
        }
    }
    // Fn calculate indent qty 
    const FnCalculateIndentQty = async (selectedOrederQty) => {
        if (addfgProductData.length !== 0) {
            setAddFgProductData((prevArray) => {
                const updatedIndentDetails = [
                    ...prevArray.map((item) => {
                        const product_child_rm_quantity = item.product_child_bom_quantity * selectedOrederQty;
                        const indent_weight = item.product_child_bom_weight * product_child_rm_quantity;
                        const product_fg_material_quantity = selectedOrederQty;
                        const product_fg_material_weight = item.product_child_bom_weight * product_fg_material_quantity;
                        return {
                            ...item,
                            product_child_rm_quantity,
                            indent_weight,
                            product_fg_material_quantity,
                            product_fg_material_weight

                        };
                    })
                ];
                sessionStorage.setItem('addfgProductData', JSON.stringify(updatedIndentDetails));
                return updatedIndentDetails;
            });
        }
    }

    const FnShowAllBomMstRecords = async (FgTypePartCode) => {

        try {
            const bomMstRecordsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFgBomMst/FnShowProductFgBomMstRecord/${FgTypePartCode}/${COMPANY_ID}?page=0&size=${entriesPerPage}`)

            const bomMstRecordResponce = await bomMstRecordsApiCall.json();
            console.log("FG bomMstRecordResponce: ", bomMstRecordResponce)
            if (bomMstRecordResponce.length > 0) {

                var bomMstColumn = [];
                var bomMstColumnHeads = [];
                for (let keys in bomMstRecordResponce[0]) { bomMstColumnHeads.push(keys) };
                console.log("column heads: ", bomMstColumnHeads)

                for (let colKey = 0; colKey < bomMstColumnHeads.length; colKey++) {
                    if (colKey === 0) {
                        bomMstColumn.push({
                            Headers: "Action",
                            accessor: "Action",
                            Cell: row => (
                                <div style={{ display: "flex" }}>
                                    <input type='checkbox' class="FGMaterialRec" name="FGMaterialRec" id={'FGMaterial_' + row.original.product_child_rm_id} defaultChecked
                                        value={row.original.product_child_rm_id} onClick={(e) => toggleFGMaterialChkBoxes('checkFGMaterialSelection')}></input>

                                </div>
                            ),
                        });
                    }
                    bomMstColumn.push({ Headers: bomMstColumnHeads[colKey], accessor: bomMstColumnHeads[colKey] });
                }
                setColumns(bomMstColumn)
                setFgBomData(bomMstRecordResponce)
                $('#selectAllFGMaterials').prop('checked', true);
                $('#modal-id').show();
                $('#header-listing').show();
                FnSetChkBoxCheck(FgTypePartCode, bomMstRecordResponce);
            } else {
                $('#no_records_msg_id').show();
                $('#header-listing').hide();
                $("#Add-Button").hide();
                setColumns([])
                setFgBomData([])
                return data;
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
            return data;

        }
    }

    const FnSetChkBoxCheck = (FgTypePartCode, fgBomResponce) => {
        const filterData = [...addfgProductData].filter(item => FgTypePartCode === item.product_parent_fg_id).map(existFGItem => {
            fgBomResponce.forEach(newFgItem => {
                if (existFGItem.product_child_rm_id === newFgItem.product_child_rm_id) {
                    const checkbox = $(`input:checkbox[name="FGMaterialRec"][value="${newFgItem.product_child_rm_id}"]`);
                    checkbox.prop('checked', true);
                }
            });
        });

        // Check if all individual checkboxes are checked and update the "Check All" checkbox state
        const allChecked = $('.FGMaterialRec:checked').length === $('.FGMaterialRec').length;
        $('#selectAllFGMaterials').prop('checked', allChecked);
    };



    const FnApplyFilterNShowData = async () => {

        let checkedData = [];
        //for multipication of order qty and product child rm qty(smv_product_fg)
        $("input:checkbox[name=FGMaterialRec]:checked").each(function () {

            let findCheckedData = data.find(item => item.product_child_rm_id === $(this).val());
            findCheckedData['id'] = `${findCheckedData.product_parent_fg_id}:${findCheckedData.product_child_rm_id}`;//append uniq key to check multiple duplicate fg material
            const itemval = findCheckedData.product_child_rm_quantity;
            const fg_total_quantity = order_quantity * itemval;
            findCheckedData.product_child_rm_quantity = fg_total_quantity; // Append fg_total_quantity to product_child_rm_quantity
            const itemvalweight = findCheckedData.product_child_rm_weight;
            const fg_total_weight = fg_total_quantity * parseFloat(itemvalweight); // Append fg_total_quantity to product_child_rm_quantity
            const productFgWeight = order_quantity * parseFloat(itemvalweight);
            findCheckedData.indent_weight = validateNumberDateInput.current.decimalNumber(fg_total_weight, 4);
            findCheckedData['product_fg_material_quantity'] = order_quantity;
            findCheckedData['product_fg_material_weight'] = validateNumberDateInput.current.decimalNumber(productFgWeight, 4);
            checkedData.push(findCheckedData);
        });
        console.log("addfgProductData", checkedData)
        setAddFgProductData((prevArray) => {
            if (checkedData === null) {
                return null; // If checkedData is null, set dispatchDetailsData to null.
            } else {
                const filteredData = [...prevArray].filter(item => prevArray.some(prevItem => prevItem.id === item.id));
                const updatedIndentDetails = [
                    ...filteredData,
                    ...checkedData.filter(item => !filteredData.some(prevItem => prevItem.id === item.id)).map(function (item) {
                        return {
                            ...item,
                            finish_goods: item.product_parent_fg_name,
                            product_rm_name: item.product_child_rm_name,
                            product_rm_std_weight: item.product_child_rm_weight,
                            approval_remark: "",
                            lead_time: "0",
                            product_material_approved_quantity: '0',
                            product_material_approved_weight: '0',
                            product_material_rejected_quantity: '0',
                            product_material_rejected_weight: '0',
                            product_material_stock_quantity: "0",
                            product_material_stock_weight: "0",
                            product_material_reserve_quantity: "0",
                            product_material_reserve_weight: "0",
                            product_material_return_quantity: "0",
                            product_material_return_weight: "0",
                            product_material_stock_quantity: "0",
                            product_material_id: item.product_child_rm_id,
                            product_child_rm_unit_id: item.product_child_rm_unit_id,
                            indent_item_status: 'P',
                            product_child_bom_quantity: item.product_child_rm_quantity,
                            product_child_bom_weight: item.product_child_rm_weight,
                            prev_fg_quantity: item.product_child_rm_quantity,
                            prev_fg_weight: item.product_child_rm_weight

                        };
                    })
                ];
                sessionStorage.setItem('addfgProductData', JSON.stringify(updatedIndentDetails));
                return updatedIndentDetails;
            }
        });
        setSchedulesRm(false);
        setShowBomFilterForm(false)
        if (checkedData !== '' && checkedData !== null && checkedData.length !== 0) { $('.showData').show() } else { $('.showData').hide() }
        sessionStorage.setItem('isComboFilterExist', false)
        // Remove Bom Filter Session
        var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`)) === null ? [] : JSON.parse(localStorage.getItem(`localStorageKeyArr`));
        console.log('LocalArr: ', LocalArr)
        for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
            localStorage.removeItem(LocalArr[localArrIndex])
        }
        removeSessions();
        setTimeout(() => { categoryDisplay(); }, 300);
    }

    const handleInputChangeText = async (row, event) => {
        let clickedColName = row.column.Headers;                // Get the column name where event triggered.
        let currentRowData = row.original;
        if (validateRejectionRemark(event)) {                      // if valid then update that value and go for calculation.
            row.original[clickedColName] = event.target.value;     // remove the starting zeros.
            currentRowData = row.original;
        } else {
            row.original[clickedColName] = '';
            $(event.target.id).focus();
        }

        let tblRows = row.rows.map(tblRow => tblRow.original);  // Store all table rows original object in array.
        tblRows[row.row.id] = currentRowData;                   // Update the updated row data at position.
        setAddFgProductData(tblRows);                        // Set that updated data in table data hook;
        sessionStorage.setItem('addfgProductData', JSON.stringify(tblRows));
    }

    const validateRejectionRemark = (event, product_rejct_qty) => {
        const textOnlyCheck = /^[A-Za-z\s]+$/;
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === product_rejct_qty > 0 || !textOnlyCheck.test(event.target.value)) {

            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid approval remark...!';

            return false;
        }

        else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }

    const handleInputChange = async (currentRowData, event) => {

        let clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        let enteredValue = parseInt(event.target.value);

        if (clickedColName === 'approval_remark') {
            // let indentItemsQtyinp = document.getElementById(event.target.id);
            let indentItemsQtyinp = document.querySelector('#' + event.target.id);
            if (event.target.value !== '') {
                currentRowData[clickedColName] = event.target.value;
                delete indentItemsQtyinp.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = '';
                indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid approval remark...!';
            }
        }
        else if (clickedColName === 'indent_item_status') {
            currentRowData[clickedColName] = event.target.value;
        }

        else if (validateMaterialDetails(event)) {                      // if valid then update that value and go for calculation.
            currentRowData[clickedColName] = enteredValue;    // remove the starting zeros.
            let material_qty = currentRowData['product_child_rm_quantity']
            if (keyForViewUpdate === 'approve') {


                // let product_app_qty = currentRowData['product_material_approved_quantity']
                let product_app_qty = enteredValue
                let product_rejct_qty = (material_qty - product_app_qty);
                let product_std_wgt = currentRowData['product_rm_std_weight']
                let product_approved_wgt = product_app_qty * product_std_wgt;
                let product_rejct_wgt = product_rejct_qty * product_std_wgt;

                currentRowData['product_material_approved_weight'] = validateNumberDateInput.current.decimalNumber(product_approved_wgt, 4)
                currentRowData['product_material_rejected_weight'] = validateNumberDateInput.current.decimalNumber(product_rejct_wgt, 4)
                let indentItemsQtyinp = document.querySelector('#' + event.target.id);

                if (product_app_qty > material_qty) {
                    indentItemsQtyinp.parentElement.dataset.tip = 'cannot exceed material qauntity...!';
                    return false;
                }

                if (product_rejct_qty > 0) {
                    currentRowData['product_material_rejected_quantity'] = product_rejct_qty
                    var $rejectionRemark = $(`#approval_remark_${currentRowData.product_child_rm_id}_${currentRowData.product_parent_fg_id}`);
                    if ($rejectionRemark.val() === "") {
                        $rejectionRemark.prop('disabled', false);
                        if (event._reactName === 'onBlur') {
                            $rejectionRemark.focus();
                        }
                    }
                } else {
                    currentRowData['product_material_rejected_quantity'] = 0
                    delete indentItemsQtyinp.parentElement.dataset.tip;
                }
            } else {
                let product_std_wgt = currentRowData['product_rm_std_weight']
                let material_wgt = material_qty * product_std_wgt;
                currentRowData['indent_weight'] = validateNumberDateInput.current.decimalNumber(material_wgt, 4)
            }
        }
        else {
            currentRowData[clickedColName] = '';
        }


        const detailData = [...addfgProductData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        detailData[arrayIndex] = currentRowData
        setAddFgProductData(detailData);                        // Set that updated data in table data hook;
        sessionStorage.setItem('addfgProductData', JSON.stringify(detailData)); // Set that updated data in session.


    };

    const handleScheduleData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const poschedule_data = [...scheduleDataArray]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        poschedule_data[arrayIndex] = currentRowData
        setScheduleDataArray(poschedule_data);
    }


    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{1,18}(?:\.\d{0,4})?$/;

        // const checkPercent = /^(\d+(\.\d+)?)%?$/; // to check percent 
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === 0 || event.target.value === null || !numCheck.test(event.target.value)) {

            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';

            return false;
        }

        else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }


    const removeMaterial = (row) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            const detailData = [...addfgProductData]
            // const arrayIndex = parseInt(e.target.parentElement.parentElement.parentElement.getAttribute('rowindex'))
            const arrayIndex = detailData.findIndex(item => item.product_child_rm_id === row.product_child_rm_id && item.product_parent_fg_id === row.product_parent_fg_id);
            if (arrayIndex !== -1) { detailData.splice(arrayIndex, 1); }
            setAddFgProductData(detailData);
            sessionStorage.setItem('addfgProductData', JSON.stringify(detailData));
            let materialScheduleTblRows = JSON.parse(localStorage.getItem('indentScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('indentScheduleData'));
            if (materialScheduleTblRows.length > 0) {
                const updatedMaterialSchedules = materialScheduleTblRows.filter(item => item.product_material_id !== row.product_child_rm_id || item.product_fg_id !== row.product_parent_fg_id);
                setScheduleDataArray(updatedMaterialSchedules);
                localStorage.setItem('indentScheduleData', JSON.stringify(updatedMaterialSchedules));
            }
        }
    }



    const addNewScheduleRow = () => {
        setSchedulerowcount(schedulerowcount + 1)
        addScheduleTblRow(schedulerowcount + 1);
    }

    const openSchedules = (requistionItem) => {

        if (keyForViewUpdate !== 'delete' && keyForViewUpdate !== 'view') {
            let product_rm_name = requistionItem.product_child_rm_name;
            let product_child_rm_id = requistionItem.product_child_rm_id;
            let product_child_rm_quantity = requistionItem.product_child_rm_quantity;
            let product_child_rm_stock_unit_name = requistionItem.product_child_rm_stock_unit_name;
            let unit_id = requistionItem.product_child_rm_unit_id;
            let product_rm_std_weight = requistionItem.product_rm_std_weight;
            let product_parent_fg_id = requistionItem.product_parent_fg_id;
            let approve_qty = requistionItem.product_material_approved_quantity;

            let schCount = 1;
            setSchedules(true)
            setTimeout(() => {
                $('#productMatName').append(product_rm_name);
                $('#productMatName').attr('product_child_rm_id', product_child_rm_id);
                $('#productMatName').attr('product_parent_fg_id', product_parent_fg_id);
                if (keyForViewUpdate !== 'approve') {
                    $('#productMatQty').append(product_child_rm_quantity);
                } else {
                    $('#productMatQty').append(approve_qty);
                }
                $('#productMatQty').attr('material_quantity', product_child_rm_quantity);
                $('#productMatStdWeight').append(product_rm_std_weight);
                const avgWt = product_rm_std_weight * product_child_rm_quantity;
                $('#productMatTotalWeight').append(avgWt.toFixed(2));
                $('#productMatUnit').append(product_child_rm_stock_unit_name);
                $('#productMatUnit').attr('product_material_unit_id', unit_id);

                let updatedScheduleDataArray = JSON.parse(localStorage.getItem('indentScheduleData'));
                console.log(updatedScheduleDataArray)
                let filteredSchedule = [];
                if (updatedScheduleDataArray !== null) {
                    filteredSchedule = updatedScheduleDataArray.filter(item => {
                        if (item.id !== undefined) {
                            const [fgId, itemId] = item.id.split(':');
                            return product_child_rm_id === itemId && product_parent_fg_id === fgId;
                        } else {
                            return product_child_rm_id === item.product_child_rm_id && product_parent_fg_id === item.product_parent_fg_id;
                        }
                    });
                }
                if (filteredSchedule.length !== 0) {
                    filteredSchedule.forEach(item => {
                        addScheduleTblRow(schCount);

                        $('#scheduleQty-' + schCount).val(item.product_material_schedule_quantity)
                        $('#scheduleWt-' + schCount).val(item.product_material_schedule_weight)
                        $('#scheduleDate-' + schCount).val(item.expected_schedule_date)
                        $('#mtRecptQty-' + schCount).val(item.product_material_receipt_quantity)
                        $('#mtRecptWt-' + schCount).val(item.product_material_receipt_weight)
                        $('#mtRecptDate-' + schCount).val(item.product_material_receipt_date)
                        schCount += 1;
                        console.log(item);
                    });

                } else {
                    remainingSchduleQty = product_child_rm_quantity;
                    addScheduleTblRow(schCount);
                    schCount += 1;
                }

                setSchedulerowcount(schCount)

            }, 300)
        }
    }

    const addScheduleTblRow = (rowCount) => {


        if (rowCount !== 1) {
            let newRow = $(`<tr role="row" scheduleTrCount="${rowCount}" id="RMSchedulesEntry-tr-${rowCount}"></tr>`);
            let secondLastRow = $("#scheduleTable tr").eq(-1);
            newRow.insertBefore(secondLastRow);
            $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append('<span class="RemoveTrBtn"  id="RemoveTrBtn-' + rowCount + '" name=' + rowCount + ' value=' + rowCount + '><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="erp_trRemove_icon"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-miterlimit="10" stroke-width="32" d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 256H176"></path></svg></span>'))
        }

        const materialStdWt = parseFloat($('#productMatStdWeight').text().trim());
        const averageWt = materialStdWt * remainingSchduleQty;

        const createTd2 = $('<input type="number" rowIndex="' + rowCount + '" class="scheduleQty" id="scheduleQty-' + rowCount + '" value="' + remainingSchduleQty + '"/>').addClass("erp_input_field mb-0")
        const createTd3 = $('<input type="text" rowIndex="' + rowCount + '" id="scheduleWt-' + rowCount + '" value="' + validateNumberDateInput.current.decimalNumber(averageWt, 4) + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd4 = $('<input type="date" rowIndex="' + rowCount + '" id="scheduleDate-' + rowCount + '" value="' + txt_schedule_date + '" />').addClass("erp_input_field mb-0")
        const createTd5 = $('<input type="number" rowIndex="' + rowCount + '" id="mtRecptQty-' + rowCount + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd6 = $('<input type="text" rowIndex="' + rowCount + '" id="mtRecptWt-' + rowCount + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd7 = $('<input type="date" rowIndex="' + rowCount + '" id="mtRecptDate-' + rowCount + '" value="' + dt_overall_schedule_date + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd8 = $(`<select rowIndex='${rowCount}' id='scheduleStatus-${rowCount}' disabled='disabled' class="form-select form-select-sm">
        <option value="P">Pending</option> <option value="A">Approved</option>
       <option value="I">Partial receipt</option> <option value="R">Rejected</option>
        <option value="C">Completed</option>  <option value="X">Canceled</option>  </select>`);
        const createTd9 = $('<textarea type="text" rowIndex="' + rowCount + '" rowIndex="' + rowCount + '" id="schedulrRemark-' + rowCount + '" maxLength="255" ></textarea>').addClass("erp_input_field_table_txt mb-0")


        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd2));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd3));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd4));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd5));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd6));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd7));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd8));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd9));

        const datePicker = document.getElementById('scheduleDate-' + rowCount);
        datePicker.min = todayDate();
        const datePicker2 = document.getElementById('mtRecptDate-' + rowCount);
        datePicker2.min = todayDate();

        $('#scheduleTable tbody').on('click', '.RemoveTrBtn', function () {
            var $tr = $(this).closest('tr');
            $tr.remove();
        });

        // scheduleQty
        $('.scheduleQty').on('input', function (e) {
            if (validateMaterialDetails(e)) {
                $(this).val(parseInt($(this).val(), 10));
                let rowIndex = $(this).attr("rowIndex");
                const materialStdWt = parseFloat($('#productMatStdWeight').text().trim());
                const averageWt = materialStdWt * parseFloat($(this).val().trim());
                $(`#scheduleWt-${rowIndex}`).val(averageWt.toFixed(4));
            } else {
                $(this).focus();
            }
        });
    }

    function schedule() {

        let checkIsValidated = validateSchedules();
        if (checkIsValidated === true) {
            const table = document.getElementById('scheduleTable');
            const tbody = table.querySelector('tbody');
            const trElements = tbody.querySelectorAll('tr');

            let updatedScheduleDataArray = JSON.parse(localStorage.getItem('indentScheduleData')) !== null ? JSON.parse(localStorage.getItem('indentScheduleData')) : [];
            let product_child_rm_id = $('#productMatName').attr('product_child_rm_id')
            let product_parent_fg_id = $('#productMatName').attr('product_parent_fg_id');
            // Filter out items where product_rm_name matches newScheduleData.product_rm_name
            updatedScheduleDataArray = updatedScheduleDataArray.filter(item => {
                return (
                    item.product_material_id !== product_child_rm_id ||
                    item.product_fg_id !== product_parent_fg_id
                );
            });

            for (let count = 0; count < trElements.length; count++) {
                let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
                const scheduleData = {
                    id: product_parent_fg_id + ":" + product_child_rm_id + ":" + count,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    product_rm_name: $('#productMatName').text(),
                    indent_no: txt_indent_no,
                    indent_date: txt_indent_date,
                    indent_version: txt_indent_version,
                    product_material_id: product_child_rm_id,
                    product_fg_id: product_parent_fg_id,
                    product_material_unit_id: parseInt($('#productMatUnit').attr('product_material_unit_id')),
                    product_material_schedule_quantity: $('#scheduleQty-' + scheduleTrCount).val(),
                    product_material_schedule_weight: $('#scheduleWt-' + scheduleTrCount).val(),
                    material_weight: $('#productMatTotalWeight').text(),
                    material_quantity: $('#productMatQty').text(),
                    expected_schedule_date: $('#scheduleDate-' + scheduleTrCount).val(),
                    product_material_receipt_quantity: $('#mtRecptQty-' + scheduleTrCount).val(),
                    product_material_receipt_weight: $('#mtRecptWt-' + scheduleTrCount).val(),
                    material_receipt_date: $('#mtRecptDate-' + scheduleTrCount).val(),
                    indent_item_status: chk_indent_status,
                    remark: $('#schedulrRemark-' + scheduleTrCount).val(),
                    so_sr_no: 0,
                    customer_order_no: cmb_customer_order_no,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' ? UserName : ''
                }

                // Add the new data to the updatedScheduleDataArray
                updatedScheduleDataArray.push(scheduleData);

            }
            // Update the state with the updated array
            setScheduleDataArray(updatedScheduleDataArray);
            localStorage.setItem('indentScheduleData', JSON.stringify(updatedScheduleDataArray));


            // FnScheduleColumns()
            setSchedules(false)

        }


        console.log(checkIsValidated)
    }

    const deleteSchedules = (idToRemove) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            let scheduleData = JSON.parse(localStorage.getItem('indentScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('indentScheduleData'));
            const filteredScheduleData = scheduleData.filter(item => item.id !== idToRemove);
            setScheduleDataArray(filteredScheduleData);
            localStorage.setItem('indentScheduleData', JSON.stringify(filteredScheduleData));

        }
    }




    const renderScheduleTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='po-schedule-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Indent No</th>
                        <th className="erp_table_th">Indent Date</th>
                        <th className="erp_table_th">Indent Version</th>
                        <th className="erp_table_th">Schedule Quantity</th>
                        <th className="erp_table_th">Schedule Weight</th>
                        <th className="erp_table_th">Expected Schedule Date</th>
                        <th className="erp_table_th">Receipt Quantity</th>
                        <th className="erp_table_th">Receipt Weight</th>
                        <th className="erp_table_th">Indent Item Status</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        scheduleDataArray.map((scheduleItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className={`erp_table_td  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>
                                    <div style={{ display: "flex" }}>
                                        <Tooltip title="Delete" placement="top">
                                            <MDTypography className="erp-view-btn">
                                                <MdDelete className="erp-delete-btn" id={scheduleItem.id} onClick={() => deleteSchedules(scheduleItem.id)} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className="erp_table_td"> {scheduleItem.product_rm_name} </td>
                                <td className="erp_table_td">{scheduleItem.indent_no}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.indent_date)}</td>
                                <td className="erp_table_td text-end">{scheduleItem.indent_version}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_schedule_quantity}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_schedule_weight}</td>
                                <td className="erp_table_td text-end">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.expected_schedule_date)}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_receipt_quantity}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_receipt_weight}</td>
                                <td> <select id={`indent_item_status_${scheduleItem.product_material_id}`} value={scheduleItem.indent_item_status} disabled={(keyForViewUpdate !== 'approve')}
                                    className="form-select form-select-sm approveField" Headers='indent_item_status' onChange={(e) => { handleScheduleData(scheduleItem, e); }} >
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Rejected</option>
                                    <option value="I">Partial Issue</option>
                                    <option value="C">Completed</option>
                                    <option value="X">Canceled</option>
                                    <option value="O">Purchse Order Genreated</option>
                                    <option value="POA">Purchse Order Approved</option>
                                    <option value="G">Goods Receipts</option>
                                </select></td>
                                <td className="erp_table_td">
                                    <input
                                        type="textarea"
                                        className="erp_input_field mb-0 "
                                        id={`${`remark`}_${scheduleItem.product_material_id}`} disabled={true}
                                        value={scheduleItem.remark} maxLength={500} // Use a function to get the input value based on propertyName

                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [scheduleDataArray]);





    // const renderSchedulesTable = useMemo(() => {
    //     return <>
    //         <Table className="erp_table" id='schedules-table' responsive bordered>
    //             <thead className="erp_table_head">
    //                 <tr>
    //                     <th className="erp_table_th">Action</th>
    //                     <th className="erp_table_th">Raw Material </th>
    //                     <th className="erp_table_th">Indent No</th>
    //                     <th className="erp_table_th">Indent Version</th>
    //                     <th className="erp_table_th">Indent Date</th>
    //                     <th className="erp_table_th">Indent Item Status</th>
    //                     <th className="erp_table_th">Schedule Quantity</th>
    //                     <th className="erp_table_th">Schedule Weight</th>
    //                     <th className="erp_table_th">Expected Schedule Date</th>
    //                     <th className="erp_table_th">Reciept Quanity</th>
    //                     <th className="erp_table_th">Reciept Weight</th>
    //                     <th className="erp_table_th">Remark</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {
    //                     scheduleDataArray.map((Schedule, Index) =>
    //                         <tr rowIndex={Index}>

    //                             <td className="erp_table_td">
    //                                 {/* <MdDelete className="erp-delete-btn" id={row.original.id} onClick={() => deleteSchedules(row.original.id)} /> */}
    //                                 <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => deleteSchedules(Schedule, e)} />

    //                             </td>
    //                             <td className="erp_table_td">{Schedule.product_child_rm_name}</td>
    //                             <td className="erp_table_td">{Schedule.indent_no}</td>
    //                             <td className="erp_table_td">{Schedule.indent_version}</td>
    //                             <td className="erp_table_td">{Schedule.indent_date}</td>
    //                             <td className="erp_table_td">
    //                                 <select id={`indent_item_status_${Schedule.product_rm_id}`} disabled value={Schedule.indent_item_status} className="form-select form-select-sm" >
    //                                     <option value="P">Pending</option>
    //                                     <option value="A">Approved</option>
    //                                     <option value="R">Rejected</option>
    //                                     <option value="I">Partial Issue</option>
    //                                     <option value="C">Completed</option>
    //                                     <option value="X">Canceled</option>
    //                                 </select></td>
    //                             {/* <td className="erp_table_td">{Schedule.indent_item_status}</td>  */}
    //                             <td className="erp_table_td">{Schedule.product_material_schedule_quantity}</td>
    //                             <td className="erp_table_td">{Schedule.product_material_schedule_weight}</td>
    //                             <td className="erp_table_td">{Schedule.expected_schedule_date}</td>
    //                             <td className="erp_table_td">{Schedule.product_material_receipt_quantity}</td>
    //                             <td className="erp_table_td">{Schedule.product_material_receipt_weight}</td>
    //                             <td className="erp_table_td">{Schedule.remark}</td>

    //                         </tr>
    //                     )
    //                 }
    //             </tbody>
    //         </Table>
    //     </>
    // }, [scheduleDataArray]);





    const validateSchedules = () => {
        let result = true;
        const table = document.getElementById('scheduleTable');
        const tbody = table.querySelector('tbody');
        const trElements = tbody.querySelectorAll('tr');
        let addedQty = 0;

        for (let count = 0; count < trElements.length; count++) {
            let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');

            let scheduleQty = $('#scheduleQty-' + scheduleTrCount).val();
            let scheduleQtyinp = document.querySelector('#scheduleQty-' + scheduleTrCount);
            addedQty += parseInt(scheduleQty);
            if (scheduleQty === '' || (parseInt(scheduleQty) === 0 || parseInt(scheduleQty) < 0)) {
                scheduleQtyinp.parentElement.dataset.tip = 'Please enter valid quantity!...';
                return false;
            } else { delete scheduleQtyinp.parentElement.dataset.tip; }

            const scheduleDate = $('#scheduleDate-' + scheduleTrCount).val();
            const scheduleDateinp = document.querySelector('#scheduleDate-' + scheduleTrCount);

            if (scheduleDate === '') {
                scheduleDateinp.parentElement.dataset.tip = 'Please enter schedule date!...';
                return false;
            } else { delete scheduleDateinp.parentElement.dataset.tip; }


        }

        if (addedQty > parseInt($('#productMatQty').text())) {
            setErrMsg('Schedule quantity not more than Total quantity  ' + parseInt($('#productMatQty').text()) + ' !...')
            setShowErrorMsgModal(true)
            return false;
        } else if (addedQty < parseInt($('#productMatQty').text())) {
            setErrMsg('Schedule quantity not less than Product material quantity ' + parseInt($('#productMatQty').text()) + ' !...')
            setShowErrorMsgModal(true)
            return false;
        }
        return result;
    }



    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#indent-details-tbl').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const renderDetailsTable = useMemo(() => {

        return <>
            <Table className="erp_table erp_table_scroll" id='indent-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Finish Goods</th>
                        <th className="erp_table_th">Raw Material</th>
                        <th className="erp_table_th">Technical Specification</th>
                        <th className="erp_table_th">Unit Name</th>
                        <th className="erp_table_th">Stock Quantity</th>
                        <th className="erp_table_th">Lead time</th>
                        <th className={`erp_table_th`}>Indent Quantity</th>
                        <th className={`erp_table_th`}>Approved Quantity</th>
                        <th className="erp_table_th">Rejected Quanity</th>
                        <th className={`erp_table_th`}>Approval Remark</th>
                        <th className="erp_table_th">Indent Item Status</th>
                        <th className="erp_table_th">Material FG Quantity</th>
                        <th className="erp_table_th" >Drawing No</th>
                        <th className="erp_table_th">Material Oem Part Code</th>
                        <th className="erp_table_th">Material Our Part Code</th>
                        <th className="erp_table_th">Reserve quantity</th>
                        <th className="erp_table_th">Return quantity</th>
                        <th className="erp_table_th">Material Weight</th>
                        <th className="erp_table_th">Indent Weight</th>
                        <th className="erp_table_th">Approved Weight</th>
                        <th className="erp_table_th">Rejected Weight</th>
                        <th className="erp_table_th">Material FG Weight</th>
                        <th className="erp_table_th">Reserve weight</th>
                        <th className="erp_table_th">Stock Weight</th>
                        <th className="erp_table_th">Return weight</th>

                    </tr>
                </thead>
                <tbody>
                    {addfgProductData.map((indentItem, Index) =>
                        <tr rowIndex={Index}>
                            <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>
                                <div style={{ display: 'flex' }}>
                                    <div className={`${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' && keyForViewUpdate !== 'approve' ? 'display' : 'd-none'}`}>
                                        <Tooltip title="Delete" placement="top">
                                            <MDTypography className="erp-view-btn">
                                                <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index} onClick={(e) => removeMaterial(indentItem)} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                    <Tooltip title="Schedule" placement="top">
                                        <MDTypography className="erp-schedule-btn">
                                            <AiOutlineSchedule className="erp-schedule-btn" onClick={() => {
                                                const args = chk_indent_source === 'S'
                                                    ? [indentItem]
                                                    : [indentItem];

                                                openSchedules(...args);
                                            }} />
                                        </MDTypography>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className={`erp_table_td `}>{indentItem.product_parent_fg_name}</td>
                            <td className={`erp_table_td`}>{indentItem.product_child_rm_name}</td>
                            <td className={`erp_table_td`}>{indentItem.product_child_rm_tech_spect}</td>
                            <td className={`erp_table_td`}>{indentItem.product_child_rm_stock_unit_name}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_stock_quantity}</td>
                            <td className="erp_table_td text-end">{indentItem.lead_time}</td>
                            <td className="erp_table_td text-end">
                                <input type="text" id={`product_child_rm_quantity_${indentItem.product_child_rm_id}`} className="erp_input_field mb-0 text-end "
                                    value={indentItem.product_child_rm_quantity} onChange={(e) => { handleInputChange(indentItem, e); }}
                                    disabled={IsView ? true : false} Headers='product_child_rm_quantity' />
                            </td>
                            {/* <td className={`erp_table_td ${ keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? '' : "text-end"}`}>
                                {keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view' || keyForViewUpdate !== 'delete' ?
                                    <input type="text" id={`product_child_rm_quantity${indentItem.product_child_rm_id}`} className="erp_input_field mb-0 text-end "
                                    value={indentItem.product_child_rm_quantity} onChange={(e) => { handleInputChange(indentItem, e); }}
                                    disabled={IsView ? true : false} Headers='product_child_rm_quantity' />
                                    :
                                    indentItem.product_child_rm_quantity
                                }
                            </td> */}
                            <td className={`erp_table_td ${keyForViewUpdate === 'approve' ? '' : "text-end"}`}>
                                {keyForViewUpdate === 'approve' ?
                                    <input type="text"
                                        id={`product_material_approved_quantity_${chk_indent_source === 'S' ? indentItem.product_child_rm_id : indentItem.product_rm_id}`}
                                        className="erp_input_field mb-0 text-end approveField" value={indentItem.product_material_approved_quantity} onChange={(e) => { handleInputChange(indentItem, e); }}
                                        disabled={isApprove ? false : true}
                                        Headers='product_material_approved_quantity' />
                                    :
                                    indentItem.product_material_approved_quantity
                                }
                            </td>
                            <td className="erp_table_td text-end">{indentItem.product_material_rejected_quantity}</td>
                            <td className="erp_table_td">
                                {keyForViewUpdate === 'approve' ?
                                    <input type="text" id={`approval_remark_${indentItem.product_child_rm_id}_${indentItem.product_parent_fg_id}`}
                                        className="erp_input_field mb-0" value={indentItem.approval_remark} onChange={(e) => { handleInputChange(indentItem, e); }}
                                        onBlur={(e) => { handleInputChange(indentItem, e); }} disabled={isApprove ? false : true}
                                        Headers='approval_remark' />
                                    :
                                    indentItem.approval_remark
                                }

                                {/* <input type="text" id={`approval_remark_${indentItem.product_child_rm_id}_${indentItem.product_parent_fg_id}`}
                                    className="erp_input_field mb-0" value={indentItem.approval_remark} onChange={(e) => { handleInputChange(indentItem, e); }}
                                    onBlur={(e) => { handleInputChange(indentItem, e); }} disabled={isApprove ? false : true}
                                    Headers='approval_remark' /> */}
                            </td>
                            <td className="erp_table_td">
                                <select id={`indent_item_status_${indentItem.product_child_rm_id}`} value={indentItem.indent_item_status} disabled={(keyForViewUpdate !== 'approve')}
                                    className="form-select form-select-sm approveField" Headers='indent_item_status' onChange={(e) => { handleInputChange(indentItem, e); }}>
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Rejected</option>
                                    <option value="I">Partial Issue</option>
                                    <option value="C">Completed</option>
                                    <option value="X">Canceled</option>
                                    <option value="O">Purchse Order Genreated</option>
                                    <option value="POA">Purchse Order Approved</option>
                                    <option value="G">Goods Receipts</option>
                                </select></td>
                            <td className="erp_table_td text-end" >{indentItem.product_fg_material_quantity}</td>
                            <td className="erp_table_td" >{indentItem.product_child_rm_drawing_no}</td>
                            <td className="erp_table_td">{indentItem.product_child_rm_oem_part_code}</td>
                            <td className="erp_table_td">{indentItem.product_child_rm_our_part_code}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_reserve_quantity}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_return_quantity} </td>
                            <td className="erp_table_td text-end">{indentItem.product_rm_std_weight}</td>
                            <td className={`erp_table_td text-end`}>{validateNumberDateInput.current.decimalNumber(indentItem.indent_weight, 4)}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_approved_weight}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_rejected_weight}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(indentItem.product_fg_material_weight, 4)}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_reserve_weight}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_stock_weight}</td>
                            <td className="erp_table_td text-end">{indentItem.product_material_return_weight} </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [addfgProductData, keyForViewUpdate])

    //---------------------------------------------------------Print Functionality Starts--------------------------------------------------------
    //Purchase Requisition Print Start
    const purchaseRequisitionContent = {
        company: {
            company: '',
            company_branch: '',
        },
        customerdetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        indentdetails: {
            indent_no: '',
            indent_date: '',
            indent_version: '',
            indent_type: '',
        },
        header: {
            indent_source: '',
            department_name: '',
            indented_by: '',
            customer_name: '',
            customer_order_no: '',
            customer_order_date: '',
            approved_by_name: '',
            approved_date: '',
            remark: '',
        },
        footer: {
            indented_by_name: '',
            approved_by_name: '',

            sumofStockQuantity: '',
            sumIndentQuantity: '',
            sumofApprovedQuantity: '',
            sumofRejectedQuantity: '',

        },
        items: [],
    };
    const printInvoice = async (openPrintModal) => {

        // Define purchaseRequisitionContent within the printInvoice function
        fin_year = idList.financial_year;
        ind_version = idList.indent_version;
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnShowAllDetailsAndMastermodelRecords/${ind_version}/${fin_year}/${COMPANY_ID}?indent_no=${idList.indent_no}`)
        const response = await apiCall.json();
        console.log("Response : ", response);
        let indentMaterMasterData = response.IndentMasterRecord;
        let indentMaterDetailsData = response.IndentDetailsRecords;


        //indent details
        purchaseRequisitionContent.indentdetails.indent_no = txt_indent_no
        purchaseRequisitionContent.indentdetails.indent_date = validateNumberDateInput.current.formatDateToDDMMYYYY(indentMaterMasterData.indent_date)
        purchaseRequisitionContent.indentdetails.indent_version = txt_indent_version
        // purchaseRequisitionContent.indentdetails.indent_type =  $('#cmb_indent_type').find(":selected").text()


        //Company details
        purchaseRequisitionContent.company.company = indentMaterMasterData.company_name
        purchaseRequisitionContent.company.company_branch = indentMaterMasterData.company_branch_name

        //Customer details
        purchaseRequisitionContent.customerDetails.customer = indentMaterMasterData.customer_name
        purchaseRequisitionContent.customerDetails.address = indentMaterMasterData.cust_branch_address1
        purchaseRequisitionContent.customerDetails.state = indentMaterMasterData.customer_state_name
        purchaseRequisitionContent.customerDetails.contact = indentMaterMasterData.cust_branch_phone_no
        purchaseRequisitionContent.customerDetails.email = indentMaterMasterData.cust_branch_EmailId

        // header Details
        purchaseRequisitionContent.header.indent_source = indentMaterMasterData.indent_source_desc
        purchaseRequisitionContent.header.department_name = indentMaterMasterData.department_name
        purchaseRequisitionContent.header.indented_by = indentMaterMasterData.indented_by_name
        purchaseRequisitionContent.header.customer_name = indentMaterMasterData.customer_name
        purchaseRequisitionContent.header.customer_order_no = indentMaterMasterData.customer_order_no
        purchaseRequisitionContent.header.customer_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(indentMaterMasterData.customer_order_date)
        purchaseRequisitionContent.header.approved_by_name = indentMaterMasterData.approved_by_name
        purchaseRequisitionContent.header.approved_date = validateNumberDateInput.current.formatDateToDDMMYYYY(indentMaterMasterData.approved_date)
        purchaseRequisitionContent.header.remark = indentMaterMasterData.remark

        //footer details
        purchaseRequisitionContent.footer.indented_by_name = indentMaterMasterData.indented_by_name
        purchaseRequisitionContent.footer.approved_by_name = indentMaterMasterData.approved_by_name

        let sumStockQuantity = 0;
        let sumIndentQuantity = 0;
        let sumofApprovedQuantity = 0;
        let sumofRejectedQuantity = 0;

        for (let index = 0; index < indentMaterDetailsData.length; index++) {
            const element = indentMaterDetailsData[index];

            // Add product_material_stock_quantity to the sum
            sumStockQuantity += element.product_material_stock_quantity;
            sumIndentQuantity += element.product_material_quantity;
            sumofApprovedQuantity += element.product_material_approved_quantity;
            sumofRejectedQuantity += element.product_material_rejected_quantity;

            const detailsData = {
                sr_no: index + 1,
                product_rm_name: element.product_rm_name === undefined || element.product_rm_name === null ? "" : element.product_rm_name,
                material_tech_spect: element.product_rm_tech_spect === undefined || element.product_rm_tech_spect === null ? "" : element.product_rm_tech_spect,
                product_parent_fg_name: element.product_parent_fg_name === undefined || element.product_parent_fg_name === null ? "" : element.product_parent_fg_name,
                product_rm_oem_part_code: element.product_rm_oem_part_code === undefined || element.product_rm_oem_part_code === null ? "" : element.product_rm_oem_part_code,
                product_rm_our_part_code: element.product_rm_our_part_code === undefined || element.product_rm_our_part_code === null ? "" : element.product_rm_our_part_code,
                product_make_name: element.product_make_name === 'NA' || element.product_make_name === undefined || element.product_make_name === null ? "" : element.product_make_name,
                product_material_grade_name: element.product_material_grade_name === 'NA' || element.product_material_grade_name === undefined || element.product_material_grade_name === null ? "" : element.product_material_grade_name,

                product_material_stock_quantity: element.product_material_stock_quantity,
                lead_time: element.lead_time,
                unit: element.product_rm_stock_unit_name,
                indent_quantity: element.product_material_quantity,
                product_material_approved_quantity: element.product_material_approved_quantity,
                product_material_rejected_quantity: element.product_material_rejected_quantity,
            }
            purchaseRequisitionContent.items.push(detailsData)
            console.log(detailsData);
        }

        // Assign calculated values to footer
        purchaseRequisitionContent.footer.sumofStockQuantity = sumStockQuantity;
        purchaseRequisitionContent.footer.sumIndentQuantity = sumIndentQuantity;
        purchaseRequisitionContent.footer.sumofApprovedQuantity = sumofApprovedQuantity;
        purchaseRequisitionContent.footer.sumofRejectedQuantity = sumofRejectedQuantity;

        console.log('Sum of product_material_stock_quantity:', sumStockQuantity);
        console.log('Sum of sumIndentQuantity:', sumIndentQuantity);
        console.log('Sum of sumofApprovedQuantity:', sumofApprovedQuantity);
        console.log('Sum of sumofRejectedQuantity:', sumofRejectedQuantity);

        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: purchaseRequisitionContent, navigationLink: '/Transactions/TPurchaseOrder/PurchaseRequisition/PurchaseEntry/', invoiceType: 'PR', title: 'PurchaseRequisition(BOM Based)', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
        } else {
            // return the html code.
            return renderToString(<PurchaseRequisition invoiceContent={purchaseRequisitionContent} />);
        }
    };

    //---------------------------------------------------------Print Functionality End----------------------------------------------
    const refreshData = async (key) => {

        switch (key) {
            case 'get_employee':

                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department });
                globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: cmb_indented_by });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                const getEmployeesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setIndentedByIdOptions(getEmployeesApiCall)
                break;
            //get customer list
            case 'get_customer':
                const customerOrderIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                setCustomerOptions(customerOrderIdApiCall)
                break;
            default:
                break;
        }
    }

    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {

        switch (eventKey) {
            case 'purchaseOrderTerms':
                const checkedLength = $("input:checkbox[name=checkEnquiryTerms]:checked").length;
                if (checkedLength === 0) {
                    await FnShowPOTermsRecords();
                }
                break;
            case 'documentList':
                await FnShowDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }
    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    // Fn for delete requisition record
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentDetails/FnDeleteRecord/${txt_indent_version}/${COMPANY_ID}?indent_no=${txt_indent_no}&user_name=${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("indent entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                {/* <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td> */}
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = 'info'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = 'initial'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    // Fn for get document list
    const FnShowDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_indent_no !== 0 ? txt_indent_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }
    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.document_group,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }


    return (
        <>

            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'>
                                Indent (BOM Based){requestTypeHeading()}<span id="submain_heading">(Creation)</span></label>
                        </div>
                        <form id="purchaseRequisitionID">
                            <div className="row  py-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Indent Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_indent_type" className="form-select form-select-sm" value={cmb_indent_type} onChange={() => { comboOnChange('indentTypeId'); validateFields(); }} disabled={keyForViewUpdate === '' && requestfor === 'NA' ? false : true} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {indenttypeOptions.length !== 0 ? (
                                                    <>
                                                        {indenttypeOptions.map(indentTypeId => (
                                                            <option key={indentTypeId.field_id} value={indentTypeId.field_id}>
                                                                {indentTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_indent_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Indent No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_indent_no" className="erp_input_field" value={txt_indent_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_indent_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_indent_version' className="erp_input_field text-end" value={txt_indent_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_indent_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='txt_indent_date' className="erp_input_field" value={txt_indent_date} onChange={(e) => { setIndentDate(e.target.value); validateFields(); }} min={() => { today() }} />
                                                    <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Indent Source <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="So Based" type="radio" lbl="So Based" value="S" name="chk_indent_source" checked={chk_indent_source === "S"} onClick={() => { setIndentSource("S"); comboOnChange("so_based") }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Direct" type="radio" lbl="Direct" value="O" name="chk_indent_source" checked={chk_indent_source === "O"} onClick={() => { setIndentSource("O"); comboOnChange("Direct_based") }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer {chk_indent_source === "O" ? null : <span className="required">*</span>}</Form.Label>
                                        </div>
                                        <div className='col '>
                                            <select id="cmb_customer" className="form-select form-select-sm" value={cmb_customer} optional={chk_indent_source === "O" ? "optional" : ""}
                                                onChange={(e) => { setCustomerId(e.target.value); comboOnChange('customer'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerOptions?.map(customer => (
                                                    <option value={customer.field_id}>{customer.field_name}</option>

                                                ))}

                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={` ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer Order & Order on {chk_indent_source === "O" ? null : <span className="required">*</span>}</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} optional={chk_indent_source === "O" ? "optional" : ""}
                                                        onChange={(e) => { setCustomerOrderNo(e.target.value); comboOnChange('CustomerOrder'); validateFields(); }}>
                                                        <option value="">Select</option>
                                                        {customerOrdOpts.length !== 0 ?
                                                            <>
                                                                {customerOrdOpts?.map(order => (
                                                                    <option value={order.customer_order_no} ord_date={order.customer_order_Date} ord_qty={order.order_quantity}>{order.customer_order_no}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='customer_order_date' className="erp_input_field" value={customer_order_date} optional={chk_indent_source === "O" ? "optional" : ""} />
                                                    <MDTypography variant="button" id="error_customer_order_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Finish Goods </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_fgtype_part_code" className="form-select form-select-sm" value={cmb_fgtype_part_code} onChange={() => { comboOnChange('FgTypePartCode'); }} optional='optional'  >
                                                <option value="">Select</option>

                                                {fgtypepartcodeOptions?.map(customer => (
                                                    <option value={customer.product_parent_fg_id}>{customer.product_parent_fg_name}</option>

                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Order Quantity {chk_indent_source === "O" ? null : <span className="required">*</span>}</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='material_quantity' className="erp_input_field" value={order_quantity} onChange={e => { setOrderQuantity(e.target.value); comboOnChange('Order_quantity'); }} optional={chk_indent_source === "S" ? "optional" : ""} disabled />
                                            <MDTypography variant="button" id="error_material_quantity" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {departmentIdOPtions?.map(departmentId => (
                                                    <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub Department </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sub_department" className="form-select form-select-sm" value={cmb_sub_department} onChange={() => { comboOnChange('subDepartment'); validateFields(); }} optional='optional'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {subDepartmentOPtions?.map(departmentId => (
                                                    <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sub_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Cost Center: <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select size="sm" id="cmb_cost_center" value={cmb_cost_center} className="form-select form-select-sm erp_input_field" onChange={() => { comboOnChange('cost_center'); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {cost_centerOptions?.map(option => (
                                                    <option value={option.field_id} centerShortName={option.cost_center_short_name}>[{option.cost_center_short_name}] {option.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Indented By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col '>
                                            <select id="cmb_indented_by" className="form-select form-select-sm" value={cmb_indented_by} onChange={() => { comboOnChange('identedById'); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {indentedByIdOptions?.map(identedById => (
                                                    <option value={identedById.field_id}>{identedById.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_indented_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_employee') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {(keyForViewUpdate === 'view' && chk_indent_status === 'A' || keyForViewUpdate === 'approve') ? (
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Approved By</Form.Label>
                                                </div>
                                                <div className='col'>

                                                    <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                                                        <option value="">Select</option>
                                                        {approvedByOptions.length !== 0 ?
                                                            <>
                                                                {approvedByOptions?.map(cmb_approved_by_id => (
                                                                    <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-sm-3">
                                                    <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                    <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                    ) : null
                                    }
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Schedule Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='txt_schedule_date' className="erp_input_field" value={txt_schedule_date} onChange={(e) => { setScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_txt_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Indent Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_indent_status" className="form-select form-select-sm" value={chk_indent_status} onChange={(e) => { setIndentStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="R">Rejected</option>
                                                <option value="I">Partial Issue</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">

                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" lbl="Yes" type="radio" value="true" name="chk_isactive" checked={chk_isactive === true} onClick={() => { setIsActive(true); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" lbl="No" type="radio" value="false" name="chk_isactive" checked={chk_isactive === false} onClick={() => { setIsActive(false); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <Modal size="lg" className='erp_document_Form' show={showSChedulesRm} onHide={handleCloseSchedulesPopupRm} backdrop="static" keyboard={false} centered>
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>Raw Material Filter</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopupRm}></button></span>
                        </Modal.Header>
                        <Modal.Body>
                            <React.Fragment>
                                <div className='row' id='modal-id' style={{ display: 'none' }} >
                                    <dl class="row">
                                        <dt className="col-sm-3 erp-form-label-md-lg">Product Fg Id : {cmb_fgtype_part_code}</dt>
                                        <dd className="col-sm-9 erp-form-label" id='productMatName'></dd>
                                        <dt className="col-sm-3 erp-form-label-md-lg">Product Fg Name : {selectedText}</dt>
                                        <dd className="col-sm-9 erp-form-label" id='productMatName'></dd>
                                        <dt className="col-sm-3 erp-form-label-md-lg" id='checkAll'> <input type="checkbox" name="selectAllFGMaterials" className="erp-form-label pb-1" label="Select All" id="selectAllFGMaterials" onClick={(e) => toggleFGMaterialChkBoxes('selectAllFGMaterials')} /> Select All</dt>

                                        <Datatable data={data} columns={columns} />

                                        <div className="erp_frm_Btns bomFilterData"  >
                                            <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" id="Add-Button" onClick={FnApplyFilterNShowData}>
                                                Add
                                            </MDButton>
                                        </div>
                                    </dl>
                                </div>
                                <div style={{ display: 'none' }} id="no_records_msg_id"><span className="page_entries">No Records Found....</span></div>
                            </React.Fragment>
                        </Modal.Body>
                    </Modal>

                    <Modal size="lg" className='erp_document_Form' show={showSChedules} onHide={handleCloseSchedulesPopup} backdrop="static" keyboard={false} centered>
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>Add Schedules</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopup}></button></span>
                        </Modal.Header>
                        <Modal.Body>
                            <dl className="row mb-0">
                                <div className="col-md-8 col-sm-12">
                                    <dl className="row mb-0">
                                        <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                        <dd className="col-sm-12 col-md-9 erp-form-label" id='productMatName'></dd>
                                    </dl>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <dl className="row mb-0">
                                        <dt className="col-sm-12 col-md-6 erp-form-label-md-lg"> Total Quantity:</dt>
                                        <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatQty'></dd>
                                    </dl>
                                </div>

                            </dl>

                            <dl className="row mb-0">
                                <div className="col-md-8 col-sm-12">
                                    <dl className="row mb-0">
                                        <dt className="col-sm-6 col-md-3 erp-form-label-md-lg"> Material Unit:</dt>
                                        <dd className="col-sm-6 col-md-9 erp-form-label" id='productMatUnit'></dd>  </dl>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <dl className="row mb-0">
                                        <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">Total Weight:</dt>
                                        <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatTotalWeight'></dd>
                                    </dl>
                                </div>
                            </dl>

                            <dl className="row mb-0">
                                <div className="col-md-8 col-sm-12">
                                    <dl className="row mb-0">
                                        <dt className="col-md-3 col-sm-12 erp-form-label-md-lg">Std. Weight:</dt>
                                        <dd className="col-md-9 col-sm-12  erp-form-label" id='productMatStdWeight'></dd>
                                    </dl>
                                </div>
                            </dl>


                            <div className='row table-responsive'>
                                <Table className="erp_table" id="scheduleTable" bordered striped >
                                    <thead className='erp_table_head erp_table_th'>
                                        <tr>
                                            <th className="col-2">Action</th>
                                            <th>Schedule Quantity</th>
                                            <th>Schedule Weight</th>
                                            <th>Expected Schedule Date</th>
                                            <th>Material Receipt Quantity</th>
                                            <th>Material Receipt Weight</th>
                                            <th>Material Receipt Date</th>
                                            <th>Indent Item Status</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>

                                    <tbody role="rowgroup">
                                        <tr scheduleTrCount={scheduleRowCount} id="RMSchedulesEntry-tr-1">
                                            <td style={{ width: '100px' }}><IoRemoveCircleOutline className='erp_trRemove_icon' /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewScheduleRow()} /></td>
                                        </tr>

                                    </tbody>


                                </Table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='erp-modal-footer'>
                            <MDButton type="button" className="erp-gb-button" variant="button"
                                fontWeight="regular" onClick={() => schedule()}>Schedule</MDButton>
                        </Modal.Footer>
                    </Modal>


                    {/* </div> */}



                    {/* Document modal */}
                    <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                        </Modal.Header>
                        <Modal.Body>
                            <DocumentF group_id={txt_indent_no !== 0 ? txt_indent_no.replaceAll('/', '_') : null} document_group={docGroup} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Add new Record Popup */}
                    <Modal size="lg" show={showAddRecIndentModal} onHide={closeRecordAddIndentModal} backdrop="static" keyboard={false} centered >
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentModal}></button></span>
                        </Modal.Header>
                        <Modal.Body className='erp_city_modal_body'>
                            {displayRecordIndentComponent()}
                        </Modal.Body>
                        <Modal.Footer>
                            <MDButton type="button" onClick={closeRecordAddIndentModal} className="btn erp-gb-button" variant="button"
                                fontWeight="regular">Close</MDButton>

                        </Modal.Footer>
                    </Modal >

                    <hr />
                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="erp-form-label-md">Raw Matreial Filter</Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex row">
                                    <div className="col-sm-6 d-flex">
                                        <Form.Control type="text" id="txt-detail-data-highliter" className="erp_input_field txt-filter-bom showData" />
                                        <MDButton type="button" className="erp-gb-button erp_MLeft_btn showData" variant="button" onClick={() => scrollToTableRow()}
                                            fontWeight="regular">Search</MDButton>
                                    </div>
                                    <div className="col-sm-6 align-self-end pt-1">
                                        <div className='d-flex justify-content-end'>
                                            <MDButton type="button" id="export_btn_id" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button" onClick={ExportToExcel}
                                                fontWeight="regular" >Export</MDButton>
                                            <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                            <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} disabled={indent_master_id !== 0 ? 'disabled' : ''}>Import</MDButton>
                                        </div>
                                    </div>
                                </div>
                                {addfgProductData.length !== 0 ?
                                    <>
                                        <div className="erp-Mt-10">
                                            <div className='row'>
                                                {renderDetailsTable}
                                                {scheduleDataArray.length !== 0 ?
                                                    <>
                                                        <hr />
                                                        <Accordion defaultActiveKey="1" >
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header className="erp-form-label-md">Material Schedules</Accordion.Header>
                                                                <Accordion.Body className="p-0">
                                                                    {renderScheduleTable}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </> : ""
                                                }
                                            </div>
                                        </div> </>
                                    : ""}

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <hr />
                    <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData} >
                        <Accordion.Item eventKey="purchaseOrderTerms">
                            <Accordion.Header className="erp-form-label-md">Purchase Order Terms</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <div className="col-12 col-sm-6">
                                        <input type='checkbox' class="" id="selectAllPOTerms" onClick={(e) => checkBoxesSelection('selectAllPOTerms')} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} /><label class="erp-form-label p-1 me-2"> Select All </label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                                            onClick={() => {
                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                setHeaderName('Common Parameter'); // CommonParamterEntry
                                                setShowAddRecIndentModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                            }}>Add
                                        </MDButton>
                                    </div>
                                </div>
                                <div className='row '>
                                    {renderPOTermsTable}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {keyForViewUpdate !== '' ?
                        <>
                            <hr />
                            <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                                <Accordion.Item eventKey="documentList">
                                    <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                    <Accordion.Body>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row'>
                                                <div className="col-12 text-center">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </> : null
                    }
                    <hr />
                    <div className="erp_frm_Btns">
                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                        <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => { addRequisition(!isApprove ? 'ADD' : 'A') }}
                            fontWeight="regular">Save</MDButton>
                        <MDButton type="button" id="reject-btn" className={`erp-gb-button erp_MLeft_btn ${isApprove ? 'display' : 'd-none'}`} variant="button" onClick={() => addRequisition('R')}>Rejected</MDButton>
                        {/* <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? chk_indent_status === 'POA' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id='printbutton-id'
                    onClick={() => { printInvoice(true); }}  >Print Invoice<FiDownload className="erp-download-icon-btn" /></MDButton> */}
                        <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? chk_indent_status !== 'P' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id='printbutton-id'
                            onClick={() => { printInvoice(true); }}  >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                        <MDButton className={`erp-gb-button erp_MLeft_btn ${indent_master_id === 0 || indent_master_id === '' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm} disabled={indent_master_id === 0 ? 'disabled' : ''}>Upload Document</MDButton>&nbsp;
                        <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" id='clearbutton-id' onClick={FnclearFormFields} fontWeight="regular" disabled={indent_master_id !== 0 && keyForViewUpdate !== 'update' ? 'disabled' : ''}>Clear</MDButton>
                        <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>

                    </div >

                    <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                    <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, messageForConfirmation, modalOrderDetails]} />
                    <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                    {/* <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} /> */}

                    {/* Delete Modal */}
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                        <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                        <Modal.Body className='erp_modal_body'>
                            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                            <h6>Are you sure?</h6>
                            <div className="erp-form-label">Do you wish to delete this record ?</div>
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center'>
                            <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                                Cancel
                            </Button>&nbsp;
                            <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            </DashboardLayout>
        </>
    )
} export default FrmPurchaseRequisitionEntry


