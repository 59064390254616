
import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// import react icons
import { useNavigate, useLocation } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import FrmMProductCategory1Entry from 'Masters/MProductCategory1/FrmProductCategory1Entry';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ConfigConstants from "assets/Constants/config-constant";


export default function FrmMProductCategory2Entry(props) {
  const { state } = useLocation();
  const { productcategory2ID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};


  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;

  // For navigate
  const navigate = useNavigate();
  const comboDataAPiCall = useRef();
  const validate = useRef();


  // combobox Option
  const [cat2ProductCategory1Options, setCat2ProductCategory1Options] = useState([])
  const [cat2ProductTypeOptions, setCat2ProductTypeOptions] = useState([]);

  // Add Product Type Form Fields
  const [product_category2_ID, setCat2ProductCategory2Id] = useState(productcategory2ID)
  const [cmb_cat2_product_category1_id, setCat1ProductCategory1Id] = useState('');
  const [cmb_cat2_product_type_id, setCat2ProductTypeId] = useState();
  const [txt_cat2_product_category2_name, setCat2ProductCategory2] = useState('');
  const [txt_cat2_product_category2_short_name, setCat2ProductCategory2ShortName] = useState('');
  const [txt_cat2_remark, setCat2Remark] = useState('');
  const [actionType, setActionType] = useState('')


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  const [showAddRecCat2, setShowAddRecCat2Modal] = useState(false);
  const [cat2ModalHeaderName, setCat2HeaderName] = useState('')


  // Show ADd record Modal
  const handleCloseRecCat2Modal = async () => {
    switch (cat2ModalHeaderName) {
      case 'Product Type':
        // comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypes) => {
        //   setCat2ProductTypeOptions(productTypes)
        // })
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_type"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const productTypes = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setCat2ProductTypeOptions(productTypes)

        break;

      case 'Product Category-1':
        const productTpVal = document.getElementById('cmb_cat2_product_type_id').value;

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_category1"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const productCategory1 = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setCat2ProductCategory1Options(productCategory1)

        // comboDataAPiCall.current.fillMasterData("smv_product_category1", "product_type_id", '').then((productCategory1) => {
        //   setCat2ProductCategory1Options(productCategory1);
        // })
        break;

      default:
        return null;
    }
    setShowAddRecCat2Modal(false);
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
    sessionStorage.removeItem('dataAddedByCombo')
  }



  useEffect(async () => {
    ActionType();
    await fillComobos();
    if (product_category2_ID !== 0) {
      await FnCheckUpdateResponce();
    }

  }, [])


  const fillComobos = async () => {
    // comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypes) => {
    //   setCat2ProductTypeOptions(productTypes)
    // })

    resetGlobalQuery();
    globalQuery.columns.push("field_id");
    globalQuery.columns.push("field_name");
    globalQuery.table = "smv_product_type"
    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    const productTypes = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
    setCat2ProductTypeOptions(productTypes)


  }

  const FnAddUpdateCategory2 = async () => {
    debugger
    try {
      const checkIsValidate = await validate.current.validateForm("productcategory2FormId");;

      if (checkIsValidate) {
        var active;
        var activeValue = document.querySelector('input[name=isactive]:checked').value
        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
        }

        const data = {
          company_id: COMPANY_ID,
          product_category2_id: product_category2_ID,
          created_by: UserName,
          modified_by: product_category2_ID === 0 ? null : UserName,
          product_category1_id: cmb_cat2_product_category1_id,
          product_type_id: cmb_cat2_product_type_id,
          product_category2_name: txt_cat2_product_category2_name,
          product_category2_short_name: txt_cat2_product_category2_short_name.trim(),
          remark: txt_cat2_remark,
          is_active: active
        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory2/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();
        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };
  const clearFormFields = () => {
    setCat2ProductTypeId('');
    setCat1ProductCategory1Id('');
    setCat2ProductCategory2('');
    setCat2ProductCategory2ShortName('');
    setCat2Remark('');

  }

  const FnCheckUpdateResponce = async () => {
    debugger
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory2/FnShowParticularRecordForUpdate/${product_category2_ID}`)
      const updateRes = await apiCall.json();

      let resp = JSON.parse(updateRes.data)
      setCat2ProductCategory2Id(resp.product_category2_id)
      setCat2ProductTypeId(resp.product_type_id);
      await comboOnChange('productType')
      setCat1ProductCategory1Id(resp.product_category1_id);
      await comboOnChange('productCategory1');
      setCat2ProductCategory2(resp.product_category2_name);
      setCat2ProductCategory2ShortName(resp.product_category2_short_name);
      setCat2Remark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }

      switch (keyForViewUpdate) {
        case 'update':
          $('#saveBtn').attr('disabled', false);
          $('#cmb_cat2_product_type_id').attr('disabled', true);
          $('#cmb_cat2_product_category1_id').attr('disabled', true);
          $('#txt_cat2_product_category2_short_name').attr('disabled', true);
          $('#txt_cat2_product_category2_name').attr('disabled', true);
          break;
        case 'view':
          $("input[type=radio]").attr('disabled', true);
          await validate.current.readOnly("productcategory2FormId");

          break;
      }
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        const productTpVal = document.getElementById('cmb_cat2_product_type_id').value;
        setCat2ProductTypeId(productTpVal)
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setCat2HeaderName('Product Type')
          setShowAddRecCat2Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)

        } else {
          if (productTpVal !== null || productTpVal !== '') {
            $('#error_cmb_cat2_product_type_id').hide();

            // comboDataAPiCall.current.fillMasterData("smv_product_category1", "product_type_id", '').then((productCategory1) => {
            //   setCat2ProductCategory1Options(productCategory1);
            // })
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "smv_product_category1"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productCategory1 = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setCat2ProductCategory1Options(productCategory1)
          }
        }
        break;

      case 'productCategory1':
        var productCat1Val = document.getElementById('cmb_cat2_product_category1_id').value;
        setCat1ProductCategory1Id(productCat1Val)
        if (productCat1Val === "0") {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setCat2HeaderName('Product Category-1')
          setShowAddRecCat2Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");

          }, 200)

        } else {
          if (productCat1Val !== "") {
            $('#error_cmb_product_category1_id').hide();
          }
        }
        break;
    }
  }

  const cat2DisplayRecordComponent = () => {
    switch (cat2ModalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled />;

      case 'Product Category-1':
        return <FrmMProductCategory1Entry btn_disabled />;

      default:
        return null;
    }
  }



  const validateFields = () => {
    validate.current.validateFieldsOnChange('productcategory2FormId')
  }

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductCategory2Listing`);
    }
  }


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Create)');
        break;
    }

  };


  return (
    <>

      <div className='erp_top_Form'>
        <FrmValidations ref={validate} />
        <ComboBox ref={comboDataAPiCall} />

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Product Category 2 {actionType} </label>
          </div>
          <form id="productcategory2FormId">
            <div className='row erp_transporter_div'>
              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat2_product_type_id" className="form-select form-select-sm" value={cmb_cat2_product_type_id} onChange={(e) => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {cat2ProductTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_cat2_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category-1</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_cat2_product_category1_id" className="form-select form-select-sm" value={cmb_cat2_product_category1_id} onChange={(e) => comboOnChange('productCategory1')} optional="optional">
                      <option value="">Select</option>
                      <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                      {cat2ProductCategory1Options?.map(productCategory1 => (
                        <option value={productCategory1.field_id}>{productCategory1.field_name}</option>
                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Category-2</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_cat2_product_category2_name" className="erp_input_field" value={txt_cat2_product_category2_name} onChange={e => { setCat2ProductCategory2(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_cat2_product_category2_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


              </div>

              {/* second row */}

              <div className="col-sm-6 erp_form_col_div">
                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_cat2_product_category2_short_name" className="erp_input_field" value={txt_cat2_product_category2_short_name} onChange={e => { setCat2ProductCategory2ShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" optional="optional" />
                    <MDTypography variant="button" id="error_product_category2_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_cat2_remark" value={txt_cat2_remark} onChange={e => { setCat2Remark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_cat2_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductCategory2Listing/reg' : '/Masters/ProductCategory2Listing';
                navigate(path);
              }} variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}
            >Back</MDButton>
            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={FnAddUpdateCategory2} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}
          </div >
        </div>

        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecCat2} onHide={handleCloseRecCat2Modal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecCat2Modal}></button></div>
            </div>
            {cat2DisplayRecordComponent()}
          </Modal.Body>

        </Modal >



      </div>

    </>
  )
}
