
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import Accordion from "react-bootstrap/Accordion";
import { Button, Modal, Table } from 'react-bootstrap';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { FaDatabase } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import ComboBox from 'Features/ComboBox';
import MDTypography from 'components/MDTypography';
import TGenericAddmaterialProductionfrm from 'Transactions/TSizingProduction/TGenericAddmaterialProductionfrm';

function FrmWInspMaterialMovementEntry({ onGetInspectionMaterialData, inspMaterialData, productionDateopt, subSectionVal, ActionLabel, ProductionWInspStatus }) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
  const { state } = useLocation();
  const { keyForViewUpdate } = state || {}

  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  //useRef Hooks
  const comboDataAPiCall = useRef();
  const validateNumberDateInput = useRef();
  const validate = useRef();
  const [actionLabel, setActionLabel] = useState('Save')

  // Fields for consumption qty
  const [actionType, setActionType] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  // Consumption modal
  // Session Expired modal
  const [materialStockList, setMaterialStockList] = useState([])
  const [show, setShow] = useState(false);
  const closeConsumptionModal = () => setShow(false)

  // Fields for consumption qty
  const [materialName, setMaterialName] = useState()
  const [unitName, setUnitName] = useState()
  const [consumptionQty, setConsumptionQty] = useState()

  //Inspection Production master hooks
  const [subsection_id, SetSubsection_id] = useState(subSectionVal);

  const [dt_inspection_production_date, setInspProductionDate] = useState(today);
  const [warpingOrderNo, setWarpingOrderNO] = useState('');
  const [setNo, setsetNO] = useState('');
  const [RowIndexNo, setRowIndex] = useState('');

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  //Inspection Production  details comboboxes
  const [inspectionMaterialData, setInspectionMaterialData] = useState(inspMaterialData !== null && inspMaterialData.length !== 0 ? inspMaterialData : [])
  const [rowCount, setRowCount] = useState(1)
  const [setNoOptions, setSetNoOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);


  const inspectionMaterialBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    inspection_production_date: today(),
    inspection_production_set_no: 0,
    shift: '',
    inspection_production_order_no: '',
    status_remark: '',
    material_status: 'P',
    weaving_production_inspection_material_id: 0,
    product_inspection_material_balance_quantity: 0,
    product_material_id: "",
    product_material_unit_id: 0,
    consumption_quantity: 0,
    product_material_quantity: 0,
    created_by: UserName,
  }

  useEffect(() => {

    const loadDataOnload = async () => {
      setIsLoading(true)
      await ActionType()
      await fillComboBox();

      if (inspMaterialData.length === 0) {
        const getExistingInspProductionData = [...inspectionMaterialData]
        getExistingInspProductionData.push(inspectionMaterialBlankObject)
        setInspectionMaterialData(getExistingInspProductionData)
        onGetInspectionMaterialData(getExistingInspProductionData)
      }
      setIsLoading(false)
    }
    loadDataOnload()
  }, [])

  useEffect(async () => {
    const FunctionCall = async () => {
      setInspProductionDate(productionDateopt);

      if (inspMaterialData.length !== 0) {
        setInspectionMaterialData(inspMaterialData);
        await Promise.all(inspMaterialData.map(async (Item, index) => {
          return renderMaterialNameList(Item.inspection_production_set_no, index);
        }));
      }
    };
    FunctionCall();
  }, [productionDateopt]);


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setIsApprove(false);
        setActionType('(Modification)');
        setActionLabel('Update')
        break;
      case 'view':
        setIsApprove(false);
        setActionType('(View)');
        await validate.current.readOnly("inspectionMaterialTbl");
        $("table").find("input,button,textarea,select").attr("disabled", "disabled");

        break;
      case 'approve':
        setActionType('(Approve)');
        setActionLabel('Approve')
        $("form").find("input,textarea,select").attr("disabled", "disabled");
        $("table").find("input,button,textarea,select").attr("disabled", "disabled");
        $('table .approveField').removeAttr("disabled");
        setIsApprove(true)
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };

  const fillComboBox = async () => {
    try {
      const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
      setShiftOptions(shiftsApiCall);

      resetGlobalQuery();
      globalQuery.columns.push('*');
      globalQuery.table = "xtv_weaving_production_order";
      globalQuery.conditions.push({ field: "weaving_order_status", operator: "=", value: 'A', });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const SectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setSetNoOptions(SectionApiCall);
      // resetGlobalQuery();
      // globalQuery.columns.push('*');
      // globalQuery.table = "xtv_weaving_production_loom_details";
      // globalQuery.conditions.push({ field: "loom_production_master_status", operator: "=", value: 'A', });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      // const SectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      // setSetNoOptions(SectionApiCall);
    } catch (error) {
      console.log("error: ", error)
    }
  }


  const renderMaterialNameList = async (setno, index) => {

    let MaterialObject = document.getElementById(`product_material_id_${index}`);

    MaterialObject.innerHTML = '<option value="">Select</option>';
    MaterialObject.insertAdjacentHTML('beforeend', '<option value="0">Add New Record +</option>');

    resetGlobalQuery();
    globalQuery.columns = ['stock_quantity', 'product_material_name', 'product_material_id', 'product_inspection_material_balance_quantity']
    globalQuery.table = "xtv_weaving_production_material";
    globalQuery.conditions.push({ field: "production_sub_section_name", operator: "=", value: 'Inspection (Weaving)', });
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "set_no", operator: "=", value: setno, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

    const materialApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    FnFillMaterialOptions(materialApiCall, MaterialObject)
  }

  const FnUpdateInspMaterialMovementRecords = async (currentProductTypeGroup, e, index) => {

    let clickedColName = e.target.getAttribute('Headers');
    delete e.target.parentElement.dataset.tip;
    let enteredValue = e.target.value;

    const updatePtGroupData = [...inspectionMaterialData]

    switch (clickedColName) {
      case 'inspection_production_date':
        currentProductTypeGroup[clickedColName] = enteredValue;
      break;

      case 'inspection_production_set_no':
        currentProductTypeGroup.product_material_quantity = 0
        currentProductTypeGroup.consumption_quantity = 0
        currentProductTypeGroup.product_inspection_material_balance_quantity = 0
        currentProductTypeGroup.product_material_unit_name = ''
        if (enteredValue !== '') {
          currentProductTypeGroup[clickedColName] = enteredValue;
          setsetNO(enteredValue)
          await renderMaterialNameList(enteredValue, index)
          setRowIndex(index)
          const selectedSetNO = setNoOptions.find(setNo => setNo.set_no === enteredValue);
          currentProductTypeGroup.set_no = selectedSetNO.set_no
          setWarpingOrderNO(selectedSetNO.set_no)

        } else {
          currentProductTypeGroup[clickedColName] = enteredValue;
          currentProductTypeGroup.inspection_production_order_no = 0
          currentProductTypeGroup.product_material_quantity = 0
          currentProductTypeGroup.product_material_unit_name = ''
          currentProductTypeGroup.product_material_unit_id = 0
          currentProductTypeGroup.product_material_id = ''
        }
        break;


      case 'product_material_id':

        const combinationExists = inspectionMaterialData.find(item =>
          item.product_material_id === enteredValue &&
          item.inspection_production_set_no === currentProductTypeGroup.inspection_production_set_no &&
          item.shift === currentProductTypeGroup.shift
        );
        if (combinationExists) {
          e.target.parentElement.dataset.tip = 'Shift and set number combination already in use. Please select a different combination...!';
          currentProductTypeGroup[clickedColName] = "";
          break;
        }
        else {
          if (e.target.value === '0') {
            setHeaderName('Warping Order')
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css("padding-top", "0px");
            }, 200)
          } else {
            currentProductTypeGroup[clickedColName] = e.target.value;

            const availableQty = FnConsumptionChange(e.target.value, index, updatePtGroupData)
            currentProductTypeGroup['stock_quantity'] = availableQty
            // currentProductTypeGroup['stock_quantity'] = availableQty.remainingQty
            // currentProductTypeGroup['product_inspection_material_balance_quantity'] = availableQty.remainingBalanceQty
            let setNO = currentProductTypeGroup.inspection_production_set_no;
            resetGlobalQuery();
            globalQuery.columns.push('*');
            globalQuery.table = "xtv_weaving_production_material";
            globalQuery.conditions.push({ field: "production_sub_section_name", operator: "=", value: 'Inspection (Weaving)', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: enteredValue, });
            globalQuery.conditions.push({ field: "set_no", operator: "=", value: setNO, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const productMaterialNameApicall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const selectedMaterialName = productMaterialNameApicall.find(material => material.product_material_id === enteredValue);

            currentProductTypeGroup.product_material_quantity = selectedMaterialName.product_material_quantity
            currentProductTypeGroup.product_inspection_material_balance_quantity = selectedMaterialName.product_inspection_material_balance_quantity
            currentProductTypeGroup.product_material_unit_name = selectedMaterialName.product_material_unit_name
            currentProductTypeGroup.product_material_unit_id = selectedMaterialName.product_material_unit_id
            currentProductTypeGroup.product_material_id = selectedMaterialName.product_material_id

            const selectedMaterialEntry = inspectionMaterialData.find(entry => entry.product_material_id === enteredValue);
            if (selectedMaterialEntry) {
              const totalProductQuantity = selectedMaterialEntry.product_inspection_material_balance_quantity;
              const entriesWithSameSetNo = inspectionMaterialData.filter(entry => entry.inspection_production_set_no === selectedMaterialName.set_no);

              // Calculate total consumption quantity
              const totalConsumptionQuantity = entriesWithSameSetNo.reduce((total, entry) => {
                return total + parseInt(entry.consumption_quantity);
              }, 0);

              // Check if there are duplicates in inspectionMaterialData    
              const existingEntry = inspectionMaterialData.find(entry => entry.inspection_production_set_no === selectedMaterialName.set_no);
              if (existingEntry) {
                currentProductTypeGroup.product_inspection_material_balance_quantity = totalProductQuantity - totalConsumptionQuantity;
                // If the balance quantity is negative, set it to 0
                if (currentProductTypeGroup.product_inspection_material_balance_quantity < 0) {
                  currentProductTypeGroup.product_inspection_material_balance_quantity = 0;
                }
              }
            }
          }
        }
        break;

      case 'shift':
        delete e.target.parentElement.dataset.tip;
        if (e.target.value !== '') {
          const combinationExists = inspectionMaterialData.find(item =>
            item.shift === e.target.value && item.inspection_production_set_no === currentProductTypeGroup.inspection_production_set_no
            && item.product_material_id === currentProductTypeGroup.product_material_id
          );
          if (combinationExists) {
            e.target.parentElement.dataset.tip = 'Shift and set number combination already in use. Please select a different combination...!';
            currentProductTypeGroup[clickedColName] = "";
            break;
          } else {
            currentProductTypeGroup[clickedColName] = e.target.value
          }
        } else {
          currentProductTypeGroup[clickedColName] = e.target.value;
        }
        break;

      case 'consumption_quantity':

        if (e.target.value !== '0') {
          if (e.target.value > currentProductTypeGroup.product_inspection_material_balance_quantity) {
            e.target.parentElement.dataset.tip = 'Consumption Quantity cant exceed Balance Quantity material Qunatity...!'
            currentProductTypeGroup[clickedColName] = validateNumberDateInput.current.decimalNumber((currentProductTypeGroup.product_inspection_material_balance_quantity).toString(), 4);
            setTimeout(function () {
              delete e.target.parentElement.dataset.tip;
            }, 3000);
          } else {
            currentProductTypeGroup[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
            if (e._reactName === 'onBlur') {
              const remainingQty = FnConsumptionChange(currentProductTypeGroup.product_material_id, index, updatePtGroupData)
              if (remainingQty < 0) {
                e.target.parentElement.dataset.tip = 'Consumption Quantity cannot be greater than stock qunatity...!'
                return;
              }
            }
            delete e.target.parentElement.dataset.tip;
          }
        }
        break;
      case 'material_status':
        currentProductTypeGroup[clickedColName] = e.target.value;
        delete e.target.parentElement.dataset.tip;
        break
      default:
        break;
    }

    // const updatePtGroupData = [...inspectionMaterialData]
    const productTypeIndexArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
    updatePtGroupData[productTypeIndexArray] = currentProductTypeGroup;
    setInspectionMaterialData(updatePtGroupData);
    onGetInspectionMaterialData(updatePtGroupData)
    fnManageWeavingInspStatus(updatePtGroupData);
  }

  const FnConsumptionChange = (product_material_id, index, updatePtGroupData) => {
    const object = document.getElementById(`product_material_id_${index}`)
    const selectedOption = object.options[object.selectedIndex]; // object is your select element
    const initialAvailableQty = selectedOption.getAttribute('stock_quantity');
    // const initialAvailableBalanceQty = selectedOption.getAttribute('product_inspection_material_balance_quantity');

    const totalConsumedQty = updatePtGroupData.filter(item => item.product_material_id === product_material_id).reduce((acc, curr) => {
      const consumptionQty = parseFloat(curr.consumption_quantity) || 0; // Parse as float or default to 0 if it's not a valid number
      return acc + consumptionQty;
    }, 0);
    const remainingQty = parseFloat(initialAvailableQty) - totalConsumedQty;
    // const remainingBalanceQty = parseFloat(initialAvailableBalanceQty) - totalConsumedQty;
    updatePtGroupData.filter(item => item.product_material_id === product_material_id)
      .forEach(item => {
        // item.product_inspection_material_balance_quantity = remainingBalanceQty
        item.stock_quantity = remainingQty

      })
    return remainingQty;
    // return { remainingQty, remainingBalanceQty };
  };

  const fnManageWeavingInspStatus = (inspectionMaterialData) => {
    if (keyForViewUpdate === 'approve') {
      const allApproved = inspectionMaterialData.every(item => item.material_status === 'A');
      const someRejected = inspectionMaterialData.some(item => item.material_status === 'R');

      if (allApproved) {
        ProductionWInspStatus('A');
        ActionLabel('Approve');
      } else if (someRejected) {
        ProductionWInspStatus('R');
        ActionLabel('Rejected');
      } else {
        const item = inspectionMaterialData.find(item => item.material_status === 'P');
        if (item) {
          ProductionWInspStatus(item.material_status);
          ActionLabel('Save');
        } else {
          ProductionWInspStatus('P');
          ActionLabel('Save');
        }
      }
    }
  };
  const setRowCountAndAddRow = (rowCount) => {

    const tableRows = document.querySelectorAll('#inspectionMaterialTbl tbody tr');
    let lastRowSetNo = '';
    let lastRowShift = '';
    let lastRowMaterial = '';
    let lastConsumptionQty = '';

    if (tableRows.length > 0) {
      const lastRowIndex = tableRows.length - 1;
      const lastRowSelectSetNo = tableRows[lastRowIndex].querySelector('select[id^="inspection_production_set_no_"]');
      if (lastRowSelectSetNo) {
        lastRowSetNo = lastRowSelectSetNo.value;
      }
      const lastRowSelectMaterial = tableRows[lastRowIndex].querySelector('select[id^="product_material_id_"]');
      if (lastRowSelectMaterial) {
        lastRowMaterial = lastRowSelectMaterial.value;
      }

      const lastRowSelectShift = tableRows[lastRowIndex].querySelector('select[id^="shift_"]');
      if (lastRowSelectShift) {
        lastRowShift = lastRowSelectShift.value;
      }
      const lastRowConsumptionQty = tableRows[lastRowIndex].querySelector('input[id^="consumption_quantity_"]');
      if (lastRowConsumptionQty) {
        lastConsumptionQty = lastRowConsumptionQty.value;
      }
    }

    if (lastRowSetNo !== '' && lastRowShift !== '' && lastRowMaterial !== '' && (lastConsumptionQty !== '' && lastConsumptionQty !== '0')) {
      const getExistingInspProductionData = [...inspectionMaterialData]
      getExistingInspProductionData.push(inspectionMaterialBlankObject)
      setInspectionMaterialData(getExistingInspProductionData)
      onGetInspectionMaterialData(getExistingInspProductionData)
      setRowCount(rowCount + 1);
    } else {
      const lastRow = tableRows[tableRows.length - 1];
      const setNoInput = lastRow.querySelector('select[id^="inspection_production_set_no_"]');
      const shiftInput = lastRow.querySelector('select[id^="shift_"]');
      const materialName = lastRow.querySelector('select[id^="product_material_id_"]');
      const consumptionQty = lastRow.querySelector('input[id^="consumption_quantity_"]');

      if (shiftInput) {
        const selectedOption = shiftInput.options[shiftInput.selectedIndex];
        if (!selectedOption || !selectedOption.value) {
          shiftInput.parentElement.dataset.tip = 'Please select Shift';
          shiftInput.focus();
          return;
        } else {
          delete shiftInput.parentElement.dataset.tip;
        }
      }
      if (setNoInput) {
        const selectedOption = setNoInput.options[setNoInput.selectedIndex];
        if (!selectedOption || !selectedOption.value) {
          setNoInput.parentElement.dataset.tip = 'Please select SetNO.';
          setNoInput.focus();
          return;
        } else {
          delete setNoInput.parentElement.dataset.tip;
        }
      }

      if (materialName) {
        const selectedOption = materialName.options[materialName.selectedIndex];
        if (!selectedOption || !selectedOption.value) {
          materialName.parentElement.dataset.tip = 'Please select Material Name';
          materialName.focus();
          return;
        } else {
          delete materialName.parentElement.dataset.tip;
        }
      }
      if (consumptionQty.value === '0') {
        consumptionQty.parentElement.dataset.tip = 'Please add consumption Quntity';
        consumptionQty.focus();
        return;
      } else {
        delete consumptionQty.parentElement.dataset.tip;
      }
    }
  };

  const removeFirstRow = (indexToRemove) => {

    if (indexToRemove !== 0) {
      const inspectionMaterialDetails = [...inspectionMaterialData]
      const element = inspectionMaterialDetails[indexToRemove]
      const updatedInspProductionData = inspectionMaterialData.filter((item, index) => index !== indexToRemove);
      // update consumed qty of removed row materials

      const removedIndex = updatedInspProductionData.findIndex(item => item.product_material_id === element.product_material_id)
      if (removedIndex !== -1) {
        FnConsumptionChange(element.product_material_id, removedIndex, updatedInspProductionData)
      }
      setInspectionMaterialData(updatedInspProductionData)
      onGetInspectionMaterialData(updatedInspProductionData)

    } else {
      const updatedInspProductionData = [...inspectionMaterialData];
      updatedInspProductionData[0] = { ...inspectionMaterialBlankObject };
      setInspectionMaterialData(updatedInspProductionData);
      onGetInspectionMaterialData(updatedInspProductionData)

    }
  }


  const FnValidateForm = async () => {
    let validStatus = true;
    const tableRows1 = document.querySelectorAll('#inspectionMaterialTbl tbody tr');
    tableRows1.forEach(row => {
      const statusRemark = row.querySelector('input[id^="status_remark_"]');
      const materialStatus = row.querySelector(`select[id^="material_status_"]`);
      const status = statusRemark.value.trim();
      const materialStaturValue = materialStatus.value.trim();

      if (materialStaturValue === "R") {
        if (status === '') {
          statusRemark.parentElement.dataset.tip = 'Please enter the Rejection remark...!';
          statusRemark.focus();
          validStatus = false;
          return;
        } else {
          delete statusRemark.parentElement.dataset.tip;
        }
      } else {
        delete statusRemark.parentElement.dataset.tip;
      }

    });
    return validStatus;
  };


  //-----------------------------------------------------
  const FnFillMaterialOptions = (options, object) => {
    options.forEach(item => {
      const optionElement = document.createElement('option');
      optionElement.value = item.product_material_id;
      optionElement.text = item.product_material_name;
      optionElement.setAttribute('stock_quantity', item.stock_quantity);
      // optionElement.setAttribute('product_inspection_material_balance_quantity', item.product_inspection_material_balance_quantity);
      object.appendChild(optionElement);
    });
  }

  const displayRecordComponent = () => {

    switch (modalHeaderName) {
      case 'Warping Order':
        return <TGenericAddmaterialProductionfrm generatedSZNo={warpingOrderNo} setNO={setNo} keyForViewUpdate={'update'} productionmaterialDate={dt_inspection_production_date} subSectionId={subsection_id} />;

      default:
        return null;
    }
  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Warping Order':
        await renderMaterialNameList(setNo, RowIndexNo)
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 100)
  }

  // -------------------------------Stock consumption starts here---------------------------------------

  // Material quantity consumption 
  const FnMaterialConsumption = async (item, rowIndex) => {
    try {
      resetGlobalQuery();
      globalQuery.columns = ['godown_name', 'godown_section_name', 'godown_section_beans_name', 'closing_balance_quantity'
        , 'closing_balance_weight', 'product_unit_name', 'product_rm_id', "product_rm_name",
        "godown_id", "godown_section_id", "godown_section_beans_id"];
      globalQuery.table = "smv_product_rm_stock_summary";
      globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: item.product_material_id });
      // globalQuery.conditions.push({ field: "closing_balance_quantity", operator: "!=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const stockList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

      let totalConsumptionQty = item.consumption_quantity

      // This is for existing selected stock for godown
      if (item.hasOwnProperty('consumptionQtyInfo')) {
        item.consumptionQtyInfo?.map(consumeQty => {
          let totalConsumptionQtyGdWise = 0;

          let stockDetail = stockList.find(item => item.godown_id === consumeQty.godown_id
            && item.godown_section_id === consumeQty.godown_section_id && item.godown_section_beans_id === consumeQty.godown_section_beans_id)

          inspectionMaterialData.filter(item => item.product_material_id === consumeQty.product_material_id)
            .forEach(item => {
              if (item.consumptionQtyInfo) {
                item.consumptionQtyInfo.filter(filterConumptionInfo =>
                  filterConumptionInfo.product_material_id === consumeQty.product_material_id &&
                  filterConumptionInfo.godown_id === consumeQty.godown_id &&
                  filterConumptionInfo.godown_section_id === consumeQty.godown_section_id &&
                  filterConumptionInfo.godown_section_beans_id === consumeQty.godown_section_beans_id
                ).map(consumptionQtyInfo => {
                  const { consumption_quantity } = consumptionQtyInfo
                  totalConsumptionQtyGdWise += parseFloat(consumption_quantity)

                })
              }
            })

          if (stockDetail) {
            stockDetail.consumptionQtyInfo = consumeQty
            stockDetail.closing_balance_quantity = stockDetail.closing_balance_quantity - totalConsumptionQtyGdWise + parseFloat(consumeQty.consumption_quantity)
            // Accessing the index of stockDetail in stockList
            const stockIndex = stockList.indexOf(stockDetail);
            stockList[stockIndex] = stockDetail
          }
        })
      }
      // This is for new stock of godown
      else {
        inspectionMaterialData
          .filter(item => item.product_material_id === item.product_material_id)
          .forEach(item => {
            if (item.consumptionQtyInfo) {
              item.consumptionQtyInfo.map(consumptionQtyInfo => {
                const { product_material_id, godown_id, godown_section_id, godown_section_beans_id, consumption_quantity } = consumptionQtyInfo
                const stockIndex = stockList.findIndex(particularMaterialGd =>
                  particularMaterialGd.product_rm_id === product_material_id &&
                  particularMaterialGd.godown_id === godown_id &&
                  particularMaterialGd.godown_section_id === godown_section_id &&
                  particularMaterialGd.godown_section_beans_id === godown_section_beans_id
                );

                const getStockObject = stockIndex !== -1 ? stockList[stockIndex] : null;

                if (getStockObject) {
                  // Update key-value pair in getStockObject
                  getStockObject.closing_balance_quantity = getStockObject.closing_balance_quantity - consumption_quantity;
                  // Set the updated object back to the stockList array
                  stockList[getStockObject.index] = getStockObject;
                }
              })
            }
          })
      }

      stockList.map((stocks) => {
        const consumption_quantity = totalConsumptionQty > 0 ? Math.min(stocks.closing_balance_quantity, totalConsumptionQty) : 0;
        stocks.consumptionQtyInfo = {
          index: rowIndex,
          shift: item.shift,
          set_no: item.set_no,
          product_material_id: item.product_material_id,
          consumption_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(consumption_quantity), 4),
          godown_id: stocks.godown_id,
          godown_section_id: stocks.godown_section_id,
          godown_section_beans_id: stocks.godown_section_beans_id
        };
        totalConsumptionQty -= stocks.closing_balance_quantity;
      })

      console.log(stockList);
      setMaterialName($(`#product_material_id_${rowIndex} option:selected`).text())
      setUnitName(item.product_material_unit_name)
      setConsumptionQty(item.consumption_quantity)
      setMaterialStockList(stockList)
      setShow(true)

    } catch (error) {
      console.log(error);
    }
  }

  const FnMaterialWiseStockQtyConsumption = (item, event, index) => {
    const stockData = [...materialStockList]
    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = validateNumberDateInput.current.decimalNumber(event.target.value, 4)

    if (enteredValue > item.closing_balance_quantity) {
      let actualvalidation = document.querySelector(`#${event.target.id}`);
      actualvalidation.parentElement.dataset.tip = `Consumption quantity cannot be greater than stock quantity...!`;
      return false;
    } else {
      item[clickedColName] = enteredValue;
      item.consumptionQtyInfo.consumption_quantity = enteredValue;
      delete event.target.parentElement.dataset.tip;
      stockData[index] = item
      setMaterialStockList(stockData);
    }
  }

  const FnCloseStockConsumptionModal = () => {
    const stockData = [...materialStockList]
    const wpMovementData = [...inspectionMaterialData]

    // Check consumption quantity & stock qty matches or not validation
    const validateConsumptionQty = FnValidateStockConsumption()

    if (validateConsumptionQty) {
      const wpIndex = stockData[0]?.consumptionQtyInfo.index;
      if (wpIndex >= 0 && wpIndex < wpMovementData.length) {
        if (!wpMovementData[wpIndex].consumptionQtyInfo) {
          wpMovementData[wpIndex].consumptionQtyInfo = [];
        } else {
          wpMovementData[wpIndex].consumptionQtyInfo = [];
        }
      } else {
        console.error(`Index ${wpIndex} is out of range.`);
      }
      stockData.forEach(stock => {
        wpMovementData[wpIndex].consumptionQtyInfo.push(stock.consumptionQtyInfo);
      });
      console.log(wpMovementData);
      setInspectionMaterialData(wpMovementData)
      setShow(false)
    }
  };

  const FnValidateStockConsumption = () => {
    let erorMsgObj = document.querySelector(`#stock_consumption_error`);
    const stockData = [...materialStockList]
    let total_consumption_qty = stockData.reduce((total, item) => total + parseFloat(item.consumptionQtyInfo.consumption_quantity), 0)
    $('#stock_consumption_error').empty()
    if (total_consumption_qty < parseFloat(consumptionQty)) {
      erorMsgObj.textContent = "Total stock consumption quantity is less than consumption quantity...!";
      $('#stock_consumption_error').show();
      return false;
    } else if (total_consumption_qty > parseFloat(consumptionQty)) {
      if (erorMsgObj) {
        erorMsgObj.textContent = "Total stock consumption quantity cannot be greater than consumption quantity...!";
        $('#stock_consumption_error').show();
      }
      return false;
    }

    $('#stock_consumption_error').hide();
    return true;
  }

  return (
    <div>
      <ComboBox ref={comboDataAPiCall} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />

      <FrmValidations ref={validate} />
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="erp-form-label-md">Inspection Material Movement</Accordion.Header>
          <Accordion.Body>
            <Table id='inspectionMaterialTbl' className={`erp_table ${inspectionMaterialData.length !== 0 ? 'display' : 'd-none'}`} responsive bordered striped>
              <thead className="erp_table_head">
                <tr>
                  <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                  <th className="erp_table_th">Sr no .</th>
                  <th className="erp_table_th">Date</th>
                  <th className="erp_table_th">Shift</th>
                  <th className="erp_table_th">Set no</th>
                  <th className="erp_table_th">Material Name</th>
                  <th className="erp_table_th">Material Unit</th>
                  <th className="erp_table_th">Quantity</th>
                  <th className="erp_table_th">Balance</th>
                  <th className="erp_table_th">Stock Qty</th>
                  <th className="erp_table_th">Consumption Quantity</th>
                  <th className="erp_table_th">Material Status</th>
                  <th className="erp_table_th">Status Remark</th>
                </tr>
              </thead>
              <tbody>
                {inspectionMaterialData.map((item, index) =>

                  <tr rowindex={index} className="sticky-column">
                    <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                      <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                      <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCountAndAddRow(index)} />
                    </td>

                    <td className='erp_table_td'>
                      {index + 1}
                    </td>
                    <td className='erp_table_td'>
                      {
                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                          ? <>
                            <input
                              type="date"
                              className="erp_input_field mb-0"
                              disabled={item.material_status_desc === 'Approved'}
                              Headers="inspection_production_date"
                              id={`inspection_production_date_${index}`}
                              value={item.inspection_production_date}
                              onChange={(e) => FnUpdateInspMaterialMovementRecords(item, e, index)}
                            />
                          </>
                          : item.inspection_production_date
                      }
                    </td>
                    <td className={`erp_table_td `}>
                      {
                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                          ? <select id={`shift_${index}`} value={item.shift} className="erp_input_field_table_txt mb-0"
                            Headers="shift"
                            disabled={item.material_status_desc === 'Approved'}
                            onChange={(e) => FnUpdateInspMaterialMovementRecords(item, e, index)}
                          >
                            <option value="">Select</option>
                            {shiftOptions?.map(shift => (
                              <option value={shift.field_name}>{shift.field_name}</option>
                            ))}
                          </select>
                          : item.shift
                      }
                    </td>
                    <td className={`erp_table_td `}>
                      {
                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                          ? <select id={`inspection_production_set_no_${index}`} value={item.inspection_production_set_no} className="erp_input_field_table_txt mb-0"
                            Headers="inspection_production_set_no"
                            disabled={item.material_status_desc === 'Approved'}
                            onChange={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); }}
                          >
                            <option value="">Select</option>
                            {
                              //array in ascending order
                              setNoOptions.sort((a, b) => a.set_no - b.set_no).map(setNO => (
                                <option value={setNO.set_no}>{setNO.set_no}</option>
                              ))}

                          </select>
                          : item.inspection_production_set_no
                      }
                    </td>
                    <td className={`erp_table_td`}>
                      <select className="form-select form-select-sm mb-0" id={`product_material_id_${index}`} value={item.product_material_id}
                        onChange={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); }}
                        disabled={item.material_status_desc === 'Approved'}
                        Headers='product_material_id' >
                        <option value="">Select</option>
                      </select>
                    </td>

                    <td className='erp_table_td'>
                      {
                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                          ? <span className="erp_input_field_table_txt mb-0" id={`product_material_unit_name_${index}`}>{item.product_material_unit_name}</span>
                          : item.product_material_unit_name
                      }
                    </td>

                    <td className='erp_table_td'>
                      {
                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                          ? <input className="erp_input_field_table_txt mb-0" id={`product_material_quantity_${index}`}
                            Headers="product_material_quantity"
                            value={item.product_material_quantity}
                          />
                          : item.product_material_quantity
                      }
                    </td>
                    <td className='erp_table_td'>
                      {
                        keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view'
                          ? <input className="erp_input_field_table_txt mb-0" id={`product_inspection_material_balance_quantity_${index}`}
                            Headers="product_inspection_material_balance_quantity"
                            value={item.product_inspection_material_balance_quantity}
                          />
                          : item.product_inspection_material_balance_quantity
                      }
                    </td>
                    <td className={`erp_table_td`}>{item?.stock_quantity ? item.stock_quantity : 0}</td>
                    <td className='erp_table_td'>
                      {
                        keyForViewUpdate !== 'view'
                          ? <div className='d-flex' style={{ alignItems: 'center' }}>
                            <input
                              type="text"
                              className="erp_input_field_table_txt remove0 mb-0 ms-1"
                              id={`consumption_quantity_${index}`}
                              value={item.consumption_quantity}
                              Headers="consumption_quantity"
                              style={{ backgroundColor: '#AFE1AF' }}
                              onChange={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); }}
                              onBlur={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); }}
                              disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve'}
                              maxLength='19'
                            />
                            <FaDatabase onClick={() => FnMaterialConsumption(item, index)} />
                          </div>
                          : item.consumption_quantity
                      }
                    </td>

                    <td className="erp_table_td">
                      <select id={`material_status_${index}`} className="erp_input_field_table_txt form-select form-select-sm mb-0" value={item.material_status}
                        disabled={isApprove ? item.material_status_desc === 'Approved' : true}
                        onChange={(e) => { FnUpdateInspMaterialMovementRecords(item, e, index); FnValidateForm(); }}
                        Headers='material_status'>
                        <option value="P">Pending</option>
                        <option value="A">Approved</option>
                        <option value="R">Rejected</option>
                        <option value="I">Partial Issue</option>
                        <option value="C">Completed</option>
                        <option value="X">Canceled</option>
                        <option value="Z">PreeClosed</option>
                      </select>
                    </td>

                    <td className='erp_table_td'>
                      {keyForViewUpdate === 'approve' ? (
                        <input
                          type="text"
                          id={`status_remark_${index}`}
                          disabled={!isApprove}
                          className="erp_input_field_table_txt mb-0"
                          value={item.status_remark}
                          onChange={(e) => {
                            FnUpdateInspMaterialMovementRecords(item, e, index);

                          }}
                        />
                      ) : (
                        item.status_remark
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      {/* Add new Record Popup */}
      <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
        <Modal.Body className='erp_city_modal_body'>
          <div className='row'>
            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
          </div>
          {displayRecordComponent()}

        </Modal.Body>
      </Modal >


      {show ?
        <Modal show={show} onHide={closeConsumptionModal} centered size="lg">
          <Modal.Header closeButton style={{ fontSize: '11px' }}>
            <Modal.Title style={{ fontSize: '20px', color: '#045cb4' }}>Material Consumption</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '15px' }}>
            <dl className='row'>
              <dt className="col-sm-2 erp-form-label-md">Material & Unit: </dt>
              <dd className="col-sm-4 erp-form-label-md">{materialName} - {unitName}</dd>
              <dt className="col-sm-2 erp-form-label-md">Consumption Qty: </dt>
              <dd className="col-sm-4 erp-form-label-md">{consumptionQty}</dd>
            </dl>

            {materialStockList.length !== 0 ? (
              <>
                <Table striped bordered hover>
                  <thead className='erp_table_head'>
                    <tr>
                      <th className="erp_table_th">Location</th>
                      <th className="erp_table_th">Available Qty</th>
                      <th className="erp_table_th">Consumption Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialStockList.map((item, index) => (
                      <tr key={index}>

                        <td className='erp_table_td erp-form-label-md'>
                          {item.godown_section_name ? item.godown_section_beans_name ?
                            `${item.godown_name + '[' + item.godown_section_name + ']' + '[' + item.godown_section_beans_name + ']'}`
                            : `${item.godown_name + '[' + item.godown_section_name + ']'}` : item.godown_name}
                        </td>
                        <td className='erp_table_td'>{item.closing_balance_quantity}</td>
                        <td className='erp_table_td'>
                          <input
                            type="text"
                            value={item?.consumptionQtyInfo?.consumption_quantity}
                            className="erp_input_field mb-0"
                            disabled={item.material_status_desc === 'Approved' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'view'}
                            Headers="stock_consumption_quantity"
                            id={`stock_consumption_quantity_${index}`}
                            onChange={(event) => FnMaterialWiseStockQtyConsumption(item, event, index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <MDTypography variant="button" id="stock_consumption_error" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </>
            ) : (
              <div>Not available stock!...</div>
            )}
            <div className='text-center mt-1'>
              <Button className={`erp-gb-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} onClick={FnCloseStockConsumptionModal} >
                Ok
              </Button>
            </div>
          </Modal.Body>

        </Modal>
        : null}
    </div>
  )
}

export default FrmWInspMaterialMovementEntry