import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Modal from 'react-bootstrap/Modal';
import Tooltip from "@mui/material/Tooltip";
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
// Imports React bootstra
import Form from 'react-bootstrap/Form';

// Icons
import { MdRefresh } from "react-icons/md";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import ProfitCenterEntry from "Masters/ProfitCenter/ProfitCenterEntry";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import DepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";


function FrmProductionSectionEntry(props) {
    //changes by ujjwala on 27/12/2024
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { productionsectionId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

    // Use Refs to call external functions
    const validateNumberDateInput = useRef();
    const combobox = useRef();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const navigate = useNavigate()

    // production section Feilds
    const [txt_production_section_id, setproduction_sectionid] = useState(productionsectionId === null ? 0 : productionsectionId);


    const [txt_parentDepartment, setParentDepartment] = useState('');
    const [cmb_plant_id, setPlantName] = useState('');
    const [txt_sectionName, setSectionName] = useState('');
    const [txt_shortName, setShortName] = useState('');
    const [txt_costCenter, setCostCenter] = useState('');
    const [txt_profitCenter, setProfitCenter] = useState('');
    const [txt_SectionHeadID, setSectionHeadID] = useState();
    const [txt_SectionSubHeadID, setSectionSubHeadID] = useState();
    const [txt_SectionStaffStrength, setSectionStaffStrength] = useState(1);
    const [txt_Section_std_worker_strength, setSectionWorkerStrength] = useState(1);
    const [chk_isactive, setIsActive] = useState(true);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    //option Box
    const [parentDepartOption, setParentDepartOption] = useState([]);
    const [branchHeadOptions, setBranchHeadOptions] = useState([]);
    const [DeptSubHeadOptions, setDeptSubHeadOptions] = useState([]);
    const [costCenterOption, setCostCenterOption] = useState([]);
    const [profitCenterOption, setProfitCenterOption] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MProductionSection/FrmProductionSectionListing`)
        }
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await FillComobos();
            if (productionsectionId !== 0) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])

    const addProductionSection = async () => {
        
        try {
            const checkIsValidate = await validate.current.validateForm('ProductionSectionform');
            if (checkIsValidate === true) {
                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    production_section_id: txt_production_section_id,
                    plant_id: cmb_plant_id,
                    plant_name: $('#cmb_plant_id option:selected').text(),
                    created_by: UserName,
                    modified_by: txt_production_section_id === 0 ? null : UserName,
                    parent_department_id: txt_parentDepartment,
                    production_section_name: txt_sectionName,
                    production_section_short_name: txt_shortName,
                    cost_center_id: txt_costCenter,
                    profit_center_id: txt_profitCenter,
                    production_section_head_id: txt_SectionHeadID,
                    production_section_subhead_id: txt_SectionSubHeadID,
                    production_section_std_staff_strength: txt_SectionStaffStrength,
                    production_section_std_worker_strength: txt_Section_std_worker_strength,
                    is_active: chk_isactive,
                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSection/FnAddUpdateProdSectionRecord`, requestOptions)
                const responce = await apicall.json()
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    };

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_sectionName').attr('disabled', true)
                $('#txt_shortName').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("ProductionSectionform");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('ProductionSectionform')
    }

    const FnCheckUpdateResponce = async () => {
        
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSection/FnShowParticularProdSectionRecordForUpdate/${txt_production_section_id}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.ProductionSectionData

            setproduction_sectionid(data.production_section_id)
            setPlantName(data.plant_id);
            setParentDepartment(data.parent_department_id)
            setSectionName(data.production_section_name);
            setShortName(data.production_section_short_name);
            setCostCenter(data.cost_center_id);
            setProfitCenter(data.profit_center_id);
            setSectionHeadID(data.production_section_head_id);
            setSectionSubHeadID(data.production_section_subhead_id);
            setSectionStaffStrength(data.production_section_std_staff_strength);
            setSectionWorkerStrength(data.production_section_std_worker_strength);

            setIsActive(data.is_active);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const FillComobos = async () => {
        
        if (combobox.current) {
            ///Department List
            combobox.current.fillMasterData("cmv_department", "parent_department", "Production Department").then((departmentList) => {
                setParentDepartOption(departmentList);
            })

            ///Employee List
            combobox.current.fillMasterData("cmv_employee", "", "").then((employeeList) => {
                setBranchHeadOptions(employeeList)
                setDeptSubHeadOptions(employeeList)
            })

            // Plant List
            combobox.current.fillMasterData("cmv_plant", "", "").then((getProdPlantApiCall) => {
                setPlantOptions(getProdPlantApiCall);
            })

            //Costcenter List
            try {
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                globalQuery.table = "fmv_cost_center";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const costCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setCostCenterOption(costCenterList);

                //Profitcenter List
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
                globalQuery.table = "fmv_profit_center";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const profitCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setProfitCenterOption(profitCenterList);

            } catch (error) {
                console.log("error : ", error)
            }
            
        }
    }

    const addRecordInProperty = async (key) => {
        switch (key) {
            case 'parentdepartment':
                const departlist = document.getElementById('txt_parentDepartment').value
                setParentDepartment(departlist)
                $('#error_txt_parentDepartment').hide();
                if (departlist === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'SectionHead':
                const sectionHead = document.getElementById('txt_SectionHeadID').value;
                setSectionHeadID(sectionHead)
                $('#error_txt_SectionHeadID').hide();
                if (sectionHead === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'SectionSubHead':
                const SectionSubHead = document.getElementById('txt_SectionSubHeadID').value;
                setSectionSubHeadID(SectionSubHead)
                $('#error_txt_SectionSubHeadID').hide();
                if (SectionSubHead === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case 'cost_center':
                const costcenter = document.getElementById('txt_costCenter').value;
                setCostCenter(costcenter)
                $('#error_txt_costCenter').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;
            case 'profit_center':
                const profitcenter = document.getElementById('txt_profitCenter').value;
                setProfitCenter(profitcenter)
                $('#error_txt_profitCenter').hide();
                if (profitcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Profit Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;
            default:
                break;
        }
    }


    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Profit Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
                    globalQuery.table = "fmv_profit_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setProfitCenterOption(profitCenterList);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;
            case 'Cost Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                    globalQuery.table = "fmv_cost_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCostCenterOption(profitCenterList);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;
           

            case 'Department':
                combobox.current.fillMasterData("cmv_department", "", "").then((departmentList) => {
                    setParentDepartOption(departmentList)
                })
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 100)
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;

            case 'Profit Center':
                return <ProfitCenterEntry btn_disabled={true} />;

            case 'Department':
                return <DepartmentEntry btn_disabled={true} />;

            default:
                return null;
        }
    }

    const FnRefreshbtn = async (key) => {
        switch (key) {
            case 'DepartmentHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList1) => {
                    setBranchHeadOptions(empList1)
                })
                break;

            case 'DepartmentSubHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList) => {
                    setDeptSubHeadOptions(empList)
                })
                break;

            default:
                break;
        }
    }


    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <ComboBox ref={comboDataAPiCall} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Production Section {actionType} </label>
                    </div>
                    <form id="ProductionSectionform">
                        <div className="row erp_transporter_div">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Parent Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_parentDepartment" value={txt_parentDepartment} className="form-select form-select-sm erp_input_field" onChange={() => addRecordInProperty('parentdepartment')} disabled={keyForViewUpdate === 'update'}>
                                            <option value="">Select</option>
                                            {parentDepartOption?.map(parentDepart => (
                                                <option value={parentDepart.field_id}>{parentDepart.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_parentDepartment" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { setPlantName(e.target.value); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Section Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_sectionName" value={txt_sectionName} onChange={e => { setSectionName(e.target.value); validateFields() }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_sectionName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_shortName" className="erp_input_field" value={txt_shortName} onChange={(e) => { setShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="255" optional="optional" disabled={keyForViewUpdate === 'update'} />
                                        <MDTypography variant="button" id="error_txt_shortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Profit Center</Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_profitCenter" value={txt_profitCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('profit_center'); }} optional="optional" >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {profitCenterOption?.map(option => (
                                                // <option value={option.field_id}>{option.field_name}</option>
                                                <option value={option.field_id}>[{option.profit_center_short_name}] {option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_profitCenter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Cost Center</Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_costCenter" value={txt_costCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('cost_center'); }} optional="optional" >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {costCenterOption?.map(option => (
                                                // <option value={option.field_id}>{option.field_name}</option>
                                                <option value={option.field_id}>[{option.cost_center_short_name}] {option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_costCenter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label"> Production Head <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_SectionHeadID" value={txt_SectionHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("SectionHead"); }}>
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {branchHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_SectionHeadID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("DepartmentHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Production SubHead</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_SectionSubHeadID" value={txt_SectionSubHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("SectionSubHead"); }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>

                                            {DeptSubHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>

                                            ))}
                                        </select>

                                        <MDTypography variant="button" id="error_txt_SectionSubHeadID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("DepartmentSubHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Strength <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_SectionStaffStrength" className="erp_input_field erp_align-right" value={txt_SectionStaffStrength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSectionStaffStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_SectionStaffStrength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Wroker </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Section_std_worker_strength" className="erp_input_field erp_align-right" value={txt_Section_std_worker_strength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSectionWorkerStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_Section_std_worker_strength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MProductionSection/FrmProductionSectionListing/reg' : '/Masters/MProductionSection/FrmProductionSectionListing';
                                navigate(path);
                            }}

                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={addProductionSection} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>


                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}

                    </Modal.Body>
                </Modal >
            </div>
        </>
    )
}

export default FrmProductionSectionEntry;
